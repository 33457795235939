import React from 'react';
import { Checkbox } from '@jotforminc/checkbox';

const CustomCheckbox = ({
  value,
  options,
  inputClass,
  onChange
} : IProps) : JSX.Element => {
  const handleCheckboxUpdate = (keyValue, isChecked, isDisabled) => {
    if (!isDisabled) {
      const values = value.length > 0 ? value.split('|') : [];
      const updatedValues = isChecked ? values.filter(val => val !== keyValue) : [...values, keyValue];
      onChange(updatedValues.join('|'));
    }
  };

  // for additional class
  const additionalClass = ((inputClass !== '') && inputClass) ? ` ${inputClass}` : '';

  return (
    <>
      {options.map(option => {
        return (
          <div
            className={`pwCheckbox${additionalClass}`}
            onChange={() => handleCheckboxUpdate(option.value, option.isValueChecked, option.disabled)}
          >
            <Checkbox
              disabled={option.disabled}
              tabIndex={option.disabled ? -1 : 0}
              checked={option.isValueChecked}
            />
            <span className='pwCheckbox-text'>{option.text}</span>
            {option.isRequired
            && <span className='pwCheckbox-required'>*</span>}
          </div>
        );
      })}
    </>
  );
};

interface IOptions {
  value: string | number
  text: string | number
  isValueChecked: boolean
  disabled: boolean
  isRequired: boolean
}

export interface IProps {
  value: string;
  options: IOptions[];
  onChange: () => void;
  inputClass: string
}
CustomCheckbox.defaultProps = {
  inputClass: ''
};

export default CustomCheckbox;
