import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@jotforminc/magnet';
import * as ACTION_CREATORS from '../../store/actionCreators';
import { MODALS } from '../../constants/modals';

const ReplaceFormButton = ({ formID: currentFormID, ...props }) => {
  const dispatch = useDispatch();

  const handleSelectForm = useCallback(([formID]) => dispatch(ACTION_CREATORS.replaceFormItemAction({ itemID: currentFormID, formID })), [currentFormID]);

  const handleChangeResourceClick = useCallback(e => {
    e.stopPropagation();
    dispatch(ACTION_CREATORS.showGenericModalAction({
      name: MODALS.FORM_PICKER_MODAL,
      onConfirm: handleSelectForm,
      initialSelectedForms: [currentFormID],
      isChangeFormAction: true,
      isMultiSelect: false
    }));
  }, [handleSelectForm, currentFormID]);

  return (
    <Button
      onClick={handleChangeResourceClick}
      variant='outline'
      fullWidth
      className='customMagnetBtn text-capitalize'
      {...props}
    />
  );
};

ReplaceFormButton.propTypes = {
  formID: PropTypes.string.isRequired
};

export default ReplaceFormButton;
