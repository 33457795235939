/* eslint-disable react/button-has-type */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles/app.scss';

const SliderCarousel = ({ imgArray, currentImg, setCurrentImg }) => {
  return (
    <div className='imageCarouselWrapper'>
      <div className='imageCarousel'>
        {imgArray.map((img, index) => {
          const isSelected = currentImg === index;
          return (
            <Fragment key={img}>
              <img
                className={`desktopNav ${isSelected ? ' selected' : ''}`}
                onClick={() => setCurrentImg(index)}
                src={img}
              />
              <button
                className={`mobileNavBtn ${isSelected ? ' selected' : ''}`}
                onClick={() => setCurrentImg(index)}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

SliderCarousel.propTypes = {
  imgArray: PropTypes.array.isRequired,
  currentImg: PropTypes.number,
  setCurrentImg: PropTypes.func
};

SliderCarousel.defaultProps = {
  currentImg: 0,
  setCurrentImg: f => f
};

export default SliderCarousel;
