import React from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';

const PanelItemDescriptionRenderer = ({ description, ...props }) => (
  <div {...props} className="field-description" data-testid="fieldDescription">{t(description)}</div>
);

PanelItemDescriptionRenderer.propTypes = { description: string };
PanelItemDescriptionRenderer.defaultProps = { description: '' };

export default PanelItemDescriptionRenderer;
