import React from 'react';
import { FeatureToggles } from '@jotforminc/feature-toggle';
import { useEnabledFeatures } from './utils/features/helper';

const withFeatureToggles = (features = useEnabledFeatures()) => Component => () => (
  <FeatureToggles features={features}>
    <Component />
  </FeatureToggles>
);

export default withFeatureToggles;
