import {
  bool, elementType, func, string
} from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Button from '../../../../../../../components/Button';
import { primaryAccentDefault, rightPanelItemBorderColor } from '../../../../../../../styles/colors';

const ScRolesItem = styled.div`
.roleBtn {
  border: 1px solid ${rightPanelItemBorderColor};
  background-color: var(--jfv-panel-right-item-background);
  ${({ isSelected }) => {
    return isSelected && css`background-color: ${primaryAccentDefault};`;
  }}

  padding: 12px 38px;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  transition: background-color .3s ease;

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg { width: 35px; color: #fff; fill: #fff; }
  }

  &:hover {
    background-color: var(--jfv-panel-darkest-gray);
    ${({ isSelected }) => { return isSelected && css`background-color: ${primaryAccentDefault};`; }}
  }

  &-title {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
  }

  @media screen and (max-width: 480px) {
    padding: 12px 44px;
  }
}
`;

const RolesItem = ({
  title, Icon, onChange, isSelected
}) => {
  return (
    <ScRolesItem isSelected={isSelected}>
      <Button className="roleBtn" onClick={onChange}>
        <div className="roleBtn-icon">
          <Icon />
        </div>
      </Button>
      <span className="roleBtn-title">{title}</span>
    </ScRolesItem>
  );
};

RolesItem.propTypes = {
  title: string,
  Icon: elementType,
  onChange: func,
  isSelected: bool
};

RolesItem.defaultProps = {
  title: '',
  Icon: props => <div {...props} />,
  onChange: f => f,
  isSelected: false
};

export default RolesItem;
