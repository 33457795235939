export const mainFontColor = '#0A1551';
export const primaryPurple = '#9B61D5';
export const primaryRed = '#FF4947';

export const warningYellow = '#FAA500';

export const leftPanelBgColor = '#565F6E';
export const leftPanelItemSeperatorColor = '#4C5360';
export const leftPanelItemIconBgColor = '#394049';
export const leftPanelItemIconBgColorHovered = '#AC78E1';

export const rightPanelItemBorderColor = '#303339';
export const rightPanelItemBgColor = '#555F6E';

export const settingsBgColor = '#F3F3FE';
export const settingsLeftPanelBgColor = '#404651';

export const branding21MainBlue = '#0099FF';
export const primaryAccentDefault = '#0075e3';
export const primaryAccentDefaultHover = '#0066C3';
