import { IPaymentConnectionItem } from '../../types/common';
import * as actionTypes from '../actionTypes';

const connectionsReducer = (state: IPaymentConnectionItem[] = [], action: actionType) : IPaymentConnectionItem[] => {
  switch (action.type) {
    case actionTypes.INIT_CONNECTIONS:
      return action.payload.connections;
    case actionTypes.CREATE_CONNECTION:
      return [...state, action.payload.connection];
    case actionTypes.UPDATE_CONNECTION:
      return state.map(connection => {
        if (connection.cid === action.payload.connection.cid) {
          return { ...connection, ...action.payload.connection };
        }

        return connection;
      });
    case actionTypes.REMOVE_CONNECTION:
      return state.filter(connection => connection.cid !== action.payload.cid);
    default:
      return state;
  }
};

interface payloadType {
  connections: IPaymentConnectionItem[]
  connection: IPaymentConnectionItem,
  cid: IPaymentConnectionItem['cid']
}

interface actionType {
  type: string,
  payload: payloadType
}

export default connectionsReducer;
