import {
  bool, oneOfType, shape, number, string, node, elementType, objectOf, oneOf, arrayOf
} from 'prop-types';

const answersPropType = oneOfType([
  objectOf(shape({})).isRequired,
  bool
]);

const formPropType = shape({
  id: oneOfType([string, number]).isRequired,
  title: string.isRequired,
  isEncrypted: oneOf(['Yes', 'No']),
  count: oneOfType([string, number]).isRequired,
  new: oneOfType([string, number]).isRequired,
  flag: oneOfType([string, number]),
  last_submission: string,
  pdfTemplates: arrayOf(shape({})),
  paymentStrings: arrayOf(objectOf(string)),
  products: arrayOf(objectOf(oneOfType([shape({}), string]))),
  hideSubmissionHeader: oneOf(['Yes', 'No']),
  showApprovalStatus: oneOf(['Yes', 'No'])
});

const propTypes = {
  renderable: oneOfType([
    elementType,
    node
  ]),
  stringOrNumber: oneOfType([
    number,
    string
  ]),
  submissionPropType: shape({
    id: oneOfType([string, number]).isRequired,
    answers: answersPropType.isRequired,
    created_at: string.isRequired
  }),
  userPropType: shape({
    email: string,
    username: string.isRequired,
    language: string.isRequired,
    forms: arrayOf(formPropType).isRequired,
    account_type: oneOfType([string, shape({
      name: string
    })]).isRequired
  })
};

export {
  propTypes
};
