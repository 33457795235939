import {
  useEffect,
  useState,
  useRef
} from 'react';
import { ABTestManager } from '@jotforminc/abtest-manager';

export const useABTestManager = options => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTestVariant, setIsTestVariant] = useState(false);
  const abTestActionRef = useRef(f => f);

  useEffect(() => {
    const abTestManager = new ABTestManager(options);
    abTestManager.isTestVariant().then(setIsTestVariant).finally(() => setIsLoading(false));
    abTestActionRef.current = abTestManager.registerABTestAction;
  }, []);

  return [isLoading, isTestVariant, abTestActionRef.current];
};

export const useOutsideClick = (callback, exceptionalSelectorList = []) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      event.stopPropagation();
      let clickedExceptionalElement = false;
      for (let index = 0; index < exceptionalSelectorList.length; index++) {
        const selector = exceptionalSelectorList[index];
        const exceptionalEl = document.querySelector(selector);
        if (exceptionalEl && exceptionalEl.contains(event.target)) {
          clickedExceptionalElement = true;
          break;
        }
      }
      if (clickedExceptionalElement) return;

      if (ref.current && (!ref.current.contains(event.target) || ref.current === event.target)) {
        callback();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
