import { ModalBody } from '@jotforminc/magnet';
import styled from 'styled-components';

export const StyledTableProductModalBody = styled(ModalBody)`
    padding: 0 !important;
    border-radius: 0.5rem;
    iframe {
        width: 100%;
        min-height: 40.5rem;
    }
`;
