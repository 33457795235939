import React from 'react';
import {
  string, object, bool, func
} from 'prop-types';
import './style.scss';
import { AppPreviewBody } from '../../../AppPreview';

const AppTemplateAreaRenderer = ({
  modelContentLoading, backgroundColor, setModelContentLoading,
  rightArrowRef, leftArrowRef, template
}) => {
  const { app_id: appID } = template;
  return (
    <div style={{ backgroundColor: '#F3F3FE' }} className="modal-template-area relative z-1 md:min-h-sm duration-200 w-full h-108 md:h-120 lg:border border-navy-75 lg:radius-md overflow-hidden">
      <div className="app-template relative flex w-full h-full" style={{ backgroundColor }}>
        <div className="template-area w-full h-full flex items-start justify-center">
          <div className="template-area-track w-full h-full flex overflow-hidden items-center justify-center">
            <AppPreviewBody
              template={template}
              currentThemeId={appID}
              customTryText='Try this app!'
              showAsTemplatePreview={true}
              customOnLoad={() => {
                setModelContentLoading(false);
                rightArrowRef.current.classList.remove('load-active');
                leftArrowRef.current.classList.remove('load-active');
              }}
              modalContentLoading={modelContentLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AppTemplateAreaRenderer.defaultProps = {
  backgroundColor: '',
  rightArrowRef: null,
  leftArrowRef: null,
  modelContentLoading: true,
  template: {},
  setModelContentLoading: f => f
};

AppTemplateAreaRenderer.propTypes = {
  modelContentLoading: bool,
  backgroundColor: string,
  rightArrowRef: object,
  leftArrowRef: object,
  template: object,
  setModelContentLoading: func
};

export default AppTemplateAreaRenderer;
