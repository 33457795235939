import Styled from 'styled-components';

export default Styled.div`
  display: flex;
  border: 1px solid #303339;
  box-shadow: rgba(86, 70, 50, 0.1) 0px 2px 4px;
  background-color: #555F6E;
  align-items: center;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  margin: 2px;

  .previewMode-image {

    img {
      width: 120px;
      min-height: 120px;
      max-height: 120px;
      object-fit: contain;
    }

    .jfIconSVG-wrapper {
      svg {
        width: 120px;
        min-height: 64px;
        max-height: 120px;
        object-fit: contain;
      }
    }
  }

  .rightSideWrapper {
    flex: 1 1 auto;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
    background-color: #FF4947;
    width: 32px;
    height: 32px;
    border: 1px solid #c94948;
    border-radius: 3px;
    position: absolute;
    right: 8px;
    top: 8px;
    opacity: 0;
    pointer-events: none;

    .previewHint {
      font-size: 16px;
      line-height: 14px;
      color: #fff;

      & ~ .previewMode-button {
        margin-top: 15px;

        button {
          font-size: 12px;
          border-radius: 26px;
          padding: 7px 16px;
          margin: 0;
          float: left;
        }
      }
    }

    .previewMode-button {
      button {
        display: inline-block;
        cursor: pointer;
        border-radius: 22px;
        background-color: #0099FF;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        padding: 0.875em 3em;
        color: rgb(255, 255, 255);
        border: none;
        outline: none;
        flex-wrap: nowrap;
        margin: 15px;

        &:hover {
          background-color: #0089e4;
          transition: .1s all ease;
        }
      }

      .multipleImage-removeBtn{
        svg {
          width: 16px;
          height: 16px;
          fill: #fff;
          margin-top: 6px;
        }

        svg path{
          fill: #fff;
        }
      }
    }

    @media screen and (max-width: 480px) {
      white-space: normal;

      .previewMode-button {
        button {
          font-size: 14px;
          padding: 0.6em 0.875em;
        }
      }
    }
  }

  ${({ layout }) => (layout === 'column') && `
    flex-direction: column;

    .previewMode-image {
      img {
        width: 100%;
      }
    }

    .rightSideWrapper {
      .previewMode-button {
        button {
          border-radius: 30px;
          font-size: 12px;
          flex-wrap: nowrap;
          padding: 7px 16px;
          margin: 0;
          float: left;

          &:hover {
            background-color: #0089e4;
            transition: .1s all ease;
          }
        }
      }
    }
  `}
`;
