import {
  GRID_ITEM_START_END_VALUES,
  GRID_TEMPLATE_COLUMNS_VALUES,
  JUSTIFY_SELF_VALUES
} from '../../constants/common.values';
import { ResponsiveClasses } from '../../utils';

export const gridTemplateColumnsClasses:ResponsiveClasses<typeof GRID_TEMPLATE_COLUMNS_VALUES> = {
  none: {
    base: 'cols-none',
    xs: 'xs:cols-none',
    sm: 'sm:cols-none',
    md: 'md:cols-none',
    lg: 'lg:cols-none',
    xl: 'xl:cols-none',
    xxl: 'xxl:cols-none'
  },
  1: {
    base: 'cols-1',
    xs: 'xs:cols-1',
    sm: 'sm:cols-1',
    md: 'md:cols-1',
    lg: 'lg:cols-1',
    xl: 'xl:cols-1',
    xxl: 'xxl:cols-1'
  },
  2: {
    base: 'cols-2',
    xs: 'xs:cols-2',
    sm: 'sm:cols-2',
    md: 'md:cols-2',
    lg: 'lg:cols-2',
    xl: 'xl:cols-2',
    xxl: 'xxl:cols-2'
  },
  3: {
    base: 'cols-3',
    xs: 'xs:cols-3',
    sm: 'sm:cols-3',
    md: 'md:cols-3',
    lg: 'lg:cols-3',
    xl: 'xl:cols-3',
    xxl: 'xxl:cols-3'
  },
  4: {
    base: 'cols-4',
    xs: 'xs:cols-4',
    sm: 'sm:cols-4',
    md: 'md:cols-4',
    lg: 'lg:cols-4',
    xl: 'xl:cols-4',
    xxl: 'xxl:cols-4'
  },
  5: {
    base: 'cols-5',
    xs: 'xs:cols-5',
    sm: 'sm:cols-5',
    md: 'md:cols-5',
    lg: 'lg:cols-5',
    xl: 'xl:cols-5',
    xxl: 'xxl:cols-5'
  },
  6: {
    base: 'cols-6',
    xs: 'xs:cols-6',
    sm: 'sm:cols-6',
    md: 'md:cols-6',
    lg: 'lg:cols-6',
    xl: 'xl:cols-6',
    xxl: 'xxl:cols-6'
  },
  7: {
    base: 'cols-7',
    xs: 'xs:cols-7',
    sm: 'sm:cols-7',
    md: 'md:cols-7',
    lg: 'lg:cols-7',
    xl: 'xl:cols-7',
    xxl: 'xxl:cols-7'
  },
  8: {
    base: 'cols-8',
    xs: 'xs:cols-8',
    sm: 'sm:cols-8',
    md: 'md:cols-8',
    lg: 'lg:cols-8',
    xl: 'xl:cols-8',
    xxl: 'xxl:cols-8'
  },
  9: {
    base: 'cols-9',
    xs: 'xs:cols-9',
    sm: 'sm:cols-9',
    md: 'md:cols-9',
    lg: 'lg:cols-9',
    xl: 'xl:cols-9',
    xxl: 'xxl:cols-9'
  },
  10: {
    base: 'cols-10',
    xs: 'xs:cols-10',
    sm: 'sm:cols-10',
    md: 'md:cols-10',
    lg: 'lg:cols-10',
    xl: 'xl:cols-10',
    xxl: 'xxl:cols-10'
  },
  11: {
    base: 'cols-11',
    xs: 'xs:cols-11',
    sm: 'sm:cols-11',
    md: 'md:cols-11',
    lg: 'lg:cols-11',
    xl: 'xl:cols-11',
    xxl: 'xxl:cols-11'
  },
  12: {
    base: 'cols-12',
    xs: 'xs:cols-12',
    sm: 'sm:cols-12',
    md: 'md:cols-12',
    lg: 'lg:cols-12',
    xl: 'xl:cols-12',
    xxl: 'xxl:cols-12'
  }
};

export const gridColumnStartClasses:ResponsiveClasses<typeof GRID_ITEM_START_END_VALUES> = {
  auto: {
    base: 'col-start-auto',
    xs: 'xs:col-start-auto',
    sm: 'sm:col-start-auto',
    md: 'md:col-start-auto',
    lg: 'lg:col-start-auto',
    xl: 'xl:col-start-auto',
    xxl: 'xxl:col-start-auto'
  },
  1: {
    base: 'col-start-1',
    xs: 'xs:col-start-1',
    sm: 'sm:col-start-1',
    md: 'md:col-start-1',
    lg: 'lg:col-start-1',
    xl: 'xl:col-start-1',
    xxl: 'xxl:col-start-1'
  },
  2: {
    base: 'col-start-2',
    xs: 'xs:col-start-2',
    sm: 'sm:col-start-2',
    md: 'md:col-start-2',
    lg: 'lg:col-start-2',
    xl: 'xl:col-start-2',
    xxl: 'xxl:col-start-2'
  },
  3: {
    base: 'col-start-3',
    xs: 'xs:col-start-3',
    sm: 'sm:col-start-3',
    md: 'md:col-start-3',
    lg: 'lg:col-start-3',
    xl: 'xl:col-start-3',
    xxl: 'xxl:col-start-3'
  },
  4: {
    base: 'col-start-4',
    xs: 'xs:col-start-4',
    sm: 'sm:col-start-4',
    md: 'md:col-start-4',
    lg: 'lg:col-start-4',
    xl: 'xl:col-start-4',
    xxl: 'xxl:col-start-4'
  },
  5: {
    base: 'col-start-5',
    xs: 'xs:col-start-5',
    sm: 'sm:col-start-5',
    md: 'md:col-start-5',
    lg: 'lg:col-start-5',
    xl: 'xl:col-start-5',
    xxl: 'xxl:col-start-5'
  },
  6: {
    base: 'col-start-6',
    xs: 'xs:col-start-6',
    sm: 'sm:col-start-6',
    md: 'md:col-start-6',
    lg: 'lg:col-start-6',
    xl: 'xl:col-start-6',
    xxl: 'xxl:col-start-6'
  },
  7: {
    base: 'col-start-7',
    xs: 'xs:col-start-7',
    sm: 'sm:col-start-7',
    md: 'md:col-start-7',
    lg: 'lg:col-start-7',
    xl: 'xl:col-start-7',
    xxl: 'xxl:col-start-7'
  },
  8: {
    base: 'col-start-8',
    xs: 'xs:col-start-8',
    sm: 'sm:col-start-8',
    md: 'md:col-start-8',
    lg: 'lg:col-start-8',
    xl: 'xl:col-start-8',
    xxl: 'xxl:col-start-8'
  },
  9: {
    base: 'col-start-9',
    xs: 'xs:col-start-9',
    sm: 'sm:col-start-9',
    md: 'md:col-start-9',
    lg: 'lg:col-start-9',
    xl: 'xl:col-start-9',
    xxl: 'xxl:col-start-9'
  },
  10: {
    base: 'col-start-10',
    xs: 'xs:col-start-10',
    sm: 'sm:col-start-10',
    md: 'md:col-start-10',
    lg: 'lg:col-start-10',
    xl: 'xl:col-start-10',
    xxl: 'xxl:col-start-10'
  },
  11: {
    base: 'col-start-11',
    xs: 'xs:col-start-11',
    sm: 'sm:col-start-11',
    md: 'md:col-start-11',
    lg: 'lg:col-start-11',
    xl: 'xl:col-start-11',
    xxl: 'xxl:col-start-11'
  },
  12: {
    base: 'col-start-12',
    xs: 'xs:col-start-12',
    sm: 'sm:col-start-12',
    md: 'md:col-start-12',
    lg: 'lg:col-start-12',
    xl: 'xl:col-start-12',
    xxl: 'xxl:col-start-12'
  }
};

export const gridColumnEndClasses:ResponsiveClasses<typeof GRID_ITEM_START_END_VALUES> = {
  auto: {
    base: 'col-end-auto',
    xs: 'xs:col-end-auto',
    sm: 'sm:col-end-auto',
    md: 'md:col-end-auto',
    lg: 'lg:col-end-auto',
    xl: 'xl:col-end-auto',
    xxl: 'xxl:col-end-auto'
  },
  1: {
    base: 'col-end-1',
    xs: 'xs:col-end-1',
    sm: 'sm:col-end-1',
    md: 'md:col-end-1',
    lg: 'lg:col-end-1',
    xl: 'xl:col-end-1',
    xxl: 'xxl:col-end-1'
  },
  2: {
    base: 'col-end-2',
    xs: 'xs:col-end-2',
    sm: 'sm:col-end-2',
    md: 'md:col-end-2',
    lg: 'lg:col-end-2',
    xl: 'xl:col-end-2',
    xxl: 'xxl:col-end-2'
  },
  3: {
    base: 'col-end-3',
    xs: 'xs:col-end-3',
    sm: 'sm:col-end-3',
    md: 'md:col-end-3',
    lg: 'lg:col-end-3',
    xl: 'xl:col-end-3',
    xxl: 'xxl:col-end-3'
  },
  4: {
    base: 'col-end-4',
    xs: 'xs:col-end-4',
    sm: 'sm:col-end-4',
    md: 'md:col-end-4',
    lg: 'lg:col-end-4',
    xl: 'xl:col-end-4',
    xxl: 'xxl:col-end-4'
  },
  5: {
    base: 'col-end-5',
    xs: 'xs:col-end-5',
    sm: 'sm:col-end-5',
    md: 'md:col-end-5',
    lg: 'lg:col-end-5',
    xl: 'xl:col-end-5',
    xxl: 'xxl:col-end-5'
  },
  6: {
    base: 'col-end-6',
    xs: 'xs:col-end-6',
    sm: 'sm:col-end-6',
    md: 'md:col-end-6',
    lg: 'lg:col-end-6',
    xl: 'xl:col-end-6',
    xxl: 'xxl:col-end-6'
  },
  7: {
    base: 'col-end-7',
    xs: 'xs:col-end-7',
    sm: 'sm:col-end-7',
    md: 'md:col-end-7',
    lg: 'lg:col-end-7',
    xl: 'xl:col-end-7',
    xxl: 'xxl:col-end-7'
  },
  8: {
    base: 'col-end-8',
    xs: 'xs:col-end-8',
    sm: 'sm:col-end-8',
    md: 'md:col-end-8',
    lg: 'lg:col-end-8',
    xl: 'xl:col-end-8',
    xxl: 'xxl:col-end-8'
  },
  9: {
    base: 'col-end-9',
    xs: 'xs:col-end-9',
    sm: 'sm:col-end-9',
    md: 'md:col-end-9',
    lg: 'lg:col-end-9',
    xl: 'xl:col-end-9',
    xxl: 'xxl:col-end-9'
  },
  10: {
    base: 'col-end-10',
    xs: 'xs:col-end-10',
    sm: 'sm:col-end-10',
    md: 'md:col-end-10',
    lg: 'lg:col-end-10',
    xl: 'xl:col-end-10',
    xxl: 'xxl:col-end-10'
  },
  11: {
    base: 'col-end-11',
    xs: 'xs:col-end-11',
    sm: 'sm:col-end-11',
    md: 'md:col-end-11',
    lg: 'lg:col-end-11',
    xl: 'xl:col-end-11',
    xxl: 'xxl:col-end-11'
  },
  12: {
    base: 'col-end-12',
    xs: 'xs:col-end-12',
    sm: 'sm:col-end-12',
    md: 'md:col-end-12',
    lg: 'lg:col-end-12',
    xl: 'xl:col-end-12',
    xxl: 'xxl:col-end-12'
  }
};

export const gridRowStartClasses:ResponsiveClasses<typeof GRID_ITEM_START_END_VALUES> = {
  auto: {
    base: 'row-start-auto',
    xs: 'xs:row-start-auto',
    sm: 'sm:row-start-auto',
    md: 'md:row-start-auto',
    lg: 'lg:row-start-auto',
    xl: 'xl:row-start-auto',
    xxl: 'xxl:row-start-auto'
  },
  1: {
    base: 'row-start-1',
    xs: 'xs:row-start-1',
    sm: 'sm:row-start-1',
    md: 'md:row-start-1',
    lg: 'lg:row-start-1',
    xl: 'xl:row-start-1',
    xxl: 'xxl:row-start-1'
  },
  2: {
    base: 'row-start-2',
    xs: 'xs:row-start-2',
    sm: 'sm:row-start-2',
    md: 'md:row-start-2',
    lg: 'lg:row-start-2',
    xl: 'xl:row-start-2',
    xxl: 'xxl:row-start-2'
  },
  3: {
    base: 'row-start-3',
    xs: 'xs:row-start-3',
    sm: 'sm:row-start-3',
    md: 'md:row-start-3',
    lg: 'lg:row-start-3',
    xl: 'xl:row-start-3',
    xxl: 'xxl:row-start-3'
  },
  4: {
    base: 'row-start-4',
    xs: 'xs:row-start-4',
    sm: 'sm:row-start-4',
    md: 'md:row-start-4',
    lg: 'lg:row-start-4',
    xl: 'xl:row-start-4',
    xxl: 'xxl:row-start-4'
  },
  5: {
    base: 'row-start-5',
    xs: 'xs:row-start-5',
    sm: 'sm:row-start-5',
    md: 'md:row-start-5',
    lg: 'lg:row-start-5',
    xl: 'xl:row-start-5',
    xxl: 'xxl:row-start-5'
  },
  6: {
    base: 'row-start-6',
    xs: 'xs:row-start-6',
    sm: 'sm:row-start-6',
    md: 'md:row-start-6',
    lg: 'lg:row-start-6',
    xl: 'xl:row-start-6',
    xxl: 'xxl:row-start-6'
  },
  7: {
    base: 'row-start-7',
    xs: 'xs:row-start-7',
    sm: 'sm:row-start-7',
    md: 'md:row-start-7',
    lg: 'lg:row-start-7',
    xl: 'xl:row-start-7',
    xxl: 'xxl:row-start-7'
  },
  8: {
    base: 'row-start-8',
    xs: 'xs:row-start-8',
    sm: 'sm:row-start-8',
    md: 'md:row-start-8',
    lg: 'lg:row-start-8',
    xl: 'xl:row-start-8',
    xxl: 'xxl:row-start-8'
  },
  9: {
    base: 'row-start-9',
    xs: 'xs:row-start-9',
    sm: 'sm:row-start-9',
    md: 'md:row-start-9',
    lg: 'lg:row-start-9',
    xl: 'xl:row-start-9',
    xxl: 'xxl:row-start-9'
  },
  10: {
    base: 'row-start-10',
    xs: 'xs:row-start-10',
    sm: 'sm:row-start-10',
    md: 'md:row-start-10',
    lg: 'lg:row-start-10',
    xl: 'xl:row-start-10',
    xxl: 'xxl:row-start-10'
  },
  11: {
    base: 'row-start-11',
    xs: 'xs:row-start-11',
    sm: 'sm:row-start-11',
    md: 'md:row-start-11',
    lg: 'lg:row-start-11',
    xl: 'xl:row-start-11',
    xxl: 'xxl:row-start-11'
  },
  12: {
    base: 'row-start-12',
    xs: 'xs:row-start-12',
    sm: 'sm:row-start-12',
    md: 'md:row-start-12',
    lg: 'lg:row-start-12',
    xl: 'xl:row-start-12',
    xxl: 'xxl:row-start-12'
  }
};

export const gridRowEndClasses:ResponsiveClasses<typeof GRID_ITEM_START_END_VALUES> = {
  auto: {
    base: 'row-end-auto',
    xs: 'xs:row-end-auto',
    sm: 'sm:row-end-auto',
    md: 'md:row-end-auto',
    lg: 'lg:row-end-auto',
    xl: 'xl:row-end-auto',
    xxl: 'xxl:row-end-auto'
  },
  1: {
    base: 'row-end-1',
    xs: 'xs:row-end-1',
    sm: 'sm:row-end-1',
    md: 'md:row-end-1',
    lg: 'lg:row-end-1',
    xl: 'xl:row-end-1',
    xxl: 'xxl:row-end-1'
  },
  2: {
    base: 'row-end-2',
    xs: 'xs:row-end-2',
    sm: 'sm:row-end-2',
    md: 'md:row-end-2',
    lg: 'lg:row-end-2',
    xl: 'xl:row-end-2',
    xxl: 'xxl:row-end-2'
  },
  3: {
    base: 'row-end-3',
    xs: 'xs:row-end-3',
    sm: 'sm:row-end-3',
    md: 'md:row-end-3',
    lg: 'lg:row-end-3',
    xl: 'xl:row-end-3',
    xxl: 'xxl:row-end-3'
  },
  4: {
    base: 'row-end-4',
    xs: 'xs:row-end-4',
    sm: 'sm:row-end-4',
    md: 'md:row-end-4',
    lg: 'lg:row-end-4',
    xl: 'xl:row-end-4',
    xxl: 'xxl:row-end-4'
  },
  5: {
    base: 'row-end-5',
    xs: 'xs:row-end-5',
    sm: 'sm:row-end-5',
    md: 'md:row-end-5',
    lg: 'lg:row-end-5',
    xl: 'xl:row-end-5',
    xxl: 'xxl:row-end-5'
  },
  6: {
    base: 'row-end-6',
    xs: 'xs:row-end-6',
    sm: 'sm:row-end-6',
    md: 'md:row-end-6',
    lg: 'lg:row-end-6',
    xl: 'xl:row-end-6',
    xxl: 'xxl:row-end-6'
  },
  7: {
    base: 'row-end-7',
    xs: 'xs:row-end-7',
    sm: 'sm:row-end-7',
    md: 'md:row-end-7',
    lg: 'lg:row-end-7',
    xl: 'xl:row-end-7',
    xxl: 'xxl:row-end-7'
  },
  8: {
    base: 'row-end-8',
    xs: 'xs:row-end-8',
    sm: 'sm:row-end-8',
    md: 'md:row-end-8',
    lg: 'lg:row-end-8',
    xl: 'xl:row-end-8',
    xxl: 'xxl:row-end-8'
  },
  9: {
    base: 'row-end-9',
    xs: 'xs:row-end-9',
    sm: 'sm:row-end-9',
    md: 'md:row-end-9',
    lg: 'lg:row-end-9',
    xl: 'xl:row-end-9',
    xxl: 'xxl:row-end-9'
  },
  10: {
    base: 'row-end-10',
    xs: 'xs:row-end-10',
    sm: 'sm:row-end-10',
    md: 'md:row-end-10',
    lg: 'lg:row-end-10',
    xl: 'xl:row-end-10',
    xxl: 'xxl:row-end-10'
  },
  11: {
    base: 'row-end-11',
    xs: 'xs:row-end-11',
    sm: 'sm:row-end-11',
    md: 'md:row-end-11',
    lg: 'lg:row-end-11',
    xl: 'xl:row-end-11',
    xxl: 'xxl:row-end-11'
  },
  12: {
    base: 'row-end-12',
    xs: 'xs:row-end-12',
    sm: 'sm:row-end-12',
    md: 'md:row-end-12',
    lg: 'lg:row-end-12',
    xl: 'xl:row-end-12',
    xxl: 'xxl:row-end-12'
  }
};

export const justifySelfClasses:ResponsiveClasses<typeof JUSTIFY_SELF_VALUES> = {
  auto: {
    base: 'justify-self-auto',
    xs: 'xs:justify-self-auto',
    sm: 'sm:justify-self-auto',
    md: 'md:justify-self-auto',
    lg: 'lg:justify-self-auto',
    xl: 'xl:justify-self-auto',
    xxl: 'xxl:justify-self-auto'
  },
  center: {
    base: 'justify-self-center',
    xs: 'xs:justify-self-center',
    sm: 'sm:justify-self-center',
    md: 'md:justify-self-center',
    lg: 'lg:justify-self-center',
    xl: 'xl:justify-self-center',
    xxl: 'xxl:justify-self-center'
  },
  start: {
    base: 'justify-self-start',
    xs: 'xs:justify-self-start',
    sm: 'sm:justify-self-start',
    md: 'md:justify-self-start',
    lg: 'lg:justify-self-start',
    xl: 'xl:justify-self-start',
    xxl: 'xxl:justify-self-start'
  },
  end: {
    base: 'justify-self-end',
    xs: 'xs:justify-self-end',
    sm: 'sm:justify-self-end',
    md: 'md:justify-self-end',
    lg: 'lg:justify-self-end',
    xl: 'xl:justify-self-end',
    xxl: 'xxl:justify-self-end'
  },
  stretch: {
    base: 'justify-self-stretch',
    xs: 'xs:justify-self-stretch',
    sm: 'sm:justify-self-stretch',
    md: 'md:justify-self-stretch',
    lg: 'lg:justify-self-stretch',
    xl: 'xl:justify-self-stretch',
    xxl: 'xxl:justify-self-stretch'
  }
};
