import { Tab, TabList } from '@jotforminc/tabs';
import { Button } from '@jotforminc/magnet';
import { elementType, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconPlusSm } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import { RightPanelTabLists } from '../../../../constants/rightPanel';
import { RPTabButtonContainer, RPTabButtonWrapper } from '../../../RightPanelContainer';
import NavigationBarProperties from '../NavigationBarProperties';
import AllPagesSettingsPanel from '../AllPagesSettingsPanel';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import SELECTORS from '../../../../../../store/selectors';

const MenuSettingsPanel = ({
  showNavigationBar,
  showAllPagesIcon,
  showMobileMenuIcon,
  mobileMenuIcon,
  DescriptionRenderer
}) => {
  const dispatch = useDispatch();
  const _pages = useSelector(SELECTORS.getPagesWithNames);
  const pages = _pages.filter(page => !page.linkedItemID);
  const tabButton = useMemo(() => RPTabButtonWrapper({ onClick: f => f, itemTabs: RightPanelTabLists().AppName }), []);

  return (
    <TabList
      list={RightPanelTabLists().MenuSettings}
      TabButtonContainer={RPTabButtonContainer}
      TabButton={tabButton}
    >
      <Tab id="General" key="General">
        <NavigationBarProperties
          showNavigationBar={showNavigationBar}
          DescriptionRenderer={DescriptionRenderer}
          mobileMenuIcon={mobileMenuIcon}
          showMobileMenuIcon={showMobileMenuIcon}
        />
      </Tab>
      <Tab id="AllPages" key="AllPages">
        <AllPagesSettingsPanel
          showAllPagesIcon={showAllPagesIcon}
        />
        <div className={`absolute w-full bg-gray-600 ${pages.length < 10 && 'xs:bottom-0'} bottom-14 left-0 px-4 pt-2 xs:pb-3 pb-18`}>
          <Button
            onClick={() => dispatch(ACTION_CREATORS.addNewPageAction({ newPageOrder: pages.length + 1, dontSelect: true }))}
            startIcon={IconPlusSm}
            fullWidth
          >
            {t('Add Page')}
          </Button>
        </div>
      </Tab>
    </TabList>
  );
};

MenuSettingsPanel.propTypes = {
  showNavigationBar: string,
  showAllPagesIcon: string,
  showMobileMenuIcon: string,
  mobileMenuIcon: string,
  DescriptionRenderer: elementType
};

MenuSettingsPanel.defaultProps = {
  showNavigationBar: '',
  showAllPagesIcon: '',
  showMobileMenuIcon: '',
  mobileMenuIcon: '',
  DescriptionRenderer: PanelItemDescriptionRenderer
};

export default MenuSettingsPanel;
