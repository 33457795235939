import React, { memo, useRef } from 'react';
import { t } from '@jotforminc/translation';
import { Hooks, Popover } from '@jotforminc/uikit';
import { IconPlus } from '@jotforminc/svg-icons';
import { func, string } from 'prop-types';
import { isYes, checkMobilePhone } from '../../../../../../utils';
import Button from '../../../../../../components/Button';
import PageIcon from '../../PageIcon';
import { ScPopoverIconSelector } from './ScPopoverIconSelector';
import IconSelector from '../../../../../../components/IconSelector';

const PageIconButton = ({
  showPageIcon, pageIcon, handlePageUpdate
}) => {
  const iconSelectorPopoverRef = useRef();
  const chooseIconSelectorPopoverRef = useRef();
  const isMobilePhone = checkMobilePhone();
  const hasIcon = isYes(showPageIcon) && pageIcon;
  const [isIconSelectorVisible, setIsIconSelectorVisible] = Hooks.useClickOutsideState(false, [iconSelectorPopoverRef, chooseIconSelectorPopoverRef]);
  return (
    <div className='pageSettings-icon'>
      <Button
        ref={chooseIconSelectorPopoverRef}
        onClick={() => setIsIconSelectorVisible(isVisible => !isVisible)}
        className={t(`taste pageSettingsIconBtn ${hasIcon ? 'change' : 'add'}`)}
        {...(!isMobilePhone && { name: t(`${hasIcon ? 'Change' : 'Add'} Icon`) })}
      >
        {hasIcon ? (
          <PageIcon
            showPageIcon={showPageIcon}
            pageIcon={pageIcon}
          />
        ) : (
          <IconPlus />
        )}
      </Button>
      {isIconSelectorVisible && (
        <ScPopoverIconSelector>
          <Popover
            ref={iconSelectorPopoverRef}
            targetRef={chooseIconSelectorPopoverRef}
            style={{ zIndex: '2' }}
            popoverOptions={{
              placement: 'bottom-start'
            }}
          >
            <IconSelector
              value={pageIcon}
              onChange={({ url }) => handlePageUpdate({ showPageIcon: 'Yes', pageIcon: url })}
              onRemove={() => handlePageUpdate({ showPageIcon: 'No', pageIcon: '' })}
              uniqKey="pageItem"
            />
            <div data-popper-arrow />
          </Popover>
        </ScPopoverIconSelector>
      )}
    </div>
  );
};

PageIconButton.defaultProps = {
  showPageIcon: '',
  pageIcon: '',
  handlePageUpdate: () => { }
};

PageIconButton.propTypes = {
  showPageIcon: string,
  pageIcon: string,
  handlePageUpdate: func
};

export default memo(PageIconButton);
