import React from 'react';
import {
  IconImagesFilled, IconImageSliderFilled, IconVideoFilled, IconMapLocationPinFilled, IconShareNodesFilled, IconMessageStarFilled, IconSpacerVerticalFilled
} from '@jotforminc/svg-icons';

export const overriddenFeaturedWidgetPropertiesMap = {
  '529cd0ea8afa8f742d000004': { icon: () => <IconImageSliderFilled width="24px" color="#fff" /> }, // Image Slider
  '0fff4c94790070b09933edd8': { icon: () => <IconMapLocationPinFilled width="24px" color="#fff" />, name: 'Map' }, // Open Street Map
  '529260f705a7114f70000008': { icon: () => <IconShareNodesFilled width="24px" color="#fff" /> }, // Social Follow
  '592d0b6764f0c37320000472': { icon: () => <IconMessageStarFilled width="24px" color="#fff" />, name: 'Testimonial' }, // Testimonials
  '5273bb4faa80af0979000005': { icon: () => <IconVideoFilled width="24px" color="#fff" />, name: 'Video' }, // Youtube
  '528ea7c6a4de152e2a00000b': { icon: () => <IconSpacerVerticalFilled width="24px" color="#fff" />, name: 'Spacer' }, // Spacer
  '12e02d58a07168bd32df509c': { icon: () => <IconImagesFilled width="24px" color="#fff" />, name: 'Image Gallery' } // Image Gallery
};

export const FeaturedWidgetIDs = [
  '12e02d58a07168bd32df509c', // Image Gallery
  '529cd0ea8afa8f742d000004', // Image Slider
  '5273bb4faa80af0979000005', // YouTube
  '0fff4c94790070b09933edd8', // Open Street Map
  '529260f705a7114f70000008', // Social Follow
  '592d0b6764f0c37320000472' // Testimonials
];

export const PageElementWidgetIDs = [
  '528ea7c6a4de152e2a00000b' // Spacer
];

export const OtherWidgetIDs = [
  '5297606bfe8fa6bf0d000016', // Data Grid
  '529641beb15ce2ac76000007', // PDF Embedder
  '5293065005a7114f7000002a', // Fit Text
  '5295629cba137d764f000004', // Iframe Embed
  '53ba6d90e4c0226c7a000007', // Facebook Like Box
  '5272130bf9879ec031000017', // Instagram
  '5374688fa2c8bddc16000019', // Show Map Location
  '52900b6557a7644251000004', // QR Code
  '529d9a5c4b5cd3fd5a000005', // Animated Heading
  'e5cea8c176512adc41b76621', // Whatsapp
  '5272055ff9879ec031000008', // SoundCloud
  '528ef93577d598b430000010', // Countdown
  '5298b86b81b4577459000026', // Day Countdown
  '526a2cd08a881e882100000c', // Vimeo
  '529d9f15f3d08cfe5d000004', // Cool Caption
  '5295bb771a667c525e000007', // Kinomap
  '5295bdbba3ba8bd35e000004', // Photozou
  '54c10483b90217b7300000b7', // Basic Separator
  '529333ae5b04b0c007000004', // Fancy Timer
  '52722f603e8f4eec31000031', // Twitter
  '52945520dacb3d5320000015', // Ticker
  '5293852fb05330801c000004', // Big Header (City)
  '527210b1f9879ec03100000e', // Spotify
  '56a707d26f2fcf1a7b000004', // Comparison Slider
  '587cd59512ec8eda4b000004', // Cincopa DeepUploader
  '537ddad0f080c17a6d000086', // AutoScrolling Text
  '52722eb93e8f4eec3100002e', // PasteBin
  '5295afd37da55cb758000008', // 23 Video
  '5295ba2b4a87e6175e000004', // Giphy
  '5293030905a7114f70000027', // Digital Clock
  '5295bc6528947a7d5e000004', // LogoTV
  '526a318cdeab09892100001c', // Animoto
  '52971c2431357d3907000011', // Draw.io Embedder
  '528ed87181616a2f2a00001e', // Skype Call Button
  '527261883e8f4eec31000035', // Sketchfab
  '5294a0b8bf38bd4034000007', // Chuck Norris Jokes
  '52945fef3477f3512000001a', // Big Header (Chubby)
  '529cb40bcffa1df571000004', // Quotes
  '529cf1de381e0e8840000004', // Arc Text
  '5298b2a88868157559000021', // Math Graphs
  '526a2d72deab09892100000d', // Dotsub
  '526a2dbf8a881e882100000f', // Funny or Die
  '5295c144a9770db75f000004', // WordPress TV
  '5295bf984d4ed6385f000004', // TED
  '52a6ccdafbe985206800000f', // World Time
  '529ca19069fc7db354000004', // Livestream
  '527211b7f9879ec031000011', // Flickr
  '527213673e8f4eec3100000d', // Pinterest
  '52721af9f9879ec031000023', // Circuitlab
  '52950bf5bfdbf21b4d000005', // Spoiler
  '52722e513e8f4eec3100002b', // Scribd
  '5283818625d5b96b67000004', // Viddler
  '527ca0b14966e5c956000004', // MixCloud
  '7089d6db5602ca02def704df', // Ziggeo Video Player
  '526a31538a881e8821000015', // DailyMotion
  '52946064dacb3d5320000023', // Big Header (Comics)
  '529464a3dacb3d5320000028', // Rss Reader
  '52721c2f3e8f4eec31000022', // deviantART
  '526a33768a881e882100001e', // Youku
  '52945f44dacb3d5320000020', // Big Header (Sports)
  '5295bfc6edbacd465f000004' // Trailer Addict
];

export const AllWidgetIDs = [
  ...FeaturedWidgetIDs,
  ...PageElementWidgetIDs,
  ...OtherWidgetIDs
];
