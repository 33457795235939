import {
  IconCheckSquareFilled,
  IconInputTextLong,
  IconInputTextShort,
  IconLocationPinFilled,
  IconPhoneFilled,
  IconSingleSelectionFilled
} from '@jotforminc/svg-icons';

export const fieldElements = [
  {
    title: 'Full Name',
    type: 'control_fullname',
    addable: false
  },
  {
    title: 'Email',
    type: 'control_email',
    addable: false
  },
  {
    title: 'Phone',
    type: 'control_phone',
    Icon: IconPhoneFilled,
    addable: true
  },
  {
    title: 'Address',
    type: 'control_address',
    Icon: IconLocationPinFilled,
    addable: true
  },
  {
    title: 'Short Text',
    type: 'control_textbox',
    Icon: IconInputTextShort,
    addable: true
  },
  {
    title: 'Long Text',
    type: 'control_textarea',
    Icon: IconInputTextLong,
    addable: true
  },
  {
    title: 'Single Choice',
    type: 'control_radio',
    Icon: IconSingleSelectionFilled,
    addable: true
  },
  {
    title: 'Multiple Choice',
    type: 'control_checkbox',
    Icon: IconCheckSquareFilled,
    addable: true
  }
];
