import React from 'react';
import { bool, shape } from 'prop-types';
import Styled from 'styled-components';

const ScOptionRenderer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;
const OptionRenderer = ({ option: { text, value, Icon }, isSelected }) => {
  return (
    <ScOptionRenderer data-value={value} data-selected={isSelected}>
      <Icon />
      <span style={{
        marginLeft: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '18px'
      }}
      >
        {text}
      </span>
    </ScOptionRenderer>
  );
};

OptionRenderer.propTypes = {
  option: shape({}).isRequired,
  isSelected: bool.isRequired
};

export default OptionRenderer;
