import React from 'react';
import ApmPickerFooter from './ApmPickerFooter';
import {
  TApm,
  TGatewaySettingsView,
  TFormId,
  TCurrencyCode,
  TGateway,
  IGatewaySettings
} from '../../types/common';
import GatewaySettingsFooter from './GatewaySettingsFooter';
import ConnectionModalFooter from './ConnectionModalFooter';

const ModalFooterRenderer = ({
  view,
  checkoutFormId,
  activeAPM,
  currentCurrency,
  handleSwitchPaymentGateway,
  isGatewayConnected,
  activeGatewaySettings,
  temporaryActiveGatewaySettings
}: IProps): JSX.Element => {
  const FooterRenderer = (): JSX.Element => {
    switch (view) {
      case 'gateway-settings':
        return (
          <GatewaySettingsFooter
            checkoutFormId={checkoutFormId}
            isGatewayConnected={isGatewayConnected}
            activeGatewaySettings={activeGatewaySettings}
            temporaryActiveGatewaySettings={temporaryActiveGatewaySettings}
          />
        );
      case 'connection-modal':
        return (
          <ConnectionModalFooter
            checkoutFormId={checkoutFormId}
            activeGatewaySettings={activeGatewaySettings}
            temporaryActiveGatewaySettings={temporaryActiveGatewaySettings}
          />
        );
      case 'apm-picker':
        return (
          <ApmPickerFooter
            checkoutFormId={checkoutFormId}
            activeAPM={activeAPM}
            currentCurrency={currentCurrency}
            handleSwitchPaymentGateway={handleSwitchPaymentGateway}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <FooterRenderer />
  );
};

interface IProps {
  view: TGatewaySettingsView
  activeAPM: TApm
  checkoutFormId: TFormId
  currentCurrency: TCurrencyCode
  handleSwitchPaymentGateway: (id: TFormId, data: { nameAPM: TApm, type: TGateway, currency: TCurrencyCode }) => void
  isGatewayConnected: boolean
  activeGatewaySettings: IGatewaySettings
  temporaryActiveGatewaySettings: Partial<IGatewaySettings>
}

export default ModalFooterRenderer;
