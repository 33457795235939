import React from 'react';
import {
  IconImageFilled, IconCircleUserFilled, IconIconsFilled, IconLabelButtonFilled
} from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';

export const OPTION_VALUES = {
  IMAGE: 'image',
  AVATAR: 'avatar',
  ICON: 'icon',
  CUSTOM_TEXT: 'text',
  BUTTON: 'button'
};

export const SIDE_SELECTION_IMAGE_TYPES = {
  AVATAR: 'multisection-settings-avatar',
  IMAGE: 'multisection-settings-image'
};

export const PROP_TYPES = {
  START: 'start',
  END: 'end'
};

// Constants for side selections
// It can also take function if default is conditional
export const SIDE_SELECTION_DEFAULTS = {
  [OPTION_VALUES.IMAGE]: null,
  [OPTION_VALUES.AVATAR]: null,
  [OPTION_VALUES.ICON]: {
    svgRef: 'jfc_icon_solid-info',
    url: '/cardforms/assets/icons/icon-sets-v2/solid/Date/jfc_icon_solid-info.svg',
    bgColor: '#FFF0'
  },
  [OPTION_VALUES.BUTTON]: { text: t('Button') }
};

export const SIDE_SELECTION_OPTIONS = {
  [OPTION_VALUES.AVATAR]: {
    icon: () => (
      <IconCircleUserFilled height={20} width={20} />
    ),
    label: t('Avatar'),
    value: OPTION_VALUES.AVATAR,
    hasSubfield: true
  },
  [OPTION_VALUES.IMAGE]: {
    icon: () => (
      <IconImageFilled height={20} width={20} />
    ),
    label: t('Image'),
    value: OPTION_VALUES.IMAGE,
    hasSubfield: true
  },
  [OPTION_VALUES.BUTTON]: {
    icon: () => (
      <IconLabelButtonFilled height={20} width={20} />
    ),
    label: t('Button'),
    value: OPTION_VALUES.BUTTON,
    hasSubfield: true,
    subfieldLabel: 'Button Text'
  },
  [OPTION_VALUES.ICON]: {
    icon: () => (
      <IconIconsFilled height={20} width={20} />
    ),
    label: t('Icon'),
    value: OPTION_VALUES.ICON,
    hasSubField: true
  }
};

export const defaultDropdownSections = [
  {
    description: '',
    options: [SIDE_SELECTION_OPTIONS[OPTION_VALUES.IMAGE], SIDE_SELECTION_OPTIONS[OPTION_VALUES.AVATAR], SIDE_SELECTION_OPTIONS[OPTION_VALUES.ICON]]
  }
];
