interface I_CLIENT_LIST {
  [key: string]: {
    mollie: string
    square: string
  }
}

export const CLIENT_ID_LIST_DEV: I_CLIENT_LIST = {
  'sumer.jotform.pro': {
    mollie: 'app_Fby7GwTCeJvN49QMg3qEh4TK',
    square: 'sandbox-sq0idb-47x4WuRX5zHJX-EV6wyFbw'
  },
  'sevkialacatli.jotform.pro': {
    mollie: 'app_GAcnUevgth7FfJSkcrMFQ8aB',
    square: 'sandbox-sq0idb-f5TSdPznSvKli63YO7cMPA'
  },
  'ozlem.jotform.pro': {
    mollie: 'app_qBbkbJdVtga5w4nb5sxVDWJz',
    square: 'sandbox-sq0idb-kv0F6n36dqZvMpStLfaSCw'
  },
  'meliskaya.jotform.pro': {
    mollie: 'app_GzcX42mYjYjjWcqhXfSXQNA7',
    square: 'sandbox-sq0idb-1Mas_EAM2kYWeFonqK-Ghw'
  },
  'sinanusluer.jotform.pro': {
    mollie: 'app_73qU2RnTbZJSbfbhtbyytuRT',
    square: 'sandbox-sq0idb-oo_UjnF2VTaUYINm-60y4A'
  },
  'mirac.jotform.pro': {
    mollie: 'app_54uM946bVj6jqnQazyzDHqGQ',
    square: 'sandbox-sq0idb--UsIKMaL-ZnFl12zCDnovg'
  },
  'gokhankovanlilar.jotform.pro': {
    mollie: 'app_SYsixx3EphNtbzuFQewGNw8b',
    square: 'sandbox-sq0idb-sIfz4IL0_V8HLdGMWJFcgw'
  }
};
