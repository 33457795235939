import React, { memo } from 'react';
import { func, string } from 'prop-types';
import {
  Location, Router, LocationProvider, globalHistory
} from '@reach/router';
import { renderable } from './constants/propTypes';

// Reach router's ref blocker to prevent focus:
// https://github.com/reach/router/blob/d2c9ad06715c9d48c2d16f55f7cd889b626d2521/src/index.js#L342
const Comp = props => <div {...props} />;

LocationProvider.prototype.componentDidMount = function componentDidMount() {
  const { state: { refs } } = this;
  refs.unlisten = globalHistory.listen(() => {
    this.setState(() => ({ context: this.getContext() }));
  });
};

const CoreAppRouter = ({ basepath, children, onLocationChange }) => {
  return (
    <Location>
      {({ location }) => {
        onLocationChange(location.pathname);
        return (
          <Router
            component={Comp}
            basepath={basepath}
            location={location}
          >
            {children}
          </Router>
        );
      }}
    </Location>
  );
};

CoreAppRouter.propTypes = {
  basepath: string,
  children: renderable.isRequired,
  onLocationChange: func
};

CoreAppRouter.defaultProps = {
  basepath: '',
  onLocationChange: f => f
};

export default memo(CoreAppRouter);
