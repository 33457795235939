/* eslint-disable max-len */
import Styled from 'styled-components';
import { mainFontColor } from '../../styles/colors';

export const ScDropdown = Styled.div`
  position: relative;

  .dropdown-button {
    display: block;
    padding: 14px;
    border: 1px solid #C8CEED;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    color: #0A1551;
    background-color: #fff;
    text-align: left;
    position: relative;
    cursor: pointer;
    outline: none;
    font-family: inherit;

    &:after {
      content: "";
      border-left: 1px solid #C8CEED;
      position: absolute;
      top: 0;
      width: 45px;
      height: 100%;
      right: 0;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.397 6.948L11.1.804A.5.5 0 0010.7 0H1.3a.5.5 0 00-.4.804l4.702 6.144a.5.5 0 00.794 0z' fill='%236F76A7' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

    + div[data-popper-placement] {
      width: 100%;
    }
  }

  [data-popper-placement] {
    z-index: 3;
  }

  .dropdown-container {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #d8dae9;
    border-top: 0 solid #d8dae9;

    & > div > b {
      display: list-item;
      padding: 14px;
    }
  }

  .dropdown-option {
    &-container {
      margin: 0;
      color: ${mainFontColor};
      border-width: 0;
      position: relative;
      font-size: 16px;
      padding: 14px;
      text-align: left;
      cursor: pointer;
      transition: .3s;

      &:after {
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #A4DAFF;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        opacity: 0;
        transition: .3s;
      }

      &:hover {
        background-color: #EDF8FF;
        &:after {
          opacity: 1;
        }
      }

      &[aria-selected="true"] {
        background-color: #DBF1FF;
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23FFF'/%3E%3Cpath d='m5.625 10 2.8125 2.25 4.5-5.625' stroke='%2309F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-color: #DBF1FF;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1;
        }
      }
    }
  }

  .appStatus-disabledOnADay-timezone & {
    .dropdown-button {
      padding: 14px 46px 14px 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px;

      @media screen and (min-width: 481px) and (max-width: 768px) {
        width: 225px;
      }
    }

    input[type="search"] {
      width: 287px;
      padding: 10px 10px 10px 36px;
      border: 1px solid #D8DAE9;
      border-radius: 0 4px 0 0;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.47432
      1.49974c1.64444 0 2.9826 1.34575 2.9826 2.99989 0 .6231-.19111 1.22257-.55307 1.73387l-.29362.4147-.41216.29488c-.50835.36407-1.10445.55645-1.72375.55645-1.64486
      0-2.9826-1.34576-2.9826-2.9999 0-1.65414 1.33774-2.99989 2.9826-2.99989zm0-1.49974C2.00302 0 0 2.01484 0 4.50005c0 2.48522 2.00302 4.50006 4.47432 4.50006.96565
      0 1.85748-.31092 2.58815-.83403l3.59303 3.61372c.146.1468.3371.2202.5286.2202.1915 0 .3822-.0734.5282-.2202.2919-.2932.2919-.7691 0-1.0623L8.11925
      7.10382c.52016-.73532.82897-1.63221.82897-2.60335 0-2.48521-2.00303-4.50004811-4.4739-4.50004811V0z' fill='%238D8FA6'/%3E%3C/svg%3E");      background-repeat: no-repeat;
      background-position: 15px center;

      .dropdown-button & {
        border-bottom: none;
        padding-bottom: 10px;
      }
    }

    .dropdown-container {
      list-style: none;
      margin: 0;
      height: calc(250px + (1.7rem / 2));
      overflow-y: scroll;
      padding-right: 0px;
      padding-left: 0px;
      width: 285px;
      box-sizing: content-box;
      border-radius: 0 0 4px 4px;
    }
  }
`;
/* eslint-enable max-len */
