import React from 'react';
import { object, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';

import { placeholderAgentImage } from '../../constantImages';
import { onImageError } from '../../onImageError';
import PreviewHint from './PreviewHint';

const AgentTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate,
  setImageLoaded, templateCloneWrapper
}) => {
  const {
    title,
    thumbnailBackgroundColor,
    screenshot,
    _previewImageUrl
  } = template;
  const isSkeleton = title === 'skeleton';
  return (
    <div className={`item agent-item${imageLoaded && !isSkeleton ? ' image-loaded' : ''}`}>
      <div className="image" style={{ borderColor: thumbnailBackgroundColor }} onClick={() => handlePreviewTemplate('viewTemplateFromImg')}>
        <PreviewHint />
        <img
          alt={title}
          onError={onImageError}
          className='agent-main'
          onLoad={() => setImageLoaded(true)}
          src={!isSkeleton ? (screenshot || _previewImageUrl) : placeholderAgentImage}
        />
        <img
          className='agent-blank'
          alt={title}
          src={placeholderAgentImage}
        />
      </div>
      <h3 onClick={() => handlePreviewTemplate('viewTemplateFromTitle')}><span>{title}</span></h3>
      <button
        className="useTemplate useAgent cta-usetemplate"
        type="button"
        onClick={templateCloneWrapper}
      >
        <span>{t('Use Agent')}</span>
      </button>
      <button
        className="previewCta"
        type="button"
        onClick={() => handlePreviewTemplate('viewTemplateFromPreviewCta')}
      >
        <span>{t('Preview')}</span>
      </button>
    </div>
  );
};

export default AgentTemplateItem;
AgentTemplateItem.defaultProps = {
};
AgentTemplateItem.propTypes = {
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  templateCloneWrapper: func.isRequired,
  handlePreviewTemplate: func.isRequired
};
