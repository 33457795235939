import { func, string } from 'prop-types';
import React, {
  useCallback, useState
} from 'react';
import TextInput from '../../../../TextInput';

const ButtonMailInput = ({
  value,
  onChange,
  placeholder
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleBlur = useCallback(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextInput
      value={inputValue}
      onChange={onChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
};

ButtonMailInput.propTypes = {
  value: string,
  onChange: func,
  placeholder: string
};

ButtonMailInput.defaultProps = {
  value: null,
  onChange: f => f,
  placeholder: ''
};

export default ButtonMailInput;
