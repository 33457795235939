import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Onebip gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlOnebip: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_onebip',
  name: gatewayFieldToName.control_onebip,
  supportedCurrencies: gatewaySupportedCurrencies.control_onebip,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_onebip,
  sortByCountry: gatewayListSortedByCountry.control_onebip,

  /** APM's */
  isApm: false,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: false,
  allowResourceTypes: ['FORM'],
  isDeprecated: true,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: false,
    mode: {},
    propReferences: {
      username: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Username' } },
      itemNo: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Product Item No' } },
      productName: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Product Name' } },
      productPrice: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Product Price' } }
    },
    uniquePropNames: ['username', 'itemNo', 'productName', 'productPrice']
  }
};
