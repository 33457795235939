import type {
  PAYMENT_FIELDS,
  PAYMENT_QUESTION_PROP_NAMES,
  COMPONENT_PROPERTIES,
  GATEWAY_RESOURCE_PROPS_TYPE,
  INDEPENDENT_RESOURCE_FIELD_NAMES,
  CHILD_GATEWAYS_RESOURCE_PROPS_TYPE
} from '../types';

import control2co from './control2co';
import controlAfterpay from './controlAfterpay';
import controlApplePayGooglePay from './controlApplePayGooglePay';
import controlAuthnet from './controlAuthnet';
import controlBluepay from './controlBluepay';
import controlBluesnap from './controlBluesnap';
import controlBraintree from './controlBraintree';
import controlCardconnect from './controlCardconnect';
import controlCashApp from './controlCashApp';
import controlChargify from './controlChargify';
import controlClearpay from './controlClearpay';
import controlCybersource from './controlCybersource';
import controlEcheck from './controlEcheck';
import controlEway from './controlEway';
import controlFirstdata from './controlFirstdata';
import controlGocardless from './controlGocardless';
import controlIyzico from './controlIyzico';
import controlMollie from './controlMollie';
import controlMoneris from './controlMoneris';
import controlPagseguro from './controlPagseguro';
import controlPayfast from './controlPayfast';
import controlPayjunction from './controlPayjunction';
import controlPaymentwall from './controlPaymentwall';
import controlPaypal from './controlPaypal';
import controlPaypalComplete from './controlPaypalComplete';
import controlPaypalExpress from './controlPaypalExpress';
import controlPaypalInvoicing from './controlPaypalInvoicing';
import controlPaypalPro from './controlPaypalPro';
import controlPaypalSPB from './controlPaypalSPB';
import controlPaysafe from './controlPaysafe';
import controlPayu from './controlPayu';
import controlPayuMoney from './controlPayuMoney';
import controlSensepass from './controlSensepass';
import controlSkrill from './controlSkrill';
import controlSofort from './controlSofort';
import controlSquare from './controlSquare';
import controlStripe from './controlStripe';
import controlStripeACH from './controlstripeACH';
import controlStripeACHManual from './controlStripeACHManual';
import controlStripeCheckout from './controlStripeCheckout';
import controlVenmo from './controlVenmo';
import controlWorldpay from './controlWorldpay';
import controlWorldpayUS from './controlWorldpayus';

const GATEWAY_RESOURCES = {
  ...control2co,
  ...controlAfterpay,
  ...controlAuthnet,
  ...controlBluepay,
  ...controlBluesnap,
  ...controlBraintree,
  ...controlCardconnect,
  ...controlCashApp,
  ...controlChargify,
  ...controlClearpay,
  ...controlCybersource,
  ...controlEcheck,
  ...controlEway,
  ...controlFirstdata,
  ...controlGocardless,
  ...controlIyzico,
  ...controlMollie,
  ...controlMoneris,
  ...controlPagseguro,
  ...controlPayfast,
  ...controlPayjunction,
  ...controlPaymentwall,
  ...controlPaypal,
  ...controlPaypalComplete,
  ...controlPaypalExpress,
  ...controlPaypalInvoicing,
  ...controlPaypalPro,
  ...controlPaypalSPB,
  ...controlPaysafe,
  ...controlPayu,
  ...controlPayuMoney,
  ...controlSensepass,
  ...controlSkrill,
  ...controlSofort,
  ...controlSquare,
  ...controlStripe,
  ...controlStripeACH,
  ...controlStripeACHManual,
  ...controlStripeCheckout,
  ...controlVenmo,
  ...controlWorldpay,
  ...controlWorldpayUS
};

// consider parent gateways separate as they contain >1 gateways
const PARENT_GATEWAY_RESOURCES = {
  ...controlApplePayGooglePay
};

const subpropsToFilter = [
  'selectedCondition',
  'disableCondition',
  'lockedCondition',
  'title',
  'description',
  'steps'
];
const componentTypesToFilter = ['copyUrl'];

// filter resource subprops with function values to reuse them in backend
const getSupportedGatewayResourcePropsList = (): GATEWAY_RESOURCE_PROPS_TYPE => {
  const supportedGatewayResourcePropsFiltered = Object.keys(GATEWAY_RESOURCES).reduce((acc, gatewayType) => {
    const gatewayProps = GATEWAY_RESOURCES[gatewayType as PAYMENT_FIELDS] as Record<PAYMENT_QUESTION_PROP_NAMES | INDEPENDENT_RESOURCE_FIELD_NAMES, COMPONENT_PROPERTIES>;
    const filteredResourceProps = Object.keys(gatewayProps).reduce((acc2, gatewayProp) => {
      const gatewaySubprops = gatewayProps[gatewayProp as PAYMENT_QUESTION_PROP_NAMES | INDEPENDENT_RESOURCE_FIELD_NAMES];
      if (componentTypesToFilter.includes(gatewaySubprops.type)) { return { ...acc2 }; }
      const filteredResourceSubprops = Object.keys(gatewaySubprops).reduce((acc3, gatewaySubprop) => {
        if (subpropsToFilter.includes(gatewaySubprop)) { return { ...acc3 }; }
        return { ...acc3, [gatewaySubprop]: gatewaySubprops[gatewaySubprop as keyof COMPONENT_PROPERTIES] };
      }, {});
      return { ...acc2, [gatewayProp]: filteredResourceSubprops };
    }, {});
    return { ...acc, [gatewayType]: filteredResourceProps };
  }, {});
  return supportedGatewayResourcePropsFiltered;
};

const getSupportedChildGatewayResourcePropsList = (): GATEWAY_RESOURCE_PROPS_TYPE => {
  const supportedGatewayResourcePropsFiltered = Object.keys(PARENT_GATEWAY_RESOURCES).reduce((acc, parentGateway) => {
    const parentGatewayProps = PARENT_GATEWAY_RESOURCES[parentGateway as PAYMENT_FIELDS] as CHILD_GATEWAYS_RESOURCE_PROPS_TYPE;
    const childGatewaysWithProps = Object.keys(parentGatewayProps).reduce((acc2, childGateway) => {
      const gatewayProps = parentGatewayProps[childGateway as PAYMENT_FIELDS] as Record<PAYMENT_QUESTION_PROP_NAMES | INDEPENDENT_RESOURCE_FIELD_NAMES, COMPONENT_PROPERTIES>;
      const filteredResourceProps = Object.keys(gatewayProps).reduce((acc3, gatewayProp) => {
        const gatewaySubprops = gatewayProps[gatewayProp as PAYMENT_QUESTION_PROP_NAMES | INDEPENDENT_RESOURCE_FIELD_NAMES];
        if (componentTypesToFilter.includes(gatewaySubprops.type)) { return { ...acc3 }; }
        const filteredResourceSubprops = Object.keys(gatewaySubprops).reduce((acc4, gatewaySubprop) => {
          if (subpropsToFilter.includes(gatewaySubprop)) { return { ...acc4 }; }
          return { ...acc4, [gatewaySubprop]: gatewaySubprops[gatewaySubprop as keyof COMPONENT_PROPERTIES] };
        }, {});
        return { ...acc3, [gatewayProp]: filteredResourceSubprops };
      }, {});
      return { ...acc2, [childGateway]: filteredResourceProps };
    }, {});
    return { ...acc, [parentGateway]: childGatewaysWithProps };
  }, {});
  return supportedGatewayResourcePropsFiltered;
};

const supportedGatewayResourcePropsList = { ...getSupportedGatewayResourcePropsList(), ...getSupportedChildGatewayResourcePropsList() };
export { supportedGatewayResourcePropsList };

export const getGatewayResourceProperties = (type: PAYMENT_FIELDS): Partial<Record<PAYMENT_QUESTION_PROP_NAMES, COMPONENT_PROPERTIES>> => {
  const props = GATEWAY_RESOURCES[type];
  if (typeof props === 'undefined') {
    return {};
  }
  return props;
};

export const getChildGatewayResourcePropertiesByParent = (parentType: PAYMENT_FIELDS, childType: PAYMENT_FIELDS): Partial<Record<PAYMENT_QUESTION_PROP_NAMES, COMPONENT_PROPERTIES>> => {
  const props = PARENT_GATEWAY_RESOURCES[parentType]?.[childType];
  if (typeof props === 'undefined') {
    return {};
  }

  return props;
};
