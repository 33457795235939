import React from 'react';
import { t } from '@jotforminc/translation';
import { CONNECTION_LIST_DATA } from '../../../../../types/common';

interface I_CONNECTIONINFO {
  modeIndicator: { bgColor: string, text: string};
  connectionInfo: CONNECTION_LIST_DATA;
  disabled: boolean;
}

const ConnectionInfo = ({ modeIndicator: { bgColor, text }, connectionInfo: { title }, disabled }: I_CONNECTIONINFO): JSX.Element => {
  return (
    <div className={`flex flex-row items-center color-white font-normal ${disabled ? 'opacity-40' : ''}`}>
      <span className={`radius px-1 text-xs w-10 h-5 flex items-center justify-center mr-3 shrink-0 ${bgColor}`}>
        {t(text)}
      </span>
      <span className='text-sm line-height-md whitespace-nowrap overflow-hidden truncate'>
        {title}
      </span>
    </div>
  );
};

export default ConnectionInfo;
