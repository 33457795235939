import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Clearpay gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlClearpay: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_clearpay: {
    location: {
      title: 'Business Location',
      type: 'dropdown',
      subType: 'dynamicDropdown',
      default: '',
      resources: ['FORM', 'APP']
    },
    sendReceipt: {
      title: 'Send Payment Receipt',
      type: 'toggle',
      description: 'Send an email with a link to the Square receipt to the customer.',
      selectedCondition: settings => String(settings.sendReceipt) === 'true',
      valueTrue: 'true',
      valueFalse: 'false',
      default: 'false',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    emailField: {
      title: 'Customer Email',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: { prop: 'paymentType', operator: '===', value: 'subscription' },
      autoSave: true
    },
    orderFulfillmentType: {
      title: 'Order Fulfillment Type',
      type: 'dropdown',
      options: [
        { id: 'PICKUP', key: 'PICKUP', text: 'Pickup' },
        { id: 'SHIPMENT', key: 'SHIPMENT', text: 'Shipment' }
      ],
      default: 'Pickup',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    paymentMethodsTitle: {
      title: 'Payment Methods Label Text',
      type: 'input',
      default: 'Payment Methods',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlClearpay;
