import React, { useCallback } from 'react';
import {
  PaymentActions, PaymentProvider, ProductSettingsPanel, PaymentSettingsEditor as PaymentSettingsEditorContainer
} from '@jotforminc/payment-settings-editor';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList } from '@jotforminc/tabs';
import isEmpty from 'lodash/isEmpty';
import SELECTORS from '../../../../../../store/selectors';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';
import { MODALS } from '../../../../../../constants/modals';
import { RightPanelTabLists } from '../../../../constants/rightPanel';
import { RPTabButtonContainer, RPTabButtonWrapper } from '../../../RightPanelContainer';
import ContactInformationSettingsPanel from '../ContactInformationSettingsPanel';

const PaymentProperties = () => {
  const dispatch = useDispatch();
  const appID = useSelector(SELECTORS.getAppID);
  const sampleProductListItem = useSelector(SELECTORS.getSampleProductListElement);
  const isPaymentOverQuota = useSelector(SELECTORS.getIsPaymentOverQuota);
  const productListLayout = useSelector(SELECTORS.getProductListLayout);
  const { useDecimal, decimalMark } = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);
  const isReusableConnectionEnabled = useSelector(SELECTORS.getIsReusableConnectionEnabled);
  const showSearch = useSelector(SELECTORS.getShowSearch);
  const currencyInfo = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);
  const checkoutFormQuestions = useSelector(SELECTORS.getCheckoutFormQuestions);
  const checkoutFormActiveGatewaySettings = useSelector(SELECTORS.getCheckoutFormActiveGatewaySettings);
  const checkoutFormPaymentQuestion = useSelector(SELECTORS.getCheckoutFormPaymentQuestion);
  const appInfo = useSelector(SELECTORS.getPortalInfoSelector);

  const [selectedItemID] = useSelector(SELECTORS.getSelectedPortalItems);
  const selectedProductListItem = useSelector(SELECTORS.getItemWithDefaults(selectedItemID));

  const productListItem = isEmpty(selectedProductListItem) ? sampleProductListItem : selectedProductListItem;

  const { formID, products } = productListItem;

  const {
    useFastCheckout
  } = useSelector(SELECTORS.getPortalInfoSelector);

  const checkoutFormID = useSelector(SELECTORS.getCheckoutFormIDSelector);
  const user = useSelector(SELECTORS.getUser);

  const handlePaymentSettingsChange = useCallback((type, { productListSettingsItem }) => {
    dispatch(ACTION_CREATORS.updatePortalPropAction(productListSettingsItem));
  }, []);

  const handleGatewaySettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateGatewaySettings(settings)), []);

  const handleCheckoutFormQuestionUpdate = useCallback((key, value) => dispatch(ACTION_CREATORS.updateCheckoutFormQuestion(key, value)), []);

  const onRightPanelModeChange = () => {
    dispatch(ACTION_CREATORS.setActiveProduct('', ''));
  };

  const handleUseFastCheckoutChange = value => dispatch(ACTION_CREATORS.updatePortalAction({ useFastCheckout: value }));

  const handleAddPaymentIntegration = useCallback(() => {
    dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gateway'));
    dispatch(ACTION_CREATORS.setPaymentModalFlowType('ELEMENT_TO_GATEWAY'));
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  const handleEditPaymentIntegration = useCallback(() => {
    dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gatewaySettings'));
    dispatch(ACTION_CREATORS.setPaymentModalFlowType('EDIT_GATEWAY'));
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  const handleDeletePaymentIntegration = useCallback(() => {
    dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gateway'));
    dispatch(ACTION_CREATORS.setPaymentModalFlowType('ELEMENT_TO_GATEWAY'));
    const data = {
      type: 'control_payment',
      currency: currencyInfo.currency
    };

    handleGatewaySettingsChange(data);
  }, []);

  const handleCheckoutFormSettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateCheckoutFormSettings(settings)), []);
  const togglePaymentConnectionModal = useCallback(() => {
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  const paymentType = checkoutFormActiveGatewaySettings.paymentType || checkoutFormPaymentQuestion.paymentType;

  return (
    <div className='rp-payment-properties'>
      <TabList
        list={RightPanelTabLists().StorePaymentProperties}
        TabButtonContainer={RPTabButtonContainer}
        TabButton={RPTabButtonWrapper({ onClick: f => f, itemTabs: RightPanelTabLists().StorePaymentProperties })}
      >
        <Tab id="paymentMethod">
          <PaymentProvider>
            <PaymentSettingsEditorContainer
              appName="appBuilder"
              products={products}
              productListLayout={productListLayout}
              showSearch={showSearch}
              questionProperties={currencyInfo}
              appID={appID}
              initialPanel="paymentSettings"
              formId={formID}
              checkoutFormId={checkoutFormID}
              actions={PaymentActions}
              onPaymentSettingsChange={handlePaymentSettingsChange}
              onGatewaySettingsChange={handleGatewaySettingsChange}
              onRightPanelModeChange={onRightPanelModeChange}
              useFastCheckout={useFastCheckout}
              onUseFastCheckoutChange={handleUseFastCheckoutChange}
              isPaymentOverQuota={isPaymentOverQuota}
              questions={checkoutFormQuestions}
              activeGatewaySettings={checkoutFormActiveGatewaySettings}
              user={user}
              onCheckoutFormQuestionUpdate={handleCheckoutFormQuestionUpdate}
              onAddPaymentIntegrationBtnClick={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal) && handleAddPaymentIntegration}
              onEditPaymentIntegrationBtnClick={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal) && handleEditPaymentIntegration}
              onDeletePaymentIntegrationBtnClick={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal) && handleDeletePaymentIntegration}
              isNewPaymentModalEnabled={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal)}
              checkoutFormPaymentQuestion={{ ...checkoutFormPaymentQuestion, ...currencyInfo, paymentType }}
              togglePaymentConnectionModal={togglePaymentConnectionModal}
              onSaveGateway={({ changedProps }) => {
                handleCheckoutFormSettingsChange({ ...changedProps });
              }}
              userIsResourceOwner={appInfo.username === user.username}
            />
          </PaymentProvider>
        </Tab>
        <Tab id="productSettings">
          <ProductSettingsPanel
            appID={appID}
            productListSettings={{
              showSearch,
              productListLayout,
              useDecimal,
              decimalMark
            }}
            onProductListSettingsChange={handlePaymentSettingsChange}
            isReusableConnectionEnabled={isReusableConnectionEnabled}
          />
        </Tab>
        <Tab id="checkoutForm">
          <ContactInformationSettingsPanel />
        </Tab>
      </TabList>
    </div>
  );
};

PaymentProperties.propTypes = {};

PaymentProperties.defaultProps = {};

export default PaymentProperties;
