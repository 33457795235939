import React from 'react';
import classNames from 'classnames';
import { Button } from '@jotforminc/uikit';

const Tab = ({ id, text, className }: ITab) : JSX.Element => {
  return <Button id={id} className={classNames('tabItem', className)}>{text}</Button>;
};

interface ITab {
  id: string,
  text: string,
  className?: string
}

Tab.defaultProps = {
  className: ''
};

export default Tab;
