import { put, select, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { TOGGLE_NETWORK_STATUS } from '../actionTypes';
import SELECTORS from '../selectors';
import { APP_MODES } from '../../constants';
import { toastAction } from '../actionCreators';

function* toastIt({ payload: status }) {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode !== APP_MODES.builder) return;
  const message = status ? 'You\'re back online!' : 'Internet connection is lost, you\'ll be able to continue when you restore the connection.';
  if (status) {
    toast.dismiss();
  }
  yield put(toastAction({
    message,
    type: status ? 'success' : 'warning',
    backdrop: !status
  }));
}

export function* watchNetworkStatus() {
  while (true) {
    const action = yield take(TOGGLE_NETWORK_STATUS);
    yield toastIt(action);
  }
}
