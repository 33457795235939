import React, { forwardRef, useState } from 'react';
import {
  string, func, node, bool, elementType, oneOfType
} from 'prop-types';
import { t } from '@jotforminc/translation';

import { Button } from '@jotforminc/magnet';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { StyledModal as Modal } from '@jotforminc/styled-modal';

import { ScRF, ScRB, ScContainer } from './scRevokeAccess';

const ConfirmationModal = forwardRef(({
  icon,
  title,
  subtitle,
  description,
  backText,
  children,
  onBack,
  showDontShowAgain,
  dontShowAgainText,
  confirmText,
  onConfirm,
  ButtonRenderer,
  confirmButtonColor,
  BackButtonRenderer: PropBackButtonRenderer,
  ConfirmButtonRenderer: PropConfirmButtonRenderer,
  confirmationDisable,
  backDisable,
  errorMessage,
  ...modalProps
}, ref) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const BackButtonRenderer = PropBackButtonRenderer || ButtonRenderer;
  const ConfirmButtonRenderer = PropConfirmButtonRenderer || ButtonRenderer;
  return (
    <Modal
      {...modalProps}
      ref={ref}
      footer={(
        <ScRF>
          <BackButtonRenderer
            colorStyle="secondary"
            variant="outline"
            disabled={backDisable}
            className="text-uppercase"
            onClick={onBack}
            aria-label={backText}
          >
            {backText}
          </BackButtonRenderer>
          <ScContainer>
            {errorMessage ? <div className='error'>{errorMessage}</div> : null}
            <ConfirmButtonRenderer
              colorStyle={confirmButtonColor}
              disabled={confirmationDisable}
              className="text-uppercase"
              onClick={() => onConfirm({ dontShowAgain })}
              aria-label={confirmText}
            >
              {confirmText}
            </ConfirmButtonRenderer>
          </ScContainer>
        </ScRF>
      )}
    >
      <ScRB data-sc="revoke-body">
        <div className="content">
          {icon}
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
          {children && (
            <div className="custom-content">
              {children}
            </div>
          )}
          {description && <p>{description}</p>}
          {showDontShowAgain ? (
            <label className="dontshow" htmlFor="dontShowAgain">
              <input
                onChange={e => setDontShowAgain(e.target.checked)}
                checked={dontShowAgain}
                type="checkbox"
                name="dontShowAgain"
                id="dontShowAgain"
                className="dontshow-input"
              />
              <span className="dontshow-checkbox" />
              <span className="dontshow-text">
                {dontShowAgainText}
              </span>
            </label>
          ) : null}
        </div>
      </ScRB>
    </Modal>
  );
});

ConfirmationModal.propTypes = {
  icon: node,
  title: string,
  subtitle: oneOfType([string, elementType]),
  description: oneOfType([string, elementType]),
  backText: string,
  confirmText: string,
  confirmButtonColor: string,
  showDontShowAgain: bool,
  dontShowAgainText: string,
  children: node,
  ButtonRenderer: elementType,
  BackButtonRenderer: elementType,
  ConfirmButtonRenderer: elementType,
  onBack: func,
  onConfirm: func,
  confirmationDisable: bool,
  backDisable: bool,
  errorMessage: string
};

ConfirmationModal.defaultProps = {
  title: 'Title',
  subtitle: 'Subtitle',
  description: 'Description',
  icon: <IconExclamationTriangleFilled width="76" height="76" />,
  backText: t('Back'),
  confirmText: t('Confirm'),
  confirmButtonColor: 'error',
  showDontShowAgain: false,
  dontShowAgainText: t('Don\'t show this message again'),
  children: null,
  ButtonRenderer: Button,
  BackButtonRenderer: undefined,
  ConfirmButtonRenderer: undefined,
  onBack: f => f,
  onConfirm: f => f,
  confirmationDisable: false,
  backDisable: false,
  errorMessage: ''
};

export default ConfirmationModal;
