/* eslint-disable max-lines */
import { t } from '@jotforminc/translation';
import { TEXT_ALIGNMENT_RENDERER_ARRAY } from '../../../constants/textAlignment';
import { DESCRIPTION_LIMIT, APP_GRAPHIC_TYPES } from '../../../../../constants';
import LogoIconColored from '../../../../../assets/icon_logo_colored.svg';
import GeneralIconColored from '../../../../../assets/icon_general_colored.svg';
import StyleIconColored from '../../../../../assets/icon_style_colored.svg';
import { FEATURE_NAMES } from '../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../utils/features/helper';
import { RightPanelModes } from '../constants';
import { ITEM_TYPES } from '../../../../../constants/itemTypes';
import { BUTTON_STYLING_LIST } from '../../../../../properties/styling';
import { ELEMENT_SIZE_RENDERER_ARRAY } from '../../../constants/elementSize';
import { DONATION_ITEM_CONSTANTS } from './donationItemConstants';
import {
  CARD_ACTIONS, CARD_ACTION_PROPS, CARD_ACTION_VALUES, CARD_LAYOUT_OPTIONS,
  IMAGE_ACTIONS,
  IMAGE_ACTION_PROPS,
  IMAGE_ACTION_VALUES
} from './constants';
import { ROLE_COMPONENT_PROPS } from './ButtonActions/RoleComponents/roleComponentProps';
import { OPTION_VALUES, SIDE_SELECTION_OPTIONS } from './ListItemSideItemSelection/constants';
import { ListViewButton, handleListNavigation } from './ResourceSelection/helper';

const getRightPanelConstants = mode => {
  const elementSizeProperty = {
    type: 'selection',
    title: t('Element Size'),
    options: ELEMENT_SIZE_RENDERER_ARRAY
  };
  // common props for Table, Report and Sentbox
  const resourceSelectionProp = {
    type: 'resourceSelection',
    title: t('Select a resource'),
    allowMultiplePropChange: true,
    additionalProps: ['resourceType', 'resourceTitle', 'title', 'type', 'id'],
    disableElementLabel: true
  };

  const ResourceLinkProperties = {
    tabs: {
      General: {
        label: t('General')
      },
      Style: {
        label: t('Style')
      }
    },
    properties: {
      General: {
        resourceID: resourceSelectionProp,
        title: {
          type: 'textinput',
          title: t('Title'),
          placeholder: t('Enter a title'),
          ariaLabel: t('Title')
        },
        description: {
          type: 'textarea',
          title: t('Description'),
          placeholder: t('Type a description'),
          limit: DESCRIPTION_LIMIT,
          description: '',
          sanitize: true,
          ariaLabel: t('Description')
        },
        elementSize: elementSizeProperty,
        shrink: {
          type: 'toggle',
          title: t('Shrink'),
          description: t('Make element smaller.'),
          disableElementLabel: true,
          ariaLabel: t('Shrink')
        },
        ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
          duplicate: {
            type: 'duplication',
            title: t('Duplicate Element'),
            description: t('Duplicate this element with all saved properties.'),
            allowMultiplePropChange: true,
            additionalProps: ['page'],
            ariaLabel: t('Duplicate Element')
          }
        } : {}
      },
      Style: {
        listItemStyle: {
          type: 'itemStyler',
          title: t('Element Style'),
          buttonText: 'Change Style',
          checkboxText: t('Apply to all items'),
          ariaLabel: t('Element Style')
        },
        itemBgURL: {
          title: t('Element Background Image'),
          type: 'imageUpload',
          renderTabs: ['gallery', 'upload', 'choose'],
          tethered: true,
          imagePreviewLayout: 'column',
          ariaLabel: t('Element Background Image'),
          offsetY: -20
        },
        itemTextAlignment: {
          type: 'selection',
          title: t('Text Alignment'),
          options: TEXT_ALIGNMENT_RENDERER_ARRAY,
          ariaLabel: t('Text Alignment')
        },
        showItemIcon: {
          type: 'itemIconToggle',
          title: t('Show Icon'),
          allowMultiplePropChange: true,
          additionalProps: ['itemIcon', 'itemIconBgColor', 'itemIconColor', 'type'],
          disableElementLabel: true,
          ariaLabel: t('Show Icon')
        }
      }
    }
  };
  const RightPanelConstants = {
    [RightPanelModes.APP_ITEM]: {
      [ITEM_TYPES.FORM]: {
        tabs: {
          General: {
            label: t('General')
          },
          Style: {
            label: t('Style')
          }
        },
        properties: {
          General: {
            id: {
              type: 'formSelection',
              title: t('Select a form'),
              allowMultiplePropChange: false,
              additionalProps: ['formTitle', 'status', 'isInitialForm'],
              disableElementLabel: true,
              ariaLabel: t('Select a form')
            },
            title: {
              type: 'formLabel',
              title: t('Form Label'),
              placeholder: t('Type a Form Label'),
              additionalProps: ['formTitle'],
              ariaLabel: t('Form Label')
            },
            description: {
              type: 'textarea',
              title: t('Form Description'),
              placeholder: t('Type a description'),
              limit: DESCRIPTION_LIMIT,
              description: '',
              sanitize: true,
              ariaLabel: t('Form Description')
            },
            embeddedForm: {
              type: 'showFormToggle',
              title: t('Show form'),
              description: t('Display the form in your app.'),
              allowMultiplePropChange: true,
              additionalProps: ['embeddedFormHeight', 'showEmbeddedFormFullHeight', 'formType', 'embeddedFormThankYouPageDisplaySecond', 'status'],
              disableElementLabel: true,
              ariaLabel: t('Show form')
            },
            elementSize: elementSizeProperty,
            shrink: {
              type: 'shrinkToggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true,
              allowMultiplePropChange: true,
              additionalProps: ['embeddedForm'],
              ariaLabel: t('Shrink')
            },
            required_showBadge: {
              type: 'loginRequiredToggle',
              title: t('Mark as required'),
              description: t('Mark the element as required.'),
              disableElementLabel: true,
              ariaLabel: t('Mark as required')
            },
            completed_showBadge: {
              type: 'badgeToggle',
              title: t('Mark as completed'),
              description: t('Mark the element as completed after submission.'),
              disableElementLabel: true,
              allowMultiplePropChange: true,
              additionalProps: ['completed_clearBadgePeriod', 'completed_clearBadgeOn', 'required_showBadge'],
              ariaLabel: t('Mark as completed')
            }
          },
          Style: {
            listItemStyle: {
              type: 'itemStyler',
              title: t('Element Style'),
              buttonText: t('Change Style'),
              checkboxText: t('Apply to all items')
            },
            itemBgURL: {
              title: t('Element Background Image'),
              type: 'imageUpload',
              renderTabs: ['gallery', 'upload', 'choose'],
              tethered: true,
              imagePreviewLayout: 'column',
              offsetY: -20
            },
            itemTextAlignment: {
              type: 'selection',
              title: t('Text Alignment'),
              options: TEXT_ALIGNMENT_RENDERER_ARRAY
            },
            showItemIcon: {
              type: 'itemIconToggle',
              title: t('Show Icon'),
              allowMultiplePropChange: true,
              additionalProps: ['itemIcon', 'itemIconBgColor', 'itemIconColor', 'type'],
              disableElementLabel: true
            }
          }
        }
      },
      [ITEM_TYPES.LIST]: {
        tabs: {
          General: {
            label: t('General')
          }
        },
        properties: {
          General: {
            resourceID: {
              ...resourceSelectionProp,
              handleNavigation: handleListNavigation,
              ViewButton: ListViewButton,
              additionalProps: [...resourceSelectionProp.additionalProps, 'viewID'],
              description: 'Use "Edit List" to edit the data, or "Change Table" to switch to another set of content.'
            }
          }
        }
      },
      [ITEM_TYPES.CARD_ITEM]: {
        tabs: {
          General: {
            label: t('General')
          },
          Action: {
            label: t('Action')
          }
        },
        properties: {
          General: {
            cardLayout: {
              type: 'cardLayout',
              title: t('Card Layout'),
              options: CARD_LAYOUT_OPTIONS,
              allowMultiplePropChange: true
            },
            title: {
              type: 'textinput',
              title: t('Title'),
              placeholder: t('Type a title'),
              maxLength: 80
            },
            description: {
              type: 'textarea',
              title: t('Description'),
              placeholder: t('Type a description'),
              limit: DESCRIPTION_LIMIT
            },
            start: {
              type: 'listItemStart',
              dropdownSections: [
                {
                  description: '',
                  options: [SIDE_SELECTION_OPTIONS[OPTION_VALUES.IMAGE], SIDE_SELECTION_OPTIONS[OPTION_VALUES.AVATAR], SIDE_SELECTION_OPTIONS[OPTION_VALUES.ICON]]
                }
              ],
              title: t('Visuals'),
              description: `${t('Add images or avatars to your list options')}.`,
              additionalProps: ['itemFontColor', 'itemBorderColor', 'itemBgColor', 'shrink', 'cardLayout']
            },
            shrink: {
              type: 'toggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true
            },
            ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
              duplicate: {
                type: 'duplication',
                title: t('Duplicate Element'),
                description: t('Duplicate this element with all saved properties.'),
                allowMultiplePropChange: true,
                additionalProps: ['page']
              }
            } : {}
          },
          Action: {
            buttonRole: {
              type: 'roles',
              title: t('Card Actions'),
              allowMultiplePropChange: true,
              buttonActionProps: CARD_ACTIONS
            },
            buttonValue: {
              type: 'buttonRoleComponents',
              additionalProps: ['buttonRole', 'page', 'formTitle'],
              ROLE_COMPONENT_PROPS: CARD_ACTION_PROPS,
              hasCondition: true,
              conditionItemProp: 'buttonRole',
              conditionChecker: ({ buttonRole }) => !!buttonRole && buttonRole !== CARD_ACTION_VALUES.NONE
            },
            end: {
              type: 'listItemEnd',
              dropdownSections: [
                {
                  description: '',
                  options: [SIDE_SELECTION_OPTIONS[OPTION_VALUES.ICON], SIDE_SELECTION_OPTIONS[OPTION_VALUES.BUTTON]]

                }
              ],
              title: t('Include an icon or button'),
              description: `${t('Add an icon or button to the end of your element')}.`,
              additionalProps: ['itemFontColor', 'itemBorderColor', 'itemBgColor'],
              hasCondition: true,
              conditionItemProp: 'buttonValue',
              conditionChecker: ({ buttonValue }) => !!buttonValue
            }
          }
        }
      },
      [ITEM_TYPES.HEADING]: {
        tabs: {
          General: {
            label: t('General')
          }
        },
        properties: {
          title: {
            type: 'textinput',
            title: t('Heading Text'),
            placeholder: t('Type a heading'),
            description: ''
          },
          description: {
            type: 'textarea',
            title: t('Subheading Text'),
            placeholder: t('Type a subheading'),
            limit: DESCRIPTION_LIMIT,
            description: ''
          },
          itemFontColor: {
            type: 'colorPicker',
            title: t('Text Color'),
            placeholder: '',
            description: '',
            placement: 'bottom-end',
            offsetX: 39
          },
          headingSize: {
            type: 'selection',
            title: t('Heading Size'),
            options: [
              {
                text: 'Default',
                value: 'default'
              },
              {
                text: 'Small',
                value: 'small'
              },
              {
                text: 'Large',
                value: 'large'
              }
            ]
          },
          itemTextAlignment: {
            type: 'selection',
            title: t('Text Alignment'),
            options: TEXT_ALIGNMENT_RENDERER_ARRAY
          },
          shrink: {
            type: 'toggle',
            title: t('Shrink'),
            description: t('Make element smaller.'),
            disableElementLabel: true
          },
          ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
            duplicate: {
              type: 'duplication',
              title: t('Duplicate Element'),
              description: t('Duplicate this element with all saved properties.'),
              allowMultiplePropChange: true,
              additionalProps: ['page']
            }
          } : {}
        }
      },
      [ITEM_TYPES.DONATION]: DONATION_ITEM_CONSTANTS,
      [ITEM_TYPES.DOCUMENT]: {
        tabs: {
          General: {
            label: t('General')
          },
          Style: {
            label: t('Style')
          }
        },
        properties: {
          General: {
            upload: { // todo:: change
              type: 'documentFileUpload',
              title: t('Upload Document'),
              description: '',
              tethered: true,
              defaultValue: '', // from props?
              allowMultiplePropChange: true,
              additionalProps: ['fileName', 'fileURL', 'fileType', 'fileSize']
            },
            title: {
              type: 'textinput',
              title: t('Document Name'),
              placeholder: t('Write here'),
              limit: 240,
              description: '',
              hasCondition: true,
              conditionItemProp: 'fileURL',
              conditionChecker: ({ fileURL }) => !!fileURL
            },
            description: {
              type: 'textarea',
              title: t('Description'),
              placeholder: t('Type a description'),
              limit: DESCRIPTION_LIMIT,
              description: '',
              sanitize: true,
              hasCondition: true,
              conditionItemProp: 'fileURL',
              conditionChecker: ({ fileURL }) => !!fileURL
            },
            elementSize: elementSizeProperty,
            shrink: {
              type: 'toggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true
            },
            ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
              duplicate: {
                type: 'duplication',
                title: t('Duplicate Element'),
                description: t('Duplicate this element with all saved properties.'),
                allowMultiplePropChange: true,
                additionalProps: ['page']
              }
            } : {}
          },
          Style: {
            hasCondition: true,
            conditionProp: 'fileURL',
            conditionChecker: fileURL => !!fileURL,
            listItemStyle: {
              type: 'itemStyler',
              title: t('Element Style'),
              buttonText: t('Change Style'),
              checkboxText: t('Apply to all items')
            },
            itemBgURL: {
              title: t('Element Background Image'),
              type: 'imageUpload',
              renderTabs: ['gallery', 'upload', 'choose'],
              tethered: true,
              imagePreviewLayout: 'column',
              offsetY: -20
            },
            itemTextAlignment: {
              type: 'selection',
              title: t('Text Alignment'),
              options: TEXT_ALIGNMENT_RENDERER_ARRAY
            },
            showItemIcon: {
              type: 'itemIconToggle',
              title: t('Show Icon'),
              allowMultiplePropChange: true,
              additionalProps: ['itemIcon', 'itemIconBgColor', 'itemIconColor', 'type'],
              disableElementLabel: true
            }
          }
        }
      },
      [ITEM_TYPES.LINK]: {
        tabs: {
          General: {
            label: t('General')
          },
          Style: {
            label: t('Style')
          }
        },
        properties: {
          General: {
            description: {
              type: 'textinput',
              title: t('Link Title'),
              placeholder: t('Enter a title')
            },
            title: {
              type: 'textarea',
              title: t('Link'),
              placeholder: t('Type a link'),
              description: ''
            },
            showLinkPreview: {
              type: 'toggle',
              title: t('Show preview image'),
              description: t('Show or hide the thumbnail image of the link'),
              disableElementLabel: true,
              hasCondition: true,
              conditionItemProp: 'title',
              conditionChecker: ({ title }) => !!title && title !== 'https://'
            },
            showURL: {
              type: 'toggle',
              title: t('Show URL'),
              disableElementLabel: true,
              hasCondition: true,
              conditionItemProp: 'title',
              conditionChecker: ({ title }) => !!title && title !== 'https://'
            },
            elementSize: elementSizeProperty,
            shrink: {
              type: 'toggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true
            },
            ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
              duplicate: {
                type: 'duplication',
                title: t('Duplicate Element'),
                description: t('Duplicate this element with all saved properties.'),
                allowMultiplePropChange: true,
                additionalProps: ['page']
              }
            } : {}
          },
          Style: {
            hasCondition: true,
            conditionProp: 'title',
            conditionChecker: title => !!title && title !== 'https://',
            listItemStyle: {
              type: 'itemStyler',
              title: t('Element Style'),
              buttonText: 'Change Style',
              checkboxText: t('Apply to all items')
            },
            itemBgURL: {
              title: t('Element Background Image'),
              type: 'imageUpload',
              renderTabs: ['gallery', 'upload', 'choose'],
              tethered: true,
              imagePreviewLayout: 'column',
              offsetY: -20
            },
            itemTextAlignment: {
              type: 'selection',
              title: t('Text Alignment'),
              options: TEXT_ALIGNMENT_RENDERER_ARRAY
            },
            showItemIcon: {
              type: 'itemIconToggle',
              title: t('Show Icon'),
              allowMultiplePropChange: true,
              additionalProps: ['itemIcon', 'itemIconBgColor', 'itemIconColor', 'type'],
              disableElementLabel: true
            }
          }
        }
      },
      [ITEM_TYPES.TABLE_LINK]: ResourceLinkProperties,
      [ITEM_TYPES.REPORT_LINK]: ResourceLinkProperties,
      [ITEM_TYPES.SENTBOX_LINK]: ResourceLinkProperties,
      [ITEM_TYPES.SIGN_LINK]: {
        tabs: {
          General: {
            label: t('General')
          },
          Style: {
            label: t('Style')
          }
        },
        properties: {
          General: {
            resourceID: resourceSelectionProp,
            title: {
              type: 'textinput',
              title: t('Title'),
              placeholder: t('Enter a title')
            },
            description: {
              type: 'textarea',
              title: t('Description'),
              placeholder: t('Type a description'),
              limit: DESCRIPTION_LIMIT,
              description: '',
              sanitize: true
            },
            elementSize: elementSizeProperty,
            shrink: {
              type: 'toggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true
            },
            required_showBadge: {
              type: 'loginRequiredToggle',
              title: t('Mark as required'),
              description: t('Mark the element as required.'),
              disableElementLabel: true
            },
            completed_showBadge: {
              type: 'badgeToggle',
              title: t('Mark as completed'),
              description: t('Mark the element as completed after submission.'),
              disableElementLabel: true,
              allowMultiplePropChange: true,
              additionalProps: ['completed_clearBadgePeriod', 'completed_clearBadgeOn', 'required_showBadge']
            }
          },
          Style: {
            ...ResourceLinkProperties.properties.Style
          }
        }
      },
      [ITEM_TYPES.IMAGE]: {
        tabs: {
          General: {
            label: t('General')
          },
          Actions: {
            label: t('Actions')
          }
        },
        properties: {
          General: {
            imageURL: {
              type: 'imageUploadWithLoading',
              allowMultiplePropChange: true,
              defaultValue: {
                imageURL: '',
                width: '',
                height: '',
                proportion: ''
              },
              autoRetrieveDimensions: true,
              title: t('Image'),
              tethered: true,
              isColumn: false,
              imagePreviewLayout: 'line',
              allowImageHint: true,
              description: t('Supported formats: jpg, jpeg, png, gif, svg'),
              offsetY: -44
            },
            size: {
              type: 'dimensionPicker',
              title: t('Size'),
              allowMultiplePropChange: true,
              additionalProps: ['width', 'height', 'keepProportion', 'proportion'],
              hasCondition: true,
              conditionItemProp: 'width',
              conditionChecker: ({ width }) => !!width
            },
            itemTextAlignment: {
              type: 'selection',
              title: t('Image Alignment'),
              description: t('Select how the image is aligned horizontally.'),
              options: TEXT_ALIGNMENT_RENDERER_ARRAY,
              ariaLabel: t('Image Alignment'),
              additionalProps: ['width'],
              hasCondition: true,
              conditionItemProp: 'width',
              conditionChecker: ({ width }) => !!width
            },
            altText: {
              type: 'textinput',
              title: t('Alternative Text'),
              placeholder: t('Description of the image'),
              description: t('Show this text when the image can\'t be displayed or loaded (uses the HTML alt attribute).'),
              hasCondition: true,
              conditionItemProp: 'imageURL',
              conditionChecker: ({ imageURL }) => !!imageURL
            },
            shrink: {
              type: 'toggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true
            },
            ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
              duplicate: {
                type: 'duplication',
                title: t('Duplicate Element'),
                description: t('Duplicate this element with all saved properties.'),
                allowMultiplePropChange: true,
                additionalProps: ['page']
              }
            } : {}
          },
          Actions: {
            buttonRole: {
              type: 'roles',
              title: t('Image Action'),
              additionalProps: ['imageLink', 'type'],
              allowMultiplePropChange: true,
              buttonActionProps: IMAGE_ACTIONS
            },
            buttonValue: {
              type: 'buttonRoleComponents',
              additionalProps: ['buttonRole', 'page', 'formTitle', 'imageLink', 'type'],
              ROLE_COMPONENT_PROPS: IMAGE_ACTION_PROPS,
              hasCondition: true,
              conditionItemProp: 'buttonRole',
              conditionChecker: ({ buttonRole }) => !!buttonRole && buttonRole !== IMAGE_ACTION_VALUES.NONE
            }
          }
        }
      },
      [ITEM_TYPES.BUTTON]: {
        tabs: {
          General: {
            label: t('General')
          },
          Style: {
            label: t('Style')
          }
        },
        properties: {
          General: {
            title: {
              type: 'textinput',
              title: t('Button Text'),
              placeholder: t('Button Text'),
              description: ''
            },
            buttonRole: {
              title: t('Button Action'),
              type: 'buttonActions',
              allowMultiplePropChange: true,
              additionalProps: ['buttonValue', 'page']
            },
            buttonValue: {
              type: 'buttonRoleComponents',
              ROLE_COMPONENT_PROPS: ROLE_COMPONENT_PROPS,
              additionalProps: ['buttonRole', 'page', 'formTitle'],
              hasCondition: true,
              conditionItemProp: 'buttonRole',
              conditionChecker: ({ buttonRole }) => !!buttonRole
            },
            shrink: {
              type: 'toggle',
              title: t('Shrink'),
              description: t('Make element smaller.'),
              disableElementLabel: true
            },
            ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
              duplicate: {
                type: 'duplication',
                title: t('Duplicate Element'),
                description: t('Duplicate this element with all saved properties.'),
                allowMultiplePropChange: true,
                additionalProps: ['page']
              }
            } : {}
          },
          Style: {
            listItemStyle: {
              type: 'itemStyler',
              title: t('Button Style'),
              buttonText: t('Change Style'),
              // customPaletteProps: { // order is important! // gradient
              //   itemBgColor: {
              //     title: t('Start Color')
              //   },
              //   itemBgColorEnd: {
              //     title: t('End Color')
              //   },
              //   itemBorderColor: DEFAULT_CUSTOM_PALETTE_PROPS.itemBorderColor,
              //   itemFontColor: DEFAULT_CUSTOM_PALETTE_PROPS.itemFontColor
              // },
              colorList: BUTTON_STYLING_LIST
            },
            buttonRadius: {
              type: 'selection',
              title: t('Button Corner'),
              options: [{
                text: 'Default',
                value: 'default'
              },
              {
                text: 'Rounded',
                value: 'rounded'
              }
              ]
            },
            buttonWidth: {
              type: 'selection',
              title: t('Button Width'),
              options: [{
                text: 'Auto',
                value: 'auto'
              },
              {
                text: 'Full',
                value: 'full'
              }
              ]
            },
            itemTextAlignment: {
              type: 'selection',
              title: t('Button Alignment'),
              options: TEXT_ALIGNMENT_RENDERER_ARRAY
            }
          }
        }
      },
      [ITEM_TYPES.DIVIDER]: {
        tabs: {
          General: {
            label: t('General')
          }
        },
        properties: {
          itemFontColor: {
            type: 'colorPicker',
            title: t('Divider Color'),
            placement: 'bottom-end',
            offsetX: 39
          },
          ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
            duplicate: {
              type: 'duplication',
              title: t('Duplicate Element'),
              description: t('Duplicate this element with all saved properties.'),
              allowMultiplePropChange: true,
              additionalProps: ['page']
            }
          } : {}
        }
      },
      [ITEM_TYPES.PARAGRAPH]: {
        tabs: {
          General: {
            label: t('General')
          }
        },
        properties: {
          shrink: {
            type: 'toggle',
            title: t('Shrink'),
            description: t('Make element smaller.'),
            disableElementLabel: true
          },
          ...isFeatureEnabled(FEATURE_NAMES.ItemDuplication) ? {
            duplicate: {
              type: 'duplication',
              title: t('Duplicate Element'),
              description: t('Duplicate this element with all saved properties.'),
              allowMultiplePropChange: true,
              additionalProps: ['page']
            }
          } : {}
        }
      },
      [ITEM_TYPES.PRODUCT_LIST]: {
        tabs: {
          General: {
            label: t('General')
          }
        },
        properties: {
          General: {
            type: 'productListSettings',
            disableElementLabel: true,
            noPadding: true
          }
        }
      }
    },
    [RightPanelModes.APP_HEADER]: {
      tabs: {
        General: {
          label: t('General'),
          hasIcon: true,
          Icon: GeneralIconColored
        },
        Logo: {
          label: t('Logo'),
          Icon: LogoIconColored,
          hasIcon: true
        },
        Style: {
          label: t('Style'),
          hasIcon: true,
          Icon: StyleIconColored
        }
      },
      properties: {
        General: {
          openAppHeader: {
            type: 'toggle',
            title: t('Show App Header'),
            disableElementLabel: true
          },
          name: {
            type: 'textinput',
            title: t('App Title'),
            placeholder: t('App Title'),
            description: ''
          },
          description: {
            type: 'textarea',
            title: t('App Description'),
            placeholder: t('Add description'),
            limit: DESCRIPTION_LIMIT,
            description: '',
            sanitize: true
          }
        },
        Logo: {
          appLogo: {
            type: 'appLogo',
            noLine: true
          }
        },
        Style: {
          lineElement: {
            showAppHeader: {
              type: 'toggle',
              title: isFeatureEnabled(FEATURE_NAMES.AppHeaderVisibility) ? t('Show Background') : t('Show App Header'),
              disableElementLabel: true
            },
            appHeaderBgColor: {
              type: 'colorPicker',
              title: t('Background Color'),
              placeholder: '',
              description: '',
              isColumn: true,
              hasCondition: true,
              conditionChecker: ({ propValue, expectedValue }) => propValue === expectedValue,
              conditionProp: 'showAppHeader',
              conditionValue: 'Yes'
            },
            appHeaderBgURL: {
              type: 'imageUpload',
              title: t('Background Image'),
              description: '',
              tethered: true,
              isColumn: true,
              imagePreviewLayout: 'column',
              hasCondition: true,
              conditionChecker: ({ propValue, expectedValue }) => propValue === expectedValue,
              conditionProp: 'showAppHeader',
              conditionValue: 'Yes',
              offsetX: -14,
              offsetY: -20
            }
          },
          appFontColor: {
            type: 'colorPicker',
            title: t('Text Color'),
            placeholder: '',
            description: '',
            ...isFeatureEnabled(FEATURE_NAMES.AppHeaderVisibility) ? {
              hasCondition: true,
              conditionChecker: ({ propValue, expectedValue }) => propValue === expectedValue,
              conditionProp: 'openAppHeader',
              conditionValue: 'Yes'
            } : {},
            placement: 'bottom-end',
            offsetX: 39
          },
          appHeaderTextAlignment: {
            type: 'selection',
            title: t('Header Alignment'),
            options: TEXT_ALIGNMENT_RENDERER_ARRAY,
            ...isFeatureEnabled(FEATURE_NAMES.AppHeaderVisibility) ? {
              hasCondition: true,
              conditionChecker: ({ propValue, expectedValue }) => propValue === expectedValue,
              conditionProp: 'openAppHeader',
              conditionValue: 'Yes'
            } : {}
          }
        }
      }
    },
    [RightPanelModes.APP_DESIGNER]: {
      tabs: {
        General: {
          label: t('General')
        },
        AppLayouts: {
          label: t('App Layouts')
        }
      },
      properties: {
        General: {
          colorScheme: {
            transient: true,
            type: 'colorPalette',
            title: t('Color Scheme'),
            description: '',
            ariaLabel: t('Color Scheme'),
            role: 'radiogroup'
          },
          lineElement2: {
            sectionLabel: t('App Background Properties'),
            appBgColor: {
              type: 'colorPicker',
              title: t('Start Color'),
              placeholder: '',
              description: '',
              isColumn: true,
              ariaLabel: t('Start Color')
            },
            appBgColorEnd: {
              type: 'colorPicker',
              title: t('End Color'),
              placeholder: '',
              description: '',
              isColumn: true,
              ariaLabel: t('End Color'),
              placement: 'bottom-end',
              offsetX: 39
            },
            appBgURL: {
              type: 'imageUpload',
              title: t('App Background Image'),
              description: '',
              tethered: true,
              ariaLabel: t('App Background Image'),
              offsetY: -20
            }
          },
          lineElement3: {
            listBgColor: {
              type: 'colorPicker',
              title: t('List Background Color'),
              placeholder: '',
              description: '',
              hasCondition: false,
              conditionChecker: ({ propValue, expectedValue }) => propValue === expectedValue,
              conditionProp: 'showListBg',
              conditionValue: 'Yes',
              ariaLabel: t('List Background Color'),
              placement: 'bottom-end',
              offsetX: 39
            }
          },
          appFontFamily: {
            type: 'fontPicker',
            title: t('Font Family'),
            placeholder: '',
            description: '',
            ariaLabel: t('Font Family')
          }
        },
        AppLayouts: {
          appLayout: {
            type: 'layouts',
            title: '',
            description: '',
            ariaLabel: t('App Layouts')
          }
        }
      }
    },
    [RightPanelModes.APP_ICON]: {
      tabs: {
        General: {
          label: t('General')
        }
      },
      properties: {
        appLogo: {
          type: 'appLogo',
          noLine: true,
          elementType: APP_GRAPHIC_TYPES.appIcon
        }
      }
    },
    [RightPanelModes.SPLASH_SCREEN]: {
      tabs: {
        General: {
          label: t('General')
        }
      },
      properties: {
        lineElement: {
          hasCondition: true,
          conditionProp: 'splashFontColor',
          conditionChecker: ({ propValue, expectedValue }) => !propValue || propValue === expectedValue,
          conditionValue: '#ffffff',
          splashBgColor: {
            type: 'colorPicker',
            title: t('Background Color'),
            placeholder: '',
            description: '',
            placement: 'bottom-end',
            offsetX: 39
          }
        },
        lineElement2: {
          hasCondition: true,
          conditionProp: 'splashFontColor',
          conditionChecker: ({ propValue, expectedValue }) => propValue && propValue !== expectedValue,
          conditionValue: '#ffffff',
          splashBgColor: {
            type: 'colorPicker',
            title: t('Background Color'),
            placeholder: '',
            description: '',
            isColumn: true
          },
          splashFontColor: {
            type: 'colorPicker',
            title: t('Font Color'),
            placeholder: '',
            description: '',
            isColumn: true
          }
        }
      }
    },
    [RightPanelModes.APP_WIDGET]: {
      tabs: {
        General: {
          label: t('General')
        }
      },
      properties: {
        General: {
          party: {
            type: 'party'
          }
        }
      }
    },
    [RightPanelModes.APP_FOOTER]: {
      tabs: {
        General: {
          label: t('General')
        }
      },
      properties: {
        General: {
          type: 'appFooter'
        }
      }
    },
    [RightPanelModes.MENU_SETTINGS]: {
      tabs: {
        General: {
          label: t('General')
        }
      },
      properties: {
        General: {
          type: 'menuSettings',
          noLine: true
        }
      }
    },
    [RightPanelModes.PAGE_SETTINGS]: {
      tabs: {
        General: {
          label: t('General')
        }
      },
      properties: {
        General: {
          type: 'pageSettings',
          noLine: true
        }
      }
    },
    [RightPanelModes.DONATION_PAYMENT_SETTINGS]: {
      tabs: {
        General: {
          label: t('donation settings')
        }
      },
      properties: {
        General: {
          type: 'donationSettings',
          disableElementLabel: true,
          noPadding: true
        }
      }
    },
    [RightPanelModes.CONTACT_INFORMATION_SETTINGS]: {
      tabs: {
        General: {
          label: t('Contact Information Properties')
        }
      },
      properties: {
        General: {
          type: 'contactInformationSettings',
          disableElementLabel: true,
          noPadding: true
        }
      }
    },
    ...isFeatureEnabled(FEATURE_NAMES.ProductList) ? {
      [RightPanelModes.PAYMENT_SETTINGS]: {
        tabs: {
          General: {
            label: t('General')
          }
        },
        properties: {
          General: {
            type: 'paymentSettings',
            disableElementLabel: true,
            noPadding: true
          }
        }
      }
    } : {}
  };
  RightPanelConstants[RightPanelModes.APP_LOGO] = {
    ...RightPanelConstants[RightPanelModes.APP_HEADER],
    tabs: {
      ...RightPanelConstants[RightPanelModes.APP_HEADER].tabs,
      Logo: {
        ...RightPanelConstants[RightPanelModes.APP_HEADER].tabs.Logo,
        isDefault: true // TODO any better way?
      }
    }
  };
  RightPanelConstants[RightPanelModes.APP_STYLE] = {
    ...RightPanelConstants[RightPanelModes.APP_HEADER],
    tabs: {
      ...RightPanelConstants[RightPanelModes.APP_HEADER].tabs,
      Style: {
        ...RightPanelConstants[RightPanelModes.APP_HEADER].tabs.Style,
        isDefault: true // TODO any better way?
      }
    }
  };
  RightPanelConstants[RightPanelModes.APP_HEADER] = {
    ...RightPanelConstants[RightPanelModes.APP_HEADER],
    tabs: {
      ...RightPanelConstants[RightPanelModes.APP_HEADER].tabs,
      General: {
        ...RightPanelConstants[RightPanelModes.APP_HEADER].tabs.General,
        isDefault: true
      }
    }
  };
  return RightPanelConstants[mode];
};

export default getRightPanelConstants;
