import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { IconLockFilled } from '@jotforminc/svg-icons';
import { BaseInput, BaseInputAddon } from './BaseInput';
import { InputTextProps, inputTextDefaultProps } from './input.types';
import { useFormControl } from '../../contexts';

export const InputText = forwardRef((
  props: InputTextProps,
  ref?: ComponentPropsWithRef<'input'>['ref']
):JSX.Element => {
  const {
    id,
    readOnly,
    size,
    disabled,
    theme,
    prefix,
    suffix,
    showLockIconInReadOnly,
    useDefaultStyleInReadOnly,
    required,
    ...rest
  } = useFormControl(props);

  const addonStart = (
    <BaseInputAddon
      {...prefix}
      size={size}
      theme={theme}
      disabled={!!disabled}
      readOnly={!!readOnly}
      direction="start"
    />
  );

  const addonEnd = (
    <BaseInputAddon
      {...suffix}
      size={size}
      icon={readOnly && showLockIconInReadOnly ? IconLockFilled : suffix?.icon}
      theme={theme}
      disabled={!!disabled}
      readOnly={!!readOnly}
      direction="end"
    />
  );

  return (
    <BaseInput
      id={id}
      ref={ref}
      readOnly={!!readOnly}
      useDefaultStyleInReadOnly={!!useDefaultStyleInReadOnly}
      size={size}
      theme={theme}
      disabled={!!disabled}
      addonStart={addonStart}
      addonEnd={addonEnd}
      required={required}
      {...rest}
    />
  );
});

InputText.defaultProps = inputTextDefaultProps;
