/* eslint-disable camelcase */
import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';

import SELECTORS from '../../../../../store/selectors';
import BadgeToggle from '../RightPanel/BadgeToggle';
import ElementStyler from './ElementStyler';
import ToggleButtonGroup from '../../ToggleButtonGroup';
import { ColorPicker } from '../../ColorPicker';
import RadioToggle from '../../RadioToggle';

import { ScRightPanelBlock, ScTetheredBuilderWrapper } from '../../../styles/scRightPanel';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import {
  showComponentByProperty,
  HEADING_SIZE_OPTIONS,
  getDefaultValuesforKeys
} from './utils';
import { TEXT_ALIGNMENT_RENDERER_ARRAY } from '../../../constants/textAlignment';
import { useItemDefaults } from '../../../../../properties';
import { isYes } from '../../../../../utils';
import ItemIconToggle from '../RightPanel/ItemIconToggle';
import RPImageUpload from '../RightPanel/RPImageUpload';
import DuplicateItemButton from '../RightPanel/DuplicateItemButton';
import { FEATURE_NAMES } from '../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../utils/features/helper';
import { ITEM_TYPES } from '../../../../../constants/itemTypes';
import { BUTTON_STYLING_LIST, ITEM_STYLING_LIST } from '../../../../../properties/styling';
import LoginRequiredToggle from '../RightPanel/LoginRequireToggle';
import { ScLineHr } from '../RightPanel/ScLineHr';

const MultipleRightPanel = () => {
  const panelElementRef = useRef();
  const selectedItemProps = useSelector(SELECTORS.getSelectedPortalItemsWithInfo);
  const version = useSelector(SELECTORS.getAppVersionSelector);
  const isAllSelectedItemsAreForm = useSelector(SELECTORS.getAllSelectedItemsAreForms); // Until forms are duplicable
  const dispatch = useDispatch();

  const isButtonStyler = !selectedItemProps.some(item => item.type !== ITEM_TYPES.BUTTON);
  const getItemWithDefaults = useCallback(type => useItemDefaults(type, version), [version]);
  const selectedItemsWithDefaults = selectedItemProps.map(({ type, ...rest }) => ({ ...getItemWithDefaults(type), ...rest }));
  const panelProps = [
    'required_showBadge', 'completed_showBadge', ['itemBgColor', 'itemFontColor', 'itemBorderColor'], 'itemTextAlignment',
    'headingSize', 'itemFontColor', 'showURL', 'showItemIcon', 'shrink', 'itemIcon', 'itemIconColor', 'itemIconBgColor', 'itemBgURL',
    'duplicate'
  ];

  const {
    completed_showBadgeVisible,
    itemBgColorVisible,
    itemTextAlignmentVisible,
    headingSizeVisible,
    itemFontColorVisible,
    required_showBadgeVisible,
    showURLVisible,
    showItemIconVisible,
    shrinkVisible,
    itemBgURLVisible
  } = showComponentByProperty({
    items: selectedItemsWithDefaults,
    properties: panelProps,
    version
  });

  const defaultProperties = getDefaultValuesforKeys(selectedItemsWithDefaults, version);

  const onChangeHandler = useCallback(props => dispatch(ACTION_CREATORS.onMultipleItemUpdate(props)), []);

  const isMixed = useMemo(() => {
    if (isYes(defaultProperties.required_showBadge)) return false;

    return defaultProperties.completed_showBadge === '';
  }, [defaultProperties]);

  return (
    <>
      {required_showBadgeVisible && (
        <ScRightPanelBlock line>
          <LoginRequiredToggle
            onChange={value => onChangeHandler({ required_showBadge: value })}
            value={defaultProperties.required_showBadge}
            title={t('Mark as required')}
            description={t('Mark the element as required.')}
            isMixed={defaultProperties.required_showBadge === ''}
          />
        </ScRightPanelBlock>
      )}
      {completed_showBadgeVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <BadgeToggle
              value={defaultProperties.completed_showBadge}
              title={t('Mark as completed')}
              description={t('Mark the element as completed after submission.')}
              clearBadgeOn={defaultProperties.clearBadgeOn}
              completed_clearBadgeOn={defaultProperties.completed_clearBadgeOn}
              clearBadgePeriod={defaultProperties.clearBadgePeriod}
              completed_clearBadgePeriod={defaultProperties.completed_clearBadgePeriod}
              onChange={onChangeHandler}
              required_showBadge={defaultProperties.required_showBadge}
              isMixed={isMixed}
            />
          </ScRightPanelBlock>
        </>
      )}
      { showURLVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <RadioToggle
              onChange={value => onChangeHandler({ showURL: value })}
              value={defaultProperties.showURL}
              title={t('Show URL')}
              isMixed={defaultProperties.showURL === ''}
            />
          </ScRightPanelBlock>
        </>
      )}
      { itemBgColorVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="itemStyler">{t('Element Style')}</label>
            <ElementStyler
              onChange={onChangeHandler}
              itemBgColor={defaultProperties.itemBgColor}
              itemBorderColor={defaultProperties.itemBorderColor}
              itemFontColor={defaultProperties.itemFontColor}
              id="itemStyler"
              colorList={isButtonStyler ? BUTTON_STYLING_LIST : ITEM_STYLING_LIST}
            />
          </ScRightPanelBlock>
        </>
      )}
      {itemBgURLVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock column ref={panelElementRef}>
            <label htmlFor="itemBackground">{t('Element Background Image')}</label>
            <ScTetheredBuilderWrapper>
              <RPImageUpload
                id="itemBackground"
                targetRef={panelElementRef}
                onChange={value => onChangeHandler({ itemBgURL: value })}
                title={t('Show Icon')}
                value={defaultProperties.itemBgURL}
                renderTabs={['gallery', 'upload', 'choose']}
              />
            </ScTetheredBuilderWrapper>
          </ScRightPanelBlock>
        </>
      )}
      { itemFontColorVisible && !itemBgColorVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock column>
            <label htmlFor="textColor">{t('Font Color')}</label>
            <ColorPicker
              id="textColor"
              defaultValue={defaultProperties.itemFontColor}
              onChange={value => onChangeHandler({ itemFontColor: value })}
            />
          </ScRightPanelBlock>
        </>
      )}
      {showItemIconVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <ItemIconToggle
              id="elementIcon"
              itemIcon={defaultProperties.itemIcon}
              onChange={onChangeHandler}
              itemIconBgColor={defaultProperties.itemIconBgColor}
              itemIconColor={defaultProperties.itemIconColor}
              title={t('Show Icon')}
              value={defaultProperties.showItemIcon}
              showRemoveButton={false}
            />
          </ScRightPanelBlock>
        </>
      )}
      { itemTextAlignmentVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="textAlignment">{t('Alignment')}</label>
            <ToggleButtonGroup
              id="textAlignment"
              options={TEXT_ALIGNMENT_RENDERER_ARRAY}
              onChange={value => onChangeHandler({ itemTextAlignment: value })}
              value={defaultProperties.itemTextAlignment}
            />
          </ScRightPanelBlock>
        </>
      )}
      { headingSizeVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="headingSize">{t('Heading Size')}</label>
            <ToggleButtonGroup
              id="headingSize"
              options={HEADING_SIZE_OPTIONS}
              onChange={value => onChangeHandler({ headingSize: value })}
              value={defaultProperties.headingSize}
            />
          </ScRightPanelBlock>
        </>
      )}
      { shrinkVisible && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <RadioToggle
              onChange={value => onChangeHandler({ shrink: value })}
              value={defaultProperties.shrink}
              title={t('Shrink')}
              description={t('Make element smaller.')}
              isMixed={defaultProperties.shrink === ''}
            />
          </ScRightPanelBlock>
        </>
      )}
      { isFeatureEnabled(FEATURE_NAMES.ItemDuplication) && !isAllSelectedItemsAreForm && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="duplicateItemButton">{t('Duplicate Elements')}</label>
            <DuplicateItemButton action="multipleRightPanelDuplicationClicked" />
          </ScRightPanelBlock>
        </>
      )}
    </>
  );
};

export default MultipleRightPanel;
