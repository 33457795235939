import React, { useState } from 'react';
import cx from 'classnames';
import CommonDropdown from '@jotforminc/dropdown';
import { IconChevronDown } from '@jotforminc/svg-icons';

const NewDropdown = ({
  selectedOptionValues,
  options,
  zIndex,
  onChange,
  subType,
  inputClass,
  disabled,
  dropdownWrapperClass,
  dropdownInputClass,
  dropdownTheme
} : IProps) : JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState('');
  const selectedValue = (typeof selectedOptionValues === 'object' && subType === 'addQuestion') ? selectedOptionValues.type : selectedOptionValues;

  const baseDropdownInputClasses = cx('h-10 border text-md truncate relative min-w-44 mt-3 py-2 pl-4 pr-8 text-left sm:mt-0', {
    'radius-t': position === 'bottom',
    'radius-b': position === 'top'
  });

  const baseDropdownWrapperClasses = cx('border transform text-md text-left', {
    'radius-t translate-y-px': position === 'top',
    'radius-b -translate-y-px': position === 'bottom'
  });

  const lightThemeInputClasses = cx('bg-white color-navy-700', {
    'border-navy-100 hover:border-navy-200 hover:border-opacity-80 radius': !isOpen,
    'border-blue-200': isOpen
  });

  const lightThemeWrapperClasses = 'bg-white border-blue-200';

  const darkThemeInputClasses = cx('bg-gray-500 color-white border-gray-600', {
    'border-gray-900 radius': !isOpen,
    'border-gray-700': isOpen
  });

  const darkThemeWrapperClasses = cx('bg-gray-500 color-white border-gray-600', {
    'color-white radius': !isOpen,
    'border-gray-700': isOpen
  });

  const dropdownInputClasses = cx(
    baseDropdownInputClasses,
    dropdownTheme === 'light' ? lightThemeInputClasses : darkThemeInputClasses,
    cx(dropdownInputClass)
  );

  const dropdownWrapperClasses = cx(
    baseDropdownWrapperClasses,
    dropdownTheme === 'light' ? lightThemeWrapperClasses : darkThemeWrapperClasses,
    cx(dropdownWrapperClass)
  );

  const activeOptionClasses = cx(
    dropdownTheme === 'light' ? 'bg-blue-100 bg-opacity-70' : 'bg-gray-700 bg-opacity-70 color-white'
  );

  const defaultOptionClasses = cx(
    dropdownTheme === 'light' ? 'text-md px-4 py-2 block w-full truncate text-left' : 'color-white text-md px-4 py-2 block w-full truncate text-left hover:bg-gray-300 duration-300'
  );
  const InputAfterIcon = dropdownTheme === 'light' ? <IconChevronDown className="w-4 h-4 absolute top-1/2 transform -translate-y-1/2 pointer-events-none right-3" />
    : <IconChevronDown className="w-4 h-4 absolute top-1/2 transform -translate-y-1/2 pointer-events-none right-3 color-gray-900" />;

  return (
    <CommonDropdown
      inputClass={inputClass}
      isDropdownOpen={(isDropdownOpen: boolean) => setIsOpen(isDropdownOpen)}
      getDropdownPosition={(dropdownPosition: string) => setPosition(dropdownPosition)}
      defaultInputClass={dropdownInputClasses}
      defaultWrapperClass={dropdownWrapperClasses}
      defaultOptionClass={defaultOptionClasses}
      inActiveOptionClass="hover:bg-blue-50 hover:bg-opacity-40 cursor-pointer focus:bg-blue-50 focus:bg-opacity-40"
      activeOptionClass={activeOptionClasses}
      defaultListClass="max-h-30vh overflow-x-hidden overflow-y-auto overscroll-contain"
      defaultInputTextClass="w-full truncate pr-1"
      defaultSearchInputClass="w-full h-8 px-2 text-md border border-gray-50 radius"
      defaultSearchBoxClass="p-2 border-b border-b-blue-200"
      defaultNotResultFoundClass="text-md color-gray-200 py-2 px-4 text-center"
      disabledClass="opacity-50"
      inputAfter={InputAfterIcon}
      selectedOptionValues={[selectedValue !== '' ? selectedValue : options[0]?.value]}
      onOptionClick={(data: string | number, e: Event) => {
        if (data === 'addQuestion') {
          const addQuestionObj = options.find(opt => opt.value === data);
          if (addQuestionObj) {
            onChange(addQuestionObj, e);
          }
        } else {
          onChange(data, e);
        }
      }}
      data={options}
      zIndex={zIndex}
      disabled={disabled}
    />
  );
};

interface IOption {
  text: string,
  value: string | number,
}

export interface IProps {
  inputClass: string,
  selectedOptionValues: string | number,
  options: Array<IOption>,
  onChange: (value: string | number | IOption, e: Event) => void,
  zIndex: number,
  subType?: 'addQuestion' | '',
  disabled: boolean,
  dropdownWrapperClass: string,
  dropdownInputClass: string,
  dropdownTheme: string
}
NewDropdown.defaultProps = {
  selectedOptionValues: '',
  inputClass: '',
  zIndex: 99999,
  dropdownWrapperClass: '',
  dropdownInputClass: '',
  dropdownTheme: 'light'
};

export default NewDropdown;
