import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';

import { radioCVA, radioCircle, radioWrapper } from './radio.cva';
import { RadioProps, radioDefaultProps } from './radio.types';

import { ChoiceLabel } from '../../shared/ChoiceLabel/ChoiceLabel';
import { useFormControl } from '../../contexts';

export const Radio = forwardRef((
  props: RadioProps,
  ref?: ComponentPropsWithRef<'input'>['ref']
): JSX.Element => {
  const {
    description,
    label,
    htmlSize,
    theme,
    disabled,
    checked,
    size,
    error,
    className,
    id,
    truncate,
    value,
    ...rest
  } = useFormControl<RadioProps>(props, ['theme', 'size', 'disabled']);

  const hasLabel = label && label.length > 0;
  const hasDescription = description && description.length > 0;
  const wrapperClass = cx(!hasLabel && !hasDescription ? className : null, radioWrapper({ size }));

  const radioIcon = (
    <span className={wrapperClass}>
      <input
        type="radio"
        id={id}
        checked={!!checked}
        value={value}
        className={radioCVA({
          size,
          theme,
          disabled: !!disabled,
          checked: !!checked,
          error: !!error
        })}
        disabled={!!disabled}
        ref={ref}
        size={htmlSize}
        {...rest}
      />
      {!!checked && <span className={radioCircle({ size, theme, disabled: !!disabled })} />}
    </span>
  );

  return (
    (hasLabel || hasDescription) ? (
      <ChoiceLabel
        id={id}
        input={radioIcon}
        description={description}
        label={label}
        className={className}
        size={size}
        disabled={!!disabled}
        theme={theme}
        truncate={truncate}
      />
    ) : radioIcon
  );
});

Radio.defaultProps = radioDefaultProps;
