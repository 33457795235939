import { autoTranslate } from '@jotforminc/utils';

export default autoTranslate({
  CLOSE: 'Close',
  BACK: 'Back',
  BETA: 'BETA',
  NEW: 'NEW',
  USE_TEMPLATE: 'Use Template',
  PREVIEW_FORM: 'Preview Form',
  PLEASE_WAIT: 'Please wait...',
  CHOOSE_FROM_TEMPLATES: 'Choose a template',
  CHOOSE_FROM_TEMPLATES_SUBTITLE: 'Explore {formTemplatesCount}+ ready-made templates to create a form in minutes',
  SELECT_YOUR_FORM: 'Select a Form',
  SELECT_YOUR_FORM_SUBTITLE: 'Select the form you want to use.'
});
