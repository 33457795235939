export const TIME_ZONES = [
  {
    text: 'Africa',
    name: 'Africa',
    type: 'group',
    options: [
      { key: 'Africa/Abidjan', value: 'Africa/Abidjan', text: 'Abidjan (GMT)' },
      { key: 'Africa/Accra', value: 'Africa/Accra', text: 'Accra (GMT)' },
      { key: 'Africa/Addis_Ababa', value: 'Africa/Addis_Ababa', text: 'Addis Ababa (GMT+03:00)' },
      { key: 'Africa/Algiers', value: 'Africa/Algiers', text: 'Algiers (GMT+01:00)' },
      { key: 'Africa/Asmara', value: 'Africa/Asmara', text: 'Asmara (GMT+03:00)' },
      { key: 'Africa/Bamako', value: 'Africa/Bamako', text: 'Bamako (GMT)' },
      { key: 'Africa/Bangui', value: 'Africa/Bangui', text: 'Bangui (GMT+01:00)' },
      { key: 'Africa/Banjul', value: 'Africa/Banjul', text: 'Banjul (GMT)' },
      { key: 'Africa/Bissau', value: 'Africa/Bissau', text: 'Bissau (GMT)' },
      { key: 'Africa/Blantyre', value: 'Africa/Blantyre', text: 'Blantyre (GMT+02:00)' },
      { key: 'Africa/Brazzaville', value: 'Africa/Brazzaville', text: 'Brazzaville (GMT+01:00)' },
      { key: 'Africa/Bujumbura', value: 'Africa/Bujumbura', text: 'Bujumbura (GMT+02:00)' },
      { key: 'Africa/Cairo', value: 'Africa/Cairo', text: 'Cairo (GMT+02:00)' },
      { key: 'Africa/Casablanca', value: 'Africa/Casablanca', text: 'Casablanca (GMT)' },
      { key: 'Africa/Ceuta', value: 'Africa/Ceuta', text: 'Ceuta (GMT+01:00)' },
      { key: 'Africa/Conakry', value: 'Africa/Conakry', text: 'Conakry (GMT)' },
      { key: 'Africa/Dakar', value: 'Africa/Dakar', text: 'Dakar (GMT)' },
      { key: 'Africa/Dar_es_Salaam', value: 'Africa/Dar_es_Salaam', text: 'Dar es Salaam (GMT+03:00)' },
      { key: 'Africa/Djibouti', value: 'Africa/Djibouti', text: 'Djibouti (GMT+03:00)' },
      { key: 'Africa/Douala', value: 'Africa/Douala', text: 'Douala (GMT+01:00)' },
      { key: 'Africa/El_Aaiun', value: 'Africa/El_Aaiun', text: 'El Aaiun (GMT)' },
      { key: 'Africa/Freetown', value: 'Africa/Freetown', text: 'Freetown (GMT)' },
      { key: 'Africa/Gaborone', value: 'Africa/Gaborone', text: 'Gaborone (GMT+02:00)' },
      { key: 'Africa/Harare', value: 'Africa/Harare', text: 'Harare (GMT+02:00)' },
      { key: 'Africa/Johannesburg', value: 'Africa/Johannesburg', text: 'Johannesburg (GMT+02:00)' },
      { key: 'Africa/Juba', value: 'Africa/Juba', text: 'Juba (GMT+03:00)' },
      { key: 'Africa/Kampala', value: 'Africa/Kampala', text: 'Kampala (GMT+03:00)' },
      { key: 'Africa/Khartoum', value: 'Africa/Khartoum', text: 'Khartoum (GMT+03:00)' },
      { key: 'Africa/Kigali', value: 'Africa/Kigali', text: 'Kigali (GMT+02:00)' },
      { key: 'Africa/Kinshasa', value: 'Africa/Kinshasa', text: 'Kinshasa (GMT+01:00)' },
      { key: 'Africa/Lagos', value: 'Africa/Lagos', text: 'Lagos (GMT+01:00)' },
      { key: 'Africa/Libreville', value: 'Africa/Libreville', text: 'Libreville (GMT+01:00)' },
      { key: 'Africa/Lome', value: 'Africa/Lome', text: 'Lome (GMT)' },
      { key: 'Africa/Luanda', value: 'Africa/Luanda', text: 'Luanda (GMT+01:00)' },
      { key: 'Africa/Lubumbashi', value: 'Africa/Lubumbashi', text: 'Lubumbashi (GMT+02:00)' },
      { key: 'Africa/Lusaka', value: 'Africa/Lusaka', text: 'Lusaka (GMT+02:00)' },
      { key: 'Africa/Malabo', value: 'Africa/Malabo', text: 'Malabo (GMT+01:00)' },
      { key: 'Africa/Maputo', value: 'Africa/Maputo', text: 'Maputo (GMT+02:00)' },
      { key: 'Africa/Maseru', value: 'Africa/Maseru', text: 'Maseru (GMT+02:00)' },
      { key: 'Africa/Mbabane', value: 'Africa/Mbabane', text: 'Mbabane (GMT+02:00)' },
      { key: 'Africa/Mogadishu', value: 'Africa/Mogadishu', text: 'Mogadishu (GMT+03:00)' },
      { key: 'Africa/Monrovia', value: 'Africa/Monrovia', text: 'Monrovia (GMT)' },
      { key: 'Africa/Nairobi', value: 'Africa/Nairobi', text: 'Nairobi (GMT+03:00)' },
      { key: 'Africa/Ndjamena', value: 'Africa/Ndjamena', text: 'Ndjamena (GMT+01:00)' },
      { key: 'Africa/Niamey', value: 'Africa/Niamey', text: 'Niamey (GMT+01:00)' },
      { key: 'Africa/Nouakchott', value: 'Africa/Nouakchott', text: 'Nouakchott (GMT)' },
      { key: 'Africa/Ouagadougou', value: 'Africa/Ouagadougou', text: 'Ouagadougou (GMT)' },
      { key: 'Africa/Porto', value: 'Africa/Porto', text: 'Porto-Novo (GMT+01:00)' },
      { key: 'Africa/Sao_Tome', value: 'Africa/Sao_Tome', text: 'Sao Tome (GMT)' },
      { key: 'Africa/Tripoli', value: 'Africa/Tripoli', text: 'Tripoli (GMT+02:00)' },
      { key: 'Africa/Tunis', value: 'Africa/Tunis', text: 'Tunis (GMT+01:00)' },
      { key: 'Africa/Windhoek', value: 'Africa/Windhoek', text: 'Windhoek (GMT+02:00)' }
    ]
  },
  {
    text: 'America',
    name: 'America',
    type: 'group',
    options: [
      { key: 'America/Adak', value: 'America/Adak', text: 'Adak (GMT-10:00)' },
      { key: 'America/Anchorage', value: 'America/Anchorage', text: 'Anchorage (GMT-09:00)' },
      { key: 'America/Anguilla', value: 'America/Anguilla', text: 'Anguilla (GMT-04:00)' },
      { key: 'America/Antigua', value: 'America/Antigua', text: 'Antigua (GMT-04:00)' },
      { key: 'America/Araguaina', value: 'America/Araguaina', text: 'Araguaina (GMT-03:00)' },
      { key: 'America/Argentina/Buenos_Aires', value: 'America/Argentina/Buenos_Aires', text: 'Buenos Aires, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Catamarca', value: 'America/Argentina/Catamarca', text: 'Catamarca, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Cordoba', value: 'America/Argentina/Cordoba', text: 'Cordoba, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Jujuy', value: 'America/Argentina/Jujuy', text: 'Jujuy, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/La_Rioja', value: 'America/Argentina/La_Rioja', text: 'La Rioja, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Mendoza', value: 'America/Argentina/Mendoza', text: 'Mendoza, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Rio_Gallegos', value: 'America/Argentina/Rio_Gallegos', text: 'Rio Gallegos, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Salta', value: 'America/Argentina/Salta', text: 'Salta, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/San_Juan', value: 'America/Argentina/San_Juan', text: 'San Juan, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/San_Luis', value: 'America/Argentina/San_Luis', text: 'San Luis, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Tucuman', value: 'America/Argentina/Tucuman', text: 'Tucuman, Argentina (GMT-03:00)' },
      { key: 'America/Argentina/Ushuaia', value: 'America/Argentina/Ushuaia', text: 'Ushuaia, Argentina (GMT-03:00)' },
      { key: 'America/Aruba', value: 'America/Aruba', text: 'Aruba (GMT-04:00)' },
      { key: 'America/Asuncion', value: 'America/Asuncion', text: 'Asuncion (GMT-03:00)' },
      { key: 'America/Atikokan', value: 'America/Atikokan', text: 'Atikokan (GMT-05:00)' },
      { key: 'America/Bahia', value: 'America/Bahia', text: 'Bahia (GMT-02:00)' },
      { key: 'America/Bahia_Banderas', value: 'America/Bahia_Banderas', text: 'Bahia Banderas (GMT-06:00)' },
      { key: 'America/Barbados', value: 'America/Barbados', text: 'Barbados (GMT-04:00)' },
      { key: 'America/Belem', value: 'America/Belem', text: 'Belem (GMT-03:00)' },
      { key: 'America/Belize', value: 'America/Belize', text: 'Belize (GMT-06:00)' },
      { key: 'America/Blanc-Sablon', value: 'America/Blanc-Sablon', text: 'Blanc-Sablon (GMT-04:00)' },
      { key: 'America/Boa_Vista', value: 'America/Boa_Vista', text: 'Boa Vista (GMT-04:00)' },
      { key: 'America/Bogota', value: 'America/Bogota', text: 'Bogota (GMT-05:00)' },
      { key: 'America/Boise', value: 'America/Boise', text: 'Boise (GMT-07:00)' },
      { key: 'America/Cambridge_Bay', value: 'America/Cambridge_Bay', text: 'Cambridge Bay (GMT-07:00)' },
      { key: 'America/Campo_Grande', value: 'America/Campo_Grande', text: 'Campo Grande (GMT-03:00)' },
      { key: 'America/Cancun', value: 'America/Cancun', text: 'Cancun (GMT-06:00)' },
      { key: 'America/Caracas', value: 'America/Caracas', text: 'Caracas (GMT-04:30)' },
      { key: 'America/Cayenne', value: 'America/Cayenne', text: 'Cayenne (GMT-03:00)' },
      { key: 'America/Cayman', value: 'America/Cayman', text: 'Cayman (GMT-05:00)' },
      { key: 'America/Chicago', value: 'America/Chicago', text: 'Chicago (GMT-06:00)' },
      { key: 'America/Chihuahua', value: 'America/Chihuahua', text: 'Chihuahua (GMT-07:00)' },
      { key: 'America/Costa_Rica', value: 'America/Costa_Rica', text: 'Costa Rica (GMT-06:00)' },
      { key: 'America/Creston', value: 'America/Creston', text: 'Creston (GMT-07:00)' },
      { key: 'America/Cuiaba', value: 'America/Cuiaba', text: 'Cuiaba (GMT-03:00)' },
      { key: 'America/Curacao', value: 'America/Curacao', text: 'Curacao (GMT-04:00)' },
      { key: 'America/Danmarkshavn', value: 'America/Danmarkshavn', text: 'Danmarkshavn (GMT)' },
      { key: 'America/Dawson', value: 'America/Dawson', text: 'Dawson (GMT-08:00)' },
      { key: 'America/Dawson_Creek', value: 'America/Dawson_Creek', text: 'Dawson Creek (GMT-07:00)' },
      { key: 'America/Denver', value: 'America/Denver', text: 'Denver (GMT-07:00)' },
      { key: 'America/Detroit', value: 'America/Detroit', text: 'Detroit (GMT-05:00)' },
      { key: 'America/Dominica', value: 'America/Dominica', text: 'Dominica (GMT-04:00)' },
      { key: 'America/Edmonton', value: 'America/Edmonton', text: 'Edmonton (GMT-07:00)' },
      { key: 'America/Eirunepe', value: 'America/Eirunepe', text: 'Eirunepe (GMT-04:00)' },
      { key: 'America/El_Salvador', value: 'America/El_Salvador', text: 'El Salvador (GMT-06:00)' },
      { key: 'America/Fortaleza', value: 'America/Fortaleza', text: 'Fortaleza (GMT-03:00)' },
      { key: 'America/Glace_Bay', value: 'America/Glace_Bay', text: 'Glace Bay (GMT-04:00)' },
      { key: 'America/Godthab', value: 'America/Godthab', text: 'Godthab (GMT-03:00)' },
      { key: 'America/Goose_Bay', value: 'America/Goose_Bay', text: 'Goose Bay (GMT-04:00)' },
      { key: 'America/Grand_Turk', value: 'America/Grand_Turk', text: 'Grand Turk (GMT-05:00)' },
      { key: 'America/Grenada', value: 'America/Grenada', text: 'Grenada (GMT-04:00)' },
      { key: 'America/Guadeloupe', value: 'America/Guadeloupe', text: 'Guadeloupe (GMT-04:00)' },
      { key: 'America/Guatemala', value: 'America/Guatemala', text: 'Guatemala (GMT-06:00)' },
      { key: 'America/Guayaquil', value: 'America/Guayaquil', text: 'Guayaquil (GMT-05:00)' },
      { key: 'America/Guyana', value: 'America/Guyana', text: 'Guyana (GMT-04:00)' },
      { key: 'America/Halifax', value: 'America/Halifax', text: 'Halifax (GMT-04:00)' },
      { key: 'America/Havana', value: 'America/Havana', text: 'Havana (GMT-05:00)' },
      { key: 'America/Hermosillo', value: 'America/Hermosillo', text: 'Hermosillo (GMT-07:00)' },
      { key: 'America/Indiana/Indianapolis', value: 'America/Indiana/Indianapolis', text: 'Indianapolis, Indiana (GMT-05:00)' },
      { key: 'America/Indiana/Knox', value: 'America/Indiana/Knox', text: 'Knox, Indiana (GMT-06:00)' },
      { key: 'America/Indiana/Marengo', value: 'America/Indiana/Marengo', text: 'Marengo, Indiana (GMT-05:00)' },
      { key: 'America/Indiana/Petersburg', value: 'America/Indiana/Petersburg', text: 'Petersburg, Indiana (GMT-05:00)' },
      { key: 'America/Indiana/Tell_City', value: 'America/Indiana/Tell_City', text: 'Tell City, Indiana (GMT-06:00)' },
      { key: 'America/Indiana/Vevay', value: 'America/Indiana/Vevay', text: 'Vevay, Indiana (GMT-05:00)' },
      { key: 'America/Indiana/Vincennes', value: 'America/Indiana/Vincennes', text: 'Vincennes, Indiana (GMT-05:00)' },
      { key: 'America/Indiana/Winamac', value: 'America/Indiana/Winamac', text: 'Winamac, Indiana (GMT-05:00)' },
      { key: 'America/Inuvik', value: 'America/Inuvik', text: 'Inuvik (GMT-07:00)' },
      { key: 'America/Iqaluit', value: 'America/Iqaluit', text: 'Iqaluit (GMT-05:00)' },
      { key: 'America/Jamaica', value: 'America/Jamaica', text: 'Jamaica (GMT-05:00)' },
      { key: 'America/Juneau', value: 'America/Juneau', text: 'Juneau (GMT-09:00)' },
      { key: 'America/Kentucky/Louisville', value: 'America/Kentucky/Louisville', text: 'Louisville, Kentucky (GMT-05:00)' },
      { key: 'America/Kentucky/Monticello', value: 'America/Kentucky/Monticello', text: 'Monticello, Kentucky (GMT-05:00)' },
      { key: 'America/Kralendijk', value: 'America/Kralendijk', text: 'Kralendijk (GMT-04:00)' },
      { key: 'America/La_Paz', value: 'America/La_Paz', text: 'La Paz (GMT-04:00)' },
      { key: 'America/Lima', value: 'America/Lima', text: 'Lima (GMT-05:00)' },
      { key: 'America/Los_Angeles', value: 'America/Los_Angeles', text: 'Los Angeles (GMT-08:00)' },
      { key: 'America/Lower_Princes', value: 'America/Lower_Princes', text: 'Lower Princes (GMT-04:00)' },
      { key: 'America/Maceio', value: 'America/Maceio', text: 'Maceio (GMT-03:00)' },
      { key: 'America/Managua', value: 'America/Managua', text: 'Managua (GMT-06:00)' },
      { key: 'America/Manaus', value: 'America/Manaus', text: 'Manaus (GMT-04:00)' },
      { key: 'America/Marigot', value: 'America/Marigot', text: 'Marigot (GMT-04:00)' },
      { key: 'America/Martinique', value: 'America/Martinique', text: 'Martinique (GMT-04:00)' },
      { key: 'America/Matamoros', value: 'America/Matamoros', text: 'Matamoros (GMT-06:00)' },
      { key: 'America/Mazatlan', value: 'America/Mazatlan', text: 'Mazatlan (GMT-07:00)' },
      { key: 'America/Menominee', value: 'America/Menominee', text: 'Menominee (GMT-06:00)' },
      { key: 'America/Merida', value: 'America/Merida', text: 'Merida (GMT-06:00)' },
      { key: 'America/Metlakatla', value: 'America/Metlakatla', text: 'Metlakatla (GMT-08:00)' },
      { key: 'America/Mexico_City', value: 'America/Mexico_City', text: 'Mexico City (GMT-06:00)' },
      { key: 'America/Miquelon', value: 'America/Miquelon', text: 'Miquelon (GMT-03:00)' },
      { key: 'America/Moncton', value: 'America/Moncton', text: 'Moncton (GMT-04:00)' },
      { key: 'America/Monterrey', value: 'America/Monterrey', text: 'Monterrey (GMT-06:00)' },
      { key: 'America/Montevideo', value: 'America/Montevideo', text: 'Montevideo (GMT-02:00)' },
      { key: 'America/Montreal', value: 'America/Montreal', text: 'Montreal (GMT-05:00)' },
      { key: 'America/Montserrat', value: 'America/Montserrat', text: 'Montserrat (GMT-04:00)' },
      { key: 'America/Nassau', value: 'America/Nassau', text: 'Nassau (GMT-05:00)' },
      { key: 'America/New_York', value: 'America/New_York', text: 'New York (GMT-05:00)' },
      { key: 'America/Nipigon', value: 'America/Nipigon', text: 'Nipigon (GMT-05:00)' },
      { key: 'America/Nome', value: 'America/Nome', text: 'Nome (GMT-09:00)' },
      { key: 'America/Noronha', value: 'America/Noronha', text: 'Noronha (GMT-02:00)' },
      { key: 'America/North_Dakota/Beulah', value: 'America/North_Dakota/Beulah', text: 'Beulah, North Dakota (GMT-06:00)' },
      { key: 'America/North_Dakota/Center', value: 'America/North_Dakota/Center', text: 'Center, North Dakota (GMT-06:00)' },
      { key: 'America/North_Dakota/New_Salem', value: 'America/North_Dakota/New_Salem', text: 'New Salem, North Dakota (GMT-06:00)' },
      { key: 'America/Ojinaga', value: 'America/Ojinaga', text: 'Ojinaga (GMT-07:00)' },
      { key: 'America/Panama', value: 'America/Panama', text: 'Panama (GMT-05:00)' },
      { key: 'America/Pangnirtung', value: 'America/Pangnirtung', text: 'Pangnirtung (GMT-05:00)' },
      { key: 'America/Paramaribo', value: 'America/Paramaribo', text: 'Paramaribo (GMT-03:00)' },
      { key: 'America/Phoenix', value: 'America/Phoenix', text: 'Phoenix (GMT-07:00)' },
      { key: 'America/Port-au-Prince', value: 'America/Port-au-Prince', text: 'Port-au-Prince (GMT-05:00)' },
      { key: 'America/Port_of_Spain', value: 'America/Port_of_Spain', text: 'Port of Spain (GMT-04:00)' },
      { key: 'America/Porto_Velho', value: 'America/Porto_Velho', text: 'Porto Velho (GMT-04:00)' },
      { key: 'America/Puerto_Rico', value: 'America/Puerto_Rico', text: 'Puerto Rico (GMT-04:00)' },
      { key: 'America/Rainy_River', value: 'America/Rainy_River', text: 'Rainy River (GMT-06:00)' },
      { key: 'America/Rankin_Inlet', value: 'America/Rankin_Inlet', text: 'Rankin Inlet (GMT-06:00)' },
      { key: 'America/Recife', value: 'America/Recife', text: 'Recife (GMT-03:00)' },
      { key: 'America/Regina', value: 'America/Regina', text: 'Regina (GMT-06:00)' },
      { key: 'America/Resolute', value: 'America/Resolute', text: 'Resolute (GMT-06:00)' },
      { key: 'America/Rio_Branco', value: 'America/Rio_Branco', text: 'Rio Branco (GMT-04:00)' },
      { key: 'America/Santa_Isabel', value: 'America/Santa_Isabel', text: 'Santa Isabel (GMT-08:00)' },
      { key: 'America/Santarem', value: 'America/Santarem', text: 'Santarem (GMT-03:00)' },
      { key: 'America/Santiago', value: 'America/Santiago', text: 'Santiago (GMT-03:00)' },
      { key: 'America/Santo_Domingo', value: 'America/Santo_Domingo', text: 'Santo Domingo (GMT-04:00)' },
      { key: 'America/Sao_Paulo', value: 'America/Sao_Paulo', text: 'Sao Paulo (GMT-03:00)' },
      { key: 'America/Scoresbysund', value: 'America/Scoresbysund', text: 'Scoresbysund (GMT-01:00)' },
      { key: 'America/Shiprock', value: 'America/Shiprock', text: 'Shiprock (GMT-07:00)' },
      { key: 'America/Sitka', value: 'America/Sitka', text: 'Sitka (GMT-09:00)' },
      { key: 'America/St_Barthelemy', value: 'America/St_Barthelemy', text: 'St Barthelemy (GMT-04:00)' },
      { key: 'America/St_Johns', value: 'America/St_Johns', text: 'St Johns (GMT-03:30)' },
      { key: 'America/St_Kitts', value: 'America/St_Kitts', text: 'St Kitts (GMT-04:00)' },
      { key: 'America/St_Lucia', value: 'America/St_Lucia', text: 'St Lucia (GMT-04:00)' },
      { key: 'America/St_Thomas', value: 'America/St_Thomas', text: 'St Thomas (GMT-04:00)' },
      { key: 'America/St_Vincent', value: 'America/St_Vincent', text: 'St Vincent (GMT-04:00)' },
      { key: 'America/Swift_Current', value: 'America/Swift_Current', text: 'Swift Current (GMT-06:00)' },
      { key: 'America/Tegucigalpa', value: 'America/Tegucigalpa', text: 'Tegucigalpa (GMT-06:00)' },
      { key: 'America/Thule', value: 'America/Thule', text: 'Thule (GMT-04:00)' },
      { key: 'America/Thunder_Bay', value: 'America/Thunder_Bay', text: 'Thunder Bay (GMT-05:00)' },
      { key: 'America/Tijuana', value: 'America/Tijuana', text: 'Tijuana (GMT-08:00)' },
      { key: 'America/Toronto', value: 'America/Toronto', text: 'Toronto (GMT-05:00)' },
      { key: 'America/Tortola', value: 'America/Tortola', text: 'Tortola (GMT-04:00)' },
      { key: 'America/Vancouver', value: 'America/Vancouver', text: 'Vancouver (GMT-08:00)' },
      { key: 'America/Whitehorse', value: 'America/Whitehorse', text: 'Whitehorse (GMT-08:00)' },
      { key: 'America/Winnipeg', value: 'America/Winnipeg', text: 'Winnipeg (GMT-06:00)' },
      { key: 'America/Yakutat', value: 'America/Yakutat', text: 'Yakutat (GMT-09:00)' },
      { key: 'America/Yellowknife', value: 'America/Yellowknife', text: 'Yellowknife (GMT-07:00)' }
    ]
  },
  {
    text: 'Antarctica',
    name: 'Antarctica',
    type: 'group',
    options: [
      { key: 'Antarctica/Casey', value: 'Antarctica/Casey', text: 'Casey (GMT+08:00)' },
      { key: 'Antarctica/Davis', value: 'Antarctica/Davis', text: 'Davis (GMT+07:00)' },
      { key: 'Antarctica/DumontDUrville', value: 'Antarctica/DumontDUrville', text: 'DumontDUrville (GMT+10:00)' },
      { key: 'Antarctica/Macquarie', value: 'Antarctica/Macquarie', text: 'Macquarie (GMT+11:00)' },
      { key: 'Antarctica/Mawson', value: 'Antarctica/Mawson', text: 'Mawson (GMT+05:00)' },
      { key: 'Antarctica/McMurdo', value: 'Antarctica/McMurdo', text: 'McMurdo (GMT+13:00)' },
      { key: 'Antarctica/Palmer', value: 'Antarctica/Palmer', text: 'Palmer (GMT-03:00)' },
      { key: 'Antarctica/Rothera', value: 'Antarctica/Rothera', text: 'Rothera (GMT-03:00)' },
      { key: 'Antarctica/South_Pole', value: 'Antarctica/South_Pole', text: 'South Pole (GMT+13:00)' },
      { key: 'Antarctica/Syowa', value: 'Antarctica/Syowa', text: 'Syowa (GMT+03:00)' },
      { key: 'Antarctica/Vostok', value: 'Antarctica/Vostok', text: 'Vostok (GMT+06:00)' }
    ]
  },
  {
    text: 'Arctic',
    name: 'Arctic',
    type: 'group',
    options: [
      { key: 'Arctic/Longyearbyen', value: 'Arctic/Longyearbyen', text: 'Longyearbyen (GMT+01:00)' }
    ]
  },
  {
    text: 'Asia',
    name: 'Asia',
    type: 'group',
    options: [
      { key: 'Asia/Aden', value: 'Asia/Aden', text: 'Aden (GMT+03:00)' },
      { key: 'Asia/Almaty', value: 'Asia/Almaty', text: 'Almaty (GMT+06:00)' },
      { key: 'Asia/Amman', value: 'Asia/Amman', text: 'Amman (GMT+02:00)' },
      { key: 'Asia/Anadyr', value: 'Asia/Anadyr', text: 'Anadyr (GMT+12:00)' },
      { key: 'Asia/Aqtau', value: 'Asia/Aqtau', text: 'Aqtau (GMT+05:00)' },
      { key: 'Asia/Aqtobe', value: 'Asia/Aqtobe', text: 'Aqtobe (GMT+05:00)' },
      { key: 'Asia/Ashgabat', value: 'Asia/Ashgabat', text: 'Ashgabat (GMT+05:00)' },
      { key: 'Asia/Baghdad', value: 'Asia/Baghdad', text: 'Baghdad (GMT+03:00)' },
      { key: 'Asia/Bahrain', value: 'Asia/Bahrain', text: 'Bahrain (GMT+03:00)' },
      { key: 'Asia/Baku', value: 'Asia/Baku', text: 'Baku (GMT+04:00)' },
      { key: 'Asia/Bangkok', value: 'Asia/Bangkok', text: 'Bangkok (GMT+07:00)' },
      { key: 'Asia/Beirut', value: 'Asia/Beirut', text: 'Beirut (GMT+02:00)' },
      { key: 'Asia/Bishkek', value: 'Asia/Bishkek', text: 'Bishkek (GMT+06:00)' },
      { key: 'Asia/Brunei', value: 'Asia/Brunei', text: 'Brunei (GMT+08:00)' },
      { key: 'Asia/Choibalsan', value: 'Asia/Choibalsan', text: 'Choibalsan (GMT+08:00)' },
      { key: 'Asia/Chongqing', value: 'Asia/Chongqing', text: 'Chongqing (GMT+08:00)' },
      { key: 'Asia/Colombo', value: 'Asia/Colombo', text: 'Colombo (GMT+05:30)' },
      { key: 'Asia/Damascus', value: 'Asia/Damascus', text: 'Damascus (GMT+02:00)' },
      { key: 'Asia/Dhaka', value: 'Asia/Dhaka', text: 'Dhaka (GMT+06:00)' },
      { key: 'Asia/Dili', value: 'Asia/Dili', text: 'Dili (GMT+09:00)' },
      { key: 'Asia/Dubai', value: 'Asia/Dubai', text: 'Dubai (GMT+04:00)' },
      { key: 'Asia/Dushanbe', value: 'Asia/Dushanbe', text: 'Dushanbe (GMT+05:00)' },
      { key: 'Asia/Gaza', value: 'Asia/Gaza', text: 'Gaza (GMT+02:00)' },
      { key: 'Asia/Harbin', value: 'Asia/Harbin', text: 'Harbin (GMT+08:00)' },
      { key: 'Asia/Hebron', value: 'Asia/Hebron', text: 'Hebron (GMT+02:00)' },
      { key: 'Asia/Ho_Chi_Minh', value: 'Asia/Ho_Chi_Minh', text: 'Ho Chi Minh (GMT+07:00)' },
      { key: 'Asia/Hong_Kong', value: 'Asia/Hong_Kong', text: 'Hong Kong (GMT+08:00)' },
      { key: 'Asia/Hovd', value: 'Asia/Hovd', text: 'Hovd (GMT+07:00)' },
      { key: 'Asia/Irkutsk', value: 'Asia/Irkutsk', text: 'Irkutsk (GMT+09:00)' },
      { key: 'Asia/Jakarta', value: 'Asia/Jakarta', text: 'Jakarta (GMT+07:00)' },
      { key: 'Asia/Jayapura', value: 'Asia/Jayapura', text: 'Jayapura (GMT+09:00)' },
      { key: 'Asia/Jerusalem', value: 'Asia/Jerusalem', text: 'Jerusalem (GMT+02:00)' },
      { key: 'Asia/Kabul', value: 'Asia/Kabul', text: 'Kabul (GMT+04:30)' },
      { key: 'Asia/Kamchatka', value: 'Asia/Kamchatka', text: 'Kamchatka (GMT+12:00)' },
      { key: 'Asia/Karachi', value: 'Asia/Karachi', text: 'Karachi (GMT+05:00)' },
      { key: 'Asia/Kashgar', value: 'Asia/Kashgar', text: 'Kashgar (GMT+08:00)' },
      { key: 'Asia/Kathmandu', value: 'Asia/Kathmandu', text: 'Kathmandu (GMT+05:45)' },
      { key: 'Asia/Kolkata', value: 'Asia/Kolkata', text: 'Kolkata (GMT+05:30)' },
      { key: 'Asia/Krasnoyarsk', value: 'Asia/Krasnoyarsk', text: 'Krasnoyarsk (GMT+08:00)' },
      { key: 'Asia/Kuala_Lumpur', value: 'Asia/Kuala_Lumpur', text: 'Kuala Lumpur (GMT+08:00)' },
      { key: 'Asia/Kuching', value: 'Asia/Kuching', text: 'Kuching (GMT+08:00)' },
      { key: 'Asia/Kuwait', value: 'Asia/Kuwait', text: 'Kuwait (GMT+03:00)' },
      { key: 'Asia/Macau', value: 'Asia/Macau', text: 'Macau (GMT+08:00)' },
      { key: 'Asia/Magadan', value: 'Asia/Magadan', text: 'Magadan (GMT+12:00)' },
      { key: 'Asia/Makassar', value: 'Asia/Makassar', text: 'Makassar (GMT+08:00)' },
      { key: 'Asia/Manila', value: 'Asia/Manila', text: 'Manila (GMT+08:00)' },
      { key: 'Asia/Muscat', value: 'Asia/Muscat', text: 'Muscat (GMT+04:00)' },
      { key: 'Asia/Nicosia', value: 'Asia/Nicosia', text: 'Nicosia (GMT+02:00)' },
      { key: 'Asia/Novokuznetsk', value: 'Asia/Novokuznetsk', text: 'Novokuznetsk (GMT+07:00)' },
      { key: 'Asia/Novosibirsk', value: 'Asia/Novosibirsk', text: 'Novosibirsk (GMT+07:00)' },
      { key: 'Asia/Omsk', value: 'Asia/Omsk', text: 'Omsk (GMT+07:00)' },
      { key: 'Asia/Oral', value: 'Asia/Oral', text: 'Oral (GMT+05:00)' },
      { key: 'Asia/Phnom_Penh', value: 'Asia/Phnom_Penh', text: 'Phnom Penh (GMT+07:00)' },
      { key: 'Asia/Pontianak', value: 'Asia/Pontianak', text: 'Pontianak (GMT+07:00)' },
      { key: 'Asia/Pyongyang', value: 'Asia/Pyongyang', text: 'Pyongyang (GMT+09:00)' },
      { key: 'Asia/Qatar', value: 'Asia/Qatar', text: 'Qatar (GMT+03:00)' },
      { key: 'Asia/Qyzylorda', value: 'Asia/Qyzylorda', text: 'Qyzylorda (GMT+06:00)' },
      { key: 'Asia/Rangoon', value: 'Asia/Rangoon', text: 'Rangoon (GMT+06:30)' },
      { key: 'Asia/Riyadh', value: 'Asia/Riyadh', text: 'Riyadh (GMT+03:00)' },
      { key: 'Asia/Sakhalin', value: 'Asia/Sakhalin', text: 'Sakhalin (GMT+11:00)' },
      { key: 'Asia/Samarkand', value: 'Asia/Samarkand', text: 'Samarkand (GMT+05:00)' },
      { key: 'Asia/Seoul', value: 'Asia/Seoul', text: 'Seoul (GMT+09:00)' },
      { key: 'Asia/Shanghai', value: 'Asia/Shanghai', text: 'Shanghai (GMT+08:00)' },
      { key: 'Asia/Singapore', value: 'Asia/Singapore', text: 'Singapore (GMT+08:00)' },
      { key: 'Asia/Taipei', value: 'Asia/Taipei', text: 'Taipei (GMT+08:00)' },
      { key: 'Asia/Tashkent', value: 'Asia/Tashkent', text: 'Tashkent (GMT+05:00)' },
      { key: 'Asia/Tbilisi', value: 'Asia/Tbilisi', text: 'Tbilisi (GMT+04:00)' },
      { key: 'Asia/Tehran', value: 'Asia/Tehran', text: 'Tehran (GMT+03:30)' },
      { key: 'Asia/Thimphu', value: 'Asia/Thimphu', text: 'Thimphu (GMT+06:00)' },
      { key: 'Asia/Tokyo', value: 'Asia/Tokyo', text: 'Tokyo (GMT+09:00)' },
      { key: 'Asia/Ulaanbaatar', value: 'Asia/Ulaanbaatar', text: 'Ulaanbaatar (GMT+08:00)' },
      { key: 'Asia/Urumqi', value: 'Asia/Urumqi', text: 'Urumqi (GMT+08:00)' },
      { key: 'Asia/Vientiane', value: 'Asia/Vientiane', text: 'Vientiane (GMT+07:00)' },
      { key: 'Asia/Vladivostok', value: 'Asia/Vladivostok', text: 'Vladivostok (GMT+11:00)' },
      { key: 'Asia/Yakutsk', value: 'Asia/Yakutsk', text: 'Yakutsk (GMT+10:00)' },
      { key: 'Asia/Yekaterinburg', value: 'Asia/Yekaterinburg', text: 'Yekaterinburg (GMT+06:00)' },
      { key: 'Asia/Yerevan', value: 'Asia/Yerevan', text: 'Yerevan (GMT+04:00)' }
    ]
  },
  {
    text: 'Atlantic',
    name: 'Atlantic',
    type: 'group',
    options: [
      { key: 'Atlantic/Azores', value: 'Atlantic/Azores', text: 'Azores (GMT-01:00)' },
      { key: 'Atlantic/Bermuda', value: 'Atlantic/Bermuda', text: 'Bermuda (GMT-04:00)' },
      { key: 'Atlantic/Canary', value: 'Atlantic/Canary', text: 'Canary (GMT)' },
      { key: 'Atlantic/Cape_Verde', value: 'Atlantic/Cape_Verde', text: 'Cape Verde (GMT-01:00)' },
      { key: 'Atlantic/Faroe', value: 'Atlantic/Faroe', text: 'Faroe (GMT)' },
      { key: 'Atlantic/Madeira', value: 'Atlantic/Madeira', text: 'Madeira (GMT)' },
      { key: 'Atlantic/Reykjavik', value: 'Atlantic/Reykjavik', text: 'Reykjavik (GMT)' },
      { key: 'Atlantic/South_Georgia', value: 'Atlantic/South_Georgia', text: 'South Georgia (GMT-02:00)' },
      { key: 'Atlantic/St_Helena', value: 'Atlantic/St_Helena', text: 'St Helena (GMT)' },
      { key: 'Atlantic/Stanley', value: 'Atlantic/Stanley', text: 'Stanley (GMT-03:00)' }
    ]
  },
  {
    text: 'Australia',
    name: 'Australia',
    type: 'group',
    options: [
      { key: 'Australia/Adelaide', value: 'Australia/Adelaide', text: 'Adelaide (GMT+10:30)' },
      { key: 'Australia/Brisbane', value: 'Australia/Brisbane', text: 'Brisbane (GMT+10:00)' },
      { key: 'Australia/Broken_Hill', value: 'Australia/Broken_Hill', text: 'Broken Hill (GMT+10:30)' },
      { key: 'Australia/Currie', value: 'Australia/Currie', text: 'Currie (GMT+11:00)' },
      { key: 'Australia/Darwin', value: 'Australia/Darwin', text: 'Darwin (GMT+09:30)' },
      { key: 'Australia/Eucla', value: 'Australia/Eucla', text: 'Eucla (GMT+08:45)' },
      { key: 'Australia/Hobart', value: 'Australia/Hobart', text: 'Hobart (GMT+11:00)' },
      { key: 'Australia/Lindeman', value: 'Australia/Lindeman', text: 'Lindeman (GMT+10:00)' },
      { key: 'Australia/Lord_Howe', value: 'Australia/Lord_Howe', text: 'Lord Howe (GMT+11:00)' },
      { key: 'Australia/Melbourne', value: 'Australia/Melbourne', text: 'Melbourne (GMT+11:00)' },
      { key: 'Australia/Perth', value: 'Australia/Perth', text: 'Perth (GMT+08:00)' },
      { key: 'Australia/Sydney', value: 'Australia/Sydney', text: 'Sydney (GMT+11:00)' }
    ]
  },
  {
    text: 'Europe',
    name: 'Europe',
    type: 'group',
    options: [
      { key: 'Europe/Amsterdam', value: 'Europe/Amsterdam', text: 'Amsterdam (GMT+01:00)' },
      { key: 'Europe/Andorra', value: 'Europe/Andorra', text: 'Andorra (GMT+01:00)' },
      { key: 'Europe/Athens', value: 'Europe/Athens', text: 'Athens (GMT+02:00)' },
      { key: 'Europe/Belgrade', value: 'Europe/Belgrade', text: 'Belgrade (GMT+01:00)' },
      { key: 'Europe/Berlin', value: 'Europe/Berlin', text: 'Berlin (GMT+01:00)' },
      { key: 'Europe/Bratislava', value: 'Europe/Bratislava', text: 'Bratislava (GMT+01:00)' },
      { key: 'Europe/Brussels', value: 'Europe/Brussels', text: 'Brussels (GMT+01:00)' },
      { key: 'Europe/Bucharest', value: 'Europe/Bucharest', text: 'Bucharest (GMT+02:00)' },
      { key: 'Europe/Budapest', value: 'Europe/Budapest', text: 'Budapest (GMT+01:00)' },
      { key: 'Europe/Chisinau', value: 'Europe/Chisinau', text: 'Chisinau (GMT+02:00)' },
      { key: 'Europe/Copenhagen', value: 'Europe/Copenhagen', text: 'Copenhagen (GMT+01:00)' },
      { key: 'Europe/Dublin', value: 'Europe/Dublin', text: 'Dublin (GMT)' },
      { key: 'Europe/Gibraltar', value: 'Europe/Gibraltar', text: 'Gibraltar (GMT+01:00)' },
      { key: 'Europe/Guernsey', value: 'Europe/Guernsey', text: 'Guernsey (GMT)' },
      { key: 'Europe/Helsinki', value: 'Europe/Helsinki', text: 'Helsinki (GMT+02:00)' },
      { key: 'Europe/Isle_of_Man', value: 'Europe/Isle_of_Man', text: 'Isle of Man (GMT)' },
      { key: 'Europe/Istanbul', value: 'Europe/Istanbul', text: 'Istanbul (GMT+03:00)' },
      { key: 'Europe/Jersey', value: 'Europe/Jersey', text: 'Jersey (GMT)' },
      { key: 'Europe/Kaliningrad', value: 'Europe/Kaliningrad', text: 'Kaliningrad (GMT+03:00)' },
      { key: 'Europe/Kiev', value: 'Europe/Kiev', text: 'Kiev (GMT+02:00)' },
      { key: 'Europe/Lisbon', value: 'Europe/Lisbon', text: 'Lisbon (GMT)' },
      { key: 'Europe/Ljubljana', value: 'Europe/Ljubljana', text: 'Ljubljana (GMT+01:00)' },
      { key: 'Europe/London', value: 'Europe/London', text: 'London (GMT)' },
      { key: 'Europe/Luxembourg', value: 'Europe/Luxembourg', text: 'Luxembourg (GMT+01:00)' },
      { key: 'Europe/Madrid', value: 'Europe/Madrid', text: 'Madrid (GMT+01:00)' },
      { key: 'Europe/Malta', value: 'Europe/Malta', text: 'Malta (GMT+01:00)' },
      { key: 'Europe/Mariehamn', value: 'Europe/Mariehamn', text: 'Mariehamn (GMT+02:00)' },
      { key: 'Europe/Minsk', value: 'Europe/Minsk', text: 'Minsk (GMT+03:00)' },
      { key: 'Europe/Monaco', value: 'Europe/Monaco', text: 'Monaco (GMT+01:00)' },
      { key: 'Europe/Moscow', value: 'Europe/Moscow', text: 'Moscow (GMT+03:00)' },
      { key: 'Europe/Oslo', value: 'Europe/Oslo', text: 'Oslo (GMT+01:00)' },
      { key: 'Europe/Paris', value: 'Europe/Paris', text: 'Paris (GMT+01:00)' },
      { key: 'Europe/Podgorica', value: 'Europe/Podgorica', text: 'Podgorica (GMT+01:00)' },
      { key: 'Europe/Prague', value: 'Europe/Prague', text: 'Prague (GMT+01:00)' },
      { key: 'Europe/Riga', value: 'Europe/Riga', text: 'Riga (GMT+02:00)' },
      { key: 'Europe/Rome', value: 'Europe/Rome', text: 'Rome (GMT+01:00)' },
      { key: 'Europe/Samara', value: 'Europe/Samara', text: 'Samara (GMT+04:00)' },
      { key: 'Europe/San_Marino', value: 'Europe/San_Marino', text: 'San Marino (GMT+01:00)' },
      { key: 'Europe/Sarajevo', value: 'Europe/Sarajevo', text: 'Sarajevo (GMT+01:00)' },
      { key: 'Europe/Simferopol', value: 'Europe/Simferopol', text: 'Simferopol (GMT+02:00)' },
      { key: 'Europe/Skopje', value: 'Europe/Skopje', text: 'Skopje (GMT+01:00)' },
      { key: 'Europe/Sofia', value: 'Europe/Sofia', text: 'Sofia (GMT+02:00)' },
      { key: 'Europe/Stockholm', value: 'Europe/Stockholm', text: 'Stockholm (GMT+01:00)' },
      { key: 'Europe/Tallinn', value: 'Europe/Tallinn', text: 'Tallinn (GMT+02:00)' },
      { key: 'Europe/Tirane', value: 'Europe/Tirane', text: 'Tirane (GMT+01:00)' },
      { key: 'Europe/Uzhgorod', value: 'Europe/Uzhgorod', text: 'Uzhgorod (GMT+02:00)' },
      { key: 'Europe/Vaduz', value: 'Europe/Vaduz', text: 'Vaduz (GMT+01:00)' },
      { key: 'Europe/Vatican', value: 'Europe/Vatican', text: 'Vatican (GMT+01:00)' },
      { key: 'Europe/Vienna', value: 'Europe/Vienna', text: 'Vienna (GMT+01:00)' },
      { key: 'Europe/Vilnius', value: 'Europe/Vilnius', text: 'Vilnius (GMT+02:00)' },
      { key: 'Europe/Volgograd', value: 'Europe/Volgograd', text: 'Volgograd (GMT+04:00)' },
      { key: 'Europe/Warsaw', value: 'Europe/Warsaw', text: 'Warsaw (GMT+01:00)' },
      { key: 'Europe/Zagreb', value: 'Europe/Zagreb', text: 'Zagreb (GMT+01:00)' },
      { key: 'Europe/Zaporozhye', value: 'Europe/Zaporozhye', text: 'Zaporozhye (GMT+02:00)' },
      { key: 'Europe/Zurich', value: 'Europe/Zurich', text: 'Zurich (GMT+01:00)' }
    ]
  },
  {
    text: 'Indian',
    name: 'Indian',
    type: 'group',
    options: [
      { key: 'Indian/Antananarivo', value: 'Indian/Antananarivo', text: 'Antananarivo (GMT+03:00)' },
      { key: 'Indian/Chagos', value: 'Indian/Chagos', text: 'Chagos (GMT+06:00)' },
      { key: 'Indian/Christmas', value: 'Indian/Christmas', text: 'Christmas (GMT+07:00)' },
      { key: 'Indian/Cocos', value: 'Indian/Cocos', text: 'Cocos (GMT+06:30)' },
      { key: 'Indian/Comoro', value: 'Indian/Comoro', text: 'Comoro (GMT+03:00)' },
      { key: 'Indian/Kerguelen', value: 'Indian/Kerguelen', text: 'Kerguelen (GMT+05:00)' },
      { key: 'Indian/Mahe', value: 'Indian/Mahe', text: 'Mahe (GMT+04:00)' },
      { key: 'Indian/Maldives', value: 'Indian/Maldives', text: 'Maldives (GMT+05:00)' },
      { key: 'Indian/Mauritius', value: 'Indian/Mauritius', text: 'Mauritius (GMT+04:00)' },
      { key: 'Indian/Mayotte', value: 'Indian/Mayotte', text: 'Mayotte (GMT+03:00)' },
      { key: 'Indian/Reunion', value: 'Indian/Reunion', text: 'Reunion (GMT+04:00)' }
    ]
  },
  {
    text: 'Pacific',
    name: 'Pacific',
    type: 'group',
    options: [
      { key: 'Pacific/Apia', value: 'Pacific/Apia', text: 'Apia (GMT+13:00)' },
      { key: 'Pacific/Auckland', value: 'Pacific/Auckland', text: 'Auckland (GMT+13:00)' },
      { key: 'Pacific/Chatham', value: 'Pacific/Chatham', text: 'Chatham (GMT+13:45)' },
      { key: 'Pacific/Chuuk', value: 'Pacific/Chuuk', text: 'Chuuk (GMT+10:00)' },
      { key: 'Pacific/Easter', value: 'Pacific/Easter', text: 'Easter (GMT-05:00)' },
      { key: 'Pacific/Efate', value: 'Pacific/Efate', text: 'Efate (GMT+11:00)' },
      { key: 'Pacific/Enderbury', value: 'Pacific/Enderbury', text: 'Enderbury (GMT+13:00)' },
      { key: 'Pacific/Fakaofo', value: 'Pacific/Fakaofo', text: 'Fakaofo (GMT+13:00)' },
      { key: 'Pacific/Fiji', value: 'Pacific/Fiji', text: 'Fiji (GMT+12:00)' },
      { key: 'Pacific/Funafuti', value: 'Pacific/Funafuti', text: 'Funafuti (GMT+12:00)' },
      { key: 'Pacific/Galapagos', value: 'Pacific/Galapagos', text: 'Galapagos (GMT-06:00)' },
      { key: 'Pacific/Gambier', value: 'Pacific/Gambier', text: 'Gambier (GMT-09:00)' },
      { key: 'Pacific/Guadalcanal', value: 'Pacific/Guadalcanal', text: 'Guadalcanal (GMT+11:00)' },
      { key: 'Pacific/Guam', value: 'Pacific/Guam', text: 'Guam (GMT+10:00)' },
      { key: 'Pacific/Honolulu', value: 'Pacific/Honolulu', text: 'Honolulu (GMT-10:00)' },
      { key: 'Pacific/Johnston', value: 'Pacific/Johnston', text: 'Johnston (GMT-10:00)' },
      { key: 'Pacific/Kiritimati', value: 'Pacific/Kiritimati', text: 'Kiritimati (GMT+14:00)' },
      { key: 'Pacific/Kosrae', value: 'Pacific/Kosrae', text: 'Kosrae (GMT+11:00)' },
      { key: 'Pacific/Kwajalein', value: 'Pacific/Kwajalein', text: 'Kwajalein (GMT+12:00)' },
      { key: 'Pacific/Majuro', value: 'Pacific/Majuro', text: 'Majuro (GMT+12:00)' },
      { key: 'Pacific/Marquesas', value: 'Pacific/Marquesas', text: 'Marquesas (GMT-09:30)' },
      { key: 'Pacific/Midway', value: 'Pacific/Midway', text: 'Midway (GMT-11:00)' },
      { key: 'Pacific/Nauru', value: 'Pacific/Nauru', text: 'Nauru (GMT+12:00)' },
      { key: 'Pacific/Niue', value: 'Pacific/Niue', text: 'Niue (GMT-11:00)' },
      { key: 'Pacific/Norfolk', value: 'Pacific/Norfolk', text: 'Norfolk (GMT+11:30)' },
      { key: 'Pacific/Noumea', value: 'Pacific/Noumea', text: 'Noumea (GMT+11:00)' },
      { key: 'Pacific/Pago_Pago', value: 'Pacific/Pago_Pago', text: 'Pago Pago (GMT-11:00)' },
      { key: 'Pacific/Palau', value: 'Pacific/Palau', text: 'Palau (GMT+09:00)' },
      { key: 'Pacific/Pitcairn', value: 'Pacific/Pitcairn', text: 'Pitcairn (GMT-08:00)' },
      { key: 'Pacific/Pohnpei', value: 'Pacific/Pohnpei', text: 'Pohnpei (GMT+11:00)' },
      { key: 'Pacific/Port_Moresby', value: 'Pacific/Port_Moresby', text: 'Port Moresby (GMT+10:00)' },
      { key: 'Pacific/Rarotonga', value: 'Pacific/Rarotonga', text: 'Rarotonga (GMT-10:00)' },
      { key: 'Pacific/Saipan', value: 'Pacific/Saipan', text: 'Saipan (GMT+10:00)' },
      { key: 'Pacific/Tahiti', value: 'Pacific/Tahiti', text: 'Tahiti (GMT-10:00)' },
      { key: 'Pacific/Tarawa', value: 'Pacific/Tarawa', text: 'Tarawa (GMT+12:00)' },
      { key: 'Pacific/Tongatapu', value: 'Pacific/Tongatapu', text: 'Tongatapu (GMT+13:00)' },
      { key: 'Pacific/Wake', value: 'Pacific/Wake', text: 'Wake (GMT+12:00)' },
      { key: 'Pacific/Wallis', value: 'Pacific/Wallis', text: 'Wallis (GMT+12:00)' }
    ]
  }
];

export const ScheduleDropdownOptions = {
  interval() {
    return [
      { key: 'daily', value: 'daily', text: 'Daily' },
      { key: 'weekly', value: 'weekly', text: 'Weekly' },
      { key: 'monthly', value: 'monthly', text: 'Monthly' }
    ];
  },
  time: {
    sendTime: [
      { key: '0', value: '00:00', text: '12:00 AM' },
      { key: '1', value: '01:00', text: '1:00 AM' },
      { key: '2', value: '02:00', text: '2:00 AM' },
      { key: '3', value: '03:00', text: '3:00 AM' },
      { key: '4', value: '04:00', text: '4:00 AM' },
      { key: '5', value: '05:00', text: '5:00 AM' },
      { key: '6', value: '06:00', text: '6:00 AM' },
      { key: '7', value: '07:00', text: '7:00 AM' },
      { key: '8', value: '08:00', text: '8:00 AM' },
      { key: '9', value: '09:00', text: '9:00 AM' },
      { key: '10', value: '10:00', text: '10:00 AM' },
      { key: '11', value: '11:00', text: '11:00 AM' },
      { key: '12', value: '12:00', text: '12:00 PM' },
      { key: '13', value: '13:00', text: '1:00 PM' },
      { key: '14', value: '14:00', text: '2:00 PM' },
      { key: '15', value: '15:00', text: '3:00 PM' },
      { key: '16', value: '16:00', text: '4:00 PM' },
      { key: '17', value: '17:00', text: '5:00 PM' },
      { key: '18', value: '18:00', text: '6:00 PM' },
      { key: '19', value: '19:00', text: '7:00 PM' },
      { key: '20', value: '20:00', text: '8:00 PM' },
      { key: '21', value: '21:00', text: '9:00 PM' },
      { key: '22', value: '22:00', text: '10:00 PM' },
      { key: '23', value: '23:00', text: '11:00 PM' }
    ],
    timeZone: TIME_ZONES
  },
  sendDate() {
    return {
      daily: [
        { key: 'weekIn', value: 'weekIn', text: 'Monday - Friday' },
        { key: 'weekEnd', value: 'weekEnd', text: 'Saturday - Sunday' },
        { key: 'weekFull', value: 'weekFull', text: 'Monday - Sunday' }
      ],
      weekly: [
        { key: 'everyMonday', value: 'everyMonday', text: 'Every Monday' },
        { key: 'everyTuesday', value: 'everyTuesday', text: 'Every Tuesday' },
        { key: 'everyWednesday', value: 'everyWednesday', text: 'Every Wednesday' },
        { key: 'everyThursday', value: 'everyThursday', text: 'Every Thursday' },
        { key: 'everyFriday', value: 'everyFriday', text: 'Every Friday' },
        { key: 'everySaturday', value: 'everySaturday', text: 'Every Saturday' },
        { key: 'everySunday', value: 'everySunday', text: 'Every Sunday' }
      ],
      monthly: [
        { key: 'firstWorkDay', value: 'firstWorkDay', text: 'First work day of the month' },
        { key: 'firstMonday', value: 'firstMonday', text: 'First monday of the month' },
        { key: 'lastFriday', value: 'lastFriday', text: 'Last friday of the month' },
        { key: 'firstDay', value: 'firstDay', text: 'First day of the month' },
        { key: 'selectedDay', value: 'selectedDay', text: 'Select a day of the month' }
      ],
      selectedDay: (() => {
        const optionArray = [];
        for (let i = 1; i < 32; i++) {
          optionArray.push({ key: `${i}`, value: `${i}`, text: `${i}` });
        }
        return optionArray;
      })()
    };
  }
};
