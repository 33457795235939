import React, { useCallback } from 'react';
import { PaymentSettingsEditor as PaymentSettingsEditorContainer, PaymentActions, PaymentProvider } from '@jotforminc/payment-settings-editor';
import { useSelector, useDispatch } from 'react-redux';
import SELECTORS from '../../../../../../store/selectors';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import ItemSettings from './ItemSettings';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';
import { MODALS } from '../../../../../../constants/modals';

const ProductListProperties = () => {
  const itemID = useSelector(SELECTORS.getSelectedPortalItem);
  const dispatch = useDispatch();
  const productListItem = useSelector(SELECTORS.getItemWithDefaults(itemID));
  const productListLayout = useSelector(SELECTORS.getProductListLayout);
  const showSearch = useSelector(SELECTORS.getShowSearch);
  const currencyInfo = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);
  const checkoutFormPaymentQuestion = useSelector(SELECTORS.getCheckoutFormPaymentQuestion);
  const appInfo = useSelector(SELECTORS.getPortalInfoSelector);

  const { formID, products: _products } = productListItem;

  const appID = useSelector(SELECTORS.getAppID);
  const checkoutFormID = useSelector(SELECTORS.getCheckoutFormIDSelector);
  const activeProductInfo = useSelector(SELECTORS.getActiveProduct);
  const {
    itemID: activeProductItemID,
    productID
  } = activeProductInfo;
  const activeProduct = useSelector(SELECTORS.getFormProductListProduct(itemID, productID));
  const user = useSelector(SELECTORS.getUser);

  const handleProductChange = (type, data, { products = [] }) => dispatch(ACTION_CREATORS.productListChangeAction({ type, data, products }));

  const handlePaymentSettingsChange = useCallback((type, { productListSettingsItem }) => dispatch(ACTION_CREATORS.updatePortalPropAction(productListSettingsItem)), [itemID]);

  const handleActiveProduct = () => {
    if (activeProductItemID !== itemID) {
      // !Typescript => type must be string
      return {};
    }

    return activeProduct;
  };

  const handleRightPanelModeChange = productId => {
    if (productId) {
      dispatch(ACTION_CREATORS.setActiveProduct(itemID, productId));
    } else {
      dispatch(ACTION_CREATORS.setActiveProduct('', ''));
    }
  };

  const activeProductProps = handleActiveProduct();

  const onItemPropChange = (key, value) => dispatch(ACTION_CREATORS.updateItemPropAction({
    itemID,
    prop: {
      [key]: value
    }
  }));
  const initialEditor = activeProductProps?.pid ? 'productEditor' : '';

  const handleCheckoutFormSettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateCheckoutFormSettings(settings)), []);
  const togglePaymentConnectionModal = useCallback(() => {
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  return (
    <PaymentProvider>
      <ItemSettings onItemPropChange={onItemPropChange} productListItem={productListItem} />
      <PaymentSettingsEditorContainer
        appName="appBuilder"
        formId={formID}
        checkoutFormId={checkoutFormID}
        actions={PaymentActions}
        onProductsChange={handleProductChange}
        onPaymentSettingsChange={handlePaymentSettingsChange}
        products={_products}
        productListLayout={productListLayout}
        showSearch={showSearch}
        questionProperties={currencyInfo}
        appID={appID}
        activeProductProps={activeProductProps}
        onRightPanelModeChange={handleRightPanelModeChange}
        initialPanel="sellProducts"
        initialEditor={initialEditor}
        user={user}
        isFeatureEnabled={isFeatureEnabled}
        FEATURE_NAMES={FEATURE_NAMES}
        checkoutFormPaymentQuestion={{ ...checkoutFormPaymentQuestion, ...currencyInfo, paymentType: 'product' }}
        togglePaymentConnectionModal={togglePaymentConnectionModal}
        onSaveGateway={({ changedProps }) => {
          handleCheckoutFormSettingsChange({ ...changedProps });
        }}
        userIsResourceOwner={appInfo.username === user.username}
      />
    </PaymentProvider>
  );
};

export default ProductListProperties;
