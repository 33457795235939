/** Data */
import { gatewayDefaultSandboxPropNames } from '@jotforminc/payment-constants';

/** Type */
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';

/**
 * Checks if a given property name belongs to the sandbox.
 *
 * @param propName - The name of the property to check.
 * @returns A boolean indicating whether the property belongs to the sandbox.
 */
export const isPropBelongsToSandbox = (propName: PAYMENT_QUESTION_PROP_NAMES): boolean => {
  return gatewayDefaultSandboxPropNames.includes(propName as any);
};
