import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Stripe Checkout gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlStripeCheckout: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_stripeCheckout: {
    useStripeCoupons: {
      title: 'Fetch Coupons from Stripe Account',
      description: 'Fetch coupons from the given Stripe account. This will override your Jotform coupons.',
      type: 'toggle',
      selectedCondition: settings => settings.useStripeCoupons === '1' || settings.useStripeSubscriptions === '1',
      valueTrue: '1',
      valueFalse: '0',
      default: '0',
      resources: ['FORM'],
      renderCondition: { prop: 'paymentType', operator: '===', value: 'subscription' },
      disableCondition: settings => settings.useStripeSubscriptions === '1'
    },
    useStripeSubscriptions: {
      title: 'Fetch Subscriptions from Stripe Account',
      description: 'Fetch subscriptions from the given Stripe account. This will override your Jotform subscriptions.',
      type: 'toggle',
      selectedCondition: settings => settings.useStripeSubscriptions === '1',
      valueTrue: '1',
      valueFalse: '0',
      default: '0',
      resources: ['FORM'],
      renderCondition: { prop: 'paymentType', operator: '===', value: 'subscription' }
    },
    showStripeCoupons: {
      title: 'Show Fetched Coupons from Stripe Account',
      description: 'Show fetched coupons from the given Stripe account.',
      type: 'toggle',
      selectedCondition: settings => settings.showStripeCoupons === '1',
      valueTrue: '1',
      valueFalse: '0',
      default: '1',
      resources: ['FORM'],
      renderCondition: { prop: 'paymentType', operator: '===', value: 'subscription' }
    },
    sendReceiptEmail: {
      title: 'Send Receipt Email',
      type: 'toggle',
      selectedCondition: settings => settings.sendReceiptEmail === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM', 'APP']
    },
    laterCharge: {
      title: 'Charge Customer Immediately',
      type: 'toggle',
      selectedCondition: settings => settings.laterCharge === 'immediately',
      valueTrue: 'immediately',
      valueFalse: 'later',
      default: 'immediately',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    billingEmail: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    customDataField: {
      title: 'Custom Data Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_textbox', 'control_autoincrement'],
      questionTypeToAdd: 'control_textbox',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    }
  }
};

export default controlStripeCheckout;
