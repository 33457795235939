import React from 'react';
import { IconDocumentSimpleFilled } from '@jotforminc/svg-icons';
import Styled from 'styled-components';

const ScDocumentPlaceholder = Styled.div`
  background-color: #303339;
  border-radius: 4px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #AEB0B8;
    width: 40px;
    height: 40px;
  }
`;
const CustomPlaceholderIcon = () => (
  <ScDocumentPlaceholder>
    <IconDocumentSimpleFilled />
  </ScDocumentPlaceholder>
);

export default CustomPlaceholderIcon;
