import React, { useCallback } from 'react';
import { t } from '@jotforminc/translation';
import { ImageUploadWithViewer } from '@jotforminc/image-upload';
import { safeJSONParse } from '@jotforminc/utils';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

interface IImageUpload {
  BuilderImageUpload: any
  name: string;
  onChange: (url: string) => void
}

const ImageUpload: React.FunctionComponent<IImageUpload> = ({
  BuilderImageUpload,
  name,
  onChange
}) => {
  const { resource, gatewayProperties } = usePaymentPropsPanel();
  const value = gatewayProperties[name] || '';

  const encodeURI = (uri: string) => {
    const splitted = uri.split('/');
    if (splitted.length > 0 && !(splitted.length === 1 && splitted[0].trim() === '')) {
      const fileName = encodeURIComponent(splitted[splitted.length - 1]);
      splitted.pop();
      return `${splitted.join('/')}/${fileName}`;
    }
    return '';
  };

  const onImageChange = useCallback((imgArr: string[]) => {
    onChange(JSON.stringify(imgArr));
  }, [onChange]);

  return (
    <>
      {
        resource !== 'FORM' ? (
          <ImageUploadWithViewer
            isSortable={true}
            onChange={onImageChange}
            defaultValue={safeJSONParse(value, [])}
            buttonText={t('Choose Images')}
          />
        ) : (
          <BuilderImageUpload
            name="icon"
            value={value}
            label={t('Choose an Image')}
            moreImagesFullWidth={true}
            onSuccess={(e: any) => { onChange(encodeURI(e.url)); }}
            onRemove={() => { onChange(''); }}
            width={378}
            tetherPosition='top left'
          />
        )
      }
    </>
  );
};

export default ImageUpload;
