const patchTagify = tagify => {
  const clonedTagify = tagify;
  clonedTagify.addMixTags = function addMixTags(tagsItems) {
    const {
      settings: {
        pattern, transformTag, classNames, tagSuffix, focusAfterAddingTag
      },
      state: { tag: { prefix: tagPrefix } = {} }
    } = clonedTagify;

    const defaultPrefix = tagPrefix || (pattern?.source || [])[0];
    const prefixedTagItems = tagsItems.map(tagItem => ({ ...tagItem, prefix: tagItem.prefix || defaultPrefix }));

    return prefixedTagItems.map(tagItem => {
      transformTag.call(clonedTagify, tagItem);
      const tagElement = clonedTagify.createTagElem(tagItem);

      try {
        if (!clonedTagify.replaceTextWithNode(tagElement)) {
          if (clonedTagify.DOM.input.lastChild && clonedTagify.DOM.input.lastChild.tagName === 'BR') {
            clonedTagify.DOM.input.insertBefore(tagElement, clonedTagify.DOM.input.lastChild);
          } else {
            clonedTagify.DOM.input.appendChild(tagElement);
          }
        }
      } catch {
        clonedTagify.DOM.input.appendChild(tagElement);
      }

      if (tagSuffix) {
        clonedTagify.insertAfterTag(tagElement, tagSuffix);
      }

      if (focusAfterAddingTag) {
        clonedTagify.DOM.input.focus();
        clonedTagify.placeCaretAfterNode(tagElement);
      }

      setTimeout(() => tagElement.classList.add(classNames.tagNoAnimation), 300);

      clonedTagify.value.push(tagItem);
      clonedTagify.update();

      clonedTagify.trigger('add', { tag: tagElement, data: tagItem });
      return tagElement;
    });
  };

  clonedTagify.DOM.input.addEventListener('keypress', e => {
    if (e?.keyCode === 13) {
      clonedTagify.DOM.input.blur();
      e.preventDefault();
    }
  });

  return clonedTagify;
};

export default patchTagify;
