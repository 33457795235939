import React, {
  memo, useCallback, useMemo, useState
} from 'react';
import { useDispatch } from 'react-redux';

import { Hooks } from '@jotforminc/uikit';
import { ImageUpload } from '@jotforminc/image-upload';
import { Tab, TabList } from '@jotforminc/tabs';
import { useDebounce } from '@jotforminc/hooks';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { string } from 'prop-types';
import isEqual from 'lodash/isEqual';

import Styled from 'styled-components';
import { IconTrashFilled } from '@jotforminc/svg-icons';
import {
  IMAGE_TYPE, APP_LOGO_PROPS, APP_ICON_PROPS, APP_GRAPHIC_TYPES, IMAGE_PREVIEW_REMOVE_BUTTON_TEXTS
} from '../../../../../constants';
import { RPTabButtonContainer, RPTabButtonWrapper } from '../../RightPanelContainer';
import { RightPanelTabLists } from '../../../constants/rightPanel';
import { ScRightPanelBlock } from '../../../styles/scRightPanel';
import { ColorPicker } from '../../ColorPicker';
import appConfig from '../../../../../constants/appConfig';
import { useAppDefaults } from '../../../../../properties';
import { APP_LOGO_PANEL_TAB_NAMES } from '../constants';
import ImagePreview from '../../ImagePreview';
import {
  navigateToAction,
  toastAction, updateAppLogo
} from '../../../../../store/actionCreators';
import RangeSlider from '../../../../../components/RangeSlider';
import { FEATURE_NAMES } from '../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../utils/features/helper';
import PanelItemDescriptionRenderer from './PanelItemDescriptionRenderer';
import IconSelector from '../../../../../components/IconSelector';
import { BUILDER_TABS, DESTINATION_TYPES, SETTINGS_SUBTABS } from '../../../../../constants/navigation';
import { ScSectionTitle } from './ScSectionTitle';
import { ScLineHr } from './ScLineHr';

const ScRangeSliderBlock = Styled.div`
  margin-top: 20px;

  hr {
    width: 100%;
    position: absolute;
    left: 0;
  }

  label {
    margin-top: 20px;
  }
`;

const AppLogoPropertyPanel = ({ elementType, iconSelectorTheme, ...otherProps }) => {
  const dispatch = useDispatch();
  const isAppLogoPanel = elementType === APP_GRAPHIC_TYPES.appLogo;
  const propNames = useMemo(() => (isAppLogoPanel ? APP_LOGO_PROPS : APP_ICON_PROPS), [isAppLogoPanel]);
  const {
    [propNames.logoBgColor]: logoBgColor,
    [propNames.logoColor]: logoColor,
    [propNames.logoImageURL]: logoImageURL,
    [propNames.logoType]: logoType,
    appIconBackground, appIconType, appIconURL, appIconColor,
    id: appID,
    installableIconURL,
    appLogoSize: _appLogoSize
  } = otherProps;

  const {
    [propNames.logoImageURL]: defaultLogoURL,
    appIconBackground: defaultAppIconBgColor,
    appIconColor: defaultAppIconColor,
    appIconURL: defaultAppIconURL,
    appIconType: defaultAppIconType,
    appLogoSize: defaultAppLogoSize
  } = useAppDefaults();
  const isLogoIcon = logoType === IMAGE_TYPE.icon;
  const isDefaultLogo = logoImageURL === defaultLogoURL;
  const defaultActiveTab = (() => {
    if (!isAppLogoPanel) {
      return (isLogoIcon || isDefaultLogo) ? APP_LOGO_PANEL_TAB_NAMES.Icon : APP_LOGO_PANEL_TAB_NAMES.Upload;
    }
    return isLogoIcon ? APP_LOGO_PANEL_TAB_NAMES.Icon : APP_LOGO_PANEL_TAB_NAMES.Upload;
  })();

  const isAppIconUntouched = useMemo(() => {
    if (!isAppLogoPanel) return false;

    const appIconProps = [appIconBackground, appIconColor, appIconURL, appIconType];
    const logoProps = [logoBgColor, logoColor, logoImageURL, logoType];
    const defaultAppIconProps = [defaultAppIconBgColor, defaultAppIconColor, defaultAppIconURL, defaultAppIconType];

    // if the app icon is untouched or default
    // change it along with the app logo
    return (isEqual(appIconProps, logoProps) || isEqual(appIconProps, defaultAppIconProps));
  }, [isAppLogoPanel, appIconBackground, appIconType, appIconURL, appIconColor]);

  const onViewChangesButton = useCallback(() => {
    if (appID) {
      dispatch(navigateToAction({ to: DESTINATION_TYPES.BUILDER_PAGE, tab: BUILDER_TABS.SETTINGS, subTab: SETTINGS_SUBTABS.APP_NAME }));
    }
  }, []);

  const handleSyncToast = useCallback(() => {
    if (!window.sessionStorage.getItem('appIconSyncToastNotification')) {
      dispatch(toastAction({
        message: t('The app icon is synced with the app logo.'),
        buttonText: 'View',
        onButtonClick: onViewChangesButton
      }));
      window.sessionStorage.setItem('appIconSyncToastNotification', true);
    }
  }, []);

  const onLogoUpdate = useCallback(({ url, type, logoSvgRef = '' }) => {
    const isRemovingImage = isAppLogoPanel && url === '';
    const appLogoType = type || IMAGE_TYPE.image;
    const data = isAppLogoPanel
      ? { [APP_LOGO_PROPS.logoImageURL]: url, [APP_LOGO_PROPS.logoType]: appLogoType }
      : { [APP_ICON_PROPS.logoImageURL]: url, [APP_ICON_PROPS.svgRef]: logoSvgRef, [APP_ICON_PROPS.logoType]: appLogoType };

    const updateAllIcons = !isRemovingImage && isAppIconUntouched;
    const logoProps = updateAllIcons ? {
      ...data,
      ...{
        appIconURL: url,
        appIconSvgRef: logoSvgRef,
        appIconType: type || IMAGE_TYPE.image
      }
    } : data;

    if (updateAllIcons) {
      handleSyncToast();
    }

    dispatch(updateAppLogo(logoProps));
  }, [isAppLogoPanel, propNames, isAppIconUntouched]);

  const onPropChange = useCallback(prop => e => {
    const propName = propNames[prop];
    const data = { [propName]: e };

    const portalProps = isAppIconUntouched ? { ...data, ...{ [APP_ICON_PROPS[prop]]: e } } : data;
    if (isAppIconUntouched && prop !== 'appLogoSize') {
      handleSyncToast();
    }

    dispatch(updateAppLogo(portalProps));
  }, [propNames, isAppIconUntouched]);

  const defaultLogoRemovable = useMemo(() => isAppLogoPanel || !isDefaultLogo, [isDefaultLogo, isAppLogoPanel]);
  const isPreviewModeActive = useMemo(() => logoImageURL && defaultLogoRemovable, [logoImageURL, defaultLogoRemovable]);

  const removedURL = useMemo(() => {
    return !isAppLogoPanel ? defaultLogoURL : '';
  }, [defaultLogoURL, isAppLogoPanel]);

  const ImagePreviewRemoveButton = props => {
    const removeButtonText = IMAGE_PREVIEW_REMOVE_BUTTON_TEXTS[(logoType === IMAGE_TYPE.icon) ? IMAGE_TYPE.icon : IMAGE_TYPE.image];
    return (
      <Button
        variant='filled' colorStyle="secondary" startIcon={IconTrashFilled}
        {...props}
      >
        {t(removeButtonText)}
      </Button>
    );
  };

  const tabButton = useMemo(() => RPTabButtonWrapper({ onClick: f => f, itemTabs: RightPanelTabLists().AppName }), []);
  const handleImageSelect = useCallback(res => onLogoUpdate({ url: res, type: IMAGE_TYPE.image }), [onLogoUpdate]);
  const handleImageUpload = useCallback(res => onLogoUpdate({ url: res?.url, type: IMAGE_TYPE.image }), [onLogoUpdate]);
  const handleLinkTextInputSubmit = useCallback(res => onLogoUpdate({ url: res, type: IMAGE_TYPE.image }), [onLogoUpdate]);

  const handleIconChange = useCallback(res => onLogoUpdate({ url: res?.url, logoSvgRef: res?.iconSvgRef, type: IMAGE_TYPE.icon }), [onLogoUpdate]);
  const handleIconRemove = useCallback(() => onLogoUpdate({ url: removedURL, logoSvgRef: '', type: IMAGE_TYPE.image }), [onLogoUpdate]);
  const iconValue = isLogoIcon ? logoImageURL : '';
  const handleLogoColorChange = useCallback(e => onPropChange('logoColor')(e), [onPropChange]);
  const handleLogoBgColorChange = useCallback(e => onPropChange('logoBgColor')(e), [onPropChange]);
  const useInstallableAppIcon = !isAppLogoPanel && installableIconURL;
  const handleAppLogoSizeChange = useCallback(useDebounce(e => onPropChange('appLogoSize')(e)), [onPropChange]);

  const [appLogoSize, setAppLogoSize] = useState(_appLogoSize);

  const handleLogoRemove = useCallback(() => {
    setAppLogoSize(defaultAppLogoSize);
    onLogoUpdate({ url: removedURL, type: IMAGE_TYPE.image });
  }, [removedURL, onLogoUpdate, setAppLogoSize]);

  Hooks.useEffectIgnoreFirst(() => {
    handleAppLogoSizeChange(appLogoSize);
  }, [appLogoSize]);

  return (
    <TabList
      defaultActiveTab={defaultActiveTab}
      list={RightPanelTabLists().AppName}
      TabButtonContainer={RPTabButtonContainer}
      TabButton={tabButton}
    >
      <Tab id={APP_LOGO_PANEL_TAB_NAMES.Upload} key={APP_LOGO_PANEL_TAB_NAMES.Upload}>
        <ScRightPanelBlock line>
          {(isPreviewModeActive)
            ? (
              <>
                <ImagePreview
                  imageURL={useInstallableAppIcon ? installableIconURL : logoImageURL}
                  imageType={useInstallableAppIcon ? IMAGE_TYPE.image : logoType}
                  layout="line"
                  onRemove={handleLogoRemove}
                  RemoveButtonRenderer={ImagePreviewRemoveButton}
                />
                {isFeatureEnabled(FEATURE_NAMES.AppLogoSize) && isAppLogoPanel && !isLogoIcon
                  ? (
                    <>
                      <ScRangeSliderBlock>
                        <ScLineHr />
                        <label htmlFor="iconColor">{t('Logo Size')}</label>
                        <RangeSlider value={appLogoSize} onChange={setAppLogoSize} />
                        <PanelItemDescriptionRenderer description={t('Adjust your logo size.')} />
                      </ScRangeSliderBlock>
                    </>
                  ) : null}
              </>
            ) : (
              <ImageUpload
                onImageSelect={handleImageSelect}
                onImageUpload={handleImageUpload}
                onLinkTextInputSubmit={handleLinkTextInputSubmit}
                renderTabs={['upload', 'choose']}
                useAPI={true}
                allowedTypes='image/gif, image/png, image/jpeg, image/jpg, image/pjpeg'
              />
            )}
        </ScRightPanelBlock>
      </Tab>
      <Tab id={APP_LOGO_PANEL_TAB_NAMES.Icon} key={APP_LOGO_PANEL_TAB_NAMES.Icon}>
        <ScSectionTitle>{t('Icon Color Style')}</ScSectionTitle>
        <ScRightPanelBlock column>
          <label htmlFor="iconColor">{t('Icon Color')}</label>
          <ColorPicker id="iconColor" defaultValue={logoColor} onChange={handleLogoColorChange} />
        </ScRightPanelBlock>
        <ScRightPanelBlock column>
          <label htmlFor="logoBackground">{t('Background Color')}</label>
          <ColorPicker
            id="logoBackground"
            defaultValue={logoBgColor}
            onChange={handleLogoBgColorChange}
            offsetX={39} placement='bottom-end'
          />
        </ScRightPanelBlock>
        <ScLineHr />
        <ScSectionTitle>{t('Select Icon')}</ScSectionTitle>
        <div className="icon-selector-wrap">
          <IconSelector
            allIconsContainerID={appConfig.svgIconsContainer}
            onChange={handleIconChange}
            onRemove={handleIconRemove}
            value={iconValue}
            allowShuffle={false}
            theme={iconSelectorTheme}
          />
        </div>
      </Tab>
    </TabList>
  );
};

AppLogoPropertyPanel.propTypes = {
  elementType: string,
  iconSelectorTheme: string
};

AppLogoPropertyPanel.defaultProps = {
  elementType: APP_GRAPHIC_TYPES.appLogo,
  iconSelectorTheme: ''
};

export default memo(AppLogoPropertyPanel);
