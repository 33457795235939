import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';

import { Hooks } from '@jotforminc/uikit';
import get from 'lodash/get';
import { handleCustomNavigation } from '@jotforminc/utils';
import { TConnectionStatus, TFormId } from '../../../types/common';
import BaseConnectionButton from './BaseConnectionButton';
import {
  createPaypalReferral,
  getPaypalMerchantStatus
} from '../../../api';
import { getUrlParameterByName } from '../../../utils/general';
import { getoAuthPaypalEnvironment } from './utils';
import { checkIsGatewayConnected } from '../../../utils';
import { I_PAYPALCOMPLETE_SETTINGS } from '../../modals/Flows/Types/GatewayTypes';
import { I_GATEWAYS, I_PAYMENT_TYPES } from '../../modals/Flows/Types';
import useGatewayFlows from '../../modals/Flows/Context/useGatewayFlows';

declare global {
  interface Window {
    jfPaypalTimer?: number;
  }
}

const ConnectionButtonPaypal = ({
  settings,
  checkoutFormId,
  connectionStatus,
  setConnectionStatus,
  paymentType,
  gatewayType
} : IProps) : JSX.Element => {
  const [paypalMerchantStatus, setPaypalMerchantStatus] = useState<string>('');
  const [actionUrl, setActionUrl] = useState('');
  const { changedSettings, setChangedSettings, handleDisconnectGateway } = useGatewayFlows();

  Hooks.useEffectIgnoreFirst(() => {
    if (connectionStatus === 'connected') {
      handleDisconnectGateway(gatewayType);
    }
  }, [settings.sandbox]);

  useEffect(() => {
    if (checkIsGatewayConnected({ ...settings, type: gatewayType })) {
      setConnectionStatus('connected');
    } else if (connectionStatus !== 'initializing' && connectionStatus !== 'notInitialized') {
      setConnectionStatus('notConnected');
    }
  }, [settings]);

  const createReferral = () => {
    const data = {
      formId: checkoutFormId,
      environment: getoAuthPaypalEnvironment({ ...settings, type: gatewayType }),
      gateway: gatewayType.split('_')[1],
      showSPB: settings.showSPB !== 'No' ? 'Yes' : 'No',
      showCardFields: settings.showCardFields !== 'No' ? 'Yes' : 'No',
      paymentType: paymentType
    };

    setConnectionStatus('initializing');

    createPaypalReferral(data)
      .then(res => {
        const link = get(res, 'links[1].href');
        const status = link ? 'notConnected' : 'notInitialized';

        setConnectionStatus(status);
        setActionUrl(link || '');
      }).catch(() => {
        setConnectionStatus('notInitialized');
        setActionUrl('');
      });
  };

  useEffect(() => {
    if (!checkIsGatewayConnected(settings)) {
      createReferral();
    }
  }, [settings.sandbox, settings.showSPB, settings.showCardFields]);

  const paypalOauthConnectorV2 = (): void => {
    const popupWidth = 800;
    const popupX = window.screenX + (window.innerWidth - popupWidth) / 2;
    const popupY = window.screenY + (window.innerHeight - 800) / 2;

    setConnectionStatus('connecting');

    const paypalPopup = handleCustomNavigation(
      actionUrl,
      'view',
      true,
      `width=${popupWidth},height=800,status=0,top=${popupY}px,left=${popupX}px,location=1,toolbar=0,scrollbars=1,resizable=1`
    );

    if (window.jfPaypalTimer) {
      window.clearInterval(window.jfPaypalTimer);
    }

    window.jfPaypalTimer = window.setInterval(() => {
      if (paypalPopup && paypalPopup.closed !== false) {
        window.clearInterval(window.jfPaypalTimer);
      }

      if (paypalPopup && paypalPopup.closed && connectionStatus === 'notConnected' && paypalMerchantStatus === '') {
        setConnectionStatus('notConnected');
        window.clearInterval(window.jfPaypalTimer);
      }

      if (paypalPopup) {
        try {
          const currentHostname = window.location.hostname;
          const popupHostname = paypalPopup.location.hostname;

          if (currentHostname === popupHostname) {
            const callbackUrl = paypalPopup.location.href;
            if (callbackUrl && callbackUrl.includes('#paypalConnection')) {
              window.clearInterval(window.jfPaypalTimer);
              paypalPopup.close();

              const merchantId = getUrlParameterByName('merchantId', callbackUrl);
              const merchantIdInPayPal = getUrlParameterByName('merchantIdInPayPal', callbackUrl);
              if (merchantId && merchantIdInPayPal) {
                const data = {
                  environment: getoAuthPaypalEnvironment({ ...settings, type: gatewayType }),
                  gateway: gatewayType.split('_')[1],
                  merchantId: merchantIdInPayPal,
                  formId: checkoutFormId
                };

                setPaypalMerchantStatus('started');
                getPaypalMerchantStatus(data).then(res => {
                  setPaypalMerchantStatus('succeeded');

                  if (res) {
                    let isOnlyConnectedToSPB = null;
                    let propsToUpdate = {};
                    let cardFieldsProduct = null;
                    let isCardFieldsApproved = null;
                    const type = gatewayType.split('_')[1];
                    if (type === 'paypalcomplete') {
                      isOnlyConnectedToSPB = (settings.showCardFields === 'No' && settings.showSPB !== 'No') ? 'Yes' : 'No';
                      propsToUpdate = { merchantId: merchantIdInPayPal, isOnlyConnectedToSPB };

                      cardFieldsProduct = res.products.find((p: any) => p.name === 'PPCP_CUSTOM');
                      isCardFieldsApproved = true;

                      if (settings.showCardFields === 'Yes' && cardFieldsProduct) {
                        isCardFieldsApproved = ['APPROVED', 'SUBSCRIBED'].includes(cardFieldsProduct.vetting_status);
                      } else if (settings.showCardFields === 'Yes' && !cardFieldsProduct) {
                        isCardFieldsApproved = false;
                      }

                      // If the merchant account have not supported the card fields, activate smart payment buttons.
                      if (!isCardFieldsApproved && settings.showSPB === 'No') {
                        propsToUpdate.showSPB = 'Yes';
                        propsToUpdate.isOnlyConnectedToSPB = 'Yes';
                      }
                      if (!isCardFieldsApproved) {
                        propsToUpdate.showCardFields = 'No';
                        propsToUpdate.isOnlyConnectedToSPB = 'Yes';
                      }
                    } else if (type === 'paypalInvoicing') {
                      propsToUpdate = { merchantId: merchantIdInPayPal, merchantEmail: res.primary_email };
                    }

                    if (!Object.keys(propsToUpdate).length) return;

                    setChangedSettings({ ...changedSettings, ...propsToUpdate });
                  } else {
                    setConnectionStatus('notConnected');
                  }
                }).catch(() => {
                  setPaypalMerchantStatus('failed');
                  setConnectionStatus('notConnected');
                });
              }
            } else {
              window.clearInterval(window.jfPaypalTimer);
              paypalPopup.close();
              setPaypalMerchantStatus('failed');
              setConnectionStatus('notConnected');
            }
          }
        // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    }, 250);
  };

  return (
    <BaseConnectionButton
      settings={settings}
      connectionStatus={connectionStatus}
      connect={paypalOauthConnectorV2}
    />
  );
};

export interface IProps {
  settings: I_PAYPALCOMPLETE_SETTINGS;
  checkoutFormId: TFormId;
  connectionStatus: string;
  setConnectionStatus: Dispatch<SetStateAction<TConnectionStatus>>;
  paymentType: I_PAYMENT_TYPES | null;
  gatewayType: I_GATEWAYS;
}

export default ConnectionButtonPaypal;
