import { arrayOf, func, shape } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import SchemaItem from './SchemaItem';

const SortableItem = SortableElement(itemProps => <div className="schemaItemWrapper"><SchemaItem {...itemProps} /></div>); // Wrapper div is important for Sorting!

const SortableItemList = SortableContainer(({ children, additionalClassName }) => {
  return (
    <div className={`schemaItemList${additionalClassName ? ` ${additionalClassName}` : ''}`}>
      {children}
    </div>
  );
});

const SchemaItemList = ({
  items,
  onSortChange,
  ...rest
}) => {
  const { editableItemID } = rest;

  const handleShouldSortingStart = useCallback(e => {
    const { nodeName } = e.target;

    if (nodeName === 'BUTTON' || typeof editableItemID === 'number') return true;
    return false;
  }, [editableItemID]);

  const [helperClass, setHelperClass] = useState('');

  const handleOnSortEnd = useCallback(e => {
    onSortChange(e);
    setHelperClass('');
  }, [onSortChange]);

  const handleSortMove = useCallback(() => {
    setHelperClass('isDragMove');
  }, []);

  return (
    <SortableItemList
      additionalClassName={helperClass}
      shouldCancelStart={handleShouldSortingStart}
      onSortEnd={handleOnSortEnd}
      onSortMove={handleSortMove}
      lockAxis="y"
    >
      {items.map((item, index) => (
        <SortableItem
          index={index} item={item} itemIndex={index}
          key={`item_${item.id}`} {...rest}
        />
      ))}
    </SortableItemList>
  );
};

SchemaItemList.propTypes = {
  items: arrayOf(shape({})),
  onSortChange: func
};

SchemaItemList.defaultProps = {
  items: [],
  onSortChange: f => f
};

export default SchemaItemList;
