import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { useSelector, useDispatch } from 'react-redux';

import { IconPlus } from '@jotforminc/svg-icons';
import ProductItemList from '../ProductItemList';
import { changeActiveEditor, changeActiveTab, setActiveProduct } from '../../store/actionCreators';
import {
  ProductQuantitySelectorTabEditor, ProductOptionCreatorTabEditor, SellProductsEditor, ProductOptionsTabEditor
} from '../editors';
import Selectors from '../../store/selectors';
import * as actionCreators from '../../store/actionCreators';
import {
  FTcreateProduct,
  FTupdateProduct,
  FTremoveProduct,
  FTSortProducts,
  ISellProductsItem
} from '../../types/common';

const SellProductsPanel = ({ actions, initialEditor, activeProductProps }: ISellProductsPanelProps) : JSX.Element => {
  const activeEditor = useSelector(Selectors.getActiveEditor);
  const dispatch = useDispatch();
  const isAllowedTransition = newEditor => ['', 'productOptionCreatorTabEditor', 'productEditor', 'productQuantitySelectorTabEditor'].includes(newEditor);

  useEffect(() => {
    const allowedTransition = isAllowedTransition(activeEditor);
    if (initialEditor !== activeEditor && !allowedTransition) {
      dispatch(actionCreators.changeActiveEditor(initialEditor));
    }
  }, [dispatch, activeEditor, initialEditor]);

  const openProductEditor = useCallback(() => {
    dispatch(changeActiveEditor('productEditor'));
    dispatch(changeActiveTab('basic'));
  }, [dispatch]);

  const handleAddNewProduct = useCallback(() => {
    dispatch(actionCreators.setActiveProduct('', true));
    openProductEditor();
  }, [dispatch, openProductEditor]);

  useEffect(() => {
    const allowedTransition = isAllowedTransition(activeEditor);
    if (!!activeProductProps?.pid && !allowedTransition) {
      openProductEditor();
      dispatch(setActiveProduct(activeProductProps));
    }
  }, [dispatch, activeEditor, activeProductProps, openProductEditor]);

  return (
    <div className={classNames('paymentPanel', 'sellProductsPanel')}>
      <div className="panelContent">
        {activeEditor === '' && (
          <>
            <hr className="lineHr" />
            <div className={classNames('launcher')}>
              <div className="buttonsArea">
                <Button
                  onClick={handleAddNewProduct}
                  fullWidth
                  startIcon={IconPlus}
                  className='mb-2'
                >
                  {t('Add a New Product')}
                </Button>
              </div>

              <ProductItemList actions={actions} />
            </div>
          </>
        )}

        {(activeEditor === 'productEditor') && (
          <SellProductsEditor actions={actions} />
        )}

        {activeEditor === 'productOptionsTabEditor' && (
          <ProductOptionsTabEditor />
        )}

        {activeEditor === 'productQuantitySelectorTabEditor' && (
          <ProductQuantitySelectorTabEditor />
        )}

        {activeEditor === 'productOptionCreatorTabEditor' && (
          <ProductOptionCreatorTabEditor />
        )}
      </div>
    </div>
  );
};

interface IActions {
  createProduct: FTcreateProduct,
  updateProduct: FTupdateProduct,
  removeProduct: FTremoveProduct,
  sortProducts: FTSortProducts,
}
interface ISellProductsPanelProps {
  actions: IActions
  initialEditor: string,
  activeProductProps: ISellProductsItem
}

export default SellProductsPanel;
