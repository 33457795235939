import React, { useCallback, Suspense } from 'react';
import PropTypes from 'prop-types';
import { getAPIURL } from '@jotforminc/request-layer';
import { safeLazy } from '@jotforminc/safe-lazy-import';

const LoginFlowWithStyles = safeLazy(() => import(/* webpackChunkName: "LoginFlowWithStyles" */'../../../../../components/LoginFlowWithStyles'));

const GuestLoginModal = ({
  user,
  onConfirm,
  onCancel
}) => {
  const handleUserLogin = useCallback(loggedInUser => {
    onConfirm(loggedInUser);
  }, []);

  return (
    <Suspense fallback={<div />}>
      <LoginFlowWithStyles
        onClose={onCancel}
        forceUser={true}
        apiURL={getAPIURL()}
        onUserLogin={handleUserLogin}
        user={user}
        isNewBranding={true}
        buttonNames={{
          google: 'apps-guest-modal-google-login-jfheader',
          facebook: 'apps-guest-modal-facebook-login-jfheader',
          apple: 'apps-guest-modal-apple-login-jfheader',
          emailLogin: 'apps-guest-modal-standart-login-button',
          emailSignup: 'apps-guest-modal-header-createmyaccount'
        }}
      />
    </Suspense>
  );
};

GuestLoginModal.propTypes = {
  user: PropTypes.shape({}),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
};

GuestLoginModal.defaultProps = {
  user: {},
  onCancel: f => f,
  onConfirm: f => f
};

export default GuestLoginModal;
