/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
import React from 'react';
import { bool, func, node } from 'prop-types';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { IconXmark, IconMobileTitle } from '@jotforminc/svg-icons';
import string from 'lodash/string';
import {
  SCDialogRenderer,
  SCContentRenderer,
  SCHeaderRenderer,
  SCHeaderTitle,
  SCHeaderTitleIcon,
  SCHeaderTitleContent,
  SCHeaderTitleClose,
  SCFooterRenderer
} from './SCAppNameIconSettingsModal';
import { NavigationNames } from './Navigation';

// DialogRenderer
export const DialogRenderer = ({ children }) => (
  <SCDialogRenderer className="appNameIconSettingsModal">
    {children}
  </SCDialogRenderer>
);

DialogRenderer.propTypes = { children: node };
DialogRenderer.defaultProps = { children: null };

// ContentRenderer
export const ContentRenderer = ({ children, ...props }) => (
  <SCContentRenderer {...props}>
    {children}
  </SCContentRenderer>
);

ContentRenderer.propTypes = { children: node };
ContentRenderer.defaultProps = { children: null };

// HeaderRenderer
export const HeaderRenderer = ({
  title, desc, onCloseClick, ...props
}) => (
  <SCHeaderRenderer>
    <SCHeaderTitle>
      <SCHeaderTitleIcon>
        <IconMobileTitle />
      </SCHeaderTitleIcon>
      {(title || desc) && (
        <SCHeaderTitleContent>
          {title && (
            <h2>
              {t(title)}
            </h2>
          )}
          {desc && <span>{t(desc)}</span>}
        </SCHeaderTitleContent>
      )}
    </SCHeaderTitle>
    <SCHeaderTitleClose>
      <Button
        aria-label={t('Close Button')}
        onClick={onCloseClick}
        rounded={true}
        startIcon={IconXmark}
        colorStyle="secondary"
        {...props}
      />
    </SCHeaderTitleClose>
  </SCHeaderRenderer>
);

HeaderRenderer.propTypes = { title: string, desc: string, onCloseClick: func };
HeaderRenderer.defaultProps = { title: '', desc: '', onCloseClick: f => f };

// FooterRenderer
export const FooterRenderer = ({
  isBackVisible, isDoneVisible, onBackClick, onDoneClick, ...props
}) => (
  <SCFooterRenderer {...props}>
    {isBackVisible && (
      <Button
        aria-label={t('Back Button')}
        onClick={onBackClick}
        colorStyle="secondary"
      >
        {t('Back')}
      </Button>
    )}
    {isDoneVisible && (
    <Button
      aria-label={t('Done Button')}
      onClick={onDoneClick}
    >
      {t('Done')}
    </Button>
    )}
  </SCFooterRenderer>
);

FooterRenderer.propTypes = {
  isBackVisible: bool,
  isDoneVisible: bool,
  onBackClick: func,
  onDoneClick: func
};

FooterRenderer.defaultProps = {
  isBackVisible: false,
  isDoneVisible: false,
  onBackClick: f => f,
  onDoneClick: f => f
};

export const headerPropsMap = {
  [NavigationNames.AppIdentifierSettingsContainer]: {
    title: 'App Name & Icon',
    desc: 'The app icon will appear when users add your app to their home screen.'
  },
  [NavigationNames.AppLogoPropertyPanel]: {
    title: 'App Icon Properties',
    desc: ''
  }
};
