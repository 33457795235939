import { styling } from '@jotforminc/portal-schemas';
import { filterItemPropertiesBySchema, useAppDefaults, useItemDefaults } from '../index';
import { ITEM_TYPES } from '../../constants/itemTypes';

// Item Level
const {
  itemFontColor,
  itemBorderColor,
  itemBgColor
} = useItemDefaults(ITEM_TYPES.FORM);

export const SCHEME_TYPES = {
  APP: 'APP',
  BUTTON: 'BUTTON',
  DONATION: 'DONATION',
  ITEM: 'ITEM'
};

export const APP_SCHEME_KEYS = [
  'appBgColor',
  'appBgColorEnd',
  'appHeaderBgColor',
  'appFontColor',
  'listBgColor',
  'logoBackground',
  'iconColor',
  'splashBgColor',
  'splashFontColor',
  'appIconBackground',
  'appIconColor'
];

export const ITEM_SCHEME_KEYS = [
  'itemBorderColor',
  'itemBgColor',
  'itemFontColor'
];

export const ITEM_STYLING_LIST = styling.getItemStylingList({
  itemFontColor,
  itemBorderColor,
  itemBgColor
});

export const { BUTTON_STYLING_LIST } = styling;

// App Level
const {
  appBgColor,
  appBgColorEnd,
  appHeaderBgColor,
  appFontColor,
  listBgColor,
  logoBackground,
  iconColor,
  splashBgColor,
  splashFontColor,
  appIconBackground,
  appIconColor
} = useAppDefaults();

export const APP_COLOR_STYLINGS = styling.getAppColorStylings({
  appBgColor,
  appBgColorEnd,
  appHeaderBgColor,
  appFontColor,
  listBgColor,
  logoBackground,
  iconColor,
  splashBgColor,
  splashFontColor,
  appIconBackground,
  appIconColor
}, ITEM_STYLING_LIST);

// To use in RP only for preview
export const getAppPaletteColors = () => {
  return Object.entries(APP_COLOR_STYLINGS)
    .reduce((prev, [id, { appPaletteColors }]) => ([...prev, { id, ...appPaletteColors }]), []);
};

export const getItemPaletteColors = colorList => {
  return Object.entries(colorList)
    .reduce((prev, [id, itemColors]) => ([...prev, { id, ...itemColors }]), []);
};

// To use in the actions
export const getAppStylingColors = schemeID => {
  const colorScheme = APP_COLOR_STYLINGS[schemeID] || {};
  const { appColors = {} } = colorScheme;
  return appColors;
};

export const getItemStylingColors = (colorList = ITEM_STYLING_LIST, schemeID) => colorList[schemeID] || {};

export const getColorList = type => {
  switch (type) {
    case ITEM_TYPES.BUTTON:
      return BUTTON_STYLING_LIST;
    case ITEM_TYPES.DONATION:
      const donationStylingList = Object.entries(ITEM_STYLING_LIST).reduce((prev, [id, itemColors]) => {
        const item = [id, {
          ...itemColors,
          itemButtonBgColor: id === 'default' ? '#0099FF' : BUTTON_STYLING_LIST[id]?.itemBgColor
        }];

        return [...prev, item];
      }, []);
      return Object.fromEntries(donationStylingList);
    default:
      return ITEM_STYLING_LIST;
  }
};

export const getColoredPropertiesBySchemeID = (items, schemeID, version) => {
  const itemsToChange = [];
  let itemColors = {};
  items.forEach(({ type, id }) => {
    const colorList = getColorList(type);
    const properties = getItemStylingColors(colorList, schemeID);
    itemColors = { ...itemColors, ...properties };
    const propertyKeys = Object.keys(properties);
    // filter 'props' according to every items own schema
    const filteredKeys = filterItemPropertiesBySchema(type, version, propertyKeys);
    if (filteredKeys.length) {
      const filteredProperties = {};
      filteredKeys.forEach(key => { filteredProperties[key] = properties[key]; });
      // use new filtered 'props' to update item
      itemsToChange.push({ id, ...filteredProperties });
    }
  });
  return { itemsToChange, itemColors };
};

export const getPaletteColors = schemeType => {
  switch (true) {
    case schemeType === SCHEME_TYPES.APP:
      return getAppPaletteColors();
    case schemeType === SCHEME_TYPES.BUTTON:
    case schemeType === SCHEME_TYPES.DONATION:
      return getItemPaletteColors(BUTTON_STYLING_LIST);
    default:
      return getItemPaletteColors(ITEM_STYLING_LIST);
  }
};

export const getButtonText = schemeType => {
  switch (true) {
    case schemeType === SCHEME_TYPES.APP:
      return 'A';
    case schemeType === SCHEME_TYPES.BUTTON:
      return 'Button';
    default:
      return 'Text';
  }
};

export const mapSchemeToCustomProp = (scheme, data) => {
  let newData = {};
  if (scheme && Object.keys(scheme).length > 0) {
    Object.keys(data).forEach(k => {
      newData = {
        ...((scheme[k]) ? { ...newData, [scheme[k]]: data[k] } : newData)
      };
    });
    return newData;
  }
  return data;
};
