import React from 'react';
import { t } from '@jotforminc/translation';
import { IconAlignTextCenter, IconAlignTextLeft, IconAlignTextRight } from '@jotforminc/svg-icons';

export const TEXT_ALIGNMENT_OPTION_TESTIDS = [ // Indices are important
  'leftAlignment',
  'centerAlignment',
  'rightAlignment'
];

const TEXT_ALIGNMENTS = {
  left: {
    Renderer: () => (
      <div className="textAlignmentItem" data-testid={TEXT_ALIGNMENT_OPTION_TESTIDS[0]}>
        <IconAlignTextLeft width="20px" />
        <span className="textAlignmentItem-leftAlign">{t('Left')}</span>
      </div>
    )
  },
  center: {
    Renderer: () => (
      <div className="textAlignmentItem" data-testid={TEXT_ALIGNMENT_OPTION_TESTIDS[1]}>
        <IconAlignTextCenter width="20px" />
        <span className="textAlignmentItem-centerAlign">{t('Center')}</span>
      </div>
    )
  },
  right: {
    Renderer: () => (
      <div className="textAlignmentItem" data-testid={TEXT_ALIGNMENT_OPTION_TESTIDS[2]}>
        <IconAlignTextRight width="20px" />
        <span className="textAlignmentItem-rightAlign">{t('Right')}</span>
      </div>
    )
  }
};

export const TEXT_ALIGNMENT_RENDERER_ARRAY = Object.entries(TEXT_ALIGNMENTS)
  .map(([key, { Renderer }]) => ({
    value: key,
    Renderer
  }));
