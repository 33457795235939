import React from 'react';
import { SettingsPanelWrapper, SettingsPanelListItem } from '@jotforminc/panel-components';
import SettingsNavigationPanelItem from './SettingsNavigationPanelItem';
import { getSettingsNavigationProperties } from '../constants';
import ScSettingsNavigationPanel from './ScSettingsNavigationPanel';
import { useIfMobileSettings } from '../../../../../utils/navigation';

const SettingsNavigationPanel = () => {
  const renderNavigationPanel = () => {
    return getSettingsNavigationProperties()?.map(({ visibility, ...navigationItemProps }) => {
      if (visibility === false) {
        return null;
      }
      return (
        <SettingsPanelListItem key={navigationItemProps.key}>
          <SettingsNavigationPanelItem
            {...navigationItemProps}
          />
        </SettingsPanelListItem>
      );
    });
  };

  const isMobileSubPage = useIfMobileSettings();
  return (
    <ScSettingsNavigationPanel
      className="settingsNavigationPanel newPanel"
      isMobileSubPage={isMobileSubPage}
    >
      <SettingsPanelWrapper>
        {renderNavigationPanel()}
      </SettingsPanelWrapper>
    </ScSettingsNavigationPanel>
  );
};

export default SettingsNavigationPanel;
