import React from 'react';
import { func, node } from 'prop-types';

const DefaultContainerRenderer = ({ children, onMouseLeave }) => <div onMouseLeave={onMouseLeave}>{children}</div>;

DefaultContainerRenderer.propTypes = {
  children: node.isRequired,
  onMouseLeave: func
};

DefaultContainerRenderer.defaultProps = {
  onMouseLeave: f => f
};

export default DefaultContainerRenderer;
