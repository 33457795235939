import { t } from '@jotforminc/translation';
import { ResettableInput as JFResettableInput } from '@jotforminc/jotform-common';
import React, { useCallback } from 'react';
import { elementType, func, string } from 'prop-types';
import { IconArrowRotateRight } from '@jotforminc/svg-icons';
import Button from '../../../../components/Button';
import { ScResettableInput } from './ScResettableInput';

const ResettableInput = ({
  value,
  defaultValue,
  placeholder,
  onChange,
  HintRenderer
}) => {
  const ResetButtonRenderer = useCallback(({ onClick, ...resetButtonProps }) => {
    const onResetButtonClick = () => {
      onClick();
      onChange('');
    };
    return (
      <Button
        className="bare"
        name={t('Sync with original')}
        onClick={onResetButtonClick}
        {...resetButtonProps}
      >
        <IconArrowRotateRight width="16px" color="#fff" />
      </Button>
    );
  }, []);

  const InputWrapperRenderer = useCallback(wrapperProps => {
    return <div className="textInput-Wrapper">{wrapperProps.children}</div>;
  }, []);

  return (
    <ScResettableInput>
      <JFResettableInput
        value={value}
        defaultValue={defaultValue}
        showHint={true}
        placeholder={placeholder}
        onChange={onChange}
        HintRenderer={HintRenderer}
        InputWrapperRenderer={InputWrapperRenderer}
        ResetButtonRenderer={ResetButtonRenderer}
        maxLength="150"
      />
    </ScResettableInput>
  );
};

ResettableInput.propTypes = {
  value: string,
  defaultValue: string,
  placeholder: string,
  onChange: func,
  HintRenderer: elementType
};

ResettableInput.defaultProps = {
  value: '',
  defaultValue: '',
  placeholder: '',
  onChange: f => f,
  // eslint-disable-next-line react/prop-types
  HintRenderer: ({ defaulValue }) => <div>{defaulValue}</div>
};

export default ResettableInput;
