import React from 'react';
import cn from 'classnames';
import { string } from 'prop-types';
import Styled from 'styled-components';
import Image from './Image';

const ScTeamAvatar = Styled.div`
  min-width: 17px;
  height: 17px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 17px;
    height: 17px;
    object-fit: cover;
  }

 .jfIconSVG-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 17px;
    height: 17px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .emoji-mart-emoji span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TeamAvatar = props => {
  const { iconBgColor, url, icon } = props;

  return (
    <ScTeamAvatar
      className={cn('teamcover-avatar-wrapper', { hasBgColor: !url && icon && iconBgColor })}
      style={{ backgroundColor: iconBgColor }}
    >
      <Image {...props} />
    </ScTeamAvatar>
  );
};

TeamAvatar.propTypes = {
  iconBgColor: string,
  url: string,
  icon: string
};

TeamAvatar.defaultProps = {
  iconBgColor: '',
  url: '',
  icon: ''
};

export default TeamAvatar;
