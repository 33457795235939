import React from 'react';
import { cx } from 'class-variance-authority';

import { DialogIconProps, dialogIconDefaultProps } from './dialog.types';
import { dialogIconCVA } from './dialog.cva';

export const DialogIcon = (props: DialogIconProps): JSX.Element => {
  const {
    icon: Icon,
    colorStyle,
    className,
    ...rest
  } = props;

  const iconClassName = cx(className, dialogIconCVA({
    colorStyle
  }));

  return (
    <div {...rest} className={iconClassName}>
      <Icon className="w-8 h-8 xs:h-10 xs:w-10" />
    </div>
  );
};

DialogIcon.defaultProps = dialogIconDefaultProps;
