import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import MultiSectionSelect from '../MultiSectionSelect';
import { OPTION_VALUES, defaultDropdownSections } from './constants';
import {
  isOptionDefault, decidePropType, selectSubfield as selectSubfieldDefault
} from './helper';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';

const ListItemSideItemSelection = ({
  id,
  onChange,
  value: propValue,
  itemBgColor,
  itemBorderColor,
  itemFontColor,
  description,
  selectSubfield,
  dropdownSections,
  excludedDefaultDsOptions
}) => {
  const propLocation = decidePropType(id);

  const _activeOption = {
    option: { value: propValue.type },
    value: propValue.data
  };

  const { option: activeOption = {}, value: activeValue } = _activeOption;

  const activeSection = dropdownSections.find(
    section => section.options.some(option => option.value === activeOption.value)
  );

  const activeOptionProps = activeSection?.options.find(option => option.value === activeOption.value && option.hasSubfield);

  const {
    hasSubfield = null, subfieldLabel = null, label = null, title = null
  } = activeOptionProps ?? {};
  const onSelectedFieldChange = val => {
    onChange({ type: activeOption.value, data: val });
  };

  const onOptionChange = ({ option, value }) => {
    const { data } = propValue;
    onChange({ type: option.value, data: option.value === OPTION_VALUES.ICON ? value : value ?? data });
  };

  const [SelectedField, selectedFieldProps] = selectSubfield({
    type: activeOption.value,
    value: activeValue,
    onChange: onSelectedFieldChange,
    propLocation,
    excludedDefaultDsOptions
  });

  const { defaultSelection = null } = selectedFieldProps ?? {};

  useEffect(() => {
    if (defaultSelection) {
      onSelectedFieldChange({ ...defaultSelection });
    }
  }, [defaultSelection, activeValue]);

  return (
    <>
      {!!description && <PanelItemDescriptionRenderer description={description} />}
      <div className='mt-2'>
        <MultiSectionSelect
          sections={dropdownSections}
          value={_activeOption}
          onChange={onOptionChange}
          isNoneAvailable
        />
        {hasSubfield && !isOptionDefault(activeOption.value) && (title || label || subfieldLabel) && <span className='flex mt-6 mb-2'>{t(title ?? subfieldLabel ?? label)}</span>}
        <SelectedField
          onChange={onSelectedFieldChange}
          value={activeValue}
          colorScheme={{ itemBgColor, itemBorderColor, itemFontColor }}
          id={id}
          {...(selectedFieldProps || {})}
        />
      </div>
    </>
  );
};

ListItemSideItemSelection.propTypes = {
  onChange: propTypes.func,
  value: propTypes.object,
  id: propTypes.string.isRequired,
  itemBgColor: propTypes.string,
  itemBorderColor: propTypes.string,
  itemFontColor: propTypes.string,
  description: propTypes.string,
  selectSubfield: propTypes.func,
  dropdownSections: propTypes.array,
  excludedDefaultDsOptions: propTypes.array
};

ListItemSideItemSelection.defaultProps = {
  onChange: f => f,
  value: {},
  itemBgColor: '',
  itemBorderColor: '',
  itemFontColor: '',
  description: '',
  selectSubfield: selectSubfieldDefault,
  dropdownSections: defaultDropdownSections,
  excludedDefaultDsOptions: []
};

export default ListItemSideItemSelection;
