import { t } from '@jotforminc/translation';
import * as COMPONENT_NAMES from '../../../constants/componentNames';
import { isYes } from '../../../utils';

type DropdownOptionsType = { key?: string, text: string; value: string; }[];

type DividerElementType = {
  type: string,
  labelProps: {
    class: string
  }
}

type TextInputElementType = {
  type: string,
  labelProps: {
    label: string,
    class: string
  },
  componentProps: {
    defaultValue: string,
    hintOn: string,
  },
  condition: () => boolean
}

type ToggleElementType = {
  type: string,
  labelProps: {
    label: string,
    description: string,
    class: string
  },
  componentProps: {
    isSelected: boolean
  }
}

type DropdownElementType = {
  type: string,
  labelProps: {
    label: string,
    description: string,
    class: string
  },
  componentProps: {
    selectedOptionValues: string,
    options: DropdownOptionsType,
    dropdownWrapperClass: string,
    dropdownInputClass: string,
    dropdownTheme: string
  }
}

type InputRangeElementType = {
  type: string,
  noLabel: boolean,
  componentProps: {
    currencyInfo: string,
    minLimit: string,
    maxLimit: string,
    minKey: string,
    maxKey: string,
    onCustomChange: (data: {key: string, value: string}) => void,
    inputClass: string
  },
  condition: () => boolean
}

type InputRangeWithTypeElementType = {
  type: string,
  noLabel: boolean,
  componentProps: {
    currencyInfo: string,
    entryLimit: string,
    entryLimitMin: string,
    limitTypeOptions?: DropdownOptionsType,
    limitDescription?: string,
    onCustomChange: (data: {key: string, value: string}) => void,
    inputClass: string
  },
  condition: () => boolean
}

type subfieldOptionsElementType = {
  type: string,
  labelProps: {
    label: string,
    description: string,
    class: string
  },
  componentProps: {
    subfieldOrder: string,
    sublabels: any,
    subfields: string,
    states: string,
    onCellChange: (value: any, id: any) => void,
    onSort: (value: any) => void,
    onCheckbox: (value: any) => void,
    disabled: boolean
  }
}

type statesType = {
  type: string,
  labelProps: {
    label: string,
    description: string,
    class: string
  },
  componentProps: {
    defaultValue: string,
    options: DropdownOptionsType
  },
  condition?: () => boolean
};

type MaxSizeElementType = {
  type: string,
  noLabel: boolean,
  componentProps: {
    type: string,
    value: string,
    suffix: string,
    parentClass: string
  },
  condition: () => boolean
}

type TextAreaElementType = {
  type: string,
  labelProps: {
    label: string,
    description: string,
    class: string
  },
  componentProps: {
    defaultValue: string
    inputClass: string,
    onBlur: (e: any) => void,
    disabled?: boolean
  }
}

type ElementType = {
  text?: TextInputElementType,
  countryCode?: ToggleElementType,
  validation?: DropdownElementType,
  characterLimit?: ToggleElementType,
  characterLimitRange?: InputRangeElementType,
  entryLimitRange?: InputRangeElementType,
  entryLimitRangeWithType?: InputRangeWithTypeElementType,
  divider?: DividerElementType,
  divider2?: DividerElementType,
  divider3?: DividerElementType,
  divider4?: DividerElementType,
  divider5?: DividerElementType,
  divider6?: DividerElementType,
  required?: ToggleElementType,
  subfieldOptions?: subfieldOptionsElementType,
  states?: statesType,
  middle?: ToggleElementType,
  prefix?: ToggleElementType,
  suffix?: ToggleElementType,
  entryLimits?: ToggleElementType,
  entryLimit?: ToggleElementType,
  entryLimitToggle?: ToggleElementType,
  disallowFree?: ToggleElementType,
  maxsize?: MaxSizeElementType,
  options?: TextAreaElementType,
  special?: DropdownElementType,
  allowOther?: ToggleElementType,
  otherText?: TextInputElementType,
  hidden?: ToggleElementType
};

const toggleClassname = `pt-6 pb-5 px-4 w-full mb-0 flex justify-between items-center p-${COMPONENT_NAMES.TOGGLE}`;
const textInputClassname = `pt-6 pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`;

const getValidationDropdownOpions = () => ([
  { text: t('None'), value: 'None' },
  { text: t('Alphabetic'), value: 'Alphabetic' },
  { text: t('Alphanumeric'), value: 'Alphanumeric' },
  { text: t('Currency'), value: 'Currency' },
  { text: t('Cyrillic'), value: 'Cyrillic' },
  { text: t('Email'), value: 'Email' },
  { text: t('Numeric'), value: 'Numeric' },
  { text: t('URL'), value: 'Url' }
]);

const getPredefinedOptDropdownOptions = () => ([
  { text: t('None'), value: 'None' },
  { text: t('Gender'), value: 'Gender' },
  { text: t('Days'), value: 'Days' },
  { text: t('Months'), value: 'Months' }
]);

const getInputRangeLimitTypeDropdownOptions = () => ([{ text: t('Words'), value: 'Words' }, { text: t('Letters'), value: 'Letters' }]);

const dropdownElement = (label: string, description: string, selectedOptionValues: string, options: DropdownOptionsType) => (
  {
    type: COMPONENT_NAMES.NEWDROPDOWN,
    labelProps: {
      label: label,
      description: description,
      class: `pt-6 pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.NEWDROPDOWN}`
    },
    componentProps: {
      selectedOptionValues: selectedOptionValues,
      options: options,
      dropdownInputClass: 'portal-new-dropdown-button w-full color-white mt-5 ',
      dropdownWrapperClass: 'portal-new-dropdown-wrapper',
      dropdownTheme: 'dark'
    }
  }
);

const toggleElement = (label: string, description: string, isSelected: boolean, isReadOnly = false, valueTrue?: string, valueFalse?: string) => (
  {
    type: COMPONENT_NAMES.TOGGLE,
    labelProps: {
      label: label,
      description: description,
      class: toggleClassname
    },
    componentProps: {
      isSelected: isSelected,
      readOnly: isReadOnly,
      valueTrue,
      valueFalse
    }
  }
);

const requiredElement = (isSelected: boolean, isReadOnly = false): ToggleElementType => (
  toggleElement(t('Required'), t('Prevent submission if this field is empty'), isSelected, isReadOnly)
);

const hideFieldElement = (isSelected: boolean, isReadOnly = false) => (
  toggleElement(t('Hide Field'), '', isSelected, isReadOnly)
);

const textInputElement = (label: string, defaultValue: string, isSelected = true, hintOn = '', className = textInputClassname): TextInputElementType => (
  {
    type: COMPONENT_NAMES.TEXTINPUT,
    labelProps: {
      label: label,
      class: className
    },
    componentProps: {
      defaultValue: defaultValue,
      hintOn: hintOn
    },
    condition: () => isSelected
  }
);

const fieldLabelElement = (defaultValue: string): TextInputElementType => (
  textInputElement(t('Field Label'), defaultValue)
);

const inputMaskElements = (description: string, isSelected: boolean, toggledInputDefVal = '', inputMaskPropValue: string, valueTrue?: string, valueFalse?: string) => (
  {
    inputMask: toggleElement(t('Input Mask'), description, isSelected, false, valueTrue, valueFalse),
    [inputMaskPropValue]: textInputElement('', toggledInputDefVal, isSelected, '', `pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`)
  }
);

const textAreaElement = (label: string, description: string, defaultValue: string, onBlur: (e: any) => void, disabled = false): TextAreaElementType => (
  {
    type: COMPONENT_NAMES.TEXTAREA,
    labelProps: {
      label: label,
      description: description,
      class: 'w-11/12 m-4'
    },
    componentProps: {
      defaultValue: defaultValue,
      onBlur: onBlur,
      inputClass: 'w-full h-28 my-2 p-2 bg-gray-500',
      disabled: disabled
    }
  }
);

const inputRangeElement = (
  minLimit: string,
  maxLimit: string,
  handlePropChange: (data: {key: string, value: string}) => void,
  isSelected: boolean,
  minKey = 'minsize',
  maxKey = 'maxsize'
): InputRangeElementType => (
  {
    type: COMPONENT_NAMES.INPUT_RANGE,
    noLabel: true,
    componentProps: {
      currencyInfo: '',
      minLimit: minLimit,
      maxLimit: maxLimit,
      minKey,
      maxKey,
      onCustomChange: handlePropChange,
      inputClass: 'pt-0 pb-5 px-4 w-full mb-0'
    },
    condition: () => isSelected
  }
);

const inputRangeWithTypeElement = (
  entryLimit: string,
  entryLimitMin: string,
  handlePropChange: (data: {key: string, value: string}) => void,
  isSelected: boolean,
  limitDescription = '',
  limitTypeOptions = getInputRangeLimitTypeDropdownOptions()
): InputRangeWithTypeElementType => (
  {
    type: COMPONENT_NAMES.INPUT_RANGE_WITH_TYPE,
    noLabel: true,
    componentProps: {
      currencyInfo: '',
      entryLimit,
      entryLimitMin,
      limitTypeOptions: limitTypeOptions,
      limitDescription: limitDescription,
      onCustomChange: handlePropChange,
      inputClass: 'pt-0 pb-5 px-4 w-full mb-0'
    },
    condition: () => isSelected
  }
);

const dividerElement: DividerElementType = {
  type: COMPONENT_NAMES.DIVIDER,
  labelProps: {
    class: 'lineHr block border-transparent w-full m-0'
  }
};

// eslint-disable-next-line complexity
export const getElementList = (props: {[key: string]: string}, handlePropChange: (obj: { key: string, value: string }) => void, requiredElementsQuestionIds: string[] = []): ElementType => {
  switch (props.type) {
    case 'control_phone':
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        countryCode: toggleElement(t('Country Code'), t('Include a number field for country code'), isYes(props.countryCode)),
        divider2: dividerElement,
        ...inputMaskElements(t('Enter # for numbers'), isYes(props.inputMask), props.inputMaskValue, 'inputMaskValue', 'enable', 'disable'),
        divider3: dividerElement,
        required: requiredElement(isYes(props.required)),
        divider4: dividerElement,
        hidden: hideFieldElement(isYes(props.hidden))
      };
    case 'control_textbox':
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        validation: dropdownElement(t('Validation'), t('Require entries to match a certain format'), props.validation === 'Fill Mask' ? '' : props.validation, getValidationDropdownOpions()),
        divider2: dividerElement,
        characterLimit: toggleElement(t('Character Limit'), t('Limit the number of characters allowed for this field'), (isYes(props.characterLimit) || !!props.minsize || !!props.maxsize)),
        characterLimitRange: inputRangeElement(props.minsize, props.maxsize, handlePropChange, (isYes(props.characterLimit) || !!props.minsize || !!props.maxsize)),
        divider3: dividerElement,
        ...inputMaskElements(
          `<p class="inputMask-text">${t('Restrict users to match the format you specify.')}</p>
          <p class="inputMask-text">${t('Use <strong>@</strong> symbol to mask letters, <strong>#</strong> for numbers and <strong>*</strong> for both.')}</p>
          <p class="inputMask-text">
            ${t('<a href="https://www.jotform.com/help/381-Getting-Started-with-the-basics-of-Input-Masking" target="_blank" class="color-blue-400">Learn more about Input Masks</a>.')}
          </p>`,
          isYes(props.inputMask) || !!props.inputTextMask, props.inputTextMask, 'inputTextMask'
        ),
        divider4: dividerElement,
        required: requiredElement(isYes(props.required)),
        divider5: dividerElement,
        hidden: hideFieldElement(isYes(props.hidden))
      };
    case 'control_address':
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        subfieldOptions: {
          type: COMPONENT_NAMES.LABELVALUETABLE,
          labelProps: {
            label: t('Subfield Options'),
            description: t('Select which fields to include on your form and edit their sublabels'),
            class: 'pt-6 pb-5 px-4 block w-full'
          },
          componentProps: {
            subfieldOrder: props.subfieldOrder,
            subfields: props.subfields,
            states: props.states,
            sublabels: props.sublabels,
            onCellChange: (value: any) => handlePropChange({ key: 'sublabels', value }),
            onCheckbox: (value: string) => handlePropChange({ key: 'subfields', value }),
            onSort: (value: string) => handlePropChange({ key: 'subfieldOrder', value }),
            disabled: requiredElementsQuestionIds.includes(props.qid)
          }
        },
        divider2: dividerElement,
        states: {
          type: COMPONENT_NAMES.RADIOGROUP,
          labelProps: {
            label: t('State Options'),
            description: t('Give your users a text box or predefined options'),
            class: 'pt-6 pb-5 px-4 block w-full'
          },
          componentProps: {
            defaultValue: props.states,
            options: [
              { key: 'textbox', value: 'textbox', text: 'Text Box' },
              { key: 'americanStates', value: 'americanStates', text: 'American States' }
            ]
          },
          condition: () => props.subfields.split('|').includes('state')
        },
        divider3: dividerElement,
        required: requiredElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.required), requiredElementsQuestionIds.includes(props.qid)),
        divider4: dividerElement,
        hidden: hideFieldElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.hidden), requiredElementsQuestionIds.includes(props.qid))
      };
    case 'control_fullname': {
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        middle: toggleElement(t('Middle Name'), t('Let users enter a middle name'), isYes(props.middle)),
        divider2: dividerElement,
        prefix: toggleElement(t('Prefix'), t('Let users enter a title before their name'), isYes(props.prefix)),
        divider3: dividerElement,
        suffix: toggleElement(t('Suffix'), t('Let users enter a title after their name'), isYes(props.suffix)),
        divider4: dividerElement,
        required: requiredElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.required), requiredElementsQuestionIds.includes(props.qid)),
        divider5: dividerElement,
        hidden: hideFieldElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.hidden), requiredElementsQuestionIds.includes(props.qid))
      };
    }
    case 'control_email': {
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        entryLimits: toggleElement(
          t('Entry Limits'),
          t('Limit the maximum number of characters'),
          props.entryLimits ? isYes(props.entryLimits) : !!props.maxsize,
          false,
          'Yes',
          'No'
        ),
        maxsize: {
          type: COMPONENT_NAMES.SUFFIXINPUT,
          noLabel: true,
          componentProps: {
            type: 'number',
            value: props.maxsize,
            suffix: 'CHARS',
            parentClass: 'suffixInput'
          },
          condition: () => { return props.entryLimits ? isYes(props.entryLimits) : !!props.maxsize; }
        },
        divider2: dividerElement,
        disallowFree: toggleElement(t('Disallow Free Addresses'), t('Don’t accept free email addresses'), isYes(props.disallowFree)),
        divider3: dividerElement,
        required: requiredElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.required), requiredElementsQuestionIds.includes(props.qid)),
        divider4: dividerElement,
        hidden: hideFieldElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.hidden), requiredElementsQuestionIds.includes(props.qid))
      };
    }
    case 'control_radio': {
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        options: textAreaElement(
          t('Options'), t('Give options for users to select from. Enter each option on a new line.'), props.options.replaceAll('|', '\n'),
          (e: any) => handlePropChange({ key: 'options', value: e.target.value.replaceAll('\n', '|') }), (!!props.special && props.special !== 'None')
        ),
        divider2: dividerElement,
        special: dropdownElement(t('Predefined Options'), t('Select a ready-made list of options (e.g., days, months, etc.)'), props.special, getPredefinedOptDropdownOptions()),
        divider3: dividerElement,
        allowOther: toggleElement(t('Display Other Option'), t('Allow users to enter text when their selection is not available.'), isYes(props.allowOther)),
        otherText: textInputElement('', props.otherText, isYes(props.allowOther), t('Other Text'), `pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`),
        divider4: dividerElement,
        required: requiredElement(isYes(props.required)),
        divider5: dividerElement,
        hidden: hideFieldElement(isYes(props.hidden))
      };
    }
    case 'control_textarea': {
      const isEntryLimitSelected = props.entryLimit !== 'None-0' || isYes(props.entryLimitToggle);
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        entryLimitToggle: toggleElement(t('Entry Limits'), t('Limit the minimum or maximum amount of text allowed'), isEntryLimitSelected),
        entryLimitRangeWithType: inputRangeWithTypeElement(props.entryLimit, props.entryLimitMin, handlePropChange, isEntryLimitSelected, t('Limit Type'), getInputRangeLimitTypeDropdownOptions()),
        divider2: dividerElement,
        required: requiredElement(isYes(props.required)),
        divider3: dividerElement,
        hidden: hideFieldElement(isYes(props.hidden))
      };
    }
    case 'control_checkbox': {
      const isEntryLimitSelected = isYes(props.entryLimit) || !!props.minSelection || !!props.maxSelection;
      return {
        text: fieldLabelElement(props.text),
        divider: dividerElement,
        options: textAreaElement(
          t('Options'), t('Give options for users to select from. Enter each option on a new line.'), props.options.replaceAll('|', '\n'),
          (e: any) => handlePropChange({ key: 'options', value: e.target.value.replaceAll('\n', '|') }), (!!props.special && props.special !== 'None')
        ),
        divider2: dividerElement,
        special: dropdownElement(t('Predefined Options'), t('Select a ready-made list of options (e.g., days, months, etc.)'), props.special, getPredefinedOptDropdownOptions()),
        divider3: dividerElement,
        allowOther: toggleElement(t('Display Other Option'), t('Allow users to enter text when their selection is not available.'), isYes(props.allowOther)),
        otherText: textInputElement('', props.otherText, isYes(props.allowOther), t('Other Text'), `pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`),
        divider4: dividerElement,
        entryLimit: toggleElement(t('Entry Limits'), t('Limit the minimum or maximum amount of text allowed'), isEntryLimitSelected),
        entryLimitRange: inputRangeElement(props.minSelection, props.maxSelection, handlePropChange, isEntryLimitSelected, 'minSelection', 'maxSelection'),
        divider5: dividerElement,
        required: requiredElement(isYes(props.required)),
        divider6: dividerElement,
        hidden: hideFieldElement(isYes(props.hidden))
      };
    }
    default:
      return { };
  }
};
