import React from 'react';
import { func, string } from 'prop-types';

import { Schema as CommonSchema } from '@jotforminc/jotform-common';

const Schema = ({ value, onChange }) => {
  return (
    <CommonSchema
      onChange={onChange}
      value={value}
      definition={'Testimonial\ntext:Label\nimage:Image\ntextarea:Testimonial'} // todo ???
    />
  );
};

Schema.propTypes = {
  onChange: func,
  value: string
};
Schema.defaultProps = {
  onChange: f => f,
  value: ''
};

export default Schema;
