import { AriaAttributes, MouseEvent } from 'react';
import { OffsetOptions, Placement } from '@floating-ui/react';
import { SketchPickerProps } from 'react-color';
import { Size, Theme } from '../../constants/common.types';
import { DataAttrs } from '../../types/system.types';

export type ColorPickerProps = {
  disabled?: boolean,
  size?: Size,
  rounded?: boolean,
  placement?: Placement,
  offset?: OffsetOptions,
  theme?: Theme,
  onChange?: (color: string) => void,
  color?: string,
  custom?: boolean,
  className?: string
  zIndex?: number,
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void,
  buttonAttrs?: DataAttrs & AriaAttributes & {
    id: string
  },
  pickerAttrs?: DataAttrs & AriaAttributes & {
    id?: string
  }
} & Pick<SketchPickerProps, 'presetColors' | 'onSwatchHover' | 'disableAlpha'>

export const colorPickerDefaultProps:Partial<ColorPickerProps> = {
  size: 'medium',
  placement: 'bottom-end',
  offset: 6,
  rounded: false,
  disableAlpha: false,
  theme: 'light',
  custom: false,
  zIndex: 1005
};
