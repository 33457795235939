import React, {
  ComponentPropsWithRef, ElementType, forwardRef
} from 'react';
import { cx } from 'class-variance-authority';
import { PolymorphicProps } from '../../types/system.types';
import { GridComponentType, GridProps } from './grid.types';
import { convertResponsiveClass } from '../../utils';
import { justifyContentClasses } from '../../constants/common.cva';

import '@jotforminc/jotform.css';
import {
  gridTemplateColumnsClasses
} from './grid.cva';

export const Grid:GridComponentType = forwardRef(
  <Element extends ElementType>(
    props: PolymorphicProps<Element, GridProps>,
    ref?: ComponentPropsWithRef<Element>['ref']
  ): JSX.Element => {
    const {
      as = 'div',
      justifyContent,
      columns,
      children,
      className,
      ...rest
    } = props;

    const Component = as;
    const gridClasses = [
      ...convertResponsiveClass(justifyContent, justifyContentClasses),
      ...convertResponsiveClass(columns, gridTemplateColumnsClasses)
    ];

    return (
      <Component {...rest} ref={ref} className={cx('grid', ...gridClasses, className)}>{children}</Component>
    );
  }
);
