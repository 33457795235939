import SchemaImageUpload from './components/SchemaImageUpload';
import SchemaTextArea from './components/SchemaTextArea';
import SchemaTextInput from './components/SchemaTextInput';
import SchemaDropdown from './components/SchemaDropdown';

export const getFieldComponent = type => {
  const mapper = {
    text: SchemaTextInput,
    image: SchemaImageUpload,
    textarea: SchemaTextArea,
    dropdown: SchemaDropdown
  };
  return mapper[type];
};
