import React from 'react';
import {
  number, func, bool, shape
} from 'prop-types';

import '../styles/Spinner.scss';

export const Spinner = ({
  value,
  onChange,
  range,
  allowManuelChange,
  spinnerProps,
  maxValue,
  minValue
}) => {
  const handleChange = type => (e = {}) => {
    switch (type) {
      case 'UP':
        const calculatedValue = value + range;
        return (calculatedValue <= maxValue) && onChange(calculatedValue);
      case 'DOWN':
        return (value >= minValue) && onChange(value - range);
      default:
        const { value: newValue } = e.target;
        return onChange(newValue);
    }
  };

  const isValueSmall = value < minValue;
  const isValueBig = value > maxValue;
  const isValueOnRange = (value <= maxValue) && (value >= minValue);

  const calculateValue = () => {
    if (isValueOnRange) return value;

    if (isValueSmall) return minValue;

    if (isValueBig) return maxValue;
  };

  const inputValue = calculateValue();

  const handleSpinnerClick = e => e.stopPropagation();
  // it is for trash but not now
  // const lastItem = value.toString() === '1' ? 'lastItem' : '';
  return (
    <div className="form-spinner" onClick={handleSpinnerClick}>
      <div className="form-spinner-input-wrapper">
        <input
          type="number"
          data-type="input-spinner"
          value={inputValue?.toString()}
          className="form-spinner-input form-textbox"
          readOnly={!allowManuelChange}
          onChange={f => f}
          {...spinnerProps}
        />
      </div>
      <div className="form-spinner-button-container">
        <button type="button" className="form-spinner-button form-spinner-up" onClick={handleChange('UP')} />
        <button type="button" className="form-spinner-button form-spinner-down" onClick={handleChange('DOWN')} />
        {/* eslint-enable */}
      </div>
    </div>
  );
};

Spinner.propTypes = {
  value: number,
  onChange: func,
  range: number,
  allowManuelChange: bool,
  spinnerProps: shape({}),
  maxValue: number,
  minValue: number
};

Spinner.defaultProps = {
  value: 1,
  onChange: f => f,
  range: 1,
  allowManuelChange: true,
  spinnerProps: {},
  maxValue: 10,
  minValue: 1
};
