import React, { Component } from 'react';
import { t } from '@jotforminc/translation';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import '../styles/index.scss';

class ErrorPage extends Component {
  render() {
    return (
      <div className="jfErrorPage-wrapper" data-component="Container" data-testid="error-page-root">
        <div className="jfErrorPage">
          <div className="jfErrorPage-container">
            <div className="jfErrorPage-content">
              <div className="jfErrorPage-icon-container">
                <IconExclamationTriangleFilled />
              </div>
              <div className="jfErrorPage-title">
                {t('Something went wrong')}
              </div>
              <div className="jfErrorPage-highlight">
                {t('Please refresh the page to try again.')}
              </div>
              <button
                data-component="Button_Button"
                type="button"
                onClick={() => window.location.reload()}
                className="jfErrorPage-action"
              >
                {t('Refresh')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
