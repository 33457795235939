import React, { useCallback } from 'react';
import { string } from 'prop-types';
import { useNavigate } from '@reach/router';
import { getAppPath } from '@jotforminc/router-bridge';
import { t } from '@jotforminc/translation';
import { ErrorScreen as CommonErrorScreen } from '@jotforminc/jotform-common';
import { handleCustomNavigation } from '@jotforminc/utils';
import { Button } from '@jotforminc/magnet';
import { PORTAL_ERROR_MAP } from '../../constants';
import { useAppURL } from '../../utils/hooks';

const DEFAULT_CUSTOM_ERROR_TEXT = t('An unexpected error occurred.');

const createAnApp = () => {
  const paths = window.location.pathname.split('/').slice(1);
  if (paths[0] === 'app' && ['create', 'build'].includes(paths[1])) handleCustomNavigation(`${getAppPath()}/`, '_self');
};

const redirectToLoginPage = () => {
  handleCustomNavigation(`/login?rp=${global.location.href}`, '_self', true);
};

const ERROR_MESSAGE_MAP = {
  [PORTAL_ERROR_MAP.FETCH[404]]: {
    title: t('App not found.'),
    desc: t('We\'re sorry, but the app you’re looking for cannot be found.'),
    button: false
  },
  UNKNOWN_ERROR: {
    title: t('Something went wrong.'),
    desc: t('We\'re working to fix the problem. Please refresh the page and try again.'),
    button: {
      text: t('Refresh'),
      colorStyle: 'primary',
      onClick: () => window.location.reload()
    }
  },
  FORM_NOT_FOUND: {
    title: t('Form not found.'),
    desc: t('We\'re sorry, but the form you’re looking for cannot be found.'),
    button: {
      text: t('Go To App'),
      colorStyle: 'primary',
      shouldBackToApp: true
    }
  },
  REPORT_NOT_FOUND: {
    title: t('Report not found.'),
    desc: t('We\'re sorry, but the report you’re looking for cannot be found.'),
    button: {
      text: t('Go To App'),
      colorStyle: 'primary',
      shouldBackToApp: true
    }
  },
  TABLES_NOT_FOUND: {
    title: t('Table not found.'),
    desc: t('We\'re sorry, but the report you’re looking for cannot be found.'),
    button: {
      text: t('Go To App'),
      colorStyle: 'primary',
      shouldBackToApp: true
    }
  },
  SIGN_NOT_FOUND: {
    title: t('Sign document not found.'),
    desc: t('We are sorry but the sign document you are looking for cannot be found.'),
    button: {
      text: t('Go To App'),
      colorStyle: 'primary',
      shouldBackToApp: true
    }
  },
  UNKNOWN_FORM_ERROR: {
    title: t('Page not found.'),
    desc: t('Unfortunately the page you\'re looking for cannot be found.'),
    button: {
      text: t('Go To App'),
      colorStyle: 'primary',
      shouldBackToApp: true
    }
  },
  DISABLED_APP: {
    title: t('App is disabled.')
  },
  DELETED_APP: {
    title: t('App is deleted.'),
    desc: t('Please contact with the app owner.')
  },
  CREATE_APP: {
    title: t('App not found.'),
    desc: t('We\'re sorry, but the app you’re looking for cannot be found.'),
    button: {
      text: t('Create your own'),
      colorStyle: 'primary',
      onClick: createAnApp
    }
  },
  CREATE_APP_UNKNOWN: {
    title: t('Something went wrong.'),
    desc: t('We\'re sorry, but the form you want to create an app with cannot be found.'),
    button: {
      text: t('Create your own'),
      colorStyle: 'primary',
      onClick: createAnApp
    }
  },
  CREATE_APP_RATE_LIMIT: {
    title: t('Rate limit reached.'),
    desc: t('We\'re sorry, but app creation failed. Please try again later.'),
    button: {
      text: t('Try Again'),
      colorStyle: 'primary',
      onClick: () => window.location.reload()
    }
  },
  CREATE_APP_RATE_LIMIT_GUEST: {
    title: t('Rate limit reached.'),
    desc: t('We\'re sorry, but app creation failed. You can log in to create an app.'),
    button: {
      text: t('Go to Login Page'),
      colorStyle: 'primary',
      onClick: redirectToLoginPage
    }
  },
  BUILDER_APP_NOT_FOUND: {
    title: t('App not found.'),
    desc: t('We\'re sorry, but the app you’re looking for cannot be found.'),
    button: {
      text: t('Create your own'),
      colorStyle: 'primary',
      onClick: createAnApp
    }
  },
  OFFLINE: {
    title: t('No internet connection.'),
    desc: t('Please connect to the internet and try again.')
  }
};

export function ErrorScreen({ errorType, customErrorText = '' }) {
  const navigate = useNavigate();
  const {
    title,
    desc,
    button
  } = ERROR_MESSAGE_MAP[errorType];

  const {
    shouldBackToApp, onClick, className, colorStyle
  } = button || {};
  const appURL = useAppURL();

  const handleNavigateApp = useCallback(() => {
    navigate(appURL);
    window.location.reload();
  }, [appURL]);

  const buttonHandler = shouldBackToApp ? handleNavigateApp : onClick;

  const ButtonRenderer = () => {
    return button ? (
      <Button colorStyle={colorStyle} onClick={buttonHandler} className={`${className} mt-7`}>{button.text}</Button>
    ) : null;
  };

  return (
    <CommonErrorScreen
      title={title}
      description={desc || customErrorText}
      ButtonRenderer={ButtonRenderer}
    />
  );
}

ErrorScreen.propTypes = {
  errorType: string,
  customErrorText: string
};

ErrorScreen.defaultProps = {
  errorType: 'UNKNOWN_ERROR',
  customErrorText: DEFAULT_CUSTOM_ERROR_TEXT
};
