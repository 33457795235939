import { ABTestManager } from '@jotforminc/abtest-manager';

export const AB_TESTS = {
  ShowFormFromFormBuilder: 'appShowFormFromFormBuilder',
  AiAssistant: 'appAiAssistant'
};

export const AB_TEST_MANAGERS = {
  [AB_TESTS.ShowFormFromFormBuilder]: user => {
    return new ABTestManager({
      user,
      isTestEnabled: true,
      testName: 'appsFormDisplayType',
      urlParam: 'asfffb',
      controlVariantCode: '26802',
      testVariantCode: '26812',
      cacheVariantCodeAtLocalStorage: true
    });
  },
  [AB_TESTS.AiAssistant]: user => {
    return new ABTestManager({
      user,
      isTestEnabled: true,
      testName: 'appBuilderCopilotBeta',
      urlParam: 'appBuilderCopilotBeta',
      controlVariantCode: '28461',
      testVariantCode: '28471',
      cacheVariantCodeAtLocalStorage: true
    });
  }
};
