import Styled from 'styled-components';

/* eslint max-len: "off" */
const FormItemWrapper = Styled.div`
display: flex;
align-items: center;
position: relative;
cursor: pointer;
transition:
  background-color 0.15s ease,
  border 0.15s ease,
  transform 10ms linear;
text-decoration: none;
border-radius: .25em;
font-family: "Circular", "Circular Std", "Circular Std Bold", "Work Sans SemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

padding: ${p => p.theme.itemPadding};
line-height: ${p => p.theme.lineHeight};
background: ${p => p.theme.itemBg};
border: 1px solid ${p => p.theme.itemBorder};

&:hover:not(.isDisabled) {
  background-color: ${p => p.theme.itemBgFocus};

  .itemActions {
    display: block;
  }
}

[aria-selected="true"] & {
  border-color: ${p => p.theme.itemBorderSelected};
  background-color: ${p => p.theme.itemBgSelected};

  &:hover:not(.isDisabled) {
    background-color: ${p => p.theme.itemBgSelectedHover};
  }
}

&:focus {
  outline: 0;
}

.itemContent {
  text-align: left;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemTitle {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  font-weight: ${p => p.theme.titleFontWeight};
  font-size: ${p => p.theme.titleFontSize};
  color: ${p => p.theme.titleColor};

  @media screen and (max-width: 600px) {
    white-space: normal;
  }
}

.itemDesc {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-weight: ${p => p.theme.detailsFontWeight};
  font-size: ${p => p.theme.detailsFontSize};
  color: ${p => p.theme.detailsColor};

  span {
    color: #D93434;
    font-weight: 500;
  }
}

&.isDisabled {
  cursor: default;
  background-color: #FAFAFB;
  border-color: #EBECF3;

  &:hover .itemDisabled {
    opacity: 1;
  }

  .itemInfo, .itemContent {
    opacity: .5;
  }
}

.itemDisabled {
  font-size: 13px;
  padding: 11px;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 16px;
  background-color: #23283A;
  border-radius: 3px;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: .3s;
}

.itemInfo {
  &-formType {
    width: 38px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='32' viewBox='0 0 26 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.533333' y='0.533333' width='24.9333' height='30.9333' rx='3.73333' fill='white' stroke='%23B1C3E9' stroke-width='1.06667'/%3E%3Cpath d='M5 10.6908C5 9.75699 5.78756 9 6.75906 9H19.6589C20.6304 9 21.4179 9.75699 21.4179 10.6908V10.6908C21.4179 11.6246 20.6304 12.3816 19.6589 12.3816H6.75906C5.78756 12.3816 5 11.6246 5 10.6908V10.6908Z' fill='%230099FF'/%3E%3Cpath d='M5 16.3265C5 15.3927 5.78756 14.6357 6.75906 14.6357H19.6589C20.6304 14.6357 21.4179 15.3927 21.4179 16.3265V16.3265C21.4179 17.2603 20.6304 18.0173 19.6589 18.0173H6.75906C5.78756 18.0173 5 17.2603 5 16.3265V16.3265Z' fill='%23FF6100'/%3E%3Cpath d='M5 21.9625C5 21.0287 5.78756 20.2717 6.75906 20.2717H19.6589C20.6304 20.2717 21.4179 21.0287 21.4179 21.9625V21.9625C21.4179 22.8963 20.6304 23.6532 19.6589 23.6532H6.75906C5.78756 23.6532 5 22.8963 5 21.9625V21.9625Z' fill='%23FFB629'/%3E%3C/svg%3E%0A");

    &.isCard {
      background-image: url("data:image/svg+xml,%3Csvg width='38' height='22' viewBox='0 0 38 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='3.5' width='20' height='15' rx='3.5' fill='white' stroke='%23B1C3E9'/%3E%3Crect x='17.5' y='3.5' width='20' height='15' rx='3.5' fill='white' stroke='%23B1C3E9'/%3E%3Crect x='3' y='10' width='13' height='3' rx='1.5' fill='%230099FF'/%3E%3Crect x='22' y='10' width='13' height='3' rx='1.5' fill='%23FFB629'/%3E%3Crect x='7.5' y='0.5' width='23' height='21' rx='3.5' fill='white' stroke='%23B1C3E9'/%3E%3Cpath d='M11.364 8.32266C11.364 7.44722 12.0737 6.73754 12.9491 6.73754H24.5733C25.4487 6.73754 26.1584 7.44722 26.1584 8.32266V8.32266C26.1584 9.19809 25.4487 9.90777 24.5733 9.90777H12.9491C12.0737 9.90777 11.364 9.19809 11.364 8.32266V8.32266Z' fill='%23FF6100'/%3E%3Cpath d='M11.364 13.6063C11.364 12.7309 12.0737 12.0212 12.9491 12.0212H24.5733C25.4487 12.0212 26.1584 12.7309 26.1584 13.6063V13.6063C26.1584 14.4818 25.4487 15.1915 24.5733 15.1915H12.9491C12.0737 15.1915 11.364 14.4818 11.364 13.6063V13.6063Z' fill='%23FF6100'/%3E%3C/svg%3E%0A");
    }

    &.isSheet {
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='31' height='31' rx='2.5' fill='white' stroke='%23DADEF3'/%3E%3Crect x='3.5' y='5.5' width='7' height='5' rx='1' fill='%230099FF'/%3E%3Crect x='3.5' y='12.5' width='7' height='7' rx='1' fill='%230099FF'/%3E%3Crect x='3.5' y='21.5' width='7' height='7' rx='1' fill='%230099FF'/%3E%3Crect x='12.5' y='5.5' width='7' height='5' rx='1' fill='%23FF6100'/%3E%3Crect x='12.5' y='12.5' width='7' height='7' rx='1' fill='%23FF6100'/%3E%3Crect x='12.5' y='21.5' width='7' height='7' rx='1' fill='%23FF6100'/%3E%3Crect x='21.5' y='5.5' width='7' height='5' rx='1' fill='%23FFB629'/%3E%3Crect x='21.5' y='12.5' width='7' height='7' rx='1' fill='%23FFB629'/%3E%3Crect x='21.5' y='21.5' width='7' height='7' rx='1' fill='%23FFB629'/%3E%3C/svg%3E%0A");
    }

    &.isPaymentForm {
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%230A1551' d='M20.487 17.1c-1.013-.382-1.356-.611-1.356-.916 0-.352.322-.547.907-.547.369 0 .712.05 1.051.157a.765.765 0 0 0 .97-.543l.014-.042a.817.817 0 0 0-.585-.997 4.919 4.919 0 0 0-.988-.152v-.352a.71.71 0 0 0-1.416 0v.45c-1.259.283-2.03 1.127-2.03 2.229 0 1.378 1.195 1.954 2.234 2.306.852.288 1.182.547 1.182.924 0 .386-.381.62-1.025.62-.433 0-.874-.081-1.315-.238a.746.746 0 0 0-.975.517l-.025.093a.837.837 0 0 0 .555 1.005c.407.131.87.216 1.319.246v.432a.71.71 0 0 0 1.415 0v-.53c1.298-.263 2.129-1.161 2.129-2.314.017-1.132-.607-1.836-2.06-2.349Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M6 1h12a3 3 0 0 1 3 3v8.303a2.568 2.568 0 0 0-3.545 1.199c-1.074.64-1.855 1.739-1.855 3.208 0 .761.219 1.404.556 1.927-.187.258-.326.55-.408.863l-.02.075-.004.014a2.67 2.67 0 0 0 1.84 3.229c.041.062.084.123.13.182H6a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3Z' clip-rule='evenodd'/%3E%3Cpath fill='%23C8CEED' fill-rule='evenodd' d='M6 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h11.693a2.574 2.574 0 0 1-.128-.182A2.646 2.646 0 0 1 16.289 22H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8.008c.36.03.698.133 1 .295V4a3 3 0 0 0-3-3H6Z' clip-rule='evenodd'/%3E%3Cpath fill='%23FFB629' fill-rule='evenodd' d='M16.171 14.72H7.64a1.14 1.14 0 0 0 0 2.28h7.97a3.879 3.879 0 0 1-.01-.29c0-.77.213-1.437.571-1.99Z' clip-rule='evenodd'/%3E%3Cpath fill='%2309F' d='M6.5 8.14C6.5 7.51 7.01 7 7.64 7h8.72a1.14 1.14 0 0 1 0 2.28H7.64c-.63 0-1.14-.51-1.14-1.14Z'/%3E%3Cpath fill='%23FF6100' d='M6.5 12c0-.63.51-1.14 1.14-1.14h8.72a1.14 1.14 0 0 1 0 2.28H7.64c-.63 0-1.14-.51-1.14-1.14Z'/%3E%3C/svg%3E");
    }

    &.isCard.isPaymentForm {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' class='w-32 h-32'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M4 16h1.17A3.001 3.001 0 0 0 8 18h7.332A3.946 3.946 0 0 1 15 16.387c0-1.69.928-2.952 2.215-3.662A2.71 2.71 0 0 1 22 12.219V10a2 2 0 0 0-2-2h-1.17A3.001 3.001 0 0 0 16 6H8a3.001 3.001 0 0 0-2.83 2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2Z' clip-rule='evenodd'/%3E%3Cpath fill='%2309F' d='M4 12a.9.9 0 0 1 .9-.9H5v1.8h-.1A.9.9 0 0 1 4 12Z'/%3E%3Cpath fill='%23C8CEED' fill-rule='evenodd' d='M8 7h8a2 2 0 0 1 2 2v2.632a2.702 2.702 0 0 1 1-.53V9h1a1 1 0 0 1 1 1v1.312c.405.213.749.527 1 .907V10a2 2 0 0 0-2-2h-1.17A3.001 3.001 0 0 0 16 6H8a3.001 3.001 0 0 0-2.83 2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1.17A3.001 3.001 0 0 0 8 18h7.332a3.846 3.846 0 0 1-.288-1H8a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2ZM4 9h1v6H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Z' clip-rule='evenodd'/%3E%3Cpath fill='%23FF6100' fill-rule='evenodd' d='M7.5 10a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h7c.322 0 .61.153.792.39A4.01 4.01 0 0 0 15.23 15H8.5a1 1 0 0 1-1-1Z' clip-rule='evenodd'/%3E%3Cpath fill='%230A1551' d='M20.487 17.1c-1.013-.382-1.356-.611-1.356-.916 0-.352.322-.547.907-.547.369 0 .712.05 1.051.157a.765.765 0 0 0 .97-.543l.014-.042a.817.817 0 0 0-.585-.997 4.919 4.919 0 0 0-.988-.152v-.352a.71.71 0 0 0-1.416 0v.45c-1.259.283-2.03 1.127-2.03 2.229 0 1.378 1.195 1.954 2.234 2.306.852.288 1.182.547 1.182.924 0 .386-.381.62-1.025.62-.433 0-.874-.081-1.315-.238a.746.746 0 0 0-.975.517l-.025.093a.837.837 0 0 0 .555 1.005c.407.131.87.216 1.319.246v.432a.71.71 0 0 0 1.415 0v-.53c1.298-.263 2.129-1.161 2.129-2.314.017-1.132-.607-1.836-2.06-2.349Z'/%3E%3C/svg%3E");
    }
  }
}
`;

export default FormItemWrapper;
