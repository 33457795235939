import React, { useCallback, useState } from 'react';
import {
  elementType, func, number, oneOfType, string
} from 'prop-types';
import Styled from 'styled-components';
import { t } from '@jotforminc/translation';
import { isLetterKey } from '@jotforminc/payment-settings-editor';
import TextInput from '@jotforminc/text-input';
import RadioToggle from '../../../RadioToggle';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import { rightPanelItemBorderColor, rightPanelItemBgColor } from '../../../../../../styles/colors';
import { useItemDefaults } from '../../../../../../properties';
import { ITEM_TYPES } from '../../../../../../constants/itemTypes';
import { isYes } from '../../../../../../utils';
import ScCheckboxItem from '../../../../styles/ScCheckboxItem';
import { ScRightPanelDropdown } from '../../../../styles/scRightPanel';
import Dropdown from '../../../../../../components/Dropdown';
import { embeddedFormThankYouPageDisplaySecondOptions } from '../../constants';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';

const { embeddedFormHeight: defaultEmbeddedFormHeight, embeddedFormThankYouPageDisplaySecond: defaultEmbeddedFormThankYouPageDisplaySecond } = useItemDefaults(ITEM_TYPES.FORM);

const ScShowFormToggle = Styled.div`
  .heightTitle {
    margin: 20px 0 10px 0;
  }

  .heightInputRow {
    display: flex;

    .inputWrapper {
      position: relative;
      width: 100%;

      div {
        border: none;
        height: 38px;

        input {
          border: 1px solid ${rightPanelItemBorderColor};
          border-radius: 4px;
          padding: 9px 42px 9px 11px;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
      }

      span {
        color: #8C9AAC;
        position: absolute;
        right: 1px;
        top: 0;
        width: 36px;
        height: 38px;
        border-left: 1px solid ${rightPanelItemBorderColor};
        line-height: 36px;
        text-align: center;
      }
    }

    .resetButton {
      background: ${rightPanelItemBgColor};
      border: 1px solid ${rightPanelItemBorderColor};
      border-radius: 4px;
      margin-left: 10px;
    }
  }

  .thankYouTitle {
    display: block;
    margin: 34px 0 0;

    ~ div > .dropdown-button {
      margin-top: 15px;
    }
  }
`;

const ShowFormToggle = ({
  title, description, DescriptionRenderer, value: embeddedForm, embeddedFormHeight, onChange, showEmbeddedFormFullHeight: _showEmbeddedFormFullHeight,
  formType, embeddedFormThankYouPageDisplaySecond, status
}) => {
  const [showEmbeddedFormFullHeight, setShowEmbeddedFormFullHeight] = useState(formType === 'LEGACY' && _showEmbeddedFormFullHeight);
  const handleEmbeddedFormChange = useCallback(value => {
    onChange({ embeddedForm: value, ...isYes(value) && { shrink: 'No' } });
  }, [onChange]);

  const isNotAvailable = ['DELETED', 'DISABLED'].includes(status);

  const handleEmbeddedFormHeightChange = useCallback(val => onChange({ embeddedFormHeight: val }), [onChange]);

  const handleEmbeddedFormThankYouPageDisplaySecondChange = useCallback(val => onChange({ embeddedFormThankYouPageDisplaySecond: val }), [onChange]);

  const handleInputFocus = useCallback(e => e.target.select(), []);

  const handleFormHeightCheckboxChange = useCallback(({ target: { checked } }) => {
    onChange({ showEmbeddedFormFullHeight: checked ? 'Yes' : 'No' });
    setShowEmbeddedFormFullHeight(checked ? 'Yes' : 'No');
  }, []);

  const showFullSizeCheckbox = formType === 'LEGACY' && isYes(embeddedForm);

  const showCustomHeightInput = isYes(embeddedForm) && !isYes(showEmbeddedFormFullHeight);

  const handleKeydown = e => isLetterKey(e) && e.preventDefault();
  return (
    <ScShowFormToggle>
      <RadioToggle
        value={embeddedForm}
        onChange={handleEmbeddedFormChange}
        title={title}
        readOnly={isNotAvailable}
        tooltipText={isNotAvailable && t('This can’t be enabled If form is disabled or deleted')}
        description={description}
        DescriptionRenderer={DescriptionRenderer}
      />
      <>
        {showFullSizeCheckbox && (
          <>
            <ScCheckboxItem
              onClick={handleFormHeightCheckboxChange}
              checked={isYes(showEmbeddedFormFullHeight)}
            >
              {t('Display form in full height.')}
            </ScCheckboxItem>

            {isFeatureEnabled(FEATURE_NAMES.ThankYouPageDisplayTime) && (
              <>
                <label className="thankYouTitle" htmlFor="embeddedFormThankYouPageDisplaySecond">{t('Thank You Page Display Time')}</label>
                <DescriptionRenderer description={t('Set the Thank You Page display time.')} />
                <Dropdown
                  id="embeddedFormThankYouPageDisplaySecond"
                  onChange={handleEmbeddedFormThankYouPageDisplaySecondChange}
                  defaultValue={parseInt(embeddedFormThankYouPageDisplaySecond, 10)}
                  options={embeddedFormThankYouPageDisplaySecondOptions}
                  DropdownWrapperRenderer={ScRightPanelDropdown}
                />
              </>
            )}
          </>
        )}
        {showCustomHeightInput && (
          <>
            <div className="heightTitle">{t('Height')}</div>
            <div className="heightInputRow">
              <div className="inputWrapper">
                <TextInput
                  value={embeddedFormHeight}
                  onChange={handleEmbeddedFormHeightChange}
                  onFocus={handleInputFocus}
                  onKeyDown={handleKeydown}
                  className="textInput"
                  type="number"
                />
                <span>{t('PX')}</span>
              </div>
            </div>
          </>
        )}
      </>
    </ScShowFormToggle>
  );
};

ShowFormToggle.propTypes = {
  title: string,
  description: string,
  DescriptionRenderer: elementType,
  value: string,
  onChange: func,
  embeddedFormHeight: string,
  showEmbeddedFormFullHeight: string,
  embeddedFormThankYouPageDisplaySecond: oneOfType([number, string]),
  formType: string,
  status: string
};

ShowFormToggle.defaultProps = {
  title: '',
  description: '',
  DescriptionRenderer: PanelItemDescriptionRenderer,
  value: 'No',
  onChange: f => f,
  embeddedFormHeight: defaultEmbeddedFormHeight,
  embeddedFormThankYouPageDisplaySecond: defaultEmbeddedFormThankYouPageDisplaySecond,
  showEmbeddedFormFullHeight: '',
  formType: 'LEGACY',
  status: 'ENABLED'
};

export default ShowFormToggle;
