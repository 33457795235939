import { createSelector } from 'reselect';
import { FeaturedWidgetIDs, OtherWidgetIDs, PageElementWidgetIDs } from '../../constants/availableWidgets';

export const getWidgetsSelector = state => state.widgets;
export const getFeaturedWidgetsSelector = createSelector(getWidgetsSelector, allWidgets => allWidgets.filter(({ client_id: clientID }) => FeaturedWidgetIDs.includes(clientID)).sort(
  (a, b) => FeaturedWidgetIDs.indexOf(a.client_id) - FeaturedWidgetIDs.indexOf(b.client_id)
));
export const getPageWidgetsSelector = createSelector(getWidgetsSelector, allWidgets => allWidgets.filter(({ client_id: clientID }) => PageElementWidgetIDs.includes(clientID)));
export const getOtherWidgetsSelector = createSelector(getWidgetsSelector, allWidgets => allWidgets.filter(({ client_id: clientID }) => OtherWidgetIDs.includes(clientID)).sort(
  (a, b) => OtherWidgetIDs.indexOf(a.client_id) - OtherWidgetIDs.indexOf(b.client_id)
));
export const getWidgetByClientIDSelector = id => createSelector(getWidgetsSelector, widgets => widgets.find(({ client_id: clientID }) => id === clientID) || {});
