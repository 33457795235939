import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export const combinedInfo = () => layer.get('user/combinedinfo?loc=1&campaign=1');
export const systemPlans = () => layer.get('system/plans');
export const trackAction = data => layer.post('t/jfrev', data, {
  headers: {
    'Content-Type': 'application/json'
  }
});
