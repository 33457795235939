import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortableHandle } from 'react-sortable-hoc';
import { IconGridDotsVertical } from '@jotforminc/svg-icons';
import { Checkbox } from '@jotforminc/magnet';
import DataGridCell from './DataGridCell';

const getCellClassByType = type => {
  switch (type) {
    case 'textInput':
      return 'bg-gray-400 pl-2';
    case 'label':
    default:
      return 'bg-gray-600';
  }
};

const DragHandle = SortableHandle(() => <IconGridDotsVertical className="fill-gray-300" width="16" height="16" />);

const DataGridRow = ({
  rowItems, columns, showCheckbox, showHandle, onCheckbox, checked
}) => {
  const columnKeys = columns.map(({ id }) => id);
  const { id: rowId } = rowItems;

  return (
    <div className="dataGrid-row flex gap-2 items-center bg-gray-600 color-white text-sm pl-2">
      {showHandle && <DragHandle />}
      {showCheckbox
        && (
        <Checkbox
          checked={checked}
          id={`dataGrid-checkbox-${rowId}`}
          name={`dataGrid-checkbox-${rowId}`}
          value={`dataGrid-checkbox-${rowId}`}
          size="small"
          theme="dark"
          onChange={e => onCheckbox(e.target.checked)}
        />
        )}
      <>
        {Object.entries(rowItems)
          .sort(([r1], [r2]) => columnKeys.indexOf(r1) - columnKeys.indexOf(r2)) // sort by column order
          .map(([key, value]) => {
            if (columnKeys.includes(key)) {
              const { type, cellProps, width } = columns.find(({ id }) => id === key);
              return (
                <DataGridCell
                  key={key}
                  type={type}
                  id={rowId}
                  value={value}
                  disabled={!checked}
                  className={classNames({
                    'color-gray-200': !checked,
                    'flex-1 basis-0': !width
                  }, `py-3 ${getCellClassByType(type)}`)}
                  style={{ width }}
                  {...cellProps}
                />
              );
            }
            return null;
          })}
      </>
    </div>
  );
};

DataGridRow.propTypes = {
  rowItems: PropTypes.shape({ id: PropTypes.string }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  showHandle: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheckbox: PropTypes.func
};

DataGridRow.defaultProps = {
  onCheckbox: () => {}
};

export default DataGridRow;
