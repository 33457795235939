import { objectEntries } from './data';

export const autoTranslateSingleValue = value => {
  if (typeof value.locale === 'function') {
    return value.locale();
  }
  return value;
};

export const autoTranslate = translationObject => {
  const translatedObject = {};

  objectEntries(translationObject).forEach(item => {
    const [key, value] = item;
    translatedObject[key] = autoTranslateSingleValue(value);
  });
  return translatedObject;
};
