export const isRGB = color => {
  return typeof color === 'string' && (color?.slice(0, 4) === 'rgb(' || color?.slice(0, 5) === 'rgba(') && color?.slice(-1) === ')';
};

export const isHexColor = color => {
  const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  return hexColorRegex.test(color);
};

export const rgbToHex = rgbVal => {
  let rgb = rgbVal.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  rgb = rgb[2] | (rgb[1] << 8) | (rgb[0] << 16); // eslint-disable-line no-bitwise
  return `#${(0x1000000 | rgb).toString(16).substring(1)}`; // eslint-disable-line no-bitwise
};

export const isBrightColor = sourceColor => {
  if (sourceColor && sourceColor.length > 0) {
    const color = (sourceColor.charAt(0) === '#') ? sourceColor.substring(1, 7) : sourceColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 160);
  }
  return false;
};
