import Styled from 'styled-components';

const entering = 'calc(100% + 12px);';
const exiting = 'calc(100% + 4px);';

export const ScBuilderStageContainer = Styled.div`
  width: 100%;
  position: relative;
  ${({ isMultiPage }) => (isMultiPage ? 'height: calc(100% - 63px);' : 'height: 100%;')}

  .toolbox-anim-enter {
    left: ${exiting};
    opacity: 0;
  }
  .toolbox-anim-enter-active, .toolbox-anim-enter-done {
    opacity: 1;
    left: ${entering};
    transition: all 0.3s;
  }
  .toolbox-anim-exit {
    left: ${entering};
    opacity: 1;
  }
  .toolbox-anim-exit-active {
    left: ${exiting};
    opacity: 0;
    transition: all 0.3s;
  }
  .toolbox-anim-appear {
    left: ${exiting}
    opacity: 0;
  }
  .toolbox-anim-appear-active, .toolbox-anim-appear-done {
    opacity: 1;
    left: ${entering};
    transition: all 0.3s;
  }

  &.previewPanelOpen {
  }

  @media screen and (min-width: 480px) and (max-width: 1200px) {
    &.rpOpen, &.lpOpen {
      .toolbox-anim-enter {
        top: calc(100% + 4px);
        left: 50%;
        opacity: 0;
      }
      .toolbox-anim-enter-active, .toolbox-anim-enter-done {
        opacity: 1;
        top: calc(100% + 12px);
        left: 50%;
        transition: all 0.3s;
        height: 0;
      }
      .toolbox-anim-exit {
        top: calc(100% + 12px);
        left: 50%;
        opacity: 1;
      }
      .toolbox-anim-exit-active {
        top: calc(100% + 4px);
        left: 50%;
        opacity: 0;
        transition: all 0.3s;
      }
      .toolbox-anim-appear {
        top: calc(100% + 4px);
        left: 50%;
        opacity: 0;
      }
      .toolbox-anim-appear-active, .toolbox-anim-appear-done {
        opacity: 1;
        top: calc(100% + 12px);
        left: 50%;
        transition: all 0.3s;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .toolbox-anim-enter {
      top: calc(100% + 4px);
      left: 50%;
      opacity: 0;
    }
    .toolbox-anim-enter-active, .toolbox-anim-enter-done {
      opacity: 1;
      top: calc(100% + 12px);
      left: 50%;
      transition: all 0.3s;
      height: 0;
    }
    .toolbox-anim-exit {
      top: calc(100% + 12px);
      left: 50%;
      opacity: 1;
    }
    .toolbox-anim-exit-active {
      top: calc(100% + 4px);
      left: 50%;
      opacity: 0;
      transition: all 0.3s;
    }
    .toolbox-anim-appear {
      top: calc(100% + 4px);
      left: 50%;
      opacity: 0;
    }
    .toolbox-anim-appear-active, .toolbox-anim-appear-done {
      opacity: 1;
      top: calc(100% + 12px);
      left: 50%;
      transition: all 0.3s;
    }
  }
`;

export const ScSelectFormCont = Styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  & > div:not([data-uikit-modal-container="true"]) {
    & > .cb-body {
      justify-content: flex-end;
    }
    & > .cb-searchCont {
      display: none;
    }
  }

  .cb-status {
    display: none !important;
  }

  .cb-addForm {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.625em;
    border-radius: 2px;
    border: solid 1px #d8dae9;
    background-color: #f7f7f8;
    font-size: 0.875em;
    color: #2c3345;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0.4em;
    }
  }

  .addMoreFormsButton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  .formListContainer {
    li {
      list-style: none;
      position: relative;

      &.shrink {
        height: 100%;
      }

      & + li {
         &.hovered {
           &.hovered-above{
             margin-top: 32px;
           }
         }
      }

      &.hovered {
        &.hovered-above{
          margin-top: 32px;
        }
        &.hovered-below{
          margin-bottom: 32px;
        }
      }
    }
  }
`;

export const ScFormTemplates = Styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    padding: 1em 4%;
  }
  .title { font-size: 1.75em; font-weight: 700; }
  .desc { color: #8d8fa8; line-height: 1.5; }

  .iframe-cont {
    flex: 1 1 auto;
    transition: opacity .3s ease;
    opacity: 0;
    max-height: 0;
    min-height: 40vh;

    &.isLoaded {
      opacity: 1;
      max-height: unset;
    }
  }
  iframe { height: 100%; width: 100%; }
`;
