import { t } from '@jotforminc/translation';
import { usePrevious } from '@jotforminc/hooks';
import { array, func, string } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import Styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import WarningBox from '@jotforminc/warning-box';
import { Button } from '@jotforminc/magnet';
import { IconPlusSm } from '@jotforminc/svg-icons';
import PageSelection from './PageSelection';
import * as ACTION_CREATORS from '../../../../../../../../store/actionCreators';
import SELECTORS from '../../../../../../../../store/selectors';

const ScPageSelection = Styled.div`
  & > div {
    color: #FFB448;
    margin-bottom: 10px;
  }
`;

const ButtonPageSelection = ({ onChange, value, _pages }) => {
  const dispatch = useDispatch();
  const pages = _pages ?? useSelector(SELECTORS.getPagesWithNames);

  const pagesDropdownOptions = (!_pages ? pages.filter(page => !page?.linkedItemID) : pages).map(({ id, name }) => ({ text: name, value: id }));

  const previousPages = usePrevious(pages);

  useEffect(() => {
    if (previousPages && !previousPages.length && pages.length) onChange('homepage');
  }, [pages]);

  const handleAddNewPage = useCallback(() => dispatch(ACTION_CREATORS.addNewPageAction({ dontSelect: true })), []);

  return pages.length > 1
    ? <PageSelection onChange={onChange} value={value} options={pagesDropdownOptions} />
    : (
      <ScPageSelection>
        <WarningBox
          isLongText
          size='medium'
        >
          {t('You must have more than one page in your app to use this feature.')}
        </WarningBox>
        <Button
          className="customMagnetBtn"
          variant="outline"
          fullWidth
          onClick={handleAddNewPage}
          startIcon={IconPlusSm}
        >
          {t('Add a Page')}
        </Button>
      </ScPageSelection>
    );
};

ButtonPageSelection.propTypes = {
  value: string,
  onChange: func,
  _pages: array
};

ButtonPageSelection.defaultProps = {
  value: '',
  onChange: f => f,
  _pages: null
};

export default ButtonPageSelection;
