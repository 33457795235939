import Styled from 'styled-components';

export const ScDimensionPicker = Styled.div`
  display: flex;
  justify-content: space-between;
  .dimention-input-wrapper {
    position: relative;
  }
  .text-input {
    width: 156px;
  }
  .lock-icon-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    &:before,
    &:after {
      content: "";
      display: flex;
      width: 8px;
      height: 1px;
      border-top: 1px solid #fff;
    }
  }
  .suffix {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 40px;
    top: 0;
    right: 0;
    border-left: 1px solid var(--jfv-panel-darkest-gray);
  }

  .hint {
    color: #949fae;
    margin: 0;
    margin-top: 3px;
  }
`;
