import { cva } from 'class-variance-authority';
import { CVAType } from '../../types/system.types';
import { ColorPickerProps } from './color-picker.types';
import { primary } from '../../tokens/colors';

type ColorPickerButtonCVAType = CVAType<Pick<ColorPickerProps, 'size' | 'rounded' | 'theme'>>

export const colorPickerButtonCVA = cva<ColorPickerButtonCVAType>('magnet-colorpicker-button inline-flex shrink-0 border border-opacity-20', {
  variants: {
    size: {
      small: 'w-5 h-5',
      medium: 'w-6 h-6',
      large: 'w-8 h-8'
    },
    rounded: {
      true: 'radius-full',
      false: 'radius'
    },
    theme: {
      light: primary.border.darkest.base,
      dark: 'border-gray-25'
    }
  }
});
