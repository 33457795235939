import React from 'react';
import { bool, node, func } from 'prop-types';
import { IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import '../../styles/_jfHeaderFormList.scss';
import { useKeyboardEvent } from '../../utils/hooks';

const ConditionalModal = ({
  isModal,
  isModalOpen,
  isClosable,
  onClose,
  children
}) => {
  useKeyboardEvent(['Escape'], () => isModalOpen && onClose());

  return (isModal ? (
    <div className={`jNewHeaderFormList${isModalOpen ? ' isActive' : ''}`}>
      <div className="jNewHeaderFormList-container">
        {children}
      </div>
      {isClosable && (
        <button
          className="jNewHeaderFormList-close"
          onClick={onClose} type="button"
          aria-label={t('Close')}
        >
          <div className="jNewHeaderFormList-closeIcon">
            <IconXmark />
          </div>
        </button>
      )}
    </div>
  ) : children);
};

ConditionalModal.propTypes = {
  isModal: bool,
  isModalOpen: bool,
  isClosable: bool,
  onClose: func,
  children: node.isRequired
};

ConditionalModal.defaultProps = {
  isModal: false,
  isModalOpen: false,
  isClosable: true,
  onClose: f => f
};

export default ConditionalModal;
