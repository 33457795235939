import React, { lazy } from 'react';
import { render } from 'react-dom';
import Tracking from '@jotforminc/tracking';
import { ErrorPage } from '../components';

// For future needs
// const getChunkName = name => {
//   const startIndex = name.indexOf('|') + 2;
//   const endIndex = name.indexOf('*/') - 1;
//   return (startIndex && endIndex)
//     ? name.substr(startIndex, endIndex - startIndex)
//     : 'generic';
// };

const safeLazy = componentImport => lazy(async () => {
  try {
    const component = await componentImport();
    return component;
  } catch (error) {
    Tracking.configureScope(scope => {
      scope.addBreadcrumb({
        message: 'Failed to load lazy script',
        category: 'editor',
        level: 'error'
      });
    });
    Tracking.captureException(error);
    let root = [...document.body.children].find(elem => elem._reactRootContainer);
    if (!root) { // as a fallback
      root = document.createElement('div');
      root.className = 'safe-lazy-root';
      document.body.appendChild(root);
    }
    render(<ErrorPage />, root);
    throw error;
  }
});

export default safeLazy;
