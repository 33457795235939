export const isNativeMobileApp = () => {
  try {
    const userAgent = global.navigator?.userAgent || '';

    return ['JotForm Mobile', 'JFCEMobile'].some(app => userAgent.includes(app));
  } catch (error) {
    return false;
  }
};

export const trackDataForMobile = (action = '', target = '') => {
  window.sendMessageToJFMobile?.({ type: 'trackData', value: [action, target] });
};
