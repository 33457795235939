import React, { useRef, useState, useEffect } from 'react';
import {
  elementType, shape, arrayOf, string, func, bool
} from 'prop-types';
import {
  TextInput as UIKitTextInput, Popover, SelectionGroup, Hooks
} from '@jotforminc/uikit';
import { useFuse } from '@jotforminc/hooks';

import { ScTextInput } from './styles';

const TextInput = ({
  type,
  value,
  defaultValue,
  suggestionList,
  suggestionListSearchKeys,
  fuseOptions,
  containerClassName,
  TextInputRenderer,
  SuggestionRenderer,
  onChange: baseOnChange,
  unstyled,
  ...remainingProps
}) => {
  const popoverRef = useRef();
  const textInputRef = useRef();

  const [isDirty, setDirty] = useState(false);
  const [textInputValue, setTextInputValue] = useState(value || defaultValue);
  const foundSuggestionList = useFuse(
    suggestionList,
    typeof textInputValue?.toString === 'function' ? textInputValue.toString() : '',
    suggestionListSearchKeys,
    fuseOptions
  );
  const [isSuggestionListVisible, setSuggestionVisibility] = Hooks.useClickOutsideState(false, [popoverRef, textInputRef]);

  const handleTextInputChange = event => {
    const { target: { value: tmpValue } } = event;
    setTextInputValue(tmpValue);
    setDirty(true);

    if (baseOnChange) {
      baseOnChange(tmpValue, event);
    }
  };

  const handleSelectionChange = selectedSuggestionValue => {
    setTextInputValue(selectedSuggestionValue);
    setSuggestionVisibility(false);
    setDirty(false);

    if (baseOnChange) {
      baseOnChange(selectedSuggestionValue);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== textInputValue) {
      setTextInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    setSuggestionVisibility(Boolean(textInputValue && foundSuggestionList.length > 0 && isDirty));
  }, [textInputValue, foundSuggestionList, isDirty]);

  return (
    <ScTextInput
      className={containerClassName}
      unstyled={unstyled}
    >
      <TextInputRenderer
        type={type}
        ref={textInputRef}
        value={textInputValue}
        onChange={handleTextInputChange}
        {...remainingProps}
      />
      {
        isSuggestionListVisible && (
          <Popover
            ref={popoverRef}
            targetRef={textInputRef}
            style={{ zIndex: 10 }}
            popoverOptions={{ placement: 'bottom-start' }}
          >
            <SuggestionRenderer
              onClick={handleSelectionChange}
              suggestions={foundSuggestionList}
            />
          </Popover>
        )
      }
    </ScTextInput>
  );
};

TextInput.propTypes = {
  type: string,
  value: string,
  defaultValue: string,
  unstyled: bool,
  suggestionListSearchKeys: arrayOf(string),
  suggestionList: arrayOf(shape({})),
  fuseOptions: shape({}),
  onChange: func,
  containerClassName: string,
  TextInputRenderer: elementType,
  SuggestionRenderer: elementType
};

TextInput.defaultProps = {
  type: 'text',
  value: undefined,
  defaultValue: '',
  unstyled: false,
  suggestionList: [],
  suggestionListSearchKeys: ['text', 'value'],
  fuseOptions: { threshold: 0.3, shouldSort: true },
  onChange: f => f,
  containerClassName: '',
  TextInputRenderer: UIKitTextInput,
  // eslint-disable-next-line react/prop-types
  SuggestionRenderer: ({ suggestions, onClick, ...props }) => <SelectionGroup options={suggestions} onSelectionChange={onClick} {...props} />
};

export default TextInput;
