import type { GATEWAY_DEFAULT_SANDBOX_PROP_TYPES, GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES } from '@jotforminc/payment-constants';

/**
 * Array of strings representing enabled sandbox values.
 * These values are used to determine if a sandbox is enabled.
 */
const enabledSandboxes = ['true', 'Enabled', 'Yes', 'enabled', 'sandbox'];

/**
 * Converts the value of a sandbox property to a string representation of '0', '1', or '2'.
 *
 * @param sandboxProp - The sandbox property to convert.
 * @param value - The value of the sandbox property.
 * @returns The converted value as '0', '1', or '2'.
 */
export const sandboxPostDataConverter = (
  sandboxProp: GATEWAY_DEFAULT_SANDBOX_PROP_TYPES,
  value: GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES
): '0' | '1' | '2' => {
  switch (sandboxProp) {
    case 'allowTest':
    case 'sandbox':
    case 'testMode':
    case 'testmodeAuth':
      return enabledSandboxes.includes(value) ? '1' : '0';
    case 'environment':
      if (enabledSandboxes.includes(value)) {
        return '1';
      }

      return value === 'development' ? '2' : '0';
    default:
      return '1';
  }
};
