import { createSelector } from 'reselect';
import { getTeamID } from '../../utils';
import { getPortalOwner } from './portalSelectors';

export const getUser = state => state.user;
export const getUserSlug = createSelector(getUser, user => user.slug);
export const getIsAdmin = createSelector(getUser,
  ({ account_type: accountType }) => accountType
  && (accountType.name === 'ADMIN' || accountType.name === 'SUPPORT' || accountType.name === 'HELPDESK'));
export const getUsername = createSelector(getUser, user => user?.username);
export const selectIsUserLoggedIn = createSelector(getUser, user => user.USER_TYPE && !!user.email);
export const getUserTimezone = createSelector(getUser, user => user?.time_zone);
export const getMainSlug = createSelector(getUserSlug, userSlug => (getTeamID() ? window?.__team?.slug : userSlug));
export const getPaymentOverQuotaWarnings = createSelector(getUser, user => user?.paymentOverQuotaWarnings);
export const getIsAppFirstUser = createSelector(getUser, user => user?.isAppFirstUser);
export const getIsReusableConnectionEnabled = createSelector(getUser, () => window?.isPaymentReusableConnection || false);

export const getApprovedUsersForForm = createSelector([getUser, getPortalOwner], ({ username: loggedInUser }, portalUser) => {
  return Array.from(new Set([...[loggedInUser, portalUser]]));
});
export const getIsPaymentOverQuota = createSelector(getPaymentOverQuotaWarnings, paymentOverQuotaWarnings => {
  const overLimit = paymentOverQuotaWarnings?.data?.accountLimit <= paymentOverQuotaWarnings?.data?.usage;
  const shouldShowWarning = (paymentOverQuotaWarnings?.display !== 'hide') && (paymentOverQuotaWarnings?.color === 'red');
  return overLimit && shouldShowWarning; // double check is better than single check :D
});

export const getOverQuotaWarningProps = createSelector(getPaymentOverQuotaWarnings, paymentOverQuotaWarnings => ({
  term: paymentOverQuotaWarnings?.term || '',
  content: paymentOverQuotaWarnings?.content || '',
  campaign: paymentOverQuotaWarnings?.content || ''
}));

export const getOverQuotaUpgradeLink = createSelector([getOverQuotaWarningProps, getIsPaymentOverQuota], (overQuotaProps, isPaymentOverQuota) => {
  if (!isPaymentOverQuota) {
    return '';
  }

  const { term, content, campaign } = overQuotaProps;

  return `/pricing/?utm_source=appbuilder_warnings&utm_medium=banner&utm_term=${term}&utm_content=${content}&utm_campaign=${campaign}`;
});
