/* eslint-disable no-unused-vars */
const OVERRIDE_TEXT = 'This method should be overriden!';

export default class ActionableItem {
  constructor(defaults = {}, actions = {}) {
    this.defaults = defaults;
    this.actions = actions;
    this.DEFAULT_TEXTS = {};
    this.ACTIONS = {};
  }

  isTitleDefault() {
    throw new Error(OVERRIDE_TEXT);
  }

  get defaultTexts() {
    throw new Error(OVERRIDE_TEXT);
  }

  dispatchAction(actionKey, props) {
    const relatedAction = this.actions[actionKey];
    if (!relatedAction) {
      throw new Error(`action ${relatedAction} does not exist`);
    }

    return relatedAction(props);
  }
}
