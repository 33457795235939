import React from 'react';

import { cx } from 'class-variance-authority';

import { ChoiceLabelProps, choiceLabelDefaultProps } from './choice-label.types';
import {
  choiceLabelCVA, choiceLabelContainerCVA, choiceLabelDescriptionCVA, choiceLabelTextCVA
} from './choice-label.cva';

export const ChoiceLabel = (props: ChoiceLabelProps):JSX.Element => {
  const {
    className, description, disabled, id, input, label, size, theme, truncate
  } = props;
  const hasDescription = description.length > 0;
  const hasLabel = label.length > 0;

  return (
    <label className={cx(className, choiceLabelCVA({ size, truncate }))} htmlFor={id}>
      {input}
      <span className={choiceLabelContainerCVA({ size, truncate })}>
        {hasLabel && (
          <span
            className={choiceLabelTextCVA({
              size, theme, disabled, hasDescription, truncate
            })}
          >
            {label}
          </span>
        )}
        {hasDescription && (
          <span className={choiceLabelDescriptionCVA({
            size, theme, disabled, truncate
          })}
          >
            {description}
          </span>
        )}
      </span>
    </label>
  );
};

ChoiceLabel.defaultProps = choiceLabelDefaultProps;
