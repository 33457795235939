import React, {
  forwardRef, useCallback, useMemo, useRef
} from 'react';
import {
  bool, element, elementType, func, number, oneOf, oneOfType, string
} from 'prop-types';
import Toggle from '@jotforminc/toggle';
import { Utils, Hooks } from '@jotforminc/uikit';
import PanelItemDescriptionRenderer from '../HomePage/RightPanel/PanelItemDescriptionRenderer';
import { ScRadioToggle } from './scRadioToggle';
import { isYes } from '../../../../utils';
import Tooltip from '../../../../components/Tooltip';

const RadioToggle = forwardRef(({
  description,
  title,
  value,
  onChange,
  DescriptionRenderer,
  isMixed,
  readOnly,
  tooltipText,
  tooltipTextAlign,
  tooltipTextZindex,
  badge
}, ref) => {
  const isSelected = useMemo(() => isYes(value), [value]);

  const handleOnChange = useCallback(() => {
    const newSelectedValue = !isSelected;
    onChange(newSelectedValue ? 'Yes' : 'No');
  }, [isSelected, onChange]);

  const MemoDescriptionRenderer = useCallback(() => (description ? <DescriptionRenderer description={description} /> : null), [description]);
  const innerRef = useRef();
  const combinedRef = Hooks.useCombinedRefs(innerRef, ref);

  return (
    <ScRadioToggle className={`${badge ? 'mb-5' : ''} radioToggle`} data-testid="radioToggle">
      <div className="radioToggleTexts">
        <div className="radioToggleTexts-title flex items-center" data-testid="toggleTitle">
          <span>{title}</span>
          {badge || ''}
        </div>
        <MemoDescriptionRenderer />
      </div>
      <div className="radioToggle-toggleWrapper">
        <Toggle
          data-testid="radioToggle-toggle"
          isSelected={isSelected}
          onClick={handleOnChange}
          onKeyDown={e => (Utils.isPressedKeyEnter(e) && handleOnChange(e))}
          squareMode
          tabIndex="0"
          role="switch"
          aria-checked={isSelected}
          isMixed={isMixed}
          readOnly={readOnly}
          ref={combinedRef}
        />
      </div>
      {tooltipText && <Tooltip style={{ width: '190px', textAlign: tooltipTextAlign, zIndex: tooltipTextZindex }} ref={combinedRef}>{tooltipText}</Tooltip>}
    </ScRadioToggle>
  );
});

RadioToggle.propTypes = {
  description: string,
  title: string,
  value: oneOfType([oneOf(['Yes', 'No']), bool]),
  onChange: func,
  DescriptionRenderer: elementType,
  isMixed: bool,
  readOnly: bool,
  tooltipText: string,
  tooltipTextAlign: oneOf(['center', 'left', 'right']),
  tooltipTextZindex: number,
  badge: element
};

RadioToggle.defaultProps = {
  description: '',
  title: '',
  value: 'No',
  onChange: f => f,
  DescriptionRenderer: PanelItemDescriptionRenderer,
  isMixed: false,
  readOnly: false,
  tooltipText: '',
  tooltipTextAlign: 'left',
  tooltipTextZindex: 2,
  badge: null
};

export default RadioToggle;
