import Styled from 'styled-components';
import { isYes } from '../../../utils';

const ScDetailsModal = Styled.div`
  font-size: 16px;
  position: relative;

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 28px;
    right: 28px;

    .close-modal {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #EDEEF5;

      svg {
        width: 30px;
        height: 30px;
        color: #6F76A7;
      }
    }

    &-wrapper {
      position: sticky;
      right: 0;
      top: 0;
      z-index: 2;

      &:not(:has(+.sliderContainer)) {
        height: 80px;
      }

      @media screen and (max-width: 480px) {
        &:not(:has(+.sliderContainer)) {
          height: unset;
        }
      }
    }

    .product-list-item-favBtn {
      display: none;
    }

    .product-list-item-favLabel {
      cursor: pointer;
      position: relative;
      margin-right: 10px;
      top: unset;
      right: unset;
    }

    .product-list-item-favLabel svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px 28px 3px 28px;

    .product-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .product-name {
      font-size: 18px;
    }

    .product-price {
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
    }

    .product-details {
      width: 100%;
      position: relative;
      color: #6F76A7;
    }

    .product-options {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .product-option {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin-top: 14px;
      flex: 0 0 calc(50% - 8px);

      &-label {
        flex: 1 1 100%;
        margin-bottom: 10px;
      }
    }

    .product-description {
      border-top: 1px solid #C8CEED;
      margin-top: 20px;
      padding-top: 20px;
      width: 100%;
      white-space: pre-line;
      overflow-wrap: break-word;
    }

    .line {
      height: 25px;
      position: sticky;
      bottom: 0;
      background: linear-gradient(0deg,#FFFFFF 0%,rgba(255,255,255,0) 100%);
      left: 0;
      width: 100%;
    }
  }

  button.taste {
    display: flex;
    width: 196px;
    text-transform: none;
  }

  .sliderImage img {
    width: 100%;
    height: 100%;
    object-fit: ${({ fitImageToCanvas }) => (isYes(fitImageToCanvas) ? 'cover' : 'contain')}
  }

  .imageCarousel img {
    object-fit: ${({ fitImageToCanvas }) => (isYes(fitImageToCanvas) ? 'cover' : 'contain')}
  }

  @media screen and (max-width: 480px) {
    .content {
     .product-option {
        flex: 0 0 100%;

        .form-spinner {
          max-width: 100%;
        }
        .form-textbox {
          max-width: 100%;
        }
     }
    }
  }
`;

export default ScDetailsModal;
