import * as Components from '../components/fields';
import * as COMPONENT_NAMES from './componentNames';

const COMPONENT_TYPE_LIST = {
  [COMPONENT_NAMES.TOGGLE]: Components.Toggle,
  [COMPONENT_NAMES.CONNECTION_BUTTON]: Components.ConnectionButton,
  [COMPONENT_NAMES.SELECTBOX]: Components.Selectbox,
  [COMPONENT_NAMES.CURRENCY_DROPDOWN]: Components.CurrencyDropdown,
  [COMPONENT_NAMES.LAYOUT_BUTTON]: Components.LayoutTypeButton,
  [COMPONENT_NAMES.TEXTINPUT]: Components.TextInput,
  [COMPONENT_NAMES.INFOLINE]: Components.InfoLine,
  [COMPONENT_NAMES.CHECKBOX]: Components.CustomCheckbox,
  [COMPONENT_NAMES.COPYURL]: Components.CopyUrl,
  [COMPONENT_NAMES.STEPS]: Components.Steps,
  [COMPONENT_NAMES.COLORPICKER]: Components.ColorPicker,
  [COMPONENT_NAMES.TEXTAREA]: Components.TextArea,
  [COMPONENT_NAMES.GROUPHEADER]: Components.GroupHeader,
  [COMPONENT_NAMES.FIXED_AMOUNTS]: Components.FixedAmounts,
  [COMPONENT_NAMES.DIVIDER]: Components.Divider,
  [COMPONENT_NAMES.PRICE_INPUT]: Components.PriceInput,
  [COMPONENT_NAMES.INPUT_RANGE]: Components.InputRange,
  [COMPONENT_NAMES.INPUT_RANGE_WITH_TYPE]: Components.InputRangeWithType,
  [COMPONENT_NAMES.NEWDROPDOWN]: Components.NewDropdown,
  [COMPONENT_NAMES.NEWTEXTINPUT]: Components.NewTextInput,
  [COMPONENT_NAMES.CREDENTIAL]: Components.Credential,
  [COMPONENT_NAMES.LABELVALUETABLE]: Components.LabelValueTable,
  [COMPONENT_NAMES.RADIOGROUP]: Components.RadioGroup,
  [COMPONENT_NAMES.SUFFIXINPUT]: Components.SuffixInput
};

export default COMPONENT_TYPE_LIST;
