import React, {
  cloneElement,
  Children,
  Fragment,
  PropsWithChildren
} from 'react';
import type { ReactElement } from 'react';
import { cx } from 'class-variance-authority';

import { ModalActionsProps } from './modal.types';

export const ModalActions = (props: PropsWithChildren<ModalActionsProps>): JSX.Element => {
  const {
    className, children, ...rest
  } = props;

  const childrenArray = Children.toArray(children).filter(child => !!child);

  const childCount: number = childrenArray && childrenArray.length;

  /* If actions includes primary action and other action element, primary action should be in first order.
   * For this reason reverse layout's condition applied for only two elements in children array.
   * In other cases, it was decided to continue with the simple layout in the current structure.
   */
  const reverseElement = childCount < 3;

  return (
    <div
      {...rest}
      className={cx(className,
        'flex items-center justify-center xs:justify-between py-4 px-8 w-full gap-4 border-t border-solid border-navy-50',
        { 'flex-col xs:flex-row': !reverseElement },
        { 'flex-col-reverse xs:flex-row': reverseElement })}
    >
      {childrenArray.map((child, index) => {
        const childType = child as ReactElement;
        const key = `modal-actions-${index}`;
        const actionSplit = childCount >= 3 && index === (childCount - 3);

        return (
          <Fragment key={key}>
            {childType !== null && cloneElement(childType, {
              ...childType.props,
              className: actionSplit
                ? cx(childType.props.className, 'mr-auto w-full xs:w-auto')
                : cx(childType.props.className, 'w-full xs:w-auto')
            })}
          </Fragment>
        );
      })}
    </div>
  );
};
