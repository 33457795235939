import React, { useCallback } from 'react';
import { bool, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconUserIdCardFilled, IconCreditCardFilled } from '@jotforminc/svg-icons';
import { CHECKOUT_FORM_STATUSES } from '../../../constants';
import LoginCue from './LoginCue';
import ScCheckoutFormHeader from './ScCheckoutFormHeader';

const CheckoutFormHeader = ({ checkoutFormStatus, showLoginCue, previewMode }) => {
  const generateHeaderText = useCallback(status => {
    switch (status) {
      case CHECKOUT_FORM_STATUSES.OPENED:
        return t('Contact Information');
      case CHECKOUT_FORM_STATUSES.PAYMENT:
        return t('Payment Information');
      default:
        return '';
    }
  }, []);

  const generateHeaderIcon = useCallback(status => {
    switch (status) {
      case CHECKOUT_FORM_STATUSES.OPENED:
        return (<IconUserIdCardFilled />);
      case CHECKOUT_FORM_STATUSES.PAYMENT:
        return (<IconCreditCardFilled />);
      default:
        return '';
    }
  }, []);

  return (
    <ScCheckoutFormHeader
      showLoginCue={showLoginCue}
      status={checkoutFormStatus}
      previewMode={previewMode}
    >
      {showLoginCue && (<LoginCue />)}
      <div className="checkout-form-header">
        {generateHeaderIcon(checkoutFormStatus)}
        <h3 className="checkout-form-header-text">
          {generateHeaderText(checkoutFormStatus)}
        </h3>
      </div>
    </ScCheckoutFormHeader>
  );
};

CheckoutFormHeader.propTypes = {
  checkoutFormStatus: string,
  showLoginCue: bool,
  previewMode: bool
};

CheckoutFormHeader.defaultProps = {
  checkoutFormStatus: '',
  showLoginCue: false,
  previewMode: false
};

export default CheckoutFormHeader;
