/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Dropdown } from '@jotforminc/uikit';
import { classNames } from '@jotforminc/utils';

const DropdownEditor = ({
  options,
  onChange,
  selectedOptionValue,
  textInputPlaceholder,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const selectOption = selectedOption => {
    onChange(selectedOption);
  };

  const ContainerRenderer = ({ children }) => (
    <ul className="">{children}</ul>
  );
  const ButtonRenderer = forwardRef(({
    option: { text, value } = {}, placeholder, isOptionsVisible, ...buttonProps
  }, ref) => {
    useEffect(() => {
      setMenuOpen(isOptionsVisible);
    }, [isOptionsVisible]);

    return (
      <div className="jfWizard-e-sign-bar--selectBox-button">
        <button
          ref={ref}
          {...buttonProps}
          data-value={value}
          type="button" className=''
        >
          {text || placeholder}
        </button>
      </div>
    );
  });

  const TextInputRenderer = ({ ...textInputProps }) => {
    return (
      <div className="">
        <TextInput className="" {...textInputProps} />
      </div>
    );
  };

  const GroupRenderer = ({ text, children, ...groupProps }) => {
    return (
      <div {...groupProps}>
        <strong className="">{text}</strong>
        <div>{children}</div>
      </div>
    );
  };

  const OptionContainerRenderer = optionProps => {
    // eslint-disable-next-line react/destructuring-assignment
    const color = optionProps?.children?.props?.option?.color || '#151039';
    return (
      <li style={{ color, '--icon-bg-color': color }} {...optionProps} />
    );
  };

  return (
    <div className={classNames('jfWizard-e-sign-bar--selectBox', menuOpen && 'active')}>
      <Dropdown
        ContainerRenderer={ContainerRenderer}
        ButtonRenderer={ButtonRenderer}
        TextInputRenderer={TextInputRenderer}
        GroupRenderer={GroupRenderer}
        OptionContainerRenderer={OptionContainerRenderer}
        options={options}
        textInputPlaceholder={textInputPlaceholder}
        defaultValue={selectedOptionValue}
        popoverProps={{
          className: 'jfWizard-e-sign-bar--selectBox-list'
        }}
        onOptionSelect={selectOption}
        {...props}
      />
    </div>

  );
};

DropdownEditor.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selectedOptionValue: PropTypes.string,
  textInputPlaceholder: PropTypes.string
};

DropdownEditor.defaultProps = {
  onChange: f => f,
  selectedOptionValue: null,
  textInputPlaceholder: null
};

export default DropdownEditor;
