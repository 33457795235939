import React, { useMemo } from 'react';
import { isResourcePropertyRequired, isResourcePropertyVisible } from '@jotforminc/payment-gateways';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { TBuilderDropdown } from '../../../../types/common';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import ComponentWrapper from './ComponentWrapper';

import {
  CopyURL,
  Dropdown,
  Toggle,
  TextInput,
  TextArea,
  ImageUpload,
  Checkbox,
  CheckboxGroup
} from '../Fields';

interface IComponentRenderer {
  props: any;
  name: string;
  BuilderDropdown: TBuilderDropdown;
  BuilderImageUpload: React.ComponentType;
  BuilderToggle: React.ComponentType;
}

const ComponentRenderer = ({
  name,
  props,
  BuilderDropdown,
  BuilderImageUpload,
  BuilderToggle
}: IComponentRenderer): JSX.Element | null => {
  const {
    setGatewayProperty,
    resource,
    formType,
    formQuestionTypes,
    gatewayProperties,
    settingConfigModeState,
    allRequiredPropNames,
    correctedPropNames
  } = usePaymentPropsPanel();
  const {
    type,
    title,
    subTitle,
    infoText,
    description,
    requiredCondition,
    autoSave,
    renderCondition,
    resources
  } = props;
  const required = isResourcePropertyRequired(
    requiredCondition,
    isEnterprise(),
    formType,
    formQuestionTypes,
    gatewayProperties
  );

  const infoTextComponent = useMemo(() => (
    <div className='color-gray-200 flex flex-row mt-2'>
      <IconInfoCircleFilled className='min-w-4 h-4 mr-1' />
      <div className='text-xs'>{t(infoText)}</div>
    </div>
  ), [infoText]);

  const titleAndDesc = useMemo(() => (
    <div className='flex flex-col mb-2'>
      <div className='flex flex-row'>
        <span className='text-sm line-height-md tracking-lg font-bold'>{t(title)}</span>
        {required && <span className='color-red-400 ml-1'>*</span>}
      </div>
      {description && (<span className='color-gray-200 text-xs'>{t(description)}</span>)}
    </div>
  ), [title, required, description]);

  const configurationModeClass = useMemo(() => {
    if (settingConfigModeState.active) {
      const highlightedProps = allRequiredPropNames && allRequiredPropNames.includes(name) && !correctedPropNames.includes(name);
      return highlightedProps ? 'highlighted' : 'opacity-40';
    }
    return '';
  }, [allRequiredPropNames, correctedPropNames, name]);

  if (!isResourcePropertyVisible(
    renderCondition,
    resources,
    resource,
    isEnterprise(),
    formType,
    formQuestionTypes,
    gatewayProperties
  )) {
    return null;
  }

  const onPropertyChange = (key: string, val: string) => {
    setGatewayProperty(key, val, autoSave, type);
  };

  switch (type) {
    case 'toggle':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          <Toggle
            title={title}
            required={required}
            description={description}
            BuilderToggle={BuilderToggle}
            name={name}
            onClick={(val: string) => onPropertyChange(name, val)}
            {...props}
          />
        </ComponentWrapper>
      );
    case 'dropdown':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          {titleAndDesc}
          <Dropdown
            required={required}
            title={title}
            name={name}
            BuilderDropdown={BuilderDropdown}
            onPropertyChange={onPropertyChange}
            {...props}
          />
          {infoText && infoTextComponent}
        </ComponentWrapper>
      );
    case 'copyUrl':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          <CopyURL
            name={name}
            {...props}
          />
        </ComponentWrapper>
      );
    case 'input':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          {titleAndDesc}
          <TextInput
            name={name}
            onChange={(val: string) => onPropertyChange(name, val)}
            {...props}
          />
          {infoText && infoTextComponent}
        </ComponentWrapper>
      );
    case 'textarea':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          {titleAndDesc}
          <TextArea
            name={name}
            onChange={(val: string) => onPropertyChange(name, val)}
            {...props}
          />
        </ComponentWrapper>
      );
    case 'imageUpload':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          {titleAndDesc}
          <ImageUpload
            name={name}
            BuilderImageUpload={BuilderImageUpload}
            onChange={(val: string) => onPropertyChange(name, val)}
            {...props}
          />
        </ComponentWrapper>
      );
    case 'checkbox':
      return (
        <ComponentWrapper
          additionalClass={`${name} ${configurationModeClass}`}
        >
          <Checkbox
            name={name}
            onChange={(val: string) => onPropertyChange(name, val)}
            {...props}
          />
        </ComponentWrapper>
      );
    case 'checkboxGroup':
      return (
        <ComponentWrapper
          additionalClass={`py-3 ${name} ${configurationModeClass}`}
        >
          {titleAndDesc}
          <CheckboxGroup
            name={name}
            onChange={(val: string) => onPropertyChange(name, val)}
            {...props}
          />
        </ComponentWrapper>
      );
    case 'title':
      return (
        <ComponentWrapper
          additionalClass={`py-1 ${name} ${configurationModeClass}`}
        >
          <div className='flex flex-row'>
            <span className='text-sm line-height-md tracking-lg font-bold'>{t(title)}</span>
            {required && <span className='color-red-400 ml-1'>*</span>}
          </div>
        </ComponentWrapper>
      );
    case 'infoline':
      return (
        <ComponentWrapper
          additionalClass={`py-2 ${name} ${configurationModeClass}`}
        >
          <span className='inline-block font-bold text-md tracking-md line-height-xl'>{t(title)}</span>
          <span className='inline-block text-sm line-height-md tracking-lg color-gray-200'>{t(subTitle)}</span>
        </ComponentWrapper>
      );
    default:
      break;
  }

  return null;
};

export default ComponentRenderer;
