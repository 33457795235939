import React, {
  useState, useEffect, useMemo
} from 'react';
import propTypes from 'prop-types';
import Option from './Option';
import { KeyboardNavigation, KEYBOARD_EVENT_KEYS } from '../../../../../../../utils/KeyboardNavigation';
import { SectionDivider } from './styled';

const OptionGroup = ({
  sections,
  onChangeOption,
  selectedOption,
  shouldDivideSections,
  shouldNavigateWithKeyboard
}) => {
  const orderedOptions = useMemo(() => sections.map(section => section.options).flat(), [sections]);
  const currentOptionIndex = orderedOptions.findIndex(option => option.value === selectedOption.value);

  const keyboardNavigation = useMemo(() => (shouldNavigateWithKeyboard ? new KeyboardNavigation(orderedOptions) : null), [orderedOptions]);
  const [activeOption, setActiveOption] = useState(
    currentOptionIndex > -1
      ? { value: orderedOptions[currentOptionIndex].value, index: currentOptionIndex }
      : { value: '', index: 0 }
  );

  keyboardNavigation?.setIndex(activeOption.index);

  const changeActiveOption = newIndex => {
    if (orderedOptions[newIndex]) {
      setActiveOption({ value: orderedOptions[newIndex].value, index: newIndex });
    }
  };

  useEffect(() => {
    const handleKeyDown = e => {
      if (!keyboardNavigation) return;

      keyboardNavigation.handleKeyEvent(e, index => {
        changeActiveOption(index);
        if (e.key === KEYBOARD_EVENT_KEYS.ENTER && orderedOptions[index] && orderedOptions[index].value) {
          changeActiveOption(index);
          onChangeOption(orderedOptions[index]);
        }
      });
    };

    if (keyboardNavigation) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (keyboardNavigation) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [
    orderedOptions,
    changeActiveOption,
    activeOption,
    onChangeOption,
    keyboardNavigation
  ]);

  const filteredSections = sections.filter(({ options }) => options.length > 0);

  return (
    <>
      {
      filteredSections.filter(({ options }) => options.length > 0).map((section, indx) => {
        if (section.options.length < 1) {
          return;
        }
        return (
          <div key={`${section.description}`}>
            {!!section.description && shouldDivideSections && <span className='dropdown-option table-name'>{section.description.toUpperCase()}</span>}
            { section.options.map(option => {
              return (
                <Option
                  key={`${section.description}-${option.value}`}
                  option={option}
                  isSelected={selectedOption?.value === option.value}
                  onChange={onChangeOption}
                  isActive={activeOption.value === option.value}
                />
              );
            }) }
            {
                shouldDivideSections
                && filteredSections.length > 1
                && indx !== filteredSections.length - 1
                && <SectionDivider />
              }
          </div>
        );
      })
      }
    </>
  );
};

OptionGroup.propTypes = {
  sections: propTypes.array,
  selectedOption: propTypes.object,
  onChangeOption: propTypes.func.isRequired,
  shouldDivideSections: propTypes.bool.isRequired,
  shouldNavigateWithKeyboard: propTypes.bool.isRequired
};

OptionGroup.defaultProps = {
  sections: [],
  selectedOption: {}
};

export default OptionGroup;
