/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */
import { t } from '@jotforminc/translation';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { handleCustomNavigation } from '@jotforminc/utils';
import TemplateItemContainer from '../TemplateItem/TemplateItemContainer';
import { ScTemplates } from '../ScTemplates';

import 'swiper/css';
import 'swiper/css/navigation';
import { getDeviceType } from '../../getDeviceType';

const handleAskMeAction = (question, frameRef) => {
  const frameContent = frameRef.current;
  const objWindow = frameContent.contentWindow;
  const objDoc = frameContent.contentDocument || frameContent.contentWindow.document;
  objWindow.postMessage({
    question,
    source: 'agent-directory'
  }, window.location.origin);
  setTimeout(() => {
    const agentSendBtn = objDoc.querySelector('.sendButton');
    if (agentSendBtn) {
      agentSendBtn.click();
    }
  }, 500);
};

export const OverviewTabRenderer = (props = {}) => {
  const { description = '' } = props;
  return (
    <div className='w-full mt-10'>
      <strong class="mb-4 color-navy-700 text-xl line-height-2xl block locale">{t('About this agent')}</strong>
      <div className='color-navy-300 text-md line-height-xl about-this-template-section' dangerouslySetInnerHTML={{ __html: t(description) }} />
    </div>
  );
};

export const FormTemplatesTabRenderer = (data = {}) => {
  const { username, formTemplate: formTemplateData } = data || {};
  const connectedFormTemplates = formTemplateData || [];
  const sliderRef = useRef(null);

  const deviceType = getDeviceType();
  const isDesktop = deviceType === 'desktop';
  return (
    <div className='w-full mt-10'>
      <strong class="mb-4 color-navy-700 text-xl line-height-2xl block locale">{t('Connected Forms')}</strong>
      <div
        // className='color-navy-300 text-md line-height-xl about-this-template-section'
        className={`mb-6 more-templates-area${isEnterprise() ? ' no-description' : ''}`}
      >
        <ScTemplates isDesktop={isDesktop} slider>
          <Swiper
            dir='ltr'
            ref={sliderRef}
            spaceBetween={0}
            slidesPerView={4}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40
              }
            }}
          >
            {
              connectedFormTemplates.map(formTemplate => (
                <SwiperSlide key={(formTemplate._id || formTemplate.id).toString()}>
                  <TemplateItemContainer
                    source='MYAGENTS'
                    username={username}
                    type='form-templates'
                    template={formTemplate}
                    templateTheme='classic'
                    customCloneText='View Template'
                    setSelectedTemplate={() => {
                      handleCustomNavigation(`${window.location.origin}/form-templates/${formTemplate._slug}`, '_blank');
                    }}
                    handleTemplateClone={() => {
                      handleCustomNavigation(`${window.location.origin}/form-templates/${formTemplate._slug}`, '_blank');
                    }}
                    liteMode={false}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>
        </ScTemplates>
      </div>
    </div>
  );
};

export const AskMeSectionRenderer = ({ template, frameRef }) => {
  try {
    const { JSON: jsonData = '{}' } = template;
    const { questions } = JSON.parse(jsonData);
    return (
      <div className='page-detail-ask-me mt-8 hidden lg:block'>
        <strong class="text-xl line-height-2xl color-navy-700 font-medium locale">Ask me things like...</strong>
        <ul className='flex flex-col gap-3 -mb-1 mt-4 bg-agent-purple bg-opacity-10 radius color-navy-500 list-disc text-sm line-height-md py-4 pl-8 pr-4 rtl:pr-8 rtl:pl-4'>
          {
            questions.map(question => (
              <li
                className='cursor-pointer'
                onClick={() => handleAskMeAction(question, frameRef)}
              >
                {question}
              </li>
            ))
          }
        </ul>
      </div>
    );
  } catch {
    return null;
  }
};

const AgentTemplatePreviewContent = () => {
  return null;
};

export default AgentTemplatePreviewContent;
