import React, { useCallback } from 'react';
import TextInput from '@jotforminc/text-input';
import { inputStyle } from '../../constants/styles';

const NewTextInput = ({
  id,
  onChange,
  defaultValue,
  placeholder,
  inputClass
}: IProps): JSX.Element => {
  const onBlurHandler = useCallback(e => {
    const val = e.target.value;
    onChange(val);
  }, [onChange]);

  // for additional class
  const additionalClass = inputClass !== '' ? inputClass : 'w-full';

  return (
    <TextInput
      id={id}
      onBlur={onBlurHandler}
      defaultValue={defaultValue}
      // eslint-disable-next-line max-len
      className={`${inputStyle()} ${additionalClass}`}
      placeholder={placeholder}
      unstyled
    />
  );
};

export interface IProps {
  id: string
  onChange: (value: string) => void
  defaultValue: string
  placeholder?: string
  inputClass?: string
}
NewTextInput.defaultProps = {
  inputClass: ''
};

export default NewTextInput;
