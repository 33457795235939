import {
  ALIGN_ITEMS_VALUES,
  ALIGN_SELF_VALUES,
  FLEX_DIRECTION_VALUES,
  FLEX_GROW_VALUES,
  FLEX_SHRINK_VALUES,
  FLEX_WRAP_VALUES,
  ORDER_VALUES
} from '../../constants/common.values';

import { ResponsiveClasses } from '../../utils';

export const alignItemsClasses:ResponsiveClasses<typeof ALIGN_ITEMS_VALUES> = {
  baseline: {
    base: 'items-baseline',
    xs: 'xs:items-baseline',
    sm: 'sm:items-baseline',
    md: 'md:items-baseline',
    lg: 'lg:items-baseline',
    xl: 'xl:items-baseline',
    xxl: 'xxl:items-baseline'
  },
  stretch: {
    base: 'items-stretch',
    xs: 'xs:items-stretch',
    sm: 'sm:items-stretch',
    md: 'md:items-stretch',
    lg: 'lg:items-stretch',
    xl: 'xl:items-stretch',
    xxl: 'xxl:items-stretch'
  },
  center: {
    base: 'items-center',
    xs: 'xs:items-center',
    sm: 'sm:items-center',
    md: 'md:items-center',
    lg: 'lg:items-center',
    xl: 'xl:items-center',
    xxl: 'xxl:items-center'
  },
  end: {
    base: 'items-end',
    xs: 'xs:items-end',
    sm: 'sm:items-end',
    md: 'md:items-end',
    lg: 'lg:items-end',
    xl: 'xl:items-end',
    xxl: 'xxl:items-end'
  },
  start: {
    base: 'items-start',
    xs: 'xs:items-start',
    sm: 'sm:items-start',
    md: 'md:items-start',
    lg: 'lg:items-start',
    xl: 'xl:items-start',
    xxl: 'xxl:items-start'
  }
};

export const directionClasses:ResponsiveClasses<typeof FLEX_DIRECTION_VALUES> = {
  column: {
    base: 'flex-col',
    xs: 'xs:flex-col',
    sm: 'sm:flex-col',
    md: 'md:flex-col',
    lg: 'lg:flex-col',
    xl: 'xl:flex-col',
    xxl: 'xxl:flex-col'
  },
  'column-reverse': {
    base: 'flex-col-reverse',
    xs: 'xs:flex-col-reverse',
    sm: 'sm:flex-col-reverse',
    md: 'md:flex-col-reverse',
    lg: 'lg:flex-col-reverse',
    xl: 'xl:flex-col-reverse',
    xxl: 'xxl:flex-col-reverse'
  },
  row: {
    base: 'flex-row',
    xs: 'xs:flex-row',
    sm: 'sm:flex-row',
    md: 'md:flex-row',
    lg: 'lg:flex-row',
    xl: 'xl:flex-row',
    xxl: 'xxl:flex-row'
  },
  'row-reverse': {
    base: 'flex-row-reverse',
    xs: 'xs:flex-row-reverse',
    sm: 'sm:flex-row-reverse',
    md: 'md:flex-row-reverse',
    lg: 'lg:flex-row-reverse',
    xl: 'xl:flex-row-reverse',
    xxl: 'xxl:flex-row-reverse'
  }
};

export const wrapClasses:ResponsiveClasses<typeof FLEX_WRAP_VALUES> = {
  wrap: {
    base: 'flex-wrap',
    xs: 'xs:flex-wrap',
    sm: 'sm:flex-wrap',
    md: 'md:flex-wrap',
    lg: 'lg:flex-wrap',
    xl: 'xl:flex-wrap',
    xxl: 'xxl:flex-wrap'
  },
  nowrap: {
    base: 'flex-nowrap',
    xs: 'xs:flex-nowrap',
    sm: 'sm:flex-nowrap',
    md: 'md:flex-nowrap',
    lg: 'lg:flex-nowrap',
    xl: 'xl:flex-nowrap',
    xxl: 'xxl:flex-nowrap'
  }
};

export const orderClasses:ResponsiveClasses<typeof ORDER_VALUES> = {
  last: {
    base: 'order-last',
    xs: 'xs:order-last',
    sm: 'sm:order-last',
    md: 'md:order-last',
    lg: 'lg:order-last',
    xl: 'xl:order-last',
    xxl: 'xxl:order-last'
  },
  first: {
    base: 'order-first',
    xs: 'xs:order-last',
    sm: 'sm:order-first',
    md: 'md:order-first',
    lg: 'lg:order-first',
    xl: 'xl:order-first',
    xxl: 'xxl:order-first'
  },
  none: {
    base: 'order-none',
    xs: 'xs:order-last',
    sm: 'sm:order-none',
    md: 'md:order-none',
    lg: 'lg:order-none',
    xl: 'xl:order-none',
    xxl: 'xxl:order-none'
  }
};

export const growClasses:ResponsiveClasses<typeof FLEX_GROW_VALUES> = {
  0: {
    base: 'grow-0',
    xs: 'xs:grow-0',
    sm: 'sm:grow-0',
    md: 'md:grow-0',
    lg: 'lg:grow-0',
    xl: 'xl:grow-0',
    xxl: 'xxl:grow-0'
  },
  1: {
    base: 'grow-1',
    xs: 'xs:grow-1',
    sm: 'sm:grow-1',
    md: 'md:grow-1',
    lg: 'lg:grow-1',
    xl: 'xl:grow-1',
    xxl: 'xxl:grow-1'
  }
};

export const shrinkClasses:ResponsiveClasses<typeof FLEX_SHRINK_VALUES> = {
  0: {
    base: 'shrink-0',
    xs: 'xs:shrink-0',
    sm: 'sm:shrink-0',
    md: 'md:shrink-0',
    lg: 'lg:shrink-0',
    xl: 'xl:shrink-0',
    xxl: 'xxl:shrink-0'
  },
  1: {
    base: 'shrink-1',
    xs: 'xs:shrink-1',
    sm: 'sm:shrink-1',
    md: 'md:shrink-1',
    lg: 'lg:shrink-1',
    xl: 'xl:shrink-1',
    xxl: 'xxl:shrink-1'
  }
};

export const alignSelfClasses:ResponsiveClasses<typeof ALIGN_SELF_VALUES> = {
  baseline: {
    base: 'self-baseline',
    xs: 'xs:self-baseline',
    sm: 'sm:self-baseline',
    md: 'md:self-baseline',
    lg: 'lg:self-baseline',
    xl: 'xl:self-baseline',
    xxl: 'xxl:self-baseline'
  },
  auto: {
    base: 'self-auto',
    xs: 'xs:self-auto',
    sm: 'sm:self-auto',
    md: 'md:self-auto',
    lg: 'lg:self-auto',
    xl: 'xl:self-auto',
    xxl: 'xxl:self-auto'
  },
  center: {
    base: 'self-center',
    xs: 'xs:self-center',
    sm: 'sm:self-center',
    md: 'md:self-center',
    lg: 'lg:self-center',
    xl: 'xl:self-center',
    xxl: 'xxl:self-center'
  },
  start: {
    base: 'self-start',
    xs: 'xs:self-start',
    sm: 'sm:self-start',
    md: 'md:self-start',
    lg: 'lg:self-start',
    xl: 'xl:self-start',
    xxl: 'xxl:self-start'
  },
  end: {
    base: 'self-end',
    xs: 'xs:self-end',
    sm: 'sm:self-end',
    md: 'md:self-end',
    lg: 'lg:self-end',
    xl: 'xl:self-end',
    xxl: 'xxl:self-end'
  },
  stretch: {
    base: 'self-stretch',
    xs: 'xs:self-stretch',
    sm: 'sm:self-stretch',
    md: 'md:self-stretch',
    lg: 'lg:self-stretch',
    xl: 'xl:self-stretch',
    xxl: 'xxl:self-stretch'
  }
};
