import React from 'react';
import './FormLoadingSkeleton.scss';

export const FormLoadingSkeleton = () => {
  return (
    <div class="form-loading-skeleton-container">
      <span class="form-loading-skeleton-box" style={{ width: '100%', height: '74px' }} />
    </div>
  );
};
