import React from 'react';
import { Button } from '@jotforminc/magnet';
import { IconPlugFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';

interface I_ADDCONNECTIONBUTTON {
  disabledBtnClass: string;
}

const AddConnectionButton = ({ disabledBtnClass }: I_ADDCONNECTIONBUTTON): JSX.Element => {
  const {
    togglePaymentConnectionModal,
    isCollaborator,
    isJotformerAccessingUserResource
  } = usePaymentPropsPanel();
  const isAddPaymentConnBtnDisabled = isCollaborator || isJotformerAccessingUserResource;

  return (
    <Button
      className={`w-full ${isAddPaymentConnBtnDisabled ? disabledBtnClass : ''}`}
      startIcon={IconPlugFilled}
      onClick={() => {
        if (isAddPaymentConnBtnDisabled) { return; }
        togglePaymentConnectionModal(true);
      }}
      data-test-id='add_payment_connection_btn'
    >
      {t('Add Payment Connection')}
    </Button>
  );
};

export default AddConnectionButton;
