import { createSelector } from 'reselect';

export const getUndoRedo = state => state.undoRedo;
export const hasUndoSelector = createSelector(getUndoRedo, undoRedo => {
  const { head } = undoRedo;
  return head >= 0;
});
export const hasRedoSelector = createSelector(getUndoRedo, undoRedo => {
  const { stack, head } = undoRedo;
  return stack.length >= 0 && head < (stack.length - 1);
});
export const getUndoStackHeadSelector = createSelector(getUndoRedo, undoRedo => undoRedo.head);
export const getCommandSelector = idx => createSelector(getUndoRedo, undoRedo => {
  const { stack } = undoRedo;
  if (idx >= stack.length || idx < 0) throw new Error('Command not found!');

  return stack[idx];
});
