import Styled from 'styled-components';
import { ScLogo } from '../AppLogo/scLogo';

const ScAppIconWrapper = Styled.div`
  ${ScLogo}
  border-radius: 10px;
  ${({ isSVG, bgColor }) => (isSVG ? `background: ${bgColor};` : '')}
  margin-bottom: 5px;

  .jfIconSVG-wrapper {
    display: flex;
  }
`;

export default ScAppIconWrapper;
