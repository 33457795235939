import { t } from '@jotforminc/translation';

export const RESET_PERIOD_VALUES = {
  NEVER: 'never',
  DAILY: 'daily',
  WEEKLY: 'weekly'
};

export const RESET_PERIOD = [
  { text: t('Daily'), value: RESET_PERIOD_VALUES.DAILY },
  { text: t('Weekly'), value: RESET_PERIOD_VALUES.WEEKLY }
];

export const WEEKLY_PERIOD_OPTIONS_VALUES = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};

export const WEEKLY_PERIOD_OPTIONS = [
  { text: t('Every Monday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.MONDAY },
  { text: t('Every Tuesday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.TUESDAY },
  { text: t('Every Wednesday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.WEDNESDAY },
  { text: t('Every Thursday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.THURSDAY },
  { text: t('Every Friday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.FRIDAY },
  { text: t('Every Saturday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.SATURDAY },
  { text: t('Every Sunday'), value: WEEKLY_PERIOD_OPTIONS_VALUES.SUNDAY }
];
