import { v4 as uuid } from 'uuid';
import debounce from 'lodash/debounce';
import { useState, useEffect, useRef } from 'react';

class ResizeHandler {
  constructor() {
    this.callbackList = {};
  }

  initializeResizeEventListener = () => {
    window.addEventListener('resize', this.handleResizeEvent);
  };

  handleResizeEvent = debounce(() => {
    const {
      innerWidth: width,
      innerHeight: height
    } = window;
    Object.values(this.callbackList).forEach(setStateFN => setStateFN({ width, height }));
  }, 500);

  addEventListener = (id, setStateFN) => {
    this.callbackList[id] = setStateFN;
  };

  removeEventListener = id => {
    delete this.callbackList[id];
  };
}

const ResizeSingleton = new ResizeHandler();

const useConstructor = (callBack = () => {}) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

export const useResize = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useConstructor(() => {
    ResizeSingleton.initializeResizeEventListener();
  });

  useEffect(() => {
    const id = uuid();
    ResizeSingleton.addEventListener(id, setDimensions);
    return () => ResizeSingleton.removeEventListener(id);
  }, []);

  return dimensions;
};
