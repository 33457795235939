import sortBy from 'lodash/sortBy';
import { IStore } from '../../types/common';
import EditorBinding from '../EditorBinding';
import {
  CHANGE_ACTIVE_EDITOR,
  CHANGE_ACTIVE_PANEL,
  CHANGE_ACTIVE_TAB,
  SET_ACTIVE_GATEWAY_SETTINGS,
  SET_ACTIVE_PRODUCT,
  UPDATE_ACTIVE_GATEWAY_SETTINGS
} from '../actionTypes';

const actionCatcher = (store: IStoreParam): any => (next: any): any => (action: any): void => {
  next(action);
  const state = store.getState();
  const { pid: activeProductID } = state.activeProduct;
  // will be refactored after update
  if (action.type.indexOf('@PRODUCTS/') > -1) {
    EditorBinding.onProductsChange(action.type, action.payload, { products: state.products });
  }

  if (action.type.indexOf('@ACTIVE_PRODUCT/') > -1) {
    const { data, activeProduct } = action.payload;
    const oldProducts = state?.products?.map((product, index) => ({ ...product, index }));
    const updatingProductIndex = activeProduct && oldProducts.findIndex(({ pid: oldPid }) => oldPid === activeProduct.pid);
    const updatingProduct = activeProduct && data && { ...activeProduct, ...data, index: updatingProductIndex };
    const filteredOldProducts = updatingProduct && oldProducts.filter(({ pid: oldPid }) => oldPid !== updatingProduct.pid);

    if (updatingProduct) {
      EditorBinding.onProductsChange(action.type, updatingProduct, { products: sortBy([...filteredOldProducts, updatingProduct], 'index') });
    }
  }

  if (SET_ACTIVE_PRODUCT === action.type && !action.silent) {
    EditorBinding.onRightPanelModeChange(action.payload.value.pid);
  }

  if ([SET_ACTIVE_GATEWAY_SETTINGS, UPDATE_ACTIVE_GATEWAY_SETTINGS].includes(action.type)) {
    EditorBinding.onGatewaySettingsChange(state.activeGatewaySettings);
  }

  if (action.type.indexOf('@PRODUCT_LIST/') > -1) {
    EditorBinding.onPaymentSettingsChange(action.type, action.payload);
  }

  if ([CHANGE_ACTIVE_TAB, CHANGE_ACTIVE_PANEL, CHANGE_ACTIVE_EDITOR].includes(action.type)) {
    EditorBinding.onRightPanelModeChange(activeProductID);
  }
};

interface IStoreParam {
  getState: () => IStore,
}

export default actionCatcher;
