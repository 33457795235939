import {
  string, oneOfType, arrayOf, node
} from 'prop-types';

export const renderable = oneOfType([arrayOf(node), node]);

export const ItemVisualPropTypes = {
  itemBgColor: string,
  itemBorderColor: string,
  itemFontColor: string,
  itemButtonBgColor: string,
  itemButtonFontColor: string
};

export const ItemVisualDefaultProps = {
  itemBgColor: '',
  itemBorderColor: '',
  itemFontColor: '',
  itemButtonBgColor: '',
  itemButtonFontColor: ''
};
