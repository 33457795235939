import React from 'react';
import { t } from '@jotforminc/translation';
import { shape, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { ScRightPanelBlock } from '../../../../styles/scRightPanel';
import TextInput from '../../../TextInput';

const ItemSettings = ({ onItemPropChange, productListItem, activeEditor }) => {
  const {
    title: _title
  } = productListItem;

  const isVisible = !activeEditor;

  return isVisible && (
    <div>
      <ScRightPanelBlock line>
        <label htmlFor="menuName">{t('Product List Title')}</label>
        <TextInput
          id="menuName"
          onChange={value => onItemPropChange('title', value)}
          value={_title}
        />
      </ScRightPanelBlock>
    </div>
  );
};

ItemSettings.propTypes = {
  onItemPropChange: func,
  productListItem: shape({}),
  activeEditor: string
};

ItemSettings.defaultProps = {
  onItemPropChange: f => f,
  productListItem: {},
  activeEditor: null
};

const mapStateToProps = ({ ui: { activeEditor } }) => ({
  activeEditor
});

export default connect(mapStateToProps, null)(ItemSettings);
