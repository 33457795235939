import React from 'react';
import { string, func } from 'prop-types';
import { IconMobile, IconTablet, IconDesktop } from '@jotforminc/svg-icons';
import { RESPONSIVE_MODES } from '../core/constants';

const ViewMode = ({ viewMode, onViewModeChange }) => {
  const { MOBILE, TABLET, DESKTOP } = RESPONSIVE_MODES;

  const isActive = (vMode, type) => (vMode === type ? 'bg-blue-500 color-white' : '');

  return (
    <div className="responsive-controller bg-white absolute left-4 rtl:left-auto rtl:right-4 bottom-4 w-12 z-1 flex flex-col hidden md:block radius-lg shadow-xl">
      <button
        type="button"
        aria-label="Mobile"
        onClick={() => onViewModeChange(MOBILE)}
        className={`mobile border-0 flex items-center justify-center w-full h-12 radius-t-lg ${isActive(viewMode, MOBILE)}`}
      >
        <IconMobile width={24} height={24} />
      </button>
      <button
        type="button"
        aria-label="Tablet"
        onClick={() => onViewModeChange(TABLET)}
        className={`tablet border-0 flex items-center justify-center w-full h-12 radius-b-lg lg:radius-b-none ${isActive(viewMode, TABLET)}`}
      >
        <IconTablet width={24} height={24} />
      </button>
      <button
        type="button"
        aria-label="Desktop"
        onClick={() => onViewModeChange(DESKTOP)}
        className={`pc border-0 hidden lg:flex items-center justify-center w-full h-12 radius-b-lg ${isActive(viewMode, DESKTOP)}`}
      >
        <IconDesktop width={24} height={24} />
      </button>
    </div>
  );
};

ViewMode.propTypes = {
  viewMode: string,
  onViewModeChange: func
};

ViewMode.defaultProps = {
  viewMode: RESPONSIVE_MODES.MOBILE,
  onViewModeChange: f => f
};

export default ViewMode;
