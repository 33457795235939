import Styled from 'styled-components';

export const ScSectionTitle = Styled.div`
    color: var(--jfv-panel-right-title-color);
    font-size: var(--jfv-panel-right-title-font-size);
    font-weight: var(--jfv-panel-right-title-font-weight);
    text-transform: uppercase;
    display: block;
    padding: 1.25em 1em 0 1em;

    // A/B Test: ctAppNameIconModal
    .appNameIconSettingsModal & {
        color: #0A1551;
        padding: 0 0 20px 0;
    }
`;
