import React from 'react';
import propTypes from 'prop-types';
import { IconCheckCircleFilled } from '@jotforminc/svg-icons';

const Option = ({
  option, isSelected, onChange, isActive
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={`dropdown-option ${isSelected || isActive ? 'isActive ' : ''}`} onClick={() => onChange(option)}>
      <span className='option-text'>
        {option.label}
      </span>
      {/* selected check */}
      {
        isSelected && <IconCheckCircleFilled className='selected-option-icon' height={18} width={18} />
      }

    </div>
  );
};

Option.propTypes = {
  option: propTypes.object,
  isSelected: propTypes.bool,
  onChange: propTypes.func,
  isActive: propTypes.bool
};

Option.defaultProps = {
  option: {},
  isSelected: false,
  onChange: f => f,
  isActive: propTypes.bool
};

export default Option;
