const gray = '#C8CEED';
const darkGray = '#6F76A7';
const lightGray = '#F3F3FE';
const blue = '#0099FF';
const darkBlue = '#0A1551';
const lightestBlue = '#EDF8FF';
const lightBlue = '#DBF1FF';

export const theme = {
  fontSize: '16px',

  itemBg: '#fff',
  itemBgFocus: lightGray,
  itemBgSelected: lightestBlue,
  itemBgSelectedHover: lightBlue,
  itemBorder: gray,
  itemBorderSelected: blue,
  lineHeight: `${21 / 16}em`,
  itemPadding: `${15 / 16}em`,

  // title
  titleColor: darkBlue,
  titleFontSize: `${17 / 16}em`,
  titleFontWeight: '500',

  // details
  detailsColor: darkGray,
  detailsFontSize: `${14 / 16}em`,
  detailsFontWeight: '300',

  // checkbox
  chBorder: gray,
  chSelected: blue,
  // eslint-disable-next-line max-len
  chIcon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-width='2' d='M4 8.025L6.994 10.951 12.905 4.5' transform='translate%28-3 -3%29'/%3E%3C/svg%3E%0A",
  // eslint-disable-next-line max-len
  chPartialIcon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='3' viewBox='0 0 10 3'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-width='2' d='M4 8.5L11.937 8.5' transform='translate%28-3 -7%29'/%3E%3C/svg%3E"
};
