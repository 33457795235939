import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  string, object, elementType, func
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconProductAppsMono, IconProductTablesMono } from '@jotforminc/svg-icons';
import { COLUMN_IDS } from './constants';

const Column = ({
  title, column, Item, setColumns, columns
}) => {
  const selectAll = ({ target: { checked: isChecked = false } }) => {
    if (isChecked) {
      setColumns(prev => ({
        ...prev,
        [COLUMN_IDS.SELECTEDS_COLUMN]: {
          ...prev[COLUMN_IDS.SELECTEDS_COLUMN],
          items: [...prev[COLUMN_IDS.SELECTION_COLUMN].items]
        }
      }));
    } else {
      setColumns(prev => ({
        ...prev,
        [COLUMN_IDS.SELECTEDS_COLUMN]: {
          ...prev[COLUMN_IDS.SELECTEDS_COLUMN],
          items: []
        }
      }));
    }
  };
  return (
    <Droppable droppableId={column.id} key={column.id}>
      {provided => {
        return (
          <div
            className='selectionColumn'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className='column-header'>
              {
                column.id === COLUMN_IDS.SELECTION_COLUMN && (<IconProductAppsMono width="20px" height="20px" />)
              }
              {
                column.id === COLUMN_IDS.SELECTEDS_COLUMN && (<IconProductTablesMono width="20px" height="20px" />)
              }
              <span className='column-title'>{t(title)}</span>
              {
                column.id === COLUMN_IDS.SELECTION_COLUMN && (
                // eslint-disable-next-line @jotforminc/no-native-button
                <div>
                  <input
                    id="selectionDnd-selectAll"
                    name="selectionDnd-selectAll"
                    type='checkbox'
                    onChange={selectAll}
                    checked={columns[COLUMN_IDS.SELECTEDS_COLUMN].items.length === columns[COLUMN_IDS.SELECTION_COLUMN].items.length}
                  />
                  <label htmlFor='selectionDnd-selectAll'>{t('Select All')}</label>
                </div>
                )
              }
            </div>
            {column?.items?.map((item, index) => {
              return (
                <Item
                  item={item}
                  index={index}
                  setColumns={setColumns}
                  columns={columns}
                />
              );
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

Column.propTypes = {
  title: string,
  column: object,
  columns: object.isRequired,
  Item: elementType.isRequired,
  setColumns: func.isRequired
};

Column.defaultProps = {
  title: '',
  column: {
    items: []
  }
};

export default Column;
