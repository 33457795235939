import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Apple Pay & Google Pay gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlApplePayGooglePay: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_applePayGooglePay',
  name: gatewayFieldToName.control_applePayGooglePay,
  supportedCurrencies: gatewaySupportedCurrencies.control_applePayGooglePay,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_applePayGooglePay,
  sortByCountry: gatewayListSortedByCountry.control_applePayGooglePay,

  /** APM's */
  isApm: true,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: true,
  childTypes: ['control_square', 'control_stripeCheckout'],
  isReferenceParentGatewayType: false,
  apmType: 'appleAndGooglePay',
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: false,
    mode: {
      name: 'sandbox',
      options: []
    },
    propReferences: {},
    uniquePropNames: []
  }
};
