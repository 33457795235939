import { func, object, string } from 'prop-types';
import React, { useMemo } from 'react';
import { t } from '@jotforminc/translation';

const RolesComponents = ({
  buttonRole, onChange, value, ROLE_COMPONENT_PROPS, ...rest
}) => {
  const roleProps = useMemo(() => ROLE_COMPONENT_PROPS[buttonRole], [buttonRole]);

  if (!roleProps) {
    return null;
  }

  const { title, RoleComponent, componentProps } = roleProps;

  return (
    <>
      <label htmlFor={buttonRole}>{t(title)}</label>
      <RoleComponent
        id={buttonRole}
        onChange={onChange}
        value={value}
        {...componentProps}
        {...rest}
      />
    </>
  );
};

RolesComponents.propTypes = {
  buttonRole: string,
  onChange: func,
  value: string,
  ROLE_COMPONENT_PROPS: object
};

RolesComponents.defaultProps = {
  buttonRole: null,
  onChange: f => f,
  value: null,
  ROLE_COMPONENT_PROPS: {}
};

export default RolesComponents;
