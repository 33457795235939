import React from 'react';

const TablistWrapper = props => (
  <button
    type="button"
    className="jfTabs-tabs-button"
    role="tab"
    {...props}
  />
);

export default TablistWrapper;
