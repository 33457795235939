import React from 'react';
import {
  ModalHeader, Flex, ModalActions, Button
} from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { IconProductTablesFilled } from '@jotforminc/svg-icons';
import { stepPropTypeDefaults, stepPropTypes } from '../constants';

const TableSelectionDefaults = () => <></>;

TableSelectionDefaults.Header = ({ onClose }) => {
  return (
    <ModalHeader
      title={t('Select Tables')}
      description={t('Select a table you want to add your list.')}
      onClose={onClose}
      icon={() => (
        <Flex justifyContent="center" alignItems="center" className='h-10 w-10 bg-tables-default radius'>
          <IconProductTablesFilled width={24} height={24} color='#ffffff' />
        </Flex>
      )}
    />
  );
};

TableSelectionDefaults.Actions = ({
  stepsLength, currentStep, prevStep, nextStep, isNextDisabled
}) => {
  return (
    <ModalActions>
      {
            currentStep > 0 && (
            <Button
              type='button'
              title={t('Back')}
              colorStyle='secondary'
              onClick={prevStep}
            >
                {t('Back')}
            </Button>
            )
        }
      {
            currentStep < stepsLength - 1 && (
            <Button
              type='button'
              title={t('Next')}
              colorStyle='primary'
              onClick={nextStep}
              disabled={isNextDisabled}
            >
                {t('Next')}
            </Button>
            )
        }
    </ModalActions>
  );
};

TableSelectionDefaults.BackButton = ({ currentStep, prevStep }) => (
  currentStep > 0 && (
  <Button
    type='button'
    title={t('Back')}
    colorStyle='secondary'
    onClick={prevStep}
  >
    {t('Back')}
  </Button>
  )
);

TableSelectionDefaults.NextButton = ({ currentStep, stepsLength, nextStep }) => (
  currentStep < stepsLength - 1 && (
    <Button
      type='button'
      title={t('Next')}
      colorStyle='primary'
      onClick={nextStep}
    >
        {t('Next')}
    </Button>
  )
);

TableSelectionDefaults.Header.propTypes = {
  ...stepPropTypes
};

TableSelectionDefaults.BackButton.propTypes = {
  ...stepPropTypes
};

TableSelectionDefaults.NextButton.propTypes = {
  ...stepPropTypes
};

TableSelectionDefaults.Actions.propTypes = {
  ...stepPropTypes
};

TableSelectionDefaults.defaultProps = {
  ...stepPropTypeDefaults
};

export default TableSelectionDefaults;
