import React, { forwardRef, useCallback } from 'react';
import Styled from 'styled-components';
import { ColorPicker as UiKitColorPicker } from '@jotforminc/uikit';
import PropTypes from 'prop-types';
import { checkMobilePhone } from '../../../utils';

/* eslint-disable max-len */
const ScColorPickerWrapper = Styled.div`
  display: flex;
  align-items: stretch;
  background: none;
  border-radius: 2px;
  border: solid 1px #303339;
  height: 38px;
  position: relative;
  background-color: var(--jfv-panel-right-item-background);
// A/B Test: ctAppNameIconModal
  .appNameIconSettingsModal & {
    background-color: transparent;
    border: 1px solid #C8CEED;
    border-radius: 4px;
  }

  > input[value] {
    border: none;
    flex: 1 1 auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: calc(100% - 38px);
    overflow-x : auto;
  }
  input:focus {
    box-shadow: none;
  }
  .colorInd {
    position: absolute;
    right: 0;
    height: 36px;
    padding: 6px;
    width: 36px;
    border-radius: 0 2px 2px 0;
    border-left: solid 1px #303339;
    background-color: var(--jfv-panel-right-item-background);
    div { height: 100%; }
    // A/B Test: ctAppNameIconModal
  .appNameIconSettingsModal & {
    border-left: 0;
    border-radius: 4;
    background-color: transparent;
    div {
      border-radius: 4px;
    }
  }
  }
  [data-popper-placement] {
    z-index: 1000;
    input {
      min-width: 95%;
    }
  }
  .sketch-picker > div:last-child > div:last-child {
    position: relative;
    border-radius: 3px;
    background-image: linear-gradient(45deg, #ebebeb 25%, transparent 25%, transparent 75%, #ebebeb 75%, #ebebeb),
                      linear-gradient(45deg, #ebebeb 25%, transparent 25%, transparent 75%, #ebebeb 75%, #ebebeb);
    background-size:10px 10px;
    background-position: 2px 4px, 7px 9px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.22406 12.7794C2.93288 12.4882 2.93171 12.0165 3.2223 11.7241L6.92822 7.99637L3.21877 4.29041C2.92994 4.00099 2.92641 3.53334 3.2123 3.24039L3.22347 3.22745C3.51583 2.92921 3.99642 2.92626 4.29055 3.22215L7.98529 6.93988L11.7089 3.21862C12.0042 2.9245 12.483 2.92744 12.7736 3.22568L12.7883 3.24039C13.073 3.53334 13.0706 4.00099 12.7806 4.29041L9.07178 7.99637L12.7777 11.7241C13.0677 12.0165 13.0671 12.4888 12.7753 12.7794L12.7742 12.7812C12.4818 13.0729 12.0083 13.0729 11.7165 12.7812L7.98529 9.05286L4.28349 12.7776C3.99172 13.0712 3.51701 13.0718 3.22465 12.7794' fill='%23FF0000'/%3E%3C/svg%3E%0A");
    }
  }
`;
/* eslint-enable max-len */

const DummyWrappperDiv = props => {
  return (<ScColorPickerWrapper {...props} />);
};
// eslint-disable-next-line react/prop-types
const DummyColorWrapper = forwardRef(({ color, onClick }, ref) => (
  <button
    type="button" className="colorInd" onClick={onClick}
    ref={ref}
  >
    <div style={{ background: color }} />
  </button>
));

export const ColorPicker = ({
  defaultValue, offsetX, offsetY, mobileOffsetX, mobileOffsetY, placement, mobilePlacement, ...props
}) => {
  const colorStringGenerator = useCallback(({ rgb, hex }) => {
    const alpha = defaultValue === '#FFF0' ? 1 : rgb.a;
    return alpha === 1 ? hex : `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`;
  }, [defaultValue]);

  const isMobile = checkMobilePhone();

  return (
    <UiKitColorPicker
      ColorRenderer={DummyColorWrapper}
      WrapperRenderer={DummyWrappperDiv}
      ColorPickerWrappper={DummyWrappperDiv}
      colorStringGenerator={colorStringGenerator}
      defaultValue={defaultValue}
      tabIndex={-1}
      popoverProps={{
        popoverOptions: {
          placement: isMobile ? mobilePlacement : placement,
          modifiers: [
            { name: 'offset', options: { offset: [(isMobile ? mobileOffsetX : offsetX), (isMobile && mobileOffsetY ? mobileOffsetY : offsetY)] } }
          ]
        }
      }}
      {...props}
    />
  );
};

ColorPicker.propTypes = {
  defaultValue: PropTypes.string,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  mobileOffsetX: PropTypes.number,
  mobileOffsetY: PropTypes.number,
  placement: PropTypes.string,
  mobilePlacement: PropTypes.string
};

ColorPicker.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  mobileOffsetX: 39,
  mobileOffsetY: undefined,
  placement: 'bottom-start',
  mobilePlacement: 'bottom-end',
  defaultValue: ''
};
