import { cva } from 'class-variance-authority';
import { FormControlContextType } from '../contexts';
import { BaseInputProps, CVAType } from '../types';
import { primary } from '../tokens/colors';
import { ResponsiveClasses } from '../utils';
import { JUSTIFY_CONTENT_VALUES } from './common.values';

type FormControlInputCVAType = CVAType<Pick<FormControlContextType, 'theme' | 'disabled' | 'size'> & Pick<BaseInputProps, 'ghost'>>;
type FormControlContainerCVAType = CVAType<Omit<FormControlContextType, 'id' | 'size'> & Pick<BaseInputProps, 'ghost'>>;

export const commonFormControlCVA = {
  container: cva<FormControlContainerCVAType>('flex', {
    variants: {
      colorStyle: {},
      disabled: {
        true: 'opacity-40'
      },
      readOnly: {},
      theme: {},
      required: {},
      ghost: {
        true: 'border-0 bg-transparent',
        false: 'border radius outline-2 outline-offset-0 hover:duration-300 focus:duration-300 duration-all-colors'
      }
    },
    compoundVariants: [
      {
        theme: 'light',
        className: primary.text.darkest.base
      },
      {
        theme: 'light',
        disabled: false,
        readOnly: false,
        ghost: false,
        className: 'bg-white'
      },
      {
        theme: 'light',
        disabled: true,
        readOnly: false,
        ghost: false,
        className: 'border-navy-50 bg-navy-50 outline-transparent'
      },
      // shadow
      {
        colorStyle: ['error', 'success', 'warning'],
        readOnly: false,
        ghost: false,
        className: 'shadow-xs'
      },
      {
        disabled: false,
        ghost: false,
        className: 'hover:shadow-xs'
      },
      // readonly - light
      {
        theme: 'light',
        disabled: false,
        readOnly: true,
        ghost: false,
        className: [
          primary.border.medium.base,
          primary.accent.default.border.focusWithin,
          'bg-navy-25 outline-transparent hover:border-navy-300 focus-within:outline-blue-200 focus-within:outline-opacity-50'
        ]
      },
      // default - light
      {
        theme: 'light',
        disabled: false,
        readOnly: false,
        colorStyle: 'default',
        ghost: false,
        className: [
          primary.border.medium.base,
          primary.accent.default.border.focusWithin,
          'outline-transparent hover:border-navy-300 focus-within:outline-blue-200 focus-within:outline-opacity-50'
        ]
      },
      // primary - light
      {
        theme: 'light',
        disabled: false,
        readOnly: false,
        colorStyle: 'primary',
        ghost: false,
        className: [
          primary.accent.default.border.base,
          'outline-blue-200 outline-opacity-0 hover:outline-opacity-30 focus-within:outline-opacity-50'
        ]
      },
      // success - light
      {
        theme: 'light',
        disabled: false,
        readOnly: false,
        colorStyle: 'success',
        ghost: false,
        className: [
          primary.success.default.border.base,
          'outline-green-200 outline-opacity-0 hover:outline-opacity-50 focus-within:outline-opacity-100'
        ]
      },
      // error - light
      {
        theme: 'light',
        disabled: false,
        readOnly: false,
        colorStyle: 'error',
        ghost: false,
        className: [
          primary.error.default.border.base,
          'outline-red-200 outline-opacity-0 hover:outline-opacity-50 focus-within:outline-opacity-100'
        ]
      },
      // warning - light
      {
        theme: 'light',
        disabled: false,
        readOnly: false,
        ghost: false,
        colorStyle: 'warning',
        className: 'border-yellow-500 outline-yellow-200 outline-opacity-0 hover:outline-opacity-50 focus-within:outline-opacity-100'
      },
      {
        theme: 'dark',
        className: 'color-white'
      },
      {
        theme: 'dark',
        ghost: false,
        className: 'bg-gray-500'
      },
      // default - dark
      {
        theme: 'dark',
        disabled: false,
        readOnly: false,
        ghost: false,
        colorStyle: 'default',
        className: [
          primary.accent.default.border.focusWithin,
          'border-gray-500 outline-transparent hover:border-gray-300 focus-within:outline-blue-500 focus-within:outline-opacity-30'
        ]
      },
      // primary - dark
      {
        theme: 'dark',
        disabled: false,
        readOnly: false,
        ghost: false,
        colorStyle: 'primary',
        className: [
          primary.accent.default.border.base,
          'outline-blue-500 outline-opacity-0 hover:outline-opacity-20 focus-within:outline-opacity-30'
        ]
      },
      // success - dark
      {
        theme: 'dark',
        disabled: false,
        readOnly: false,
        ghost: false,
        colorStyle: 'success',
        className: [
          primary.success.default.border.base,
          'outline-green-600 outline-opacity-0 hover:outline-opacity-30 focus-within:outline-opacity-50'
        ]
      },
      // error - dark
      {
        theme: 'dark',
        disabled: false,
        readOnly: false,
        ghost: false,
        colorStyle: 'error',
        className: 'border-red-400 outline-red-400 outline-opacity-0 hover:outline-opacity-30 focus-within:outline-opacity-50'
      },
      // warning - dark
      {
        theme: 'dark',
        disabled: false,
        readOnly: false,
        ghost: false,
        colorStyle: 'warning',
        className: 'border-yellow-500 outline-yellow-500 outline-opacity-0 hover:outline-opacity-30 focus-within:outline-opacity-50'
      },
      // readonly - dark
      {
        theme: 'dark',
        disabled: false,
        readOnly: true,
        ghost: false,
        className: [
          primary.accent.default.border.focusWithin,
          'border-gray-500 outline-transparent hover:border-gray-300 focus-within:outline-blue-500 focus-within:outline-opacity-30'
        ]
      },
      {
        theme: 'dark',
        disabled: true,
        readOnly: false,
        ghost: false,
        className: 'outline-transparent border-gray-500'
      }
    ]
  }),
  input: cva<FormControlInputCVAType>('appearance-none bg-transparent grow-1 outline-0 w-full focus-visible-none border-0', {
    variants: {
      size: {
        small: 'text-sm',
        medium: 'text-sm',
        large: 'text-md'
      },
      theme: {},
      disabled: {},
      ghost: {
        true: '',
        false: 'radius'
      }
    },
    compoundVariants: [
      {
        theme: 'light',
        disabled: false,
        className: 'placeholder-navy-200'
      },
      {
        theme: 'light',
        disabled: true,
        className: 'placeholder-navy-300'
      }
    ]
  })
};

export const justifyContentClasses:ResponsiveClasses<typeof JUSTIFY_CONTENT_VALUES> = {
  around: {
    base: 'justify-around',
    xs: 'xs:justify-around',
    sm: 'sm:justify-around',
    md: 'md:justify-around',
    lg: 'lg:justify-around',
    xl: 'xl:justify-around',
    xxl: 'xxl:justify-around'
  },
  between: {
    base: 'justify-between',
    xs: 'xs:justify-between',
    sm: 'sm:justify-between',
    md: 'md:justify-between',
    lg: 'lg:justify-between',
    xl: 'xl:justify-between',
    xxl: 'xxl:justify-between'
  },
  center: {
    base: 'justify-center',
    xs: 'xs:justify-center',
    sm: 'sm:justify-center',
    md: 'md:justify-center',
    lg: 'lg:justify-center',
    xl: 'xl:justify-center',
    xxl: 'xxl:justify-center'
  },
  end: {
    base: 'justify-end',
    xs: 'xs:justify-end',
    sm: 'sm:justify-end',
    md: 'md:justify-end',
    lg: 'lg:justify-end',
    xl: 'xl:justify-end',
    xxl: 'xxl:justify-end'
  },
  evenly: {
    base: 'justify-evenly',
    xs: 'xs:justify-evenly',
    sm: 'sm:justify-evenly',
    md: 'md:justify-evenly',
    lg: 'lg:justify-evenly',
    xl: 'xl:justify-evenly',
    xxl: 'xxl:justify-evenly'
  },
  start: {
    base: 'justify-start',
    xs: 'xs:justify-start',
    sm: 'sm:justify-start',
    md: 'md:justify-start',
    lg: 'lg:justify-start',
    xl: 'xl:justify-start',
    xxl: 'xxl:justify-start'
  }
};
