import React, { forwardRef } from 'react';
import { ScToggleButtonGroupContainer } from './ScToggleButtonGroupContainer';

const DefaultToggleButtonGrpRenderer = forwardRef((props, ref) => (
  <ScToggleButtonGroupContainer
    {...props}
    data-testid="toggle-button-group"
    ref={ref}
  />
));

export default DefaultToggleButtonGrpRenderer;
