/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@jotforminc/magnet';
import { IconCopy } from '@jotforminc/svg-icons';

import { handleCustomNavigation } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import WorkflowTemplateAreaRenderer from './components/PreviewModalContents/ApprovalTemplatePreviewContent';
import TableTemplateAreaRenderer from './components/PreviewModalContents/TableTemplatePreviewContent';
import { AskMeSectionRenderer, FormTemplatesTabRenderer, OverviewTabRenderer } from './components/PreviewModalContents/AgentTemplatePreviewContent';
import AppTemplateAreaRenderer from './components/PreviewModalContents/AppTemplateAreaRenderer';
import { formatBigNumber } from './utils';
import PDFTemplateAreaRenderer from './components/PreviewModalContents/PDFTemplateAreaRenderer';

export const customModalConstants = {
  'agent-templates': {
    OverviewTabRenderer,
    cloneCTAColor: 'apps',
    ctaClasses: ['isAgent'],
    customCTAText: 'Use Agent',
    FormTemplatesTabRenderer,
    customLoadMoreText: 'Show more agents',
    similarTemplatesText: 'Other agents',
    customSearchPlaceholder: 'Search in Agent Directory',
    templateAreaRendererClass: 'w-full h-full relative z-0',
    tabs: [{ id: 'form-templates', name: 'Connected Forms', data: '' }],
    CategoriesSectionRenderer: ({ template, frameRef }) => <AskMeSectionRenderer template={template} frameRef={frameRef} />
  },
  'form-templates': {},
  'sign-templates': {
    TemplateAreaRenderer: PDFTemplateAreaRenderer
  },
  'pdf-templates': {
    TemplateAreaRenderer: PDFTemplateAreaRenderer
  },
  'app-templates': {
    TemplateAreaRenderer: AppTemplateAreaRenderer
  },
  'approval-templates': {
    TemplateAreaRenderer: WorkflowTemplateAreaRenderer,
    customSearchPlaceholder: 'Search in Workflow Templates'
  },
  'table-templates': {
    TemplateAreaRenderer: TableTemplateAreaRenderer,
    ctaClasses: ['shrink-1'],
    SecondaryCTA: ({ slug }) => (
      <Button
        colorStyle="secondary"
        variant="outline"
        size="large"
        onClick={() => handleCustomNavigation(`/table-templates/demo/${slug}`, '_self')}
      >
        {t('See Demo')}
      </Button>
    )
  },
  'page-templates': {
  }
};

export const DefaultOverviewTabRenderer = ({
  isEnterpriseEnv, templateCategoriesData,
  _description, description, _clonecount, clonecount,
  templateType, username, _username
}) => {
  return (
    <div data-tab="overview" className="details-tab-content flex flex-col lg:flex-row mt-10 gap-10 lg:gap-16 pb-4 lg:pb-16">
      {
        (!isEnterpriseEnv) && (
          <div className="flex-1">
            <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">{t('About this template')}</strong>
            <div className="color-navy-300 text-md line-height-xl about-this-template-section" dangerouslySetInnerHTML={{ __html: _description || description }} />
          </div>
        )
      }
      <div className="w-80 shrink-0 flex flex-col gap-10 lg:gap-16">
        <div className="categories-section block lg:hidden">
          <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">Categories</strong>
          <ul className="flex flex-wrap gap-3 -mb-1">
            {templateCategoriesData.map(category => (
              <li>
                <a
                  href={category.link} title={category.title}
                  className="no-underline color-navy-300 py-0.5 px-2 text-sm line-height-md radius mb-1 flex border-0 bg-navy-25 hover:bg-navy-75"
                  rel="nofollow noopener"
                >
                  {category.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="details-section">
          <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">Details</strong>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-1 text-xs color-navy-300" tabindex="0">
              <IconCopy width={16} height={16} />
              <div>
                <strong>{formatBigNumber(_clonecount || clonecount)}</strong>
                <span>
                  {' '}
                  {t('Clone')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {
          !isEnterpriseEnv && (
            <div className="created-by-section">
              <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">Created by</strong>
              <div className="flex items-center gap-2">
                <div className="radius-full border border-navy-50 w-8 h-8 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 28"
                    width="16" height="16"
                  >
                    <path
                      id="jotform-logomark-first"
                      fill="#FF6100"
                      d="M19.294 3.934a3.341 3.341 0 0 1 4.727 0 3.344 3.344 0 0 1 0 4.728l-11.817 11.82a3.341 3.341 0 0 1-4.726 0 3.344 3.344 0 0 1 0-4.727l11.816-11.82Z"
                    />
                    <path id="jotform-logomark-second" fill="#0099FF" d="M9.25.98a3.344 3.344 0 0 1 4.727 4.728l-8.271 8.274a3.342 3.342 0 0 1-4.727 0 3.344 3.344 0 0 1 0-4.729L9.25.98Z" />
                    <path id="jotform-logomark-third" fill="#FFB629" d="M18.704 17.527a3.344 3.344 0 0 1 4.726 4.728l-4.726 4.729a3.342 3.342 0 1 1-4.727-4.728l4.727-4.729Z" />
                    <path id="jotform-logomark-fourth" fill="#0A1551" d="M1.671 28h4.667c.745 0 1.118-.9.591-1.427L1.426 21.07C.9 20.542 0 20.915 0 21.659v4.67C0 27.251.748 28 1.671 28Z" />
                  </svg>
                </div>
                <a
                  target="_blank"
                  rel="nofollow noopener"
                  href={`/${templateType}/user/${_username || username}`}
                  className="color-blue-500 text-md line-height-xl no-underline hover:underline"
                >
                  {_username || username}
                </a>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
