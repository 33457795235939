import { cva } from 'class-variance-authority';
import { CVAType } from '../../types/system.types';
import { DialogIconProps, DialogProps } from './dialog.types';
import { primary } from '../../tokens/colors';

type DialogIconCVAType = CVAType<Pick<DialogIconProps, 'colorStyle'>>;
type DialogContainerCVAType = CVAType<Pick<DialogProps, 'noFade' >>
type DialogCVAType = CVAType<Pick<DialogProps, 'size' >>

export const dialogContainerCVA = cva<DialogContainerCVAType>('bg-gray-900 bg-opacity-60 fixed inset-0 flex items-end xs:items-center justify-center pt-12 xs:pt-0', {
  variants: {
    noFade: {
      false: 'anim-150 fade-in'
    }
  }
});
export const dialogCVA = cva<DialogCVAType>(`${primary.text.medium.base} bg-white w-full radius-t-lg xs:radius-b-lg max-h-full flex flex-col relative m-0`, {
  variants: {
    size: {
      small: 'max-w-120',
      medium: 'max-w-sm'
    }
  }
});
export const dialogIconCVA = cva<DialogIconCVAType>('bg-opacity-50 flex items-center justify-center w-16 h-16 xs:w-20 xs:h-20 radius-lg mb-8 mx-auto', {
  variants: {
    colorStyle: {
      success: 'bg-green-200 color-green-500',
      informative: 'bg-blue-200 color-blue-500',
      warning: 'bg-yellow-200 color-yellow-500',
      error: 'bg-red-200 color-red-500'
    }
  }
});
