import React, { Component } from 'react';
import {
  shape, string, bool, func, node, elementType
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import HiddenFrame from '../HiddenFrame';
import DragContainer from '../DragContainer';
import {
  Form as DefaultForm,
  Text as DefaultText,
  Wrapper as DefaultWrapper,
  ErrorText as DefaultErrorText,
  Thumbnail as DefaultThumbnail,
  FileInput as DefaultFileInput,
  FilePreview as DefaultFilePreview,
  TextWrapper as DefaultTextWrapper,
  DragContainer as DefaultDragContainer,
  LoadingScreen as DefaultLoadingScreen
} from './defaults';

import { createFileMetadata } from '../../utils';
import { SERVER_ERROR, FILE_TYPE_ERROR } from '../../utils/constants';

let apiKey = '';
const isThereInvitation = global.location.href.match(/iak=([\d | \w | -]*)/) !== null && global.location.href.match(/iak=([\d | \w | -]*)/).length === 2;
let invitationAPIKey = '';
if (isThereInvitation) {
  [, invitationAPIKey] = global.location.href.match(/iak=([\d | \w | -]*)/);
}
if (isThereInvitation && invitationAPIKey.length > 0) {
  apiKey = `${invitationAPIKey}`;
}
// We have duplicated above apiKey logic from form-builder/api.js for a fast secuirty fix of #5463996
// And we need to put it to global scope unfortunately because these iak params gets deleted on page navigations.
// an unfortunate lazy and fast fix :(

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.currentlyUploadingFile = null;

    const { showFile } = props;
    const isModeDisplay = showFile && showFile.name;
    const uploadedFiles = isModeDisplay ? [showFile] : [];
    const mode = isModeDisplay ? 'display' : 'upload';
    this.state = {
      mode,
      uploadedFiles,
      init: true,
      isDragging: false,
      isUploading: false
    };

    const xhr = new global.XMLHttpRequest();
    this.supportXHR2 = xhr.upload && window.File && window.FileList && window.FileReader;
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { showFile } = nextProps;
    const { uploadedFiles } = prevState;
    if (showFile?.name && !isEqual(showFile, uploadedFiles[0])) {
      return {
        mode: 'display',
        uploadedFiles: [showFile],
        init: true,
        isDragging: false,
        isUploading: false
      };
    }
    return null;
  };

  handleFormSubmit = () => this.setState({ isUploading: true, init: false });

  handleUploadFrameLoad = ({ content }) => {
    const [serverMessage] = (content.match(/\{.*\}/) || ['']);
    try {
      const response = JSON.parse(serverMessage);
      if (response.success !== true) {
        this.fileUploadError(SERVER_ERROR);
        return;
      }
      const { multipleFileUpload } = this.props;
      if (multipleFileUpload && !this.checkMultipleUploadError(response)) {
        return;
      }
      this.fileUploadComplete(response);
    } catch (e) {
      this.fileUploadError(SERVER_ERROR);
    }
  };

  handleDragStateChange = isDragging => this.setState({ isDragging });

  get uploadURL() {
    const { targetURL, optimize } = this.props;
    const isQueryStringExist = /[?&](.*?)=/.test(targetURL);
    const extraParameter = optimize ? `${isQueryStringExist ? '&' : '?'}optimize=1` : '';
    return `${targetURL}${extraParameter}`;
  }

  uploadFile = e => {
    // is file uploading
    const { onFileUploading, limitType, multipleFileUpload } = this.props;
    onFileUploading(true);

    const fd = new global.FormData();
    let files = e.target.files || e.dataTransfer.files;
    if (multipleFileUpload) {
      files = (files.length && !Array.isArray(files)) ? Array.from(files) : files;
    }

    if (!this.controlUploadingFiles(files)) {
      return;
    }

    if (multipleFileUpload) {
      files.forEach((file, index) => {
        if (index < 10 && this.checkFileType(file)) {
          fd.append('file[]', file);
        }
      });
    } else {
      [this.currentlyUploadingFile] = files;
      const newFileName = this.currentlyUploadingFile.name.split('\'').join('');
      fd.append('file', this.currentlyUploadingFile, newFileName);
    }

    if (limitType) {
      fd.append('limit_type', limitType);
    }

    this.setState({
      isUploading: true,
      isUploadFailed: false,
      init: false
    }, () => {
      const request = new global.XMLHttpRequest();
      request.open('POST', this.uploadURL);
      if (apiKey) { // for collaborator auths
        request.setRequestHeader('apiKey', apiKey);
      }
      request.onreadystatechange = () => {
        if (request.status === 200) {
          if (request.readyState === 4) {
            try {
              const response = JSON.parse(request.responseText);
              if (response.success !== true) {
                this.fileUploadError(SERVER_ERROR);
                return;
              }

              if (multipleFileUpload && !this.checkMultipleUploadError(response, files)) {
                return;
              }

              this.fileUploadComplete(response);
            } catch (err) {
              this.fileUploadError(SERVER_ERROR);
            }
          }
        } else { // request not success
          this.fileUploadError(SERVER_ERROR);
        }
      };
      request.send(fd);
    });
  };

  fileUploadComplete = resp => {
    let { uploadedFiles: uploadedFilesArr } = this.state;
    const {
      multipleFileUpload, displayUpload, onFileUpload, multi, onFileUploading, onDisplayModeChange
    } = this.props;

    // is file uploading complete
    onFileUploading(false);

    const url = encodeURI(resp.message);
    const newFileRecord = createFileMetadata(this.currentlyUploadingFile, url);

    let urls = [];
    if (resp.message.length > 0) {
      urls = resp.message;
    }
    if (multipleFileUpload) {
      urls.forEach((currentUrl, index) => {
        if (typeof currentUrl !== 'object' && (typeof currentUrl === 'string' && !currentUrl.match(/\{.*\}/))) { // The object file means it's an error.
          uploadedFilesArr.push(createFileMetadata(this.currentlyUploadingFile[index], currentUrl));
        }
      });
    } else {
      uploadedFilesArr = [newFileRecord];
    }

    const nextMode = displayUpload ? 'display' : 'upload';
    this.setState({
      uploadedFiles: uploadedFilesArr,
      isUploading: false,
      init: false,
      isDragging: false,
      mode: nextMode
    });

    onDisplayModeChange(nextMode);
    if (!onFileUpload) {
      return;
    }

    if (multipleFileUpload) {
      onFileUpload(uploadedFilesArr);
    } else if (multi) {
      const fileNames = uploadedFilesArr.map(file => file.name);
      onFileUpload(fileNames);
    } else {
      onFileUpload(newFileRecord);
    }
  };

  checkMultipleUploadError = (response, files) => {
    const { addNotification } = this.props;

    const errors = [];
    if (Array.isArray(response.message)) {
      response.message.forEach(file => {
        if (typeof file === 'object') {
          errors.push(file.error);
        } else if (typeof file === 'string' && file.match(/\{.*\}/)) {
          errors.push(JSON.parse(file).error);
        }
      });

      if (errors.length === response.message.length) {
        this.fileUploadError(errors.join('\n'));
        return false;
      }

      if (errors.length > 0 && addNotification) {
        errors.forEach(error => {
          setTimeout(() => {
            // SetTimeout is necessary to send all errors.
            addNotification({
              message: error,
              autoDismiss: 7,
              level: 'error'
            });
          }, 100);
        });
      }

      if (files && files.length > 10 && addNotification) {
        addNotification({
          autoDismiss: 7,
          level: 'error',
          message: `${10 - errors.length} of the images were uploaded succesfully. ${files.length - 10} images couldn't be attached due to file limit (max 10 images)`
        });
      }

      return true;
    }

    if (typeof response.message === 'string' && response.message.match(/\{.*\}/)) {
      const errorMessage = JSON.parse(response.message).error;
      this.fileUploadError(errorMessage);
      return false;
    }

    this.fileUploadError(SERVER_ERROR);
    return false;
  };

  fileUploadError = message => {
    const { init, isDragging } = this.state;
    if (init && !isDragging) {
      return;
    }

    const { onError, onFileUploading } = this.props;
    onFileUploading(false);
    onError(message);

    this.setState({
      isDragging: false,
      isUploadFailed: true,
      isUploading: false,
      errorMessage: message
    });
  };

  controlUploadingFiles = files => {
    const { multipleFileUpload, addNotification, onFileUploading } = this.props;
    if (!multipleFileUpload) {
      [this.currentlyUploadingFile] = files;
      return Boolean(this.checkFileType());
    }

    const errors = [];

    files.forEach(file => {
      this.currentlyUploadingFile = file;
      if (!this.checkFileType()) {
        errors.push(FILE_TYPE_ERROR);
      }
    });

    if (errors.length === files.length) {
      this.fileUploadError(FILE_TYPE_ERROR);
      onFileUploading(false);
      return false;
    }

    if (errors.length > 0) {
      errors.forEach(error => {
        setTimeout(() => {
          addNotification({
            message: error,
            autoDismiss: 7,
            level: 'error'
          });
        }, 100);
      });
    }

    this.currentlyUploadingFile = files;
    return true;
  };

  checkFileType = checkingFile => {
    const { imageUpload, allowedTypes, typeErrorMessage } = this.props;
    const file = checkingFile || this.currentlyUploadingFile;
    if (imageUpload) {
      if (file.type.indexOf('image') < 0 || file.type.indexOf('image/heic') > -1) {
        this.fileUploadError(FILE_TYPE_ERROR);
        return false;
      }

      return true;
    }

    if (isEmpty(allowedTypes)) {
      return true;
    }

    const accepted = allowedTypes.split(',').map(el => el.trim());
    if (accepted.indexOf('image/*') >= 0 && file.type.indexOf('image') < 0 && accepted.indexOf(file.type) < 0) {
      this.fileUploadError(typeErrorMessage || FILE_TYPE_ERROR);
      return false;
    }

    if (accepted.indexOf(file.type) < 0) {
      this.fileUploadError(typeErrorMessage || FILE_TYPE_ERROR);
      return false;
    }

    return true;
  };

  changeModeToUpload = () => {
    const { onFileRemove, onDisplayModeChange } = this.props;
    this.setState({
      mode: 'upload',
      uploadedFiles: []
    }, () => {
      onDisplayModeChange('upload');
    });

    if (onFileRemove) {
      onFileRemove();
    }
  };

  renderForm() {
    const { isDragging } = this.state;
    const {
      children, buttonText, multipleFileUpload, putFileInput, imageUpload, allowedTypes, translate,
      FileInputRenderer, FormRenderer, TextRenderer
    } = this.props;

    const containerDisplay = isDragging ? 'none' : 'block';
    const accept = (imageUpload && !allowedTypes) ? 'image/*' : allowedTypes;
    const inputEl = children || (
      <FileInputRenderer
        translate={translate}
        buttonText={buttonText}
        putFileInput={putFileInput}
        multiple={multipleFileUpload}
        accept={accept}
        name={multipleFileUpload ? 'file[]' : 'file'}
        onChange={this.uploadFile}
        onClick={() => this.setState({ init: false })}
      />
    );

    if (!putFileInput) {
      return (
        <TextRenderer style={{ display: containerDisplay, textAlign: 'center' }}>
          {inputEl}
        </TextRenderer>
      );
    }

    return (
      <FormRenderer
        id="myForm"
        style={{ display: containerDisplay }}
        action={this.uploadURL}
        method="post"
        encType="multipart/form-data"
        onSubmit={this.handleFormSubmit}
        target="uploadTrg"
      >
        {inputEl}
      </FormRenderer>
    );
  }

  render() {
    const {
      multipleFileUpload, putFileInput, translate,
      WrapperRenderer, FilePreviewRenderer, DragContainerRenderer, TextRenderer,
      ErrorTextRenderer, TextWrapperRenderer, LoadingScreenRenderer, isFileUploading,
      removeButtonText, Thumbnail
    } = this.props;
    const {
      isUploadFailed, mode, isUploading, errorMessage, isDragging, uploadedFiles
    } = this.state;

    const [file] = uploadedFiles;

    const isStillFileUploading = isUploading || isFileUploading;

    return (
      <WrapperRenderer>
        {!multipleFileUpload && file && (
        <FilePreviewRenderer
          file={file} translate={translate} changeModeToUpload={this.changeModeToUpload}
          Thumbnail={Thumbnail} removeButtonText={removeButtonText}
        />
        )}
        <DragContainer
          onDrop={this.uploadFile}
          onDragStateChange={this.handleDragStateChange}
          ContainerRenderer={DragContainerRenderer}
          style={{ display: mode === 'display' ? 'none' : 'block' }}
          className={`fileUpload${isUploadFailed ? ' hasError' : ''}`}
        >
          {putFileInput && (
            <HiddenFrame onLoad={this.handleUploadFrameLoad} />
          )}
          <TextWrapperRenderer>
            {!isStillFileUploading && this.renderForm()}
            {this.supportXHR2 && !isStillFileUploading && (
              <TextRenderer style={{ display: (isDragging || isUploadFailed) ? 'none' : 'block' }}>
                {translate('or drag and drop here')}
              </TextRenderer>
            )}
            {(isDragging && !isStillFileUploading) && (
              <TextRenderer>
                {translate('Drop files here')}
              </TextRenderer>
            )}
            {isStillFileUploading && (
              <LoadingScreenRenderer
                Wrapper={TextRenderer}
                translate={translate}
              />
            )}
            {isUploadFailed && (
              <ErrorTextRenderer>
                {translate(errorMessage)}
              </ErrorTextRenderer>
            )}
          </TextWrapperRenderer>
        </DragContainer>
      </WrapperRenderer>
    );
  }
}

FileUpload.propTypes = {
  TextRenderer: elementType,
  FormRenderer: elementType,
  WrapperRenderer: elementType,
  ErrorTextRenderer: elementType,
  FileInputRenderer: elementType,
  FilePreviewRenderer: elementType,
  TextWrapperRenderer: elementType,
  DragContainerRenderer: elementType,
  children: node,
  showFile: shape({
    name: string
  }),
  buttonText: string,
  allowedTypes: string,
  typeErrorMessage: string,
  targetURL: string,
  limitType: string,
  multi: bool,
  multipleFileUpload: bool,
  imageUpload: bool,
  putFileInput: bool,
  displayUpload: bool,
  optimize: bool,
  onFileUpload: func,
  onFileRemove: func,
  addNotification: func,
  translate: func,
  LoadingScreenRenderer: elementType,
  onFileUploading: func,
  isFileUploading: bool,
  removeButtonText: string,
  Thumbnail: elementType,
  onError: func,
  onDisplayModeChange: func
};

FileUpload.defaultProps = {
  TextRenderer: DefaultText,
  FormRenderer: DefaultForm,
  WrapperRenderer: DefaultWrapper,
  ErrorTextRenderer: DefaultErrorText,
  FileInputRenderer: DefaultFileInput,
  FilePreviewRenderer: DefaultFilePreview,
  TextWrapperRenderer: DefaultTextWrapper,
  DragContainerRenderer: DefaultDragContainer,
  Thumbnail: DefaultThumbnail,
  children: null,
  showFile: {},
  buttonText: 'Upload File',
  allowedTypes: '',
  typeErrorMessage: '',
  limitType: '',
  targetURL: '/server.php?action=uploadImageS3',
  putFileInput: true,
  optimize: false,
  multipleFileUpload: false,
  multi: false,
  imageUpload: false,
  displayUpload: false,
  onFileUpload: f => f,
  onFileRemove: f => f,
  addNotification: f => f,
  translate: f => f,
  LoadingScreenRenderer: DefaultLoadingScreen,
  onFileUploading: f => f,
  isFileUploading: false,
  removeButtonText: 'Remove File',
  onError: f => f,
  onDisplayModeChange: f => f
};

export {
  DefaultText as Text,
  DefaultForm as Form,
  DefaultWrapper as Wrapper,
  DefaultFileInput as FileInput,
  DefaultThumbnail as Thumbnail,
  DefaultErrorText as ErrorText,
  DefaultFilePreview as FilePreview,
  DefaultTextWrapper as TextWrapper,
  DefaultDragContainer as DragContainer,
  DefaultLoadingScreen as LoadingScreen
};

export default FileUpload;
