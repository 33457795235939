import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Square gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlSquare: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_square',
  name: gatewayFieldToName.control_square,
  supportedCurrencies: gatewaySupportedCurrencies.control_square,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_square,
  sortByCountry: gatewayListSortedByCountry.control_square,

  /** APM's */
  isApm: false,
  isApmSupport: true,
  supportedApm: {
    control_applePayGooglePay: {
      name: gatewayFieldToName.control_applePayGooglePay,
      supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_square,
      supportedCurrencies: gatewaySupportedCurrencies.control_square
    },
    control_cashApp: {
      name: gatewayFieldToName.control_cashApp,
      supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_square,
      supportedCurrencies: gatewaySupportedCurrencies.control_square
    },
    control_afterpay: {
      name: gatewayFieldToName.control_afterpay,
      supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_square,
      supportedCurrencies: gatewaySupportedCurrencies.control_square
    },
    control_clearpay: {
      name: gatewayFieldToName.control_clearpay,
      supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_square,
      supportedCurrencies: gatewaySupportedCurrencies.control_square
    }
  },
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'oauth',
    allowSwitchMode: true,
    mode: {
      name: 'allowTest',
      options: [
        { value: 'true', text: 'Test Mode', mappedValue: '1' },
        { value: 'false', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    isChargeLaterSupport: true,
    chargeLaterPropReferences: {
      activator: {
        propName: 'authOnly',
        propValue: 'Yes'
      }
    },
    propReferences: {
      merchantId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Merchant ID' } },
      accessToken: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Access Token' } },
      refreshToken: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Refresh Token' } },
      allowTest: { isSecret: 'No', isRequired: 'Yes' },
      tokenExpiry: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Token Expiry' } }
    },
    uniquePropNames: ['merchantId']
  }
};
