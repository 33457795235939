import { requestLayer } from './requestLayer';
import { CONNECTION_STATUS_TYPES } from '../types';

/**
 * Changes the status of a connection.
 *
 * @param connectionId - The ID of the connection.
 * @param status{CONNECTION_STATUS_TYPES} - The new status of the connection.
 * @returns A Promise that resolves to the response from the server.
 */
export const changeStatusConnection = (connectionId: string, status: CONNECTION_STATUS_TYPES): Promise<any> => {
  const postData = new FormData();
  postData.append('connectionId', connectionId);
  postData.append('key', 'status');
  postData.append('value', status);

  return requestLayer.post(`/API/payment/update-connection/${connectionId}`, postData);
};
