import React, { forwardRef } from 'react';
import { elementType, node } from 'prop-types';

import TabList from './TabList';
import './components/TabList.scss';

import {
  Wrapper,
  TabListContainer,
  TabButtonContainer,
  TabButton
} from './components';

const TabListWithStyles = forwardRef(({
  children, ...props
}, tabListRef) => (
  <TabList {...props} ref={tabListRef}>
    {children}
  </TabList>
));

TabListWithStyles.propTypes = {
  children: node,
  Wrapper: elementType,
  TabListContainer: elementType,
  TabButtonContainer: elementType,
  TabButton: elementType
};

TabListWithStyles.defaultProps = {
  children: null,
  Wrapper,
  TabListContainer,
  TabButtonContainer,
  TabButton
};

export default TabListWithStyles;
