import React, { useMemo } from 'react';
import { t } from '@jotforminc/translation';
import propTypes from 'prop-types';
import DataSourcePicker from '..';
import { DATA_SOURCE_COLUMN_TYPES } from '../../../../../../../constants/itemTypes';
import { CARD_ACTION_VALUES } from '../../constants';
import { BUTTON_ROLE_TYPES } from '../../ButtonActions/buttonRoleTypes';

const DsRoleComponents = ({ buttonRole, ROLE_COMPONENT_PROPS, ...rest }) => {
  const roleProps = useMemo(() => ROLE_COMPONENT_PROPS[buttonRole], [buttonRole]);

  const excludedDsRoles = [CARD_ACTION_VALUES.NAVIGATION, CARD_ACTION_VALUES.FORM, BUTTON_ROLE_TYPES.SHARE];

  if (!roleProps) {
    return null;
  }

  const { title, RoleComponent, componentProps } = roleProps;

  return (
    <>
      <label htmlFor={buttonRole}>{t(title)}</label>
      {
        excludedDsRoles.includes(buttonRole)
          ? (
            <RoleComponent
              id={buttonRole}
              {...componentProps}
              {...rest}
            />
          )
          : (
            <DataSourcePicker
              columnType={DATA_SOURCE_COLUMN_TYPES.TEXT}
              isSingleEntry={buttonRole === CARD_ACTION_VALUES.LINK}
              {...rest}
            />
          )
      }
    </>
  );
};

DsRoleComponents.propTypes = {
  buttonRole: propTypes.string,
  ROLE_COMPONENT_PROPS: propTypes.array
};

DsRoleComponents.defaultProps = {
  buttonRole: '',
  ROLE_COMPONENT_PROPS: []
};

export default DsRoleComponents;
