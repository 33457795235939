import {
  func, number, oneOfType, string
} from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PortalFileUpload from '../../../../../components/PortalFileUpload';
import { updateItemLoadingStatus } from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';

const DocumentFileUpload = props => {
  const {
    fileName, fileType, fileURL, fileSize, itemID, tempID, allowedFileTypes, onChange
  } = props;
  const dispatch = useDispatch();
  const isFileUploading = useSelector(SELECTORS.getItemLoadingStatusByID(itemID || tempID)); // TODO this can be passed as prop too

  const onFileUploading = useCallback(val => {
    dispatch(updateItemLoadingStatus({
      [tempID || itemID]: val
    }));
  }, [itemID, tempID]);

  // we may take error message as a parameter if we create our own error comp. renderer
  const handleFileUploadError = useCallback(() => onFileUploading(false), [onFileUploading]);

  const handleFileUpload = useCallback(({
    name, url, type, size
  }) => onChange({
    fileURL: url, fileName: name, fileType: type, fileSize: size?.toString(), title: name
  }), [onChange]);

  const handleFileRemove = useCallback(() => onChange({
    fileURL: '', fileName: '', fileType: '', fileSize: '', title: ''
  }), [onChange]);

  const handleFileUploading = useCallback(val => onFileUploading(val), [onFileUploading]);

  const value = useMemo(() => ({
    name: fileName,
    type: fileType,
    url: fileURL,
    size: fileSize
  }), [fileURL]);

  return (
    <PortalFileUpload
      {...props}
      value={value}
      onFileUpload={handleFileUpload}
      onFileRemove={handleFileRemove}
      isFileUploading={!!isFileUploading}
      onFileUploading={handleFileUploading}
      onFileUploadError={handleFileUploadError}
      allowedFileType={allowedFileTypes}
    />
  );
};

DocumentFileUpload.propTypes = {
  fileName: string,
  fileType: string,
  fileURL: string,
  fileSize: string,
  theme: string,
  itemID: oneOfType([string, number]),
  tempID: string,
  allowedFileTypes: string,
  onChange: func
};

DocumentFileUpload.defaultProps = {
  fileName: '',
  fileType: '',
  fileURL: '',
  fileSize: '',
  theme: '',
  itemID: '',
  tempID: '',
  allowedFileTypes: '',
  onChange: f => f
};

export default DocumentFileUpload;
