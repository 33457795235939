import { cva } from 'class-variance-authority';
import { primary, secondary } from '../../tokens/colors';
import { CVAType, RadioProps } from '../../types';

// Common classes
const radioSizes = {
  small: 'w-4 h-4',
  medium: 'w-5 h-5',
  large: 'w-6 h-6'
};

// CVA Types
type RadioCVAType = CVAType<Pick<RadioProps, 'theme' | 'size' | 'disabled' | 'checked' | 'error'>>
type RadioCircleCVAType = CVAType<Pick<RadioProps, 'size' | 'theme' | 'disabled'>>
type RadioWrapper = CVAType<Pick<RadioProps, 'size'>>

// Variance values
export const radioCVA = cva<RadioCVAType>(
  ['appearance-none border-2 radius-full inline-flex shrink-0 focus:outline-2 outline-offset-0 m-0'], {
    variants: {
      theme: {
        dark: 'outline-blue-600 outline-opacity-50',
        light: 'outline-blue-200'
      },
      size: radioSizes,
      disabled: {
        true: 'cursor-not-allowed'
      },
      checked: {},
      error: {}
    },
    compoundVariants: [
      {
        theme: ['dark', 'light'],
        checked: false,
        disabled: false,
        error: true,
        className: [
          primary.error.default.border.base,
          primary.error.dark.border.hover
        ]
      },
      // Dark
      {
        theme: 'dark',
        checked: false,
        disabled: false,
        error: false,
        className: [
          secondary.background.dark.base,
          secondary.border.medium.base,
          primary.accent.default.border.hover
        ]
      },
      {
        theme: ['dark', 'light'],
        checked: true,
        disabled: false,
        className: [
          primary.accent.default.bg.base,
          primary.accent.default.border.base,
          primary.accent.dark.bg.hover,
          primary.accent.dark.border.hover,
          primary.accent.default.bg.focus
        ]
      },
      {
        theme: 'dark',
        checked: true,
        disabled: true,
        className: [secondary.background.light.base, secondary.border.medium.base]
      },
      {
        theme: 'dark',
        checked: false,
        disabled: true,
        className: [secondary.background.medium.base, secondary.border.medium.base]
      },
      {
        theme: 'dark',
        checked: false,
        disabled: false,
        error: true,
        className: ['focus:outline-red-400 focus:outline-opacity-50']
      },
      // Light
      {
        theme: 'light',
        checked: false,
        disabled: false,
        error: false,
        className: [primary.border.medium.base, primary.accent.default.border.hover, primary.accent.default.border.focus, 'bg-white']
      },
      {
        theme: 'light',
        checked: true,
        disabled: true,
        className: 'bg-navy-50 border-navy-50'
      },
      {
        theme: 'light',
        checked: false,
        disabled: true,
        className: 'border-navy-50'
      },
      {
        theme: 'light',
        checked: false,
        disabled: false,
        error: true,
        className: ['focus:outline-red-200 focus:outline-opacity-50']
      }
    ]
  }
);

export const radioWrapper = cva<RadioWrapper>('inline-flex shrink-0 relative', {
  variants: {
    size: radioSizes
  }
});

export const radioCircle = cva<RadioCircleCVAType>('absolute radius-full pointer-events-none', {
  variants: {
    size: {
      small: 'inset-1',
      medium: 'inset-1.5',
      large: 'inset-2'
    },
    theme: {},
    disabled: {}
  },
  compoundVariants: [
    {
      theme: 'dark',
      disabled: true,
      className: 'bg-gray-300'
    },
    {
      theme: 'dark',
      disabled: false,
      className: 'bg-white'
    },
    {
      theme: 'light',
      disabled: [false, true],
      className: 'bg-white'
    }
  ]
});
