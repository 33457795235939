/* eslint-disable max-len */
import Styled from 'styled-components';
import { ScDropdown } from '../Dropdown/scDropdown';

export const ScControlBarDropdown = Styled(ScDropdown)`
   border-left: 1px solid #939BC4;
   width: 194px;

  .dropdown-button {
    display: flex;
    align-items: center;
    border: none;
    padding-right: 32px;

    &:after {
      width: 36px;
      border-left: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath stroke='%2309F' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3E%3C/svg%3E");
    }

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #0099FF;
    }
  }

  .dropdown-container {
    border: 1px solid #939BC4;
    border-radius: 0 0 4px 4px;
  }

  .dropdown-option {
    &-container {
      &:last-child {
        border-radius: 0 0 4px 4px;
      }

      &:after {
        background: transparent;
      }

      &:hover {
        background-color: #F3F3FE;
      }

      &[aria-selected="true"] {
        background-color: #E5E5FA;

        &:after {
          background-color: #E5E5FA;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'%3E%3Cpath fill='%236F76A7' fill-rule='evenodd' d='M7 14c3.866 0 7-3.134 7-7 0-3.86599-3.134-7-7-7-3.86599 0-7 3.13401-7 7 0 3.866 3.13401 7 7 7Zm4.0058-8.72223c.2539-.24168.2596-.63909.0127-.88764-.2469-.24856-.6528-.25414-.9067-.01246l-4.26504 4.0596-1.9241-2.14102c-.23392-.26029-.63909-.28566-.90497-.05667-.26589.22899-.29181.62562-.05789.88591l2.3693 2.63642c.11599.12906.28098.20592.45645.21261.17548.0067.34608-.05735.47198-.17719l4.74827-4.51956Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
      }
    }
  }
`;
