import React, { forwardRef } from 'react';
import { Dropdown as UiKitDropdown } from '@jotforminc/uikit';

const ButtonRenderer = forwardRef<HTMLButtonElement, ButtonRendererProps>(({ option: { text }, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className="dropdown-button"
      type="button"
      {...props}
    >
      <span className="dropdown-text">
        {text}
      </span>
      <div className="dropdown-arrow-Wrapper">
        <span className="dropdown-arrow" />
      </div>
    </button>
  );
});

function Dropdown({
  defaultValue,
  options,
  onChange,
  disabled,
  subType
}: IProps): JSX.Element {
  const defaultVal = (typeof defaultValue === 'object' && subType === 'addQuestion') ? defaultValue.type : defaultValue;
  return (
    <div className="dropdownWrapper">
      <UiKitDropdown
        defaultValue={defaultVal}
        options={options}
        ButtonRenderer={ButtonRenderer}
        onOptionSelect={(data: { value: string | number; }) => {
          if (data.value === 'addQuestion') {
            onChange(data);
          } else {
            onChange(data.value);
          }
        }}
        disabled={disabled}
      />
    </div>
  );
}
interface IOption {
  text: string,
  value: string | number,
  disabled?: boolean,
  subType?: string
}
export interface IProps {
  type?: string,
  options: IOption | IOption[],
  defaultValue?: IOption['value'],
  onChange: (value: string | number) => void,
  disabled?: boolean,
  subType?: string
}

interface ButtonRendererProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  option: {
    text: string
  }
}

export default Dropdown;
