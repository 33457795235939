/** Data */
import Fuse from 'fuse.js';
import type { GATEWAY_CONNECTION_PROPS, GATEWAY_LIST_CONNECTION_PROPS } from '@jotforminc/payment-constants';
import { gatewaySearchableTags, supportedPaymentTypesWithLabel } from '@jotforminc/payment-constants';
import type { UTILS_GET_GATEWAY_LIST_OPTIONS } from '../../types';

/** Utils */
import { getGatewayList } from './getGatewayList';
import { isDevelopment } from './isDevelopment';

type EXTENDED_GATEWAY_CONNECTION_PROPS = GATEWAY_CONNECTION_PROPS & {
  searchTags?: ReadonlyArray<string>;
  propReferencesKeys?: ReadonlyArray<string>;
  paymentTypesReadable?: ReadonlyArray<string>;
}

/**
 * Retrieves a filtered list of payment gateways based on the provided filter options and search text.
 *
 * @param {Object} options - The options for filtering and searching the payment gateways.
 * @param {string | null} options.searchText - The search text to filter the payment gateways.
 * @param {boolean} [options.includeSearchTags=true] - Whether to include search tags in the search.
 * @param {boolean} [options.includeSupportedCurrency=false] - Whether to include supported currencies in the search.
 * @param {boolean} [options.includeSupportedPaymentTypes=false] - Whether to include supported payment types in the search.
 * @param {boolean} [options.includeGatewayConnectionPropReferencesKeys=false] - Whether to include gateway connection property reference keys in the search.
 * @param {UTILS_GET_GATEWAY_LIST_OPTIONS} options.gatewayFilterOptions - The filter options for the payment gateways.
 * @returns {GATEWAY_CONNECTION_PROPS[]} The filtered list of payment gateways.
 */
export const gatewayListSearch = ({
  gatewayList,
  searchText = null,
  includeSearchTags = true,
  includeSupportedCurrency = false,
  includeSupportedPaymentTypes = false,
  includeGatewayConnectionPropReferencesKeys = false,
  gatewayFilterOptions
}: {
  gatewayList: GATEWAY_LIST_CONNECTION_PROPS;
  searchText?: string | null
  includeSearchTags?: boolean,
  includeSupportedCurrency?: boolean,
  includeSupportedPaymentTypes?: boolean,
  includeGatewayConnectionPropReferencesKeys?: boolean,
  gatewayFilterOptions: UTILS_GET_GATEWAY_LIST_OPTIONS
}): GATEWAY_CONNECTION_PROPS[] => {
  /**
   * Retrieves a filtered list of payment gateways based on the provided filter options.
   *
   * @returns {EXTENDED_GATEWAY_CONNECTION_PROPS[]} The filtered list of payment gateways.
   */
  const getFilteredGateways = getGatewayList({ gatewayList, ...gatewayFilterOptions }) as EXTENDED_GATEWAY_CONNECTION_PROPS[];

  if (!searchText) {
    return getFilteredGateways as GATEWAY_CONNECTION_PROPS[];
  }

  /**
   * Retrieves the additional gateway properties.
   * @returns {AdditionalGatewayProps} The additional gateway properties.
   */
  const addionalGatewayProps = getFilteredGateways.map(gateway => {
    const gatewayClone = { ...gateway };
    if (includeGatewayConnectionPropReferencesKeys) {
      gatewayClone.propReferencesKeys = Object.keys(gateway.connection.propReferences); // Assign the propReferencesKeys property to the copy
    }

    if (includeSearchTags) {
      gatewayClone.searchTags = gatewaySearchableTags[gateway.gateway_type];
    }

    if (includeSupportedPaymentTypes) {
      const getPaymentTypes = gateway.supportedPaymentTypes[gatewayFilterOptions.resourceType].map(paymentType => {
        return supportedPaymentTypesWithLabel[paymentType];
      });
      gatewayClone.paymentTypesReadable = getPaymentTypes;
    }
    return gatewayClone;
  });

  const searchableKeys = [{ name: 'name', weight: 0.3 }];
  if (includeSearchTags) { searchableKeys.push({ name: 'searchTags', weight: 0.3 }); }
  if (includeSupportedCurrency) { searchableKeys.push({ name: 'supportedCurrencies', weight: 0.4 }); }
  if (includeSupportedPaymentTypes) { searchableKeys.push({ name: 'paymentTypesReadable', weight: 0.3 }); }
  if (includeGatewayConnectionPropReferencesKeys && isDevelopment()) { searchableKeys.push({ name: 'propReferencesKeys', weight: 0.3 }); }

  /**
   * Options for configuring the Fuse.js search algorithm.
   */
  const fuseOptions = {
    threshold: 0.3,
    tokenize: true,
    matchAllTokens: true,
    keys: searchableKeys
  };

  const fuseInit = new Fuse(addionalGatewayProps, fuseOptions);
  const result = fuseInit.search(searchText);

  if (result.length === 0) {
    return [];
  }

  return result.map(gateway => {
    const gatewayClone = { ...gateway.item };
    delete gatewayClone.searchTags;
    delete gatewayClone.propReferencesKeys;
    delete gatewayClone.paymentTypesReadable;
    return gatewayClone;
  });
};
