import { ComponentPropsWithRef } from 'react';
import { ColorStyle } from '../../constants/common.types';

export type LinkProps = ComponentPropsWithRef<'a'> & {
  colorStyle?: ColorStyle,
  disabled?: boolean
};

export const linkDefaultProps:Partial<LinkProps> = {
  colorStyle: 'primary',
  disabled: false
};
