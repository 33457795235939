import {
  AI_ADD_ASSISTANT_CHAT_MESSAGE,
  AI_ADD_CHAT_MESSAGE,
  AI_SET_MESSAGES,
  AI_SHOW_ASSISTANT,
  AI_SHOW_ERROR_MESSAGE
} from '../actionTypes';

const initialState = {
  messages: [],
  showAssistant: false,
  isWaitingForResponse: false,
  isDirty: false,
  isOpened: false,
  isDefaultOpen: false
};

const AssistantReducer = (state = initialState, action) => {
  switch (action.type) {
    case AI_SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
        isWaitingForResponse: false
      };
    case AI_SHOW_ASSISTANT:
      return {
        ...state,
        showAssistant: action.payload.aiIsShow
      };
    case AI_ADD_CHAT_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            message_id: new Date().valueOf(),
            content: action.payload.prompt,
            message_type: 'USER_MESSAGE'
          }
        ],
        isWaitingForResponse: true
      };
    case AI_ADD_ASSISTANT_CHAT_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, {
          content: action.data,
          message_type: 'ASSISTANT',
          message_id: new Date().valueOf()
        }]
      };
    case AI_SHOW_ERROR_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            message_id: new Date().valueOf(),
            content: action.payload,
            message_type: 'ERROR'
          }
        ],
        isWaitingForResponse: false
      };
    default:
      return state;
  }
};

export default AssistantReducer;
