import React from 'react';
import { elementType, func } from 'prop-types';

import { useDragState, useEffectIgnoreFirst } from '../../utils/hooks';

const DragContainer = ({
  ContainerRenderer, onDragStateChange, onDragOver, onDragLeave, onDrop, onDragEnter, ...props
}) => {
  const [isDragging, dragEventHandlers] = useDragState(false, {
    onDragOver,
    onDragLeave,
    onDrop,
    onDragEnter
  });

  useEffectIgnoreFirst(() => {
    onDragStateChange(isDragging);
  }, [isDragging]);

  return <ContainerRenderer {...props} {...dragEventHandlers} />;
};

DragContainer.propTypes = {
  ContainerRenderer: elementType,
  onDragStateChange: func,
  onDragOver: func,
  onDragLeave: func,
  onDrop: func,
  onDragEnter: func
};

DragContainer.defaultProps = {
  ContainerRenderer: props => <div {...props} />,
  onDragStateChange: f => f,
  onDragOver: f => f,
  onDragLeave: f => f,
  onDrop: f => f,
  onDragEnter: f => f
};

export default DragContainer;
