/* Widget Constants */
const widgetTranlatebles = {
  '533946093c1ad0c45d000070' /*  Configurable List */: {
    SHOW_HIDE_CALENDAR: 'Show/hide calendar'
  }
};

/*  TRANSLATABLE WORDS PARSER (Field Declarations) */

// Configurable List : 533946093c1ad0c45d000070

const parseConfigurableListFieldDeclarations = declaration => {
  /*
       Text: [label]:text[:placeholder]
       Number: [label]:number[:placeholder:step,min,max]
       Textarea: [label]:textarea[:placeholder]
       Dropdown: [label]:dropdown:option1[,option2,...:placeholder]
       Radio: [label]:radio:option1[,option2,...]
       Checkbox: [label]:checkbox:option1[,option2,...]
       Date Input: [label]:dateInput[:format]
       Time Input: [label]:timeInput:format[,now]
       Date: [label]:date[:format:range:today]
       Time: [label]:time[:format,now]
       Static Text: [label]:static:[text]
    */

  function moveAsteriskToEnd(input = '') {
    if (input.startsWith('*')) {
      return `${input.substring(1).trim()}`;
    }
    return input;
  }
  if (declaration) {
    const translatables = [];
    const lines = declaration.split('\n');
    lines.forEach(line => {
      const type = line.split(':')[1]?.trim();
      switch (type) {
        case 'number':
        case 'textarea':
        case 'static':
        case 'text':
          // label
          translatables.push(moveAsteriskToEnd(line.split(':')[0]));
          // placeholder or text (static)
          translatables.push(moveAsteriskToEnd(line.split(':')[2]));
          break;
        case 'radio':
        case 'checkbox':
        case 'dropdown':
          // label
          translatables.push(moveAsteriskToEnd(line.split(':')[0]));
          // options
          const options = line.split(':')[2] ? line.split(':')[2].split(',') : [];
          translatables.push([...translatables, ...options]);
          // placeholder (dropdown)
          if (line.split(':')[3]) {
            translatables.push(line.split(':')[3]);
          }
          break;
        case 'dateInput':
        case 'timeInput':
        case 'time':
        case 'date':
          // only label
          translatables.push(moveAsteriskToEnd(line.split(':')[0]));
          break;
        default:
          break;
      }
    });
    return translatables;
  }

  return [];
};

const parseMatrixDynamiqueDeclations = declaration => {
  // ex: head1:60,head2:120

  if (declaration) {
    const columns = declaration.split(',');
    return columns.map(column => column && column.split(':')[0]);
  }

  return [];
};

const getWidgetFieldParser = widgetID => {
  switch (widgetID) {
    case '533946093c1ad0c45d000070': /*  Configurable List */
      return parseConfigurableListFieldDeclarations;
    case '5299b58aba554b7e24000007': /* Matrix Dynamique */
      return parseMatrixDynamiqueDeclations;
    default:
      return null;
  }
};

const getWidgetTranslatebleTexts = (widgetID, fieldDeclaration) => {
  if (fieldDeclaration) {
    const parser = getWidgetFieldParser(widgetID);
    return [...new Set([
      ...(Object.values(widgetTranlatebles[widgetID]) || []),
      ...parser(fieldDeclaration).flat(Infinity)
    ])];
  }
  return Object.values(widgetTranlatebles[widgetID]);
};

export {
  widgetTranlatebles,
  getWidgetTranslatebleTexts,
  parseConfigurableListFieldDeclarations
};
