import React from 'react';
import PropTypes from 'prop-types';
import ReplaceFormButton from './ReplaceFormButton';

const DeletedFormWarning = ({ formID }) => (
  <div>
    This form has been deleted and won’t be visible in the app.
    {' '}
    <ReplaceFormButton formID={formID}>
      Change Form
    </ReplaceFormButton>
  </div>
);

DeletedFormWarning.propTypes = {
  formID: PropTypes.string.isRequired
};

export default DeletedFormWarning;
