import React, { useCallback } from 'react';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { useSelector, useDispatch } from 'react-redux';

import Selectors from '../../store/selectors';
import { Tabs, Tab } from '../fields';
import * as actionCreators from '../../store/actionCreators';
import ProductBasicTabEditor from './ProductBasicTabEditor';
import OptionsProductEditor from './ProductOptionsTabEditor';
import {
  TActiveTab, FTcreateProduct, FTupdateProduct, ISellProductsItem, FTSortProducts
} from '../../types/common';

const SellProductsEditor = ({ actions } : ISellProductsEditorProps) : JSX.Element => {
  const dispatch = useDispatch();
  const activeTab = useSelector(Selectors.getActiveTab);
  const formId = useSelector(Selectors.getFormId);
  const activeProduct = useSelector(Selectors.getActiveProduct);

  // Note: We should handle the changes immediately and if the request will fail, then we should back to the old state. Thus, we are able to interact with the user suddenly.
  // So, we keep the current data before the changes.
  const getPrevData = useCallback((data: Partial<ISellProductsItem>) : Partial<ISellProductsItem> => {
    const temp: any = {};
    const keys = Object.keys(data) as Array<Partial<keyof ISellProductsItem>>;

    keys.forEach((k: keyof ISellProductsItem): void => {
      temp[k] = activeProduct[k];
    });

    return temp;
  }, [activeProduct]);

  const createProduct = useCallback((data: Partial<ISellProductsItem>) => {
    return actions.createProduct(formId, data)
      .then(response => {
        dispatch(actionCreators.updateActiveProduct(response));
        dispatch(actionCreators.createProduct(response));
      }).catch((err: Error) => console.error(err));
  }, [formId, actions, dispatch]);

  const updateProduct = useCallback((data: Partial<ISellProductsItem>) => {
    // Note: We should handle the changes immediately and if the request will fail, then we should back to the old state. Thus, we are able to interact with the user suddenly.
    const prevData = getPrevData(data);

    dispatch(actionCreators.updateActiveProduct(data));
    dispatch(actionCreators.updateProduct(data, activeProduct.pid));

    return actions.updateProduct(formId, activeProduct.pid, data)
      .then(() => ({
      })).catch((err: Error) => {
        dispatch(actionCreators.updateActiveProduct(prevData));
        dispatch(actionCreators.updateProduct(prevData, activeProduct.pid));
        console.error(err);
      });
  }, [formId, actions, dispatch, activeProduct, getPrevData]);

  const onTabSelect = useCallback((tabId: string): void => {
    dispatch(actionCreators.changeActiveTab(tabId as TActiveTab));
  }, [dispatch]);

  return (
    <div className="pEditor sellProductsEditor">
      <Tabs onSelect={onTabSelect}>
        <Tab text={t('Basic')} id="basic" className={activeTab === 'basic' ? 'selected' : ''} />
        <Tab text={t('Options')} id="options" className={activeTab === 'options' ? 'selected' : ''} />
      </Tabs>

      <div className={classNames('pEditorContent', `${activeTab}Tab`)}>
        {activeTab === 'basic' && (
          <ProductBasicTabEditor
            activeProduct={activeProduct}
            onProductCreate={createProduct}
            onProductUpdate={updateProduct}
          />
        )}

        {activeTab === 'options' && (
          <OptionsProductEditor />
        )}

        {/* TODO :: Stock */}
      </div>
    </div>
  );
};

interface IActions {
  createProduct: FTcreateProduct,
  updateProduct: FTupdateProduct,
  sortProducts: FTSortProducts,
}
interface ISellProductsEditorProps {
  actions: IActions
}

export default SellProductsEditor;
