import { FETCH_SIGNS } from '../actionTypes';

const initialState = [];

const SignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIGNS.SUCCESS: {
      return [...action.payload];
    }
    default:
      break;
  }
  return state;
};

export default SignsReducer;
