import React from 'react';
import { IconXmark } from '@jotforminc/svg-icons';
import { cx } from 'class-variance-authority';
import { Button } from '../Button';
import primary from '../../tokens/colors/primary';
import { modalHeaderDefaultProps, ModalHeaderProps } from './modal.types';
import { modalIconCVA, modalIconWrapperCVA } from './modal.cva';

export const ModalHeader = (props: ModalHeaderProps): JSX.Element => {
  const {
    title,
    description,
    closeButtonRef,
    onClose,
    icon: Icon,
    logoIcon,
    iconColorStyle,
    customIconColorStyle
  } = props;

  const hasIcon = !!Icon;
  const hasDescription = !!description;

  const iconWrapperClassName = cx(customIconColorStyle, modalIconWrapperCVA({
    iconColorStyle,
    logoIcon
  }));

  const iconClassName = cx(modalIconCVA({
    logoIcon
  }));

  return (
    <div className="flex items-center w-full px-8 py-6 border-b border-solid border-navy-50">
      {hasIcon && (
        <div className={iconWrapperClassName}>
          <Icon className={iconClassName} />
        </div>
      )}
      <div className="mr-6 min-w-0">
        <h2 className={cx(primary.text.darkest.base, 'text-lg line-height-xl font-medium m-0')}>{title}</h2>
        {hasDescription && (
          <p className={cx(primary.text.medium.base, 'text-xs xs:text-sm xs:line-height-md m-0')}>{description}</p>
        )}
      </div>
      <Button
        startIcon={IconXmark}
        colorStyle="secondary"
        rounded
        aria-label="Close Button"
        ref={closeButtonRef}
        onClick={onClose}
        className="ml-auto"
      />
    </div>
  );
};

ModalHeader.defaultProps = modalHeaderDefaultProps;
