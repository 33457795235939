import React from 'react';
import classNames from 'classnames';
import { Button } from '@jotforminc/magnet';

const PaymentOverQuotaWarning = (): JSX.Element => {
  const handleUpgradeNow = () => {
    const link = '/pricing/?utm_source=paymentsettingseditor_warnings&utm_medium=banner'; // TODO :: add the utm sources about currentLimit
    window.open(link, '_blank');
  };

  return (
    <>
      <div className="lineElement">
        <div className={classNames('overQuotaWarning')}>
          <div className={classNames('overQuotaWarningText')}>
            You’re out of
            {' '}
            <b>payment submission</b>
            {' '}
            limit.
            {' '}
          </div>
          <Button colorStyle='error' onClick={handleUpgradeNow}>Upgrade Now</Button>
        </div>
      </div>
      <hr className="lineHr" />
    </>
  );
};

export default PaymentOverQuotaWarning;
