import React, { memo } from 'react';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import * as Modal from '../Items';

const PaymentDiscardChanges = () => {
  return (
    <>
      <Modal.Body>
        <div className='flex flex-col justify-center items-center text-center'>
          <IconExclamationTriangleFilled className='w-20 h-20 color-red-400 mb-2' />
          <h1 className='text-lg font-medium line-height-lg color-navy-700 mb-1'>{t('Continue without saving your configuration?')}</h1>
          <p className='text-sm font-normal line-height-sm color-navy-200 pb-2'>{t('Any unsaved changes will be lost.')}</p>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </>
  );
};

export default memo(PaymentDiscardChanges);
