import { t } from '@jotforminc/translation';
import { useDebounce } from '@jotforminc/hooks';
import { elementType, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText, Flex } from '@jotforminc/magnet';
import { IconAlignTextJustify, IconPlus } from '@jotforminc/svg-icons';
import { SVGIcon } from '@jotforminc/icon-selector';
import { useUnmount } from '../../../../../../utils/hooks';
import NavigationPanelButton from '../../../../../PublicApp/TopBar/NavigationPanelButton';
import { ScRightPanelBlock, ScRightPanelMobileMenuBlock } from '../../../../styles/scRightPanel';
import RadioToggle from '../../../RadioToggle';
import { OVERRIDED_ICON_LIST, RightPanelModes } from '../../constants';
import { ScRpMenuDeviceSection } from '../MenuSettingsPanel/ScRpMenuDeviceSection';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import IconSelector from '../../../../../../components/IconSelector';
import SELECTORS from '../../../../../../store/selectors';
import topBarPreview from '../../../../../../assets/topBarPreview.png';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { ScLineHr } from '../ScLineHr';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isYes } from '../../../../../../utils';

const DEFAULT_MENU_TITLE = '';

const NavigationBarProperties = ({
  showNavigationBar,
  DescriptionRenderer,
  mobileMenuIcon,
  showMobileMenuIcon
}) => {
  const dispatch = useDispatch();
  const mobileMenuTitle = useSelector(SELECTORS.getMobileMenuTitle);
  const [isIconSelectorVisible, setIsIconSelectorVisible] = useState(false);
  const [_mobileMenuTitle, _setMobileMenuTitle] = useState(mobileMenuTitle);
  const mobileMenuIconWithDefault = mobileMenuIcon || '/cardforms/assets/icons/icon-sets-v2/color/Colored/jfc_icon_color-coloredjfsvg-business-color_hamburgermenu.svg';
  const isIconVisible = isYes(showMobileMenuIcon);

  const onPortalPropertyChange = useDebounce(data => dispatch(ACTION_CREATORS.updatePortalAction(data)), 150);
  const onChange = propName => value => onPortalPropertyChange({ [propName]: value });

  const handlePageUpdate = useCallback((propName, value) => {
    if (!value && !isIconVisible) {
      _setMobileMenuTitle(DEFAULT_MENU_TITLE);
      onChange(propName)(DEFAULT_MENU_TITLE);
      return;
    }

    onChange(propName)(value);
  }, [showMobileMenuIcon, onChange]);

  const handleMobileTextChange = useCallback(value => {
    _setMobileMenuTitle(value);
  }, [showMobileMenuIcon]);

  const handleMobileTextBlur = useCallback(({ target: { value } }) => {
    handlePageUpdate('mobileMenuTitle', value);
  }, [handlePageUpdate]);

  const handleMenuIconChange = useCallback(value => {
    if (!isYes(value) && !mobileMenuTitle) {
      onPortalPropertyChange({
        mobileMenuTitle: DEFAULT_MENU_TITLE,
        showMobileMenuIcon: value
      });
      _setMobileMenuTitle(DEFAULT_MENU_TITLE);
      return;
    }

    onChange('showMobileMenuIcon')(value);
  }, [mobileMenuTitle, showMobileMenuIcon, onChange]);

  const handleUnmount = useCallback(() => {
    if (mobileMenuTitle !== _mobileMenuTitle) {
      handlePageUpdate('mobileMenuTitle', _mobileMenuTitle);
    }
  }, [handlePageUpdate, mobileMenuTitle, _mobileMenuTitle]);

  useUnmount(handleUnmount); // handeUnmount function is executed on unmount.

  const handleIconSelectorOnChange = url => {
    onPortalPropertyChange({
      mobileMenuIcon: url,
      ...(!isIconVisible ? { showMobileMenuIcon: 'Yes' } : {})
    });
    setIsIconSelectorVisible(false);
  };

  const MenuIcon = () => {
    if (!isIconVisible) return null;

    return mobileMenuIcon ? (
      <SVGIcon
        iconColor='#fff'
        className='flex'
        url={isFeatureEnabled(FEATURE_NAMES.FullIconUrl) ? `https://cdn.jotfor.ms${mobileMenuIcon}` : mobileMenuIcon}
      />
    ) : (
      <IconAlignTextJustify className="defaultBar" />
    );
  };

  return (
    <>
      <ScRightPanelBlock line>
        <RadioToggle
          onChange={onChange('showNavigationBar')}
          value={showNavigationBar}
          title={t('Show Navigation Menu')}
        />
      </ScRightPanelBlock>
      <ScLineHr />
      {isYes(showNavigationBar) && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="mobileMenuDisplay">{t('Mobile Menu Display')}</label>
            <DescriptionRenderer style={{ marginTop: 0 }} description={t('Change menu text and icon.')} />
            <ScRightPanelMobileMenuBlock>
              <Flex alignItems='center' className='relative gap-2 mt-3'>
                <Flex
                  className='menu-icon-wrapper w-10 h-10 radius p-2 bg-gray-500 hover:bg-gray-400 cursor-pointer'
                  alignItems='center'
                  justifyContent='center'
                  colorStyle="neutral"
                  onClick={() => setIsIconSelectorVisible(!isIconSelectorVisible)}
                  aria-hidden="true"
                  data-testid="iconSelector-button"
                >
                  {isIconVisible ? <MenuIcon /> : <IconPlus color='#C8CEED' />}
                </Flex>
                {isIconSelectorVisible && (
                  <div className='icon-selector-wrapper absolute top-11 left-0 z-1'>
                    <IconSelector
                      onChange={({ url }) => handleIconSelectorOnChange(url)}
                      onRemove={() => { handleMenuIconChange('No'); setIsIconSelectorVisible(false); }}
                      overriddenIcons={OVERRIDED_ICON_LIST[RightPanelModes.MENU_SETTINGS]}
                      value={isIconVisible ? mobileMenuIconWithDefault : ''}
                      allowRemove
                      removeButtonText={t('Remove Icon')}
                      uniqKey="menuIcon"
                    />
                  </div>
                )}
                <InputText
                  id="menuName"
                  className='w-full'
                  onChange={e => handleMobileTextChange(e.target.value)}
                  onBlur={handleMobileTextBlur}
                  value={_mobileMenuTitle}
                  theme='dark'
                  placeholder={t('Type a menu text')}
                />
              </Flex>
            </ScRightPanelMobileMenuBlock>
            <ScRpMenuDeviceSection>
              <div className='menuDevice'>
                <img
                  src={topBarPreview}
                  alt={t('mobile device')}
                />
                <div className='menu-topbar-preview'>
                  <NavigationPanelButton
                    className="test"
                    mobileMenuTitle={_mobileMenuTitle}
                    mobileMenuIcon={mobileMenuIcon}
                    showMobileMenuIcon={showMobileMenuIcon}
                    isRightPanel={true}
                  />
                </div>
              </div>
            </ScRpMenuDeviceSection>
          </ScRightPanelBlock>
        </>
      )}
    </>
  );
};

NavigationBarProperties.propTypes = {
  showNavigationBar: string,
  DescriptionRenderer: elementType,
  mobileMenuIcon: string,
  showMobileMenuIcon: string
};

NavigationBarProperties.defaultProps = {
  showNavigationBar: '',
  DescriptionRenderer: PanelItemDescriptionRenderer,
  mobileMenuIcon: '',
  showMobileMenuIcon: ''
};

export default NavigationBarProperties;
