import React from 'react';
import type { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';
import { DialogTitleProps } from './dialog.types';
import { primary } from '../../tokens/colors';

export const DialogTitle = (props: PropsWithChildren<DialogTitleProps>): JSX.Element => {
  const { children, className, ...rest } = props;

  return (
    <h2
      {...rest}
      className={cx(className, primary.text.darkest.base, 'text-center text-md font-medium xs:text-lg mb-px')}
    >
      {children}
    </h2>
  );
};
