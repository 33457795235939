import React from 'react';
import { Provider } from 'react-redux';
import store from './store';

const withStore = AppComponent => {
  return () => (
    <Provider store={store}>
      <AppComponent />
    </Provider>
  );
};

export default withStore;
