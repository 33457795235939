import { select, takeEvery, put } from 'redux-saga/effects';
import { SEARCH_IN_PRODUCTS } from '../actionTypes';
import SELECTORS from '../selectors';
import { showGenericModalAction } from '../actionCreators';
import { MODALS } from '../../constants/modals';

function* watchSearchInputClicked() {
  const productLists = yield select(SELECTORS.getProductListItems);
  const areMultiProductListsExist = productLists.length > 0;

  if (areMultiProductListsExist) {
    yield put(showGenericModalAction({ name: MODALS.SEARCH_IN_PRODUCTS_MODAL }));
  }
}

export default function* filterProductSaga() {
  yield takeEvery(SEARCH_IN_PRODUCTS, watchSearchInputClicked);
}
