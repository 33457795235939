/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, MutableRefObject } from 'react';
import { Popover } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { fieldElements } from './constants';

type AddElementPopoverType = {
  elementsPopoverRef: MutableRefObject<undefined>,
  addElementButtonRef: MutableRefObject<undefined>,
  onAddElement: (fieldType: string) => void,
  setIsElementsVisible: any
}

const AddElementPopover: FunctionComponent<AddElementPopoverType> = ({
  elementsPopoverRef, addElementButtonRef, onAddElement, setIsElementsVisible
}) => {
  const handleAddElement = (elementType: string) => {
    onAddElement(elementType);
    setIsElementsVisible(false);
  };
  return (
    <Popover
      ref={elementsPopoverRef}
      targetRef={addElementButtonRef}
      className="field-elements z-9 w-11/12"
      popoverOptions={{
        placement: 'bottom'
      }}
    >
      <div className="w-full">
        {fieldElements.filter(e => e.addable).map(({ type, title, Icon }) => (
          <div
            key={type}
            className="field-elements-item flex items-center w-full h-11 bg-white border-b border-navy-100 cursor-pointer"
            onClick={() => handleAddElement(type)}
          >
            <Icon
              className="ml-4 mr-3"
              width="16px"
              height="16px"
              fill="#6C73A8"
            />
            <p className="font-medium">{t(title)}</p>
          </div>
        ))}
      </div>
    </Popover>
  );
};

export default AddElementPopover;
