import { useItemDefaults } from '../../properties';
import Items from './items';

export default class ActionHelper {
  static ACTIONABLE_ITEMS = Object.keys(Items);

  static createComponent(type) {
    const itemProps = Items[type];
    const ItemClass = itemProps.class;
    if (!ItemClass) {
      throw new Error('Invalid item type!');
    }

    const defaults = useItemDefaults(type);

    return new ItemClass(defaults, itemProps.actions);
  }
}
