/** Data */
import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';

/** Types */
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import type{
  UTILS_GET_GATEWAY_PROP_REFERENCES_OPTIONS,
  UTILS_GET_GATEWAY_PROP_REFERENCES_RETURN_DATA
} from '../../types';

/** Utils */
import { isPropBelongsToSandbox } from './isPropBelongsToSandbox';

/**
 * Retrieves the property references for a given payment gateway.
 *
 * @param options - The options for retrieving the property references.
 * @param options.gateway - The payment gateway for which to retrieve the property references.
 * @param options.includeSandboxProps - Whether to include sandbox properties in the result. Default is false.
 * @returns An array of property references for the specified payment gateway.
 */
export const getGatewayConnectionPropReferences = ({
  gateway,
  includeSandboxProps = false
}: UTILS_GET_GATEWAY_PROP_REFERENCES_OPTIONS): UTILS_GET_GATEWAY_PROP_REFERENCES_RETURN_DATA[] | null => {
  const Data = supportedGatewayConnectionPropsList[gateway];
  const { connection } = Data;
  const propReferences = Object.entries(connection.propReferences);

  if (!Data || propReferences.length === 0) {
    return null;
  }

  const formattedData = propReferences.map(([key, value]) => ({
    name: key as PAYMENT_QUESTION_PROP_NAMES,
    props: value
  }));

  if (!includeSandboxProps) {
    return formattedData.filter(({ name }) => !isPropBelongsToSandbox(name));
  }

  return formattedData;
};
