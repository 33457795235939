import React, { FunctionComponent } from 'react';
import { FormWizard } from '@jotforminc/form-wizard';
import { t } from '@jotforminc/translation';
import COMPONENT_TYPE_LIST from '../../../constants/componentTypes';
import * as COMPONENT_NAMES from '../../../constants/componentNames';
import { ICurrency } from '../../../types/common';
import '../../../styles/editor.scss';
import '@jotforminc/jotform.css';

type DonationBoxPropertiesPanelType = {
  fixedAmounts: [object],
  currencyInfo: ICurrency,
  onAddFixedAmount: () => void,
  onChangeFixedAmount: () => void,
  onDeleteFixedAmount: () => void,
  onPropChange: ({ key, value }: { key: string, value: string }) => void,
  showCustomAmount: boolean,
  enableCustomAmountLimits: boolean,
  showDonationGoal: boolean,
  donationGoal: string | number,
  minCustomAmountLimit: string | number,
  maxCustomAmountLimit: string | number
}

const DonationBoxPropertiesPanel: FunctionComponent<DonationBoxPropertiesPanelType> = ({
  fixedAmounts,
  currencyInfo,
  onAddFixedAmount,
  onChangeFixedAmount,
  onDeleteFixedAmount,
  onPropChange,
  showCustomAmount,
  enableCustomAmountLimits,
  showDonationGoal,
  donationGoal,
  minCustomAmountLimit,
  maxCustomAmountLimit
}) => {
  const elementList = {
    fixedAmount: {
      type: COMPONENT_NAMES.FIXED_AMOUNTS,
      labelProps: {
        label: t('Fixed Amounts'),
        class: 'block mb-0 py-5 px-4'
      },
      componentProps: {
        fixedAmounts,
        currencyInfo,
        onAddFixedAmount,
        onChangeFixedAmount,
        onDeleteFixedAmount
      }
    },
    divider: {
      type: COMPONENT_NAMES.DIVIDER,
      labelProps: {
        label: t('Donation divider 1'),
        class: 'formWizard-hiddenLabelWrapper lineHr block border-transparent w-full m-0'
      }
    },
    showCustomAmount: {
      type: COMPONENT_NAMES.TOGGLE,
      labelProps: {
        label: t('Allow Custom Amounts'),
        description: t('Let donors enter a custom amount'),
        class: `pointer-events-none flex justify-between items-center mb-0 p-4 p-${COMPONENT_NAMES.TOGGLE}`
      },
      componentProps: {
        isSelected: showCustomAmount
      }
    },
    enableCustomAmountLimits: {
      type: COMPONENT_NAMES.TOGGLE,
      labelProps: {
        label: t('Amount Limits'),
        description: t('Limit the minimum or maximum value allowed'),
        class: `pointer-events-none flex justify-between items-center mb-0 p-4 p-${COMPONENT_NAMES.TOGGLE}`
      },
      componentProps: {
        isSelected: enableCustomAmountLimits
      },
      condition: () => showCustomAmount
    },
    customAmountLimits: {
      type: COMPONENT_NAMES.INPUT_RANGE,
      noLabel: true,
      componentProps: {
        currencyInfo,
        minLimit: minCustomAmountLimit,
        maxLimit: maxCustomAmountLimit,
        onCustomChange: onPropChange
      },
      condition: () => showCustomAmount && enableCustomAmountLimits
    },
    divider_1: {
      type: COMPONENT_NAMES.DIVIDER,
      labelProps: {
        label: t('Donation divider 2'),
        class: 'formWizard-hiddenLabelWrapper lineHr block border-transparent w-full m-0'
      }
    },
    showDonationGoal: {
      type: COMPONENT_NAMES.TOGGLE,
      labelProps: {
        label: t('Show Donation Goal'),
        description: t('Show your donation goal\'s progress on the app'),
        class: `pointer-events-none flex justify-between items-center mb-0 p-4 p-${COMPONENT_NAMES.TOGGLE}`
      },
      componentProps: {
        isSelected: showDonationGoal
      }
    },
    donationGoal: {
      type: COMPONENT_NAMES.PRICE_INPUT,
      labelProps: {
        label: t('Donation goal input'),
        class: 'formWizard-hiddenLabelWrapper'
      },
      componentProps: {
        value: donationGoal,
        currencyInfo,
        min: 0
      },
      condition: () => showDonationGoal
    }
  };

  return (
    <div className="donationBoxPropertiesPanel">
      <FormWizard
        onChange={(_: any, data: {key: string, value: string}) => onPropChange(data)}
        elementList={elementList}
        componentTypeList={COMPONENT_TYPE_LIST}
      />
    </div>
  );
};

export default DonationBoxPropertiesPanel;
