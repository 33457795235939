import { elementType, string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsPanelListNavigation } from '@jotforminc/panel-components';
import SELECTORS from '../../../../store/selectors';
import { navigateToAction } from '../../../../store/actionCreators';
import { DESTINATION_TYPES } from '../../../../constants/navigation';
import { actAsButton } from '../../../../utils';

const NavigationPanelItem = ({
  title,
  description,
  path,
  Icon
}) => {
  const dispatch = useDispatch();

  const isNavigationPanelItemActive = useSelector(SELECTORS.getIsNavigationPanelItemActive(path));

  const handleNavigationItemClick = () => {
    dispatch(navigateToAction({ to: DESTINATION_TYPES.BUILDER_PAGE, subTab: path }));
  };

  return (
    <SettingsPanelListNavigation
      {...actAsButton(handleNavigationItemClick)}
      description={description}
      icon={Icon}
      label={title}
      listNavigationClasses={isNavigationPanelItemActive ? 'active' : null}
    />
  );
};

NavigationPanelItem.propTypes = {
  title: string,
  description: string,
  path: string,
  Icon: elementType
};

NavigationPanelItem.defaultProps = {
  title: '',
  description: '',
  path: '',
  Icon: null
};

export default NavigationPanelItem;
