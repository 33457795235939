import React from 'react';
import { Loader } from '@jotforminc/magnet';
import { func } from 'prop-types';

const FileLoadingRenderer = ({ translate }) => {
  return (
    <div className='flex flex-col items-center gap-2'>
      <Loader size="large" theme='dark' />
      <h5 className='color-navy-300'>
        {translate('Uploading')}
        ...
      </h5>
    </div>
  );
};

FileLoadingRenderer.propTypes = {
  translate: func
};

FileLoadingRenderer.defaultProps = {
  translate: f => f
};

export default FileLoadingRenderer;
