import React from 'react';

import { Hooks } from '@jotforminc/uikit';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { renderable } from '../../../../constants/propTypes';
import SettingsNavigationPanel from './SettingsNavigationPanel';
import SettingsStage from './SettingsStage';
import { ScSettings } from './scSettings';
import RightPanel from '../HomePage/RightPanel';
import PortalFeedbackWithWarning from '../PortalFeedbackWithWarning';
import { setRightPanelModeAction, toggleRightPanelAction } from '../../../../store/actionCreators';
import SELECTORS from '../../../../store/selectors';

const Settings = ({ children }) => {
  const dispatch = useDispatch();
  const isRightPanelOpen = useSelector(SELECTORS.isRightPanelOpenSelector);

  const selectors = '.settingsNavigationPanel, .rightPanelContainer';
  const [isStageClick, setStageClick] = Hooks.useClickOutsideStateWithSelector(false, selectors);
  const selectStage = () => setStageClick(true);
  const onSettingsStageClick = () => {
    selectStage();
    if (isRightPanelOpen && !isStageClick) {
      dispatch(toggleRightPanelAction(false));
      dispatch(setRightPanelModeAction(''));
    }
  };

  return (
    <ScSettings
      onClick={onSettingsStageClick}
      data-testid="settingsStage-test"
      className={classnames({ rpOpen: isRightPanelOpen })}
    >
      <SettingsNavigationPanel />
      <SettingsStage>
        {children}
      </SettingsStage>
      <RightPanel />
      <PortalFeedbackWithWarning
        isMultiPage={false} /* We don't need to position element */
      />
    </ScSettings>
  );
};

Settings.propTypes = {
  children: renderable.isRequired
};

export default Settings;
