import {
  LogoCashappLogomarkColor,
  LogoPagseguroLogomarkColor,
  LogoPaypalLogomarkLight,
  LogoSquareLogomarkLight,
  LogoPaypalBusinessLogomarkLight,
  LogoPaypalInvoicingLogomarkLight,
  LogoPaypalProLogomarkLight,
  LogoPaypalCheckoutLogomarkLight,
  LogoPaypalExpressLogomarkLight,
  LogoAuthorizenetLogomarkLight,
  LogoStripeLogomarkLight,
  LogoStripeCheckoutLogomarkLight,
  LogoStripeAchmLogomarkLight,
  LogoStripeAchLogomarkLight,
  LogoBraintreeLogomarkLight,
  LogoWorldpayUkLogomarkLight,
  LogoBluesnapLogomarkLight,
  LogoMonerisLogomarkLight,
  LogoGocardlessLogomarkColor,
  LogoPayfastLogomarkLight,
  LogoSensepassLogomarkLight,
  LogoKlarnaLogomarkLight,
  LogoFirstdataLogomarkLight,
  LogoCardconnectLogomarkLight,
  LogoWorldpayUsLogomarkLight,
  Logo2CheckoutLogomarkLight,
  LogoPaysafeLogomarkLight,
  LogoIyzicoLogomarkLight,
  LogoSkrillLogomarkLight,
  LogoChargifyLogomarkLight,
  LogoPayjunctionLogomarkLight,
  LogoClickbankLogomarkLight,
  LogoOnebipLogomarkLight,
  LogoPaymentwallLogomarkLight,
  LogoPurchaseorderLogomarkLight,
  LogoCybersourceLogomarkLight,
  LogoEcheckLogomarkLight,
  LogoBluepayLogomarkLight,
  LogoEwayLogomarkLight,
  LogoPayuLogomarkLight,
  LogoPayuIndiaLogomarkLight,
  LogoVenmoLogomarkLight,
  LogoMollieLogomarkLight,
  LogoAfterpayLogomarkLight
} from '@jotforminc/svg-icons';
import type { GATEWAY_DEFAULT_ICONS } from '../types';
import LogoApplegooglepayLight from './gatewayCustomIcons/lightIcons/applePayGooglePay';

/**
 * Light icons for payment gateways.
 * @type {GATEWAY_DEFAULT_ICONS}
 */
export const gatewayLightIcons: GATEWAY_DEFAULT_ICONS = {
  control_square: LogoSquareLogomarkLight,
  control_paypalcomplete: LogoPaypalBusinessLogomarkLight,
  control_paypal: LogoPaypalLogomarkLight,
  control_paypalInvoicing: LogoPaypalInvoicingLogomarkLight,
  control_paypalpro: LogoPaypalProLogomarkLight,
  control_paypalSPB: LogoPaypalCheckoutLogomarkLight,
  control_paypalexpress: LogoPaypalExpressLogomarkLight,
  control_authnet: LogoAuthorizenetLogomarkLight,
  control_stripe: LogoStripeLogomarkLight,
  control_stripeCheckout: LogoStripeCheckoutLogomarkLight,
  control_stripeACHManual: LogoStripeAchmLogomarkLight,
  control_stripeACH: LogoStripeAchLogomarkLight,
  control_braintree: LogoBraintreeLogomarkLight,
  control_cashApp: LogoCashappLogomarkColor,
  control_afterpay: LogoAfterpayLogomarkLight,
  control_clearpay: LogoAfterpayLogomarkLight,
  control_venmo: LogoVenmoLogomarkLight,
  control_applePayGooglePay: LogoApplegooglepayLight,
  control_mollie: LogoMollieLogomarkLight,
  control_cybersource: LogoCybersourceLogomarkLight,
  control_echeck: LogoEcheckLogomarkLight,
  control_bluepay: LogoBluepayLogomarkLight,
  control_eway: LogoEwayLogomarkLight,
  control_payu: LogoPayuLogomarkLight,
  control_payuMoney: LogoPayuIndiaLogomarkLight,
  control_worldpay: LogoWorldpayUkLogomarkLight,
  control_bluesnap: LogoBluesnapLogomarkLight,
  control_moneris: LogoMonerisLogomarkLight,
  control_gocardless: LogoGocardlessLogomarkColor,
  control_payfast: LogoPayfastLogomarkLight,
  control_sensepass: LogoSensepassLogomarkLight,
  control_sofort: LogoKlarnaLogomarkLight,
  control_pagseguro: LogoPagseguroLogomarkColor,
  control_firstdata: LogoFirstdataLogomarkLight,
  control_cardconnect: LogoCardconnectLogomarkLight,
  control_worldpayus: LogoWorldpayUsLogomarkLight,
  control_2co: Logo2CheckoutLogomarkLight,
  control_paysafe: LogoPaysafeLogomarkLight,
  control_iyzico: LogoIyzicoLogomarkLight,
  control_skrill: LogoSkrillLogomarkLight,
  control_chargify: LogoChargifyLogomarkLight,
  control_payjunction: LogoPayjunctionLogomarkLight,
  control_clickbank: LogoClickbankLogomarkLight,
  control_onebip: LogoOnebipLogomarkLight,
  control_paymentwall: LogoPaymentwallLogomarkLight,
  control_payment: LogoPurchaseorderLogomarkLight
};
