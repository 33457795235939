import React from 'react';
import propTypes from 'prop-types';
import { SVGIcon } from '@jotforminc/icon-selector';
import appConfig from '../../constants/appConfig';

const SvgRenderer = ({
  svgRef, iconColor, url, style
}) => {
  const isIconSpriteLoaded = !!document.getElementById(appConfig.svgIconsContainer);
  const buildr = isIconSpriteLoaded && svgRef;

  if (buildr) {
    return (
      <svg
        fill={iconColor}
        viewBox="0 0 24 24"
        style={{ width: '100%', height: '100%', ...style }}
      >
        <use xlinkHref={`#${svgRef}`} href={`#${svgRef}`} />
      </svg>
    );
  }
  return (
    <SVGIcon
      url={url} iconColor={iconColor}
      style={{ ...style }}
    />
  );
};

SvgRenderer.propTypes = {
  svgRef: propTypes.string,
  url: propTypes.string,
  iconColor: propTypes.string,
  style: propTypes.object
};

SvgRenderer.defaultProps = {
  svgRef: '',
  url: '',
  iconColor: '',
  style: {}
};

export default SvgRenderer;
