import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Gocardless gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlGocardless: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_gocardless: {
    billingName: {
      title: 'Customer Name Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    billingEmail: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    billingAdd: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    }
  }
};

export default controlGocardless;
