import React from 'react';
import type { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';

import { DialogBodyProps } from './dialog.types';

export const DialogBody = (props: PropsWithChildren<DialogBodyProps>): JSX.Element => {
  const {
    children, className, ...rest
  } = props;

  return (
    <div {...rest} className={cx(className, 'overflow-auto px-8 pt-8 mb-0')}>{children}</div>
  );
};
