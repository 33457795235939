import React, { forwardRef, useEffect, useState } from 'react';
import {
  string, shape, elementType, bool
} from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import AlertLabelWithIcon from '@jotforminc/alert-label';
import { AlertLabelTypes } from '@jotforminc/constants';

import { getValueFromEvent } from '../utils/helper';
import { useErrors } from './errorContext';

// TODO: we can do better validation handlers, i don't like isErrored fn.
// For example, we can keep a validations array with predefined & extendable validations with that update we'will not need required prop on label
const FieldLine = forwardRef(({
  id,
  noLabel,
  labelProps,
  componentProps,
  LabelRenderer,
  ComponentRenderer
}, ref) => {
  const {
    onBlur,
    onClick,
    onChange,
    onInput,
    onFieldError,
    onFieldWarning,
    isErrored,
    isWarned,
    errorList = {},
    warningList = {},
    ignoreBlurRequirementOnValidation,
    validateOnMount = false,
    initialValue,
    isEncrypted = false
  } = componentProps;

  const {
    setFieldDirty, dirtyFields
  } = useErrors();
  const errorFromContext = errorList[id];
  const warningFromContext = warningList[id];
  const [fieldError, setError] = useState(errorFromContext);
  const [fieldWarning, setWarning] = useState(warningFromContext);
  const isDirty = dirtyFields[id];
  Hooks.useEffectIgnoreFirst(() => {
    if (errorList[id] !== fieldError) {
      onFieldError(fieldError);
    }
    if (!isDirty) { setFieldDirty(id); }
  }, [fieldError]);

  Hooks.useEffectIgnoreFirst(() => {
    if (warningList[id] !== fieldWarning) {
      onFieldWarning(fieldWarning);
    }
  }, [fieldWarning]);

  const handleValidations = value => {
    if (typeof isErrored !== 'function') { return; }
    if (!isDirty && ignoreBlurRequirementOnValidation) {
      setFieldDirty(id);
    }
    const error = isErrored(value);
    setError(error);
  };

  const handleBlur = (event, ...params) => {
    if (typeof onBlur === 'function') { onBlur(event, ...params); }
    const value = getValueFromEvent(event);
    handleValidations(value);
  };

  const handleClick = (event, ...params) => {
    if (typeof onClick === 'function') {
      onClick(event, ...params);
    }
    if (typeof isWarned !== 'function') { return; }
    const warning = isWarned({ ...componentProps });
    if (warning?.key !== fieldWarning?.key) {
      setWarning(warning);
    }
  };

  const handleChange = (event, ...rest) => {
    const value = getValueFromEvent(event);
    onChange(value, ...rest);
    if (isDirty || ignoreBlurRequirementOnValidation) { handleValidations(value); }
  };

  const handleInput = (event, ...rest) => {
    const value = getValueFromEvent(event);
    if (typeof onInput === 'function') { onInput(value, ...rest); }
    if (isDirty) { handleValidations(value); }
  };

  useEffect(() => {
    // console.log(validateOnMount, initialValue);
    if (validateOnMount) {
      if (!initialValue && initialValue !== '') {
        throw new Error('To use the validateOnMount property, one must set initialValue to validate.');
      }
      setError(isErrored(initialValue));
    }
  }, [validateOnMount]);

  const Component = (
    <ComponentRenderer
      data-testid={id}
      {...componentProps}
      onInput={handleInput}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      id={id}
      ref={ref}
    />
  );

  if (noLabel) {
    return (
      <div className="formWizard-componentWrapper">
        {Component}
        <div className="formWizard-error">
          <div className="formWizard-error-text">
            {fieldError}
          </div>
        </div>
      </div>
    );
  }

  // Notification mail editing is disabled on encrypted forms.
  const isContentDisabled = isEncrypted && id === 'content';
  return (
    <LabelRenderer
      {...labelProps}
      elementID={id}
      htmlFor={id}
    >
      {isContentDisabled
        ? (
          <AlertLabelWithIcon
            description={"Encrypted forms can't make changes to notification emails. Disable form encryption to continue."}
            messageType={AlertLabelTypes.WARNING}
            htmlFor="encryptionWarning"
          />
        ) : Component }
      {isWarned && fieldWarning
          && (
            <AlertLabelWithIcon description={fieldWarning} messageType={AlertLabelTypes.WARNING} />
          )}
    </LabelRenderer>
  );
});

FieldLine.propTypes = {
  id: string.isRequired,
  noLabel: bool,
  labelProps: shape({}),
  componentProps: shape({}),
  LabelRenderer: elementType.isRequired,
  ComponentRenderer: elementType.isRequired
};

FieldLine.defaultProps = {
  noLabel: false,
  labelProps: {},
  componentProps: {}
};

export default FieldLine;
