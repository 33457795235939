import React, {
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { Hooks } from '@jotforminc/uikit';
import { handleCustomNavigation } from '@jotforminc/utils';
import { TConnectionStatus, TFormId } from '../../../types/common';
import { getUpdatingSettingsFromResponse } from './utils';
import BaseConnectionButton from './BaseConnectionButton';
import {
  getMollieConnectCode,
  getMollieProfiles,
  checkMollieConnection,
  getJWTToken
} from '../../../api';
import { CLIENT_ID_LIST_DEV } from '../../modals/Flows/Constants/Credentials';
import { I_MOLLIE_SETTINGS } from '../../modals/Flows/Types/GatewayTypes';
import useGatewayFlows from '../../modals/Flows/Context/useGatewayFlows';
import { getDisconnectSettings } from '../../modals/Flows/Utils';
import { checkIsGatewayConnected } from '../../../utils';
import { I_GATEWAYS } from '../../modals/Flows/Types';

const ConnectionButtonMollie = ({
  settings,
  checkoutFormId,
  connectionStatus,
  setConnectionStatus,
  gatewayType
} : IProps) : JSX.Element => {
  const {
    changedSettings,
    setChangedSettings,
    handleDisconnectGateway,
    user: { username }
  } = useGatewayFlows();

  Hooks.useEffectIgnoreFirst(() => {
    if (connectionStatus === 'connected') {
      handleDisconnectGateway(gatewayType);
    }
  }, [settings.sandbox]);

  useEffect(() => {
    if (checkIsGatewayConnected({ ...settings, type: gatewayType })) {
      setConnectionStatus('connected');
    } else {
      setConnectionStatus('notConnected');
    }
  }, [settings.profileId]);

  useEffect(() => {
    if (connectionStatus === 'connected') {
      checkMollieConnection(checkoutFormId, changedSettings?.connectionID ?? '').then(res => {
        if (res === 'connected') {
          getMollieProfiles(checkoutFormId, changedSettings?.connectionID ?? '').then(resp => {
            if (resp) {
              const profiles: Array<{ value: string, text: string }> = [];
              JSON.parse(resp).forEach((prof: { id: string, name: string }) => {
                profiles.push({
                  value: prof.id,
                  text: prof.name
                });
              });

              setChangedSettings({
                ...changedSettings,
                profileOptions: profiles
              });
            }
          }).catch(() => {
            // console.log('Could not get mollie profile: ', e);
          });
        } else {
          const disconnectSettings = getDisconnectSettings('control_mollie');
          setChangedSettings({ ...changedSettings, ...disconnectSettings });
        }
      }).catch(() => {
        // console.log('error: ', e)
      });
    }
  }, [checkoutFormId, connectionStatus]);

  const mollieConnectionMethod = (authUrl: string, redirectUrl: string, allowTest = false) => {
    const popupWidth = 800;
    const popupX = window.screenX + (window.innerWidth - popupWidth) / 2;
    const popupY = window.screenY + (window.innerHeight - 800) / 2;

    const molliePopup = handleCustomNavigation(
      authUrl,
      'view',
      true,
      `width=${popupWidth},height=800,status=0,top=${popupY}px,left=${popupX}px,location=1,toolbar=0,scrollbars=1,resizable=1`
    );

    if (window.jfMollieTimer) {
      window.clearInterval(window.jfMollieTimer);
    }

    window.jfMollieTimer = window.setInterval(() => {
      if (molliePopup && molliePopup.closed !== false) {
        window.clearInterval(window.jfMollieTimer);
      }
    }, 500);

    const handleSquareAuthMessage = event => {
      if (window.jfMollieTimer) {
        window.clearInterval(window.jfMollieTimer);
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      mollieConnectLoop(redirectUrl, allowTest, event.data.code);
      molliePopup.close();
      window.removeEventListener('message', handleSquareAuthMessage);
    };

    window.addEventListener('message', handleSquareAuthMessage);
  };

  const mollieConnectLoop = (redirectUrl: string, sandbox: boolean, setMollieCode: string | null = null) => {
    const mollieCode = setMollieCode;

    const innerTimer = setInterval(() => {
      clearInterval(innerTimer);
      if (mollieCode !== null) {
        const params = {
          authCode: mollieCode,
          redirectUrl,
          sandbox,
          isNewPaymentModal: true
        };

        getMollieConnectCode(checkoutFormId, params).then(resp => {
          const parsedResponse = JSON.parse(JSON.parse(resp));
          const settingsToBeUpdated = getUpdatingSettingsFromResponse(parsedResponse, 'control_mollie');
          if (!Object.keys(settingsToBeUpdated).length) return;
          if (parsedResponse.connectionID && parsedResponse.connectionID !== '') {
            setChangedSettings({
              ...changedSettings,
              ...settingsToBeUpdated,
              connectionID: parsedResponse.connectionID
            });
          } else {
            setConnectionStatus('notConnected');
          }
        }).catch(() => {
          setConnectionStatus('notConnected');
        });
      }
    }, 1000);
  };

  const connectMollie = async () => {
    const isRDS = /^https:\/\/\w*\.jotform\.pro$/.test(window.location.origin);
    const sandbox = settings.sandbox === 'Yes';
    const clientId = !isRDS ? 'app_JQ9PBxQBHbq8v5wRunCwmkFt' : CLIENT_ID_LIST_DEV[window.location.host].mollie;

    const redirectBaseUrl = isRDS ? window.location.origin : 'https://secure.jotform.com';
    const redirectUrl = `${redirectBaseUrl}/api/mollie/oauth.php`;
    const baseUrlMollie = 'https://www.mollie.com';

    const JWTToken = await getJWTToken(checkoutFormId, username);

    if (!JWTToken) {
      return setConnectionStatus('notConnected');
    }

    const scope = 'scope=payments.write orders.write orders.read payments.read mandates.read subscriptions.read subscriptions.write customers.read customers.write profiles.read';
    const mollieURL = `${baseUrlMollie}/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURI(redirectUrl)}&state=${JWTToken}&${scope}&response_type=code&approval_prompt=auto`;

    setConnectionStatus('connecting');
    mollieConnectionMethod(mollieURL, redirectUrl, sandbox);
  };

  return (
    <BaseConnectionButton
      settings={settings}
      connect={connectMollie}
      connectionStatus={connectionStatus}
    />
  );
};

export interface IProps {
  settings: I_MOLLIE_SETTINGS;
  checkoutFormId: TFormId;
  connectionStatus: string;
  setConnectionStatus: Dispatch<SetStateAction<TConnectionStatus>>;
  gatewayType: I_GATEWAYS;
}

export default ConnectionButtonMollie;
