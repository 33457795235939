import {
  put, take, select
} from 'redux-saga/effects';

import {
  ADD_PORTAL_ITEMS,
  ADD_TO_STACK,
  REDO,
  REMOVE_PORTAL_ITEMS,
  UNDO,
  UPDATE_PORTAL,
  UPDATE_ITEM_PROP,
  RESET_STACK,
  UPDATE_ORDER,
  UPDATE_MULTIPLE_ITEM,
  ADD_NEW_PAGE,
  DELETE_PAGE,
  DUPLICATE_ITEM
} from '../actionTypes';
import {
  addPortalItemAction,
  changeOrderAction,
  removePortalItems, trackEventAction,
  updateItemPropAction,
  updatePortalAction,
  updateMultipleItemAction,
  deletePageAction, addNewPageAction
} from '../actionCreators';
import SELECTORS from '../selectors';
import { ITEM_ADDITION_ORDER_STRATEGY } from '../../constants';

const ACTION_DEFINITIONS = {
  'ADD_PORTAL_ITEMS/REQUEST': 'itemsAdded',
  'REMOVE_PORTAL_ITEMS/REQUEST': 'itemsRemoved',
  'UPDATE_PORTAL/UNDOABLE': 'appUpdated',
  'UPDATE_ITEM_PROP/UNDOABLE': 'itemUpdated',
  'UPDATE_ORDER/UNDOABLE': 'itemOrdersUpdated',
  'UPDATE_MULTIPLE_ITEM/UNDOABLE': 'multipleItemsUpdated',
  'ADD_NEW_PAGE/UNDOABLE': 'newPageAdded',
  'DELETE_PAGE/UNDOABLE': 'pageDeleted'
};

function generateCommand(requestAction, successAction) {
  const { type: requestType, payload: requestPayload } = requestAction;
  const { type: successType, payload: successPayload, currentData } = successAction;
  let undoAction;
  switch (successType) {
    case ADD_PORTAL_ITEMS.SUCCESS:
      const addedItemIDs = successPayload.map(i => i.id);
      undoAction = removePortalItems(addedItemIDs);
      break;
    case REMOVE_PORTAL_ITEMS.SUCCESS:
      const { itemIDs: removingPortalItems } = requestPayload;
      const location = currentData.length && currentData[0] ? currentData[0].portalOrder : ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE;
      // for now first location is enough, if it is not enough collect every undo action seperately
      undoAction = {
        ...addPortalItemAction(removingPortalItems.map(removingItemID => currentData.find(({ id }) => id === removingItemID)),
          location),
        isUndo: true
      };
      break;
    case UPDATE_PORTAL.SUCCESS:
      const ignoreProps = ['appVersion'];
      const undoProps = Object.keys(currentData).filter(prop => !ignoreProps.includes(prop)).reduce((pre, next) => {
        return { ...pre, [next]: currentData[next] };
      }, {});
      undoAction = updatePortalAction(undoProps);
      break;
    case UPDATE_ITEM_PROP.SUCCESS:
      const { itemID } = requestPayload;
      const props = Object.keys(currentData).reduce((pre, next) => {
        return { ...pre, [next]: currentData[next] };
      }, {});
      undoAction = updateItemPropAction({
        itemID,
        prop: {
          ...props
        }
      });
      break;
    case UPDATE_ORDER.SUCCESS:
      undoAction = changeOrderAction({ allItems: currentData });
      break;
    case UPDATE_MULTIPLE_ITEM.SUCCESS:
      undoAction = updateMultipleItemAction(currentData);
      break;
    case ADD_NEW_PAGE.SUCCESS:
      const { pageID: addedID } = successPayload;
      const { deleteItems = false } = requestPayload || {};
      undoAction = deletePageAction(addedID, deleteItems);
      break;
    case DELETE_PAGE.SUCCESS:
      const { pageID, deleteItems: _deleteItems } = requestPayload;
      const pageToAdd = currentData.pages.find(_page => _page.id === pageID);
      const requestData = { pages: pageToAdd, newPageOrder: pageToAdd.pageOrder };
      if (_deleteItems) {
        const deletedItems = currentData.items.filter(item => item.page === pageID);
        requestData.items = deletedItems;
      } else {
        requestData.items = currentData.items;
      }
      undoAction = addNewPageAction(requestData);
      break;
    default:
      throw new Error('Unknown Action: Can not create undo action', successType);
  }

  return {
    undoAction: { ...undoAction, dontStack: true },
    redoAction: { type: requestType, payload: requestPayload, dontStack: true }
  };
}

function* addToStack(action) {
  const { requestAction, dontStack = false } = action;
  if (dontStack) {
    return;
  }
  try {
    const command = generateCommand(requestAction, action);
    const hasRedo = yield select(SELECTORS.hasRedoSelector);
    if (hasRedo) {
      yield put({ type: RESET_STACK });
    }
    yield put({ type: ADD_TO_STACK, payload: { command } });
  } catch (e) {
    console.warn('Action couldn\'t added to undo stack!', e);
  }
}

// Takes care of the stack
export function* watchUndoableActions() {
  const actionsToTrack = [
    REMOVE_PORTAL_ITEMS,
    ADD_PORTAL_ITEMS,
    UPDATE_PORTAL,
    UPDATE_ITEM_PROP,
    UPDATE_ORDER,
    UPDATE_MULTIPLE_ITEM,
    ADD_NEW_PAGE,
    DELETE_PAGE,
    DUPLICATE_ITEM
  ].map(a => a.SUCCESS); // Listen success actions so there wont be any undo for an operation hasn't done yet at any time.. Also we have the genuine ids..
  while (true) {
    const undoable = yield take(props => {
      const { type, requestAction: { dontStack = false } = {} } = props;
      return actionsToTrack.includes(type) && !dontStack;
    });
    yield addToStack(undoable);
  }
}

function* doUndo() {
  const currentHeadPosition = yield select(SELECTORS.getUndoStackHeadSelector);
  const actionToHandleIdx = currentHeadPosition + 1; // prev value
  const { undoAction } = yield select(SELECTORS.getCommandSelector(actionToHandleIdx));
  const { type } = undoAction;
  yield put(trackEventAction({
    action: 'undoAction',
    target: { action: ACTION_DEFINITIONS[type] }
  }));
  yield put(undoAction);
}

function* doRedo() {
  const currentHeadPosition = yield select(SELECTORS.getUndoStackHeadSelector);
  const { redoAction } = yield select(SELECTORS.getCommandSelector(currentHeadPosition));
  const { type } = redoAction;
  yield put(trackEventAction({
    action: 'redoAction',
    target: { action: ACTION_DEFINITIONS[type] }
  }));
  yield put(redoAction);
}

// Takes care of the head
export function* watchUndoRedoActions() {
  while (true) {
    const action = yield take(({ type }) => [UNDO, REDO].includes(type));
    const worker = action.type === UNDO ? doUndo : doRedo;
    yield worker(action);
  }
}
