import { func, string } from 'prop-types';
import React, { useMemo } from 'react';
import Roles from './Roles';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';
import { getButtonActionProps } from './Roles/buttonActionProps';

const ButtonActions = ({ onChange, value: actionbuttonRole }) => {
  const handleButtonRoleChange = ({ buttonRole }) => {
    if (actionbuttonRole !== buttonRole) onChange({ buttonRole });
  };

  const buttonActionProps = useMemo(() => {
    const isShareEnabled = isFeatureEnabled(FEATURE_NAMES.ShareOptions);
    return getButtonActionProps(isShareEnabled);
  }, [window.location.href]);

  return (
    <Roles
      onChange={handleButtonRoleChange}
      buttonActionProps={buttonActionProps}
      value={actionbuttonRole}
    />
  );
};

ButtonActions.propTypes = {
  onChange: func,
  value: string
};

ButtonActions.defaultProps = {
  onChange: f => f,
  value: ''
};

export default ButtonActions;
