/* eslint-disable camelcase */
import React, {
  useCallback, useMemo
} from 'react';
import { elementType, func, string } from 'prop-types';

import { t } from '@jotforminc/translation';

import styled from 'styled-components';
import Badge from '../../../../../../components/Badge';
import { isYes } from '../../../../../../utils';
import RadioToggle from '../../../RadioToggle';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import ResetBadge from './ResetBadge';
import { RESET_PERIOD_VALUES } from './badgeToggleConstants';
import LoginRequiredToggle from '../LoginRequireToggle';

const ScBadgeToogle = styled.div`

  .rp-badge {
    margin-left: 8px;

    .badge-content {
      width: 20px;
      height: 20px;

      svg {
      width: 20px;
      height: 20px;
    }
    }
  }
`;

const BadgeToggle = props => {
  const {
    value, DescriptionRenderer, onChange,
    required_showBadge, completed_clearBadgeOn, completed_clearBadgePeriod
  } = props;

  const clearBadgePeriod = completed_clearBadgePeriod;
  const clearBadgeOn = completed_clearBadgeOn;

  // TODO :: add neutral value
  const initialClearBadgeToggleValue = useMemo(() => ((clearBadgePeriod && clearBadgePeriod !== RESET_PERIOD_VALUES.NEVER) ? 'Yes' : 'No'), [clearBadgePeriod]);
  const requiredDependentValue = useMemo(() => (isYes(required_showBadge) ? 'Yes' : value), [value, required_showBadge]);

  const showPeriodDropdowns = isYes(initialClearBadgeToggleValue);
  const showClearBadge = isYes(requiredDependentValue);

  const handleShowBadgeChange = useCallback(e => {
    if (isYes(required_showBadge)) return;

    if (isYes(e)) {
      return onChange({ completed_showBadge: e, completed_clearBadgePeriod: clearBadgePeriod || RESET_PERIOD_VALUES.NEVER });
    }
    onChange({ completed_showBadge: e });
  }, [clearBadgePeriod, onChange, required_showBadge]);

  const handleClearBadgeToggleChange = useCallback(e => {
    const isActive = isYes(e);

    if (isActive) return onChange({ completed_clearBadgePeriod: RESET_PERIOD_VALUES.DAILY });
    onChange({ completed_clearBadgePeriod: RESET_PERIOD_VALUES.NEVER, completed_clearBadgeOn: '' });
  }, [clearBadgePeriod, onChange]);

  const tooltipText = useMemo(() => (isYes(required_showBadge) ? t('This can’t be disabled If mark as required is enabled') : null), [required_showBadge]);
  return (
    <ScBadgeToogle>
      <LoginRequiredToggle
        {...props}
        value={requiredDependentValue}
        onChange={handleShowBadgeChange}
        readOnly={isYes(required_showBadge)}
        tooltipText={tooltipText}
        badge={showClearBadge ? <Badge className="rp-badge w-5 h-5 ml-2 anim anim-700 fade-in" /> : ''}
      />
      {showClearBadge && (
        <>
          <RadioToggle
            title={t('Reset the completed marking')}
            description={t('Reset the completed marking after a period.')}
            onChange={handleClearBadgeToggleChange}
            value={initialClearBadgeToggleValue}
            DescriptionRenderer={DescriptionRenderer}
          />
          {showPeriodDropdowns && (
            <div className='mt-4'>
              <ResetBadge
                clearBadgePeriod={clearBadgePeriod}
                completed_clearBadgePeriod={clearBadgePeriod}
                clearBadgeOn={clearBadgeOn}
                completed_clearBadgeOn={clearBadgeOn}
                onChange={onChange}
              />
            </div>
          )}
        </>
      )}
    </ScBadgeToogle>
  );
};

BadgeToggle.propTypes = {
  value: string,
  DescriptionRenderer: elementType,
  clearBadgePeriod: string,
  clearBadgeOn: string,
  onChange: func,
  required_showBadge: string,
  completed_clearBadgeOn: string,
  completed_clearBadgePeriod: string
};

BadgeToggle.defaultProps = {
  value: '',
  DescriptionRenderer: PanelItemDescriptionRenderer,
  clearBadgePeriod: '',
  clearBadgeOn: '',
  onChange: f => f,
  required_showBadge: 'No',
  completed_clearBadgeOn: '',
  completed_clearBadgePeriod: 'never'
};

export default BadgeToggle;
