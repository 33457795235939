import React, { FunctionComponent, useEffect, useState } from 'react';
import { t } from '@jotforminc/translation';
import { FormWizard } from '@jotforminc/form-wizard';
import { Button } from '@jotforminc/magnet';

import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { IQuestion } from '../../../types/common';
import COMPONENT_TYPE_LIST from '../../../constants/componentTypes';
import { getElementList } from './formFieldsEditorElements';
import { getFieldTitleByType } from './utils';
import FormFieldConfirmationModal from './FormFieldsConfirmationModal';
import { isYes } from '../../../utils';
import { CHECKOUT_FORM_REQUIRED_QIDS } from '../../../constants/variables';

type FormFieldsEditorViewType = {
  editingItem: IQuestion,
  setEditingItemId: React.Dispatch<React.SetStateAction<string | null>>,
  onCheckoutFormQuestionUpdate: (id: number | string, prop: {[key: string]: string}) => void,
  gatewayRequiredQuestionIds: string[]
};

const connectedFields = (props: any, editingItem: IQuestion): {[key: string]: {[key: string]: string}} => {
  return {
    characterLimit: { minsize: '', maxsize: '' },
    inputMask: { inputTextMask: '', inputMaskValue: (!props.inputMaskValue && isYes(props.inputMask)) ? '(###) ###-####' : props.inputMaskValue },
    inputTextMask: { validation: 'Fill Mask' },
    entryLimitToggle: { entryLimit: 'None-0', entryLimitMin: 'None-0' },
    ...(editingItem.subfields
      && { states: { subfields: (props.states === 'americanStates') ? editingItem.subfields.split('|').filter((field : string) => field !== 'country').join('|') : editingItem.subfields } }
    )
  };
};

const ignoredFields = ['characterLimit', 'entryLimits', 'entryLimitToggle'];

const FormFieldsEditorView: FunctionComponent<FormFieldsEditorViewType> = ({
  editingItem, setEditingItemId, onCheckoutFormQuestionUpdate, gatewayRequiredQuestionIds = []
}) => {
  const [editedProps, setEditedProps] = useState<{[key: string]: string}>({});
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { qid, type } = editingItem;

  const handlePropChange = ({ key, value }: {key: string, value: string}) => setEditedProps(props => (
    { ...props, [key]: value, ...connectedFields({ ...props, [key]: value }, editingItem)[key] || {} }
  ));

  const handleSave = () => {
    onCheckoutFormQuestionUpdate(qid, omit(editedProps, ignoredFields));
    setEditingItemId(null);
  };

  const hasDiff = () => !Object.keys(editedProps).some(key => !isEqual(editingItem[key as keyof IQuestion], editedProps[key]));

  const elementList = getElementList({ ...editingItem, ...editedProps }, handlePropChange, [...CHECKOUT_FORM_REQUIRED_QIDS, ...gatewayRequiredQuestionIds]);

  useEffect(() => {
    if (editingItem.type === 'control_email') {
      const isInitialMaxsizeFilled = editingItem.maxsize && editedProps.entryLimits === undefined;
      const isToggleClosed = editedProps.entryLimits === 'No' && editedProps.maxsize !== '';
      if (isInitialMaxsizeFilled) {
        setEditedProps({ ...editedProps, entryLimits: 'Yes' });
      } else if (isToggleClosed) {
        setEditedProps({ ...editedProps, maxsize: '' });
      }
    }
  }, [editedProps]);

  return (
    <div className='formFieldsEditorView flex flex-col w-full h-full'>
      <div className={`${isModalOpen ? 'modalBg' : ''} formFieldsEditorView-content flex-1 overflow-y-scroll`}>
        <div className='font-medium text-uppercase ml-4 mt-6'>{t(`${getFieldTitleByType(type)} Properties`)}</div>
        <FormWizard
          onChange={(_: any, data: { key: string, value: string }) => handlePropChange(data)}
          elementList={elementList}
          componentTypeList={COMPONENT_TYPE_LIST}
        />
      </div>
      <div className="payment-formFieldsEditorView-footer formFieldsEditorView-footer fixed bottom-0 w-full">
        <div className="bg-gray-600 flex justify-between py-3 px-4 flex-col gap-2 xs:gap-0 xs:flex-row">
          <Button
            className="p-2"
            colorStyle="secondary"
            onClick={() => (hasDiff() ? setEditingItemId(null) : setModalOpen(true))}
          >
            {t('BACK')}
          </Button>
          <Button
            className="p-2"
            colorStyle="success"
            onClick={handleSave}
          >
            {t('SAVE')}
          </Button>
        </div>
        {isModalOpen && <FormFieldConfirmationModal onCancel={() => setModalOpen(false)} onDiscard={() => setEditingItemId(null)} onConfirm={handleSave} />}
      </div>
    </div>
  );
};

export default FormFieldsEditorView;
