import React from 'react';
import { string } from 'prop-types';

const WidgetItemDescriptionRenderer = ({ description, ...props }) => (
  <p
    {...props}
    className="field-description"
    data-testid="fieldDescription"
    dangerouslySetInnerHTML={{ __html: description }}
  />
);

WidgetItemDescriptionRenderer.propTypes = { description: string };
WidgetItemDescriptionRenderer.defaultProps = { description: '' };

export default WidgetItemDescriptionRenderer;
