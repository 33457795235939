import React, { Suspense, memo } from 'react';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import { Loading } from '../Loading';

const LibIconSelector = safeLazy(() => import(/* webpackChunkName: "IconSelector" */'../Lib/IconSelector'));

const IconSelector = props => {
  return (
    <Suspense fallback={<Loading />}>
      <LibIconSelector
        {...props}
      />
    </Suspense>
  );
};

export default memo(IconSelector);
