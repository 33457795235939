import React, { useState } from 'react';
import { string, object, func } from 'prop-types';
import Loader from '../Loader';

const LegacyTemplatesRenderer = ({ frameSrc, frameRef, handleTemplateLoad }) => {
  const [frameActive, setFrameActive] = useState(false);
  return (
    <>
      <Loader />
      <iframe
        ref={frameRef}
        src={frameSrc}
        title="Templates"
        style={!frameActive ? { pointerEvents: 'none' } : {}}
        onLoad={() => {
          setFrameActive(true);
          handleTemplateLoad();
        }}
        className="jfWizard-templates-frame"
      />
    </>
  );
};

export default LegacyTemplatesRenderer;

LegacyTemplatesRenderer.propTypes = {
  frameSrc: string,
  frameRef: object,
  handleTemplateLoad: func
};

LegacyTemplatesRenderer.defaultProps = {
  frameSrc: '',
  frameRef: null,
  handleTemplateLoad: f => f
};
