import qs from 'qs';
/** Data */
import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';
import type {
  GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES,
  PAYMENT_APM_NAMES,
  PAYMENT_FIELDS
} from '@jotforminc/payment-constants';
import { handleCustomNavigation } from '@jotforminc/utils';
import { sandboxPostDataConverter } from './sandboxPostDataConverter';

/**
 * Returns the OAuth URL for the specified payment gateway.
 * @param gateway - The payment gateway.
 * @returns The OAuth URL for the specified payment gateway, or null if the gateway is not supported.
 */
const getOauthUrl = (gateway: PAYMENT_FIELDS): string |null => {
  switch (gateway) {
    case 'control_square':
    case 'control_cashApp':
    case 'control_afterpay':
    case 'control_clearpay':
      return '/payment/oauth/square/start';
    case 'control_gocardless':
      return '/payment/oauth/gocardless/start';
    case 'control_mollie':
      return '/payment/oauth/mollie/start';
    case 'control_stripe':
    case 'control_stripeCheckout':
    case 'control_stripeACHManual':
      return '/payment/oauth/stripe/start';
    case 'control_paypalSPB':
    case 'control_paypalInvoicing':
    case 'control_paypalcomplete':
    case 'control_venmo':
      return '/payment/connection/paypal/start';
    default:
      return null;
  }
};

/**
 * Returns the OAuth source based on the provided payment gateway.
 *
 * @param gateway - The payment gateway.
 * @returns The OAuth source or null if the gateway is not supported.
 */
const getOauthSource = (gateway: PAYMENT_FIELDS): string | null => {
  switch (gateway) {
    case 'control_square':
    case 'control_cashApp':
    case 'control_afterpay':
    case 'control_clearpay':
      return 'square';
    case 'control_gocardless':
      return 'gocardless';
    case 'control_mollie':
      return 'mollie';
    case 'control_stripe':
    case 'control_stripeCheckout':
    case 'control_stripeACHManual':
      return 'stripe';
    case 'control_paypalSPB':
    case 'control_paypalInvoicing':
    case 'control_paypalcomplete':
    case 'control_venmo':
      return 'paypal';
    default:
      return null;
  }
};

export const handleOauthPopupManager = ({
  currentlyGateway,
  selectedApm = null,
  selectedChildGatewayType = null,
  connectionName,
  connectionModeValue = null,
  isEditMode = false,
  isReconnectMode = false,
  connectionId,
  onStart,
  onCanceled,
  onRequestProcessing
}: {
  currentlyGateway: PAYMENT_FIELDS;
  selectedApm: PAYMENT_APM_NAMES | null;
  selectedChildGatewayType: PAYMENT_FIELDS | null;
  connectionName: string;
  connectionModeValue: GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES | null;
  isEditMode?: boolean;
  isReconnectMode?: boolean;
  connectionId?: string;
  onStart: () => void;
  onCanceled: () => void;
  onRequestProcessing: (gateway: PAYMENT_FIELDS, response: any) => void;
}): void => {
  /** Start */
  onStart();

  const popupHeight = 700;
  const popupWidth = 700;
  const popupX = window.screenX + (window.innerWidth - popupWidth) / 2;
  const popupY = window.screenY + (window.innerHeight - popupHeight) / 2;

  let Data = supportedGatewayConnectionPropsList[currentlyGateway];
  let settedGatewayType = currentlyGateway;

  if (selectedApm && selectedChildGatewayType) {
    Data = supportedGatewayConnectionPropsList[selectedChildGatewayType];
    settedGatewayType = selectedChildGatewayType;
  }

  if (Data) {
    const queryParameters: { [key: string]: string | boolean } = {};
    const { allowSwitchMode, mode } = Data.connection;

    if (connectionModeValue && allowSwitchMode === true && mode && mode.name) {
      const preparedSandboxValue = sandboxPostDataConverter(mode.name, connectionModeValue);
      const sandboxData = (preparedSandboxValue === '1' || preparedSandboxValue === '2') ? 'Yes' : 'No';

      queryParameters.sandbox = sandboxData;
    }

    queryParameters.title = connectionName;
    queryParameters.gateway = settedGatewayType.replace('control_', '');

    if ((isEditMode || isReconnectMode) && connectionId) {
      queryParameters.connectionID = connectionId;

      if (isEditMode) {
        queryParameters.flow = 'edit';
      }

      if (isReconnectMode) {
        queryParameters.flow = 'reconnect';
      }
    }

    if (window.teamID) {
      queryParameters.teamID = window.teamID;
    }

    if (selectedApm) {
      queryParameters.nameApm = currentlyGateway.replace('control_', '');
    }

    const oAuthURL = getOauthUrl(settedGatewayType);
    const oAuthSource = getOauthSource(settedGatewayType);

    if (oAuthURL && oAuthSource) {
      const gatewayOAuthPopup = handleCustomNavigation(
        `${oAuthURL}?${qs.stringify(queryParameters)}`,
        'view',
        true,
        `width=${popupWidth},height=${popupHeight},status=0,top=${popupY}px,left=${popupX}px,location=1,toolbar=0,scrollbars=1,resizable=1`
      );

      if (window.jfPaymentOauthTimer) {
        window.clearInterval(window.jfPaymentOauthTimer);
      }

      window.jfPaymentOauthTimer = window.setInterval(() => {
        if (gatewayOAuthPopup && gatewayOAuthPopup.closed) {
          window.clearInterval(window.jfPaymentOauthTimer);
          onCanceled();
        }
      }, 50);

      const handleAuthMessage = (event: MessageEvent): any => {
        if (event.origin !== window.location.origin || event.data.source !== `jf_${oAuthSource}_payment`) {
          return false;
        }

        if (window.jfPaymentOauthTimer) {
          window.clearInterval(window.jfPaymentOauthTimer);
        }

        gatewayOAuthPopup?.close?.();
        window.removeEventListener('message', handleAuthMessage);

        onRequestProcessing(settedGatewayType, event.data.account_id);
        console.log('onRequestProcessing', event.data.account_id);
      };

      window.addEventListener('message', handleAuthMessage);
    } else {
      onRequestProcessing(settedGatewayType, {
        status: false,
        errorKeys: ['invalidFields'],
        errors: {
          invalidFields: {
            error: true,
            message: 'Some fields are invalid. Please check and try again.'
          }
        }
      });
    }
  } else {
    onRequestProcessing(settedGatewayType, {
      status: false,
      errorKeys: ['invalidGateway'],
      errors: {
        invalidGateway: {
          error: true,
          message: 'Invalid gateway type.'
        }
      }
    });
  }
};
