import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Stripe Checkout gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlStripeCheckout: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_stripeCheckout',
  name: gatewayFieldToName.control_stripeCheckout,
  supportedCurrencies: gatewaySupportedCurrencies.control_stripeCheckout,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_stripeCheckout,
  sortByCountry: gatewayListSortedByCountry.control_stripeCheckout,

  /** APM's */
  isApm: false,
  isApmSupport: true,
  supportedApm: {
    control_applePayGooglePay: {
      name: gatewayFieldToName.control_applePayGooglePay,
      supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_square,
      supportedCurrencies: gatewaySupportedCurrencies.control_square
    }
  },
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'oauth',
    allowSwitchMode: true,
    mode: {
      name: 'allowTest',
      options: [
        { value: 'true', text: 'Test Mode', mappedValue: '1' },
        { value: 'false', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    isChargeLaterSupport: true,
    chargeLaterPropReferences: {
      activator: {
        propName: 'laterCharge',
        propValue: 'immediately'
      }
    },
    propReferences: {
      stripeUserId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Stripe User ID' } },
      allowTest: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['stripeUserId']
  }
};
