import React, {
  useRef,
  useMemo
} from 'react';
import { t } from '@jotforminc/translation';
import { Button, Hooks } from '@jotforminc/uikit';
import {
  IconCheck,
  IconArrowsRotate,
  IconCaretDown,
  IconCaretUp,
  IconLinkDiagonalBroken
} from '@jotforminc/svg-icons';
import { useSelector } from 'react-redux';
import { IGatewaySettings } from '../../../types/common';
import useGatewayFlows from '../../modals/Flows/Context/useGatewayFlows';
import { sendTrackData } from '../../../utils';
import selectors from '../../../store/selectors';

const BaseConnectionButton = ({
  settings,
  connect,
  connectionStatus
} : IProps) : JSX.Element => {
  const connectionButtonDropdownRef = useRef<HTMLDivElement>(null);
  const {
    currentlyGateway, handleDisconnectGateway, resourceType, formId, getParentGatewayType
  } = useGatewayFlows();
  const [connectionButtonDropdownVisible, setConnectionButtonDropdownVisible] = Hooks.useClickOutsideState(false, [connectionButtonDropdownRef]);
  const username = useSelector(selectors.getUsername);

  const ButtonText = useMemo(() => {
    let btnText = null;
    let icon = null;

    if (connectionStatus === 'notConnected') {
      btnText = t('Connect');
    } else if (connectionStatus === 'connected' || connectionStatus === 'failed') {
      btnText = t('Connected');
    } else if (connectionStatus === 'connecting') {
      btnText = `${t('Connecting')}...`;
      icon = <IconArrowsRotate className="iconArrowRotate" />;
    } else if (connectionStatus === 'initializing') {
      btnText = `${t('Initializing')}...`;
      icon = <IconArrowsRotate className="iconArrowRotate" />;
    } else if (connectionStatus === 'notInitialized') {
      btnText = t('Not initialized');
    }

    return (
      <>
        {icon}
        <span>{btnText}</span>
      </>
    );
  }, [connectionStatus, settings.nameAPM]);

  const renderButton = () => {
    const connectionButton = (
      <Button
        className={`connectionButton ${connectionStatus}`}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          const preventClickStatuses = ['connected', 'connecting', 'initializing', 'notInitialized'];
          if (preventClickStatuses.includes(connectionStatus)) {
            if (connectionStatus === 'connected') {
              setConnectionButtonDropdownVisible(true);
            }
            e.preventDefault();
            return;
          }
          if (connect) {
            connect();
            const type = getParentGatewayType();
            sendTrackData(username, 'new_payment_modal_connect_clicked', { resourceType, formId, gateway: type });
          }
        }}
      >
        <div>
          <span>
            {connectionStatus === 'connected' && <IconCheck className="IconCheck" />}
            {ButtonText}
            {connectionStatus === 'connected' && !connectionButtonDropdownVisible && <IconCaretDown className="IconCaretDown" />}
            {connectionStatus === 'connected' && connectionButtonDropdownVisible && <IconCaretUp className="IconCaretUp" />}
          </span>
        </div>
      </Button>
    );

    return connectionButton;
  };

  const handleDisconnect = () => {
    if (!currentlyGateway) return;
    handleDisconnectGateway(currentlyGateway);
    const type = getParentGatewayType();
    sendTrackData(username, 'new_payment_modal_disconnect_clicked', { resourceType, formId, gateway: type });
  };

  return (
    <div className="connectBtn connected relative">
      { renderButton() }
      <button
        ref={connectionButtonDropdownRef}
        type='button'
        onKeyUp={handleDisconnect}
        onClick={handleDisconnect}
        className={`
          ${(connectionStatus === 'connected' && connectionButtonDropdownVisible) ? 'h-11 p-3 opacity-1' : 'h-0 p-0 opacity-0'}
          absolute top-full flex justify-center items-center connectionButtonDropdown disconnectBtnWrapper transition duration-300
        `}
      >
        <div className="disconnectBtn">
          <IconLinkDiagonalBroken />
          <span>{t('Disconnect')}</span>
        </div>
      </button>
    </div>
  );
};
export interface IProps {
  settings: IGatewaySettings,
  connect?: () => void,
  connectionStatus: string,
}

export default BaseConnectionButton;
