import React from 'react';
import { number, shape } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import PageSettingsItem from './PageSettingsItem';

const DraggablePageSettingsItem = props => {
  const {
    page,
    index
  } = props;
  const isLocked = !!page?.linkedItemID;
  return (
    <Draggable
      draggableId={`${page.id}`}
      index={index}
      key={page.id || page.tempID}
      isDragDisabled={isLocked}
    >
      {({
        innerRef: ref, draggableProps, dragHandleProps
      }) => (
        <PageSettingsItem
          ref={ref}
          draggableProps={draggableProps}
          dragHandleProps={dragHandleProps}
          page={page}
          isLocked={isLocked}
          {...props}
        />
      )}
    </Draggable>
  );
};

DraggablePageSettingsItem.defaultProps = {
  page: {}
};

DraggablePageSettingsItem.propTypes = {
  page: shape({}),
  index: number.isRequired
};

export default DraggablePageSettingsItem;
