import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control PayPal Pro gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlPaypalPro: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_paypalpro',
  name: gatewayFieldToName.control_paypalpro,
  supportedCurrencies: gatewaySupportedCurrencies.control_paypalpro,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_paypalpro,
  sortByCountry: gatewayListSortedByCountry.control_paypalpro,

  /** APM's */
  isApm: false,
  isApmSupport: true,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: false,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: true,
    mode: {
      name: 'sandbox',
      options: [
        { value: 'enabled', text: 'Test Mode', mappedValue: '1' },
        { value: 'Disabled', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    isSecure3DSupport: true,
    secure3DPropReferences: {
      activator: {
        propName: 'sca',
        propValue: 'Yes'
      },
      dependentProps: ['cardinalAPIkey', 'cardinalAPIindentifier', 'cardinalOrgUnitID']
    },
    isChargeLaterSupport: true,
    chargeLaterPropReferences: {
      activator: {
        propName: 'authOnly',
        propValue: 'Yes'
      }
    },
    propReferences: {
      username: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'API Username' } },
      password: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'API Password' } },
      signature: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'API Signature' } },
      sca: {
        isSecret: 'No',
        isRequired: 'Yes',
        type: 'toggle',
        inputLabels: {
          label: 'Enable 3D Secure 2.0',
          description: 'Collect online payments in European Economic Area.',
          learnMoreLink: 'https://www.jotform.com/help/458-how-to-integrate-form-with-paypal-pro#3ds'
        },
        toggleProps: {
          enabled: { value: 'Yes', text: 'ON' },
          disabled: { value: 'No', text: 'OFF' },
          defaultValue: 'No'
        }
      },
      cardinalAPIkey: {
        isSecret: 'Yes',
        isRequired: 'Yes',
        isDependentProp: 'Yes',
        dependentProp: {
          propName: 'sca',
          propValue: 'Yes'
        },
        inputLabels: { label: 'Cardinal API Key' }
      },
      cardinalAPIindentifier: {
        isSecret: 'Yes',
        isRequired: 'Yes',
        isDependentProp: 'Yes',
        dependentProp: {
          propName: 'sca',
          propValue: 'Yes'
        },
        inputLabels: { label: 'Cardinal API Identifier' }
      },
      cardinalOrgUnitID: {
        isSecret: 'Yes',
        isRequired: 'Yes',
        isDependentProp: 'Yes',
        dependentProp: {
          propName: 'sca',
          propValue: 'Yes'
        },
        inputLabels: { label: 'Cardinal OrgUnit ID' }
      },
      sandbox: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['username', 'password', 'signature', 'sca', 'cardinalAPIkey', 'cardinalAPIindentifier', 'cardinalOrgUnitID']
  }
};
