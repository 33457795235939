import { T_CONDITIONS } from '@jotforminc/payment-constants';
/**
 * Checks if given object is plain
 * @returns {boolean}
 */

export const isPlainObject = (obj: Exclude<T_CONDITIONS, boolean>): boolean => {
  return Object.prototype.toString.call(obj) === '[object Object]'
      && obj !== null
      && Object.getPrototypeOf(obj) === Object.prototype;
};
