import React from 'react';
import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { t } from '@jotforminc/translation';

import Notification from './Notification';
import { undoAction } from '../../store/actionCreators';
import { getUndoRedoBindings } from '../../modules/Builder/constants/undoRedo';
import { checkMobileOrTablet } from '../../utils';

const UndoNotification = ({ message }) => {
  const dispatch = useDispatch();
  const isDesktop = !checkMobileOrTablet();

  const onUndoClick = () => dispatch(undoAction());
  const [undoBinding] = getUndoRedoBindings();
  return (
    <Notification
      message={message}
      {...isDesktop && { description: t(`Hit ${undoBinding} to undo anytime!`) }}
      buttonText={t('Undo')}
      onButtonClick={onUndoClick}
    />
  );
};

UndoNotification.propTypes = {
  message: string.isRequired
};

export default UndoNotification;
