import { t } from '@jotforminc/translation';
import React from 'react';
import { Button } from '@jotforminc/magnet';
import { IconXmark, IconEyeFilled } from '@jotforminc/svg-icons';

const HeaderRenderer = onClose => () => (
  <div className='absolute top-0 flex items-center w-full p-6 border-b border-b-navy-25 bg-white'>
    <div className="min-w-11 h-11 radius flex items-center justify-center bg-navy-300">
      <IconEyeFilled className='w-7 h-7 fill-white' />
    </div>
    <div className="w-full ml-3 text-md font-medium color-navy-700">{t('View Contact Information')}</div>
    <Button
      className="ml-4 z-2"
      onClick={onClose}
      colorStyle='secondary'
      rounded
      startIcon={IconXmark}
    />
  </div>
);

export default HeaderRenderer;
