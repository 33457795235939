import {
  PaymentActions, PaymentProvider, PaymentSettingsEditor as PaymentSettingsEditorContainer
} from '@jotforminc/payment-settings-editor';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList } from '@jotforminc/tabs';
import SELECTORS from '../../../../../../store/selectors';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { MODALS } from '../../../../../../constants/modals';
import { UI_PROPS } from '../../../../../../constants';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';
import { RPTabButtonContainer, RPTabButtonWrapper } from '../../../RightPanelContainer';
import ContactInformationSettingsPanel from '../ContactInformationSettingsPanel';
import { RightPanelTabLists } from '../../../../constants/rightPanel';

const DonationPaymentProperties = () => {
  const dispatch = useDispatch();
  const isPaymentOverQuota = useSelector(SELECTORS.getIsPaymentOverQuota);
  const appID = useSelector(SELECTORS.getAppID);
  const checkoutFormID = useSelector(SELECTORS.getCheckoutFormIDSelector);
  const user = useSelector(SELECTORS.getUser);
  const currencyInfo = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);
  const isGatewaySettingsDisplayed = useSelector(SELECTORS.getUIProp(UI_PROPS.isGatewaySettingsDisplayed));
  const donationItemID = useSelector(SELECTORS.getDonationItemID);
  const checkoutFormQuestions = useSelector(SELECTORS.getCheckoutFormQuestions);
  const checkoutFormPaymentQuestion = useSelector(SELECTORS.getCheckoutFormPaymentQuestion);
  const { itemAvailable } = useSelector(SELECTORS.getItemAvailable(donationItemID));
  const appInfo = useSelector(SELECTORS.getPortalInfoSelector);

  const handlePaymentSettingsChange = useCallback((type, { productListSettingsItem }) => dispatch(ACTION_CREATORS.updatePortalPropAction(productListSettingsItem)), []);

  const handleGatewaySettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateGatewaySettings(settings)), []);

  const handleCheckoutFormQuestionUpdate = useCallback((id, prop) => dispatch(ACTION_CREATORS.updateCheckoutFormQuestion(id, prop)), []);

  const handleCheckoutFormSettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateCheckoutFormSettings(settings)), []);

  const sendActionTrackEvent = useCallback(actionType => {
    dispatch(ACTION_CREATORS.trackEventAction({ action: actionType }));
  }, [dispatch]);

  const handleAddPaymentIntegration = useCallback(() => {
    dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gateway')); // select-connectionSettings
    dispatch(ACTION_CREATORS.setPaymentModalFlowType('ELEMENT_TO_GATEWAY'));
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  const handleEditPaymentIntegration = useCallback(() => {
    dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gatewaySettings')); // select-connectionSettings
    dispatch(ACTION_CREATORS.setPaymentModalFlowType('EDIT_GATEWAY'));
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  const handleDeletePaymentIntegration = useCallback(() => {
    dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gateway')); // select-connectionSettings
    dispatch(ACTION_CREATORS.setPaymentModalFlowType('ELEMENT_TO_GATEWAY'));
    const data = {
      type: 'control_payment',
      currency: currencyInfo.currency
    };

    handleGatewaySettingsChange(data);
  }, []);

  const togglePaymentConnectionModal = useCallback(() => {
    dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
  }, []);

  useEffect(() => {
    if (isGatewaySettingsDisplayed) {
      dispatch(ACTION_CREATORS.setUIProp(UI_PROPS.isGatewaySettingsDisplayed, false));
    }
  }, []);

  return (
    <TabList
      list={RightPanelTabLists().DonationPaymentProperties}
      TabButtonContainer={RPTabButtonContainer}
      TabButton={RPTabButtonWrapper({ onClick: f => f, itemTabs: RightPanelTabLists().DonationPaymentProperties })}
    >
      <Tab id="paymentMethod">
        <PaymentProvider>
          <PaymentSettingsEditorContainer
            appName="appBuilder"
            appID={appID}
            initialPanel="paymentSettings"
            checkoutFormId={checkoutFormID}
            actions={PaymentActions}
            onGatewaySettingsChange={handleGatewaySettingsChange}
            sendActionTrackEvent={sendActionTrackEvent}
            isPaymentOverQuota={isPaymentOverQuota}
            isDonationItemAvailable={itemAvailable}
            user={user}
            questionProperties={currencyInfo}
            onPaymentSettingsChange={handlePaymentSettingsChange}
            paymentType="donation"
            isGatewaySettingsDisplayed={isGatewaySettingsDisplayed}
            questions={checkoutFormQuestions}
            activeGatewaySettings={checkoutFormPaymentQuestion}
            onCheckoutFormQuestionUpdate={handleCheckoutFormQuestionUpdate}
            onAddPaymentIntegrationBtnClick={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal) && handleAddPaymentIntegration}
            onEditPaymentIntegrationBtnClick={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal) && handleEditPaymentIntegration}
            onDeletePaymentIntegrationBtnClick={isFeatureEnabled(FEATURE_NAMES.NewPaymentModal) && handleDeletePaymentIntegration}
            isFeatureEnabled={isFeatureEnabled}
            FEATURE_NAMES={FEATURE_NAMES}
            togglePaymentConnectionModal={togglePaymentConnectionModal}
            checkoutFormPaymentQuestion={{ ...checkoutFormPaymentQuestion, ...currencyInfo, paymentType: 'donation' }}
            onSaveGateway={({ changedProps }) => {
              handleCheckoutFormSettingsChange({ ...changedProps });
            }}
            userIsResourceOwner={appInfo.username === user.username}
          />
        </PaymentProvider>
      </Tab>
      <Tab id="checkoutForm">
        <ContactInformationSettingsPanel />
      </Tab>
    </TabList>
  );
};

export default DonationPaymentProperties;
