import type { GATEWAY_LIST_SORTED_BY_COUNTRY } from '../types';

/**
 * Represents a list of gateways sorted by country.
 * @type {GATEWAY_LIST_SORTED_BY_COUNTRY}
 */
export const gatewayListSortedByCountry: GATEWAY_LIST_SORTED_BY_COUNTRY = {
  control_square: {
    default: 1,
    countries: {
      TR: 2,
      AU: 2,
      BR: 2,
      DE: 2,
      NL: 2,
      ZA: 1,
      US: 1,
      GB: 1
    }
  },
  control_paypalcomplete: {
    default: 2,
    countries: {
      TR: 3,
      AU: 3,
      BR: 3,
      DE: 3,
      NL: 3,
      ZA: 2,
      US: 2,
      GB: 2
    }
  },
  control_paypal: {
    default: 3,
    countries: {
      TR: 4,
      AU: 4,
      BR: 4,
      DE: 4,
      NL: 4,
      ZA: 3,
      US: 3,
      GB: 3
    }
  },
  control_paypalInvoicing: {
    default: 4,
    countries: {
      TR: 5,
      AU: 5,
      BR: 5,
      DE: 5,
      NL: 5,
      ZA: 4,
      US: 4,
      GB: 4
    }
  },
  control_authnet: {
    default: 5,
    countries: {
      TR: 6,
      AU: 6,
      BR: 6,
      DE: 6,
      NL: 6,
      ZA: 5,
      US: 5,
      GB: 5
    }
  },
  control_stripe: {
    default: 6,
    countries: {
      TR: 7,
      AU: 7,
      BR: 7,
      DE: 7,
      NL: 7,
      ZA: 6,
      US: 6,
      GB: 6
    }
  },
  control_stripeCheckout: {
    default: 7,
    countries: {
      TR: 8,
      AU: 8,
      BR: 8,
      DE: 8,
      NL: 8,
      ZA: 7,
      US: 7,
      GB: 7
    }
  },
  control_braintree: {
    default: 8,
    countries: {
      TR: 9,
      AU: 9,
      BR: 9,
      DE: 9,
      NL: 9,
      ZA: 9,
      US: 8,
      GB: 8
    }
  },
  control_paypalpro: {
    default: 9,
    countries: {
      TR: 10,
      AU: 10,
      BR: 10,
      DE: 10,
      NL: 10,
      ZA: 10,
      US: 9,
      GB: 9
    }
  },
  control_applePayGooglePay: {
    default: 10,
    countries: {
      TR: 11,
      AU: 11,
      BR: 11,
      DE: 11,
      NL: 11,
      ZA: 11,
      US: 11,
      GB: 10
    }
  },
  control_mollie: {
    default: 11,
    countries: {
      TR: 12,
      AU: 12,
      BR: 12,
      DE: 12,
      NL: 11,
      ZA: 12,
      US: 12,
      GB: 12
    }
  },
  control_cybersource: {
    default: 12,
    countries: {
      TR: 13,
      AU: 13,
      BR: 13,
      DE: 13,
      NL: 12,
      ZA: 13,
      US: 13,
      GB: 13
    }
  },
  control_echeck: {
    default: 13,
    countries: {
      TR: 14,
      AU: 14,
      BR: 14,
      DE: 14,
      NL: 13,
      ZA: 14,
      US: 14,
      GB: 14
    }
  },
  control_bluepay: {
    default: 14,
    countries: {
      TR: 15,
      AU: 15,
      BR: 15,
      DE: 15,
      NL: 14,
      ZA: 15,
      US: 15,
      GB: 15
    }
  },
  control_eway: {
    default: 15,
    countries: {
      TR: 16,
      AU: 15,
      BR: 16,
      DE: 16,
      NL: 15,
      ZA: 16,
      US: 16,
      GB: 16
    }
  },
  control_payu: {
    default: 16,
    countries: {
      TR: 17,
      AU: 16,
      BR: 17,
      DE: 17,
      NL: 16,
      ZA: 17,
      US: 17,
      GB: 17
    }
  },
  control_payuMoney: {
    default: 17,
    countries: {
      TR: 18,
      AU: 17,
      BR: 18,
      DE: 18,
      NL: 17,
      ZA: 18,
      US: 18,
      GB: 11
    }
  },
  control_worldpay: {
    default: 18,
    countries: {
      TR: 19,
      AU: 18,
      BR: 19,
      DE: 19,
      NL: 18,
      ZA: 19,
      US: 19,
      GB: 18
    }
  },
  control_bluesnap: {
    default: 19,
    countries: {
      TR: 20,
      AU: 19,
      BR: 20,
      DE: 20,
      NL: 19,
      ZA: 20,
      US: 20,
      GB: 19
    }
  },
  control_moneris: {
    default: 20,
    countries: {
      TR: 21,
      AU: 20,
      BR: 21,
      DE: 21,
      NL: 20,
      ZA: 21,
      US: 21,
      GB: 20
    }
  },
  control_stripeACHManual: {
    default: 21,
    countries: {
      TR: 22,
      AU: 21,
      BR: 22,
      DE: 22,
      NL: 21,
      ZA: 22,
      US: 22,
      GB: 21
    }
  },
  control_stripeACH: {
    default: 22,
    countries: {
      TR: 23,
      AU: 22,
      BR: 23,
      DE: 23,
      NL: 22,
      ZA: 23,
      US: 23,
      GB: 22
    }
  },
  control_gocardless: {
    default: 23,
    countries: {
      TR: 24,
      AU: 23,
      BR: 24,
      DE: 24,
      NL: 23,
      ZA: 23,
      US: 24,
      GB: 23
    }
  },
  control_payfast: {
    default: 24,
    countries: {
      TR: 25,
      AU: 24,
      BR: 25,
      DE: 25,
      NL: 24,
      ZA: 8,
      US: 25,
      GB: 24
    }
  },
  control_venmo: {
    default: 25,
    countries: {
      TR: 26,
      AU: 25,
      BR: 26,
      DE: 26,
      NL: 25,
      ZA: 24,
      US: 10,
      GB: 25
    }
  },
  control_cashApp: {
    default: 26,
    countries: {
      TR: 27,
      AU: 26,
      BR: 27,
      DE: 27,
      NL: 26,
      ZA: 25,
      US: 26,
      GB: 26
    }
  },
  control_sofort: {
    default: 27,
    countries: {
      TR: 28,
      AU: 27,
      BR: 28,
      DE: 27,
      NL: 27,
      ZA: 26,
      US: 27,
      GB: 27
    }
  },
  control_pagseguro: {
    default: 28,
    countries: {
      TR: 29,
      AU: 28,
      BR: 28,
      DE: 28,
      NL: 28,
      ZA: 27,
      US: 28,
      GB: 28
    }
  },
  control_payment: {
    default: 29,
    countries: {
      TR: 30,
      AU: 29,
      BR: 29,
      DE: 29,
      NL: 29,
      ZA: 29,
      US: 29,
      GB: 29
    }
  },
  control_firstdata: {
    default: 30,
    countries: {
      TR: 31,
      AU: 30,
      BR: 30,
      DE: 30,
      NL: 30,
      ZA: 30,
      US: 30,
      GB: 30
    }
  },
  control_cardconnect: {
    default: 31,
    countries: {
      TR: 32,
      AU: 31,
      BR: 31,
      DE: 31,
      NL: 31,
      ZA: 31,
      US: 31,
      GB: 31
    }
  },
  control_worldpayus: {
    default: 32,
    countries: {
      TR: 33,
      AU: 32,
      BR: 32,
      DE: 32,
      NL: 32,
      ZA: 32,
      US: 32,
      GB: 32
    }
  },
  control_2co: {
    default: 33,
    countries: {
      TR: 34,
      AU: 33,
      BR: 33,
      DE: 33,
      NL: 33,
      ZA: 33,
      US: 33,
      GB: 33
    }
  },
  control_paysafe: {
    default: 34,
    countries: {
      TR: 35,
      AU: 34,
      BR: 34,
      DE: 34,
      NL: 34,
      ZA: 34,
      US: 34,
      GB: 34
    }
  },
  control_iyzico: {
    default: 35,
    countries: {
      TR: 1,
      AU: 35,
      BR: 35,
      DE: 35,
      NL: 35,
      ZA: 35,
      US: 35,
      GB: 35
    }
  },
  control_skrill: {
    default: 36,
    countries: {
      TR: 36,
      AU: 36,
      BR: 36,
      DE: 36,
      NL: 36,
      ZA: 36,
      US: 36,
      GB: 36
    }
  },
  control_chargify: {
    default: 37,
    countries: {
      TR: 37,
      AU: 37,
      BR: 37,
      DE: 37,
      NL: 37,
      ZA: 37,
      US: 37,
      GB: 37
    }
  },
  control_payjunction: {
    default: 38,
    countries: {
      TR: 38,
      AU: 38,
      BR: 38,
      DE: 38,
      NL: 38,
      ZA: 38,
      US: 38,
      GB: 38
    }
  },
  control_paypalSPB: {
    default: 39,
    countries: {
      TR: 39,
      AU: 39,
      BR: 39,
      DE: 39,
      NL: 39,
      ZA: 39,
      US: 39,
      GB: 39
    }
  },
  control_paymentwall: {
    default: 40,
    countries: {
      TR: 40,
      AU: 40,
      BR: 40,
      DE: 40,
      NL: 40,
      ZA: 40,
      US: 40,
      GB: 40
    }
  },
  control_paypalexpress: {
    default: 41,
    countries: {
      TR: 41,
      AU: 41,
      BR: 41,
      DE: 41,
      NL: 41,
      ZA: 41,
      US: 41,
      GB: 41
    }
  },
  control_sensepass: {
    default: 42,
    countries: {
      TR: 42,
      AU: 42,
      BR: 42,
      DE: 42,
      NL: 42,
      ZA: 42,
      US: 42,
      GB: 42
    }
  },
  control_clickbank: {
    default: 43,
    countries: {
      TR: 43,
      AU: 43,
      BR: 43,
      DE: 43,
      NL: 43,
      ZA: 43,
      US: 43,
      GB: 43
    }
  },
  control_onebip: {
    default: 44,
    countries: {
      TR: 44,
      AU: 44,
      BR: 44,
      DE: 44,
      NL: 44,
      ZA: 44,
      US: 44,
      GB: 44
    }
  },
  control_afterpay: {
    default: 45,
    countries: {
      TR: 45,
      AU: 45,
      BR: 45,
      DE: 45,
      NL: 45,
      ZA: 45,
      US: 45,
      GB: 45
    }
  },
  control_clearpay: {
    default: 46,
    countries: {
      TR: 46,
      AU: 46,
      BR: 46,
      DE: 46,
      NL: 46,
      ZA: 46,
      US: 46,
      GB: 46
    }
  }
};
