import { bool, elementType, string } from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { Hooks } from '@jotforminc/uikit';
import NoImage from './NoImage';

const Image = ({
  imageURL, altText, showAltTextActiveOnError, PlaceholderRenderer
}) => {
  const [useFallback, setUseFallback] = useState(false);
  const handleBrokenImage = useCallback(() => setUseFallback(true), []);

  Hooks.useEffectIgnoreFirst(() => {
    setUseFallback(false); // Try again if imageURL corrected
  }, [imageURL]);

  return useFallback
    ? <NoImage altText={altText} showAltText={showAltTextActiveOnError} {...(!imageURL && { PlaceholderRenderer })} />
    : (
      <img
        src={imageURL}
        alt={altText}
        onError={handleBrokenImage}
        className="imageElementImg"
      />
    );
};

Image.propTypes = {
  imageURL: string,
  altText: string,
  showAltTextActiveOnError: bool,
  PlaceholderRenderer: elementType
};

Image.defaultProps = {
  imageURL: '',
  altText: '',
  showAltTextActiveOnError: true,
  PlaceholderRenderer: undefined
};

export default memo(Image);
