import React, { useCallback } from 'react';
import Toggle from '@jotforminc/toggle';

const PwToggle = ({
  id,
  className,
  onChange,
  isSelected,
  readOnly,
  valueTrue,
  valueFalse,
  isSquareMode,
  offText,
  onText
}: IProps): JSX.Element => {
  const onClickHandler = useCallback(() => {
    onChange(isSelected ? valueFalse : valueTrue);
  }, [isSelected, onChange, valueTrue, valueFalse]);

  return (
    <div className="mt-3 ml-0 pointer-events-auto sm:ml-2 sm:mt-0">
      <Toggle
        id={id}
        className={className}
        onClick={onClickHandler}
        isSelected={isSelected}
        readOnly={readOnly}
        squareMode={isSquareMode}
        offText={offText}
        onText={onText}
      />
    </div>
  );
};

export interface IProps {
  id?: string;
  className?: string;
  isSelected?: boolean;
  readOnly?: boolean;
  onChange: (value: boolean | string) => void;
  valueTrue: boolean | string;
  valueFalse: boolean | string;
  isSquareMode?: boolean;
  offText: string;
  onText: string;
}

PwToggle.defaultProps = {
  id: '',
  className: '',
  isSelected: false,
  readOnly: false,
  isSquareMode: true,
  valueTrue: 'Yes',
  valueFalse: 'No'
};

export default PwToggle;
