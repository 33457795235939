/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import React from 'react';
import { IconInputTextShort } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';

export const DEFAULT_OPTIONS = {
  NONE: { label: t('None'), value: 'none' },
  CUSTOM_TEXT: {
    icon: () => <IconInputTextShort height={20} width={20} />,
    label: t('Custom Text'),
    value: 'text',
    limit: 25
  }
};

const DEFAULT_MAP = {
  isNoneAvailable: DEFAULT_OPTIONS.NONE,
  isCustomTextAvailable: DEFAULT_OPTIONS.CUSTOM_TEXT
};

export const generateDefaultSection = defaults => {
  const options = [];
  Object.entries(DEFAULT_MAP).forEach(([key, val]) => {
    if (defaults[key]) {
      options.push({ ...val, ...(typeof defaults[key] === 'object' ? defaults[key] : {}) });
    }
  });

  return { description: '', options };
};

export const findOption = (sections, optionValue) => {
  const relatedSection = sections.find(section => section.options.some(option => option.value === optionValue));
  return relatedSection?.options.find(option => option.value === optionValue);
};
