import React from 'react';
import { t } from '@jotforminc/translation';

export const ELEMENT_SIZE_OPTION_TESTIDS = [ // Indices are important
  'normal',
  'large'
];

const ELEMENT_SIZES = {
  normal: {
    Renderer: () => (
      <div className="textAlignmentItem" data-testid={ELEMENT_SIZE_OPTION_TESTIDS[0]}>
        <span>{t('Normal')}</span>
      </div>
    )
  },
  large: {
    Renderer: () => (
      <div className="textAlignmentItem" data-testid={ELEMENT_SIZE_OPTION_TESTIDS[1]}>
        <span>{t('Large')}</span>
      </div>
    )
  }
};

export const ELEMENT_SIZE_RENDERER_ARRAY = Object.entries(ELEMENT_SIZES)
  .map(([key, { Renderer }]) => ({
    value: key,
    Renderer
  }));
