import React, { useMemo, useState } from 'react';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/uikit';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import PaymentProperties from '../constants/PaymentProperties';
import { TGateway, IGatewayProperties, TApm } from '../types/common';
import * as actionCreators from '../store/actionCreators';
import { GATEWAYS_WITH_APM } from '../constants/variables';
import { isAdmin, isHipaa } from '../utils/general';

const testGatewayList = ['control_payu', 'control_sensepass'];
const hipaaBlockGatewayList = ['control_payu'];

const GatewayPicker = ({
  paymentPropertiesList,
  onGatewayListItemClicked,
  activeGatewaySettings: {
    type,
    nameAPM
  }
} : IProps): JSX.Element => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredList = useMemo(() => {
    const filteredBySearchQuery = paymentPropertiesList.filter(([, { name }]) => name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
    return searchQuery.length === 0 ? paymentPropertiesList : filteredBySearchQuery;
  }, [searchQuery]);

  return (
    <>
      <div className="gatewayPickerView">
        <div className="searchArea">
          <input
            type="search"
            onChange={(e: React.ChangeEvent<EventTarget>) => setSearchQuery((e.target as HTMLInputElement).value)}
            placeholder={t('Search Gateways')}
          />
        </div>

        <div className="gatewayList">
          {filteredList.map(([gateway, properties]) => {
            let isSelected = type === gateway && !nameAPM;
            if (nameAPM && gateway === nameAPM) {
              isSelected = GATEWAYS_WITH_APM[nameAPM].length > 1 ? GATEWAYS_WITH_APM[nameAPM].find(item => item.type === type) : type === PaymentProperties[nameAPM].type;
            }
            if (
              (isAdmin() && testGatewayList.includes(gateway))
              || (isHipaa() && !hipaaBlockGatewayList.includes(gateway))
              || (!testGatewayList.includes(gateway) && !isHipaa())
            ) {
              return (
                <Button
                  onClick={() => {
                    if (isSelected) {
                      dispatch(actionCreators.changeGatewaySettingsView('gateway-settings'));
                    } else {
                      onGatewayListItemClicked(gateway);
                    }
                  }} className={classNames({
                    gatewayListItem: true,
                    selected: isSelected
                  })} id={`gateway_${gateway}`}
                  key={`gateway_${gateway}`}
                >
                  <div className="details">
                    <img src={properties.icon} alt="" />
                    <b>{properties.name}</b>
                    <span className="description" />
                  </div>
                  {/* {gateway === 'control_stripe' && (
                    <span className="overlay">{t('Selected currency isn\'t supported')}</span>
                  )} */}
                </Button>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
};

interface IProps {
  onGatewayListItemClicked: (gateway: TGateway) => void,
  paymentPropertiesList: [TGateway, IGatewayProperties],
  activeGatewaySettings: IActiveGatewaySettings
}
interface IActiveGatewaySettings {
  type: TGateway
  nameAPM: TApm
}

export default GatewayPicker;
