import { AB_TEST_MANAGERS, AB_TESTS } from './abtests';

export default class AbTestsSingleton {
  managers = {};

  loggers = {};

  static user = {};

  static instance = null;

  constructor() {
    if (!AbTestsSingleton.instance) {
      AbTestsSingleton.instance = this;
    }

    return AbTestsSingleton.instance;
  }

  getManagerFor(name, user = null) {
    if (!AB_TEST_MANAGERS[name]) {
      // eslint-disable-next-line no-console
      console.warn(`A/B Test ${name} does not exist`);
      return null;
    }

    if (!this.managers[name]) {
      this.managers[name] = AB_TEST_MANAGERS[name](user || AbTestsSingleton.user);
    }

    return this.managers[name];
  }

  getLoggerFor(name) {
    if (!this.loggers[name]) {
      this.loggers[name] = this.getManagerFor(name)?.registerABTestAction;
    }

    return this.loggers[name] || (() => {});
  }
}

export const getAbTestManagerFor = (name, user = null) => new AbTestsSingleton().getManagerFor(name, user);
export const logAbTestActionFor = (name, params) => new AbTestsSingleton().getLoggerFor(name)(params);

export { AB_TESTS };
