import React from 'react';
import { take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Notification from '../../components/Toaster/Notification';
import UndoNotification from '../../components/Toaster/UndoNotification';
import { TOAST } from '../actionTypes';

const toastIt = ({ payload, type }) => {
  const { backdrop, options: _options } = payload;

  let options = { autoClose: 3e3, ..._options };

  if (backdrop) {
    options = {
      autoClose: false,
      closeOnClick: false,
      draggable: false
    };
  }

  let NotificationComponent;

  switch (type) {
    case TOAST.UNDO_NOTIFICATION:
      NotificationComponent = UndoNotification;
      break;
    default:
      NotificationComponent = Notification;
      break;
  }

  toast(<NotificationComponent {...payload} />, options);
};

export function* watchToastActions() {
  while (true) {
    const action = yield take(({ type }) => type.startsWith('@TOAST'));
    toastIt(action);
  }
}
