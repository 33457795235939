import React from 'react';
import classNames from 'classnames';
import { IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import {
  bool,
  func,
  node,
  string
} from 'prop-types';
import './styles/style.scss';

const WarningBox = ({
  colorStyle,
  size,
  Icon,
  fullWidth,
  isLongText,
  children,
  className,
  wrapperClassName,
  showAnimate
}) => {
  return (
    <div className={classNames([{ 'anim anim-700 fade-in': showAnimate, 'w-full': fullWidth }, 'bg-white', 'radius mt-2', wrapperClassName])}>
      <div className={classNames([{
        'warningBox color-yellow-600': colorStyle === 'warning',
        'errorBox color-red-400': colorStyle === 'error',
        'infoBox color-blue-500': colorStyle === 'info',
        'successBox color-green-500': colorStyle === 'success',
        'min-h-8 h-auto sm:min-h-auto sm:h-8 text-xs smallWarningBox': (size === 'small' && !isLongText),
        'min-h-8 h-auto text-xs smallWarningBox': (size === 'small' && isLongText),
        'min-h-10 h-auto sm:min-h-auto sm:h-10 text-sm': (size === 'medium' && !isLongText),
        'min-h-10 h-auto text-sm': (size === 'medium' && isLongText),
        'w-full': fullWidth,
        'anim anim-700 fade-in': showAnimate
      }, 'warningBoxContainer flex justify-start items-center gap-2 p-2 font-medium line-height-xs radius overflow-hidden ease-in-out duration-300', className])}
      >
        <div>
          <Icon
            className={classNames([{
              'fill-yellow-600': colorStyle === 'warning',
              'fill-red-400': colorStyle === 'error',
              'fill-blue-500': colorStyle === 'info',
              'fill-green-500': colorStyle === 'success',
              'w-4 h-4': size === 'small',
              'w-5 h-5': size === 'medium'
            },
            'flex items-center justify-center'])}
          />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

WarningBox.propTypes = {
  colorStyle: string,
  size: string,
  Icon: func,
  fullWidth: bool,
  isLongText: bool,
  children: node,
  className: string,
  wrapperClassName: string,
  showAnimate: bool
};

WarningBox.defaultProps = {
  colorStyle: 'warning',
  size: 'small',
  Icon: IconExclamationCircleFilled,
  fullWidth: true,
  isLongText: false,
  children: null,
  className: '',
  wrapperClassName: '',
  showAnimate: true
};

export default WarningBox;
