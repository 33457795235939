import React from 'react';
import { Dropdown as UIKitDropdown } from '@jotforminc/uikit';
import Styled from 'styled-components';

const DropdownWr = Styled.div`
  font-size: 1em;
  color: #2c3345;
  line-height: 1;
  display: flex;

  button {
    font-family: ${p => p.theme.fontFamily};
    border: 1px solid #d8dae9;
    border-radius: ${p => p.theme.buttonRadius};
    background: transparent;
    padding: .75em 1em;
    color: currentColor;
    ${p => p.theme.defaultTransition}

    &:focus { border-color: #81829e; outline: 0; }
  }

  ul {
    background: #fff;
    border: 1px solid #d8dae9;
    border-radius: ${p => p.theme.buttonRadius};
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.16);
    padding: .5em 0;
  }

  li { cursor: pointer; ${p => p.theme.defaultTransition} }
  li > * { padding: .5em 1em; }
  li + li { border-top: 1px solid #eaebf2; }

  li[data-selected="true"],
  li:hover { background-color: #f1f1f1; }
`;

export const Dropdown = props => {
  return <DropdownWr><UIKitDropdown {...props} /></DropdownWr>;
};
