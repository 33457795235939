import React, { useEffect, useState } from 'react';
import {
  Button, ModalActions, ModalBody, ModalHeader
} from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { useDispatch } from 'react-redux';
import { stepPropTypeDefaults, stepPropTypes } from '../constants';
import TableSelectionDefaults from '../common/TableSelectionDefaults';
import SelectionDnd from './SelectionDnd';
import { fetchColumns } from '../../../../../modules/api';
import { COLUMN_IDS } from './SelectionDnd/constants';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import { Loading } from '../../../../Loading';

const ColumnSelection = props => {
  const {
    sharedState: { resourceID, areColumnsLoading, viewID }, setSharedState, itemID, onPropChange, onClose
  } = props;

  const dispatch = useDispatch();
  const [cols, setCols] = useState({
    [COLUMN_IDS.SELECTION_COLUMN]: {
      id: COLUMN_IDS.SELECTION_COLUMN,
      items: []
    },
    [COLUMN_IDS.SELECTEDS_COLUMN]: {
      id: COLUMN_IDS.SELECTEDS_COLUMN,
      items: []
    }
  });

  useEffect(() => {
    if (!resourceID) {
      return;
    }

    const getColumns = async () => {
      setSharedState(prev => ({
        ...prev,
        areColumnsLoading: true
      }));

      try {
        const { columns: resourceColumns = [] } = await fetchColumns({ resourceID, viewID }) ?? [];
        setCols(
          {
            [COLUMN_IDS.SELECTION_COLUMN]: {
              id: COLUMN_IDS.SELECTION_COLUMN,
              items: resourceColumns
            },
            [COLUMN_IDS.SELECTEDS_COLUMN]: {
              id: COLUMN_IDS.SELECTEDS_COLUMN,
              items: []
            }
          }
        );
      } catch (error) {
        console.error('Error fetching table views:', error);
      } finally {
        setSharedState(prev => ({
          ...prev,
          areColumnsLoading: false
        }));
      }
    };

    getColumns();
  }, [resourceID]);

  const onClickApplyChanges = () => {
    onPropChange({ resourceID, viewID }, { bypassDebounce: true });
    dispatch(ACTION_CREATORS.dsFetchColumnsRequest({ resourceID, viewID }));
    dispatch(ACTION_CREATORS.createAndLinkDetailsPage({
      itemID, columns: cols[COLUMN_IDS.SELECTEDS_COLUMN].items, resourceID, viewID
    }));
    onClose();
  };

  return (
    <>
      <ModalHeader
        title={t('Detail Page of List Element')}
        description={t('Select the columns you want to show on the detail page of list element.')}
        onClose={onClose}
      />
      <ModalBody>
        {areColumnsLoading ? <Loading />
          : (
            <SelectionDnd
              columns={cols}
              setColumns={setCols}
            />
          )}
      </ModalBody>
      <ModalActions>
        <TableSelectionDefaults.BackButton {...props} />
        <Button
          type='button'
          title={t('Apply Changes')}
          colorStyle='success'
          onClick={onClickApplyChanges}
        >
          {t('Apply Changes')}
        </Button>
      </ModalActions>
    </>
  );
};

ColumnSelection.propTypes = {
  ...stepPropTypes
};

ColumnSelection.defaultProps = {
  ...stepPropTypeDefaults
};

export default ColumnSelection;
