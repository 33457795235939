import React, {
  useRef,
  useState,
  useEffect,
  useMemo
} from 'react';
import { Button } from '@jotforminc/magnet';
import { Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import WarningBox from '@jotforminc/warning-box';
import { IconPencilFilled, IconCheckCircleFilled } from '@jotforminc/svg-icons';
import useGatewayFlows from '../modals/Flows/Context/useGatewayFlows';

const Credential = ({
  id,
  defaultValue
}: IProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [prevValue, setPrevValue] = useState(defaultValue);
  const [value, setValue] = useState(defaultValue);
  const [isSaved, setIsSaved] = useState(false);
  const [editMode, setEditMode] = Hooks.useClickOutsideState(false, [wrapperRef]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const { onSaveGatewayCredential, user, portalOwner } = useGatewayFlows();

  const isAppOwner = useMemo(() => user.username === portalOwner, [user.username, portalOwner]);
  const isCollaborator = !!(window.location.href.match(/iak=([\d | \w | -]*)/) ?? [])[1];
  const isEditPermission = isAppOwner || isCollaborator;

  const isValid = (val: string) => {
    if (val === '') {
      setErrorMessage('Value cannot be empty.');
      return false;
    }

    const lastTenCharacters = val.slice(-10);
    const asteriskCount = (lastTenCharacters.match(/\*/g) || []).length;
    if (asteriskCount === 10) {
      setErrorMessage('Invalid value.');
      return false;
    }

    return true;
  };

  useEffect(() => {
    const warningboxSuccess = document.querySelector('.credential-warningbox-success');
    if (isSaved) {
      warningboxSuccess?.classList.remove('hide');
      warningboxSuccess?.classList.add('show');
    } else {
      warningboxSuccess?.classList.remove('show');
      warningboxSuccess?.classList.add('hide');
    }

    let timeoutId: number;
    if (isSaved) { timeoutId = setTimeout(() => { setIsSaved(false); }, 3000); }

    return () => { if (timeoutId) { clearTimeout(timeoutId); } };
  }, [isSaved]);

  useEffect(() => {
    if (!editMode && !isSaved) {
      setValue(prevValue);
    }
  }, [editMode]);

  return (
    <>
      <div ref={wrapperRef} className='flex flex-row items-end justify-between'>
        <div className='w-11/12 mr-2'>
          <input
            id={id}
            type='text'
            ref={textInputRef}
            // eslint-disable-next-line max-len
            className={`bg-${!editMode || !isEditPermission ? 'gray-25' : 'white'} color-navy-700 border text-md min-w-44 p-2 text-left border-navy-100 mt-3 hover:border-navy-200 hover:border-opacity-80 radius focus:outline focus:outline-blue-200 sm:mt-0 h-10 w-full`}
            value={value}
            readOnly={!editMode || !isEditPermission}
            onChange={e => setValue(e.target.value)}
          />
        </div>
        {isEditPermission && (
          <Button
            id={`save_btn_${id}`}
            startIcon={editMode ? IconCheckCircleFilled : IconPencilFilled}
            colorStyle={editMode ? 'success' : 'primary'}
            aria-label={t('Save Credential')}
            onClick={() => {
              if (editMode) { // save credentials
                if (!isValid(value)) { return; }
                onSaveGatewayCredential({ [id]: value });
                setPrevValue(value);
                setIsSaved(true);
              } else if (textInputRef.current) { // open edit mode
                textInputRef.current.focus();
                setValue('');
                setIsSaved(false);
              }
              setErrorMessage('');
              setEditMode(!editMode);
            }}
          />
        )}
      </div>
      {
        (errorMessage && editMode) && (
          <WarningBox>
            {t(errorMessage)}
          </WarningBox>
        )
      }
      {
        isSaved && (
          <div className="anim anim-700 fade-in w-full bg-transparent radius mt-2">
            {/* eslint-disable-next-line */}
            <div className="color-green-500 min-h-8 h-auto sm:min-h-auto sm:h-8 text-sm smallWarningBox w-full anim anim-700 fade-in warningBoxContainer flex justify-start items-center gap-2 p-2 font-medium line-height-xs radius overflow-hidden ease-in-out duration-300">
              <IconCheckCircleFilled className="fill-green-500 w-4 h-4 flex items-center justify-center" />
              <div>{t('Credential is successfully saved.')}</div>
            </div>
          </div>
        )
      }
    </>
  );
};

export interface IProps {
  id: string
  defaultValue: string
}

export default Credential;
