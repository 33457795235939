import React, {
  ReactNode, FC, useCallback, MouseEvent
} from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jotforminc/magnet';
import { arrayMove, closest, generateUniqueID } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import {
  IconPlus, IconCheck, IconPencilFilled, IconTrashFilled, IconGridDotsVertical
} from '@jotforminc/svg-icons';
import * as actionCreators from '../../store/actionCreators';
import { SortableDragHandle, getActiveProduct, getQuantityData } from '../../utils';
import Selectors from '../../store/selectors';
import { stringToArray } from '../../utils/general';
import { updateProduct } from '../../api';
import { TActiveEditor, ICustomOption, IQuantityOption } from '../../types/common';
import { checkMobileOrTablet } from '../../utils/domUtils';

const SortElement = SortableElement(({ children }: { children: ReactNode }) => (
  <div className="optionsProductEditor-optionList-item productItem productSortableItem">{children}</div>
));

const SortContainer = SortableContainer(({ children }: { children: ReactNode }) => (
  <div className="optionsProductEditor-optionList">{children}</div>
));

const setActiveEditor = (editorName: TActiveEditor, dispatch: Dispatch): void => {
  dispatch(actionCreators.changeActiveEditor(editorName));
};

const OptionsProductEditor: FC = () => {
  const dispatch = useDispatch();
  const activeProduct = useSelector(Selectors.getActiveProduct);
  const productOptions = getActiveProduct(activeProduct);
  const formId = useSelector(Selectors.getFormId);

  const handleShouldSortingStart = useCallback((event: MouseEvent<HTMLButtonElement>): boolean => (
    !!closest(event.target as HTMLButtonElement, '.productItemActions', '.productItem')
  ), []);

  const handleUpdate = (newOption: (ICustomOption | IQuantityOption)[]): void => {
    updateProduct(formId, activeProduct.pid, { options: JSON.stringify(newOption) });
    dispatch(actionCreators.updateActiveProduct({ options: newOption }));
    dispatch(actionCreators.updateProduct({ options: newOption }, activeProduct.pid));
  };

  const handleAddQuantitySelector = () : void => {
    const newOption = getQuantityData(productOptions, null);
    handleUpdate(newOption);
  };

  const isQuantitySelectorDisabled = productOptions.filter(opt => opt.type === 'quantity').length > 0;

  const isMobileOrTablet = checkMobileOrTablet();

  return (
    <div className="optionsProductEditor">
      {/* TODO: Implement FormWizard */}
      <div className="optionsProductEditor-option">
        <div className="optionsProductEditor-option-label">
          <div className="row-title">{t('Add a Quantity Selector')}</div>
          <div className="row-description optionsProductEditor-option-description">{t('Allows multiple quantity purchase')}</div>
        </div>
        <Button
          onClick={() => { setActiveEditor('productQuantitySelectorTabEditor', dispatch); handleAddQuantitySelector(); }}
          disabled={isQuantitySelectorDisabled}
          startIcon={isQuantitySelectorDisabled ? IconCheck : IconPlus}
        >
          {t(isQuantitySelectorDisabled ? 'Added' : 'Add')}
        </Button>
      </div>
      <hr className="lineHr" />
      <div className="optionsProductEditor-option">
        <div className="optionsProductEditor-option-label">
          <div className="row-title">{t('Add a Product Option')}</div>
          <div className="row-description optionsProductEditor-option-description">{t('Create options like shoe sizes')}</div>
        </div>
        <Button
          className='text-capitalize'
          onClick={() => { setActiveEditor('productOptionCreatorTabEditor', dispatch); }}
          startIcon={IconPlus}
        >
          {t('Add')}
        </Button>
      </div>
      <hr className="lineHr" />
      {productOptions.length > 0 && (
        <SortContainer
          helperClass="onDrag"
          onSortEnd={({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
            handleUpdate(arrayMove(productOptions, oldIndex, newIndex));
          }}
          lockAxis="y"
          shouldCancelStart={handleShouldSortingStart}
          useDragHandle={isMobileOrTablet}
        >
          {productOptions.map((opt, i) => (
            <SortElement
              index={i}
              key={generateUniqueID()}
            >
              {isMobileOrTablet && (
                <SortableDragHandle>
                  <div>
                    <IconGridDotsVertical fill="#E9ECFF" className="w-6 absolute -left-6 top-2/4 transform -translate-y-2/4 cursor-move" />
                  </div>
                </SortableDragHandle>
              )}
              <div className={`${isMobileOrTablet && 'hidden'}`}>
                <IconGridDotsVertical fill="#E9ECFF" className="w-6 absolute -left-6 top-2/4 transform -translate-y-2/4 cursor-move" />
              </div>
              <div className="productItemContent">
                <div>
                  {opt.name && <div className="item-name">{opt.name}</div>}
                  <div className="item-price">
                    {`${stringToArray(opt.properties).length} ${t('Options')}`}
                  </div>
                </div>
              </div>
              <div className="productItemActions">
                <Button
                  className='text-capitalize'
                  onClick={() => {
                    dispatch(actionCreators.changeSelectedOption(i));
                    setActiveEditor(opt.type === 'custom' ? 'productOptionCreatorTabEditor' : 'productQuantitySelectorTabEditor', dispatch);
                  }}
                  startIcon={IconPencilFilled}
                  size='small'
                />
                <Button
                  onClick={() => { handleUpdate(productOptions.filter((_, index) => i !== index)); }}
                  startIcon={IconTrashFilled}
                  size='small'
                />
              </div>
            </SortElement>
          ))}
        </SortContainer>
      )}
    </div>
  );
};

export default OptionsProductEditor;
