import { StorageHelper } from '@jotforminc/storage-helper';
import {
  adminOnlyFeatures,
  defaultFeatures,
  ignoreList,
  localStorageEnabledFeatures,
  sessionStorageEnabledFeatures
} from '../../constants/features';

export default class FeaturesSingleton {
  href = '';

  enabledFeatures = {};

  constructor() {
    if (!FeaturesSingleton.instance) {
      // calculate features
      this.enabledFeatures = this.getEnabledFeatures();
      FeaturesSingleton.instance = this;
    }

    return FeaturesSingleton.instance;
  }

  getEnabledFeatures() {
    if (this.href === window.location.href) {
      return this.enabledFeatures;
    }
    this.href = window.location.href;
    const isAdminOnlyFeatureEnabled = window.isAdminUser || ['marketing.jotform.com'].includes(window.location.hostname);

    const isInternRDS = this.href.includes('.jotform.dev');
    // // Enable ALL
    if (!isInternRDS && this.href.includes('useNewBuilder=1')) {
      this.enabledFeatures = Object.keys(defaultFeatures).reduce((prev, key) => {
        if (ignoreList.includes(key)) return { ...prev };
        const isAdminOnlyFeature = adminOnlyFeatures.includes(key);
        const isAllowed = isAdminOnlyFeature ? isAdminOnlyFeatureEnabled : true;
        return { ...prev, [key]: isAllowed };
      }, {});

      return this.enabledFeatures;
    }

    // Enable? by query param(s)
    this.enabledFeatures = Object.entries(defaultFeatures).reduce((prev, [key, val]) => {
      const enabledByURL = window.location.href.includes(`use${key}=1`);
      const enabledBySS = sessionStorageEnabledFeatures.includes(key) && StorageHelper.getItem({ key: `use${key}`, type: 'session' });
      const enabledByLSC = localStorageEnabledFeatures.includes(key) && StorageHelper.getItem({ key: `use${key}`, type: 'local' });

      if (sessionStorageEnabledFeatures.includes(key) && enabledByURL) {
        StorageHelper.setItem({ key: `use${key}`, value: true, type: 'session' });
      }

      const isAdminOnlyFeature = adminOnlyFeatures.includes(key);
      return { ...prev, [key]: (isAdminOnlyFeature && isAdminOnlyFeatureEnabled) || (val || enabledByURL || !!enabledBySS || !!enabledByLSC) };
    }, {});

    return this.enabledFeatures;
  }
}
