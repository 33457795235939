import React, { useCallback } from 'react';
import { t } from '@jotforminc/translation';
import { ImageUploadWithViewer } from '@jotforminc/image-upload';
import { DEFAULT_PRODUCT_DATA } from '../../constants/variables';

type ImageInputProps = {
  handleChange: (key: string, value: string) => void,
  images: string[]
}

const ImagesInput = ({ handleChange, images = JSON.parse(DEFAULT_PRODUCT_DATA.images) } : ImageInputProps): JSX.Element => {
  const onImageChange = useCallback((imgArr: string[]) => {
    const updatedImgArr = (imgArr.length > images.length) ? imgArr.filter(img => !JSON.parse(DEFAULT_PRODUCT_DATA.images).includes(img)) : imgArr;
    handleChange('images', JSON.stringify(updatedImgArr));
  }, [handleChange]);

  return (
    <div className="productEditorInputGroup">
      <label htmlFor="#">{t('Images')}</label>
      <ImageUploadWithViewer
        isSortable={true}
        onChange={onImageChange}
        defaultValue={images}
        buttonText={t('Choose Images')}
      />
    </div>
  );
};

export default ImagesInput;
