import { CARD_ACTION_VALUES } from '../../../modules/Builder/components/HomePage/RightPanel/constants';
import ActionableItem from '../ActionableItem';
import { imageActions } from './actions';

export default class Image extends ActionableItem {
  constructor(defaults = {}, actions = {}) {
    super(defaults, actions);
    this.ACTIONS = imageActions;
    this.DEFAULT_TEXTS = {
      [CARD_ACTION_VALUES.LINK]: 'Go to Link',
      [CARD_ACTION_VALUES.MAIL]: 'Email Us',
      [CARD_ACTION_VALUES.PHONE]: 'Call Us',
      [CARD_ACTION_VALUES.NAVIGATION]: 'Go to Page',
      [CARD_ACTION_VALUES.FORM]: 'Open Form',
      [CARD_ACTION_VALUES.NONE]: this.defaults.title || ''
    };
  }

  isTitleDefault(title) {
    return this.defaults.title === title;
  }

  get defaultTexts() {
    return this.DEFAULT_TEXTS;
  }
}
