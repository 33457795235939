import React from 'react';
import { AlertLabelTypes } from '@jotforminc/constants';
import { t } from '@jotforminc/translation';
import { string, node, oneOf } from 'prop-types';
import { IconInfoCircleFilled, IconExclamationCircleFilled, IconCheckCircleFilled } from '@jotforminc/svg-icons';

const getLabelClassNames = messageType => {
  const classNames = ['formWizard-label', 'isMessage'];
  switch (messageType) {
    case AlertLabelTypes.INFO:
      classNames.push('isInfo');
      break;
    case AlertLabelTypes.WARNING:
      classNames.push('isWarning');
      break;
    case AlertLabelTypes.SUCCESS:
      classNames.push('isSuccess');
      break;
    default:
      break;
  }
  return classNames.join(' ');
};

const AlertLabelWithIcon = ({
  children, htmlFor, label, description, messageType
}) => {
  const iconMap = {
    [AlertLabelTypes.WARNING]: IconExclamationCircleFilled,
    [AlertLabelTypes.SUCCESS]: IconCheckCircleFilled,
    [AlertLabelTypes.INFO]: IconInfoCircleFilled
  };
  const Icon = iconMap[messageType];
  return (
    <label className="formWizard-wrapper" htmlFor={htmlFor}>
      {(label || description) && (
        <div className={getLabelClassNames(messageType)}>
          <div className="formWizard-label-column forIcon">
            <Icon />
          </div>
          <div className="formWizard-label-column forContent">
            <div className="formWizard-label-text">{t(label)}</div>
            <div className="formWizard-label-description">{t(description)}</div>
          </div>
        </div>
      )}
      <div>{children}</div>
    </label>
  );
};

AlertLabelWithIcon.propTypes = {
  children: node,
  htmlFor: string,
  label: string,
  description: node,
  messageType: oneOf(Object.values(AlertLabelTypes))
};

AlertLabelWithIcon.defaultProps = {
  children: null,
  htmlFor: '',
  label: '',
  description: '',
  messageType: AlertLabelTypes.SUCCESS
};

export default AlertLabelWithIcon;
