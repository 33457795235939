import type { PAYMENT_FIELDS } from '@jotforminc/payment-constants';
import type { REQUEST_POST_NEW_CONNECTION_WITH_CREDENTIALS } from '../types';

import { requestLayer } from './requestLayer';
import { sandboxPostDataConverter } from '../utils/functions/sandboxPostDataConverter';

/**
 * Creates a new connection with credentials for a payment gateway.
 *
 * @param gateway - The payment gateway to create a connection for.
 * @param isAvailableSandboxMode - Indicates if the sandbox mode is available.
 * @param enterpriseSubdomain - Subdomain Name of current enterprise server.
 * @param connectionName - The name of the connection.
 * @param connectionModeName - The name of the connection mode.
 * @param connectionModeValue - The value of the connection mode.
 * @param credentials - The credentials for the connection.
 * @returns A Promise that resolves to the response from the server.
 */
export const newConnectionWithCredentials = (
  gateway: PAYMENT_FIELDS,
  isAvailableSandboxMode: boolean,
  enterpriseSubdomain: string | null,
  {
    connectionName,
    connectionModeName,
    connectionModeValue,
    credentials,
    isEditMode = false,
    isReconnectMode = false,
    connectionId
  }: REQUEST_POST_NEW_CONNECTION_WITH_CREDENTIALS
): Promise<any> => {
  const postData = new FormData();
  const isActiveSandboxMode = isAvailableSandboxMode && connectionModeName !== null && connectionModeValue !== null;

  postData.append('title', connectionName);
  postData.append('sandbox', isActiveSandboxMode ? sandboxPostDataConverter(connectionModeName, connectionModeValue) : '0');

  if ((isEditMode || isReconnectMode) && connectionId) {
    postData.append('connectionId', connectionId);
    postData.append('forceCreate', 'agv');
  }

  Object.entries(credentials).forEach(([key, value], index) => {
    postData.append(`credentials[${index}][key]`, key);
    postData.append(`credentials[${index}][value]`, typeof value === 'object' ? JSON.stringify(value) : value);
  });

  if (isActiveSandboxMode) {
    postData.append(`credentials[${Object.values(credentials).length}][key]`, connectionModeName);
    postData.append(`credentials[${Object.values(credentials).length}][value]`, connectionModeValue);
  }

  if (enterpriseSubdomain) {
    postData.append('scope', 'org');
    postData.append('username', enterpriseSubdomain);
  }

  return requestLayer.post(`/API/payment/connections/${gateway}`, postData);
};
