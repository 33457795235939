import Styled, { css } from 'styled-components';
import { checkMobilePhone } from '../../../utils';
import { mainFontColor } from '../../../styles/colors';

const isMobilePhone = checkMobilePhone();

const ScCheckoutFormRenderer = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .4s all;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: ${isMobilePhone ? '0' : '13px 13px 0 0'};
    transition: .4s all;
    opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
  }

  ${({ previewMode }) => (previewMode
    ? css`
      background-color: ${mainFontColor};
      padding: 130px 50px 72px 50px;
      margin: 0 24px;
      border-radius: 4px;

      iframe {
        border-radius: 0;
        background-color: #fff;
        pointer-events: none;
      }

      @media screen and (max-width: 480px) {
        padding: 130px 30px 72px 30px;
      }
    `
    : css``)};

`;

export default ScCheckoutFormRenderer;
