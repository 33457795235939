/* eslint-disable react/prop-types */
import React from 'react';
import { t } from '@jotforminc/translation';
import { OPTION_VALUES, SIDE_SELECTION_DEFAULTS } from '../../modules/Builder/components/HomePage/RightPanel/ListItemSideItemSelection/constants';
import SvgRenderer from '../common/SvgRenderer';
import { changeDomainWithCurrent, sanitizeHTML } from '../../utils';
import { ScIconWrapper } from './styled';
import DefaultImage from './DefaultImage';

export const VALUE_BOUNDED_OPTIONS = {
  [OPTION_VALUES.ICON]: { props: ['url', 'svgRef'] },
  [OPTION_VALUES.BUTTON]: { props: ['text'] }
};

export const getSideElementProps = (isEnd = false) => ({
  [OPTION_VALUES.ICON]: {
    style: isEnd ? { width: 20, height: 20 } : { width: 36, height: 36 }
  }
});

export const sideOptionRenderer = ({ type, data }, isEnd = false) => {
  const boundedProps = VALUE_BOUNDED_OPTIONS[type];
  const checkStrategy = boundedProps?.checkEvery ? 'every' : 'some';
  if (boundedProps && !boundedProps.props?.[checkStrategy](key => !!data?.[key])) {
    return null;
  }

  const staticProps = getSideElementProps(isEnd)[type];

  switch (type) {
    case OPTION_VALUES.IMAGE: {
      // eslint-disable-next-line jsx-a11y/alt-text
      return () => (
        <>
          {
            data === SIDE_SELECTION_DEFAULTS[type]
              ? <DefaultImage />
              : <img alt={t('list item image')} src={data.url} />
          }
        </>
      );
    }

    case OPTION_VALUES.AVATAR: {
      return () => (
        <div className='isAvatarImage'>
          {
            data === SIDE_SELECTION_DEFAULTS[type]
              ? <DefaultImage />
              : <img alt={t('list item avatar')} src={data.url} />
          }
        </div>
      );
    }

    case OPTION_VALUES.ICON: {
      return ({ colorScheme }) => {
        return (
          <ScIconWrapper bgColor={data?.bgColor ?? colorScheme.itemBgColor}>
            <SvgRenderer
              url={changeDomainWithCurrent(data?.url)}
              svgRef={data?.svgRef}
              iconColor={data?.color ?? colorScheme.itemFontColor}
              {...staticProps}
            />
          </ScIconWrapper>
        );
      };
    }

    case OPTION_VALUES.CUSTOM_TEXT: {
      return ({ onPropChange, TitleRenderer }) => (
        <TitleRenderer
          defaultValue={data?.text}
          value={data?.text}
          overrideProps={{
            onChange: val => onPropChange({ text: val }),
            characterLimit: 15
          }}
        >
          {sanitizeHTML(data?.text)}
        </TitleRenderer>
      );
    }

    case OPTION_VALUES.BUTTON: {
      return ({ onPropChange, TitleRenderer }) => {
        return (
          <TitleRenderer
            defaultValue={data?.text}
            value={data?.text}
            overrideProps={
              {
                onChange: val => onPropChange({ text: val }),
                characterLimit: 20
              }
            }
          >
            {sanitizeHTML(data?.text)}
          </TitleRenderer>
        );
      };
    }

    default:
      return null;
  }
};
