import Styled from 'styled-components';

export const ScRF = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ScContainer = Styled.div`
  display: flex;
  align-items: center;

  .error {
    color: #dc2626;
    font-size: 14px;
    margin-right: 16px;
  }
`;

export const ScRB = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .content {
    width: 90%;
    max-width: 500px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 1em;
    color: #0A1551;
    font-weight: 700;
    margin: auto;

    > svg {
      color: #DC2626;
      display: unset;
    }
  }

  .content.wider { max-width: 540px; }

  .assignToOrgSvg {
    width: 54vw;
    height: 36vh;
    max-width: 192px;
    max-height: 136px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    color: #141E46;
    font-weight: 700;
    margin-top: 18px;
  }

  .subtitle {
    font-size: 15px;
    line-height: 16px;
    color: #252F58;
    font-weight: 500;
    margin-top: 12px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #343C6A;
  }

  p, .custom-content { font-weight: 400; margin-bottom: 24px; }

  .sec { color: #2c3345; margin: 1em 0 0; line-height: 1.5; }

  [data-value="sendNotificationEmail"] { display: inline-flex; }

  .assigneeMsg {
    max-width: 426px;
    width: 100%;
    display: block;
    height: 90px;
    border-radius: 4px;
    border: solid 1px #d8dae9;
    margin: 1em auto 0;
    font-size: .875rem;
    padding: .375rem .5rem;
    transition: border-color .15s ease;

    ::placeholder { color: #8d8fa8; }
    :focus { outline: 0; border-color: #4c7af7; }
  }

  .dontshow {
    display: inline-flex;
    background-color: #ecf4ff;
    align-items: center;
    padding: 10px;
    margin-top: 38px;
    margin-bottom: 20px;

    &-input {
      opacity: 0;
      pointer-events: none;
      position: absolute;

      &:checked + .dontshow-checkbox:before {
        background-color: #54b45c;
        border-color: #54b45c;
      }

      &:checked + .dontshow-checkbox:after {
        opacity: 1;
      }

      &:focus {
        outline: 0;
      }
    }

    &-checkbox {
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 2px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid #CCD0DA;
        background-color: #fff;
        border-radius: 2px;
        top: 0;
        left: 0;
        transition: .3s;
        transform: translate(-1px, -1px);
      }

      &:after {
        content: "";
        display: block;
        width: 4px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 1px;
        left: 4px;
        opacity: 1;
        transition: .3s;
        z-index: 2;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      margin-left: 8px;
      line-height: 1;
      color: #52587e;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 0.875em;

    p { margin: 1em 0 .75em; }
  }
`;
