import React, { forwardRef } from 'react';
import { bool, oneOfType, shape } from 'prop-types';

const ButtonRenderer = forwardRef(({
  option: { text, value, Icon } = {},
  isOptionsVisible, ...props
}, ref) => {
  return (
    <button
      ref={ref}
      className="dropdown-button"
      type="button"
      data-value={value}
      {...props}
    >
      {Icon && <Icon />}
      <span style={{ marginLeft: '8px' }}>
        {text}
      </span>
    </button>
  );
});

ButtonRenderer.propTypes = {
  option: oneOfType([shape({}), bool]),
  isOptionsVisible: bool
};

ButtonRenderer.defaultProps = {
  option: {},
  isOptionsVisible: false
};

export default ButtonRenderer;
