import { cva } from 'class-variance-authority';
import { primary, secondary } from '../../tokens/colors';
import { CVAType } from '../../types/system.types';
import { CheckboxProps } from './checkbox.types';

// CVA Types
type CheckboxCVAType = CVAType<Pick<CheckboxProps, 'size' | 'disabled' | 'checked' | 'indeterminate' | 'theme' | 'error'>>
type CheckboxWrapperCVAType = CVAType<Pick<CheckboxProps, 'size'>>
type CheckboxIconCVAType = CVAType<Pick<CheckboxProps, 'disabled' | 'theme'>>

// Common classes
const checkboxSizes = {
  small: 'w-4 h-4',
  medium: 'w-5 h-5',
  large: 'w-6 h-6'
};

// Classes
export const checkboxCVA = cva<CheckboxCVAType>(
  'appearance-none border-2 radius inline-flex shrink-0 focus:outline-2 outline-offset-0 m-0', {
    variants: {
      size: checkboxSizes,
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer'
      },
      checked: {},
      indeterminate: {},
      theme: {},
      error: {
        false: primary.accent.default.border.focus
      }
    },
    compoundVariants: [
      // Dark
      {
        theme: 'dark',
        error: false,
        className: 'outline-blue-600 outline-opacity-50'
      },
      {
        theme: 'dark',
        error: true,
        checked: true,
        className: 'outline-blue-600 outline-opacity-50'
      },
      {
        theme: 'dark',
        checked: false,
        disabled: false,
        error: false,
        indeterminate: false,
        className: [
          secondary.background.dark.base,
          secondary.border.medium.base,
          primary.accent.default.border.hover
        ]
      },
      {
        theme: 'dark',
        checked: true,
        disabled: true,
        indeterminate: false,
        className: [secondary.background.light.base, secondary.border.medium.base]
      },
      {
        theme: 'dark',
        indeterminate: true,
        checked: false,
        disabled: true,
        className: [secondary.background.light.base, secondary.border.medium.base]
      },
      {
        theme: 'dark',
        checked: false,
        disabled: true,
        indeterminate: false,
        className: [secondary.background.medium.base, secondary.border.medium.base]
      },
      // Light
      {
        theme: 'light',
        error: false,
        className: 'focus:outline-blue-200'
      },
      {
        theme: 'light',
        error: true,
        checked: true,
        className: 'focus:outline-blue-200'
      },
      {
        theme: 'light',
        checked: false,
        disabled: false,
        indeterminate: false,
        error: false,
        className: [primary.border.medium.base, primary.accent.default.border.hover, 'bg-white']
      },
      {
        theme: 'light',
        checked: true,
        disabled: true,
        className: 'bg-navy-50 border-navy-50'
      },
      {
        theme: 'light',
        indeterminate: true,
        checked: false,
        disabled: true,
        className: 'bg-navy-50 border-navy-50'
      },
      {
        theme: 'light',
        checked: false,
        disabled: true,
        className: 'border-navy-50'
      },
      // Dark & Light
      {
        theme: ['dark', 'light'],
        checked: false,
        error: false,
        className: [primary.accent.default.border.focus]
      },
      {
        theme: ['dark', 'light'],
        checked: true,
        disabled: false,
        indeterminate: false,
        className: [
          primary.accent.default.bg.base,
          primary.accent.default.border.base,
          primary.accent.dark.bg.hover,
          primary.accent.dark.border.hover
        ]
      },
      {
        theme: ['dark', 'light'],
        checked: false,
        indeterminate: true,
        disabled: false,
        className: [
          primary.accent.default.bg.base,
          primary.accent.default.border.base,
          primary.accent.dark.bg.hover,
          primary.accent.dark.border.hover
        ]
      },
      {
        theme: ['dark', 'light'],
        checked: false,
        indeterminate: false,
        disabled: false,
        error: true,
        className: [
          primary.error.default.border.base,
          primary.error.dark.border.hover
        ]
      },
      {
        theme: 'light',
        checked: false,
        indeterminate: false,
        disabled: false,
        error: true,
        className: 'focus:outline-red-200 focus:outline-opacity-50'
      },
      {
        theme: 'dark',
        checked: false,
        indeterminate: false,
        error: true,
        disabled: false,
        className: [secondary.background.dark.base, 'focus:outline-red-400 focus:outline-opacity-50']
      }
    ]
  }
);

export const checkboxWrapperCVA = cva<CheckboxWrapperCVAType>('inline-flex shrink-0 relative', {
  variants: {
    size: checkboxSizes
  }
});

export const checkboxIconCVA = cva<CheckboxIconCVAType>('absolute inset-0 w-full h-full pointer-events-none', {
  variants: {
    disabled: {
      false: 'color-white'
    },
    theme: {}
  },
  compoundVariants: [
    {
      theme: 'dark',
      disabled: true,
      className: 'color-gray-600'
    },
    {
      theme: 'light',
      disabled: true,
      className: primary.text.light.base
    }
  ]
});
