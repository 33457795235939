import React, { useEffect, useRef, useState } from 'react';
import {
  array,
  bool, func, object, oneOfType, string
} from 'prop-types';
import FontPickerSearch from './FontPickerSearch';
import FontPickerListItem from './FontPickerListItem';

const FontPickerList = ({
  data,
  defaultFont,
  handleFontSearching,
  isOpened,
  listCreated,
  onSelectFont,
  searchResult,
  searchValue,
  selected
}) => {
  const baseBgUrl = '//jotform.com/images/fonts-new';
  const listRef = useRef(null);
  const inputRef = useRef(null);
  const [selectedArr, setSelectedArr] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleFontSelection = ({ target }) => {
    const index = target.getAttribute('data-id');
    onSelectFont(selectedArr[index]);
  };

  const handleHoverAction = ({ type, index }) => {
    setHoverIndex(type === 'enter' ? index : -1);
  };

  useEffect(() => {
    setSelectedArr(searchValue.length > 0 ? searchResult : data);
  }, [data, searchResult, searchValue]);

  useEffect(() => {
    if (listRef && listRef.current && listRef.current.scrollTop) {
      listRef.current.scrollTop = 0;
    }

    if (inputRef && inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [isOpened]);

  return (
    <div
      className={`jfc-font-picker-list${isOpened ? ' is-opened' : ''}${(searchValue.length > 0 && searchResult.length < 1 ? ' has-no-result' : '')}`}
      ref={listRef}
    >
      <FontPickerSearch
        defaultFont={defaultFont}
        onChange={handleFontSearching}
        ref={inputRef}
        value={searchValue}
      />
      {
        selectedArr && selectedArr.length > 0
          ? selectedArr.map((item, index) => (
            <FontPickerListItem
              key={index.toString()} // eslint-disable-line react/no-array-index-key
              baseBgUrl={baseBgUrl}
              defaultFont={defaultFont}
              handleFontSelection={handleFontSelection}
              handleHoverAction={handleHoverAction}
              hoverIndex={hoverIndex}
              index={index}
              item={item}
              listCreated={listCreated}
              selected={selected}
            />
          ))
          : (
            <div className="jfc-font-picker-list__empty">
              Nothing Found
            </div>
          )
        }
    </div>
  );
};

FontPickerList.propTypes = {
  data: oneOfType([array, object]),
  defaultFont: string,
  handleFontSearching: func,
  isOpened: bool,
  listCreated: bool,
  onSelectFont: func,
  searchResult: oneOfType([array, object]),
  searchValue: string,
  selected: string
};

FontPickerList.defaultProps = {
  data: [],
  defaultFont: '',
  handleFontSearching: f => f,
  isOpened: false,
  listCreated: true,
  onSelectFont: f => f,
  searchResult: [],
  searchValue: '',
  selected: ''
};

export default FontPickerList;
