import React from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';

const CartItemContentRenderer = ({
  totalAmount: amountText,
  name,
  optionsValue
}) => {
  return (
    <div className="product-content">
      <div className="product-content-name">{name}</div>
      <div className="product-content-amount">
        {amountText || t('Free')}
      </div>
      {optionsValue && (
        <div style={{ color: '#6F76A7', marginTop: '4px' }}>{optionsValue}</div>
      )}
    </div>
  );
};

CartItemContentRenderer.propTypes = {
  totalAmount: string,
  name: string,
  optionsValue: string
};

CartItemContentRenderer.defaultProps = {
  totalAmount: '',
  name: '',
  optionsValue: ''
};

export default CartItemContentRenderer;
