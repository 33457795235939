import Styled, { css } from 'styled-components';
import { primaryAccentDefault } from '../../../../../../styles/colors';

export const ScAppLayoutPanel = Styled.div`
${
  ({
    isSelected
  }) => {
    return `
      background-color: var(--jfv-panel-right-item-background);
      display: inline-block;
      border-radius: 4px;
      padding: 3px;
      width: calc(50% - 10px);
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;
      border: 1px solid #303339;

      &:nth-child(even) { margin-right: 10px; }
      &:nth-child(odd) { margin-left: 10px; }

      &:hover {
        border-color: ${primaryAccentDefault};

        .theme {
          &-overlay {
            opacity: 1;
            pointer-events: auto;

            ${isSelected ? css`
              .useBtn { display: none; }
            ` : css`
              .currentBtn { display: none; }
            `}

          }
        }
      }

      @media screen and (max-width: 480px) {
        width: 204px;
        &:nth-child(even) { margin-right: 0; margin-left: 28px; }
        &:nth-child(odd) { margin-left: 28px; }
      }

      .theme {
        position: relative;
        max-height: 110px;
        overflow: hidden;

        &-name {
          font-size: 14px;
          margin: 9px 7px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0;
          pointer-events: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          ${isSelected ? 'background-color: #0A155166;' : 'background-color: transparent;'}
          transition: .3s all ease;
          border-radius: 4px;
          border: 1px solid rgb(218,222,243);

          ${isSelected ? css`
            opacity: 1;
            pointer-events: auto;
            .useBtn { display: none; }
          ` : css`
            .currentBtn { display: none; }
          `}

          &:hover {
            border-color: ${primaryAccentDefault};
          }

          & > button.panel {
            width: 140px;
          }
        }
      }
    `;
  }
}
`;
