import {
  func, number, oneOfType, shape, string
} from 'prop-types';
import React from 'react';
import { safeJSONParse } from '@jotforminc/utils';

import { useDispatch, useSelector } from 'react-redux';
import { updateItemLoadingStatus } from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';
import PortalFileUpload from '../../../../../components/PortalFileUpload';

const WidgetFileUpload = props => {
  const {
    onChange, itemID, tempID, allowedFileTypes, value
  } = props;

  const isFileUploading = useSelector(SELECTORS.getItemLoadingStatusByID(itemID || tempID));
  const dispatch = useDispatch();

  const handleChange = data => {
    onChange(JSON.stringify(data));
  };

  const onFileUploading = val => {
    dispatch(updateItemLoadingStatus({
      [tempID || itemID]: val
    }));
  };

  // we may take error message as a parameter if we create our own error comp. renderer
  const handleFileUploadError = () => {
    onFileUploading(false);
  };
  const _value = safeJSONParse(value);

  return (
    <PortalFileUpload
      {...props}
      value={_value}
      onFileUpload={handleChange}
      onFileRemove={() => handleChange('')}
      isFileUploading={!!isFileUploading}
      onFileUploading={val => onFileUploading(val)}
      onFileUploadError={handleFileUploadError}
      allowedFileType={allowedFileTypes}
    />
  );
};

WidgetFileUpload.propTypes = {
  onChange: func,
  theme: string,
  itemID: oneOfType([string, number]),
  tempID: string,
  allowedFileTypes: string,
  value: shape({})
};

WidgetFileUpload.defaultProps = {
  onChange: f => f,
  theme: '',
  itemID: '',
  tempID: '',
  allowedFileTypes: '',
  value: {}
};

export default WidgetFileUpload;
