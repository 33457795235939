/* eslint-disable max-lines */
import { t, translationRenderer } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import React from 'react';
import flatMap from 'lodash/flatMap';
import * as COMPONENT_NAMES from '../../../../constants/componentNames';
import { IFormWizardElements } from '../../../../types/common';
import {
  I_GATEWAYS,
  I_GATEWAY_PROPERTIES,
  I_DATA_FROM_SETTINGS,
  I_PAYMENT_TYPES,
  I_RESOURCE_TYPES,
  T_FORM_QUESTIONS
} from '../Types';
import {
  I_STRIPE_CHECKOUT_SETTINGS,
  I_AUTHNET_SETTINGS,
  I_PAYPAL_SETTINGS,
  I_BLUEPAY_SETTINGS,
  I_BLUESNAP_SETTINGS,
  I_BRAINTREE_SETTINGS,
  I_CARDCONNECT_SETTINGS,
  I_CHARGIFY_SETTINGS,
  I_CYBERSOURCE_SETTINGS,
  I_ECHECK_SETTINGS,
  I_EWAY_SETTINGS,
  I_MONERIS_SETTINGS,
  I_PAYJUNCTION_SETTINGS,
  I_WORLDPAYUK_SETTINGS,
  I_2CO_SETTINGS,
  I_PAYSAFE_SETTINGS,
  I_SENSEPASS_SETTINGS,
  I_FIRSTDATA_SETTINGS,
  I_GOCARDLESS_SETTINGS,
  I_IYZICO_SETTINGS,
  I_MOLLIE_SETTINGS,
  I_PAYFAST_SETTINGS,
  I_PAYPALCOMPLETE_SETTINGS,
  I_PAGSEGURO_SETTINGS,
  I_PAYPALPRO_SETTINGS,
  I_PAYU_SETTINGS,
  I_WORLDPAYUS_SETTINGS,
  I_PAYPALINVOICING_SETTINGS,
  I_SQUARE_SETTINGS,
  I_STRIPE_SETTINGS
} from '../Types/GatewayTypes';
import { prepareAddQuestionData, getAddQuestionDefValue } from '../Utils';
import { inputStyle } from '../../../../constants/styles';
import { countries } from '../../../../constants/countries';

const DROPDOWN_STYLE = 'flex flex-col justify-between items-start px-3 mt-7 mb-9 text-md font-medium line-height-xs color-navy-700 sm:flex-row sm:items-center';
// not an actual field
const GroupHeader = ({ label, description }: { label: string, description: string }) => (
  <>
    <div className='px-3 mt-7 mb-9'>
      <div className='text-lg font-medium line-height-xs color-navy-700'>{label}</div>
      { description && <div className='text-sm font-normal line-height-xxs color-navy-700 mt-2'>{ description }</div> }
    </div>
  </>
);

const MODE_TEXT = {
  LIVE: 'Live Mode',
  TEST: 'Test Mode'
};

// gateways that users do not have to enter credentials for
export const GATEWAYS_PREDEFINED_TEST = ['control_cardconnect'];

export const GATEWAY_PROPERTIES: I_GATEWAY_PROPERTIES[] = [
  {
    type: 'control_authnet',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'testmodeAuth',
        options: [
          { value: 'No', text: MODE_TEXT.LIVE },
          { value: 'Yes', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'apiLoginId',
          label: 'API Login ID'
        },
        {
          key: 'transactionKey',
          label: 'Transaction Key'
        }
      ]
    },
    additionalSettings: (settings: I_AUTHNET_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      askBillingInformation: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Ask Billing Information to Customer'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.askBillingInformation === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      shipToName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Shipping Name Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shipToName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Full Name', settings.shipToName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      shipToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Shipping Address Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shipToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.shipToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      authInvoiceField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Invoice Number Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.authInvoiceField, formQuestions),
          options: prepareAddQuestionData(formQuestions, ['control_autoincrement', 'control_textbox'], 'Invoice', settings.authInvoiceField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      companyName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Company Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.companyName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_textbox', 'Text', settings.companyName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      authnetEmulator: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Emulator'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.authnetEmulator || 'none',
          options: [
            { value: 'none', text: t('None') },
            { value: 'epn', text: t('EPN') },
            { value: 'goemerchant', text: t('GoEmerchant') },
            { value: 'merchantone', text: t('MerchantOne') },
            { value: 'payjunction', text: t('PayJunction') },
            { value: 'usaepay', text: t('USAePay') }
          ],
          inputClass: 'w-68'
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      },
      addressTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.addressTitle || t('Billing Address'),
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_bluepay',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'accountId',
          label: 'Account ID'
        },
        {
          key: 'apiSecret',
          label: 'API Secret'
        }
      ]
    },
    hideAdditionalSettings: true,
    additionalSettings: (settings: I_BLUEPAY_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      requireBillingAddr: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Require Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.requireBillingAddr === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_bluesnap',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'apiKey',
          label: 'API Key'
        },
        {
          key: 'password',
          label: 'API Password'
        }
      ]
    },
    additionalSettings: (settings: I_BLUESNAP_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      sca: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          description: `
            ${t('To be able to collect payments in European Economic Area, please enable this toggle.')}
            ${' '}
            <a class="pointer-events-auto" href="https://support.bluesnap.com/docs/3d-secure#test-3-d-secure-2" target="_blank" rel="nofollow"><u>${t('More')}</u></a>.
          `,
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.sca === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      phoneField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Phone'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.phoneField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_phone', 'Phone', settings.phoneField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billingAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billingAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      },
      softDescriptor: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Soft Descriptor'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          defaultValue: settings.softDescriptor || '',
          placeholder: t('Enter Company Name etc.'),
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_braintree',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'publicKey',
          label: 'Public Key'
        },
        {
          key: 'privateKey',
          label: 'Private Key'
        },
        {
          key: 'merchantId',
          label: t('Merchant ID'),
          isSensitiveField: false
        }
      ]
    },
    additionalSettings: (settings: I_BRAINTREE_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      threeDSecure: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: flatMap(translationRenderer('You must enable 3D Secure 2.0 in your Braintree Account as well. You can follow [1[this guide]].')({
            // eslint-disable-next-line max-len
            renderer1: (str: string) => `<a class="pointer-events-auto" rel="nofollow" target="_blank" href="https://articles.braintreepayments.com/guides/fraud-tools/3d-secure#confirm-setup"><u>${str}</u></a>`
          })).join('')
        },
        componentProps: {
          isSelected: settings.threeDSecure === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      requireBillingAddr: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Require Billing Address and Check AVS'),
          class: DROPDOWN_STYLE,
          description: `
            ${t('Send billing address to Braintree and check')}
            ${' '}
            <a href="https://articles.braintreepayments.com/guides/fraud-tools/basic/avs-cvv-rules" target="_blank"><u>Address Verification System (AVS)</u></a>.
          `
        },
        componentProps: {
          isSelected: settings.requireBillingAddr === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      merchantAccountId: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Merchant Account ID'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          defaultValue: settings.merchantAccountId || '',
          inputClass: 'w-68'
        }
      },
      braintreeDescriptionField: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Product Description Field'),
          class: DROPDOWN_STYLE,
          // is flatmap join necessary?
          description: flatMap(translationRenderer('API name of the [1[Braintree custom field]] to which the selected product names will be passed.')({
            renderer1: (str: string) => `<a href="https://articles.braintreepayments.com/control-panel/custom-fields#creating-a-custom-field" target="_blank"><u>${str}</u></a>`
          })).join('')
        },
        componentProps: {
          defaultValue: settings.braintreeDescriptionField || '',
          inputClass: 'w-68'
        }
      },
      askBillingInformation: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Ask Billing Information to Customer'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.askBillingInformation === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      companyName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Company Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.companyName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_textbox', 'Text', settings.companyName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      phoneField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Phone'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.phoneField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_phone', 'Phone', settings.phoneField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_cardconnect',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'apiUsername',
          label: 'API Username'
        },
        {
          key: 'apiPassword',
          label: 'API Password'
        },
        {
          key: 'merchantId',
          label: 'Merchant ID',
          isSensitiveField: false
        }
      ],
      condition: sandbox => sandbox !== 'enabled'
    },
    additionalSettings: (settings: I_CARDCONNECT_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      addCaptcha: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Add Captcha'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.addCaptcha, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_captcha', 'Captcha', settings.addCaptcha),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_chargify',
    connectionSettings: {
      type: 'credential',
      fields: [
        {
          key: 'hostName',
          label: 'Host Name'
        },
        {
          key: 'apiKey',
          label: 'API Key'
        },
        {
          key: 'sharedKey',
          label: 'Shared Key'
        }
      ]
    },
    additionalSettings: (settings: I_CHARGIFY_SETTINGS): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_clickbank',
    connectionSettings: {
      type: 'credential',
      fields: [
        { key: 'login', label: 'Account Name' },
        { key: 'itemNo', label: 'Product Item No' },
        { key: 'productName', label: 'Product Name' },
        { key: 'productPrice', label: 'Product Price' }
      ]
    }
  },
  {
    type: 'control_cybersource',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'merchantID',
          label: 'Merchant ID',
          isSensitiveField: false
        },
        {
          key: 'transactionKey',
          label: 'Transaction Key'
        }
      ]
    },
    additionalSettings: (settings: I_CYBERSOURCE_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      billToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      email: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Bill To'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.email, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.email),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      threeDSecure: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: `
            ${t('To be able to collect payments in European Economic Area, please enable this toggle.')}
            ${' '}
            <a class="pointer-events-auto" href="https://www.jotform.com/help/588-how-to-integrate-cybersource-with-jotform#3d" target="_blank" rel="nofollow"><u>${t('More')}</u></a>.
          `
        },
        componentProps: {
          isSelected: settings.threeDSecure === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      cardinalAPIkey: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Cardinal API Key'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.cardinalAPIkey,
          inputClass: 'w-68'
        },
        condition: () => settings.threeDSecure === 'Yes'
      },
      cardinalAPIindentifier: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Cardinal API Identifier'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.cardinalAPIindentifier,
          inputClass: 'w-68'
        },
        condition: () => settings.threeDSecure === 'Yes'
      },
      cardinalOrgUnitID: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Cardinal OrgUnit ID'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.cardinalOrgUnitID,
          inputClass: 'w-68'
        },
        condition: () => settings.threeDSecure === 'Yes'
      },
      orderPrefix: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Order Prefix'),
          class: DROPDOWN_STYLE,
          description: t('This prefix will be appended to the beginning of the Order ID that\'s sent to CyberSource.')
        },
        componentProps: {
          defaultValue: settings.orderPrefix || '',
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_echeck',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'apiLoginId',
          label: 'API Login ID'
        },
        {
          key: 'transactionKey',
          label: 'Transaction Key'
        }
      ]
    },
    additionalSettings: (settings: I_ECHECK_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      askBillingInformation: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Ask Billing Information to Customer'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.askBillingInformation === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      billToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM' && settings.askBillingInformation === 'Yes'
      },
      ecTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('eCheck.Net Title'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ecTitle || '',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_eway',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'apiKey',
          label: 'API Key'
        },
        {
          key: 'apiPassword',
          label: 'API Password'
        },
        {
          key: 'encryptionKey',
          label: 'Client-side Encryption Key'
        }
      ]
    },
    additionalSettings: (settings: I_EWAY_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      requireBillingAddr: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Require Billing Address and Check AVS'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.requireBillingAddr === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  // TODO :: ADD ALPHABETICALLY FROM HERE
  {
    type: 'control_paypalcomplete',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_PAYPALCOMPLETE_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      paymentMethodHeader: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Payment Method'),
          description: t('You must select at least one payment method.')
        },
        LabelRenderer: GroupHeader,
        condition: () => !settings.merchantId || settings.merchantId.length === 0
      },
      showCardFields: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show "Debit or Credit Card"'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: !settings.showCardFields || settings.showCardFields === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF',
          onChange: (e: Event) => {
            if (settings.showCardFields === 'Yes' && settings.showSPB === 'No') {
              e.preventDefault();
            }
          }
        },
        condition: () => !settings.merchantId || settings.merchantId.length === 0
      },
      showSPB: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Paypal'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: (!settings.showSPB || settings.showSPB === 'Yes'),
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF',
          onChange: (e: Event) => {
            if (settings.showCardFields === 'No' && settings.showSPB === 'Yes') {
              e.preventDefault();
            }
          }
        },
        condition: () => !settings.merchantId || settings.merchantId.length === 0
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: t('The credit card will be authorized after the form is submitted. You can manually charge the card later. (The grace period is 3 days.)'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      billToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      groupHeaderSPBStyle: {
        labelProps: {
          label: t('PayPal Smart Payment Buttons Style')
        },
        LabelRenderer: GroupHeader,
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleColor: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Color'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleColor || 'gold',
          options: [
            { value: 'gold', text: t('Gold') },
            { value: 'blue', text: t('Blue') },
            { value: 'silver', text: t('Silver') },
            { value: 'white', text: t('White') },
            { value: 'black', text: t('Black') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleShape: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Shape'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleShape || 'rect',
          options: [
            { value: 'pill', text: t('Pill') },
            { value: 'rect', text: t('Rect') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleSize: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Size'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleSize || 'medium',
          options: [
            { value: 'small', text: t('Small') },
            { value: 'medium', text: t('Medium') },
            { value: 'large', text: t('Large') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleLabel: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Label'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleLabel || 'checkout',
          options: [
            { value: 'checkout', text: t('PayPal Checkout') },
            { value: 'pay', text: t('Pay with PayPal') },
            { value: 'buynow', text: t('Buy Now') },
            { value: 'paypal', text: t('PayPal') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleLayout: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Layout'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleLayout || 'vertical',
          options: [
            { value: 'vertical', text: t('Vertical') },
            { value: 'horizontal', text: t('Horizontal') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      payLaterEnabled: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Pay Later Messaging'),
          description: t('Displays information about the installments for Pay Later which is available in the US.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.payLaterEnabled === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      groupHeaderPayLaterStyle: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Pay Later Messaging Style')
        },
        LabelRenderer: GroupHeader,
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes'
      },
      payLaterLayout: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Layout Style'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterLayout || 'text',
          options: [
            { value: 'text', text: t('Text') },
            { value: 'flex', text: t('Flex') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes'
      },
      payLaterLogoType: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Logo Type'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterLogoType || 'inline',
          options: [
            { value: 'primary', text: t('Primary') },
            { value: 'alternative', text: t('Alternative') },
            { value: 'inline', text: t('Inline') },
            { value: 'none', text: t('None') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text')
      },
      payLaterLogoPosition: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Logo Position'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterLogoPosition || 'left',
          options: [
            { value: 'left', text: t('Left') },
            { value: 'right', text: t('Right') },
            { value: 'top', text: t('top') }
          ],
          inputClass: 'w-68'
        },
        // eslint-disable-next-line max-len
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text') && ['primary', 'alternative'].includes(settings.payLaterLogoType)
      },
      payLaterTextColor: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Text Color'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterTextColor || 'black',
          options: [
            { value: 'black', text: t('Black') },
            { value: 'white', text: t('White') },
            { value: 'monochrome', text: t('Monochrome') },
            { value: 'grayscale', text: t('Grayscale') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text')
      },
      payLaterTextSize: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Text Size'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterTextSize || '12',
          options: [
            { value: '10', text: t('10') },
            { value: '11', text: t('11') },
            { value: '12', text: t('12') },
            { value: '13', text: t('13') },
            { value: '14', text: t('14') },
            { value: '15', text: t('15') },
            { value: '16', text: t('16') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text')
      },
      payLaterBackgroundColor: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Message Background'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterBackgroundColor || 'blue',
          options: [
            { value: 'blue', text: t('Blue') },
            { value: 'black', text: t('Black') },
            { value: 'white', text: t('White') },
            { value: 'white-no-border', text: t('White - No Border') },
            { value: 'gray', text: t('Gray') },
            { value: 'monochrome', text: t('Monochrome') },
            { value: 'grayscale', text: t('Grayscale') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'flex'
      },
      payLaterRatio: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Banner ratio'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterRatio || '20x1',
          options: [
            { value: '8x1', text: '8x1' },
            { value: '20x1', text: '20x1' }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'flex'
      }
    })
  },
  {
    type: 'control_paypalInvoicing',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_PAYPALINVOICING_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Bill to'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      businessDetailsHeader: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Your Business Details'),
          description: t('Your business information will be included on PayPal invoices.')
        },
        LabelRenderer: GroupHeader
      },
      softDescriptor: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Company Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.softDescriptor,
          inputClass: 'w-68'
        }
      },
      merchantName: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.merchantName,
          inputClass: 'w-68'
        }
      },
      companyAddress: {
        type: COMPONENT_NAMES.TEXTAREA,
        labelProps: {
          label: t('Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.companyAddress,
          inputClass: `w-68 ${inputStyle('textarea')}`
        }
      },
      merchantPhone: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Phone'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.merchantPhone,
          inputClass: 'w-68'
        }
      },
      billToName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Full Name', settings.billToName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      useAsShipping: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Shipping Information'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings.useAsShipping === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      shipToName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Shipping Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shipToName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Full Name', settings.shipToName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM' && settings.useAsShipping === 'Yes'
      },
      shipToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Shipping Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shipToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.shipToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM' && settings.useAsShipping === 'Yes'
      },
      allowPartial: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Allow Partial Payment'),
          description: t('Selecting yes will allow the customer to pay in installments. You can set a minimum amount for installment payments.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings.allowPartial === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      minAmount: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Minimum Amount'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.minAmount || '',
          inputClass: 'w-68'
        },
        condition: () => settings.allowPartial === 'enabled'
      }
    })
  },
  {
    type: 'control_paypal',
    connectionSettings: {
      type: 'credential',
      label: t('Connect to Paypal Standard'),
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'account',
          label: 'PayPal Account'
        }
      ]
    },
    additionalSettings: (settings: I_PAYPAL_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      payeraddress: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Require Shipment Address'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.payeraddress === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      country: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Checkout Language'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.country || '',
          options: countries,
          inputClass: 'w-68'
        }
      },
      ipnrelay: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('IPN Relay URL'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ipnrelay || '',
          inputClass: 'w-68'
        }
      },
      emailpending: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Pending Payment Email'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.emailpending || 'No',
          options: [
            { text: t('No'), value: 'No' },
            { text: t('Notifiers Only'), value: 'Yes' },
            { text: t('Notifiers & Autoresponders'), value: 'All' }
          ],
          inputClass: 'w-68'
        }
      },
      customDataField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Fetch Data from a Text Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.customDataField, formQuestions),
          options: prepareAddQuestionData(formQuestions, ['control_textbox', 'control_calculation'], 'Text', settings.customDataField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      pagestyle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Redirected PayPal Page Style'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.pagestyle || '',
          inputClass: 'w-68'
        }
      },
      checkoutLogo: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Checkout Logo'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.checkoutLogo || '',
          inputClass: 'w-68'
        }
      },
      cartColor: {
        type: COMPONENT_NAMES.COLORPICKER,
        labelProps: {
          label: t('Checkout Cart Color'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          value: settings.cartColor || '#FFFFFF',
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_gocardless',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'allowTest',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'Enabled', text: MODE_TEXT.TEST }
        ]
      },
      label: t('Connect'),
      description: t('You will be redirected to GoCardless to complete the connection.')
    },
    additionalSettings: (settings: I_GOCARDLESS_SETTINGS, _, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      billingName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Name Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Name', settings.billingName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        }
      },
      billingEmail: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingEmail, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.billingEmail),
          subType: 'addQuestion',
          inputClass: 'w-68'
        }
      },
      billingAdd: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingAdd, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billingAdd),
          subType: 'addQuestion',
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_firstdata',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'gatewayId',
          label: 'Gateway ID'
        },
        {
          key: 'password',
          label: 'API Password'
        }
      ]
    },
    additionalSettings: (settings: I_FIRSTDATA_SETTINGS): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_iyzico',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'No', text: MODE_TEXT.LIVE },
          { value: 'Yes', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'iyzicoApiKey',
          label: 'Merchant API Key'
        },
        {
          key: 'iyzicoSecretKey',
          label: 'API Secret Key'
        }
      ]
    },
    hideAdditionalSettings: true,
    additionalSettings: (settings: I_IYZICO_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      billingName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Name Field'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Name', settings.billingName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billingEmail: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingEmail, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.billingEmail),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billingAdd: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Billing Address'),
          class: DROPDOWN_STYLE,
          required: true
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingAdd, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billingAdd),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_mollie',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'No', text: MODE_TEXT.LIVE },
          { value: 'Yes', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_MOLLIE_SETTINGS): IFormWizardElements => ({
      profileId: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Profile'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.profileOptions ? settings.profileId : 'default',
          options: (settings.profileOptions && settings.profileId) ? settings.profileOptions : [{ value: 'default', text: t('Connect to {gateway} first').replace('{gateway}', 'Mollie') }],
          disabled: !settings.profileId || !settings.profileOptions,
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_moneris',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'monerisid',
          label: 'Store ID'
        },
        {
          key: 'monerisapi',
          label: 'API Token'
        }
      ]
    },
    additionalSettings: (settings: I_MONERIS_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      askBillingInformation: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Ask Billing Information to Customer'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.askBillingInformation === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      billToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Address Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      shipToName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Shipping Name Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shipToName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Full Name', settings.shipToName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      shipToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Shipping Address Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shipToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.shipToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      },
      orderIDPrefix: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Order ID Prefix'),
          class: DROPDOWN_STYLE,
          description: t('This prefix will be appended to the beginning of the Order ID that\'s sent to Moneris.')
        },
        componentProps: {
          defaultValue: settings.orderIDPrefix || '',
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_pagseguro',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'No', text: MODE_TEXT.LIVE },
          { value: 'Yes', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'pagseguroid',
          label: 'E-Mail'
        },
        {
          key: 'pagseguroapi',
          label: 'Token'
        }
      ]
    },
    additionalSettings: (settings: I_PAGSEGURO_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || '',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      addressTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Billing Address Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.addressTitle || '',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_payfast',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'Enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'merchantKey',
          label: 'Merchant Key'
        },
        {
          key: 'passphrase',
          label: 'Passphrase'
        },
        {
          key: 'merchantId',
          label: 'Merchant ID',
          isSensitiveField: false
        }
      ]
    },
    hideAdditionalSettings: true,
    additionalSettings: (settings: I_PAYFAST_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      billingName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Name Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Name', settings.billingName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billingEmail: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingEmail, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.billingEmail),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billingPhone: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Phone Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingPhone, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Phone Number', settings.billingPhone),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      billingAdd: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingAdd, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billingAdd),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      customDataField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Custom Data Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.customDataField, formQuestions),
          options: prepareAddQuestionData(formQuestions, ['control_textbox', 'control_autoincrement'], 'Type a question', settings.customDataField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_paymentwall',
    connectionSettings: {
      type: 'credential',
      fields: [
        {
          key: 'publicKey',
          label: 'Project Key'
        },
        {
          key: 'privateKey',
          label: 'Secret Key'
        }
      ]
    }
  },
  {
    type: 'control_payjunction',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'username',
          label: 'API Login'
        },
        {
          key: 'password',
          label: 'API Password'
        }
      ]
    },
    additionalSettings: (settings: I_PAYJUNCTION_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      avsMatchType: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('AVS Match Type'),
          class: DROPDOWN_STYLE,
          description: t('Should be the same with the AVS settings assigned in your PayJunction merchant account.')
        },
        componentProps: {
          selectedOptionValues: settings.avsMatchType || 'No',
          options: [
            { value: 'NO', text: t('Disabled') },
            { value: 'BYPASS', text: t('Bypass') },
            { value: 'ADDRESS', text: t('Address') },
            { value: 'ADDRESS_AND_ZIP', text: t('Address and Zip') },
            { value: 'ADDRESS_OR_ZIP', text: t('Address or Zip') },
            { value: 'ZIP', text: t('Zip') }
          ],
          inputClass: 'w-68'
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || '',
          inputClass: 'w-68'
        }
      },
      addressTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Billing Address Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.addressTitle || '',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_stripeCheckout',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'allowTest',
        options: [
          { value: 'false', text: MODE_TEXT.LIVE },
          { value: 'true', text: MODE_TEXT.TEST }
        ]
      },
      label: t('Connect to Stripe')
    },
    additionalSettings: (settings: I_STRIPE_CHECKOUT_SETTINGS): IFormWizardElements => ({
      laterCharge: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.laterCharge === 'later',
          valueTrue: 'later',
          valueFalse: 'immediately',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      sendReceiptEmail: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Receipt Email'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.sendReceiptEmail === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      }
    })
  },
  {
    type: 'control_stripeACH',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'environment',
        options: [
          { value: 'production', text: t('Production') },
          // { value: 'development', text: t('Development') },
          { value: 'sandbox', text: t('Sandbox') }
        ]
      },
      fields: [
        {
          key: 'apiKey',
          label: 'Stripe Secret API Key'
        },
        {
          key: 'plaidClient',
          label: 'Plaid Client ID'
        },
        {
          key: 'plaidSecret',
          label: 'Plaid Secret Key'
        },
        {
          key: 'plaidSigningSecret',
          label: 'Plaid Signing Secret Key'
        }
      ]
    }
  },
  {
    type: 'control_stripeACHManual',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'allowTest',
        options: [
          { value: 'false', text: MODE_TEXT.LIVE },
          { value: 'true', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (): IFormWizardElements => ({})
  },
  {
    type: 'control_worldpay',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'testMode',
        options: [
          { value: 'No', text: MODE_TEXT.LIVE },
          { value: 'Yes', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'installationID',
          label: 'Installation ID'
        }
      ]
    },
    additionalSettings: (settings: I_WORLDPAYUK_SETTINGS): IFormWizardElements => ({
      emailpending: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Pending Payment Email'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.emailpending || 'No',
          options: [
            { value: 'No', text: t('No') },
            { value: 'Yes', text: t('Notifiers Only') },
            { value: 'All', text: t('Notifiers & Autoresponders') }
          ],
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_worldpayus',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'secureNetId',
          label: 'Secure Net ID'
        },
        {
          key: 'secureKey',
          label: 'Secure Key'
        }
      ]
    },
    additionalSettings: (settings: I_WORLDPAYUS_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || '',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_2co',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'Enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'vendorNumber',
          label: 'Vendor Number'
        }
      ]
    },
    additionalSettings: (settings: I_2CO_SETTINGS): IFormWizardElements => ({
      emailpending: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Pending Payment Email'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.emailpending || 'No',
          options: [
            { value: 'No', text: t('No') },
            { value: 'Yes', text: t('Notifiers Only') },
            { value: 'All', text: t('Notifiers & Autoresponders') }
          ],
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_stripe',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'allowTest',
        options: [
          { value: 'false', text: MODE_TEXT.LIVE },
          { value: 'true', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_STRIPE_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS, currentlyPaymentType: I_PAYMENT_TYPES): IFormWizardElements => ({
      laterCharge: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.laterCharge === 'later',
          valueTrue: 'later',
          valueFalse: 'immediately',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      stripeLink: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable, 1-Click Checkout with Link'),
          description: t('Create a Link account for faster checkout.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.stripeLink === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      createCustomer: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Create Stripe Customer Record'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.createCustomer,
          options: currentlyPaymentType === 'subscription' ? [
            { value: 'Unique', text: t('For Each Unique Customer') },
            { value: 'All', text: t('For Each Submission') }
          ] : [
            { value: 'Unique', text: t('For Each Unique Customer') },
            { value: 'All', text: t('For Each Submission') },
            { value: 'No', text: t('Do Not Create') }
          ],
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_square',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'allowTest',
        options: [
          { value: 'false', text: MODE_TEXT.LIVE },
          { value: 'true', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_SQUARE_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      location: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Business Location'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.locationOptions ? settings.location : 'default',
          options: (settings.locationOptions && settings.location)
            ? settings.locationOptions
            : [{ value: 'default', text: t('Connect to {gateway} first').replace('{gateway}', 'Square') }],
          disabled: !settings.location || !settings.locationOptions,
          inputClass: 'w-68'
        }
      },
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: t('Authorize the card now to charge it manually later. Expires after 6 days.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      sendReceipt: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Receipt'),
          description: t('Send an email with a link to the Square receipt to the customer.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.sendReceipt && settings.sendReceipt.toString() === 'true',
          valueTrue: true,
          valueFalse: false,
          onText: 'ON',
          offText: 'OFF'
        }
      },
      orderFulfillmentType: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Order Fulfillment Type'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.orderFulfillmentType || 'none',
          options: [
            { value: 'none', text: t('None') },
            { value: 'SHIPMENT', text: t('Shipment') },
            { value: 'PICKUP', text: t('Pickup') }
          ],
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      },
      showCard: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Credit Card'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showCard === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      showApplePay: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Apple Pay'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showApplePay === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.nameAPM !== 'appleAndGooglePay' && settings.nameAPM !== 'CashApp'
      },
      showGooglePay: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Google Pay'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showGooglePay === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.nameAPM !== 'appleAndGooglePay' && settings.nameAPM !== 'CashApp'
      },
      showCashApp: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Cash App Pay'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showCashApp === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.nameAPM !== 'appleAndGooglePay' && settings.nameAPM !== 'CashApp'
      },
      showACH: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show ACH Bank Transfer'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showACH === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.nameAPM !== 'appleAndGooglePay' && settings.nameAPM !== 'CashApp'
      },
      showAfterpay: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Afterpay'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showAfterpay === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.nameAPM !== 'appleAndGooglePay' && settings.nameAPM !== 'CashApp' && settings.nameAPM !== 'Afterpay'
      }
    })
  },
  {
    type: 'control_paypalpro',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'Disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'username',
          label: 'API Username'
        },
        {
          key: 'password',
          label: 'API Password'
        },
        {
          key: 'signature',
          label: 'API Signature'
        }
      ]
    },
    additionalSettings: (settings: I_PAYPALPRO_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      sca: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          description: `
            ${t('To be able to collect payments in European Economic Area, please enable this toggle.')}
            ${' '}
            <a class="pointer-events-auto" href="https://www.jotform.com/help/458-how-to-integrate-form-with-paypal-pro#3ds" target="_blank" rel="nofollow"><u>${t('More')}</u></a>.
          `,
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.sca === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      cardinalAPIkey: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Cardinal API Key'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.cardinalAPIkey || '',
          inputClass: 'w-68'
        },
        condition: () => settings.sca === 'Yes'
      },
      cardinalAPIindentifier: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Cardinal API Identifier'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.cardinalAPIindentifier || '',
          inputClass: 'w-68'
        },
        condition: () => settings.sca === 'Yes'
      },
      cardinalOrgUnitID: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Cardinal OrgUnit ID'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.cardinalOrgUnitID || '',
          inputClass: 'w-68'
        },
        condition: () => settings.sca === 'Yes'
      },
      paymentMethod: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Default Payment Method'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          selectedOptionValues: settings.paymentMethod || 'paypal',
          options: [
            { value: 'paypal', text: t('PayPal') },
            { value: 'cc', text: t('Credit Card') }
          ],
          inputClass: 'w-68'
        }
      },
      acceptedCc: {
        type: COMPONENT_NAMES.CHECKBOX,
        labelProps: {
          label: t('Accepted Credit Card'),
          class: DROPDOWN_STYLE,
          wrapperClassName: 'space-y-1.5 mt-3 sm:mt-0'
        },
        componentProps: {
          value: settings.acceptedCc || '',
          options: [
            { value: 'visa', text: t('Visa'), isValueChecked: settings.acceptedCc && settings.acceptedCc.indexOf('visa') > -1 },
            { value: 'mc', text: t('Mastercard'), isValueChecked: settings.acceptedCc && settings.acceptedCc.indexOf('mc') > -1 },
            { value: 'amex', text: t('American Express'), isValueChecked: settings.acceptedCc && settings.acceptedCc.indexOf('amex') > -1 },
            { value: 'dc', text: t('Discover'), isValueChecked: settings.acceptedCc && settings.acceptedCc.indexOf('dc') > -1 }
          ],
          inputClass: 'w-68 flex items-center gap-1.5'
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      },
      addressTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Billing Address'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.addressTitle || t('Billing Address'),
          inputClass: 'w-68'
        }
      },
      showPaypalStandard: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Allow PayPal Standard Payments'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.showPaypalStandard === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      paypalLink: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show PayPal Link'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.paypalLink === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${DROPDOWN_STYLE} pointer-events-none`,
          description: t('Authorize the card now to charge it manually later.')
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      // TODO :: check address fields and render
      shipToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.shipToAddress || 'none',
          options: [
            { value: 'none', text: t('None') },
            { value: 'billing', text: t('Same as billing') }
          ],
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      pagestyle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Redirected PayPal Page Style'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          defaultValue: settings.pagestyle,
          inputClass: 'w-68'
        }
      },
      cartColor: {
        type: COMPONENT_NAMES.COLORPICKER,
        labelProps: {
          label: t('Checkout Cart Color'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          value: settings.cartColor
        }
      }
    })
  },
  // /** Name APM: Start */
  {
    type: 'control_CashApp',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'allowTest',
        options: [
          { value: 'false', text: MODE_TEXT.LIVE },
          { value: 'true', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_SQUARE_SETTINGS): IFormWizardElements => ({
      location: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Business Location'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.locationOptions ? settings.location : 'default',
          options: (settings.locationOptions && settings.location)
            ? settings.locationOptions
            : [{ value: 'default', text: t('Connect to {gateway} first').replace('{gateway}', 'Square') }],
          disabled: !settings.location || !settings.locationOptions,
          inputClass: 'w-68'
        }
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: t('Authorize the card now to charge it manually later. Expires after 6 days.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      sendReceipt: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Receipt'),
          description: t('Send an email with a link to the Square receipt to the customer.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.sendReceipt && settings.sendReceipt.toString() === 'true',
          valueTrue: true,
          valueFalse: false,
          onText: 'ON',
          offText: 'OFF'
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      }
    })
  },
  {
    type: 'control_Venmo',
    connectionSettings: {
      type: 'oauth',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      }
    },
    additionalSettings: (settings: I_PAYPALCOMPLETE_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      billToAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billToAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billToAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      },
      groupHeaderSPBStyle: {
        labelProps: {
          label: t('PayPal Smart Payment Buttons Style')
        },
        LabelRenderer: GroupHeader,
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleColor: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Color'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleColor || 'gold',
          options: [
            { value: 'gold', text: t('Gold') },
            { value: 'blue', text: t('Blue') },
            { value: 'silver', text: t('Silver') },
            { value: 'white', text: t('White') },
            { value: 'black', text: t('Black') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleShape: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Shape'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleShape || 'rect',
          options: [
            { value: 'pill', text: t('Pill') },
            { value: 'rect', text: t('Rect') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleSize: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Size'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleSize || 'medium',
          options: [
            { value: 'small', text: t('Small') },
            { value: 'medium', text: t('Medium') },
            { value: 'large', text: t('Large') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleLabel: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Label'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleLabel || 'checkout',
          options: [
            { value: 'checkout', text: t('PayPal Checkout') },
            { value: 'pay', text: t('Pay with PayPal') },
            { value: 'buynow', text: t('Buy Now') },
            { value: 'paypal', text: t('PayPal') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      styleLayout: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Button Layout'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.styleLayout || 'vertical',
          options: [
            { value: 'vertical', text: t('Vertical') },
            { value: 'horizontal', text: t('Horizontal') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      payLaterEnabled: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Pay Later Messaging'),
          description: t('Displays information about the installments for Pay Later which is available in the US.'),
          class: `${DROPDOWN_STYLE} pointer-events-none`
        },
        componentProps: {
          isSelected: settings.payLaterEnabled === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes')
      },
      groupHeaderPayLaterStyle: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Pay Later Messaging Style')
        },
        LabelRenderer: GroupHeader,
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes'
      },
      payLaterLayout: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Layout Style'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterLayout || 'text',
          options: [
            { value: 'text', text: t('Text') },
            { value: 'flex', text: t('Flex') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes'
      },
      payLaterLogoType: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Logo Type'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterLogoType || 'inline',
          options: [
            { value: 'primary', text: t('Primary') },
            { value: 'alternative', text: t('Alternative') },
            { value: 'inline', text: t('Inline') },
            { value: 'none', text: t('None') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text')
      },
      payLaterLogoPosition: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Logo Position'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterLogoPosition || 'left',
          options: [
            { value: 'left', text: t('Left') },
            { value: 'right', text: t('Right') },
            { value: 'top', text: t('top') }
          ],
          inputClass: 'w-68'
        },
        // eslint-disable-next-line max-len
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text') && ['primary', 'alternative'].includes(settings.payLaterLogoType)
      },
      payLaterTextColor: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Text Color'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterTextColor || 'black',
          options: [
            { value: 'black', text: t('Black') },
            { value: 'white', text: t('White') },
            { value: 'monochrome', text: t('Monochrome') },
            { value: 'grayscale', text: t('Grayscale') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text')
      },
      payLaterTextSize: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Text Size'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterTextSize || '12',
          options: [
            { value: '10', text: t('10') },
            { value: '11', text: t('11') },
            { value: '12', text: t('12') },
            { value: '13', text: t('13') },
            { value: '14', text: t('14') },
            { value: '15', text: t('15') },
            { value: '16', text: t('16') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && (!settings.payLaterLayout || settings.payLaterLayout === 'text')
      },
      payLaterBackgroundColor: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Message Background'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterBackgroundColor || 'blue',
          options: [
            { value: 'blue', text: t('Blue') },
            { value: 'black', text: t('Black') },
            { value: 'white', text: t('White') },
            { value: 'white-no-border', text: t('White - No Border') },
            { value: 'gray', text: t('Gray') },
            { value: 'monochrome', text: t('Monochrome') },
            { value: 'grayscale', text: t('Grayscale') }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'flex'
      },
      payLaterRatio: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Banner ratio'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: settings.payLaterRatio || '20x1',
          options: [
            { value: '8x1', text: '8x1' },
            { value: '20x1', text: '20x1' }
          ],
          inputClass: 'w-68'
        },
        condition: () => (!settings.showSPB || settings.showSPB === 'Yes') && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'flex'
      }
    })
  },
  // {
  //   type: 'control_appleAndGooglePay'
  // },
  // /** Name APM: End */
  {
    type: 'control_payu',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ]
      },
      fields: [
        {
          key: 'merchantPosId',
          label: t('Merchant Pos ID')
        },
        {
          key: 'signatureKey',
          label: t('Signature Key')
        },
        {
          key: 'oAuthClientId',
          label: t('oAuth Client ID')
        },
        {
          key: 'oAuthClientSecret',
          label: t('oAuth Client Secret')
        }
      ]
    },
    hideAdditionalSettings: true,
    additionalSettings: (settings: I_PAYU_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      askBillingInformation: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Ask Billing Information to Customer'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.askBillingInformation === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => resourceType === 'FORM'
      },
      billingAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billingAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      shippingAddress: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Use Field as Shipping Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.shippingAddress, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.shippingAddress),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      billingName: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Billing Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.billingName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Name', settings.billingName),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      emailField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Email Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      },
      phoneField: {
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
          label: t('Customer Phone Field'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          selectedOptionValues: getAddQuestionDefValue(settings.phoneField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_phone', 'Phone', settings.phoneField),
          subType: 'addQuestion',
          inputClass: 'w-68'
        },
        condition: () => settings.askBillingInformation === 'Yes' && resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_sensepass',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'No', text: MODE_TEXT.LIVE },
          { value: 'Yes', text: MODE_TEXT.TEST }
        ],
        hideCondition: settings => isEnterprise() && ((settings.connectionIdOptions && Object.keys(settings.connectionIdOptions).length > 0) || typeof settings.connectionIdOptions === 'undefined')
      },
      fields: [
        {
          key: 'apiKey',
          label: 'API Key',
          hideCondition: settings => isEnterprise() && ((settings.connectionIdOptions && Object.keys(settings.connectionIdOptions).length > 0) || typeof settings.connectionIdOptions === 'undefined')
        },
        {
          key: 'reusableConnectionID',
          label: 'API Key',
          type: 'dropdown',
          staticKey: 'connectionIdOptions',
          /* eslint-disable-next-line max-len */
          hideCondition: settings => (isEnterprise() && ((settings.connectionIdOptions && Object.keys(settings.connectionIdOptions).length === 0))) || typeof settings.connectionIdOptions === 'undefined'
        }
      ]
    },
    hideAdditionalSettings: true, // all advanced settings edited according to the form builder & can be visible giving `true` to this prop
    additionalSettings: (settings: I_SENSEPASS_SETTINGS, resourceType: I_RESOURCE_TYPES, formQuestions: T_FORM_QUESTIONS): IFormWizardElements => ({
      askBillingInformation: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Ask Billing Information to Customer'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.askBillingInformation === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      },
      customerName: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Customer Name'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.customerName === 'Yes',
          selectedOptionValues: getAddQuestionDefValue(settings.customerName, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_fullname', 'Name', settings.customerName),
          subType: 'addQuestion',
          inputClass: 'w-68',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      billingAdd: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Billing Address'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.billingAdd === 'Yes',
          selectedOptionValues: getAddQuestionDefValue(settings.billingAdd, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Address', settings.billingAdd),
          subType: 'addQuestion',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      emailField: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Customer Email'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.emailField === 'Yes',
          selectedOptionValues: getAddQuestionDefValue(settings.emailField, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.emailField),
          subType: 'addQuestion',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      receiptEmailNotification: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Request by Email'),
          description: t('An invoice email will be generated by SensePass and sent to the customer. It will be valid for 5 days.'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.receiptEmailNotification === 'Yes',
          selectedOptionValues: getAddQuestionDefValue(settings.receiptEmailNotification, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.receiptEmailNotification),
          subType: 'addQuestion',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.askBillingInformation === 'Yes' && settings.emailField === 'Yes'
      },
      customerPhone: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Customer Phone'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.customerPhone === 'Yes',
          selectedOptionValues: getAddQuestionDefValue(settings.customerPhone, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_address', 'Phone Number', settings.customerPhone),
          subType: 'addQuestion',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.askBillingInformation === 'Yes'
      },
      receiptSMSNotification: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Request by Text Message'),
          description: t('An invoice SMS will be generated by SensePass and sent to the customer. It will be valid for 5 days.'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          isSelected: settings.receiptSMSNotification === 'Yes',
          selectedOptionValues: getAddQuestionDefValue(settings.receiptSMSNotification, formQuestions),
          options: prepareAddQuestionData(formQuestions, 'control_email', 'Email', settings.receiptSMSNotification),
          subType: 'addQuestion',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        },
        condition: () => settings.askBillingInformation === 'Yes' && settings.customerPhone === 'Yes'
      }
    })
  },
  {
    type: 'control_sofort',
    connectionSettings: {
      type: 'credential',
      fields: [
        {
          key: 'configKey',
          label: 'Configuration Key'
        }
      ]
    }
  },
  {
    type: 'control_paysafe',
    connectionSettings: {
      type: 'credential',
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'enabled', text: MODE_TEXT.TEST },
          { value: 'Disabled', text: MODE_TEXT.LIVE }
        ]
      },
      fields: [
        { key: 'account', label: 'Merchant Account Number' }
      ]
    },
    additionalSettings: (settings: I_PAYSAFE_SETTINGS, resourceType: I_RESOURCE_TYPES): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.ccTitle || t('Credit Card'),
          inputClass: 'w-68'
        }
      },
      addressTitle: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Billing Address Label Text'),
          class: DROPDOWN_STYLE
        },
        componentProps: {
          defaultValue: settings.addressTitle || t('Billing Address'),
          inputClass: 'w-68'
        },
        condition: () => resourceType === 'FORM'
      }
    })
  },
  {
    type: 'control_skrill',
    connectionSettings: {
      type: 'credential',
      fields: [
        { key: 'account', label: 'Merchant Account' }
      ],
      modeSettings: {
        key: 'sandbox',
        options: [
          { value: 'disabled', text: MODE_TEXT.LIVE },
          { value: 'enabled', text: MODE_TEXT.TEST }
        ],
        hideCondition: settings => settings && true // hide always
      }
    }
  },
  // {
  //   type: 'control_paypalSPB'
  // },
  {
    type: 'control_onebip',
    connectionSettings: {
      type: 'credential',
      fields: [
        { key: 'username', label: 'Username' },
        { key: 'itemNo', label: 'Product Item No' },
        { key: 'productName', label: 'Product Name' },
        { key: 'productPrice', label: 'Product Price' }
      ]
    }
  }
  // /** Purchase Order */
  // {
  //   type: 'control_payment'
  // }
];

export const getDataFromSettings = (settings: object, currentlyGateway: I_GATEWAYS | null): I_DATA_FROM_SETTINGS => {
  const gatewayProperties = GATEWAY_PROPERTIES.find(gateway => gateway.type === currentlyGateway);
  const connectionSettings = gatewayProperties && gatewayProperties.connectionSettings;
  const modeSettings = connectionSettings && connectionSettings.modeSettings;
  const modeSettingsKey = modeSettings && modeSettings.key;
  const selectedOptionValue = typeof settings[modeSettingsKey] === 'boolean' ? settings[modeSettingsKey].toString() : settings[modeSettingsKey];
  const toggleValue = modeSettings?.options && (selectedOptionValue === undefined ? modeSettings.options[1] : modeSettings.options.find(option => option.value !== selectedOptionValue))?.value;
  return {
    gatewayProperties,
    connectionSettings,
    modeSettings,
    modeSettingsKey,
    selectedOptionValue,
    toggleValue
  };
};
