import React from 'react';
import { Tooltip } from '@jotforminc/tooltip';
import { t } from '@jotforminc/translation';

const ProtectedTag = () => (
  <>
    <div className="isPHIIcon jfTooltipNew-hoverTarget">
      <Tooltip
        v2
        className="isPHITooltip"
        align="Center"
        attach="Left"
      >
        {t('Protected Health Information: This field includes sensitive information. This data can’t be added.')}
      </Tooltip>
    </div>
  </>
);

export default ProtectedTag;
