import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control PayPal Checkout (SPB) gateway connection properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPaypalSPB: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_paypalSPB: {
    paypalButton: {
      title: 'Replace Submit Button With PayPal',
      description: 'Your form will be submitted as soon as payment is completed.',
      type: 'toggle',
      selectedCondition: settings => settings.paypalButton === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      autoSave: true,
      renderCondition: { prop: 'formType', operator: '===', value: 'legacyForm' }
    },
    fundingCredit: {
      title: 'Enable PayPal Credit',
      type: 'toggle',
      selectedCondition: settings => settings.fundingCredit === 'default',
      valueTrue: 'default',
      valueFalse: 'disabled',
      default: 'default',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'payLaterEnabled', operator: '!==', value: 'Yes' },
      autoSave: true
    },
    fundingCard: {
      title: 'Enable Card Payments',
      type: 'toggle',
      selectedCondition: settings => settings.fundingCard === 'default',
      valueTrue: 'default',
      valueFalse: 'disabled',
      default: 'default',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'payLaterEnabled', operator: '!==', value: 'Yes' },
      autoSave: true
    },
    fundingElv: {
      title: 'Enable Electronic Direct Debit',
      type: 'toggle',
      description:
        translationRenderer('Allow ELV (Elektronisches Lastschriftverfahren) payment method for banks in Germany. [1[Learn more]].')({
          renderer1: (text: string) => (
            <a className='color-blue-500 font-bold' href="https://link.jotform.com/Fv1UzBUydD" target="_blank">
              {text}
            </a>
          )
        }),
      selectedCondition: settings => settings.fundingElv === 'default',
      valueTrue: 'default',
      valueFalse: 'disabled',
      default: 'default',
      resources: ['FORM', 'APP'],
      autoSave: true
    },
    infoline: {
      type: 'infoline',
      title: 'PayPal Smart Buttons Style',
      resources: ['FORM', 'APP']
    },
    styleColor: {
      title: 'Button Color',
      type: 'dropdown',
      options: [
        { id: 'gold', key: 'gold', text: 'Gold' },
        { id: 'blue', key: 'blue', text: 'Blue' },
        { id: 'silver', key: 'silver', text: 'Silver' },
        { id: 'black', key: 'black', text: 'Black' }
      ],
      default: 'gold',
      resources: ['FORM', 'APP'],
      autoSave: true
    },
    styleShape: {
      title: 'Button Shape',
      type: 'dropdown',
      options: [
        { id: 'pill', key: 'pill', text: 'Pill' },
        { id: 'rect', key: 'rect', text: 'Rect' }
      ],
      default: 'rect',
      resources: ['FORM', 'APP'],
      autoSave: true
    },
    styleSize: {
      title: 'Button Size',
      type: 'dropdown',
      options: [
        { id: 'small', key: 'small', text: 'Small' },
        { id: 'medium', key: 'medium', text: 'Medium' },
        { id: 'large', key: 'large', text: 'Large' }
      ],
      default: 'medium',
      resources: ['FORM', 'APP'],
      autoSave: true
    },
    styleLabel: {
      title: 'Button Label',
      type: 'dropdown',
      options: [
        { id: 'checkout', key: 'checkout', text: 'PayPal Checkout' },
        { id: 'pay', key: 'pay', text: 'Pay with PayPal' },
        { id: 'buynow', key: 'buynow', text: 'Buy Now' },
        { id: 'paypal', key: 'paypal', text: 'PayPal' }
      ],
      default: 'checkout',
      resources: ['FORM', 'APP'],
      autoSave: true
    },
    styleLayout: {
      title: 'Button Layout',
      type: 'dropdown',
      options: [
        { id: 'vertical', key: 'vertical', text: 'Vertical' },
        { id: 'horizontal', key: 'horizontal', text: 'Horizontal' }
      ],
      default: 'vertical',
      resources: ['FORM', 'APP'],
      autoSave: true
    },
    payLaterEnabled: {
      title: 'Show Pay Later Messaging',
      description: 'Displays information about the installments for Pay Later which is available in the US.',
      type: 'toggle',
      selectedCondition: settings => settings.payLaterEnabled === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'fundingCredit', operator: '!==', value: 'disabled' },
          { prop: 'paymentType', operator: '===', value: 'product' }
        ]
      },
      autoSave: true
    },
    infoline_2: {
      type: 'infoline',
      title: 'Pay Later Messaging Style',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' }
        ]
      }
    },
    payLaterLayout: {
      title: 'Layout Style',
      type: 'dropdown',
      options: [
        { id: 'text', key: 'text', text: 'Text' },
        { id: 'flex', key: 'flex', text: 'Flex' }
      ],
      default: 'text',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' }
        ]
      },
      autoSave: true
    },
    payLaterLogoType: {
      title: 'Logo Type',
      type: 'dropdown',
      options: [
        { id: 'primary', key: 'primary', text: 'Primary' },
        { id: 'alternative', key: 'alternative', text: 'Alternative' },
        { id: 'inline', key: 'inline', text: 'Inline' },
        { id: 'none', key: 'none', text: 'None' }
      ],
      default: 'inline',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' },
          { prop: 'payLaterLayout', operator: '===', value: 'text' }
        ]
      },
      autoSave: true
    },
    payLaterLogoPosition: {
      title: 'Logo Position',
      type: 'dropdown',
      options: [
        { id: 'left', key: 'left', text: 'Left' },
        { id: 'right', key: 'right', text: 'Right' },
        { id: 'top', key: 'top', text: 'Top' }
      ],
      default: 'left',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' },
          { prop: 'payLaterLayout', operator: '===', value: 'text' },
          {
            or: [
              { prop: 'payLaterLogoType', operator: '===', value: 'primary' },
              { prop: 'payLaterLogoType', operator: '===', value: 'alternative' }
            ]
          }
        ]
      },
      autoSave: true
    },
    payLaterTextColor: {
      title: 'Text Color',
      type: 'dropdown',
      options: [
        { id: 'black', key: 'black', text: 'Black' },
        { id: 'white', key: 'white', text: 'White' },
        { id: 'monochrome', key: 'monochrome', text: 'Monochrome' },
        { id: 'grayscale', key: 'grayscale', text: 'Grayscale' }
      ],
      default: 'black',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' },
          { prop: 'payLaterLayout', operator: '===', value: 'text' }
        ]
      },
      autoSave: true
    },
    payLaterTextSize: {
      title: 'Text Size',
      type: 'dropdown',
      options: [
        { id: '10', key: '10', text: '10' },
        { id: '11', key: '11', text: '11' },
        { id: '12', key: '12', text: '12' },
        { id: '13', key: '13', text: '13' },
        { id: '14', key: '14', text: '14' },
        { id: '15', key: '15', text: '15' },
        { id: '16', key: '16', text: '16' }
      ],
      default: '12',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' },
          { prop: 'payLaterLayout', operator: '===', value: 'text' }
        ]
      },
      autoSave: true
    },
    payLaterBackgroundColor: {
      title: 'Message Background',
      type: 'dropdown',
      options: [
        { id: 'blue', key: 'blue', text: 'Blue' },
        { id: 'black', key: 'black', text: 'Black' },
        { id: 'white', key: 'white', text: 'White' },
        { id: 'white-no-border', key: 'white-no-border', text: 'White - No Border' },
        { id: 'gray', key: 'gray', text: 'Gray' },
        { id: 'monochrome', key: 'monochrome', text: 'Monochrome' },
        { id: 'grayscale', key: 'grayscale', text: 'Grayscale' }
      ],
      default: 'blue',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' },
          { prop: 'payLaterLayout', operator: '===', value: 'flex' }
        ]
      },
      autoSave: true
    },
    payLaterRatio: {
      title: 'Banner Ratio',
      type: 'dropdown',
      options: [
        { id: '8x1', key: '8x1', text: '8x1' },
        { id: '20x1', key: '20x1', text: '20x1' }
      ],
      default: '20x1',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'payLaterEnabled', operator: '===', value: 'Yes' },
          { prop: 'paymentType', operator: '===', value: 'product' },
          { prop: 'payLaterLayout', operator: '===', value: 'flex' }
        ]
      },
      autoSave: true
    }
  }
};

export default controlPaypalSPB;
