import { t } from '@jotforminc/translation';
import React from 'react';
import { ScAppThemesPanel } from './ScAppThemesPanel';
import Button from '../../../../components/Button';

const themes = {
  'Default Theme': { // Generate
    appCoverBgColor: '#8093c8',
    appHeaderBgColor: '#ffffff',
    appIconBackground: '#f1863e',
    appFontFamily: 'Circular',
    appFontColor: '#2c3345',
    appBgColor: '#f6f6fa',
    appBgColorEnd: '#f6f6fa',
    listBgColor: '#ffffff',
    itemBorderColor: '#e5e5f0',
    itemBgColor: '#ffffff'
  },
  Healthcare: {
    appCoverBgColor: 'darkblue',
    appBgColor: 'blue',
    appBgColorEnd: 'blue'
  },
  Sakura: {
    appCoverBgColor: 'pink',
    appBgColor: 'lightpink',
    appBgColorEnd: 'lightpink'
  }
};

const AppThemesPanel = () => {
  return Object.entries(themes).map(([themeName, themeProps]) => {
    return (
      <ScAppThemesPanel
        appCoverBgColor={themeProps.appCoverBgColor}
        appHeaderBgColor={themeProps.appHeaderBgColor}
        appIconBackground={themeProps.appIconBackground}
        appFontFamily={themeProps.appFontFamily}
        appFontColor={themeProps.appFontColor}
        appBgColor={themeProps.appBgColor}
        appBgColorEnd={themeProps.appBgColorEnd}
        listBgColor={themeProps.listBgColor}
        itemBorderColor={themeProps.itemBorderColor}
        itemBgColor={themeProps.itemBgColor}
        title={themeName}
        isSelected={1}
        key={themeName}
      >
        <div className="theme-overlay">
          <Button
            className="panel isBlue"
          >
            {t('Current Theme')}
          </Button>
          <Button
            className="panel isGreen"
          >
            {t('Use Theme')}
          </Button>
          <Button
            className="panel isGreen"
          >
            {t('Apply')}
          </Button>
        </div>
        <div className="theme">
          <div className="theme-cover" />
          <div className="theme-header">
            <div className="theme-header-logo" />
            <div className="theme-header-title">
              {t('Text')}
            </div>
          </div>
          <div className="theme-itemList">
            <div className="theme-itemList-item" />
            <div className="theme-itemList-item" />
            <div className="theme-itemList-item" />
          </div>
        </div>
        <div className="theme-name">{themeName}</div>

      </ScAppThemesPanel>
    );
  });
};

export default AppThemesPanel;
