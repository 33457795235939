import React from 'react';
import { DATA_SOURCE_COLUMN_TYPES } from '../../../../../../constants/itemTypes';
import Image from '../ListItemSideItemSelection/subSelections/Image';

const Empty = () => <></>;

export const getSubfield = columnType => {
  // eslint-disable-next-line func-names
  const Component = (function () {
    switch (columnType) {
      case DATA_SOURCE_COLUMN_TYPES.IMAGE: {
        return Image;
      }
      default:
        return Empty;
    }
  }());

  return props => <Component {...props} />;
};
