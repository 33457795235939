import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Mollie gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlMollie: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_mollie: {
    profileId: {
      title: 'Profile',
      type: 'dropdown',
      subType: 'dynamicDropdown',
      default: '',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlMollie;
