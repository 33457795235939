import React from 'react';
import type { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';
import { DialogDescriptionProps } from './dialog.types';
import { primary } from '../../tokens/colors';

export const DialogDescription = (props: PropsWithChildren<DialogDescriptionProps>): JSX.Element => {
  const { children, className, ...rest } = props;

  return (
    <p
      {...rest}
      className={cx(className, primary.text.medium.base, 'text-center text-sm mt-2')}
    >
      {children}
    </p>
  );
};
