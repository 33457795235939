import { t } from '@jotforminc/translation';
import React from 'react';
import Styled from 'styled-components';
import { useSelector } from 'react-redux';
import RPImageUpload from './RPImageUpload';
import SELECTORS from '../../../../../store/selectors';

const ScImageUploadWithLoading = Styled.div`
  width: 100%;

  .imageUploadWithLoading {
    ${({ loading }) => (loading ? 'display: none; ' : 'display: block;')}
  }

  .loading {
    ${({ loading }) => (loading ? 'display: flex;' : 'display: none;')}
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-circle {
      border: 4px solid #c3cad8;
      border-radius: 50%;
      border-top: 4px solid #949eb2;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }

    &-text {
      font-style: italic;
      color: #9e9fb7;
      font-size: 1em;
      text-align: center;
      font-weight: 300;
      margin-top: 10px;
    }
  }
`;

const ImageUploadWithLoadingState = ({ ...props }) => {
  const loading = useSelector(SELECTORS.getIsSelectedItemLoadingStatus);
  // don't unmount ImageUpload component during the loading process
  return (
    <ScImageUploadWithLoading loading={loading ? 1 : 0}>
      <div className="imageUploadWithLoading">
        <RPImageUpload {...props} />
      </div>
      <div className="loading">
        <div className="loading-circle" />
        <div className="loading-text">{t('Uploading...')}</div>
      </div>
    </ScImageUploadWithLoading>
  );
};

export default ImageUploadWithLoadingState;
