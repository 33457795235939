import { useState, useEffect, useRef } from 'react';

export const useKeyboardEvent = (keyArray, callback) => {
  useEffect(() => {
    const handler = e => {
      const { key = '', keyCode } = e;
      if ((key && keyArray.indexOf(key) > -1) || (keyArray.indexOf(keyCode) > -1)) callback();
    };
    global.window.addEventListener('keydown', handler);
    return () => global.window.removeEventListener('keydown', handler);
  }, []);
};

export const useOfflineMode = ({ onOffline: onOfflineFn = f => f, onOnline: onOnlineFn = f => f } = {}) => {
  const onOfflineRef = useRef(onOfflineFn);
  const onOnlineRef = useRef(onOnlineFn);
  if (onOfflineRef.current !== onOfflineFn) onOfflineRef.current = onOfflineFn;
  if (onOnlineRef.current !== onOnlineRef) onOnlineRef.current = onOnlineFn;

  const { current: onOffline } = onOfflineRef;
  const { current: onOnline } = onOnlineRef;

  const handleOfflineState = () => onOffline();
  const handleOnlineState = () => onOnline();

  useEffect(() => {
    window.addEventListener('offline', handleOfflineState);
    window.addEventListener('online', handleOnlineState);

    return () => {
      window.removeEventListener('offline', handleOfflineState);
      window.removeEventListener('online', handleOnlineState);
    };
  }, []);
};

export const useMediaQuery = mediaQuery => {
  if (typeof window !== 'object' || !('matchMedia' in window)) {
    return null;
  }

  const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler);
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();
    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler);
      } catch (e) {
        // Safari isn't supporting mediaQueryList.removeEventListener
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);

  return isVerified;
};

export const useDidUpdateEffect = (fn, inputs) => {
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    if (!isMountingRef.current) {
      return fn();
    }
    isMountingRef.current = false;
  }, inputs);
};
