import { t } from '@jotforminc/translation';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { Button } from '@jotforminc/magnet';

const ProductDetailActionButtons = ({
  isFastCheckoutActive,
  onNavigateOrderNow,
  showDoneButton,
  isProductSelected,
  onProductItemChange,
  onFastCheckout,
  actionButtonText
}) => {
  return (
    <div className={`w-full flex bg-white sticky bottom-0 z-2 px-7 py-5 border-t border-t-navy-100 ${isFastCheckoutActive ? 'justify-end' : 'justify-between'}`}>
      {!isFastCheckoutActive ? (
        <>
          <Button variant='outline' className='border-navy-700 color-navy-700' onClick={onNavigateOrderNow}>{t('Order Now')}</Button>
          {showDoneButton && (
          <Button
            colorStyle={isProductSelected ? 'primary' : 'teams'}
            variant={isProductSelected ? 'outline' : 'filled'}
            onClick={onProductItemChange}
          >
            {actionButtonText}
          </Button>
          )}
        </>
      ) : (
        <Button
          variant='outline'
          className='border-navy-700 color-navy-700'
          onClick={onFastCheckout}
        >
          {t('Order Now')}
        </Button>
      )}
    </div>
  );
};

ProductDetailActionButtons.propTypes = {
  isFastCheckoutActive: bool,
  onNavigateOrderNow: func,
  showDoneButton: bool,
  isProductSelected: bool,
  onProductItemChange: func,
  onFastCheckout: func,
  actionButtonText: string
};

ProductDetailActionButtons.defaultProps = {
  isFastCheckoutActive: false,
  onNavigateOrderNow: f => f,
  showDoneButton: false,
  isProductSelected: false,
  onProductItemChange: f => f,
  onFastCheckout: f => f,
  actionButtonText: ''
};

export default ProductDetailActionButtons;
