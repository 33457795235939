import Bowser from 'bowser';

export const hasClassName = (_element: HTMLElement, expectedClassName: string): boolean => {
  return _element.classList.contains(expectedClassName);
};

const isBrowserEnvironment = typeof window !== 'undefined';
const fakeBowser = {
  is: () => false
};
const BrowserParser = isBrowserEnvironment ? Bowser.getParser(window.navigator.userAgent) : fakeBowser;

export default BrowserParser;
export const FORCE_MOBILE_DEVICE_PARAM = ['forceMobileDevice', '1'];
export const shouldForceMobile = (): boolean => {
  const url = new URL(window.location.href);
  const [key, val] = FORCE_MOBILE_DEVICE_PARAM;
  const forceMobileVal = url.searchParams.get(key);
  return forceMobileVal === val;
};

export const checkMobilePhone = (): boolean => (BrowserParser.is('mobile') && !BrowserParser.is('tablet')) || shouldForceMobile() || window.innerWidth <= 480;
export const checkTablet = (): boolean => (BrowserParser.is('tablet') || window.innerWidth <= 768) && !BrowserParser.is('mobile');
export const checkMobileOrTablet = (): boolean => checkMobilePhone() || checkTablet();
