import {
  FETCH_USER_TEAMS
} from '../actionTypes';
import * as ACTION_TYPES from '../actionTypes';
import { isTeamMember } from '../../constants/team';

const initialState = {
  userTeams: [],
  userTeamPermissions: {},
  isTeamMember
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_TEAMS.SUCCESS: {
      return {
        ...state,
        userTeams: action.payload
      };
    }
    case ACTION_TYPES.FETCH_USER_TEAM_PERMISSIONS.SUCCESS:
      return {
        ...state,
        userTeamPermissions: action.payload
      };
    default:
      break;
  }
  return state;
};

export default teamReducer;
