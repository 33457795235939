import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import ButtonPageSelection from '../ButtonPageSelection';
import SELECTORS from '../../../../../../../../store/selectors';

const DsPageSelection = props => {
  const { linkedResourceID } = props;
  const pages = useSelector(SELECTORS.getPagesWithNames);
  const items = useSelector(SELECTORS.getPortalItems);

  // Exclude pages that not matched with the linkedResourceID
  const excludedPages = pages.filter(page => {
    if (!page.linkedItemID) {
      return true;
    }

    const relatedItem = items.find(item => item.id === page.linkedItemID);
    return relatedItem?.resourceID === linkedResourceID;
  });

  return (
    <ButtonPageSelection {...props} _pages={excludedPages} />
  );
};

DsPageSelection.propTypes = {
  linkedResourceID: string
};

DsPageSelection.defaultProps = {
  linkedResourceID: ''
};

export default DsPageSelection;
