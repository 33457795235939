import React from 'react';
import { useDispatch } from 'react-redux';
import { translationRenderer } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import WarningBox from '@jotforminc/warning-box';
import { changeGatewaySettingsView, changeGatewaySettingsVisibility } from '../store/actionCreators';

const DonationItemAvailabilityWarning = ({ openPaymentGatewayModalClick, sendActionTrackEvent }: ITabs): JSX.Element => {
  const dispatch = useDispatch();

  const handlePaymentGatewayClick = () => {
    if (openPaymentGatewayModalClick) {
      openPaymentGatewayModalClick();
    } else {
      dispatch(changeGatewaySettingsVisibility(true));
      dispatch(changeGatewaySettingsView('gateway-picker'));
    }
    sendActionTrackEvent('paymentGatewayWarningButtonClickedFromPaymentPropertiesPanel');
  };

  return (
    <div className='px-4'>
      <WarningBox
        isLongText
        size="medium"
        wrapperClassName='mt-5 mb-4'
      >
        {translationRenderer('This element won\'t be visible until you integrate with a [1[payment gateway]].')({
          renderer1: str => (
            <Button variant='ghost' onClick={handlePaymentGatewayClick}>
              {str}
            </Button>
          )
        })}
      </WarningBox>
    </div>
  );
};
interface ITabs {
  sendActionTrackEvent: (actionType: string) => void,
  openPaymentGatewayModalClick: () => void,
}

export default DonationItemAvailabilityWarning;
