import React, {
  useCallback, useEffect, useMemo, useRef
} from 'react';
import { t } from '@jotforminc/translation';
import { Modal } from '@jotforminc/uikit';
import { useDispatch, useSelector } from 'react-redux';
import { IconXmark } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import GatewayPicker from '../components/GatewayPicker';
import GatewaySettings from '../components/GatewaySettings';
import {
  IGatewaySettingsContainerRef, IGatewaySettingsPanelHeaderRendererProps, IGatewaySettingsPanelContainer,
  IGatewaySettingsPanelProps, IAllActions, TFormId, IGatewaySettings, TAppName, TAppId, TPaymentType,
  TGateway, TApm, TGatewaySettingsView
} from '../types/common';
import '../styles/gatewayManagement.scss';
import Selectors from '../store/selectors';
import { updateAllProductListSettingsOnProduct } from '../api';
import * as actionCreators from '../store/actionCreators';
import ConnectionModal from '../components/ConnectionModal';
import PaymentProperties from '../constants/PaymentProperties';
import APMPicker from '../components/APMPicker';
import { GATEWAYS_WITH_FIXED_CURRENCY, GATEWAYS_WITH_APM } from '../constants/variables';
import { checkIsGatewayConnected } from '../utils';
import { getFilteredCurrencies } from '../utils/paymentProperties';
import ModalFooterRenderer from './ModalFooterRenderer';

const GatewaySettingsPanel = ({
  Container,
  HeaderRenderer,
  actions,
  onContainerClose,
  checkoutFormId,
  appName,
  appID,
  paymentType,
  view,
  activeGatewaySettings
} : IProps): JSX.Element => {
  const dispatch = useDispatch();
  const activeAPM = useSelector(Selectors.getActiveAPMSelection) as TApm;
  const { currency: currentCurrency } = useSelector(Selectors.getProductListSettings);
  const temporaryActiveGatewaySettings = useSelector(Selectors.getTemporaryActiveGatewaySettings);

  const containerRef = useRef<IGatewaySettingsContainerRef>();

  useEffect(() => {
    if (typeof containerRef.current?.show === 'function') {
      containerRef.current.show();
    }
  }, [containerRef]);

  const onClose = useCallback(() => {
    if (containerRef.current) {
      if (typeof containerRef.current?.hide === 'function') {
        containerRef.current.hide();
      }

      dispatch(actionCreators.changeGatewaySettingsView('gateway-picker'));
      if (GATEWAYS_WITH_FIXED_CURRENCY.includes(activeGatewaySettings.type)) {
        dispatch(actionCreators.updateActiveGatewaySettings({ showCurrencyWarning: false }));
      }

      if (typeof onContainerClose === 'function') {
        onContainerClose();
      }
    }
  }, [containerRef, onContainerClose, dispatch, activeGatewaySettings.type]);

  const renderHeaderTitle = () => {
    let headerTitle;
    switch (view) {
      case 'gateway-settings':
      case 'connection-modal':
        headerTitle = t('{integrationName} Integration Settings').replace('{integrationName}', activeGatewaySettings.builderLabel);
        break;
      case 'apm-picker':
        headerTitle = PaymentProperties[activeAPM].name;
        break;
      case 'gateway-picker':
      default:
        headerTitle = t('Payment Gateways');
        break;
    }
    return (<span className='headerTitle'>{headerTitle}</span>);
  };

  const renderHeaderImage = () => {
    let iconSrc = '';

    switch (view) {
      case 'gateway-settings':
      case 'connection-modal':
        const type = activeGatewaySettings.nameAPM ? activeGatewaySettings.nameAPM : activeGatewaySettings.type;
        iconSrc = PaymentProperties[type].icon;
        break;
      case 'apm-picker':
        iconSrc = PaymentProperties[activeAPM].icon;
        break;
      case 'gateway-picker':
      default:
        iconSrc = 'https://cdn.jotfor.ms/assets/img/payments/gateway_settings_icon_new.svg';
        break;
    }

    return (<img className="headerImage" src={iconSrc} alt='headerImage' />);
  };

  const handleSwitchPaymentGateway = (id: TFormId, data: Partial<IGatewaySettings>) => {
    actions.switchPaymentGateway(id, data)
      .then(response => {
        const possibleCurrencies = getFilteredCurrencies(response[0].type, response[0].nameAPM);
        dispatch(actionCreators.setActiveGatewaySettings({ ...response[0], status: 'notConnected' }));
        dispatch(actionCreators.changeGatewaySettingsView('gateway-settings'));

        if (!(currentCurrency in possibleCurrencies)) {
          updateAllProductListSettingsOnProduct(appID, { platform: appName, questionProperties: { currency: response[0].currency } })
            .then(() => { dispatch(actionCreators.updateProductListSettings({ currency: response[0].currency })); });
        }
      }).catch(err => { console.error(err); });
  };

  const onGatewayListItemClicked = (selectedGateway: TGateway) : void => {
    const data = {
      type: selectedGateway,
      currency: currentCurrency
    };

    if (GATEWAYS_WITH_APM[selectedGateway]) {
      if (GATEWAYS_WITH_APM[selectedGateway].length > 1) {
        dispatch(actionCreators.changeActiveAPMSelection(selectedGateway));
        dispatch(actionCreators.changeGatewaySettingsView('apm-picker'));
        return;
      }
      data.type = PaymentProperties[selectedGateway].type;
      data.nameAPM = selectedGateway;
    }

    handleSwitchPaymentGateway(checkoutFormId, data);
  };

  const paymentPropertiesList = useMemo(() => Object.entries(PaymentProperties).filter(([, { paymentTypes }]) => {
    return (!Array.isArray(paymentTypes) || paymentTypes.includes(paymentType));
  }), [paymentType]);

  return (
    <Container
      className="gatewaySettingsContent"
      innerRef={containerRef}
      HeaderRenderer={(
        <HeaderRenderer
          onClose={onClose}
        >
          <div>
            {renderHeaderImage()}
            {renderHeaderTitle()}
          </div>
        </HeaderRenderer>
      )}
      FooterRenderer={(
        <ModalFooterRenderer
          view={view}
          activeAPM={activeAPM}
          checkoutFormId={checkoutFormId}
          currentCurrency={currentCurrency}
          appID={appID}
          appName={appName}
          handleSwitchPaymentGateway={handleSwitchPaymentGateway}
          isGatewayConnected={checkIsGatewayConnected(activeGatewaySettings)}
          activeGatewaySettings={activeGatewaySettings}
          temporaryActiveGatewaySettings={temporaryActiveGatewaySettings}
        />
      )}
      onClose={onClose}
    >
      <div className="views">
        {view === 'gateway-picker' && (
          <GatewayPicker
            {...{
              paymentPropertiesList,
              onGatewayListItemClicked,
              activeGatewaySettings
            }}
          />
        )}

        {view === 'gateway-settings' && (
          <GatewaySettings
            activeGatewaySettings={activeGatewaySettings}
            temporaryActiveGatewaySettings={temporaryActiveGatewaySettings}
            actions={actions}
            checkoutFormId={checkoutFormId}
            appID={appID}
            appName={appName}
          />
        )}

        {view === 'connection-modal' && (
          <ConnectionModal
            activeGatewaySettings={activeGatewaySettings}
          />
        )}

        {view === 'apm-picker' && (
          <APMPicker
            activeAPM={activeAPM}
          />
        )}
      </div>
    </Container>
  );
};

export interface IProps extends IGatewaySettingsPanelProps {
  actions: Pick<IAllActions, 'fetchConnectionList' | 'switchPaymentGateway'>
  onContainerClose?: () => void,
  activeGatewaySettings?: IGatewaySettings,
  checkoutFormId: TFormId,
  appName: TAppName,
  appID: TAppId,
  paymentType: TPaymentType,
  view: TGatewaySettingsView
}

interface IModalChildren {
  children: React.ReactChild
}

const dialogRenderer = (props: IModalChildren) => <div className="paymentIntegrationModal">{props.children}</div>;
const contentRenderer = (props: IModalChildren) => <div className="paymentIntegrationModalContent">{props.children}</div>;

GatewaySettingsPanel.defaultProps = {
  HeaderRenderer: ({ children, onClose } : IGatewaySettingsPanelHeaderRendererProps) : JSX.Element => (
    <div className="gatewaySettingsHeader">
      {children}
      <Button
        onClick={onClose}
        rounded
        colorStyle='secondary'
        startIcon={IconXmark}
      />
    </div>
  ),
  Container: ({
    children,
    innerRef,
    onClose,
    HeaderRenderer,
    FooterRenderer,
    className
  } : IGatewaySettingsPanelContainer) : JSX.Element => (
    <Modal
      ref={innerRef}
      DialogRenderer={dialogRenderer}
      ContentRenderer={contentRenderer}
      HeaderRenderer={() => HeaderRenderer}
      FooterRenderer={() => FooterRenderer}
      autoFocus={true}
      usePortal={true}
      closeOnOutsideClick={false} // Note: When opened true, please check to confirm dialog
      onModalClose={onClose}
    >
      <div className={className}>
        {children}
      </div>
    </Modal>
  )
};

export default GatewaySettingsPanel;
