import React from 'react';

import { IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { ScIMHeader } from '../../../../components/AppModal/ScAppModal';

const HeaderRenderer = onCloseModal => () => (
  <div className="flex items-center justify-between p-4 xs:px-8 xs:py-4" style={{ borderBottom: '1px solid #E3E5F5' }}>
    <div>
      <h4 className="color-navy-700 font-medium text-md xs:text-lg">{t('Share This App')}</h4>
      <div className="color-navy-300 font-book text-xs mt-1 xs:text-sm">{t('Choose a social media platform to share this app.')}</div>
    </div>
    <ScIMHeader className='p-0'>
      <Button
        data-testid="close-modal"
        aria-label={t('Close Modal')}
        onClick={onCloseModal}
        startIcon={IconXmark}
        colorStyle='secondary'
        rounded
      />
    </ScIMHeader>
  </div>
);

export default HeaderRenderer;
