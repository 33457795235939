import React, { Children, Fragment, cloneElement } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { cx } from 'class-variance-authority';

import { DialogActionsProps, dialogActionsDefaultProps } from './dialog.types';

export const DialogActions = (props: PropsWithChildren<DialogActionsProps>): JSX.Element => {
  const {
    className, children, defaultAlignment, ...rest
  } = props;

  const childrenArray = Children.toArray(children).filter(child => !!child);

  return (
    <div {...rest} className={cx(className, 'flex flex-col xs:flex-row justify-center items-center gap-4 w-full p-8')}>
      {childrenArray.length > 0 && defaultAlignment ? childrenArray.map((child, index) => {
        const alignLeft = child && childrenArray.length === 3 && index === 0;
        const childType = child as ReactElement;
        const key = `dialog-actions-${index}`;

        return (
          <Fragment key={key}>
            {childType !== null && cloneElement(childType, {
              ...childType.props,
              className: alignLeft ? cx(childType.props.className, 'xs:mr-auto w-full xs:w-auto') : cx(childType.props.className, 'w-full xs:w-auto')
            })}
          </Fragment>
        );
      }) : children}
    </div>
  );
};

DialogActions.defaultProps = dialogActionsDefaultProps;
