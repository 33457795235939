import { loadJS } from '@jotforminc/utils';

export function loadXDSScript(callback: () => void): void {
  loadJS('jotFormxds', 'https://cdn.jotfor.ms/js/libraries/xds-server/jotFormxds.js', callback);
}

export const loadScriptSync = (src: string, id: string, content: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const script = global.document.createElement('script');
    if (src) {
      script.src = src;
    }
    if (id) {
      script.id = id;
    }
    if (content) {
      const inlineScript = global.document.createTextNode(content);
      script.appendChild(inlineScript);
    }
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', e => {
      reject(e);
    });
    global.document.body.appendChild(script);
  });
};

export const getUrlParameterByName = (name: string, url: string = window.location.href): string | null => {
  const localName = name.replace(/[[\]]/gi, '\\$&');
  const regex = new RegExp(`[?&]${localName}(=([^&#]*)|&|#|$)`, 'gi');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const arrayToString = (arr: any[], seperator = '\n'): string => arr.join(seperator);

export const stringToArray = (val: string, seperator = '\n'): string[] => val.split(seperator);

export const searchValue = (val: string, query: string, seperator = '|'): boolean => stringToArray(val, seperator).indexOf(query) > -1;

export const changeInArray = (arr: unknown[], index: number, newItem: unknown): unknown[] => arr.map((item, i) => (i === index ? newItem : item));

export const isAdmin = (): boolean => window.isAdminUser;

export const isHipaa = (): boolean => window.isHIPAA === '1';
