import Styled from 'styled-components';

export const ScWrapper = Styled.div`
  height: 100%;
  background-color: #f3f3fd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  font-family: Circular, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell, "Helvetica Neue", sans-serif;
`;
