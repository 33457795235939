import {
  ComponentPropsWithoutRef, ElementType, ReactNode
} from 'react';
import { ForwardedComponentType } from '../../types/system.types';
import { FormControlContextType, commonInputDefaults } from '../../contexts';
import { BaseInputAddonDirection, BaseInputAddonVariant, Icon } from '../../constants/common.types';
import { ColorPickerProps, colorPickerDefaultProps } from '../ColorPicker/color-picker.types';

// BaseInput & BaseInputAddon
export type NativeInputTypes = Omit<ComponentPropsWithoutRef<'input'>, keyof FormControlContextType | 'prefix'>
export type CommonInputProps = FormControlContextType & NativeInputTypes & {
  ghost?: boolean
};

type BaseInputOwnProps = {
  htmlSize?: number,
  addonStart?: ReactNode,
  addonEnd?: ReactNode,
  as?: 'input' | 'dropdown',
  expand?: boolean,
  useDefaultStyleInReadOnly?: boolean
}

export type BaseInputProps = BaseInputOwnProps & CommonInputProps;

export type BaseInputAddonProps = Partial<FormControlContextType> & {
  icon?: Icon,
  text?: string,
  direction?: BaseInputAddonDirection
  variant?: BaseInputAddonVariant,
  useInputColor?: boolean
}

export type BaseInputAddonComponentType = ForwardedComponentType<BaseInputAddonProps, 'span'>

export type AppendixType = {
  as?: ElementType
  icon?: Icon,
  text?: string,
  variant?: BaseInputAddonVariant,
  useInputColor?: boolean
  [otherProps: string]: unknown
}

// InputText
export type InputTextProps = CommonInputProps & {
  showLockIconInReadOnly?: boolean
  useDefaultStyleInReadOnly?: boolean
  prefix?: AppendixType
  suffix?: AppendixType
}

// InputNumber
export type InputNumberProps = Omit<CommonInputProps, 'step'> & {
  step?: number
}

// InputColor
export type InputColorProps = CommonInputProps & {
  pickerOptions?: Pick<ColorPickerProps, 'disableAlpha' | 'offset' | 'onSwatchHover' | 'placement' | 'presetColors' | 'buttonAttrs' | 'pickerAttrs'>
}

// Defaults
export const baseInputDefaultProps:Partial<BaseInputProps> = { ...commonInputDefaults, as: 'input' };

const appendixDefaults:AppendixType = {
  as: 'span',
  icon: undefined,
  text: undefined,
  variant: 'ghost'
};

export const inputAddonDefaultProps:Partial<BaseInputAddonProps> = {
  ...commonInputDefaults,
  direction: 'start',
  variant: 'ghost'
};

export const inputTextDefaultProps:Partial<InputTextProps> = {
  ...commonInputDefaults,
  showLockIconInReadOnly: true,
  prefix: appendixDefaults,
  suffix: appendixDefaults
};

export const inputNumberDefaultProps:InputNumberProps = {
  ...commonInputDefaults,
  step: 1
};

const {
  disableAlpha, presetColors, offset, placement, onSwatchHover
}:InputColorProps['pickerOptions'] = colorPickerDefaultProps;

export const inputColorDefaultProps:InputColorProps = {
  ...commonInputDefaults,
  pickerOptions: {
    disableAlpha,
    presetColors,
    offset,
    placement,
    onSwatchHover
  }
};
