import React, { ReactNode } from 'react';
import type { PAYMENT_FIELDS } from '@jotforminc/payment-constants';
import type { CONNECTION_GET_SANDBOX_TYPES } from '@jotforminc/payment-gateways';
import { translationRenderer } from '@jotforminc/translation';
import { IconProductTeamsBasicFilled, IconBuildings, IconUserFilled } from '@jotforminc/svg-icons';

export const SCROLLABLE_CONTAINER_CLASSLIST = {
  FORM: '.rightPanelContent',
  APP: 'div[data-tabid="General"]'
};

export const OWNER_INDICATOR_CONFIG = {
  user: {
    Icon: IconUserFilled,
    colorClass: 'color-yellow-400'
  },
  team: {
    Icon: IconProductTeamsBasicFilled,
    colorClass: 'color-blue-400'
  },
  org: {
    Icon: IconBuildings,
    colorClass: 'color-orange-400'
  }
};

export const MODE_INDICATORS: Array<{
  sandbox: CONNECTION_GET_SANDBOX_TYPES,
  text: string,
  bgColor: string
}> = [
  {
    sandbox: '0',
    text: 'Live',
    bgColor: 'bg-blue-500'
  },
  {
    sandbox: '1',
    text: 'Test',
    bgColor: 'bg-yellow-500'
  },
  {
    sandbox: '2',
    text: 'Dev',
    bgColor: 'bg-yellow-500'
  }
];

export const PLUG_NOCONNECTION = {
  iconClass: 'color-yellow-500',
  btnClass: 'bg-yellow-100 hover:bg-yellow-100'
};

export const CONNECTION_STATUSES = {
  0: {
    title: 'Connection valid',
    titleClass: '',
    descriptionClass: ''
  },
  1: {
    title: 'Connection attached!',
    titleClass: '',
    descriptionClass: ''
  },
  2: {
    title: 'Connection deleted',
    titleClass: '',
    descriptionClass: ''
  },
  3: {
    title: 'Connection failed!',
    titleClass: 'color-red-500 font-normal text-xs',
    descriptionClass: 'color-gray-600 mt-1 font-normal text-xs'
  },
  5: {
    title: 'Connection disabled!',
    titleClass: '',
    descriptionClass: ''
  },
  99: {
    title: '',
    titleClass: '',
    descriptionClass: ''
  }
};

type GATEWAY_INFO_TEXTS = Partial<{ [key in PAYMENT_FIELDS]: string | ReactNode; }>;
export const gatewayInfoTexts: GATEWAY_INFO_TEXTS = {
  control_paypalInvoicing: translationRenderer('Visit our [1[{link}]] to learn how to integrate PayPal Invoicing with Jotform.')({
    renderer1: () => (
      <a href="https://www.jotform.com/help/565-How-to-integrate-PayPal-Invoicing-with-JotForm" target="_blank">user guide</a>
    )
  }),
  control_venmo: translationRenderer('Our [1[{venmo}]] integration is powered by PayPal Business and is available only to U.S.-based merchants and customers.')({
    renderer1: () => (
      <a href="https://venmo.com/" target="_blank">Venmo</a>
    )
  })
};

const POWERED_BY = 'Powered by';
type GATEWAY_POWEREDBY_LABELS = Partial<{ [key in PAYMENT_FIELDS]: string; }>;
export const gatewayPoweredByLabels: GATEWAY_POWEREDBY_LABELS = {
  control_bluepay: `${POWERED_BY} Clover`,
  control_echeck: `${POWERED_BY} Authorize.net`,
  control_cashApp: `${POWERED_BY} Square`,
  control_afterpay: `${POWERED_BY} Square`,
  control_clearpay: `${POWERED_BY} Square`
};
