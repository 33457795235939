import React from 'react';
import { func, shape } from 'prop-types';
import { t } from '@jotforminc/translation';

import { ScCustomColorPalette } from './scItemStyler';
import { ScTabMenu } from '../../styles/scRightPanel';
import { ColorPicker } from '../ColorPicker';
import { ItemVisualDefaultProps, ItemVisualPropTypes } from '../../../../constants/propTypes';
import { DEFAULT_CUSTOM_PALETTE_PROPS } from '../../constants/colorPalette';

const CustomPalette = ({
  onChange, customPaletteProps, ...props
}) => {
  return (
    <ScCustomColorPalette>
      {Object.entries(customPaletteProps).map(([key, { title, offsetX, placement }]) => (
        <ScTabMenu column key={key}>
          <label htmlFor={key}>{t(title)}</label>
          <ColorPicker
            id={key}
            defaultValue={props[key]}
            value={props[key]}
            onChange={e => onChange({ [key]: e })}
            offsetX={offsetX}
            placement={placement}
          />
        </ScTabMenu>
      ))}
    </ScCustomColorPalette>
  );
};

CustomPalette.propTypes = {
  onChange: func,
  customPaletteProps: shape({}),
  ...ItemVisualPropTypes
};

CustomPalette.defaultProps = {
  onChange: f => f,
  customPaletteProps: DEFAULT_CUSTOM_PALETTE_PROPS, // if there is no customPaletteProps at the right panel constants
  ...ItemVisualDefaultProps
};

export default CustomPalette;
