import { checkLiveChatSupport } from '../modules/api';

export const initLiveChat = async () => {
  try {
    const res = await checkLiveChatSupport();

    if (res && res !== 'FALSE') {
      const myPattern = new RegExp(/<script type='text\/javascript'>([\s\S]*?)<\/script>/g);
      // eslint-disable-next-line no-eval
      eval(myPattern.exec(res)[1]);
      window.chatsupport = true;
    }
  } catch (error) {
    console.log(error);
  }
};
