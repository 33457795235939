import React, { useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Hooks } from '@jotforminc/uikit';
import { APP_DESIGN_MODES } from '../../../constants/designModes';
import { setAppDesignModeAction, setRightPanelModeAction, toggleRightPanelAction } from '../../../../../store/actionCreators';
import { RightPanelContainer } from '../../RightPanelContainer';
import SELECTORS from '../../../../../store/selectors';
import RightPanelGenerator from './RightPanelGenerator';
import RightPanelTitleRenderer from './RightPanelTitleRenderer';
import MultipleRightPanel from '../MultipleRightPanel';
import { RightPanelModes } from '../constants';
import PortalErrorBoundary from '../../../../../components/PortalErrorBoundary';

const RightPanel = () => {
  const dispatch = useDispatch();

  const rightPanelMode = useSelector(SELECTORS.getRightPanelModeSelector);
  const appDesignMode = useSelector(SELECTORS.getAppDesignModeSelector);
  const selectedItemID = useSelector(SELECTORS.getSelectedPortalItem);
  const isWidgetMode = rightPanelMode === RightPanelModes.APP_WIDGET;
  const itemProps = useSelector(SELECTORS.getPortalItemByIDSelector(selectedItemID));

  const {
    type: itemType,
    clientID,
    formTitle,
    buttonRole
  } = itemProps;

  const _widgetProps = useSelector(SELECTORS.getWidgetByClientIDSelector(clientID));
  const { name: widgetName } = _widgetProps;

  const mayOpen = useMemo(() => !!rightPanelMode, [rightPanelMode]);

  const RightPanelComponent = useCallback(props => {
    const {
      // eslint-disable-next-line react/prop-types
      mayOpen: canOpen, selectedItemID: rpSelectedItemID, rightPanelMode: mode, selectedItemProps, widgetProps
    } = props;
    const resetKeys = [canOpen, rpSelectedItemID, rightPanelMode];
    const isMultiplePanelMode = mode === RightPanelModes.MULTIPLE_ITEM;
    return (
      <PortalErrorBoundary
        onReset={() => {
          dispatch(toggleRightPanelAction(false));
          dispatch(setRightPanelModeAction(RightPanelModes.APP_DESIGNER));
        }}
        resetKeys={resetKeys}
      >
        { isMultiplePanelMode ? (<MultipleRightPanel />)
          : (
            <RightPanelGenerator
              mode={mode}
              selectedItemID={rpSelectedItemID}
              itemProps={selectedItemProps}
              widgetProps={widgetProps}
            />
          )}
      </PortalErrorBoundary>
    );
  }, []);

  Hooks.useEffectIgnoreFirst(() => {
    if (!mayOpen) {
      dispatch(toggleRightPanelAction(false));
      if (appDesignMode !== APP_DESIGN_MODES.app) dispatch(setAppDesignModeAction(APP_DESIGN_MODES.app)); // TODO idk why is this necessary
    }
  }, [mayOpen]);

  const rightPanelTitle = useMemo(() => (
    <RightPanelTitleRenderer
      rightPanelMode={rightPanelMode}
      itemType={itemType}
      itemTitle={isWidgetMode ? widgetName : formTitle}
      buttonRole={buttonRole}
    />
  ), [rightPanelMode, itemType, formTitle, mayOpen, isWidgetMode, widgetName, buttonRole]);

  return (
    <RightPanelContainer title={rightPanelTitle}>
      {mayOpen && (
        <RightPanelComponent
          mayOpen={mayOpen}
          selectedItemID={selectedItemID}
          rightPanelMode={rightPanelMode}
          selectedItemProps={itemProps}
          widgetProps={_widgetProps}
        />
      )}
    </RightPanelContainer>
  );
};

export default memo(RightPanel);
