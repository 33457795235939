import { rgba } from 'polished';

const thatLaci = '#2c3345';
const thatGreen = '#54b45c';
const thatGray = '#d8dae9';

export const theme = {
  fontSize: '16px',

  itemBg: '#f7f7f8',
  itemBgFocus: rgba(thatGray, 0.3),
  itemBgSelected: rgba(thatGreen, 0.15),
  itemBorder: thatGray,
  itemBorderSelected: thatGreen,
  itemBorderHover: thatGreen,
  lineHeight: `${21 / 16}em`,
  itemPadding: `${15 / 16}em`,

  // title
  titleColor: thatLaci,
  titleFontSize: `${18 / 16}em`,
  titleFontWeight: '700',

  // details
  detailsColor: '#8d8eaa',
  detailsFontSize: `${14 / 16}em`,

  // checkbox
  chBorder: thatGray,
  chSelected: thatGreen,
  // eslint-disable-next-line max-len
  chIcon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-width='2' d='M4 8.025L6.994 10.951 12.905 4.5' transform='translate%28-3 -3%29'/%3E%3C/svg%3E%0A"
};
