import {
  bool, func, node, number, string
} from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ConfirmationModal from '../ConfirmationModal';

import 'react-circular-progressbar/dist/styles.css';
import { ScCircularProgressBar } from './ScCircularProgressBar';

const StillEditingModal = forwardRef(({
  backText,
  confirmText,
  onReject,
  onResolve,
  title,
  description,
  defaultVisible,
  timeout,
  content,
  onClose
}, ref) => {
  const [remainingTime, setRemainingTime] = useState(timeout);

  const progressValue = remainingTime / 1000;

  const maxProgressValue = timeout / 1000; // second
  const icon = (
    <ScCircularProgressBar>
      <CircularProgressbar
        value={progressValue}
        text={`${progressValue}`}
        minValue={0}
        maxValue={maxProgressValue}
        className="circular-progress-bar"
        counterClockwise={true}
        styles={buildStyles({
          textColor: '#0087E0',
          pathColor: '#0087E0',
          trailColor: '#69C3FF80',
          textSize: '31px'
        })}
      />
    </ScCircularProgressBar>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(remainingTime - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    if (remainingTime === 0) {
      onReject();
    }
  }, [remainingTime]);

  return (
    <ConfirmationModal
      ref={ref}
      backText={backText}
      confirmText={confirmText}
      subtitle=""
      onBack={onReject}
      onConfirm={onResolve}
      title={title}
      description={description}
      defaultVisible={defaultVisible}
      icon={icon}
      confirmButtonColor="success"
      onClose={onClose}
    >
      {content}
    </ConfirmationModal>
  );
});

StillEditingModal.propTypes = {
  backText: string,
  confirmText: string,
  onReject: func,
  onResolve: func,
  title: string,
  description: string,
  defaultVisible: bool,
  timeout: number,
  content: node,
  onClose: func
};

StillEditingModal.defaultProps = {
  backText: '',
  confirmText: '',
  onReject: f => f,
  onResolve: f => f,
  title: '',
  description: '',
  defaultVisible: true,
  timeout: 30 * 1000, // 30 second,
  content: null,
  onClose: f => f
};

export default StillEditingModal;
