import { Hooks } from '@jotforminc/uikit';
import { elementType, func, string } from 'prop-types';
import React, {
  useCallback, useMemo, useRef, useState
} from 'react';
import styled from 'styled-components';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';

import ImageUpload from './ImageUpload';

const ScTetheredImageUpload = styled.div`
  width: 100%;
  position: relative;

  .imageUpload {
    position: absolute;
    top: 40px;
    box-shadow: 0px 8px 16px 0px #0a0b0d52,0px 2px 4px 0px #0a0b0d0a;

    .tabContent {
      border-radius: 0px 0px 4px 4px;
    }
  }
`;

const TetheredImageUpload = ({
  value,
  RemoveButtonText = t('Remove File'),
  onImageRemove,
  onChange,
  PreviewWrapperRenderer,
  ImageRenderer,
  ImageLoadingRenderer,
  ImageNameRenderer
}) => {
  const imageUploadPopoverRef = useRef();
  const targetRef = useRef();

  const [isImageLoading, setImageLoading] = useState(true);
  const [isImageUploadVisible, setImageUploadVisible] = Hooks.useClickOutsideState(false, [imageUploadPopoverRef, targetRef]);

  const anyFileUploaded = useMemo(() => value, [value]);

  const handleOnChange = useCallback(newValue => {
    onChange(newValue);
  }, [onChange]);

  const handleOnRemove = useCallback(() => {
    onImageRemove();
    onChange('');
  }, [onChange]);

  const handleImageLoading = useCallback(() => {
    setImageLoading(false);
  }, []);

  const imageName = useMemo(() => {
    if (!value) return;

    const parsedURL = value.split('/');
    const name = parsedURL[parsedURL.length - 1];

    return name;
  }, [value]);

  const Preview = useCallback(() => {
    return (
      <PreviewWrapperRenderer>
        <div className="leftSideWrapper">
          <ImageRenderer
            className="previewImg"
            src={value} alt='alt' onLoad={handleImageLoading}
            style={{ ...!isImageLoading ? { display: 'block' } : { display: 'none' } }}
          />
          <ImageLoadingRenderer style={{ ...isImageLoading ? { display: 'block' } : { display: 'none' } }}>Loading...</ImageLoadingRenderer>
        </div>
        <div className="rightSideWrapper">
          <ImageNameRenderer className="imgName">{imageName}</ImageNameRenderer>
          <Button type="button" className='mt-4' onClick={handleOnRemove}>{RemoveButtonText}</Button>
        </div>
      </PreviewWrapperRenderer>
    );
  }, [isImageLoading, RemoveButtonText, value, onChange, imageName]);

  const Uploader = useCallback(() => {
    return (
      <ScTetheredImageUpload ref={imageUploadPopoverRef}>
        <Button
          type="button"
          variant='outline'
          className='customMagnetBtn'
          fullWidth
          onClick={(() => setImageUploadVisible(!isImageUploadVisible))}
          ref={targetRef}
        >
          {t('Choose a File')}
        </Button>
        {
          isImageUploadVisible && (
          <ImageUpload
            onImageSelect={handleOnChange}
            onImageUpload={val => handleOnChange(val.url)}
            onImageRemove={() => handleOnChange('')}
            onLinkTextInputSubmit={onChange}
            renderTabs={['upload', 'choose', 'link']}
            useAPI={true}
            forceSelect={false}
          />
          )
        }
      </ScTetheredImageUpload>
    );
  }, [isImageUploadVisible, targetRef]);

  return (anyFileUploaded ? <Preview /> : <Uploader />);
};

TetheredImageUpload.propTypes = {
  value: string,
  onImageRemove: func,
  PreviewWrapperRenderer: elementType,
  ImageRenderer: elementType,
  // eslint-disable-next-line react/require-default-props
  RemoveButtonText: string, // default prop is given above
  ImageLoadingRenderer: elementType,
  ImageNameRenderer: elementType,
  onChange: func
};

TetheredImageUpload.defaultProps = {
  value: undefined,
  onImageRemove: f => f,
  PreviewWrapperRenderer: props => <div {...props} />,
  // eslint-disable-next-line
  ImageRenderer: props => <img {...props} />,
  ImageLoadingRenderer: props => <div {...props} />,
  ImageNameRenderer: props => <div {...props} />,
  onChange: f => f
};

export default TetheredImageUpload;
