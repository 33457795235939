import { t } from '@jotforminc/translation';

export const countries = [
  { text: t('Default'), value: '' },
  { text: t('Albania'), value: 'AL' },
  { text: t('Algeria'), value: 'DZ' },
  { text: t('Andorra'), value: 'AD' },
  { text: t('Angola'), value: 'AO' },
  { text: t('Anguilla'), value: 'AI' },
  { text: t('Antigua and Barbuda'), value: 'AG' },
  { text: t('Argentina'), value: 'AR' },
  { text: t('Armenia'), value: 'AM' },
  { text: t('Aruba'), value: 'AW' },
  { text: t('Australia'), value: 'AU' },
  { text: t('Austria'), value: 'AT' },
  { text: t('Azerbaijan Republic'), value: 'AZ' },
  { text: t('Bahamas'), value: 'BS' },
  { text: t('Bahrain'), value: 'BH' },
  { text: t('Barbados'), value: 'BB' },
  { text: t('Belgium'), value: 'BE' },
  { text: t('Belize'), value: 'BZ' },
  { text: t('Benin'), value: 'BJ' },
  { text: t('Bermuda'), value: 'BM' },
  { text: t('Bhutan'), value: 'BT' },
  { text: t('Bolivia'), value: 'BO' },
  { text: t('Bosnia and Herzegovina'), value: 'BA' },
  { text: t('Botswana'), value: 'BW' },
  { text: t('Brazil'), value: 'BR' },
  { text: t('Brunei'), value: 'BN' },
  { text: t('Bulgaria'), value: 'BG' },
  { text: t('Burkina Faso'), value: 'BF' },
  { text: t('Burundi'), value: 'BI' },
  { text: t('Cambodia'), value: 'KH' },
  { text: t('Canada'), value: 'CA' },
  { text: t('Cape Verde'), value: 'CV' },
  { text: t('Cayman Islands'), value: 'KY' },
  { text: t('Czech Republic'), value: 'CZ' },
  { text: t('Chad'), value: 'TD' },
  { text: t('Chile'), value: 'CL' },
  { text: t('China'), value: 'CN' },
  { text: t('Colombia'), value: 'CO' },
  { text: t('Comoros'), value: 'KM' },
  { text: t('Cook Islands'), value: 'CK' },
  { text: t('Costa Rica'), value: 'CR' },
  { text: t('Croatia'), value: 'HR' },
  { text: t('Cyprus'), value: 'CY' },
  { text: t('Denmark'), value: 'DK' },
  { text: t('Djibouti'), value: 'DJ' },
  { text: t('Dominica'), value: 'DM' },
  { text: t('Dominican Republic'), value: 'DO' },
  { text: t('Ecuador'), value: 'EC' },
  { text: t('El Salvador'), value: 'SV' },
  { text: t('Eritrea'), value: 'ER' },
  { text: t('Estonia'), value: 'EE' },
  { text: t('Ethiopia'), value: 'ET' },
  { text: t('Falkland Islands'), value: 'FK' },
  { text: t('Faroe Islands'), value: 'FO' },
  { text: t('Fiji'), value: 'FJ' },
  { text: t('Finland'), value: 'FI' },
  { text: t('France'), value: 'FR' },
  { text: t('French Guiana'), value: 'GF' },
  { text: t('French Polynesia'), value: 'PF' },
  { text: t('Gabon Republic'), value: 'GA' },
  { text: t('Gambia'), value: 'GM' },
  { text: t('Germany'), value: 'DE' },
  { text: t('Gibraltar'), value: 'GI' },
  { text: t('Greece'), value: 'GR' },
  { text: t('Greenland'), value: 'GL' },
  { text: t('Grenada'), value: 'GD' },
  { text: t('Guadeloupe'), value: 'GP' },
  { text: t('Guatemala'), value: 'GT' },
  { text: t('Guinea'), value: 'GN' },
  { text: t('Guinea Bissau'), value: 'GW' },
  { text: t('Guyana'), value: 'GY' },
  { text: t('Honduras'), value: 'HN' },
  { text: t('Hong Kong'), value: 'HK' },
  { text: t('Hungary'), value: 'HU' },
  { text: t('Iceland'), value: 'IS' },
  { text: t('India'), value: 'IN' },
  { text: t('Indonesia'), value: 'ID' },
  { text: t('Ireland'), value: 'IE' },
  { text: t('Israel'), value: 'IL' },
  { text: t('Italy'), value: 'IT' },
  { text: t('Jamaica'), value: 'JM' },
  { text: t('Japan'), value: 'JP' },
  { text: t('Jordan'), value: 'JO' },
  { text: t('Kazakhstan'), value: 'KZ' },
  { text: t('Kenya'), value: 'KE' },
  { text: t('Kiribati'), value: 'KI' },
  { text: t('Kuwait'), value: 'KW' },
  { text: t('Kyrgyzstan'), value: 'KG' },
  { text: t('Laos'), value: 'LA' },
  { text: t('Latvia'), value: 'LV' },
  { text: t('Lesotho'), value: 'LS' },
  { text: t('Liechtenstein'), value: 'LI' },
  { text: t('Lithuania'), value: 'LT' },
  { text: t('Luxembourg'), value: 'LU' },
  { text: t('Madagascar'), value: 'MG' },
  { text: t('Malawi'), value: 'MW' },
  { text: t('Malaysia'), value: 'MY' },
  { text: t('Maldives'), value: 'MV' },
  { text: t('Mali'), value: 'ML' },
  { text: t('Malta'), value: 'MT' },
  { text: t('Marshall Islands'), value: 'MH' },
  { text: t('Martinique'), value: 'MQ' },
  { text: t('Mauritania'), value: 'MR' },
  { text: t('Mauritius'), value: 'MU' },
  { text: t('Mayotte'), value: 'YT' },
  { text: t('Mexico'), value: 'MX' },
  { text: t('Micronesia'), value: 'FM' },
  { text: t('Mongolia'), value: 'MN' },
  { text: t('Montserrat'), value: 'MS' },
  { text: t('Morocco'), value: 'MA' },
  { text: t('Mozambique'), value: 'MZ' },
  { text: t('Namibia'), value: 'NA' },
  { text: t('Nauru'), value: 'NR' },
  { text: t('Nepal'), value: 'NP' },
  { text: t('Netherlands'), value: 'NL' },
  { text: t('Netherlands Antilles'), value: 'AN' },
  { text: t('New Caledonia'), value: 'NC' },
  { text: t('New Zealand'), value: 'NZ' },
  { text: t('Nicaragua'), value: 'NI' },
  { text: t('Niger'), value: 'NE' },
  { text: t('Niue'), value: 'NU' },
  { text: t('Norfolk Island'), value: 'NF' },
  { text: t('Norway'), value: 'NO' },
  { text: t('Oman'), value: 'OM' },
  { text: t('Palau'), value: 'PW' },
  { text: t('Panama'), value: 'PA' },
  { text: t('Papua New Guinea'), value: 'PG' },
  { text: t('Peru'), value: 'PE' },
  { text: t('Philippines'), value: 'PH' },
  { text: t('Pitcairn Islands'), value: 'PN' },
  { text: t('Poland'), value: 'PL' },
  { text: t('Portugal'), value: 'PT' },
  { text: t('Qatar'), value: 'QA' },
  { text: t('Republic of the Congo'), value: 'CG' },
  { text: t('Reunion'), value: 'RE' },
  { text: t('Romania'), value: 'RO' },
  { text: t('Russia'), value: 'RU' },
  { text: t('Rwanda'), value: 'RW' },
  { text: t('Saint Kitts and Nevis Anguilla'), value: 'KN' },
  { text: t('Saint Pierre and Miquelon'), value: 'PM' },
  { text: t('Saint Vincent and Grenadines'), value: 'VC' },
  { text: t('Samoa'), value: 'WS' },
  { text: t('San Marino'), value: 'SM' },
  { text: t('São Tomé and Príncipe'), value: 'ST' },
  { text: t('Saudi Arabia'), value: 'SA' },
  { text: t('Senegal'), value: 'SN' },
  { text: t('Seychelles'), value: 'SC' },
  { text: t('Sierra Leone'), value: 'SL' },
  { text: t('Singapore'), value: 'SG' },
  { text: t('Slovakia'), value: 'SK' },
  { text: t('Slovenia'), value: 'SI' },
  { text: t('Solomon Islands'), value: 'SB' },
  { text: t('South Africa'), value: 'ZA' },
  { text: t('South Korea'), value: 'KR' },
  { text: t('Spain'), value: 'ES' },
  { text: t('Sri Lanka'), value: 'LK' },
  { text: t('St. Helena'), value: 'SH' },
  { text: t('St. Lucia'), value: 'LC' },
  { text: t('Suriname'), value: 'SR' },
  { text: t('Svalbard and Jan Mayen Islands'), value: 'SJ' },
  { text: t('Swaziland'), value: 'SZ' },
  { text: t('Sweden'), value: 'SE' },
  { text: t('Switzerland'), value: 'CH' },
  { text: t('Taiwan'), value: 'TW' },
  { text: t('Tajikistan'), value: 'TJ' },
  { text: t('Tanzania'), value: 'TZ' },
  { text: t('Thailand'), value: 'TH' },
  { text: t('Togo'), value: 'TG' },
  { text: t('Tonga'), value: 'TO' },
  { text: t('Trinidad and Tobago'), value: 'TT' },
  { text: t('Tunisia'), value: 'TN' },
  { text: t('Turkey'), value: 'TR' },
  { text: t('Turkmenistan'), value: 'TM' },
  { text: t('Turks and Caicos Islands'), value: 'TC' },
  { text: t('Tuvalu'), value: 'TV' },
  { text: t('Uganda'), value: 'UG' },
  { text: t('Ukraine'), value: 'UA' },
  { text: t('United Arab Emirates'), value: 'AE' },
  { text: t('United Kingdom'), value: 'GB' },
  { text: t('United States'), value: 'US' },
  { text: t('Uruguay'), value: 'UY' },
  { text: t('Vanuatu'), value: 'VU' },
  { text: t('Vatican City State'), value: 'VA' },
  { text: t('Venezuela'), value: 'VE' },
  { text: t('Vietnam'), value: 'VN' },
  { text: t('Virgin Islands (British)'), value: 'VG' },
  { text: t('Wallis and Futuna Islands'), value: 'WF' },
  { text: t('Yemen'), value: 'YE' },
  { text: t('Zambia'), value: 'ZM' }
];
