import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import * as ACTION_CREATORS from '../../store/actionCreators';
import { RightPanelModes } from '../../modules/Builder/components/HomePage/constants';
import { UI_PROPS } from '../../constants';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { MODALS } from '../../constants/modals';

const DonationGatewayWarning = ({ actionTrackerType = '' }) => {
  const dispatch = useDispatch();

  const handlePaymentGatewayClick = e => {
    e.stopPropagation();
    if (isFeatureEnabled(FEATURE_NAMES.NewPaymentModal)) {
      dispatch(ACTION_CREATORS.setPaymentModalFlowStep('select-gateway'));
      dispatch(ACTION_CREATORS.setPaymentModalFlowType('ELEMENT_TO_GATEWAY'));
      dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PAYMENT_GATEWAY_PICKER_MODAL }));
    } else {
      dispatch(ACTION_CREATORS.setUIProp(UI_PROPS.isGatewaySettingsDisplayed, true));
    }
    dispatch(ACTION_CREATORS.openRightPanelWithModeAction(RightPanelModes.DONATION_PAYMENT_SETTINGS));
    dispatch(ACTION_CREATORS.trackEventAction({ action: `paymentGatewayWarningButtonClickedFrom${actionTrackerType}` }));
  };

  return (
    <div>
      {translationRenderer('This element won\'t be visible until you integrate with a [1[payment gateway]].')({
        renderer1: str => (
          <Button variant='ghost' onClick={handlePaymentGatewayClick}>
            {str}
          </Button>
        )
      })}
    </div>
  );
};

DonationGatewayWarning.propTypes = {
  actionTrackerType: PropTypes.string.isRequired
};

export default DonationGatewayWarning;
