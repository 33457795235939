import { isEnterprise } from '@jotforminc/enterprise-utils';

/**
 * Logs payment events.
 *
 * @param actor - The actor performing the action.
 * @param action - The action being performed.
 * @param target - The target of the action.
 * @param accountType - The type of the account.
 */
export const logPaymentEvents = (
  actor: string,
  action: string,
  target: string,
  accountType: string
): void => {
  try {
    if (window.JotFormActions && typeof window.JotFormActions === 'function') {
      /* eslint-disable-next-line max-len */
      const dontRecordBSG = !isEnterprise() && (!('JotFormActions' in window) || ['ADMIN', 'SUPPORT', 'INTERN', 'HELPDESK'].indexOf(accountType) > -1 || ['DEVELOPMENT', 'TESTING'].indexOf(window.JOTFORM_ENV) > -1);
      const dontRecordEnterpriseDev = window.location.host.indexOf('jotform.biz') > -1;

      if (dontRecordBSG || dontRecordEnterpriseDev) { return; }

      const logger = window.JotFormActions('payment');
      logger.tick({
        actor,
        action,
        target
      });
    }
  } catch (e) {
    console.log({ message: 'Event tracking failed!', errorMessage: e });
  }
};
