import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { updateActiveGatewaySettings } from '../api';
import * as actionCreators from '../store/actionCreators';
import {
  AllUnionMemberKeys, IGatewaySettings, ValueOf
} from '../types/common';
import PaymentProperties from '../constants/PaymentProperties';
import { checkIsGatewayConnected } from '.';

const TEST_MODE_PROPS = ['allowTest', 'sandbox', 'testmodeAuth', 'environment'];

export const getGatewayDisconnectSettings = ({
  activeGatewaySettings
}: IgetGatewayDisconnectSettingsProps): IgatewayDisconnectSettings => {
  const { type: gateway } = activeGatewaySettings;
  const currentGatewayProperties = PaymentProperties[gateway];
  const { connectionReferences } = currentGatewayProperties;

  const updatingSettings = Object.keys(connectionReferences).reduce((prev, setting) => {
    if (TEST_MODE_PROPS.includes(setting)) return prev;
    return {
      ...prev,
      [setting]: ''
    };
  }, {});

  return updatingSettings;
};

// TODO: maybe use thunk instead of passing dispatch?
export const updateGatewaySettingsActionHelper = async ({
  checkoutFormId,
  activeGatewaySettings,
  updatingSettings,
  successCallback,
  errorCallback,
  dispatch
}: UpdateGatewaySettingsActionHelperProps): Promise<void> => {
  let extraSettings = {};
  const updatedTestModeProp = Object.keys(updatingSettings).filter(item => TEST_MODE_PROPS.includes(item));
  const disconnectSettings = getGatewayDisconnectSettings({ activeGatewaySettings });

  if (checkIsGatewayConnected(activeGatewaySettings) && updatedTestModeProp.length > 0 && !updatingSettings.isConnectionButtonAction) {
    extraSettings = disconnectSettings;
  }

  const questionFormattedSettings = Object.entries({ ...extraSettings, ...updatingSettings }).filter(([setting]: Array<string>) => setting !== 'isConnectionButtonAction')
    .reduce((prev, [property, PropertyValue]) => {
      return { ...prev, [`question[${property}]`]: PropertyValue };
    }, {});

  try {
    await updateActiveGatewaySettings(checkoutFormId, activeGatewaySettings.qid, questionFormattedSettings);
    dispatch(actionCreators.updateActiveGatewaySettings({ ...updatingSettings, ...extraSettings }));
    dispatch(actionCreators.updateTemporaryActiveGatewaySettings({}));

    if (successCallback) successCallback();
  } catch (err: any) {
    if (errorCallback) errorCallback(err);
  }
};

type TPaymentProperties = typeof PaymentProperties;

type TConnectionReferencesType = ValueOf<TPaymentProperties>['connectionReferences'];

type TAllPossibleConnectionReferencesKeys = AllUnionMemberKeys<TConnectionReferencesType>

type IgatewayDisconnectSettings = Partial<{
  [key in TAllPossibleConnectionReferencesKeys]: string;
}>

interface IgetGatewayDisconnectSettingsProps {
  activeGatewaySettings: IGatewaySettings,
}

type UpdateGatewaySettingsActionHelperProps = {
  checkoutFormId: string,
  activeGatewaySettings: IGatewaySettings,
  updatingSettings?: any,
  successCallback?: () => any,
  errorCallback?: (error: AxiosError) => void,
  dispatch: Dispatch
}
