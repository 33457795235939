import React, {
  useEffect, useState, FC, ChangeEvent, useCallback, KeyboardEvent
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';
import { useDebounce } from '@jotforminc/hooks';
import { Button } from '@jotforminc/magnet';

import Selectors from '../../store/selectors';
import { InputValuesType } from '../../types/common';
import { stringToArray } from '../../utils/general';
import { updateProduct } from '../../api';
import * as actionCreators from '../../store/actionCreators';
import ArrowIcon from '../../assets/svg/arrowIcon.svg';
import {
  getActiveProduct, getFilteredOptions, getQuantityData, isLetterKey
} from '../../utils';
import { DEFAULT_QUANTITY } from '../../constants/variables';

const ProductQuantitySelectorTabEditor: FC = () => {
  const dispatch = useDispatch();
  const formId = useSelector(Selectors.getFormId);
  const activeProduct = useSelector(Selectors.getActiveProduct);
  const selectedOption = useSelector(Selectors.getSelectedOption);
  const productOptions = getActiveProduct(activeProduct);

  const initQuantity = getFilteredOptions(productOptions, 'quantity');
  const initInputValues = initQuantity.length > 0 ? initQuantity.map(opt => ({
    name: opt.name,
    start: stringToArray(opt.properties)[0],
    end: stringToArray(opt.properties)[stringToArray(opt.properties).length - 1]
  }))[0] : DEFAULT_QUANTITY;
  const [inputValues, setInputValues] = useState<InputValuesType>(initInputValues);

  const saveQuantityData = (updatedInputValues: InputValuesType): void => {
    const newOption = getQuantityData(productOptions, selectedOption, updatedInputValues);
    updateProduct(formId, activeProduct.pid, { options: JSON.stringify(newOption) });
    dispatch(actionCreators.updateProduct({ options: newOption }, activeProduct.pid));
    dispatch(actionCreators.updateActiveProduct({ options: newOption }));
  };

  const handleOnInputChange = (key: string, val: string) => {
    const currentInputValues = { ...inputValues, [key]: val };
    setInputValues(currentInputValues);
  };

  const debouncedSaveOption = useCallback(useDebounce(saveQuantityData, 150), []);

  useEffect(() => {
    debouncedSaveOption(inputValues);
  }, [inputValues]);

  const getCorrectNumber = (strNumber: string) : number => {
    const number = parseInt(strNumber, 10) || 1;
    if (number < 1) {
      return 1;
    }
    if (number > 1000) {
      return 1000;
    }
    return number;
  };

  const handleOnInputBlur = (key: string): void => {
    if (key === 'name' && !inputValues.name) {
      inputValues.name = DEFAULT_QUANTITY.name;
    } else {
      let end = getCorrectNumber(inputValues.end);
      let start = getCorrectNumber(inputValues.start);
      if (key === 'start' && start > end) {
        end = start;
      } else if (key === 'end' && end < start) {
        start = end;
      }
      inputValues.start = String(start);
      inputValues.end = String(end);
    }

    saveQuantityData(inputValues);
  };

  const handleGoBack = (): void => {
    dispatch(actionCreators.changeSelectedOption(null));
    dispatch(actionCreators.changeActiveEditor('productEditor'));
  };

  const handleKeydown = (e: KeyboardEvent<HTMLDivElement>) => isLetterKey(e) && e.preventDefault();

  return (
    <div className="productQuantityCreatorTabEditor">
      <div className="productQuantityCreatorTabEditor-label">
        <span className="row-title">{t('Label')}</span>
        <input
          type="text"
          onBlur={() => handleOnInputBlur('name')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnInputChange('name', e.target.value)}
          value={inputValues.name}
        />
      </div>
      <div className="productQuantityCreatorTabEditor-range">
        <span className="row-title">{t('Range')}</span>
        <div className="productQuantityCreatorTabEditor-range-inputCont">
          <input
            onBlur={() => handleOnInputBlur('start')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnInputChange('start', e.target.value)}
            value={inputValues.start}
            onKeyDown={handleKeydown}
            type="number"
          />
          <ArrowIcon className="productQuantityCreatorTabEditor-arrow" />
          <input
            onBlur={() => handleOnInputBlur('end')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnInputChange('end', e.target.value)}
            value={inputValues.end}
            onKeyDown={handleKeydown}
            type="number"
          />
        </div>
      </div>
      <Button
        onClick={handleGoBack}
      >
        {t('Go Back')}
      </Button>
    </div>
  );
};

export default ProductQuantitySelectorTabEditor;
