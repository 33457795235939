import React, { ComponentPropsWithRef, ElementType, forwardRef } from 'react';
import { cx } from 'class-variance-authority';
import {
  BaseInputAddonComponentType,
  BaseInputAddonProps, BaseInputProps, baseInputDefaultProps, inputAddonDefaultProps
} from './input.types';
import { baseInputAddon, baseInputContainerCVA, baseInputCVA } from './input.cva';
import { PolymorphicProps } from '../../types';

import '@jotforminc/jotform.css';
import { commonFormControlCVA } from '../../constants/common.cva';

export const BaseInput = forwardRef((
  props: BaseInputProps,
  ref?: ComponentPropsWithRef<'input'>['ref']
):JSX.Element => {
  const {
    colorStyle,
    size,
    disabled,
    theme,
    readOnly,
    htmlSize,
    id,
    className,
    addonStart,
    addonEnd,
    required,
    ghost,
    as = 'input',
    expand = false,
    value,
    children,
    placeholder,
    useDefaultStyleInReadOnly,
    ...rest
  } = props;

  const handleFocus = (e: React.MouseEvent<HTMLDivElement>) => {
    const element = e.target as HTMLElement;
    if (element.tagName === 'BUTTON' || element.tagName === 'LABEL' || element.tagName === 'INPUT') return;

    const input = e?.currentTarget?.getElementsByTagName('input')[0];
    input?.focus();
  };

  const readOnlyVariant = (readOnly && !disabled) && !useDefaultStyleInReadOnly;

  const baseInputContainerClassName = cx(
    className,
    commonFormControlCVA.container({
      colorStyle,
      readOnly: readOnlyVariant,
      disabled: !!disabled,
      theme,
      ghost: !!ghost
    }),
    baseInputContainerCVA({
      size, as, expand, disabled: !!disabled, readOnly: !!readOnly
    })
  );

  const baseInputClassName = cx(
    commonFormControlCVA.input({
      disabled,
      theme,
      size,
      ghost: !!ghost
    }),
    baseInputCVA({
      size,
      as,
      hasPlaceholder: !!placeholder,
      hasChildren: !!children,
      theme
    })
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={baseInputContainerClassName}
      onClick={handleFocus}
      ref={as === 'dropdown' ? ref : null}
      {... as === 'dropdown' ? rest : {}}
    >
      {addonStart}
      {as === 'input'
        && (
          <input
            className={baseInputClassName}
            disabled={disabled}
            size={htmlSize}
            readOnly={readOnly}
            ref={ref}
            id={id}
            value={value}
            required={required}
            placeholder={placeholder}
            {...rest}
          />
        )}
      {as === 'dropdown' && <span className={baseInputClassName}>{children ?? placeholder}</span>}
      {addonEnd}
    </div>
  );
});

BaseInput.defaultProps = baseInputDefaultProps;

export const BaseInputAddon:BaseInputAddonComponentType = forwardRef(
  <Element extends ElementType>(
    props: PolymorphicProps<Element, BaseInputAddonProps>,
    ref?: ComponentPropsWithRef<Element>['ref']
  ):JSX.Element => {
    const {
      size = inputAddonDefaultProps.size,
      theme = inputAddonDefaultProps.theme,
      direction = inputAddonDefaultProps.direction,
      variant = inputAddonDefaultProps.variant,
      disabled = inputAddonDefaultProps.disabled,
      readOnly = inputAddonDefaultProps.readOnly,
      as = 'span',
      useInputColor,
      id,
      icon: Icon,
      text,
      htmlFor,
      ...rest
    } = props;

    if (!Icon && !text) return <></>;

    const addonContainerClassName = cx(baseInputAddon.textCVA({
      size,
      theme,
      direction,
      variant,
      readOnly: !!readOnly,
      disabled: !!disabled,
      useInputColor: !!useInputColor
    }));

    const addonIconClassName = baseInputAddon.iconCVA({ size });
    const Component = as;

    return (
      <Component
        className={addonContainerClassName}
        htmlFor={htmlFor}
        id={id}
        ref={ref}
        {...rest}
      >
        {direction === 'start'
      && (
        <>
          {Icon && <Icon className={addonIconClassName} />}
          {text && text}
        </>
      )}
        {direction === 'end'
      && (
        <>
          {text && text}
          {Icon && <Icon className={addonIconClassName} />}
        </>
      )}
      </Component>
    );
  }
);
