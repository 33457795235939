import React, { Suspense } from 'react';
import { safeLazy } from '@jotforminc/safe-lazy-import';

import 'emoji-mart/css/emoji-mart.css';

const EmojiComponent = safeLazy(() => import(/* webpackChunkName: "emoji_mart_emoji" */'emoji-mart/dist/components/emoji/emoji'));

const Emoji = props => (
  <Suspense fallback={<div />}>
    <EmojiComponent {...props} />
  </Suspense>
);

export default Emoji;
