import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import { getExactPrice } from '@jotforminc/money-utils';
import { isThereASeparator, getRawPrice } from '../fields/ConnectionButton/utils';
import { TCurrencyCode } from '../../types/common';
import { isLetterKey } from '../../utils';

type PriceInputProps = {
  price: number,
  currency: TCurrencyCode,
  onValueChangeNative: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PriceInput = ({ price = 0, currency, onValueChangeNative }: PriceInputProps) : JSX.Element => {
  const [inputPrice, setInputPrice] = useState<string>(price.toString());
  const [isEditingState, setEditingState] = useState(false);

  const handlePriceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPrice(e.target.value);
    onValueChangeNative(e);
  };

  const getInputValue = () => {
    if (isEditingState) {
      return inputPrice;
    }
    const exactPrice = getExactPrice({ price, currency });
    if (['-NaN', 'NaN'].includes(exactPrice)) {
      return '0';
    }
    return exactPrice;
  };

  return (
    <div className="productEditorInputGroup">
      <label htmlFor="#">{t('Price')}</label>
      <div className="productEditorInputInnerGroup">
        <input
          type="text"
          id="price"
          className="productEditorItem"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            // BUGFIX #4128563 :: There shouldn't be more than one separator. Default separator is dot.
            if (isLetterKey(e) || isThereASeparator(e, inputPrice)) {
              e.preventDefault();
            }
          }}
          onBlur={() => {
            if (inputPrice === '') {
              setInputPrice('0');
            }
            setEditingState(false);
          }}
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = getRawPrice(e.target.value).toString();
            setInputPrice(e.target.value);
            setEditingState(true);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handlePriceInputChange(e);
          }}
          value={getInputValue()}
        />
        <span className="productEditorAbsoluteItem">{currency}</span>
      </div>
    </div>
  );
};

export default PriceInput;
