import React from 'react';
import {
  bool, func, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { SVGIcon } from '@jotforminc/icon-selector';
import { IconAlignTextJustify } from '@jotforminc/svg-icons';
import Button from '../../../components/Button';
import { FEATURE_NAMES } from '../../../constants/features';
import { isFeatureEnabled } from '../../../utils/features/helper';
import { isYes } from '../../../utils';
import ScNavigationPanelButton from './ScNavigationPanelButton';

const NavigationPanelButton = ({
  handleOpenNavigationPanel,
  mobileMenuTitle,
  showMobileMenuIcon,
  mobileMenuIcon,
  isRightPanel,
  isScrolledDown
}) => {
  const isIconVisible = isYes(showMobileMenuIcon);
  const hasMenuText = !!mobileMenuTitle;
  const hasBothIconAndText = isIconVisible && hasMenuText;

  const MenuIcon = () => {
    if (!isIconVisible) return null;

    return mobileMenuIcon ? (
      <SVGIcon
        url={isFeatureEnabled(FEATURE_NAMES.FullIconUrl) ? `https://cdn.jotfor.ms${mobileMenuIcon}` : mobileMenuIcon}
      />
    ) : (
      <IconAlignTextJustify className="defaultBar" />
    );
  };

  return (
    <ScNavigationPanelButton
      hasBothIconAndText={hasBothIconAndText}
      isRightPanel={isRightPanel}
      isScrolledDown={isScrolledDown && isIconVisible}
    >
      <Button
        data-testid="navBurger"
        className="bare navBurger"
        onClick={handleOpenNavigationPanel}
        aria-label={t('Navigation')}
      >
        <MenuIcon />
        <span>{mobileMenuTitle}</span>
      </Button>
    </ScNavigationPanelButton>
  );
};

NavigationPanelButton.propTypes = {
  handleOpenNavigationPanel: func,
  mobileMenuTitle: string,
  showMobileMenuIcon: string,
  mobileMenuIcon: string,
  isRightPanel: bool,
  isScrolledDown: bool
};

NavigationPanelButton.defaultProps = {
  handleOpenNavigationPanel: f => f,
  mobileMenuTitle: '',
  showMobileMenuIcon: '',
  mobileMenuIcon: '',
  isRightPanel: false,
  isScrolledDown: false
};

export default NavigationPanelButton;
