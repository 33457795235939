import React from 'react';
import { bool, elementType, string } from 'prop-types';
import { Badge, Indicator } from '@jotforminc/magnet';
import { IconExclamation } from '@jotforminc/svg-icons';
import classNames from 'classnames';

const SettingsPanelListNavigation = ({
  badgeText, customBadgeText, badgeType, badgeClass, indicatorClass, description, hasError, icon: Icon, label, listNavigationClasses, showMsLaunchBadge, BadgeComponent, subLabel, ...props
}) => {
  return (
    <span
      className={classNames('settingsNav relative radius-tl-md radius-bl-md flex w-full items-center', listNavigationClasses)}
      {...props}
    >
      <span className="settingsIcon relative flex justify-center items-center w-10 h-10 flex-0">
        <Icon className="w-6 h-6" />
        {badgeText !== null && (<Indicator className={classNames('absolute right-0 top-0', indicatorClass)} colorStyle={badgeType} size="small" />)}
      </span>
      <span className="flex-1 overflow-hidden max-w-full">
        <span className="settingsLabelWrapper flex gap-1.5 items-center">
          <span className="settingsLabel font-medium text-uppercase truncate min-w-0">
            {label}
            {subLabel && (
            <span className="text-xs text-capitalize font-normal">
              {subLabel}
            </span>
            )}
          </span>
          {badgeText !== null && (
            <>
              <BadgeComponent colorStyle={badgeType} size="small" className={badgeClass}>{badgeText}</BadgeComponent>
            </>
          )}
          {
            (showMsLaunchBadge && customBadgeText) && (
              <Badge
                size="small"
                rounded={false}
                variant="subtle"
                colorStyle="neutral"
                style={{
                  padding: '4px 6px',
                  borderRadius: '2px',
                  backgroundColor: '#CDA9F9'
                }}
              >
                {customBadgeText}
              </Badge>
            )
          }
        </span>
        <span className="settingsDesc mt-.5 text-sm">
          {description}
        </span>
      </span>
      {hasError && <span className="settingsError radius-full bg-red-400 color-white"><IconExclamation className="block w-5 h-5" /></span>}
    </span>
  );
};

SettingsPanelListNavigation.propTypes = {
  badgeText: string,
  badgeType: string,
  badgeClass: string,
  indicatorClass: string,
  description: string.isRequired,
  hasError: bool,
  icon: elementType.isRequired,
  label: string.isRequired,
  listNavigationClasses: string,
  showMsLaunchBadge: bool,
  customBadgeText: string,
  subLabel: string,
  BadgeComponent: elementType
};

SettingsPanelListNavigation.defaultProps = {
  badgeText: null,
  badgeType: 'secondary',
  badgeClass: '',
  indicatorClass: '',
  hasError: false,
  listNavigationClasses: null,
  showMsLaunchBadge: false,
  customBadgeText: null,
  subLabel: null,
  BadgeComponent: Badge
};

export default SettingsPanelListNavigation;
