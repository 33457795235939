import React, { FC } from 'react';
import {
  IconExclamationCircle
} from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  Dialog,
  DialogIcon,
  DialogBody,
  DialogTitle,
  DialogDescription,
  DialogActions,
  DialogCloseButton,
  Button
} from '@jotforminc/magnet';

interface IAdditionalSetupRequired {
  showDialog: boolean;
  onClose: () => void;
}

const AdditionalSetupRequiredDialog: FC<IAdditionalSetupRequired> = ({
  showDialog,
  onClose
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={showDialog}
      ariaLabel={t('Additional Setup Required')}
    >
      <DialogBody>
        <DialogIcon
          icon={IconExclamationCircle}
          colorStyle="warning"
        />
        <DialogTitle>
          { t('Additional setup required') }
        </DialogTitle>
        <DialogDescription>
          {translationRenderer('The connection you’re about to use [1[requires]] you to [1[configure settings]] in the payment properties panel.')({
            renderer1: (text: string) => <b className='color-navy-500'>{text}</b>
          })}
        </DialogDescription>
      </DialogBody>
      <DialogActions>
        <Button
          colorStyle="primary"
          variant="filled"
          onClick={onClose}
        >
          {t('OK, Got It!')}
        </Button>
      </DialogActions>
      <DialogCloseButton onClick={onClose} />
    </Dialog>
  );
};

export default AdditionalSetupRequiredDialog;
