import React from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@jotforminc/translation';
import Styled from 'styled-components';
import { Button } from '@jotforminc/magnet';
import { checkMobilePhone } from '../../../utils';
import * as ACTION_CREATORS from '../../../store/actionCreators';
import { CHECKOUT_FORM_STATUSES, UI_PROPS } from '../../../constants';

const ScLoginCue = Styled.div`
  background-color: #F3F3FE;
  color: #6F76A7;
  padding: 9px 14px;
  margin: 25px 20px 8px 20px;
  border-radius: 4px;
  ${({ isMobilePhone }) => (isMobilePhone ? 'order: 2; margin-top: 0;' : '')}
`;
const LoginCue = () => {
  const dispatch = useDispatch();
  const isMobilePhone = checkMobilePhone();
  const handleLogin = () => {
    if (isMobilePhone) {
      dispatch(ACTION_CREATORS.setCheckoutFormStatus(CHECKOUT_FORM_STATUSES.INITIAL));
    }
    dispatch(ACTION_CREATORS.setUIProp(UI_PROPS.activeAccountBoxScreen, 'loginOptions'));
    dispatch(ACTION_CREATORS.setAccountBoxStatus(true));
  };
  return (
    <ScLoginCue className="checkout-login" isMobilePhone={isMobilePhone}>
      {t('Already have an account?')}
      <Button variant='ghost' onClick={handleLogin}>{t('Log in')}</Button>
    </ScLoginCue>
  );
};

export default LoginCue;
