import { createSelector } from 'reselect';
import {
  IStore,
  IUiReducer,
  ISellProductsItem,
  ISubscriptionItem,
  IUser,
  IPaymentConnectionItem,
  IGatewaySettings,
  IProductListSettings,
  IQuestions
} from '../types/common';

const getUI = (state: IStore) : IUiReducer => state.ui;
const getProducts = (state: IStore) : ISellProductsItem[] => state.products;
const getSubscriptions = (state: IStore) : ISubscriptionItem[] => state.subscriptions;
const getActiveProduct = (state: IStore) : ISellProductsItem => state.activeProduct;
const getUser = (state: IStore) : IUser => state.user;
const getActiveConnection = (state: IStore) : IPaymentConnectionItem => state.activeConnection;
const getConnections = (state: IStore) : IPaymentConnectionItem[] => state.connections;
const getActiveGatewaySettings = (state: IStore) : IGatewaySettings => state.activeGatewaySettings;
const getProductListSettings = (state: IStore) : IProductListSettings => state.productListSettings;
const getCheckoutFormQuestions = (state: IStore) : IQuestions => state.checkoutForm.questions;

// Selectors
export default {
  getUI,
  getFormId: createSelector(getUI, props => props.formId),
  getCheckoutFormId: createSelector(getUI, props => props.checkoutFormId),
  getActivePanel: createSelector(getUI, props => props.activePanel),
  getActiveEditor: createSelector(getUI, props => props.activeEditor),
  getActiveTab: createSelector(getUI, props => props.activeTab),
  getSelectedOption: createSelector(getUI, props => props.selectedOption),
  getProducts: createSelector(getProducts, props => props),
  getSubscriptions: createSelector(getSubscriptions, props => props),
  getNewPid: createSelector(getProducts, props => `${1000 + props.length}`),
  getActiveProduct: createSelector(getActiveProduct, props => props),
  getIsGatewaySettingsDisplayed: createSelector(getUI, props => props.isGatewaySettingsDisplayed),
  getActiveAPMSelection: createSelector(getUI, props => props.activeAPMSelection),
  getGatewaySettingsView: createSelector(getUI, props => props.gatewaySettingsView),
  getUsername: createSelector(getUser, props => props.username),
  getActiveConnection: createSelector(getActiveConnection, props => props),
  getConnections: createSelector(getConnections, props => props),
  getActiveGatewaySettings: createSelector(getActiveGatewaySettings, props => props),
  getTemporaryActiveGatewaySettings: createSelector(getUI, props => props.temporaryActiveGatewaySettings),
  getProductListSettings: createSelector(getProductListSettings, props => props),
  getCheckoutFormQuestions: createSelector(getCheckoutFormQuestions, props => props),
  getSelectedGatewayWithAPM: createSelector(getUI, props => props.selectedGatewayWithAPM),
  getConnectionModalPropValues: createSelector(getUI, props => props.connectionModalPropValues)
};
