import { t } from '@jotforminc/translation';
import { Hooks, Popover } from '@jotforminc/uikit';
import {
  func, shape, string
} from 'prop-types';
import React, {
  useCallback, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../../components/Button';
import { applyColorSchemeToItemAction, updateItemPropAction } from '../../../../../../store/actionCreators';
import SELECTORS from '../../../../../../store/selectors';
import { DEFAULT_CUSTOM_PALETTE_PROPS } from '../../../../constants/colorPalette';
import ScCheckboxItem from '../../../../styles/ScCheckboxItem';
import ItemStyler from '../../../ItemStyler';
import { ScChangeStyle } from './ScChangeStyle';
import { mapSchemeToCustomProp, ITEM_STYLING_LIST } from '../../../../../../properties/styling';
import { checkMobilePhone } from '../../../../../../utils';

// DONT USE THIS COMPONENT ANYWHERE, USE <ElementStyler /> instead, thx
const ListItemStyler = ({
  onPropChange, checkboxText, customPaletteProps, colorList, storeWithDifferentPropName
}) => {
  const dispatch = useDispatch();
  const [selectedItemID] = useSelector(SELECTORS.getSelectedPortalItems);
  const itemProps = useSelector(SELECTORS.getItemWithDefaults(selectedItemID));
  const designatedItemID = useSelector(SELECTORS.getDesignatedItemIDSelector);

  const colorPalettePopoverRef = useRef();
  const chooseColorPaletteRef = useRef();
  const applyToAllRef = useRef();
  const [isColorPaletteVisible, setColorPaletteVisible] = Hooks.useClickOutsideState(false, [colorPalettePopoverRef, chooseColorPaletteRef, applyToAllRef]);
  const [applyToAllChecked, setApplyToAllChecked] = useState(designatedItemID === selectedItemID);

  const onItemPropCheckboxClicked = e => {
    const { target: { checked } } = e;
    if (checked) {
      onPropChange('designatedItemProps')(selectedItemID);
    } else {
      onPropChange('designatedItemProps')('');
    }
    setApplyToAllChecked(!applyToAllChecked);
  };

  const appliedColors = {
    itemBgColor: itemProps[storeWithDifferentPropName?.itemBgColor || 'itemBgColor'],
    itemBorderColor: itemProps[storeWithDifferentPropName?.itemBorderColor || 'itemBorderColor'],
    itemFontColor: itemProps[storeWithDifferentPropName?.itemFontColor || 'itemFontColor']
  };

  Hooks.useEffectIgnoreFirst(() => {
    setApplyToAllChecked(selectedItemID === designatedItemID);
  }, [selectedItemID, designatedItemID]);

  const handleChange = useCallback(data => {
    dispatch(updateItemPropAction({ itemID: selectedItemID, prop: { ...mapSchemeToCustomProp(storeWithDifferentPropName, data) } }));
  }, [selectedItemID]);

  const handleSchemeChange = useCallback(schemeID => {
    const updatedColorList = { ...colorList };
    if (storeWithDifferentPropName && Object.keys(storeWithDifferentPropName).length > 0) {
      Object.keys(updatedColorList).forEach(k => {
        updatedColorList[k] = mapSchemeToCustomProp(storeWithDifferentPropName, updatedColorList[k]);
      });
    }
    dispatch(applyColorSchemeToItemAction(schemeID, updatedColorList, selectedItemID));
  }, [selectedItemID]);

  const popoverOptions = !applyToAllChecked
    ? { placement: 'bottom' }
    : {
      placement: 'bottom',
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 50]
        }
      }]
    };

  const isMobile = checkMobilePhone();
  const popoverStyle = !applyToAllChecked ? { padding: '0 1.75em 0 1em', zIndex: '2' } : { width: '92%', zIndex: '2' };
  const popoverMobileStyle = !applyToAllChecked ? { padding: '0 0.4em', zIndex: '2', maxWidth: '280px' } : { width: '92%', zIndex: '2' };
  const showApplyToAll = applyToAllChecked;

  return (
    <>
      <ScChangeStyle {...appliedColors}>
        <Button ref={chooseColorPaletteRef} onClick={() => setColorPaletteVisible(!isColorPaletteVisible)}>{t('Change Style')}</Button>
        { showApplyToAll && (
          <ScCheckboxItem ref={applyToAllRef} onClick={onItemPropCheckboxClicked} checked={applyToAllChecked}>{checkboxText}</ScCheckboxItem>
        )}
      </ScChangeStyle>
      { isColorPaletteVisible && (
        <Popover
          ref={colorPalettePopoverRef}
          targetRef={chooseColorPaletteRef}
          className="itemStyler"
          style={isMobile ? popoverMobileStyle : popoverStyle}
          popoverOptions={popoverOptions}
        >
          <ItemStyler
            id="itemStylerComponent"
            onChange={handleChange}
            onSchemeChange={handleSchemeChange}
            customPaletteProps={customPaletteProps}
            {...appliedColors}
          />
        </Popover>
      )}
    </>
  );
};

ListItemStyler.propTypes = {
  onPropChange: func.isRequired,
  checkboxText: string,
  colorList: shape({}),
  customPaletteProps: shape({}),
  storeWithDifferentPropName: shape({})
};

ListItemStyler.defaultProps = {
  checkboxText: t('Apply to all forms'),
  customPaletteProps: DEFAULT_CUSTOM_PALETTE_PROPS,
  colorList: ITEM_STYLING_LIST,
  storeWithDifferentPropName: {}
};

export default ListItemStyler;
