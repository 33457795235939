import React, { useCallback } from 'react';
import classnames from 'classnames';
import {
  bool, elementType,
  func, shape, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import Button from '../../../../components/Button';

const ColorPaletteItem = ({
  palette: paletteInfo,
  isSelected,
  onChange,
  Wrapper,
  buttonText
}) => {
  const { id, ...palette } = paletteInfo;
  const handleClick = useCallback(() => onChange(id), [palette, id]);

  return (
    <Wrapper
      {...palette}
    >
      <Button
        onClick={handleClick}
        className={classnames('color-palette-button', isSelected ? 'selected' : '')}
        aria-label={t('{id} Color Scheme').replace('{id}', id)}
        data-testid={`${id} Color Scheme`}
      >
        {t(buttonText)}
      </Button>
    </Wrapper>
  );
};

ColorPaletteItem.propTypes = {
  palette: shape({
    backgroundColor: string,
    fontColor: string,
    borderColor: string
  }),
  isSelected: bool,
  onChange: func.isRequired,
  Wrapper: elementType.isRequired,
  buttonText: string.isRequired
};

ColorPaletteItem.defaultProps = {
  palette: {
    fontColor: '#2c3345',
    backgroundColor: '#ffffff',
    borderColor: '#e5e5f0'
  },
  isSelected: false
};

export default ColorPaletteItem;
