import moment from 'moment';
import { t } from '@jotforminc/translation';

export const getLastUpdatedText = (lastUpdatedAt, userTimeZone) => {
  const latestRevDate = moment.tz(lastUpdatedAt, 'YYYY-MM-DD HH:mm:ss', 'America/New_York').tz(userTimeZone);

  if (!latestRevDate.isValid) {
    return '';
  }

  const diff = moment(new Date(), 'YYYY-MM-DD').diff(latestRevDate.format('YYYY-MM-DD'), 'days');

  switch (diff) {
    case 0:
      const time = latestRevDate.format('LT');
      return t('Last updated at {date}').replace('{date}', time);
    case 1:
      return `${t('Last updated')} ${t('yesterday')}`;
    default:
      const date = latestRevDate.format('DD MMM YYYY LT');
      return t('Last updated at {date}').replace('{date}', date);
  }
};
