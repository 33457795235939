import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import DataGridRow from './components/DataGridRow';
import '@jotforminc/jotform.css';
import './styles/data-grid.scss';

const SortableDataGridRow = SortableElement(DataGridRow);

const SortableDataGridContainer = SortableContainer(({ children, ...props }) => <div {...props}>{children}</div>);

const DataGrid = ({
  rows, columns, checkboxColumnKey, sortable, onSort, onCheckbox
}) => {
  const showCheckbox = Boolean(checkboxColumnKey);

  const handleCheckboxChange = (rowId, isChecked) => {
    onCheckbox(
      rows
        .filter(row => (rowId === row.id ? isChecked : row.visible)) // filter visible rows
        .map(({ id }) => id)
    );
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    onSort(arrayMove(rows, oldIndex, newIndex));
  };

  return (
    <SortableDataGridContainer
      className="flex flex-col border-gray-400 border-2 radius-sm bg-gray-600 gap-px"
      axis="y"
      lockAxis="y"
      helperClass="dataGrid-sortable-helper"
      onSortEnd={handleSortEnd}
      useDragHandle
    >
      {rows.map((row, index) => (
        <SortableDataGridRow
          key={row.id}
          index={index}
          rowItems={row}
          showCheckbox={showCheckbox}
          checked={showCheckbox ? row[checkboxColumnKey] : false}
          columns={columns}
          showHandle={sortable}
          onCheckbox={isChecked => handleCheckboxChange(row.id, isChecked)}
        />
      ))}
    </SortableDataGridContainer>
  );
};

DataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkboxColumnKey: PropTypes.string,
  sortable: PropTypes.bool,
  onSort: PropTypes.func,
  onCheckbox: PropTypes.func
};

DataGrid.defaultProps = {
  sortable: false,
  checkboxColumnKey: false,
  onSort: () => {},
  onCheckbox: () => {}
};

export default DataGrid;
