import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import BaseFooter from './BaseFooter';
import { changeGatewaySettingsView } from '../../store/actionCreators';
import {
  IGatewaySettings,
  TFormId
} from '../../types/common';
import { updateGatewaySettingsActionHelper } from '../../utils/actionUtils';

const GatewaySettingsFooter = ({
  checkoutFormId,
  activeGatewaySettings,
  temporaryActiveGatewaySettings,
  isGatewayConnected
}: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    updateGatewaySettingsActionHelper({
      checkoutFormId,
      activeGatewaySettings,
      updatingSettings: temporaryActiveGatewaySettings,
      dispatch
    });
  }, [checkoutFormId, temporaryActiveGatewaySettings, activeGatewaySettings, dispatch]);

  return (
    <>
      <BaseFooter
        saveText='Save'
        backText='Back'
        handleSave={handleSave}
        handleBack={() => dispatch(changeGatewaySettingsView('gateway-picker'))}
        disabled={isEmpty(temporaryActiveGatewaySettings)}
        showBackBtn={!isGatewayConnected}
      />
    </>
  );
};

interface IProps {
  activeGatewaySettings: IGatewaySettings
  temporaryActiveGatewaySettings: Partial<IGatewaySettings>
  isGatewayConnected: boolean
  checkoutFormId: TFormId
}

export default GatewaySettingsFooter;
