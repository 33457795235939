import React from 'react';
import { func, shape, string } from 'prop-types';
import { serialize, handleCustomNavigation, pushDataLayer } from '@jotforminc/utils';

import { fetch } from '../../core/utils';
import FormTypeSelect from './FormTypeSelect';

export default class StartFromScratch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreatingForm: false
    };

    this.disableCreateFormButtons = this.disableCreateFormButtons.bind(this);
    this.createForm = this.createForm.bind(this);
    this.reloadPageOnCache = event => {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }

  componentWillUnmount() {
    window.removeEventListener('pageshow', this.reloadPageOnCache);
  }

  createForm(createFormType) {
    const { isCreatingForm } = this.state;
    const {
      currentFolder, actionLogger, onFormCreate, teamID, folderID, creationLogger
    } = this.props;
    const fetchURL = '/API/form/new';
    if (isCreatingForm) { return; }

    this.disableCreateFormButtons();
    if (creationLogger) {
      creationLogger(`startFromScratch-${createFormType}`);
    }

    switch (createFormType) {
      case 'ClassicForm':
        fetch(`${fetchURL}?${serialize({
          folder: currentFolder || folderID
        })}`, { headers: teamID ? { 'jf-team-id': teamID } : {} }).then(r => r.json()).then(data => {
          const { content: { id } } = data;

          if (actionLogger) {
            actionLogger({
              action: 'formCreationCompleted',
              target: id
            });
          }
          pushDataLayer({ method: 'Classic Form', event: 'create_form_from_scratch' });
          onFormCreate(id);
        });
        break;
      case 'CardForm':
        fetch(`${fetchURL}?${serialize({
          formType: 'cardForm',
          themeId: '59647bf8cf3bfe639c0b7cb1',
          folder: currentFolder
        })}`, { headers: teamID ? { 'jf-team-id': teamID } : {} }).then(r => r.json()).then(data => {
          const { content: { id } } = data;

          if (actionLogger) {
            actionLogger({
              action: 'formCreationCompleted',
              target: id
            });
          }
          pushDataLayer({ method: 'Card Form', event: 'create_form_from_scratch' });
          onFormCreate(id);
        });
        break;
      default:
        break;
    }
  }

  disableCreateFormButtons() {
    window.addEventListener('pageshow', this.reloadPageOnCache);
    this.setState({
      isCreatingForm: true
    });
  }

  render() {
    const {
      setView, actionLogger, onBackButtonClick, viewProps
    } = this.props;
    const { onBackButtonClick: onBackButtonClickFromViewProps } = viewProps;

    return (
      <FormTypeSelect
        setView={setView}
        actionLogger={actionLogger}
        className="isStartFromScratch"
        onClickItem={this.createForm}
        onBackButtonClick={onBackButtonClick || onBackButtonClickFromViewProps}
      />
    );
  }
}

StartFromScratch.propTypes = {
  setView: func,
  currentFolder: string,
  folderID: string,
  actionLogger: func,
  onFormCreate: func,
  onBackButtonClick: func,
  teamID: string,
  viewProps: shape({
    onBackButtonClick: func
  }),
  creationLogger: func
};

StartFromScratch.defaultProps = {
  setView: f => f,
  currentFolder: null,
  folderID: null,
  actionLogger: null,
  onBackButtonClick: null,
  onFormCreate: id => { handleCustomNavigation(`/build/${id}`, '_self'); },
  teamID: null,
  viewProps: {
    onBackButtonClick: null
  },
  creationLogger: f => f
};
