import React, { useRef } from 'react';
import { string } from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { ScUIKitButton } from './scButton';
import Tooltip from '../Tooltip';

const Button = React.forwardRef(({ name, ...props }, ref) => {
  const innerRef = useRef();
  const combinedRef = Hooks.useCombinedRefs(innerRef, ref);

  return (
    <>
      <ScUIKitButton
        ref={combinedRef}
        type="button"
        name={name}
        {...props}
      />
      {name && <Tooltip ref={combinedRef}>{name}</Tooltip>}
    </>
  );
});

Button.propTypes = {
  name: string
};

Button.defaultProps = {
  name: ''
};

export default Button;
