import { t } from '@jotforminc/translation';
import type { CONNECTION_GET_SANDBOX_TYPES } from '../../types';

/**
 * Converts the sandbox property value to a corresponding string representation.
 *
 * @param sandboxProp - The sandbox property value to convert.
 * @returns The string representation of the sandbox property value.
 */
export const sandboxGetDataConverter = (
  sandboxProp: CONNECTION_GET_SANDBOX_TYPES,
  isAppentModeText = true
): string => {
  switch (sandboxProp) {
    case '0':
      return isAppentModeText ? t('Live Mode') : t('Live');
    case '1':
      return isAppentModeText ? t('Test Mode') : t('Test');
    case '2':
      return isAppentModeText ? t('Development Mode') : t('Development');
    default:
      return '';
  }
};
