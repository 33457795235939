import React, { useState, useCallback } from 'react';
import { bool, func, oneOf } from 'prop-types';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import { ResourcePicker } from '@jotforminc/resource-picker';
import { PickerModal } from '@jotforminc/picker-modal';
import { EmptyList, NotFoundList } from '@jotforminc/empty-list';
import { t } from '@jotforminc/translation';
import { useDispatch, useSelector } from 'react-redux';
import SelectTemplate from '../../SelectTemplate';
import { ScResourcePicker } from './ScResourcePicker';

import NoFormsFoundIcon from '../../../../../assets/noFormsFoundIcon.svg';
import NoSignsFoundIcon from '../../../../../assets/noSignsFoundIcon.svg';
import NoTablesFoundIcon from '../../../../../assets/noTablesFoundIcon.svg';
import NoReportsFoundIcon from '../../../../../assets/noReportsFoundIcon.svg';
import NoAppsFoundIcon from '../../../../../assets/noAppsFoundIcon.svg';

import NoFormsIcon from '../../../../../assets/noFormsIcon.svg';
import NoTablesIcon from '../../../../../assets/noTablesIcon.svg';
import NoReportsIcon from '../../../../../assets/noReportsIcon.svg';
import NoAppsIcon from '../../../../../assets/noAppsIcon.svg';

import { ITEM_TYPES, RESOURCE_TYPES } from '../../../../../constants/itemTypes';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';
import { useSignTemplateAction } from '../../../../../store/actionCreators';
import ResourcePickerWithControlBar from '../../../../../components/ResourcePickerWithControlBar';
import { COMPONENT_KEYS, decideElementByType } from './helper';

const resourceData = {
  [RESOURCE_TYPES.SENTBOX]: {
    resourceName: 'form',
    emptyListTitle: 'YOU DON\'T HAVE ANY FORMS IN THE APP YET!',
    emptyListDescription: 'There should be at least one form in the app to add the Sentbox element.',
    modalTitle: 'Select Forms',
    modalDescription: 'Select the forms you want to show submissions.',
    resourcePickerType: 'form',
    notFoundIcon: NoFormsFoundIcon,
    emptyListIcon: NoFormsIcon,
    emptyListResourceType: 'form',
    dataSelector: SELECTORS.getPortalFormItems
  },
  [RESOURCE_TYPES.TABLE]: {
    resourceName: 'table',
    emptyListTitle: 'YOU DON\'T HAVE ANY TABLES YET!',
    emptyListDescription: ' ',
    modalTitle: 'Select Tables',
    modalDescription: 'Select the tables you want to add to your app.',
    resourcePickerType: 'sheet',
    notFoundIcon: NoTablesFoundIcon,
    emptyListIcon: NoTablesIcon,
    emptyListResourceType: 'table',
    controlBar: true
  },
  [RESOURCE_TYPES.REPORT]: {
    resourceName: 'report',
    emptyListTitle: 'YOU DON\'T HAVE ANY REPORTS YET!',
    emptyListDescription: ' ',
    modalTitle: 'Select Reports',
    modalDescription: 'Select the reports you want to add to your app.',
    resourcePickerType: 'report',
    notFoundIcon: NoReportsFoundIcon,
    emptyListIcon: NoReportsIcon,
    emptyListResourceType: 'report',
    controlBar: true
  },
  [RESOURCE_TYPES.SIGN]: {
    resourceName: 'sign',
    emptyListTitle: 'YOU DON\'T HAVE ANY SIGNS IN THE APP YET!',
    emptyListDescription: 'There should be at least one sign in the app to add the Sentbox element.',
    modalTitle: 'Select Sign Documents',
    modalDescription: 'Select the sign document you want to add to your app.',
    resourcePickerType: 'sign',
    notFoundIcon: NoSignsFoundIcon,
    emptyListIcon: NoFormsIcon,
    emptyListResourceType: 'sign',
    confirmText: 'Add Sign Documents',
    showTemplatesOnNoResource: true,
    cloneTemplateAction: useSignTemplateAction,
    dataSelector: SELECTORS.getAvailableSignSelector
  },
  PORTAL: {
    resourceName: 'app',
    emptyListTitle: 'YOU DON\'T HAVE ANY APPS YET!',
    emptyListDescription: ' ',
    modalTitle: 'Go to another app',
    modalDescription: 'Select another app to view, edit, or share.',
    resourcePickerType: 'app',
    notFoundIcon: NoAppsFoundIcon,
    emptyListIcon: NoAppsIcon,
    emptyListResourceType: 'portal',
    dataSelector: SELECTORS.getApps
  },
  [RESOURCE_TYPES.FORM]: {
    resourceName: 'form',
    emptyListTitle: 'YOU DON\'T HAVE ANY FORMS YET!',
    emptyListDescription: ' ',
    modalTitle: 'Select Forms',
    modalDescription: 'Select the forms you want to add to your app.',
    resourcePickerType: 'form',
    notFoundIcon: NoFormsFoundIcon,
    emptyListIcon: NoFormsIcon,
    emptyListResourceType: 'form',
    dataSelector: SELECTORS.getForms
  }
};

const NotFoundListPlaceholder = ({ resourceType }) => { // eslint-disable-line react/prop-types
  const { [resourceType]: { emptyListResourceType } } = resourceData;
  return (
    <NotFoundList
      resourceType={emptyListResourceType}
    />
  );
};

const EmptyListPlaceholder = ({ resourceType }) => { // eslint-disable-line react/prop-types
  const { [resourceType]: { emptyListResourceType, emptyListTitle, emptyListDescription } } = resourceData;
  return (
    <EmptyList
      resourceType={emptyListResourceType}
      title={emptyListTitle}
      description={emptyListDescription}
    />
  );
};

const ResourcePickerModal = props => {
  const {
    onConfirm, onTemplateClone, onClose, resourceType, isFullScreen, forceSelect, isMultiSelect, type, ...rest
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const {
    [resourceType]: {
      modalTitle, modalDescription, resourcePickerType, confirmText, resourceName, cloneTemplateAction, showTemplatesOnNoResource = false, dataSelector, controlBar = false
    }
  } = resourceData;

  const dispatch = useDispatch();
  const resources = dataSelector ? useSelector(dataSelector) : [];
  const user = useSelector(SELECTORS.getUser);
  const hasItems = resources.length > 0;

  const handleSelectionChange = useCallback(selection => {
    const selectedResource = Array.isArray(selection) ? selection : [selection];
    if (forceSelect) {
      onConfirm({ selectedResourcesArray: selectedResource, resourceType }); // no need to store selected item/s
      onClose();
      return;
    }

    setSelectedItems(selectedResource);
  }, [setSelectedItems, forceSelect]);

  const handleAddButtonClick = useCallback(() => {
    const selectedResourcesArray = controlBar ? selectedItems : resources.filter(({ id }) => selectedItems.includes(id));
    if (onConfirm) {
      onConfirm({ selectedResourcesArray, resourceType });
    } else {
      dispatch(ACTION_CREATORS.onResourcePickerModalConfirm({
        ...props,
        selectedResourcesArray
      }));
    }
    onClose();
  }, [onConfirm, selectedItems]);

  const handlePickerClose = () => {
    setSelectedItems([]);
    onClose();
  };

  const EmptyListElement = useCallback(() => <EmptyListPlaceholder resourceType={resourceType} />, [resourceType]);
  const NotFoundListElement = useCallback(() => <NotFoundListPlaceholder resourceType={resourceType} />, [resourceType]);

  const handleCloneTemplate = cloneFormData => {
    dispatch(cloneTemplateAction({ id: cloneFormData.id, action: 'cloneESignTemplate', generateEmbedDocumentKey: 1 }, props));
    onClose();
  };

  const ResourcePickerComponent = controlBar ? ResourcePickerWithControlBar : ResourcePicker;

  const showResourceTemplates = !hasItems && showTemplatesOnNoResource && !isEnterprise();
  return (
    <ScResourcePicker isFullScreen={isFullScreen}>
      <PickerModal
        defaultVisible
        isFullScreen={isFullScreen}
        footer={!forceSelect && (
          decideElementByType(type, COMPONENT_KEYS.FOOTER, {
            type,
            resourceName,
            onAddButtonClick: handleAddButtonClick,
            selectedItemCount: selectedItems.length,
            hasItems: hasItems || controlBar,
            confirmText: confirmText || 'Add',
            onCancel: !controlBar && handlePickerClose
          })
        )}
        header={isFullScreen ? null : decideElementByType(type, COMPONENT_KEYS.HEADER, {
          modalTitle, modalDescription
        })}
        onClose={handlePickerClose}
        headerVisible={!showResourceTemplates}
        className={showResourceTemplates ? 'templateModal' : ''}
        exceptionalSelectorList={['.jfWizard-modal', '.jfSelect-list', '.jfSelect-searchBox', '.jfWizard-modal-wrapper']}
      >
        {(isFullScreen && !showResourceTemplates) && (
          <div className="header-content">
            <div className="pm-t">{t(modalTitle)}</div>
            <p className="pm-d">{t(modalDescription)}</p>
          </div>
        )}
        {(showResourceTemplates) ? (
          <SelectTemplate
            user={user}
            templateType={resourceName}
            handleClone={handleCloneTemplate}
            handlePickerClose={handlePickerClose}
            source={`app-${resourceName}-element`}
          />
        ) : (
          <ResourcePickerComponent
            resourceType={resourcePickerType}
            resources={resources}
            isMultiSelect={isMultiSelect}
            onSelectionChange={handleSelectionChange}
            emptyListPlaceholder={EmptyListElement}
            notFoundListPlaceholder={NotFoundListElement}
            allowOnlyOwnTeam={true}
            {...rest}
          />
        )}
      </PickerModal>
    </ScResourcePicker>
  );
};

ResourcePickerModal.propTypes = {
  onConfirm: func,
  onClose: func,
  onTemplateClone: func,
  resourceType: oneOf(Object.values(RESOURCE_TYPES)).isRequired,
  isFullScreen: bool,
  forceSelect: bool,
  isMultiSelect: bool,
  type: oneOf(ITEM_TYPES)
};

ResourcePickerModal.defaultProps = {
  onConfirm: undefined,
  onClose: f => f,
  onTemplateClone: f => f,
  isFullScreen: false,
  forceSelect: false,
  isMultiSelect: true,
  type: undefined
};

export default ResourcePickerModal;
