import React from 'react';
import {
  bool, string, shape, number
} from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';
import { IconProductSignColorBorder } from '@jotforminc/svg-icons';

import Styled from 'styled-components';
import { getShortMonthName } from '../../../../utils/utils';
import FormItemWrapper from '../../../FormPicker/FormItem/styled';
import { CheckBox } from '../../../FormPicker/CheckBox';
import { ScIconWrapper } from './styles';

const ScSignItemWrapper = Styled(FormItemWrapper)`
  .itemDesc {
    position: relative;
  }

  &.isDisabled {
    &:hover {
      .errorText {
        display: block;
      }
    }
  }

  .errorText {
    display: none;
    position: absolute;
    left: 93px;
    bottom: 4px;
    font-size: 13px;
    line-height: normal;
    padding: 8px;
    text-transform: lowercase;
    border-radius: 4px;
    color: #fff;
    font-weight: 400;
    background-color: #0A1551;
    font-family: "Circular";

    &:first-letter { text-transform: uppercase; }

    .link {
      color: #0099FF;
      cursor: pointer;
    }
  }
`;

const DescriptionText = ({ totalCount, updatedAtReadable }) => {
  // eslint-disable-next-line no-nested-ternary
  const firstPart = (totalCount > 0)
    ? totalCount > 1
      ? translationRenderer('[1[{totalCount}]] Sign Documents')({ renderer1: () => totalCount })
      : translationRenderer('[1[{oneCount}]] Sign Document')({ renderer1: () => 1 })
    : '';
  const secondPart = translationRenderer('Updated on [1[{updatedAtReadable}]]')({
    renderer1: () => updatedAtReadable
  });

  return (
    <>
      {firstPart}
      { totalCount > 0 ? '. ' : '' }
      {secondPart}
    </>
  );
};

DescriptionText.propTypes = {
  totalCount: number.isRequired,
  updatedAtReadable: string.isRequired
};

const SignItem = ({
  option,
  isSelected,
  isMultiSelect
}) => {
  const {
    totalCount,
    title,
    creationTime: createdAt,
    modificationTime: updatedAt,
    errorText,
    disabled
  } = option;

  const lastEditDate = (updatedAt ? updatedAt.split(' ')[0] : createdAt.split(' ')[0]).split('-');
  const updatedAtReadable = `${lastEditDate[2]} ${getShortMonthName(parseInt(lastEditDate[1], 10))} ${lastEditDate[0]}`;

  const className = disabled ? 'isDisabled' : '';

  return (
    <ScSignItemWrapper tabIndex="0" className={className}>
      {isMultiSelect && <CheckBox isSelected={isSelected} />}
      <div className="itemInfo">
        <ScIconWrapper className="lsApp-list-item-type">
          <IconProductSignColorBorder />
        </ScIconWrapper>
      </div>
      <div className="itemContent">
        <h2 className="itemTitle">
          {title}
        </h2>
        <p className="itemDesc">
          <DescriptionText totalCount={totalCount - 1} updatedAtReadable={updatedAtReadable} />
        </p>
      </div>
      <div className='errorText'>{errorText}</div>
    </ScSignItemWrapper>
  );
};

const reportsItemProperties = shape({
  form_count: number,
  form_id: string,
  title: string,
  status: string,
  created_at: string,
  updated_at: string,
  list_type: string
});

SignItem.propTypes = {
  option: reportsItemProperties.isRequired,
  isMultiSelect: bool,
  isSelected: bool.isRequired,
  disabled: bool,
  errorText: string
};

SignItem.defaultProps = {
  isMultiSelect: true,
  disabled: false,
  errorText: ''
};

export default SignItem;
