import React, {
  useEffect, useCallback, useRef, useMemo
} from 'react';
import {
  number, oneOfType, shape, string
} from 'prop-types';
import { Tab, TabList } from '@jotforminc/tabs';
import { useDispatch, useSelector } from 'react-redux';
import getRightPanelConstants from './rightPanelConstants';
import { RPTabButtonContainer, RPTabButtonWrapper } from '../../RightPanelContainer';
import { RightPanelModeEvents, RightPanelModes } from '../constants';
import PanelLineGenerator from './PanelLineGenerator';
import { trackEventAction } from '../../../../../store/actionCreators';
import { generateWidgetPanelProps } from './utils';
import { DATA_SOURCE_ITEMS } from '../../../../../constants/itemTypes';
import { detailPageItemPropertiesMutater, mergePanelConfigs } from './dataSourceHelpers';
import SELECTORS from '../../../../../store/selectors';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';

const RightPanelGenerator = ({
  mode, selectedItemID, itemProps, widgetProps
}) => {
  const dispatch = useDispatch();
  const tabListRef = useRef();
  const { type } = itemProps;
  useEffect(() => {
    const action = RightPanelModeEvents[mode];
    const target = { itemID: selectedItemID, itemType: type };
    const eventData = mode === RightPanelModes.APP_ITEM ? { action, target } : { action };
    dispatch(trackEventAction(eventData));
  }, [mode, selectedItemID]);

  const rightPanelProps = useMemo(() => getRightPanelConstants(mode), [mode]);
  const isItemPropertyPanel = useMemo(() => mode === RightPanelModes.APP_ITEM, [mode]);
  const isWidgetPanel = mode === RightPanelModes.APP_WIDGET;
  const { presentationItemType, presentationItemID } = itemProps;

  const page = useSelector(SELECTORS.getPageByID(itemProps.page));
  const pageLinkedItemID = page?.linkedItemID;
  const dataSourcePageLinkedItemProps = useSelector(SELECTORS.getPortalItemByIDSelector(pageLinkedItemID));

  const activeRightPanelTab = useSelector(SELECTORS.getActiveRightPanelTab);

  const restrictedItemProps = ['hasCondition', 'conditionProp', 'conditionChecker'];

  const panelProperties = useMemo(() => {
    const sourceItemPanel = rightPanelProps[type];

    const mutatePanelByTabName = ({
      panel, tabMutater, propertyMutater, ...rest
    }) => {
      const { tabs } = panel;
      const tabNames = Object.keys(tabs);

      // Apply the mutaters to each tab and return the new panel object
      const newTabs = tabMutater
        ? tabNames.reduce((prev, tabName) => {
          const mutatedTab = tabMutater({ panel, ...rest }, [prev, panel.properties[tabName]]);

          return {
            ...prev,
            [tabName]: {
              ...mutatedTab
            }
          };
        }, {})
        : panel.tabs;

      const newProperties = propertyMutater
        ? tabNames.reduce((prev, tabName) => {
          // unfortunately the right panel constansts are not in same schema
          const properties = panel.properties[tabName] ?? panel.properties;
          const mutatedProperties = propertyMutater({ panel, ...rest }, [prev, properties]);
          const preservedProps = restrictedItemProps.reduce((restirectedPropPrev, restrictedPropName) => {
            const restrictedProp = properties?.[restrictedPropName];

            if (!restrictedProp) {
              return restirectedPropPrev;
            }

            return { ...restirectedPropPrev, [restrictedPropName]: restrictedProp };
          }, {});

          if (!panel.properties[tabName]) {
            return {
              ...mutatedProperties,
              ...preservedProps
            };
          }

          return {
            ...prev,
            [tabName]: {
              ...mutatedProperties,
              ...preservedProps

            }
          };
        }, {})
        : panel.properties;

      return {
        ...panel,
        tabs: newTabs,
        properties: newProperties
      };
    };

    const isDataSourceItem = !!(isItemPropertyPanel && DATA_SOURCE_ITEMS.includes(type) && presentationItemID && presentationItemType);
    if (isItemPropertyPanel && (pageLinkedItemID || isDataSourceItem)) {
      const presentationItemPanel = rightPanelProps[presentationItemType];
      const itemPanel = isDataSourceItem ? mergePanelConfigs(sourceItemPanel, presentationItemPanel) : sourceItemPanel;

      return mutatePanelByTabName({
        panel: itemPanel,
        dataSourcePageLinkedItemProps,
        itemProps,
        isDataSourceItem: isDataSourceItem,
        propertyMutater: detailPageItemPropertiesMutater
      });
    }

    return ((isItemPropertyPanel && type) ? rightPanelProps[type] : rightPanelProps);
  }, [isItemPropertyPanel, type, rightPanelProps, selectedItemID]);

  const renderItemTab = useCallback((tabID, properties, siblingTabCount) => {
    const propertyItems = Object.keys(properties).filter(prop => !restrictedItemProps.includes(prop));
    const items = propertyItems.map(property => {
      const elements = properties[property];
      return (
        <PanelLineGenerator
          key={`${tabID}_${property}_${selectedItemID || 'app'}`}
          elements={elements}
          property={property}
          properties={properties}
          parentTabCount={siblingTabCount}
        />
      );
    });
    return (
      <Tab key={`${tabID}_${propertyItems.length}`} id={tabID}>{items}</Tab>
    );
  }, [mode, selectedItemID]);

  const { tabs: itemTabs = {}, properties: _itemProps = {} } = panelProperties;
  const itemProperties = isWidgetPanel ? generateWidgetPanelProps(widgetProps) : _itemProps;

  const filteredItemProperties = useCallback(() => {
    const properties = Object.entries(itemProperties);

    return properties.filter(([, tabProp]) => {
      const { hasCondition, conditionProp, conditionChecker } = tabProp;

      if (hasCondition) {
        return conditionChecker(itemProps[conditionProp]);
      }

      return true;
    }).reduce((prev, [key, property]) => ({ ...prev, [key]: property }), {});
  }, [itemProps, itemProperties]);

  const filteredItemTabNames = useMemo(() => (
    Object.keys(itemTabs).length > 1 ? Object.keys(filteredItemProperties()) : Object.keys(itemTabs)), [filteredItemProperties, itemTabs]);

  const filteredItemTabs = useMemo(() => (Object.keys(itemTabs).length > 1 ? Object.entries(itemTabs).reduce((prev, [key, value]) => {
    if (filteredItemTabNames.includes(key)) return { ...prev, [key]: value };

    return { ...prev };
  }, {}) : itemTabs), [filteredItemTabNames]);

  const tabNames = filteredItemTabNames;
  const tabListWrapperClassName = 'tabListWrapper';

  const hasExpectedActiveTabProps = itemProperties[activeRightPanelTab];

  const activeTabName = hasExpectedActiveTabProps ? activeRightPanelTab : tabNames[0];

  useEffect(() => {
    if (typeof itemTabs === 'string' && itemTabs !== 'all') {
      tabListRef.current.setActiveTabID(activeTabName);
    } else if (typeof itemTabs === 'object') {
      const [defaultTabKey] = Object.entries(itemTabs).find(([key, value]) => !!value.isDefault && key) || [];
      tabListRef.current.setActiveTabID(defaultTabKey || activeTabName);
    }
  }, [mode, selectedItemID]);

  const onTabChange = useCallback(tabID => {
    dispatch(ACTION_CREATORS.setActiveRightPanelTab(tabID));
  }, []);

  return (
    <TabList
        // eslint-disable-next-line react/prop-types
      Wrapper={useCallback(props => <div {...props} className={`${props.className ? `${props.className} ` : ''}${tabListWrapperClassName}`} />, [tabListWrapperClassName])}
      defaultActiveTab={tabNames[0] ?? 'General'}
      list={filteredItemTabs}
      ref={tabListRef}
      TabButtonContainer={props => <RPTabButtonContainer {...props} style={{ ...Object.entries(filteredItemTabs).length === 1 ? { display: 'none' } : {} }} />}
      TabButton={RPTabButtonWrapper({ onClick: f => f, itemTabs: itemTabs })}
      onTabChange={onTabChange}
    >
      {renderItemTab(activeTabName, Object.entries(itemTabs).length > 1 ? itemProperties[activeTabName] : itemProperties, Object.entries(itemTabs).length)}
    </TabList>
  );
};
RightPanelGenerator.propTypes = {
  itemProps: shape({}),
  mode: string.isRequired,
  selectedItemID: oneOfType([string, number]),
  widgetProps: shape({})
};

RightPanelGenerator.defaultProps = {
  itemProps: {},
  widgetProps: {},
  selectedItemID: ''
};

export default RightPanelGenerator;
