export const PERMISSION = {
  DEFAULT: 'default',
  GRANTED: 'granted',
  DENIED: 'denied'
};

export const SUBSCRIPTION_ID_KEY = 'push-notification-subscription-id';

export const DEVICE_REGISTRATION = {
  KEY: 'push-notification-device-registration-status',
  STATUS: {
    DEFAULT: 'default',
    REGISTERED: 'registered',
    UNREGISTERED: 'unregistered'
  }
};
