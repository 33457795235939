import { t } from '@jotforminc/translation';
import { IFormWizardElements } from '../../../../types/common';
import { I_CARDCONNECT_SETTINGS, I_SENSEPASS_SETTINGS, I_SQUARE_SETTINGS } from '../Types/GatewayTypes';
import * as COMPONENT_NAMES from '../../../../constants/componentNames';

export const CONNECTION_SECTION_PROPERTIES = [
  {
    type: 'control_square',
    properties: (settings: I_SQUARE_SETTINGS, isEdit: boolean): IFormWizardElements => ({
      infoline: {
        type: COMPONENT_NAMES.INFOLINE,
        componentProps: {
          type: 'warning',
          text: `Your currency was set as ${settings.currency} based on your <a href="https://squareup.com/dashboard" target="_blank">Square account</a> settings.`
        },
        condition: () => !isEdit && (typeof settings.location !== 'undefined' && typeof settings.currency !== 'undefined')
      }
    })
  },
  {
    type: 'control_cardconnect',
    properties: (settings: I_CARDCONNECT_SETTINGS): IFormWizardElements => ({
      infoline: {
        type: COMPONENT_NAMES.INFOLINE,
        componentProps: {
          text: `
            <span className='text-xs'>${t('Gateway test parameters have set up for CardConnect. You will make test transactions by referring to the information below.')}</span>
            ${t('Merchant ID:')} <b>496160873888</b>,${' '} ${t('API Username:')} <b> testing</b>,${' '} ${t('API Password:')} <b> testing123 </b>`
        },
        condition: () => settings.sandbox === 'enabled'
      }
    })
  },
  {
    type: 'control_sensepass',
    properties: (settings: I_SENSEPASS_SETTINGS): IFormWizardElements => ({
      isDirectFlow: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable Direct Payments'),
          class: 'flex flex-col justify-between items-start my-7 text-md font-medium line-height-xs color-navy-700 sm:flex-row sm:items-center',
          description: t('Allows users to complete payments without being redirected to SensePass Checkout.')
        },
        componentProps: {
          isSelected: settings.isDirectFlow === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF'
        }
      }
    })
  },
  {
    type: 'control_sensepass',
    properties: (settings: I_SENSEPASS_SETTINGS): IFormWizardElements => ({
      clientID: {
        type: COMPONENT_NAMES.NEWTEXTINPUT,
        labelProps: {
          label: t('Client ID'),
          class: 'block text-sm font-medium color-navy-700 line-height-xl tracking-md mt-2 mb-1',
          required: true
        },
        componentProps: {
          defaultValue: settings.clientID || '',
          // eslint-disable-next-line max-len
          inputClass: 'bg-white color-navy-700 border text-md min-w-44 p-2 text-left border-navy-100 mt-3 hover:border-navy-200 hover:border-opacity-80 radius focus:outline focus:outline-blue-200 sm:mt-0 h-10 w-84'
        },
        condition: () => settings.isDirectFlow === 'Yes'
      }
    })
  }
];
