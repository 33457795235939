import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { Button } from '@jotforminc/magnet';
import {
  IconLinkDiagonal,
  IconFacebookSquareFilled,
  IconLinkedinSquareFilled,
  IconEllipsisHorizontal,
  IconCopyFilled,
  IconTwitterSquareFilled,
  IconWhatsappSquareFilled
} from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { CopyButton } from '@jotforminc/uikit';
import { func, string } from 'prop-types';
import { shareUsingNavigator } from '@jotforminc/utils';
import { useDispatch, useSelector } from 'react-redux';
import AppModal from '../../../../components/AppModal';
import DialogRenderer from './DialogRenderer';
import HeaderRenderer from './HeaderRenderer';
import { getSocialMediaOptions, getSocialSharingUtmSources } from '../../utils';
import { checkMobileOrTablet, getAppLinkWithUTM, readURLHash } from '../../../../utils';
import { trackEventAction } from '../../../../store/actionCreators';
import SELECTORS from '../../../../store/selectors';
import { APP_PREVIEW_URL_HASH } from '../../../../constants';
import { momentToMilliSeconds } from '../../../Builder/components/Settings/utils';

const ShareAppModal = ({
  onClose, shareMessage, publicLink, appTitle, appID
}) => {
  const ref = useRef();
  const [, setWidth] = useState(0);
  const [isCopied, setCopied] = useState(false);
  const copyButtonText = useMemo(() => (isCopied ? t('Copied to clipboard!') : t('Copy Link')));
  const copyButtonTextMobile = useMemo(() => (isCopied ? t('Copied!') : t('Copy Link')));
  const dispatch = useDispatch();
  const { updated_at: updatedAt } = useSelector(SELECTORS.getAppInfoWithDefaults);
  useEffect(() => {
    const copyTimer = setTimeout(() => {
      setCopied(false);
    }, 3e3);
    return () => clearTimeout(copyTimer);
  }, [isCopied]);

  const handleCopyLink = useCallback(() => {
    setCopied(true);
    dispatch(trackEventAction({ action: 'copyButtonClickedAtShareAppModal' }));
  }, []);

  useEffect(() => {
    if (readURLHash() !== APP_PREVIEW_URL_HASH) return;
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobileOrTablet = checkMobileOrTablet();

  const icons = {
    facebookIcon: <IconFacebookSquareFilled className="w-12 lg:min-w-18" color="#3975EA" />,
    twitterIcon: <IconTwitterSquareFilled className="w-12 lg:min-w-18" color="#000000" />,
    linkedinIcon: <IconLinkedinSquareFilled className="w-12 lg:min-w-18" color="#3966AD" />,
    whatsappIcon: <IconWhatsappSquareFilled className="w-12 lg:min-w-18" color="#32D851" />
  };

  const socialMediaOptions = getSocialMediaOptions(shareMessage, publicLink, appID, icons, momentToMilliSeconds(updatedAt));

  const handleShareButtonClick = option => {
    option.action();
    dispatch(trackEventAction({ action: `shareOn${option.title}ClickedAtShareAppModal` }));
  };

  const handleMoreButtonClick = () => {
    shareUsingNavigator({ title: appTitle, text: t(shareMessage), url: `${getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('nativeShare'))}&time=${momentToMilliSeconds(updatedAt)}` });
    dispatch(trackEventAction({ action: 'moreButtonClickedAtShareAppModal' }));
  };

  const Content = useCallback(() => (
    <>
      <div className="flex justify-start items-center p-4 overflow-x-auto lg:p-8 xs:justify-center">
        {socialMediaOptions.map(option => {
          return (
            <button
              type="button"
              onClick={() => handleShareButtonClick(option)}
              className="lg:min-h-28 lg:max-w-40 w-24 h-24 inline-flex flex-col justify-center items-center shrink-0
              font-medium duration-300 outline-2 outline-transparent outline-offset-0 border
              focus:outline-opacity-50 h-10 radius border-transparent bg-transparent
              color-navy-500 hover:bg-navy-25 focus:outline-navy-50 px-3"
            >
              {option.icon}
              <div className="mt-2 text-md font-book lg:text-lg">{option.title}</div>
            </button>
          );
        })}
        {isMobileOrTablet && (
          <>
            <CopyButton
              className="min-h-24 min-w-24 max-w-40
              inline-flex shrink-0 justify-center items-center
              font-medium duration-300 outline-2 outline-transparent
              outline-offset-0 border focus:outline-opacity-50 border-transparent
              radius bg-transparent color-navy-500
              hover:bg-navy-25 focus:outline-navy-50 h-10 px-3
              flex-col"
              textToCopy={getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('copyLink'))}
              onCopy={handleCopyLink}
              title={t('Copy Link')}
            >
              <div className="w-12 h-12 inline-flex shrink-0 justify-center items-center bg-navy-25 radius">
                <IconCopyFilled width="24px" height="24px" />
              </div>
              <span className="mt-2 text-md font-book lg:text-lg">{copyButtonTextMobile}</span>
            </CopyButton>
            {(window.navigator.share) && (
              <Button
                onClick={handleMoreButtonClick}
                size="medium"
                variant="ghost"
                className="min-h-24 min-w-24 max-w-40"
                colorStyle="secondary"
              >
                <div className="w-12 h-12 inline-flex shrink-0 justify-center items-center bg-navy-25 radius">
                  <IconEllipsisHorizontal width="24px" height="24px" className="color-navy-700" />
                </div>
                <div className="mt-2 text-md font-book lg:text-lg">{t('More')}</div>
              </Button>
            )}
          </>
        )}
      </div>
      {!isMobileOrTablet && (
        <div className="flex flex-col mx-8 mb-3">
          <div className="flex justify-between radius items-center py-2.5 px-3 mb-2.5" style={{ backgroundColor: '#EBECF5', color: '#2C3345' }}>
            <div className="flex items-center flex-1 basis-0 min-w-0">
              <IconLinkDiagonal width="20px" className="shrink-0" />
              <span className="ml-2 whitespace-nowrap overflow-hidden" style={{ textOverflow: 'ellipsis' }}>{publicLink}</span>
            </div>
            <CopyButton
              className="copyBtn shrink-0"
              textToCopy={getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('copyLink'))}
              onCopy={handleCopyLink}
              title={t('Copy Link')}
            >
              <span>{copyButtonText}</span>
            </CopyButton>
          </div>
        </div>
      )}
    </>
  ), [isCopied, isMobileOrTablet]);

  return (
    <AppModal
      defaultVisible={true}
      DialogRenderer={DialogRenderer}
      HeaderRenderer={HeaderRenderer(onClose)}
      onModalClose={onClose}
      ref={ref}
    >
      <Content />
    </AppModal>
  );
};

ShareAppModal.propTypes = {
  onClose: func,
  shareMessage: string.isRequired,
  publicLink: string.isRequired,
  appTitle: string.isRequired,
  appID: string.isRequired
};

ShareAppModal.defaultProps = {
  onClose: f => f
};

export default ShareAppModal;
