import type {
  T_CONDITIONS,
  GATEWAY_SUPPORTED_RESOURCES,
  FORM_QUESTION_TYPES,
  GATEWAY_QUESTION_PROPERTIES,
  FORM_TYPES
} from '@jotforminc/payment-constants';
import { isPlainObject } from './isPlainObject';
import { checkResourcePropertyCondition } from './checkResourcePropertyCondition';
/**
 * Checks if resource property visible
 * @returns {boolean}
 */

export const isResourcePropertyVisible = (
  renderCondition: T_CONDITIONS,
  resources: GATEWAY_SUPPORTED_RESOURCES[],
  resource: GATEWAY_SUPPORTED_RESOURCES,
  isEnterprise: boolean,
  formType: FORM_TYPES,
  formQuestionTypes: FORM_QUESTION_TYPES[],
  gatewayProperties: GATEWAY_QUESTION_PROPERTIES
): boolean => {
  if (Array.isArray(resources) && !resources.includes(resource)) {
    return false;
  }

  if (typeof renderCondition === 'undefined') { return true; }
  if (typeof renderCondition === 'boolean') { return renderCondition; }

  if (isPlainObject(renderCondition)) {
    return checkResourcePropertyCondition(
      renderCondition,
      isEnterprise,
      formType,
      formQuestionTypes,
      gatewayProperties
    );
  }

  return false;
};
