import React, { useEffect } from 'react';
import ComponentRenderer from './ComponentRenderer';
import { TBuilderDropdown } from '../../../../types/common';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import { SCROLLABLE_CONTAINER_CLASSLIST } from '../Constants';

interface PROPS {
  BuilderDropdown: TBuilderDropdown;
  BuilderImageUpload: React.ComponentType;
  BuilderToggle: React.ComponentType;
}

const GatewayResourceProperties = ({
  BuilderDropdown,
  BuilderImageUpload,
  BuilderToggle
}: PROPS): JSX.Element => {
  const {
    gatewayResourceProps,
    invalidPropNames,
    scrollBottomForError,
    setScrollBottomForError,
    resource
  } = usePaymentPropsPanel();

  useEffect(() => {
    const scrollableContainer = document.querySelector(SCROLLABLE_CONTAINER_CLASSLIST[resource]);
    if (
      invalidPropNames.length > 0
      && scrollBottomForError
      && scrollableContainer
    ) {
      scrollableContainer.scroll({
        top: 1000,
        behavior: 'smooth'
      });
      setScrollBottomForError(false);
    }
  }, [invalidPropNames]);

  return (
    <>
      {
        Object.entries(gatewayResourceProps).map(item => {
          return (
            <ComponentRenderer
              key={item[0]}
              name={item[0]}
              props={item[1]}
              BuilderDropdown={BuilderDropdown}
              BuilderToggle={BuilderToggle}
              BuilderImageUpload={BuilderImageUpload}
            />
          );
        })
      }
    </>
  );
};

export default GatewayResourceProperties;
