/* eslint-disable complexity, camelcase, max-statements */
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react';
import debounce from 'lodash/debounce';
import isPlainObject from 'lodash/isPlainObject';
import {
  logPaymentEvents,
  getGatewayType,
  getParentGatewayType,
  getVisibleAndRequiredResourceProps,
  getInvalidOrCorrectedPropNames
} from '@jotforminc/payment-gateways';
import type {
  FIELD_TYPE,
  GATEWAY_QUESTION_PROPERTIES,
  GATEWAY_PAYMENT_TYPES,
  PAYMENT_FIELDS
} from '@jotforminc/payment-constants';
import {
  supportedGatewayConnectionPropsList,
  getGatewayResourceProperties,
  getChildGatewayResourcePropertiesByParent
} from '@jotforminc/payment-constants';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import {
  I_PAYMENT_PROPS_PANEL_PROVIDER,
  I_PAYMENT_PROPS_PANEL_CONTEXT,
  T_CHANGED_PROPS,
  T_QUESTION_PROP_NAMES,
  T_BUILDER_BTN_STATUS,
  CONNECTION_LIST_DATA,
  T_SETTING_CONFIG_MODE_STATE
} from '../../../../types/common';
import {
  changePaymentType as _changePaymentType,
  getConnectionInformation,
  detachPaymentConnection
} from '../../../../api';

const PaymentPropsPanelContext = createContext<I_PAYMENT_PROPS_PANEL_CONTEXT>({} as I_PAYMENT_PROPS_PANEL_CONTEXT);

export const PaymentPropsPanelProvider: FunctionComponent<I_PAYMENT_PROPS_PANEL_PROVIDER> = (({
  children,
  questionProperties,
  formQuestions,
  resource,
  checkoutFormID,
  products,
  formType,
  user,
  userIsResourceOwner,
  updateBuilderButtonStatus,
  updateGatewayConfigurationStatus,
  onPaymentTypeChange,
  onSaveGateway,
  onAutosaveGateway,
  onDetachConnection,
  getQidOnAddQuestion,
  togglePaymentConnectionModal
}) => {
  const { teamID = null, isTeamMember = null } = window;
  const [connectionInfo, setConnectionInfo] = useState<CONNECTION_LIST_DATA>({
    title: '',
    sandbox: '0',
    hasConnectionInfo: false,
    status: '0',
    scope: 'user',
    scope_name: '',
    scope_fullname: null,
    is_gateway_disabled: false
  });
  const [scrollBottomForError, setScrollBottomForError] = useState(false);
  const [showAdditionalSetupDialog, setShowAdditionalSetupDialog] = useState<boolean>(false);
  const [connectionChangeData, setConnectionChangeData] = useState<{ changed: boolean, isConnectionUsed: boolean, isConnectionSwitched: boolean }>({
    changed: false,
    isConnectionUsed: false,
    isConnectionSwitched: false
  });
  const [gatewayProperties, setGatewayProperties] = useState<GATEWAY_QUESTION_PROPERTIES>(questionProperties); // gatewayProperties handle states throughout the panel
  const [invalidPropNames, setInvalidPropNames] = useState<T_QUESTION_PROP_NAMES>([]); // will be deleted
  const parentType = getParentGatewayType(gatewayProperties.nameAPM);
  const childType = parentType && gatewayProperties.type;
  const isParentAPM = parentType && childType;
  const isMobileApp = window.navigator.userAgent.indexOf('JotForm Mobile') > -1 || window.navigator.userAgent.indexOf('JFCEMobile') > -1;
  const isCollaboratorInNewShareFlow = user.isCSICollaborator;
  const isCollaborator = (!!(window.location.href.match(/iak=([\d | \w | -]*)/) ?? [])[1] && !isMobileApp) || isCollaboratorInNewShareFlow;
  const userAccountType = user.accountType || user.account_type.name;
  const isJotformerAccessingUserResource = !isEnterprise() && !userIsResourceOwner && ['ADMIN', 'SUPPORT', 'HELPDESK'].includes(userAccountType);
  const [settingConfigModeState, setSettingConfigModeState] = useState<T_SETTING_CONFIG_MODE_STATE>({
    active: false,
    index: 0
  });

  /* Enterprise roles */
  const isInTeamAsset = (isTeamMember && teamID) || false;
  const hasTeamRole = isInTeamAsset && user && user.teamRoles;
  const isTeamMemberAndCreator = hasTeamRole && user.teamRoles.length === 1 && user.teamRoles.includes('form_collaborator');
  const isTeamAdmin = hasTeamRole && user.teamRoles.includes('team_admin');
  const isOrganizationAdmin = isEnterprise() && userAccountType === 'ADMIN';

  const { paymentConnectionID } = questionProperties;
  const hasConnection = !!paymentConnectionID;
  const [changedProps, setChangedProps] = useState<T_CHANGED_PROPS>({});
  const gatewayType: PAYMENT_FIELDS = useMemo(() => getGatewayType(gatewayProperties.type, gatewayProperties.nameAPM), [gatewayProperties.type, gatewayProperties.nameAPM]);
  const formQuestionTypes = useMemo(() => {
    return formQuestions ? formQuestions.map(fq => fq.type) : [];
  }, [formQuestions]);

  const gatewayResourceProps = useMemo(() => {
    return isParentAPM ? getChildGatewayResourcePropertiesByParent(parentType, childType) : getGatewayResourceProperties(gatewayType);
  }, [isParentAPM, parentType, childType, gatewayType]);

  useEffect(() => {
    const prev = gatewayProperties.paymentConnectionID;
    const next = questionProperties.paymentConnectionID;
    const isConnectionUsed = !prev;
    const isConnectionSwitched = prev && next && (prev !== next);
    if (isConnectionUsed || isConnectionSwitched) {
      setConnectionChangeData({
        changed: true,
        isConnectionUsed,
        isConnectionSwitched
      });
    }
  }, [questionProperties.paymentConnectionID, gatewayProperties.paymentConnectionID]);

  const resetChangedProps = () => {
    setChangedProps({});
  };

  const resetInvalidPropNames = () => {
    setInvalidPropNames([]);
  };

  const resetAllProps = useCallback(() => {
    setGatewayProperties(questionProperties);
    resetChangedProps();
    resetInvalidPropNames();
  }, [questionProperties]);

  useEffect(() => {
    const isDifferentAPM = gatewayProperties.nameAPM !== questionProperties.nameAPM;
    const isDifferentPaymentType = (gatewayProperties.type !== questionProperties.type) || isDifferentAPM;

    if (isDifferentPaymentType) {
      resetAllProps();
    }
  }, [questionProperties.type, questionProperties.nameAPM]);

  useEffect(() => {
    resetAllProps();
  }, [paymentConnectionID]);

  const logPaymentEvent = useCallback((action: string, target: string): void => {
    const actor = user?.username || '';
    logPaymentEvents(actor, action, target, userAccountType);
  }, [user?.username, userAccountType]);

  const handleAutosave = (key: string, val: string) => {
    onAutosaveGateway({
      qid: questionProperties.qid,
      key,
      val,
      gatewayType
    });
  };

  const handleAutosaveDebounced = useCallback(debounce((key: string, val: string) => {
    handleAutosave(key, val);
  }, 1000), []);

  const getAllRequiredPropNames = useCallback(() => {
    return getVisibleAndRequiredResourceProps(
      gatewayResourceProps,
      resource,
      isEnterprise(),
      formType,
      formQuestionTypes,
      gatewayProperties
    ).map(item => item[0]);
  }, [gatewayProperties, questionProperties.paymentConnectionID]);
  const allRequiredPropNames = getAllRequiredPropNames();

  const getAllRequiredPropNamesByPaymentType = useCallback((paymentType: GATEWAY_PAYMENT_TYPES) => {
    return getVisibleAndRequiredResourceProps(
      gatewayResourceProps,
      resource,
      isEnterprise(),
      formType,
      formQuestionTypes,
      { ...gatewayProperties, paymentType }
    ).map(item => item[0]);
  }, [gatewayProperties, questionProperties.paymentConnectionID]);

  const correctedPropNames = getInvalidOrCorrectedPropNames(
    gatewayResourceProps,
    resource,
    isEnterprise(),
    formType,
    formQuestionTypes,
    gatewayProperties,
    true
  );

  const setButtonStatus = () => {
    if (typeof updateBuilderButtonStatus !== 'function') { return; }
    let status: T_BUILDER_BTN_STATUS = 'success';
    if (!hasConnection) { status = 'warning'; }

    if (
      hasConnection
      && ((connectionInfo && connectionInfo.status === '3') || (correctedPropNames.length !== allRequiredPropNames.length))
    ) { status = 'error'; }

    updateBuilderButtonStatus(status);
  };

  const setGatewayProperty = (key: string, val: string, isAutosave?: boolean, fieldType?: FIELD_TYPE) => {
    const updatedKeyVal = { [key]: val };
    const updatedData = { ...gatewayProperties, ...updatedKeyVal };
    setGatewayProperties(updatedData);

    const _invalidPropNames = getInvalidOrCorrectedPropNames(
      gatewayResourceProps,
      resource,
      isEnterprise(),
      formType,
      formQuestionTypes,
      updatedData
    );
    setInvalidPropNames(_invalidPropNames);

    const shouldDebounce = fieldType && ['input', 'textarea'].includes(fieldType);
    if (shouldDebounce) {
      handleAutosaveDebounced(key, val);
    } else {
      handleAutosave(key, val);
    }
  };

  const setMultipleGatewayProperties = (updateObj: object, isAutosave?: boolean) => {
    setGatewayProperties({ ...gatewayProperties, ...updateObj });

    if (isAutosave) {
      onSaveGateway({
        qid: questionProperties.qid,
        changedProps: updateObj
      });
    } else {
      setChangedProps({ ...changedProps, ...updateObj });
    }
  };

  const changePaymentType = async (oldVal: GATEWAY_PAYMENT_TYPES, newVal: GATEWAY_PAYMENT_TYPES) => {
    const res = await _changePaymentType(checkoutFormID, oldVal, newVal);

    if (res && [400, 500].includes(res.code)) {
      return;
    }

    const paymentType = newVal === 'custom' ? 'donation' : newVal;

    const questionProps = {
      ...res.question,
      paymentType
    };

    onPaymentTypeChange({
      qid: questionProperties.qid,
      questionProps,
      formProps: res.form,
      paymentType
    });

    setGatewayProperties({ ...gatewayProperties, ...questionProps });

    logPaymentEvent('change-payment-type', JSON.stringify({ old: oldVal, new: newVal }));
  };

  const resetConnectionInfo = () => setConnectionInfo({
    title: '',
    sandbox: '0',
    hasConnectionInfo: false,
    status: '0',
    scope: 'user',
    scope_name: '',
    scope_fullname: null,
    is_gateway_disabled: false
  });

  useEffect(() => {
    if (hasConnection) {
      const fetchConnectionData = async () => {
        const res = await getConnectionInformation(questionProperties.paymentConnectionID, checkoutFormID);
        if (isPlainObject(res)) {
          const {
            title,
            sandbox,
            status,
            scope,
            scope_fullname,
            scope_name,
            is_gateway_disabled = false
          } = res;
          setConnectionInfo({
            title,
            sandbox,
            hasConnectionInfo: true,
            status,
            scope,
            scope_fullname,
            scope_name,
            is_gateway_disabled
          });
        }
      };
      fetchConnectionData();
    }

    return () => {
      resetConnectionInfo();
    };
  }, [hasConnection, questionProperties.paymentConnectionID, checkoutFormID]);

  const handleSave = () => {
    const _invalidPropNames = getInvalidOrCorrectedPropNames(
      gatewayResourceProps,
      resource,
      isEnterprise(),
      formType,
      formQuestionTypes,
      gatewayProperties
    );
    if (_invalidPropNames.length > 0) {
      setInvalidPropNames(_invalidPropNames);
      setScrollBottomForError(true);
      return;
    }

    if (Object.keys(changedProps).length === 0) { return; }

    onSaveGateway({
      qid: questionProperties.qid,
      changedProps
    });

    logPaymentEvent('save-gateway-settings', gatewayProperties.type);

    resetChangedProps();
    resetInvalidPropNames();
  };

  useEffect(() => {
    // show additional setup dialog on connection change, if _invalidPropNames.length > 0
    const _invalidPropNames = getInvalidOrCorrectedPropNames(
      gatewayResourceProps,
      resource,
      isEnterprise(),
      formType,
      formQuestionTypes,
      gatewayProperties
    );
    const isConnectionJustUsed = connectionChangeData.changed && connectionChangeData.isConnectionUsed;
    const hasInvalidProp = _invalidPropNames.length > 0;
    if (hasConnection && isConnectionJustUsed && hasInvalidProp) {
      setConnectionChangeData({
        changed: false,
        isConnectionUsed: false,
        isConnectionSwitched: false
      });
      setShowAdditionalSetupDialog(true);
    }
  }, [gatewayResourceProps, gatewayProperties, formQuestionTypes, connectionChangeData, hasConnection]);

  useEffect(() => {
    if (hasConnection) {
      const _invalidPropNames = getInvalidOrCorrectedPropNames(
        gatewayResourceProps,
        resource,
        isEnterprise(),
        formType,
        formQuestionTypes,
        gatewayProperties
      );
      if (_invalidPropNames.length > 0) {
        setInvalidPropNames(_invalidPropNames);
      }
    }
  }, [hasConnection]);

  /* eslint-disable complexity */
  const detachConnection = async () => {
    const { isPaymentStoreInBasicFields, paymentType } = gatewayProperties;
    const fromFieldsPanel = isPaymentStoreInBasicFields;
    const gatewayWithProductType = !fromFieldsPanel && paymentType === 'product';

    if (!(fromFieldsPanel || gatewayWithProductType)) { return false; }

    let isSuccessful = true;
    if (hasConnection) {
      const res = await detachPaymentConnection(resource, checkoutFormID);
      if (!(typeof res === 'boolean' && res)) { isSuccessful = false; }
    }

    // log gateway and connection remove
    if (isSuccessful) {
      const { name } = supportedGatewayConnectionPropsList[childType || gatewayProperties.type];
      const apm = (parentType && supportedGatewayConnectionPropsList[parentType]?.name) || gatewayProperties.nameAPM;
      const target = { name, ...(apm && { apm }) };
      const logAction = hasConnection ? 'gateway-connection-remove' : 'gateway-remove';

      logPaymentEvent(logAction, JSON.stringify(target));
    }

    if (isSuccessful && typeof onDetachConnection === 'function') {
      const {
        qid,
        type,
        builderLabel,
        multiple,
        showTotal,
        enableLightBox,
        useDecimal,
        decimalMark,
        currency
      } = questionProperties;
      const props = {
        type: 'control_payment',
        isPaymentStoreInBasicFields: true,
        multiple: multiple || 'Yes',
        showTotal: showTotal || 'Yes',
        enableLightBox: enableLightBox || '0',
        useDecimal: useDecimal || 'Yes',
        decimalMark: decimalMark || 'point',
        currency: currency || 'USD',
        prevBuilderLabel: builderLabel || 'Product List',
        prevType: type,
        prevQid: qid,
        isConnected: 0
      };

      onDetachConnection({
        qid,
        props,
        checkoutFormID
      });
    }

    return isSuccessful;
  };

  useEffect(() => {
    setButtonStatus();
    if (typeof updateGatewayConfigurationStatus === 'function') {
      const isGatewayConfigured = correctedPropNames.length === allRequiredPropNames.length;
      updateGatewayConfigurationStatus(isGatewayConfigured);
    }
  }, [connectionInfo, questionProperties]);

  const resetSettingConfigModeState = () => {
    setSettingConfigModeState({
      active: false,
      index: 0
    });
  };

  /* eslint-enable complexity */

  const returnValue = useMemo(() => {
    return {
      gatewayProperties,
      setGatewayProperties,
      setGatewayProperty,
      changePaymentType,
      connectionInfo,
      handleSave,
      detachConnection,
      getQidOnAddQuestion,
      togglePaymentConnectionModal,
      setMultipleGatewayProperties,
      setScrollBottomForError,
      isJotformerAccessingUserResource,
      scrollBottomForError,
      userIsResourceOwner,
      gatewayResourceProps,
      formQuestions,
      formQuestionTypes,
      checkoutFormID,
      questionProperties,
      hasConnection,
      changedProps,
      invalidPropNames,
      gatewayType,
      resource,
      products,
      isParentAPM,
      childType,
      user,
      isCollaborator,
      paymentConnectionID,
      isInTeamAsset,
      isTeamMemberAndCreator,
      isTeamAdmin,
      isOrganizationAdmin,
      showAdditionalSetupDialog,
      setShowAdditionalSetupDialog,
      correctedPropNames,
      allRequiredPropNames,
      setButtonStatus,
      formType,
      resetConnectionInfo,
      settingConfigModeState,
      setSettingConfigModeState,
      resetSettingConfigModeState,
      getAllRequiredPropNamesByPaymentType
    };
  }, [
    gatewayProperties,
    setGatewayProperties,
    setGatewayProperty,
    changePaymentType,
    connectionInfo,
    handleSave,
    detachConnection,
    getQidOnAddQuestion,
    togglePaymentConnectionModal,
    setMultipleGatewayProperties,
    setScrollBottomForError,
    isJotformerAccessingUserResource,
    scrollBottomForError,
    userIsResourceOwner,
    gatewayResourceProps,
    formQuestions,
    formQuestionTypes,
    checkoutFormID,
    questionProperties,
    hasConnection,
    changedProps,
    invalidPropNames,
    gatewayType,
    resource,
    products,
    isParentAPM,
    childType,
    user,
    isCollaborator,
    paymentConnectionID,
    isInTeamAsset,
    isTeamMemberAndCreator,
    isTeamAdmin,
    isOrganizationAdmin,
    showAdditionalSetupDialog,
    setShowAdditionalSetupDialog,
    correctedPropNames,
    allRequiredPropNames,
    setButtonStatus,
    formType,
    resetConnectionInfo,
    settingConfigModeState,
    setSettingConfigModeState,
    resetSettingConfigModeState,
    getAllRequiredPropNamesByPaymentType
  ]);

  return <PaymentPropsPanelContext.Provider value={returnValue}>{children}</PaymentPropsPanelContext.Provider>;
});

export default function usePaymentPropsPanel(): I_PAYMENT_PROPS_PANEL_CONTEXT {
  return useContext(PaymentPropsPanelContext);
}
