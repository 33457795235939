import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { I_MODAL_BODY } from '../Types';
import useGatewayFlows from '../Context/useGatewayFlows';

const Body: FunctionComponent<I_MODAL_BODY> = (({ children }) => {
  const { currentlyStep } = useGatewayFlows();
  return (
    <div className={classNames(
      'payment-modal-body-wrapper w-full h-auto py-8 px-6',
      {
        'overflow-y-scroll': currentlyStep !== 'select-paymentType'
      }
    )}
    >
      {children}
    </div>
  );
});

export default Body;
