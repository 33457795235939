import React, { useRef } from 'react';
import { func } from 'prop-types';

const HiddenFrame = ({ onLoad }) => {
  const uploadFrameRef = useRef(false);

  const handleLoad = () => {
    let content = false;
    const { current: iframe } = uploadFrameRef;
    switch (true) {
      case Boolean(iframe.contentDocument):
        content = iframe.contentDocument.body.innerHTML;
        break;
      case Boolean(iframe.contentWindow):
        content = iframe.contentWindow.document.body.innerHTML;
        break;
      case Boolean(iframe.document):
        content = iframe.document.body.innerHTML;
        break;
      default:
        break;
    }

    return onLoad({ content, iframe });
  };

  return (
    <iframe
      width="0"
      height="0"
      title="frame"
      id="uploadTrg"
      name="uploadTrg"
      frameBorder="0"
      scrolling="yes"
      ref={uploadFrameRef}
      onLoad={handleLoad}
    />
  );
};

HiddenFrame.propTypes = {
  onLoad: func
};

HiddenFrame.defaultProps = {
  onLoad: f => f
};

export default HiddenFrame;
