import {
  FETCH_SHARE_LIST, SET_TEMPLATE_CATEGORIES, SET_TEMPLATE_LANGUAGES, UPDATE_RESOURCE_SHARE_URL
} from '../actionTypes';

const initialState = {
  resourceShareURL: '',
  shareList: [],
  templateLanguages: [],
  templateCategories: []
};

const ShareReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHARE_LIST.SUCCESS: {
      return {
        ...state,
        shareList: [...action.payload]
      };
    }
    case UPDATE_RESOURCE_SHARE_URL.SUCCESS: {
      return {
        ...state,
        resourceShareURL: action.payload
      };
    }
    case SET_TEMPLATE_CATEGORIES: {
      return {
        ...state,
        templateCategories: action.payload
      };
    }
    case SET_TEMPLATE_LANGUAGES: {
      return {
        ...state,
        templateLanguages: action.payload
      };
    }
    default:
      break;
  }
  return state;
};

export default ShareReducer;
