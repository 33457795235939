/* eslint-disable no-undef */
import { RequestLayer, Interceptors, getDomainURL } from '@jotforminc/request-layer';

import { iconSpriteURL } from '../constants';

/**
 *
 * @param containerID is the id of the target div which svgs will live
 * @returns {Promise<unknown>}
 */
export const prepareIcons = containerID => {
  return new Promise((resolve, reject) => {
    const container = document.querySelector(`#${containerID}`);

    if (container && container.innerHTML.trim() !== '') {
      return resolve();
    }

    let containerElement = container;
    if (!containerElement) {
      containerElement = document.createElement('div');
      containerElement.setAttribute('id', containerID);
      containerElement.setAttribute('style', 'display: none;');
    }

    const isInternRDS = window.location.href.includes('.jotform.dev');
    const domainURL = isInternRDS ? '' : getDomainURL();
    const cardformsLayer = new RequestLayer(`${domainURL}/cardforms`, {
      interceptorConfig: {
        teamID: global.teamID,
        customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
      }
    });
    cardformsLayer.get(iconSpriteURL)
      .then(response => {
        containerElement.innerHTML = response.trim();
        document.body.insertBefore(containerElement, document.body.firstChild);
        return resolve();
      })
      .catch(e => reject(e)); // Send exception to the Sentry!
  });
};

export const safeEscapeCSS = str => {
  if (!CSS?.escape) {
    return str;
  }

  return CSS.escape(str);
};
