import React, { useState } from 'react';
import { translationRenderer } from '@jotforminc/translation';
import {
  gatewayLightIcons, supportedGatewayConnectionPropsList
} from '@jotforminc/payment-constants';
import type { PAYMENT_FIELDS } from '@jotforminc/payment-constants';
import { IconExclamationCircle, IconExclamationCircleFilled, IconBuildings } from '@jotforminc/svg-icons';

/**
 * Component to display a disabled gateway message in an enterprise environment.
 *
 * @param {Object} props - The component props.
 * @param {PAYMENT_FIELDS} props.gatewayType - The type of the payment gateway.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * This component shows an icon and a tooltip message indicating that a specific
 * payment gateway is disabled by the organization. It changes the icon on hover
 * to provide a visual cue to the user.
 */
export const ConnectionGatewayDisabledInEnterprise = ({ gatewayType }: { gatewayType: PAYMENT_FIELDS; }): any => {
  const [isHovered, setIsHovered] = useState(false);

  const Icon = gatewayLightIcons[gatewayType] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  const getGatewayName = supportedGatewayConnectionPropsList[gatewayType].name;

  return (
    <div
      className='reusableModal-tooltip-trigger relative flex items-center'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <IconExclamationCircleFilled className='w-6 h-6 color-gray-800 ease-in-out duration-300' />
      ) : (
        <IconExclamationCircle className='h-6 w-6 color-gray-100 ease-in-out duration-300' />
      )}
      <div
        className="reusableModal-custom-tooltip flex items-center justify-start radius-lg gap-1" style={{
          width: 'max-content'
        }}
      >
        <Icon className='h-6 w-6 shrink-0' />
        <span className='flex items-center gap-1' data-test-id="disabled_message_informs">
          {translationRenderer('[1[{gatewayName}]] is disabled by your [2[{organization}]]')({
            renderer1: () => <span className='font-medium'>{getGatewayName}</span>,
            renderer2: () => (
              <span className='color-orange-400 flex items-center gap-1'>
                <IconBuildings className="color-current shrink-0 w-4 h-4" />
                {' '}
                Organization
              </span>
            )
          })}
        </span>
      </div>

    </div>
  );
};
