import { func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { TetheredImageUpload } from '@jotforminc/image-upload';

const ScTetheredImagePreview = styled.div`
  display: flex;
  border: 1px solid #303339;
  background-color: #626E81;
  align-items: center;
  border-radius: 2px;
  padding: 10px;
  width: 100%;

  .leftSideWrapper {
    flex: 0 0 120px;
    min-width: 0;

    .previewImg {
      width: 120px;
      min-height: 120px;
      max-height: 120px;
      object-fit: contain;
    }
  }

  .rightSideWrapper {
    flex: 1 1 auto;
    overflow: hidden;
    margin-left: 10px;
    height: 120px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .imgName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .removeBtn {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 12px;
      color: #FF6100;
      margin-top: auto;
      display: block;
      text-align: left;
    }
  }

`;

const SchemaImageUpload = ({ value, onChange }) => {
  return (
    <TetheredImageUpload
      PreviewWrapperRenderer={ScTetheredImagePreview}
      value={value} onChange={onChange}
    />
  );
};

SchemaImageUpload.propTypes = {
  value: string,
  onChange: func
};

SchemaImageUpload.defaultProps = {
  value: '',
  onChange: f => f
};

export default SchemaImageUpload;
