import React, { forwardRef } from 'react';
import {
  bool, element, func, oneOf, oneOfType, string
} from 'prop-types';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { t } from '@jotforminc/translation';
import { useSelector } from 'react-redux';
import RadioToggle from '../../../RadioToggle';
import { isYes } from '../../../../../../utils';
import SELECTORS from '../../../../../../store/selectors';

const LoginRequiredToggle = forwardRef(({
  onChange,
  value,
  title,
  description,
  isMixed,
  readOnly,
  tooltipText: _tooltipText,
  badge
}, ref) => {
  const isAppLoginable = isYes(useSelector(SELECTORS.selectIsAppLoginable));
  const shouldReadOnly = (!isYes(value) && isEnterprise() && !isAppLoginable);
  let tooltipText = '';
  if (readOnly) {
    tooltipText = _tooltipText;
  } else if (shouldReadOnly) {
    tooltipText = t('You should check “Require Registration” option or change Access Settings to Private or Company Access in the Settings of the Publish tab.');
  }
  return (
    <RadioToggle
      onChange={onChange}
      value={value}
      title={title}
      description={description}
      isMixed={isMixed}
      readOnly={readOnly || shouldReadOnly}
      tooltipText={tooltipText}
      tooltipTextAlign='left'
      tooltipTextZindex='2'
      badge={badge}
      ref={ref}
    />
  );
});

LoginRequiredToggle.propTypes = {
  onChange: func,
  value: oneOfType([oneOf(['Yes', 'No']), bool]),
  title: string,
  description: string,
  isMixed: bool,
  readOnly: bool,
  tooltipText: string,
  badge: element
};

LoginRequiredToggle.defaultProps = {
  onChange: f => f,
  value: 'No',
  title: '',
  description: '',
  isMixed: false,
  readOnly: false,
  tooltipText: '',
  badge: ''
};

export default LoginRequiredToggle;
