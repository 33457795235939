import React, { useEffect, useRef, useState } from 'react';
import {
  ModalBody, ModalActions, Button, Flex
} from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { RESOURCE_TYPES } from '@jotforminc/resource-picker';
import { EmptyList } from '@jotforminc/empty-list';
import { useDispatch } from 'react-redux';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import ResourcePickerWithControlBar from '../../../../ResourcePickerWithControlBar';
import { fetchTableTabs } from '../../../../../modules/api';
import { stepPropTypeDefaults, stepPropTypes } from '../constants';
import TableSelectionDefaults from '../common/TableSelectionDefaults';
import TabSelection from '../TabSelection';
import ColumnSelection from '../ColumnSelection';
import { TABLE_PRIVACY_TYPES, decidePrivacyType, tablePrivacyWarnings } from './constants';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';

const TableSelection = ({
  nextStep,
  sharedState,
  setSharedState,
  onClose,
  resourceID,
  setSteps
}) => {
  const { selectedResource = null, areTabsLoading = false } = sharedState;
  const dispatch = useDispatch();
  const [fetchKey, setFetchKey] = useState(0);
  const [showCreateTableButton, setShowCreateTableButton] = useState(!selectedResource);
  const resourcePickerRef = useRef(null);

  const privacyType = decidePrivacyType(selectedResource);
  const unallowedPrivacyTypes = [TABLE_PRIVACY_TYPES.PRIVATE_WITH_USER_SUBMISSION];

  const { message: privacyMessage, classNames: privacyClassnames } = tablePrivacyWarnings?.[privacyType] ?? {};

  useEffect(() => {
    const resources = resourcePickerRef.current?.getResources();
    if (resources?.length < 1) {
      setShowCreateTableButton(false);
    } else {
      setShowCreateTableButton(!selectedResource);
    }
  }, [resourcePickerRef.current]);

  // fetch table tabs
  useEffect(() => {
    if (!selectedResource || !selectedResource.id) {
      return;
    }

    const fetchTabs = async () => {
      setSharedState(prev => ({
        ...prev,
        areTabsLoading: true
      }));
      try {
        const tabs = await fetchTableTabs(selectedResource.id);
        const normalizedTabs = Object.entries(tabs).map(([id, tabInfo]) => ({ id, ...tabInfo }));
        setSharedState(prev => ({ ...prev, tableTabs: normalizedTabs }));
        if (normalizedTabs.length > 1) {
          setSteps(prev => [...prev.slice(0, 1), TabSelection, ...prev.slice(1, 2)]);
        } else {
          setSteps([TableSelection, ColumnSelection]);
        }
      } catch (error) {
        console.error('Error fetching table views:', error);
      } finally {
        setSharedState(prev => ({
          ...prev,
          areTabsLoading: false
        }));
      }
    };

    fetchTabs();
  }, [selectedResource]);

  const createTable = () => {
    dispatch(ACTION_CREATORS.dsCreateTableRequest({ setFetchKey }));
  };

  const onNext = () => {
    nextStep();
  };

  const onChange = selection => {
    const privacy = decidePrivacyType(selection[0]);
    if (unallowedPrivacyTypes.includes(privacy)) {
      dispatch(ACTION_CREATORS.trackEventAction({ action: 'unallowedPrivacyTypeSelected', target: { tableID: selection[0]?.id } }));
    }
    setSharedState(prev => (
      { ...prev, selectedResource: selection[0], resourceID: selection[0]?.id }));
  };

  const EmptyListElement = () => (
    <div>
      <EmptyList
        resourceType={RESOURCE_TYPES.SHEET}
        title={'YOU DON\'T HAVE ANY TABLES YET!'}
        description=""
      />

      <Flex justifyContent="center" className='mt-6'>
        <Button
          className='color-approvals-default border-approvals-default'
          variant='outline'
          onClick={createTable}
        >
          {t('Create Table')}
        </Button>
      </Flex>
    </div>
  );

  return (
    <>
      <TableSelectionDefaults.Header onClose={onClose} />
      <ModalBody>
        <ResourcePickerWithControlBar
          ref={resourcePickerRef}
          key={fetchKey}
          resourceType={RESOURCE_TYPES.SHEET}
          fetcherConfig={{ params: { includeShare: 1 }, filters: { 'isEncrypted:eq': 'No' } }}
          onSelectionChange={onChange}
          emptyListPlaceholder={EmptyListElement}
          allowOnlyOwnTeam={true}
          defaultValue={resourceID}
        />
      </ModalBody>
      <ModalActions>
        {
          privacyMessage && (
          <Flex alignItems="center">
            <IconInfoCircleFilled width={16} height={16} className={`flex shrink-0 ${privacyClassnames}`} />
            <span className={`flex ml-2 text-sm line-height-md ${privacyClassnames}`}>
              {t(privacyMessage)}
            </span>
          </Flex>
          )
        }
        {
        showCreateTableButton
        && (
        <Button
          type='button'
          title={t('Next')}
          colorStyle='secondary'
          onClick={createTable}
        >
          {t('Create Table')}
        </Button>
        )
        }
        <Button
          className="ml-auto"
          type='button'
          title={t('Next')}
          colorStyle='primary'
          disabled={areTabsLoading || !selectedResource || unallowedPrivacyTypes.includes(privacyType)}
          onClick={onNext}
        >
          {t('Next')}
        </Button>
      </ModalActions>
    </>
  );
};

TableSelection.propTypes = {
  ...stepPropTypes
};

TableSelection.defaultProps = {
  ...stepPropTypeDefaults
};

export default TableSelection;
