/* eslint-disable max-len */
import Styled from 'styled-components';
import { rightPanelItemBorderColor } from '../../../../styles/colors';

export const ScFontPicker = Styled.div`

  .theme-portal {
    .jfc-font-picker-header {
      background-color: var(--jfv-panel-right-item-background);
      border: var(--jfv-panel-right-item-border);
      border-radius: 2px;
      height: 38px;

      p {
        font-family: ${({ value }) => value};
        color: #ffffff;
      }

      div {
        border-left: 1px solid ${rightPanelItemBorderColor};
        width: 40px;

        span {
          display: block;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 8px solid ${rightPanelItemBorderColor};
        }
      }
    }

    .jfc-font-picker-search {
      display: none;
    }

    .jfc-font-picker-list {
      border-color: #242B3E;
      scrollbar-color: grey black;
      background-color: var(--jfv-panel-dark-gray);
    }

    .jfc-font-picker-list__item {
      background-color: var(--jfv-panel-dark-gray);
      color: #FFFFFF;

      &:after {
        content: "";
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #555F6E;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        opacity: 0;
        transition: .3s;
      }

      &:hover {
        background-color: var(--jfv-panel-darkest-gray);

        &:after {
          opacity: 1;
        }
      }

      &.is-selected {
        background-color: var(--jfv-panel-selected-blue);

        &:before {
          background-color: transparent;
        }

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.5' cy='11.5' r='11.5' fill='%23fff'/%3E%3Cpath d='M9.66591 16H9.6644c-.23734 0-.452-.0956-.60695-.2501l-3.86622-3.8452C5.07256 11.7606 5 11.5747 5 11.3716c0-.4682.38398-.8475.8579-.8475.21013 0 .40212.0747.55102.1979l3.26001 3.2434 6.86697-6.71976c.155-.15156.3689-.24564.6047-.24564.4747 0 .8594.38004.8594.84893 0 .23594-.0975.44873-.2547.60254l-7.4755 7.30213c-.1542.1523-.3673.2464-.60313.2464h-.00151.00075Z' fill='%2309F'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1;
        }
      }
    }
  }
`;
/* eslint-enable max-len */
