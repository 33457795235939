import {
  IconCartShoppingFilled,
  IconHeartFilled,
  IconCreditCardFilled,
  IconCoinsFilled
} from '@jotforminc/svg-icons';
import { I_PAYMENT_TYPE_LIST } from '../Types';

const PAYMENT_TYPES: I_PAYMENT_TYPE_LIST[] = [
  {
    type: 'product',
    name: 'Sell Products or Services',
    description: 'Create a list of products to sell of services to book',
    icon: IconCartShoppingFilled,
    color: 'color-green-500'
  },
  {
    type: 'donation',
    name: 'Collect Donations',
    description: 'Let users make secure contributions',
    icon: IconHeartFilled,
    color: 'color-blue-400'
  },
  {
    type: 'subscription',
    name: 'Sell Subscriptions',
    description: 'Easily sell and manage subscription-based services',
    icon: IconCreditCardFilled,
    color: 'color-orange-500'
  },
  {
    type: 'custom',
    name: 'Receive Custom Amount',
    description: 'Let users enter a custom amount or calculate it automatically',
    icon: IconCoinsFilled,
    color: 'color-navy-500'
  }
];

export default PAYMENT_TYPES;
