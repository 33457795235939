import React from 'react';
import PropTypes from 'prop-types';

const DataGridCell = ({
  type, id, value, onCellChange, disabled, ...props
}) => {
  switch (type) {
    case 'textInput':
      return (
        <input
          value={value}
          onChange={e => onCellChange(e.target.value, id)}
          disabled={disabled}
          {...props}
        />
      );
    case 'label':
    default:
      return <div {...props}>{value}</div>;
  }
};

DataGridCell.propTypes = {
  type: PropTypes.oneOf(['textInput', 'label']).isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onCellChange: PropTypes.func
};

DataGridCell.defaultProps = {
  onCellChange: () => {}
};

export default DataGridCell;
