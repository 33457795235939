import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export const getUnpaidInvoices = () => layer.get('enterprise/invoice/getUnpaidInvoices');

export const fetchNavigationResources = (from, id, to) => layer.get(`navigate/${from}/${id}/${to}`);

export const fetchTeamProps = teamID => layer.get(`team/${teamID}/properties`, { headers: { 'jf-team-id': teamID } });
