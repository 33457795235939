import type { GATEWAY_NAMES } from '../types';

/**
 * Mapping of gateway field names to their corresponding gateway names.
 * @type {GATEWAY_NAMES}
 */
export const gatewayFieldToName: GATEWAY_NAMES = {
  control_square: 'Square',
  control_paypalcomplete: 'PayPal Business',
  control_paypal: 'PayPal Personal',
  control_paypalInvoicing: 'PayPal Invoicing',
  control_paypalpro: 'PayPal Pro',
  control_paypalSPB: 'PayPal Checkout',
  control_paypalexpress: 'PayPal Express',
  control_authnet: 'Authorize.Net',
  control_stripe: 'Stripe',
  control_stripeCheckout: 'Stripe Checkout',
  control_stripeACHManual: 'Stripe ACH Manual',
  control_stripeACH: 'Stripe ACH',
  control_braintree: 'Braintree',
  control_cashApp: 'Cash App Pay',
  control_afterpay: 'Afterpay',
  control_clearpay: 'Clearpay',
  control_venmo: 'Venmo',
  control_applePayGooglePay: 'Apple & Google Pay',
  control_mollie: 'Mollie',
  control_cybersource: 'CyberSource',
  control_echeck: 'eCheck.Net',
  control_bluepay: 'Bluepay',
  control_eway: 'Eway',
  control_payu: 'PayU',
  control_payuMoney: 'PayU India',
  control_worldpay: 'Worldpay UK',
  control_bluesnap: 'BlueSnap',
  control_moneris: 'Moneris',
  control_gocardless: 'GoCardless',
  control_payfast: 'Payfast',
  control_sensepass: 'SensePass',
  control_sofort: 'Sofort',
  control_pagseguro: 'PagSeguro',
  control_firstdata: 'First Data',
  control_cardconnect: 'CardConnect',
  control_worldpayus: 'Worldpay US',
  control_2co: '2Checkout',
  control_paysafe: 'Paysafe',
  control_iyzico: 'iyzico',
  control_skrill: 'Skrill',
  control_chargify: 'Chargify',
  control_payjunction: 'PayJunction',
  control_clickbank: 'ClickBank',
  control_onebip: 'OneBip',
  control_paymentwall: 'Paymentwall',
  control_payment: 'Purchase Order'
};
