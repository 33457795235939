export const DEFAULT_GATEWAY_PROPS = [
  {
    type: 'control_paypalcomplete',
    properties: {
      sandbox: 'disabled',
      showCardFields: 'Yes',
      showSPB: 'Yes'
    }
  },
  {
    type: 'control_Venmo',
    properties: {
      type: 'control_paypalcomplete',
      nameAPM: 'Venmo',
      sandbox: 'disabled'
    }
  },
  {
    type: 'control_paypalInvoicing',
    properties: {
      sandbox: 'Disabled'
    }
  },
  {
    type: 'control_stripe',
    properties: {
      allowTest: 'false',
      createCustomer: 'No'
    }
  },
  {
    type: 'control_square',
    properties: {
      allowTest: 'false'
    }
  },
  {
    type: 'control_CashApp',
    properties: {
      type: 'control_square',
      allowTest: 'false',
      nameAPM: 'CashApp'
    }
  },
  {
    type: 'control_mollie',
    properties: {
      sandbox: 'No'
    }
  }
];
