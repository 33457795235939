const generatePromiseActionType = (actionType, undoable = false) => ({
  REQUEST: `${actionType}/REQUEST`,
  SUCCESS: `${actionType}/SUCCESS`,
  ERROR: `${actionType}/ERROR`,
  ...undoable && {
    UNDOABLE: `${actionType}/UNDOABLE`
  }
});

export const FETCH_USER = generatePromiseActionType('FETCH_USER');
export const UPDATE_USER = generatePromiseActionType('UPDATE_USER');
export const CONTINUE_AS_USER = generatePromiseActionType('CONTINUE_AS_USER');
export const FETCH_PORTAL = generatePromiseActionType('FETCH_PORTAL');
export const FETCH_SHARE_INFO = generatePromiseActionType('FETCH_SHARE_INFO');
export const FETCH_ENVIRONMENT = generatePromiseActionType('FETCH_ENVIRONMENT');
export const UPDATE_PORTAL = generatePromiseActionType('UPDATE_PORTAL', true);
export const ENABLE_PUSH_NOTIFICATION = 'ENABLE_PUSH_NOTIFICATION';
export const UPDATE_PORTAL_SLUG = generatePromiseActionType('UPDATE_PORTAL_SLUG');
export const ADD_PORTAL_ITEMS = generatePromiseActionType('ADD_PORTAL_ITEMS');
export const CREATE_DATA_SOURCE_ITEM = generatePromiseActionType('CREATE_DATA_SOURCE_ITEM');
export const UPDATE_ORDER = generatePromiseActionType('UPDATE_ORDER', true);
export const UPDATE_ITEM_PROP = {
  ...generatePromiseActionType('UPDATE_ITEM_PROP', true),
  WITH_DEBOUNCE: 'WITH_DEBOUNCE',
  WITHOUT_DEBOUNCE: 'WITHOUT_DEBOUNCE'
};
export const UPDATE_MULTIPLE_ITEM = generatePromiseActionType('UPDATE_MULTIPLE_ITEM', true);
export const REMOVE_PORTAL_ITEMS = generatePromiseActionType('REMOVE_PORTAL_ITEMS');
export const FETCH_FORMS = generatePromiseActionType('FETCH_FORMS');
export const FETCH_SIGNS = generatePromiseActionType('FETCH_SIGNS');
export const CREATE_NEW_PORTAL = generatePromiseActionType('CREATE_NEW_PORTAL');
export const FETCH_SHARE_LIST = generatePromiseActionType('FETCH_SHARE_LIST');
export const BULK_SHARE_PORTAL = generatePromiseActionType('BULK_SHARE_PORTAL');
export const BULK_DELETE_SHARE_PORTAL = generatePromiseActionType('BULK_DELETE_SHARE_PORTAL');
export const CREATE_FORM_FROM_TEMPLATE = generatePromiseActionType('CREATE_FORM_FROM_TEMPLATE');
export const CREATE_FORM_FROM_SCRATCH = generatePromiseActionType('CREATE_FORM_FROM_SCRATCH');
export const CREATE_SIGN_DOCUMENT_FROM_TEMPLATE = generatePromiseActionType('CREATE_SIGN_DOCUMENT_FROM_TEMPLATE');
export const UPDATE_RESOURCE_SHARE_URL = generatePromiseActionType('UPDATE_RESOURCE_SHARE_URL');
export const SEND_FEEDBACK = generatePromiseActionType('SEND_FEEDBACK');
export const FETCH_APPS = generatePromiseActionType('FETCH_APPS');
export const FETCH_WIDGETS = generatePromiseActionType('FETCH_WIDGETS');
export const FETCH_CDN_CONFIG = generatePromiseActionType('FETCH_CDN_CONFIG');
export const LAYOUT_CHANGE = generatePromiseActionType('LAYOUT_CHANGE');
export const CHANGE_PAGE_ORDER = generatePromiseActionType('CHANGE_PAGE_ORDER');
export const DUPLICATE_ITEM = 'DUPLICATE_ITEM';
export const PRODUCT_LIST_CHANGE = 'PRODUCT_LIST_CHANGE';
export const ADD_PRODUCT_LIST_ITEM = generatePromiseActionType('ADD_PRODUCT_LIST_ITEM', true);
export const UPDATE_PRODUCT_LIST_ITEM = generatePromiseActionType('UPDATE_PRODUCT_LIST_ITEM', true);
export const DELETE_PRODUCT_LIST_ITEM = generatePromiseActionType('DELETE_PRODUCT_LIST_ITEM', true);
export const FETCH_USER_TEAMS = generatePromiseActionType('FETCH_USER_TEAMS');
export const FETCH_USER_TEAM_PERMISSIONS = generatePromiseActionType('FETCH_USER_TEAM_PERMISSIONS');
export const UPDATE_APP_LOGO = generatePromiseActionType('UPDATE_APP_LOGO');
export const REPLACE_FORM_ITEM = generatePromiseActionType('REPLACE_FORM_ITEM');
export const FETCH_STORE_PROPERTIES = generatePromiseActionType('FETCH_STORE_PROPERTIES');

export const FETCH_CHECKOUT_FORM_QUESTIONS = generatePromiseActionType('FETCH_CHECKOUT_FORM_QUESTIONS');
export const ADD_CHECKOUT_FORM_QUESTION = generatePromiseActionType('ADD_CHECKOUT_FORM_QUESTION');
export const REMOVE_CHECKOUT_FORM_QUESTION = generatePromiseActionType('REMOVE_CHECKOUT_FORM_QUESTION');
export const UPDATE_CHECKOUT_FORM_QUESTION = generatePromiseActionType('UPDATE_CHECKOUT_FORM_QUESTION');
export const SORT_CHECKOUT_FORM_QUESTION = generatePromiseActionType('SORT_CHECKOUT_FORM_QUESTION');
export const CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE = generatePromiseActionType('CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE');
export const CHECKOUT_FORM_SETTINGS_CHANGE = generatePromiseActionType('CHECKOUT_FORM_SETTINGS_CHANGE');

// Gateway Actions
export const SET_PAYMENT_MODAL_FLOW_TYPE = '@UI/PAYMENT_MODAL_FLOW_TYPE';
export const SET_PAYMENT_MODAL_FLOW_SELECTED_GATEWAY = '@UI/PAYMENT_MODAL_FLOW_SELECTED_GATEWAY';
export const SET_PAYMENT_MODAL_FLOW_STEP = '@UI/PAYMENT_MODAL_FLOW_STEP';

export const SET_APP_DESIGN_MODE = '@UI/SET_APP_DESIGN_MODE';

export const TOGGLE_ACCOUNT_BOX = '@UI/TOGGLE_ACCOUNT_BOX';
export const SET_RIGHT_PANEL_MODE = '@UI/SET_RIGHT_PANEL_MODE';
export const SET_API_REQUESTS_COMPLETED = '@UI/SET_API_REQUESTS_COMPLETED';
export const APP_UPDATED = '@UI/APP_UPDATED';
export const SET_PWA_STATUS = '@UI/SET_PWA_STATUS';
export const TOGGLE_RIGHT_PANEL = '@UI/TOGGLE_RIGHT_PANEL';
export const TOGGLE_LEFT_PANEL = '@UI/TOGGLE_LEFT_PANEL';
export const TOGGLE_PREVIEW_PANEL = '@UI/TOGGLE_PREVIEW_PANEL';
export const TOGGLE_LIVE_PREVIEW = '@UI/TOGGLE_LIVE_PREVIEW';
export const SHOULD_WINDOW_SQUEEZE = '@UI/SHOULD_WINDOW_SQUEEZE';
export const APP_PREVIEW_STATUS = '@UI/APP_PREVIEW_STATUS';
export const APP_PREVIEW_DEVICE = '@UI/APP_PREVIEW_DEVICE';
export const SET_APP_HEADER_CROP_MODE = '@UI/SET_APP_HEADER_CROP_MODE';
export const SET_APP_COVER_CROP_MODE = '@UI/SET_APP_COVER_CROP_MODE';
export const SET_ITEM_LOADING_STATUS = '@UI/SET_ITEM_LOADING_STATUS';
export const SET_DISABLED_APP_SCREEN = '@UI/SET_DISABLED_APP_SCREEN';
export const SET_LAST_ADDED_ITEM = '@UI/SET_LAST_ADDED_ITEM';
export const SET_APP_LOCATION = '@UI/SET_APP_LOCATION';
export const FETCH_RESOURCES_ERROR = '@UI/FETCH_RESOURCES/ERROR';
export const SET_FORCE_TITLE_RENAME = '@UI/SET_FORCE_TITLE_RENAME';
export const SET_ACTIVE_SCREEN = '@UI/SET_ACTIVE_SCREEN';
export const SET_ACTIVE_FORM_PRODUCT = '@UI/SET_ACTIVE_FORM_PRODUCT';
export const PREPARE_RIGHT_PANEL = '@UI/PREPARE_RIGHT_PANEL';
export const SET_ACTIVE_ITEM = '@UI/SET_ACTIVE_ITEM';
export const SET_ACTIVE_BUILDER_PAGE = '@UI/SET_ACTIVE_BUILDER_PAGE';

export const SELECT_FORM = '@CORE/SELECT_FORM';
export const SELECT_PORTAL_ITEM = '@UI/SELECT_PORTAL_ITEM';
export const SELECT_MULTIPLE_PORTAL_ITEM = '@CORE/SELECT_MULTIPLE_PORTAL_ITEM';
export const SELECT_ALL_ITEMS = '@CORE/SELECT_ALL_ITEMS';
export const REMOVE_SELECTED_ITEMS = '@CORE/REMOVE_SELECTED_ITEMS';
export const TOGGLE_MOBILE_MULTIPLE_SELECTION_MODE = '@UI/TOGGLE_MOBILE_MULTIPLE_SELECTION_MODE';
export const SET_APP_STATUS = '@CORE/SET_APP_STATUS';
export const TOGGLE_NETWORK_STATUS = '@CORE/TOGGLE_NETWORK_STATUS';
export const SET_MODE = '@CORE/SET_MODE';
export const TRACK_EVENT = '@CORE/TRACK_EVENT';
export const UNDO = '@CORE/UNDO';
export const REDO = '@CORE/REDO';
export const NAVIGATE_TO = '@CORE/NAVIGATE_TO';

export const APPLY_COLOR_SCHEME_TO_APP = '@THEME/APPLY_COLOR_SCHEME_TO_APP';
export const APPLY_COLOR_SCHEME_TO_ITEM = '@THEME/APPLY_COLOR_SCHEME_TO_ITEM';

export const ADD_TO_STACK = '@COMMAND/ADD_TO_STACK';
export const RESET_STACK = '@COMMAND/RESET_STACK';

export const TOAST = {
  ERROR: '@TOAST/ERROR',
  NOTIFICATION: '@TOAST/NOTIFICATION',
  UNDO_NOTIFICATION: '@TOAST/UNDO_NOTIFICATION',
  SHOPPING_NOTIFICATION: '@TOAST/SHOPPING_NOTIFICATION'
};
export const APP_TOAST_STACK = generatePromiseActionType('@UI/APP_TOAST_STACK');

export const APP_TOAST = {
  SHOPPING_NOTIFICATION: '@APP_TOAST/SHOPPING_NOTIFICATION'
};

export const SHOW_MODAL = '@MODALS/SHOW_MODAL';

export const SHOW_WHATS_NEW_MODAL = '@MODALS/SHOW_MODAL_WHATS_NEW_MODAL';
export const START_SHOW_WHATS_NEW_MODAL_FLOW = '@START_SHOW_WHATS_NEW_MODAL_FLOW';

export const BUILD_WITH_FORM = 'BUILD_WITH_FORM';

export const SET_TODO_COUNT = '@PROGRESS/SET_TODO_COUNT';
export const SET_DONE_COUNT = '@PROGRESS/SET_DONE_COUNT';
export const CALCULATE_TODO_COUNT = '@PROGRESS/CALCULATE_TODO_COUNT';
export const CALCULATE_DONE_COUNT = '@PROGRESS/CALCULATE_DONE_COUNT';
export const SET_IS_APP_DONE = '@PROGRESS/SET_APP_DONE';
export const SET_LAST_ANIMATED_DONE_COUNT = '@PROGRESS/SET_LAST_ANIMATED_DONE_COUNT';

export const RESTART_PROGRESS = 'RESTART_PROGRESS';

export const BRANDING_BANNER_CLICK = 'BRANDING_BANNER_CLICK';

export const ADD_NEW_PAGE = generatePromiseActionType('ADD_NEW_PAGE', true);
export const UPDATE_PAGE = generatePromiseActionType('UPDATE_PAGE', true);
export const DELETE_PAGE = generatePromiseActionType('DELETE_PAGE', true);
export const SELECT_PAGES = '@UI/SELECT_PAGES';
export const UPDATE_LAST_INTERACTED_PAGE_ID = '@PORTAL/UPDATE_LAST_INTERACTED_PAGE_ID';
export const WATCH_HEADING_ITEM_FOR_PAGE_NAMING = 'WATCH_HEADING_ITEM_FOR_PAGE_NAMING';
export const ACTIVE_PAGE = 'ACTIVE_PAGE/PUBLIC_APP'; // which page is active on the public app

export const SET_CHECKOUT_FORM_STATUS = '@UI/SET_CHECKOUT_FORM_STATUS';
export const SET_CHECKOUT_FORM_VISIBILITY = '@UI/SET_CHECKOUT_FORM_VISIBILITY';

export const ITEM_ADDITION_PORTAL_ORDER_WORKER = generatePromiseActionType('ITEM_ADDITION_PORTAL_ORDER_WORKER');
export const UPDATE_ORDER_WORKER = generatePromiseActionType('UPDATE_ORDER_WORKER');

export const SET_TEMPLATE_CATEGORIES = '@SHARE/SET_TEMPLATE_CATEGORIES';
export const SET_TEMPLATE_LANGUAGES = '@SHARE/SET_TEMPLATE_LANGUAGES';

export const SET_USER_SLUG = '@UI/SET_USER_SLUG';
export const SET_UI_PROP = '@UI/SET_UI_PROP';

export const SET_ACTIVE_PRODUCT = '@PRODUCTLIST/SET_ACTIVE_PRODUCT';
export const PRODUCT_ITEM_DETAIL = generatePromiseActionType('@PRODUCTLIST/PRODUCT_ITEM_DETAIL');
export const SEARCH_IN_PRODUCTS = '@PRODUCTLIST/SEARCH_IN_PRODUCTS';
export const FILTER_PRODUCTS = '@PRODUCTLIST/FILTER_PRODUCTS';
export const SET_CART_PRODUCTS = '@PRODUCTLIST/SET_CART_PRODUCTS';
export const ADD_OR_UPDATE_CART = '@PRODUCTLIST/ADD_OR_UPDATE_CART';
export const FAVORITE_PRODUCT = '@PRODUCTLIST/FAVORITE_PRODUCT';
export const SET_FAVORITE_PRODUCTS = '@PRODUCTLIST/SET_FAVORITE_PRODUCTS';
export const SET_CHECKOUT_KEY = '@PRODUCTLIST/SET_CHECKOUT_KEY';
export const SET_IS_CHANGED_PRICE_IN_CART = '@PRODUCTLIST/SET_IS_CHANGED_PRICE_IN_CART';

export const USER_CHANGE = 'USER_CHANGE';

export const UPDATE_PORTAL_USER_PROPS = generatePromiseActionType('UPDATE_PORTAL_USER_PROPS');

export const SET_ROUTER_ANIMATION_TYPE = '@UI/SET_ROUTER_ANIMATION_TYPE';

export const NAVIGATION_ITEM_CLICK = 'NAVIGATION_ITEM_CLICK';
export const NAVIGATION_SETTINGS_POPOVER = 'NAVIGATION_SETTINGS_POPOVER';

export const ON_TODO_COMPLETE = 'ON_TODO_COMPLETE';

export const ON_PRODUCT_ADD_TO_CART = 'ON_PRODUCT_ADD_TO_CART';
export const CREATE_PORTAL_WITH_STORE = 'CREATE_PORTAL_WITH_STORE';
export const CREATE_PORTAL_WITH_TEMPLATE = 'CREATE_PORTAL_WITH_TEMPLATE';
export const CREATE_PORTAL_WITH_DONATION = 'CREATE_PORTAL_WITH_DONATION';
export const SHOW_DONATION_ITEM = 'SHOW_DONATION_ITEM';

export const ON_FORM_PICKER_MODAL_CONFIRM = 'ON_FORM_PICKER_MODAL_CONFIRM';
export const ON_RESOURCE_PICKER_MODAL_CONFIRM = 'ON_RESOURCE_PICKER_MODAL_CONFIRM';
export const ON_LEFT_PANEL_ITEM_CLICK = 'ON_LEFT_PANEL_ITEM_CLICK';
export const ON_ITEM_CLICK = 'ON_ITEM_CLICK';
export const ON_STAGE_CLICK = 'ON_STAGE_CLICK';
export const ON_DRAG_END = 'ON_DRAG_END';
export const ON_DELETE_PAGE = 'ON_DELETE_PAGE';
export const ON_MULTIPLE_ITEM_UPDATE = 'ON_MULTIPLE_ITEM_UPDATE';

export const OPEN_RIGHT_PANEL_WITH_MODE = 'OPEN_RIGHT_PANEL_WITH_MODE';
export const SET_ACTIVE_RIGHT_PANEL_TAB = 'SET_ACTIVE_RIGHT_PANEL_TAB';

export const OPEN_PRODUCT_DETAIL = 'OPEN_PRODUCT_DETAIL';

export const DONATE = 'DONATE';
export const INIT_ELEMENTS_PANEL_AB_TEST = 'INIT_ELEMENTS_PANEL_AB_TEST';

export const PUSH_NOTIFICATION_ALLOW = 'PUSH_NOTIFICATION_ALLOW';
export const PUSH_NOTIFICATION_DISMISS = 'PUSH_NOTIFICATION_DISMISS';
export const FETCH_NOTIFICATON_HISTORY = generatePromiseActionType('FETCH_NOTIFICATON_HISTORY');
export const FETCH_NOTIFICATION_STATS = generatePromiseActionType('FETCH_NOTIFICATION_STATS');
export const SEND_PUSH_NOTIFICATION = generatePromiseActionType('SEND_PUSH_NOTIFICATION');

// Live Preview Actions
export const SET_LIVE_PREVIEW_SIZE_MODE = '@UI/SET_LIVE_PREVIEW_SIZE_MODE';
export const SET_LIVE_PREVIEW_STATUS = '@UI/LIVE_PREVIEW_STATUS';

/// Data Source Actions
export const DS_CREATE_DETAIL_PAGE = 'DS_CREATE_DETAIL_PAGE';
export const DS_FETCH_RESOURCE_COLUMNS = { ...generatePromiseActionType('DS_FETCH_COLUMNS'), WITH_THROTTLE: 'WITH_THROTTLE' };
export const DS_FETCH_SOURCE_ITEM_DATA = generatePromiseActionType('DS_FETCH_SOURCE_ITEM_DATA');
export const DS_FETCH_RESOURCE_ROW = {
  PAGINATED_SUCCESS: 'PAGINATED_SUCCESS',
  ...generatePromiseActionType('DS_FETCH_RESOURCE_ROW')
};

export const DS_SET_LOADING = 'DS_SET_LOADING';
export const DS_LIST_SET_ACTIVE_ROW_ID = generatePromiseActionType('DS_LIST_SET_ACTIVE_ROW_ID');

export const DS_INITIALIZE_ITEMS = 'DS_INITIALIZE_ITEMS';
export const DS_CREATE_DETAIL_PAGE_DEFAULTS = 'DS_CREATE_DETAIL_PAGE_DEFAULTS';
export const DS_INITIALIZE_PROPS_WITH_COLUMNS = generatePromiseActionType('DS_INITIALIZE_PROPS_WITH_COLUMNS');
export const DS_DELETE_DETAIL_PAGE = 'DS_DELETE_DETAIL_PAGE';
export const DS_FETCH_DETAIL_PAGE = generatePromiseActionType('DS_FETCH_DETAIL_PAGE');
export const DS_EDIT_TABLE = generatePromiseActionType('DS_EDIT_TABLE');
export const DS_CREATE_TABLE = generatePromiseActionType('DS_CREATE_TABLE');
export const DS_FETCH_ALL_RESOURCE_LINKED_ITEMS = 'DS_FETCH_ALL_RESOURCE_LINKED_ITEMS';
export const DS_CLEAR_ITEMS_BY_IDS = 'DS_CLEAR_ITEMS_BY_IDS';

// AI assistant actions
export const AI_ADD_CHAT_MESSAGE = 'AI_ADD_CHAT_MESSAGE';
export const AI_ADD_ASSISTANT_CHAT_MESSAGE = 'AI_ADD_ASSISTANT_CHAT_MESSAGE';
export const AI_SHOW_ASSISTANT = 'AI_SHOW_ASSISTANT';
export const AI_SET_MESSAGES = 'AI_SET_MESSAGES';
export const AI_SHOW_ERROR_MESSAGE = 'AI_SHOW_ERROR_MESSAGE';

// User Management
export const SET_USER_MANAGEMENT_USERS = 'SET_USER_MANAGEMENT_USERS';

export const NAVIGATE_BACK = 'NAVIGATE_BACK';
export const POPSTATE = 'POPSTATE';
