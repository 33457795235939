import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { InputText } from '@jotforminc/magnet';
import { useDispatch, useSelector } from 'react-redux';
import { ScRightPanelBlock } from '../../../../styles/scRightPanel';
import SELECTORS from '../../../../../../store/selectors';
import RadioToggle from '../../../RadioToggle';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { ScLineHr } from '../ScLineHr';
import PageSettingsContainer from './PageSettingsContainer';

const AllPagesSettingsPanel = ({
  showAllPagesIcon
}) => {
  const dispatch = useDispatch();
  const _pages = useSelector(SELECTORS.getPagesWithNames);
  const [query, setQuery] = useState('');
  const pages = _pages.filter(page => !page.linkedItemID);

  const onPortalPropertyChange = propName => value => {
    dispatch(ACTION_CREATORS.updatePortalAction({
      [propName]: value
    }));
  };

  const filteredPages = Hooks.useFuse(
    _pages,
    query,
    ['name', 'pageIcon'],
    {
      threshold: 0.4, tokenize: true, matchAllTokens: true, distance: 400
    }
  ).map(p => p.item || p);

  return (
    <>
      <ScLineHr />
      <ScRightPanelBlock line>
        <RadioToggle
          onChange={onPortalPropertyChange('showAllPagesIcon')}
          value={showAllPagesIcon}
          title={t('Disable Page Icons')}
        />
      </ScRightPanelBlock>
      <ScLineHr />
      {pages.length >= 10 && (
        <ScRightPanelBlock line style={{ paddingBottom: 0 }}>
          <InputText
            id="search"
            placeholder={t('Search Page')}
            onChange={e => setQuery(e.target.value)}
            theme='dark'
          />
        </ScRightPanelBlock>
      )}
      <ScRightPanelBlock line>
        {filteredPages.length > 0 ? (
          <PageSettingsContainer showAllPagesIcon={showAllPagesIcon} filteredPages={filteredPages} />
        ) : (
          <div>{t('There is no page.')}</div>
        )}
      </ScRightPanelBlock>
    </>
  );
};

AllPagesSettingsPanel.propTypes = {
  showAllPagesIcon: string
};

AllPagesSettingsPanel.defaultProps = {
  showAllPagesIcon: ''
};

export default AllPagesSettingsPanel;
