import React from 'react';
import { useDispatch } from 'react-redux';
import { getLayoutProps } from './appLayoutConstants';
import AppLayoutItem from './AppLayoutItem';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';

const AppLayoutsPanel = ({ onChange, value }) => {
  const dispatch = useDispatch();
  return Object.entries(getLayoutProps()).map(item => {
    const [key, { title, icon }] = item;

    const handleLayoutChange = () => {
      onChange(key);
      dispatch(ACTION_CREATORS.layoutChangeAction(key));
    };

    return (
      <AppLayoutItem
        key={key}
        isSelected={value === key}
        onClick={handleLayoutChange}
        title={title}
        icon={icon}
      />
    );
  });
};

export default AppLayoutsPanel;
