import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Flex } from '@jotforminc/magnet';
import Column from './Column';
import { stepPropTypeDefaults, stepPropTypes } from '../../constants';
import SelectionItem from './SelectionItem';
import SelectedItem from './SelectedItem';
import { COLUMN_IDS } from './constants';

const SelectionDnd = ({ columns, setColumns }) => {
  const onDragEnd = result => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (
      (
        source.droppableId === COLUMN_IDS.SELECTION_COLUMN
        && source.droppableId === destination.droppableId
      )
    ) {
      return;
    }

    // prevent item move from selected column to selection column
    // and remove the moved item
    if (source.droppableId === COLUMN_IDS.SELECTEDS_COLUMN && destination.droppableId === COLUMN_IDS.SELECTION_COLUMN) {
      const sourceColumn = columns[source.droppableId];
      const sourceItems = [...sourceColumn.items];
      sourceItems.splice(source.index, 1);
      setColumns(prev => ({
        ...prev,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        }
      }));
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const sourceItem = sourceItems[source.index];

      // check item is already added
      if (destItems.some(destItem => destItem.qid === sourceItem.qid)) {
        return;
      }

      destItems.splice(destination.index, 0, sourceItem);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  return (
    <Flex className='h-22 gap-2 justify-between'>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns)}
      >
        <Column
          title='Table Colums'
          column={columns.selectionColumn}
          columns={columns}
          Item={SelectionItem}
          setColumns={setColumns}
        />
        <Column
          title='Detail Page'
          column={columns.selectedsColumn}
          columns={columns}
          Item={SelectedItem}
          setColumns={setColumns}
        />
      </DragDropContext>
    </Flex>
  );
};

SelectionDnd.propTypes = {
  ...stepPropTypes
};

SelectionDnd.defaultProps = {
  ...stepPropTypeDefaults
};

export default SelectionDnd;
