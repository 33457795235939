import React, { useCallback } from 'react';
import { IconCartShoppingFilled } from '@jotforminc/svg-icons';
import Styled from 'styled-components';
import { shape } from 'prop-types';
import PortalItem from './PortalItem';
import { ScPortalOption } from './scPortalOption';
import { theme as defaultPortalOptionTheme } from '../../FormPicker/helpers/defaultTheme';
import { portalDescriptionCommonStyle, portalTitleCommonStyle } from './PortalItemDefaults/commonCss';

const StyledTitleContainerRenderer = Styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const StyledTitleRenderer = Styled.h2`
  && {
  ${portalTitleCommonStyle}
  font-weight: ${p => p.titleFontWeight};
  color: ${p => p.titleColor};
  font-size: 16px;
  line-height: 20px;
  }
`;

const StyledDescriptionRenderer = Styled.p`
  && {
    ${portalDescriptionCommonStyle}
    color: ${p => p.detailsColor};
    font-size: 14px;
    line-height: 18px;
  }
`;

const PortalOption = ({ option, theme }) => {
  const isBranding21 = true;
  const { isDisabled = false } = option;

  const TitleRenderer = useCallback(({ title, isStoreApp }) => {
    const { titleFontWeight, titleFontSize, titleColor } = theme;
    return (
      <StyledTitleContainerRenderer>
        <StyledTitleRenderer
          titleFontWeight={titleFontWeight}
          titleFontSize={titleFontSize}
          titleColor={titleColor}
        >
          {title}
        </StyledTitleRenderer>
        {isStoreApp && <IconCartShoppingFilled />}
      </StyledTitleContainerRenderer>
    );
  }, [theme]);

  const DescriptionRenderer = useCallback(({ children }) => {
    const { detailsColor, detailsFontSize } = theme;
    return (
      <StyledDescriptionRenderer
        detailsColor={detailsColor}
        detailsFontSize={detailsFontSize}
      >
        {children}
      </StyledDescriptionRenderer>
    );
  }, [theme]);

  return (
    <ScPortalOption isBranding21={isBranding21} theme={theme} isDisabled={isDisabled}>
      <PortalItem {...option} TitleRenderer={TitleRenderer} DescriptionRenderer={DescriptionRenderer} />
    </ScPortalOption>
  );
};

PortalOption.propTypes = {
  option: shape({}),
  theme: shape({})
};
PortalOption.defaultProps = {
  option: {},
  theme: defaultPortalOptionTheme
};

export default PortalOption;
