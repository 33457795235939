import type { GATEWAY_SUPPORTED_RESOURCES } from '@jotforminc/payment-constants';
import { requestLayer } from './requestLayer';

/**
 * Detaches a connection resource from a payment gateway.
 *
 * @param {GATEWAY_SUPPORTED_RESOURCES} resourceType - The type of the resource to detach.
 * @param {string} resourceId - The ID of the resource to detach.
 * @returns {Promise<any>} - A promise that resolves when the resource is detached.
 */
export const detachConnectionResource = (resourceType: GATEWAY_SUPPORTED_RESOURCES, resourceId: string): Promise<any> => {
  return requestLayer.post(`/API/payment/detach-connection/${resourceType}/${resourceId}`);
};
