import { func, string, array } from 'prop-types';
import React, { useCallback } from 'react';
import RoleItem from './RolesItem';
import ScRoles from './ScRoles';

const Roles = ({
  onChange, buttonActionProps, value
}) => {
  return (
    <ScRoles>
      {
        buttonActionProps.map(actionButtonProps => {
          const { value: actionButtonValue } = actionButtonProps;
          const handleOnChange = useCallback(() => onChange({ buttonRole: actionButtonValue }));
          const isSelected = value === actionButtonValue;
          return (
            <RoleItem
              key={actionButtonValue}
              isSelected={isSelected}
              onChange={handleOnChange}
              {...actionButtonProps}
            />
          );
        })
      }
    </ScRoles>
  );
};

Roles.propTypes = {
  onChange: func,
  value: string,
  buttonActionProps: array
};

Roles.defaultProps = {
  onChange: f => f,
  buttonActionProps: [],
  value: undefined
};

export default Roles;
