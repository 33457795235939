import React from 'react';
import { string } from 'prop-types';

const Color = ({ color }) => (
  <span className="suffix" style={{ padding: '0 5px' }}>
    <div className="colorPickerAfter" style={{ dwidth: 28, height: 28, background: color }} />
  </span>
);

Color.propTypes = {
  color: string
};

Color.defaultProps = {
  color: ''
};

export default Color;
