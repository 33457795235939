import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Authorize.Net gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlAuthnet: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_authnet: {
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    askBillingInformation: {
      title: 'Ask Billing Information to Customer',
      description: 'Automate collecting billing information with form fields.',
      type: 'toggle',
      selectedCondition: settings => settings.askBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    shipToName: {
      title: 'Shipping Name Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'askBillingInformation', operator: '===', value: 'Yes' },
      autoSave: true
    },
    shipToAddress: {
      title: 'Shipping Address Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'askBillingInformation', operator: '===', value: 'Yes' },
      autoSave: true
    },
    emailField: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'askBillingInformation', operator: '===', value: 'Yes' },
      autoSave: true
    },
    authInvoiceField: {
      title: 'Invoice Number Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_autoincrement', 'control_textbox', 'control_hidden'],
      questionTypeToAdd: 'control_autoincrement',
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'askBillingInformation', operator: '===', value: 'Yes' },
      autoSave: true
    },
    companyName: {
      title: 'Customer Company Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_textbox'],
      questionTypeToAdd: 'control_textbox',
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'askBillingInformation', operator: '===', value: 'Yes' },
      autoSave: true
    },
    authnetEmulator: {
      title: 'Emulator',
      type: 'dropdown',
      options: [
        { id: 'none', key: 'none', text: 'None' },
        { id: 'epn', key: 'epn', text: 'EPN' },
        { id: 'goemerchant', key: 'goemerchant', text: 'GoEmerchant' },
        { id: 'merchantone', key: 'merchantone', text: 'MerchantOne' },
        { id: 'payjunction', key: 'payjunction', text: 'PayJunction' },
        { id: 'usaepay', key: 'usaepay', text: 'USAePay' }
      ],
      default: 'none',
      resources: ['FORM', 'APP']
    },
    ccTitle: {
      title: 'Credit Card Field Label',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    },
    addressTitle: {
      title: 'Billing Address Field Label',
      type: 'input',
      default: 'Billing Address',
      resources: ['FORM']
    }
  }
};

export default controlAuthnet;
