/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @jotforminc/no-native-button */
import {
  Modal, ModalHeader, ModalBody, ModalActions, Button, Flex, Badge
} from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { func, object } from 'prop-types';
import React, { useState } from 'react';
import {
  IconChevronLeft, IconChevronRight
} from '@jotforminc/svg-icons';

export const SOURCE_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
};

const WhatsNewModalDialogue = ({
  onClose, contents
}) => {
  const [index, setIndex] = useState(0);
  const {
    title = `${t("What's New")} 🔥`,
    description = 'Get the latest changes, updates and beta features',
    featureName,
    specs,
    src: { type = SOURCE_TYPE.IMAGE, url = '', alt = '' } = {},
    date,
    disableNewFeatureBadge = false
  } = contents[index];

  const Visual = () => {
    switch (type) {
      case SOURCE_TYPE.VIDEO: {
        return (
          <div className='p-4'>
            <video
              className='w-full'
              controls
              autoPlay
              controlsList='noplaybackrate nodownload'
              disablePictureInPicture
              disableRemotePlayback
              width="full"
              muted={true}
            >
              <source src={url} type="video/mp4" />
            </video>
          </div>
        );
      }

      default: {
        return <img className='w-full' alt={t(alt)} src={url} />;
      }
    }
  };

  return (
    <Modal
      open={true}
      ariaLabel="Modal Title"
      onClose={onClose}
      size='large'
    >
      <ModalHeader
        description={t(description)}
        iconColorStyle="primary"
        onClose={onClose}
        title={t(title)}
      />
      <ModalBody tabIndex={0}>
        <Flex direction='column' className='gap-4'>
          <Flex justifyContent='between' className='gap-2'>
            <Flex direction='column' className='gap-1'>
              <h3 className='text-lg font-bold color-navy-500'>{t(featureName)}</h3>
              {date && <p className='color-navy-300 text-xs'>{date}</p>}
            </Flex>
            {
              !disableNewFeatureBadge
            && (
            <Badge colorStyle="apps">
              {t('NEW FEATURE')}
            </Badge>
            )
            }
          </Flex>
          {
            url && <Visual />
            }
          {
            specs
            && (
              <ul className='list-disc flex flex-col gap-1 pl-5 color-navy-500 text-sm'>
                {
                  specs.map(feature => <li>{t(feature)}</li>)
                }
              </ul>
            )
          }
        </Flex>
        {
           index > 0 && (
           <Button
             variant="filled"
             onClick={() => { setIndex(prev => prev - 1); }}
             title={t('Back')}
             size="medium"
             colorStyle='apps'
             className='radius-full absolute top-2/4 xs:-left-5 left-1'
             startIcon={IconChevronLeft}
           />
           )
        }
        {
          index + 1 < contents.length && (
            <Button
              variant="filled"
              onClick={() => { setIndex(prev => prev + 1); }}
              title={t('Next')}
              size="medium"
              colorStyle='apps'
              className='radius-full absolute top-2/4 xs:-right-5 right-1'
              startIcon={IconChevronRight}
            />
          )
        }
      </ModalBody>
      <ModalActions>
        {
          contents.length > 1 && (
          <p className='mr-auto w-full xs:w-auto'>
            {`${index + 1}/${contents.length}`}
          </p>
          )
        }
        <Button
          className='ml-auto'
          onClick={onClose}
          colorStyle="success"
        >
          {t('Ok, Got it!')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

WhatsNewModalDialogue.propTypes = {
  onClose: func,
  contents: object.isRequired
};

WhatsNewModalDialogue.defaultProps = {
  onClose: f => f
};

export default WhatsNewModalDialogue;
