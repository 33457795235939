import React, {
  useState, useEffect, useMemo, forwardRef
} from 'react';
import {
  bool, string, func, oneOfType, number
} from 'prop-types';
import { useEffectIgnoreFirst } from '../../utils/hooks';

const TextInput = forwardRef(({
  id,
  label,
  value,
  defaultValue,
  onChange,
  onBlur,
  isErrored,
  errorMessage: baseErrorMessage,
  multiline,
  showInitialError,
  errorClassName,
  ...props
}, ref) => {
  // States
  const [isDirty, setDirty] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(baseErrorMessage);

  // Event handler
  const onInputValueChange = e => {
    setInputValue(e.target.value);
    onChange(e, inputValue, setInputValue);
  };

  const onInputBlur = e => {
    onBlur(e, inputValue, setInputValue);
    setDirty(isErrored(inputValue));
  };

  // Effect
  useEffect(() => {
    setInputValue(value || defaultValue);
    setDirty(false);
  }, [value, defaultValue]);

  useEffect(() => {
    setErrorMessage('');
  }, [isDirty]);

  useEffectIgnoreFirst(() => {
    setErrorMessage(baseErrorMessage);
  }, [baseErrorMessage]);

  useEffect(() => {
    if (isDirty || showInitialError) {
      setErrorMessage(isErrored(inputValue));
    }
  }, [isDirty, inputValue, showInitialError]);

  // Memoized values
  const eventHandlerProps = useMemo(() => Object.keys(props)
    .filter(propKey => /^on[A-Z]/.test(propKey) && typeof props[propKey] === 'function')
    .reduce((prev, propKey) => ({ ...prev, [propKey]: e => props[propKey](e, inputValue, setInputValue) }), {}),
  [props]);

  const InputElement = multiline ? 'textarea' : 'input';
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <InputElement
        id={id}
        ref={ref}
        value={inputValue}
        onChange={onInputValueChange}
        onBlur={onInputBlur}
        {...props}
        {...eventHandlerProps}
      />
      {errorMessage && <div className={errorClassName}>{errorMessage}</div>}
    </>
  );
});

TextInput.propTypes = {
  id: string,
  label: string,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  onChange: func,
  onBlur: func,
  isErrored: func,
  errorMessage: string,
  errorClassName: string,
  multiline: bool,
  showInitialError: bool
};

TextInput.defaultProps = {
  id: '',
  label: '',
  value: '',
  defaultValue: '',
  onChange: f => f,
  onBlur: f => f,
  isErrored: () => false,
  errorMessage: '',
  multiline: false,
  errorClassName: null,
  showInitialError: false
};

export default TextInput;
