import React from 'react';
import { string } from 'prop-types';

const TextWrapper = ({ className, ...props }) => <div className={['fileUpload-verticalAlign', className].join(' ')} {...props} />;

TextWrapper.propTypes = {
  className: string
};

TextWrapper.defaultProps = {
  className: ''
};

export default TextWrapper;
