/**
 * Functions that modify and act on HTML-related objects.
 */

/**
 * Convert a string with HTML entities in it to a
 * raw text version.
 * @param {any} html string that may contain html entities,
 * such as &gt;
 * @returns {string} String with HTML entities decoded, if html param
 * is not a string, returns ''.
 */
export function decodeEntities(html) {
  if (typeof html !== 'string') {
    return '';
  }
  return html.replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"');
}

/**
 * Updates document title with the new resource name
 *
 * @param {string} newResourceName
 * @returns void
 */
export const updateTitleWithNewResourceName = newResourceName => {
  try {
    /**
     * Our common document title scheme:
     * BSG: `{resourceName} - Jotform {productName}`
     * Ent: `{resourceName} - {productName}`
     */
    if (document.title.includes(' - ')) {
      document.title = document.title.replace(/^.*(\s-.*)$/, `${newResourceName}$1`);
    } else {
      // Means that we dont have a resource name at title yet - only the product name
      document.title = `${newResourceName} - ${document.title}`;
    }
  } catch (e) {
    // Could not update the window title
  }
};

/**
 * IMPORTANT :: This will not prevent XSS
 * Use this for trusted values
 * */
export const stripHTML = string => {
  if (
    string.indexOf
    && (
      string.indexOf('<') !== -1
      || string.indexOf('>') !== -1
    )
  ) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = string;
    return tmp.textContent || tmp.innerText || string;
  }
  return string;
};
