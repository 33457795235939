import React from 'react';
import { renderable } from '../../../../constants/propTypes';
import { getPublishNavigationProperties } from './constants';
import NavigationPanel from '../NavigationPanel';
import { ScPublish } from './ScPublish';
import PublishStage from './PublishStage';

const Publish = ({ children }) => {
  return (
    <ScPublish>
      <NavigationPanel
        navigationProperties={getPublishNavigationProperties()}
      />
      <PublishStage>
        {children}
      </PublishStage>
    </ScPublish>
  );
};

Publish.propTypes = {
  children: renderable.isRequired
};

export default Publish;
