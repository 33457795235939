import React, { useMemo } from 'react';
import {
  arrayOf, bool, elementType, oneOf, shape, string
} from 'prop-types';
import { t } from '@jotforminc/translation';

import { ScPortalItem } from './scPortalItem';
import DefaultTitleRenderer from './PortalItemDefaults/TitleRenderer';
import DefaultDescriptionRenderer from './PortalItemDefaults/DescriptionRenderer';
import DefaultItemContentRenderer from './PortalItemDefaults/ItemContentRenderer';
import DefaultIconRenderer from './PortalItemDefaults/IconRenderer';
import { ICON_SIZES } from './PortalItemDefaults/constants';
import { useMomentDate } from '../../../utils/hooks';

const PortalItem = ({
  id,
  title,
  icon,
  items,
  updated_at: modifyDate,
  created_at: createdAt,
  isDisabled,
  TitleRenderer,
  DescriptionRenderer,
  ItemContentRenderer,
  IconRenderer,
  iconSize,
  checkoutFormID,
  moveStatus
}) => {
  const isStoreApp = (Number(checkoutFormID) > 0);
  const [date] = useMomentDate(modifyDate || createdAt, 'YYYY-MM-DD HH:mm');
  const translatedItemDescriptionText = useMemo(() => t('{formsLength} Forms. Modified on {modifyDate}')
    .replace('{formsLength}', items.filter(i => i.type === 'FORM').length)
    .replace('{modifyDate}', date?.format('MMMM D, YYYY')), [items, date]);
  return (
    <ScPortalItem>
      {isDisabled && (
        <div style={{ // We may need to show some content as if not disabled so opacity wont solve this problem.
          position: 'absolute',
          width: '100%',
          height: '90%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        }}
        />
      )}

      <IconRenderer
        titleForAlt={title}
        icon={icon}
        iconSize={iconSize}
      />

      <ItemContentRenderer>
        <TitleRenderer
          title={title || 'New App'}
          id={id}
          isStoreApp={isStoreApp}
          moveStatus={moveStatus}
        />
        <DescriptionRenderer>{translatedItemDescriptionText}</DescriptionRenderer>
      </ItemContentRenderer>
    </ScPortalItem>
  );
};

PortalItem.propTypes = {
  id: string,
  title: string,
  icon: shape({}),
  items: arrayOf(shape({})),
  updated_at: string,
  created_at: string,
  isDisabled: bool,
  TitleRenderer: elementType,
  DescriptionRenderer: elementType,
  ItemContentRenderer: elementType,
  IconRenderer: elementType,
  iconSize: oneOf(Object.values(ICON_SIZES)),
  checkoutFormID: string,
  moveStatus: shape({
    status: string
  })
};

PortalItem.defaultProps = {
  id: '',
  title: '',
  icon: {},
  items: [],
  updated_at: '',
  created_at: '',
  isDisabled: false,
  TitleRenderer: DefaultTitleRenderer,
  DescriptionRenderer: DefaultDescriptionRenderer,
  ItemContentRenderer: DefaultItemContentRenderer,
  IconRenderer: DefaultIconRenderer,
  iconSize: ICON_SIZES.MEDIUM,
  checkoutFormID: '',
  moveStatus: {}
};

export default PortalItem;
