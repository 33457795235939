import { RequestLayer, ResponseResult } from '@jotforminc/request-layer';
import { PostSubscriptionRequest, ProjectConfig, PushManagerConfig } from '../types';
import { WebPushNotificationEvent } from '../service-worker/types';

const requestLayer = new RequestLayer('/API');

export default class Api {
  private readonly resourceId: string;

  private readonly resourceType: string;

  constructor(config: PushManagerConfig) {
    this.resourceType = config.resourceType;
    this.resourceId = config.resourceId;
  }

  private basePath() {
    return `web-push/${this.resourceType}/${this.resourceId}`;
  }

  getConfig(): Promise<ResponseResult<ProjectConfig>> {
    return requestLayer.get(`${this.basePath()}/config`);
  }

  postSubscription(subscription: PostSubscriptionRequest): Promise<ResponseResult<string>> {
    return requestLayer.post(`${this.basePath()}/subscribe`, subscription);
  }

  checkSubscription(subscriptionID: string): Promise<ResponseResult<boolean>> {
    return requestLayer.post(`${this.basePath()}/check-subscription`, { subscriptionID });
  }

  updateSubscription(subscription: PushSubscription, subscriptionID: string): Promise<ResponseResult<boolean>> {
    return requestLayer.post(`${this.basePath()}/update-subscription`, { subscription, subscriptionID });
  }

  saveEvent(campaignID: string, notificationID: string, event: WebPushNotificationEvent): Promise<ResponseResult<boolean>> {
    return requestLayer.post(`${this.basePath()}/save-event`, { campaignID, notificationID, event });
  }
}
