import React from 'react';
import { IconInfoCircleFilled, IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import WarningBox from '@jotforminc/warning-box';

const PwInfoLine = ({
  text,
  type,
  wrapperClassName
}: IProps): JSX.Element => {
  return (
    <WarningBox
      colorStyle={type === 'warning' ? 'warning' : 'info'}
      Icon={type === 'warning' ? IconExclamationCircleFilled : IconInfoCircleFilled}
      isLongText
      wrapperClassName={wrapperClassName}
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </WarningBox>
  );
};

export interface IProps {
  text: string;
  type: string;
  wrapperClassName: string
}

export default PwInfoLine;
