export const Texts = {
  SIGN_UP_NOW: 'Sign Up Now!',
  CREATE_MY_ACCOUNT: 'CREATE MY ACCOUNT',
  HELLO_TEXT: 'Hello,',
  LOG_OUT: 'Log out',
  MY_FORMS: 'My Forms',
  PROFILE: 'Profile'
};

export const appList = [
  'viewForm',
  'formBuilder',
  'pdfEditor',
  'tables',
  'inbox',
  'formAnalytics',
  'reports',
  'sign',
  'approvals',
  'portal',
  'boards',
  'conversations',
  'aiAgentBuilder',
  'signInbox'
];
