import { supportedCurrenciesWithLabel } from '@jotforminc/payment-constants';
import { C_GATEWAYS } from '../components/modals/Flows/Constants';
import PaymentProperties from '../constants/PaymentProperties';
import { GATEWAYS_WITH_APM } from '../constants/variables';
import {
  IGatewayProperties, IPaymentConnectionItem, IPaymentProperties, TApm, TCurrencyCode, TGateway
} from '../types/common';

export function getGatewayProperties(gateway: TGateway | TApm) : IGatewayProperties {
  if (!PaymentProperties[gateway]) { return; } // TODO: check the usage of this function and remove this line
  return PaymentProperties[gateway];
}

type FTFindGatewaysByProperty = (
  propery: (string | number | symbol),
  expected?: [TGateway | TApm] | string | boolean | number,
  comparator?: (params: Partial<IGatewayProperties>) => boolean
) => IPaymentProperties[] | [];

export const findGatewaysByProperty : FTFindGatewaysByProperty = (property, expected, comparator) => {
  const result: IPaymentProperties[] = [];

  Object.entries(PaymentProperties).forEach(([gateway, properties]) => {
    let answer = false;

    if (typeof comparator === 'function') {
      answer = comparator(properties);
    } else if (property === 'gateway') {
      answer = gateway === expected;
    } else {
      answer = properties[property as keyof IGatewayProperties] === expected;
    }

    if (answer === true) {
      result.push({ [gateway]: properties });
    }
  });

  return result;
};

export function getConnectionEnvironment(connection: IPaymentConnectionItem) : ('PRODUCTION' | 'SANDBOX') {
  const { environment } = PaymentProperties[connection.gateway];
  if (!environment) { return 'PRODUCTION'; }

  return environment.liveModeValue.includes(connection.props[environment.name]) ? 'PRODUCTION' : 'SANDBOX';
}

export const getFilteredCurrencies = (gateway: TGateway, nameAPM: TApm): Record<string, string> => {
  // TODO :: REFACTOR
  if (nameAPM && GATEWAYS_WITH_APM[nameAPM]) {
    // get apm currencies
    const { supportedCurrencies } = GATEWAYS_WITH_APM[nameAPM].filter(obj => obj.type === gateway)[0];
    return Object.fromEntries(Object.entries(supportedCurrenciesWithLabel).filter(([key]) => supportedCurrencies.indexOf(key as TCurrencyCode) > -1));
  }

  const foundGateway = C_GATEWAYS.find(item => item.type === gateway);
  const supportedCurrencies = foundGateway ? foundGateway.supportedCurrencies : PaymentProperties[gateway].currencies;

  return Object.fromEntries(Object.entries(supportedCurrenciesWithLabel).filter(([key]) => supportedCurrencies.indexOf(key as TCurrencyCode) > -1));
};
