import Styled from 'styled-components';

export const ScIconWrapper = Styled.div`
  position: relative;
  margin-left: 4px;
  margin-right: 10px;
  display: flex;
  align-items: center;

  html[dir="rtl"] & {
    margin-right: 0;
    margin-left: .2em;
  }

  svg {
    width: 36px;
    height: 36px;
  }
`;
