import Styled from 'styled-components';

const ScNavigationPanel = Styled.div`
  display: block;
  position: absolute;
  left: 0;
  width: 318px;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 144px;
  }

  @media screen and (max-width: 480px) {
    display: block;
    width: 100%;
    ${({ isMobileSubPage }) => (isMobileSubPage ? 'display: none;' : '')}
  }
`;
export default ScNavigationPanel;
