import { isYes } from '../../../../../utils';

export const TABLE_PRIVACY_TYPES = {
  PUBLIC: 'PUBLIC',
  PRIVATE_WITHOUT_USER_SUBMISSION: 'PRIVATE_WITHOUT_USER_SUBMISSION',
  PRIVATE_WITH_USER_SUBMISSION: 'PRIVATE_WITH_USER_SUBMISSION'
};

export const tablePrivacyWarnings = {
  [TABLE_PRIVACY_TYPES.PUBLIC]: {
    message: 'The table is public. All of the data in this table will be visible to all users.',
    classNames: 'color-navy-300'
  },
  [TABLE_PRIVACY_TYPES.PRIVATE_WITHOUT_USER_SUBMISSION]: {
    message: 'The table is private. The columns that you select on app will be visible to all users who view the app.'
  },
  [TABLE_PRIVACY_TYPES.PRIVATE_WITH_USER_SUBMISSION]: {
    message: 'The table is private and it contains user submissions. Therefore, currently you cannot use this table in your app.',
    classNames: 'color-red-400'
  }
};

export const decidePrivacyType = resource => {
  if (!resource) {
    return null;
  }

  const { last_submission: lastSubmission, share: { permissions: { assigneeProtected } = {} } = {} } = resource;
  const hasUserSubmission = !!lastSubmission;
  const isPrivate = isYes(assigneeProtected) || !resource?.share?.permissions;

  if (isPrivate && hasUserSubmission) {
    return TABLE_PRIVACY_TYPES.PRIVATE_WITH_USER_SUBMISSION;
  }

  if (isPrivate) {
    return TABLE_PRIVACY_TYPES.PRIVATE_WITHOUT_USER_SUBMISSION;
  }

  return TABLE_PRIVACY_TYPES.PUBLIC;
};
