import {
  call, put, select, take
} from 'redux-saga/effects';
import { StorageHelper } from '@jotforminc/storage-helper';
import {
  ADD_NEW_PAGE,
  ADD_PORTAL_ITEMS,
  DELETE_PAGE,
  REMOVE_PORTAL_ITEMS,
  TOGGLE_LEFT_PANEL,
  TOGGLE_RIGHT_PANEL,
  UPDATE_ITEM_PROP,
  UPDATE_MULTIPLE_ITEM,
  UPDATE_ORDER, UPDATE_PAGE,
  UPDATE_PORTAL
} from '../actionTypes';
import SELECTORS from '../selectors';
import { isGuestUser } from '../utils';
import { APP_MODES } from '../../constants';
import { showGenericModalAction } from '../actionCreators';
import { MODALS } from '../../constants/modals';

export const SIGNUP_ACTION_COUNTER_KEY = 'appBuilderSignupActionCount';
const SIGNUP_ACTION_LIMIT = 20;
let isModalFirstOpened = false;
let currentActionCount = 0;

export function* watchSignupActions() {
  const actionsToTrack = [
    UPDATE_PORTAL.SUCCESS,

    ADD_PORTAL_ITEMS.SUCCESS,
    UPDATE_ITEM_PROP.SUCCESS,
    UPDATE_MULTIPLE_ITEM.SUCCESS,
    UPDATE_ORDER.SUCCESS,
    REMOVE_PORTAL_ITEMS.SUCCESS,

    ADD_NEW_PAGE.SUCCESS,
    UPDATE_PAGE.SUCCESS,
    DELETE_PAGE.SUCCESS,

    TOGGLE_LEFT_PANEL,
    TOGGLE_RIGHT_PANEL
  ];

  const appMode = yield select(SELECTORS.getAppModeSelector);
  const isBuilder = appMode === APP_MODES.builder;

  const user = yield select(SELECTORS.getUser);
  const isGuest = isGuestUser(user);

  while (isBuilder && isGuest) {
    yield take(props => {
      const { type } = props;
      return actionsToTrack.includes(type);
    });

    if (!isModalFirstOpened) {
      currentActionCount = yield call(StorageHelper.incrementCounter, { key: SIGNUP_ACTION_COUNTER_KEY });
    }

    if (currentActionCount === SIGNUP_ACTION_LIMIT && !isModalFirstOpened) {
      // Show signup modal
      yield put(showGenericModalAction({ name: MODALS.GUEST_LOGIN_MODAL }));
      isModalFirstOpened = true;
      StorageHelper.removeLocalStorageItem({ key: SIGNUP_ACTION_COUNTER_KEY });
    }
  }
}
