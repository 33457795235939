import React from 'react';
import { FormWizard } from '@jotforminc/form-wizard';
import { t } from '@jotforminc/translation';
import COMPONENT_TYPE_LIST from '../constants/componentTypes';
import * as COMPONENT_NAMES from '../constants/componentNames';
import AddPaymentIntegrationContainer from '../components/fields/AddPaymentIntegrationContainer';
import PaymentOverQuotaWarning from '../components/PaymentOverQuotaWarning';
import { IGatewaySettings } from '../types/common';
import DonationItemAvailabilityWarning from '../components/DonationItemAvailabilityWarning';
import '../styles/editor.scss';
import { I_RESOURCE_TYPES } from '../components/modals/Flows/Types';

const elementList = (elementProps: TElementListProps, activeGatewaySettings: IGatewaySettings) => {
  const {
    currency,
    useDecimal,
    decimalMark
  } = elementProps;
  return {
    ...elementProps.useFastCheckout ? {
      useFastCheckout: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Express Checkout'),
          description: t('Users can order items quickly while not allowing to favorite items and revisit the shopping cart.'),
          class: `pointer-events-none flex justify-between items-center mb-0 p-4 font-medium color-white p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          visibility: true,
          isSelected: elementProps.useFastCheckout === 'Yes'
        }
      }
    } : {},
    currency: {
      type: COMPONENT_NAMES.CURRENCY_DROPDOWN,
      labelProps: {
        label: t('Currency'),
        class: `block mb-0 py-5 px-4 font-medium color-white p-${COMPONENT_NAMES.DROPDOWN}`
      },
      componentProps: {
        activeCurrencyCode: currency,
        activeGatewaySettings: activeGatewaySettings
      }
    },
    useDecimal: {
      type: COMPONENT_NAMES.TOGGLE,
      labelProps: {
        label: t('Show Decimals on the Form'),
        class: `pointer-events-none flex justify-between items-center mb-0 p-4 font-medium color-white p-${COMPONENT_NAMES.TOGGLE}`
      },
      componentProps: {
        isSelected: useDecimal === 'Yes'
      }
    },
    decimalMark: {
      type: COMPONENT_NAMES.SELECTBOX,
      labelProps: {
        label: t('Decimal Separator'),
        class: `block mb-0 py-5 px-4 font-medium color-white p-${COMPONENT_NAMES.SELECTBOX}`
      },
      componentProps: {
        options: [
          { text: t('Point'), value: 'point' },
          { text: t('Comma'), value: 'comma' }
        ],
        defaultValue: decimalMark
      },
      condition: () => useDecimal === 'Yes'
    }
  };
};

const PaymentSettings = ({
  resourceType = 'FORM',
  elementProps,
  isPaymentOverQuota,
  onChange,
  activeGatewaySettings,
  isDonationItemAvailable,
  sendActionTrackEvent,
  onAddPaymentIntegrationBtnClick,
  onEditPaymentIntegrationBtnClick,
  onDeletePaymentIntegrationBtnClick,
  onOpenGatewaySettings,
  onRemoveGateway
} : TPaymentSettingsProps): JSX.Element => {
  const elList = elementList(elementProps, activeGatewaySettings);
  const isPurchaseOrder = resourceType === 'FORM' && activeGatewaySettings.type === 'control_payment' && !activeGatewaySettings.isPaymentStoreInBasicFields;
  return (
    <>
      {isPaymentOverQuota && <PaymentOverQuotaWarning />}
      {isDonationItemAvailable === false && <DonationItemAvailabilityWarning openPaymentGatewayModalClick={onAddPaymentIntegrationBtnClick} sendActionTrackEvent={sendActionTrackEvent} />}
      <hr className="lineHr block border-transparent w-full m-0" />
      {
        !isPurchaseOrder && (
          <div className="pt-7 pb-6 px-4">
            <div className="block text-uppercase color-white font-bold text-md">{t('Payment Integration')}</div>
            <div className="text-left opacity-60 font-normal mt-2 color-white text-sm">{t('Add a payment integration to accept payments.')}</div>
            <AddPaymentIntegrationContainer
              onAddPaymentIntegrationBtnClick={onAddPaymentIntegrationBtnClick}
              onEditPaymentIntegrationBtnClick={onEditPaymentIntegrationBtnClick}
              onDeletePaymentIntegrationBtnClick={onDeletePaymentIntegrationBtnClick}
              activeGatewaySettings={activeGatewaySettings}
              onOpenGatewaySettings={onOpenGatewaySettings}
              onRemoveGateway={onRemoveGateway}
            />
          </div>
        )
      }
      {Object.entries(elList).map(([key, value]) => (
        <>
          <hr className="lineHr block border-transparent w-full m-0" />
          <div className="lineElement">
            <div className='listSettings'>
              <FormWizard
                onChange={onChange}
                elementList={{ [key]: value }}
                componentTypeList={COMPONENT_TYPE_LIST}
              />
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default PaymentSettings;

type TPaymentSettingsProps = {
  resourceType: I_RESOURCE_TYPES,
  elementProps: TElementListProps,
  isPaymentOverQuota: boolean,
  onChange: (_: any, data: any) => void,
  activeGatewaySettings: IGatewaySettings,
  isDonationItemAvailable: boolean,
  sendActionTrackEvent: () => void,
  onRemoveGateway: () => void,
  onAddPaymentIntegrationBtnClick: () => void,
  onEditPaymentIntegrationBtnClick: () => void,
  onDeletePaymentIntegrationBtnClick: () => void,
  onOpenGatewaySettings: () => void
}

type TElementListProps = {
  currency: string,
  useDecimal: string,
  decimalMark: string,
  useFastCheckout?: string
}
