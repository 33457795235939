import React, {
  useState, useRef, forwardRef, useImperativeHandle, useEffect
} from 'react';
import {
  shape, arrayOf, elementType, string, number, oneOfType, func, bool
} from 'prop-types';
import isEqual from 'lodash/isEqual';
import { nodeContains } from '@jotforminc/utils';

import Button from '../Button';
import Popover from '../Popover';
import OptionGroup from '../OptionGroup';

import {
  generateShortID, isPressedKeyEnter, findOptionByValue, flattenOptions
} from '../../utils';
import {
  useClickOutsideState, useEffectIgnoreFirst, useCombinedRefs, useFuse
} from '../../utils/hooks';
import TextInput from '../TextInput';

const Dropdown = forwardRef(({
  options,
  defaultValue,
  GroupRenderer,
  OptionRenderer,
  OptionContainerRenderer,
  ButtonRenderer,
  ContainerRenderer,
  TextInputRenderer,
  NoResultsRenderer,
  onOptionSelect,
  closeOnOptionSelect,
  closeOnScroll,
  scrollableElementSelector,
  popoverProps,
  disabled,
  filterable,
  extendable,
  onTextInputChange,
  onOptionAdded,
  allowEmpty,
  placeholder,
  textInputPlaceholder,
  triggerChangeOnDatasetChange,
  extendOnGroupSearch,
  onOptionsVisibilityChanged,
  skipSetSelectedOption,
  optionsAutoFocus,
  optionsAutoPosition,
  dropdownButtonAriaLabelPrefix,
  recordEvent,
  translate,
  textInputAriaLabel,
  clearSearchButtonText,
  textInputFocusOptions
}, ref) => {
  const textInputRef = useRef();
  const buttonRef = useRef();
  const containerRef = useRef();
  const dropdownRef = useRef();
  const optionsRef = useRef();
  const containerID = useRef(generateShortID()).current;
  const rootRef = useCombinedRefs(dropdownRef, ref);

  const [selectedOption, setSelectedOption] = useState(false);
  const [isOptionsVisible, _setOptionsVisibility] = useClickOutsideState(false, [containerRef, buttonRef]);

  const [textInputValue, _setTextInputValue] = useState('');
  const [placement, setPlacement] = useState();

  const finalOptions = extendOnGroupSearch && filterable && textInputValue ? flattenOptions(options) : options;
  const filteredOptions = useFuse(finalOptions, filterable ? textInputValue : undefined)
    .map(each => (each.item ? each.item : each));

  const handleInputChange = e => {
    const { value } = (typeof e === 'string') ? { value: e } : e.target;
    _setTextInputValue(value);
    onTextInputChange(value);
  };

  const clearInput = () => handleInputChange('');
  const handleChangeOptionsVisibility = visible => {
    clearInput();
    _setOptionsVisibility(visible);
  };

  const checkOptionSelectionState = option => {
    if (selectedOption && option && selectedOption.value && option.value) {
      return selectedOption.value === option.value;
    }
    return false;
  };
  const onButtonClick = () => {
    if (!isOptionsVisible) recordEvent();
    handleChangeOptionsVisibility(!isOptionsVisible);
  };
  const onButtonKeyPress = event => isPressedKeyEnter(event) && handleChangeOptionsVisibility(!isOptionsVisible);
  const setSelected = option => {
    if (option && option.disabled) return;

    clearInput();
    if (!skipSetSelectedOption) {
      setSelectedOption(option);
    }
    if (option) {
      const { disabled: isDisabled, ...rest } = option;
      onOptionSelect(rest);
    } else { // Selection must be cleared
      onOptionSelect();
    }

    if (closeOnOptionSelect) {
      handleChangeOptionsVisibility(false);
    }
  };

  const findByText = text => options.find(opt => opt.text.toLowerCase() === text.toLowerCase());
  const handleAddNewOption = newOptionText => {
    const alreadyHave = findByText(newOptionText);
    if (alreadyHave) {
      setSelected(alreadyHave);

      return;
    }
    clearInput();
    onOptionAdded(newOptionText);
  };

  const handleKeyDown = e => {
    if (extendable && textInputValue && isPressedKeyEnter(e)) {
      handleAddNewOption(textInputValue);
    }
  };

  const clearSelection = () => {
    setSelected();
  };

  useEffectIgnoreFirst(() => {
    const foundByValue = findOptionByValue(defaultValue, options, allowEmpty);
    // Check every possible difference on the option.
    // It may have other attributes besides text which affects selected option visibility especially when used with custom option renderer
    if (!isEqual(foundByValue, selectedOption)) {
      if (foundByValue) {
        setSelectedOption(foundByValue);
        if (triggerChangeOnDatasetChange) {
          onOptionSelect(foundByValue);
        }
      } else {
        setSelectedOption(false);
      }
    }
  }, [defaultValue, options]);

  useEffect(() => {
    const foundByValue = findOptionByValue(defaultValue, options, allowEmpty);
    if (foundByValue) {
      setSelectedOption(foundByValue);
    }
  }, []);

  useEffect(() => {
    onOptionsVisibilityChanged(isOptionsVisible);
    if (isOptionsVisible) {
      setTimeout(() => { // Popper renders content as display:none at first, and focus call is not working on non displayed elements
        if (textInputRef?.current) textInputRef.current.focus(textInputFocusOptions);

        if (optionsAutoFocus && optionsRef?.current?.firstChild) optionsRef.current.firstChild.focus();

        if (optionsAutoPosition && buttonRef?.current && optionsRef?.current) {
          const buttonTop = buttonRef.current.getBoundingClientRect().top;
          const optsHeight = optionsRef.current.offsetHeight + (textInputRef?.current?.offsetHeight || 0);
          setPlacement(((window.innerHeight - buttonTop) < (optsHeight + 40)) && ((buttonTop - optsHeight) > 40) ? 'top-end' : popoverProps?.popoverOptions?.placement);
        }
      });
    }
  }, [isOptionsVisible]);

  useEffect(() => {
    if (closeOnScroll && isOptionsVisible) {
      const handleScroll = e => {
        // fix for closing modal while scrolling this dropdown options issue
        if (!optionsRef?.current || !nodeContains(optionsRef.current, e.target)) {
          handleChangeOptionsVisibility(false);
        }
      };

      const scrollableElement = scrollableElementSelector ? document.querySelector(scrollableElementSelector) || document : document;

      scrollableElement.addEventListener('scroll', handleScroll, true);
      return () => {
        scrollableElement.removeEventListener('scroll', handleScroll, true);
      };
    }
  }, [scrollableElementSelector, closeOnScroll, isOptionsVisible]);

  useImperativeHandle(rootRef, () => ({
    buttonRef,
    containerRef,
    setSelected,
    setOptionsVisibility: handleChangeOptionsVisibility,
    clearSelection,
    isOptionsVisible
  }));

  return (
    <>
      <ButtonRenderer
        ref={buttonRef}
        isOptionsVisible={isOptionsVisible}
        option={selectedOption}
        onClick={onButtonClick}
        onKeyPress={onButtonKeyPress}
        disabled={disabled}
        placeholder={placeholder}
        buttonAriaLabelPrefix={dropdownButtonAriaLabelPrefix}
        tabIndex={0}
        aria-label={placeholder}
        role="combobox"
        aria-expanded={isOptionsVisible}
        aria-controls={`dropdown-options-${containerID}`}
      />
      {isOptionsVisible && (
        <Popover
          ref={containerRef}
          targetRef={buttonRef}
          {...popoverProps}
          {...placement ? {
            popoverOptions: {
              ...popoverProps?.popoverOptions || {},
              placement
            }
          } : {}}
          tabIndex="0"
        >
          {(filterable || extendable) ? (
            <>
              <TextInputRenderer
                ref={textInputRef}
                type={extendable ? 'text' : 'search'}
                value={textInputValue}
                placeholder={textInputPlaceholder || translate(extendable ? 'Type to add' : 'Type to filter')}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                aria-label={textInputAriaLabel || textInputPlaceholder}
                NoResultsRenderer={NoResultsRenderer}
                textInputFocusOptions={textInputFocusOptions}
              />
              {clearSearchButtonText && textInputValue !== '' && <button type="button" className="dropdown-clearSearchBtn" onClick={clearInput}>{clearSearchButtonText}</button>}
            </>
          ) : null}
          <OptionGroup
            ref={optionsRef}
            containerID={containerID}
            options={filteredOptions}
            setSelected={setSelected}
            GroupRenderer={GroupRenderer}
            OptionRenderer={OptionRenderer}
            ContainerRenderer={ContainerRenderer}
            OptionContainerRenderer={OptionContainerRenderer}
            NoResultsRenderer={NoResultsRenderer}
            checkOptionSelectionState={checkOptionSelectionState}
            inputValue={textInputValue}
            onClearFilter={clearInput}
          />
        </Popover>
      )}
    </>
  );
});

Dropdown.propTypes = {
  options: arrayOf(shape({
    text: string,
    value: oneOfType([string, number])
  })),
  disabled: bool,
  popoverProps: shape({}),
  GroupRenderer: elementType,
  ButtonRenderer: elementType,
  OptionRenderer: elementType,
  OptionContainerRenderer: elementType,
  ContainerRenderer: elementType,
  TextInputRenderer: elementType,
  NoResultsRenderer: elementType,
  closeOnOptionSelect: bool,
  closeOnScroll: bool,
  scrollableElementSelector: string,
  defaultValue: oneOfType([string, number]),
  onOptionSelect: func,
  filterable: bool,
  extendable: bool,
  onTextInputChange: func,
  onOptionAdded: func,
  allowEmpty: bool,
  placeholder: string,
  textInputPlaceholder: string,
  triggerChangeOnDatasetChange: bool,
  extendOnGroupSearch: bool,
  onOptionsVisibilityChanged: func,
  skipSetSelectedOption: bool,
  optionsAutoFocus: bool,
  optionsAutoPosition: bool,
  dropdownButtonAriaLabelPrefix: string,
  recordEvent: func,
  translate: func,
  textInputAriaLabel: string,
  clearSearchButtonText: string,
  textInputFocusOptions: shape({})
};

/* eslint react/prop-types: "off" */
Dropdown.defaultProps = {
  options: [],
  disabled: false,
  defaultValue: undefined,
  closeOnOptionSelect: true,
  closeOnScroll: false,
  scrollableElementSelector: '',
  popoverProps: {},
  GroupRenderer: ({ text, children, ...props }) => (
    <div {...props}>
      <b>{text}</b>
      <div>{children}</div>
    </div>
  ),
  ContainerRenderer: forwardRef(({ children, id }, ref) => (
    <ul
      ref={ref}
      aria-label="options"
      role="listbox"
      id={`dropdown-options-${id}`}
    >
      {children}
    </ul>
  )),
  ButtonRenderer: forwardRef(({
    option: { text, value } = {}, placeholder, buttonAriaLabelPrefix, ...props
  }, ref) => (
    <Button
      ref={ref}
      type="button"
      data-value={value}
      data-testid="dropdown-button"
      buttonAriaLabelPrefix={buttonAriaLabelPrefix}
      {...props}
    >
      {text || placeholder}
    </Button>
  )),
  OptionRenderer: ({ option: { text, value }, isSelected }) => <div data-value={value} data-selected={isSelected}>{text}</div>,
  OptionContainerRenderer: props => <li {...props} />,
  TextInputRenderer: forwardRef(({ ...props }, ref) => <TextInput ref={ref} {...props} />),
  NoResultsRenderer: null,
  onOptionSelect: f => f,
  filterable: false,
  extendable: false,
  onTextInputChange: f => f,
  onOptionAdded: f => f,
  allowEmpty: false,
  placeholder: 'Please select',
  textInputPlaceholder: '',
  triggerChangeOnDatasetChange: false,
  extendOnGroupSearch: false,
  onOptionsVisibilityChanged: f => f,
  skipSetSelectedOption: false,
  optionsAutoFocus: false,
  optionsAutoPosition: false,
  dropdownButtonAriaLabelPrefix: '',
  recordEvent: f => f,
  translate: f => f,
  textInputAriaLabel: '',
  clearSearchButtonText: null,
  textInputFocusOptions: {}
};

export default Dropdown;
