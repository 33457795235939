import { t } from '@jotforminc/translation';
import { addressHintObjectToString } from '@jotforminc/utils';

// TODO: use from constants when question properties are moved to constants
export const getDefaultQuestionValues = () => ({
  control_textbox: {
    text: t('Type a question'),
    labelAlign: 'Auto',
    required: 'No',
    size: 20,
    validation: 'None',
    maxsize: '',
    inputTextMask: '',
    defaultValue: '',
    subLabel: '',
    hint: '',
    description: '',
    readonly: 'No'
  },
  control_phone: {
    text: t('Phone Number'),
    labelAlign: 'Auto',
    required: 'No',
    countryCode: 'No',
    inputMask: 'enable',
    inputMaskValue: '(###) ###-####',
    validation: 'None',
    description: '',
    sublabels: {
      country: t('Country Code'),
      area: t('Area Code'),
      phone: t('Phone Number'),
      full: t('Phone Number'),
      masked: ''
    },
    readonly: 'No',
    compoundHint: ''
  },
  control_address: {
    text: t('Address'),
    labelAlign: 'Auto',
    required: 'No',
    requiredInputs: 'st1|state|city|zip|country',
    selectedCountry: '',
    states: 'textbox',
    description: '',
    subfields: 'state|zip|st1|city|st2',
    sublabels: {
      addr_line1: t('Street Address'),
      addr_line2: t('Street Address Line 2'),
      city: t('City'),
      state: t('State / Province'),
      postal: t('Postal / Zip Code'),
      country: t('Country')
    },
    compoundHint: addressHintObjectToString({
      state_select: t('Please Select'),
      country_select: t('Please Select')
    }),
    countryDropdownDefaultText: t('Please Select'),
    customCountryDropdown: 'No',
    customCountryOptions: '',
    hasAutocomplete: 'No',
    hasGeolocation: 'No',
    customStateOptions: ''
  },
  control_textarea: {
    text: t('Type a question'),
    labelAlign: 'Auto',
    required: 'No',
    cols: 40,
    rows: 6,
    validation: 'None',
    entryLimitMin: 'None-0',
    entryLimit: 'None-0',
    maxsize: '',
    defaultValue: '',
    subLabel: '',
    hint: '',
    description: '',
    readonly: 'No',
    wysiwyg: 'Disable',
    mde: 'No',
    enableSentiment: 'No'
  },
  control_checkbox: {
    text: t('Type a question'),
    labelAlign: 'Auto',
    required: 'No',
    options: `${t('Type option 1')}|${t('Type option 2')}|${t('Type option 3')}|${t('Type option 4')}`,
    special: 'None',
    calcValues: '',
    allowOther: 'No',
    otherText: t('Other'),
    spreadCols: '1',
    selected: '',
    minSelection: '',
    maxSelection: '',
    description: '',
    shuffle: 'No',
    readonly: 'No'
  },
  control_radio: {
    text: t('Type a question'),
    labelAlign: 'Auto',
    required: 'No',
    options: `${t('Type option 1')}|${t('Type option 2')}|${t('Type option 3')}|${t('Type option 4')}`,
    special: 'None',
    calcValues: '',
    allowOther: 'No',
    otherText: t('Other'),
    selected: '',
    spreadCols: '1',
    description: '',
    shuffle: 'No',
    readonly: 'No'
  }
});
