import { bool, func, string } from 'prop-types';
import React, { useCallback } from 'react';
import Styled from 'styled-components';
import { t } from '@jotforminc/translation';

import appConfig from '../../../../../constants/appConfig';
import { COLORED_FLAGS, OVERRIDED_ICON_LIST } from '../constants';
import { isYes } from '../../../../../utils';
import { ScRightPanelBlock } from '../../../styles/scRightPanel';
import { ColorPicker } from '../../ColorPicker';
import RadioToggle from '../../RadioToggle';
import { ITEM_TYPES } from '../../../../../constants/itemTypes';
import { FEATURE_NAMES } from '../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../utils/features/helper';
import IconSelector from '../../../../../components/IconSelector';

const ScItemIconToggle = Styled.div`
  ${ScRightPanelBlock} {
    padding-left: 0;

    & + ${ScRightPanelBlock} {
      padding-right: 0;
    }
  }

  .icon-selector-wizard {
    padding: 0;
  }

  ${({ showRemoveButton }) => (showRemoveButton ? '' : `
    .icon-selector-wizard .remove-icon-button {
      display: none;
    }
  `)}

  div[data-tabid="Colored"] .iconSelector-icon {
    fill: unset !important;
  }
`;

const ItemIconToggle = ({
  value, itemIcon, onChange, itemIconBgColor, itemIconColor, type, showRemoveButton, defaultValue, ...props
}) => {
  const handleOnChange = useCallback(prop => e => {
    onChange({ [prop]: e });
  }, [onChange]);

  const handleIconChange = useCallback(iconValue => {
    const { url } = iconValue;
    handleOnChange('itemIcon')(url);
  }, [onChange]);

  return (
    <ScItemIconToggle className="item-icon-toggle" showRemoveButton={showRemoveButton}>
      <RadioToggle
        value={value}
        onChange={handleOnChange('showItemIcon')}
        {...props}
      />
      {isYes(value) && (
        <>
          <ScRightPanelBlock column>
            <label htmlFor="itemIconColor">{t('Icon Color')}</label>
            <ColorPicker id="itemIconColor" defaultValue={itemIconColor} onChange={handleOnChange('itemIconColor')} />
          </ScRightPanelBlock>
          <ScRightPanelBlock column>
            <label htmlFor="itemIconBgColor">{t('Background Color')}</label>
            <ColorPicker
              id="itemIconBgColor"
              defaultValue={itemIconBgColor}
              onChange={handleOnChange('itemIconBgColor')}
              placement='bottom-end'
              offsetX={39}
            />
          </ScRightPanelBlock>
          <IconSelector
            value={itemIcon}
            allIconsContainerID={appConfig.svgIconsContainer}
            onChange={handleIconChange}
            overriddenIcons={OVERRIDED_ICON_LIST[type]}
            {...isFeatureEnabled(FEATURE_NAMES.Flags) && type === ITEM_TYPES.LINK ? { overriddenColoredIcons: COLORED_FLAGS } : {}}
          // onRemove={handleIconRemove} // there is no remove handler!
            allowShuffle={true}
            removeButtonText={t('Reset')}
            resettable={true}
            defaultValue={defaultValue}
          />
        </>
      )}
    </ScItemIconToggle>
  );
};

ItemIconToggle.propTypes = {
  value: string,
  itemIcon: string,
  onChange: func,
  itemIconBgColor: string,
  itemIconColor: string,
  type: string,
  showRemoveButton: bool,
  defaultValue: string
};

ItemIconToggle.defaultProps = {
  value: '',
  itemIcon: '',
  onChange: f => f,
  itemIconBgColor: '',
  itemIconColor: '',
  type: '',
  showRemoveButton: true,
  defaultValue: ''
};

export default ItemIconToggle;
