import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import classNames from 'classnames';

function SearchInput({
  placeholder,
  value: defaultValue,
  onChange,
  autoFocus,
  className,
  isNewBranding
}) {
  const [value, setValue] = useState(defaultValue);

  const handleInputChange = e => setValue(e.target.value);

  Hooks.useEffectIgnoreFirst(() => onChange(value), [value]);

  return (
    <input
      className={classNames('cb-search', className, { isNewBranding })}
      type="search"
      value={value}
      placeholder={placeholder}
      onChange={handleInputChange}
      autoFocus={autoFocus}
    />
  );
}

SearchInput.propTypes = {
  placeholder: string,
  value: string,
  onChange: func,
  autoFocus: bool,
  className: string,
  isNewBranding: bool
};

SearchInput.defaultProps = {
  placeholder: 'Search',
  value: '',
  onChange: f => f,
  autoFocus: false,
  className: null,
  isNewBranding: false
};

export default SearchInput;
