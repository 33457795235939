import React, { useCallback, useMemo } from 'react';
import { bool, func, number } from 'prop-types';

const DefaultItemRenderer = ({
  index, covered, onChange, hovered,
  onMouseEnter
}) => {
  const handleMouseEnter = useCallback(() => onMouseEnter(index), []);
  const shouldColor = useMemo(() => (covered || hovered), [covered, hovered]);
  return (
    <button
      type="button"
      onMouseEnter={handleMouseEnter}
      onClick={() => onChange(index)}
      style={{ textDecoration: shouldColor ? 'underline' : 'none' }}
    >
      {index}
    </button>
  );
};

DefaultItemRenderer.propTypes = {
  index: number.isRequired,
  covered: bool,
  hovered: bool,
  onMouseEnter: func,
  onChange: func
};

DefaultItemRenderer.defaultProps = {
  covered: false,
  hovered: false,
  onMouseEnter: f => f,
  onChange: f => f
};

export default DefaultItemRenderer;
