import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';

import { templateConstants } from '../../templateConstants';
import { getDeviceType } from '../../getDeviceType';

import Accordion from './Accordion';
import RadioButton from './RadioButton';

const Sorting = ({
  username, filters, setFilters, templateType
}) => {
  const [sortValue, setSortValue] = useState('popular');

  const handleChangeFilter = (filterKey, newValue) => {
    if (filters[filterKey] === newValue) return;
    setFilters({
      ...filters,
      [filterKey]: newValue
    });
  };
  const handleChangeSorting = e => {
    const option = e.target.value;
    setSortValue(option);
    window.standaloneTemplatesLogger({
      actor: username,
      target: option,
      action: `${getDeviceType()}:sorting`
    });
    handleChangeFilter('sorting', option);
  };

  return (
    <Accordion username={username} text={t('SORT BY')} label={t(capitalizeFirstLetter(sortValue))}>
      {(templateConstants[templateType].sortingOptions || []).map(option => (
        <RadioButton
          key={option}
          label={t(capitalizeFirstLetter(option))}
          name="sort"
          value={option}
          id={option}
          checked={sortValue === option}
          onChange={handleChangeSorting}
        />
      ))}
    </Accordion>
  );
};

Sorting.defaultProps = {
  username: '',
  filters: {},
  setFilters: () => {},
  templateType: ''
};

Sorting.propTypes = {
  username: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  templateType: PropTypes.string
};

export default Sorting;
