/* eslint-disable react/prop-types */
import React from 'react';
import Styled from 'styled-components';
import Container from '../Container';

export const LoaderSvg = Styled.svg`
width: 100%;
height: 100%;
.line {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 17px;
  stroke-dasharray: 0, 10px;
  stroke-dashoffset: -8px;
  animation-name: lineAnim1;
  animation-fill-mode: backwards;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.line2 {
  animation-delay: 0.1s;
}
.line3 {
  animation-delay: 0.2s;
}
@keyframes lineAnim1 {
  0% {
    stroke-dasharray: 0, 18px;
    stroke-dashoffset: -8px;
  }
  100% {
    stroke-dasharray: 18px;
    stroke-dashoffset: 0;
  }
}
`;

const Loader = ({ size, className }) => {
  return (
    <Container size={size} className={className}>
      <LoaderSvg
        style={{ overflow: 'visible' }}
        width="60"
        height="60"
        viewBox="0 0 60 60"
      >
        <line
          className="line line1"
          x1="9.68"
          y1="37.79"
          x2="9.68"
          y2="20.19"
          stroke="#0099ff"
        />
        <line
          className="line line2"
          x1="29.95"
          y1="37.79"
          x2="29.95"
          y2="20.19"
          stroke="#ff6100"
        />
        <line
          className="line line3"
          x1="50.32"
          y1="37.79"
          x2="50.32"
          y2="20.19"
          stroke="#ffb629"
        />
      </LoaderSvg>
    </Container>
  );
};

export default Loader;
