import React from 'react';
import { t } from '@jotforminc/translation';

const LoadingScreen = () => {
  return (
    <>
      <div className="fileUpload-verticalAlign-text-loader"> </div>
      <div className="fileUpload-verticalAlign-text-loading">{t('Uploading...')}</div>
    </>
  );
};

export default LoadingScreen;
