import React, { useState } from 'react';
import propTypes from 'prop-types';
import { ImageUpload as JFImageUpload } from '@jotforminc/image-upload';
import { t } from '@jotforminc/translation';
import classnames from 'classnames';
import ImagePreview from '../../../../ImagePreview';
import { IMAGE_TYPE } from '../../../../../../../constants';
import ChooseFileButton from '../../ChooseFileButton';
import { ScRightPanelBlock } from '../../../../../styles/scRightPanel';
import { SIDE_SELECTION_DEFAULTS } from '../constants';

const Image = ({
  value, onChange, type
}) => {
  const isValueDefault = value === SIDE_SELECTION_DEFAULTS[type];
  const [isFileUploadButtonVisible, setIsFileUploadButtonVisible] = useState(!value || !value?.url || isValueDefault);
  const onRemoveImage = () => {
    onChange(null);
    setIsFileUploadButtonVisible(true);
  };

  if (isFileUploadButtonVisible) {
    return (
      <ScRightPanelBlock line style={{ padding: 0 }}>
        <div className='field-description mb-2'>{t('Supported formats: jpg, jpeg, png, gif, svg')}</div>
        <ChooseFileButton onClick={() => setIsFileUploadButtonVisible(false)} />
      </ScRightPanelBlock>
    );
  }

  return (
    <div className={classnames(type, isValueDefault)}>
      {
           value?.url && !isValueDefault
             ? (
               <ImagePreview
                 imageURL={value.url}
                 imageType={IMAGE_TYPE.image}
                 layout="line"
                 onRemove={onRemoveImage}
               />
             )
             : (
               <JFImageUpload
                 onImageSelect={url => onChange({ url })}
                 onImageUpload={({ url }) => onChange({ url })}
                 onImageRemove={onRemoveImage}
                 onLinkTextInputSubmit={url => onChange({ url })}
                 renderTabs={['upload', 'choose', 'link']}
                 useAPI={true}
                 allowMultipleSelection={false}
                 allowedTypes='image/*'
               />
             )
      }
    </div>
  );
};

Image.propTypes = {
  value: propTypes.object,
  onChange: propTypes.func,
  type: propTypes.string
};

Image.defaultProps = {
  value: undefined,
  onChange: f => f,
  type: ''
};

export default Image;
