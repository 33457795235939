/* eslint-disable max-len */
import React from 'react';
import { bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import Styled from 'styled-components';
import { useSelector } from 'react-redux';
import SELECTORS from '../../../store/selectors';

const ScAdminWarning = Styled.div`
  display: block;
  background-color: red;
  color: #fff;
  border-radius: 16px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(86,70,50,.1);
  margin-bottom: 8px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const ScPortalFeedbackWithWarning = Styled.div`
  ${({ isMultiPage }) => (isMultiPage ? 'position: absolute;' : 'position: fixed;')}
  display: flex;
  flex-direction: column;
  align-items: end;
  right: 15px;
  z-index: 1;
  bottom: 0;
  
  @media screen and (max-width: 480px) {
    right: 100px;
  }
`;
/* eslint-enable max-len */

const PortalFeedbackWithWarning = ({
  isMultiPage
}) => {
  const portalOwner = useSelector(SELECTORS.getPortalOwner);
  const shouldWarnEditer = useSelector(SELECTORS.getShouldWarnToEditers);

  return (
    <ScPortalFeedbackWithWarning className="admin-warning" isMultiPage={isMultiPage}>
      {shouldWarnEditer && (
        <ScAdminWarning>
          {t(`Please be careful, this app belongs to ${portalOwner}.`)}
        </ScAdminWarning>
      )}
    </ScPortalFeedbackWithWarning>
  );
};
PortalFeedbackWithWarning.propTypes = {
  isMultiPage: bool
};

PortalFeedbackWithWarning.defaultProps = {
  isMultiPage: false
};

export default PortalFeedbackWithWarning;
