import { bool, string } from 'prop-types';
import React, {
  createRef, useEffect
} from 'react';
import IframeRenderer from './IframeRenderer';
import { generateIframeURL } from '../../utils';
import { FORM_PRODUCTS } from '../../../../constants';

const TablesRenderer = ({
  appID,
  productID,
  forModal = false,
  ...props
}) => {
  const iframeRef = createRef();

  // Generate the iframe URL
  const pageURL = generateIframeURL({ appID, id: productID, type: FORM_PRODUCTS.TABLES });

  // Temporary fix, will be out of service soon
  useEffect(() => {
    if (!forModal) {
      return;
    }
    const iframe = iframeRef.current;
    let intervalId;

    if (iframe) {
      const checkIframeLoaded = () => {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

        if (iframeDocument.readyState === 'complete') {
          const formBtn = iframeDocument.querySelector('.jSheetForm-select.js-formButton');
          const productTab = iframeDocument.querySelector('.product-tab')?.children;
          const newTabBtn = productTab?.[1];
          const searchTabsBtn = productTab?.[2];
          const removingItems = [formBtn, newTabBtn, searchTabsBtn];
          if (removingItems.every(item => item && !!item.style)) {
            // eslint-disable-next-line no-return-assign, no-param-reassign
            removingItems.forEach(item => item.style.display = 'none');
          }

          if (formBtn && productTab) {
            clearInterval(intervalId);
          }
        }
      };

      intervalId = setInterval(checkIframeLoaded, 500);

      return () => clearInterval(intervalId);
    }
  }, [iframeRef.current]);

  return (
    <IframeRenderer ref={iframeRef} pageURL={pageURL} {...props} />
  );
};

TablesRenderer.propTypes = {
  appID: string,
  productID: string,
  forModal: bool
};

TablesRenderer.defaultProps = {
  appID: '',
  productID: ''
};

export default TablesRenderer;
