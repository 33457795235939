/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconXmark, IconGridDotsVertical } from '@jotforminc/svg-icons';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { func, number, object } from 'prop-types';
import { COLUMN_IDS, ICONS_WRT_COLUMN_TYPE } from './constants';

const SelectedItem = ({
  item, index, setColumns
}) => {
  const { text, qid, type } = item;
  const removeItem = (e, itemID) => {
    setColumns(prev => {
      const newSelectedItems = prev[COLUMN_IDS.SELECTEDS_COLUMN].items.filter(({ qid: prevSelectedItemID }) => prevSelectedItemID !== itemID);

      return {
        ...prev,
        [COLUMN_IDS.SELECTEDS_COLUMN]: { ...prev[COLUMN_IDS.SELECTEDS_COLUMN], items: newSelectedItems }
      };
    });
  };

  const Icon = ICONS_WRT_COLUMN_TYPE[type];

  return (
    <Draggable
      key={`selected-${qid}`}
      draggableId={`selected-${qid}`}
      index={index}
    >
      {provided => {
        return (
          <div
            className='selection-item column-right'
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {Icon
                        && (
                        <div className='column-type-icon'>
                          <Icon width={16} height={16} />
                        </div>
                        )}
            <div className='column-item-text'>
              {text}
            </div>
            <div className='drag-icon'>
              <IconGridDotsVertical width={16} height={16} />
            </div>
            <div className='remove-icon' onClick={e => removeItem(e, qid)}>
              <IconXmark height={20} width={20} />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

SelectedItem.propTypes = {
  item: object.isRequired,
  index: number.isRequired,
  setColumns: func.isRequired,
  columns: object.isRequired
};

export default SelectedItem;
