import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import '../../styles/settingsPanel.scss';

const SettingsPanelWrapper = ({
  listClasses, listWrapperClasses, children
}) => {
  return (
    <div className={classNames('settingsPanel w-full h-full bg-gray-600 overflow-auto', listWrapperClasses)}>
      <ul className={classNames('settingsPanel-list space-y-3', listClasses)}>
        {children}
      </ul>
    </div>
  );
};

SettingsPanelWrapper.propTypes = {
  listClasses: string,
  listWrapperClasses: string,
  children: node
};

SettingsPanelWrapper.defaultProps = {
  listClasses: null,
  listWrapperClasses: null,
  children: null
};

export default SettingsPanelWrapper;
