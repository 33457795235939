import React from 'react';
import ReactDOM from 'react-dom';
import { string, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { CSSTransition } from 'react-transition-group';
import {
  IconCheckCircleFilled, IconXmark, IconInfoCircleFilled, IconExclamationTriangleFilled
} from '@jotforminc/svg-icons';
import ScToast from './ScToast';

const ICON_MAP = {
  notification: () => <IconInfoCircleFilled width="24px" color="#09F" />,
  warning: () => <IconExclamationTriangleFilled width="24px" color="#FFB629" />,
  success: () => <IconCheckCircleFilled width="24px" color="#78BB07" />,
  error: () => <IconExclamationTriangleFilled width="24px" color="#D11F42" />,
  default: () => <IconInfoCircleFilled width="24px" color="#09F" />
};

// todo :: get icon type from constant, not string

const Notification = ({
  message,
  description,
  type,
  buttonText,
  onButtonClick,
  backdrop
}) => {
  const Icon = ICON_MAP[type] || ICON_MAP.default;

  return (
    <>
      { backdrop && ReactDOM.createPortal(
        <CSSTransition
          unmountOnExit={true}
          timeout={100}
          in={true}
          appear={true}
          classNames="toast-backdrop"
        >
          <div className="toast-backdrop" />
        </CSSTransition>, document.getElementById('toast-root')
      )}
      <ScToast>
        <div className="icon"><Icon /></div>
        <div className="context">
          <div className="message">{t(message)}</div>
          { description && (<div className="description">{t(description)}</div>) }
        </div>
        { buttonText && (
          <Button
            variant='ghost'
            onClick={onButtonClick}
          >
            {t(buttonText)}
          </Button>
        )}
        { 1 && (
          <Button
            colorStyle='teams'
            rounded
            className="ml-3"
            startIcon={IconXmark}
          />
        )}
      </ScToast>
    </>
  );
};

Notification.propTypes = {
  message: string,
  description: string,
  type: string,
  buttonText: string,
  onButtonClick: func,
  backdrop: bool
};

Notification.defaultProps = {
  message: '',
  type: 'default',
  buttonText: '',
  description: '',
  onButtonClick: f => f,
  backdrop: false
};

export default Notification;
