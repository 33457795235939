import Styled from 'styled-components';

export const ScIconWrapper = Styled.div` 
  width: 96px;
  height: 96px;
  background-color:rgba(255, 182, 41, 0.25);
  color: #FFB629;
  border-radius:100%;
  padding: 1px 8px 15px;
  margin: 0 auto;
`;
