import React, { Component } from 'react';

function withActions(WrappedComponent, actionsProjectName, additionalProjects = []) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.JotFormActions = null;
      this.additionalActionLoggers = {};

      this.setupJotFormActions = this.setupJotFormActions.bind(this);
      this.registerJotFormAction = this.registerJotFormAction.bind(this);

      this.setupJotFormActions();
    }

    get shouldLogActions() {
      const { disableActionLogger } = this.props; // eslint-disable-line
      return global.location.href.indexOf('.jotform.pro') < 0 && global.location.href.indexOf('.jotform.biz') < 0 && disableActionLogger !== true;
    }

    setupJotFormActions() {
      const { JotFormActions } = global;
      if (typeof JotFormActions === 'function') {
        this.JotFormActions = JotFormActions(actionsProjectName);

        // Register additional loggers too.
        additionalProjects.forEach(projectName => {
          this.additionalActionLoggers[projectName] = JotFormActions(projectName);
        });
      }
    }

    registerJotFormAction({ action, target }, forAdditionalProject = '') {
      const { user: { username: actor = null } } = global;
      if (!this.shouldLogActions) {
        return;
      }
      const mainLogger = this.JotFormActions;
      const additionalLogger = this.additionalActionLoggers[forAdditionalProject];
      if (!!forAdditionalProject && !additionalLogger) {
        console.info('No additional project found, falling back to main');
      }
      const logger = additionalLogger || mainLogger;

      if (actor && logger) {
        logger.tick({ actor, action, target });
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          ref={r => { this.componentHandle = r; }}
          actionLogger={this.registerJotFormAction}
        />
      );
    }
  };
}

export default withActions;
