export const ICON_TYPES_ALL = {
  png: 'PNG',
  jpg: 'JPG',
  jpeg: 'JPEG',
  gif: 'GIF',
  bmp: 'BMP',
  tiff: 'TIFF',
  tif: 'TIF',
  mp3: 'MP3',
  ogg: 'OGG',
  mav: 'WAV',
  wma: 'WMA',
  mp4: 'MP4',
  webm: 'WEBM',
  mpg: 'MPG',
  flv: 'FLV',
  avi: 'AVI',
  pdf: 'PDF',
  csv: 'CSV',
  txt: 'TXT',
  rtf: 'RTF',
  html: 'HTML',
  zip: 'ZIP',
  xls: 'XLS',
  xlsx: 'XLSX',
  doc: 'DOC',
  docx: 'DOCX'
};
export const ICON_TYPES = {
  iconFileImage: ['PNG', 'JPG', 'JPEG', 'GIF', 'BMP', 'TIFF', 'TIF'],
  iconFileAudio: ['MP3', 'OGG', 'WAV', 'WMA'],
  iconFileVideo: ['MP4', 'WEBM', 'MPG', 'FLV', 'AVI'],
  iconFileDocument: ['PDF', 'CSV', 'TXT', 'RTF', 'HTML', 'ZIP'],
  iconFileExcel: ['XLS', 'XLSX'],
  iconFileDoc: ['DOC', 'DOCX'],
  iconFileUnsupported: []
};
export const getIconType = fileFormat => {
  const findIndex = Object.values(ICON_TYPES).findIndex(iconType => iconType.includes(fileFormat));
  return findIndex > -1 ? Object.keys(ICON_TYPES)[findIndex] : 'iconFileUnsupported';
};

export const makeCamelCase = str => {
  if (typeof str !== 'string') return null;
  const newStr = str.length > 0 ? str[0].toUpperCase() + str.slice(1).toLowerCase() : '';
  newStr.replace('_', ' ');
  return newStr;
};

export const getFileName = url => {
  return (url || '').split('/').pop().split('?')[0];
};

export const getFileExtension = url => {
  return getFileName(url).split('.').pop().toUpperCase();
};

export const isImage = src => ['JPEG', 'PNG', 'GIF', 'TIFF', 'JPG']
  .indexOf(getFileExtension(src)) !== -1;

const encodeRequestUrl = url => {
  return url.replace('#', '%23').replace('&', '%26');
};

export const prepareFiles = (answer, withIconType = false) => (
  answer.map(file => {
    const fileName = getFileName(file);
    const fileUrl = encodeRequestUrl(file);
    const extension = getFileExtension(fileName);
    return {
      fileName,
      fileUrl,
      isImageFile: isImage(fileName),
      extension,
      previewUrl: fileUrl,
      ...(withIconType ? { iconType: getIconType(extension) } : {})
    };
  })
);

export const getUploadUrl = url => {
  return url.split('?')[0];
};

export const removeExtension = filename => {
  return filename.replace(/\.[^/.]+$/, '');
};

export const isPDF = src => getFileExtension(src) === 'PDF';

export function isImageFile(filename = '') {
  return filename.match(/(jpg|jpeg|gif|png)((\?.*)$|$)/i) !== null;
}
