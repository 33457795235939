import React from 'react';
import {
  oneOfType, arrayOf, node, string, bool
} from 'prop-types';
import { classNames } from '@jotforminc/utils';

import texts from '../../core/texts';
import Loader from '../Loader';

export default class WizardListItem extends React.Component {
  render() {
    const {
      icon,
      text,
      subText,
      isBeta,
      isNew,
      isLoading,
      className,
      ...props
    } = this.props;

    return (
      <li
        className={classNames('jfWizard-list-item-wrapper', className)}
      >
        <button
          className={classNames('jfWizard-list-item', isLoading && 'isLoading')}
          type="button"
          aria-label={text}
          {...props}
        >
          {icon && (
            <div className="jfWizard-list-item-icon-wrapper">
              <div className="jfWizard-list-item-icon">
                {icon}
              </div>
              {isLoading && (
                <div className="jfWizard-item-loader">
                  <Loader />
                </div>
              )}
              {isNew && (
                <div className='jfWizard-list-item-isNew'>{texts.NEW}</div>
              )}
            </div>
          )}
          <div className="jfWizard-list-item-text-wrapper">
            {text && <div className="jfWizard-list-item-text">{text}</div>}
            {subText && <div className="jfWizard-list-item-subtext">{subText}</div>}
            {isBeta && (
              <div className="jfWizard-list-item-isBeta">{texts.BETA}</div>
            )}
          </div>
        </button>
      </li>
    );
  }
}

WizardListItem.propTypes = {
  icon: oneOfType([
    arrayOf(node),
    node
  ]),
  text: string,
  subText: string,
  className: string,
  isBeta: bool,
  isNew: bool,
  isLoading: bool
};

WizardListItem.defaultProps = {
  icon: null,
  text: null,
  subText: null,
  className: '',
  isBeta: false,
  isNew: false,
  isLoading: false
};
