import axios from 'axios';

export async function logSearchAction(username, keyword, project, source = '') {
  const formData = new FormData();

  formData.append('project', project);
  formData.append('username', username);
  formData.append('term', keyword);
  formData.append('location', source !== '' ? source : project);

  return axios.post('/API/search', formData);
}
