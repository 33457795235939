import { ICON_JSON } from './contentsDynamic';

export const TAB_ICON_SRC = {
  All: 'jfc_icon_solid-star',
  Business: 'jfc_icon_solid-briefcase',
  Education: 'jfc_icon_solid-graduation-cap',
  Smileys: 'jfc_icon_solid-smile',
  Animals: 'jfc_icon_solid-dog-face',
  Childhood: 'jfc_icon_solid-pac-man',
  Entertainment: 'jfc_icon_solid-camera-movie',
  Holidays: 'jfc_icon_solid-muffin',
  Colored: 'jfc_icon_color-coloredjfsvg-fun-eye'
};

export const searchTermEndPointUrl = 'getIconSuggestion?word=';

export const iconSpriteURL = 'assets/icons/icon-sets-v2/iconSprite.svg';

export const iconTypeRegex = /jfc_icon_(.*?)-/;

export const nameAndCategoryRegex = /^(.*?)\|{2}(.*?)$/;

export { ICON_JSON };
