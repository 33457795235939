import React from 'react';
import { DEFAULT_OPTIONS } from '../MultiSectionSelect/helper';
import { OPTION_VALUES, PROP_TYPES, SIDE_SELECTION_DEFAULTS } from './constants';
import IconSelector from './subSelections/IconSelector';
import Image from './subSelections/Image';
import { VALUE_BOUNDED_OPTIONS } from '../../../../../../components/ListItemBase/helper';
import TextInput from '../../../TextInput';
import DataSourcePicker from '../DataSourcePicker';
import { DATA_SOURCE_COLUMN_TYPES } from '../../../../../../constants/itemTypes';

const Empty = () => <></>;

const shouldUseDefault = (type, value) => {
  return VALUE_BOUNDED_OPTIONS[type] && VALUE_BOUNDED_OPTIONS[type]?.props?.every(prop => value?.[prop] === undefined);
};

const generateDefault = ({ type, value, ...rest }) => {
  const useDefault = shouldUseDefault(type, value);
  if (useDefault) {
    const defaultProps = SIDE_SELECTION_DEFAULTS[type];
    if (typeof defaultProps === 'function') {
      return defaultProps({ ...rest });
    }
    return SIDE_SELECTION_DEFAULTS[type];
  }
  return null;
};

export const selectSubfield = ({
  type, value, onChange, propLocation
}) => {
  const defaultSelection = generateDefault({ type, value, propLocation });

  const getSubfieldWithProps = () => {
    switch (type) {
      case OPTION_VALUES.AVATAR: {
        return [Image, { type: 'multisection-settings-avatar', onChange }];
      }
      case OPTION_VALUES.IMAGE: {
        return [Image, { type: 'multisection-settings-image', onChange }];
      }

      case OPTION_VALUES.ICON: {
        return [IconSelector];
      }

      case OPTION_VALUES.BUTTON: {
        return [TextInput, { value: value?.text, maxLength: 20, onChange: text => onChange({ text }) }];
      }

      default: {
        return [Empty];
      }
    }
  };

  const subfieldWithProps = getSubfieldWithProps();

  const subfieldWithDefaultSelection = [subfieldWithProps[0], { ...subfieldWithProps[1], defaultSelection: defaultSelection ?? undefined }];
  return subfieldWithDefaultSelection;
};

export const listItemStartDsSubFieldSelection = props => ({
  type, value, onChange, ...rest
}) => {
  switch (type) {
    case OPTION_VALUES.AVATAR:
    case OPTION_VALUES.IMAGE: {
      return [DataSourcePicker, {
        ...props,
        ...rest,
        columnType: DATA_SOURCE_COLUMN_TYPES.IMAGE,
        value: value?.url,
        onChange: val => onChange({ url: val })
      }];
    }

    default: {
      return [Empty];
    }
  }
};

export const listItemEndDsSubFieldSelection = props => ({
  type, value, onChange
}) => {
  switch (type) {
    case OPTION_VALUES.BUTTON: {
      return [DataSourcePicker, {
        ...props, columnType: DATA_SOURCE_COLUMN_TYPES.TEXT, value: value?.text, onChange: val => onChange({ text: val })
      }];
    }

    case OPTION_VALUES.ICON: {
      return [IconSelector];
    }

    default: {
      return [Empty];
    }
  }
};

export const isOptionDefault = optionValue => {
  return !!Object.values(DEFAULT_OPTIONS).find(defaultOpt => defaultOpt.value === optionValue);
};

export const decidePropType = propID => {
  return propID.startsWith('start') ? PROP_TYPES.START : PROP_TYPES.END;
};
