import React from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string, bool, elementType
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { Dropdown as UiKitDropdown } from '@jotforminc/uikit';
import { ScDropdown } from './scDropdown';
import OptionContainerRenderer from './defaults/OptionContainerRenderer';
import DefaultButtonRenderer from './defaults/ButtonRenderer';
import ContainerRenderer from './defaults/ContainerRenderer';

const Dropdown = ({
  onChange, defaultValue, options, filterable, popoverProps, DropdownWrapperRenderer, OptionRenderer, ButtonRenderer, value, ...props
}) => {
  const handleOptionSelect = ({ value: newVal }) => onChange(newVal);

  return (
    <DropdownWrapperRenderer>
      <UiKitDropdown
        ContainerRenderer={ContainerRenderer}
        OptionContainerRenderer={OptionContainerRenderer}
        OptionRenderer={OptionRenderer}
        ButtonRenderer={ButtonRenderer}
        onOptionSelect={handleOptionSelect}
        defaultValue={value || defaultValue}
        options={options}
        filterable={filterable}
        extendOnGroupSearch={filterable}
        popoverProps={popoverProps}
        translate={t}
        {...props}
      />
    </DropdownWrapperRenderer>
  );
};

Dropdown.propTypes = {
  onChange: func,
  defaultValue: oneOfType([string, number]),
  value: oneOfType([string, number]),
  options: arrayOf(shape({})),
  filterable: bool,
  popoverProps: shape({}),
  DropdownWrapperRenderer: elementType,
  OptionRenderer: elementType,
  ButtonRenderer: elementType
};

Dropdown.defaultProps = {
  onChange: f => f,
  defaultValue: undefined,
  value: undefined,
  options: [],
  filterable: false,
  popoverProps: {},
  DropdownWrapperRenderer: ScDropdown,
  OptionRenderer: undefined,
  ButtonRenderer: DefaultButtonRenderer
};

export default Dropdown;
