// A/B Test: ctAppNameIconModal
import { StorageHelper } from '@jotforminc/storage-helper';

export const abTestUrlParam = 'ctanim';
const key = 'appNameIconModalSeen';

export const getHasAbTestParam = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.has(abTestUrlParam);
};

const getSeenList = () => {
  const seenListJSON = StorageHelper.getLocalStorageItem({ key });
  return JSON.parse(seenListJSON) || [];
};

export const getIsModalSeen = appID => {
  if (getHasAbTestParam()) return false;
  return getSeenList()?.includes(appID);
};

export const setIsModalSeen = appID => {
  const currentList = getSeenList();
  const nextSeenList = currentList.includes(appID) ? currentList : [...currentList, appID];
  const value = JSON.stringify(nextSeenList);
  StorageHelper.setLocalStorageItem({ key, value });
};
