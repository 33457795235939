import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control BlueSnap gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlBluesnap: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_bluesnap',
  name: gatewayFieldToName.control_bluesnap,
  supportedCurrencies: gatewaySupportedCurrencies.control_bluesnap,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_bluesnap,
  sortByCountry: gatewayListSortedByCountry.control_bluesnap,

  /** APM's */
  isApm: false,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: true,
    mode: {
      name: 'sandbox',
      options: [
        { value: 'enabled', text: 'Test Mode', mappedValue: '1' },
        { value: 'disabled', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    propReferences: {
      apiKey: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'API Key' } },
      password: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'API Password' } },
      sandbox: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['apiKey', 'password']
  }
};
