import React from 'react';
import { t } from '@jotforminc/translation';
import PropTypes from 'prop-types';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import Styled from 'styled-components';
import { isTeamResourcePicker } from '../../../../../utils';

const ScModalFooterWarning = Styled.div`
  display: flex;
  align-items: center;
  color: #141E46;
  font-size: 16px;
  text-align: left;
  
  svg {
    width: 16px;
    color: #0099FF;
    margin-right: 10px;
  }
`;

const Footer = ({
  type, confirmText, selectedItemCount, onAddButtonClick, hasItems, onCancel, hasSentbox
}) => (
  <div className="pm-f">
    {onCancel ? (
      <Button colorStyle='secondary' onClick={onCancel}>
        {t('Cancel')}
      </Button>
    ) : (
      <div>
        {hasSentbox ? (
          <ScModalFooterWarning>
            <IconInfoCircleFilled width="16" color="#0075e3" />
            <span>{t('Related Sentbox element(s) will be updated.')}</span>
          </ScModalFooterWarning>
        ) : (
          <>
            {(selectedItemCount > 0 && isTeamResourcePicker()) && t(`{itemCount} ${type}s selected.`).replace('{itemCount}', selectedItemCount)}
          </>
        )}
      </div>
    )}
    {hasItems && (
      <Button data-testid="footer-confirm-button" onClick={onAddButtonClick} disabled={selectedItemCount === 0}>
        {t(confirmText)}
      </Button>
    )}
  </div>
);

Footer.propTypes = {
  type: PropTypes.string.isRequired,
  selectedItemCount: PropTypes.number.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  hasItems: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  hasSentbox: PropTypes.bool
};

Footer.defaultProps = {
  confirmText: 'Add',
  onCancel: false,
  hasSentbox: false
};

export default Footer;
