import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { checkIsGatewayConnectedAndTestMode } from '@jotforminc/payment-settings-editor';
import { generateIframeURL } from '../../modules/PublicApp/utils';
import { QUANTITY_PROP } from '../../modules/PublicApp/ProductCart/Cart/constants';
import * as PORTAL_SELECTORS from './portalSelectors';
import * as CHECKOUT_FORM_SELECTORS from './checkoutFormSelectors';
import { getIsPaymentOverQuota, getIsReusableConnectionEnabled } from './userSelectors';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';

export const getProducts = ({ products }) => products;

export const getFilteredProducts = createSelector(getProducts, ({ filteredProducts }) => filteredProducts);

export const getCart = createSelector(getProducts, ({ cartProducts }) => cartProducts);

export const getAllFavoriteProducts = createSelector(getProducts, ({ favoriteProducts }) => favoriteProducts);

export const getFavoriteProductsByItem = itemID => createSelector(getAllFavoriteProducts, allFavoriteProducts => allFavoriteProducts[itemID]);

export const getCheckoutKey = createSelector(getProducts, ({ checkoutKey }) => checkoutKey);

export const getSelectedProducts = itemID => createSelector(getCart, PORTAL_SELECTORS.getPortalItemByIDSelector(itemID), (products, { formID }) => {
  const selectedItemProducts = products[formID];
  return selectedItemProducts || []; // empty array as default value
});

export const getIsChangedPriceInCart = createSelector(getProducts, ({ isChangedPriceInCart }) => isChangedPriceInCart);

export const getFormProductListByItemID = (itemID = '') => createSelector(PORTAL_SELECTORS.getPortalItemByIDSelector(itemID), productListItem => {
  const { products = [] } = productListItem || {};

  return products;
});

export const getFormProductListProduct = (itemID, productID) => createSelector(getFormProductListByItemID(itemID), products => {
  return products.find(({ pid }) => pid === productID);
});

export const getCheckoutFormIDSelector = createSelector(PORTAL_SELECTORS.getPortalInfoSelector, ({ checkoutFormID }) => checkoutFormID);

export const getCheckoutFormURLSelector = createSelector([getCheckoutFormIDSelector, PORTAL_SELECTORS.getPortalInfoSelector], (checkoutFormID, portalInfo) => {
  const { id: appID } = portalInfo;

  return generateIframeURL({ appID, id: checkoutFormID, additionalQueries: { isCheckoutForm: 1 } });
});

export const getFavoriteProductLists = createSelector(getAllFavoriteProducts, PORTAL_SELECTORS.getPortalItems, (favs, items) => {
  return Object.entries(favs).reduce((prev, [productListID, favsArrray]) => {
    const productList = items.find(item => item.id === productListID);
    if (!productList) return prev;
    const { products } = productList;
    const favProducts = products.filter(product => favsArrray.includes(product.pid));
    if (favProducts.length === 0) return prev;
    const favProductProps = favProducts.map(product => {
      const {
        price, name, images, pid, options
      } = product;
      return {
        price, name, images, pid, options
      };
    });
    return [...prev, { id: productListID, cartProducts: favProductProps }];
  }, []);
});

export const getProductListItemsWithCartProducts = createSelector([getCart, PORTAL_SELECTORS.getProductListItems], (cartProducts, productListItems) => {
  return productListItems.filter(item => !!cartProducts[item.formID] && cartProducts[item.formID].length > 0).map(item => ({
    ...item,
    cartProducts: cartProducts[item.formID]
  }));
});

export const getCartProductsCount = createSelector(getCart, cartProducts => Object.values(cartProducts).reduce((acc, products) => acc + products.length, 0));

export const getCartTotal = createSelector(getCart, cartProducts => Object.values(cartProducts).reduce((acc, products) => {
  return acc + products.reduce((amountAcc, product) => amountAcc + product.price * (product.quantity || 1), 0);
}, 0));

export const getCartProductItemCount = createSelector(getCart, cartProducts => Object.values(cartProducts).reduce((acc, products) => {
  return acc + products.reduce((quantityAcc, product) => quantityAcc + (product.quantity || 1), 0);
}, 0));

export const getAllCartProducts = createSelector(getCart, (cartProducts = {}) => {
  return Object.values(cartProducts).reduce((acc, array) => [...acc, ...array], []);
});

export const getCartProductByIDs = (formID, productID) => createSelector(getCart, products => {
  const productListProducts = products[formID];

  if (!productListProducts || isEmpty(productListProducts)) {
    return {};
  }
  return productListProducts.find(pr => pr.pid === productID) || {};
});

export const getIsProductSelected = (itemID, productID) => createSelector(getSelectedProducts(itemID), selectedProducts => {
  return !!selectedProducts.find(product => product.pid === productID);
});

export const getIsProductFavorite = (itemID, productID) => createSelector(getFavoriteProductLists, favorites => {
  const itemFavorites = favorites.find(favs => favs.id === itemID) || {};

  const {
    cartProducts = []
  } = itemFavorites;

  const favoriteProduct = cartProducts.find(product => product.pid === productID);
  return !!favoriteProduct;
});

export const getSelectedProductOptions = (formID, productID) => createSelector(getCartProductByIDs(formID, productID), product => {
  if (!product || isEmpty(product)) {
    return {};
  }

  const { options = [] } = product;
  return options.reduce((prev, opt, index) => ({ ...prev, [index]: opt.selected }), {});
});

export const getActiveProduct = createSelector(getProducts, ({ activeProduct }) => activeProduct);

export const getSelectedOptionsValue = (formID, productID) => createSelector(getCartProductByIDs(formID, productID), product => {
  if (!product) return '';

  const { options = [] } = product;

  const valuesArray = options.reduce((prev, opt) => {
    const { selected, type } = opt;
    if (!selected || type === QUANTITY_PROP) return prev;

    return [...prev, selected];
  }, []);

  return valuesArray.join(', ');
});

export const getPaymentErrors = createSelector([CHECKOUT_FORM_SELECTORS.getCheckoutFormActiveGatewaySettings,
  getIsPaymentOverQuota,
  CHECKOUT_FORM_SELECTORS.getCheckoutFormPaymentQuestion,
  getIsReusableConnectionEnabled], (gatewaySettings, isPaymentOverQuota, checkoutFormPaymentQuestion, isReusableConnectionEnabled) => {
  const paymentConnectionID = checkoutFormPaymentQuestion?.paymentConnectionID;
  const isConnected = isReusableConnectionEnabled ? paymentConnectionID : checkIsGatewayConnectedAndTestMode(gatewaySettings)[0];
  const isTestMode = checkIsGatewayConnectedAndTestMode(gatewaySettings)[1];
  const isNotConfigured = !isConnected && !isTestMode;

  const overQuota = isFeatureEnabled(FEATURE_NAMES.OverQuota) && isPaymentOverQuota;

  const erroredArray = [isReusableConnectionEnabled ? !isConnected : isNotConfigured, overQuota];

  if (!isReusableConnectionEnabled) { erroredArray.push(isTestMode); }

  const showErrorIcon = erroredArray.some(errorCase => !!errorCase);

  const generateErroredClassname = () => {
    switch (true) {
      case isReusableConnectionEnabled:
        return 'test-mode';
      case !!overQuota:
      case isNotConfigured:
        return 'not-configured';
      case isTestMode:
        return 'test-mode';
      default:
        return '';
    }
  };

  return {
    showErrorIcon,
    erroredClassname: generateErroredClassname()
  };
});

export const getFormIDByItemID = itemID => createSelector(PORTAL_SELECTORS.getProductListItems, items => items.find(item => item.id === itemID)?.formID);
export const getProductListByID = formID => createSelector(PORTAL_SELECTORS.getProductListItems, productListItem => productListItem.find(item => item.formID === formID) || {});
export const getProductsOfProductListByID = formID => createSelector(getProductListByID(formID), productList => productList?.products || []);
export const getProduct = (formID, productID) => createSelector(getProductsOfProductListByID(formID), products => products.find(product => product.pid === productID) || {});
export const getItemIDByFormID = formID => createSelector(getProductListByID(formID), productList => productList.id);

export const getDonationPriceInCart = createSelector(getCart, ({ donation }) => donation?.length > 0 && donation[0]?.price);
