import PropTypes from 'prop-types';
import React from 'react';
import isUndefined from 'lodash/isUndefined';
import { a11yClickHandler, a11yListNavigation } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';

const RadioGroup = ({
  onChange, name, defaultValue, elementLabel, additionalClasses, options
}) => {
  const onKeyDown = event => {
    a11yListNavigation(event, onChange, 'label > span');
    Object.defineProperty(event, 'target', { writable: false, value: event?.target?.previousElementSibling });
    a11yClickHandler(event, onChange);
  };

  const renderOption = (option, index) => (
    <label
      key={option.key}
      className="eachRadio"
    >
      <input
        type="radio"
        name={name}
        value={option.value}
        defaultChecked={option.value === defaultValue}
        checked={option.value === defaultValue}
        onChange={onChange}
      />
      <span
        role="radio"
        aria-checked={option.value === defaultValue}
        tabIndex={index === 0 ? '0' : '-1'}
        className="eachRadio-label"
        aria-labelledby={`radio-${option.key}`}
        onKeyDown={onKeyDown}
      >
        {t(option.text)}
      </span>
    </label>
  );

  const groupOptions = options.map(renderOption);
  return (
    <div
      aria-label={elementLabel?.text}
      role="radiogroup"
      className={!isUndefined(additionalClasses) && additionalClasses !== '' ? `radioGroup ${additionalClasses}` : 'radioGroup'}
    >
      {groupOptions}
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string.isRequired,
  // on select change
  onChange: PropTypes.func.isRequired,
  additionalClasses: PropTypes.string,
  elementLabel: PropTypes.object
};

RadioGroup.defaultProps = {
  additionalClasses: '',
  elementLabel: {}
};

export default RadioGroup;
