import { t } from '@jotforminc/translation';
import isArray from 'lodash/isArray';
import { I_GATEWAYS, T_FORM_QUESTIONS } from '../Types';

export const prepareAddQuestionData = (formQuestions: T_FORM_QUESTIONS, type: string | string[], text: string, data: { value: string } | string): Array<{ value: string; text: string; }> => {
  if (!formQuestions) return;
  const fields = isArray(type) ? formQuestions.filter(q => type.includes(q.type)) : formQuestions.filter(q => q.type === type);
  const _type = isArray(type) ? type[0] : type;
  const noneOpt = { value: 'none', text: t('None') };

  if (fields.length === 0) {
    if (typeof data === 'object' && data.value === 'addQuestion') {
      return [
        { value: _type, text: t(`${text} Field`) },
        noneOpt
      ];
    }

    return [
      { value: 'addQuestion', text: t(`+ Add ${text} Field`), type: _type },
      noneOpt
    ];
  }

  return [
    noneOpt,
    ...fields.reduce((prev, cur) => [...prev, { value: cur.qid, text: cur.text }], [])
  ];
};

export const getAddQuestionDefValue = (prop: { value: string } | string, formQuestions: T_FORM_QUESTIONS): string => {
  if (!formQuestions) return;
  if (prop) {
    switch (typeof prop) {
      case 'string':
        return formQuestions.find(q => q.qid === prop) ? prop : 'none';
      case 'object':
        return prop.value === 'addQuestion' ? prop : 'none';
      default:
        return 'none';
    }
  }

  return 'none';
};

export const getDisconnectSettings = (type: I_GATEWAYS): object => {
  switch (type) {
    case 'control_paypalcomplete':
      return { merchantId: '' };
    case 'control_paypalInvoicing':
      return {
        merchantId: '',
        merchantEmail: ''
      };
    case 'control_stripe':
      return {
        publishableKey: '',
        apiKey: '',
        connect: '',
        stripeUserId: ''
      };
    case 'control_stripeCheckout':
      return {
        stripeUserId: ''
      };
    case 'control_square':
      return {
        accessToken: '',
        merchantId: '',
        location: '',
        tokenExpiry: '',
        refreshToken: ''
      };
    case 'control_mollie':
      return {
        profileId: '',
        refreshToken: ''
      };
    default:
      break;
  }
};

// connection options for dropdown
export const prepareConnectionOptions = (gateway: I_GATEWAYS | null, data: object): object[] => {
  switch (gateway) {
    case 'control_sensepass':
      const connectionIdOpts = data ? Object.entries(data).reduce((acc, cur) => {
        const opt = { value: `${cur[0]}`, text: cur[1] };
        return [...acc, opt];
      }, []) : [];
      return [
        { value: 'none', text: t('None') },
        ...connectionIdOpts
      ];
    default:
      break;
  }

  return [];
};

export const isPositiveValue = (value: string | number): boolean => {
  const positiveValues = [1, 'true', 'True', 'enabled', 'Enabled', 'Yes', 'yes', 'sandbox'];
  return positiveValues.includes(value);
};
