import { t } from '@jotforminc/translation';
import { DESCRIPTION_LIMIT } from '../../../../../constants';
import { BUTTON_STYLING_LIST } from '../../../../../properties/styling';
import { TEXT_ALIGNMENT_RENDERER_ARRAY } from '../../../constants/textAlignment';

const DONATION_ITEM_CONSTANTS = {
  tabs: {
    General: {
      label: t('General')
    },
    Style: {
      label: t('Style')
    }
  },
  properties: {
    General: {
      warning: {
        type: 'donationGatewayWarningItem',
        disableElementLabel: true,
        noLine: true
      },
      title: {
        type: 'textinput',
        title: t('Heading Text'),
        placeholder: t('Type a heading'),
        limit: 240,
        description: '',
        noTopLine: true
      },
      description: {
        type: 'textarea',
        title: t('Subheading Text'),
        placeholder: t('Type a subheading'),
        limit: DESCRIPTION_LIMIT,
        description: '',
        sanitize: true
      },
      donationBoxPropertiesPanelField: {
        type: 'donationBoxPropertiesPanelField',
        allowMultiplePropChange: true,
        disableElementLabel: true,
        ariaLabel: t('Show Donation Goal Bar'),
        noPadding: true,
        noLabel: true
      },
      donateButtonTitle: {
        type: 'textinput',
        title: t('Button Text'),
        placeholder: t('Type a donate button text'),
        description: ''
      }
    },
    Style: {
      listItemStyle: {
        type: 'itemStyler',
        title: t('Button Style'),
        buttonText: t('Change Style'),
        colorList: BUTTON_STYLING_LIST,
        storeWithDifferentPropName: {
          itemBgColor: 'itemButtonBgColor',
          itemFontColor: 'itemButtonFontColor',
          itemBorderColor: 'itemButtonBorderColor'
        },
        customPaletteProps: {
          itemBgColor: {
            title: t('Button Bg Color')
          },
          itemFontColor: {
            title: t('Button Font Color'),
            placement: 'bottom-end',
            offsetX: 39
          }
        }
      },
      buttonRadius: {
        type: 'selection',
        title: t('Button Corner'),
        options: [{
          text: 'Default',
          value: 'default'
        },
        {
          text: 'Rounded',
          value: 'rounded'
        }
        ]
      },
      itemTextAlignment: {
        type: 'selection',
        title: t('Heading Text Alignment'),
        options: TEXT_ALIGNMENT_RENDERER_ARRAY
      }
    }
  }
};

export { DONATION_ITEM_CONSTANTS };
