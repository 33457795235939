import { ChoiceTruncate, Size, Theme } from '../../constants/common.types';
import { commonInputDefaults } from '../../contexts';
import { NativePropsWithoutRef } from '../../types/system.types';

export type CheckboxBaseProps = {
  label?: string,
  description?: string
  htmlSize?: number,
  truncate?: ChoiceTruncate,
  size?: Size,
  indeterminate?: boolean,
  theme?: Theme,
  error?: boolean,
  in?: 'dropdown'
}

export type CheckboxProps = NativePropsWithoutRef<'input', CheckboxBaseProps> & CheckboxBaseProps;

export const checkboxDefaultProps:Partial<CheckboxProps> = {
  size: commonInputDefaults.size,
  theme: commonInputDefaults.theme
};
