import { TextInput } from '@jotforminc/uikit';
import { func, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

const SchemaTextArea = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnBlur = useCallback(({ target: { value: newValue } }) => {
    onChange(newValue);
    setInputValue(newValue);
  }, [onChange]);

  return (
    <TextInput
      value={inputValue}
      onBlur={handleOnBlur}
      multiline={true}
    />
  );
};

SchemaTextArea.propTypes = {
  value: string,
  onChange: func
};

SchemaTextArea.defaultProps = {
  value: '',
  onChange: f => f
};

export default SchemaTextArea;
