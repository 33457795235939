import React from 'react';
import {
  elementType,
  func, number, oneOfType, shape, string
} from 'prop-types';

import { Utils } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { formatBytes } from '@jotforminc/utils';

const ImagePreview = ({
  file, changeModeToUpload, Thumbnail, removeButtonText
}) => {
  const { name, size } = file;
  const sizeText = formatBytes(size);

  const handleKeyDown = event => Utils.isPressedKeyEnterOrSpace(event) && changeModeToUpload();
  return (
    <div
      className="cardNew"
    >
      <div
        className="cardNew-leftSide"
      >
        <Thumbnail file={file} />
      </div>
      <div className="cardNew-rightSide">
        <div className="cardNew-content">
          <span className="cardNew-content-fileName">{name}</span>
          <span className="cardNew-content-fileSize">{sizeText}</span>
        </div>
        <Button
          onKeyDown={handleKeyDown}
          onClick={changeModeToUpload}
          type="button"
        >
          {t(removeButtonText)}
        </Button>
      </div>
    </div>
  );
};

ImagePreview.propTypes = {
  file: shape({
    name: string,
    url: string,
    size: oneOfType([string, number])
  }),
  changeModeToUpload: func,
  Thumbnail: elementType,
  removeButtonText: string
};

ImagePreview.defaultProps = {
  file: {},
  changeModeToUpload: f => f,
  Thumbnail: ({ url }) => <img src={url} alt="ImageUpload-preview_alternative" />, // eslint-disable-line react/prop-types
  removeButtonText: 'Remove Image'
};

export default ImagePreview;
