import React from 'react';
import Styled from 'styled-components';
import { renderable } from '../../../../../constants/propTypes';

const ScDialogRenderer = Styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.4);
  z-index: 1002;
`;

const DialogRenderer = ({ children }) => (<ScDialogRenderer>{children}</ScDialogRenderer>);
DialogRenderer.propTypes = { children: renderable.isRequired };

export default DialogRenderer;
