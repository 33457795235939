import { t } from '@jotforminc/translation';

import {
  IconEnvelopeClosedFilled, IconFormFilled, IconLinkDiagonal, IconPhoneFilled,
  IconProductPagesFilled, IconArrowUpRightFromSquareSm
} from '@jotforminc/svg-icons';
import { BUTTON_ROLE_TYPES } from '../buttonRoleTypes';
// import  { IconProductInboxFilled }  from '@jotforminc/svg-icons';

export const getButtonActionProps = isShareEnabled => [
  {
    value: BUTTON_ROLE_TYPES.LINK,
    title: t('Visit URL'),
    Icon: IconLinkDiagonal
  },
  {
    value: BUTTON_ROLE_TYPES.MAIL,
    title: t('Send email'),
    Icon: IconEnvelopeClosedFilled
  },
  {
    value: BUTTON_ROLE_TYPES.PHONE,
    title: t('Make call'),
    Icon: IconPhoneFilled
  },
  {
    value: BUTTON_ROLE_TYPES.NAVIGATION,
    title: t('Navigate to page'),
    Icon: IconProductPagesFilled
  },
  {
    value: BUTTON_ROLE_TYPES.FORM,
    title: t('Open form'),
    Icon: IconFormFilled
  },
  ...(isShareEnabled) ? [{
    value: BUTTON_ROLE_TYPES.SHARE,
    title: t('Share app'),
    Icon: IconArrowUpRightFromSquareSm
  }] : []
  // { // will be handled later
  //   value: 'SENTBOX',
  //   title: t('Show form submissions'),
  //   Icon: IconProductInboxFilled
  // }
];
