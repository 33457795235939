import React, { memo } from 'react';
import { LogoSquareDark, LogoStripeColor } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import * as Modal from '../Items';
import useGatewayFlows from '../Context/useGatewayFlows';
import { C_GATEWAYS } from '../Constants';

const PaymentApmPicker = () : JSX.Element | null => {
  const {
    currentlyApm,
    currentlyApmProperties,
    currentlyGateway,
    selectGateway
  } = useGatewayFlows();

  if (!currentlyApm || !currentlyApmProperties) {
    return null;
  }

  return (
    <>
      <Modal.Header
        title={currentlyApmProperties.name}
        leftIcon={<currentlyApmProperties.icon className='w-10 h-10' />}
        iconBackgroundColor='bg-gray-50'
        iconColor='color-white'
      />
      <Modal.Body>
        <p className='color-navy-700 text-xs font-normal mb-9'>
          {t('Our Apple Pay and Google Pay integration is powered by Square and Stripe Checkout. '
          + 'Credit card will also be offered as a payment option in case the user is on a device or browser that doesn\'t support Apple Pay or Google Pay.')}
        </p>
        <h2 className='color-navy-700 text-md font-medium line-height-xs mb-4'>{t('Select a gateway')}</h2>
        <div className='grid cols-2 gap-3'>
          {currentlyApmProperties.childTypes?.map(type => {
            return C_GATEWAYS.filter(data => data.type === type).map(gateway => {
              return (
                <button
                  onClick={() => selectGateway(gateway.type, currentlyApmProperties)}
                  type='button'
                  key={gateway.type}
                  className={`w-full h-auto radius border hover:border-blue-500 ${currentlyGateway === gateway.type ? 'border-blue-500 bg-blue-100' : 'border-navy-100'}`}
                >
                  <div className="w-full h-full p-4">
                    { gateway.type === 'control_square' && <LogoSquareDark className='w-32 h-20 sm:w-36' /> }
                    { gateway.type === 'control_stripeCheckout' && <LogoStripeColor className='w-28 h-20 sm:w-32' /> }
                  </div>
                </button>
              );
            });
          })}
        </div>
      </Modal.Body>
      <Modal.Footer />
    </>
  );
};

export default memo(PaymentApmPicker);
