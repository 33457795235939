import isUndefined from 'lodash/isUndefined';
import { DATA_SOURCE_COLUMN_TYPES, ITEM_TYPES } from '../../../constants/itemTypes';

export const strToDsFormat = str => {
  if (!str) {
    return '';
  }
  return `{${str}}`;
};

export const detailsPageDefaultFuncsMap = {
  [DATA_SOURCE_COLUMN_TYPES.TEXT]: ({ text, qid, ...rest }) => ({
    title: text,
    cardLayout: 'horizontal',
    description: `{${qid}}`,
    itemBgColor: '#ffffff',
    itemBorderColor: '#d8dae9',
    itemFontColor: '#2c3345',
    start: {
      type: 'none',
      data: null
    },
    end: {
      type: 'none',
      data: null
    },
    ...rest,
    type: 'CARD_ITEM'
  }),

  [DATA_SOURCE_COLUMN_TYPES.IMAGE]: ({ text, qid, ...rest }) => ({
    imageURL: `{${qid}}`,
    itemTextAlignment: 'center',
    shrink: 'No',
    ...rest,
    type: 'IMAGE'
  }),

  [ITEM_TYPES.HEADING]: ({ text, qid, ...rest }) => ({
    title: `{${qid}}`,
    description: '',
    headingSize: 'default',
    itemTextAlignment: 'left',
    shrink: 'No',
    ...rest,
    type: 'HEADING'
  })
};

// {
//   "start": {
//       "type": "image",
//       "data": {
//           "url": "https://www.jotform.com/uploads/erencam/form_files/Blank-Avatar.6666c07f7603c4.07905215.png"
//       }
//   },
//   "version": 2
// }

const divideColumnsByType = columns => {
  return columns.reduce((prev, column, order) => {
    switch (column.type) {
      case DATA_SOURCE_COLUMN_TYPES.IMAGE:
        return { ...prev, images: [...prev.images, { ...column, order }] };
      case DATA_SOURCE_COLUMN_TYPES.TEXT:
        return { ...prev, texts: [...prev.texts, { ...column, order }] };
      default:
        return prev;
    }
  }, { images: [], texts: [] });
};

export const generateDetailDefaultElements = ({ columns, pageID }) => {
  const { images, texts } = divideColumnsByType(columns);
  const orderedColumns = [...images, ...texts].toSorted((({ order: o1 }, { order: o2 }) => o1 - o2));
  const headingOrder = texts?.[0].order;

  if (!isUndefined(headingOrder)) {
    orderedColumns[headingOrder] = { ...orderedColumns[headingOrder], type: ITEM_TYPES.HEADING };
  }

  return orderedColumns.map(({ order, ...col }) => detailsPageDefaultFuncsMap[col.type]?.({ ...col, page: pageID }));
};

export const generateNewPropsForPresentationItem = columns => {
  const { images, texts } = divideColumnsByType(columns);
  const [titleCol, descriptionCol] = texts;
  const [imageURLCol] = images;

  return {
    ...(titleCol ? { title: strToDsFormat(titleCol.qid) } : {}),
    ...(descriptionCol ? { description: strToDsFormat(descriptionCol.qid) } : {}),
    ...(imageURLCol ? { start: { type: 'image', data: { url: strToDsFormat(imageURLCol.qid) } } } : {})
  };
};
