import { t } from '@jotforminc/translation';
import { BUTTON_ROLE_TYPES } from '../buttonRoleTypes';
import ButtonFormSelection from '../components/ButtonFormSelection';
import ButtonLinkInput from '../components/ButtonLinkInput';
import ButtonPageSelection from '../components/ButtonPageSelection';
import ButtonPhoneInput from '../components/ButtonPhoneInput';
import ButtonMailInput from '../components/ButtonMailInput';
import ButtonShareSelection from '../components/ButtonShareSelection';

export const ROLE_COMPONENT_PROPS = {
  [BUTTON_ROLE_TYPES.LINK]: {
    title: t('Link'),
    RoleComponent: ButtonLinkInput,
    componentProps: {
      placeholder: t('Enter a link'),
      defaultValue: 'https://'
    }
  },
  [BUTTON_ROLE_TYPES.MAIL]: {
    title: t('Recipient'),
    RoleComponent: ButtonMailInput,
    componentProps: {
      placeholder: t('Enter an email')
    }
  },
  [BUTTON_ROLE_TYPES.PHONE]: {
    title: t('Phone Number'),
    RoleComponent: ButtonPhoneInput,
    componentProps: {
      placeholder: '(555) - 555 555'
    }
  },
  [BUTTON_ROLE_TYPES.FORM]: {
    title: t('Form'),
    RoleComponent: ButtonFormSelection
  },
  [BUTTON_ROLE_TYPES.NAVIGATION]: {
    title: t('Page'),
    RoleComponent: ButtonPageSelection
  },
  [BUTTON_ROLE_TYPES.SENTBOX]: {
    title: t('Form'),
    RoleComponent: ButtonFormSelection
  },
  [BUTTON_ROLE_TYPES.SHARE]: {
    title: 'Social Media Share Message',
    RoleComponent: ButtonShareSelection
  }
};
