import React from 'react';
import { FormControl, InputText } from '@jotforminc/magnet';

/** types */
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import type { UTILS_GET_GATEWAY_PROP_REFERENCES_RETURN_DATA } from '../../../types';

/** context */
import { useGatewayConnection } from '../../../context';

/** utils */
import { maskCredentialData } from '../../../utils/functions/maskCredentialData';

/** components */
import { ToggleOnOff } from './toggleOnOff';

export type RENDER_CREDENTIAL_INPUTS_TYPES = {
  isConnected: boolean;
  connectionProps: UTILS_GET_GATEWAY_PROP_REFERENCES_RETURN_DATA[];
};

/**
 * Renders input fields for gateway connection credentials.
 *
 * This component dynamically generates input fields based on the provided connection properties.
 * It handles both text inputs and toggle switches, and manages their states and error handling.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.isConnected - Indicates if the gateway is currently connected
 * @param {UTILS_GET_GATEWAY_PROP_REFERENCES_RETURN_DATA[]} props.connectionProps - Array of connection property data
 * @returns {React.ReactElement | null} The rendered input fields or null if no connection props are provided
 */
export const RenderCredentialInputs = ({
  isConnected = false,
  connectionProps
}: RENDER_CREDENTIAL_INPUTS_TYPES): any => {
  const {
    connectionErrorInputErrors,
    connectionInputValues,
    changeConnectionInputValue,
    connectionErrorTypes,
    removeInputError
  } = useGatewayConnection();

  if (!connectionProps) {
    return null;
  }

  const countElement = connectionProps.length || 0;
  const isHasToggle = connectionProps.find(prop => prop.props.type === 'toggle') || false;

  return connectionProps.map((data, index) => {
    const { name, props } = data;
    const {
      isRequired, inputLabels, isDependentProp, dependentProp, toggleProps
    } = props;

    const isLastItem = index === countElement - 1;
    const isEnableColunm = countElement > 1;
    const isRemainderColunm = countElement % 2 !== 0;

    const isToggleWithGridColSpan = isHasToggle && (countElement - 1) % 2 !== 0 && isLastItem;
    const remainderStyle = !isEnableColunm || (isEnableColunm && isRemainderColunm && isLastItem) || isToggleWithGridColSpan ? { gridColumn: 'span 2 / auto' } : {};

    const defaultInputValue = connectionInputValues[name as keyof typeof connectionInputValues] || '';
    const inputType = props.type && props.type === 'toggle' ? 'toggle' : 'input';

    if (isDependentProp && dependentProp) {
      const { propName, propValue } = dependentProp;
      if (connectionInputValues[propName as keyof typeof connectionInputValues] !== propValue) {
        return null;
      }
    }

    switch (inputType) {
      case 'input':
        const transformedInputValue = defaultInputValue && isConnected ? maskCredentialData(defaultInputValue) : defaultInputValue;

        return (
          <div key={name} className='w-full' style={remainderStyle}>
            <FormControl
              id={name}
              disabled={isConnected}
              colorStyle={connectionErrorInputErrors[name] ? 'error' : undefined}
            >
              <label htmlFor={name} className='mb-2 flex flex-col max-h-11'>
                <span className="font-medium text-md tracking-md color-navy-700 line-height-xl">
                  {inputLabels?.label}
                  {' '}
                  {isRequired === 'Yes' && (<span className='color-red-400'>*</span>)}
                </span>
                {inputLabels && inputLabels.description && (
                  <span className="color-navy-300 text-sm tracking-lg font-normal line-height-md">{inputLabels.description}</span>
                )}
              </label>
              <InputText
                id={name}
                className='fs-mask'
                required={isRequired === 'Yes'}
                showLockIconInReadOnly={false}
                autoComplete="off"
                placeholder={inputLabels?.placeholder || undefined}
                value={transformedInputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeConnectionInputValue({
                    [name as PAYMENT_QUESTION_PROP_NAMES]: e.target.value || ''
                  });
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (connectionErrorTypes?.includes('missingRequiredFields') && connectionErrorInputErrors[name] && e.target.value !== '') {
                    removeInputError(name);
                  }
                }}
                data-test-id={name}
              />
            </FormControl>
          </div>
        );
      case 'toggle':
        if (!toggleProps) {
          return null;
        }

        const { defaultValue, enabled, disabled } = toggleProps;

        return (
          <div key={name} className='w-full' style={{ gridColumn: 'span 2 / auto' }}>
            <div className='flex flex-row justify-between items-center'>
              <div className="w-3/4 flex flex-col">
                <span className="font-medium text-md tracking-md color-navy-700 line-height-xl">
                  {inputLabels?.label}
                </span>
                <span className="text-sm color-navy-300">
                  {inputLabels?.description}
                  {inputLabels?.learnMoreLink && (
                    <a href={inputLabels?.learnMoreLink} target='_blank' className='ml-1 underline color-blue-500 font-bold'>Learn more.</a>
                  )}
                </span>
              </div>
              <ToggleOnOff
                defaultSelectedValue={defaultInputValue === '' ? defaultValue : defaultInputValue}
                enabledValue={enabled.value}
                inputName={name}
                values={[
                  { value: enabled.value, text: enabled.text },
                  { value: disabled.value, text: disabled.text }
                ]}
                isLocked={isConnected}
                onChangeValue={(value: string) => {
                  changeConnectionInputValue({
                    [name as PAYMENT_QUESTION_PROP_NAMES]: value || ''
                  });
                }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  });
};
