import {
  call, put, select, takeEvery
} from 'redux-saga/effects';
import { ABTestManager, ActionManager } from '@jotforminc/abtest-manager';
import { StorageHelper } from '@jotforminc/storage-helper';
import range from 'lodash/range';
import { navigate } from '@reach/router';
import SELECTORS from '../selectors';
import * as ACTION_TYPES from '../actionTypes';
import * as ACTION_CREATORS from '../actionCreators';
import {
  APP_MODES, UI_PROPS, ADD_ELELEMT_PULSE_EFFECT_LC_ST_KEY
} from '../../constants';
import { isYes } from '../../utils';
import { generateAppURL } from '../../utils/navigation';
import { RightPanelModes } from '../../modules/Builder/components/HomePage/constants';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { MODALS } from '../../constants/modals';
import { abTestActionLoggerSingleton, AB_TEST_NAMES } from '../../utils/AbTestActionLoggerSingleton';
import { getHasAbTestParam, getIsModalSeen } from '../../modules/Builder/components/Modals/AppNameIconSettingsModal/seenManager';

function* closeUIPanelsIfOpen() {
  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);
  const isLeftPanelOpen = yield select(SELECTORS.isLeftPanelOpenSelector);
  if (isRightPanelOpen) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(false));
  }
  if (isLeftPanelOpen) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
  }
}

function* handleAppLocation() {
  const pathName = yield select(SELECTORS.getAppLocation);
  const appID = yield select(SELECTORS.getAppID);

  if (!(pathName.includes('/sentbox'))) { return; } // return if path is no sentbox

  const {
    loginable, assigneeProtected, ssoProtected, organizationAccess, requireLogin
  } = yield select(SELECTORS.getAppInfoWithDefaults);

  let isAppLoginable = isYes(loginable);
  if (window.JOTFORM_ENV === 'ENTERPRISE') {
    const isAppPrivate = isYes(assigneeProtected) || isYes(ssoProtected) || !!organizationAccess;
    const isAppRequireLogin = isYes(requireLogin);
    isAppLoginable = isAppPrivate || isAppRequireLogin;
  }

  // Dont show submissions if app is not Loginable
  if (!isAppLoginable) {
    return navigate(generateAppURL({ appID }));
  }
}

export function* watchUIupdates(props) {
  const { type, payload } = props;
  const appMode = yield select(SELECTORS.getAppModeSelector);

  if (appMode === APP_MODES.public) {
    switch (type) {
      case ACTION_TYPES.SET_APP_LOCATION:
        yield handleAppLocation();
        return;
      default:
        return;
    }
  }

  if (type === ACTION_TYPES.SELECT_PORTAL_ITEM && payload) {
    const pageID = yield select(SELECTORS.getPageIDByItemID(payload));
    yield put(ACTION_CREATORS.updateLastInteractedPageIDAction(pageID || ''));
  } else if (type === ACTION_TYPES.SELECT_PAGES && payload) {
    yield put(ACTION_CREATORS.updateLastInteractedPageIDAction(payload));
    yield put(ACTION_CREATORS.setRightPanelModeAction(RightPanelModes.PAGE_SETTINGS));
  }

  const isAppCoverCropperActive = yield select(SELECTORS.getAppCoverCropState);
  const isAppHeaderCropperActive = yield select(SELECTORS.getAppHeaderCropState);

  if ((/.*(APP_COVER|APP_HEADER)/.test(type)) && payload) {
    yield closeUIPanelsIfOpen();
  }

  if (!isAppCoverCropperActive && !isAppHeaderCropperActive) { return; }

  if (/(.*TOGGLE)(.*PANEL)/.test(type) && !payload) { return; }

  if (/(.*SET_STAGE_CLICK)/.test(type)) { return; }

  if (isAppHeaderCropperActive && !(/.*(APP_HEADER)/.test(type)) && !(/.*(APP_HEADER)/.test(payload))) yield put({ type: ACTION_TYPES.SET_APP_HEADER_CROP_MODE, payload: false });
  if (isAppCoverCropperActive && !(/.*(APP_COVER|APP_HEADER|API_REQUEST)/.test(type))) yield put({ type: ACTION_TYPES.SET_APP_COVER_CROP_MODE, payload: false });
}

function* watchRightPanelActions({ payload: isRightPanelOpen }) {
  const shouldSqueeze = yield select(SELECTORS.shouldSqueezeSelector);
  const isLeftPanelOpen = yield select(SELECTORS.isLeftPanelOpenSelector);
  const isLivePreviewOn = yield select(SELECTORS.getIsLivePreviewOn);
  if (shouldSqueeze && isRightPanelOpen && isLeftPanelOpen) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
  }

  if (isLivePreviewOn) {
    const shouldOpen = shouldSqueeze ? !isRightPanelOpen && !isLeftPanelOpen : !isRightPanelOpen;
    yield put(ACTION_CREATORS.togglePreviewPanelAction(shouldOpen));
  }

  const isShoppingApp = yield select(SELECTORS.getIsShoppingApp);
  const activeProductInfo = yield select(SELECTORS.getActiveProduct);
  const hasActiveProduct = !!activeProductInfo?.productID;
  if (isFeatureEnabled(FEATURE_NAMES.ProductList) && isShoppingApp && !isRightPanelOpen && hasActiveProduct) {
    yield put(ACTION_CREATORS.setActiveProduct('', ''));
  }
}

function* watchLeftPanelActions({ payload: isLeftPanelOpen }) {
  const shouldSqueeze = yield select(SELECTORS.shouldSqueezeSelector);
  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);
  const isLivePreviewOn = yield select(SELECTORS.getIsLivePreviewOn);
  if (shouldSqueeze && isRightPanelOpen && isLeftPanelOpen) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(false));
  }

  if (isLivePreviewOn && shouldSqueeze) {
    yield put(ACTION_CREATORS.togglePreviewPanelAction(!isRightPanelOpen && !isLeftPanelOpen));
  }

  if (isLeftPanelOpen) {
    yield put(ACTION_CREATORS.trackEventAction({ action: 'leftPanelOpened' }));
  }
}

function* watchLivePreviewActions({ payload: isLivePreviewOn }) {
  const shouldSqueeze = yield select(SELECTORS.shouldSqueezeSelector);

  if (isLivePreviewOn) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(false));
    if (shouldSqueeze) {
      yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
    }
  }
}

export function* watchUIPanelsChanges() {
  yield takeEvery(ACTION_TYPES.TOGGLE_RIGHT_PANEL, watchRightPanelActions);
  yield takeEvery(ACTION_TYPES.TOGGLE_LEFT_PANEL, watchLeftPanelActions);
  yield takeEvery(ACTION_TYPES.TOGGLE_LIVE_PREVIEW, watchLivePreviewActions);
}

export function* watchWindowSqueeze({ payload: shouldSqueeze }) {
  const isLeftPanelOpen = yield select(SELECTORS.isLeftPanelOpenSelector);
  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);
  if (shouldSqueeze && isLeftPanelOpen && isRightPanelOpen) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
  }
}

export function* keepClosedUIPanels() {
  // cases left and right panel should be closed
  // TODO: handle ui panels in another way
  yield takeEvery([ACTION_TYPES.APP_PREVIEW_STATUS], closeUIPanelsIfOpen);
}

export function* watchMultipleSelection({ payload: { withRange, withMouse } }) {
  const selectedItems = yield select(SELECTORS.getSelectedMultipleItems);
  const appItems = yield select(SELECTORS.getPortalItems);
  const isMobileMultipleSelectMode = yield select(SELECTORS.getMobileMultipleSelectionMode);

  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);

  const isThereAnySelectedItem = selectedItems.length > 0;

  // close right panel when any items are not selected
  if (!isThereAnySelectedItem && isRightPanelOpen) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(false));
  }

  if (!isThereAnySelectedItem && isMobileMultipleSelectMode) {
    yield put(ACTION_CREATORS.toggleMultipleSelectionModeAction(false));
  }

  if (withRange && selectedItems.length >= 2 && !withMouse) {
    const rangeEnd = selectedItems[selectedItems.length - 1];
    const rangeStart = selectedItems[selectedItems.length - 2];

    const rangePortalOrders = appItems.filter(item => [rangeEnd, rangeStart].includes(item?.id)).map(el => el?.portalOrder);

    const portalOrderRange = range(Math.min(...rangePortalOrders), Math.max(...rangePortalOrders) + 1);

    const willBeSelectedAppItems = appItems.filter(item => portalOrderRange.includes(parseInt(item?.portalOrder, 10))).map(el => el?.id);

    //! prevent selection of app header on multiple selection mode
    // eslint-disable-next-line max-len
    yield put(ACTION_CREATORS.selectMultipleItemAction({ selection: Array.from(new Set([...selectedItems, ...willBeSelectedAppItems])), withMouse: true }));
  }
}

export function* watchSelectAllItems() {
  const allItems = yield select(SELECTORS.getPortalItems);
  const itemIDs = allItems.map(i => i.id);
  yield put(ACTION_CREATORS.selectMultipleItemAction({ selection: itemIDs, withMouse: true }));
}

// A/B Test: ctAppNameIconModal
// note: this a/b test distributed from backend with the name ctAppNameIconModalTwo
export function* initAppNameIconModalAbTest() {
  const user = yield select(SELECTORS.getUser);
  const appID = yield select(SELECTORS.getAppID);
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  const isModalAvailable = yield select(SELECTORS.getAppNameIconSettingsModalAvailable);
  const hasUrlParam = getHasAbTestParam();
  const actionManager = new ActionManager({ user, projectName: AB_TEST_NAMES.CT_APP_NAME_ICON_MODAL_TWO });

  abTestActionLoggerSingleton[AB_TEST_NAMES.CT_APP_NAME_ICON_MODAL_TWO] = actionManager.registerJotformAction;

  const showModal = (isModalAvailable || hasUrlParam) && isBuilder && !getIsModalSeen(appID);

  if (showModal) {
    yield put(ACTION_CREATORS.showGenericModalAction({ name: MODALS.APP_NAME_ICON_SETTING_MODAL }));
  }
}

export function* initAppElementPanelAbTest() {
  const apps = yield select(SELECTORS.getAllApps);
  const user = yield select(SELECTORS.getUser);
  const firstTimeAppCreation = apps.length === 1;

  const abTestManager = new ABTestManager({
    user,
    isTestEnabled: true,
    testName: AB_TEST_NAMES.ELEMENT_PANEL_VISIBILITY,
    controlVariantCode: '17121',
    testVariantCode: '17131',
    urlParam: 'aepdfv',
    customUserChecks: {
      firstTimeAppCreation
    }
    // debugMode: {
    //   logTestState: true,
    //   forceTestVariant: true
    // }
  });

  const isTestVariant = yield call(abTestManager.isTestVariant.bind(abTestManager));
  abTestActionLoggerSingleton[AB_TEST_NAMES.ELEMENT_PANEL_VISIBILITY] = abTestManager.registerABTestAction;

  if (isTestVariant) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(true));
  }
}

export function* checkAddElementPulseVisible() {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!isBuilder) return;
  const pulseStorage = StorageHelper.getLocalStorageItem({ key: ADD_ELELEMT_PULSE_EFFECT_LC_ST_KEY });
  if (!pulseStorage) {
    yield put(ACTION_CREATORS.setUIProp(UI_PROPS.addElementButtonPulseVisible, true));
  }
}
