import React from 'react';
import { arrayOf, object, func } from 'prop-types';
import { t } from '@jotforminc/translation';

import { IconCopyLine } from '@jotforminc/svg-icons';
import { ScRelatedCategories } from './ScRelatedCategories';
import { getTemplateCountText } from '../utils';

const RelatedCategories = ({
  selectedCategory, categories, handleSelectCategory, setActiveParentCategory
}) => {
  const getRelatedCategories = () => {
    const { relatedCategories } = selectedCategory;
    if (!relatedCategories || relatedCategories === '') return [];
    const relatedCategoryObjects = [];
    const splitted = relatedCategories.split(',');
    splitted.forEach(categoryIterator => {
      if (categoryIterator !== '') {
        const category = categories.find(cat => cat._id === categoryIterator);
        if (category) {
          relatedCategoryObjects.push(category);
        }
      }
    });
    return relatedCategoryObjects;
  };

  if (selectedCategory === null) return null;
  const relateds = getRelatedCategories();
  if (relateds.length === 0) return null;

  return (
    <ScRelatedCategories>
      <h3>{t('More categories like this')}</h3>
      <ul className="similar-category">
        {
          relateds.filter(rel => rel.total !== undefined).map(related => (
            <li
              key={related._id}
              className="similar-category-card-list"
              onClick={() => {
                setActiveParentCategory();
                handleSelectCategory({ category: related });
              }}
            >
              <div className="similar-category-card">
                <p>{t(related.name)}</p>
                <span className="count">
                  <IconCopyLine width="16" height="16" />
                  {getTemplateCountText(related.total)}
                </span>
              </div>
            </li>
          ))
        }
      </ul>
    </ScRelatedCategories>
  );
};

RelatedCategories.defaultProps = {
  selectedCategory: null
};

RelatedCategories.propTypes = {
  selectedCategory: object,
  handleSelectCategory: func.isRequired,
  categories: arrayOf(object).isRequired,
  setActiveParentCategory: func.isRequired
};

export default RelatedCategories;
