import React from 'react';
import { string, object } from 'prop-types';
import Styled from 'styled-components';
import { getIconInformationForPortalItem } from '@jotforminc/search-filter';
import { SVGIcon } from '@jotforminc/icon-selector';
import { ICON_SIZES } from './constants';

const ScIconRenderer = Styled.div`
  margin: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 4px;

  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }

  * > img {
    width: 100%;
    height: 100%;
    border-radius: 4px
    object-fit: contain;
  }

  * > svg.injected-svg {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 5px;
  }

  ${({ iconSize }) => (iconSize === ICON_SIZES.SMALL ? `
    margin: 0 8px 0 0;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 4px;
  ` : '')}

`;

const IconRenderer = ({
  titleForAlt,
  icon,
  iconSize: initialIconSize
}) => {
  const {
    isSvgIcon,
    url,
    iconColor,
    finalUrl,
    alt,
    svgStyleProps,
    iconSize
  } = getIconInformationForPortalItem(titleForAlt, icon, initialIconSize);
  return (
    <ScIconRenderer {...svgStyleProps} iconSize={iconSize} className="itemLogo">
      {isSvgIcon
        ? (
          <SVGIcon
            url={url}
            iconColor={iconColor}
          />
        )
        : (
          <img
            src={finalUrl}
            title={alt}
            alt={alt}
            onError={e => { e.target.onerror = null; e.target.src = 'https://cdn.jotfor.ms/assets/img/portal/pickerItemFallbackImg.png'; }}
          />
        )}
    </ScIconRenderer>
  );
};

IconRenderer.propTypes = {
  titleForAlt: string,
  icon: object,
  iconSize: string
};

IconRenderer.defaultProps = {
  titleForAlt: '',
  icon: {},
  iconSize: ICON_SIZES.MEDIUM
};

export default IconRenderer;
