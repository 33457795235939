import React from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';

import { badgeCVA, badgeIconCVA, badgeTextCVA } from './badge.cva';
import { BadgeProps, badgeDefaultProps } from './badge.types';

export const Badge = (props: BadgeProps):JSX.Element => {
  const {
    className,
    children,
    colorStyle,
    rounded,
    size,
    truncate,
    variant,
    icon: BadgeIcon,
    ...rest
  } = props;

  const hasChildren = !!children;
  const hasIcon = !!BadgeIcon;

  const badgeClassName = cx(className, badgeCVA({
    colorStyle,
    rounded,
    size,
    variant,
    truncate,
    hasChildren,
    hasIcon
  }));

  return (
    <span className={badgeClassName} {...rest}>
      {hasIcon && <BadgeIcon className={badgeIconCVA({ size })} />}
      {hasChildren && <span className={badgeTextCVA({ truncate })}>{children}</span>}
    </span>
  );
};

Badge.defaultProps = badgeDefaultProps;
