/* eslint-disable no-nested-ternary */
import React, {
  FunctionComponent, MouseEvent, useState, useMemo
} from 'react';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import {
  IconEyeFilled, IconPencilFilled, IconTrashFilled
} from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import CheckoutFormVisibilitySettingsForm from '../../CheckoutFormVisibilitySettingsForm';
import {
  IActionButtons, IGatewaySettings, IQuestion, TPaymentType
} from '../../../types/common';
import FormSettingsEditorView from './FormSettingsEditorView';
import FormFieldsEditorView from './FormFieldsEditorView';

type CheckoutFormVisibilitySettingsPanelType = {
  onPreviewClick: (e: MouseEvent) => void,
  paymentType: TPaymentType,
  activeGatewaySettings: IGatewaySettings,
  checkoutFormQuestions: IQuestion[],
  onCheckoutFormQuestionUpdate: (id: number | string, prop: {[key: string]: string}) => void,
  isContactInformationEnabled?: boolean,
  onAddElement: (fieldType: string) => void,
  onSortEnd: (oldIndex: number, newIndex: number) => void
  onRemoveElement: (qid: string | null) => void,
  gatewayRequiredQuestionIds: string[],
  onUseFastCheckoutChange: (val: 'Yes' | 'No') => void,
  useFastCheckout: 'Yes' | 'No',
  isReusableConnectionEnabled: boolean
}

const CheckoutFormVisibilitySettingsPanel: FunctionComponent<CheckoutFormVisibilitySettingsPanelType> = ({
  onPreviewClick,
  paymentType,
  activeGatewaySettings,
  checkoutFormQuestions,
  onCheckoutFormQuestionUpdate,
  isContactInformationEnabled,
  onRemoveElement,
  onAddElement,
  onSortEnd,
  gatewayRequiredQuestionIds,
  onUseFastCheckoutChange,
  useFastCheckout,
  isReusableConnectionEnabled
}) => {
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  const editingItem = Array.isArray(checkoutFormQuestions) && checkoutFormQuestions.find(({ qid }) => qid === editingItemId);

  const actionButtons: IActionButtons = useMemo(() => [
    {
      icon: IconPencilFilled,
      title: t('EDIT'),
      type: 'edit',
      action: qid => setEditingItemId(qid)
    },
    {
      icon: IconTrashFilled,
      title: t('DELETE'),
      type: 'delete',
      action: onRemoveElement,
      colorStyle: 'error'
    }], []);

  return (
    !isContactInformationEnabled ? (
      <div className={classNames('checkoutFormQuestionsVisibilitySettings')}>
        <div className="block mb-0 p-4 font-medium color-white">
          <div className={classNames('previewWizard-wrapper')}>
            <div className="row-title">{t(`Contact Information${isContactInformationEnabled ? ' Page' : ''}`)}</div>
            <div className="row-description">
              {t('This section will be shown during the checkout process')}
            </div>
            <div className="previewField">
              <div className="previewField-hover">
                <Button
                  startIcon={IconEyeFilled}
                  onClick={onPreviewClick}
                >
                  {t('View Page')}
                </Button>
              </div>
              <img
                src="https://cdn.jotfor.ms/assets/img/payments/panel_checkout_preview.png"
                alt="Checkout preview"
                className="previewImg"
              />
            </div>
          </div>
        </div>
        <div>
          <hr className="lineHr" />
          <div className="block mb-0 p-4 font-medium color-white">
            <div className="row-title">
              {t('Contact Information Fields')}
            </div>
            <div className="row-description">
              {t('Select which fields you\'d like to add to the app')}
            </div>
            <CheckoutFormVisibilitySettingsForm
              paymentType={paymentType}
              activeGatewaySettings={activeGatewaySettings}
              checkoutFormQuestions={checkoutFormQuestions}
              onCheckoutFormQuestionUpdate={onCheckoutFormQuestionUpdate}
            />
          </div>
        </div>
      </div>
    ) : (
      !editingItem ? (
        <FormSettingsEditorView
          onPreviewClick={onPreviewClick}
          checkoutFormQuestions={checkoutFormQuestions}
          onAddElement={onAddElement}
          onSortEnd={onSortEnd}
          actionButtons={actionButtons}
          gatewayRequiredQuestionIds={gatewayRequiredQuestionIds}
          onUseFastCheckoutChange={onUseFastCheckoutChange}
          useFastCheckout={useFastCheckout}
          isReusableConnectionEnabled={isReusableConnectionEnabled}
          paymentType={paymentType}
        />
      ) : (
        <FormFieldsEditorView
          editingItem={editingItem}
          setEditingItemId={setEditingItemId}
          onCheckoutFormQuestionUpdate={onCheckoutFormQuestionUpdate}
          gatewayRequiredQuestionIds={gatewayRequiredQuestionIds}
        />
      )
    )
  );
};

CheckoutFormVisibilitySettingsPanel.defaultProps = {
  isContactInformationEnabled: false
};

export default CheckoutFormVisibilitySettingsPanel;
