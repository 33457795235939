import type { GATEWAY_SUPPORTED_RESOURCES } from '@jotforminc/payment-constants';
import { requestLayer } from './requestLayer';

/**
 * Attaches a connection resource to a payment gateway.
 *
 * @param connectionId - The ID of the connection.
 * @param resourceType - The type of the resource.
 * @param resourceId - The ID of the resource.
 * @returns A Promise that resolves to the result of the API call.
 */
export const attachConnectionResource = (connectionId: string, resourceType: GATEWAY_SUPPORTED_RESOURCES, resourceId: string): Promise<any> => {
  return requestLayer.post(`/API/payment/attach-connection/${resourceType}/${resourceId}/${connectionId}`);
};
