/* eslint-disable max-len */
import React from 'react';

const MobileBorder = () => {
  return (
    <svg
      className="overflow-hidden max-w-100 absolute left-0 right-0 mx-auto"
      viewBox="0 0 405 844"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M164.062 23.6924H241.143C250.068 23.6924 257.371 30.9948 257.371 39.92C257.371 48.8452 250.068 56.1477 241.143 56.1477H164.062C155.136 56.1477 147.834 48.8452 147.834 39.92C147.834 30.9948 155.136 23.6924 164.062 23.6924Z"
        fill="black"
      />
      <path
        d="M343.053 0H61.3405C27.4247 0 0 27.4247 0 61.3405V782.659C0 816.575 27.4247 844 61.3405 844H343.215C377.131 844 404.555 816.575 404.555 782.659V61.3405C404.555 27.4247 377.131 0 343.215 0H343.053ZM390.437 782.497C390.437 808.624 369.341 829.72 343.215 829.72H61.3405C35.214 829.72 14.1181 808.624 14.1181 782.497V61.3405C14.1181 35.214 35.214 14.1181 61.3405 14.1181H343.215C369.341 14.1181 390.437 35.214 390.437 61.3405V782.659V782.497Z"
        fill="#C6C9E1"
      />
    </svg>

  );
};

// MobileBorder.propTypes = { splashBgColor: string.isRequired };

export default MobileBorder;
