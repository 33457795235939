import { useMemo } from 'react';
import { useLocation } from '@reach/router';
import { getAppPath, getInitialRoutePath } from '@jotforminc/router-bridge';
import { trimEnd } from 'lodash/string';
import { isPlatformEnv } from '@jotforminc/utils';
import { addForceMobileDeviceParam } from '../modules/PublicApp/utils';
import appConfig, { getBasePath } from '../constants/appConfig';
import { checkMobilePhone, shouldForceMobile, isAppSubdomain } from '../utils';
import { FORM_PRODUCTS } from '../constants';
import {
  SPECIAL_PAGES, DESTINATION_TYPES, BUILDER_TABS, DEFAULT_SUBTABS
} from '../constants/navigation';
import { RESOURCE_TYPES } from '../constants/itemTypes';
import * as NAVIGATION_CONSTANTS from '../constants/navigation';

/*
!!! Caution: searchParams are being mutated all the time !!!
*/

export const simplifySearchParams = searchParams => {
  // Simplify by reducing duplicate entries by taking last.
  // TODO any future conflicting values??

  const newSearchParams = new URLSearchParams();
  searchParams.forEach((val, key) => newSearchParams.set(key, val));
  return newSearchParams;
};

export const getQueryString = ({
  sessionID = '', token = '', saclID = '', forLogout = false
} = {}) => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  if (sessionID && token) {
    searchParams.set('session', sessionID);
    searchParams.set('stoken', token);
  } else if (saclID) {
    searchParams.set('draft', saclID);
  } else if (forLogout) {
    searchParams.delete('session');
    searchParams.delete('stoken');
    searchParams.delete('draft');
  }

  if (shouldForceMobile()) {
    addForceMobileDeviceParam(searchParams);
  }
  return simplifySearchParams(searchParams).toString();
};

export const getProductSegment = ({ productType, productID }) => {
  switch (productType) {
    case FORM_PRODUCTS.SENTBOX:
    case FORM_PRODUCTS.TABLES:
    case FORM_PRODUCTS.REPORT:
    case FORM_PRODUCTS.SIGN:
    case FORM_PRODUCTS.CONTINUE_DRAFT:
      return `/${productID}/${productType}`;
    default:
      return `/${productID}`;
  }
};

export const getScreenSegment = ({ screenType, itemID, productID }) => {
  switch (screenType) {
    case SPECIAL_PAGES.PRODUCT:
      return `/${screenType}/${itemID}/${productID}`;
    default:
      return `/${screenType}`;
  }
};

export const getPageSegment = ({
  productType, screenType, itemID, productID, pageID
}) => {
  const specialPagePaths = ['cart', 'checkout', 'previousOrders', 'previousDonations', 'product'];

  switch (true) {
    case specialPagePaths.includes(pageID):
      return `/${pageID}${(pageID === 'product') ? `/${itemID}/${productID}` : ''}`;
    case productType !== undefined:
      return getProductSegment({ productType, productID });
    case screenType !== undefined:
      return getScreenSegment({ screenType, itemID, productID });
    default:
      return (pageID && pageID !== 'homepage') ? `/page/${pageID}` : '';
  }
};

export const getAppIDSegment = ({ appID: _appID }) => {
  const appID = _appID || getInitialRoutePath().split('/')[1];
  return window?.isStandaloneWithSlug ? '' : `/${appID}`;
};

export const getOriginSegment = ({ absolutePath }) => {
  const { origin } = new URL(window.location.href);
  return absolutePath ? origin : '';
};

export const getBaseProjectSegment = () => {
  return appConfig.basePath === '/' ? '' : appConfig.basePath;
};

export const getBuilderSegment = ({ forBuilder }) => (forBuilder ? '/build' : '');

export const getLocationHash = () => window.location.hash;

export const generateAppURL = ({
  forLogout = false,
  absolutePath = true,
  forBuilder = false,
  appID: _appID,
  pageID,
  itemID,
  productID,
  productType,
  screenType,
  sessionID,
  token,
  saclID
} = {}) => {
  const originSegment = getOriginSegment({ absolutePath });
  const baseProjectSegment = getBaseProjectSegment();
  const builderSegment = getBuilderSegment({ forBuilder });
  const appIDSegment = getAppIDSegment({ appID: _appID });
  const pageSegment = getPageSegment({
    productType, screenType, itemID, productID, pageID
  });
  const queryString = getQueryString({
    sessionID, token, forLogout, saclID
  });
  const locationHash = getLocationHash();
  let url = `${originSegment}${baseProjectSegment}${builderSegment}${appIDSegment}${pageSegment}`;
  url = queryString ? `${url}?${queryString}` : url;
  return locationHash ? `${url}${locationHash}` : url;
};

const reportTypeMap = {
  cal: 'calendar',
  csv: 'csv',
  grid: 'grid',
  table: 'table',
  reports: 'report',
  report: 'report',
  rss: 'rss',
  excel: 'excel',
  _default: 'report'
};

export const generateResourceURL = (resourceType, resourceID, appID, reportType) => {
  const productSlugsMap = {
    [RESOURCE_TYPES.TABLE]: 'tables',
    [RESOURCE_TYPES.REPORT]: reportTypeMap[reportType] || reportTypeMap._default,
    [RESOURCE_TYPES.SENTBOX]: 'sentbox'
  };

  const url = new URL(`${window.location.origin}/${productSlugsMap[resourceType]}/${resourceID}?`);
  if (resourceType === RESOURCE_TYPES.SENTBOX) {
    url.searchParams.set('filters', `submittedVia:eq=${appID}`);
  }
  return url.toString();
};

export const getReportTypeFromResourceURL = resourceURL => {
  const slug = Object.values(reportTypeMap).find(type => resourceURL.includes(type));
  return slug === reportTypeMap.reports ? 'reports' : slug;
};

export const generateContinueWithDraftPath = (formID, sessionID, token, continueKey) => {
  // const searchParams = handleSearchParams(new URLSearchParams(window.location.search));
  const searchParams = new URLSearchParams();

  if (continueKey) {
    searchParams.set('draft', continueKey);
  } else if (sessionID && token) {
    searchParams.set('session', sessionID);
    searchParams.set('stoken', token);
  }

  // const queryString = simplifySearchParams(searchParams).toString();

  return `${formID}/continue?${searchParams.toString()}`;
};

const trimAppTemplatePreviewPath = pathName => {
  const appTemplatePreviewPrefix = '/app-templates/preview';
  if (pathName.includes(appTemplatePreviewPrefix)) {
    return pathName.replace(appTemplatePreviewPrefix, '');
  }
  return pathName;
};

export const useRouteSegments = () => {
  // A/B Test: ctAppNameIconModal
  let location = {};
  try {
    location = useLocation();
  } catch (e) {
    location = {};
  }
  const { pathname = '' } = location;

  const appPathname = trimAppTemplatePreviewPath(pathname);
  return useMemo(() => appPathname.split('/'), [appPathname]);
};

export const filterUselessPathSegments = (segmentList, appID = window?.appID) => {
  const appPathPartList = getAppPath().split('/');
  return segmentList.filter(n => n && [...appPathPartList, appID].indexOf(n) === -1);
};

export const sanitizePathSegments = pathname => {
  const appPathname = trimAppTemplatePreviewPath(pathname);
  const basePath = getBasePath();
  const baseSegmentPath = appPathname.replace(basePath, '');
  const segment = baseSegmentPath.split('/');
  return filterUselessPathSegments(segment);
};

export const usePathSegment = () => {
  const location = useLocation();
  const { pathname = '' } = location;
  return sanitizePathSegments(pathname);
};

// appID should be passed as parameter for IOS 12 and below because window.appID is not available.
export const useFormProductByPath = (appID = window?.appID) => {
  const slugSegments = (window?.isStandaloneWithSlug || window.location.host === 'app.jotform.com') ? usePathSegment() : filterUselessPathSegments(useRouteSegments(), appID);

  if (slugSegments.length === 1) return FORM_PRODUCTS.FORM;
  if (slugSegments.length === 2) {
    const productTypeForSlug = slugSegments[1];
    return [
      FORM_PRODUCTS.SENTBOX, FORM_PRODUCTS.CONTINUE_DRAFT,
      FORM_PRODUCTS.REPORT, FORM_PRODUCTS.TABLES, FORM_PRODUCTS.SIGN
    ].includes(productTypeForSlug) ? productTypeForSlug : '';
  }
  return false;
};

export const useIfMobileSettings = () => {
  const isMobilePhone = checkMobilePhone() || shouldForceMobile();
  const [, , , , page = '', subPage = ''] = useRouteSegments() || [];
  const isInSubpage = (page === 'settings' || page === 'publish') && subPage !== '';

  return isMobilePhone && isInSubpage;
};

export const isAppJotform = () => {
  return window.location.host === 'app.jotform.com';
};

export const getTrackTarget = ({ productType, productID }) => {
  switch (productType) {
    case FORM_PRODUCTS.FORM:
      return { productID, via: FORM_PRODUCTS.FORM };
    default:
      return { productID };
  }
};

export const getBaseAppPath = () => {
  const baseSegments = [];
  const { origin, pathname } = window.location;
  const appPathname = trimAppTemplatePreviewPath(pathname);
  const pathnameSegments = appPathname.split('/').slice(1);

  if (isPlatformEnv()) {
    baseSegments.push(`/${pathnameSegments.shift()}`, `/${pathnameSegments.shift()}`); // ex. /platform , /salesforce
  }

  if (!isAppSubdomain()) {
    if (pathnameSegments[0] !== 'app') {
      baseSegments.push(`/${pathnameSegments.shift()}`); // like /en
    }
    baseSegments.push(`/${pathnameSegments.shift()}`); // /app
  }

  if (window.isBuilder) {
    baseSegments.push(`/${pathnameSegments.shift()}`); // /build
  }

  if (window.isStandaloneWithSlug) { // /userSlug/appSlug
    baseSegments.push(`/${pathnameSegments.shift()}`);
    baseSegments.push(`/${pathnameSegments.shift()}`);
  } else { // appID
    baseSegments.push(`/${pathnameSegments.shift()}`);
  }

  return `${origin}${baseSegments.join('')}`;
};

export const getSpecialPageDestination = specialPage => {
  switch (specialPage) {
    case SPECIAL_PAGES.CART:
      return checkMobilePhone() ? DESTINATION_TYPES.SCREEN : DESTINATION_TYPES.PAGE;
    default:
      return DESTINATION_TYPES.SCREEN;
  }
};

export const getSegmentsInsidePortal = () => {
  const pathname = trimAppTemplatePreviewPath(window.location.pathname);
  let segments = pathname.split('/').slice(1); // get rid of empty element

  if (!isAppSubdomain()) { // contains /app
    segments.shift();
  }

  if (window.isBuilder) { // contains /build
    segments.shift();
  }

  if (window.isStandaloneWithSlug) { // contains /userSlug/appSlug
    segments = segments.slice(2);
  } else { // contains /appID
    segments = segments.slice(1);
  }

  return segments;
};

export const getRouteSegments = () => trimEnd(window.location.pathname, '/').split('/');

export const getBuilderRouteSegments = () => {
  const routeSegments = getRouteSegments();

  const buildSegmentIndex = routeSegments.findIndex(segment => segment === BUILDER_TABS.BUILD);

  return routeSegments.slice(buildSegmentIndex);
};

export const getCurrentTabFromURL = () => {
  const builderRouteSegments = getBuilderRouteSegments();

  return builderRouteSegments.length > 2 ? builderRouteSegments[2] : BUILDER_TABS.BUILD;
};

export const getCurrentSubTabFromURL = () => {
  // Empty element is not removed in order to comply with useRouteSegments.
  const builderRouteSegments = getBuilderRouteSegments();
  const currentTab = getCurrentTabFromURL();

  if (builderRouteSegments.length > 3) {
    return builderRouteSegments[3];
  }

  if (!checkMobilePhone()) {
    return DEFAULT_SUBTABS[currentTab];
  }

  return '';
};

export const getItemFromURL = () => {
  const segments = getSegmentsInsidePortal();
  const specialPagePaths = Object.values(SPECIAL_PAGES);

  switch (true) {
    case window.isBuilder:
      const tab = getCurrentTabFromURL();
      const subTab = getCurrentSubTabFromURL();
      return { itemType: DESTINATION_TYPES.BUILDER_PAGE, itemName: { tab, subTab } };
    case segments[0] === 'page':
      return { itemType: DESTINATION_TYPES.PAGE, itemName: segments[1] };
    case specialPagePaths.includes(segments[0]):
      return { itemType: DESTINATION_TYPES.SPECIAL_PAGE, itemName: segments[0] };
    case segments.length === 1: // formID
      return { itemType: DESTINATION_TYPES.PRODUCT, itemName: `${segments[0]}` };
    case segments.length === 2: // formID/productType
      return { itemType: DESTINATION_TYPES.PRODUCT, itemName: `${segments[0]}/${segments[1]}` };
    default:
      return { itemType: DESTINATION_TYPES.PAGE, itemName: '' };
  }
};

export const prepareSpecialPageNavigationProps = (destination, payload) => {
  switch (destination) {
    case DESTINATION_TYPES.SCREEN:
      return { screenType: payload.pageID, pageID: '', ...payload };
    default:
      return payload;
  }
};

export const getBackURL = () => {
  const { origin, pathname } = window.location;
  const segments = pathname.split('/');
  segments.pop();
  const newPathname = segments.join('/');
  const queryString = getQueryString();
  return `${origin}${newPathname}${queryString && `?${queryString}`}`;
};

export const generateBuilderAppURL = ({ tab, subTab, includeQs = false }) => {
  const basePath = getBaseAppPath();
  const queryString = getQueryString();
  let path;
  switch (true) {
    case tab === NAVIGATION_CONSTANTS.BUILDER_TABS.BUILD:
      path = basePath;
      break;
    case !!tab && !!subTab:
      path = `${basePath}/${tab}/${subTab}`;
      break;
    case !!tab:
      path = `${basePath}/${tab}`;
      break;
    default:
      path = basePath;
      break;
  }
  return includeQs ? `${path}${queryString && `?${queryString}`}` : path;
};
