import type {
  FORM_TYPES,
  T_CONDITIONS,
  T_SINGLE_CONDITION,
  FORM_QUESTION_TYPES,
  GATEWAY_QUESTION_PROPERTIES
} from '@jotforminc/payment-constants';
import { isPlainObject } from './isPlainObject';
import { calculateOperation } from './calculateOperation';
/**
 * Checks if resource property required
 * @returns {boolean}
 */

export const checkResourcePropertyCondition = (
  conditionObj: Exclude<T_CONDITIONS, boolean>,
  isEnterprise: boolean,
  formType: FORM_TYPES,
  formQuestionTypes: FORM_QUESTION_TYPES[],
  gatewayProperties: GATEWAY_QUESTION_PROPERTIES
): boolean => {
  if (isPlainObject(conditionObj) && 'and' in conditionObj) {
    return conditionObj.and.every(item => checkResourcePropertyCondition(
      item,
      isEnterprise,
      formType,
      formQuestionTypes,
      gatewayProperties
    ));
  }

  if (isPlainObject(conditionObj) && 'or' in conditionObj) {
    return conditionObj.or.some(item => checkResourcePropertyCondition(
      item,
      isEnterprise,
      formType,
      formQuestionTypes,
      gatewayProperties
    ));
  }

  const {
    prop,
    value: compareValue,
    operator
  } = conditionObj as T_SINGLE_CONDITION;

  let value;
  switch (prop) {
    case 'jotformEnv':
      value = isEnterprise ? 'enterprise' : 'bsg';
      break;
    case 'formType':
      value = formType;
      break;
    case 'formQuestions':
      value = formQuestionTypes;
      break;
    default:
      value = gatewayProperties[prop];
      break;
  }

  return calculateOperation(value, compareValue, operator);
};
