import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { IconXmark } from '@jotforminc/svg-icons';
import { Button } from '../Button';
import { DialogCloseButtonProps } from './dialog.types';

export const DialogCloseButton = forwardRef((
  props: DialogCloseButtonProps,
  ref?: ComponentPropsWithRef<'button'>['ref']
): JSX.Element => {
  const {
    ...rest
  } = props;

  return (
    <Button
      className="absolute top-4 right-4"
      startIcon={IconXmark}
      colorStyle="secondary"
      rounded
      aria-label="Close Button"
      ref={ref}
      {...rest}
    />
  );
});
