import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { IconCreditCardPlus } from '@jotforminc/svg-icons';
import { changeGatewaySettingsView, changeGatewaySettingsVisibility } from '../../store/actionCreators';
import EditPaymentIntegration from './EditPaymentIntegration';
import { IGatewaySettings } from '../../types/common';

export default function AddPaymentIntegrationContainer({
  onAddPaymentIntegrationBtnClick,
  onEditPaymentIntegrationBtnClick,
  onDeletePaymentIntegrationBtnClick,
  activeGatewaySettings,
  onRemoveGateway,
  onOpenGatewaySettings
}: TAddPaymentIntegrationProps): JSX.Element {
  const dispatch = useDispatch();
  const openGatewayPicker = useCallback(() => {
    dispatch(changeGatewaySettingsVisibility(true));
    dispatch(changeGatewaySettingsView('gateway-picker'));
  }, [dispatch]);

  const AddPaymentIntegrationButtonClick = () => {
    if (onAddPaymentIntegrationBtnClick) {
      onAddPaymentIntegrationBtnClick();
    } else {
      openGatewayPicker();
    }
  };

  const AddPaymentIntegrationButton = (): JSX.Element => {
    return (
      <div className="mt-4">
        <Button
          fullWidth
          startIcon={IconCreditCardPlus}
          onClick={AddPaymentIntegrationButtonClick}
          className='text-capitalize'
        >
          {t('Add Payment Integration')}
        </Button>
      </div>
    );
  };
  const isGatewayExist = activeGatewaySettings
    && Object.keys(activeGatewaySettings).length !== 0
    && activeGatewaySettings.type !== 'control_payment';

  return (
    <>
      {
        isGatewayExist
          ? (
            <EditPaymentIntegration
              activeGatewaySettings={activeGatewaySettings}
              onRemoveGateway={onRemoveGateway}
              onOpenGatewaySettings={onOpenGatewaySettings}
              onEditPaymentIntegrationBtnClick={onEditPaymentIntegrationBtnClick}
              onDeletePaymentIntegrationBtnClick={onDeletePaymentIntegrationBtnClick}
            />
          )
          : <AddPaymentIntegrationButton />
      }
    </>
  );
}

type TAddPaymentIntegrationProps = {
  onAddPaymentIntegrationBtnClick: () => void
  onEditPaymentIntegrationBtnClick: () => void
  onDeletePaymentIntegrationBtnClick: () => void
  activeGatewaySettings: IGatewaySettings
  onRemoveGateway: () => void
  onOpenGatewaySettings: () => void
}
