/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState
} from 'react';

/** Data */
import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { StorageHelper } from '@jotforminc/storage-helper';

/** Types */
import type {
  PAYMENT_FIELDS,
  GATEWAY_CONNECTION_PROPS,
  GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES,
  PAYMENT_APM_NAMES,
  GATEWAY_LIST_CONNECTION_PROPS
} from '@jotforminc/payment-constants';
import type {
  USE_GATEWAY_CONNECTION_CONTEXT,
  GATEWAY_CONNECTION_MODEL_PROVIDER,
  CONNECTION_MODAL_STEPS,
  CONNECTION_MODAL_FLOW_TYPES,
  GATEWAY_NEW_CONNECTION_STATES,
  GATEWAY_NEW_CONNECTION_ERROR_TYPES,
  GATEWAY_CONNECTION_INPUT_VALUES,
  GATEWAY_CONNECTION_DISCARD_TYPES,
  EXTENDED_CONNECTION_LIST_DATA,
  CONNECTION_LIST_DATA,
  LOGGED_USER,
  GET_STORAGED_DATA,
  GATEWAY_FILTER_ON_SOURCE,
  FORM_AUTO_FLOWS_DECISION
} from '../types';

/** Data */
import {
  getGatewayConnectionPropReferences,
  handleOauthPopupManager,
  logPaymentEvents,
  nameApmToGateway,
  sandboxMappedValueToValue,
  sandboxValueToMappedValue
} from '../utils/functions';

/** API's */
import {
  newConnectionWithCredentials,
  checkUniqueConnectionNameWithRequest,
  changeStatusConnection,
  getPaymentConnections,
  attachConnectionResource,
  detachConnectionResource,
  getGatewayList,
  moveResourcesToNewConnection,
  getHasConnectionStats
} from '../api';

/** hook's */
import { useUserRoles } from '../hooks/useUserRoles';

/**
 * The context for the gateway connection.
 */
const Context = createContext<USE_GATEWAY_CONNECTION_CONTEXT>({} as USE_GATEWAY_CONNECTION_CONTEXT);

export const GatewayConnectionProvider: FunctionComponent<GATEWAY_CONNECTION_MODEL_PROVIDER> = (({
  children,
  /** Defaults */
  defaultStep = 'connectionPicker',
  defaultIsOpen = false,
  defaultFlowType,
  defaultSelectedGateway = null,
  resourceType,
  resourceId = null,
  modalSource,
  currentlyGatewayFilter = null,
  user: loggedUser = null,
  userLocation = null,
  editConnectionData = null,
  /** Enterprise */
  enterpriseSubdomain = null,
  /** Callbacks */
  onClose,
  onUseConnection,
  onCreateConnection,
  onReconnectConnection,
  onEditConnection
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);
  const [user, setUser] = useState<LOGGED_USER>(loggedUser);
  const [currentlyStep, setStep] = useState<CONNECTION_MODAL_STEPS>(defaultStep);
  const [currentlyFlow, setFlowType] = useState<CONNECTION_MODAL_FLOW_TYPES>(defaultFlowType);
  const [currentlyGateway, setSelectedGateway] = useState<PAYMENT_FIELDS | null>(defaultSelectedGateway);
  const [currentlyGatewayProperties, setCurrentlyGatewayProperties] = useState<GATEWAY_CONNECTION_PROPS | null>(null);
  const [currentlyFilteredGatewayType, setCurrentlyFilteredGatewayType] = useState<GATEWAY_FILTER_ON_SOURCE | null>(currentlyGatewayFilter);
  const [currentlyAutoFlowsDecision, setCurrentlyAutoFlowsDecision] = useState<FORM_AUTO_FLOWS_DECISION | null>(null);
  const [previousStep, setPreviousStep] = useState<CONNECTION_MODAL_STEPS | null>(null);
  const [isWaitingNextStep, setIsWaitingNextStep] = useState<boolean>(false);
  const [gatewayList, setGatewayList] = useState<GATEWAY_LIST_CONNECTION_PROPS>({} as GATEWAY_LIST_CONNECTION_PROPS);

  /** connection states */
  const [connectedId, setConnectedId] = useState<string | null>(null);
  const [currentlyConnection, setCurrentlyConnection] = useState<CONNECTION_LIST_DATA | null>(null);
  const [selectedApm, setSelectedApm] = useState<PAYMENT_APM_NAMES | null>(null);
  const [selectedChildGatewayType, setSelectedChildGatewayType] = useState<PAYMENT_FIELDS | null>(null);
  const [selectedConnectionId, setSelectedConnectionId] = useState<string | null>(null);
  const [connectionList, setConnectionList] = useState<EXTENDED_CONNECTION_LIST_DATA[] | null>(null);
  const [connectionMode, setConnectionMode] = useState<GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES | null>(null);
  const [connectionName, setConnectionName] = useState<string | null>(null);
  const [connectionNameIsValid, setConnectionNameIsValid] = useState<boolean>(true);
  const [connectionInputValues, setConnectionInputValues] = useState<GATEWAY_CONNECTION_INPUT_VALUES>({});
  const [connectionState, setConnectionState] = useState<GATEWAY_NEW_CONNECTION_STATES>('idle');
  const [connectionError, setConnectionError] = useState<boolean>(false);
  const [connectionErrorTypes, setConnectionErrorTypes] = useState<GATEWAY_NEW_CONNECTION_ERROR_TYPES[] | null>(null);
  const [connectionErrorInputErrors, setConnectionErrorInputErrors] = useState<{ [key: string]: string }>({});
  const [connectionFooterError, setConnectionFooterError] = useState<string | null>(null);
  const [connectionInvalidCredentialMessages, setConnectionInvalidCredentialMessages] = useState<string | null>(null);
  const [connectionDiscardDialogIsOpen, setConnectionDiscardDialogIsOpen] = useState<boolean>(false);
  const [connectionDiscardType, setConnectionDiscardType] = useState<GATEWAY_CONNECTION_DISCARD_TYPES | null>(null);
  const [connectionDiscardContinueButtonIsLoading, setConnectionDiscardContinueButtonIsLoading] = useState<boolean>(false);
  const [connectionModeDiscardedData, setConnectionModeDiscardedData] = useState<GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES | null>(null);
  /** user roles */
  const { isTeamMemberAndCreator, isTeamAdmin, isOrganizationAdmin } = useUserRoles(user);

  useEffect(() => {
    initializeConnection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Default Data */
  const fetchConnectionList = useCallback(() => {
    getPaymentConnections({
      resourceType,
      resourceId
    }).then(data => {
      if (data && !Array.isArray(data) && typeof data === 'object') {
        setConnectionList(Object.values(data));
        const findInUseConnection = Object.values(data).find((connection: any) => connection.inUse === true) as CONNECTION_LIST_DATA;
        setCurrentlyConnection(findInUseConnection || null);
      }
    }).catch(error => {
      console.error('Connection List Error...', error);
      setConnectionList(null);
    });
  }, [resourceId, resourceType]);

  /**
   * Closes the modal.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = () => {
    setIsOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  /**
   * Resets the states of the gateway connection.
   */
  const resetStates = () => {
    setIsWaitingNextStep(false);
    setConnectionMode(null);
    setConnectionName(null);
    setConnectionInputValues({});
    setConnectionState('idle');
    setConnectionError(false);
    setConnectionErrorTypes(null);
    setConnectionErrorInputErrors({});
    setConnectionInvalidCredentialMessages(null);
    setConnectionModeDiscardedData(null);
    setSelectedChildGatewayType(null);
    setConnectionFooterError(null);

    setCurrentlyFilteredGatewayType(null);
    setCurrentlyGatewayProperties(null);
    setSelectedGateway(null);
  };

  /**
   * Resets the state of the child gateway connection.
   * Sets all the state variables to their initial values.
   */
  const resetChildGatewayStates = () => {
    setIsWaitingNextStep(false);
    setConnectionMode(null);
    setConnectionName(null);
    setConnectionInputValues({});
    setConnectionState('idle');
    setConnectionError(false);
    setConnectionErrorTypes(null);
    setConnectionErrorInputErrors({});
    setConnectionInvalidCredentialMessages(null);
    setConnectionModeDiscardedData(null);
    setSelectedChildGatewayType(null);
  };

  const resetErrors = () => {
    setConnectionError(false);
    setConnectionErrorTypes(null);
    setConnectionErrorInputErrors({});
    setConnectionInvalidCredentialMessages(null);
  };

  /**
   * Sets the logged user information.
   *
   * @param {LOGGED_USER} userInforms - The information of the logged user.
   */
  const changeLoggedUser = useCallback((userInforms: LOGGED_USER) => {
    setUser(userInforms);
  }, []);

  /**
   * Saves the connection data before login or signup.
   */
  const saveConnectionDataBeforeLoginOrSignup = useCallback(() => {
    StorageHelper.setItem({
      key: 'reusableConnection',
      value: {
        currentlyGateway,
        currentlyStep,
        selectedChildGatewayType,
        connectionName,
        connectionMode
      },
      type: 'local',
      ttl: 3600
    });
  }, [connectionMode, connectionName, currentlyGateway, currentlyStep, selectedChildGatewayType]);

  /**
   * Removes the storage connection data before login or signup.
   */
  const removeStorageConnectionDataBeforeLoginOrSignup = useCallback(() => {
    StorageHelper.removeLocalStorageItem('reusableConnection');
  }, []);

  /**
   * Changes the current step of the connection modal.
   * @param step - The new step to set.
   */
  const changeStep = useCallback((step: CONNECTION_MODAL_STEPS) => {
    if (currentlyStep) {
      setPreviousStep(currentlyStep);
    }
    if (currentlyStep === 'connectionPicker' && step === 'gatewayPicker') {
      resetStates();
    }
    setStep(step);
  }, [currentlyStep]);

  /**
   * Changes the flow type of the gateway connection modal.
   * @param {CONNECTION_MODAL_FLOW_TYPES} flowType - The new flow type to set.
   */
  const changeFlowType = (flowType: CONNECTION_MODAL_FLOW_TYPES) => {
    setFlowType(flowType);
  };

  /**
   * Changes the selected gateway.
   *
   * @param {PAYMENT_FIELDS | null} gateway - The gateway to be selected. Pass `null` to deselect the gateway.
   * @returns {void}
   */
  const changeSelectedGateway = useCallback((gateway: PAYMENT_FIELDS | null): void => {
    setSelectedGateway(gateway);
    if (gateway) {
      setCurrentlyGatewayProperties(supportedGatewayConnectionPropsList[gateway]);
    }
  }, []);

  /**
   * Changes the selected child gateway type.
   *
   * @param {PAYMENT_FIELDS | null} gateway - The gateway to be selected. Pass `null` to clear the selection.
   * @returns {void}
   */
  const changeChildSelectedGateway = useCallback((gateway: PAYMENT_FIELDS | null): void => {
    setSelectedChildGatewayType(gateway);
  }, []);

  /**
   * Discards the new connection process.
   *
   * @param type - The type of discard action to perform.
   */
  const showOrHideDiscardNewConnectionDialog = useCallback((type: GATEWAY_CONNECTION_DISCARD_TYPES | null) => {
    if (type === null) {
      setConnectionDiscardDialogIsOpen(false);
      setConnectionDiscardType(null);
    } else {
      console.log('Discard Type...', type);
      setConnectionDiscardDialogIsOpen(true);
      setConnectionDiscardType(type);
    }
  }, []);

  /**
   * Logs payment events.
   */
  const logPaymentEvent = useCallback((action: string, target: string): void => {
    const actor = user?.username || '';
    const accountType = user?.accountType || user?.account_type?.name || '';
    logPaymentEvents(actor, action, target, accountType);
  }, [user?.username, user?.accountType, user?.account_type]);

  /**
   * Updates resources when the connection ID changes.
   */
  const updateResourcesOnConnectionIdChange = useCallback(({
    oldConnectionId,
    newConnectionId
  }: {
    oldConnectionId: string;
    newConnectionId: string;
  }) => {
    if (!oldConnectionId || !newConnectionId) {
      throw new Error('Required Parameters Missing... oldConnectionId or newConnectionId');
    }

    setIsWaitingNextStep(true);
    if (connectionFooterError) {
      setConnectionFooterError(null);
    }

    const showError = () => {
      setTimeout(() => {
        setConnectionFooterError('An error occurred, please try again.');
        setIsWaitingNextStep(false);
      }, 500);
    };

    moveResourcesToNewConnection({ oldConnectionID: oldConnectionId, newConnectionID: newConnectionId }).then(res => {
      const { status = false } = res;

      if (status) {
        if (
          modalSource
          && modalSource === 'MY_ACCOUNT'
          && currentlyFlow
          && (currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT')
        ) {
          if (typeof onEditConnection === 'function') {
            onEditConnection(newConnectionId);
          }

          if (typeof onReconnectConnection === 'function') {
            onReconnectConnection(newConnectionId);
          }

          if (typeof onClose === 'function') {
            onClose();
          }
        }
      } else {
        showError();
      }
    }).catch(error => {
      console.error('moveResourcesToNewConnection Error...', error);
      showError();
    });
  }, [
    connectionFooterError,
    currentlyFlow,
    modalSource,
    onClose,
    onEditConnection,
    onReconnectConnection
  ]);

  /**
   * Sets the selected connection ID.
   *
   * @param {string} connectionId - The ID of the connection to be selected.
   */
  const selectConnection = useCallback((connectionId: string | null) => {
    setSelectedConnectionId(connectionId);
  }, []);

  /**
   * A hook that handles the process of attaching a connection resource to a selected connection.
   * It performs the following steps:
   * 1. Checks if a connection is currently in use and detaches it if necessary.
   * 2. Attaches the selected connection resource.
   * 3. Retrieves and sets the gateway type and connection properties.
   * 4. Logs the connection switch or usage event.
   * 5. Closes the modal after a short delay.
   *
   * @async
   * @function
   * @name saveToAttachConnectionResource
   * @returns {Promise<void>}
   * @throws Will throw an error if the connection attachment fails.
   *
   */
  const saveToAttachConnectionResource = useCallback(async (
    {
      manuelSettedSelectedConnectionId,
      manuelFilledSelectedConnectionDetails = false
    }:
    {
      manuelSettedSelectedConnectionId?: string;
      manuelFilledSelectedConnectionDetails?: boolean;
    }
  ) => {
    const getSelectedConnectionId = manuelSettedSelectedConnectionId || selectedConnectionId;

    if (getSelectedConnectionId && resourceId) {
      let isSwitchConnection = false;
      setIsWaitingNextStep(true);

      if (currentlyConnection && currentlyConnection.inUse && getSelectedConnectionId && currentlyConnection.id !== getSelectedConnectionId) {
        await detachConnectionResource(resourceType, resourceId);
        isSwitchConnection = true;
      }

      attachConnectionResource(getSelectedConnectionId, resourceType, resourceId).then(isSuccess => {
        if (isSuccess && isSuccess === true) {
          let getGatewayType = connectionList?.find(connection => connection.id === getSelectedConnectionId);

          if (!getGatewayType && manuelFilledSelectedConnectionDetails) {
            if (currentlyGateway || selectedChildGatewayType) {
              getGatewayType = {
                gateway: currentlyGateway,
                parent_gateway: selectedChildGatewayType || null,
                sandbox: sandboxValueToMappedValue({
                  gatewayType: selectedChildGatewayType || currentlyGateway || null,
                  sandboxValue: connectionMode || null
                })
              } as any;
            }
          }

          if (getGatewayType) {
            const settedGateway = getGatewayType.parent_gateway ? getGatewayType.parent_gateway : getGatewayType.gateway;
            const connectionPropsList = supportedGatewayConnectionPropsList[settedGateway];
            const settedApm = getGatewayType.parent_gateway ? supportedGatewayConnectionPropsList[getGatewayType.gateway].apmType : null;

            /* Get Sandbox Key Val Pair */
            const modeOptionValue = sandboxMappedValueToValue({
              gatewayType: settedGateway,
              mappedSandboxValue: getGatewayType.sandbox
            }) || null;
            const sandboxKey = connectionPropsList.connection.mode.name || null;
            const sandboxValue = modeOptionValue || null;
            const sandboxKeyValPair = sandboxKey && sandboxValue ? { [sandboxKey]: sandboxValue } : {};
            /* Get Sandbox Key Val Pair: End */

            if (typeof onUseConnection === 'function') {
              onUseConnection(getSelectedConnectionId, settedGateway, settedApm || null, sandboxKeyValPair);
            }

            /* LOG SWITCH-USE CONNECTION */
            try {
              let target;
              const currentParent = currentlyConnection?.parent_gateway && { parentGateway: supportedGatewayConnectionPropsList[currentlyConnection?.parent_gateway].name };
              const nextParent = getGatewayType.parent_gateway && { parentGateway: supportedGatewayConnectionPropsList[getGatewayType.parent_gateway].name };
              const nextData = {
                name: supportedGatewayConnectionPropsList[getGatewayType.gateway].name,
                ...(nextParent && nextParent)
              };
              if (isSwitchConnection) {
                target = {
                  prev: {
                    name: currentlyConnection?.gateway ? supportedGatewayConnectionPropsList[currentlyConnection?.gateway].name : '',
                    ...(currentParent && currentParent)
                  },
                  next: nextData
                };
              } else {
                target = nextData;
              }

              logPaymentEvent(isSwitchConnection ? 'switch-connection' : 'use-connection', JSON.stringify(target));
            } catch (error) {
              console.log('Error on logging', error);
            }
            /* LOG SWITCH-USE CONNECTION */
          }
          setTimeout(() => {
            closeModal();
          }, 500);
        }
      }).catch(error => {
        console.error('Connection Attach Error...', error);
        setIsWaitingNextStep(false);
      });
    } else {
      console.log('Connection ID or Resource ID is missing...', getSelectedConnectionId, resourceId);
    }
  }, [
    closeModal,
    connectionList,
    connectionMode,
    currentlyConnection,
    currentlyGateway,
    logPaymentEvent,
    onUseConnection,
    resourceId,
    resourceType,
    selectedChildGatewayType,
    selectedConnectionId
  ]);

  /**
   * Handles the logic for the next step in the gateway connection process.
   * @returns {void}
   */
  const nextStep = useCallback(async (): Promise<void> => {
    switch (currentlyStep) {
      case 'gatewayPicker':
        if (currentlyGateway && currentlyGatewayProperties) {
          const {
            isApm, isChildTypes, childTypes, connection, apmType
          } = currentlyGatewayProperties;

          if (isApm === true && isChildTypes === true && childTypes.length > 0) {
            if (apmType) {
              setSelectedApm(apmType);
            }
            changeStep('apmGatewayPicker');
          } else {
            if (isApm && !isChildTypes && apmType) {
              setSelectedApm(apmType);
            } else if (selectedApm) {
              setSelectedApm(null);
            }

            if (connection.allowSwitchMode === true && connection.mode.options && connection.mode.options.length > 0) {
              const defaultMode = connection.mode.options[connection.mode.options.length - 1].value;
              setConnectionMode(defaultMode);
            }
            changeStep('gatewayConnection');
          }

          setPreviousStep('gatewayPicker');
        }
        break;
      case 'gatewayConnection':
        if (
          (modalSource === 'MY_ACCOUNT' || modalSource === 'ENTERPRISE_ADMIN')
          && currentlyFlow && (currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT') && connectedId && editConnectionData) {
          const oldConnectionId = editConnectionData.connectionId;
          const newConnectionId = connectedId;

          updateResourcesOnConnectionIdChange({ oldConnectionId, newConnectionId });
        } else if (connectedId) {
          setIsWaitingNextStep(true);
          changeStatusConnection(connectedId, '1').then(res => {
            const { status, errorKeys } = res;
            if (status && status === true) {
              if (modalSource && (modalSource === 'MY_ACCOUNT' || modalSource === 'ENTERPRISE_ADMIN') && typeof onClose === 'function') {
                onClose();
              } else if (
                modalSource
                && ['FORM', 'APP'].includes(modalSource)
                && ['FORM', 'APP'].includes(resourceType)
                && currentlyFlow === 'FORM_CONNECTION_DEPENDENT_AUTO_FLOW'
                && currentlyAutoFlowsDecision
                && (currentlyAutoFlowsDecision.newUserProductList || currentlyAutoFlowsDecision.newUserPaymentGatewayField)
              ) {
                selectConnection(connectedId);
                saveToAttachConnectionResource({
                  manuelSettedSelectedConnectionId: connectedId,
                  manuelFilledSelectedConnectionDetails: true
                });
              } else {
                fetchConnectionList();
                setTimeout(() => {
                  changeStep('connectionPicker');
                  resetStates();
                }, 500);
              }

              const target = {
                name: currentlyGatewayProperties?.name,
                ...(selectedChildGatewayType && { childGateway: supportedGatewayConnectionPropsList[selectedChildGatewayType].name })
              };

              logPaymentEvent('create-connection', JSON.stringify(target));
              if (typeof onCreateConnection === 'function' && connectionName && currentlyGatewayProperties?.name) {
                onCreateConnection(connectionName, currentlyGatewayProperties?.name);
              }
            } else {
              console.error('Connection Status Error...', errorKeys);
            }
          }).catch(error => {
            console.error('Connection Status Error...', error);
          });
        }
        break;
      case 'connectionPicker':
        saveToAttachConnectionResource({});
        break;
      case 'apmGatewayPicker':
        if (currentlyGateway && currentlyGatewayProperties) {
          if (selectedApm && selectedChildGatewayType) {
            const getSelectedChildGateway = supportedGatewayConnectionPropsList[selectedChildGatewayType];
            const { connection } = getSelectedChildGateway;

            if (connection.allowSwitchMode === true && connection.mode.options && connection.mode.options.length > 0) {
              const defaultMode = connection.mode.options[connection.mode.options.length - 1].value;
              setConnectionMode(defaultMode);
            }

            changeStep('gatewayConnection');
          }
        }
        break;
      default:
        break;
    }
  }, [
    currentlyStep,
    currentlyGateway,
    currentlyGatewayProperties,
    modalSource,
    currentlyFlow,
    connectedId,
    editConnectionData,
    saveToAttachConnectionResource,
    changeStep,
    selectedApm,
    updateResourcesOnConnectionIdChange,
    onClose,
    currentlyAutoFlowsDecision,
    selectedChildGatewayType,
    logPaymentEvent,
    onCreateConnection,
    connectionName,
    selectConnection,
    fetchConnectionList,
    resourceType
  ]);

  /**
   * Navigates back to the previous step in the gateway connection flow.
   * @returns {void}
   */
  const backStep = useCallback((): void => {
    switch (currentlyStep) {
      case 'apmGatewayPicker':
        setSelectedApm(null);
        setSelectedChildGatewayType(null);
        setCurrentlyGatewayProperties(null);
        setSelectedGateway(null);
        changeStep('gatewayPicker');
        break;
      case 'gatewayConnection':
        if (connectedId && connectionState === 'connected' && connectionDiscardDialogIsOpen) {
          setTimeout(() => {
            setConnectionDiscardContinueButtonIsLoading(false);
            showOrHideDiscardNewConnectionDialog(null);
            resetStates();
            changeStep('gatewayPicker');
          }, 500);
        } else if (selectedChildGatewayType) {
          resetChildGatewayStates();
          changeStep('apmGatewayPicker');
        } else {
          resetStates();
          changeStep('gatewayPicker');
        }
        break;
      case 'gatewayPicker':
        if (selectedConnectionId) {
          setSelectedConnectionId(null);
        }
        changeStep('connectionPicker');
        break;
      default:
        break;
    }
  }, [
    currentlyStep,
    changeStep,
    connectedId,
    selectedChildGatewayType,
    connectionState,
    connectionDiscardDialogIsOpen,
    selectedConnectionId,
    showOrHideDiscardNewConnectionDialog
  ]);

  /**
   * Validates the connection based on the connection type.
   * @param connectionType - The type of connection ('oauth' or 'credentials').
   * @returns A boolean indicating whether the connection is valid or not.
   */
  const connectionManuelValidation = useCallback((connectionType: Readonly<'oauth' | 'credentials'>) => {
    const errors: { [key: string]: string } = {};

    const isValidEmail = (email: string): boolean => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    switch (connectionType) {
      case 'credentials':

        /** Required */
        if (currentlyGatewayProperties) {
          const propReferences = getGatewayConnectionPropReferences({ gateway: currentlyGatewayProperties.gateway_type });
          if (propReferences) {
            propReferences.forEach(({ name, props }) => {
              switch (true) {
                case (
                  props.isRequired === 'Yes'
                  && (!props.dependentProp
                    || (props.dependentProp && connectionInputValues[props.dependentProp.propName] === props.dependentProp.propValue)
                  )
                    && (!connectionInputValues[name] || connectionInputValues[name]?.trim() === '')
                ):
                  errors[name] = 'Required';
                  break;
                /** email verifications for special gateways */
                case ['control_paypal', 'control_pagseguro'].includes(currentlyGatewayProperties.gateway_type)
                  && ['account', 'pagseguroid'].includes(name)
                  && typeof connectionInputValues[name] !== 'undefined'
                  && !isValidEmail(connectionInputValues[name] || ''):
                  errors[name] = 'invalidEmailAddress';
                  break;
                default:
                  break;
              }
            });
          }
        }

        /** Connection Name */
        if (connectionName === null || connectionName === '' || connectionName.trim() === '') {
          errors.connectionName = 'Required';
        } else if (!connectionNameIsValid) {
          errors.connectionName = 'Not Unique';
        }

        if (Object.keys(errors).length > 0) {
          setConnectionError(true);
          setConnectionErrorInputErrors(errors);
          const isRequiredError = Object.values(errors).includes('Required');
          const isNotUniqueError = Object.values(errors).includes('Not Unique');
          const isInvalidEmailError = Object.values(errors).includes('invalidEmailAddress');

          const connectionErrors: GATEWAY_NEW_CONNECTION_ERROR_TYPES[] = [];
          if (isRequiredError) { connectionErrors.push('missingRequiredFields'); }
          if (isNotUniqueError) { connectionErrors.push('notUniqueConnectionName'); }
          if (isInvalidEmailError) { connectionErrors.push('invalidEmailAddress'); }

          setConnectionErrorTypes(connectionErrors);

          setTimeout(() => {
            setConnectionState('error');
          }, 90);

          return false;
        }

        resetErrors();
        return true;
      case 'oauth':

        /** connection name */
        if (connectionName === null || connectionName === '' || connectionName.trim() === '') {
          errors.connectionName = 'Required';
        } else if (!connectionNameIsValid) {
          errors.connectionName = 'Not Unique';
        }

        if (Object.keys(errors).length > 0) {
          setConnectionError(true);
          setConnectionErrorInputErrors(errors);
          const isRequiredError = Object.values(errors).includes('Required');
          const isNotUniqueError = Object.values(errors).includes('Not Unique');

          const connectionErrors: GATEWAY_NEW_CONNECTION_ERROR_TYPES[] = [];
          if (isRequiredError) { connectionErrors.push('missingRequiredFields'); }
          if (isNotUniqueError) { connectionErrors.push('notUniqueConnectionName'); }

          setConnectionErrorTypes(connectionErrors);

          setTimeout(() => {
            setConnectionState('error');
          }, 90);

          return false;
        }

        resetErrors();
        return true;
      default:
        return false;
    }
  }, [connectionInputValues, connectionName, connectionNameIsValid, currentlyGatewayProperties]);

  /**
   * Validates the connection request or sets the connection ID based on the response.
   *
   * @param {PAYMENT_FIELDS} gatewayType - The type of payment gateway.
   * @param {any} response - The response received from the server.
   */
  const connectionRequestValidationOrSetConnectionId = useCallback((gatewayType: PAYMENT_FIELDS, response: any) => {
    const errors: { [key: string]: string } = {};
    const { status: responseStatus, errorKeys, connection: connectionData } = response;

    if (responseStatus === false && response.message && typeof response.message === 'string') {
      setConnectionState('error');
      setConnectionError(true);
      setConnectionErrorTypes(['invalidFields']);
    } else if (responseStatus === false && errorKeys.length > 0) {
      setConnectionState('error');
      setConnectionError(true);

      const requestErrors: GATEWAY_NEW_CONNECTION_ERROR_TYPES[] = [];
      const propReferences = getGatewayConnectionPropReferences({ gateway: gatewayType });

      errorKeys.forEach((key: string) => {
        switch (key) {
          case 'invalidInput':
          case 'invalidFields':
          case 'invalidGateway':
            requestErrors.push('invalidFields');
            break;
          case 'invalidCredential':
            requestErrors.push('invalidCredentials');
            if (propReferences) {
              propReferences.forEach(({ name }) => {
                errors[name] = 'Invalid';
              });
              setConnectionErrorInputErrors(errors);
            }

            setConnectionInvalidCredentialMessages(response.errors.invalidCredential.message);
            break;
          case 'duplicateConnection':
            requestErrors.push('notUniqueConnection');
            if (propReferences) {
              propReferences.forEach(({ name }) => {
                errors[name] = 'Not Unique';
              });
              setConnectionErrorInputErrors(errors);
            }
            break;
          case 'duplicateConnectionName':
            errors.connectionName = 'Not Unique';
            requestErrors.push('notUniqueConnectionName');
            break;
          case 'credentialsNotSame':
            requestErrors.push('credentialsNotSame');
            break;
          case 'rateLimiter':
            requestErrors.push('rateLimiter');
            break;
          default:
            break;
        }
      });
      setConnectionErrorTypes(requestErrors);
    } else if (responseStatus === true) {
      setConnectionState('connected');
      setConnectionError(false);
      setConnectionErrorTypes(null);
      setConnectionErrorInputErrors({});
      setConnectionInvalidCredentialMessages(null);
      if (connectionData.created && connectionData.created === true && connectionData.id) {
        setConnectedId(connectionData.id);
      }
    }
  }, []);

  const gatewayConnect = useCallback(() => {
    if (!currentlyGatewayProperties) { return; }

    const { isApm, connection, gateway_type: Gateway } = currentlyGatewayProperties;
    let connectionType = connection.type;
    if (isApm && selectedChildGatewayType) {
      connectionType = supportedGatewayConnectionPropsList[selectedChildGatewayType].connection.type;
    }

    const isEditMode = defaultFlowType === 'EDIT_CONNECTION' && editConnectionData && editConnectionData.connectionId ? true : false;
    const isReConnectMode = defaultFlowType === 'RE_CONNECT' && editConnectionData && editConnectionData.connectionId ? true : false;
    const editedConnectionId = editConnectionData && editConnectionData.connectionId ? editConnectionData.connectionId : undefined;

    switch (connectionType) {
      case 'credentials':

        /** connection button states */
        setConnectionState('loading');

        /** manuel validation */
        const isValidCredentials = connectionManuelValidation('credentials');
        if (!isValidCredentials) { return; }

        if (connectionName && connectionNameIsValid && currentlyGatewayProperties) {
          setConnectionState('loading');

          newConnectionWithCredentials(
            Gateway,
            connection.allowSwitchMode,
            enterpriseSubdomain,
            {
              connectionName: connectionName,
              connectionModeName: connection.allowSwitchMode && connection.mode.name ? connection.mode.name : null,
              connectionModeValue: connection.allowSwitchMode && connectionMode ? connectionMode : null,
              credentials: connectionInputValues,
              isEditMode: isEditMode,
              isReconnectMode: isReConnectMode,
              connectionId: editedConnectionId
            }
          ).then(response => {
            /** credential errors or set connection id */
            connectionRequestValidationOrSetConnectionId(Gateway, response);
            return true;
          }).catch(error => {
            console.error('Connection Error...', error);
          });
        }

        break;
      case 'oauth':

        /** connection button states */
        setConnectionState('loading');

        /** manuel validation */
        const isValidOauth = connectionManuelValidation('oauth');
        if (!isValidOauth) { return; }

        if (connectionName) {
          checkUniqueConnectionNameWithRequest({
            connectionName,
            isEditMode: isEditMode,
            isReconnectMode: isReConnectMode,
            connectionId: editedConnectionId
          }).then(response => {
            if (response === true) {
              setConnectionError(true);
              setConnectionErrorInputErrors({ connectionName: 'Not Unique' });
              setConnectionErrorTypes(['notUniqueConnectionName']);

              setTimeout(() => {
                setConnectionState('error');
              }, 90);

              return false;
            }

            resetErrors();

            handleOauthPopupManager({
              currentlyGateway: Gateway,
              selectedApm,
              selectedChildGatewayType,
              connectionName: connectionName || '',
              connectionModeValue: connectionMode,
              isEditMode: isEditMode,
              isReconnectMode: isReConnectMode,
              connectionId: editedConnectionId,
              onStart: () => {
                console.log('Oauth Start...');
                setConnectionState('loading');
              },
              onCanceled: () => {
                console.log('Oauth Canceled...');
                setConnectionState('canceled');
              },
              onRequestProcessing: (getGatewayType, responseData) => {
                /** credential errors or set connection id */
                connectionRequestValidationOrSetConnectionId(getGatewayType, responseData);
                return true;
              }
            });

            return true;
          }).catch(() => {
            setConnectionState('error');
            setConnectionError(true);
            setConnectionErrorInputErrors({ connectionName: 'Network Error' });
          });
        }

        break;
      default:
        break;
    }
  }, [
    currentlyGatewayProperties,
    selectedChildGatewayType,
    defaultFlowType,
    editConnectionData,
    connectionManuelValidation,
    connectionName,
    connectionNameIsValid,
    enterpriseSubdomain,
    connectionMode,
    connectionInputValues,
    connectionRequestValidationOrSetConnectionId,
    selectedApm
  ]);

  /**
   * Disconnects the gateway connection.
   *
   * @remarks
   * This function sets the connection state to 'loading', removes or cancels the connection
   * with the specified `connectedId`, and updates the connection state and error values accordingly.
   *
   * @returns A Promise that resolves when the connection is successfully removed or cancelled.
   *
   * @param connectedId - The ID of the connected gateway.
   */
  const gatewayDisconnect = useCallback(() => {
    if (!connectedId) { return; }

    setConnectedId(null);
    setConnectionInputValues({});
    setConnectionState('idle');
    setConnectionError(false);
    setConnectionErrorTypes(null);
    setConnectionErrorInputErrors({});
    if (connectionDiscardDialogIsOpen) {
      if (connectionDiscardType === 'changeMode' && connectionModeDiscardedData) {
        setConnectionMode(connectionModeDiscardedData);
      }

      if (
        currentlyFlow
        && (currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT')
        && editConnectionData
        && editConnectionData.connectionName
      ) {
        setConnectionName(editConnectionData.connectionName);
      } else {
        setConnectionName(null);
      }

      setTimeout(() => {
        setConnectionDiscardContinueButtonIsLoading(false);
        showOrHideDiscardNewConnectionDialog(null);
      }, 500);
    }
  }, [
    connectedId,
    connectionDiscardDialogIsOpen,
    connectionDiscardType,
    connectionModeDiscardedData,
    currentlyFlow,
    showOrHideDiscardNewConnectionDialog,
    editConnectionData
  ]);

  /**
   * Removes the input error for the specified input name.
   * If the input name exists in the connectionErrorInputErrors object,
   * it will be removed from the object.
   *
   * @param {string} inputName - The name of the input to remove the error for.
   */
  const removeInputError = useCallback((inputName: string) => {
    if (connectionErrorInputErrors[inputName]) {
      const errors = { ...connectionErrorInputErrors };
      delete errors[inputName];
      setConnectionErrorInputErrors(errors);
      if (Object.keys(errors).length === 0) {
        setConnectionError(false);
      }
    }
  }, [connectionErrorInputErrors]);

  /**
   * Checks if the provided connection name is unique on blur event.
   * If the connection name is not unique, it sets the connection error state and error messages.
   * If the connection name is unique, it removes the input error for the connection name.
   * @param {string} name - The connection name to check for uniqueness.
   */
  const checkUniqueConnectionNameOnBlur = useCallback((name: string) => {
    checkUniqueConnectionNameWithRequest({
      connectionName: name,
      isEditMode: editConnectionData && editConnectionData.connectionId ? true : false,
      connectionId: editConnectionData && editConnectionData.connectionId ? editConnectionData.connectionId : undefined
    }).then(response => {
      if (response === true) {
        setConnectionError(true);
        setConnectionErrorTypes(['notUniqueConnectionName']);
        setConnectionErrorInputErrors({ ...connectionErrorInputErrors, connectionName: 'Not Unique' });
        setConnectionNameIsValid(false);
      } else {
        removeInputError('connectionName');
      }
    }).catch(error => {
      console.error('Connection Name Error...', error);
    });
  }, [connectionErrorInputErrors, editConnectionData, removeInputError]);

  /**
   * Changes the connection name.
   * @param {string} name - The new connection name.
   */
  const changeConnectionName = useCallback((name: string) => {
    setConnectionName(name);
    if (!connectionNameIsValid && name !== connectionName) {
      setConnectionNameIsValid(true);
      removeInputError('connectionName');
    }
  }, [connectionNameIsValid, connectionName, removeInputError]);

  /**
   * Changes the connection sandbox mode of the gateway.
   * @param mode - The new connection mode.
   */
  const changeConnectionMode = useCallback((mode: GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES) => {
    if (mode === connectionMode) { return; }

    setConnectionMode(mode);
  }, [connectionMode]);

  /**
   * Updates the connection input values with the provided value.
   * @param {GATEWAY_CONNECTION_INPUT_VALUES} value - The value to update the connection input values with.
   */
  const changeConnectionInputValue = useCallback((value: GATEWAY_CONNECTION_INPUT_VALUES) => {
    setConnectionInputValues({
      ...connectionInputValues,
      ...value
    });
  }, [connectionInputValues]);

  /**
   * Handles the connection button click event based on the current connection state.
   */
  const connectionButtonHandler = useCallback(() => {
    switch (connectionState) {
      case 'idle':
      case 'error':
      case 'canceled':
        gatewayConnect();
        break;
      case 'connected':
        gatewayDisconnect();
        break;
      case 'loading':
      default:
        break;
    }
  }, [connectionState, gatewayConnect, gatewayDisconnect]);

  /**
   * Handles the continue button process for discarding a new connection.
   */
  const discardNewConnectionDialogContinueButtonProcess = useCallback(() => {
    setConnectionDiscardContinueButtonIsLoading(true);
    switch (connectionDiscardType) {
      case 'disconnect':
      case 'changeMode':
        gatewayDisconnect();
        break;
      case 'backOrClose':
        if (
          currentlyFlow
          && (currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT')
          && typeof onClose === 'function'
        ) {
          setConnectionDiscardContinueButtonIsLoading(false);
          onClose();
        } else {
          backStep();
        }
        break;
      case 'switchGateway':
        nextStep();
        break;
      default:
        break;
    }
  }, [connectionDiscardType, gatewayDisconnect, currentlyFlow, nextStep, onClose, backStep]);

  /**
   * Changes the discarded connection mode.
   *
   * @param mode - The new value for the discarded connection mode.
   */
  const changeDiscardedConnectionMode = useCallback((mode: GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES) => {
    setConnectionModeDiscardedData(mode);
  }, []);

  /**
   * Directs to a filled gateway connection by setting various connection parameters.
   *
   * @param {Object} params - The parameters for setting the gateway connection.
   * @param {string | null} params.connectionName - The name of the connection to be set.
   * @param {PAYMENT_FIELDS | null} params.currentlyGateway - The currently selected gateway.
   * @param {PAYMENT_FIELDS | null} params.selectedChildGatewayType - The selected child gateway type.
   * @param {CONNECTION_MODAL_STEPS} params.currentlyStep - The current step in the connection modal.
   * @param {GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES | null} params.connectionMode - The mode of the connection.
   *
   * @returns {void}
   */
  const directToFilledGatewayConnection = useCallback(({
    connectionName: settedConnectionName,
    currentlyGateway: settedCurrentlyGateway,
    selectedChildGatewayType: settedSelectedChildGatewayType,
    currentlyStep: settedCurrentlyStep,
    connectionMode: settedConnectionMode
  }: {
    connectionName: string | null;
    currentlyGateway: PAYMENT_FIELDS | null;
    selectedChildGatewayType: PAYMENT_FIELDS | null;
    currentlyStep: CONNECTION_MODAL_STEPS;
    connectionMode: GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES | null;
  }) => {
    if (settedConnectionName) {
      changeConnectionName(settedConnectionName);
    }

    if (settedCurrentlyGateway) {
      changeSelectedGateway(settedCurrentlyGateway);
      if (settedSelectedChildGatewayType) {
        changeChildSelectedGateway(settedSelectedChildGatewayType);
      }
      const {
        isApm, isChildTypes, childTypes, apmType
      } = supportedGatewayConnectionPropsList[settedCurrentlyGateway];

      if (isApm === true && isChildTypes === true && childTypes.length > 0) {
        if (apmType) {
          setSelectedApm(apmType);
        }
      } else if (isApm && !isChildTypes && apmType) {
        setSelectedApm(apmType);
      } else if (selectedApm) {
        setSelectedApm(null);
      }

      /** Set Connection Mode */
      const {
        connection: connectionProps
      } = supportedGatewayConnectionPropsList[settedSelectedChildGatewayType ? settedSelectedChildGatewayType : settedCurrentlyGateway];

      if (connectionProps.allowSwitchMode === true && connectionProps.mode.options && connectionProps.mode.options.length > 0) {
        if (settedConnectionMode) {
          if (connectionProps.mode.options.some(option => option.value === settedConnectionMode)) {
            setConnectionMode(settedConnectionMode);
          }
        } else {
          const defaultMode = connectionProps.mode.options[connectionProps.mode.options.length - 1].value;
          setConnectionMode(defaultMode);
        }
      }
    }

    if (settedCurrentlyStep) {
      changeStep(settedCurrentlyStep);
    }
    removeStorageConnectionDataBeforeLoginOrSignup();
  }, [changeChildSelectedGateway, changeConnectionName, changeSelectedGateway, changeStep, removeStorageConnectionDataBeforeLoginOrSignup, selectedApm]);

  /**
   * Fetches the gateway list.
   * If the user is not an enterprise user, it sets the gateway list to the supported gateway connection properties list.
   * Otherwise, it fetches the gateway list from the server and sets it.
   */
  const fetchGatewayList = useCallback(() => {
    if (!isEnterprise()) {
      setGatewayList(supportedGatewayConnectionPropsList);
    } else {
      getGatewayList().then(response => {
        setGatewayList(response);
      }).catch(error => {
        console.error('Gateway List Error...', error);
      });
    }
  }, []);

  /**
   * Initializes the connection.
   * If the reusable connection data is stored, it directs to the filled gateway connection.
   * If the currently filtered gateway type is set, it directs to the filled gateway connection.
   * If the edit connection data is set, it directs to the filled gateway connection.
   */
  const initializeConnection = useCallback(() => {
    /** Fetch Gateway List for Enterprise */
    fetchGatewayList();

    /** Get, Set Storage Data for Guest User */
    const storageData = StorageHelper.getItem({
      key: 'reusableConnection',
      type: 'local'
    });

    if (storageData) {
      const Data = storageData as GET_STORAGED_DATA;
      if (Data) {
        directToFilledGatewayConnection(Data);
        removeStorageConnectionDataBeforeLoginOrSignup();
        return;
      }
    }
    /** Get, Set Storage Data for Guest User: End */

    if (
      modalSource
      && ['FORM', 'APP'].includes(modalSource)
      && ['FORM', 'APP'].includes(resourceType)
      && defaultFlowType === 'FORM_CONNECTION_DEPENDENT_AUTO_FLOW'
      && currentlyFilteredGatewayType
    ) {
      const { type: gatewayType, nameAPM = null } = currentlyFilteredGatewayType;

      /**
       * An object representing the decision states for various auto flows in the payment gateway connection context.
       */
      const autoFlowsDecision = {
        /**
         * New User > Product List
         */
        newUserProductList: false,
        /**
         * New User > Payment Gateway
         */
        newUserPaymentGatewayField: false,
        /**
         * Engaged User > Product List > Use Existing
         * Engaged User > Product List > Add New Connection
         */
        useExistingProductList: false,
        /**
         * Engaged User > Payments Gateway > Use Existing
         */
        useExistingPaymentFieldCreateNewFromExisting: false,
        /**
         * Engaged User > Payments Gateway > Create New From Non Existing
         */
        useExistingPaymentFieldCreateNewFromNonExisting: false
      };

      getHasConnectionStats({
        scope: 'user',
        gateway: nameAPM ? nameApmToGateway(nameAPM).gateway_type : gatewayType
      }).then(response => {
        const { gatewayConnectionCount = 0, totalConnection = 0 } = response;

        if (gatewayType === 'control_payment' && !nameAPM) {
          if (totalConnection === 0 && gatewayConnectionCount === 0) {
            autoFlowsDecision.newUserProductList = true;
            changeStep('gatewayPicker');
          } else if (totalConnection > 0) {
            autoFlowsDecision.useExistingProductList = true;
          }
        } else if (totalConnection === 0 && gatewayConnectionCount === 0) {
          autoFlowsDecision.newUserPaymentGatewayField = true;

          const getFilteredGatewayType = nameAPM ? nameApmToGateway(nameAPM).gateway_type : gatewayType;
          directToFilledGatewayConnection({
            connectionName: null,
            connectionMode: null,
            currentlyGateway: getFilteredGatewayType,
            selectedChildGatewayType: null,
            currentlyStep: nameAPM && nameAPM === 'appleAndGooglePay' ? 'apmGatewayPicker' : 'gatewayConnection'
          });
          setCurrentlyFilteredGatewayType(null);
        } else if (gatewayConnectionCount > 0) {
          autoFlowsDecision.useExistingPaymentFieldCreateNewFromExisting = true;
          if (modalSource === 'APP' && resourceType === 'APP') {
            setCurrentlyFilteredGatewayType(null);
          }
        } else {
          autoFlowsDecision.useExistingPaymentFieldCreateNewFromNonExisting = true;
          const getFilteredGatewayType = nameAPM ? nameApmToGateway(nameAPM).gateway_type : gatewayType;
          directToFilledGatewayConnection({
            connectionName: null,
            connectionMode: null,
            currentlyGateway: getFilteredGatewayType,
            selectedChildGatewayType: null,
            currentlyStep: nameAPM && nameAPM === 'appleAndGooglePay' ? 'apmGatewayPicker' : 'gatewayConnection'
          });
          setCurrentlyFilteredGatewayType(null);
        }

        setCurrentlyAutoFlowsDecision(autoFlowsDecision);
      }).catch(error => {
        console.error('Has Connection Stats Error...', error);
        setCurrentlyAutoFlowsDecision(null);
      });

      return;
    }

    /* Direct to Filled Gateway Connection for Edit Or Reconnect */
    if (
      modalSource
      && ['MY_ACCOUNT', 'ENTERPRISE_ADMIN'].includes(modalSource)
      && ['EDIT_CONNECTION', 'RE_CONNECT'].includes(defaultFlowType)
      && editConnectionData
      && editConnectionData.connectionId
    ) {
      setConnectedId(editConnectionData.connectionId);

      const sandboxMappedValue = sandboxMappedValueToValue({
        gatewayType: editConnectionData.selectedChildGateway || editConnectionData.selectedGateway,
        mappedSandboxValue: editConnectionData.connectionMode
      });

      directToFilledGatewayConnection({
        connectionName: editConnectionData.connectionName,
        connectionMode: sandboxMappedValue,
        currentlyGateway: editConnectionData.selectedGateway,
        selectedChildGatewayType: editConnectionData.selectedChildGateway,
        currentlyStep: 'gatewayConnection'
      });
      return;
    }
    /* Direct to Filled Gateway Connection for Edit Or Reconnect: End */

    /* Direct to Filled Gateway Connection for Currently Filtered Gateway Type */
    if (
      modalSource
      && modalSource === 'FORM'
      && resourceType === 'FORM'
      && defaultFlowType === 'CONNECTTED_ACCOUNT_TO_CONNECTION'
      && currentlyFilteredGatewayType
    ) {
      const { type, nameAPM = null } = currentlyFilteredGatewayType;
      if (type !== 'control_payment' || (type === 'control_payment' && nameAPM)) {
        const gatewayType = nameAPM ? nameApmToGateway(nameAPM).gateway_type : type;

        directToFilledGatewayConnection({
          connectionName: null,
          connectionMode: null,
          currentlyGateway: gatewayType,
          selectedChildGatewayType: null,
          currentlyStep: nameAPM && nameAPM === 'appleAndGooglePay' ? 'apmGatewayPicker' : 'gatewayConnection'
        });
        setCurrentlyFilteredGatewayType(null);
      }
    }
    /* Direct to Filled Gateway Connection for Currently Filtered Gateway Type: End */
  }, [
    currentlyFilteredGatewayType,
    defaultFlowType,
    directToFilledGatewayConnection,
    editConnectionData,
    fetchGatewayList,
    removeStorageConnectionDataBeforeLoginOrSignup,
    resourceType,
    modalSource,
    changeStep
  ]);

  const returnValue = useMemo(() => {
    return {
      isOpen,
      currentlyStep,
      currentlyGateway,
      currentlyFlow,
      currentlyGatewayProperties,
      currentlyAutoFlowsDecision,
      previousStep,
      isWaitingNextStep,
      selectedChildGatewayType,
      isTeamMemberAndCreator,
      isTeamAdmin,
      isOrganizationAdmin,
      /** connection states */
      connectedId,
      currentlyConnection,
      selectedConnectionId,
      connectionList,
      connectionMode,
      connectionName,
      connectionInputValues,
      connectionState,
      connectionError,
      connectionErrorTypes,
      connectionErrorInputErrors,
      connectionFooterError,
      connectionInvalidCredentialMessages,
      connectionDiscardDialogIsOpen,
      connectionDiscardType,
      connectionDiscardContinueButtonIsLoading,
      /** user props */
      user,
      userLocation,
      /** data */
      resourceType,
      resourceId,
      modalSource,
      currentlyFilteredGatewayType,
      editConnectionData,
      gatewayList,
      /** functions */
      closeModal,
      changeStep,
      changeFlowType,
      changeSelectedGateway,
      changeChildSelectedGateway,
      nextStep,
      backStep,
      gatewayConnect,
      gatewayDisconnect,
      connectionButtonHandler,
      changeConnectionName,
      changeConnectionMode,
      changeConnectionInputValue,
      removeInputError,
      checkUniqueConnectionNameOnBlur,
      fetchConnectionList,
      selectConnection,
      showOrHideDiscardNewConnectionDialog,
      discardNewConnectionDialogContinueButtonProcess,
      changeDiscardedConnectionMode,
      changeLoggedUser,
      saveConnectionDataBeforeLoginOrSignup,
      logPaymentEvent,
      fetchGatewayList
    };
  }, [
    isOpen,
    currentlyStep,
    currentlyGateway,
    currentlyFlow,
    currentlyGatewayProperties,
    currentlyAutoFlowsDecision,
    previousStep,
    isWaitingNextStep,
    selectedChildGatewayType,
    isTeamMemberAndCreator,
    isTeamAdmin,
    isOrganizationAdmin,
    connectedId,
    currentlyConnection,
    selectedConnectionId,
    connectionList,
    connectionMode,
    connectionName,
    connectionInputValues,
    connectionState,
    connectionError,
    connectionErrorTypes,
    connectionErrorInputErrors,
    connectionFooterError,
    connectionInvalidCredentialMessages,
    connectionDiscardDialogIsOpen,
    connectionDiscardType,
    connectionDiscardContinueButtonIsLoading,
    user,
    userLocation,
    resourceType,
    resourceId,
    modalSource,
    currentlyFilteredGatewayType,
    editConnectionData,
    gatewayList,
    closeModal,
    changeStep,
    nextStep,
    backStep,
    gatewayConnect,
    gatewayDisconnect,
    connectionButtonHandler,
    changeConnectionName,
    changeConnectionMode,
    changeConnectionInputValue,
    removeInputError,
    checkUniqueConnectionNameOnBlur,
    fetchConnectionList,
    selectConnection,
    showOrHideDiscardNewConnectionDialog,
    discardNewConnectionDialogContinueButtonProcess,
    changeDiscardedConnectionMode,
    changeSelectedGateway,
    changeChildSelectedGateway,
    changeLoggedUser,
    saveConnectionDataBeforeLoginOrSignup,
    logPaymentEvent,
    fetchGatewayList
  ]);

  return <Context.Provider value={returnValue}>{children}</Context.Provider>;
});

/**
 * Custom hook that returns the gateway connection context.
 * @returns The gateway connection context.
 */
export function useGatewayConnection(): USE_GATEWAY_CONNECTION_CONTEXT {
  return useContext(Context);
}
