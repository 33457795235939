import React, { forwardRef } from 'react';
import type { ComponentPropsWithRef, ElementType, PropsWithChildren } from 'react';
import { Dialog as HeadlessModal } from '@headlessui/react';

import '@jotforminc/jotform.css';
// eslint-disable-next-line no-restricted-imports
import '@jotforminc/jotform.css/animation.css';

import { ModalProps, modalDefaultProps } from './modal.types';
import { PolymorphicProps } from '../../types';
import { modalCVA, modalContainerCVA } from './modal.cva';

export { Portal } from '@headlessui/react';

export const Modal = forwardRef(
  <Element extends ElementType>(
    props: PropsWithChildren<PolymorphicProps<Element, ModalProps>>,
    ref: ComponentPropsWithRef<'div'>['ref']
  ): JSX.Element => {
    const {
      open,
      onClose,
      initialFocus,
      zIndex,
      children,
      ariaLabel,
      size,
      noFade,
      as: _as = 'div',
      fitOnMobile,
      ...rest
    } = props;

    const InnerComponent = _as as ElementType;

    return (
      <HeadlessModal
        onClose={onClose}
        open={open}
        initialFocus={initialFocus}
        style={{ zIndex }}
        className={modalContainerCVA({ fitOnMobile: !!fitOnMobile, noFade })}
        aria-modal={true}
        ref={ref}
        data-magnet-modal
      >
        <InnerComponent
          aria-label={ariaLabel}
          className={modalCVA({ size })}
          {...rest}
          tabIndex={0}
        >
          {children}
        </InnerComponent>
      </HeadlessModal>
    );
  });

Modal.defaultProps = modalDefaultProps;
