import React, { useMemo } from 'react';

const LayoutTypeButton = ({ isSelected, onChange }: IProps): JSX.Element => {
  const currentClassName = useMemo<string>(() => (isSelected ? '  checked' : ''), [isSelected]);

  return (
    <button
      aria-label="Layout Type Button"
      className={`layout-settings-button${currentClassName}`}
      onClick={onChange}
      type="button"
    />
  );
};

export interface IProps {
  isSelected: boolean;
  onChange: () => void;
}

export default LayoutTypeButton;
