/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  func, object, string, bool
} from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { t } from '@jotforminc/translation';

import { IconCaretRight } from '@jotforminc/svg-icons';
import { carouselBreakPoints } from '../constants';
import TemplateItemContainer from './TemplateItem/TemplateItemContainer';

const SubCategoryGalleryItem = ({
  subCategoryObject, handleSelectCategory, templateTheme, username,
  setSelectedTemplate, handleTemplateClone, skeletonActive, type, source
}) => {
  const { templates = [], total, name } = subCategoryObject || {};

  const handleClickCategory = () => {
    if (skeletonActive) return;
    handleSelectCategory({ category: subCategoryObject });
  };

  return (
    <>
      <div className={`carousel-title${!skeletonActive ? ' loaded' : ''}`}>
        <div className="title">
          <h2 onClick={handleClickCategory}><span>{t(name)}</span></h2>
          <span className="title-forms-number">{total}</span>
        </div>
        <div className="all-link" onClick={handleClickCategory}>
          <div className="icon">
            <IconCaretRight width="16" height="16" />
          </div>
          <span>{t('See All')}</span>
        </div>
      </div>
      <Carousel breakPoints={carouselBreakPoints} isRTL={document.documentElement.getAttribute('dir') === 'rtl'}>
        {
          skeletonActive ? (
            Array(3).fill().map(() => (
              <TemplateItemContainer
                context='subCategoryGallery'
                templateTheme={templateTheme}
                source={source}
                type={type}
              />
            ))
          ) : (
            [...templates].map(template => (
              <TemplateItemContainer
                type={type}
                source={source}
                template={template}
                username={username}
                context='subCategoryGallery'
                templateTheme={templateTheme}
                setSelectedTemplate={setSelectedTemplate}
                handleTemplateClone={handleTemplateClone}
              />
            ))
          )
        }
      </Carousel>
    </>
  );
};

export default SubCategoryGalleryItem;

SubCategoryGalleryItem.defaultProps = {
  source: '',
  username: '',
  skeletonActive: false
};

SubCategoryGalleryItem.propTypes = {
  source: string,
  username: string,
  skeletonActive: bool,
  type: string.isRequired,
  templateTheme: string.isRequired,
  setSelectedTemplate: func.isRequired,
  handleTemplateClone: func.isRequired,
  subCategoryObject: object.isRequired,
  handleSelectCategory: func.isRequired
};
