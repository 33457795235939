import Styled from 'styled-components';

const ScResourcePickerControlBar = Styled.div`
  display: flex;
  align-items: center;
  margin: 33px 0 10px;
  border: 1px solid #939BC4;
  border-radius: 4px;

  div[data-sc=smartList-controlBar] {
    flex-grow: 1;
    margin: 0;

    .cb-searchCont .cb-search.isNewBranding {
      background-color: transparent;
    }
  }
`;

export default ScResourcePickerControlBar;
