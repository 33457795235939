export const KEYBOARD_EVENT_KEYS = {
  ENTER: 'Enter',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp'
};

export class KeyboardNavigation {
  constructor(items, keyMap = {}, startIndex = 0) {
    this.items = items;
    this.index = startIndex;
    this.MAX_LENGTH = items.length;
    this.KEY_MAP = {
      [KEYBOARD_EVENT_KEYS.ARROW_UP]: () => this.setIndex(this.index - 1),
      [KEYBOARD_EVENT_KEYS.ARROW_DOWN]: () => this.setIndex(this.index + 1),
      ...keyMap
    };
  }

  setIndex = newIndex => {
    if (newIndex >= 0 && newIndex < this.MAX_LENGTH) {
      this.index = newIndex;
    }

    return this.index;
  };

  getIndex = () => this.index;

  handleKeyEvent = (event, callback) => {
    const keyFunc = this.KEY_MAP[event.key];
    let { index } = this;

    if (keyFunc) {
      index = keyFunc();
    }

    if (typeof callback === 'function') {
      callback(index);
    }
  };
}
