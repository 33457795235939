import React, { useCallback } from 'react';
import {
  func, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import Styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jotforminc/magnet';
import FormItem from '../../../../../../../components/Items/FormItem';
import { ITEM_TYPES } from '../../../../../../../constants/itemTypes';
import SimpleIcon from '../../../../../../../components/Items/SimpleItem/SimpleIcon';
import { mainFontColor } from '../../../../../../../styles/colors';
import * as ACTION_CREATORS from '../../../../../../../store/actionCreators';
import { MODALS } from '../../../../../../../constants/modals';
import SELECTORS from '../../../../../../../store/selectors';

const ScButtonFormSelection = Styled.div`

  .withIconItemContent {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #D3DCEF;
    border-radius: 4px;
    padding: 5px 11px 5px 5px;

    & > div:first-of-type {
      margin: 0;

      svg { fill: #FF6100}
    }
  }

  .itemContent {
    display: flex;
    text-align: left;
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
    margin-left: 5px;

    .title {
      margin: 0;
      padding: 0;
      max-width: 100%;
      font-size: 15px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${mainFontColor};
      display: block;
      font-weight: 500;
    }
  }
`;

const ButtonFormSelection = ({ value, formTitle, onChange }) => {
  const dispatch = useDispatch();
  const { title } = useSelector(SELECTORS.getFormByID(value));
  const handleSelectForm = useCallback(([id]) => onChange(id), [onChange]);

  const handleButtonClick = useCallback(() => {
    const initialSelectedForms = value ? [value] : [];
    dispatch(ACTION_CREATORS.showGenericModalAction({
      name: MODALS.FORM_PICKER_MODAL,
      onConfirm: handleSelectForm,
      useNonPortalForms: false,
      isMultiSelect: false,
      initialSelectedForms,
      onSelectTemplate: (cloneData, element) => dispatch(ACTION_CREATORS.useTemplateAction({ cloneData, element }))
    }));
  }, [handleSelectForm, value]);

  const TitleRenderer = useCallback(({ children, ...rest }) => <div className="title" {...rest}>{children}</div>, [formTitle || title]);
  const IconRenderer = useCallback(() => <SimpleIcon itemType={ITEM_TYPES.FORM} />, []);

  return (
    <ScButtonFormSelection>
      { value ? (
        <>
          <FormItem
            className='formItem'
            title={formTitle || title}
            showIcon={true}
            TitleRenderer={TitleRenderer}
            IconRenderer={IconRenderer}
          />
          <Button
            className="customMagnetBtn mt-2"
            variant="outline"
            fullWidth
            onClick={handleButtonClick}
          >
            {`${t('Change Form')}`}
          </Button>
        </>
      ) : (
        <Button
          className="customMagnetBtn"
          variant="outline"
          fullWidth
          onClick={handleButtonClick}
        >
          {`${t('Select a Form')}`}
        </Button>
      )}
    </ScButtonFormSelection>
  );
};

ButtonFormSelection.propTypes = {
  value: string,
  formTitle: string,
  onChange: func
};

ButtonFormSelection.defaultProps = {
  value: '',
  formTitle: '',
  onChange: f => f
};

export default ButtonFormSelection;
