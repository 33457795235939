import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { capitalizeFirstLetter } from '@jotforminc/utils';

const parseDropdownValue = value => {
  if (value === '') {
    const defaultOptions = window.colorListDefaults;
    return [defaultOptions[0], defaultOptions];
  }
  const options = value.split(',');
  const currentSelectedValue = options.find(opt => opt.includes('*'));
  return [
    currentSelectedValue ? currentSelectedValue.replace('*', '') : options[0],
    options.map(opt => opt.replace('*', ''))
  ];
};

const SchemaDropdown = ({ value, onChange }) => {
  const [selectedValue, options] = parseDropdownValue(value);
  const [dropdownValue, setDropdownValue] = useState(selectedValue);

  const generateDropdownValueText = newValue => {
    let text = '';
    options.forEach(opt => {
      text += opt === newValue ? `${opt}*,` : `${opt},`;
    });
    text = `${text.slice(0, -1)}`;
    return text;
  };

  const handleOnChange = event => {
    const { target } = event;
    const newValue = target.value;
    onChange(generateDropdownValueText(newValue));
    setDropdownValue(newValue);
  };

  const optionPrettier = option => {
    let prettyOption = option.split(' ')[1];
    prettyOption = prettyOption.replace('-', ' ').split(' ').map(opt => capitalizeFirstLetter(opt));
    return prettyOption.join(' ');
  };
  const dropdownOptions = [];
  options.forEach(opt => {
    dropdownOptions.push({ value: opt, text: optionPrettier(opt) });
  });

  return (
    <div className="dropdown-wrapper">
      <select onChange={handleOnChange} value={dropdownValue}>
        { dropdownOptions.map(option => <option value={option.value}>{option.text}</option>) }
      </select>
    </div>
  );
};

SchemaDropdown.propTypes = {
  value: string,
  onChange: func
};

SchemaDropdown.defaultProps = {
  value: '',
  onChange: f => f
};

export default SchemaDropdown;
