import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';
import type { GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES, PAYMENT_FIELDS } from '@jotforminc/payment-constants';

/**
 * Converts a mapped sandbox value to its corresponding actual value based on the gateway type.
 *
 * @param {Object} params - The parameters for the function.
 * @param {PAYMENT_FIELDS} params.gatewayType - The type of the payment gateway.
 * @param {string} params.mappedSandboxValue - The sandbox value to be mapped to the actual value.
 * @returns {string | null} - The actual value corresponding to the sandbox value, or null if not found.
 */
export const sandboxMappedValueToValue = ({
  gatewayType,
  mappedSandboxValue = null
}: {
  gatewayType: PAYMENT_FIELDS,
  mappedSandboxValue: string | null
}): GATEWAY_DEFAULT_SANDBOX_PROP_TYPE_VALUES | null => {
  if (!mappedSandboxValue) {
    return null;
  }

  const { connection: { allowSwitchMode, mode } } = supportedGatewayConnectionPropsList[gatewayType];

  if (allowSwitchMode && mode && mode.options) {
    const { options } = mode;
    const mappedValue = options.find(option => option.mappedValue === mappedSandboxValue);

    if (mappedValue) {
      return mappedValue.value;
    }
  }

  return null;
};
