import { ChoiceTruncate, Size, Theme } from '../../constants/common.types';
import { commonInputDefaults } from '../../contexts';
import { NativePropsWithoutRef } from '../../types/system.types';

type RadioBaseProps = {
  label?: string,
  description?: string
  htmlSize?: number,
  id: string,
  truncate?: ChoiceTruncate,
  theme?: Theme,
  size?: Size,
  error?: boolean,
  name: string,
  value: string
};

export type RadioProps = NativePropsWithoutRef<'input', RadioBaseProps> & RadioBaseProps;

export const radioDefaultProps:Partial<RadioProps> = {
  size: commonInputDefaults.size,
  theme: commonInputDefaults.theme
};
