import { FETCH_FORMS } from '../actionTypes';

const initialState = [];

const FormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FORMS.SUCCESS: {
      return [...action.payload];
    }
    default:
      break;
  }
  return state;
};

export default FormsReducer;
