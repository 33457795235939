import {
  createContext, CSSProperties, ReactNode, useContext
} from 'react';
import { useInteractions } from '@floating-ui/react';
import {
  FormControlColorStyle, Icon, Size, Theme
} from '../constants/common.types';
import { pick } from '../utils';
import { TagProps } from '../components/Tag/tag.types';

const useSelectValue = <ContextTypes extends object | null, PropTypes extends object>(
  ownContext: ContextTypes,
  props: PropTypes,
  pickedContextValues: string[]
): PropTypes => {
  if (!ownContext) {
    return props;
  }

  const pickedContext = pick<any, any>(ownContext, pickedContextValues);
  return { ...props, ...pickedContext };
};

export type FormControlContextType = {
  id?: string
  colorStyle?: FormControlColorStyle,
  size?: Size,
  disabled?: boolean,
  required?: boolean,
  readOnly?: boolean,
  theme?: Theme
}

export const commonInputDefaults:Partial<FormControlContextType> = {
  size: 'medium',
  theme: 'light',
  colorStyle: 'default'
} as const;

export const FormControlContext = createContext<FormControlContextType | null>(null);

type FormControlContextArrayKeys = (keyof FormControlContextType)[];
const contextValuesArray:FormControlContextArrayKeys = ['colorStyle', 'disabled', 'readOnly', 'required', 'size', 'theme', 'id'];

export const useFormControl = <T extends object>(
  props:T,
  pickContextValues:FormControlContextArrayKeys = contextValuesArray
):T & FormControlContextType => {
  const formContext = useContext(FormControlContext);
  return useSelectValue(formContext, props, pickContextValues);
};

export type HandleSelectParams = {
  index: number,
} & DropdownOptionType

export type HandleGroupSelectParams = {
  checked: boolean,
  allOptions: DropdownOptionType[],
  childrenArray: DropdownOptionType[]
}

export type HandleMultiSelect = {
  checked: boolean
} & DropdownOptionType;

export type DropdownOptionType = {
  index?: number,
  icon?: Icon
  value: string | number,
  disabled?: boolean,
  tagStyles?: CSSProperties,
  tagColorStyle?: TagProps['colorStyle'],
  children?: string | ReactNode
}

export type DropdownContextType = {
  activeIndex: number | null,
  selectedIndex: number | null,
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'],
  handleSelect: (args: HandleSelectParams) => void,
  handleGroupSelect: (args: HandleGroupSelectParams) => void,
  handleMultiSelect: (args: HandleMultiSelect) => void,
  multiple?: boolean,
  selectedOptionArray: DropdownOptionType[] | null,
  setSelectedOptionArray: (array: DropdownOptionType[]) => void
} & FormControlContextType;

export type DropdownGroupContextType = {
  indent?: boolean,
}

export const DropdownContext = createContext<DropdownContextType | null>(null);

export const DropdownGroupContext = createContext<DropdownGroupContextType | null>(null);

export const useDropdownContext = ():DropdownContextType => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error('DropdownOption/DropdownGroup should wrap within a Dropdown');
  }
  return context;
};

export const useDropdownGroupContext = ():DropdownGroupContextType => {
  const context = useContext(DropdownGroupContext);
  if (!context) {
    return {};
  }
  return context;
};
