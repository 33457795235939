import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_NEW_PORTAL } from '../../store/actionTypes';
import BuilderLoading from '../../components/BuilderLoading';
import { ErrorScreen } from '../PublicApp/ErrorScreen';
import SELECTORS from '../../store/selectors';

const CreateNewPortal = () => {
  const dispatch = useDispatch();
  const isAppFailed = useSelector(SELECTORS.isAppFailedSelector);

  useEffect(() => { dispatch({ type: CREATE_NEW_PORTAL.REQUEST }); }, []);

  return isAppFailed ? <ErrorScreen errorType={isAppFailed} /> : <BuilderLoading />;
};

export default CreateNewPortal;
