import React, { useCallback, useMemo } from 'react';
import {
  arrayOf, number, oneOfType, shape, string
} from 'prop-types';
import SchemaCard from './SchemaCard';
import SchemaEditable from './SchemaEditable';

const SchemaItem = ({ item, editableItemID, ...rest }) => {
  const { id, fields } = item;

  const isItemEditable = useMemo(() => id === editableItemID, [id, editableItemID]);

  const Item = useCallback(props => {
    return isItemEditable ? (
      <SchemaEditable {...props} />
    ) : (
      <SchemaCard {...props} />
    );
  }, [isItemEditable, item]);

  return <Item id={id} fields={fields} {...rest} />;
};

SchemaItem.propTypes = {
  item: shape({
    id: number,
    fields: arrayOf(shape({}))
  }),
  editableItemID: oneOfType([string, number])
};

SchemaItem.defaultProps = {
  item: {
    id: 0,
    fields: []
  },
  editableItemID: null
};

export default SchemaItem;
