/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Button } from '@jotforminc/magnet';
import { IconGridDotsVertical } from '@jotforminc/svg-icons';

import { SortableDragHandle, SortableItem, isYes } from '../utils';
import { checkMobileOrTablet } from '../utils/domUtils';
import { IActionButtons, ISortableItem } from '../types/common';

type StyledSortableItemType = {
  idx: number,
  item: ISortableItem,
  showItemActions: (id: string | null) => void
  selectedItemId: string | null,
  itemSettingsOpen: boolean,
  actionButtons: IActionButtons,
  settingsActionButtons?: IActionButtons,
  formattedPrice?: string
}

const StyledSortableItem: FunctionComponent<StyledSortableItemType> = ({
  idx,
  item,
  showItemActions,
  selectedItemId,
  itemSettingsOpen,
  actionButtons,
  formattedPrice,
  settingsActionButtons
}) => {
  const isMobileOrTablet = checkMobileOrTablet();

  const {
    pid = null,
    qid = null,
    text = null,
    name = null,
    images = null,
    disabled,
    hidden = null
  } = item;

  const id = pid || qid;

  const isItemSelected = selectedItemId === id;

  const stopPropagation = (e: any) => e.stopPropagation();

  return (
    <SortableItem key={item || qid} index={idx}>
      <div
        id={`item_${id}`}
        onMouseDown={() => isMobileOrTablet && showItemActions(id)}
        className={classNames(
          'productItem',
          'productSortableItem',
          {
            productActionsDisplayed: isItemSelected,
            disabled: ['HIDE', 'hide'].indexOf(disabled as string) > -1,
            'opacity-70': isYes(hidden)
          }
        )}
      >
        {isMobileOrTablet && (
          <SortableDragHandle>
            <div>
              <IconGridDotsVertical fill="#E9ECFF" className="w-6 absolute -left-6 top-2/4 transform -translate-y-2/4 cursor-move" />
            </div>
          </SortableDragHandle>
        )}
        <div className={`${isMobileOrTablet && 'hidden'}`}>
          <IconGridDotsVertical fill="#E9ECFF" className="w-6 absolute -left-6 top-2/4 transform -translate-y-2/4 cursor-move" />
        </div>
        <div className="productItemContent">
          {images && (
            <div className="product-list-item-image">
              {(Array.isArray(images) && images.length > 0) && (
                <img className="item-image" src={images[0]} alt="" />
              )}
            </div>
          )}
          <div className="product-list-item-information">
            <span className="item-name">{name}</span>
            {formattedPrice && (
              <span className="item-price">
                {formattedPrice}
              </span>
            )}
            {text && (
              <span className="item-text">
                {text}
              </span>
            )}
          </div>
        </div>

        {(isMobileOrTablet || itemSettingsOpen) && (
          <div className="productActionButtons">
            {actionButtons.map(button => (
              <Button
                startIcon={button.icon}
                colorStyle={button.colorStyle}
                aria-label={button.title}
                className={button.className}
                onMouseDown={stopPropagation}
                onClick={() => button.action(id)}
              />
            ))}
          </div>
        )}

        {!isMobileOrTablet && (
          <>
            <div className="productItemActions">
              {settingsActionButtons?.length ? (
                settingsActionButtons.map(button => (
                  <Button
                    startIcon={button.icon}
                    colorStyle={button.colorStyle}
                    aria-label={button.title}
                    className={button.className}
                    onMouseDown={stopPropagation}
                    onClick={() => button.action(id)}
                  />
                ))
              ) : (
                actionButtons.map(button => (
                  <Button
                    startIcon={button.icon}
                    colorStyle={button.colorStyle}
                    aria-label={button.title}
                    className={button.className}
                    onMouseDown={stopPropagation}
                    onClick={() => button.action(id)}
                  />
                ))
              )}
            </div>
          </>
        )}
      </div>
    </SortableItem>
  );
};

StyledSortableItem.defaultProps = {
  settingsActionButtons: [],
  formattedPrice: ''
};

export default StyledSortableItem;
