import { t } from '@jotforminc/translation';
import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { classNames } from '@jotforminc/utils';
import { IconCaretDownBars, IconCheck } from '@jotforminc/svg-icons';
import { Dropdown } from './Dropdown';

const SortMenu = ({ sortOnClient, selectedFolder }) => {
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [order, setOrder] = useState(null);

  const getCookie = name => {
    return decodeURIComponent(document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1'
    )) || 'created_at';
  };

  const getCommonSortingOptions = () => ([
    { text: 'Title [a-z]', value: 'title_az' },
    { text: 'Title [z-a]', value: 'title_za' },
    { text: 'Creation Date', value: 'created_at' },
    { text: 'Last Edit', value: 'updated_at' },
    { text: 'Submission Count', value: 'count' },
    { text: 'Unread', value: 'new' },
    { text: 'Last Submission', value: 'last_submission' }
  ]);

  const sortingOptionList = getCommonSortingOptions();
  const defaultOption = order || (sortingOptionList || []).find(({ value }) => getCookie(`${selectedFolder}Sorting`) === value);
  const defaultOptionValue = defaultOption?.value || order;

  const handleOptionsVisible = tmpIsOptionsVisible => {
    if (isOptionsVisible === tmpIsOptionsVisible) {
      return;
    }
    setOptionsVisible(tmpIsOptionsVisible);
  };

  const renderSortOption = val => {
    const { option: { text }, isSelected } = val;
    return (
      <>
        {text}
        {isSelected && <IconCheck className="isCheck" />}
      </>
    );
  };

  const handleSortingSelect = value => {
    setOrder(value);
    sortOnClient(value);
  };

  return (
    <div className={classNames('sb-sortW', isOptionsVisible && 'isOpened')}>
      <IconCaretDownBars className="w-6 left-2 absolute isIcon" />
      <Dropdown
        defaultValue={defaultOptionValue}
        options={sortingOptionList?.map(opt => ({ ...opt, text: t(opt.text) }))}
        onOptionSelect={({ value, key }) => handleSortingSelect(value, key)}
        OptionRenderer={renderSortOption}
        popoverProps={{
          className: 'listings-popover-modal',
          popoverOptions: {
            placement: 'bottom-end'
          }
        }}
        onOptionsVisibilityChanged={handleOptionsVisible}
        placeholder={t('Please select')}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        OptionContainerRenderer={props => <li tabIndex={0} {...props} />}
        optionsAutoFocus={true}
      />
    </div>
  );
};

SortMenu.propTypes = {
  sortOnClient: func,
  selectedFolder: string
};

SortMenu.defaultProps = {
  sortOnClient: f => f,
  selectedFolder: 'allForms'
};

export default SortMenu;
