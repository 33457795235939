import { t } from '@jotforminc/translation';
import {
  IconEnvelopeClosedFilled, IconLinkDiagonal, IconPhoneFilled, IconProductFormBuilderFilled, IconProductPagesFilled, IconXmark
} from '@jotforminc/svg-icons';
import VerticalCard from '../../../../../assets/verticalCardStyle.svg';
import HorizontalCard from '../../../../../assets/horizontalCardStyle.svg';
import ButtonLinkInput from './ButtonActions/components/ButtonLinkInput';
import ButtonMailInput from './ButtonActions/components/ButtonMailInput';
import ButtonPhoneInput from './ButtonActions/components/ButtonPhoneInput';
import ButtonFormSelection from './ButtonActions/components/ButtonFormSelection';
import ButtonPageSelection from './ButtonActions/components/ButtonPageSelection';
import { BUTTON_ROLE_TYPES } from './ButtonActions/buttonRoleTypes';
import ListLayoutHorizontal from '../../../../../assets/listLayoutHorizontal.svg';
import ListLayoutVertical from '../../../../../assets/listLayoutVertical.svg';

export const PROPERTY_ITEM = {
  TEXT_AREA: 'textarea',
  TEXT_INPUT: 'textinput',
  ITEM_STYLER: 'itemStyler',
  SELECTION: 'selection',
  COLOR_PICKER: 'colorPicker',
  TOGGLE: 'toggle',
  IMAGE_UPLOAD: 'imageUpload',
  APP_LOGO: 'appLogo',
  FONT_PICKER: 'fontPicker',
  COLOR_PALETTE: 'colorPalette',
  DOCUMENT_FILE_UPLOAD: 'documentFileUpload',
  THEMES: 'themes',
  FORM_LABEL: 'formLabel',
  IMAGE_UPLOAD_WITH_LOADING: 'imageUploadWithLoading',
  BADGE_TOGGLE: 'badgeToggle',
  LOGIN_REQUIRED_TOGGLE: 'loginRequiredToggle',
  BUTTON_ACTIONS: 'buttonActions',
  CHECKBOX: 'checkbox',
  BUTTON_ROLE_COMPONENTS: 'buttonRoleComponents',
  ITEM_ICON_TOGGLE: 'itemIconToggle',
  APP_FOOTER: 'appFooter',
  // for external widget items
  WIDGET_FILE_UPLOAD: 'widgetFileUpload',
  DROPDOWN: 'dropdown',
  SCHEMA: 'schema',
  SHOW_FORM_TOGGLE: 'showFormToggle',
  SHRINK_TOGGLE: 'shrinkToggle',
  RESOURCE_SELECTION: 'resourceSelection',
  FORM_SELECTION: 'formSelection',
  EXTERNAL: 'external',
  LAYOUTS: 'layouts',
  LINK_INPUT: 'linkInput',
  RANGESLIDER: 'rangeslider',
  DUPLICATION: 'duplication',
  MENU_SETTINGS: 'menuSettings',
  PAGE_SETTINGS: 'pageSettings',
  PRODUCT_LIST_SETTINGS: 'productListSettings',
  PAYMENT_SETTINGS: 'paymentSettings',
  DONATION_SETTINGS: 'donationSettings',
  DONATION_GATEWAY_WARNING_ITEM: 'donationGatewayWarningItem',
  DONATION_BOX_PROPERTIES_PANEL_FIELD: 'donationBoxPropertiesPanelField',
  CONTACT_INFORMATION_SETTINGS: 'contactInformationSettings',
  LIST_ITEM_START: 'listItemStart',
  LIST_ITEM_END: 'listItemEnd',
  SVG_OPTIONS: 'svgOptions',
  CARD_LAYOUT: 'cardLayout',
  ROLES: 'roles',
  DATA_SOURCE_PICKER: 'dataSourcePicker',
  DS_ROLE_COMPONENTS: 'dsRoleComponents',
  DIMENSION_PICKER: 'dimensionPicker'
};

export const CARD_LAYOUT = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

export const CARD_LAYOUT_OPTIONS = [
  {

    value: CARD_LAYOUT.HORIZONTAL,
    Svg: HorizontalCard,
    selectedSvgProps: {},
    label: t('Horizontal')
  },
  {
    value: CARD_LAYOUT.VERTICAL,
    Svg: VerticalCard,
    selectedSvgProps: {},
    label: t('Vertical')
  }
];

export const LIST_LAYOUT_OPTIONS = [
  {
    value: CARD_LAYOUT.HORIZONTAL,
    Svg: ListLayoutHorizontal,
    label: t('List View')
  },
  {
    value: CARD_LAYOUT.VERTICAL,
    Svg: ListLayoutVertical,
    selectedSvgProps: {},
    label: t('Card View')
  }
];

export const CARD_ACTION_VALUES = {
  LINK: BUTTON_ROLE_TYPES.LINK,
  MAIL: BUTTON_ROLE_TYPES.MAIL,
  PHONE: BUTTON_ROLE_TYPES.PHONE,
  NAVIGATION: BUTTON_ROLE_TYPES.NAVIGATION,
  FORM: BUTTON_ROLE_TYPES.FORM,
  NONE: 'none'
};

export const CARD_ACTIONS = [
  {
    value: CARD_ACTION_VALUES.LINK,
    title: t('Visit URL'),
    Icon: IconLinkDiagonal
  },
  {
    value: CARD_ACTION_VALUES.MAIL,
    title: t('Send Email'),
    Icon: IconEnvelopeClosedFilled
  },
  {
    value: CARD_ACTION_VALUES.PHONE,
    title: t('Make Call'),
    Icon: IconPhoneFilled
  },
  {
    value: CARD_ACTION_VALUES.NAVIGATION,
    title: t('Navigate to Page'),
    Icon: IconProductPagesFilled
  },
  {
    value: CARD_ACTION_VALUES.FORM,
    title: t('Open Form'),
    Icon: IconProductFormBuilderFilled
  },
  {
    value: CARD_ACTION_VALUES.NONE,
    title: t('None'),
    Icon: IconXmark
  }
];

export const CARD_ACTION_PROPS = {
  [CARD_ACTION_VALUES.LINK]: {
    title: t('Link'),
    RoleComponent: ButtonLinkInput,
    componentProps: {
      placeholder: t('Enter a link'),
      defaultValue: 'https://'
    }
  },
  [CARD_ACTION_VALUES.MAIL]: {
    title: t('Recipient'),
    RoleComponent: ButtonMailInput,
    componentProps: {
      placeholder: t('Enter an email')
    }
  },
  [CARD_ACTION_VALUES.PHONE]: {
    title: t('Phone Number'),
    RoleComponent: ButtonPhoneInput,
    componentProps: {
      placeholder: '(555) - 555 555'
    }
  },
  [CARD_ACTION_VALUES.FORM]: {
    title: t('Form'),
    RoleComponent: ButtonFormSelection
  },
  [CARD_ACTION_VALUES.NAVIGATION]: {
    title: t('Page'),
    RoleComponent: ButtonPageSelection
  }
};

export const PAGE_TYPES = {
  DATA_SOURCE: 'DATA_SOURCE'
};

export const IMAGE_ACTION_VALUES = {
  LINK: BUTTON_ROLE_TYPES.LINK,
  MAIL: BUTTON_ROLE_TYPES.MAIL,
  PHONE: BUTTON_ROLE_TYPES.PHONE,
  NAVIGATION: BUTTON_ROLE_TYPES.NAVIGATION,
  FORM: BUTTON_ROLE_TYPES.FORM,
  NONE: 'none'
};

export const IMAGE_ACTIONS = [
  {
    value: IMAGE_ACTION_VALUES.LINK,
    title: t('Visit URL'),
    Icon: IconLinkDiagonal
  },
  {
    value: IMAGE_ACTION_VALUES.MAIL,
    title: t('Send Email'),
    Icon: IconEnvelopeClosedFilled
  },
  {
    value: IMAGE_ACTION_VALUES.PHONE,
    title: t('Make Call'),
    Icon: IconPhoneFilled
  },
  {
    value: IMAGE_ACTION_VALUES.NAVIGATION,
    title: t('Navigate to Page'),
    Icon: IconProductPagesFilled
  },
  {
    value: IMAGE_ACTION_VALUES.FORM,
    title: t('Open Form'),
    Icon: IconProductFormBuilderFilled
  },
  {
    value: IMAGE_ACTION_VALUES.NONE,
    title: t('None'),
    Icon: IconXmark
  }
];

export const IMAGE_ACTION_PROPS = {
  [IMAGE_ACTION_VALUES.LINK]: {
    title: t('Link'),
    RoleComponent: ButtonLinkInput,
    componentProps: {
      placeholder: t('Enter a link'),
      defaultValue: 'https://'
    }
  },
  [IMAGE_ACTION_VALUES.MAIL]: {
    title: t('Recipient'),
    RoleComponent: ButtonMailInput,
    componentProps: {
      placeholder: t('Enter an email')
    }
  },
  [IMAGE_ACTION_VALUES.PHONE]: {
    title: t('Phone Number'),
    RoleComponent: ButtonPhoneInput,
    componentProps: {
      placeholder: '(555) - 555 555'
    }
  },
  [IMAGE_ACTION_VALUES.FORM]: {
    title: t('Form'),
    RoleComponent: ButtonFormSelection
  },
  [IMAGE_ACTION_VALUES.NAVIGATION]: {
    title: t('Page'),
    RoleComponent: ButtonPageSelection
  }
};
