import React from 'react';
import type { GATEWAY_QUESTION_PROPERTIES, FORM_TYPES } from '@jotforminc/payment-constants';
import { PaymentPropsPanelProvider } from './Context/usePaymentProperties';
import {
  PaymentConnectionSection,
  GatewayResourceProperties,
  PaymentTypeDropdown,
  CurrencyDropdown,
  FooterContainer
} from './Components';
import '../../../styles/editor.scss';
import {
  RESOURCES,
  TBuilderDropdown,
  T_ONPAYMENTTYPECHANGE,
  T_ONSAVEGATEWAY,
  T_ONDETACHCONNECTION,
  T_ONAUTOSAVEGATEWAY,
  T_GETQIDONADDQUESTION,
  T_TOGGLECONNECTIONMODAL,
  IUser,
  T_BUILDER_BTN_STATUS
} from '../../../types/common';
import Divider from './Components/Divider';

interface IPaymentPropertiesPanel {
  checkoutFormID: string;
  products: object[],
  formType: FORM_TYPES;
  questionProperties: GATEWAY_QUESTION_PROPERTIES;
  BuilderDropdown: TBuilderDropdown;
  BuilderImageUpload: React.ComponentType;
  BuilderToggle: React.ComponentType;
  resource: RESOURCES;
  formQuestions: [];
  user: IUser;
  userIsResourceOwner: boolean;
  onPaymentTypeChange: T_ONPAYMENTTYPECHANGE;
  onSaveGateway: T_ONSAVEGATEWAY;
  onDetachConnection: T_ONDETACHCONNECTION;
  onAutosaveGateway: T_ONAUTOSAVEGATEWAY;
  getQidOnAddQuestion: T_GETQIDONADDQUESTION;
  togglePaymentConnectionModal: T_TOGGLECONNECTIONMODAL;
  updateBuilderButtonStatus: (status: T_BUILDER_BTN_STATUS) => void;
  updateGatewayConfigurationStatus: (val: boolean) => void;
}

const PaymentPropertiesPanel = ({
  checkoutFormID,
  products,
  formType,
  questionProperties,
  BuilderDropdown,
  BuilderToggle,
  BuilderImageUpload,
  resource,
  formQuestions,
  user,
  userIsResourceOwner,
  updateBuilderButtonStatus,
  onPaymentTypeChange,
  onSaveGateway,
  onDetachConnection,
  onAutosaveGateway,
  getQidOnAddQuestion,
  togglePaymentConnectionModal,
  updateGatewayConfigurationStatus
}: IPaymentPropertiesPanel): JSX.Element => {
  const { isPaymentStoreInBasicFields = true } = questionProperties;
  const showPaymentTypeDD = ['FORM'].includes(resource) && !isPaymentStoreInBasicFields;
  return (
    <PaymentPropsPanelProvider
      questionProperties={questionProperties}
      formQuestions={formQuestions}
      checkoutFormID={checkoutFormID}
      products={products}
      formType={formType}
      resource={resource}
      onPaymentTypeChange={onPaymentTypeChange}
      onSaveGateway={onSaveGateway}
      onDetachConnection={onDetachConnection}
      onAutosaveGateway={onAutosaveGateway}
      getQidOnAddQuestion={getQidOnAddQuestion}
      togglePaymentConnectionModal={togglePaymentConnectionModal}
      user={user}
      userIsResourceOwner={userIsResourceOwner}
      updateBuilderButtonStatus={updateBuilderButtonStatus}
      updateGatewayConfigurationStatus={updateGatewayConfigurationStatus}
    >
      <div className={`paymentPropertiesPanel ${resource.toLowerCase()}`}>
        <PaymentConnectionSection />
        <Divider className="my-6" />
        {
          showPaymentTypeDD && (
            <PaymentTypeDropdown
              BuilderDropdown={BuilderDropdown}
              resource={resource}
            />
          )
        }
        <CurrencyDropdown />
        <Divider className="mt-6" />
        <GatewayResourceProperties
          BuilderDropdown={BuilderDropdown}
          BuilderImageUpload={BuilderImageUpload}
          BuilderToggle={BuilderToggle}
        />
        <FooterContainer />
      </div>
    </PaymentPropsPanelProvider>
  );
};

export default PaymentPropertiesPanel;
