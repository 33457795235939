import type { PAYMENT_APM_NAMES, GATEWAY_CONNECTION_PROPS } from '@jotforminc/payment-constants';
import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';

/**
 * Converts the name of an APM (Alternative Payment Method) to the corresponding gateway connection properties.
 *
 * @param apmName - The name of the APM.
 * @returns The gateway connection properties for the specified APM.
 * @throws Error if the APM name is invalid.
 */
export const nameApmToGateway = (apmName: PAYMENT_APM_NAMES): GATEWAY_CONNECTION_PROPS => {
  switch (apmName) {
    case 'Venmo':
      return supportedGatewayConnectionPropsList.control_venmo;
    case 'appleAndGooglePay':
      return supportedGatewayConnectionPropsList.control_applePayGooglePay;
    case 'CashApp':
      return supportedGatewayConnectionPropsList.control_cashApp;
    case 'Afterpay':
      return supportedGatewayConnectionPropsList.control_afterpay;
    case 'Clearpay':
      return supportedGatewayConnectionPropsList.control_clearpay;
    default:
      throw new Error('Invalid APM name.');
  }
};
