import { call } from 'redux-saga/effects';
import React from 'react';
import { createRoot } from 'react-dom/client';
import PortalFeedbackModal from '../../../modules/Builder/components/Modals/PortalFeedbackModal';

export const portalFeedbackModal = ({ onSend, user, feedbackInfo }) => {
  return new Promise((resolve, reject) => {
    const container = document.getElementById('modal-root');
    const root = createRoot(container);
    const handleClose = () => {
      reject();
      root.unmount();
    };
    const handleConfirm = selection => {
      resolve(selection);
      root.unmount();
    };

    root.render(<PortalFeedbackModal
      onCancel={handleClose}
      onConfirm={handleConfirm}
      onSend={onSend}
      user={user}
      feedbackInfo={feedbackInfo}
    />);
  });
};

export function* handlePortalFeedbackModal({
  payload: {
    onCancel = f => f,
    onSend = f => f,
    user = {},
    feedbackInfo
  }
}) {
  try {
    yield call(portalFeedbackModal, { onSend, user, feedbackInfo });
  } catch {
    yield call(onCancel);
  }
}
