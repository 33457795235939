import React from 'react';
import propTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { ScOptionButton, ScSvgOptionRendererContainer, ScOptionLabel } from './styled';

const SvgOptionRenderer = ({ options, onChange, value }) => {
  return (
    <ScSvgOptionRendererContainer>
      {options.map(({
        Svg,
        label,
        value: optionValue,
        selectedSvgProps
      }) => {
        const isSelected = value === optionValue;
        const svgProps = isSelected ? selectedSvgProps : {};
        return (
          <div key={label}>
            <ScOptionButton
              isSelected={isSelected}
              type='button'
              aria-label={t(`${value} layout`)}
              onClick={() => onChange(optionValue)}
            >
              <Svg {...svgProps} />
            </ScOptionButton>
            <ScOptionLabel>{label}</ScOptionLabel>
          </div>
        );
      })}
    </ScSvgOptionRendererContainer>
  );
};

SvgOptionRenderer.propTypes = {
  options: propTypes.array,
  onChange: propTypes.func,
  value: propTypes.string
};

SvgOptionRenderer.defaultProps = {
  options: [],
  onChange: f => f,
  value: ''
};

export default SvgOptionRenderer;
