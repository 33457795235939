import React, { useCallback } from 'react';
import { t } from '@jotforminc/translation';
import {
  func, oneOf, string, element
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jotforminc/magnet';
import { handleCustomNavigation } from '@jotforminc/utils';
import { MODALS } from '../../../../../../constants/modals';
import { ITEM_TYPES, RESOURCE_TYPES } from '../../../../../../constants/itemTypes';
import { generateResourceURL, getReportTypeFromResourceURL } from '../../../../../../utils/navigation';
import SimpleItem from '../../../../../../components/Items/SimpleItem/SimpleItem';
import SimpleIcon from '../../../../../../components/Items/SimpleItem/SimpleIcon';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import SELECTORS from '../../../../../../store/selectors';
import ScResourceSelection from './ScResourceSelection';
import ScActionButtons from '../FormSelection/ScActionButtons';
import { ScLineHr } from '../ScLineHr';

const resourceDataMap = {
  [RESOURCE_TYPES.TABLE]: {
    name: 'Table',
    color: '#0D953B',
    hoverColor: '#11AE47',
    firstButtonText: 'View',
    navigatePath: 'tables'
  },
  [RESOURCE_TYPES.SENTBOX]: {
    name: 'Form',
    color: '#249BB4'
  },
  [RESOURCE_TYPES.REPORT]: {
    name: 'Report',
    color: '#728DDD',
    hoverColor: '#5369AB',
    firstButtonText: 'Edit',
    navigatePath: 'reports'
  },
  [RESOURCE_TYPES.SIGN]: {
    name: 'Sign Document',
    color: '#78BB07',
    hoverColor: '#94CD2A',
    firstButtonText: 'Edit',
    navigatePath: 'sign'
  }
};

export const DefaultViewButton = ({ children, text, ...rest }) => {
  return (
    <Button
      className="customMagnetBtn firstBtn"
      {...rest}
    >
      {children ?? text}
    </Button>
  );
};

DefaultViewButton.propTypes = {
  text: string,
  children: element
};

DefaultViewButton.defaultProps = {
  text: '',
  children: undefined
};

const ResourceSelection = ({
  value: resourceID, resourceType, title, resourceTitle, description, onChange, type, id: selectionID, handleNavigation: _handleNavigation, ViewButton, viewID
}) => {
  const dispatch = useDispatch();
  const appID = useSelector(SELECTORS.getAppID);
  const { title: dsResourceTitle } = useSelector(SELECTORS.dsGetColumnsByResourceID({ resourceID, viewID }));
  const onResourcePickerConfirm = useCallback(({
    selectedResourcesArray: [{
      id, name: originalName, title: originalTitle, type: resourceSubType, formID: selectedFormID
    }]
  }) => {
    const resourceURL = generateResourceURL(resourceType, id, appID, resourceSubType);
    onChange({
      resourceID: id,
      resourceTitle: '',
      title: originalName || originalTitle,
      resourceURL,
      ...resourceType === RESOURCE_TYPES.SIGN && { formID: selectedFormID },
      ...resourceType === RESOURCE_TYPES.REPORT && { resourceSubType: resourceSubType ?? getReportTypeFromResourceURL(resourceURL) }
    });
  }, [resourceType, appID]);

  const handleChangeResourceClick = useCallback(() => {
    if (type === ITEM_TYPES.LIST) {
      dispatch(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.DATA_SOURCE_TABLE_PICKER_MODAL,
        resourceID,
        onPropChange: onChange,
        itemID: selectionID?.split('_')?.[1],
        viewID,
        resourceFetcherFilters: {
          includeShare: 1
        }
      }));
      return;
    }
    dispatch(ACTION_CREATORS.showGenericModalAction({
      name: MODALS.RESOURCE_PICKER_MODAL,
      resourceType: resourceType,
      onConfirm: onResourcePickerConfirm,
      selectedResources: [resourceID],
      isMultiSelect: false
    }));
  }, [resourceType, onResourcePickerConfirm, resourceID]);

  const {
    name: resourceName, color: iconFillColor, hoverColor: buttonBgHoverColor, firstButtonText: buttonText, navigatePath: path
  } = resourceDataMap[resourceType];
  const TitleRenderer = useCallback(({ children, ...rest }) => <div className="title" {...rest}>{children}</div>, []);
  const IconRenderer = useCallback(() => <SimpleIcon itemType={ITEM_TYPES[`${resourceType}_LINK`]} useResourceSelectionIcons={true} />, [resourceType]);

  const defaultHandleNavigation = () => {
    handleCustomNavigation(`/${path}/${resourceID}`, '_blank');
  };

  const handleNavigation = _handleNavigation?.({
    dispatch,
    itemID: selectionID.split('_')[1],
    navigationFunc: () => defaultHandleNavigation()
  }) ?? defaultHandleNavigation;

  return (
    <ScResourceSelection iconFillColor={iconFillColor}>
      <label htmlFor="resourcePicker" className="resourcePickerLabel">{t(`Connected ${resourceName} `)}</label>
      {description && <p className='resourcePickerDescription'>{t(description)}</p>}
      <div className="resourcePickerSelectionWrapper">
        <SimpleItem
          title={resourceTitle || dsResourceTitle || title}
          showIcon={true}
          TitleRenderer={TitleRenderer}
          IconRenderer={IconRenderer}
          id="resourcePicker"
        />
        <ScLineHr className='resourcePickerLine' />
        <ScActionButtons buttonBgColor={iconFillColor} buttonBgHoverColor={buttonBgHoverColor}>
          {
            buttonText && (
            <ViewButton
              onClick={handleNavigation}
              text={t(`${buttonText} ${resourceName}`)}
              itemID={selectionID.split('_')[1]}
            />
            )
          }
          <Button
            onClick={handleChangeResourceClick}
            variant='outline'
            fullWidth
            className='customMagnetBtn text-capitalize'
          >
            {t(`Change ${resourceName}`)}
          </Button>
        </ScActionButtons>
      </div>
    </ScResourceSelection>
  );
};

ResourceSelection.propTypes = {
  value: string,
  title: string,
  resourceTitle: string,
  resourceType: oneOf(Object.values(RESOURCE_TYPES)),
  onChange: func,
  type: string,
  id: string.isRequired,
  handleNavigation: func,
  ViewButton: element,
  viewID: string,
  description: string
};

ResourceSelection.defaultProps = {
  value: '',
  title: '',
  resourceTitle: '',
  resourceType: RESOURCE_TYPES.SENTBOX,
  onChange: f => f,
  type: '',
  handleNavigation: undefined,
  ViewButton: DefaultViewButton,
  viewID: '',
  description: ''
};

export default ResourceSelection;
