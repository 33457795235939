export const getImageDimensions = url => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-undef
  const img = new Image();
  img.onload = () => {
    resolve({ width: img.naturalWidth, height: img.naturalHeight });
  };
  img.onerror = () => {
    reject(new Error('Failed to load image'));
  };
  img.src = url;
});

export const handleImageWithDimensions = async (imageURL, maxWidth = 640) => {
  const { width, height } = await getImageDimensions(imageURL);
  const proportion = width / height;
  const adjustedWidth = width > maxWidth ? maxWidth : width;
  const adjustedHeight = adjustedWidth / proportion;

  return {
    proportion,
    width: Math.round(adjustedWidth),
    height: Math.round(adjustedHeight)
  };
};
