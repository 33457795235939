import React, { ReactElement } from 'react';

const Divider = ({
  className
}: { className: string }): ReactElement => {
  return (
    <div className={`h-px bg-gray-700 ${className}`} />
  );
};

export default Divider;
