import React from 'react';
import type { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';

import { modalBodyDefaultProps, ModalBodyProps } from './modal.types';
import { modalBodyCVA } from './modal.cva';

export const ModalBody = (props: PropsWithChildren<ModalBodyProps>): JSX.Element => {
  const {
    children, className, noPadding, ...rest
  } = props;
  return (
    <div {...rest} className={cx(className, modalBodyCVA({ noPadding }))}>{children}</div>
  );
};

ModalBody.defaultProps = modalBodyDefaultProps;
