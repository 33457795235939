import { cva } from 'class-variance-authority';
import { primary } from '../../tokens/colors';
import { CVAType } from '../../types/system.types';
import { LinkProps } from './link.types';

// CVA Types
export type LinkCVAType = CVAType<Pick<LinkProps, 'colorStyle' | 'disabled'>>

// Classes
export const linkCVA = cva<LinkCVAType>(
  'magnet-link duration-300 outline-2 outline-transparent focus:outline-opacity-50 hover:underline', {
    variants: {
      disabled: {
        true: 'cursor-not-allowed pointer-events-none color-navy-200'
      },
      colorStyle: {}
    },
    compoundVariants: [
      // Color Style
      {
        colorStyle: 'primary',
        disabled: false,
        className: [
          primary.accent.default.color.base,
          primary.accent.dark.color.hover,
          'focus:outline-blue-200'
        ]
      },
      {
        colorStyle: 'success',
        disabled: false,
        className: [
          primary.success.default.color.base,
          primary.success.dark.color.hover,
          'focus:outline-green-300'
        ]
      },
      {
        colorStyle: 'error',
        disabled: false,
        className: [
          primary.error.default.color.base,
          primary.error.dark.color.hover,
          'focus:outline-red-300'
        ]
      }
    ]
  }
);
