import React from 'react';
import {
  string, node, func, shape, bool
} from 'prop-types';
import isString from 'lodash/isString';
import { TextInput } from '@jotforminc/uikit';
import DomPurify from 'dompurify';

export const Label = ({
  htmlFor,
  label,
  children,
  description,
  valueList,
  errorList,
  elementID,
  componentTypeList,
  wrapperClassName,
  required,
  ...props
}) => {
  const errorMsg = errorList[elementID];
  return (
    <label className={`formWizard-wrapper ${errorMsg ? 'isErrored' : ''}`} htmlFor={htmlFor} {...props}>
      <div className="formWizard-label">
        <div className="formWizard-label-text">
          {label}
          {required && <span className="formWizard-label-required">*</span>}
        </div>
        {description && (
          isString(description) ? (
            <div className="formWizard-label-description" dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(description, { ADD_ATTR: ['target'] }) }} />
          ) : (
            <div className="formWizard-label-description">{description}</div>
          )
        )}
      </div>
      <div className={`formWizard-component${wrapperClassName ? ` ${wrapperClassName}` : ''}`}>
        {children}
        {errorMsg ? (
          <div className="formWizard-error">
            <div className="formWizard-error-text">
              {errorMsg}
            </div>
          </div>
        ) : null }
      </div>
    </label>
  );
};

Label.propTypes = {
  label: node,
  description: node,
  elementID: string.isRequired,
  htmlFor: string.isRequired,
  children: node.isRequired,
  valueList: shape({}).isRequired,
  errorList: shape({}).isRequired,
  componentTypeList: shape({}).isRequired,
  required: bool,
  wrapperClassName: string
};

Label.defaultProps = {
  label: '',
  description: '',
  required: false,
  wrapperClassName: null
};

export const Input = ({
  id, onChange, onError, isErrored, valueList, errorList, componentTypeList, ...props
}) => {
  const handleValueChange = (event, ...parameters) => onChange(event.target.value, event, ...parameters);

  return (
    <TextInput
      id={id}
      {...props}
      data-testid={`input-${id}`}
      onChange={handleValueChange}
    />
  );
};
Input.propTypes = {
  id: string.isRequired,
  onError: func.isRequired,
  onChange: func.isRequired,
  isErrored: func,
  valueList: shape({}).isRequired,
  errorList: shape({}).isRequired,
  componentTypeList: shape({}).isRequired
};

Input.defaultProps = {
  isErrored: () => false
};
