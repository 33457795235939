export const STATES = {
  'US States': [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ],
  'US States Abbr': [
    'AL',
    'AK',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
  ],
  'Canadian Provinces': [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon'
  ],
  'Brazilian States Abbr': [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
  ],
  'German States': [
    'Baden-Württemberg',
    'Bavaria',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hesse',
    'Lower Saxony',
    'Mecklenburg-Vorpommern',
    'North Rhine-Westphalia',
    'Rhineland-Palatinate',
    'Saarland',
    'Saxony',
    'Saxony-Anhalt',
    'Schleswig-Holstein',
    'Thuringia'
  ],
  'England Regions': [
    'East Midlands',
    'West Midlands',
    'East of England',
    'London',
    'North West England',
    'North East England',
    'South East England',
    'South West England',
    'Yorkshire and the Humber'
  ],
  'Australia States': [
    'New South Wales',
    'Victoria',
    'Queensland',
    'South Australia',
    'Western Australia',
    'Tasmania'
  ],
  'Austria States': [
    'Vienna',
    'Lower Austria',
    'Upper Austria',
    'Styria',
    'Salzburg',
    'Tyrol',
    'Carinthia',
    'Vorarlberg',
    'Burgenland'
  ],
  'France Regions': [
    'Grand Est',
    'Hauts-de-France',
    'Île-de-France',
    'Normandy',
    'Brittany',
    'Pays de la Loire',
    'Centre-Val de Loire',
    'Burgundy-Franche-Comté',
    'Auvergne-Rhône-Alpes',
    'Occitanie',
    'Nouvelle-Aquitaine',
    'Provence-Alpes-Côte d\'Azur',
    'Corsica'
  ],
  'Italy Regions': [
    'Abruzzo',
    'Basilicata',
    'Calabria',
    'Campania',
    'Emilia-Romagna',
    'Friuli-Venezia Giulia',
    'Lazio',
    'Liguria',
    'Lombardy',
    'Marche',
    'Molise',
    'Piedmont',
    'Apulia',
    'Sardinia',
    'Sicily',
    'Tuscany',
    'Trentino-Alto Adige/South Tyrol',
    'Umbria',
    'Aosta Valley',
    'Veneto'
  ],
  'Spain Regions': [
    'Andalusia',
    'Aragon',
    'Asturias',
    'Balearic Islands',
    'Canary Islands',
    'Cantabria',
    'Castilla-La Mancha',
    'Castile and León',
    'Catalonia',
    'Extremadura',
    'Galicia',
    'La Rioja',
    'Community of Madrid',
    'Region of Murcia',
    'Chartered Community of Navarre',
    'Basque Country',
    'Valencian Community'
  ],
  'Netherlands Provinces': [
    'Drenthe',
    'Flevoland',
    'Friesland',
    'Gelderland',
    'Groningen',
    'Limburg',
    'North Brabant',
    'North Holland',
    'Overijssel',
    'Utrecht',
    'Zeeland',
    'South Holland'
  ]
};

export const STATE_OPTIONS = {
  americanStates: 'US States',
  americanStatesAbbr: 'US States Abbr',
  canadianProvinces: 'Canadian Provinces',
  brazilianStatesAbbr: 'Brazilian States Abbr',
  germanStates: 'German States',
  englandRegions: 'England Regions',
  australiaStates: 'Australia States',
  austriaStates: 'Austria States',
  franceRegions: 'France Regions',
  italyRegions: 'Italy Regions',
  spainRegions: 'Spain Regions',
  netherlandsProvinces: 'Netherlands Provinces'
};
