import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ label, checked, ...props }) => {
  return (
    <label className={`jfSidebar--inputRadio ${checked ? 'checked' : ''}`}>
      <input
        type="radio"
        checked={checked}
        {...props}
      />
      {label && <span>{label}</span>}
    </label>
  );
};

RadioButton.defaultProps = {
  label: null,
  checked: false
};

RadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool
};

export default RadioButton;
