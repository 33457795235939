import React, {
  useState, useMemo, useEffect, forwardRef, useImperativeHandle
} from 'react';
import {
  elementType, shape, objectOf, func, string, oneOfType, bool
} from 'prop-types';
import sortBy from 'lodash/sortBy';

import FieldLine from './FieldLine';
import { Label, Input } from './defaultComponents';

import { initializeValueList, generateListUpdaterByMethod, extendProps } from '../utils/helper';
import { withErrorProvider, useErrors } from './errorContext';

const FormWizard = forwardRef(({
  elementList,
  componentTypeList,
  valueList: BaseValueList,
  defaultValue: BaseDefaultValue,
  onChange: BaseOnChange,
  onError: BaseOnError,
  onWarning: BaseOnWarning,
  LabelRenderer: BaseLabelRenderer,
  ComponentRenderer: BaseComponentRenderer,
  isEncrypted,
  allowErrorSideEffects
}, ref) => {
  const elementListMap = useMemo(() => sortBy(Object.entries(elementList), ([, { order }]) => order), [elementList]);
  const [valueList, setValueList] = useState(BaseValueList || BaseDefaultValue || initializeValueList(elementListMap));
  const {
    errorList, setFieldError, setErrorList, warningList, setFieldWarned, setWarningList
  } = useErrors();

  const onChangeGenerator = generateListUpdaterByMethod({
    list: valueList,
    setList: setValueList,
    methodKey: 'onChange',
    baseMethod: BaseOnChange
  });

  const elementListMapWithValues = elementListMap.map(([key, {
    type,
    condition = () => true,
    labelProps = {},
    componentProps = {},
    ComponentRenderer,
    LabelRenderer,
    static: isStaticField,
    noLabel,
    ref: fieldLineRef,
    updateKey,
    isCompositeFields
  }]) => {
    const getOnChangeListener = () => {
      switch (true) {
        case isStaticField:
          return f => f;
        case isCompositeFields:
          return BaseOnChange;
        default:
          return onChangeGenerator(updateKey || key, componentProps);
      }
    };

    const onFieldError = error => setFieldError(key, error);
    const onFieldWarning = warning => setFieldWarned(key, warning);
    const onChange = getOnChangeListener();
    const TypeBasedComponentRenderer = componentTypeList[type];
    const baseProps = {
      valueList, errorList, warningList, componentTypeList
    };
    const baseValue = BaseValueList
      ? { value: componentProps.value || valueList[key] || undefined }
      : { defaultValue: componentProps.defaultValue || (BaseDefaultValue ? BaseDefaultValue[key] : undefined) };

    const labelPropsWithValues = extendProps({ props: labelProps, ...baseProps });
    const componentPropsWithValues = extendProps({
      props: componentProps,
      onChange,
      setValueList,
      errorList,
      warningList,
      setErrorList,
      setWarningList,
      onFieldError,
      onFieldWarning,
      ariaLabel: componentProps.ariaLabel ? componentProps.ariaLabel : labelPropsWithValues.label,
      ...baseProps,
      ...baseValue,
      isEncrypted
    });

    return [key, {
      condition,
      fieldLineRef,
      noLabel,
      labelPropsWithValues,
      componentPropsWithValues,
      LabelRenderer,
      ComponentRenderer,
      TypeBasedComponentRenderer
    }];
  });

  const validate = () => {
    const currentErrorList = elementListMapWithValues.reduce((prev, [key, { componentPropsWithValues: { isErrored } = {} }]) => ({
      ...prev,
      [key]: isErrored ? isErrored(valueList[key]) : false
    }), {});
    const currentWarningList = elementListMapWithValues.reduce((prev, [key, { componentPropsWithValues: { isWarned } = {} }]) => ({
      ...prev,
      [key]: isWarned ? isWarned(valueList[key]) : false
    }), {});
    setWarningList(currentWarningList);
    setErrorList(currentErrorList);
    return currentErrorList;
  };

  useImperativeHandle(ref, () => ({
    validate
  }));

  useEffect(() => {
    setValueList({ ...valueList, ...BaseValueList });
  }, [BaseValueList]);

  useEffect(() => {
    if (allowErrorSideEffects) {
      validate();
    }
  }, [valueList, allowErrorSideEffects]);

  useEffect(() => {
    const ownErrors = Object.keys(elementList).reduce((prev, current) => {
      return {
        ...prev,
        [current]: errorList[current]
      };
    }, {});
    BaseOnError(ownErrors);
  }, [errorList]);

  useEffect(() => {
    const ownWarnings = Object.keys(elementList).reduce((prev, current) => {
      return {
        ...prev,
        [current]: warningList[current]
      };
    }, {});
    BaseOnWarning(ownWarnings);
  }, [warningList]);

  return elementListMapWithValues.map(([key, {
    fieldLineRef,
    condition,
    noLabel,
    labelPropsWithValues,
    LabelRenderer,
    componentPropsWithValues,
    ComponentRenderer,
    TypeBasedComponentRenderer
  }]) => {
    const isConditionSatisfied = condition(valueList, errorList, warningList);
    if (!isConditionSatisfied) {
      if (errorList[key]) {
        setFieldError(key, false);
      }
      return;
    }

    return (
      <FieldLine
        id={key}
        key={key}
        ref={fieldLineRef}
        noLabel={noLabel}
        labelProps={labelPropsWithValues}
        componentProps={componentPropsWithValues}
        LabelRenderer={LabelRenderer || BaseLabelRenderer}
        ComponentRenderer={ComponentRenderer || TypeBasedComponentRenderer || BaseComponentRenderer}
      />
    );
  });
});

FormWizard.propTypes = {
  elementList: objectOf(shape({
    type: string,
    componentProps: oneOfType([shape({}), func]),
    labelProps: oneOfType([shape({}), func]),
    condition: func,
    LabelRenderer: elementType,
    ComponentRenderer: elementType
  })),
  componentTypeList: objectOf(elementType),
  defaultValue: shape({}),
  valueList: shape({}),
  onChange: func,
  onError: func,
  onWarning: func,
  LabelRenderer: elementType,
  ComponentRenderer: elementType,
  isEncrypted: bool,
  allowErrorSideEffects: bool
};

FormWizard.defaultProps = {
  elementList: {},
  componentTypeList: {},
  valueList: undefined,
  defaultValue: undefined,
  onChange: f => f,
  onError: f => f,
  onWarning: f => f,
  LabelRenderer: Label,
  ComponentRenderer: Input,
  isEncrypted: false,
  allowErrorSideEffects: false
};

export const FormWizardWithoutErrorProvider = FormWizard;
export default withErrorProvider(FormWizard);
