import React, { useCallback, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import { Button } from '@jotforminc/magnet';
import { IconImageArrowUpFilled } from '@jotforminc/svg-icons';
import { Utils } from '@jotforminc/uikit';
import { isMultiSelection } from '../../utils';

const FileInput = ({
  putFileInput, translate, buttonText, onClick, ...inputProps
}) => {
  const inputRef = useRef();
  const isBrowserMsEdge = global.isMsEdge && global.isMsEdge === true;
  const htmlFor = isBrowserMsEdge ? 'dummyHtmlForElement' : null;
  const handleLabelClick = () => isBrowserMsEdge && inputRef.current && inputRef.current.click();
  const handlelInputClick = useCallback(e => {
    const multipleSelectionMode = isMultiSelection(e);
    if (!multipleSelectionMode) return onClick(e);

    e.preventDefault();
  }, []);

  const handleLabelKeyDown = event => Utils.isPressedKeyEnterOrSpace(event) && handleLabelClick();
  const onUploadBtnClick = () => inputRef.current.click();

  return (
    <>
      <Button
        htmlFor={htmlFor}
        onClick={() => {
          handleLabelClick();
          onUploadBtnClick();
        }}
        onKeyDown={handleLabelKeyDown}
        startIcon={IconImageArrowUpFilled}
        className='fileInputBtn min-h-8 h-auto py-3 text-capitalize'
        type='button'
      >
        {translate(buttonText)}
      </Button>
      {putFileInput && (
        <input
          type="file"
          ref={inputRef}
          {...inputProps}
          onClick={handlelInputClick}
          className='w-0 opcity-0 pointer-events-none'
        />
      )}
    </>
  );
  /* eslint-enable */
};

FileInput.propTypes = {
  buttonText: string,
  putFileInput: bool,
  translate: func,
  onClick: func
};

FileInput.defaultProps = {
  buttonText: 'Upload File',
  putFileInput: true,
  translate: f => f,
  onClick: f => f
};

export default FileInput;
