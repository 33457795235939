import React from 'react';
import { SelectionGroup as UiKitSelectionGroup } from '@jotforminc/uikit';

const ContainerRenderer = props => <ul {...props} className="selection-group-container" />;
const OptionContainerRenderer = props => <li {...props} className="selection-group-option-container" />;

const SelectionGroup = properties => (
  <UiKitSelectionGroup
    ContainerRenderer={ContainerRenderer}
    OptionContainerRenderer={OptionContainerRenderer}
    {...properties}
  />
);

SelectionGroup.propTypes = UiKitSelectionGroup.propTypes;
SelectionGroup.defaultProps = UiKitSelectionGroup.defaultProps;

export default SelectionGroup;
