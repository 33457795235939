import { ErrorBase } from './utils/error-base';

export type SubscriptionErrorName =
  | 'DeviceNotSupported'
  | 'NoVapidKey'
  | 'NoSwRegistration'
  | 'PermissionDenied'
  | 'PermissionDefault'

export class SubscriptionError extends ErrorBase<SubscriptionErrorName> {}
