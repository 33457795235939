import Styled from 'styled-components';

const ScResourceSelection = Styled.div`
  .resourcePickerLabel {
    display: inline-block;
    width: auto;
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-bottom: 0.4em;
  }
  .resourcePickerDescription {
    color: #A0A6B6;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .resourcePickerSelectionWrapper {
    background-color: #292D3C;
    padding: 16px;
    .withIconItemContent {
      border: none;
      padding: 0;
    }
    .resourcePickerLine {
      display: block;
      border-color: #434A60;
    }
  }
  .withIconItemContent {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid rgba(200, 206, 237, 0.10);
    border-radius: 4px;
    padding: 5px 11px 5px 5px;

    & > div:first-of-type {
      margin: 0;

      svg {
        ${({ iconFillColor }) => (iconFillColor ? `
        color: ${iconFillColor};
      ` : '')}
      }
    }
  }

  .customMagnetBtn {
    flex: 1;
    border: none;
  }

  .itemContent {
    display: flex;
    text-align: left;
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
    margin-left: 5px;

    .title {
      margin: 0;
      padding: 0;
      max-width: 100%;
      font-size: 15px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
      display: block;
      font-weight: 500;
    }
  }
`;

export default ScResourceSelection;
