/* eslint-disable max-len */
import Styled, { css } from 'styled-components';

const ScCheckoutFormHeader = Styled.div`
  display: flex;
  flex-direction: column;

  .checkout-form-header {
    display: flex;
    align-items: center;
    margin: 24px 20px 0;
    padding: 0 0 24px 0;
    border-bottom: 1px solid #C8CEED;

    &-text {
      font-size: 16px;
      color: #343C6A;
      font-weight: 500;
      margin-left: 8px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .checkoutCloseBtn {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #E3E5F5;
    border-radius: 50%;
    svg {
      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
      width: 30px;
      height: 30px;
      color: #000;
    }
  }


  @media screen and (max-width: 480px) {
    .checkout-form-header {
      padding: 0 0 16px 0;
    }
  }

  ${({ previewMode }) => (previewMode
    ? css`
      background-color: #fff;
      border-radius: 6px 6px 0;
      position: relative;
      bottom: -4px;
    `
    : css``)};
`;

export default ScCheckoutFormHeader;
