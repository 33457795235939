import React from 'react';
import { t } from '@jotforminc/translation';
import { DATA_SOURCE_ITEMS } from '../../../../../constants/itemTypes';
import Footer from '../FormPickerModal/Footer';

export const COMPONENT_KEYS = {
  FOOTER: 'Footer',
  HEADER: 'Header'
};

const getDefaultElement = (key, props) => {
  switch (key) {
    case COMPONENT_KEYS.FOOTER: {
      return <Footer {...props} />;
    }

    case COMPONENT_KEYS.HEADER: {
      return (
        <>
          <div className="pm-t">{t(props.modalTitle)}</div>
          <p className="pm-d">{t(props.modalDescription)}</p>
        </>
      );
    }

    default: {
      return <></>;
    }
  }
};

export const decideElementByType = (type, key, props) => {
  if (!DATA_SOURCE_ITEMS.includes(type)) {
    return getDefaultElement(key, props);
  }
};
