import React, { useRef } from 'react';
import { ImageUpload as JFImageUpload, ImageUploadWithViewer } from '@jotforminc/image-upload';
import { t } from '@jotforminc/translation';
import { Popover } from '@jotforminc/uikit';
import {
  arrayOf,
  bool,
  elementType,
  func, number, object, oneOfType, shape, string
} from 'prop-types';
import ImagePreview from '../../ImagePreview';
import PanelItemDescriptionRenderer from './PanelItemDescriptionRenderer';
import { IMAGE_GALLERY_LIST } from '../constants';
import ChooseFileButton from './ChooseFileButton';
import useRpImageUploadManager from '../../../../../utils/hooks/useRpImageUploadManager';

const RPImageUpload = ({
  value,
  onChange,
  defaultValue,
  targetRef,
  imagePreviewLayout,
  allowImageHint,
  description,
  DescriptionRenderer,
  multipleFileUpload,
  allowMultipleSelection,
  renderTabs,
  galleryImageList,
  sortable,
  offsetX,
  offsetY,
  autoRetrieveDimensions
}) => {
  const imageUploadPopoverRef = useRef();
  const hasUploadedBefore = value !== (!autoRetrieveDimensions ? defaultValue : defaultValue.imageURL);
  const {
    handleRemoveImage,
    handleItemLoading,
    handleImageUpload,
    setImageUploadVisible,
    handleOnChange,
    isImageUploadVisible
  } = useRpImageUploadManager({
    imageUploadPopoverRef,
    value,
    onChange,
    defaultValue,
    targetRef,
    allowMultipleSelection,
    autoRetrieveDimensions,
    multipleFileUpload
  });

  if (hasUploadedBefore && !allowMultipleSelection) {
    return (
      <ImagePreview
        imageURL={value}
        onRemove={handleRemoveImage}
        layout={imagePreviewLayout}
        {...allowImageHint && { imageHint: value }}
      />
    );
  }

  if (allowMultipleSelection) {
    return (
      <ImageUploadWithViewer
        imageUploadProps={{
          galleryImageList: galleryImageList,
          onFileUploading: handleItemLoading,
          forceSelect: false,
          renderTabs: renderTabs,
          layout: imagePreviewLayout
        }}
        isSortable={sortable === 'enabled'}
        defaultValue={value ? value.split('\n') : []}
        onChange={res => onChange(res.join('\n'))}
        buttonText={t('Choose Images')}
      />
    );
  }

  return (
    <>
      <ChooseFileButton onClick={() => setImageUploadVisible(!isImageUploadVisible)} />
      {isImageUploadVisible && (
        <Popover
          ref={imageUploadPopoverRef}
          targetRef={targetRef}
          style={{
            zIndex: '99',
            width: '91%',
            padding: '0'
          }}
          popoverOptions={{
            placement: 'bottom-start',
            modifiers: [
              { name: 'offset', options: { offset: [offsetX, offsetY] } }
            ]
          }}
        >
          <JFImageUpload
            onImageSelect={handleOnChange}
            onImageUpload={handleImageUpload}
            onImageRemove={handleRemoveImage}
            onLinkTextInputSubmit={handleOnChange}
            renderTabs={renderTabs}
            useAPI={true}
            forceSelect={false}
            onFileUploading={handleItemLoading}
            multipleFileUpload={multipleFileUpload}
            allowMultipleSelection={allowMultipleSelection}
            galleryImageList={galleryImageList}
          />
        </Popover>
      )}
      {description && <DescriptionRenderer description={description} />}
    </>
  );
};

RPImageUpload.propTypes = {
  onChange: func.isRequired,
  defaultValue: oneOfType([
    string,
    object
  ]),
  value: string.isRequired,
  targetRef: shape({}).isRequired,
  imagePreviewLayout: string,
  allowImageHint: bool,
  description: string,
  DescriptionRenderer: elementType,
  multipleFileUpload: bool,
  allowMultipleSelection: bool,
  renderTabs: arrayOf(string),
  galleryImageList: arrayOf(string),
  sortable: bool,
  offsetX: number,
  offsetY: number,
  autoRetrieveDimensions: bool
};

RPImageUpload.defaultProps = {
  defaultValue: '',
  imagePreviewLayout: 'column',
  allowImageHint: false,
  description: '',
  DescriptionRenderer: PanelItemDescriptionRenderer,
  multipleFileUpload: false,
  allowMultipleSelection: false,
  renderTabs: ['upload', 'choose', 'link'],
  galleryImageList: IMAGE_GALLERY_LIST,
  sortable: false,
  offsetX: 14,
  offsetY: -20,
  autoRetrieveDimensions: false
};

export default RPImageUpload;
