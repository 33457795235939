/* eslint-disable max-len */
import React from 'react';

const MobilePageBorder = props => {
  return (
    <svg
      width={400}
      height={624}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M392.989 127.632h.759v-.004A6.252 6.252 0 0 1 400 133.88v89.003a6.252 6.252 0 0 1-6.252 6.252h-.759v394.173h-13.936V50.729c0-21.477-17.41-38.887-38.888-38.887H59.831c-21.478 0-38.887 17.41-38.887 38.887v572.579H7.01V276.135h-.76A6.251 6.251 0 0 1 0 269.883v-55.169c0-3.455 2.8-6.252 6.252-6.252h.76v-13.157h-.76A6.252 6.252 0 0 1 0 189.053v-55.17c0-3.454 2.8-6.251 6.252-6.251h.76V47.88C7.011 21.436 28.446 0 54.89 0h290.218c26.444 0 47.88 21.436 47.88 47.88v79.752Z"
          fill="#333343"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h400v623.308H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobilePageBorder;
