import { t } from '@jotforminc/translation';
import { func } from 'prop-types';
import React from 'react';
import { IconCheckCircleFilled } from '@jotforminc/svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Dialog, DialogBody, DialogActions, DialogIcon, DialogDescription, DialogTitle
} from '@jotforminc/magnet';
import SELECTORS from '../../store/selectors';
import { navigateToAction } from '../../store/actionCreators';
import { DESTINATION_TYPES } from '../../constants/navigation';

const AppIsDoneModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const title = useSelector(SELECTORS.getAppTitle);

  const handleConfirm = () => {
    onClose();
    dispatch(navigateToAction({ to: DESTINATION_TYPES.PAGE, pageID: 'homepage' }));
  };

  return (
    <Dialog
      open={true}
      onClose={handleConfirm}
      ariaLabel={t('App is Done Modal')}
    >
      <DialogBody>
        <DialogIcon
          icon={IconCheckCircleFilled}
          colorStyle="success"
        />
        <DialogTitle>{`${t('{appTitle} is completed!').replace('{appTitle}', title)}`}</DialogTitle>
        <DialogDescription>{t('You\'ve completed all required steps.')}</DialogDescription>
      </DialogBody>
      <DialogActions>
        <Button colorStyle="success" variant="filled" onClick={handleConfirm}>{t('Done')}</Button>
      </DialogActions>
    </Dialog>

  );
};

AppIsDoneModal.propTypes = {
  onClose: func
};

AppIsDoneModal.defaultProps = {
  onClose: f => f
};

export default AppIsDoneModal;
