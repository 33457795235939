import React from 'react';

const PwGroupHeader = ({ text }: IProps): JSX.Element => {
  return (
    <div className="pwGroupHeader">{text}</div>
  );
};

export interface IProps {
  text: string
}

export default PwGroupHeader;
