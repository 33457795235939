/* eslint-disable max-len */
/* eslint-disable @jotforminc/no-native-button */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState
} from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  FormControl, InputText, Button, Badge, Dialog, DialogBody, DialogIcon, DialogTitle, DialogDescription, DialogContent, DialogActions, DialogCloseButton
} from '@jotforminc/magnet';
import { useDebounce } from '@jotforminc/hooks';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { handleCustomNavigation } from '@jotforminc/utils';

import {
  IconMagnifyingGlass,
  IconPlus,
  IconCheckCircleFilled,
  IconCircleSmFilled,
  IconXmark,
  IconInfoCircle,
  IconProductAppsColorBorder,
  IconProductFormCardColorBorder,
  IconProductFormBuilderColorBorder,
  IconUserFilled,
  IconXmarkCircle,
  IconExclamationCircle,
  IconProductTeamsBasicFilled,
  IconBuildings
} from '@jotforminc/svg-icons';

/** Data */
import { gatewayDefaultIcons, supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';

/** Component */
import { ResourceOwnerBadge } from '../../../gatewayConnectionManagement/components/core/resourceOwnerBadge';
import { ConnectionGatewayDisabledInEnterprise } from '../core/connectionGatewayDisabledInEnterprise';
import { ConnectionPickerEmptyIllustrationIcon } from '../assets/svgIcons';
import { DisabledConnectionInforms } from '../core/connectionDisableInformation';

/** Context */
import { useGatewayConnection } from '../../../context';

/** Utils */
import { sandboxGetDataConverter, connectionListSearch, nameApmToGateway } from '../../../utils/functions';

/** Types */
import type { CONNECTION_LIST_RESOURCE_DATA } from '../../../types';

/** Styles */
import '../assets/style.css';

/**
 * Renders a connection picker component for selecting a connection from a list.
 *
 * This component displays a list of connections and allows users to select one.
 * It includes search functionality, connection details, and various badges for different types of connections.
 *
 * @returns {React.ReactElement} The rendered connection picker component
 */
export function ConnectionPicker(): any {
  const {
    connectedId,
    selectedConnectionId,
    connectionList,
    currentlyStep,
    currentlyFilteredGatewayType,
    changeStep,
    fetchConnectionList,
    selectConnection,
    user,
    logPaymentEvent,
    isTeamMemberAndCreator,
    gatewayList
  } = useGatewayConnection();

  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [isOpenConnectionResourcesListDialog, setIsOpenConnectionResourcesListDialog] = useState<boolean>(false);
  const [connectionResourcesDialogData, setConnectionResourcesDialogData] = useState<CONNECTION_LIST_RESOURCE_DATA[] | null>(null);
  const [resourcesListDialogTitle, setResourcesListDialogTitle] = useState<string | null>(null);
  const [resourcesListConnectionId, setResourcesListConnectionId] = useState<string>('');

  const logPaymentEventDebounced = useDebounce(logPaymentEvent, 1000);

  useEffect(() => {
    fetchConnectionList();
  }, [fetchConnectionList, currentlyStep]);

  const isShowSearch = useMemo(() => connectionList && connectionList.length > 0, [connectionList]);

  const connectionListData = useMemo(() => connectionListSearch({
    connectedId: connectedId,
    connectionListData: connectionList,
    searchText: searchKey,
    includeGatewayNames: true,
    includeModeSearch: true
  }), [connectedId, connectionList, searchKey]);

  useEffect(() => {
    const isIsUse = connectionList && connectionList.some(connection => connection.inUse === true);
    if (!isIsUse && currentlyFilteredGatewayType !== null && connectionList && connectionList.length > 0) {
      const { type, nameAPM = null } = currentlyFilteredGatewayType;

      const isValidGateway = supportedGatewayConnectionPropsList[type];
      if (!isValidGateway) { return; }

      if (nameAPM) {
        const getApmGateway = nameApmToGateway(nameAPM);
        setSearchKey(getApmGateway.name);
      } else if (type !== 'control_payment') {
        setSearchKey(isValidGateway.name);
      }
    } else {
      setSearchKey(null);
    }
  }, [connectionList, currentlyFilteredGatewayType]);

  const handleCloseResourcesDialog = useCallback(() => {
    setIsOpenConnectionResourcesListDialog(false);
    setConnectionResourcesDialogData(null);
    setResourcesListDialogTitle(null);
  }, []);

  const getIcon = (resourceType: string) => {
    switch (resourceType) {
      case 'STORE':
      case 'DONATION':
        return <IconProductAppsColorBorder className='h-5 w-5 shrink-0' />;
      case 'CARD':
        return <IconProductFormCardColorBorder className='h-5 w-5 shrink-0' />;
      case 'LEGACY':
        return <IconProductFormBuilderColorBorder className='h-5 w-5 shrink-0' />;
      default:
        return null;
    }
  };

  /**
   * Determines whether the "Add New Connection" button should be displayed.
   *
   * @returns {boolean} - Returns `true` if the button should be shown, otherwise `false`.
   *
   * The button is hidden if:
   * - The user is part of an enterprise.
   * - The gateway list is empty or the user is both a team member and the creator.
   */
  const isShowAddNewConnectionButton = (): boolean => {
    if (isEnterprise()) {
      if (Object.keys(gatewayList).length === 0 || isTeamMemberAndCreator) {
        return false;
      }
    }
    return true;
  };

  return (
    <div key="connection-picker" className='min-h-0 flex flex-col'>
      <div className='mb-6'>
        {isShowSearch && (
          <FormControl className="relative">
            <InputText
              type="text"
              role="searchbox"
              placeholder={t('Search connections')}
              prefix={{
                as: 'span',
                icon: IconMagnifyingGlass,
                variant: 'ghost'
              }}
              className='gatewaySearchArea border-navy-100 hover:border-blue-500'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchKey(e.target.value || null);
                // log entry
                if (e.target.value !== '') { logPaymentEventDebounced('search-connections', e.target.value); }
              }}
              value={searchKey || ''}
              data-test-id='connection_search'
            />
            {searchKey && (
              <div className='flex items-center shrink-0 mr-2 absolute absolute right-1 top-0' style={{ marginTop: '10px' }} data-test-id='clear_input'>
                <IconXmark className='h-5 w-5 color-navy-700 cursor-pointer' onClick={() => setSearchKey(null)} />
              </div>
            )}
          </FormControl>
        )}
      </div>

      {
        searchKey && connectionListData.length === 0 ? (
          <div className="flex flex-col items-center justify-center pb-1">
            <span className='mb-4'>
              <IconMagnifyingGlass className='color-navy-300 h-10 w-10' />
            </span>

            <h5 className='font-bold text-lg color-navy-500 m-0'>{t('No results found.')}</h5>
            <span className='color-navy-300 text-md line-height-xl tracking-md font-normal'>
              {t('Sorry, we couldn’t find what you’re looking for in your connection list.')}
            </span>
          </div>
        ) : (
          <div className='overflow-auto scrollerColor' style={{ maxHeight: '25rem' }}>
            {
              !connectionListData || connectionListData.length === 0 ? (
                <div className='flex flex-col items-center justify-center text-center'>
                  <ConnectionPickerEmptyIllustrationIcon
                    onClick={() => changeStep('gatewayPicker')}
                    className='mx-auto mb-4 cursor-pointer'
                  />

                  <h5 className='font-bold text-lg color-navy-500 m-0 mb-2' data-test-id="empty_connection_text">{t('Connect with your favorite payment gateways!')}</h5>
                  <span className='color-navy-300 text-md line-height-xl tracking-md font-normal' data-test-id="empty_connection_desc">
                    {t('The connections you add will be listed here.')}
                  </span>
                </div>
              ) : connectionListData.map(connection => {
                const {
                  id,
                  gateway,
                  title,
                  sandbox,
                  assetCount = 0,
                  parent_gateway: childGatewayType,
                  inUse = false,
                  disabled = false,
                  disabled_reason: disabledReason = null,
                  resources,
                  scope,
                  subuser,
                  scope_name: scopeName,
                  scope_fullname: scopeFullName,
                  status,
                  secure3d_support: secure3DSupport,
                  is_gateway_disabled: isGatewayDisabledInEnterprise = false
                } = connection;
                const Icon = gatewayDefaultIcons[gateway] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
                const viaGateway = childGatewayType ? supportedGatewayConnectionPropsList[childGatewayType].name : null;
                const isShowSubUserOwnerBadged = scope === 'user' && subuser === true && user && scopeName !== user.username;
                const isShowTeamConnectionBadged = scope === 'team';
                const isShowOrganizationConnectionBadged = scope === 'org';

                return (
                  <div
                    key={id}
                    className={`
                      px-4 py-3 radius flex justify-between items-center border
                      mb-3 h-16 w-full ${selectedConnectionId === id ? 'bg-blue-100 border-blue-500' : 'border-navy-100'}
                      ${inUse === true || disabled === true ? 'cursor-not-allowed' : 'cursor-pointer hover:border-blue-500 hover:shadow-light-xs'}
                    `}
                    style={{
                      userSelect: 'none'
                    }}
                    onClick={() => {
                      if (inUse === true || disabled === true) {
                        return;
                      }

                      selectConnection(selectedConnectionId === id ? null : id);
                    }}
                    data-test-id={`connection_${id}`}
                  >
                    <div className={`flex justify-start items-center gap-3 ${disabled === true ? 'opacity-50' : ''}`}>
                      <Icon className="h-10 w-10" />
                      <div className="gap-1">
                        <div className='font-normal text-md tracking-sm color-navy-700 flex justify-start items-center gap-1'>
                          <span className="connection-text overflow-hidden whitespace-nowrap w-full">{title}</span>
                          {
                            isShowSubUserOwnerBadged && scopeFullName && (
                              <span className="inline-flex items-center font-normal gap-1 h-5 text-xs radius px-1.5 min-w-12 color-black bg-yellow-600 bg-opacity-20">
                                <IconUserFilled className="color-current shrink-0 w-3.5 h-3.5" />
                                <span className="whitespace-nowrap">{scopeFullName}</span>
                              </span>
                            )
                          }
                          {
                            isShowTeamConnectionBadged && scopeFullName && (
                              <span className="inline-flex items-center font-normal gap-1 h-5 text-xs radius px-1.5 min-w-12 color-black bg-blue-600 bg-opacity-20">
                                <IconProductTeamsBasicFilled className="color-current shrink-0 w-3.5 h-3.5" />
                                <span className="whitespace-nowrap">{scopeFullName}</span>
                              </span>
                            )
                          }
                          {
                            isShowOrganizationConnectionBadged && (
                              <span className="inline-flex items-center font-normal gap-1 h-5 text-xs radius px-1.5 min-w-12 color-black bg-orange-600 bg-opacity-20">
                                <IconBuildings className="color-current shrink-0 w-3.5 h-3.5" />
                                <span className="whitespace-nowrap">{t('Organization')}</span>
                              </span>
                            )
                          }
                        </div>
                        <div className='text-sm line-height-md color-navy-300 gap-1 mt-1 flex justify-start items-center'>
                          {
                            (status === '3' || status === '5') && (
                              <>
                                <span
                                  className={`inline-flex items-center font-normal gap-1 h-5 text-xs radius-full px-1.5 min-w-12 color-black bg-opacity-20 ${status === '3' ? 'bg-red-400' : 'bg-navy-300'}`}
                                >
                                  {status === '3' && <IconExclamationCircle className="color-current shrink-0 w-3.5 h-3.5" />}
                                  {status === '5' && <IconXmarkCircle className="color-current shrink-0 w-3.5 h-3.5" />}
                                  <span className="whitespace-nowrap">{status === '3' ? t('Failed') : t('Disabled')}</span>
                                </span>
                                <IconCircleSmFilled className='h-2 w-2' />
                              </>
                            )
                          }
                          <span>{sandboxGetDataConverter(sandbox)}</span>
                          {assetCount > 0 && !isShowOrganizationConnectionBadged && (
                            <button
                              type='button'
                              className='bg-transparent text-sm border-0 hover:underline color-navy-300 hover:color-gray-700 cursor-pointer p-0'
                              onClick={e => {
                                e.stopPropagation();

                                const reformatResources: CONNECTION_LIST_RESOURCE_DATA[] = [];
                                Object.values(resources).forEach(subArray => {
                                  subArray.forEach(item => {
                                    reformatResources.push(item);
                                  });
                                });
                                setResourcesListDialogTitle(title);
                                setResourcesListConnectionId(id);
                                setConnectionResourcesDialogData(reformatResources);
                                setIsOpenConnectionResourcesListDialog(true);
                              }}
                              data-test-id={`connection_resources_${id}`}
                            >
                              <IconCircleSmFilled className='h-2 w-2' />
                              <span className='ml-1'>
                                {translationRenderer(`Used in [1[{assetCount}]] ${assetCount > 1 ? 'Assets' : 'Asset'}`)({
                                  renderer1: () => assetCount
                                })}
                              </span>
                            </button>
                          )}
                          {viaGateway && (
                            <span className='ml-1'>
                              <IconCircleSmFilled className='h-2 w-2' />
                              <Badge
                                colorStyle='neutral'
                                className="bg-white ml-1"
                                style={{
                                  color: '#252d5b', backgroundColor: '#ffffff', border: '1px solid #C8CEED', fontWeight: 400
                                }}
                                data-test-id={`via_gateway_${id}`}
                              >
                                {
                                  translationRenderer('via [1[{viaGateway}]]')({
                                    renderer1: () => viaGateway
                                  })
                                }
                              </Badge>
                            </span>
                          )}
                          {secure3DSupport && (
                            <span>
                              <IconCircleSmFilled className='h-2 w-2' />
                              <Badge
                                colorStyle='neutral'
                                className="bg-white ml-1"
                                style={{
                                  color: '#252d5b', backgroundColor: '#ffffff', border: '1px solid #C8CEED', fontWeight: 400
                                }}
                                data-test-id={`secure_3d_support_${id}`}
                              >
                                {t('3D Secure Enabled')}
                              </Badge>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex justify-start items-center gap-2'>
                      {
                        inUse && inUse === true ? (
                          <span className="inline-flex items-center font-normal gap-1 h-5 text-xs radius-full px-3 color-white bg-blue-500" data-test-id={`in_use_${id}`}>
                            <span className="whitespace-nowrap">{t('In Use')}</span>
                          </span>
                        ) : connectedId === id && (
                          <span className="inline-flex items-center font-normal gap-1 h-5 text-xs radius-full px-3 color-white bg-green-500" data-test-id={`new_${id}`}>
                            <span className="whitespace-nowrap">{t('New')}</span>
                          </span>
                        )
                      }
                      {selectedConnectionId === id && (<IconCheckCircleFilled className='h-6 w-6 color-blue-500 ease-in-out duration-300' />)}
                      {isEnterprise() && isGatewayDisabledInEnterprise && (<ConnectionGatewayDisabledInEnterprise gatewayType={gateway} />)}
                      {disabled && disabled === true && (<DisabledConnectionInforms gatewayType={gateway} disabledReason={disabledReason} />)}
                    </div>
                  </div>
                );
              })
            }
          </div>
        )
      }

      {/* connect button */}

      <div className='mt-6'>
        {isShowAddNewConnectionButton() && (
          <Button
            colorStyle="secondary"
            fullWidth={true}
            startIcon={IconPlus}
            onClick={() => changeStep('gatewayPicker')}
            data-test-id='add_new_connection'
          >
            {t('Add New Connection')}
          </Button>
        )}
      </div>

      {/* resource list dialog */}
      {
        isOpenConnectionResourcesListDialog
        && connectionResourcesDialogData && (
          <Dialog
            ariaLabel={t('Connection Assets')}
            open={isOpenConnectionResourcesListDialog}
            onClose={handleCloseResourcesDialog}
          >
            <DialogBody>
              <DialogIcon
                colorStyle="informative"
                icon={IconInfoCircle}
              />
              <DialogTitle>
                {t('Connection Assets')}
              </DialogTitle>
              <DialogDescription>
                {
                  translationRenderer('[1[{title}]] is being used in [2[{count}]] assets.')({
                    renderer1: () => <span className='font-bold'>{resourcesListDialogTitle}</span>,
                    renderer2: () => connectionResourcesDialogData.length
                  })
                }
              </DialogDescription>
              <DialogContent className="px-8 space-y-2" data-test-id="used_resource_content">
                {connectionResourcesDialogData.slice(0, 4).map(resource => {
                  const showOwnerBadge = !isEnterprise() && user && (user.username !== resource.owner);
                  return (
                    <div key={resource.id} className='border border-navy-50 radius px-3 py-3 flex justify-between'>
                      <div className='flex items-center gap-3 truncate'>
                        {getIcon(resource.type)}
                        <span className='text-sm color-navy-700 truncate'>{resource.title}</span>
                      </div>
                      {
                        showOwnerBadge && (
                          <ResourceOwnerBadge
                            additionalClass="resourceOwnerBadge-modal"
                            ownerFullname={resource.owner_fullname}
                            ownerUsername={resource.owner}
                          />
                        )
                      }
                    </div>
                  );
                })}
                {connectionResourcesDialogData.length > 4 && (
                  <div className='text-sm border border-navy-50 radius px-3 py-3 flex items-center justify-start gap-3 color-navy-700'>
                    {
                      translationRenderer('+ [1[{count}]] more')({
                        renderer1: () => connectionResourcesDialogData.length - 4
                      })
                    }
                  </div>
                )}
              </DialogContent>
            </DialogBody>
            <DialogActions>
              <Button
                colorStyle="secondary" variant="outline" onClick={handleCloseResourcesDialog}
                data-test-id="close_resource_dialog"
              >
                {t('Cancel')}
              </Button>
              <Button
                colorStyle="primary"
                variant="filled"
                onClick={() => {
                  handleCustomNavigation(`/myaccount/connections/${resourcesListConnectionId}?assetsTab=1`, '_blank', true);
                }}
                data-test-id="view_in_my_account"
              >
                {t('View in My Account')}
              </Button>
            </DialogActions>
            <DialogCloseButton onClick={handleCloseResourcesDialog} />
          </Dialog>
        )
      }
    </div>
  );
}
