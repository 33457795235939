import React, {
  MouseEvent, PropsWithChildren, useEffect, useState
} from 'react';

import { cx } from 'class-variance-authority';
import { ColorResult, SketchPicker } from 'react-color';

import {
  useFloating,
  useInteractions,
  useDismiss,
  flip,
  offset as floatingOffset,
  autoUpdate
} from '@floating-ui/react';

import { Portal } from '../Modal';

import { ColorPickerProps, colorPickerDefaultProps } from './color-picker.types';
import { colorPickerButtonCVA } from './color-picker.cva';

import '@jotforminc/jotform.css';

export const ColorPicker = (props: PropsWithChildren<ColorPickerProps>): JSX.Element => {
  const {
    size,
    color,
    disabled,
    placement,
    offset,
    rounded,
    onChange,
    className,
    custom,
    children,
    theme,
    zIndex,
    onClick,
    buttonAttrs,
    pickerAttrs,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [colorValue, setColorValue] = useState(color);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    strategy: 'fixed',
    middleware: [floatingOffset(offset), flip()]
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const handleColorPickerChange = (colorObj: ColorResult) => {
    const computedColor = colorObj.rgb.a === 1 ? colorObj.hex : `rgba(${Object.values(colorObj.rgb).join(',')})`;
    setColorValue(computedColor);
    if (onChange) onChange(computedColor);
  };

  const colorPickerButtonClassName = !custom ? cx(className, colorPickerButtonCVA({ size, rounded, theme })) : className;

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    setIsOpen(disabled ? false : !isOpen);
    if (onClick) onClick(e);
  };

  useEffect(() => {
    setColorValue(color);
  }, [color]);

  return (
    <>
      <button
        ref={refs.setReference}
        style={{ backgroundColor: colorValue }}
        onClick={handleButtonClick}
        type="button"
        className={colorPickerButtonClassName}
        disabled={disabled}
        {...buttonAttrs}
        {...getReferenceProps()}
      >
        {children}
      </button>
      {isOpen
        && (
          /**
           * Use Portal instead of FloatingPortal to render ColorPicker inside Modal/Dialog.
           * This ensures ColorPicker is recognized as part of the Modal/Dialog and prevents closure on click.
           */
          <Portal>
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex }}
              className="magnet-colorpicker color-navy-700"
              {...pickerAttrs}
              {...getFloatingProps()}
            >
              <SketchPicker
                color={colorValue}
                onChange={handleColorPickerChange}
                {...rest}
              />
            </div>
          </Portal>
        )}
    </>
  );
};

ColorPicker.defaultProps = colorPickerDefaultProps;
