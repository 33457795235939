import React from 'react';
import { useSelector } from 'react-redux';
import SELECTORS from '../../../store/selectors';

const DefaultStartElement = () => {
  const { listBgColor, appBgColor } = useSelector(SELECTORS.getAppInfoWithDefaults);
  const { id: themeId } = useSelector(SELECTORS.getSelectedAppTheme) || {};
  const defaultColors = { background: appBgColor, icon: listBgColor };

  const getSvgColors = theme => {
    switch (theme) {
      case 'shadow':
        return { background: listBgColor, icon: appBgColor };
      case 'default':
        return { background: '#E3E5F5', icon: '#6C73A8' };
      default:
        return defaultColors;
    }
  };

  const svgColors = themeId ? getSvgColors(themeId) : defaultColors;

  return (
    <svg
      className='default-preview'
      xmlns="http://www.w3.org/2000/svg"
      width={105}
      height={105}
      viewBox="0 0 105 105"
      fill="none"
    >
      <path
        d="M100.116 0H4.88372C2.18652 0 0 2.18652 0 4.88372V100.116C0 102.813 2.18652 105 4.88372 105H100.116C102.813 105 105 102.813 105 100.116V4.88372C105 2.18652 102.813 0 100.116 0Z"
        fill={svgColors.background}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M27.8781 39.0698C27.8781 35.3611 30.8845 32.3546 34.5932 32.3546H70.4071C74.1158 32.3546 77.1223 35.3611 77.1223 39.0698V65.9302C77.1223 69.6389 74.1158 72.6453 70.4071 72.6453H34.5932C30.8845 72.6453 27.8781 69.6389 27.8781 65.9302V39.0698ZM48.0235 43.5465C48.0235 46.0189 46.0192 48.0232 43.5467 48.0232C41.0743 48.0232 39.07 46.0189 39.07 43.5465C39.07 41.074 41.0743 39.0698 43.5467 39.0698C46.0192 39.0698 48.0235 41.074 48.0235 43.5465ZM59.7709 48.7216L72.283 65.3212C73.1658 66.4925 72.3302 68.1687 70.8635 68.1687H45.8393L45.8048 68.1684H34.136C32.6841 68.1684 31.8445 66.5218 32.6975 65.3467L40.8227 54.1525C41.5324 53.1747 42.9899 53.1747 43.6997 54.1525L48.1828 60.329L56.932 48.7216C57.6432 47.7782 59.0597 47.7782 59.7709 48.7216Z"
        fill={svgColors.icon}
      />
    </svg>
  );
};

export default DefaultStartElement;
