import React from 'react';
import { shape, string } from 'prop-types';

const ErrorText = ({ className, style, ...props }) => (
  <span
    style={{ whiteSpace: 'pre-line', ...style }}
    className={['fileUpload-error', className].join(' ')}
    {...props}
  />
);

ErrorText.propTypes = {
  className: string,
  style: shape({})
};

ErrorText.defaultProps = {
  className: '',
  style: {}
};

export default ErrorText;
