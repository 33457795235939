const getScrollableHeightOfElement = (element, withoutLimit) => {
  const maxScrollableDistance = 10000;
  const wrapperEl = element.parentElement;
  let distance = element.clientHeight - wrapperEl.clientHeight;
  distance = !withoutLimit && distance >= maxScrollableDistance ? maxScrollableDistance : distance;
  return -distance;
};

export const getTopValue = screenshotRef => {
  const screenshotElement = screenshotRef.current;
  if (screenshotElement === null || !screenshotElement) return;
  const { clientHeight } = screenshotElement;
  if (clientHeight <= 0) return;
  return getScrollableHeightOfElement(screenshotElement);
};
