import React from 'react';
import { func, string } from 'prop-types';
import Styled from 'styled-components';
import { IconPencilToSquare } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';

const ScRightPanelHeader = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  .preview {
    color: #6F76A7;
    font-size: 14px;
  }`;

const SettingsRightPanel = ({ featureName, editButtonText, onOpenRightPanel }) => (
  <ScRightPanelHeader>
    <span className="preview">{featureName}</span>
    <Button
      onClick={onOpenRightPanel}
      aria-label={editButtonText}
      startIcon={IconPencilToSquare}
      variant='ghost'
      size='small'
    >
      {editButtonText}
    </Button>
  </ScRightPanelHeader>
);

SettingsRightPanel.propTypes = {
  featureName: string,
  editButtonText: string,
  onOpenRightPanel: func
};

SettingsRightPanel.defaultProps = {
  featureName: '',
  editButtonText: '',
  onOpenRightPanel: f => f
};

export default SettingsRightPanel;
