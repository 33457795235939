import { DUMMY_RECOMMENDED_THEMES } from './constants';

export const dummyAppThemesApi = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(DUMMY_RECOMMENDED_THEMES);
    }, 50);
  });
};

const getObjectElDocument = objectEl => {
  let docEl = objectEl.contentWindow || objectEl.contentDocument;
  if (docEl?.document) {
    docEl = docEl.document;
  }
  return docEl;
};

const getSpacerStyles = () => {
  return `
    .appHeader {
      padding:  58px 30px 87px !important;
    }
    .navigationPanelWrapper {
      padding-top:  28px !important;
    }
    .navBurger {
      top: 28px !important;
    } 
    @media screen and (max-width: 381px) {
      .formRendererNav {
        padding: 36px 12px 8px !important;
        height: 90px;
      }
    }
    .navPanel-title {
      top: 28px !important;
    }`;
};

export const addMobileHeadSpacer = objectEl => {
  const styleEl = document.createElement('style');
  styleEl.innerHTML = getSpacerStyles();
  const objectDocEl = getObjectElDocument(objectEl);
  objectDocEl?.head?.append(styleEl);
};
