import { t } from '@jotforminc/translation';
import React, { useState } from 'react';
import { func } from 'prop-types';
import { classNames } from '@jotforminc/utils';
import {
  IconFormFilled,
  IconProductPaymentFormFilled,
  IconAngleDown,
  IconCheck
} from '@jotforminc/svg-icons';
import { Dropdown } from './Dropdown';

const FilterForm = ({ filterOnClient }) => {
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const [filterValue, setFilterValue] = useState('all');

  const getCommonFilterValues = () => ([
    {
      text: 'All Forms',
      value: 'all',
      isDefault: true,
      Icon: IconFormFilled
    },
    {
      text: 'Payment Forms',
      value: 'payment',
      Icon: IconProductPaymentFormFilled
    }
  ]);

  const handleOptionsVisible = tmpIsOptionsVisible => {
    if (isOptionsVisible === tmpIsOptionsVisible) {
      return;
    }
    setOptionsVisible(tmpIsOptionsVisible);
  };

  const renderFilterOption = val => {
    const { option: { text, Icon }, isSelected } = val;
    return (
      <>
        <Icon className="isSelectionIcon" />
        {text}
        {isSelected && <IconCheck className="isCheck" />}
      </>
    );
  };

  const handleFilter = value => {
    setFilterValue(value);
    filterOnClient(value);
  };

  return (
    <div className={classNames('sb-sortW', isOptionsVisible && 'isOpened')}>
      <IconFormFilled className="w-6 left-2 absolute isIcon" />
      <Dropdown
        defaultValue={filterValue}
        options={getCommonFilterValues()?.map(opt => ({ ...opt, text: t(opt.text) }))}
        onOptionSelect={({ value, key }) => handleFilter(value, key)}
        OptionRenderer={renderFilterOption}
        popoverProps={{
          className: 'listings-popover-modal',
          popoverOptions: {
            placement: 'bottom-end'
          }
        }}
        onOptionsVisibilityChanged={handleOptionsVisible}
        placeholder={t('Please select')}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        OptionContainerRenderer={props => <li tabIndex={0} {...props} />}
        optionsAutoFocus={true}
      />
      <IconAngleDown className="w-6 right-2 absolute isArrow" />
    </div>
  );
};

FilterForm.propTypes = {
  filterOnClient: func
};

FilterForm.defaultProps = {
  filterOnClient: f => f
};

export default FilterForm;
