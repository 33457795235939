import React, { useCallback, useMemo, useState } from 'react';

import { Hooks, TextInput as UIKitTextInput } from '@jotforminc/uikit';
import {
  bool, elementType, func, string
} from 'prop-types';

const ResettableInput = ({
  value,
  defaultValue,
  showHint,
  onChange,
  HintRenderer,
  InputRenderer,
  ResetButtonRenderer,
  InputWrapperRenderer,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value || defaultValue);

  const isHintActive = useMemo(() => showHint && inputValue !== defaultValue, [inputValue, defaultValue, showHint]);

  const isResetButtonVisible = useMemo(() => inputValue !== defaultValue, [inputValue, defaultValue]);

  const handleInputChange = useCallback(e => {
    const { target: { value: newValue } } = e;
    onChange(newValue);
    setInputValue(newValue);
  }, []);

  const setInputValueAsDefault = useCallback(() => {
    setInputValue(defaultValue);
  }, []);

  const handleInputBlur = useCallback(e => {
    if (!e.target.value) {
      setInputValueAsDefault();
    }
  }, [defaultValue]);

  Hooks.useEffectIgnoreFirst(() => {
    if (value && value !== defaultValue) setInputValue(value);
  }, [value]);

  return (
    <>
      <InputWrapperRenderer>
        <InputRenderer
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          {...props}
        />
        {isResetButtonVisible && (<ResetButtonRenderer onClick={setInputValueAsDefault} />)}
      </InputWrapperRenderer>
      {isHintActive && <HintRenderer defaultValue={defaultValue} />}
    </>
  );
};

ResettableInput.propTypes = {
  value: string,
  defaultValue: string,
  onChange: func,
  showHint: bool,
  HintRenderer: elementType,
  InputRenderer: elementType,
  ResetButtonRenderer: elementType,
  InputWrapperRenderer: elementType
};

ResettableInput.defaultProps = {
  value: '',
  defaultValue: '',
  onChange: f => f,
  showHint: false,
  // eslint-disable-next-line react/prop-types
  HintRenderer: ({ defaultValue }) => (
    <div>
      Default Value:
      {defaultValue}
    </div>
  ),
  InputRenderer: props => <UIKitTextInput {...props} />,
  ResetButtonRenderer: props => <button type="button" {...props}>Reset</button>,
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  InputWrapperRenderer: props => <>{props.children}</>
};

export default ResettableInput;
