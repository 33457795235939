import React, { useCallback, useMemo } from 'react';
import { shape, string, number } from 'prop-types';
import { useSelector } from 'react-redux';
import PanelItemGenerator from './PanelItemGenerator';
import SELECTORS from '../../../../../store/selectors';
import { ScLineHr } from './ScLineHr';
import { ScSectionTitle } from './ScSectionTitle';

const PanelLineGenerator = ({
  property, elements, properties, parentTabCount
}) => {
  const portalProps = useSelector(SELECTORS.getAppInfoWithDefaults);

  const lineItem = properties[property];
  const isDsProp = !!lineItem.linkedItemID;
  const [selectedItemID] = lineItem.presentationItemID ? [lineItem.presentationItemID] : useSelector(SELECTORS.getSelectedPortalItems);

  const itemProps = useSelector(SELECTORS.getPortalItemByIDSelector(selectedItemID));

  const getValue = useCallback(el => (selectedItemID ? itemProps[el] : portalProps[el]) || '', [portalProps, selectedItemID, itemProps]);

  const restrictedProps = ['hasCondition', 'conditionProp', 'conditionValue', 'conditionChecker', 'conditionItemProp'];

  const filterConditionalProps = useCallback(conditionalProps => {
    return Object.entries(conditionalProps).reduce((prev, [key, value]) => {
      if (restrictedProps.includes(key)) {
        return { ...prev };
      }
      return { ...prev, [key]: value };
    }, {});
  }, []);

  const generateItem = useCallback((key, itemElements, itemProperty) => {
    return (
      <PanelItemGenerator
        key={key}
        elements={itemElements}
        property={itemProperty}
        itemProps={itemProps}
        selectedItemID={selectedItemID}
        isDsProp={isDsProp}
      />
    );
  }, [itemProps]);
  const SectionTitle = useCallback(() => (lineItem?.sectionLabel ? <ScSectionTitle>{lineItem.sectionLabel}</ScSectionTitle> : null), [property, properties]);

  const checkCondition = useCallback(item => {
    const {
      conditionProp,
      conditionValue,
      conditionChecker,
      conditionItemProp
    } = item;

    return conditionChecker(conditionItemProp ? { [conditionItemProp]: itemProps[conditionItemProp] } : { propValue: getValue(conditionProp), expectedValue: conditionValue });
  }, [itemProps, getValue]);

  // @eren data source panel here
  const memoizedItem = useMemo(() => {
    if (!property.startsWith('lineElement')) {
      const { hasCondition, conditionChecker, conditionItemProp } = elements;

      if (!hasCondition) return generateItem(property, elements, property);

      const filteredElements = filterConditionalProps(elements);
      return (hasCondition && conditionChecker({ [conditionItemProp]: itemProps[conditionItemProp] })) ? generateItem(property, filteredElements, property) : null;
    }

    if (lineItem?.hasCondition && !checkCondition(lineItem)) return null;

    const lineObjects = Object.keys(lineItem);
    return lineObjects.filter(lineItemEl => {
      if (restrictedProps.includes(lineItemEl)) return null;

      const lineEl = lineItem[lineItemEl];
      const {
        hasCondition = false
      } = lineEl;
      return hasCondition ? checkCondition(lineEl) : lineItemEl !== 'sectionLabel';
    }).map(lineItemElement => {
      const lineElement = lineItem[lineItemElement];
      const key = `${lineItemElement}_${property}`;
      return generateItem(key, filterConditionalProps(lineElement), lineItemElement);
    });
  }, [property, elements, generateItem, properties, getValue, itemProps]);

  const isMemoizedItemValid = Array.isArray(memoizedItem) ? memoizedItem.filter(m => m).length > 0 : !!memoizedItem;

  return (
    isMemoizedItemValid && (
    <>
      {!lineItem?.noTopLine && <ScLineHr className={`${parentTabCount > 1 ? 'hasMultipleParentTab' : ''} relative z-1`} />}
      <SectionTitle />
      {memoizedItem}
    </>
    )
  );
};

PanelLineGenerator.propTypes = {
  property: string.isRequired,
  elements: shape({}).isRequired,
  properties: shape({}).isRequired,
  parentTabCount: number.isRequired
};

export default PanelLineGenerator;
