import React from 'react';
import { string } from 'prop-types';
import { Feature } from '@paralleldrive/react-feature-toggles';
import { isActiveFeatureName } from '@paralleldrive/feature-toggles';
import { propTypes } from '@jotforminc/constants';

const FeatureComponent = ({
  name,
  activeComponent,
  inactiveComponent,
  ...props
}) => (
  <Feature {...props}>
    {({ features }) => (
      isActiveFeatureName(name, features) ? activeComponent : inactiveComponent
    )}
  </Feature>
);

FeatureComponent.propTypes = {
  name: string.isRequired,
  activeComponent: propTypes.renderable,
  inactiveComponent: propTypes.renderable
};

FeatureComponent.defaultProps = {
  activeComponent: null,
  inactiveComponent: null
};

export default FeatureComponent;
