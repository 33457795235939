/* eslint-disable max-statements */
/* eslint-disable object-property-newline */
import React, {
  Fragment, useRef, useState, useEffect,
  useMemo
} from 'react';
import {
  string, func, bool, object, shape, arrayOf
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconAngleDown, IconCopyLine, IconArrowUpToLine } from '@jotforminc/svg-icons';
import { classNameGenerator } from '@jotforminc/utils';
import { ABTestManager } from '@jotforminc/abtest-manager';

import Loader from '../Loader';
import { fetchCategories, fetchStarterPackTemplates, fetchTemplates } from './apiTemplates';
import {
  getInitialLanguage, prettyTemplatePageType, getNoResultContent,
  handleSetupActionLogger, scrollToTop, getUrlSearchParamByKey,
  addOrRemoveMultipleEventListeners, getSubCategoryViewData, getTemplateId,
  categoryHeaderRenderer, allCategoriesRenderer, getJfWizardWrapper, getPreviewID, formatBigNumber
} from './utils';
import { getDeviceType } from './getDeviceType';

import Search from './components/Search';
import Sidebar from './components/Sidebar';
import TemplateItemContainer from './components/TemplateItem/TemplateItemContainer';
import RelatedCategories from './components/RelatedCategories';
import SubCategoryGalleryItem from './components/SubCategoryGalleryItem';

import { ScTemplates } from './components/ScTemplates';
import { ScStandaloneContainer } from './components/ScMainWrapper';
import './styles/style.scss';
import { templateConstants } from './templateConstants';
import { stateClass } from '../../core/utils';

// eslint-disable-next-line complexity
const StandaloneTemplates = ({
  user,
  source,
  isInline,
  liteMode,
  username,
  frameSrc,
  categories,
  modalVisible,
  setCategories,
  searchKeyword,
  setSearchKeyword,
  hideyMyformsModal,
  callbackCategories,
  handleTemplateClone,
  displayMyformsModal,
  selectedCategory,
  setSelectedCategory,
  setStandaloneTemplates,
  customTrackingProjectName,
  defaultSelectedCategorySlug,
  liteModeActionButtonHandlers
}) => {
  const {
    handleClickSeeMoreTemplates = f => f,
    handleClickStartFromScratchBtn = f => f
  } = liteModeActionButtonHandlers;

  const { PLATFORM_ENV = '' } = window;
  const predefinedCategoryObj = PLATFORM_ENV === 'salesforce' ? 'Salesforce Forms' : defaultSelectedCategorySlug || getUrlSearchParamByKey({ key: 'templateCategory' });

  const containerRef = useRef();
  const [offset, setOffset] = useState(0);
  const templateType = frameSrc.split('/')[1];

  const [asideShow, setAsideShow] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [readyPage, setReadyPage] = useState(false);
  const [touchingEvent, setTouchingEvent] = useState(false);
  const [suggestedCategories, setSuggestedCategories] = useState(undefined);

  const [searchResult, setSearchResult] = useState({
    searchedKeyword: searchKeyword,
    searchView: searchKeyword !== null && searchKeyword !== ''
  });
  const [totalTemplates, setTotalTemplates] = useState();
  const [backToTopVisible, setBackToTopVisible] = useState(false);
  const [isMyTemplates, setIsMyTemplates] = useState(false);
  const [activeParentCategory, setActiveParentCategory] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [hasMoreTemplate, setHasMoreTemplate] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [predefinedCategory, setPredefinedCategory] = useState();

  const isFormTemplates = templateType === 'form-templates';

  const deviceType = getDeviceType();
  const isDesktop = deviceType === 'desktop';

  const isInlineWrapped = isInline && isFormTemplates;

  const [language, setLanguage] = useState([getInitialLanguage(isFormTemplates)]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  const [isSticky, setIsSticky] = useState(false);
  const [isAllCategoriesSelected, setIsAllCategoriesSelected] = useState(false);
  const [subCategoryViewData, setSubCategoryViewData] = useState({ subCategories: [], loading: false });
  const [filters, setFilters] = useState({
    sorting: 'popular',
    theme: getUrlSearchParamByKey({
      key: 'formLayout',
      defaultValue: 'classic',
      options: ['classic', 'card']
    }) || 'classic'
  });
  const [showSearchTestIsStarterPack, setShowSearchTestIsStarterPack] = useState(false);
  const templatePaginationOffset = 24;
  const currentPaginationOffset = (liteMode && !readyPage) ? templatePaginationOffset - 1 : templatePaginationOffset;
  const templatePageTypePrettyPlural = prettyTemplatePageType(templateType);
  const templatePageTypePretty = prettyTemplatePageType(templateType, false);
  const { theme } = filters;
  const isAgentTemplates = templateType === 'agent-templates';

  // A/B Test: templateSuggestionModalOnMyAppsIII
  const registerABTestActionRef = useRef(f => f);
  const abTestManager = useMemo(() => {
    return new ABTestManager({
      user,
      isTestEnabled: false,
      testName: 'templateSuggestionModalOnMyAppsIII',
      controlVariantCode: '26222',
      testVariantCode: '26232',
      urlParam: 'tsmomaiii',
      customUserChecks: {
        isLightMode: liteMode,
        isApp: templateType === 'app-templates',
        source: 'template-suggestion-modal'
      }
      // debugMode: {
      //   forceUserEligible: true,
      //   logTestState: true
      // // forceTestVariant: true
      // }
    });
  }, [user]);

  const handleLiteMode = async () => {
    if (!liteMode) {
      stateClass('remove', 'templateSuggestionModal--isVisible');
    }
  };

  useEffect(() => {
    handleLiteMode();
  }, [liteMode]);

  const backToTopIsVisible = () => {
    let scrollPos = 0;
    if (isMobile || !isDesktop) return containerRef?.current?.scrollTop > 300;
    if (isInlineWrapped) {
      const jfWizard = getJfWizardWrapper();
      if (jfWizard === null) return false;
      scrollPos = jfWizard.scrollTop;
    } else {
      scrollPos = window.scrollY;
    }
    return scrollPos > 300;
  };

  const getTemplates = async (payload = {}) => {
    const {
      category: categoryPayload = null,
      offsetParameter = 0,
      clickedLoadMore = false,
      searchKeywordParameter = ''
    } = payload;

    setLoadingTemplates(clickedLoadMore ? 'loading-more' : true);
    const isStarterPack = liteMode && (!searchKeywordParameter && !categoryPayload);
    let isTestVaiant = false;

    // A/B Test: templateSuggestionModalOnMyAppsIII
    try {
      isTestVaiant = await abTestManager.isTestVariant();
      registerABTestActionRef.current = abTestManager.registerABTestAction;
    } catch (e) {
      isTestVaiant = false;
    }

    // eslint-disable-next-line no-nested-ternary
    const fetcher = isStarterPack ? fetchStarterPackTemplates : false ? [] : fetchTemplates;
    setShowSearchTestIsStarterPack(isStarterPack);
    fetcher({
      ...payload,
      filters,
      language,
      templateType,
      username,
      source,
      rpp: currentPaginationOffset,
      featuredTemplates: isTestVaiant
    }).then(async res => {
      const { data: { content } } = res;

      const { templates: contentTemplates, requestParams = {} } = content?.templates
        ? content
        : {
          templates: content.map(template => ({
            ...template,
            previewURL: {
              card: `/preview/${(template.id || template._id)}/card&nofs`,
              classic: `/preview/${(template.id || template._id)}/classic&nofs`
            }
          }))
        };
      const cumulativeTemplates = offsetParameter === 0 ? [] : [...templates];
      const abTestAppliedTemplates = contentTemplates;
      const newTemplates = [...cumulativeTemplates, ...abTestAppliedTemplates];

      const { categories: suggestedCategoriesData } = requestParams;

      setSuggestedCategories(suggestedCategoriesData ? suggestedCategoriesData.map((category, index) => ({ ...category, hidden: index > 2 })) : suggestedCategoriesData);
      setTemplates(newTemplates);
      setStandaloneTemplates(newTemplates);
      const hasMore = newTemplates.length >= parseInt(content.total, 10);
      setHasMoreTemplate(!hasMore);
      setTotalTemplates(content.total);
      setOffset(offsetParameter + currentPaginationOffset);
      if (!readyPage) setReadyPage(true);
      setLoadingTemplates(false);
    }).catch(() => {
      console.log('Error');
      if (isAgentTemplates) {
        const agentTemplates = [].filter(agent => agent?.id).slice(0, 9).map(agent => ({
          ...agent,
          screenshot: `/landings/assets/img/agent-directory/thumbnails/${agent.id}.png`,
          previewURL: `https://www.jotform.com/agent/${agent.id}?newUI=true`
        }));
        // setSuggestedCategories(suggestedCategoriesData ? suggestedCategoriesData.map((category, index) => ({ ...category, hidden: index > 2 })) : suggestedCategoriesData);
        setTemplates(agentTemplates);
        setStandaloneTemplates(agentTemplates);
        setHasMoreTemplate(false);
        setTotalTemplates(agentTemplates.length);
        setOffset(offsetParameter + currentPaginationOffset);
        if (!readyPage) setReadyPage(true);
        setLoadingTemplates(false);
      }
    });
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 780);
    setIsSticky(false);
  };

  const handleSubCategoryViewData = (payload = {}) => {
    const { categoriesParameter = categories, category = selectedCategory } = payload;
    try {
      getSubCategoryViewData(
        category, categoriesParameter, setSubCategoryViewData, filters, language, templateType, source
      ).then(res => {
        const { data: { content: { subCategories, total } } } = res;
        setSubCategoryViewData({
          total,
          loading: false,
          subCategories: subCategories.filter(subCat => subCat.templates.length >= 1)
        });
      });
    } catch (e) {
      setSubCategoryViewData({ subCategories: [], loading: false });
    }
  };

  const handleGetCategories = refreshSubCategories => {
    fetchCategories(templateType, language, source)
      .then(res => {
        const { data: { content } } = res;
        const { sortCategoriesByFeatured = false } = templateConstants[templateType];
        let allCategories = content.filter(category => (category?.total && parseInt(category?.total, 10) > 0));
        if (sortCategoriesByFeatured) {
          const featuedCategories = allCategories.filter(category => {
            if (templateType === 'form-templates' && category?.slug === 'salesforce-forms') return true;
            return category?.featured === '1';
          });
          const unFeaturedCategories = allCategories.filter(category => {
            if (templateType === 'form-templates' && category?.slug === 'salesforce-forms') return false;
            return category?.featured === '0';
          });
          allCategories = [...featuedCategories, ...unFeaturedCategories];
        }
        setCategories(allCategories);
        callbackCategories(allCategories);
        if (refreshSubCategories) handleSubCategoryViewData(content);
      })
      .catch(() => {
        setCategories(isAgentTemplates ? [].filter(category => category.count > 0) : []);
        callbackCategories([]);
      });
  };

  const handleDesktopScroll = () => {
    setBackToTopVisible(backToTopIsVisible());
  };

  const loadPage = (payload = {}) => {
    if (!readyPage) return;
    const { languageChanged = false, category = selectedCategory, clickedMyTemplates = isMyTemplates } = payload;
    if (languageChanged) {
      handleGetCategories(true);
      if (languageChanged) setSelectedCategory();
    } else {
      handleSubCategoryViewData();
    }
    getTemplates({
      clickedLoadMore: false,
      getOnlyMyTemplates: clickedMyTemplates,
      searchKeywordParameter: category ? '' : searchKeyword,
      category: languageChanged ? undefined : selectedCategory
    });
    scrollToTop(isMobile, containerRef, isInlineWrapped);
    if (asideShow) setAsideShow(false);
  };

  const handleMount = async () => {
    if (liteMode) stateClass('add', 'templateSuggestionModal--isVisible');

    if (window.location.href.includes('integration')) {
      document.querySelector('body').classList.add('standaloneTemplates');
    }
    const jfWizard = getJfWizardWrapper();
    handleSetupActionLogger(customTrackingProjectName || 'newWizard');
    handleGetCategories();

    getTemplates({
      searchKeywordParameter: searchKeyword
    });
    addOrRemoveMultipleEventListeners(['resize', 'orientationchange'], handleResize, 'add', window);
    addOrRemoveMultipleEventListeners(['scroll'], handleDesktopScroll, 'add', (isInlineWrapped && !isMobile) ? jfWizard : window);
    return () => {
      if (liteMode) stateClass('remove', 'templateSuggestionModal--isVisible');
      addOrRemoveMultipleEventListeners(['resize', 'orientationchange'], handleResize, 'remove', window);
      addOrRemoveMultipleEventListeners(['scroll'], handleDesktopScroll, 'add', (isInlineWrapped && !isMobile) ? jfWizard : window);
      if (window.location.href.includes('integration')) {
        document.querySelector('body').classList.remove('standaloneTemplates');
      }
    };
  };

  useEffect(() => {
    handleMount();
  }, []);

  useEffect(() => {
    loadPage();
  }, [filters]);

  useEffect(() => {
    if (!predefinedCategory) return;
    const selectedCategoryID = selectedCategory?.id || selectedCategory?._id;
    const predefinedCategoryID = predefinedCategory?.id || predefinedCategory?._id;
    if (predefinedCategoryID && predefinedCategoryID !== selectedCategoryID) {
      setPredefinedCategory();
    }
  }, [selectedCategory]);

  useEffect(() => {
    loadPage({ languageChanged: true });
  }, [language]);

  const handleSelectCategory = (payload = {}) => {
    const { category, clickedAllCategories = false, clickedMyTemplates = false } = payload;
    setSearchKeyword('');
    setSelectedCategory(category);
    setIsMyTemplates(clickedMyTemplates);
    setIsAllCategoriesSelected(clickedAllCategories);
    setSearchResult({ searchView: false, searchedKeyword: '' });
    if (!clickedAllCategories) {
      getTemplates({
        category,
        clickedLoadMore: false,
        getOnlyMyTemplates: clickedMyTemplates,
        searchKeywordParameter: (category || clickedMyTemplates) ? '' : searchKeyword
      });
    }
    if (category) handleSubCategoryViewData({ category });
    if (!clickedMyTemplates) {
      window.standaloneTemplatesLogger({
        actor: username,
        action: `${deviceType}:category-click`,
        target: clickedAllCategories
          ? 'allCategories'
          : `/${templateType}/standalone/category/${category ? (category.slug || category.name).toLowerCase() : 'homepage'}`
      });
    }
    scrollToTop(isMobile, containerRef, isInlineWrapped);
    if (asideShow) setAsideShow(false);
  };

  useEffect(() => {
    if (categories && categories?.length > 0 && predefinedCategoryObj) {
      const alreadySelectedCategory = categories?.find(val => {
        return [
          val?.slug, val?._slug, val?.name, val?._name
        ].includes(predefinedCategoryObj.replaceAll('+', ' '));
      }) || null;
      if (alreadySelectedCategory) {
        handleSelectCategory({ category: alreadySelectedCategory });
        setPredefinedCategory(alreadySelectedCategory);
      }
    }
  }, [categories]);

  useEffect(() => {
    global.setSelectedTemplate = setSelectedTemplate;
    return () => {
      delete global.setSelectedTemplate;
    };
  }, []);

  const handleStickySearchWrapper = () => {
    setBackToTopVisible(backToTopIsVisible());
    if (!isMobile) return;
    if (touchingEvent === true) return;

    const TRESHOLD = 100;
    const OFFSET = 5;
    const scrollPosition = containerRef?.current?.scrollTop;
    const nextSticky = scrollPosition > TRESHOLD;

    // why need it? it prevents infinite sticky mode state changings between 95px - 105px;
    const loopingScrollInterval = (scrollPosition < TRESHOLD + OFFSET) && (scrollPosition > TRESHOLD - OFFSET);
    if (loopingScrollInterval) return;

    setIsSticky(nextSticky);
    const jfWizard = getJfWizardWrapper();
    if (jfWizard === null) return;
    if (nextSticky) {
      jfWizard.classList.add('sticky-wizard');
    } else {
      jfWizard.classList.remove('sticky-wizard');
    }
  };

  useEffect(() => {
    if (touchingEvent === false) {
      handleStickySearchWrapper();
    }
  }, [touchingEvent]);

  useEffect(() => {
    if (!isMobile) setAsideShow(false);
    handleStickySearchWrapper();
  }, [isMobile]);

  useEffect(() => { if (!modalVisible) setSelectedTemplate(); }, [modalVisible]);

  useEffect(() => {
    const jfWizard = getJfWizardWrapper();
    if (jfWizard === null) return;
    if (asideShow) {
      jfWizard.classList.add('aside-active');
    } else {
      jfWizard.classList.remove('aside-active');
    }
  }, [asideShow]);

  useEffect(() => {
    if (selectedTemplate) {
      const { previewURL } = selectedTemplate;
      const templateTitle = selectedTemplate._title || selectedTemplate.title;
      const previewURLTheme = previewURL ? previewURL[theme] : '';
      displayMyformsModal({
        theme: theme,
        filters: filters,
        language: language,
        title: templateTitle,
        template: selectedTemplate,
        id: getPreviewID(selectedTemplate),
        previewURL: isAgentTemplates ? previewURL : `/${templateType}/standalone/${previewURLTheme}`
      });
    } else {
      hideyMyformsModal();
    }
  }, [selectedTemplate]);

  const handleClearResult = () => {
    setSearchResult({ searchView: false, searchedKeyword: '' });
    setSearchKeyword('');
    getTemplates({ searchKeywordParameter: '' });
  };

  const isLoadingMore = loadingTemplates === 'loading-more';
  const templatesRenderer = () => {
    if (loadingTemplates && !isLoadingMore && !searchResult.searchView) {
      return (Array(9).fill().map((el, index) => {
        const uniqueKey = index;
        return (
          <Fragment key={`${new Date().getTime()}-${uniqueKey}`}>
            <TemplateItemContainer
              type={templateType}
              source={source}
              logAbTestAction={registerABTestActionRef.current}
              liteMode={liteMode}
            />
          </Fragment>
        );
      }));
    }

    if (templates.length === 0 && (isMyTemplates || (!loadingTemplates && searchResult.searchView))) {
      return getNoResultContent(isMyTemplates, handleClearResult, searchResult, templatePageTypePrettyPlural);
    }

    return (templates.map((template, index) => (
      <>
        {
          (liteMode && index === 0) && (
            <Fragment key='blank-form'>
              <button
                type='button'
                className={`item blank-form ${templateType}`}
                onClick={event => {
                  handleClickStartFromScratchBtn(event);
                  // A/B Test: templateSuggestionModalOnMyAppsIII
                  if (liteMode) {
                    registerABTestActionRef.current?.({ action: 'click', target: 'startFromScratchButton' });
                  }
                }}
              >
                <div className='gray-box'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={65}
                    height={65}
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>{t('Start From Scratch')}</span>
                </div>
              </button>
            </Fragment>
          )
        }
        <Fragment key={(template._id || template.id).toString()}>
          <TemplateItemContainer
            source={source}
            username={username}
            type={templateType}
            template={template}
            templateTheme={theme}
            setSelectedTemplate={setSelectedTemplate}
            handleTemplateClone={handleTemplateClone}
            logAbTestAction={registerABTestActionRef.current}
            liteMode={liteMode}
          />
        </Fragment>
      </>
    )));
  };

  const { loading, subCategories } = subCategoryViewData;
  const isSubCategoryModeDisabled = () => {
    const { subGalleryViewActive = false } = templateConstants[templateType];
    if (!subGalleryViewActive) return true;
    return (!loading && subCategories.length === 0) || !selectedCategory;
  };
  const subCategoryModeDisabled = isSubCategoryModeDisabled();

  const seeMoreButtonRenderer = () => {
    const handleClickSeeMore = () => {
      window.standaloneTemplatesLogger({
        actor: username,
        action: `${window.innerWidth <= 780 ? 'mobile' : 'desktop'}:load-more`
      });
      getTemplates({
        clickedLoadMore: true,
        offsetParameter: offset,
        category: selectedCategory,
        searchKeywordParameter: searchKeyword
      });
    };
    const { loadMoreText = 'See More Templates' } = templateConstants[templateType];
    return (
      (hasMoreTemplate && !isAllCategoriesSelected && (loadingTemplates === false || isLoadingMore)) && (
        <div className="loadMore">
          <button
            className="loadMoreCta"
            type="button"
            onClick={(liteMode && showSearchTestIsStarterPack) ? event => {
              // A/B Test: templateSuggestionModalOnMyAppsIII
              registerABTestActionRef.current?.({ action: 'click', target: 'seeMoreTemplatesButton' });
              handleClickSeeMoreTemplates(event);
            } : handleClickSeeMore}
          >
            {t(isLoadingMore ? 'Please wait...' : loadMoreText)}
          </button>
        </div>
      )
    );
  };

  const subCategoryGalleryRenderer = () => {
    if (subCategoryModeDisabled) return null;
    const subCategorySkeletons = categories.filter(category => getTemplateId(category?.parent) === getTemplateId(selectedCategory));
    const subCategoryIterators = loading ? subCategorySkeletons : subCategories;
    return (
      subCategoryIterators.map(subCategory => (
        <SubCategoryGalleryItem
          source={source}
          type={templateType}
          username={username}
          templateTheme={theme}
          skeletonActive={loading}
          subCategoryObject={subCategory}
          setSelectedTemplate={setSelectedTemplate}
          handleTemplateClone={handleTemplateClone}
          handleSelectCategory={handleSelectCategory}
        />
      ))
    );
  };

  const itemsRenderer = itemType => {
    switch (itemType) {
      case 'templates':
        return (
          <>
            {templatesRenderer()}
            {seeMoreButtonRenderer()}
            {subCategoryGalleryRenderer()}
          </>
        );
      case 'all-categories':
        return allCategoriesRenderer(categories, handleSelectCategory, setActiveParentCategory);
      default:
        break;
    }
  };

  if (!readyPage || (searchResult.searchView && loadingTemplates)) {
    return <Loader />;
  }

  const commonProps = {
    handleSelectCategory, selectedCategory, isAllCategoriesSelected, username
  };

  const searchComponentProps = {
    ...commonProps, getTemplates, searchKeyword, setSearchResult, isMyTemplates, searchResult, isAllCategoriesSelected, source,
    setSearchKeyword, stickySearchWrapper: isSticky, setTemplates, setStandaloneTemplates, setIsMyTemplates, setSelectedCategory, setIsAllCategoriesSelected, templateType
  };

  const sidebarProps = {
    ...commonProps, categories, searchResult, setAsideShow, asideShow, language, isMobile, source, predefinedCategory,
    searchComponentProps, templateType, filters, setFilters, isMyTemplates, setLanguage, setActiveParentCategory
  };

  const categoryHeaderRendererProps = {
    ...commonProps, templatePageTypePrettyPlural, setActiveParentCategory,
    templatePageTypePretty, subCategoryViewData, activeParentCategory
  };

  return (
    <ScStandaloneContainer
      ref={containerRef}
      className='standalone-container'
      onScroll={handleStickySearchWrapper}
      onTouchStart={() => setTouchingEvent(true)}
      onTouchEnd={() => setTouchingEvent(false)}
    >
      {!liteMode && <Sidebar sidebarProps={sidebarProps} />}
      <ScTemplates liteMode={liteMode} isDesktop={isDesktop}>
        <Search props={searchComponentProps} />
        {isMyTemplates && (
          <div className="resultWrapper">
            <div className="resultTitle">{t(`${templatePageTypePrettyPlural} by ${username}`)}</div>
          </div>
        )}
        {categoryHeaderRenderer(categoryHeaderRendererProps)}
        {(searchResult.searchView && templates.length > 0) && (
          <>
            <div className="resultWrapper">
              <div className="resultTitle">{t(`Listing ${formatBigNumber(totalTemplates)} Search Results for "${searchResult.searchedKeyword}"`)}</div>
              <button className="clearResult" type="button" onClick={handleClearResult}>
                {t('Clear Results')}
              </button>
            </div>
            {
              (suggestedCategories && Array.isArray(suggestedCategories) && suggestedCategories.length > 0) && (
                <div className='category-results'>
                  <div className='category-results-content'>
                    {
                      suggestedCategories.map(category => {
                        const {
                          hidden = false, name, color, total
                        } = category;
                        if (hidden) return null;
                        return (
                          <div
                            type='button'
                            className='category-result-box'
                            style={{ backgroundColor: color }}
                            onClick={() => handleSelectCategory({ category })}
                            onKeyDown={() => handleSelectCategory({ category })}
                          >
                            <h2>{name}</h2>
                            <p>
                              <IconCopyLine />
                              <span>
                                {total}
                                {' '}
                                Templates
                              </span>
                            </p>
                          </div>
                        );
                      })
                    }
                  </div>
                  {
                    suggestedCategories.find(({ hidden }) => hidden) && (
                      <div className='category-result-cta'>
                        <button
                          type='button'
                          onClick={() => setSuggestedCategories(suggestedCategories.map(category => ({ ...category, hidden: false })))}
                        >
                          <IconAngleDown />
                          { t('All Category Results') }
                        </button>
                      </div>
                    )
                  }
                </div>
              )
            }
          </>
        )}
        <div className="boxes">
          {itemsRenderer(isAllCategoriesSelected ? 'all-categories' : 'templates')}
        </div>
        <RelatedCategories
          categories={categories}
          selectedCategory={selectedCategory}
          handleSelectCategory={handleSelectCategory}
          setActiveParentCategory={setActiveParentCategory}
        />
        <button
          aria-label='Back to top'
          type='button'
          onClick={() => scrollToTop(isMobile, containerRef, isInlineWrapped)}
          className={classNameGenerator({
            backtoTop: true,
            show: backToTopVisible
          })}
        >
          <IconArrowUpToLine width={20} height={20} />
        </button>
      </ScTemplates>
    </ScStandaloneContainer>
  );
};

StandaloneTemplates.defaultProps = {
  user: {},
  source: '',
  isInline: true,
  username: '',
  liteMode: false,
  liteModeActionButtonHandlers: {},
  categories: [],
  modalVisible: false,
  setCategories: f => f,
  searchKeyword: '',
  setSearchKeyword: f => f,
  hideyMyformsModal: f => f,
  callbackCategories: f => f,
  handleTemplateClone: f => f,
  displayMyformsModal: f => f,
  selectedCategory: undefined,
  setSelectedCategory: f => f,
  setStandaloneTemplates: f => f,
  frameSrc: '/form-templates/standalone',
  defaultSelectedCategorySlug: null,
  customTrackingProjectName: undefined
};

StandaloneTemplates.propTypes = {
  user: shape({}),
  liteModeActionButtonHandlers: object,
  isInline: bool,
  liteMode: bool,
  source: string,
  frameSrc: string,
  modalVisible: bool,
  username: string,
  setCategories: func,
  searchKeyword: string,
  setSearchKeyword: func,
  hideyMyformsModal: func,
  selectedCategory: object,
  callbackCategories: func,
  setSelectedCategory: func,
  handleTemplateClone: func,
  displayMyformsModal: func,
  categories: arrayOf(object),
  setStandaloneTemplates: func,
  customTrackingProjectName: string,
  defaultSelectedCategorySlug: string
};

export default StandaloneTemplates;
