/**
 * Get parent gateway type.
 *
 * @param nameAPM - Gateway APM type.
 */

import type {
  PAYMENT_FIELDS,
  PAYMENT_APM_NAMES
} from '@jotforminc/payment-constants';

type T_PARENT_APM_CONTROL_TYPES = Partial<{
  [key in PAYMENT_APM_NAMES]: PAYMENT_FIELDS;
}>;

const PARENT_APM_CONTROL_TYPES: T_PARENT_APM_CONTROL_TYPES = {
  appleAndGooglePay: 'control_applePayGooglePay'
};

export const getParentGatewayType = (
  nameAPM: PAYMENT_APM_NAMES
): PAYMENT_FIELDS | null => {
  return PARENT_APM_CONTROL_TYPES[nameAPM] || null;
};
