import { t } from '@jotforminc/translation';
import {
  bool, func, node, string
} from 'prop-types';
import React from 'react';
import { Button } from '@jotforminc/magnet';
import { ScAppLayoutPanel } from './ScAppLayoutPanel';

const AppLayoutItem = ({
  isSelected, title, icon, onClick
}) => {
  return (
    <ScAppLayoutPanel isSelected={isSelected}>
      <button type='button' className="theme-overlay" onClick={onClick}>
        <Button
          className='currentBtn'
          colorStyle='secondary'
          size='small'
          onClick={onClick}
        >
          {t('Current Layout')}
        </Button>
        <Button
          className='useBtn'
          size='small'
          onClick={onClick}
        >
          {t('Use Layout')}
        </Button>
      </button>
      <div className="theme">
        {icon}
      </div>
      <div className="theme-name">{title}</div>
    </ScAppLayoutPanel>
  );
};

AppLayoutItem.propTypes = {
  title: string,
  icon: node,
  onClick: func,
  isSelected: bool
};

AppLayoutItem.defaultProps = {
  title: '',
  icon: <div />,
  onClick: f => f,
  isSelected: false
};

export default AppLayoutItem;
