import { FETCH_APPS } from '../actionTypes';

const initialState = [];

const AppsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPS.SUCCESS: {
      return [...action.payload
        .map(({ disableOnDateProps = {}, ...app }) => ({
          ...app,
          ...disableOnDateProps
        }))
      ];
    }
    default:
      break;
  }
  return state;
};

export default AppsReducer;
