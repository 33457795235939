import { handleCustomNavigation } from '@jotforminc/utils';
import { checkMobilePhone, isValidLink } from '../../../utils';
import appConfig from '../../../constants/appConfig';
import * as ACTION_CREATORS from '../../../store/actionCreators';
import { BUTTON_ROLE_TYPES } from '../../../modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import { CARD_ACTION_VALUES } from '../../../modules/Builder/components/HomePage/RightPanel/constants';

const createActionHandler = actionFunc => actionParams => {
  return actionFunc(actionParams);
};

const linkAction = ({ value }) => {
  if (!isValidLink(value)) {
    return;
  }
  const targetWindow = handleCustomNavigation(value, '_blank', true);
  targetWindow.opener = null;
  targetWindow.location = value;
};

const phoneAction = ({ value }) => {
  const isMobile = checkMobilePhone();
  const isEmbedded = window.location.href.includes(appConfig.embeddedAppQS);

  if (isEmbedded && isMobile) {
    handleCustomNavigation(`tel:${value}`, '_blank', true);
  } else {
    handleCustomNavigation(`tel:${value}`, '_self', true);
  }
};

const mailAction = ({ value }) => {
  handleCustomNavigation(`mailto:${value}`, '_blank', true);
};

const viewFormAction = ({ onFormViewClick, value, itemType }) => {
  onFormViewClick(value, itemType);
};

const navigationAction = ({ dispatch, value }) => {
  dispatch(ACTION_CREATORS.navigationItemClickAction(value, true));
};

const shareAction = ({ onShareAppClick, value }) => {
  onShareAppClick(value);
};

const ACTION_LIST = {
  VIEW_FORM: createActionHandler(viewFormAction),
  LINK: createActionHandler(linkAction),
  MAIL: createActionHandler(mailAction),
  NAVIGATION: createActionHandler(navigationAction),
  PHONE: createActionHandler(phoneAction),
  SHARE: createActionHandler(shareAction)
};

export const cardActions = {
  [CARD_ACTION_VALUES.FORM]: ACTION_LIST.VIEW_FORM,
  [CARD_ACTION_VALUES.LINK]: ACTION_LIST.LINK,
  [CARD_ACTION_VALUES.MAIL]: ACTION_LIST.MAIL,
  [CARD_ACTION_VALUES.NAVIGATION]: ACTION_LIST.NAVIGATION,
  [CARD_ACTION_VALUES.PHONE]: ACTION_LIST.PHONE,
  [CARD_ACTION_VALUES.NONE]: () => { }
};

export const buttonActions = {
  ...cardActions,
  [BUTTON_ROLE_TYPES.SHARE]: ACTION_LIST.SHARE
};

export const imageActions = {
  ...cardActions
};
