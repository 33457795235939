import React, { useState, useCallback } from 'react';
import { PickerModal } from '@jotforminc/picker-modal';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { openFormLimitDialog } from '@jotforminc/limit-dialog';
import {
  arrayOf, bool, func, number, shape, string
} from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import SelectTemplate from '../../SelectTemplate';
import { ScFormPicker } from './ScFormPicker';
import ResourcePickerWithControlBar from '../../../../../components/ResourcePickerWithControlBar';
import SELECTORS from '../../../../../store/selectors';
import { onFormPickerModalConfirm, useFormCreateAction, useTemplateAction } from '../../../../../store/actionCreators';
import NotFoundListPlaceholder from './NotFoundListPlaceholder';
import Header from './Header';
import Footer from './Footer';

const FormPickerModal = ({
  onConfirm, onClose, isMultiSelect, initialSelectedForms, useNonPortalForms, isChangeFormAction, page, portalOrder, withClicking, ...rest
}) => {
  const dispatch = useDispatch();
  const user = useSelector(SELECTORS.getUser);
  const forms = useSelector(useNonPortalForms ? SELECTORS.getNonPortalForms : SELECTORS.getForms);
  const [selectedForms, setSelectedForms] = useState(initialSelectedForms);
  let usedFormIDs = useNonPortalForms ? useSelector(SELECTORS.getUsedPortalFormIDs) : [];
  let hasSentbox = false;
  if (isChangeFormAction) {
    usedFormIDs = usedFormIDs.filter(id => !id.includes(initialSelectedForms));
    hasSentbox = !!useSelector(SELECTORS.getSentboxByFormID(initialSelectedForms[0]));
  }

  const hasForms = forms.length > 0;

  const getLimitInfo = userData => {
    const formUsage = userData?.usage?.form_count;
    const formLimit = userData?.account_type?.limits?.formCount;
    const belowLimits = formUsage && formLimit && parseInt(formUsage, 10) < parseInt(formLimit, 10);
    return { formUsage, belowLimits };
  };

  const handleSelectionChange = useCallback(selection => setSelectedForms(Array.isArray(selection) ? selection : [selection]), []);

  const handlePickerClose = () => {
    setSelectedForms([]);
    onClose();
  };

  const handleAddButtonClick = useCallback(() => {
    if (onConfirm) {
      onConfirm(selectedForms);
    } else {
      dispatch(onFormPickerModalConfirm({
        selection: selectedForms,
        page,
        portalOrder,
        withClicking
      }));
    }
    handlePickerClose();
  }, [onConfirm, selectedForms]);

  const handleSelectFormTemplate = cloneFormData => {
    const { belowLimits, formUsage } = getLimitInfo(user);

    if (!belowLimits && formUsage !== undefined) {
      handlePickerClose();
      const portalElement = document.querySelector('#modal-root');
      if (portalElement !== null && !isEnterprise()) {
        openFormLimitDialog({ user, portalRoot: document.querySelector('#modal-root') });
      }
    } else {
      dispatch(useTemplateAction({
        cloneData: {
          ...cloneFormData, source: 'APPS-FORM-ELEMENT', action: 'cloneForm', context: 'standalone'
        }
      }));
      handlePickerClose();
    }
  };

  const handleFormCreate = formID => {
    const { belowLimits, formUsage } = getLimitInfo(user);

    if (!belowLimits && formUsage !== undefined) {
      handlePickerClose();
      const portalElement = document.querySelector('#modal-root');
      if (portalElement !== null && !isEnterprise()) {
        openFormLimitDialog({ user, portalRoot: document.querySelector('#modal-root') });
      }
    } else {
      dispatch(useFormCreateAction(formID));
      handlePickerClose();
    }
  };

  return (
    <ScFormPicker>
      <PickerModal
        defaultVisible
        footer={(
          <Footer
            type="form"
            onAddButtonClick={handleAddButtonClick}
            selectedItemCount={selectedForms.length}
            confirmText={isMultiSelect ? 'Add Forms' : 'Select Form'}
            hasItems={hasForms}
            hasSentbox={hasSentbox}
          />
        )}
        header={<Header isMultiSelect={isMultiSelect} />}
        onClose={handlePickerClose}
        headerVisible={hasForms}
        className={!hasForms ? 'templateModal' : ''}
        exceptionalSelectorList={['.jfWizard-modal', '.jfSelect-list', '.jfSelect-searchBox', '.jfWizard-modal-wrapper']}
      >
        {
          hasForms ? (
            <ResourcePickerWithControlBar
              isChangeFormAction={isChangeFormAction}
              initialSelectedForm={initialSelectedForms[0]}
              isMultiSelect={isMultiSelect}
              usedResourceIDs={usedFormIDs}
              selectedResources={selectedForms}
              onSelectionChange={handleSelectionChange}
              notFoundListPlaceholder={NotFoundListPlaceholder}
              {...rest}
            />
          ) : (
            <SelectTemplate
              user={user}
              templateType='form'
              source='app-form-element'
              handlePickerClose={handlePickerClose}
              handleClone={handleSelectFormTemplate}
              handleFormCreate={handleFormCreate}
            />
          )
        }
      </PickerModal>
    </ScFormPicker>

  );
};

FormPickerModal.propTypes = {
  onConfirm: func,
  onClose: func.isRequired,
  forms: arrayOf(shape({})),
  isMultiSelect: bool,
  initialSelectedForms: arrayOf(string),
  usedFormIDs: arrayOf(string),
  useNonPortalForms: bool,
  page: string,
  portalOrder: number,
  withClicking: bool,
  isChangeFormAction: bool
};

FormPickerModal.defaultProps = {
  onConfirm: undefined,
  forms: [],
  isMultiSelect: true,
  initialSelectedForms: [],
  usedFormIDs: [],
  useNonPortalForms: true,
  page: '',
  portalOrder: 0,
  withClicking: false,
  isChangeFormAction: false
};

export default FormPickerModal;
