import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SliderCarousel from './SliderCarousel';
import './styles/app.scss';
import SliderArrow from '../../assets/svg/sliderArrow.svg';

const Slider = ({ imgArray, isDraggable }) => {
  if (imgArray.length === 0) {
    return null;
  }

  const [onTouchState, setOnTouchState] = useState(0);
  const [currentImg, setCurrentImg] = useState(0);
  const [sliderImageWidth, setSliderImageWidth] = useState(800);
  const imgIndex = imgArray.length - 1;
  const isSwitchable = imgArray.length > 1;
  const sliderImageEl = useRef(null);

  useEffect(() => {
    setSliderImageWidth(sliderImageEl.current.offsetWidth + 34);
  }, []);

  const handleNextImage = () => {
    if (currentImg !== imgIndex) {
      setCurrentImg(currentImg + 1);
    } else {
      setCurrentImg(0);
    }
  };

  const handlePrevImage = () => {
    if (currentImg !== 0) {
      setCurrentImg(currentImg - 1);
    } else {
      setCurrentImg(imgIndex);
    }
  };

  const onTouchStarted = e => {
    if (e.changedTouches[0]) {
      setOnTouchState(e.changedTouches[0].clientX);
    }
  };

  const onTouchEnded = e => {
    if (e.changedTouches[0]) {
      if (e.changedTouches[0].clientX - onTouchState < -50) {
        handleNextImage();
      } else if (e.changedTouches[0].clientX - onTouchState > 50) {
        handlePrevImage();
      }
    }
  };

  return (
    <div className='sliderContainer'>
      <div className='imageContainer'>
        <div className='sliderWrapper' style={{ transform: `translateX(-${currentImg * sliderImageWidth}px)` }}>
          {imgArray.map((imgs, index) => {
            return (
              <div
                className='sliderImage'
                ref={sliderImageEl}
                draggable={isDraggable && isSwitchable}
                onTouchStart={onTouchStarted}
                onTouchEnd={onTouchEnded}
                style={{ left: index * sliderImageWidth }}
                key={imgs}
              >
                <img
                  src={imgs}
                />
              </div>
            );
          })}
        </div>
        {isSwitchable && (
          <>
            <div className="line-icon arrow-next" onClick={handleNextImage}><SliderArrow className='arrow-svg' /></div>
            <div className="line-icon arrow-prev" onClick={handlePrevImage}><SliderArrow className='arrow-svg' /></div>
          </>
        )}
      </div>
      {isSwitchable && (
        <SliderCarousel imgArray={imgArray} currentImg={currentImg} setCurrentImg={setCurrentImg} />
      )}
    </div>
  );
};

Slider.propTypes = {
  imgArray: PropTypes.array,
  isDraggable: PropTypes.bool
};

Slider.defaultProps = {
  imgArray: [],
  isDraggable: true
};

export default Slider;
