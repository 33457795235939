import { Hooks } from '@jotforminc/uikit';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import { func, string } from 'prop-types';
import { move } from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { IconPlus } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';

import Styled from 'styled-components';
import SchemaItemList from './SchemaItemList';
import { parseDefinition, getItems, constructValue } from './utils';

const ScScheme = Styled.div`
  .schemaItemWrapper {
    margin-top: 20px;

    &:first-of-type {
      margin-top: unset;
    }
  }
`;

const Schema = ({ onChange, value: rawValue, definition }) => {
  const definitionItems = useMemo(() => parseDefinition(definition), [definition]);

  const {
    name,
    fieldsDefinition
  } = definitionItems;

  const itemsArray = useMemo(() => getItems(rawValue, fieldsDefinition), [rawValue, fieldsDefinition]);

  const [items, setItems] = useState(itemsArray);
  const [editableItemID, setEditableItemID] = useState();

  const handleChangeEditableID = useCallback(id => {
    setEditableItemID(id);
  }, []);

  const handleSave = useCallback(data => {
    const otherItems = items.filter(item => item.id !== data.id);
    const newItems = sortBy([...otherItems, data], 'id');
    setItems(newItems);

    // construct the value to send the DB
    const constructedValue = constructValue(newItems);
    onChange(constructedValue);
  }, [items]);

  const handleSortChange = useCallback(({ oldIndex, newIndex }) => {
    const newItems = move(oldIndex, newIndex, items);
    setItems(newItems);
    const constructedValue = constructValue(newItems);
    onChange(constructedValue);
  }, [items]);

  const handleDelete = useCallback(id => {
    const newItems = items.filter(item => item.id !== id);
    const sortedItems = sortBy(newItems, 'id');
    setItems(sortedItems);

    const constructedValue = constructValue(sortedItems);
    onChange(constructedValue);

    setEditableItemID('');
  }, [items]);

  const handleAddNewItem = useCallback(() => {
    const ids = items.reduce((prev, item) => ([...prev, item.id]), []);
    const maxID = max(ids);

    const newItemID = (maxID === 0 || !!maxID) ? (maxID + 1) : 0;
    const newItems = [...items, {
      id: newItemID,
      fields: fieldsDefinition.map(p => ({
        label: p.label,
        type: p.type,
        value: ''
      }))
    }];
    setItems(newItems);
    setEditableItemID(newItemID);
  }, [fieldsDefinition, items]);

  const handleItemCancel = useCallback(() => {
    const currentItems = getItems(rawValue, fieldsDefinition);
    setItems(currentItems);
  }, [rawValue, fieldsDefinition]);

  const AddNewButton = useCallback(() => {
    return (
      <Button
        fullWidth
        startIcon={IconPlus}
        type="button"
        onClick={handleAddNewItem}
        className='mt-5'
      >
        {t(`Add New ${name}`)}
      </Button>
    );
  }, [handleAddNewItem]);

  Hooks.useEffectIgnoreFirst(() => {
    const newItems = getItems(rawValue, fieldsDefinition);
    setItems(newItems);
  }, [rawValue, fieldsDefinition]);

  return (
    <ScScheme className="widgetsConfig">
      {items.length > 0 && (
        <SchemaItemList
          name={name}
          items={items}
          editableItemID={editableItemID}
          onSave={handleSave}
          onCancel={handleItemCancel}
          onSortChange={handleSortChange}
          onDelete={handleDelete}
          onChangeEditableID={handleChangeEditableID}
        />
      )}
      {typeof editableItemID !== 'number' && <AddNewButton />}
    </ScScheme>
  );
};

Schema.propTypes = {
  value: string,
  definition: string,
  onChange: func
};

Schema.defaultProps = {
  value: '',
  definition: 'Testimonial\ntext:Label\nimage:Image\ntextarea:Testimonial',
  onChange: f => f
};

export default Schema;
