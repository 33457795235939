/* eslint-disable max-len */
import React from 'react';
import {
  bool, string, shape
} from 'prop-types';
import Styled from 'styled-components';
import { t } from '@jotforminc/translation';
import { IconGridDotsVertical, IconProductApprovalsColor } from '@jotforminc/svg-icons';
import { getShortMonthName } from '../../../../utils/utils';
import FormItemWrapper from '../../../FormPicker/FormItem/styled';
import { CheckBox } from '../../../FormPicker/CheckBox';

const ScIconWrapper = Styled.div`
  position: relative;
  margin-right: 12px;
  z-index: 1;

  html[dir="rtl"] & {
    margin-right: 0;
    margin-left: .2em;
  }

  svg {
    width: 36px;
    height: 36px;
  }
`;

const ApprovalItem = ({
  option: {
    form_id: formId,
    title,
    status,
    last_activity: lastActivity,
    created_at: createdAt
  },
  isSelected,
  isMultiSelect,
  showFormId,
  isDraggable
}) => {
  const lastActivityDate = (lastActivity ? lastActivity.split(' ')[0] : createdAt.split(' ')[0]).split('-');
  const lastActivityReadable = `${getShortMonthName(parseInt(lastActivityDate[1], 10))} ${lastActivityDate[2]}, ${lastActivityDate[0]}`;

  return (
    <FormItemWrapper tabIndex="0">
      {isMultiSelect && <CheckBox isSelected={isSelected} />}
      <div className="itemInfo">
        <ScIconWrapper className="lsApp-list-item-type">
          <IconProductApprovalsColor />
        </ScIconWrapper>
      </div>
      <div className="itemContent">
        <h2 className="itemTitle">
          {title}
        </h2>
        <p className="itemDesc">
          {`${showFormId ? `${formId}. ` : ''} ${t('Last activity on')} ${lastActivityReadable}.`}
        </p>
      </div>
      {isDraggable && (
        <div className="itemDragHandle">
          <IconGridDotsVertical />
        </div>
      )}
      {status !== 'ENABLED' && (
        <div className="itemDisabled" aria-hidden={true}>
          {t('This approval is disabled.')}
        </div>
      )}
    </FormItemWrapper>
  );
};

const approvalItemProperties = shape({
  form_id: string,
  title: string,
  status: string,
  last_activity: string,
  created_at: string
});

ApprovalItem.propTypes = {
  option: approvalItemProperties.isRequired,
  isMultiSelect: bool,
  isSelected: bool.isRequired,
  showFormId: bool,
  isDraggable: bool
};

ApprovalItem.defaultProps = {
  isMultiSelect: true,
  showFormId: false,
  isDraggable: false
};

export default ApprovalItem;
