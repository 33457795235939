import React from 'react';
import { Textarea } from '@jotforminc/magnet';
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

interface ITextArea {
  name: PAYMENT_QUESTION_PROP_NAMES;
  placeholder?: string;
  onChange: (e: Event) => void;
  default: string;
}

const TextArea: React.FunctionComponent<ITextArea> = ({
  name,
  placeholder,
  default: defaultValue,
  onChange
}) => {
  const { gatewayProperties, invalidPropNames } = usePaymentPropsPanel();
  const val = gatewayProperties[name] || defaultValue;

  return (
    <Textarea
      id={name}
      className="h-40"
      onChange={e => onChange(e.target.value)}
      colorStyle={invalidPropNames.includes(name) ? 'error' : 'default'}
      placeholder={placeholder}
      resize="vertical"
      theme="dark"
      value={val}
      // maxLength
    />
  );
};

TextArea.defaultProps = {
  placeholder: undefined
};

export default TextArea;
