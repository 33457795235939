import { getAppPath as jfGetBasePath } from '@jotforminc/router-bridge';

import { isAppSubdomain } from '../utils';

const appTemplatesPreviewPrefix = '/app-templates/preview/app';
export const getBasePath = () => {
  const basePath = jfGetBasePath();
  const appSlug = window?.__appSlug;
  const userSlug = window?.__userSlug;
  const isStandaloneWithSlug = window?.isStandaloneWithSlug;
  const isAppJotform = isAppSubdomain();
  switch (true) {
    case isAppJotform && isStandaloneWithSlug:
      return `/${userSlug}/${appSlug}`;
    case isAppJotform:
      return '/';
    case window.location.href.includes(appTemplatesPreviewPrefix):
      return appTemplatesPreviewPrefix;
    case isStandaloneWithSlug:
      return `${basePath}/${userSlug}/${appSlug}`;
    default:
      return basePath;
  }
};

export default {
  basePath: getBasePath(),
  svgIconsContainer: 'app-icons',
  builderToasterContainerID: 'builderPortalToaster',
  publicToasterContainerID: 'publicPortalToaster',
  sentryURL: 'https://36d29a013f8a4bd7adfe310f5fe4d75a@o61806.ingest.sentry.io/5223511',
  embeddedAppQS: 'appEmbedded=1'
};
