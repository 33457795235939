import React from 'react';
import { shape } from 'prop-types';

const Text = ({ style, ...props }) => <div className="fileUpload-verticalAlign-text" style={{ textAlign: 'center', ...style }} {...props} />;

Text.propTypes = {
  style: shape({})
};

Text.defaultProps = {
  style: {}
};

export default Text;
