/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, {
  useState, useRef, useEffect
} from 'react';
import { object } from 'prop-types';
import { IconEyeFilled } from '@jotforminc/svg-icons';
import { capitalizeFirstLetter, classNames } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';

import './style.scss';
import RoleSelector from '../RoleSelector';
import { templateConstants } from '../../templateConstants';
// import Button from '../../../Button';
// import texts from '../../../../core/texts';

const PDFTemplateAreaRenderer = ({
  template, DOM,
  setModelContentLoading, templateType
}) => {
  const objectRef = useRef();
  const [signers, setSigners] = useState([]);
  const [signAnnotations, setSignAnnotations] = useState([]);
  const [currentParticipantID, setCurrentParticipantID] = useState('');
  const [loadingPreview, setLoadingPreview] = useState(true);

  const {
    _id,
    screenshot
  } = template;

  const { aliasType } = templateConstants[templateType];

  const resetPreviewStates = () => {
    setSigners([]);
    setSignAnnotations([]);
    setLoadingPreview(true);
    setCurrentParticipantID('');
  };

  useEffect(() => {
    resetPreviewStates();
  }, [template]);

  const getIframeInsideObject = objectEl => {
    if (!objectEl) return;
    const iFrameElement = objectEl.querySelector('IFRAME');
    const { contentDocument, contentWindow } = iFrameElement !== null ? iFrameElement : {};
    if (!contentWindow) return;
    return contentDocument || contentWindow.document;
  };

  const isFirstPdfPageRendered = iframeDoc => iframeDoc.getElementsByClassName('PDFV-canvas').length > 0;

  const getOwnerName = owner => {
    const { role, type } = owner;
    if (type === 'owner') return 'Owner';
    return capitalizeFirstLetter(role || type);
  };

  const formatAnnotations = (annotations, iframeDoc) => {
    if (!iframeDoc) return;
    return annotations.map(annotation => {
      const { id } = annotation;
      return {
        id,
        ref: iframeDoc.querySelector(`[data-annotation-id="${id}"]`)
      };
    });
  };

  const handleLoadObject = (event, tryCount = 0) => {
    const { current } = objectRef || {};
    if (!current) return;

    const { contentDocument, contentWindow = {} } = current;
    if (!contentDocument) return;

    if (tryCount > 100) {
      setLoadingPreview(false);
    }

    const iframeDoc = getIframeInsideObject(contentDocument);
    if (!iframeDoc || iframeDoc.readyState !== 'complete') {
      setTimeout(() => handleLoadObject(null, tryCount + 1), 100);
      return;
    }

    if (!isFirstPdfPageRendered(iframeDoc)) {
      setTimeout(() => handleLoadObject(null, tryCount + 1), 100);
      return;
    }
    setLoadingPreview(false);
    const { documentProps: { participants }, annotations = [] } = contentWindow;

    const allAnnotations = formatAnnotations(annotations, iframeDoc).filter(annotation => annotation.ref !== null);

    if (allAnnotations.length !== annotations.length && tryCount !== 100) {
      setTimeout(() => handleLoadObject(null, tryCount + 1), 100);
      return;
    }

    setSignAnnotations(allAnnotations);
    setSigners(
      participants.map(singer => {
        return { ...singer, prettyName: getOwnerName(singer) };
      })
    );
  };

  const showHideAnnotationElement = (show, element) => {
    if (show) {
      element.removeAttribute('data-participant');
    } else {
      element.setAttribute('data-participant', 'passive');
    }
  };

  const handleRoleChange = event => {
    const selectedValue = event?.value?.target || event?.value;

    setCurrentParticipantID(selectedValue);
    const { fields: selectedParticipantFields } = signers.find(signer => signer.participantID === selectedValue) || { fields: [] };
    signAnnotations.forEach(annotation => {
      const { ref: annotationElement } = annotation;
      if (selectedValue === '') {
        showHideAnnotationElement(true, annotationElement);
      } else {
        const qid = annotationElement.getAttribute('data-qid');
        const isPassive = !selectedParticipantFields.includes(qid);
        showHideAnnotationElement(!isPassive, annotationElement);
      }
    });
  };

  const signerOptions = [{ prettyName: 'All Roles ', participantID: '' }, ...signers];
  const isRoleBarActive = signers.length > 0;

  const { signable } = template;
  const isSignablePDF = signable === '1';

  // eslint-disable-next-line max-len
  const embedPDFCss = '<link rel="stylesheet" type="text/css" href="https://cdn.jotfor.ms/s/pdf-editor/latest/static/css/templates.css?v=1.2"><style>*{box-sizing: border-box;}html{overflow-x:hidden}.jfBranding{display:none!important;}</style>';
  return (
    <>
      <div
        className="modal-template-area relative z-1 md:min-h-sm duration-200 w-full h-120 lg:border border-navy-75 overflow-hidden lg:radius-t-md"
      >
        <div className="bg-navy-75 w-full h-full relative flex items-center justify-center">
          {loadingPreview && (
            <>
              <div className="jfWizard--cover-loading">
                <span />
                <span />
                <span />
              </div>
              {
                isSignablePDF && (
                  <img
                    src={screenshot}
                    alt='PDF Preview'
                    className="sign-preview-image absolute top-0 mt-14 opacity-50 z-0 pointer-events-none"
                  />
                )
              }
            </>
          )}
          {
            isSignablePDF ? (
              // eslint-disable-next-line jsx-a11y/alt-text
              <object
                ref={objectRef}
                type="text/html"
                onLoad={event => {
                  handleLoadObject(event);
                  setModelContentLoading(false);
                }}
                id="pdf-template-preview"
                className='w-full h-full relative z-2'
                data={`/${(aliasType || templateType)}/preview/esign/${_id}`}
                style={{
                  opacity: loadingPreview ? 0 : 1, height: loadingPreview ? '0px' : '100%'
                }}
              />
            ) : (
              <div className='template-area w-full h-full'>
                <object
                  title='PDF Preview Area'
                  className='pdf-template-iframe relative border-0 z-1'
                  onLoad={() => {
                    setTimeout(() => { setLoadingPreview(false); }, 1000);
                    setModelContentLoading(false);
                  }}
                  data={`data:text/html,${encodeURIComponent(`${embedPDFCss}${DOM}`)}`}
                  style={{
                    opacity: loadingPreview ? 0 : 1, zIndex: 0
                  }}
                />
              </div>

            )
          }
        </div>
      </div>
      {
        isSignablePDF && (
          <div className={classNames('jfWizard-e-sign-bar', isRoleBarActive && 'active')}>
            <div className="jfWizard-e-sign-bar--preview">
              <div className="jfWizard-e-sign-bar--preview-text">
                <IconEyeFilled width="16" height="16" />
                <span>{t('Previewing as')}</span>
                :
              </div>
              <RoleSelector
                options={signerOptions.map(option => {
                  return {
                    text: option.prettyName,
                    value: option.participantID,
                    color: option.color
                  };
                })}
                onChange={handleRoleChange}
                selectedOptionValue={currentParticipantID}
              />
            </div>
            <div className="jfWizard-e-sign-bar--text">
              {t('Customize this template and share it to collect e-signatures')}
            </div>
          </div>
        )
      }
    </>
  );
};

PDFTemplateAreaRenderer.defaultProps = {
  template: {}
};

PDFTemplateAreaRenderer.propTypes = {
  template: object
};

export default PDFTemplateAreaRenderer;
