import React from 'react';
import { TabList, Tab } from '@jotforminc/tabs';

import { func } from 'prop-types';
import ColorPalette from '../ColorPalette';
import {
  getTabMenuList
} from '../../constants/colorPalette';
import CustomPalette from './CustomPalette';
import { ScColorPaletteButton, ScItemStylerWrapper } from './scItemStyler';

const tabMenuContainer = itemProps => <div className="tabMenu" {...itemProps} />;
const tabMenuButtonWrapper = itemProps => <a className="tabMenu-inner-link" {...itemProps} />;
const tabContentWrapper = itemProps => <div className='tabContent' {...itemProps} />;

const ItemStyler = ({
  onChange, onSchemeChange, ...props
}) => {
  return (
    <ScItemStylerWrapper data-testid="itemStyler">
      <TabList
        role="tablist"
        list={getTabMenuList()}
        TabButtonContainer={tabMenuContainer}
        TabButton={tabMenuButtonWrapper}
        TabListContainer={tabContentWrapper}
      >
        <Tab id="Palette" key="Palette">
          <ColorPalette
            onChange={onSchemeChange}
            ButtonWrapper={ScColorPaletteButton}
          />
        </Tab>
        <Tab id="Custom" key="Custom">
          <CustomPalette onChange={onChange} {...props} />
        </Tab>
      </TabList>
    </ScItemStylerWrapper>
  );
};

ItemStyler.propTypes = {
  onChange: func,
  onSchemeChange: func
};

ItemStyler.defaultProps = {
  onChange: f => f,
  onSchemeChange: f => f
};

export default ItemStyler;
