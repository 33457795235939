import React, { createRef } from 'react';
import { string, func, shape } from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { JFExternalWidgetParamsServer } from '@jotforminc/widgets-js-sdk';
import { connect } from 'react-redux';
import SELECTORS from '../../../../store/selectors';

// taken from builder repo
// adjusted for app builder
class ExternalField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastReceivedMessage: null
    };

    // bindings
    this.handleExternalFieldFrameLoad = this.handleExternalFieldFrameLoad.bind(this);

    // holds JFExternalWidgetParamsServer token
    this.JFExternalWidgetToken = null;
    this.externalIframeRef = createRef();
  }

  componentWillUnmount() {
    // remove load listener of external field iframe if any
    // const { externalFieldIframe } = this.refs;
    if (this.externalIframeRef) {
      const node = this.externalIframeRef.current;
      node.removeEventListener('load', this.handleExternalFieldFrameLoad);
    }

    // unsubcribe to any JFExternalWidgetParamsServer
    if (!isUndefined(this.JFExternalWidgetToken) && JFExternalWidgetParamsServer) {
      JFExternalWidgetParamsServer.unsubscribe(this.JFExternalWidgetToken);
    }
  }

  /**
   * calls on onload of iframe defined above
   * loads an external script and send messages to that iframe
   * @param  {event} e      iframe onload event
   * @param  {[type]} params [description]
   * @return {[type]}        [description]
   */
  handleExternalFieldFrameLoad(e) {
    const {
      value: val, onChange, itemID, src, name
    } = this.props;
    const { target } = e;
    const qid = parseInt(itemID, 10);
    const { formID } = this.props;
    const params = {
      formID,
      qid,
      name,
      ref: src,
      value: val
    };
    JFExternalWidgetParamsServer.begin(() => {
      this.JFExternalWidgetToken = JFExternalWidgetParamsServer.subscribe('paramData', message => {
        if (message && parseInt(message.qid, 10) === parseInt(params.qid, 10) && message.name === name) {
          // set state
          this.setState({
            lastReceivedMessage: message
          });
          // this.lastObtainedToken = message.value;

          // trigger onchange event
          const { value = '' } = message;
          onChange(value);
        }
      });
      const frame = document.getElementById(`externalFrame_key_${qid}_${name}`);
      JFExternalWidgetParamsServer.subscribe('frameResize', message => {
        if (message && parseInt(message.qid, 10) === parseInt(params.qid, 10) && message.name === name) {
          const height = parseInt(message.height, 10);
          if (!Number.isNaN(height)) {
            frame.style.height = `${height}px`;
          }
        }
      });
    }, params, target);
  }

  /**
   * Properly appends query string to a given url
   * http://stackoverflow.com/a/6021027/1460756
   */
  updateQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    let newuri = '';
    if (uri.match(re)) {
      newuri = uri.replace(re, `$1${key}=${value}$2`);
    } else {
      newuri = `${uri + separator + key}=${value}`;
    }
    return newuri;
  }

  render() {
    const { lastReceivedMessage } = this.state;
    const {
      id, src, name, question = {}, itemID: qid
    } = this.props;
    // let { qid } = question;
    const uri = `${encodeURIComponent(`${window.location.protocol}//${window.location.host}`)}`;
    let source = this.updateQueryStringParameter(src, 'ref', uri);
    source = this.updateQueryStringParameter(source, 'qid', qid);
    source = this.updateQueryStringParameter(source, 'name', name);
    source = this.updateQueryStringParameter(source, 'env', global.window.JOTFORM_ENV || 'PRODUCTION');

    let lastReceivedValue = lastReceivedMessage ? lastReceivedMessage.value : '';
    // if property is already set in question properties
    // - since src came from `default` param, we have to make sure we are getting the right value
    // - question prop value has the default value if the value prop is empty
    if (!isUndefined(question[name]) && question[name] !== src) {
      lastReceivedValue = question[name];
    }
    this.lastObtainedToken = lastReceivedValue;

    return (
      <div>
        <iframe
          frameBorder="0"
          scrolling="no"
          width="100%"
          alllowtransparency={true}
          allow="geolocation; microphone; camera"
          id={`externalFrame_key_${qid}_${name}`}
          src={source}
          data-refkey="key"
          onLoad={this.handleExternalFieldFrameLoad}
          // ref="externalFieldIframe"
          ref={this.externalIframeRef}
          style={{ height: 38 }}
          title={`widget_${qid}`}
        />
        <input
          id={id} type="hidden" value={lastReceivedValue}
          name={name}
        />
      </div>
    );
  }
}

ExternalField.propTypes = {
  src: string.isRequired,
  question: shape({}),
  name: string.isRequired,
  id: string.isRequired,
  formID: string.isRequired,
  onChange: func.isRequired,
  itemID: string.isRequired,
  value: string
};

ExternalField.defaultProps = {
  question: {},
  value: ''
};

const mapStateToProps = state => ({
  itemID: SELECTORS.getSelectedPortalItem(state)
});

export default connect(mapStateToProps)(ExternalField);
