import React, { forwardRef } from 'react';
import { func, string } from 'prop-types';
import { IconMagnifyingGlass } from '@jotforminc/svg-icons';

const FontPickerSearch = forwardRef(({
  defaultFont, onChange, placeholder, value
}, ref) => ((
  <div className="jfc-font-picker-search">
    <span className="jfc-font-picker-search__icon">
      <IconMagnifyingGlass />
    </span>
    <input
      className="jfc-font-picker-search__input"
      onChange={onChange}
      type="text"
      placeholder={placeholder}
      ref={ref}
      style={
        {
          fontFamily: `${defaultFont}`
        }
      }
      value={value}
    />
  </div>
)));

FontPickerSearch.propTypes = {
  defaultFont: string,
  onChange: func,
  placeholder: string,
  value: string
};

FontPickerSearch.defaultProps = {
  defaultFont: '',
  onChange: f => f,
  placeholder: 'Search fonts...',
  value: ''
};

export default FontPickerSearch;
