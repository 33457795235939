import { string, shape } from 'prop-types';

export const formProperties = shape({
  id: string,
  title: string,
  count: string,
  new: string,
  updated_at: string,
  created_at: string
});
