import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from '@jotforminc/translation';
import { string, oneOfType, node } from 'prop-types';
import { IconXmark } from '@jotforminc/svg-icons';
import { Hooks } from '@jotforminc/uikit';
import SELECTORS from '../../../store/selectors';
import Button from '../../../components/Button';
import { ScRightPanel } from '../styles/scRightPanel';
import { renderable } from '../../../constants/propTypes';
import { setAppDesignModeAction, toggleRightPanelAction } from '../../../store/actionCreators';
import { APP_DESIGN_MODES } from '../constants/designModes';
import '@jotforminc/jotform.css';
// eslint-disable-next-line no-restricted-imports
import '@jotforminc/jotform.css/panel-variables.css';
import { isNewPanel } from '../../../utils';

export const RightPanelContainer = ({ children, title }) => {
  const dispatch = useDispatch();
  const isRightPanelOpen = useSelector(SELECTORS.isRightPanelOpenSelector);
  const appDesignMode = useSelector(SELECTORS.getAppDesignModeSelector);
  const rpRef = Hooks.useContinueFocus(isRightPanelOpen);
  const isMultiPage = useSelector(SELECTORS.getIsMultiPage);

  const handleClose = () => {
    if (appDesignMode !== APP_DESIGN_MODES.app) dispatch(setAppDesignModeAction(APP_DESIGN_MODES.app));
    dispatch(toggleRightPanelAction(false));
  };

  return (
    <ScRightPanel
      ref={rpRef}
      role="complementary"
      aria-label={t('Right Panel Properties')}
      tabIndex={-1}
      className={`rightPanelContainer ${isRightPanelOpen ? 'isOpen' : ''} ${isNewPanel() && 'newPanel'}`}
      isMultiPage={isMultiPage}
    >
      <div className="rp-content">
        <div className="rp-topBar">
          <div className="rp-title">{title}</div>
          <button
            aria-label={t('Close')}
            className='
                  absolute top-1/2 transform -translate-y-1/2 right-3 z-9 bg-transparent color-white border-0 w-9 h-9 inline-flex justify-center items-center radius-full hover:bg-gray-700 duration-300
                '
            data-testid='rightPanelCloseBtn'
            onClick={handleClose}
            type='button'
          >
            <IconXmark className='w-6 h-6' />
          </button>
        </div>
        {children}
      </div>
    </ScRightPanel>
  );
};

/* eslint-disable react/prop-types */
export const RPTabButtonWrapper = ({ onClick, itemTabs, style }) => ({
  onClick: tabClick, 'data-eventkey': eventKey, ...props
}) => {
  const isActive = props['data-isactive'];
  // TODO: @Bilge, find the root cause of itemTabs not containing the eventKey for a couple renders
  const activeTab = itemTabs[eventKey] || {};
  const element = activeTab || {};
  const { Icon, hasIcon = false } = element;

  const onTabClick = () => {
    onClick(props);
    tabClick();
  };
  return (
    <>
      {!hasIcon ? (
        <Button
          className="tabMenu-inner-link" {...props}
          onClick={onTabClick}
          style={style}
          role="tab"
          aria-selected={isActive}
        />
      )
        : (
          <Button
            className={`bare tabMenu-outer-link ${isActive && 'isActive'}`}
            aria-selected={isActive}
            onClick={onTabClick}
          >
            <div className="svgWrapper"><Icon /></div>
            <div
              className="tabMenu-inner-link" {...props}
            />
          </Button>
        )}
    </>
  );
};
/* eslint-enable react/prop-types */

export const RPTabButtonContainer = props => (<div className="tabMenu" role="tablist" {...props} />);

RightPanelContainer.propTypes = {
  children: renderable.isRequired,
  title: oneOfType([string, node]).isRequired
};
