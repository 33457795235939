import React from 'react';

const Steps = ({
  title,
  steps
} : IProps) : JSX.Element => {
  return (
    <div className="pwSteps">
      <span className='pwSteps-title'>{title}</span>
      <ul className='pwSteps-step'>
        {steps.map((stepObj, index) => (
          <li>{`${index + 1}. ${stepObj.step}`}</li>
        ))}
      </ul>
    </div>
  );
};

interface ISteps {
  step: string | number
}
export interface IProps {
  title: string,
  steps: ISteps[];
}

export default Steps;
