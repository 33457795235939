import React from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';

import { linkCVA } from './link.cva';
import { LinkProps, linkDefaultProps } from './link.types';

export const Link = (props: LinkProps): JSX.Element => {
  const {
    className, colorStyle, disabled, children, ...rest
  } = props;

  const linkClassName = cx(className, linkCVA({
    colorStyle,
    disabled
  }));

  return (
    <a
      className={linkClassName}
      {...rest}
    >
      {children}
    </a>
  );
};

Link.defaultProps = linkDefaultProps;
