// TODO: 'eslint-disable max-lines' will be deleted by refactoring
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* stylelint-disable selector-no-id, declaration-no-important */
import Styled, { css } from 'styled-components';
import { DEFAULT_BUILDER_WIDTHS } from '../../../constants';
import { applyAlpha } from '../../../utils/styleUtils';
import { ScDropdown } from '../../../components/Dropdown/scDropdown';
import { primaryAccentDefault, primaryAccentDefaultHover } from '../../../styles/colors';
import { ScrollBar, ScrollBarRight } from '../../../styles/global';
import { isNewPanel } from '../../../utils';

const newPanel = isNewPanel();

export const formFieldConfirmationModal = css`
  .formFieldConfirmationModal {
    width: 94%;
    z-index: 99;

    p {
      font-size: 16px;
      line-height: 18px;
      color: #fff;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    hr {
      border: 1px solid #3e4652;
    }

    &-closeBtn {
      height: auto;
      padding: 0;
      svg {
        width: 28px;
        height: 28px;
        fill: #fff;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const suffixInput = css`
  .suffixInput {
    display: flex;
    height: 38px;
    margin: 0 16px 20px;
    border: 1px solid #303339;
    border-radius: 4px;

    input {
      flex: 1 0;
      border-radius: 2px 0 0 2px;
    }
    span {
      flex: 0 1;
      padding: 8px 12px;
      color: #8D98A9;
      background-color: #555F6E;
      border-left: 1px solid #303339;
      border-radius: 0 2px 2px 0;

      @media (max-width: 768px) {
        padding: 8px 4px;
      }
    }
  }
`;

export const tabWrappersForScrollbar = css`
  .tabListWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;


    & > div[data-activetab] {
      position: relative;
      overflow-y: auto;
      min-height: -webkit-fill-available;

      &:has(div[data-activetab="productSettings"], div[data-activetab="paymentMethod"]){
        height: 100%;
      }

      & > div {
        height: 100%;

        & > div {
          padding-bottom: 104px;
          height: 100%;

          &[data-activetab],
          &[role],
          &[data-tabid] {
            overflow-y: scroll;
            ${ScrollBar}
            ${ScrollBarRight}
          }
        }
      }
    }

    & > .tabMenu {
      border-radius: 0;
      position: sticky;
      top: 0;
      z-index: 99;
    }
  }
`;

export const tabMenu = css`
    .tabMenu {
    background-color: #303339;
    display: flex;
    user-select: none;
    border-radius: 4px 4px 0 0;
    justify-content: center;
    // A/B Test: ctAppNameIconModal
    .appNameIconSettingsModal & {
      border-radius: 0;
      background-color: #F3F3FE;
      border-bottom: 1px solid #E3E5F5;
      margin: -32px -32px 32px -32px;
    }

    .tabMenu-outer-link {
      &:focus {
        outline: none;
      }
      flex-grow: 1;
      height: 87px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      color: rgba(255, 255, 255, .75);
      text-transform: uppercase;
      font-family: inherit;
      background-color: #9B61D5;
      transition: .2s all ease;

      &:not(.isActive) {
        background-color: transparent;
        .svgWrapper {
          filter: grayscale(100);
        }
      }

      .svgWrapper {
        transition: .2s all ease;
        padding-top: 14px;
        filter: none;
      }

      .tabMenu-inner-link {
        padding: 0.875em;
        font-size: 1em;
        &::after {
          display:none;
        }
      }
    }


    .tabMenu-inner-link, a.tabMenu-link {
      font-size: .875em;
      color: rgba(255, 255, 255, .75);
      padding: var(--jfv-panel-tab-padding);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
      font-weight: 400;
      transition: all .2s ease;
      cursor: pointer;
      width: 100%;
      background: transparent;
      border: none;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #9B61D5;
        transform: scaleY(0);
        transform-origin: bottom center;
      }
      // A/B Test: ctAppNameIconModal
      .appNameIconSettingsModal & {
        color: #6C73A8;
        &:after {
          background-color: #9C4DD3;
        }
      }

      &:not(.is-active):hover {
        color: #fff;
        .appNameIconSettingsModal & {
          color: #9C4DD3;
        }
      }
      &:not([data-isactive="true"]):hover {
        color: #fff;
        .appNameIconSettingsModal & {
          color: #9C4DD3;
        }
      }
      &.is-active, &[data-isactive="true"] {
        color: #fff;
        .appNameIconSettingsModal & {
          color: #9C4DD3;
          font-weight: 700;
        }

        &:after {
          transform: scaleY(1);
          transition: transform .2s ease;
        }
      }
    }
  }
`;

export const iconSelector = css`
  div[data-activetab='Icon'] {
    isolation: isolate;
  }
  .icon-selector-wizard {
    &:not(.light) {
      .tabMenu {
        background-color: #303339;
        height: 50px;
      }
      .tabMenu-inner-link, a.tabMenu-link {
        &.is-active {
          &:after {
            background-color: ${primaryAccentDefault};
          }
        }
      }
      .iconSelectorPanel {
        border: 1px solid #242b3e;
      }
      .tabContent {
        background-color: #555F6E;
      }
    }
    // A/B Test: ctAppNameIconModal
    &.light {
      .tabMenu {
        background-color: #F3F3FE;
        margin: 0;
      }
      .tabMenu-inner-link, a.tabMenu-link {
        &.is-active {
          svg {
            fill: #0A1551;
          }
          &:after {
            background-color: #0175E3;
          }
        }
      }
      .iconSelectorPanel {
        border: 1px solid #C8CEED;
        border-radius: 5px;
      }
      .tabContent {
        background-color: #fff;
      }
      .SeachIconWrapper .u-search{
        background-color: transparent!important;
        border: 1px solid #CBD1ED!important;
        color: #0A1551!important;
      }
      .iconNotFound {
        color: #0A1551;
      }
      .content-item-label {
        &:hover{
          background-color: #E3E5F5;
        }
      }
      .content-item-wrapper.isIconSelected {
        background-color: #0a1551;
        .iconSelector-icon[fill="#6B73A8"] {
          fill: #fff;
        }
      }
      .remove-icon-button {
        font-size: 16px;
        line-height: 16px;
        height: auto;
        padding: 12px 24px;
        background-color: #DC2626;
        min-width: auto;
        font-weight: 500;
        &:hover {
          background-color: #AB0101;
        }
      }
    }
  }
`;

export const imageUpload = css`
  div[data-popper-placement] .imageUpload {
      box-shadow: 0px 8px 16px 0px #0a0b0d52, 0px 2px 4px 0px #0a0b0d0a;
    }

  .imageUpload {
    border: 1px solid #2F333A;
    border-radius: 4px;
    .appNameIconSettingsModal & {
      border: 1px solid #E3E5F5;
      background: transparent;
      width: calc(100% + 8px);
      .tabMenu {
        background-color: #F3F3FE;
        margin: 0;
      }
      .tabMenu-inner-link.is-active {
        color: #0099ff;
      }
      .tabMenu-inner-link, a.tabMenu-link {
        &:not([data-isactive="true"]):hover {
          color: #0099ff;
        }
      }
      .tabContent {
        padding: 16px;
      }
      .tabContent .fileUpload {
        border-color: #E3E5F5;
      }
      .tabContent .cardNew {
        border-radius: 4px;
        border: 1px solid #E3E5F5;
        padding: 0 16px;
      }
    }

    .tabMenu-inner-link::after {
      display: none;
    }
    .tabContent {
      border-width: 0;
      background-color: var(--jfv-panel-right-item-background);
      .fileUpload {
        height: 156px;
        background-color: var(--jfv-panel-right-item-background);

        input {
          width: 1px;
          padding: 0;
          font-size: 12px;
          top: 0;
          opacity: 0;
          pointer-events: none;
        }
        &-verticalAlign {
          &-text {
            font-size: 14px;
          }
          form#myForm {
            .fileUploadLabel {
              padding: 15px 30px 15px 15px;
              & > span {
                &::before {
                  margin: -3px 17px -3px 2px;
                }
              }
            }
          }
        }
      }

      .prevUploads-button {
        flex-wrap: wrap;
        gap: 4px;

        &>a.btn {
          border-radius: 4px;
          height: 38px;
          cursor: pointer;
          text-transform: uppercase;
          color: #fff;
          border: none;
          outline: none;
          align-items: center;
          font-family: inherit;
          padding: 0.625em;
        }
      }

      div[data-tabid="link"] {

        label {
          width: 100%;
        }

        .line {
          display: flex;
          justify-content: right;

          a.btn {
            height: 38px;
            padding: 0.625em;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            min-width: 112px;
          }
        }
      }
    }
  }
`;

export const testimonialsSchemas = css`
  .schemaItemWrapper {
    .schemaCard-dragHandleWrapper, .schemaEditable-dragHandleWrapper {
      background-color: ${primaryAccentDefault};
    }
  }
  .schemaEditable-content {
    background-color: var(--jfv-panel-semidark-gray);
  }
`;

export const rpAllToggles = css`
  .formWizard-wrapper,
  .formWizard-component {
    button[data-selected=true] .toggle{
      background-color: ${primaryAccentDefault};
    }
  }
`;

export const newPanelStyles = css`
  max-width: var(--jfv-right-panel-width);
  background-color: var(--jfv-right-panel-background);

  hr {
    border-top: var(--jfv-panel-right-item-border);
    &:first-of-type {
      display: none;
    }
  }

  .rp-title {
    background-color: var(--jfv-panel-title-background);
    height: var(--jfv-panel-title-height);
    padding-left: var(--jfv-panel-title-padding-left);
    padding-right: var(--jfv-panel-title-padding-right);
    border-bottom: var(--jfv-panel-title-border-bottom);

    .rp-formName {
      font-size: var(--jfv-panel-title-font-size);
      font-weight: var(--jfv-panel-title-weight);
    }
  }

  .tabMenu {
    background-color: var(--jfv-panel-tab-wrapper-background);

      .tabMenu-inner-link {
        border-bottom-style: solid;
        border-bottom-color: transparent;
        border-bottom-width: var(--jfv-panel-tab-border-bottom);
        color: var(--jfv-panel-tab-color);
        font-size: var(--jfv-panel-tab-font-size);
        height: var(--jfv-panel-tab-height);

      &:hover,
      &[data-isactive="true"] {
        border-bottom-color: var(--jfv-apps-default);
        color: var(--jfv-panel-tab-color-active);
      }

      &:after { display: none; }
    }
  }

  .rp-payment-properties {
    .tabMenu-inner-link {
      padding: 0;
      font-size: 13px;
    }
  }

  .imageUpload.branding21 {
    .tabMenu-inner-link.is-active {
      border-bottom-color: var(--jfv-apps-default);
    }
  }
`;

export const ScRightPanel = Styled.aside`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  box-shadow: 0 6px 6px 3px rgba(0, 0, 0, 0.25);
  background-color: #404651;
  pointer-events: all; 
  position: relative;
  height:  100%;
  z-index: 4;
  width: ${DEFAULT_BUILDER_WIDTHS.rightPanel}px;

  .rp-content {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .rp-topBar {
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .rp-title {
    display: flex;
    font-size: 1.125em;
    line-height: 1.125em;
    color: #fff;
    padding: 0.875rem 2.5em 0.875rem 1em;
    overflow-wrap: break-word;
    background-color: #404651;
    min-height: 2.5em;
    height: 50px;
    align-items: center;
    justify-content: space-between;
  }

  .rp-formName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.3em;
  }

  ${tabWrappersForScrollbar}

  ${tabMenu}

  ${iconSelector}

  ${imageUpload}

  ${testimonialsSchemas}

  ${rpAllToggles}

  // TODO: @Buri, remove this when panel generator handled

  .selectIcon-styleSection {
    border-bottom: 1px solid #242b3e;
  }
  .selectIcon-selectSection {
    border-top: 1px solid #485068;
  }

  @media screen and (max-width: 480px) {
    top: 0;
    z-index: 1001;
    height: 100vh;
    width: ${DEFAULT_BUILDER_WIDTHS.mobileRightPanel}px;

    &.isOpen{
      width: ${DEFAULT_BUILDER_WIDTHS.mobileRightPanel}px;
    }
  }

  div[data-tabid="App"] {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  div[data-tabid="Style"] {
    @media screen and (max-width:480px) {
      padding-bottom: 120px;
    }
  }

  ${newPanel && newPanelStyles}
`;

export const ScPageStagePanels = Styled.div`
  .rightPanelContainer {
    position: relative;
    pointer-events: all; 
  }

  .right-preview-enter {
    opacity: 0;
    margin-right: -${DEFAULT_BUILDER_WIDTHS.rightPanel}px;
  }

  .right-preview-enter-active {
    opacity: 1;
    margin-right: 0;
    transition: all 300ms;
  }
  .right-preview-enter-done {
    margin-right: 0;
    opacity: 1;
    transition: all 300ms;
  }

  .right-preview-exit {
    opacity: 1;
  }

  .right-preview-exit-active {
    opacity: 0;
    margin-right: -${DEFAULT_BUILDER_WIDTHS.rightPanel}px;
    transition: all 300ms;
  }

  .right-preview-exit-done {
    opacity: 0;
    margin-right: -${DEFAULT_BUILDER_WIDTHS.rightPanel}px;
    transition: all 300ms;
  }

  .right-panel-container {
    overflow: hidden;
    height: 100%;
  }
  
  @media screen and (max-width: 480px){
    .right-panel-container {
      position: fixed;
      overflow-y: auto;
      margin-right: 0;
      z-index: 11;
      transition: all 300ms;
    }
    .right-preview-exit-done, .right-preview-exit-active, .right-preview-enter {
      right: -${DEFAULT_BUILDER_WIDTHS.mobileRightPanel}px;
    }
    .right-preview-exit, .right-preview-enter-done, .right-preview-enter-active {
      right: 0;
    }
  }
`;

// Right Panel Blocks Styles
export const rpJfCss = css`
  .donationBoxPropertiesPanel, .productSettings, .formFieldsEditorView {
    label {
      &.flex {
        display: flex;
      }
      &.block {
        display: block;
      }
      &.p-4 {
        padding: 1rem;
      }
      &.mb-0 {
        margin-bottom: 0;
      }
      &.mb-2 {
        margin-bottom: .5rem;
      }
      &.pt-6 {
        padding-top: 24px;
      }
      &.pb-5 {
        padding-bottom: 20px;
      }
      &.px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      &.py-5 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }
      &.w-full {
        width: 100%;
      }
      &.hidden {
        display: none;
      }
    }
  }
`;

export const rpBlockInputElement = css`
  input {
    background-color: var(--jfv-panel-right-item-background);
    border: 1px solid var(--jfv-panel-right-item-border);
    &:focus, &:active, &:hover { outline: 0; border-color: var(--jfv-panel-right-item-border); }
    color: #fff;
    border-radius: 4px;
    padding: 12px;
    line-height: 1.125;
    font-size: 1em;
    box-shadow: none;
    // A/B Test: ctAppNameIconModal
    .appNameIconSettingsModal & {
      background-color: transparent;
      border-radius: 4px;
      color: #979DC6;
    }

    // Donation Box Right Panel styles for jotform.css
    &.price {
      background-color: unset;
      color: unset;
      border-radius: unset;
      padding: unset;
      line-height: unset;
      font-size: unset;
      box-shadow: unset;
    }
  }
`;

export const rpFormFieldEditorView = css`
  .formFieldsEditorView {
    .modalBg {
      position: relative;

      &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: calc(100% + 50px);
        top: -50px;
        left: 0;
        z-index: 99;
        background-color: rgba(39,41,55,0.61);
      }
    }
    &-footer{
      z-index: 100;
    }
    .pwTextInput {
      margin-top: 0.4em;

      input {
        width: 100%;

        & + p {
          margin-top: 4px;
          margin-left: 1px;
        }
      }
    }

    .pwTextArea {
      textarea {
        width: 100%;
        border: 1px solid #303339;
        background-color: rgba(74,83,97,1);

        &[disabled] {
          background: #383e47;
          color: #576169;
          cursor: not-allowed;
        }
      }
    }

    .textInput {
      border: 1px solid #303339;
      background-color: rgba(74,83,97,1);
    }

    .portal-new-dropdown-button {
      border: 1px solid #303339;
    }

    label[for="subfieldOptions"] {
      .formWizard-component {
        margin-top: 8px;
      }
    }

    .dataGrid-row {
      input[type="checkbox"] {
        color: unset;
        padding: inherit;
        background-color: rgba(0,117,227,1);
        border-color: rgba(0,117,227,1);

        &:focus {
          border-color: rgba(10,21,81,1);
        }
        &:hover {
          background-color: ${primaryAccentDefaultHover};
          border-color: ${primaryAccentDefaultHover};
        }
      }
    }

    .radioGroup {
      margin-top: 8px;

      input[type="radio"] {
        display: none;

        &:checked+.eachRadio-label {
          box-shadow: inset 0 2px 6px 0 rgba(86,70,50,.2);
          z-index: 2;
          background-color: #09f;
          border-color: #44bfff;
          color: #fff;
        }
      }

      .eachRadio {
        margin-bottom: 0;
      }

      & > .eachRadio:first-child {
        .eachRadio-label {
          border-radius: 4px 0 0 4px;
        }
      }

      & > .eachRadio:nth-of-type(2) {
        .eachRadio-label {
          border-radius: 0 4px 4px 0;
        }
      }

      .eachRadio-label {
        border: 1px solid #2F333A;
        box-shadow: 0 2px 4px 0 rgba(86,70,50,.1);
        color: white;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .5px;
        line-height: 22px;
        padding: 8px 20px 6px;
        position: relative;
        text-transform: uppercase;
        transition: all .2s ease;
        padding: 8px;
        background-color: #4A5361;
      }
    }
  }
`;

export const ScRightPanelBlock = Styled.div`
  padding: 1.75em 1em 1.5em 1em;
  display: inline-block;
  ${({ column }) => (column ? 'width: 50%' : 'width: 100%')};
  ${({ noPadding }) => (noPadding ? 'padding: 0;' : '')};
  color: white;
  vertical-align: top;

  ${rpBlockInputElement}
  .card-icon-selector {
    > div {
      padding: 1.75em 0 1.5em 0;
      &:first-child {
        padding-right: 16px;
        @media screen and (max-width: 480px) {
          padding-right: 0;
        }
      }
    }
    .jfIconSVG-wrapper > div {
      display: flex;
      align-items: center;
    }
    .previewMode-image {
      margin: 42px 12px;
    }
    .previewMode-image .jfIconSVG-wrapper svg {
      width: 40px;
      height: 40px;
      min-height: 40px;
    }
    .rightSideWrapper > div {
      padding-top: 0;
    }
  }
  .icon-selector-wizard .remove-icon-button {
    background-color: #DC2626;
    width: 100%;
    &:hover {
      background-color: #AB0101;
    }
  }
  // TODO @Buri => Should remove inline style when publishStage leftPanel implemented

  .appNameIconSettingsModal & {
    padding: 0 8px 0 0;
    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }
  .appNameIconSettingsModal & + & {
    padding: 0 0 0 8px;
    @media screen and (max-width: 480px) {
      padding: 12px 0 0;
    }
  }

  label {
    display: inline-block;
    width: auto;
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-bottom: 0.25em;
    // A/B Test: ctAppNameIconModal
    .appNameIconSettingsModal & {
        color: #0A1551;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.084px;
        margin-bottom: 4px;
    }
  }

  ${rpJfCss}

  ${rpFormFieldEditorView}

  ${formFieldConfirmationModal}

  .iconSelectorPanel label {
    margin-bottom: 0;
  }

  .field-description {
    color: ${applyAlpha('#ffffff', 0.6)};
    margin-top: 0.25rem;
    font-size: 14px;
    a {
      color: #3086c7;
      text-decoration: none;
    }
  }

  p {
    font-size: 0.875em;
    color: #b4bcd2;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
  }

  .icon-selector-wizard {
    padding: 0;
  }

  ${suffixInput}

  .inputMask-text {
    font-size: 16px;
    color: #AEB0B8;
  }

  ${({ column }) => (column ? '@media screen and (max-width: 480px) { width: 100% }' : '')};

  ${newPanel && `
    label {
      color: var(--jfv-panel-right-title-color);
      font-size: var(--jfv-panel-right-label-font-size);
      font-weight: var(--jfv-panel-right-label-font-weight);
    }

    .field-description {
      color: var(--jfv-panel-right-helper-color);
      font-size: var(--jfv-panel-right-helper-font-size);
      font-weight: var(--jfv-panel-right-helper-font-weight);
    }
  `}
`;

export const ScTetheredBuilderWrapper = Styled.div`
//BgImage ImageUpload and Preview
  .chooseButton {
    font-size: 16px;
    border-radius: 4px;
    height: 38px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    color: #fff;
    border: none;
    outline: none;
    background-color: #555F6E;
    border: 1px solid #303339;
  }
`;

export const ScTabMenu = Styled.div`
  padding: 1em 1em 0.625em;
  display: inline-block;
  ${({ column }) => (column ? 'width: 50%' : 'width: 100%')};
  ${({ column }) => (column ? '@media screen and (max-width: 480px) { width: 100%; }' : '')};
`;

export const ScRightPanelDropdown = Styled(ScDropdown)`

  .dropdown-button {
    padding: 8px 10px;
    border: 1px solid #303339;
    border-radius: 4px;
    background-color: var(--jfv-panel-right-item-background);
    color: #FFF;
    min-height: 38px;

    &:after {
      border-left: 1px solid #303339;
      width: 39px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.194 7.83999l-4.080001-6.133C.0419994 1.60199 0 1.47299 0 1.33399 0 .967992.294999.670992.660999.666992H8.843c.368 0 .667.298.667.666998 0 .139-.043.269-.116.376l-4.092 6.131c-.121.179-.324.295-.553.295-.229 0-.432-.116-.552-.293l-.003-.003z' fill='%23303339'/%3E%3C/svg%3E");
    }
  }

  .dropdown-container {
    padding: 0;
    margin: 0;
    background-color: var(--jfv-panel-dark-gray);
    border: 1px solid #242B3E;
    border-top: none;
  }

  .dropdown-option {
    &-container {
      color: #fff;
      font-size: 14px;
      padding: 8px 11px;

      &:after {
        background-color: #505A75B2;
      }

      &:hover {
        background-color: var(--jfv-panel-darkest-gray);
      }

      &[aria-selected="true"] {
        background-color: var(--jfv-panel-selected-blue);
      }
    }
  }
`;

export const ScRightPanelMobileMenuBlock = Styled.div`
  .menu-icon-wrapper svg {
    width: 24px;
  }

  .icon-selector-wrapper {
    height: 590px;
    overflow: hidden;
  }

  .icon-selector-wizard {
    background: #303339;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;

    .remove-icon-button {
      position: relative;
      width: 95%;
      margin: 10px auto;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      text-transform: capitalize;
    }
  } 
`;
