import { put, select } from 'redux-saga/effects';
import { navigate } from '@reach/router';
import SELECTORS from '../selectors';
import { toastAction } from '../actionCreators';
import { generateAppURL } from '../../utils/navigation';
import { isYes } from '../../utils';

const sessionStorageKey = 'progressBarAvailabiltyNotification';

export function* watchProgressBarAvailability() {
  const appID = yield select(SELECTORS.getPortalIDSelector);
  const requiredAppItems = yield select(SELECTORS.getTodoItems);
  const { showProgressBar } = yield select(SELECTORS.getAppInfoWithDefaults);
  if (requiredAppItems.length >= 2 && !isYes(showProgressBar)) {
    if (!window.sessionStorage.getItem(sessionStorageKey)) {
      yield put(toastAction({
        message: 'You can now enable Progress Bar on the app',
        buttonText: 'Go to Settings',
        onButtonClick: () => {
          const settingsURL = `${generateAppURL({ forBuilder: true, appID })}/settings`;
          navigate(settingsURL);
        }
      }));
      window.sessionStorage.setItem(sessionStorageKey, true);
    }
  }
}
