import React, { useState } from 'react';
import {
  string, func, bool, shape
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconCopyFilled, IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import {
  Modal, ModalHeader, ModalBody, ModalActions, Button
} from '@jotforminc/magnet';

import CloneSettings from './CloneSettings';
import './styles/CloneModal.scss';

const CloneModal = ({
  title,
  appName,
  onClone,
  onClose,
  isThereAnyForm,
  defaultSetting,
  header: {
    text, description
  },
  buttonText,
  settingsProps,
  handleSelectionChange,
  preventEmptyTitle,
  emptyTitleErrorMessage,
  modalHeaderProps,
  ...props
}) => {
  const defaultClonedTitle = t(`Clone of ${title}`);
  const [clonedTitle, setClonedTitle] = useState(defaultClonedTitle);
  const [cloning, setCloning] = useState(false);

  const [cloneSetting, setCloneSetting] = useState(defaultSetting);

  const handleClone = () => {
    setCloning(true);
    onClone({
      title: clonedTitle,
      cloneSetting
    }).finally(() => setCloning(false));
  };

  const onSelectionChange = selectedOpt => {
    handleSelectionChange(selectedOpt);
    setCloneSetting(selectedOpt);
  };

  return (
    <Modal
      {...props}
      open
      ariaLabel={t(text)}
      onClose={onClose}
    >
      <ModalHeader
        icon={IconCopyFilled}
        iconColorStyle={appName}
        title={t(text)}
        description={t(description)}
        onClose={onClose}
        {...modalHeaderProps}
      />
      <ModalBody className="modal-body">
        <CloneSettings
          title={clonedTitle}
          onTitleChange={setClonedTitle}
          cloneSetting={cloneSetting}
          onCloneSettingChange={onSelectionChange}
          isThereAnyForm={isThereAnyForm}
          {...settingsProps}
        />
      </ModalBody>
      <ModalActions>
        {(preventEmptyTitle && !clonedTitle) ? (
          <div className='p-1 bg-red-400 color-white radius top-full text-xs flex mb-4'>
            <IconExclamationCircleFilled className='fill-white mr-1 w-4 h-4' />
            {t(emptyTitleErrorMessage || 'Title cannot be empty!')}
          </div>
        ) : null}
        <Button
          colorStyle="success"
          className="ml-auto"
          loader={cloning}
          onClick={handleClone}
          disabled={preventEmptyTitle && !clonedTitle}
        >
          {t(buttonText)}
        </Button>
      </ModalActions>
    </Modal>
  );
};

CloneModal.propTypes = {
  title: string,
  onClone: func,
  onClose: func,
  defaultSetting: string,
  isThereAnyForm: bool,
  header: shape({ text: string, description: string }),
  buttonText: string,
  appName: string,
  settingsProps: shape({}),
  handleSelectionChange: func,
  preventEmptyTitle: bool,
  emptyTitleErrorMessage: string,
  modalHeaderProps: shape({})
};

CloneModal.defaultProps = {
  title: '',
  onClone: f => f,
  onClose: f => f,
  defaultSetting: '',
  isThereAnyForm: false,
  header: { text: '', description: '' },
  buttonText: string,
  appName: '',
  settingsProps: {},
  handleSelectionChange: f => f,
  preventEmptyTitle: false,
  emptyTitleErrorMessage: '',
  modalHeaderProps: {}
};

export default CloneModal;
