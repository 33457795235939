import React from 'react';
import Styled from 'styled-components';
import { elementType } from 'prop-types';
import { portalDescriptionCommonStyle } from './commonCss';

const ScDescriptionRenderer = Styled.p`
  ${portalDescriptionCommonStyle}
`;

const DescriptionRenderer = ({ children }) => <ScDescriptionRenderer>{children}</ScDescriptionRenderer>;

DescriptionRenderer.propTypes = { children: elementType.isRequired };

export default DescriptionRenderer;
