import React from 'react';
import { Tooltip } from '@jotforminc/tooltip';
import { t } from '@jotforminc/translation';
import { IconLockFilled } from '@jotforminc/svg-icons';

const EncryptedTag = () => (
  <>
    <div className="isEncryptedFormIcon jfTooltipNew-hoverTarget">
      <IconLockFilled fill='#0A1551' />
      <Tooltip
        v2
        className="isEncryptedFormTooltip"
        align="Center"
        attach="Left"
      >
        {t('Encrypted Form: This field includes encrypted information. This data can’t be added.')}
      </Tooltip>
    </div>
  </>
);

export default EncryptedTag;
