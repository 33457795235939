import {
  FTonProductsChange, FTonPaymentSettingsChange, FTonRightPanelModeChange, FTonGatewaySettingsChange
} from '../types/common';

class EditorBinding implements IEditorBindingMethods {
  onProductsChange() {
    // will be binded in paymentcontainer
  }

  onPaymentSettingsChange() {
    // will be binded in paymentcontainer
  }

  onRightPanelModeChange() {
    // will be binded in right panel mode change
  }

  // onCouponsChange() {
  //   // will be binded in paymentcontainer
  // }

  // onGatewaySettingsChange() {
  //   // will be binded in paymentcontainer
  // }
}

const EditorBindingInstance: IEditorBindingMethods = new EditorBinding();

interface IEditorBindingMethods {
  onProductsChange: FTonProductsChange,
  onPaymentSettingsChange: FTonPaymentSettingsChange,
  onRightPanelModeChange: FTonRightPanelModeChange,
  onGatewaySettingsChange: FTonGatewaySettingsChange
}

export default EditorBindingInstance;
