/* eslint-disable */
/**
 * WidgetsServerBuilder.js 1.5.2
 *
 *  (c) 2013 JotForm Easiest Form Builder
 */
import { getActiveApp, getEnv, getBaseURL, isEU, isHIPAA } from '@jotforminc/router-bridge';
import { getWidgetTranslatebleTexts } from '../translations';
import { StorageHelper } from '@jotforminc/storage-helper';
import { loadWidgetImageInParentWindow, isBase64LoadedField } from '../utils/loadWidgetImageAsBase64';

function widgetFrameLoaded(id, formProps, value, questionProps) {
  formProps = typeof formProps === 'undefined' ? {} : formProps;
  questionProps = typeof questionProps === 'undefined' ? {} : questionProps;

  var enableLog = false;

  enableLog && console.log('SERVER: widget frame onload for', id);

  var frameObj = document.getElementById(`customFieldFrame_${id}`);
  var { src } = frameObj;

  // because we are changing iframe src dynamically
  if (src.match('/form/')) {
    return;
  }

  var refererArr = src.match(/^(ftp:\/\/|https?:\/\/)?(.+@)?([a-zA-Z0-9\.\-]+).*$/);
  var referer = refererArr[1] + refererArr[3];
  // var thisForm = (JotForm.forms[0] == undefined || typeof JotForm.forms[0] == "undefined" ) ? $($$('.jotform-form')[0].id) : JotForm.forms[0];

  // check a valid json string
  function IsValidJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function sendMessage(msg, id) {
    try {
      if (navigator.userAgent.indexOf('Firefox') != -1) {
        XD.postMessage(msg, referer, getIframeWindow(window.frames[`customFieldFrame_${id}`]));
      } else {
        XD.postMessage(msg, referer, window.frames[`customFieldFrame_${id}`]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // note that we are binding receiveMessage on iframe load
  XD.receiveMessage(message => {
    // don't parse some unknown text from third party api of widgets like google recapthca
    if (!IsValidJsonString(message.data)) {
      return;
    }

    // parse message
    var data = JSON.parse(message.data);

    enableLog && console.log('SERVER: message coming form client type ', data.type);
    // sendSubmit
    if (data.type === 'submit') {
      if (typeof data.value === 'number') {
        data.value = `${data.value}`;
      }
      var required = $(document.getElementById(`input_${data.qid}`)).hasClassName('widget-required');
      if (!(required)) {
        if (data.valid === false) {
          document.getElementById(`input_${data.qid}`).value = 'widget not used';
        } else if (data.value !== undefined) {
          document.getElementById(`input_${data.qid}`).value = data.value;
        } else {
          document.getElementById(`input_${data.qid}`).value = '';
        }
      } else if (data.valid === false) {
        JotForm.errored(document.getElementById(`input_${data.qid}`), 'Please fill this field');
      } else {
        JotForm.corrected(document.getElementById(`input_${data.qid}`));
        if (data.value !== undefined) {
          document.getElementById(`input_${data.qid}`).value = data.value;
        } else {
          document.getElementById(`input_${data.qid}`).value = '';
        }
      }

      // if no value was set, dont show it to submission data
      if (typeof data.value === 'undefined' || data.value == false) {
        var el = document.getElementById(`input_${data.qid}`);
        el.value = '';
        el.removeAttribute('name');
      }

      var allInputs = document.getElementsByClassName('widget-required');
      var sendSubmit = true;
      for (var i = -0; i < allInputs.length; i++) {
        if (allInputs[i].value.length === 0) {
          sendSubmit = false;
        }
      }
    }

    // sendData
    if (data.type === 'data' && document.getElementById(`input_${data.qid}`)) {
      document.getElementById(`input_${data.qid}`).value = data.value;
    }

    // requestFrameSize
    if (data.type === 'size') {
      var { width } = data;
      var { height } = data;

      enableLog && console.log('SERVER: resizing widget width', width, 'height', height);
      if (width !== undefined && width !== null) {
        document.getElementById(`customFieldFrame_${data.qid}`) ? document.getElementById(`customFieldFrame_${data.qid}`).style.width = `${width}px` : null;
      }
      if (height !== undefined && height !== null) {
        document.getElementById(`customFieldFrame_${data.qid}`) ? document.getElementById(`customFieldFrame_${data.qid}`).style.height = `${height}px` : null;
      }
    }

    // Translatable text (from IFrame widget)
    if (data.type === 'translation') {
      if (data.data.length > 0) {
        // Check if there is already data for this form
        var wtData = window.widgetTranslatables[formProps.formID];
        if (typeof wtData === 'undefined') {
          wtData = {};
        }
        wtData[`qid_${data.qid}`] = data.data;
        window.widgetTranslatables[formProps.formID] = wtData;

        try {
          StorageHelper.setLocalStorageItem({key:'wStorage', value:JSON.stringify(window.widgetTranslatables)});
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (data.type === 'fields:capture') {
      sendMessage(JSON.stringify({
        eventID: data.eventID,
        type: 'event:receiver',
        data: []
      }), data.qid);
    }
  }, `${document.location.protocol}//${frameObj.src.match(/^(ftp:\/\/|https?:\/\/)?(.+@)?([a-zA-Z0-9\.\-]+).*$/)[3]}`);

  // function that gets the widget settings from data-settings attribute of the iframe
  function getWidgetSettings() {
    var el = document.getElementById(`widget_settings_${id}`);
    return (el) ? el.value : null;
  }

  //get form environment
  var env = getEnv();
  var baseUrl = getBaseURL();
  var isApp = getActiveApp();
  var isEu = isEU();
  var isHipaa = isHIPAA();
  var enterprise = env === "ENTERPRISE" ? baseUrl.replace('https://', '') : false;

  function isOnFormBuilder() {
    return isApp === 'form-builder';
  }

  // function to check if a widget is required
  function isWidgetRequired() {
    var classNames = document.getElementById(`id_${id}`)?.className;
    return classNames?.includes('jf-required') || classNames?.includes('isRequired');
  }

  function isWidgetLabelEnabled() {
    var labelElement = document.getElementById(`label_${id}`);
    if (labelElement) {
      return labelElement.className.indexOf('form-label') > -1;
    }
    return false;
  }

  function getWidgetLabel(id) {
    var labelElement = document.getElementById(`label_${id}`);
    if (labelElement && labelElement.textContent) {
      return labelElement.textContent;
    }
    return '';
  }

  // send auto ready message to widget
  // do not call v3 functions when inside v4
  var isCardForm = (window.FORM_MODE == 'cardform' || window.buildermode === 'card');

  // Check theme version
  var themeVersion = (window && window.newDefaultTheme) || '';
  var isOpenedInPortal = src.match('isOpenedInPortal=true');
  var isOpenedInAgent = src.match('isOpenedInAgent=true');
  if (isOpenedInPortal || isOpenedInAgent) {
    sendMessage(JSON.stringify({type: 'theme', themeVersion: 'v2', isExtendedTheme: false}), id);
  }

  var formBackgroundv3 = (typeof getAllProperties === 'function') ? getAllProperties().form_background : '#fff';
  var formFontv3 = (typeof getAllProperties === 'function') ? getAllProperties().form_font : '';
  var msg = {
    type: 'ready',
    qid: `${id}`,
    background: formProps.formBackground || formBackgroundv3,
    fontFamily: formProps.formFont || formFontv3,
    formID: formProps.formID || BuildSource.formID,
    cardform: isCardForm,
    jotformNext: window.location.href.indexOf('jotformNext=1') > -1 || window.isComingFromJotFormNext == 1,
    onWizard: isOnFormBuilder(),
    settings: getWidgetSettings(),
    required: isWidgetRequired(),
    origin: window.location.origin || (`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`),
    themeVersion: themeVersion,
    isWidgetLabelEnabled: isWidgetLabelEnabled(),
    qlabel: getWidgetLabel(id),
    value,
    isApp,
    isEu,
    isHipaa,
    enterprise,
    lang: document.documentElement.lang
  };

  // include initial width and height of the widget
  var fwidth = frameObj.getAttribute('data-width') || frameObj.style.width;
  var fheight = frameObj.getAttribute('data-height') || frameObj.style.height;
  msg.width = parseInt(fwidth);
  msg.height = parseInt(fheight);
  // data-value attribute is set if form is in editMode.
  if (isCardForm && frameObj && typeof frameObj.up === 'function') {
    var jfFieldDiv = frameObj.up('.jfField') || false;
    var widgetID = jfFieldDiv && typeof jfFieldDiv.getAttribute === 'function' ? jfFieldDiv.getAttribute('data-widget-id') : false;
    if (widgetID) {
      msg.widgetID = widgetID;
    }
  }

  if (isBase64LoadedField(msg, questionProps.selectedField)) {
    loadWidgetImageInParentWindow(msg.value)
      .then((base64Image) => sendMessage(JSON.stringify({ ...msg, value: base64Image }), `${id}`))
      .catch(() => sendMessage(JSON.stringify(msg), `${id}`))
  } else {
    sendMessage(JSON.stringify(msg), `${id}`);
  }

  // Request IFrame widget for translatable text (if there is any)
  var translatableRequest = {
    type: 'translatable',
    formID: formProps.formID || BuildSource.formID,
    qid: `${id}`
  };
  sendMessage(JSON.stringify(translatableRequest), `${id}`);
}

function getWidgetTranslatables(widgetID, options = {}) {

  const { fieldDeclaration, fid, qid } = options;

  var response = {
    isTranslatable: false,
    data: []
  };

  const parsableWidgets = ['5299b58aba554b7e24000007'/*  Configurable List */, '533946093c1ad0c45d000070'/* Matrix Dynamique */];
  if(parsableWidgets.includes(widgetID)) {
    const texts = getWidgetTranslatebleTexts(widgetID, fieldDeclaration);
    if(texts && texts.length > 0){
      response.isTranslatable = true;
      response.data = texts;
    }
    return response;
  }

  if (typeof fid !== 'undefined' && typeof qid !== 'undefined' && !isNaN(fid) && !isNaN(qid) && fid !== '' && qid !== '') {
    var widgetTranslatables = StorageHelper.getLocalStorageItem({key:'wStorage'});

    if (widgetTranslatables !== undefined && widgetTranslatables !== null) {
      var widgetTranslatables = JSON.parse(widgetTranslatables);
      if (typeof widgetTranslatables[fid] !== 'undefined') {
        var widgetTranslatables = widgetTranslatables[fid];
        if (typeof widgetTranslatables[`qid_${qid}`] !== 'undefined') {
          var translatable = widgetTranslatables[`qid_${qid}`];
          if (typeof translatable !== 'undefined') {
            if (translatable.length > 0) {
              response.isTranslatable = true;
              response.data = translatable;
            }
          }
        }
      }
    }
  }

  return response;
}

export {
  widgetFrameLoaded,
  getWidgetTranslatables
}
