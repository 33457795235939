import { getBaseURL } from '@jotforminc/router-bridge';
import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const requestLayer = new RequestLayer(`${getBaseURL()}/API`, {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer],
    shouldCache: true,
    logCachePayload: {
      projectName: 'portal'
    }
  }
});

export default requestLayer;
