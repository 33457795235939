import React from 'react';
import { node } from 'prop-types';

const SettingsPanelListItem = ({
  children, ...props
}) => {
  return (
    <li {...props}>
      {children}
    </li>
  );
};

SettingsPanelListItem.propTypes = {
  children: node
};

SettingsPanelListItem.defaultProps = {
  children: null
};

export default SettingsPanelListItem;
