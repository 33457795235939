import Styled, { css } from 'styled-components';

export const ScPortalOption = Styled.div`
  transition: border 0.15s ease, box-shadow 0.15s ease, transform 10ms linear;
  text-decoration: none;
  border-radius: .25em;
  padding: 9px;
  line-height: ${p => p.theme.lineHeight};
  background: ${p => p.theme.itemBg};
  border: 1px solid ${p => p.theme.itemBorder};
  width: 100%;


  ${({ isBranding21 }) => (isBranding21 ? css`
    [aria-selected="true"] &, [aria-selected="true"] &:focus, [aria-selected="true"] &:hover {
      border-color: #0099FF;
      background-color: #EDF8FF;
    }
  ` : css`
    [aria-selected="true"] &, [aria-selected="true"] &:focus {
      border-color: #4573E3;
      background-color: #DBE4FA;
      box-shadow: 0 0 0 1px #4573E3 inset;
    }
  `)};

  &:hover {
    cursor: pointer;
    border-color: ${({ isBranding21 }) => (isBranding21 ? '#C8CEED' : '#4573E3')};
    background-color: ${({ isBranding21 }) => (isBranding21 ? '#F3F3FE' : '#DBE4FA')};
    ${({ isBranding21 }) => (isBranding21 ? '' : css`box-shadow: 0 0 0 1px #4573E3 inset;`)};
    .itemActions {
      display: block;
    }
  }

  &:focus {
    outline: 0;
    border-color: ${p => p.theme.itemBorder};
    background-color: ${p => p.theme.itemBgFocus};
    box-shadow: 0 0 0 1px ${p => p.theme.itemBorder} inset;
  }


  ${p => (p.isDisabled ? `
    background-color: #FAFAFB;
    border-color: #EBECF3;
    box-shadow: unset;
    cursor: default;
    `
    : '')}

`;
