/**
 * Checks if the current environment is a Jotform mobile app.
 *
 * This function examines the user agent to determine if the application
 * is running within a Jotform mobile app. It looks for the strings
 * 'JotForm Mobile' or 'JFCEMobile'.
 *
 * @returns {boolean} Returns true if the app is running inside a Jotform
 *                    mobile app, false otherwise.
 */
export const isJotformMobileApp = (): boolean => {
  return typeof window !== 'undefined' && (
    window.navigator.userAgent.indexOf('JotForm Mobile') > -1
    || window.navigator.userAgent.indexOf('JFCEMobile') > -1
  );
};
