import React, { useRef, useEffect, useState } from 'react';
import { t } from '@jotforminc/translation';

import {
  arrayOf, elementType, func, shape, string
} from 'prop-types';
import { SelectionGroup as UIKitSelectionGroup, Hooks } from '@jotforminc/uikit';
import isEqual from 'lodash/isEqual';

import DefaultToggleButtonGrpRenderer from './DefaultToggleButtonGrpRenderer';
import PanelItemDescriptionRenderer from '../HomePage/RightPanel/PanelItemDescriptionRenderer';

const ToggleButtonGroup = ({
  onChange, options, ContainerRenderer, value, DescriptionRenderer, description
}) => {
  const selectionGroupRef = useRef();
  const prevVal = Hooks.usePrev(value);
  const [selectedItem, setSelectedItem] = useState(value);

  useEffect(() => {
    const { setSelectedOptionList } = selectionGroupRef.current || {};
    // eslint-disable-next-line no-unused-expressions
    setSelectedOptionList?.([value]);
  }, [value]);

  const handleSelectionChange = itemList => {
    setSelectedItem(itemList);
  };

  Hooks.useEffectIgnoreFirst(() => {
    if (!isEqual(selectedItem, value) && !isEqual(prevVal, selectedItem)) {
      onChange(selectedItem);
    }
  }, [selectedItem, value]);

  // Only used in heading size selection, other toggle group impl (alignment) has its own Renderer in options
  const OptionRenderer = ({ option: { text } }) => <>{t(text)}</>; // eslint-disable-line react/prop-types
  return (
    <>
      <UIKitSelectionGroup
        ref={selectionGroupRef}
        defaultValue={selectedItem}
        options={options}
        onSelectionChange={handleSelectionChange}
        OptionRenderer={OptionRenderer}
        OptionContainerRendererProps={{ className: 'selectionItem', 'data-testid': 'selection-item' }}
        ContainerRenderer={ContainerRenderer}
      />
      {description && <DescriptionRenderer description={description} />}
    </>
  );
};

ToggleButtonGroup.propTypes = {
  onChange: func,
  options: arrayOf(shape({})),
  ContainerRenderer: elementType,
  value: string,
  description: string,
  DescriptionRenderer: elementType
};

ToggleButtonGroup.defaultProps = {
  onChange: f => f,
  options: [],
  ContainerRenderer: DefaultToggleButtonGrpRenderer,
  value: 'left',
  description: '',
  DescriptionRenderer: PanelItemDescriptionRenderer
};

export default ToggleButtonGroup;
