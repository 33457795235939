import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Stripe ACH Manual gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlStripeACHManual: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_stripeACHManual: {}
};

export default controlStripeACHManual;
