import * as CORE_SELECTORS from './coreSelectors';
import * as PRODUCT_SELECTORS from './productSelectors';
import * as USER_SELECTORS from './userSelectors';
import * as UI_SELECTORS from './uiSelectors';
import * as PORTAL_SELECTORS from './portalSelectors';
import * as UNDOREDO_SELECTORS from './undoRedoSelectors';
import * as FORMS_SELECTORS from './formsSelectors';
import * as WIDGETS_SELECTORS from './widgetsSelectors';
import * as SHARE_SELECTORS from './shareSelectors';
import * as TEAM_SELECTORS from './teamSelectors';
import * as APPS_SELECTORS from './appsSelectors';
import * as PROGRESS_SELECTORS from './progressSelectors';
import * as CDNCONFIG_SELECTORS from './cdnConfigSelectors';
import * as SIGNS_SELECTORS from './signsSelectors';
import * as CHECKOUT_FORM_SELECTORS from './checkoutFormSelectors';
import * as PUSH_NOTIFICATION_SELECTORS from './pushNotificationSelectors';
import * as DATA_SOURCE_SELECTORS from './dataSourceSelectors';
import * as LIVE_PREVIEW_SELECTORS from './livePreviewSelectors';
import * as ASSISTANT_SELECTORS from './assistantSelectors';
import * as USER_MANAGEMENT_SELECTORS from './userManagementSelectors';

export default {
  ...CORE_SELECTORS,
  ...PRODUCT_SELECTORS,
  ...USER_SELECTORS,
  ...UI_SELECTORS,
  ...PORTAL_SELECTORS,
  ...UNDOREDO_SELECTORS,
  ...FORMS_SELECTORS,
  ...WIDGETS_SELECTORS,
  ...SHARE_SELECTORS,
  ...TEAM_SELECTORS,
  ...APPS_SELECTORS,
  ...PROGRESS_SELECTORS,
  ...CDNCONFIG_SELECTORS,
  ...SIGNS_SELECTORS,
  ...CHECKOUT_FORM_SELECTORS,
  ...PUSH_NOTIFICATION_SELECTORS,
  ...DATA_SOURCE_SELECTORS,
  ...LIVE_PREVIEW_SELECTORS,
  ...ASSISTANT_SELECTORS,
  ...USER_MANAGEMENT_SELECTORS
};
