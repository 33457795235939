import React from 'react';
import { bool, elementType, string } from 'prop-types';
import BrokenFallbackImage from '../assets/svg/no-image.svg';
import ScNoImage from './sc/ScNoImage';

const NoImage = ({
  altText,
  showAltText,
  PlaceholderRenderer
}) => {
  return (
    <ScNoImage>
      <PlaceholderRenderer />
      {altText && showAltText && <div title={altText} className="altText">{altText}</div>}
    </ScNoImage>
  );
};

NoImage.propTypes = {
  altText: string,
  showAltText: bool,
  PlaceholderRenderer: elementType
};

NoImage.defaultProps = {
  altText: 'merhaba',
  showAltText: true,
  PlaceholderRenderer: BrokenFallbackImage
};

export default NoImage;
