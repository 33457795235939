import max from 'lodash/max';
import min from 'lodash/min';
import { DEFAULT_QUANTITY_OPTION_PROPS, QUANTITY_PROP } from '../modules/PublicApp/ProductCart/Cart/constants';

export const getOptionProps = rawOptions => {
  const { properties, name } = rawOptions;

  if (!properties) {
    return { min: 0, max: 1 };
  }

  const parsedProperties = properties.split('\n').map(prop => parseInt(prop, 10)).filter(p => p > 0);

  const maxValue = max(parsedProperties);
  const minValue = min(parsedProperties);

  return {
    name,
    maxValue,
    minValue
  };
};

export const getQuantityOptionProps = options => {
  const quantityOption = options.find(({ type }) => type === QUANTITY_PROP);
  return quantityOption || DEFAULT_QUANTITY_OPTION_PROPS;
};

export const getAttributeByItemIndex = (attributes, itemIndex) => {
  return attributes.find(attr => attr.itemIndex === itemIndex) || {};
};

export const getAttributeByItemType = (attributes, type) => {
  return attributes.find(attr => attr.type === type) || {};
};

export const getHasCustomOption = (options = []) => {
  return options.some(option => {
    const { type } = option;

    return type === 'custom';
  });
};

export const reduceProductsInfo = (products = []) => {
  return products.map((product = {}, itemIndex) => {
    const {
      pid,
      options = [],
      quantity
    } = product;

    const reducedOptions = options.reduce((prev, opt = {}, index) => {
      const { selected } = opt;

      return selected ? { ...prev, [index]: selected } : prev;
    }, {});

    return {
      productID: pid,
      quantity,
      ...(options && options.length) ? { options: reducedOptions } : {},
      index: itemIndex
    };
  });
};

export const getProductQuantityLimits = product => {
  const { options = [] } = product;
  const quantityOption = options.find(option => option.type === 'quantity');
  if (!quantityOption) {
    return [1, 1];
  }
  const rangeArray = quantityOption.properties.split('\n');
  return [parseInt(rangeArray[0], 10), parseInt(rangeArray[rangeArray.length - 1], 10)];
};

export const getCorrectedQuantity = (quantity, product) => {
  const [minQuantity, maxQuantity] = getProductQuantityLimits(product);
  if (typeof quantity === 'undefined') { // Default for products that have no quantity option
    return 1;
  }

  // We let decrease-quantity button to send minQuantity - 1 in case of deletion.
  const isDeleteOperation = quantity === minQuantity - 1 || quantity === 0;

  if (isDeleteOperation) {
    return 0;
  }

  if (quantity >= minQuantity && quantity <= maxQuantity) {
    return quantity;
  }

  // This means that quantity limits have been changed since the last addition to the cart.
  return quantity < minQuantity ? minQuantity : maxQuantity;
};
