import {
  func, number, oneOfType, string
} from 'prop-types';
import React from 'react';
import Styled from 'styled-components';

const ScRangeInput = Styled.div`
  width: 100%;
  border: 1px solid #303339;
  box-shadow: rgb(86 70 50 / 10%) 0px 2px 4px;
  border-radius: 4px;
  background-color: var(--jfv-panel-right-item-background);
  padding: 9px 15px;

  input[type=range] {
    height: 28px;
    appearance: none;
    width: 100%;
    padding: 0;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #8B919E;
    border-radius: 4px;
    border: 0px solid #000000;
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    height: 28px;
    width: 28px;
    border-radius: 25px;
    background: #FFFFFF;
    cursor: pointer;
    appearance: none;
    margin-top: -12px;
  }

${({ rangeValue }) => (rangeValue && `
  input[type=range]::-webkit-slider-runnable-track {
    --ratio: calc((${rangeValue} - 1) / 99);
    --sx: calc(0.5 * 28px + var(--ratio) * (100% - 28px));
  }

  input[type=range]::-webkit-slider-runnable-track {
    background: linear-gradient(#0099FF,#0099FF) 0/var(--sx) 100% no-repeat, #8B919E;
  }
`)}}

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #8B919E;
    border-radius: 4px;
    border: 0px solid #000000;
  }

  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    height: 28px;
    width: 28px;
    border-radius: 25px;
    border-color: white;
    background: #FFFFFF;
    cursor: pointer;
    appearance: none;
    margin-top: -12px;
  }

  ${({ rangeValue }) => (rangeValue && `
  input[type=range]::-moz-range-track {
    --ratio: calc((${rangeValue} - 1) / 99);
    --sx: calc(0.5 * 28px + var(--ratio) * (100% - 28px));
  }

  input[type=range]::-moz-range-track {
    background: linear-gradient(#0099FF,#0099FF) 0/var(--sx) 100% no-repeat, #8B919E;
  }
`)}}
`;

const RangeSlider = ({
  value,
  onChange
}) => {
  const handleChange = ({ target: { value: newValue } }) => onChange(newValue);

  return (
    <ScRangeInput rangeValue={value} min={1} max={100}>
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
      />
    </ScRangeInput>
  );
};

RangeSlider.propTypes = {
  value: oneOfType([number, string]),
  onChange: func
};

RangeSlider.defaultProps = {
  value: 0,
  onChange: f => f
};

export default RangeSlider;
