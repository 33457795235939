import React from 'react';
import { t } from '@jotforminc/translation';
import { CONNECTION_LIST_DATA } from '../../../../../types/common';
import { OWNER_INDICATOR_CONFIG } from '../../Constants';

interface I_OWNER_INDICATOR {
  connectionInfo: CONNECTION_LIST_DATA;
}

const ConnectionOwnerIndicator = ({ connectionInfo: { scope, scope_fullname: scopeFullname } }: I_OWNER_INDICATOR): JSX.Element | null => {
  if (scope === null) { return scope; }

  const { Icon, colorClass } = OWNER_INDICATOR_CONFIG[scope];
  return (
    <div className='flex justify-end mt-3 text-xs'>
      <span className='color-gray-100 whitespace-nowrap'>
        {t('Owned by')}
      </span>
      <span className={`flex items-center overflow-hidden ${colorClass}`}>
        <Icon className='w-4 h-4 mx-1 shrink-0' />
        <span className='whitespace-nowrap overflow-hidden truncate'>
          {scopeFullname}
        </span>
      </span>
    </div>
  );
};

export default ConnectionOwnerIndicator;
