import React, {
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { handleCustomNavigation } from '@jotforminc/utils';
import { Hooks } from '@jotforminc/uikit';
import { I_SQUARE_SETTINGS } from '../../modals/Flows/Types/GatewayTypes';
import useGatewayFlows from '../../modals/Flows/Context/useGatewayFlows';
import { CLIENT_ID_LIST_DEV } from '../../modals/Flows/Constants/Credentials';
import { getSquareConnectionData, getSquareLocations, getJWTToken } from '../../../api';
import { getUpdatingSettingsFromResponse } from './utils';
import BaseConnectionButton from './BaseConnectionButton';
import { TConnectionStatus } from '../../../types/common';
import { checkIsGatewayConnected } from '../../../utils';
import { I_GATEWAYS } from '../../modals/Flows/Types';

const ConnectionButtonSquare = ({
  settings,
  connectionStatus,
  setConnectionStatus,
  checkoutFormId,
  gatewayType
} : IProps) : JSX.Element => {
  const {
    changedSettings,
    formId,
    setChangedSettings,
    handleDisconnectGateway,
    user: { username }
  } = useGatewayFlows();

  Hooks.useEffectIgnoreFirst(() => {
    if (connectionStatus === 'connected') {
      handleDisconnectGateway(gatewayType);
    }
  }, [settings.allowTest]);

  useEffect(() => {
    if (checkIsGatewayConnected({ ...settings, type: gatewayType })) {
      setConnectionStatus('connected');
    } else {
      setConnectionStatus('notConnected');
    }
  }, [settings]);

  useEffect(() => {
    if (connectionStatus === 'connected') {
      getSquareLocations(settings.allowTest, checkoutFormId, changedSettings?.connectionID)
        .then(resp => {
          const locations: Array<{ value: string, text: string }> = [];

          if (resp.message && resp.message.locations) {
            resp.message.locations.forEach((loc: { id: string, name: string }) => {
              locations.push({
                value: loc.id,
                text: loc.name
              });
            });

            setChangedSettings({ ...changedSettings, locationOptions: locations });
          }
        }).catch(() => {
          // console.log('Could not get square locations: ', e);
        });
    }
  }, [connectionStatus, settings.allowTest, checkoutFormId]);

  const squareConnection = (authUrl: string, redirectUrl: string, allowTest = false) => {
    const isRDS = /^https:\/\/\w*\.jotform\.pro$/.test(window.location.origin);
    const validOrigin = !isRDS ? 'https://secure.jotform.com' : window.location.origin;
    const popupWidth = 800;
    const popupX = window.screenX + (window.innerWidth - popupWidth) / 2;
    const popupY = window.screenY + (window.innerHeight - 800) / 2;

    const squarePopup = handleCustomNavigation(
      authUrl,
      'view',
      true,
      `width=${popupWidth},height=800,status=0,top=${popupY}px,left=${popupX}px,location=1,toolbar=0,scrollbars=1,resizable=1`
    );

    if (window.jfSquareTimer) {
      window.clearInterval(window.jfSquareTimer);
    }

    window.jfSquareTimer = window.setInterval(() => {
      if (squarePopup && squarePopup.closed !== false) {
        window.clearInterval(window.jfSquareTimer);
      }

      if (squarePopup && squarePopup.closed && connectionStatus === 'connecting') {
        setConnectionStatus('notConnected');
        window.clearInterval(window.jfSquareTimer);
      }
    }, 500);

    const handleSquareAuthMessage = event => {
      if (event.origin !== validOrigin || event.data.source !== 'jfsquare_connection') {
        return;
      }

      if (window.jfSquareTimer) {
        window.clearInterval(window.jfSquareTimer);
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      squareConnectLoop(redirectUrl, allowTest, event.data.code);
      squarePopup.close();
      window.removeEventListener('message', handleSquareAuthMessage);
    };

    window.addEventListener('message', handleSquareAuthMessage);
  };

  const squareConnectLoop = (redirectURL: string, allowTest: boolean, setSquareCode = null) => {
    const squareCode = setSquareCode;

    const innerTimer = setInterval(() => {
      clearInterval(innerTimer);
      if (squareCode !== null) {
        const requestData = {
          authCode: squareCode,
          redirectURL,
          allowTest: allowTest,
          formId,
          isNewPaymentModal: true
        };

        getSquareConnectionData(requestData)
          .then(resp => {
            if (!resp.connectionID || resp.connectionID === '') {
              setConnectionStatus('notConnected');
              return;
            }

            const data = resp.message;
            const cred = data.credentials;
            const locationOptions: Array<{ value: string, text: string}> = [];

            data.locations.forEach((loc: { id: string, name: string }) => {
              locationOptions.push({
                value: loc.id,
                text: loc.name
              });
            });

            cred.location = locationOptions[0].value;
            cred.isMerchantAccountCurrency = data.isMerchantAccountCurrency;
            cred.currency = data.currency;

            const settingsToBeUpdated = getUpdatingSettingsFromResponse(cred, 'control_square');
            if (!Object.keys(settingsToBeUpdated).length) return;

            if (cred.currency) {
              settingsToBeUpdated.currency = cred.currency;
            }

            setChangedSettings({ ...changedSettings, ...settingsToBeUpdated, connectionID: resp.connectionID });
            setConnectionStatus('connected');
          }).catch(() => {
            setConnectionStatus('notConnected');
          });
      } else {
        setConnectionStatus('notConnected');
      }
    }, 1000);
  };

  const connectSquare = async () => {
    const isRDS = /^https:\/\/\w*\.jotform\.pro$/.test(window.location.origin);
    const allowTest = ['true', true, 1, '1', 'Yes', 'Enabled'].includes(settings.allowTest);
    /**
     * If LIVE : Look at JotForm Production https://developer.squareup.com/apps/sq0idp-6hj_oP1Z6MUXu_rUpVOYHg
     * If LIVE : Look at JotForm Sandbox https://developer.squareup.com/apps/sq0idp-6hj_oP1Z6MUXu_rUpVOYHg
     * If RDS :: Look at JotForm RDS Production https://developer.squareup.com/apps/sq0idp-Rts34svC1hiCcw2uMGsb3A
     */
    let clientId = allowTest ? 'sandbox-sq0idb-zE-jvajQsMZYMRTveUn21A' : 'sq0idp-6hj_oP1Z6MUXu_rUpVOYHg';

    if (isRDS) {
      clientId = allowTest ? CLIENT_ID_LIST_DEV[window.location.hostname].square : 'sq0idp-Rts34svC1hiCcw2uMGsb3A';
    }

    const redirectBaseUrl = isRDS ? window.location.origin : 'https://secure.jotform.com';
    const redirectUrl = `${redirectBaseUrl}/api/square/oauth.php`;
    const baseUrlSquare = allowTest ? 'https://connect.squareupsandbox.com' : 'https://connect.squareup.com';
    const session = allowTest;

    const JWTToken = await getJWTToken(checkoutFormId, username);

    if (!JWTToken) {
      return setConnectionStatus('notConnected');
    }

    const scope = [
      'SUBSCRIPTIONS_READ',
      'SUBSCRIPTIONS_WRITE',
      'INVOICES_WRITE',
      'INVOICES_READ',
      'ORDERS_WRITE',
      'ITEMS_WRITE',
      'ITEMS_READ',
      'PAYMENTS_WRITE',
      'PAYMENTS_READ',
      'MERCHANT_PROFILE_READ',
      'CUSTOMERS_WRITE',
      'CUSTOMERS_READ'
    ].join(',');
    const urlSquare = `${baseUrlSquare}/oauth2/authorize?client_id=${clientId}&state=${JWTToken}&session=${session}&redirect_uri=${encodeURI(redirectUrl)}&scope=${scope}`;

    setConnectionStatus('connecting');
    squareConnection(urlSquare, redirectUrl, allowTest);
  };

  return (
    <BaseConnectionButton
      settings={settings}
      connect={connectSquare}
      connectionStatus={connectionStatus}
    />
  );
};

interface IProps {
  settings: I_SQUARE_SETTINGS;
  connectionStatus: string;
  setConnectionStatus: Dispatch<SetStateAction<TConnectionStatus>>;
  checkoutFormId: string;
  gatewayType: I_GATEWAYS;
}

export default ConnectionButtonSquare;
