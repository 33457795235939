import React from 'react';
import {
  oneOfType, arrayOf, node, string
} from 'prop-types';
import { classNames } from '@jotforminc/utils';

export default class WizardList extends React.Component {
  render() {
    const { className, children, ...props } = this.props;

    return (
      <ul className={classNames('jfWizard-list', className)} {...props}>
        {children}
      </ul>
    );
  }
}

WizardList.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]),
  className: string
};

WizardList.defaultProps = {
  children: null,
  className: ''
};
