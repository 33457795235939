/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';

const TabletBorder = ({ splashBgColor }) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 830 1198">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.32 31.67C0 44.07 0 60.32 0 92.8v1012.4c0 32.48 0 48.72 6.32 61.13a58.02 58.02 0 0 0 25.35 25.35c12.4 6.32 28.65 6.32 61.13 6.32h644.4c32.48 0 48.72 0 61.13-6.32a58.01 58.01 0 0 0 25.35-25.35c6.32-12.41 6.32-28.65 6.32-61.13V92.8c0-32.48 0-48.72-6.32-61.13a58 58 0 0 0-25.35-25.35C785.93 0 769.68 0 737.2 0H92.8C60.32 0 44.08 0 31.67 6.32A58 58 0 0 0 6.32 31.67ZM798 72H32v1022h766V72Z"
        fill="#F3F3FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M797 73H33v1020h764V73ZM32 72v1022h766V72H32Z"
        fill="#C8CEED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M737.2 8H92.8c-16.37 0-28.17 0-37.44.76-9.18.75-15.17 2.2-20.06 4.69A50 50 0 0 0 13.45 35.3c-2.5 4.89-3.94 10.88-4.69 20.06C8.01 64.63 8 76.43 8 92.8v1012.4c0 16.37 0 28.17.76 37.44.75 9.18 2.2 15.17 4.69 20.06a49.97 49.97 0 0 0 21.85 21.85c4.89 2.49 10.88 3.94 20.06 4.69 9.27.75 21.07.76 37.44.76h644.4c16.37 0 28.17-.01 37.44-.76 9.18-.75 15.17-2.2 20.06-4.69a49.96 49.96 0 0 0 21.85-21.85c2.5-4.89 3.94-10.88 4.69-20.06.75-9.27.76-21.07.76-37.44V92.8c0-16.37 0-28.17-.76-37.44-.75-9.18-2.2-15.17-4.69-20.06a50 50 0 0 0-21.85-21.85c-4.89-2.5-10.88-3.94-20.06-4.69-9.27-.75-21.07-.76-37.44-.76ZM6.32 31.67C0 44.07 0 60.32 0 92.8v1012.4c0 32.48 0 48.72 6.32 61.13a58.02 58.02 0 0 0 25.35 25.35c12.4 6.32 28.65 6.32 61.13 6.32h644.4c32.48 0 48.72 0 61.13-6.32a58.01 58.01 0 0 0 25.35-25.35c6.32-12.41 6.32-28.65 6.32-61.13V92.8c0-32.48 0-48.72-6.32-61.13a58 58 0 0 0-25.35-25.35C785.93 0 769.68 0 737.2 0H92.8C60.32 0 44.08 0 31.67 6.32A58 58 0 0 0 6.32 31.67Z"
        fill={splashBgColor}
      />
      <circle
        cx="415"
        cy="36"
        r="4"
        fill="#C8CEED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M415 1164a22 22 0 1 0 0-44 22 22 0 0 0 0 44Zm0 2a24 24 0 1 0 0-48 24 24 0 0 0 0 48Z"
        fill="#C8CEED"
      />
    </svg>
  );
};

TabletBorder.propTypes = { splashBgColor: string.isRequired };

export default TabletBorder;
