import type { GATEWAY_RESOURCE_SUPPORTED_PAYMENT_TYPES } from '../types';

/**
 * Supported payment types for each application resource.
 * @type {GATEWAY_RESOURCE_SUPPORTED_PAYMENT_TYPES}
 */
export const gatewayResourceSupportedPaymentTypes: GATEWAY_RESOURCE_SUPPORTED_PAYMENT_TYPES = {
  control_square: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_paypalcomplete: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_paypal: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_paypalInvoicing: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_paypalpro: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_paypalSPB: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_paypalexpress: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_authnet: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_stripe: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_stripeCheckout: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_stripeACHManual: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_stripeACH: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_braintree: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_cashApp: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_afterpay: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_clearpay: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_venmo: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_applePayGooglePay: {
    APP: [],
    FORM: []
  },
  control_mollie: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_cybersource: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_echeck: {
    APP: [],
    FORM: ['custom']
  },
  control_bluepay: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_eway: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_payu: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_payuMoney: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_worldpay: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_worldpayus: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_bluesnap: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_moneris: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_gocardless: {
    APP: ['subscription'],
    FORM: ['subscription']
  },
  control_payfast: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_sensepass: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_sofort: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_pagseguro: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_firstdata: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_cardconnect: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_2co: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  },
  control_paysafe: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_iyzico: {
    APP: ['product', 'donation', 'custom'],
    FORM: ['product', 'donation', 'custom']
  },
  control_skrill: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_chargify: {
    APP: ['subscription'],
    FORM: ['subscription']
  },
  control_payjunction: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_clickbank: {
    APP: ['product'],
    FORM: ['product']
  },
  control_onebip: {
    APP: ['product'],
    FORM: ['product']
  },
  control_paymentwall: {
    APP: ['product', 'custom', 'donation'],
    FORM: ['product', 'custom', 'donation']
  },
  control_payment: {
    APP: ['product', 'subscription', 'custom', 'donation'],
    FORM: ['product', 'subscription', 'custom', 'donation']
  }
};
