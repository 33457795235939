import { cva } from 'class-variance-authority';
import { CVAType } from '../../types/system.types';
import { FormControlContextType } from '../../contexts';
import { primary, secondary } from '../../tokens/colors';

type FormLabelCVAType = CVAType<Pick<FormControlContextType, 'size' | 'theme' | 'required'>>
type FormLabelDescCVAType = CVAType<Pick<FormControlContextType, 'size' | 'theme'>>
type FormHelperTextCVAType = CVAType<Pick<FormControlContextType, 'theme' | 'colorStyle'>>

export const formLabelCVA = cva<FormLabelCVAType>('block font-bold', {
  variants: {
    theme: {
      light: primary.text.darkest.base,
      dark: 'color-white'
    },
    size: {
      large: 'text-md line-height-xl'
    },
    required: {}
  },
  compoundVariants: [
    {
      size: ['small', 'medium'],
      className: 'text-sm line-height-md'
    }
  ]
});

export const formLabelDescCVA = cva<FormLabelDescCVAType>('block', {
  variants: {
    theme: {
      light: primary.text.medium.base,
      dark: secondary.text.light.base
    },
    size: {
      large: 'text-sm line-height-md'
    }
  },
  compoundVariants: [
    {
      size: ['small', 'medium'],
      className: 'text-xs'
    }
  ]
});

export const formHelperTextCVA = cva<FormHelperTextCVAType>('flex items-center text-xs gap-1', {
  variants: {
    colorStyle: {
      success: primary.success.default.color.base,
      error: [primary.error.default.bg.base, 'color-white p-1 radius'],
      warning: 'color-yellow-500'
    },
    theme: {}
  },
  compoundVariants: [
    {
      theme: 'light',
      colorStyle: 'default',
      className: primary.text.medium.base
    },
    {
      theme: 'dark',
      colorStyle: 'default',
      className: secondary.text.light.base
    }
  ]
});
