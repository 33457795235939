import React from 'react';
import { renderable } from '../../../../constants/propTypes';
import ScPublishStage from './ScPublishStage';
import { useIfMobileSettings } from '../../../../utils/navigation';

const PublishStage = ({ children }) => {
  const isMobileSubPage = useIfMobileSettings();
  return (
    <ScPublishStage isMobileSubPage={isMobileSubPage}>
      {children}
    </ScPublishStage>
  );
};

PublishStage.propTypes = {
  children: renderable.isRequired
};

export default PublishStage;
