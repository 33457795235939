import React, { FC, useState } from 'react';
import {
  IconTrashFilled,
  IconExclamationCircle
} from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  Dialog,
  DialogIcon,
  DialogBody,
  DialogDescription,
  DialogTitle,
  DialogActions,
  DialogCloseButton,
  Button
} from '@jotforminc/magnet';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

interface I_DETACHCONNDIALOG {
  showDialog: boolean;
  onClose: () => void;
  onDetach: () => void;
}

const DetachConnectionDialog: FC<I_DETACHCONNDIALOG> = ({ showDialog, onClose, onDetach }: I_DETACHCONNDIALOG) => {
  const { resource } = usePaymentPropsPanel();
  const [loading, setLoading] = useState(false);
  return (
    <Dialog
      id="detach-connection-dialog"
      onClose={onClose}
      open={showDialog}
      ariaLabel={t('Detach Connection Dialog')}
    >
      <DialogBody>
        <DialogIcon
          icon={IconTrashFilled}
          colorStyle="error"
        />
        <DialogTitle className="tracking-sm">
          {translationRenderer('Do you want to remove connection for this [1[{resource}]]?')({
            renderer1: () => <span>{resource.toLowerCase()}</span>
          })}
        </DialogTitle>
        <DialogDescription
          style={{ margin: '8px' }}
        >
          {translationRenderer('If you remove the payment connection for this [1[{resource}]], this [1[{resource}]] will be [2[unable to collect payments]] until you add another connection.')({
            renderer1: () => <span>{resource.toLowerCase()}</span>,
            renderer2: text => <span className='text-sm line-height-md tracking-lg font-bold color-navy-700'>{text}</span>
          })}
        </DialogDescription>
        <div className='w-full flex justify-center'>
          <span className='flex w-auto p-2 bg-navy-25 text-sm line-height-xs tracking-lg radius'>
            <IconExclamationCircle className='w-4 h-4 mr-2' />
            {t('Your connection will remain available for future use.')}
          </span>
        </div>
      </DialogBody>
      <DialogActions>
        <Button
          colorStyle="secondary"
          variant="outline"
          onClick={onClose}
        >
          {t('No, Cancel')}
        </Button>
        <Button
          colorStyle="error"
          variant="filled"
          loader={loading}
          onClick={async () => {
            setLoading(true);
            await onDetach();
            setLoading(false);
          }}
        >
          {t('Yes, Remove')}
        </Button>
      </DialogActions>
      <DialogCloseButton onClick={onClose} />
    </Dialog>
  );
};

export default DetachConnectionDialog;
