import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { ICurrency } from '../../types/common';
import NewDropdown from './NewDropdown';
import InputRange from './InputRange';

type InputRangeWithTypeType = {
  currencyInfo: ICurrency,
  entryLimit: string,
  entryLimitMin: string,
  onCustomChange: ({ key, value }: { key: string, value: string }) => void,
  inputClass: string,
  limitTypeOptions: { text: string; value: string | number; }[],
  limitDescription?: string
}

const InputRangeWithType: FunctionComponent<InputRangeWithTypeType> = ({
  onCustomChange, entryLimit = '', entryLimitMin = '', currencyInfo, inputClass, limitTypeOptions, limitDescription
}) => {
  const firstValue = limitTypeOptions[0].value;
  const minLimit = entryLimitMin.split('-')[1] || '';
  const maxLimit = entryLimit.split('-')[1] || '';
  let limitType = entryLimit.split('-')[0] || firstValue;
  limitType = limitType === 'None' ? firstValue : limitType;

  const handleChange = ({ key, value }: {key: string, value: string}) => {
    onCustomChange({ key, value: `${limitType}-${value}` });
  };

  const handleChangeType = (type: any) => {
    onCustomChange({ key: 'entryLimit', value: `${type}-${maxLimit}` });
    onCustomChange({ key: 'entryLimitMin', value: `${type}-${minLimit}` });
  };

  return (
    <div className={cx('flex justify-between flex-wrap sm:flex-nowrap gap-4', inputClass)}>
      <InputRange
        minLimit={minLimit}
        maxLimit={maxLimit}
        minKey="entryLimitMin"
        maxKey="entryLimit"
        currencyInfo={currencyInfo}
        onCustomChange={handleChange}
        inputClass=""
      />
      <div className='w-full sm:w-auto'>
        <NewDropdown
          options={limitTypeOptions}
          selectedOptionValues={limitType}
          onChange={handleChangeType}
          inputClass="w-full"
          // TODO: new dropdown min-w-44 class shouldn't be superior
          dropdownInputClass="portal-new-dropdown-button min-w-20 color-white"
          dropdownWrapperClass="portal-new-dropdown-wrapper"
          dropdownTheme="dark"
          disabled={false}
        />
        {limitDescription ? (
          <div className="mt-2 color-white opacity-60">{limitDescription}</div>
        ) : null}
      </div>
    </div>
  );
};

InputRangeWithType.defaultProps = {
  limitDescription: ''
};

export default InputRangeWithType;
