import React, { useState, useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { Languages } from '@jotforminc/constants';
import PropTypes from 'prop-types';
import Accordion from './Accordion';

import { getDeviceType } from '../../getDeviceType';
import { fetchLanguages } from '../../apiTemplates';
import RadioButton from './RadioButton';

const LanguagesFilter = ({
  language,
  setLanguage,
  templateType,
  username,
  source
}) => {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    fetchLanguages(templateType, source)
      .then(res => {
        try {
          const { data: { content } } = res;
          const formattedLanugages = content.map(lang => {
            const templateLang = lang._language || lang.language;
            if (Languages && Languages.length > 0) {
              const languageObject = Languages.find(l => templateLang === l.key);
              if (languageObject !== undefined) return { value: templateLang, text: languageObject.value };
              return undefined;
            }
            return { value: templateLang, text: templateLang };
          });
          setLanguages([{ value: '', text: 'All' }, ...formattedLanugages.filter(lang => lang !== undefined).sort((a, b) => (a.text > b.text ? 1 : -1))]);
        } catch (e) {
          setLanguages([]);
        }
      })
      .catch(() => {
        setLanguages([]);
      });
  }, []);

  const handleSelectLanguage = e => {
    const selected = e.target.value;
    setLanguage([selected]);
    window.standaloneTemplatesLogger({
      actor: username,
      target: selected,
      action: `${getDeviceType()}:languageSelect`
    });
  };

  return (
    <Accordion
      username={username}
      text={t('LANGUAGES')}
      label={languages.find(lang => lang.value === language[0])?.text}
      isLast={true}
      scrollable
    >
      {languages.map(lang => (
        <RadioButton
          key={lang.value}
          label={lang.text}
          name="language"
          value={lang.value}
          checked={language[0] === lang.value}
          onChange={e => handleSelectLanguage(e)}
        />
      ))}
    </Accordion>
  );
};

LanguagesFilter.defaultProps = {
  source: '',
  username: '',
  templateType: 'form-templates'
};

LanguagesFilter.propTypes = {
  source: PropTypes.string,
  username: PropTypes.string,
  templateType: PropTypes.string,
  setLanguage: PropTypes.func.isRequired,
  language: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LanguagesFilter;
