import Fuse from 'fuse.js';

export const debounce = (fn, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { fn.apply(this, args); }, timeout);
  };
};

const baseFuseOptions = {};
const baseKeys = ['name', 'email'];
export const fuseSearch = (list, searchTerm, keys = baseKeys, fuseOptions = baseFuseOptions) => {
  const fuse = new Fuse(list, {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    ...fuseOptions,
    keys
  });

  return !searchTerm ? list : fuse.search(searchTerm).map(fuseResult => ({ ...fuseResult.item }));
};
