export const NON_INPUT_QUESTIONS = [
  'control_pagebreak',
  'control_collapse',
  'control_head',
  'control_text',
  'control_image',
  'control_button',
  'control_captcha',
  'control_separator',
  'control_clear',
  'control_divider'
];

// these are widgets that do not generate answers.
export const NON_INPUT_WIDGETS = [
  '5273bb4faa80af0979000005', // Youtube
  '52a8531850e4cbc101000004', // Progress Bar
  '0fff4c94790070b09933edd8', // Open Street Map
  '5295bb771a667c525e000007', // Kinomap
  '5295c0a8ba5d618d5f000004', // Video Detective
  '526a2cd08a881e882100000c', // Vimeo
  '526a318cdeab09892100001c', // Animoto
  '52721d0af9879ec031000026', // Mobypicture
  '5295afd37da55cb758000008', // 23 Video
  '5295beee9319dafd5e000004', // Socialcam
  '526a2dbf8a881e882100000f', // Funny or Die
  '5295bf984d4ed6385f000004', // TED
  '5295ba694a87e6175e000007', // Howcast
  '5295c1831c9dde8961000004', // Worldstar HipHop
  '526a2d72deab09892100000d', // Dotsub
  '5295bc6528947a7d5e000004', // LogoTV
  '573985710fe4dd7d72000009', // Wistia
  '52930ea905a7114f7000002e', // Embed Flash
  '526a31538a881e8821000015', // Dailymotion
  '5295bfef3c0d37545f000004', // Twitch
  '5295bd563a20442323000004', // MySpace Videos
  '5295b815485bd19f5d000008', // Comedy Central
  '5295b794b3a63a910c000004', // Colbert Nation
  '5295b4bd22d53d3d59000004', // Bambuser
  '5295bfc6edbacd465f000004', // Trailer Addict
  '5295c0785a989c8c5f000004', // UStream
  '529ca19069fc7db354000004', // Livestream
  '5295b856ebc6f2bf5d000004', // Coub
  '5295c144a9770db75f000004', // WordPress TV
  '5295bec630696cfc5e000004', // Screenr
  '526a3007deab098921000016', // Vzaar
  '526a33258a881e882100001b', // Minoto Video
  '5295b9b357166ffb5d000004', // Fora.tv
  '5295bd883a20442323000007', // NFB
  '5295bc310802796e5e000004', // LiveLeak
  '5295bb401a667c525e000004', // KickStarter
  '5295bf7a1750fa375f000004', // StreetFire
  '5295c117bb1d7ea95f000004', // Weavly
  '526a2c3fdeab098921000007', // Hulu
  '5283818625d5b96b67000004', // Viddler
  '5295b9701667a0de5d000008', // ESPN
  '526a33768a881e882100001e', // Youku
  '5295bc9989cc268a5e000004', // MixBit
  '5295b142ba053ae558000004', // AOL On
  '526a32b68a881e8821000018', // Veoh
  '5295c1aaaa24319761000004', // Zapiks
  '526a2ef1deab098921000013', // Metacafe
  '5295b9f083a5d70a5e000004', // Game Trailers
  '52a604a359583c1d56000006', // Movie
  '529cd0ea8afa8f742d000004', // Image Slider
  '5293065005a7114f7000002a', // Fit Text
  '5297606bfe8fa6bf0d000016', // Data grid
  '529260f705a7114f70000008', // Social Follow
  '529641beb15ce2ac76000007', // PDF Embedder
  '528ef93577d598b430000010' // Countdown
];

export const STATIC_FIELDS_WITH_PLACEHOLDER = [
  { text: 'Submission ID', placeholder: '{id}' },
  { text: 'Form Title', placeholder: '{form_title}' },
  { text: 'IP Address', placeholder: '{ip}' }
];
