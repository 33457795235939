import Styled from 'styled-components';
import { mainFontColor } from '../../../../../styles/colors';

export const ScPortalFeedbackModal = Styled.div`

  .jPortalModal-header {
    position: relative;
    padding: 18px 26px;
    flex-shrink: 0;
    border-bottom: 1px solid hsla(0,0%,84.7%,.7);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .jPortalModal-headerContent {
      flex-grow: 1;

      .jPortalModal-title {
        color: ${mainFontColor};
        font-size: 20px;
        font-family: inherit;
        width: 100%;
        font-weight: 500;
      }

      .jPortalModal-subTitle {
        margin-top: 6px;
        color: #6F76A7;

        font-size: 15px;
        width: 100%;
        font-weight: 400;
      }
    }

    .jPortalModal-closeModal {
      border: 0;
      cursor: pointer;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
      margin-left: 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      outline: none;
      background-color: #F3F3FE;

      svg {
        width: 30px;
        height: 30px;
        color: #6F76A7;
      }
    }
  }

  .jPortalModal-body {
    padding: 26px;
    overflow: auto;
    flex: 1 1 auto;
    max-height: calc(90vh - 140px);

    @media screen and (max-width: 480px) {
      height: 100%;
    }

    &.vertical {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px;
      border: 0;
      text-align: center;
      flex-wrap: wrap;
      min-height: 380px;
      position: relative;
      flex-shrink: 0;
      display: flex;
    }

    .jPortalModal-label {
      color: ${mainFontColor};
      display: block;
      width: 100%;
      font-size: .9375em;
      font-family: inherit;
      margin-bottom: .625em;
      font-weight: 500;
    }

    .jPortalModal-ratingContainer {
      margin-bottom: 16px;
      display: flex;

      .jPortalModal-rating {
        width: 32px;
        padding: 0;
        margin: 0 2px;
        height: 30px;
        background: transparent;
        border: none;
        cursor: pointer;
        text-align: left;

        svg {
          transition: .1s;
          width: 36px;
          height: 36px;
          transform: scale(.85);
        }
      }
    }

    .jPortalModal-input {
      font-size: 15px;
      width: 100%;
      border-radius: 4px;
      transition: .3s;
      text-align: left;
      border: 1px solid #bcbcc8;
      background-color: #fff;
      color: #2b3245;
      min-height: 5.25em;
      padding: .625em;
      resize: none;
      display: block;
      height: 140px;
      font-family: inherit;
    }

    .jPortalModal-highlight {
      font-size: 22px;
      font-weight: 500;
    }

    .jPortalModal-thankyouImg {
      margin-bottom: 16px;
    }
  }



  .jPortalModal-footer {
    padding: 12px 26px;
    border-top: 1px solid #edeef5;
    background-color: rgba(239,239,246,.45);
    border-radius: 0 0 4px 4px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    position: relative;

    @media screen and (max-width: 480px) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .jPortalModal-cancel {
      min-width: 130px;
    }

    .jPortalModal-send {
      min-width: 110px;
    }

    .jPortalModal-done {
      cursor: pointer;
      transition: .3s;
      font-size: 15px;
      border-radius: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: #fff;
      font-family: inherit;
      padding: 0 32px;
      height: 42px;
      font-weight: 500;
      margin-left: auto;
    }
  }
`;
