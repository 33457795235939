import * as actionTypes from '../actionTypes';
import { IPaymentConnectionItem } from '../../types/common';

const activeConnection = (state: Partial<IPaymentConnectionItem> = {}, action: IAction) : Partial<IPaymentConnectionItem> => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_CONNECTION:
      return action.payload;
    default:
      return state;
  }
};

interface IAction {
  type: string,
  payload: IPaymentConnectionItem
}

export default activeConnection;
