import React, { FC, useState, useEffect } from 'react';
import {
  IconExclamationCircle,
  IconArrowRight,
  IconCartShoppingFilled,
  IconCoinsFilled,
  IconHeartFilled,
  IconCoinsStackedFilled,
  IconInfoCircleFilled
} from '@jotforminc/svg-icons';
import { supportedPaymentTypesWithLabel } from '@jotforminc/payment-constants';
import type { GATEWAY_PAYMENT_TYPES } from '@jotforminc/payment-constants';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  Dialog,
  DialogIcon,
  DialogBody,
  DialogTitle,
  DialogDescription,
  DialogContent,
  DialogActions,
  DialogCloseButton,
  Button,
  Flex
} from '@jotforminc/magnet';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

const PAYMENT_TYPE_ICONS: Readonly<Record<GATEWAY_PAYMENT_TYPES, any>> = {
  product: IconCartShoppingFilled,
  subscription: IconCoinsStackedFilled,
  custom: IconCoinsFilled,
  donation: IconHeartFilled
};

interface IPaymentTypeDropdown {
  showDialog: boolean;
  onClose: () => void;
  prevPaymentType: GATEWAY_PAYMENT_TYPES;
  newPaymentType: GATEWAY_PAYMENT_TYPES | null;
  resetNewPaymentType: () => void;
}

const PaymentTypeDialog: FC<IPaymentTypeDropdown> = ({
  showDialog,
  onClose,
  prevPaymentType,
  newPaymentType,
  resetNewPaymentType
}) => {
  const {
    changePaymentType,
    products,
    getAllRequiredPropNamesByPaymentType,
    hasConnection
  } = usePaymentPropsPanel();
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      resetNewPaymentType();
    };
  }, []);

  const Content = () => {
    const PaymentTypeBox = ({ paymentType }: { paymentType: GATEWAY_PAYMENT_TYPES | null }) => {
      if (!paymentType) return null;

      const PaymentTypeIcon = PAYMENT_TYPE_ICONS[paymentType];
      return (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="py-5 px-3 color-navy-500 radius border border-navy-50 bg-white z-1"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            className="p-2 bg-navy-25 radius"
          >
            <PaymentTypeIcon className="w-5 h-5 color-navy-500" />
          </Flex>
          <span className='text-sm text-center line-height-xs tracking-lg font-medium mt-4 w-36'>{t(supportedPaymentTypesWithLabel[paymentType])}</span>
        </Flex>
      );
    };

    let hasRequiredProp = false;
    if (newPaymentType) {
      hasRequiredProp = getAllRequiredPropNamesByPaymentType(newPaymentType).length > 0;
    }

    const showRequiredSettingsIndicator = hasConnection && hasRequiredProp;
    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="between"
          className="px-8 relative"
        >
          <div className='absolute border-t border-navy-50 border-dashed h-px w-1/5 transform translate-x-40' />
          <PaymentTypeBox paymentType={prevPaymentType} />
          <Flex
            alignItems="center"
            justifyContent="center"
            className="p-2 bg-navy-25 radius z-1"
          >
            <IconArrowRight className="w-4 h-4 color-navy-500" />
          </Flex>
          <PaymentTypeBox paymentType={newPaymentType} />
        </Flex>
        {
          showRequiredSettingsIndicator && (
            <div className="flex text-left bg-yellow-100 p-2 radius mt-4 mx-8">
              <IconInfoCircleFilled className="h-5 w-4 shrink-0 mr-2 fill-yellow-500" />
              <span className='color-navy-500 text-sm line-height-md tracking-lg'>
                {translationRenderer('The payment type you’re about to switch to [1[requires]] you to [1[configure settings]] in the payment properties panel.')({
                  renderer1: (text: string) => <span className='color-navy-700 font-medium'>{text}</span>
                })}
              </span>
            </div>
          )
        }
      </>

    );
  };

  const onPaymentTypeChange = async () => {
    if (!newPaymentType) { return; }
    setLoader(true);
    await changePaymentType(prevPaymentType, newPaymentType);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={showDialog}
      ariaLabel={t('Payment Type Dialog')}
    >
      <DialogBody>
        <DialogIcon
          icon={IconExclamationCircle}
          colorStyle="warning"
        />
        <DialogTitle>
          { t('Do you want to change your payment type?') }
        </DialogTitle>
        {
          products && products.length > 0 && (
            <DialogDescription>
              {translationRenderer(`Changing your payment type will [1[remove all ${prevPaymentType === 'subscription' ? 'subscriptions' : 'products'}.]]`)({
                renderer1: (text: string) => <b className='color-navy-500'>{text}</b>
              })}
            </DialogDescription>
          )
        }
        <DialogContent>
          <Content />
        </DialogContent>
      </DialogBody>
      <DialogActions>
        <Button
          colorStyle="secondary"
          variant="outline"
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          colorStyle="primary"
          variant="filled"
          onClick={onPaymentTypeChange}
          loader={loader}
        >
          {t('Yes, Change')}
        </Button>
      </DialogActions>
      <DialogCloseButton onClick={onClose} />
    </Dialog>
  );
};

export default PaymentTypeDialog;
