/* eslint-disable react/require-default-props */
import React from 'react';
import {
  LogoAuthorizenetColor,
  LogoStripeDark,
  LogoPaypalColor,
  LogoBraintreeDark,
  LogoCardconnectColor,
  LogoSquareDark,
  LogoEwayDark,
  LogoVenmoDark,
  LogoMollieDark,
  LogoCybersourceDark,
  LogoPayjunctionColor
} from '@jotforminc/svg-icons';

/** styles */
import '../assets/style.css';

const GatewayLogoCard = ({
  logo: Logo,
  width = '',
  marginTop = ''
}: {
  logo: React.FC<React.SVGProps<SVGSVGElement>> | null;
  width?: string;
  marginTop?: string;
}) => (
  <div className='logo-box bg-white py-1 px-4 shadow-light-sm flex items-center justify-center shrink-0 w-32 h-14'>
    <div className='flex items-center justify-center w-full h-11'>
      {Logo && <Logo className={`${width} ${marginTop}`} />}
    </div>
  </div>
);

export const LoginFlowColumn = (): any => {
  const firstRow = [
    { logo: LogoAuthorizenetColor },
    { logo: LogoStripeDark, width: 'w-14' },
    { logo: LogoPaypalColor }
  ];

  const secondRow = [
    { logo: LogoBraintreeDark },
    { logo: LogoCardconnectColor },
    { logo: LogoSquareDark, width: 'w-18' },
    {
      logo: LogoEwayDark, width: 'w-11', marginTop: 'mt-2'
    }
  ];

  const thirdRow = [
    { logo: LogoVenmoDark, width: 'w-12' },
    { logo: LogoMollieDark, width: 'w-12' },
    { logo: LogoCybersourceDark },
    { logo: LogoPayjunctionColor }
  ];

  return (
    <div className='login-flow-column hiddenjf lg:block relative overflow-hidden bg-navy-10 max-w-116 w-full h-full py-28'>
      {/* first row */}

      <div className='flex flex-col justify-center items-center h-full relative z-1'>
        <div>
          <div className='flex justify-center items-center gap-4 mb-8'>
            <GatewayLogoCard logo={null} />
            {firstRow.map(gateway => GatewayLogoCard(gateway))}
            <GatewayLogoCard logo={null} />
          </div>
          <div className='flex justify-center items-center gap-4 mb-8 -ml-28'>
            {secondRow.map(gateway => GatewayLogoCard(gateway))}
          </div>
          <div className='flex justify-center items-center gap-4 -mr-28'>
            {thirdRow.map(gateway => GatewayLogoCard(gateway))}
          </div>
        </div>
        <div className='mt-10'>
          <p className='login-flow-header font-medium text-xl color-navy-700'>Connect with more than 40 payment gateways</p>
          <p className='login-flow-text text-sm line-height-md color-navy-300 max-w-100 w-full'>Turn your form into a powerful payment tool to collect online payments</p>
        </div>
      </div>
    </div>
  );
};
