// A/B Test: ctAppNameIconModal
// A/B Test: appElementsPanelDefaultVisibility

export const AB_TEST_NAMES = {
  ELEMENT_PANEL_VISIBILITY: 'appElementsPanelDefaultVisibility',
  CT_APP_NAME_ICON_MODAL_TWO: 'ctAppNameIconModalTwo'
};

// local storage keys
export const LC_KEYS = {
  ELEMENT_PANEL_VISIBILITY: {
    IS_ELEMENT_ADDED: 'IS_ELEMENT_ADDED'
  }
};

class AbTestActionLoggerSingleton {
  constructor() {
    Object.values(AB_TEST_NAMES).forEach(testName => {
      this[testName] = f => f;
    });
  }
}

export const abTestActionLoggerSingleton = new AbTestActionLoggerSingleton();
