import React, {
  useEffect, useCallback, useState, ChangeEvent, MouseEvent, FC
} from 'react';
import { Hooks } from '@jotforminc/uikit';
import classNames from 'classnames';
import { supportedCurrenciesWithLabel } from '@jotforminc/payment-constants';
import PwInfoLine from './infoline';
import { checkIsGatewayConnected, checkCurrencySupport } from '../../utils';
import { getFilteredCurrencies } from '../../utils/paymentProperties';
import { TCurrencyCode, IGatewaySettings } from '../../types/common';
import { GATEWAYS_WITH_FIXED_CURRENCY } from '../../constants/variables';
import { I_GATEWAYS } from '../modals/Flows/Types';

export interface IProps {
  activeCurrencyCode?: TCurrencyCode,
  onChange: (currency: TCurrencyCode) => void,
  activeGatewaySettings: IGatewaySettings
}

const CurrencyDropdown: FC<IProps> = ({
  activeCurrencyCode = 'USD',
  onChange,
  activeGatewaySettings
}) => {
  const hasGateway = activeGatewaySettings.type && activeGatewaySettings.type !== 'control_payment';
  let initialCurrencyList = hasGateway ? getFilteredCurrencies(activeGatewaySettings.type, activeGatewaySettings.nameAPM) : supportedCurrenciesWithLabel;
  const isFixedCurrency = GATEWAYS_WITH_FIXED_CURRENCY.includes(activeGatewaySettings.type) && checkIsGatewayConnected(activeGatewaySettings);
  if (isFixedCurrency) {
    initialCurrencyList = { [activeGatewaySettings.currency]: supportedCurrenciesWithLabel[activeGatewaySettings.currency] };
  }
  const [currencies, setCurrencies] = useState(initialCurrencyList);
  useEffect(() => {
    setCurrencies(initialCurrencyList);
  }, [activeGatewaySettings.type, activeGatewaySettings.nameAPM, activeGatewaySettings.currency]);

  const [showDropdown, setShowDropdown] = Hooks.useClickOutsideStateWithSelector(false, '.currency-dropdown');

  const currencySelectHandler = useCallback((e: MouseEvent) => {
    const selectedCurrency = (e.target as HTMLButtonElement).dataset.currency;

    if (selectedCurrency) {
      setShowDropdown(false);
      onChange(selectedCurrency as TCurrencyCode);
    }
  }, [onChange, setShowDropdown]);

  const filterCurrencies = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    let filteredCurrencies = {};

    try {
      filteredCurrencies = Object.fromEntries(new Map(Object.entries(currencies).filter(([shortForm, desc]) => (
        shortForm.toLowerCase().indexOf(value) > -1 || desc.toLowerCase().indexOf(value) > -1
      ))));
    } catch (err) {
      console.error('[CurrencyDropdown]', err);
    }

    setCurrencies((Object.keys(filteredCurrencies).length > 0 && value.length > 0) ? filteredCurrencies : initialCurrencyList);
  }, [currencies, setCurrencies]); // eslint-disable-line

  const changeDropdownVisibility = useCallback(() => {
    if (Object.keys(initialCurrencyList).length > 1) {
      setShowDropdown(!showDropdown);
    }
  }, [setShowDropdown, showDropdown, initialCurrencyList]);

  useEffect(() => {
    if (hasGateway) {
      const isCurrencySupported = checkCurrencySupport(activeGatewaySettings.type as I_GATEWAYS, activeCurrencyCode);
      if (!isCurrencySupported.isSupported) {
        onChange(isCurrencySupported.supportedCurrency as TCurrencyCode);
      }
    }
  }, [hasGateway, activeGatewaySettings.type, currencies]);

  return (
    <div className={classNames('currency-dropdown-container dropdownWrapper', { isFixedCurrency })}>
      <button
        type="button"
        className={classNames('mDropdownWrapper dropdown-button', {
          opened: showDropdown === true && Object.keys(initialCurrencyList).length > 1,
          dropdown_disabled: Object.keys(initialCurrencyList).length === 1
        })}
        onClick={changeDropdownVisibility}
      >
        <span className="mDropdown hideOnCrawler overflow-hidden whitespace-nowrap">{`${activeCurrencyCode} - ${supportedCurrenciesWithLabel[activeCurrencyCode]}`}</span>
        <span className="mDropdownMask " />
        <div className="dropdown-arrow-Wrapper">
          <span className="dropdown-arrow" />
        </div>
      </button>
      {showDropdown && (
        <div className="currency-dropdown">
          <div className="dropdown-container">
            <div className="dropdown-input">
              <input
                type="text"
                onChange={filterCurrencies}
              />
              <span className="searchIcon" />
            </div>
            <div className="currencies">
              {Object.entries(currencies).map(([currency, desc]) => (
                <button
                  key={currency}
                  type="button"
                  data-currency={currency}
                  className={classNames('currency-item', { active: currency === activeCurrencyCode })}
                  onClick={currencySelectHandler}
                >
                  <span className="code">{currency}</span>
                  <span className="currency">{desc}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {isFixedCurrency
      && (
        <PwInfoLine
          type=''
          wrapperClassName='pwInfoLine'
          text={`Your currency was set as ${activeCurrencyCode} based on your <a href="https://squareup.com/dashboard" target="_blank">Square account</a> settings.`}
        />
      )}
    </div>
  );
};

export default CurrencyDropdown;
