import TrackingManager from '@jotforminc/tracking';
import { select, take } from 'redux-saga/effects';
import { StorageHelper } from '@jotforminc/storage-helper';
import * as ACTION_TYPES from '../actionTypes';
import SELECTORS from '../selectors';
import { MODALS } from '../../constants/modals';
import { isYes } from '../../utils';

const activateFullStory = (user, customProps = {}) => {
  const {
    account_type: accountType,
    email,
    identifier,
    isAppFirstUser,
    username,
    name
  } = user;

  if (
    TrackingManager.FSisInitialized()
    || ['ADMIN', 'SUPPORT', 'INTERN', 'HELPDESK'].includes(accountType?.name)
  ) {
    return;
  }

  const userParams = {
    displayName: name,
    accountType: accountType?.name,
    email,
    ...customProps,
    isAppFirstUser,
    appBuilder_bool: true
  };

  TrackingManager.enableFS();
  TrackingManager.identify(identifier || username, userParams);
};

export function* activateFullStoryOnTheFly() {
  const isBuilder = yield select(SELECTORS.getIsBuilder);

  while (isBuilder) {
    const action = yield take([
      ACTION_TYPES.ADD_PORTAL_ITEMS.SUCCESS,
      ACTION_TYPES.FETCH_PORTAL.SUCCESS,
      ACTION_TYPES.CREATE_NEW_PORTAL.SUCCESS,
      ACTION_TYPES.CREATE_PORTAL_WITH_DONATION,
      ACTION_TYPES.CREATE_PORTAL_WITH_STORE,
      ACTION_TYPES.SHOW_MODAL,
      ACTION_TYPES.ENABLE_PUSH_NOTIFICATION,
      ACTION_TYPES.NAVIGATE_TO,
      ACTION_TYPES.SEND_FEEDBACK.REQUEST,
      ACTION_TYPES.AI_SHOW_ASSISTANT,
      ACTION_TYPES.AI_ADD_CHAT_MESSAGE
    ]);

    const user = yield select(SELECTORS.getUser);
    const { payload: items = [] } = action;

    const isShoppingApp = yield select(SELECTORS.getIsShoppingApp);
    const isDonationApp = yield select(SELECTORS.getIsDonationApp);
    const { pushNotification } = yield select(SELECTORS.getAppInfoWithDefaults);

    const customProps = {
      isStoreApp: isShoppingApp,
      isDonationApp: isDonationApp
    };

    const startTracking = props => activateFullStory(user, props);
    const enableTracking = isShoppingApp || isDonationApp || user.isAppFirstUser;

    const appEventsToBeSent = [];

    const appCreationSourceMap = {
      [ACTION_TYPES.CREATE_NEW_PORTAL.SUCCESS]: 'from-scratch',
      [ACTION_TYPES.CREATE_PORTAL_WITH_DONATION]: 'with-donation',
      [ACTION_TYPES.CREATE_PORTAL_WITH_STORE]: 'with-store',
      [ACTION_TYPES.FETCH_PORTAL.SUCCESS]: 'unknown'
    };

    let appCreationSource = appCreationSourceMap[action.type];

    switch (appCreationSource) {
      case 'from-scratch':
        if (action.payload.formIDs) {
          appCreationSource = 'with-form';
          break;
        }
        if (action.payload.widgetDefaults) {
          appCreationSource = 'with-element';
          break;
        }
        break;
      case 'unknown':
        appCreationSource = StorageHelper.getSessionStorageItem({
          key: 'app-creation-source'
        }) || undefined;

        StorageHelper.removeSessionStorageItem({ key: 'app-creation-source' });
        break;
      default:
        break;
    }

    if (appCreationSource) {
      appEventsToBeSent.push(() => TrackingManager.event('app-creation', { source_str: appCreationSource }));
    }

    const isExitIntentSurvey = items.name === MODALS.EXIT_INTENT_SURVEY_MODAL;

    if (isExitIntentSurvey) {
      appEventsToBeSent.push(() => TrackingManager.event('exit-intent-survey'));
    }

    if (isYes(items.enabledPushNotification)) {
      appEventsToBeSent.push(() => TrackingManager.event('push-notification-enabled'));
    }

    if (items.subTab === 'pushNotification' && isYes(pushNotification)) {
      appEventsToBeSent.push(() => TrackingManager.event('clicked-push-notification-with-enabled'));
    }

    if (action.type === ACTION_TYPES.SEND_FEEDBACK.REQUEST) {
      appEventsToBeSent.push(() => TrackingManager.event('sent-feedback', { stars: items.stars }));
    }

    if (isYes(items.aiIsShow)) {
      appEventsToBeSent.push(() => TrackingManager.event('app-builder-copilot', { from: 'app-builder', action: 'click-copilot-button' }));
    }

    if (isYes(items.prompt)) {
      appEventsToBeSent.push(() => TrackingManager.event('app-builder-copilot', { from: 'app-builder', action: 'send-prompt-message' }));
    }

    if (!enableTracking && !appEventsToBeSent.length) {
      return;
    }

    startTracking(customProps);
    appEventsToBeSent.forEach(event => event());
  }
}
