import { t } from '@jotforminc/translation';
import { ITEM_TYPES, RESOURCE_TYPES } from '../../../../constants/itemTypes';

export const RightPanelModes = {
  APP_ITEM: 'HOMEPAGE/APP_ITEM',
  APP_DESIGNER: 'HOMEPAGE/APP_DESIGNER',
  APP_HEADER: 'HOMEPAGE/APP_HEADER',
  APP_LOGO: 'HOMEPAGE/APP_LOGO',
  APP_STYLE: 'HOMEPAGE/APP_STYLE',
  APP_ICON: 'SETTINGS/APP_ICON',
  SPLASH_SCREEN: 'SETTINGS/SPLASH_SCREEN',
  MULTIPLE_ITEM: 'HOMEPAGE/MULTIPLE_ITEM',
  APP_WIDGET: 'HOMEPAGE/APP_WIDGET',
  APP_FOOTER: 'HOMEPAGE/APP_FOOTER',
  MENU_SETTINGS: 'HOMEPAGE/MENU_SETTINGS',
  PAGE_SETTINGS: 'HOMEPAGE/PAGE_SETTINGS',
  PRODUCT_LIST_SETTINGS: 'HOMEPAGE/PRODUCT_LIST_SETTINGS',
  PAYMENT_SETTINGS: 'HOMEPAGE/PAYMENT_SETTINGS',
  DONATION_PAYMENT_SETTINGS: 'HOMEPAGE/DONATION_PAYMENT_SETTINGS',
  CONTACT_INFORMATION_SETTINGS: 'HOMEPAGE/DONATION_ITEM_SETTINGS'
};

export const RightPanelModeEvents = {
  [RightPanelModes.APP_ITEM]: 'itemPropPanelOpened',
  [RightPanelModes.APP_DESIGNER]: 'appDesignerOpened',
  [RightPanelModes.APP_LOGO]: 'logoPropertyOpened',
  [RightPanelModes.APP_STYLE]: 'stylePropertyOpened',
  [RightPanelModes.APP_HEADER]: 'appHeaderPanelOpened',
  [RightPanelModes.APP_ICON]: 'appIconPropPanelOpened',
  [RightPanelModes.SPLASH_SCREEN]: 'splashScreenPanelOpened',
  [RightPanelModes.MULTIPLE_ITEM]: 'multipleItemPanelOpened',
  [RightPanelModes.APP_WIDGET]: 'widgetPanelOpened',
  [RightPanelModes.APP_FOOTER]: 'appFooterPanelOpened',
  [RightPanelModes.MENU_SETTINGS]: 'pageNavigationSettingsPanelOpened',
  [RightPanelModes.PAGE_SETTINGS]: 'pageSettingsPanelOpened',
  [RightPanelModes.PAYMENT_SETTINGS]: 'paymentSettingsPanelOpened',
  [RightPanelModes.DONATION_PAYMENT_SETTINGS]: 'donationSettingsPanelOpened',
  [RightPanelModes.DONATION_PAYMENT_SETTINGS]: 'donationPaymentPropertiesOpened',
  [RightPanelModes.CONTACT_INFORMATION_SETTINGS]: 'contactInformationSettings'
};

export const RightPanelTitles = {
  [RightPanelModes.APP_DESIGNER]: 'App Designer',
  [RightPanelModes.APP_LOGO]: 'App Header Properties',
  [RightPanelModes.APP_STYLE]: 'App Header Properties',
  [RightPanelModes.APP_HEADER]: 'App Header Properties',
  [RightPanelModes.APP_ICON]: 'App Icon Properties',
  [RightPanelModes.SPLASH_SCREEN]: 'Splash Screen',
  [RightPanelModes.MULTIPLE_ITEM]: 'Selected Elements Properties',
  [RightPanelModes.APP_FOOTER]: 'App Footer Properties',
  [RightPanelModes.MENU_SETTINGS]: 'Navigation Menu Properties',
  [RightPanelModes.PAGE_SETTINGS]: 'Page Properties',
  [RightPanelModes.PAYMENT_SETTINGS]: 'Store Properties',
  [RightPanelModes.DONATION_PAYMENT_SETTINGS]: 'Payment Properties',
  [RightPanelModes.CONTACT_INFORMATION_SETTINGS]: 'Contact Information Properties'
};

export const APP_LOGO_PANEL_TAB_NAMES = {
  Icon: 'Icon',
  Upload: 'Upload'
};

export const CUSTOM_PANEL_TITLE_ELEMENTS = [
  RightPanelModes.APP_WIDGET,
  RightPanelModes.APP_ITEM
];

const OVERRIDED_LINK_ICON_LIST = [
  'jfc_icon_solid-link||Email',
  'jfc_icon_solid-chat-conversation||Communication',
  'jfc_icon_solid-folder-open||Business',
  'jfc_icon_solid-briefcase||Business',
  'jfc_icon_solid-badge-id||Business',
  'jfc_icon_solid-phone||Communication',
  'jfc_icon_solid-mail||Email',
  'jfc_icon_solid-favorite||Basic UI',
  'jfc_icon_solid-megaphone||Communication',
  'jfc_icon_solid-paper-airplane||Email',
  'jfc_icon_solid-send-mail||Email',
  'jfc_icon_solid-at||Email',
  'jfc_icon_solid-bell-ring||Time',
  'jfc_icon_solid-hierarchy||Business',
  'jfc_icon_solid-branch||Development',
  'jfc_icon_solid-user||People'
];

const OVERRIDED_FORM_ICON_LIST = [
  'jfc_icon_color-coloredjfsvg-business-color_form||Colored'
];

const OVERRIDED_TABLE_LINK_ICON_LIST = [
  'jfc_icon_solid-table-icon||Business'
];

const OVERRIDED_REPORT_LINK_ICON_LIST = [
  'jfc_icon_solid-report-icon||Business'
];

const OVERRIDED_SENTBOX_LINK_ICON_LIST = [
  'jfc_icon_solid-sentbox-icon||Business'
];

const OVERRIDED_DOCUMENT_ICON_LIST = [
  'jfc_icon_color-coloredjfsvg-business-color_document||Colored',
  'jfc_icon_color-coloredjfsvg-business-color_image||Colored',
  'jfc_icon_color-coloredjfsvg-business-color_newcsv||Colored',
  'jfc_icon_color-coloredjfsvg-business-color_newdoc||Colored',
  'jfc_icon_color-coloredjfsvg-business-color_newpdf||Colored'
];

const OVERRIDED_MENU_ICON_LIST = [
  'jfc_icon_color-coloredjfsvg-business-color_hamburgermenu||Colored'
];

const OVERRIDEN_PAGE_ICON_LIST = [
  'jfc_icon_color-coloredjfsvg-business-color_homepage||Colored'
];

const OVERRIDED_SIGN_LINK_ICON_LIST = [
  'jfc_icon_solid-sign-icon||Business'
];

const OVERRIDED_DONATION_ICON_LIST = [
  'jfc_icon_solid-heart-filled||Basic UI'
];

export const OVERRIDED_ICON_LIST = {
  [ITEM_TYPES.LINK]: OVERRIDED_LINK_ICON_LIST,
  [ITEM_TYPES.FORM]: OVERRIDED_FORM_ICON_LIST,
  [ITEM_TYPES.DOCUMENT]: OVERRIDED_DOCUMENT_ICON_LIST,
  [ITEM_TYPES.TABLE_LINK]: OVERRIDED_TABLE_LINK_ICON_LIST,
  [ITEM_TYPES.REPORT_LINK]: OVERRIDED_REPORT_LINK_ICON_LIST,
  [ITEM_TYPES.SENTBOX_LINK]: OVERRIDED_SENTBOX_LINK_ICON_LIST,
  [RightPanelModes.MENU_SETTINGS]: OVERRIDED_MENU_ICON_LIST,
  [RightPanelModes.PAGE_SETTINGS]: OVERRIDEN_PAGE_ICON_LIST,
  [ITEM_TYPES.SIGN_LINK]: OVERRIDED_SIGN_LINK_ICON_LIST,
  [ITEM_TYPES.DONATION]: OVERRIDED_DONATION_ICON_LIST
};

export const IMAGE_GALLERY_LIST = [
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-25.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-34.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-23.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-36.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-29.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-15.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-20.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-56.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-2.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-30.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-21.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-37.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-12.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-50.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-4.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-48.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-38.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-16.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-32.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-14.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-19.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-53.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-22.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-5.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-52.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-44.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-31.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-3.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-7.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-18.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-24.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-51.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-46.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-26.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-17.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-39.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-40.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-6.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-49.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-13.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-27.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-35.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-42.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-1.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-9.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-55.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-41.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-28.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-33.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-10.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-45.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-47.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-54.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-11.png',
  'https://cdn.jotfor.ms/assets/img/thankYou/backgrounds/gallery-full-8.png'
];

export const FORM_PICKER_ACTION_TYPES = {
  SELECT_FORM: 'SELECT_FORM',
  SELECT_TEMPLATE: 'SELECT_TEMPLATE'
};

export const COLORED_FLAGS = [
  'jfc_icon_color-coloredjfsvg-other-australia||Colored',
  'jfc_icon_color-coloredjfsvg-other-brazil||Colored',
  'jfc_icon_color-coloredjfsvg-other-bulgaria||Colored',
  'jfc_icon_color-coloredjfsvg-other-canada||Colored',
  'jfc_icon_color-coloredjfsvg-other-dominican||Colored',
  'jfc_icon_color-coloredjfsvg-other-egypt||Colored',
  'jfc_icon_color-coloredjfsvg-other-elsalvador||Colored',
  'jfc_icon_color-coloredjfsvg-other-finland||Colored',
  'jfc_icon_color-coloredjfsvg-other-georgia||Colored',
  'jfc_icon_color-coloredjfsvg-other-germany||Colored',
  'jfc_icon_color-coloredjfsvg-other-hungary||Colored',
  'jfc_icon_color-coloredjfsvg-other-india||Colored',
  'jfc_icon_color-coloredjfsvg-other-indonesia||Colored',
  'jfc_icon_color-coloredjfsvg-other-jordan||Colored',
  'jfc_icon_color-coloredjfsvg-other-kenya||Colored',
  'jfc_icon_color-coloredjfsvg-other-macedonia||Colored',
  'jfc_icon_color-coloredjfsvg-other-madagascar||Colored',
  'jfc_icon_color-coloredjfsvg-other-pakistan||Colored',
  'jfc_icon_color-coloredjfsvg-other-philippines||Colored',
  'jfc_icon_color-coloredjfsvg-other-poland||Colored',
  'jfc_icon_color-coloredjfsvg-other-russia||Colored',
  'jfc_icon_color-coloredjfsvg-other-slovenia||Colored',
  'jfc_icon_color-coloredjfsvg-other-southafrica||Colored',
  'jfc_icon_color-coloredjfsvg-other-uk||Colored',
  'jfc_icon_color-coloredjfsvg-other-ukraine||Colored',
  'jfc_icon_color-coloredjfsvg-other-usa||Colored'
];

export const productSlugMap = {
  [ITEM_TYPES.FORM]: 'build',
  [ITEM_TYPES.BUTTON]: 'build',
  [ITEM_TYPES.CARD_ITEM]: 'build',
  [ITEM_TYPES.IMAGE]: 'build',
  [ITEM_TYPES.TABLE_LINK]: 'tables',
  [ITEM_TYPES.REPORT_LINK]: 'reports',
  [ITEM_TYPES.SIGN_LINK]: 'sign',
  [ITEM_TYPES.LIST]: 'tables'
};

export const actionNameMap = {
  [ITEM_TYPES.FORM]: 'editFormButtonClicked',
  [ITEM_TYPES.BUTTON]: 'buttonElementEditFormButtonClicked',
  [ITEM_TYPES.TABLE_LINK]: 'editTableButtonClicked',
  [ITEM_TYPES.REPORT_LINK]: 'editReportButtonClicked',
  [ITEM_TYPES.SIGN_LINK]: 'editSignButtonClicked'
};

export const resourceTypeMap = {
  [ITEM_TYPES.TABLE_LINK]: RESOURCE_TYPES.TABLE,
  [ITEM_TYPES.REPORT_LINK]: RESOURCE_TYPES.REPORT,
  [ITEM_TYPES.SENTBOX_LINK]: RESOURCE_TYPES.SENTBOX,
  [ITEM_TYPES.SIGN_LINK]: RESOURCE_TYPES.SIGN,
  [ITEM_TYPES.FORM]: RESOURCE_TYPES.FORM // if FormResource feature enabled
};

export const resourceLinkTypes = [
  ITEM_TYPES.TABLE_LINK,
  ITEM_TYPES.REPORT_LINK,
  ITEM_TYPES.SENTBOX_LINK,
  ITEM_TYPES.SIGN_LINK,
  ITEM_TYPES.FORM // if FormResource feature enabled
];

export const embeddedFormThankYouPageDisplaySecondOptions = [
  { text: '3 Seconds', value: 3 },
  { text: '10 Seconds', value: 10 },
  { text: '20 Seconds', value: 20 },
  { text: '30 Seconds', value: 30 },
  { text: '1 Minute', value: 60 },
  { text: '2 Minute', value: 120 }
];

export const getButtonShareDefMessages = () => ({
  appShare: t('Found a cool app I wanted to share! Check it out:'),
  donationShare: t('Be part of something meaningful. Support our cause and make a difference by donating through {App Title}, powered by @Jotform.').replace('{App Title}', `{${t('App Title')}}`),
  donationGoalShare:
  t('We\'ve raised {total amount raised} of our {goal amount} goal! Support our cause and make a difference by donating through {App Title}, powered by @Jotform.')
    .replace('{App Title}', `{${t('App Title')}}`)
    .replace('{total amount raised}', `{${t('total amount raised')}}`)
    .replace('{goal amount}', `{${t('goal amount')}}`)
});

export const getDefaultButtonShareObj = () => ({ type: 'appShare', text: getButtonShareDefMessages().appShare });

export const DELETE_PAGE_TYPES = {
  rightPanel: 'RIGHT_PANEL',
  navigationSettings: 'NAVIGATION_SETTINGS',
  pageItem: 'PAGE_ITEM'
};
