import Styled from 'styled-components';
import { settingsBgColor } from '../../../../../../styles/colors';

export const ScRpMenuDeviceSection = Styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: ${settingsBgColor};
  height: 138px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 4px;

  .menuDevice {
    position: relative;

    img {
      width: 100%;
    }
  }

  .menu-topbar-preview {
    position: absolute;
    display: flex;
    align-items: center;
    background: transparent;
    width: calc(100% - 60px);
    top: 66px;
    left: 50%;
    height: 48px;
    padding: 0 14px;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 480px) {
    .menu-topbar-preview {
      width: calc(100% - 52px);
      top: 54px;
    }
  }
`;
