import React, {
  useCallback, useMemo, ChangeEvent, useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '@jotforminc/uikit';
import Selectors from '../store/selectors';
import { IGatewaySettings } from '../types/common';
import { changeConnectionModalPropValues } from '../store/actionCreators';
import { getGatewayProperties } from '../utils/paymentProperties';

const ConnectionModal = ({
  activeGatewaySettings
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const propValues = useSelector(Selectors.getConnectionModalPropValues);

  const gatewayProperties = useMemo(() => {
    return getGatewayProperties(activeGatewaySettings.type);
  }, [activeGatewaySettings]);

  const getConnectionProps = () => {
    const connectionReferences = gatewayProperties && gatewayProperties.connectionReferences;
    return Object.keys(connectionReferences).reduce((prev, curr) => {
      const refObj = connectionReferences[curr];
      if (refObj && refObj.forConnectionModal) {
        return { ...prev, [curr]: { ...refObj } };
      }
      return prev;
    }, {});
  };

  useEffect(() => {
    // set initial values
    const connectionProps = getConnectionProps();
    const val = connectionProps ? Object.keys(connectionProps).reduce((prev, cur) => { return { ...prev, [cur]: '' }; }, {}) : {};
    dispatch(changeConnectionModalPropValues(val));

    return () => {
      dispatch(changeConnectionModalPropValues(null));
    };
  }, []);

  const renderConnectionReferences = useCallback(() => {
    const connectionProps = getConnectionProps();
    if (connectionProps) {
      const connectionInputs = Object.keys(connectionProps).map(item => {
        const refObj = connectionProps[item];
        return refObj && refObj.forConnectionModal && (
          <div className="connectionReferencesInputGroup">
            <label htmlFor="#">{refObj.label}</label>
            <TextInput
              id={item}
              className="connectionModalItem"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const val = e.target.value;
                dispatch(changeConnectionModalPropValues({ ...propValues, [item]: val }));
              }}
              defaultValue={activeGatewaySettings[item]}
            />
          </div>
        );
      });
      return (<div id="connectionInputContainer">{connectionInputs}</div>);
    }

    return null;
  }, [propValues, activeGatewaySettings]);

  return (
    <div className="connectionModalView">
      {renderConnectionReferences()}
    </div>
  );
};

interface IProps {
  activeGatewaySettings: IGatewaySettings
}

export default ConnectionModal;
