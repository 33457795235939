import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DonationBoxPropertiesPanel } from '@jotforminc/payment-settings-editor';
import { safeJSONParse } from '@jotforminc/utils';
import PropTypes from 'prop-types';
import SELECTORS from '../../../../../../store/selectors';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { isYes } from '../../../../../../utils';

const DonationBoxPropertiesPanelField = ({ onChange }) => {
  const dispatch = useDispatch();
  const selectedItemProps = useSelector(SELECTORS.getSelectedPortalItemWithInfo);
  const currencyInfo = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);

  const {
    id: itemID, donation: fixedAmountJSONString, showCustomAmount, enableCustomAmountLimits, showDonationGoal, donationGoal, minCustomAmountLimit, maxCustomAmountLimit
  } = selectedItemProps;

  const fixedAmounts = safeJSONParse(fixedAmountJSONString, []);

  const handleChange = donation => dispatch(ACTION_CREATORS.updateItemPropAction({ itemID, prop: { donation: JSON.stringify(donation) } }));

  const hasSameAmount = (newAmount, currentAmountID) => fixedAmounts.some(({ amount, id }) => parseFloat(amount) === parseFloat(newAmount) && id !== currentAmountID);

  const handleAmountAdd = (id, amount) => {
    if (!hasSameAmount(amount, id)) {
      handleChange([...fixedAmounts, { id, amount }]);
      dispatch(ACTION_CREATORS.trackEventAction({ action: 'donationBoxAddNewAmountClickedFromRightPanel' }));
    }
  };

  const handleAmountDelete = id => handleChange([...fixedAmounts.filter(({ id: amountID }) => amountID !== id)]);

  const handleAmountChange = (id, newAmount) => {
    const newAmountList = fixedAmounts.map(({ id: amountID, amount }) => ({ id: amountID, amount: amountID === id ? parseFloat(newAmount) : parseFloat(amount) }));
    if (hasSameAmount(newAmount, id)) {
      newAmountList.splice(newAmountList.findIndex(({ id: _id }) => _id === id), 1);
    }
    handleChange(newAmountList);
  };

  return (
    <DonationBoxPropertiesPanel
      fixedAmounts={fixedAmounts}
      currencyInfo={currencyInfo}
      onAddFixedAmount={handleAmountAdd}
      onChangeFixedAmount={handleAmountChange}
      onDeleteFixedAmount={handleAmountDelete}
      onPropChange={({ key, value }) => onChange({ [key]: value })}
      showCustomAmount={isYes(showCustomAmount)}
      enableCustomAmountLimits={isYes(enableCustomAmountLimits)}
      showDonationGoal={isYes(showDonationGoal)}
      donationGoal={donationGoal}
      minCustomAmountLimit={minCustomAmountLimit}
      maxCustomAmountLimit={maxCustomAmountLimit}
    />
  );
};

DonationBoxPropertiesPanelField.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default DonationBoxPropertiesPanelField;
