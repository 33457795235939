export const carouselBreakPoints = [
  {
    width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false
  },
  {
    width: 469, itemsToShow: 2, itemsToScroll: 2, pagination: false
  },
  {
    width: 850, itemsToShow: 3, itemsToScroll: 2, pagination: false
  }
];
