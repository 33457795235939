export const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY HH:mm';

export const SUBMISSION_DATE_FORMATS = [
  'M/D/YYYY',
  'D/M/YYYY',
  'MMM D, YYYY',
  'MMMM D, YYYY',
  'ddd, MMM D, YYYY',
  'dddd, MMMM D, YYYY',
  'MMM D, YYYY HH:mm',
  DEFAULT_DATE_FORMAT,
  'ddd, MMM D, YYYY HH:mm',
  'dddd, MMMM D, YYYY HH:mm'
];
