import {
  ADD_TO_STACK, REDO, RESET_STACK, UNDO
} from '../actionTypes';

const initialState = {
  stack: [], // LastInFirstOut!
  head: -1
};

const UndoRedoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_STACK:
      const { command } = action.payload;
      const newStack = [...state.stack, command];
      const newHead = newStack.length - 1; // Last in
      return {
        ...state,
        stack: newStack,
        head: newHead
      };
    case RESET_STACK:
      const currentHead = state.head;
      const clearedStack = [...state.stack.slice(0, currentHead + 1)];
      return {
        ...state,
        stack: clearedStack
      };
    case UNDO:
      return { ...state, head: state.head - 1 };
    case REDO:
      return { ...state, head: state.head + 1 };
    default:
      return state;
  }
};

export default UndoRedoReducer;
