export const parseDefinition = definition => {
  const [name, ...parseDefinitionArray] = definition.split('\n');
  const fieldsDefinition = parseDefinitionArray.map(p => {
    const [type, label] = p.split(':');
    return {
      type,
      label
    };
  });

  return {
    name,
    fieldsDefinition
  };
};

export const parseValue = value => {
  return value && value.split('\n').map(field => field.split('|').map(el => decodeURI(el)));
};

export const getItems = (value, fieldsDefinitions) => {
  const parsedValueArray = parseValue(value);
  if (!parsedValueArray) {
    return [];
  }

  return parsedValueArray.reduce((prevItems, item, itemIndex) => ([...prevItems, {
    id: itemIndex,
    fields: fieldsDefinitions.reduce((prev, field, index) => ([...prev, { ...field, value: item[index] }]), [])
  }]), []);
};

export const constructValue = items => {
  const itemFields = items.reduce((prev, item) => {
    return [...prev, item.fields];
  }, []);

  return itemFields.map(fields => (fields.map(field => (field.value ? encodeURI(field.value) : '')).join('|'))).join('\n');
};
