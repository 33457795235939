import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Braintree gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlBraintree: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_braintree',
  name: gatewayFieldToName.control_braintree,
  supportedCurrencies: gatewaySupportedCurrencies.control_braintree,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_braintree,
  sortByCountry: gatewayListSortedByCountry.control_braintree,

  /** APM's */
  isApm: false,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: true,
    mode: {
      name: 'sandbox',
      options: [
        { value: 'enabled', text: 'Test Mode', mappedValue: '1' },
        { value: 'Disabled', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    isSecure3DSupport: true,
    secure3DPropReferences: {
      activator: {
        propName: 'threeDSecure',
        propValue: 'Yes'
      },
      dependentProps: []
    },
    isChargeLaterSupport: true,
    chargeLaterPropReferences: {
      activator: {
        propName: 'authOnly',
        propValue: 'Yes'
      }
    },
    propReferences: {
      merchantId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Merchant ID' } },
      publicKey: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Public Key' } },
      privateKey: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Private Key' } },
      merchantAccountId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Merchant Account ID' } },
      threeDSecure: {
        isSecret: 'No',
        isRequired: 'No',
        type: 'toggle',
        inputLabels: {
          label: 'Enable 3D Secure 2.0',
          description: 'You must enable 3D Secure 2.0 in your Braintree Account as well.',
          learnMoreLink: 'https://articles.braintreepayments.com/guides/fraud-tools/3d-secure#confirm-setup'
        },
        toggleProps: {
          enabled: { value: 'Yes', text: 'ON' },
          disabled: { value: 'No', text: 'OFF' },
          defaultValue: 'No'
        }
      },
      sandbox: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['merchantId', 'publicKey', 'privateKey', 'merchantAccountId', 'threeDSecure']
  }
};
