import React, { useState, useMemo, useRef } from 'react';
import {
  elementType, string, func, oneOf, arrayOf, shape
} from 'prop-types';
import * as ColorPickerList from 'react-color';
import { isRGB, rgbToHex, isHexColor } from '@jotforminc/utils';

import Popover from '../Popover';
import TextInput from '../TextInput';
import { Color as DefaultColorRenderer, Wrapper as DefaultWrapperRenderer } from './defaults';

import { useEffectIgnoreFirst, useClickOutsideState } from '../../utils/hooks';

export const validateCSSColor = color => {
  const dummyElement = document.createElement('div');
  dummyElement.style.color = color;

  const validColor = dummyElement.style.color.split(/\s+/).join('').toLowerCase(); /* e.g.: rgb(255, 255, 255) */
  if (validColor && !isRGB(color) && isHexColor(color)) {
    return rgbToHex(validColor);
  }
  return validColor;
};

const defaultColorStringGenerator = ({ hex, rgb }) => (rgb.a === 1 ? hex : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`);

const ColorPicker = ({
  type, defaultValue, value, id, presetColors, popoverProps,
  ColorRenderer, WrapperRenderer, TextInputRenderer, ColorPickerWrapper,
  onChange, pickerProps, onInputRemove, colorStringGenerator, ariaLabel, isVisible
}) => {
  const containerRef = useRef(false);
  const textInputRef = useRef(false);
  const indicatorRef = useRef(false);
  const ColorPickerComponent = useMemo(() => ColorPickerList[`${type}Picker`], [type]);

  const [color, setColor] = useState(value || defaultValue);
  const [isColorPickerVisible, setColorPickerVisibility] = useClickOutsideState(false, [containerRef, textInputRef, indicatorRef]);
  const isPickerVisible = isVisible ?? isColorPickerVisible;

  useEffectIgnoreFirst(() => {
    setColor(value);
  }, [value]);

  const handleTextInputChange = ({ target: { value: inputValue } }) => {
    const trimmedValue = inputValue.trim();
    const validatedColorValue = validateCSSColor(trimmedValue);
    if (validatedColorValue || value === '') {
      onChange(validatedColorValue);
      setColor(validatedColorValue);
    }
    if (inputValue === '') {
      onInputRemove();
    }
  };

  const handleTextInputFocus = () => {
    setColorPickerVisibility(true);
  };

  const handleChangeComplete = pickedColor => {
    const currentColor = colorStringGenerator(pickedColor);
    setColor(currentColor);
    onChange(currentColor);
  };

  const toggleVisibility = () => {
    setColorPickerVisibility(!isColorPickerVisible);
  };

  return (
    <WrapperRenderer>
      <TextInputRenderer
        id={id}
        value={color}
        ref={textInputRef}
        onFocus={handleTextInputFocus}
        onChange={handleTextInputChange}
        role='textbox'
        aria-label={ariaLabel}
      />
      <ColorRenderer
        ref={indicatorRef} color={color} onClick={toggleVisibility}
        ariaLabel={ariaLabel}
      />
      {isPickerVisible && (
        <Popover ref={containerRef} {...popoverProps} targetRef={textInputRef}>
          <ColorPickerWrapper>
            <ColorPickerComponent
              color={color}
              presetColors={presetColors}
              onChangeComplete={handleChangeComplete}
              {...pickerProps}
            />
          </ColorPickerWrapper>
        </Popover>
      )}
    </WrapperRenderer>
  );
};

ColorPicker.propTypes = {
  type: oneOf(['Alpha', 'Block', 'Chrome', 'Circle', 'Compact', 'Custom', 'Github', 'Hue', 'Material', 'Photoshop', 'Sketch', 'Slider', 'Swatches', 'Twitter']),
  id: string,
  value: string,
  defaultValue: string,
  presetColors: arrayOf(string),
  popoverProps: shape(Popover.propTypes),
  ColorRenderer: elementType,
  WrapperRenderer: elementType,
  TextInputRenderer: elementType,
  ColorPickerWrapper: elementType,
  onChange: func,
  pickerProps: shape({}),
  onInputRemove: func,
  colorStringGenerator: func,
  ariaLabel: string,
  isVisible: oneOf([null, false, true])

};

ColorPicker.defaultProps = {
  type: 'Sketch',
  id: '',
  value: '',
  defaultValue: '',
  presetColors: [
    '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'rgba(255,255,255,0)'
  ],
  popoverProps: Popover.defaultProps,
  ColorRenderer: DefaultColorRenderer,
  WrapperRenderer: DefaultWrapperRenderer,
  ColorPickerWrapper: DefaultWrapperRenderer,
  TextInputRenderer: TextInput,
  onChange: f => f,
  pickerProps: {},
  onInputRemove: f => f,
  colorStringGenerator: defaultColorStringGenerator,
  ariaLabel: 'Color',
  isVisible: null
};

export default ColorPicker;
