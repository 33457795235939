import { Variables } from '@jotforminc/payment-settings-editor';
import * as ACTION_TYPES from '../actionTypes';

const initialState = {
  questions: {},
  paymentModalFlowType: 'ELEMENT_TO_GATEWAY',
  paymentModalFlowStep: 'select-gateway'
};

const CheckoutFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_PAYMENT_MODAL_FLOW_TYPE:
    case ACTION_TYPES.SET_PAYMENT_MODAL_FLOW_SELECTED_GATEWAY:
    case ACTION_TYPES.SET_PAYMENT_MODAL_FLOW_STEP: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ACTION_TYPES.FETCH_CHECKOUT_FORM_QUESTIONS.SUCCESS: {
      return { ...state, questions: action.payload };
    }
    case ACTION_TYPES.UPDATE_CHECKOUT_FORM_QUESTION.SUCCESS: {
      const questionID = action.payload.id;
      return {
        ...state,
        questions: {
          ...state.questions,
          [questionID]: {
            ...state.questions[questionID],
            ...action.payload.prop
          }
        }
      };
    }
    case ACTION_TYPES.CHECKOUT_FORM_SETTINGS_CHANGE.SUCCESS:
    case ACTION_TYPES.CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE.SUCCESS: {
      return {
        ...state,
        questions: {
          ...state.questions,
          [Variables.CHECKOUT_FORM_QUESTIONS.GATEWAY]: action.payload
        }
      };
    }
    case ACTION_TYPES.ADD_CHECKOUT_FORM_QUESTION.SUCCESS: {
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.qid]: action.payload
        }
      };
    }
    case ACTION_TYPES.REMOVE_CHECKOUT_FORM_QUESTION.REQUEST: {
      const { [action.payload]: _, ...questions } = state.questions;
      return {
        ...state,
        questions
      };
    }
    case ACTION_TYPES.SORT_CHECKOUT_FORM_QUESTION.SUCCESS: {
      return {
        ...state,
        questions: action.payload
      };
    }
    default:
      break;
  }
  return state;
};

export default CheckoutFormReducer;
