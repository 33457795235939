import React from 'react';
import { string } from 'prop-types';
import { propTypes } from '@jotforminc/constants';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { ScWrapper } from './styled/ScWrapper';
import { ScIconWrapper } from './styled/ScIconWrapper';
import { ScCard } from './styled/ScCard';
import { ScTitle } from './styled/ScTitle';
import { ScDescription } from './styled/ScDescription';

const ErrorScreen = ({
  title,
  description,
  IconRenderer,
  IconWrapper,
  TitleRenderer,
  DescriptionRenderer,
  Wrapper,
  CardRenderer,
  ButtonRenderer,
  testIDPrefix
}) => {
  return (
    <Wrapper>
      <CardRenderer>
        <IconWrapper>
          <IconRenderer />
        </IconWrapper>
        <TitleRenderer data-testid={`${testIDPrefix}-title`}>{title}</TitleRenderer>
        { description && (<DescriptionRenderer data-testid={`${testIDPrefix}-description`}>{description}</DescriptionRenderer>)}
        <ButtonRenderer />
      </CardRenderer>
    </Wrapper>
  );
};

ErrorScreen.propTypes = {
  title: string,
  description: string,
  Wrapper: propTypes.renderable,
  IconWrapper: propTypes.renderable,
  CardRenderer: propTypes.renderable,
  TitleRenderer: propTypes.renderable,
  DescriptionRenderer: propTypes.renderable,
  IconRenderer: propTypes.renderable,
  ButtonRenderer: propTypes.renderable,
  testIDPrefix: string
};

ErrorScreen.defaultProps = {
  title: '',
  description: '',
  Wrapper: ScWrapper,
  IconWrapper: ScIconWrapper,
  CardRenderer: ScCard,
  TitleRenderer: ScTitle,
  DescriptionRenderer: ScDescription,
  IconRenderer: () => <IconExclamationTriangleFilled />,
  ButtonRenderer: () => null,
  testIDPrefix: 'errorScreen'
};

export default ErrorScreen;
