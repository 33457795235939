import React, { createContext, useState, useContext } from 'react';

export const ErrorContext = createContext({});

export const withErrorProvider = Component => {
  // eslint-disable-next-line react/prop-types
  const ErrorProvider = ({ forwardedRef, ...props }) => {
    const [errorList, setErrorList] = useState({});
    const [warningList, setWarningList] = useState({});
    const [dirtyFields, setDirtyFields] = useState({});
    const setFieldError = (key, value) => {
      setErrorList({ ...errorList, [key]: value });
    };
    const setFieldWarned = (key, value) => {
      setWarningList({ ...warningList, [key]: value });
    };
    const setFieldDirty = key => {
      setDirtyFields({ ...dirtyFields, [key]: true });
    };
    const value = {
      errorList,
      warningList,
      dirtyFields,
      setErrorList,
      setWarningList,
      setFieldError,
      setFieldWarned,
      setFieldDirty
    };
    return (
      <ErrorContext.Provider value={value}>
        <Component ref={forwardedRef} {...props} />
      </ErrorContext.Provider>
    );
  };
  return React.forwardRef((props, ref) => {
    return <ErrorProvider {...props} forwardedRef={ref} />;
  });
};

export const useErrors = () => useContext(ErrorContext);
