import React from 'react';
import { func, string } from 'prop-types';

import { FontPicker as CommonFontPicker } from '@jotforminc/fontpicker';

import { ScFontPicker } from './scFontPicker';
import { fonts } from './fonts';
import { useAppDefaults } from '../../../../properties';

const FontPicker = ({
  propName, onChange, value
}) => {
  const { appFontFamily: defaultValue } = useAppDefaults();
  return (
    <ScFontPicker
      value={value}
    >
      <CommonFontPicker
        theme="portal"
        initialFont={value}
        defaultFont={defaultValue}
        onSelectFont={res => (propName ? onChange(propName)(res?.name) : onChange(res?.name))}
        fontsArr={fonts}
      />
    </ScFontPicker>
  );
};

FontPicker.propTypes = {
  propName: string,
  onChange: func,
  value: string
};

FontPicker.defaultProps = {
  propName: '',
  onChange: f => f,
  value: ''
};

export default FontPicker;
