import React from 'react';
import { t } from '@jotforminc/translation';

import { LAYOUT_TYPES } from '../../../../../../constants';

import DefualtLayout from '../../../../../../assets/icon_defaultLayout.svg';
import AllShrinkedLayout from '../../../../../../assets/icon_allShrinkedLayout.svg';
import NoIconLayout from '../../../../../../assets/icon_noIconLayout.svg';
import ShrinkedNoIconLayout from '../../../../../../assets/icon_shrinkedNoIconLayout.svg';
import AllFormsLayout from '../../../../../../assets/icon_allFormsLayout.svg';
// import MixedLayout from '../../../../../../assets/icon_mixedLayout.svg';
import PlainLayout from '../../../../../../assets/icon_plainLayout.svg';
import ShrinkedNoHeaderLayout from '../../../../../../assets/icon_shrinkedNoHeaderLayout.svg';
import LeftAlignedLayout from '../../../../../../assets/icon_leftAlignedLayout.svg';
import RightAlignedLayout from '../../../../../../assets/icon_rightAlignedLayout.svg';
import CenterAlignedLayout from '../../../../../../assets/icon_centerAlignedLayout.svg';
import LargeElementLayout from '../../../../../../assets/icon_largeElementLayout.svg';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';

export const getLayoutProps = () => ({
  [LAYOUT_TYPES.DEFAULT]: {
    title: t('Default'),
    icon: <DefualtLayout />
  },
  [LAYOUT_TYPES.ALL_SHRINKED]: {
    title: t('All Shrinked'),
    icon: <AllShrinkedLayout />
  },
  [LAYOUT_TYPES.NO_ICON]: {
    title: t('No Icon'),
    icon: <NoIconLayout />
  },
  [LAYOUT_TYPES.ALL_SHRINKED_NO_ICON]: {
    title: t('All Shrinked & No Icon'),
    icon: <ShrinkedNoIconLayout />
  },
  ...(isFeatureEnabled(FEATURE_NAMES.EmbeddedForm) ? {
    [LAYOUT_TYPES.SHOW_ALL_FORMS]: {
      title: t('Show All Forms'),
      icon: <AllFormsLayout />
    }
  } : {}),
  // [LAYOUT_TYPES.MIXED]: { // :(
  //   title: t('Mixed'),
  //   icon: <MixedLayout />
  // },
  [LAYOUT_TYPES.PLAIN]: {
    title: t('Plain'),
    icon: <PlainLayout />
  },
  [LAYOUT_TYPES.ALL_SHRINKED_NO_HEADER]: {
    title: t('All Shrinked & No Header'),
    icon: <ShrinkedNoHeaderLayout />
  },
  [LAYOUT_TYPES.LEFT_ALIGNED]: {
    title: t('Left Aligned'),
    icon: <LeftAlignedLayout />
  },
  [LAYOUT_TYPES.RIGHT_ALIGNED]: {
    title: t('Right Aligned'),
    icon: <RightAlignedLayout />
  },
  [LAYOUT_TYPES.CENTER_ALIGNED]: {
    title: t('Center Aligned'),
    icon: <CenterAlignedLayout />
  },
  [LAYOUT_TYPES.ALL_LARGE_ELEMENT]: {
    title: t('All Large Element'),
    icon: <LargeElementLayout />
  }
});
