import { ComponentPropsWithoutRef } from 'react';
import { ChoiceTruncate, Size, Theme } from '../../constants/common.types';

export type ChoiceLabelBaseProps = {
  className?: string,
  description: string,
  id: string,
  input: ComponentPropsWithoutRef<'input'>,
  label: string,
  truncate?: ChoiceTruncate,
  disabled?: boolean,
  theme?: Theme,
  size?: Size
}

export type ChoiceLabelProps = ChoiceLabelBaseProps;

export const choiceLabelDefaultProps:Partial<ChoiceLabelProps> = {
  description: '',
  label: '',
  disabled: false,
  size: 'medium',
  theme: 'light'
};
