import DimensionPicker from '../../DimensionPicker';
import TextArea from '../../TextArea';
import TextInput from '../../TextInput';
import ListItemStyler from './ListItemStyler';
import ToggleButtonGroup from '../../ToggleButtonGroup';
import { ColorPicker } from '../../ColorPicker';
import RadioToggle from '../../RadioToggle';
import RPImageUpload from './RPImageUpload';
import AppLogoPropertyPanel from './AppLogoPropertyPanel';
import FontPicker from '../../FontPicker';
import AppColorSchemes from '../../ColorPalette/AppColorSchemes';
import RightPanelDocumentFileUpload from '../Editors/RightPanelDocumentFileUpload';
import AppThemesPanel from '../../AppThemesPanel';
import FormLabel from '../../FormLabel';
import ImageUploadWithLoadingState from './ImageUploadWithLoadingState';
import BadgeToggle from './BadgeToggle';
import ButtonActions from './ButtonActions';
import Checkbox from '../../Checkbox';
import RolesComponents from './ButtonActions/RoleComponents';
import ItemIconToggle from './ItemIconToggle';
import AppFooter from './AppFooter';
import WidgetFileUpload from '../Editors/WidgetFileUpload';
import Dropdown from '../../../../../components/Dropdown';
import Schema from '../../Schema';
import ShowFormToggle from './ShowFormToggle';
import ShrinkToggle from './ShrinkToggle';
import ResourceSelection from './ResourceSelection';
import ExternalField from '../../ExternalField';
import AppLayoutsPanel from './AppLayoutsPanel';
import ButtonLinkInput from './ButtonActions/components/ButtonLinkInput';
import RangeSlider from '../../../../../components/RangeSlider';
import DuplicateItemButton from './DuplicateItemButton';
import MenuSettingsPanel from './MenuSettingsPanel';
import PageSettingsPanel from './PageSettingsPanel';
import ProductListProperties from './ProductListProperties';
import PaymentProperties from './PaymentProperties';
import FormSelection from './FormSelection';
import DonationPaymentProperties from './DonationPaymentProperties';
import DonationGatewayWarningItem from './DonationGatewayWarningItem';
import DonationBoxPropertiesPanelField from './DonationBoxPropertiesPanelField';
import ContactInformationSettingsPanel from './ContactInformationSettingsPanel';
import LoginRequiredToggle from './LoginRequireToggle';
import ListItemSideItemSelection from './ListItemSideItemSelection';
import SvgOptionRenderer from './SvgOptionRenderer';
import Roles from './ButtonActions/Roles';
import CardLayout from './CardLayout';
import DataSourcePicker from './DataSourcePicker';
import DsRoleComponents from './DataSourcePicker/DsRoleComponents';
import { PROPERTY_ITEM } from './constants';

export const PropertyItemMapper = {
  [PROPERTY_ITEM.TEXT_AREA]: TextArea,
  [PROPERTY_ITEM.TEXT_INPUT]: TextInput,
  [PROPERTY_ITEM.ITEM_STYLER]: ListItemStyler,
  [PROPERTY_ITEM.SELECTION]: ToggleButtonGroup,
  [PROPERTY_ITEM.COLOR_PICKER]: ColorPicker,
  [PROPERTY_ITEM.TOGGLE]: RadioToggle,
  [PROPERTY_ITEM.IMAGE_UPLOAD]: RPImageUpload,
  [PROPERTY_ITEM.APP_LOGO]: AppLogoPropertyPanel,
  [PROPERTY_ITEM.FONT_PICKER]: FontPicker,
  [PROPERTY_ITEM.COLOR_PALETTE]: AppColorSchemes,
  [PROPERTY_ITEM.DOCUMENT_FILE_UPLOAD]: RightPanelDocumentFileUpload,
  [PROPERTY_ITEM.THEMES]: AppThemesPanel,
  [PROPERTY_ITEM.FORM_LABEL]: FormLabel,
  [PROPERTY_ITEM.IMAGE_UPLOAD_WITH_LOADING]: ImageUploadWithLoadingState,
  [PROPERTY_ITEM.BADGE_TOGGLE]: BadgeToggle,
  [PROPERTY_ITEM.LOGIN_REQUIRED_TOGGLE]: LoginRequiredToggle,
  [PROPERTY_ITEM.BUTTON_ACTIONS]: ButtonActions,
  [PROPERTY_ITEM.CHECKBOX]: Checkbox,
  [PROPERTY_ITEM.BUTTON_ROLE_COMPONENTS]: RolesComponents,
  [PROPERTY_ITEM.ITEM_ICON_TOGGLE]: ItemIconToggle,
  [PROPERTY_ITEM.APP_FOOTER]: AppFooter,
  [PROPERTY_ITEM.DIMENSION_PICKER]: DimensionPicker,
  // for external widget items
  [PROPERTY_ITEM.WIDGET_FILE_UPLOAD]: WidgetFileUpload,
  [PROPERTY_ITEM.DROPDOWN]: Dropdown,
  [PROPERTY_ITEM.SCHEMA]: Schema,
  [PROPERTY_ITEM.SHOW_FORM_TOGGLE]: ShowFormToggle,
  [PROPERTY_ITEM.SHRINK_TOGGLE]: ShrinkToggle,
  [PROPERTY_ITEM.RESOURCE_SELECTION]: ResourceSelection,
  [PROPERTY_ITEM.FORM_SELECTION]: FormSelection,
  [PROPERTY_ITEM.EXTERNAL]: ExternalField,
  [PROPERTY_ITEM.LAYOUTS]: AppLayoutsPanel,
  [PROPERTY_ITEM.LINK_INPUT]: ButtonLinkInput,
  [PROPERTY_ITEM.RANGESLIDER]: RangeSlider,
  [PROPERTY_ITEM.DUPLICATION]: DuplicateItemButton,
  [PROPERTY_ITEM.MENU_SETTINGS]: MenuSettingsPanel,
  [PROPERTY_ITEM.PAGE_SETTINGS]: PageSettingsPanel,
  [PROPERTY_ITEM.PRODUCT_LIST_SETTINGS]: ProductListProperties,
  [PROPERTY_ITEM.PAYMENT_SETTINGS]: PaymentProperties,
  [PROPERTY_ITEM.DONATION_SETTINGS]: DonationPaymentProperties,
  [PROPERTY_ITEM.DONATION_GATEWAY_WARNING_ITEM]: DonationGatewayWarningItem,
  [PROPERTY_ITEM.DONATION_BOX_PROPERTIES_PANEL_FIELD]: DonationBoxPropertiesPanelField,
  [PROPERTY_ITEM.CONTACT_INFORMATION_SETTINGS]: ContactInformationSettingsPanel,
  [PROPERTY_ITEM.LIST_ITEM_START]: ListItemSideItemSelection,
  [PROPERTY_ITEM.LIST_ITEM_END]: ListItemSideItemSelection,
  [PROPERTY_ITEM.SVG_OPTIONS]: SvgOptionRenderer,
  [PROPERTY_ITEM.CARD_LAYOUT]: CardLayout,
  [PROPERTY_ITEM.ROLES]: Roles,
  [PROPERTY_ITEM.DATA_SOURCE_PICKER]: DataSourcePicker,
  [PROPERTY_ITEM.DS_ROLE_COMPONENTS]: DsRoleComponents
};
