import React from 'react';
import { t } from '@jotforminc/translation';

import { Button } from '@jotforminc/magnet';
import UnsplashIcon from '../assets/svg/unsplash_icon.svg';

export const DEFAULT_TAB_LIST = {
  upload: { label: 'UPLOAD' },
  choose: { label: 'MY IMAGES' },
  link: { label: 'ENTER URL' },
  icon: { label: 'SELECT ICON' },
  gallery: { label: 'GALLERY' },
  groupedGallery: { label: 'GALLERY' },
  unsplash: {
    label: (
      <div className="tabUnsplash">
        <UnsplashIcon />
        Unsplash
      </div>
    )
  }
};

export const THEME_MAP = {
  isDark: 'isDark',
  isLight: 'isLight',
  isTransparent: 'isTransparent'
};

/* eslint-disable */
export const DefaultDeleteButton = ({ onClick, disabled }) => {
  return (
    <Button colorStyle="error" className="text-uppercase" onClick={disabled ? () => {} : onClick} data-testid="deleteSelectedImage">
      {t('Delete Selected')}
    </Button>
  )
};

export const DefaultSelectButton = ({ onClick }) => (
  <Button className="mt-2 xs:mt-0 text-uppercase" onClick={onClick} data-testid="useSelectedImage">
    {t('Use Selected')}
  </Button>
)
/* eslint-enable */

export const modesRequireUploadedImages = ['imageUpload'];
