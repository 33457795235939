import Styled from 'styled-components';
import { mainFont } from '../../styles/global';

const ScToast = Styled.div`
  display: flex;
  align-items: center;
  line-height: 1.6;
  font-size: 14px;
  cursor: default;
  font-family: ${mainFont};
  padding: 16px;

  .icon {
    flex: 0 0 auto;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .context {
    flex: 1 1 100%;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .message {
      flex: 1 1 100%;
    }

    .description {
      flex: 1 1 100%;
      font-size: 12px;
      color: #6F76A7;
      display: block;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }
`;

export default ScToast;
