import React from 'react';
import { DotsLoader } from '@jotforminc/apploaders';
import Styled from 'styled-components';

const ScContainer = Styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PublicAppLoading = () => {
  return (
    <ScContainer>
      <DotsLoader />
    </ScContainer>
  );
};

export default PublicAppLoading;
