import type { T_OPERATORS } from '@jotforminc/payment-constants';
/**
 * Runs conditional operations
 * @returns {boolean}
 */

export const calculateOperation = (value: string, compareValue: string, operator: T_OPERATORS): boolean => {
  switch (operator) {
    case '===':
      return value === compareValue;
    case '!==':
      return value !== compareValue;
    case 'has':
      return value.includes(compareValue);
    default:
      return false;
  }
};
