import generateAsset from './index';
import { IMAGE_TYPE } from '../../../../constants';
/* eslint-disable */

export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const postToServer = formData => {
  return new Promise((resolve, reject) => {
    const request = new global.XMLHttpRequest();
    const targetURL = '/server.php';
    request.open('POST', targetURL);
    request.onreadystatechange = () => {
      if (request.status === 200) {
        if (request.readyState === 4) {
          try {
            const response = JSON.parse(request.responseText);
            const { message } = response;
            if (response.success !== true) {
              // handle error
            }
            const url = encodeURI(message);
            resolve(url);
          } catch (err) {
            reject();
            // handle error
          }
        }
      } else { // request not success
        // handle error
        reject();
      }
    };
    request.send(formData);
  });
};

const calculateViewBox = (logoURL, svgNode) => {
  const isColoredSVG = logoURL.includes('color/Colored/jfc_icon_color-colored');
  const viewBoxes = {
    colored: '-24 -24 100 100',
    colorless: '-8 -8 40 40',
    colored32: '-16 -16 64 64'
  };

  if (!isColoredSVG) {
      return viewBoxes.colorless;
  }
  const logoWidth = parseInt(svgNode.getAttribute('width'), 10);
  const logoHeight = parseInt(svgNode.getAttribute('height'), 10);
  let minX = 0;
  let minY = 0;

  if (logoWidth <= 0 || logoHeight <= 0 || Math.abs(logoWidth - logoHeight) < 3) {
      if (Math.abs(logoWidth - 32) <= 1) {
          return viewBoxes.colored32;
      }
      return viewBoxes.colored;
  }
  if (logoWidth > logoHeight) {
      minX = -16;
      minY = (-16 * logoHeight) / logoWidth;
  } else if (logoHeight > logoWidth) {
      minY = -16;
      minX = (-16 * logoWidth) / logoHeight;
  }
  const x = logoWidth * 2;
  const y = logoHeight * 2;
  return `${minX} ${minY} ${x} ${y}`;
};

const generateSVGString = (svgRef, appIconColor, appIconURL, width) => {
  const _svgRef = svgRef || appIconURL.match(/([^\/]+)(?=\.\w+$)/)[0];
  const path = document.querySelector(`symbol#${_svgRef}`).innerHTML;
  const svgNode = document.querySelector(`[*|href="#${_svgRef}"]`);
  const viewBox = calculateViewBox(appIconURL, svgNode);
  // please do not change below line
  return `<svg xmlns="http://www.w3.org/2000/svg" fill="${appIconColor}" viewBox="${viewBox}" width="${width}px" height="${width}px">
     ${path}
    </svg>
  `;
};

const prepareAssetProps = installableProps => {
  const {
    appIconType,
    appIconSvgRef,
    appIconBackground,
    appIconColor,
    appIconURL,
    width = 512,
    height = 512,
  } = installableProps;
  if (appIconType === IMAGE_TYPE.icon) {
    return {
      svg: generateSVGString(appIconSvgRef, appIconColor, appIconURL, width),
      bgColor: appIconBackground,
      mimetype: 'image/png',
      width,
      height,
      quality: 1,
      outputFormat: 'base64',
      assetType: 'svg'
    };
  } else if (appIconType === IMAGE_TYPE.image) {
    return {
      url: appIconURL,
      bgColor: appIconBackground, // or white ?
      mimetype: 'image/png',
      width,
      height,
      quality: 1,
      outputFormat: 'base64',
      assetType: 'image'
    };
  }
};

export const getAsset = async (props) => {
  const asset = await generateAsset(prepareAssetProps(props));
  return asset;
};
