export const ITEM_TYPES = {
  FORM: 'FORM',
  HEADING: 'HEADING',
  PARAGRAPH: 'PARAGRAPH',
  LINK: 'LINK',
  DOCUMENT: 'DOCUMENT',
  DONATION: 'DONATION',
  IMAGE: 'IMAGE',
  BUTTON: 'BUTTON',
  SHARE_BUTTON: 'SHARE_BUTTON',
  WIDGET: 'WIDGET',
  DIVIDER: 'DIVIDER',
  TABLE_LINK: 'TABLE_LINK',
  REPORT_LINK: 'REPORT_LINK',
  SENTBOX_LINK: 'SENTBOX_LINK',
  PRODUCT_LIST: 'PRODUCT_LIST',
  SIGN_LINK: 'SIGN_LINK',
  CONTACT_INFORMATION: 'CONTACT_INFORMATION',
  LIST: 'LIST',
  CARD_ITEM: 'CARD_ITEM',
  AGENT: 'AGENT'
};

export const DATA_SOURCE_ITEMS = [
  ITEM_TYPES.LIST
];

export const featurePermissionKeys = {
  [ITEM_TYPES.SIGN_LINK]: 'allowSign'
};

export const DOCUMENT_ITEM_TYPES = {
  IMG: 'Image',
  PDF: 'PDF',
  SHEET: 'Sheet',
  DOC: 'Doc',
  OTHER: 'File',
  PLACEHOLDER: 'Placeholder'
};

export const RESOURCE_TYPES = {
  FORM: 'FORM',
  SENTBOX: 'SENTBOX',
  TABLE: 'TABLE',
  REPORT: 'REPORT',
  SIGN: 'SIGN'
};

export const LINK_BEHAVIOR_ITEMS = [
  ITEM_TYPES.REPORT_LINK,
  ITEM_TYPES.IMAGE
];

export const PAGE_TYPE = 'PAGE';

export const ITEM_PROP_TYPE_VERSION = 2;

export const DATA_SOURCE_COLUMN_TYPES = {
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
  BOOLEAN: 'BOOLEAN'
};
