/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @jotforminc/no-native-button */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import {
  string, func, object, bool
} from 'prop-types';
import Loader from '../../../Loader';

import './style.scss';

const WorkflowTemplateAreaRenderer = ({
  modelContentLoading, frameRef, templatePreviewURL,
  setModelContentLoading, rightArrowRef, leftArrowRef, template
}) => {
  let formID;
  const { source_snapshot: sourceSnapshot = '{}' } = template;
  try {
    const { data } = JSON.parse(sourceSnapshot);
    try {
      const { properties: { id: parsedFormID } } = JSON.parse(data);
      formID = parsedFormID;
    } catch {
      //
    }
  } catch {
    //
  }
  const [activeTab, setActiveTab] = useState(0);

  const previewBtns = [
    { btnType: 'workflow', btnName: 'WORKFLOW' },
    { btnType: 'form', btnName: 'FORM PREVIEW' }
  ];

  const handleLoadObject = () => {
    setModelContentLoading(false);
    rightArrowRef.current.classList.remove('load-active');
    leftArrowRef.current.classList.remove('load-active');
  };

  return (
    <div className="modal-template-area relative z-1 md:min-h-sm md:max-h-sm duration-200 w-full h-120 lg:border border-navy-75 radius-none lg:radius-lg overflow-hidden">
      { modelContentLoading && <Loader /> }
      <div className='top-title w-full justify-center flex items-center absolute left-1/2 transform -translate-x-1/2 top-6 z-2'>
        <div
          className="top-title-container dir-ltr flex p-0.5 bg-navy-50 radius-full"
        >
          {
            previewBtns.map(({ btnType, btnName }, index) => (
              <button
                type='button'
                tabindex="0"
                data-type={btnType}
                data-trigger="changePreview"
                onClick={() => setActiveTab(index)}
                className={`item relative radius-full flex items-center justify-center px-2 py-1 min-w-28 border-0 bg-navy-50 ${activeTab === index ? 'bg-navy-900 color-white' : 'color-navy-300'}`}
              >
                <span class="text text-xs line-height-sm min-w-28 font-medium whitespace-nowrap">{btnName}</span>
              </button>
            ))
          }
        </div>
      </div>
      <object
        ref={frameRef}
        aria-label="Preview"
        type="text/html"
        id='workflowPreviewArea'
        data={templatePreviewURL}
        onLoad={handleLoadObject}
        className={`relative z-1 w-full h-full ${activeTab === 0 ? 'block' : 'hidden'}`}
      />
      <object
        ref={frameRef}
        aria-label="Preview"
        type="text/html"
        id='formPreviewArea'
        data={`/workflow-templates/preview/form/${formID}`}
        onLoad={handleLoadObject}
        className={`relative z-1 w-full h-full lg:min-h-sm lg:radius-md ${activeTab === 1 ? 'block' : 'hidden'}`}
      />
    </div>
  );
};

WorkflowTemplateAreaRenderer.defaultProps = {
  modelContentLoading: f => f,
  frameRef: null,
  templatePreviewURL: '',
  setModelContentLoading: f => f,
  rightArrowRef: null,
  leftArrowRef: null,
  template: {}
};

WorkflowTemplateAreaRenderer.propTypes = {
  modelContentLoading: bool,
  frameRef: object,
  templatePreviewURL: string,
  setModelContentLoading: func,
  rightArrowRef: object,
  leftArrowRef: object,
  template: object
};

export default WorkflowTemplateAreaRenderer;
