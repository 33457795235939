import React, { FunctionComponent, useState } from 'react';
import { IconPlus } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import AmountListItem from './AmountListItem';
import { ICurrency } from '../../../types/common';
import '@jotforminc/jotform.css';

type FixedAmountType = {
  id: string | number;
  amount: string;
  forceActive: boolean;
}

type FixedAmountsType = {
  fixedAmounts: [FixedAmountType],
  currencyInfo: ICurrency,
  onAddFixedAmount: (id: string | number, amount: string) => void,
  onChangeFixedAmount: (id: string | number, amount: string) => void,
  onDeleteFixedAmount: () => void
}

const FixedAmounts: FunctionComponent<FixedAmountsType> = ({
  fixedAmounts, currencyInfo, onAddFixedAmount, onChangeFixedAmount, onDeleteFixedAmount
}) => {
  const isAnyAmountActive = fixedAmounts.some(({ forceActive = false }) => forceActive);
  const [isAdding, setIsAdding] = useState(false);

  const handleAdd = (id: string | number, amount: string) => {
    setIsAdding(false);
    onAddFixedAmount(id, amount);
  };

  return (
    <div>
      <Button
        fullWidth
        className="mb-6 text-capitalize"
        startIcon={IconPlus}
        onClick={() => setIsAdding(true)}
        disabled={isAdding || isAnyAmountActive}
      >
        {t('Add New Amount')}
      </Button>
      <div className='flex flex-col gap-3'>
        {fixedAmounts.map(({ id, amount, forceActive = false }) => (
          <AmountListItem
            key={id}
            id={id}
            value={amount}
            onChange={onChangeFixedAmount}
            onDelete={onDeleteFixedAmount}
            forceActive={forceActive}
            currencyInfo={currencyInfo}
          />
        ))}
        {isAdding && (
          <AmountListItem
            id={Date.now().toString()}
            value=""
            onChange={handleAdd}
            onDelete={() => setIsAdding(false)}
            forceActive={true}
            currencyInfo={currencyInfo}
          />
        )}
      </div>
    </div>
  );
};

export default FixedAmounts;
