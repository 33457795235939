import * as actionTypes from '../actionTypes';
import {
  IUiReducer, TActiveEditor, TActiveTab, TActivePanel, TFormId, TGatewaySettingsView, IGatewaySettings, TGateway
} from '../../types/common';

const initialState: IInitialState = {
  formId: '',
  checkoutFormId: '',
  activePanel: 'sellProducts',
  activeEditor: '',
  activeTab: 'basic',
  isGatewaySettingsDisplayed: false,
  gatewaySettingsView: 'gateway-picker',
  temporaryActiveGatewaySettings: {},
  selectedOption: null,
  activeAPMSelection: '',
  selectedGatewayWithAPM: null,
  connectionModalPropValues: null
};

const uiReducer = (state: IUiReducer = initialState, action: IAction) : IUiReducer => {
  switch (action.type) {
    case actionTypes.INIT_UI:
      return { ...state, ...action.payload };
    case actionTypes.CHANGE_ACTIVE_EDITOR:
      return { ...state, activeEditor: action.payload.value };
    case actionTypes.CHANGE_ACTIVE_TAB:
      return { ...state, activeTab: action.payload.value };
    case actionTypes.CHANGE_ACTIVE_PANEL:
      return { ...state, activePanel: action.payload.value };
    case actionTypes.CHANGE_GATEWAY_SETTINGS_VISIBILIY:
      return { ...state, isGatewaySettingsDisplayed: action.payload.value };
    case actionTypes.CHANGE_GATEWAY_SETTINGS_VIEW:
      return { ...state, gatewaySettingsView: action.payload.value };
    case actionTypes.UPDATE_TEMPORARY_ACTIVE_GATEWAY_SETTINGS:
      return { ...state, temporaryActiveGatewaySettings: action.payload.value };
    case actionTypes.CHANGE_SELECTED_OPTION:
      return { ...state, selectedOption: action.payload.value };
    case actionTypes.CHANGE_ACTIVE_APM_SELECTION:
      return { ...state, activeAPMSelection: action.payload.value };
    case actionTypes.CHANGE_SELECTED_GATEWAY_WITH_APM:
      return { ...state, selectedGatewayWithAPM: action.payload.value };
    case actionTypes.CHANGE_CONNECTION_MODAL_PROP_VALUES:
      return { ...state, connectionModalPropValues: action.payload.value };
    default:
      return state;
  }
};

interface IInitialState {
  formId: TFormId,
  checkoutFormId: TFormId,
  activePanel: TActivePanel,
  activeEditor: TActiveEditor,
  activeTab: TActiveTab,
  isGatewaySettingsDisplayed: boolean,
  gatewaySettingsView: TGatewaySettingsView,
  temporaryActiveGatewaySettings: Partial<IGatewaySettings>,
  selectedOption: null,
  activeAPMSelection: string // TODO: apm types,
  selectedGatewayWithAPM: TGateway | null,
  connectionModalPropValues: Record<string, string> | null
}

interface IPayload {
  value: any
}

interface IAction {
  type: string,
  payload: IPayload
}

export default uiReducer;
