import { t } from '@jotforminc/translation';
import { elementType, func, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import Styled from 'styled-components';
import { IconArrowUpRightFromSquareSm } from '@jotforminc/svg-icons';
import TextInput from '../../../../TextInput';
import Button from '../../../../../../../components/Button';
import { checkAndAddProtocolToTargetLink, isValidLinkTarget } from '../../../../../../../utils';
import { branding21MainBlue, rightPanelItemBorderColor } from '../../../../../../../styles/colors';
import { applyAlpha } from '../../../../../../../utils/styleUtils';

const ScButtonLinkInput = Styled.div`
  position: relative;

  input { padding: 12px 48px 12px 16px; }

  & > a {
    background-color: var(--jfv-panel-right-item-background);
    position: absolute;
    height: 100%;
    width: 44px;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${rightPanelItemBorderColor};

    & > svg {
      display: block;
      width: 16px;
      color: #fff;
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px ${applyAlpha(branding21MainBlue, 0.3)};
  }

  .disabled {
    pointer-events: none;
    & > svg { color: #ffffff80; }
  }
`;

const ButtonLinkInput = ({
  value, defaultValue, onChange, placeholder, description, DescriptionRenderer
}) => {
  const isLinkValid = isValidLinkTarget(value);
  const [inputValue, setInputValue] = useState(value || defaultValue);

  const handleTextInputOnBlur = useCallback(() => {
    setInputValue(value || defaultValue);
  }, [value, setInputValue]);

  const linkHref = useMemo(() => {
    return checkAndAddProtocolToTargetLink(value);
  }, [value]);

  return (
    <>
      <ScButtonLinkInput>
        <TextInput
          value={inputValue}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={handleTextInputOnBlur}
        />
        <Button
          href={linkHref}
          className={`${!isLinkValid ? 'disabled' : ''}`}
          target="_blank"
          rel="noopener noreferrer"
          as="a"
          name={t('Open Link')}
          aria-label={t('Open Link')}
        >
          <IconArrowUpRightFromSquareSm />
        </Button>
      </ScButtonLinkInput>
      {description && DescriptionRenderer && <DescriptionRenderer description={description} />}
    </>
  );
};

ButtonLinkInput.propTypes = {
  value: string,
  onChange: func,
  defaultValue: string,
  placeholder: string,
  DescriptionRenderer: elementType,
  description: string
};

ButtonLinkInput.defaultProps = {
  value: '',
  onChange: f => f,
  defaultValue: '',
  placeholder: '',
  DescriptionRenderer: null,
  description: ''
};

export default ButtonLinkInput;
