import type { GATEWAY_LIST_CONNECTION_PROPS } from './types';
import {
  controlSquare,
  controlPaypalComplete,
  controlPaypal,
  controlPaypalInvoicing,
  controlPaypalPro,
  controlPaypalSPB,
  controlPaypalExpress,
  controlAuthnet,
  controlStripe,
  controlStripeCheckout,
  controlStripeACHManual,
  controlStripeACH,
  controlBraintree,
  controlCashApp,
  controlVenmo,
  controlApplePayGooglePay,
  controlMollie,
  controlCyberSource,
  controlEcheck,
  controlBluepay,
  controlEway,
  controlPayu,
  controlPayuMoney,
  controlWorldpay,
  controlWorldpayUS,
  controlBluesnap,
  controlMoneris,
  controlGocardless,
  controlPayfast,
  controlSensePass,
  controlSofort,
  controlPagseguro,
  controlFirstdata,
  controlCardconnect,
  control2Co,
  controlPaysafe,
  controlIyzico,
  controlSkrill,
  controlChargify,
  controlPayjunction,
  controlClickBank,
  controlOnebip,
  controlPaymentwall,
  controlPayment,
  controlAfterpay,
  controlClearpay
} from './gatewayConnectionProps';

/**
 * Represents a list of gateway connection properties.
 * @type {GATEWAY_LIST_CONNECTION_PROPS}
 */
export const supportedGatewayConnectionPropsList: GATEWAY_LIST_CONNECTION_PROPS = {
  control_square: controlSquare,
  control_paypalcomplete: controlPaypalComplete,
  control_paypal: controlPaypal,
  control_paypalInvoicing: controlPaypalInvoicing,
  control_paypalpro: controlPaypalPro,
  control_paypalSPB: controlPaypalSPB,
  control_paypalexpress: controlPaypalExpress,
  control_authnet: controlAuthnet,
  control_stripe: controlStripe,
  control_stripeCheckout: controlStripeCheckout,
  control_stripeACHManual: controlStripeACHManual,
  control_stripeACH: controlStripeACH,
  control_braintree: controlBraintree,
  control_cashApp: controlCashApp,
  control_afterpay: controlAfterpay,
  control_clearpay: controlClearpay,
  control_venmo: controlVenmo,
  control_applePayGooglePay: controlApplePayGooglePay,
  control_mollie: controlMollie,
  control_cybersource: controlCyberSource,
  control_echeck: controlEcheck,
  control_bluepay: controlBluepay,
  control_eway: controlEway,
  control_payu: controlPayu,
  control_payuMoney: controlPayuMoney,
  control_worldpay: controlWorldpay,
  control_worldpayus: controlWorldpayUS,
  control_bluesnap: controlBluesnap,
  control_moneris: controlMoneris,
  control_gocardless: controlGocardless,
  control_payfast: controlPayfast,
  control_sensepass: controlSensePass,
  control_sofort: controlSofort,
  control_pagseguro: controlPagseguro,
  control_firstdata: controlFirstdata,
  control_cardconnect: controlCardconnect,
  control_2co: control2Co,
  control_paysafe: controlPaysafe,
  control_iyzico: controlIyzico,
  control_skrill: controlSkrill,
  control_chargify: controlChargify,
  control_payjunction: controlPayjunction,
  control_clickbank: controlClickBank,
  control_onebip: controlOnebip,
  control_paymentwall: controlPaymentwall,
  control_payment: controlPayment
};
