import Styled from 'styled-components';

export const ScPageSettingsPopover = Styled.div`
  position: relative;
  padding: 8px 0;
  background-color: #252d5b;
  border-radius: 4px;
  min-width: 232px;

  .popover-settings-item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 8px 16px;
    width: 100%;
    color: #ffffff;
    background: transparent;
    justify-content: flex-start;
    border: none;
    outline: none;
    
    &:hover {
      background-color: #343c6a;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
      margin-left: 8px;
    }
    
    &.forDelete {
      color: #dc2626;
    }
  }

  .settings-item-divider {
    height: 1px;
    background-color: #343c6a;
    margin: 8px 16px;
    display: block;
    width: calc(100% - 32px);
  }
`;
