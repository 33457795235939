import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { capitalizeFirstLetter } from '@jotforminc/utils';
import { IconAngleLeft, IconCopyLine } from '@jotforminc/svg-icons';
import { getCategory } from './apiTemplates';
import { getDeviceType } from './getDeviceType';
import IconNoResult from './assets/icon-noresult.svg';

export const filterWrapper = (templateType, list, filters, index = 0) => {
  if (filters.length <= index) return list;
  const { property, value } = filters[index];
  return filterWrapper(templateType, list.filter(listItem => {
    if (templateType === 'form-templates' && listItem?.slug === 'salesforce-forms') return true;
    return listItem[property] === value;
  }), filters, index + 1);
};

export const addOrRemoveMultipleEventListeners = (listeners, handlerFunction, addOrRemove, target) => {
  if (target === null) return;
  listeners.forEach(listener => {
    if (addOrRemove === 'add') {
      target.addEventListener(listener, handlerFunction);
    } else {
      target.removeEventListener(listener, handlerFunction);
    }
  });
};

export const getInitialLanguage = isFormTemplates => {
  let result = 'en';
  const jfLang = window?.langCode;
  if (jfLang && jfLang !== '' && isFormTemplates) {
    result = jfLang.includes('-') ? jfLang.split('-')[0] : jfLang;
  }
  return result;
};

export const prettyTemplatePageType = (templateType, isPlural = true) => {
  const templateType_ = templateType === 'approval-templates' ? 'workflow-templates' : templateType;
  const [first, second] = templateType_.split('-');
  const prettyName = `${capitalizeFirstLetter(first)} ${capitalizeFirstLetter(second)}`;
  return isPlural ? prettyName : prettyName.substring(0, prettyName.length - 1);
};

export const categoryHasParent = category => category.parent !== '' && category.parent !== null && category.parent !== undefined;

export const getTemplateId = template => template?.id || template?._id;

export const getSubCategoryViewData = (selectedCategory, allCategories, setSubCategoryViewData, filters, language, templateType, source) => {
  if (!selectedCategory) return [];
  const subCategories = allCategories.filter(category => getTemplateId(category?.parent) === getTemplateId(selectedCategory));
  if (subCategories.length === 0) return [];
  setSubCategoryViewData({ subCategories: [], loading: true });
  return getCategory(filters, selectedCategory, language, templateType, source);
};

export const getNoResultContent = (isNoUserTemplates = false, handleClearResult, searchResult, templatePageTypePrettyPlural) => {
  return (
    <div className="noResult">
      <IconNoResult />
      <h4>{t('Oops, No Result Found')}</h4>
      {
        isNoUserTemplates ? (
          <p>{t('We could not find any user template for your current filtered language.')}</p>
        ) : (
          <>
            <p>
              {translationRenderer(`Sorry we could not find any results for [1[{keyword}]] in our ${templatePageTypePrettyPlural}.`)({
                renderer1: () => (
                  <b>
                    {`"${searchResult.searchedKeyword}"`}
                  </b>
                )
              })}
            </p>
            <button className="clearResult" type="button" onClick={handleClearResult}>
              {t('Clear Results')}
            </button>
          </>
        )
      }
    </div>
  );
};

export const getTemplateCountText = total => t('{categoryTotal} Templates').replace('{categoryTotal}', total);

export const getJfWizardWrapper = () => document.querySelector('.jfWizard');

export const scrollToTop = (isMobile, containerRef, isInline) => {
  const scrollPayload = { top: 0, behavior: 'smooth' };
  // eslint-disable-next-line no-nested-ternary
  const scrollElement = (isMobile || getDeviceType() !== 'desktop') ? containerRef.current : (isInline ? getJfWizardWrapper() : window);
  if (scrollElement !== null && scrollElement) {
    scrollElement.scrollTo(scrollPayload);
  }
};

const handleClickBackToHomeButton = (handleSelectCategory, username) => {
  window.standaloneTemplatesLogger({
    actor: username,
    action: `${getDeviceType()}:back-homepage`
  });
  handleSelectCategory();
};

export const categoryHeaderRenderer = props => {
  const {
    selectedCategory, handleSelectCategory, templatePageTypePrettyPlural, setActiveParentCategory,
    isAllCategoriesSelected, templatePageTypePretty, subCategoryViewData, activeParentCategory, username
  } = props;
  if (selectedCategory) {
    const totalCount = selectedCategory.total ? selectedCategory.total : subCategoryViewData.total;
    return (
      <div className="categoryHeader">
        <div className="breadcrumb">
          <button className="backButton" onClick={() => handleClickBackToHomeButton(handleSelectCategory, username)} type="button">
            <IconAngleLeft width="24" height="24" />
          </button>
          <span onClick={() => handleSelectCategory()} style={{ cursor: 'pointer' }}>
            {t(templatePageTypePrettyPlural)}
          </span>
          {(categoryHasParent(selectedCategory) || activeParentCategory) && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleSelectCategory({ category: (activeParentCategory || selectedCategory.parent) });
                setActiveParentCategory();
              }}
            >
                {t((activeParentCategory?.name || selectedCategory.parent.name))}
            </span>
          )}
          <span>{t(selectedCategory.name)}</span>
        </div>
        <h1 className="heading">{t(selectedCategory.pageTitle || selectedCategory.title || selectedCategory._title)}</h1>
        <div className="subheading">
          <IconCopyLine width="16" height="16" />
          {getTemplateCountText(totalCount || '0')}
        </div>
      </div>
    );
  }
  if (isAllCategoriesSelected) {
    return (
      <div className="categoryHeader">
        <h1 className="heading">{t(`${templatePageTypePretty} Categories`)}</h1>
        <div className="subBigHeading">{t(`You can find any ${templatePageTypePretty} Category that fits your needs.`)}</div>
      </div>
    );
  }
  return null;
};

export const allCategoriesRenderer = (categories, handleSelectCategory, setActiveParentCategory) => (
  categories.map(category => {
    const {
      description, total, name, _id
    } = category;
    return (
      <div
        key={_id}
        className="categoryBox"
        onClick={() => {
          handleSelectCategory({ category });
          setActiveParentCategory();
        }}
      >
        <h3 className="title">{t(name)}</h3>
        <span className="desc">{t(description)}</span>
        <span className="count">
          <IconCopyLine width="16" height="16" />
          {getTemplateCountText(total || 0)}
        </span>
      </div>
    );
  })
);

export const isDevelopmentEnv = () => window.JOTFORM_ENV === 'DEVELOPMENT';

export const handleSetupActionLogger = (projectName = 'newWizard') => {
  const { JotFormActions } = global;
  window.standaloneTemplatesLogger = (typeof JotFormActions === 'function' && !isDevelopmentEnv()) ? JotFormActions(projectName).tick : () => {};
};

export const isHomepage = (isMyTemplates, selectedCategory, isSearchView, isAllCategoriesSelected) => {
  if (isMyTemplates || selectedCategory || isSearchView || isAllCategoriesSelected) return false;
  return true;
};

export const getUser = () => global?.user || global?.__user || {};

export const isGuest = () => {
  const user = getUser();
  return user?.account_type?.name === 'GUEST';
};

export const getPreviewID = template => template.app_id || template.approval_id || template._id || template.id;

export const formatBigNumber = number => {
  if (number === 0) {
    return '0';
  }
  if (!number) {
    return '';
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getUsername = (usernameProp, user) => {
  if (typeof usernameProp === 'string' && usernameProp !== '') return usernameProp;
  if (typeof user === 'string' && user !== '') return user;

  if (typeof usernameProp === 'object' && usernameProp?.username) return usernameProp?.username;
  if (typeof user === 'object' && user?.username) return user?.username;

  return global?.user?.username || global?.__user?.username || '';
};

export const getUrlSearchParamByKey = ({
  key,
  options,
  defaultValue = null
}) => {
  try {
    const { location: { search = '' } } = global;
    const urlParams = new URLSearchParams(search);
    const result = urlParams.get(key);
    if (options && result && !options.includes(result)) return defaultValue;
    return result;
  } catch {
    return defaultValue;
  }
};

export const getTemplatePreviewURL = ({ template, templateType, previewURL }) => {
  const { id, _id } = template;
  const templateID = _id || id;

  switch (templateType) {
    case 'approval-templates':
      return `/workflow-templates/preview/workflow/${templateID}`;
    case 'agent-templates':
      const { agent_id: agentID } = template;
      return `/agent-directory/preview/agent/${agentID}?isTemplatePreview=1&skipWelcome=1`;
    default:
      return previewURL;
  }
};
