import React from 'react';
import { element, elementType } from 'prop-types';
import { IconCheck } from '@jotforminc/svg-icons';
import { ScBadge } from './ScBadge';

const Badge = ({
  icon,
  IconRenderer,
  ...rest
}) => {
  return (
    <ScBadge {...rest}>
      <div className="badge-content">
        <IconRenderer icon={icon} />
      </div>
    </ScBadge>
  );
};

Badge.propTypes = {
  icon: element,
  IconRenderer: elementType
};

Badge.defaultProps = {
  icon: <IconCheck color="#fff" />,
  /* eslint-disable react/prop-types */
  IconRenderer: ({ icon }) => <>{icon}</>
};

export default Badge;
