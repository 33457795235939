import { createSelector } from 'reselect';
import { useAppDefaults } from '../../properties';
import { getAppID } from './portalSelectors';

export const getAllApps = state => state.apps;
export const getApps = createSelector([getAllApps, getAppID], (apps = [], currentAppID) => apps.filter(app => app.id !== currentAppID));
export const getOtherAppByIDSelector = id => createSelector(getApps, apps => (apps ? (apps.find(({ id: appID }) => id === appID) || {}) : {}));
export const getOtherAppInfoWithDefaultsByID = appID => createSelector(getOtherAppByIDSelector(appID), app => {
  const { appVersion } = app;
  const defaultsByVersion = useAppDefaults(appVersion);
  return { ...defaultsByVersion, ...app };
});
