import React from 'react';
import { TextArea as UIKitTextArea } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { elementType, string } from 'prop-types';
import { ScTextArea } from './scTextArea';

const TextArea = ({
  placeholder, description, DescriptionRenderer, theme, ...props
}) => (
  <ScTextArea theme={theme}>
    <UIKitTextArea {...props} placeholder={t(placeholder)} className="descriptionTextArea" />
    {description && <DescriptionRenderer description={description} style={{ marginTop: 9 }} />}
  </ScTextArea>
);

TextArea.propTypes = {
  DescriptionRenderer: elementType,
  description: string,
  placeholder: string,
  theme: string
};
TextArea.defaultProps = {
  DescriptionRenderer: ({ children }) => <div>{children}</div>, // eslint-disable-line react/prop-types
  description: '',
  placeholder: 'Type a description',
  theme: 'dark'
};

export default TextArea;
