import { isEnterprise } from '@jotforminc/enterprise-utils';

import { openFormLimitDialog } from '@jotforminc/limit-dialog';
import { handleCustomNavigation, pushDataLayer } from '@jotforminc/utils';

import { layer } from '../../../utils';

const cloneApp = (id, newAppTitle, withForm, teamID) => {
  const headers = teamID ? { headers: { 'jf-team-id': teamID } } : {};
  return layer.post(`listings/portal/${id}/clone?cloneForms=${withForm ? '1' : '0'}`, {
    appProperties: {
      title: newAppTitle
    }
  }, headers);
};

export const handleCloneApp = ({
  id,
  appTitle,
  withForm,
  user: userData,
  portalRoot,
  formCount = 0,
  actionLogger,
  teamID,
  logJotformAction
}) => {
  if (withForm) {
    const user = userData?.credentials || userData;
    const isFormLimitReached = window.userFormCountLimit;

    const currentUsage = user?.usage?.form_count || 0;
    const userLimit = user?.account_type?.limits?.formCount || 5;
    const willFormLimitBeReached = parseInt(formCount, 10) + parseInt(currentUsage, 10) > parseInt(userLimit, 10);
    const showFormLimitDialog = isFormLimitReached || willFormLimitBeReached;

    if (showFormLimitDialog && !isEnterprise()) {
      openFormLimitDialog({ user, portalRoot, dispatchedFrom: 'listings-cloneApp' });
      return;
    }
  }

  return cloneApp(id, appTitle, withForm, teamID).then(res => {
    const {
      id: newID
    } = res;

    if (actionLogger) {
      actionLogger({
        action: `portalCloned${withForm ? 'withForms' : 'withoutForms'}`,
        target: id
      });

      // Also track for portal-app
      // Pls don't change the action obj.
      const portalAppsAction = { action: 'appCreated', target: JSON.stringify({ portalID: newID }) };
      actionLogger(portalAppsAction, 'portal-app');
    }

    if (logJotformAction) {
      logJotformAction({ action: 'appCreated', target: { portalID: newID, from: 'create_app_from_clone_existing_app' } });
    }

    pushDataLayer({ event: 'create_app_clone_existing_app' });
    handleCustomNavigation(`/app/build/${newID}`, '_self');
  });
};
