import { APP_PREVIEW_STATES, LIVE_PREVIEW_SIZE_MODES } from '../../constants';
import { SET_LIVE_PREVIEW_STATUS, SET_LIVE_PREVIEW_SIZE_MODE } from '../actionTypes';

const initialState = {
  livePreviewSizeMode: LIVE_PREVIEW_SIZE_MODES.FIT_HEIGHT,
  livePreviewStatus: APP_PREVIEW_STATES.IDLE
};

const LivePreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIVE_PREVIEW_SIZE_MODE: {
      return {
        ...state,
        livePreviewSizeMode: action.payload
      };
    }
    case SET_LIVE_PREVIEW_STATUS: {
      return {
        ...state,
        livePreviewStatus: action.payload
      };
    }
    default:
      break;
  }
  return state;
};

export default LivePreviewReducer;
