import moment from 'moment-timezone';
import { SUBMISSION_DATE_FORMATS } from '@jotforminc/constants';

const localeSymbol = Symbol('locale');
const timezoneSymbol = Symbol('timezone');
const callbackSymbol = Symbol('callback');

class Moment {
  constructor() {
    this[localeSymbol] = 'en';
    this[timezoneSymbol] = null;
    this[callbackSymbol] = {
      setLocale: {},
      setTimezone: {}
    };
  }

  get locale() {
    return this[localeSymbol];
  }

  get timezone() {
    return this[timezoneSymbol];
  }

  get callback() {
    return this[callbackSymbol];
  }

  get formatList() {
    const formatListObject = moment.localeData(this.locale)._longDateFormat;
    return formatListObject && Object.keys(formatListObject).length > 0 ? [
      formatListObject.LLLL,
      formatListObject.LLL,
      formatListObject.LL,
      formatListObject.L
    ] : SUBMISSION_DATE_FORMATS;
  }

  setLocale(locale = 'en') {
    this[localeSymbol] = locale;
    Object.values(this.callback.setLocale).forEach(callback => callback());
  }

  setTimezone(timezone) {
    this[timezoneSymbol] = timezone;

    Object.values(this.callback.setTimezone).forEach(callback => callback());
  }

  dateToMoment(date, dateFormat) {
    const dateObject = moment(date, dateFormat);
    const dateObjectWithTimezone = this.timezone ? dateObject.clone().tz(this.timezone) : dateObject.clone().utcOffset(-5);
    return dateObjectWithTimezone.locale(this.locale);
  }

  formatDate(date, dateFormat, intendedFormat) {
    return this.dateToMoment(date, dateFormat).format(intendedFormat);
  }

  addListener(methodName, identifier, callback) {
    this.callback[methodName][identifier] = callback;
  }

  removeListener(methodName, identifier) {
    delete this.callback[methodName][identifier];
  }
}

export default new Moment();
