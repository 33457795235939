/* eslint-disable @jotforminc/no-native-button */
import React, { useEffect, useState } from 'react';
import { IconLockFilled } from '@jotforminc/svg-icons';
import { classNames } from '@jotforminc/utils';

/** context */
import { useGatewayConnection } from '../../../context/useGatewayConnection';

/** Styles */
import '../assets/style.css';

/**
 * A customizable toggle switch component for binary options.
 *
 * This component renders a toggle switch that allows users to choose between two options.
 * It supports locked state, custom styling, and integrates with the gateway connection context.
 *
 * @param {Object} props - The component props
 * @param {Array<{value: string, text: string}>} props.values - Array of possible values and their display texts
 * @param {string} props.defaultSelectedValue - The initially selected value
 * @param {string} props.enabledValue - The value considered as "enabled" or "on"
 * @param {string} props.inputName - Unique identifier for the input
 * @param {boolean} props.isLocked - Whether the toggle is locked (non-interactive)
 * @param {function} props.onChangeValue - Callback function when the value changes
 * @returns {React.ReactElement} The rendered toggle switch
 */
export function ToggleOnOff({
  values,
  defaultSelectedValue,
  enabledValue,
  inputName,
  isLocked,
  onChangeValue
}: {
  values: ReadonlyArray<{ value: string; text: string }>;
  defaultSelectedValue: string;
  enabledValue: string;
  inputName: string;
  isLocked: boolean;
  onChangeValue: (value: string) => void;
}): any {
  const { connectionInputValues } = useGatewayConnection();
  const [selectedValue, setSelectedValue] = useState<string>(defaultSelectedValue);

  useEffect(() => {
    if (!Object.keys(connectionInputValues).includes(inputName)) {
      onChangeValue(defaultSelectedValue);
    }
  }, [connectionInputValues, defaultSelectedValue, inputName, onChangeValue]);

  useEffect(() => {
    setSelectedValue(defaultSelectedValue);
  }, [defaultSelectedValue]);

  return (
    <div
      className={classNames([
        'bg-white border py-1 border-navy-100 radius h-8 flex justify-center items-center customSwitchButtons',
        selectedValue === enabledValue ? 'bg-blue-500' : 'bg-navy-200',
        isLocked && 'cursor-not-allowed bg-opacity-40'
      ])}
      onClick={() => {
        if (isLocked) {
          return;
        }

        if (selectedValue === enabledValue) {
          const getDisabledValue = values.find(value => value.value !== enabledValue);
          setSelectedValue(getDisabledValue?.value || '');
          onChangeValue(getDisabledValue?.value || '');
        } else {
          setSelectedValue(enabledValue);
          onChangeValue(enabledValue);
        }
      }}
      data-test-id={inputName}
    >
      {values.map(data => (
        <span
          key={`toggleBtn_${data.value}`}
          className={classNames([
            'line-height-md font-normal tracking-lg text-sm py-1 px-1 hover:bg-transparent focus:outline-transparent outline-0 color-white cursor-pointer',
            'flex justify-center items-center'
          ])}
          style={{
            height: '32px', fontWeight: 400, lineHeight: '20px', minWidth: '32px'
          }}
        >
          {selectedValue !== data.value ? (
            <>
              <svg
                width="24" height="25" viewBox="0 0 24 25"
                fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5" y="1" width="23"
                  height="23" rx="3.5" fill="white"
                  stroke="#C8CEED"
                />
                <path d="M8 6V19M12 19V6M16 6V19" stroke="#C8CEED" strokeWidth="1.5" />
              </svg>

            </>
          ) : (
            <span className='flex items-center justify-center'>
              {isLocked ? <IconLockFilled className='w-5 h-5' /> : data.text}
            </span>
          )}
        </span>
      ))}

    </div>
  );
}
