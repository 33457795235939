import { t } from '@jotforminc/translation';
import {
  IconMobileGear, IconMobileTitle, IconMobilePencil, IconMobileBell
} from '@jotforminc/svg-icons';
import { isFeatureEnabled } from '../../../../utils/features/helper';
import { FEATURE_NAMES } from '../../../../constants/features';

export const settingsNavPaths = {
  appName: 'appName',
  splash: 'splash',
  details: 'details',
  pushNotification: 'pushNotification'
};

export const getSettingsNavigationProperties = () => ([
  {
    key: 'appSettings',
    title: t('App Settings'),
    description: t('App status and properties.'), // TODO :: maybe we can update this sentence in a more meaningful way?
    path: settingsNavPaths.details,
    Icon: IconMobileGear
  },
  {
    key: 'appName&title',
    title: t('App Name & Icon'),
    description: t('Customize app name and icon.'),
    path: settingsNavPaths.appName,
    Icon: IconMobileTitle
  },
  {
    key: 'splashScreen',
    title: t('Splash Screen'),
    description: t('Customize splash screen.'),
    path: settingsNavPaths.splash,
    Icon: IconMobilePencil
  },
  {
    key: 'pushNotification',
    title: t('Push Notifications'),
    description: t('Send push notifications.'),
    path: settingsNavPaths.pushNotification,
    Icon: IconMobileBell,
    visibility: isFeatureEnabled(FEATURE_NAMES.PushNotification),
    badgeText: t('NEW'),
    badgeColor: 'apps'
  }
]);

export const settingsDefaultActiveTab = settingsNavPaths.details;

export const APP_STATUS_VALUES = { ENABLED: 'ENABLED', DISABLED: 'DISABLED', DISABLE_ON_DATE: 'DISABLE_ON_DATE' };
export const APP_DISABLE_OPTIONS = { DISABLE_ON_DATE: 'disableOnDate', redirectToApp: 'redirectToApp' }; // can be extended
export const OTHER_DISABLE_OPTIONS = { redirectedApp: 'redirectedApp', disableDateTimezone: 'disableDateTimezone', disableDate: 'disableDate' };

export const APP_STATUS_TEXTS = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  DISABLE_ON_DATE: 'Disable on a date'
};

export const getAppStatusOptions = () => ([
  { text: t(APP_STATUS_TEXTS[APP_STATUS_VALUES.ENABLED]), value: APP_STATUS_VALUES.ENABLED },
  { text: t(APP_STATUS_TEXTS[APP_STATUS_VALUES.DISABLED]), value: APP_STATUS_VALUES.DISABLED },
  { text: t(APP_STATUS_TEXTS[APP_STATUS_VALUES.DISABLE_ON_DATE]), value: APP_STATUS_VALUES.DISABLE_ON_DATE }
]);

export const disabledStatusOptions = [
  {
    key: 'showMessage',
    label: t('Show Message'),
    description: t('Set a message for app which are expired or limited.'),
    property: 'showDisabledMessage',
    showDisabledTextMessage: true
  },
  {
    key: 'redirect2AnotherApp',
    label: t('Redirect to Another App'),
    description: t('Send users to another app.'),
    property: 'redirectToApp',
    showRedirectedApp: true
  }
];

export const PUSH_NOTIFICATION_STATUS = {
  PENDING: 'pending',
  SENT: 'sent'
};

export const getDefaultPermissionRequestTexts = () => ({
  title: t('Stay Updated!'),
  content: t('Allow app notifications to get the latest news, updates, and exclusive offers delivered directly to your device.')
});
