import { Dispatch } from 'redux';
import { KeyboardEvent } from 'react';
import get from 'lodash/get';
import PaymentProperties from '../../../constants/PaymentProperties';
import {
  IAllActions,
  IGatewaySettings,
  TFormId,
  ValueOf
} from '../../../types/common';
import { updateGatewaySettingsActionHelper } from '../../../utils/actionUtils';
import { loadScriptSync } from '../../../utils/general';

const keyValueNameMap = {
  control_stripeCheckout: [{
    key: 'stripeUserId',
    value: 'stripe_user_id'
  }],
  control_stripe: [{
    key: 'publishableKey',
    value: 'stripe_publishable_key'
  }],
  control_square: [{
    key: 'merchantId',
    value: 'merchant_id'
  }, {
    key: 'tokenExpiry',
    value: 'expires_at'
  }, {
    key: 'location',
    value: 'location'
  }, {
    key: 'currency',
    value: 'currency'
  }],
  control_paypalcomplete: [{
    key: 'merchantId',
    value: 'merchantId'
  },
  {
    key: 'isOnlyConnectedToSPB',
    value: 'isOnlyConnectedToSPB'
  }],
  control_paypalInvoicing: [{
    key: 'merchantId',
    value: 'merchantId'
  },
  {
    key: 'merchantEmail',
    value: 'merchantEmail'
  }],
  control_mollie: [{
    key: 'profileId',
    value: 'profileId'
  }]
} as const;

type KeyValueNameMapType = typeof keyValueNameMap;

type SettingType = keyof KeyValueNameMapType;

type KeyValuePairType = ValueOf<KeyValueNameMapType>[number];

type MessageProperyType = KeyValuePairType['value'];
type MessageType = Partial<{
  [key in MessageProperyType]: string;
}>

type GetUpdatingSettingFromResponseType = Partial<{
  [key in KeyValuePairType['key']]: string;
}>

export const getUpdatingSettingsFromResponse = (message: MessageType, settingType: SettingType): GetUpdatingSettingFromResponseType => {
  const relatedPairs = keyValueNameMap[settingType];
  const settingsToBeUpdated: GetUpdatingSettingFromResponseType = {};
  // what should happen if one value of the multiple settings returns empty string?
  let hasError = false;
  relatedPairs.forEach(pair => {
    const { key, value }: { key: typeof pair.key, value: typeof pair.value} = pair;
    const { [value]: settingValue } = message;
    if (key && settingValue) {
      settingsToBeUpdated[key] = settingValue;
      return;
    }
    hasError = true;
  });
  if (hasError) return {};
  return settingsToBeUpdated;
};

type TdisconnectCurrentGatewayProps = {
  settings: IGatewaySettings,
  actions: IAllActions,
  checkoutFormId: TFormId,
  dispatch: Dispatch
}

export const disconnectCurrentGateway = ({
  settings,
  actions,
  checkoutFormId,
  dispatch
}: TdisconnectCurrentGatewayProps): void => {
  const { type: gateway } = settings;
  const currentGatewayProperties = PaymentProperties[gateway];
  const { connectionReferences } = currentGatewayProperties;

  const updatingSettings = Object.keys(connectionReferences).reduce((prev, setting) => {
    let emptyValue = '';
    if (setting === 'allowTest') {
      const currentGatewayBasicElementList = currentGatewayProperties.basicElementList(settings, actions);
      const { allowTest: { componentProps: { valueFalse: correctedAllowTestValue = '' } = {} } = {} } = currentGatewayBasicElementList;
      emptyValue = correctedAllowTestValue;
    }
    return {
      ...prev,
      [setting]: emptyValue
    };
  }, {});

  updateGatewaySettingsActionHelper({
    checkoutFormId,
    activeGatewaySettings: settings,
    updatingSettings,
    dispatch
  });
};

export const loadoAuthPaypalScripts = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    loadScriptSync('https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js', 'paypal-js').then(() => {
      let miniBrowser = null;
      // After the load of partner-js script, PayPal is loading two more scripts. We need to wait them as well.
      const signupLoadInterval = setInterval(() => {
        miniBrowser = window;
        get(window, 'PAYPAL.apps.Signup.MiniBrowser');
        if (miniBrowser && Object.keys(miniBrowser).length > 1) {
          clearTimeout(signupLoadInterval);
          resolve();
        }
      }, 1000);

      setTimeout(() => {
        clearTimeout(signupLoadInterval);
        resolve();
      }, 10000);
    }).catch(err => {
      reject(err);
    });
  });
};

export const getoAuthPaypalEnvironment = (settings: IGatewaySettings): string => {
  let env;

  switch (settings.type) {
    case 'control_paypalSPB':
      env = settings.environment;
      break;
    case 'control_paypalcomplete':
    case 'control_paypalInvoicing':
      env = settings.sandbox === 'enabled' ? 'sandbox' : 'production';
      break;
    default:
      break;
  }

  return env;
};

export const isThereASeparator = (e: KeyboardEvent, inputPrice: string, separator = '.'): boolean => {
  return (e.key === separator && inputPrice.indexOf(separator) !== -1);
};

export const getRawPrice = (val: string): number => Number(val.replace(/[^0-9\.-]+/g, '')); // eslint-disable-line
