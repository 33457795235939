import React from 'react';
import { func } from 'prop-types';
import { IconExclamationCircle } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { ScNotificationWrapper } from './ScClonePreventedFormsNotification';

const ClonePreventedFormsNotification = ({ onClick }) => {
  return (
    <>
      <ScNotificationWrapper>
        <div className='preventedForms-icon-container'>
          <IconExclamationCircle />
        </div>
        <div className='preventedForms-text-container'>
          <p className='preventedForms-title'>{t('Some forms couldn’t be cloned')}</p>
          <p className='preventedForms-subtitle'>{t('Since the app owner restricted cloning for some forms, these forms may be missing from your app.')}</p>
        </div>
        <button
          type='button'
          onClick={onClick}
          className='preventedForms-dismiss-button'
        >
          {t('Dismiss')}
        </button>
      </ScNotificationWrapper>
    </>
  );
};

ClonePreventedFormsNotification.propTypes = {
  onClick: func
};

ClonePreventedFormsNotification.defaultProps = {
  onClick: f => f
};

export default ClonePreventedFormsNotification;
