import { t } from '@jotforminc/translation';
import { useItemDefaults } from '../../../properties';
import { ITEM_TYPES } from '../../../constants/itemTypes';

const {
  itemFontColor,
  itemBorderColor,
  itemBgColor
} = useItemDefaults(ITEM_TYPES.FORM);

export const COLOR_PALETTE = [
  {
    itemFontColor: itemFontColor,
    itemBorderColor: itemBorderColor,
    itemBgColor: itemBgColor,
    isDefault: true
  },
  {
    itemFontColor: '#00718D',
    itemBorderColor: '#00718D',
    itemBgColor: '#C5EBF4'
  },
  {
    itemFontColor: '#6A1C00',
    itemBorderColor: '#6A1C00',
    itemBgColor: '#FFF1C5'
  },
  {
    itemFontColor: '#321F16',
    itemBorderColor: '#321F16',
    itemBgColor: '#B7A696'
  },
  {
    itemFontColor: '#576739',
    itemBorderColor: '#576739',
    itemBgColor: '#D5EEAB'
  },
  {
    itemFontColor: '#FFFFFF',
    itemBorderColor: '#11111B',
    itemBgColor: '#424757'
  },
  {
    itemFontColor: '#972D54',
    itemBorderColor: '#972D54',
    itemBgColor: '#FFF8FA'
  },
  {
    itemFontColor: '#12458D',
    itemBorderColor: '#12458D',
    itemBgColor: '#AACCFB'
  },
  {
    itemFontColor: '#321860',
    itemBorderColor: '#321860',
    itemBgColor: '#C8B2EE'
  },
  {
    itemFontColor: '#9B557C',
    itemBorderColor: '#9B557C',
    itemBgColor: '#DFB4CC'
  }
];

export const getTabMenuList = () => (
  {
    Palette: {
      label: t('Color styles')
    },
    Custom: {
      label: t('Customize')
    }
  }
);

export const COLOR_PALETTE_KEY_LIST = {
  color: 'itemFontColor',
  backgroundColor: 'itemBgColor',
  borderColor: 'itemBorderColor'
};

export const DESIGNER_COLOR_SCHEME_KEY_LIST = {
  borderColor: 'appCoverBgColor',
  backgroundColor: 'appBgColor',
  fontColor: 'designatedItemFontColor'
};

export const DESIGNER_COLOR_PALETTE = [
  {
    appCoverBgColor: '#8093c8',
    appCoverBgColorEnd: '#8093c8',
    appBgColor: '#f6f6fa',
    appBgColorEnd: '#f6f6fa',
    designatedItemFontColor: '#2c3345',
    appHeaderBgColor: '#ffffff',
    appFontColor: '#2c3345',
    listBgColor: '#ffffff',
    isDefault: true
  },
  {
    appCoverBgColor: '#83d0e4',
    appCoverBgColorEnd: '#83d0e4',
    appBgColor: '#f1fcff',
    appBgColorEnd: '#f1fcff',
    designatedItemFontColor: '#00718d',
    appHeaderBgColor: '#00718d',
    appFontColor: '#ffffff',
    listBgColor: '#83d0e4'
  },
  {
    appCoverBgColor: '#fb7041',
    appCoverBgColorEnd: '#fb7041',
    appBgColor: '#ffdf79',
    appBgColorEnd: '#ffdf79',
    designatedItemFontColor: '#6a1c00',
    appHeaderBgColor: '#ffffff',
    appFontColor: '#6a1c00',
    listBgColor: '#ffffff'
  },
  {
    appCoverBgColor: '#5a4b45',
    appCoverBgColorEnd: '#5a4b45',
    appBgColor: '#efe8d4',
    appBgColorEnd: '#efe8d4',
    designatedItemFontColor: '#321f16',
    appHeaderBgColor: '#321f16',
    appFontColor: '#ffffff',
    listBgColor: '#5a4b45'
  },
  {
    appCoverBgColor: '#b3d37b',
    appCoverBgColorEnd: '#b3d37b',
    appBgColor: '#f4ffdf',
    appBgColorEnd: '#f4ffdf',
    designatedItemFontColor: '#576739',
    appHeaderBgColor: '#576739',
    appFontColor: '#ffffff',
    listBgColor: '#ffffff'
  },
  {
    appCoverBgColor: '#11111b',
    appCoverBgColorEnd: '#11111b',
    appBgColor: '#424757',
    appBgColorEnd: '#424757',
    designatedItemFontColor: '#11111b',
    appHeaderBgColor: '#ffffff',
    appFontColor: '#11111b',
    listBgColor: '#ffffff'
  },
  {
    appCoverBgColor: '#ff98bc',
    appCoverBgColorEnd: '#ff98bc',
    appBgColor: '#fff8fa',
    appBgColorEnd: '#fff8fa',
    designatedItemFontColor: '#972d54',
    appHeaderBgColor: '#972d54',
    appFontColor: '#ffffff',
    listBgColor: '#ffffff'
  },
  {
    appCoverBgColor: '#2462ba',
    appCoverBgColorEnd: '#2462ba',
    appBgColor: '#e5efff',
    appBgColorEnd: '#e5efff',
    designatedItemFontColor: '#12458d',
    appHeaderBgColor: '#12458d',
    appFontColor: '#ffffff',
    listBgColor: '#ffffff'

  },
  {
    appCoverBgColor: '#7c57bd',
    appCoverBgColorEnd: '#7c57bd',
    appBgColor: '#f7f2ff',
    appBgColorEnd: '#f7f2ff',
    designatedItemFontColor: '#412a69',
    appHeaderBgColor: '#412a69',
    appFontColor: '#ffffff',
    listBgColor: '#412a69'
  }
];

export const NEW_DESIGNER_COLOR_PALETTE = [
  {
    appCoverBgColor: '#8093C8',
    appCoverBgColorEnd: '#8093C8',
    appBgColor: '#F6F6FA',
    appBgColorEnd: '#F6F6FA',
    designatedItemFontColor: '#2C3345',
    appHeaderBgColor: '#FFFFFF',
    appFontColor: '#000000',
    listBgColor: '#FFFFFF',
    logoBackground: '#F6F6FA',
    iconColor: '#8093C8',
    // Splash should change if app is generated from form
    splashBgColor: '#F38632',
    appIconBackground: '#F6F6FA',
    appIconColor: '#8093C8',
    isDefault: true
  },
  {
    appCoverBgColor: '#83D0E4',
    appCoverBgColorEnd: '#83D0E4',
    appBgColor: '#00718D',
    appBgColorEnd: '#00718D',
    designatedItemFontColor: '#00718D',
    appHeaderBgColor: '#F1FCFF',
    appFontColor: '#00718D',
    listBgColor: '#F1FCFF',
    logoBackground: '#00718D',
    iconColor: '#83D0E4',
    splashBgColor: '#83D0E4',
    appIconBackground: '#00718D',
    appIconColor: '#83D0E4'
  },
  {
    appCoverBgColor: '#FB7041',
    appCoverBgColorEnd: '#FB7041',
    appBgColor: '#FFDF79',
    appBgColorEnd: '#FFDF79',
    designatedItemFontColor: '#6A1C00',
    appHeaderBgColor: '#FFFFFF',
    appFontColor: '#6A1C00',
    listBgColor: '#FFFFFF',
    logoBackground: '#FB7041',
    iconColor: '#FFDF79',
    splashBgColor: '#FFDF79',
    appIconBackground: '#FB7041',
    appIconColor: '#FFDF79'
  },
  {
    appCoverBgColor: '#5A4B45',
    appCoverBgColorEnd: '#5A4B45',
    appBgColor: '#321F16',
    appBgColorEnd: '#321F16',
    designatedItemFontColor: '#321F16',
    appHeaderBgColor: '#EFE8D4',
    appFontColor: '#321F16',
    listBgColor: '#EFE8D4',
    logoBackground: '#321F16',
    iconColor: '#EFE8D4',
    splashBgColor: '#5A4B45',
    appIconBackground: '#321F16',
    appIconColor: '#EFE8D4'
  },
  {
    appCoverBgColor: '#B3D37B',
    appCoverBgColorEnd: '#B3D37B',
    appBgColor: '#576739',
    appBgColorEnd: '#576739',
    designatedItemFontColor: '#576739',
    appHeaderBgColor: '#F4FFDF',
    appFontColor: '#576739',
    listBgColor: '#F4FFDF',
    logoBackground: '#B3D37B',
    iconColor: '#576739',
    splashBgColor: '#576739',
    appIconBackground: '#B3D37B',
    appIconColor: '#576739'
  },
  {
    appCoverBgColor: '#11111B',
    appCoverBgColorEnd: '#11111B',
    appBgColor: '#424757',
    appBgColorEnd: '#424757',
    designatedItemFontColor: '#FFFFFF',
    appHeaderBgColor: '#6E7487',
    appFontColor: '#FFFFFF',
    listBgColor: '#11111B',
    logoBackground: '#11111B',
    iconColor: '#FFFFFF',
    splashBgColor: '#424757',
    appIconBackground: '#11111B',
    appIconColor: '#FFFFFF'
  },
  {
    appCoverBgColor: '#FF98BC',
    appCoverBgColorEnd: '#FF98BC',
    appBgColor: '#972D54',
    appBgColorEnd: '#972D54',
    designatedItemFontColor: '#972D54',
    appHeaderBgColor: '#FFF8FA',
    appFontColor: '#972D54',
    listBgColor: '#FFFFFF',
    logoBackground: '#FF98BC',
    iconColor: '#FFFFFF',
    splashBgColor: '#972D54',
    appIconBackground: '#FF98BC',
    appIconColor: '#FFFFFF'
  },
  {
    appCoverBgColor: '#2462BA',
    appCoverBgColorEnd: '#2462BA',
    appBgColor: '#12458D',
    appBgColorEnd: '#12458D',
    designatedItemFontColor: '#12458D',
    appHeaderBgColor: '#E5EFFF',
    appFontColor: '#12458D',
    listBgColor: '#E5EFFF',
    logoBackground: '#2462BA',
    iconColor: '#E5EFFF',
    splashBgColor: '#12458D',
    appIconBackground: '#2462BA',
    appIconColor: '#E5EFFF'

  },
  {
    appCoverBgColor: '#7C57BD',
    appCoverBgColorEnd: '#7C57BD',
    appBgColor: '#412A69',
    appBgColorEnd: '#412A69',
    designatedItemFontColor: '#321860',
    appHeaderBgColor: '#F7F2FF',
    appFontColor: '#412A69',
    listBgColor: '#F7F2FF',
    logoBackground: '#7C57BD',
    iconColor: '#F7F2FF',
    splashBgColor: '#412A69',
    appIconBackground: '#7C57BD',
    appIconColor: '#F7F2FF'
  }
];

export const DEFAULT_CUSTOM_PALETTE_PROPS = { // order is important!
  itemBgColor: {
    title: 'Background Color'
  },
  itemBorderColor: {
    title: 'Border Color',
    placement: 'bottom-end',
    offsetX: 39
  },
  itemFontColor: {
    title: 'Text Color'
  }
};

export const DEFAULT_CUSTOM_DONATION_PALETTE_PROPS = {
  itemButtonBgColor: {
    title: t('Background Color')
  },
  itemButtonFontColor: {
    title: t('Text Color')
  }
};
