import { ITEM_TYPES } from '../../../constants/itemTypes';
import Button from './Button';
import Card from './Card';
import Image from './Image';
import { buttonActions, cardActions, imageActions } from './actions';

const Items = {
  [ITEM_TYPES.BUTTON]: {
    class: Button,
    actions: buttonActions
  },
  [ITEM_TYPES.CARD_ITEM]: {
    class: Card,
    actions: cardActions
  },
  [ITEM_TYPES.IMAGE]: {
    class: Image,
    actions: imageActions
  }
};

export default Items;
