import { safeJSONParse } from '@jotforminc/utils';
import * as ACTION_TYPES from '../actionTypes';

const initialState = {
  products: {},
  cartProducts: {},
  activeProduct: '',
  filteredProducts: {},
  favoriteProducts: {},
  checkoutKey: '',
  isChangedPriceInCart: false
};

const Products = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ACTIVE_PRODUCT: {
      const { itemID, productID } = action.payload;
      const activeProduct = itemID ? { itemID, productID } : {};
      return {
        ...state,
        activeProduct
      };
    }
    case ACTION_TYPES.FILTER_PRODUCTS: {
      return {
        ...state,
        filteredProducts: action.payload
      };
    }
    case ACTION_TYPES.SET_CART_PRODUCTS: {
      return {
        ...state,
        cartProducts: action.payload
      };
    }
    case ACTION_TYPES.SET_FAVORITE_PRODUCTS: {
      return {
        ...state,
        favoriteProducts: action.payload
      };
    }
    case ACTION_TYPES.SET_CHECKOUT_KEY: {
      return {
        ...state,
        checkoutKey: action.payload
      };
    }
    case ACTION_TYPES.SET_IS_CHANGED_PRICE_IN_CART: {
      return {
        ...state,
        isChangedPriceInCart: action.payload
      };
    }
    case ACTION_TYPES.FETCH_STORE_PROPERTIES.SUCCESS: {
      const { checkoutKey = '' } = action.payload;

      const cartProducts = safeJSONParse(action.payload.cartProducts);
      const favoriteProducts = safeJSONParse(action.payload.favoriteProducts);
      return {
        ...state,
        cartProducts,
        checkoutKey,
        favoriteProducts
      };
    }
    default:
      break;
  }
  return state;
};

export default Products;
