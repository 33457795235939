import {
  SET_DONE_COUNT, SET_TODO_COUNT, SET_IS_APP_DONE, SET_LAST_ANIMATED_DONE_COUNT
} from '../actionTypes';

const initialState = {
  todoCount: 0,
  doneCount: 0,
  isAppDone: false,
  lastAnimatedDoneCount: null
};

const ProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TODO_COUNT:
      return {
        ...state,
        todoCount: action.payload
      };
    case SET_DONE_COUNT:
      return {
        ...state,
        doneCount: action.payload
      };
    case SET_IS_APP_DONE:
      return {
        ...state,
        isAppDone: action.payload
      };
    case SET_LAST_ANIMATED_DONE_COUNT:
      return {
        ...state,
        lastAnimatedDoneCount: action.payload
      };
    default:
      return state;
  }
};

export default ProgressReducer;
