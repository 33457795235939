import React, {
  useCallback, useMemo, useState
} from 'react';
import { Modal, Rating } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import {
  Button,
  FormControl,
  FormLabel,
  InputText,
  Textarea,
  Flex
} from '@jotforminc/magnet';
import { func, object, string } from 'prop-types';
import { IconStarFilled, IconXmark } from '@jotforminc/svg-icons';
import { ScPortalFeedbackModal } from './ScPortalFeedbackModal';
import DialogRenderer from './DialogRenderer';
import ContentRenderer from './ContentRenderer';
import { isGuestUser } from '../../../../../store/utils';

const PortalFeedbackModal = ({
  onCancel, onSend, user, feedbackInfo
}) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [stars, setStars] = useState();
  const [feedbackEmail, setFeedbackEmail] = useState(null);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const isGuest = isGuestUser(user);
  const { featureName, formID } = feedbackInfo;
  const handleHideButtonClick = useCallback(() => {
    setFeedbackText('');
    setFeedbackEmail(null);
    setStars();
    setFeedbackSent(false);
    onCancel();
  }, []);

  const handleSendFeedback = useCallback(() => {
    onSend({
      stars, feedbackText, feedbackEmail, formID
    });
    setFeedbackSent(true);
  }, [feedbackText, stars, feedbackEmail]);

  const handlleTextareaValueChange = value => {
    setFeedbackText(value);
    if (value.length === 0) {
      setFeedbackEmail(null);
    }
  };

  const HeaderRenderer = () => {
    return feedbackSent
      ? null
      : (
        <div className="jPortalModal-header w-full color-navy-700 text-xl font-medium">
          <div className="jPortalModal-headerContent mt-2 color-current text-15 font-normal">
            <h1 className="jPortalModal-title">{t('Give Feedback')}</h1>
            <h2 className="jPortalModal-subTitle">{t('Help us provide you with a better experience.')}</h2>
          </div>
          <Button
            onClick={handleHideButtonClick}
            startIcon={IconXmark}
            colorStyle='secondary'
            rounded
            className="absolute top-6 right-6 z-2"
          />
        </div>
      );
  };

  // eslint-disable-next-line no-unused-vars
  const FooterRenderer = () => (
    <div className={`px-7 py-3 flex items-center bg-navy-25 ${feedbackSent ? 'justify-end' : 'justify-between'}`}>
      {
        feedbackSent
          ? <Button colorStyle='success' onClick={handleHideButtonClick}>{t('Done')}</Button>
          : (
            <>
              <Button
                colorStyle='secondary'
                onClick={handleHideButtonClick}
              >
                {t('Cancel')}
              </Button>
              <Button
                colorStyle='success'
                disabled={!feedbackText && !stars}
                onClick={handleSendFeedback}
              >
                {t('Send')}
              </Button>
            </>
          )
      }
    </div>
  );

  // eslint-disable-next-line react/prop-types
  const ContainerRenderer = ({ children, onMouseLeave }) => {
    return (
      <div className="jPortalModal-ratingContainer" onMouseLeave={onMouseLeave}>{children}</div>
    );
  };

  const ItemRenderer = ({
    // eslint-disable-next-line react/prop-types
    index, covered, hovered, onChange, onMouseEnter
  }) => {
    const handleMouseEnter = useCallback(() => onMouseEnter(index), [index]);
    return (
      <div
        className="jPortalModal-rating" onClick={() => onChange(index)} onMouseEnter={handleMouseEnter}
      >
        {(covered || hovered) ? <IconStarFilled color="#FFB629" /> : <IconStarFilled color="#C8CEED" />}
      </div>
    );
  };

  const feedbackTextLabel = useMemo(() => {
    switch (true) {
      case stars < 3:
        return "We're sorry to hear that. What can we do to improve your experience?";
      case stars > 3:
        return `Great! What did you like most about the ${featureName}?`; // App Builder?
      default:
        return 'What can we do to improve your experience?';
    }
  }, [stars]);

  return (
    <ScPortalFeedbackModal>
      <Modal
        defaultVisible={true}
        HeaderRenderer={HeaderRenderer}
        FooterRenderer={FooterRenderer}
        DialogRenderer={DialogRenderer}
        ContentRenderer={ContentRenderer}
        onModalClose={handleHideButtonClick}
      >
        <div className={`jPortalModal-body ${feedbackSent ? 'vertical' : ''}`}>
          {!feedbackSent
            ? (
              <Flex direction='column' className='gap-2'>
                <FormControl id="feedback-rating">
                  <FormLabel>{t(`How would you rate your experience with ${featureName}?`)}</FormLabel>
                  <Rating
                    ContainerRenderer={ContainerRenderer} ItemRenderer={ItemRenderer} scale={5}
                    value={stars} onChange={newRating => setStars(newRating)}
                  />
                </FormControl>

                <FormControl id="feedback-textarea">
                  <FormLabel>{t(feedbackTextLabel)}</FormLabel>
                  <Textarea
                    className="h-40"
                    placeholder={t('Type here...')}
                    value={feedbackText}
                    onChange={e => handlleTextareaValueChange(e.target.value)}
                  />
                </FormControl>

                {isGuest && feedbackText.length > 0 && (
                  <FormControl id="feedback-email">
                    <FormLabel description={t('You can enter your email address so that we can get back to you.')}>{`${t('Email')} (${t('optional')})`}</FormLabel>
                    <InputText
                      type='email'
                      value={feedbackEmail}
                      onChange={e => setFeedbackEmail(e.target.value)}
                    />
                  </FormControl>
                )}
              </Flex>
            )
            : (
              <>
                <img
                  src="https://cdn.jotfor.ms/img/check-icon.png"
                  alt="Thank You"
                  className="jPortalModal-thankyouImg"
                  style={{ width: 64 }}
                />
                <p className="jPortalModal-highlight">{t('Thank You!')}</p>
                <p>{t('Your feedback has been received!')}</p>
              </>
            )}
        </div>
      </Modal>
    </ScPortalFeedbackModal>

  );
};

PortalFeedbackModal.propTypes = {
  onCancel: func.isRequired,
  onSend: func.isRequired,
  user: object.isRequired,
  feedbackInfo: {
    featureName: string,
    formID: string
  }
};

export default PortalFeedbackModal;
