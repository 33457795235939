import React, { useState } from 'react';
import { IconQuestionCircleFilled, IconCopyFilled, IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';

interface ICopyURL {
  steps: string[];
  url: string;
  name: string;
}

const CopyURL: React.FunctionComponent<ICopyURL> = ({
  name,
  url,
  steps
}) => {
  const [copied, setCopied] = useState(false);
  const [stepsOpen, setStepsOpen] = useState(false);
  const hostName = isEnterprise() ? window.location.hostname : 'ipn.jotform.com';
  const value = `${hostName}${url}`;
  const prefix = 'https://';

  const onCopy = () => {
    window.navigator.clipboard.writeText(`${prefix}${value}`);
    setCopied(true);

    const timeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeout);
    }, 1000);
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-end mb-2 relative">
        {
          stepsOpen && (
            <div className="absolute w-full bg-gray-800 px-4 py-3 transform -translate-y-6 z-9 radius-lg shadow-dark-lg ipn-url-info">
              <div className="flex flex-row items-center justify-between mb-1">
                <span className="text-sm line-height-md font-bold">
                  {t('How to Set Your IPN URL')}
                </span>
                <IconXmark
                  onClick={() => setStepsOpen(false)}
                  onKeyDown={() => setStepsOpen(false)}
                  className="w-5 h-5 cursor-pointer"
                />
              </div>
              <span className="inline-block mb-2 color-gray-200 text-sm font-normal line-height-md">
                {`${t('Follow these steps to prevent submissions that have pending payments')}:`}
              </span>
              <div className='bg-gray-700 radius p-2'>
                <ul className='text-sm line-height-md font-normal color-gray-100'>
                  {steps.map((step, i) => {
                    return (
                      <li>
                        <span className='w-4 inline-block'>{`${i + 1}.`}</span>
                        <span>{t(step)}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )
        }
        <span className="text-sm line-height-md color-white font-bold">{t('IPN URL')}</span>
        <div
          tabIndex={0}
          role="button"
          aria-label={t('How to set your IPN URL')}
          className="color-blue-500 flex flex-row items-center cursor-pointer"
          onClick={() => setStepsOpen(true)}
          onKeyDown={() => setStepsOpen(true)}
        >
          <IconQuestionCircleFilled className="w-3 h-3" />
          <span
            className="text-xs font-medium px-0.5"
          >
            {t('How to set your IPN URL')}
          </span>
        </div>
      </div>
      <div className="flex flex-row items-center radius">
        <div className="h-10 p-3 flex items-center bg-gray-400 radius-l">
          <span className='color-gray-300 text-sm line-height-xs'>{prefix}</span>
        </div>
        <input
          readOnly
          type="text"
          id={name}
          value={value}
          className='payment-copyurl-input w-full h-10 py-3 pl-3 color-white bg-gray-500 border-0 text-sm line-height-xs'
        />
        <div className='h-10 px-3 flex items-center justify-center bg-gray-500 radius-r'>
          <IconCopyFilled onClick={onCopy} className="cursor-pointer w-5 h-5 color-gray-300" />
          {
            copied && <span className='ml-2'>{t('Copied!')}</span>
          }
        </div>
      </div>
    </div>
  );
};

export default CopyURL;
