export default class DefaultsCacheSingleton {
  constructor() {
    if (!DefaultsCacheSingleton.instance) {
      this.cache = {};
      DefaultsCacheSingleton.instance = this;
    }

    return DefaultsCacheSingleton.instance;
  }

  generateKey(...args) {
    return args.join('_');
  }

  get(key) {
    return this.cache[key];
  }

  set(key, value) {
    this.cache[key] = value;
  }
}
