import React from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { classNames } from '@jotforminc/utils';

import { IconArrowLeft } from '@jotforminc/svg-icons';
import texts from '../../core/texts';
import Button from '.';

export default class BackButton extends React.Component {
  render() {
    const {
      className, label, ...props
    } = this.props;
    return (
      <Button
        className={classNames('isRounded', 'forBack', className)}
        label={label}
        data-testid="backButton"
        {...props}
      >
        <IconArrowLeft className="backButton-svg" />
        <span className="backButton-text">{t('back')}</span>
      </Button>
    );
  }
}

BackButton.propTypes = {
  className: string,
  label: string
};

BackButton.defaultProps = {
  className: null,
  label: texts.BACK
};
