/* eslint-disable complexity */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  Button, FormControl, InputText, Badge
} from '@jotforminc/magnet';
import {
  IconPlugFilled,
  IconCheck,
  IconExclamationCircleFilled,
  IconCircleSmFilled,
  IconInfoCircleFilled,
  IconCheckCircleFilled
} from '@jotforminc/svg-icons';

/** Data */
import { gatewayDefaultIcons, supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';

/** Context */
import { useGatewayConnection } from '../../../context';

/** Utils */
import { getGatewayConnectionPropReferences, getGatewayErrorMessage } from '../../../utils/functions';

/** Components */
import { gatewayModeInformation } from '../core/gatewayModeInformation';
import { CommonLoginFLow } from '../core/commonLoginFlow';
import { RenderCredentialInputs } from '../core/renderCredentialInputs';

/** Styles */
import '../assets/style.css';

const UnPlugIcon = () => (
  <svg
    width="20" height="20" viewBox="0 0 24 24"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.5C6 1.67158 6.67157 1 7.5 1C8.32843 1 9 1.67158 9 2.5V5.5C9 5.77614 8.77614 6 8.5 6H6.5C6.22386 6 6 5.77614 6 5.5V2.5ZM15 2.5C15 1.67158 15.6716 1 16.5 1C17.3284 1 18 1.67158 18 2.5V3.9649C17.877 4.03199 17.7614 4.11757 17.6574 4.22165L15.8789 6H15.5C15.2239 6 15 5.77614 15 5.5V2.5ZM18.7073 8H20C20.5523 8 21 8.44772 21 9V10C21 10.5523 20.5523 11 20 11H19V13C19 13.9193 18.8189 14.8295 18.4672 15.6788C18.1154 16.5281 17.5998 17.2997 16.9497 17.9497C16.2997 18.5998 15.5281 19.1154 14.6788 19.4672C14.1393 19.6906 13.5753 19.8452 13 19.9282V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V19.9282C10.4247 19.8452 9.86065 19.6906 9.32122 19.4672C8.84654 19.2705 8.39611 19.0227 7.97762 18.7289L6.28253 20.4239C5.89199 20.8144 5.2588 20.8144 4.86827 20.4239C4.47773 20.0334 4.47773 19.4002 4.86827 19.0097L19.0109 4.86811C19.4015 4.4776 20.0347 4.4776 20.4252 4.86811C20.8157 5.25862 20.8157 5.89176 20.4252 6.28227L18.7073 8ZM5.53284 15.6788C5.59711 15.8339 5.66685 15.9865 5.74188 16.1362L13.8787 8H4C3.44772 8 3 8.44772 3 9V10C3 10.5523 3.44772 11 4 11H5V13C5 13.9193 5.18106 14.8295 5.53284 15.6788Z"
      fill="#343C6A"
    />
  </svg>
);

/**
 * Renders a gateway connection component for managing gateway connections.
 *
 * This component displays the details of a gateway connection, including its name, status, and connection mode.
 * It also includes input fields for the connection name, credential inputs, and various buttons for managing the connection.
 *
 * @returns {React.ReactElement} The rendered gateway connection component
 */
export function GatewayConnection(): any {
  const {
    currentlyGatewayProperties,
    selectedChildGatewayType,
    connectionMode,
    connectionName,
    connectionState,
    connectionError,
    connectionErrorTypes,
    connectionErrorInputErrors,
    connectionInvalidCredentialMessages,
    removeInputError,
    backStep,
    connectionButtonHandler,
    changeConnectionName,
    changeConnectionMode,
    checkUniqueConnectionNameOnBlur,
    showOrHideDiscardNewConnectionDialog,
    changeDiscardedConnectionMode,
    user
  } = useGatewayConnection();

  const [isValidConnectionName, setIsValidConnectionName] = useState<boolean>(true);
  const [isOpenLoginFlow, setIsOpenLoginFlow] = useState<boolean>(false);

  if (!currentlyGatewayProperties) {
    backStep();
    return null;
  }

  const {
    name, connection, gateway_type: Gateway, isApm, isChildTypes, childTypes, parentType
  } = currentlyGatewayProperties;
  const getConnectionProps = getGatewayConnectionPropReferences({ gateway: Gateway, includeSandboxProps: false });

  /** Default Connection Data */
  let connectionProperties = connection;
  const isChildGateway = isApm && childTypes && childTypes.length > 0 && selectedChildGatewayType;

  if (isChildGateway) {
    connectionProperties = supportedGatewayConnectionPropsList[selectedChildGatewayType].connection;
  }

  /**
   * Returns the error title based on the connection error and error types.
   * @returns The error title as a string, or null if there is no error.
   */
  const getErrorTitle = (): string | null => {
    if (connectionError && connectionErrorTypes) {
      if (connectionErrorTypes.length === 1 && connectionErrorTypes.includes('rateLimiter')) {
        return t('Action Blocked');
      }

      if (connectionErrorTypes.length > 1) {
        return translationRenderer('There are [1[{count}]] errors with this connection!')({
          renderer1: () => connectionErrorTypes.length
        }) as string;
      }

      return getGatewayErrorMessage(connectionErrorTypes[0], connectionInvalidCredentialMessages);
    }

    return null;
  };

  const Icon = gatewayDefaultIcons[currentlyGatewayProperties.gateway_type] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

  /**
   * Retrieves the information message for the gateway connection based on the gateway, connection mode, and connection state.
   * If no information message is available, it returns null.
   *
   * @returns The information message for the gateway connection, or null if no message is available.
   */
  const getInformationMessage = () => gatewayModeInformation({
    gateway: !isChildGateway ? Gateway : selectedChildGatewayType,
    connectionMode,
    connectionState
  }) || null;

  const connectionButtonDefaultLabel = () => {
    if (connectionState === 'connected') {
      return translationRenderer('Disconnect from [1[{gatewayName}]]')({
        renderer1: () => name
      });
    }

    if (isChildGateway) {
      const childGatewayName = supportedGatewayConnectionPropsList[selectedChildGatewayType].name;
      return translationRenderer('Connect with [1[{childGatewayName}]]')({
        renderer1: () => childGatewayName
      });
    }

    if (isApm && isChildTypes === false && parentType) {
      const parentGatewayName = supportedGatewayConnectionPropsList[parentType].name;
      return translationRenderer('Connect with [1[{parentGatewayName}]]')({
        renderer1: () => parentGatewayName
      });
    }

    return translationRenderer('Connect with [1[{gatewayName}]]')({
      renderer1: () => name
    });
  };

  const isGuestUser = user?.accountType === 'GUEST' || user?.account_type.name === 'GUEST' || false;

  return (
    <div key="gateway-connection">

      {/* header */}
      <div className={`p-3 radius mb-4 ${connectionState === 'connected' ? 'bg-blue-100' : 'bg-navy-25'}`}>
        <div className='flex justify-between items-center'>
          {/* left */}
          <div>
            <div className="flex items-center gap-3">
              <div className='h-12 w-12 p-2 radius flex justify-center items-center border border-navy-100 bg-white'>
                <Icon className="h-10 w-10" />
              </div>
              <div className="gap-1">
                <div className='font-medium text-lg line-height-xl tracking-sm color-navy-700'>{name}</div>
                <Badge
                  colorStyle={connectionState !== 'connected' ? 'neutral' : undefined}
                  className={`customBadgeWeight ${connectionState !== 'connected' && 'bg-navy-75'}`}
                  icon={connectionState === 'connected' ? IconCheck : undefined}
                  data-test-id="connection_status"
                >
                  {connectionState === 'connected' ? t('Connected') : t('Not connected')}
                </Badge>
              </div>
            </div>
          </div>
          {/* right */}
          {connectionProperties.allowSwitchMode && connectionProperties.mode.options && (
            <div className='bg-white border p-1 border-navy-100 gap-2 radius h-8 flex justify-between items-center customSwitchButtons'>
              {connectionProperties.mode.options.map(option => {
                return (
                  <Button
                    key={option.value}
                    size="small"
                    className="line-height-md font-normal tracking-lg text-sm py-0 px-1 hover:bg-transparent focus:outline-transparent outline-0"
                    style={{ height: '24px', fontWeight: 400, lineHeight: '20px' }}
                    {...(connectionMode !== option.value ? { variant: 'ghost' } : { variant: 'filled' })}
                    {...(connectionMode !== option.value ? { colorStyle: 'neutral' } : { colorStyle: 'primary' })}
                    onClick={() => {
                      if (connectionState === 'connected' && connectionMode !== option.value) {
                        showOrHideDiscardNewConnectionDialog('changeMode');
                        changeDiscardedConnectionMode(option.value);
                        return;
                      }

                      changeConnectionMode(option.value);
                    }}
                    data-test-id={`connection_mode_${option.value}`}
                  >
                    {option.text}
                  </Button>
                );
              })}
            </div>
          )}
          {!connectionProperties.allowSwitchMode && (
            <div className='bg-white border p-1 border-navy-100 gap-2 radius h-8 flex justify-between items-center customSwitchButtons'>
              <Button
                key='dummy-button'
                size="small"
                className="line-height-md font-normal tracking-lg text-sm py-0 px-1 hover:bg-transparent focus:outline-transparent outline-0"
                style={{ height: '24px', fontWeight: 400, lineHeight: '20px' }}
                variant="filled"
                colorStyle="primary"
              >
                {t('Live Mode')}
              </Button>
            </div>
          )}
        </div>
        {/* Information */}
        {getInformationMessage() && (
          <div className='border-navy-50 mt-3 pt-3 flex justify-start items-start gap-2' style={{ borderTopWidth: '1px' }} data-test-id="gateway_informs">
            <IconInfoCircleFilled className='w-5 h-5 color-blue-500 shrink-0' />
            {' '}
            <span className='color-navy-700 text-sm line-height-md tracking-lg'>
              {getInformationMessage()}
            </span>
          </div>
        )}
      </div>

      {/* body */}
      <div key="gateway-connection-body" id="gateway-connection-body" className='w-full'>
        {/* connection name */}
        <FormControl
          id="connection-name"
          className="mb-3 relative"
          disabled={connectionState === 'connected'}
          colorStyle={!isValidConnectionName || connectionErrorInputErrors.connectionName ? 'error' : undefined}
        >
          <label htmlFor="connection-name" className='mb-2 flex flex-col max-h-11'>
            <span className="font-medium text-md tracking-md color-navy-700 line-height-xl">
              {t('Connection Name')}
              {' '}
              <span className='color-red-400'>*</span>
            </span>
            <span className="color-navy-300 text-sm tracking-lg font-normal line-height-md">{t('Enter a name for your connection to reuse it in the future.')}</span>
          </label>
          <InputText
            id="connection-name"
            showLockIconInReadOnly={false}
            autoComplete="off"
            value={connectionName || ''}
            placeholder={t(`My ${name} Connection #1`)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const inputValue = e.target.value;
              if (inputValue.length > 40) {
                setIsValidConnectionName(false);
              } else {
                setIsValidConnectionName(true);
                changeConnectionName(inputValue);
              }
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!isValidConnectionName) {
                setIsValidConnectionName(true);
              }

              if (connectionErrorTypes?.includes('missingRequiredFields') && connectionErrorInputErrors.connectionName && e.target.value !== '') {
                removeInputError('connectionName');
              }

              if (connectionState !== 'connected' && connectionState === 'idle' && e.target.value !== '') {
                checkUniqueConnectionNameOnBlur(e.target.value);
              }
            }}
            data-test-id="connection_name"
          />
          {connectionState !== 'connected' && (
            <div className='flex flex-row justify-end items-center m-0 absolute right-1 bottom-3 mx-3'>
              <span className={`text-sm ${!isValidConnectionName ? 'color-red-400' : ''}`}>
                {connectionName?.length || 0}
                /40
              </span>
            </div>
          )}
        </FormControl>

        {/* credentials */}
        {connectionProperties.type === 'credentials' && getConnectionProps && (
          <div className='grid cols-2 gap-3 mb-3'>
            <RenderCredentialInputs
              connectionProps={getConnectionProps}
              isConnected={connectionState === 'connected'}
            />
          </div>
        )}

        {/* warning for 3D secure 2.0 */}
        {connectionProperties.isSecure3DSupport && (
          <div className='border-yellow-300 border mt-3 mb-3 flex justify-start items-start gap-2 bg-yellow-100 radius py-2 px-3'>
            <IconInfoCircleFilled className='w-5 h-5 color-yellow-400 shrink-0' />
            {' '}
            <span className='color-navy-500 text-sm line-height-md tracking-lg'>
              {
                translationRenderer('[1[{enable3DSecureVersion}]] is a one-time only setting, you can’t change it after your connection is set.')({
                  renderer1: () => <span className='color-navy-700 font-medium'>Enable 3D Secure 2.0</span>
                })
              }
            </span>
          </div>
        )}

        {/* connect button */}
        <Button
          colorStyle={connectionState === 'connected' ? 'secondary' : 'primary'}
          fullWidth={true}
          startIcon={(connectionState !== 'connected' ? IconPlugFilled : UnPlugIcon)}
          loader={connectionState === 'loading'}
          onClick={() => {
            if (isGuestUser) {
              setIsOpenLoginFlow(true);
              return;
            }

            if (connectionState === 'connected') {
              showOrHideDiscardNewConnectionDialog('disconnect');
              return;
            }

            connectionButtonHandler();
          }}
          data-test-id="connection_button"
        >
          {connectionButtonDefaultLabel()}
        </Button>

        {
          connectionError && connectionErrorTypes && (
            <div className='py-2 px-3 text-sm font-normal line-height-xs radius overflow-hidden ease-in-out duration-300 border border-red-200 bg-red-100 mt-2' data-test-id="connectionError_area">
              <div className='flex justify-start items-center gap-2 color-red-400'>
                <IconExclamationCircleFilled className='w-5 h-5 shrink-0' />
                {getErrorTitle()}
              </div>
              {
                connectionErrorTypes.length > 1 && (
                  <span className='mt-2'>
                    {connectionErrorTypes.map(errorType => (
                      <p key={errorType} className='flex justify-start items-center gap-2 m-0 my-1'>
                        <span className='w-5 h-3 flex justify-center items-center'>
                          <IconCircleSmFilled className='w-3 h-3 color-navy-300 shrink-0' />
                        </span>
                        {' '}
                        {getGatewayErrorMessage(errorType, connectionInvalidCredentialMessages)}
                      </p>
                    ))}
                  </span>
                )
              }
              {
                connectionErrorTypes.length === 1 && connectionErrorTypes.includes('rateLimiter') && (
                  <span className='mt-2' data-test-id="rate_limiter_error_message">
                    <p className='flex justify-start items-center gap-2 m-0 my-1'>
                      <span className='w-5 h-3 flex justify-center items-center'>
                        <IconCircleSmFilled className='w-3 h-3 color-navy-300' />
                      </span>
                      {' '}
                      {getGatewayErrorMessage('rateLimiter')}
                    </p>
                  </span>
                )
              }
            </div>
          )
        }
        {
          connectionState && connectionState === 'connected' && (
            <div className='border border-green-300 radius bg-green-100 mt-3 px-3 py-2 flex justify-start items-center gap-2'>
              <IconCheckCircleFilled className='w-5 h-5 color-green-500 shrink-0' />
              <span className='color-green-500 text-sm'>
                {
                  translationRenderer('You have successfully connected with [1[{gatewayName}]].')({
                    renderer1: () => name
                  })
                }
              </span>
            </div>
          )
        }
        {/* buraya gelecek */}
        {isGuestUser && (
          <div className='border border-green-300 radius bg-green-100 mt-3 px-3 flex justify-between items-center h-14'>
            <div className='flex justify-start items-start gap-2'>
              <IconInfoCircleFilled className='w-5 h-5 color-green-400 shrink-0' />
              {' '}
              <span className='color-navy-700 font-medium text-sm line-height-md tracking-lg'>
                {t('Sign up or log in to create a connection.')}
              </span>
            </div>
            <Button
              colorStyle="success"
              onClick={() => setIsOpenLoginFlow(true)}
              data-test-id="sign_up_button"
            >
              {t('Sign Up Now')}
              {' '}
              -
              {' '}
              <span style={{ fontStyle: 'italic' }}>{t('It’s Free!')}</span>
            </Button>
          </div>
        )}
        {/* login flow */}
        {isOpenLoginFlow && (
          <CommonLoginFLow onClose={() => setIsOpenLoginFlow(false)} />
        )}
      </div>
      {/* body: end */}

    </div>
  );
}
