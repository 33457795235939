import React from 'react';
import propTypes from 'prop-types';
import SvgOptionRenderer from '../SvgOptionRenderer';
import { SHRINK_WRT_CARD_LAYOUT } from './constants';

const CardLayout = ({ onChange, ...props }) => {
  const onLayoutChange = cardLayout => {
    onChange({ cardLayout, shrink: SHRINK_WRT_CARD_LAYOUT[cardLayout] ?? 'No' });
  };
  return (
    <SvgOptionRenderer {...props} onChange={onLayoutChange} />
  );
};

CardLayout.propTypes = {
  onChange: propTypes.func
};

CardLayout.defaultProps = {
  onChange: f => f
};

export default CardLayout;
