import React from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconEyeFilled } from '@jotforminc/svg-icons';

const PreviewHint = ({ ctaText }) => {
  return (
    <span className="template-hint">
      <IconEyeFilled width="20" height="20" />
      <span>{t(ctaText)}</span>
    </span>
  );
};

export default PreviewHint;

PreviewHint.defaultProps = {
  ctaText: 'Preview'
};
PreviewHint.propTypes = {
  ctaText: string
};
