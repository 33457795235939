import React, { useCallback, useState } from 'react';
import { func, shape } from 'prop-types';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { Feature } from '@jotforminc/feature-toggle';
import { FEATURE_NAMES } from '../../constants/features';
import { ScErrorFallback } from './ScErrorFallback';

const DefaultErrorFallback = ({ error, resetErrorBoundary }) => {
  const [feedbackValue, setFeedbackValue] = useState('');
  const handleValueChange = useCallback(e => {
    setFeedbackValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    console.log('Report to phoenix', error);
    resetErrorBoundary();
  }, [feedbackValue]);
  return (
    <ScErrorFallback>
      <p className="errorFallback-text">{t('An unexpected error occurred.')}</p>
      <Feature
        name={FEATURE_NAMES.FeedbackOnError}
        activeComponent={(
          <>
            <div className="errorFallback-label">{t('Can you elaborate what were you trying to do?')}</div>
            <textarea
              className="errorFallback-textarea" placeholder="Type a feedback" value={feedbackValue}
              onChange={handleValueChange}
            />
            <Button onClick={handleSubmit}>{t('Send')}</Button>
          </>
        )}
      />
    </ScErrorFallback>
  );
};

DefaultErrorFallback.propTypes = {
  error: shape({}),
  resetErrorBoundary: func
};

DefaultErrorFallback.defaultProps = {
  error: {},
  resetErrorBoundary: f => f
};

export default DefaultErrorFallback;
