import React from 'react';
import { node, string } from 'prop-types';

const Tab = ({ id, children = null }) => {
  return (
    <div data-tabid={id}>
      {children}
    </div>
  );
};

Tab.propTypes = {
  id: string.isRequired,
  children: node.isRequired
};

export default Tab;
