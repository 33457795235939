/* eslint-disable max-lines */
import { t, translationRenderer } from '@jotforminc/translation';
import flatMap from 'lodash/flatMap';
import * as COMPONENT_NAMES from './componentNames';
import {
  IGatewaySettings, additionalGatewayElementListProperties, IAllActions, IFormWizardElements, IPaymentProperties
} from '../types/common';

import { checkIsGatewayConnected } from '../utils';
import { countries } from './countries';

const FORMWIZARD_WRAPPER_CLASSNAME = 'formWizard-wrapper';

const LABEL_TESTMODE = {
  LABEL: t('Test Mode'),
  DESCRIPTION: t('Test the integration without accepting real payments.'),
  REQUIRE_RECONNECTION: t('Requires reconnection.')
};

const CLASS_WARNING = {
  LOW: 'warning-low',
  MID: 'warning-mid',
  HIGH: 'warning-high'
};

const PaymentProperties: IPaymentProperties = {
  control_square: {
    name: 'Square',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/square-logo.svg',
    currencies: [
      'USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK',
      'CHF', 'THB', 'TRY'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: true,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      accessToken: {
        isSecret: true,
        isRequired: true
      },
      merchantId: {
        isSecret: false,
        isRequired: true
      },
      location: {
        isSecret: false,
        isRequired: true
      },
      allowTest: {
        isSecret: false,
        isRequired: true
      },
      tokenExpiry: {
        isSecret: false,
        isRequired: true
      },
      refreshToken: {
        isSecret: true,
        isRequired: false
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', 'Square'),
          description: t('You will be redirected to Square to complete the connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      currencyWarning: {
        type: COMPONENT_NAMES.INFOLINE,
        labelProps: {
          label: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.INFOLINE} ${CLASS_WARNING.MID}`
        },
        componentProps: {
          text: `Your currency was set as ${settings?.currency} based on your <a href="https://squareup.com/dashboard" target="_blank">Square account</a> settings.`,
          type: 'warning'
        },
        condition: () => settings?.showCurrencyWarning && checkIsGatewayConnected(settings)
      },
      allowTest: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.allowTest === true || settings?.allowTest === 'true',
          valueTrue: 'true',
          valueFalse: 'false',
          onText: 'YES',
          offText: 'NO'
        }
      },
      infoline: {
        type: COMPONENT_NAMES.INFOLINE,
        labelProps: {
          label: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.INFOLINE}`
        },
        componentProps: {
          text: flatMap(translationRenderer('To start the OAuth flow for a Test Mode, first launch the seller test account from the [1[Square Developer Dashboard]]. '
            + 'Please login to your Square account and then launch your sandbox account from "Sandbox Test Accounts" section.')({
            renderer1: (str: string) => `<a href="https://developer.squareup.com/apps" target="_blank">${str}</a>`
          })).join('')
        },
        condition: () => settings?.allowTest === true || settings?.allowTest === 'true'
      },
      location: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Business Location'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX} ${settings?.location ? '' : 'disabled'}`
        },
        componentProps: {
          defaultValue: settings?.location,
          options: (settings?.locationOptions && checkIsGatewayConnected(settings))
            ? settings?.locationOptions
            : [{ value: 'default', text: t('Connect to {gateway}').replace('{gateway}', 'Square first') }],
          disabled: !checkIsGatewayConnected(settings)
        }
      },
      showApplePay: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Apple Pay'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showApplePay === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        },
        condition: () => settings?.nameAPM !== 'appleAndGooglePay' && settings?.nameAPM !== 'CashApp'
      },
      showGooglePay: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Google Pay'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showGooglePay === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        },
        condition: () => settings?.nameAPM !== 'appleAndGooglePay' && settings?.nameAPM !== 'CashApp'
      },
      showCashApp: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Cash App Pay'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showCashApp === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        },
        condition: () => settings?.nameAPM !== 'appleAndGooglePay' && settings?.nameAPM !== 'CashApp'
      },
      showAfterpay: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Afterpay'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showAfterpay === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        },
        condition: () => settings?.nameAPM !== 'appleAndGooglePay' && settings?.nameAPM !== 'CashApp'
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: t('Authorize the card now to charge it manually later. Expires after 6 days.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      sendReceipt: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Receipt'),
          description: t('Send an email with a link to the Square receipt to the customer.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sendReceipt === true,
          valueTrue: true,
          valueFalse: false,
          onText: 'YES',
          offText: 'NO'
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_paypalcomplete: {
    name: 'PayPal Business',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/paypal-business-logo.svg',
    currencies: [
      'USD', 'GBP', 'CAD', 'AUD', 'EUR', 'BRL', 'CZK', 'DKK', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD',
      'SEK', 'CHF', 'THB', 'INR'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: true,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      merchantId: {
        isSecret: false,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', 'PayPal'),
          description: t('You will be redirected to PayPal to complete the connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      },
      paymentMethodHeader: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Payment Method'),
          description: t('You must select at least one payment method.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.GROUPHEADER}`
        },
        condition: () => settings?.merchantId.length === 0 && settings?.nameAPM !== 'Venmo'
      },
      showCardFields: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show "Debit or Credit Card"'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showCardFields === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF',
          onChange: e => {
            if (settings?.showCardFields === 'Yes' && settings?.showSPB === 'No') {
              e.preventDefault();
            }
          }
        },
        condition: () => settings?.merchantId.length === 0 && settings?.nameAPM !== 'Venmo'
      },
      showSPB: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: 'Show Paypal',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showSPB === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'ON',
          offText: 'OFF',
          onChange: e => {
            if (settings?.showCardFields === 'No' && settings?.showSPB === 'Yes') {
              e.preventDefault();
            }
          }
        },
        condition: () => settings?.merchantId.length === 0 && settings?.nameAPM !== 'Venmo'
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: t('The credit card will be authorized after the form is submitted. You can manually charge the card later. (The grace period is 3 days.)'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        },
        condition: () => settings?.nameAPM !== 'Venmo'
      },
      groupHeaderSPBStyle: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('PayPal Smart Payment Buttons Style'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.GROUPHEADER}`
        }
      },
      styleColor: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Button Color'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.styleColor,
          options: [
            { value: 'gold', text: t('Gold') },
            { value: 'blue', text: t('Blue') },
            { value: 'silver', text: t('Silver') },
            { value: 'white', text: t('White') },
            { value: 'black', text: t('Black') }
          ]
        }
      },
      styleShape: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Button Shape'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.styleShape,
          options: [
            { value: 'pill', text: t('Pill') },
            { value: 'rect', text: t('Rect') }
          ]
        }
      },
      styleSize: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Button Size'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.styleSize,
          options: [
            { value: 'small', text: t('Small') },
            { value: 'medium', text: t('Medium') },
            { value: 'large', text: t('Large') }
          ]
        }
      },
      styleLabel: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Button Label'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.styleLabel,
          options: [
            { value: 'checkout', text: t('PayPal Checkout') },
            { value: 'pay', text: t('Pay with PayPal') },
            { value: 'buynow', text: t('Buy Now') },
            { value: 'paypal', text: t('PayPal') }
          ]
        }
      },
      styleLayout: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Button Layout'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.styleLayout,
          options: [
            { value: 'vertical', text: t('Vertical') },
            { value: 'horizontal', text: t('Horizontal') }
          ]
        }
      },
      payLaterEnabled: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Show Pay Later Messaging'),
          description: t('Displays information about the installments for Pay Later which is available in the US.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.payLaterEnabled === 'Yes'
        }
      },
      groupHeaderPayLaterStyle: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Pay Later Messaging Style'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.GROUPHEADER}`
        },
        condition: () => settings?.payLaterEnabled === 'Yes'
      },
      payLaterLayout: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Layout Style'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.payLaterLayout,
          options: [
            { value: 'text', text: t('Text') },
            { value: 'flex', text: t('Flex') }
          ]
        },
        condition: () => settings?.payLaterEnabled === 'Yes'
      },
      payLaterLogoType: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Logo Type'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.payLaterLogoType,
          options: [
            { value: 'primary', text: t('Primary') },
            { value: 'alternative', text: t('Alternative') },
            { value: 'inline', text: t('Inline') },
            { value: 'none', text: t('None') }
          ]
        },
        condition: () => settings?.payLaterEnabled === 'Yes'
      },
      payLaterTextColor: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Text Color'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.payLaterTextColor,
          options: [
            { value: 'black', text: t('Black') },
            { value: 'white', text: t('White') },
            { value: 'monochrome', text: t('Monochrome') },
            { value: 'grayscale', text: t('Grayscale') }
          ]
        },
        condition: () => settings?.payLaterEnabled === 'Yes'
      },
      payLaterTextSize: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Text Size'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.payLaterTextSize,
          options: [
            { value: '10', text: t('10') },
            { value: '11', text: t('11') },
            { value: '12', text: t('12') },
            { value: '13', text: t('13') },
            { value: '14', text: t('14') },
            { value: '15', text: t('15') },
            { value: '16', text: t('16') }
          ]
        },
        condition: () => settings?.payLaterEnabled === 'Yes'
      }
    })
  },
  control_paypal: {
    name: 'PayPal Personal',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/paypal-logo.svg',
    currencies: [
      'USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD',
      'SEK', 'CHF', 'THB', 'TRY'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'sandbox',
      liveModeValue: ['Disabled']
    },
    connectionReferences: {
      account: {
        label: t('Paypal Account'),
        isSecret: false,
        forConnectionModal: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Account email is required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'Disabled',
          onText: 'Yes',
          offText: 'No'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        }
      },
      payeraddress: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Require Shipment Address'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.payeraddress === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        }
      },
      country: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Checkout Language'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          options: countries,
          defaultValue: settings?.country
        }
      },
      ipnrelay: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('IPN Relay URL'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          txtval: settings?.ipnrelay,
          defaultValue: settings?.ipnrelay
        }
      },
      emailpending: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Pending Payment Email'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          options: [
            { text: t('No'), value: 'No' },
            { text: t('Notifiers Only'), value: 'Yes' },
            { text: t('Notifiers & Autoresponders'), value: 'All' }
          ],
          defaultValue: settings?.emailpending
        }
      },
      pagestyle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Redirected PayPal Page Style'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          txtval: settings?.pagestyle,
          defaultValue: settings?.pagestyle
        }
      },
      checkoutLogo: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Checkout Logo'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.checkoutLogo
        }
      },
      cartColor: {
        type: COMPONENT_NAMES.COLORPICKER,
        labelProps: {
          label: t('Checkout Cart Color'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.COLORPICKER}`,
          description: ''
        },
        componentProps: {
          value: settings?.cartColor
        }
      }
    })
  },
  control_paypalInvoicing: {
    name: 'PayPal Invoicing',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/paypal-logo.svg',
    currencies: [
      'USD', 'AUD', 'BRL', 'CAD', 'CZK', 'DKK', 'EUR', 'HKD', 'HUF', 'INR', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'GBP', 'RUB',
      'SGD', 'SEK', 'CHF', 'THB'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: true,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      merchantId: {
        isSecret: false,
        isRequired: true
      },
      merchantEmail: {
        isSecret: false,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('You will be redirected to PayPal to complete the connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      },
      groupHeader: {
        type: COMPONENT_NAMES.GROUPHEADER,
        labelProps: {
          label: t('Your Business Details'),
          description: t('Your business information will be included on PayPal invoices.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.GROUPHEADER}`
        }
      },
      softDescriptor: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Company Name'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.softDescriptor
        }
      },
      merchantName: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Name'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.merchantName
        }
      },
      companyAddress: {
        type: COMPONENT_NAMES.TEXTAREA,
        labelProps: {
          label: t('Address'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTAREA}`
        },
        componentProps: {
          defaultValue: settings?.companyAddress
        }
      },
      merchantPhone: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Phone'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.merchantPhone
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      allowPartial: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Allow Partial Payment'),
          description: t('Selecting yes will allow the customer to pay in installments. You can set a minimum amount for installment payments.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.allowPartial === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      },
      minAmount: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Minimum Amount'),
          description: t('Enter minimum amount'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.minAmount
        },
        condition: () => settings?.allowPartial === 'enabled'
      }
    })
  },
  control_authnet: {
    name: 'Authorize.net',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/authorizenet_2x.png',
    currencies: ['USD', 'EUR', 'CAD', 'GBP', 'AUD'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      apiLoginId: {
        label: 'API Login ID',
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      transactionKey: {
        label: 'Transaction Key',
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      testmodeAuth: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('API Login ID and Transaction Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      testmodeAuth: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.testmodeAuth === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      authnetEmulator: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Emulator'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.authnetEmulator || 'No',
          options: [
            { value: 'none', text: t('None') },
            { value: 'epn', text: t('EPN') },
            { value: 'goemerchant', text: t('GoEmerchant') },
            { value: 'merchantone', text: t('MerchantOne') },
            { value: 'payjunction', text: t('PayJunction') },
            { value: 'usaepay', text: t('USAePay') }
          ]
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_stripe: {
    name: 'Stripe',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/stripe_2x.png',
    currencies: [
      'USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL',
      'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP',
      'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK',
      'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL',
      'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB',
      'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD',
      'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST',
      'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: true,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      publishableKey: {
        isSecret: false,
        isRequired: true
      },
      apiKey: {
        isSecret: true,
        isRequired: true
      },
      connect: {
        isSecret: true,
        isRequired: true
      },
      allowTest: {
        isSecret: false,
        isRequired: true
      },
      stripeUserId: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('You will be redirected to Stripe to complete the connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      allowTest: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.allowTest === true || settings?.allowTest === 'true',
          valueTrue: 'true',
          valueFalse: 'false',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      laterCharge: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.laterCharge === 'later',
          valueTrue: 'later',
          valueFalse: 'immediately',
          onText: 'YES',
          offText: 'NO'
        }
      },
      stripeLink: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable, 1-Click Checkout with Link'),
          description: t('Create a Link account for faster checkout.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.stripeLink === 'Yes',
          labelIcon: 'sss',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      // sendEmail: {
      //   type: COMPONENT_NAMES.TOGGLE,
      //   labelProps: {
      //     label: t('Send Email to Customer'),
      //     description: t('Automatically send an email to a customer when 3D Secure authentication problems occur.'),
      //     class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
      //   },
      //   componentProps: {
      //     isSelected: settings?.sendEmail === 'Yes',
      //     valueTrue: 'Yes',
      //     valueFalse: 'No',
      //     onText: 'YES',
      //     offText: 'NO'
      //   }
      // },
      createCustomer: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Create Stripe Customer Record'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.createCustomer,
          options: settings?.paymentType === 'subscription' ? [
            { value: 'Unique', text: t('For Each Unique Customer') },
            { value: 'All', text: t('For Each Submission') }
          ] : [
            { value: 'Unique', text: t('For Each Unique Customer') },
            { value: 'All', text: t('For Each Submission') },
            { value: 'No', text: t('Do Not Create') }
          ]
        }
      }
    })
  },
  control_stripeCheckout: {
    name: 'Stripe Checkout',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/stripe_checkout_2x.png',
    currencies: [
      'USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB',
      'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD',
      'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD',
      'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD',
      'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR',
      'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP',
      'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU',
      'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: true,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      stripeUserId: {
        isSecret: false,
        isRequired: true
      },
      allowTest: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', 'Stripe'),
          description: t('You will be redirected to Stripe to complete the connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      allowTest: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.allowTest === true || settings?.allowTest === 'true',
          valueTrue: 'true',
          valueFalse: 'false',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      laterCharge: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.laterCharge === 'later',
          valueTrue: 'later',
          valueFalse: 'immediately',
          onText: 'YES',
          offText: 'NO'
        }
      },
      sendReceiptEmail: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Receipt Email'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sendReceiptEmail === 'true' || settings?.sendReceiptEmail === 'Yes',
          onText: 'YES',
          offText: 'NO'
        }
      }
    })
  },
  control_braintree: {
    name: 'Braintree',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/braintree_2x.png',
    currencies: [
      'USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND',
      'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP',
      'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR',
      'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD',
      'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD',
      'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD',
      'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD',
      'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'
    ],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      merchantId: {
        label: t('Merchant ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      publicKey: {
        label: t('Public Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      privateKey: {
        label: t('Private Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant ID, Public Key, Private Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'Disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      threeDSecure: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          description: t('You must enable 3D Secure 2.0 in your Braintree Account as well. You can follow this guide.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.threeDSecure === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      merchantAccountId: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Merchant Account ID'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.merchantAccountId || ''
        }
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      braintreeDescriptionField: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Product Description Field'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.braintreeDescriptionField
        }
      }
    })
  },
  control_paypalpro: {
    name: 'PayPal Pro',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/paypal-pro-logo.svg',
    currencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK', 'CHF', 'THB', 'TRY'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      username: {
        label: t('API Username'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      password: {
        label: t('API Password'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      signature: {
        label: t('API Signature'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`,
          description: t('API Username, API Password and API Signature are required to complete connection.')
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'Disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      sca: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`,
          description: ''
        },
        componentProps: {
          isSelected: settings?.sca === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      cardinalAPIkey: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Cardinal API Key'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.cardinalAPIkey || ''
        },
        condition: () => settings?.sca === 'Yes'
      },
      cardinalAPIindentifier: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Cardinal API Identifier'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.cardinalAPIindentifier || ''
        },
        condition: () => settings?.sca === 'Yes'
      },
      cardinalOrgUnitID: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Cardinal OrgUnit ID'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.cardinalOrgUnitID || ''
        },
        condition: () => settings?.sca === 'Yes'
      },
      paymentMethod: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Default Payment Method'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.paymentMethod || 'paypal',
          options: [
            { value: 'paypal', text: t('PayPal') },
            { value: 'cc', text: t('Credit Card') }
          ]
        }
      },
      acceptedCc: {
        type: COMPONENT_NAMES.CHECKBOX,
        labelProps: {
          label: t('Accepted Credit Card'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CHECKBOX}`,
          description: ''
        },
        componentProps: {
          value: settings.acceptedCc || '',
          options: [
            { value: 'visa', text: t('Visa'), isValueChecked: settings.acceptedCc.indexOf('visa') > -1 },
            { value: 'mc', text: t('Mastercard'), isValueChecked: settings.acceptedCc.indexOf('mc') > -1 },
            { value: 'amex', text: t('American Express'), isValueChecked: settings.acceptedCc.indexOf('amex') > -1 },
            { value: 'dc', text: t('Discover'), isValueChecked: settings.acceptedCc.indexOf('dc') > -1 }
          ]
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      },
      addressTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Billing Address'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.addressTitle || t('Billing Address')
        }
      },
      showPaypalStandard: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Allow PayPal Standard Payments'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.showPaypalStandard === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`,
          description: ''
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      pagestyle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Redirected PayPal Page Style'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.pagestyle
        }
      },
      checkoutLogo: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Checkout Logo'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`,
          description: ''
        },
        componentProps: {
          defaultValue: settings?.checkoutLogo
        }
      },
      cartColor: {
        type: COMPONENT_NAMES.COLORPICKER,
        labelProps: {
          label: t('Checkout Cart Color'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.COLORPICKER}`,
          description: ''
        },
        componentProps: {
          value: settings?.cartColor
        }
      }
    })
  },
  CashApp: {
    name: 'Cash App Pay',
    type: 'control_square',
    nameAPM: 'CashApp',
    icon: 'https://play-lh.googleusercontent.com/6RcPDQwPihY591Axu7e6mHhMZ22Q-dqeI5z9GkJiu4Hc-Xha77E6uoeplstYuv5RcnE=w480-h960-rw'
  },
  Venmo: {
    name: 'Venmo',
    type: 'control_paypalcomplete',
    nameAPM: 'Venmo',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/venmo_2x.png'
  },
  appleAndGooglePay: {
    name: 'Apple Pay & Google Pay',
    description: t(`Our Apple Pay and Google Pay integration is powered by Square and Stripe Checkout.
    Credit card will also be offered as a payment option in case the user is on a device or browser that doesn’t support Apple Pay or Google Pay.
    Please select a payment provider below to continue.`),
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/apple-google-pay-logo.svg',
    nameAPM: 'appleAndGooglePay'
  },
  control_mollie: {
    name: 'Mollie',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/mollie-logo.svg',
    // eslint-disable-next-line
    currencies: ['EUR', 'USD', 'AED', 'AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'RON', 'RUB', 'SEK', 'SGD', 'THB', 'TWD', 'ZAR'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: true,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      profileId: {
        isSecret: false,
        isRequired: true
      },
      refreshToken: {
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('You will be redirected to Mollie to complete the connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.sandbox === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      profileId: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Profile'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX} ${settings?.profileId ? '' : 'disabled'}`
        },
        componentProps: {
          defaultValue: settings?.profileId,
          options: (settings?.profileOptions && settings?.profileId) ? settings?.profileOptions : [{ value: 'default', text: t('Connect to Mollie first') }],
          disabled: !settings?.profileId
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  },
  control_cybersource: {
    name: 'CyberSource',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/cybersource_2x.png',
    currencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK', 'CHF', 'THB', 'TRY'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      merchantID: {
        label: t('Merchant ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      transactionKey: {
        label: t('Transaction Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant ID and Transaction Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'Disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      sca: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sca === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      cardinalAPIkey: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Cardinal API Key'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.cardinalAPIkey || ''
        },
        condition: () => settings?.sca === 'Yes'
      },
      cardinalAPIindentifier: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Cardinal API Identifier'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.cardinalAPIindentifier || ''
        },
        condition: () => settings?.sca === 'Yes'
      },
      cardinalOrgUnitID: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Cardinal OrgUnit ID'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.cardinalOrgUnitID || ''
        },
        condition: () => settings?.sca === 'Yes'
      },
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      orderPrefix: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Order Prefix') || '',
          description: t('This prefix will be appended to the beginning of the Order ID that\'s sent to CyberSource.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.orderPrefix || ''
        }
      }
    })
  },
  // control_echeck: {
  //   name: 'eCheck.Net',
  //   icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/echeck_2x.png',
  //   currencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'DKK', 'GBP', 'HKD', 'INR', 'ILS', 'JPY', 'MYR', 'MXN', 'NZD', 'NOK', 'PHP', 'SGD', 'SEK', 'CHF', 'TRY', 'AED', 'ZAR', 'ARS'],
  //   paymentTypes: ['product', 'subscription', 'custom', 'donation'],
  //   isPaymentAuthorizationSupported: false,
  //   isOauthSupported: false,
  //   isTaxSupported: true,
  //   isShippingSupported: true,
  //   environment: {
  //     name: 'allowTest',
  //     liveModeValue: ['No']
  //   },
  //   connectionReferences: {},
  //   basicElementList: () => ({}),
  //   advancedElementList: () => ({})
  // },
  control_bluepay: {
    name: 'Bluepay',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/bluepay-logo.svg',
    // eslint-disable-next-line
    currencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      accountId: {
        label: t('Account ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      apiSecret: {
        label: t('API Secret'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      confirmed: {
        label: t('BluePayJS is already active on my account'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: false
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Account ID and API Secret are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (): Record<string, never> => ({})
  },
  control_eway: {
    name: 'Eway',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/eway-logo.png',
    // eslint-disable-next-line
    currencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: true,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      apiKey: {
        label: t('API Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      apiPassword: {
        label: t('API Password'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      encryptionKey: {
        label: t('Client-side Encryption Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('API Key, API Password and Client-side Encryption Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      authOnly: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Authorization Only'),
          description: t('Authorize the card now to charge it manually later.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.authOnly === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      }
    })
  },
  control_payu: {
    name: 'PayU',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/payu_2x.png',
    currencies: ['PLN', 'BGN', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HUF', 'NOK', 'RON', 'RUB', 'SEK', 'UAH', 'USD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      alternativeGatewayTypes: {
        isSecret: false,
        isRequired: true
      },
      merchantPosId: {
        label: t('Merchant Pos ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      signatureKey: {
        label: t('Signature Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      oAuthClientId: {
        label: t('oAuth Client ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      oAuthClientSecret: {
        label: t('oAuth Client Secret'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant ID and Transaction Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Test Mode'),
          description: t('Test the integration without accepting real payments.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'Disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  },
  control_worldpay: {
    name: 'Worldpay UK',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/worldpay-logo.svg',
    // eslint-disable-next-line
    currencies: ['USD', 'GBP', 'EUR', 'ARS', 'AUD', 'BRL', 'CAD', 'CHF', 'CLP', 'CNY', 'COP', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'IDR', 'ISK', 'JPY', 'KES', 'KRW', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'SEK', 'SGD', 'THB', 'TRY', 'TWD', 'VND', 'ZAR'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: false,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      installationID: {
        label: t('Installation ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Installation ID are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      testMode: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.testMode === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      copyURL: {
        type: COMPONENT_NAMES.COPYURL,
        labelProps: {
          label: t('Payment Response URL')
        },
        componentProps: {
          url: 'https://ipn.jotform.com/ipns/worldpay.php'
        }
      },
      steps: {
        type: COMPONENT_NAMES.STEPS,
        componentProps: {
          title: t('To configure your Payment Response URL'),
          steps: [
            { step: t('Log into your Worldpay account') },
            { step: t('Go to “Installations” section at the bottom of the page') },
            { step: t('Select “Configurations”') },
            { step: t('Set "Payment Response URL" using the above URL') },
            { step: t('Set “Payment Response enable" to YES') }
          ]
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      emailpending: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Pending Payment Email'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.emailpending || 'No',
          options: [
            { value: 'No', text: t('No') },
            { value: 'Yes', text: t('Notifiers Only') },
            { value: 'All', text: t('Notifiers & Autoresponders') }
          ]
        }
      }
    })
  },
  control_bluesnap: {
    name: 'BlueSnap',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/bluesnap-logo.svg',
    // eslint-disable-next-line
    currencies: ['USD', 'EUR', 'AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'GBP', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      apiKey: {
        label: t('API Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      password: {
        label: t('API Password'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      softDescriptor: {
        label: t('Soft Descriptor'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('API Key and API Password are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      sca: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable 3D Secure 2.0'),
          description: t('To be able to collect payments in European Economic Area, please enable this toggle.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sca === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_moneris: {
    name: 'Moneris',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/moneris-logo.svg',
    currencies: ['CAD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      monerisid: {
        label: t('Store ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      monerisapi: {
        label: t('API Token'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Store ID and API Token are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      },
      orderIDPrefix: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Order ID Prefix') || '',
          description: t('This prefix will be appended to the beginning of the Order ID that\'s sent to Moneris.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.orderIDPrefix || ''
        }
      }
    })
  },
  control_stripeACH: {
    name: 'Stripe ACH',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/stripe_ach_2x.png',
    currencies: ['USD'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      apiKey: {
        label: t('Stripe Secret API Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      plaidClient: {
        label: t('Plaid Client ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      plaidSecret: {
        label: t('Plaid Secret Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      plaidSigningSecret: {
        label: t('Plaid Signing Secret Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      environment: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Stripe Secret API Key, Plaid Client ID and Plaid Secret Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      environment: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Environment'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.environment || 'sandbox',
          options: [
            { value: 'sandbox', text: t('Sandbox') },
            { value: 'development', text: t('Development') },
            { value: 'production', text: t('Production') }
          ]
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  },
  control_payfast: {
    name: 'Payfast',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/payfast_v2.png',
    currencies: ['ZAR'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      merchantId: {
        label: t('Merchant ID'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      merchantKey: {
        label: t('Merchant Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      passphrase: {
        label: t('Passphrase'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant ID, Merchant Key and Passphrase are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'Enabled',
          valueTrue: 'Enabled',
          valueFalse: 'Disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  },
  control_sofort: {
    name: 'Sofort',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/sofort_2x.png',
    currencies: ['EUR', 'CHF', 'GBP', 'PLN', 'SEK', 'CZK', 'HUF'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      configKey: {
        label: t('Configuration Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Configuration Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  },
  control_sensepass: {
    name: 'SensePass',
    icon: 'https://media.licdn.com/dms/image/C4D0BAQFqPvI1XH_HDQ/company-logo_200_200/0/1641913448005?e=2147483647&v=beta&t=bbDU0QqDSjCtGlHP5uz3B4_lHxomjxaaZj2rJRL3qng',
    currencies: ['USD', 'EUR', 'ILS', 'CAD'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'sandbox',
      liveModeValue: ['No']
    },
    connectionReferences: {
      apiKey: {
        label: t('API Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Configuration Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          squareMode: true,
          isSelected: settings?.sandbox === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      customerName: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Customer Name'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.customerName === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        }
      },
      billingAdd: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Billing Address'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.billingAdd === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        }
      },
      emailField: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Customer Email'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.emailField === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        }
      },
      receiptEmailNotification: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Request by Email'),
          description: t('An invoice email will be generated by SensePass and sent to the customer. It will be valid for 5 days.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.receiptEmailNotification === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        },
        condition: () => settings?.emailField === 'Yes'
      },
      customerPhone: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Customer Phone'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.customerPhone === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        }
      },
      receiptSMSNotification: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Send Payment Request by Text Message'),
          description: t('An invoice SMS will be generated by SensePass and sent to the customer. It will be valid for 5 days.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.receiptSMSNotification === 'Yes',
          valueTrue: 'Yes',
          valueFalse: 'No',
          onText: 'Yes',
          offText: 'No'
        },
        condition: () => settings?.customerPhone === 'Yes'
      }
    })
  },
  control_firstdata: {
    name: t('First Data'),
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/firstdata-logo.svg',
    // eslint-disable-next-line
    currencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      gatewayId: {
        label: t('Gateway ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      password: {
        label: t('API Password'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Gateway ID and API Password are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_cardconnect: {
    name: 'CardConnect',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/cardconnect-logo.svg',
    currencies: ['USD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      merchantId: {
        label: t('Merchant ID'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      apiUsername: {
        label: t('API Username'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      apiPassword: {
        label: t('API Password'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant ID, API Username and API Password are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_2co: {
    name: '2Checkout',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/2checkout_2x.png',
    currencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'DKK', 'GBP', 'HKD', 'INR', 'ILS', 'JPY', 'MYR', 'MXN', 'NZD', 'NOK', 'PHP', 'SGD', 'SEK', 'CHF', 'TRY', 'AED', 'ZAR', 'ARS'],
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      vendorNumber: {
        label: t('Vendor Number'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Vendor Number are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'Enabled',
          valueTrue: 'Enabled',
          valueFalse: 'Disabled',
          onText: 'YES',
          offText: 'NO'
        }
      },
      copyURL: {
        type: COMPONENT_NAMES.COPYURL,
        labelProps: {
          label: t('Payment Response URL')
        },
        componentProps: {
          url: 'https://submit.jotform.com/ipns/2co.php'
        }
      },
      infoline: {
        type: COMPONENT_NAMES.INFOLINE,
        labelProps: {
          label: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.INFOLINE}`
        },
        componentProps: {
          text: 'In order to complete your integration with 2Checkout, you must add the following URL into Global'
            + 'Settings URL section in your <a href="https://www.2checkout.com/va/notifications/" target="_blank">2Checkout\'s Webhook page.</a>'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      emailpending: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Pending Payment Email'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.emailpending || 'No',
          options: [
            { value: 'No', text: t('No') },
            { value: 'Yes', text: t('Notifiers Only') },
            { value: 'All', text: t('Notifiers & Autoresponders') }
          ]
        }
      }
    })
  },
  control_paysafe: {
    name: 'Paysafe',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/paysafe-logo.svg',
    currencies: ['USD', 'CAD', 'EUR', 'GBP'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      account: {
        label: t('Merchant Account Number'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      allowTest: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant Account Number are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_iyzico: {
    name: 'Iyzico',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons_fieldlist/iyzico.png',
    currencies: ['TRY', 'EUR', 'USD', 'GBP', 'NOK', 'RUB', 'CHF'],
    paymentTypes: ['product', 'subscription', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: false,
    isShippingSupported: false,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      iyzicoApiKey: {
        label: t('Merchant API Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      iyzicoSecretKey: {
        label: t('API Secret Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      allowTest: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant Account Number are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      allowTest: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.allowTest === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_skrill: {
    name: 'Skrill',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/skrill-logo.jpeg',
    // eslint-disable-next-line
    currencies: ['EUR', 'USD', 'GBP', 'HKD', 'SGD', 'JPY', 'CAD', 'AUD', 'CHF', 'DKK', 'SEK', 'NOK', 'ILS', 'MYR', 'NZD', 'TRY', 'AED', 'MAD', 'QAR', 'SAR', 'TWD', 'THB', 'CZK', 'HUF', 'BGN', 'PLN', 'ISK', 'INR', 'KRW', 'ZAR', 'RON', 'JOD', 'OMR', 'RSD', 'TND'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      account: {
        label: t('Merchant Account'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Merchant Account are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  },
  control_payjunction: {
    name: 'PayJunction',
    icon: 'https://cdn.jotfor.ms/assets/img/v4/payment_icons/payJunction_2x.png',
    currencies: ['USD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      username: {
        label: t('API Login'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      password: {
        label: t('API Password'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      },
      sandbox: {
        isSecret: false,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('API Login and API Password are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      },
      sandbox: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: LABEL_TESTMODE.LABEL,
          description: `${LABEL_TESTMODE.DESCRIPTION} ${checkIsGatewayConnected(settings) ? LABEL_TESTMODE.REQUIRE_RECONNECTION : ''}`,
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TOGGLE}`
        },
        componentProps: {
          isSelected: settings?.sandbox === 'enabled',
          valueTrue: 'enabled',
          valueFalse: 'disabled',
          onText: 'YES',
          offText: 'NO'
        }
      }
    }),
    advancedElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>): IFormWizardElements => ({
      avsMatchType: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('AVS Match Type'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.SELECTBOX}`
        },
        componentProps: {
          defaultValue: settings?.avsMatchType || 'NO',
          options: [
            { value: 'NO', text: t('Disabled') },
            { value: 'BYPASS', text: t('Bypass') },
            { value: 'ADDRESS', text: t('Address') },
            { value: 'ADDRESS_AND_ZIP', text: t('Address and Zip') },
            { value: 'ADDRESS_OR_ZIP', text: t('Address or Zip') },
            { value: 'ZIP', text: t('Zip') }
          ]
        }
      },
      ccTitle: {
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
          label: t('Credit Card Label Text'),
          description: '',
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.TEXTINPUT}`
        },
        componentProps: {
          defaultValue: settings?.ccTitle || t('Credit Card')
        }
      }
    })
  },
  control_paymentwall: {
    name: 'Paymentwall',
    icon: 'https://cdn.jotfor.ms/assets/img/payments/gateways-logo/paymentwall-logo.svg',
    // eslint-disable-next-line
    currencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    paymentTypes: ['product', 'custom', 'donation'],
    isPaymentAuthorizationSupported: false,
    isOauthSupported: false,
    isTaxSupported: true,
    isShippingSupported: true,
    environment: {
      name: 'allowTest',
      liveModeValue: ['No']
    },
    connectionReferences: {
      publicKey: {
        label: t('Project Key'),
        forConnectionModal: true,
        isSecret: false,
        isRequired: true
      },
      privateKey: {
        label: t('Secret Key'),
        forConnectionModal: true,
        isSecret: true,
        isRequired: true
      }
    },
    basicElementList: (settings: Partial<IGatewaySettings> & Partial<additionalGatewayElementListProperties>, actions: IAllActions): IFormWizardElements => ({
      connection: {
        type: COMPONENT_NAMES.CONNECTION_BUTTON,
        labelProps: {
          label: t('Connect to {gateway}').replace('{gateway}', settings?.builderLabel),
          description: t('Project Key and Secret Key are required to complete connection.'),
          class: `${FORMWIZARD_WRAPPER_CLASSNAME} p-${COMPONENT_NAMES.CONNECTION_BUTTON}`
        },
        componentProps: {
          settings: settings,
          actions: actions
        }
      }
    }),
    advancedElementList: (): IFormWizardElements => ({})
  }
};

export default PaymentProperties;
