import { t } from '@jotforminc/translation';

export const CLONE_APP_SETTINGS = {
  WITH_FORMS: 'withForms',
  WITHOUT_FORMS: 'withoutForms'
};

export const DEFAULT_CLONE_APP_SETTING = CLONE_APP_SETTINGS.WITH_FORMS;

export const CLONE_APP_OPTIONS = [
  {
    showWhenSelected: t('Clone app and forms'),
    text: t('Clone app and forms'),
    desc: t('Create a copy of the app and the forms it includes.'),
    value: CLONE_APP_SETTINGS.WITH_FORMS
  }, {
    showWhenSelected: t('Clone app and keep existing forms'),
    text: t('Clone app and keep existing forms'),
    desc: t('Create a copy of the app without cloning your forms.'),
    value: CLONE_APP_SETTINGS.WITHOUT_FORMS
  }
];

export const CLONE_MODAL_PROPERTIES = {
  header: {
    text: 'Clone App',
    description: 'Duplicate your app, including all app elements and settings.'
  },
  buttonText: 'Clone App',
  appName: 'apps',
  defaultSetting: DEFAULT_CLONE_APP_SETTING,
  settingsProps: {
    options: CLONE_APP_OPTIONS,
    inputLabel: 'App Name',
    settingLabel: 'App Settings',
    inputPlaceholder: 'Enter an app name'
  }
};
