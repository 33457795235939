export const toReadable = font => font.split('+').join(' ');

export const createNewArr = (category, target) => {
  const value = target.reduce((payload, item) => {
    const arr = payload;

    const newItem = {
      category,
      name: item
    };

    arr.push(newItem);

    return arr;
  }, []);

  return value;
};

export const createNewData = value => {
  const result = [
    ...createNewArr('jotform', value.jotform),
    ...createNewArr('system', value.system)
  ];

  return result;
};

export const handleSearchResult = (data, value) => {
  const cloneData = [...data];
  const filteredData = cloneData
    .filter(dataItem => dataItem.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
    .reduce((payload, item) => {
      const arr = payload;

      const obj = item;
      obj.orderNumber = cloneData.findIndex(cloneItem => cloneItem.name === item.name);

      arr.push(obj);

      return arr;
    }, []);

  return filteredData;
};
