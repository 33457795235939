import React from 'react';
import Styled from 'styled-components';
import { propTypes } from '@jotforminc/constants';

const ScItemContentRenderer = Styled.div`
  text-align: left;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
`;

const ItemContentRenderer = ({ children }) => (<ScItemContentRenderer>{children}</ScItemContentRenderer>);

ItemContentRenderer.propTypes = { children: propTypes.renderable.isRequired };

export default ItemContentRenderer;
