import React from 'react';
import { Checkbox as MagnetCheckbox } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { IconLockFilled } from '@jotforminc/svg-icons';
import type {
  T_VALUE_TRUE,
  T_VALUE_FALSE,
  T_SETTINGS_CONDITION,
  PAYMENT_QUESTION_PROP_NAMES
} from '@jotforminc/payment-constants';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

interface ICheckbox {
  name: PAYMENT_QUESTION_PROP_NAMES;
  valueTrue: T_VALUE_TRUE;
  valueFalse: T_VALUE_FALSE;
  default: string;
  text: string;
  onChange: (val: string) => void;
  lockedCondition?: T_SETTINGS_CONDITION;
  disableCondition?: T_SETTINGS_CONDITION;
}

const Checkbox: React.FunctionComponent<ICheckbox> = ({
  name,
  onChange,
  text,
  valueTrue,
  valueFalse,
  default: defaultValue,
  disableCondition,
  lockedCondition
}) => {
  const { gatewayProperties, hasConnection } = usePaymentPropsPanel();
  const value = gatewayProperties[name];
  const checkboxValue = value || defaultValue;
  const isLockedConditionMet = lockedCondition && lockedCondition(gatewayProperties);
  const isDisabledConditionMet = disableCondition && disableCondition(gatewayProperties, hasConnection);

  const unavailableClass = isDisabledConditionMet && !isLockedConditionMet ? 'opacity-40' : '';

  const onCheckboxChange = () => {
    if (isDisabledConditionMet || isLockedConditionMet) {
      return;
    }
    onChange(checkboxValue === valueTrue ? valueFalse : valueTrue);
  };

  return (
    <label htmlFor={`${name}`} className={`apm-list mb-3 bg-gray-500 flex flex-row items-center px-3 py-2.5 radius text-sm line-height-xs font-normal justify-between ${unavailableClass}`}>
      <div className='flex flex-row items-center'>
        <MagnetCheckbox
          id={name}
          key={name}
          theme="dark"
          checked={valueTrue === checkboxValue}
          onChange={onCheckboxChange}
        />
        <span className="ml-3">{t(text)}</span>
      </div>
      {isLockedConditionMet && <IconLockFilled className='w-5 h-5 color-gray-200' />}
    </label>
  );
};

Checkbox.defaultProps = {
  lockedCondition: undefined,
  disableCondition: undefined
};

export default Checkbox;
