import { openCloneAppModal } from '@jotforminc/create-new-wizards';
import { call, select } from 'redux-saga/effects';
import SELECTORS from '../../selectors';

export function* handleCloneApp({
  payload: {
    root,
    onCancel = f => f
  }
}) {
  const appInfo = yield select(SELECTORS.getPortalInfoSelector);
  const user = yield select(SELECTORS.getUser);
  const {
    id, title, items
  } = appInfo;

  try {
    yield call(openCloneAppModal, {
      id, title, portalRoot: root, user, portalItems: items
    });
  } catch {
    onCancel();
  }
}
