import React from 'react';
import {
  shape, string, func, elementType
} from 'prop-types';
import { shortenString } from '@jotforminc/utils';

import { isPressedKeyEnterOrSpace } from '../../../utils';

const FilePreview = ({
  file, translate, changeModeToUpload, Thumbnail, removeButtonText
}) => {
  const { name, type } = file;
  const shortName = shortenString(name);
  const isImage = type.indexOf('image') !== -1;

  const handleKeyDown = event => isPressedKeyEnterOrSpace(event) && changeModeToUpload();
  return (
    <div
      className="card"
    >
      <div className="card-leftSide">
        <Thumbnail file={file} />
      </div>
      <div className="card-contentWrapper">
        <div className="card-content">
          <span className="card-content-fileName">{shortName}</span>
          <a
            tabIndex="0"
            role="button"
            onKeyDown={handleKeyDown}
            onClick={changeModeToUpload}
            className="card-content-button"
            style={{
              left: isImage ? '132px' : '63px'
            }}
          >
            {translate(removeButtonText)}
          </a>
        </div>
      </div>
    </div>
  );
};

FilePreview.propTypes = {
  file: shape({
    name: string,
    type: string,
    url: string
  }),
  translate: func,
  changeModeToUpload: func,
  Thumbnail: elementType,
  removeButtonText: string
};

FilePreview.defaultProps = {
  file: {},
  translate: f => f,
  changeModeToUpload: f => f,
  Thumbnail: props => <img src="cdn.jotfor.ms/assets/img/builder/other_file_icon.png" {...props} alt="file-upload-thumbnail" />,
  removeButtonText: 'Remove File'
};

export default FilePreview;
