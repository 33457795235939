import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Iyzico gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlIyzico: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_iyzico: {
    copyUrl: {
      type: 'copyUrl',
      steps: [
        translationRenderer('Log into your [1[{text}]] account')({
          renderer1: () => <b className="color-white">iyzico</b>
        }),
        translationRenderer('Go to [1[{text}]] section')({
          renderer1: () => <b className="color-white">Settings</b>
        }),
        translationRenderer('Click [1[{text}]]')({
          renderer1: () => <b className="color-white">Merchant Settings</b>
        }),
        translationRenderer('Set [1[{text}]] to the URL below')({
          renderer1: () => <b className="color-white">Merchant Notification Url</b>
        })
      ],
      url: '/ipns/iyzico.php',
      resources: ['FORM', 'APP']
    },
    billingName: {
      title: 'Customer Name Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: true,
      autoSave: true
    },
    billingEmail: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: true,
      autoSave: true
    },
    billingAdd: {
      title: 'Use Field as Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2|country',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: true,
      autoSave: true
    }
  }
};

export default controlIyzico;
