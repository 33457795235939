import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@jotforminc/uikit';
import CheckoutFormRenderer from '../../../../PublicApp/OrderNow/CheckoutFormRenderer';
import DialogRenderer from './DialogRenderer';
import HeaderRenderer from './HeaderRenderer';
import ContentRenderer from './ContentRenderer';
import FooterRenderer from './FooterRenderer';

const CheckoutFormPreviewModal = ({ onClose, onItemSettingsClick }) => {
  return (
    <Modal
      defaultVisible={true}
      onModalClose={onClose}
      DialogRenderer={DialogRenderer}
      ContentRenderer={ContentRenderer}
      HeaderRenderer={HeaderRenderer(onClose)}
      FooterRenderer={FooterRenderer(onClose, onItemSettingsClick)}
    >
      <CheckoutFormRenderer previewMode={true} />
    </Modal>
  );
};

CheckoutFormPreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onItemSettingsClick: PropTypes.func
};

CheckoutFormPreviewModal.defaultProps = {
  onItemSettingsClick: f => f
};

export default CheckoutFormPreviewModal;
