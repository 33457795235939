import { VariantProps, cva } from 'class-variance-authority';
import { primary, secondary } from '../../tokens/colors';
import { CVAType } from '../../types';
import { ChoiceLabelProps } from './choice-label.types';

export type ChoiceLabelVariantProps = VariantProps<typeof choiceLabelCVA>

type ChoiceLabelCVAType = CVAType<Pick<ChoiceLabelProps, 'size' | 'disabled' | 'theme' | 'truncate'>>
type ChoiceLabelContainerCVAType = CVAType<Pick<ChoiceLabelProps, 'size' | 'truncate'>>
type ChoiceLabelTextCVAType = CVAType<Pick<ChoiceLabelProps, 'size' | 'disabled' | 'theme' | 'truncate'> & { hasDescription?: boolean }>
type ChoiceLabelDescCVAType = CVAType<Pick<ChoiceLabelProps, 'size' | 'disabled' | 'theme' | 'truncate'>>

// Variance values
export const choiceLabelCVA = cva<ChoiceLabelCVAType>('flex', {
  variants: {
    size: {
      small: 'gap-2',
      medium: 'gap-3',
      large: 'gap-3'
    },
    disabled: {},
    theme: {},
    truncate: {}
  },
  compoundVariants: [
    {
      truncate: ['both', 'description', 'label'],
      className: 'max-w-full'
    }
  ]
});

export const choiceLabelContainerCVA = cva<ChoiceLabelContainerCVAType>('', {
  variants: {
    size: {
      small: '-mt-px',
      large: 'mt-0.5'
    },
    truncate: {}
  },
  compoundVariants: [
    {
      truncate: ['both', 'description', 'label'],
      className: 'overflow-hidden'
    }
  ]
});

export const choiceLabelTextCVA = cva<ChoiceLabelTextCVAType>('', {
  variants: {
    size: {
      small: 'text-sm',
      medium: 'text-md',
      large: 'text-md'
    },
    hasDescription: {
      true: 'font-bold'
    },
    theme: {},
    disabled: {},
    truncate: {}
  },
  compoundVariants: [
    {
      size: ['small', 'medium', 'large'],
      className: 'block'
    },
    {
      theme: 'light',
      disabled: false,
      className: primary.text.darkest.base
    },
    {
      theme: 'light',
      disabled: true,
      className: 'color-navy-100'
    },
    {
      theme: 'dark',
      disabled: false,
      className: 'color-white'
    },
    {
      theme: 'dark',
      disabled: true,
      className: 'color-gray-400'
    },
    {
      truncate: ['both', 'label'],
      className: 'truncate'
    }
  ]
});

export const choiceLabelDescriptionCVA = cva<ChoiceLabelDescCVAType>('block', {
  variants: {
    size: {
      small: 'text-xs',
      medium: 'text-sm',
      large: 'text-sm'
    },
    theme: {},
    disabled: {},
    truncate: {}
  },
  compoundVariants: [
    {
      theme: 'light',
      disabled: false,
      className: primary.text.medium.base
    },
    {
      theme: 'light',
      disabled: true,
      className: 'color-navy-100'
    },
    {
      theme: 'dark',
      disabled: false,
      className: secondary.text.light.base
    },
    {
      theme: 'dark',
      disabled: true,
      className: 'color-gray-400'
    },
    {
      truncate: ['both', 'description'],
      className: 'truncate'
    }
  ]
});
