import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@jotforminc/uikit';
import { func } from 'prop-types';
import Tracking from '@jotforminc/tracking';

import selectors from '../../../../../store/selectors';
import {
  ContentRenderer, DialogRenderer, FooterRenderer, HeaderRenderer, headerPropsMap
} from './Renderers';
import {
  Navigation, DefaultView, NavigationNames
} from './Navigation';
import { SCViewWrapper } from './SCAppNameIconSettingsModal';
import { AB_TEST_NAMES, abTestActionLoggerSingleton } from '../../../../../utils/AbTestActionLoggerSingleton';
import { setIsModalSeen } from './seenManager';

// A/B Test: ctAppNameIconModal
const AppNameIconSettingsModal = ({ onClose }) => {
  const uikitModalRef = useRef();
  const portalProps = useSelector(selectors.getAppInfoWithDefaults);
  const appID = useSelector(selectors.getAppID);
  const [currentView, setView] = useState(DefaultView);
  const target = 'appNameIconSettingsModal';
  const modalRenderCountRef = useRef(0);
  const initialRenderCount = 2;

  const logAbTestAction = useMemo(() => abTestActionLoggerSingleton[AB_TEST_NAMES.CT_APP_NAME_ICON_MODAL_TWO] || (f => f), []);

  useEffect(() => {
    setIsModalSeen(appID);
    logAbTestAction({ action: 'seen', target });
    Tracking.enableFS({ ctAppNameIconModal_bool: true });
  }, []);

  useEffect(() => {
    modalRenderCountRef.current = modalRenderCountRef.current + 1;
    if (modalRenderCountRef.current === initialRenderCount + 1) {
      logAbTestAction({ action: 'appEdit', target });
    }
  }, [portalProps]);

  const handleCloseClick = useCallback(() => {
    uikitModalRef.current?.hide?.();
    logAbTestAction({ action: 'click', target: `closeButton-${target}` });
  }, []);

  const handleEditIconClick = useCallback(() => {
    setView(NavigationNames.AppLogoPropertyPanel);
    logAbTestAction({ action: 'click', target: `editButton-${target}` });
  }, []);

  const handleBackClick = useCallback(() => {
    setView(NavigationNames.AppIdentifierSettingsContainer);
    logAbTestAction({ action: 'click', target: `backButton-${target}` });
  }, []);

  const handleDoneClick = useCallback(() => {
    uikitModalRef.current?.hide?.();
    logAbTestAction({ action: 'click', target: `doneButton-${target}` });
  }, []);

  const CurrentView = Navigation[currentView];
  const isBackVisible = NavigationNames.AppLogoPropertyPanel === currentView;
  const isDoneVisible = NavigationNames.AppIdentifierSettingsContainer === currentView;
  const headerProps = headerPropsMap[currentView];
  const showAppIdentifierSettingsDefaultPanelHeader = false;

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      onModalClose={onClose}
      DialogRenderer={DialogRenderer}
      ContentRenderer={ContentRenderer}
      HeaderRenderer={props => (
        <HeaderRenderer
          {...props}
          {...headerProps}
          onCloseClick={handleCloseClick}
        />
      )}
      FooterRenderer={props => (
        <FooterRenderer
          {...props}
          isBackVisible={isBackVisible}
          isDoneVisible={isDoneVisible}
          onBackClick={handleBackClick}
          onDoneClick={handleDoneClick}
        />
      )}
    >
      <SCViewWrapper>
        <CurrentView
          {...portalProps}
          elementType='appIcon'
          iconSelectorTheme="light"
          showPanelHeader={showAppIdentifierSettingsDefaultPanelHeader}
          onEditIconClick={handleEditIconClick}
          onAppIconClick={handleEditIconClick}
        />
      </SCViewWrapper>
    </Modal>
  );
};

AppNameIconSettingsModal.propTypes = {
  onClose: func
};

AppNameIconSettingsModal.defaultProps = {
  onClose: f => f
};

export default AppNameIconSettingsModal;
