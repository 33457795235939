import Styled from 'styled-components';

const ScContainer = Styled.div`
    .chooseButton {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        text-transform: uppercase;
        border-radius: 2px;
        height: 38px;
        width: 100%;
        color: #fff;
        background-color: #626E81;
        border: 1px solid #303339;
    }
`;

export default ScContainer;
