import React from 'react';
import {
  func,
  bool,
  string,
  oneOfType,
  node,
  arrayOf,
  object
} from 'prop-types';
import keyboardJS from 'keyboardjs';

import BackButton from '../Button/BackButton';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.backButtonClick = this.backButtonClick.bind(this);
    this.handleEscapePressed = this.handleEscapePressed.bind(this);
  }

  componentDidMount() {
    keyboardJS.bind('esc', this.handleEscapePressed);
    this.titleRef.current?.focus();
  }

  componentWillUnmount() {
    keyboardJS.unbind('esc', this.handleEscapePressed);
  }

  handleEscapePressed(e) {
    const { backButtonVisible, isModalVisible, onModalClose } = this.props;
    e.preventDefault();
    e.stopImmediatePropagation();
    if (isModalVisible && onModalClose) {
      onModalClose();
      return false;
    }
    if (backButtonVisible) {
      this.backButtonClick();
      return false;
    }
    const { setView } = this.props;
    setView('Main', { closeWizard: true });
  }

  handleFillVariable(subTitle) {
    if (typeof subTitle !== 'string' || subTitle.length === 0) return subTitle;
    let filledSubTitle = subTitle;
    const variables = {
      TABLE_TEMPLATES_COUNT: 300,
      APP_TEMPLATES_COUNT: 600,
      appTemplatesCount: 600
    };
    Object.keys(variables).forEach(variableKey => {
      filledSubTitle = filledSubTitle.replace(`{${variableKey}}`, variables[variableKey]);
    });
    return filledSubTitle;
  }

  get htmlAttributes() {
    const uikitModalContainer = document?.querySelector?.("[data-uikit-modal-container='true']");
    const ariaLabelledBy = uikitModalContainer?.getAttribute('aria-labelledby');
    const ariaDescribedBy = uikitModalContainer?.getAttribute('aria-describedby');

    return { ariaLabelledBy, ariaDescribedBy };
  }

  get title() {
    const { ariaLabelledBy } = this.htmlAttributes;
    const { title, noDecodeURI } = this.props;
    return title && (
    <h1
      id={ariaLabelledBy} className="jfWizard-header-text jfWizard-header-title" tabIndex="0"
      ref={this.titleRef}
    >
      {noDecodeURI ? title : decodeURI(title)}
    </h1>
    );
  }

  get subTitle() {
    const { ariaDescribedBy } = this.htmlAttributes;
    const { subTitle, noDecodeURI, extraSubTitleClasses } = this.props;
    const filledSubtitle = this.handleFillVariable(subTitle);
    return subTitle && (
    <h2
      id={ariaDescribedBy} className={`jfWizard-header-text jfWizard-header-subtitle ${extraSubTitleClasses.join(' ')}`} tabIndex="0"
    >
      {noDecodeURI ? filledSubtitle : decodeURI(filledSubtitle)}
    </h2>
    );
  }

  get backButton() {
    const { backButtonVisible } = this.props;
    return backButtonVisible && <div className="jfWizard-header-back"><BackButton onClick={this.backButtonClick} /></div>;
  }

  backButtonClick() {
    const {
      onBackButtonClick, setView, actionLogger, backToMixModalActionLogger
    } = this.props;

    if (global.user && actionLogger) {
      actionLogger?.({ actor: global.user.username, action: 'wizardBackClick', target: '.forBack' });
    }

    if (onBackButtonClick) {
      backToMixModalActionLogger?.();
      return onBackButtonClick();
    }

    setView('Main');
  }

  render() {
    const { customStyles } = this.props;
    return (
      <div style={customStyles} className="jfWizard-header">
        {this.backButton}
        {this.title}
        {this.subTitle}
      </div>
    );
  }
}

Header.propTypes = {
  setView: func,
  actionLogger: func,
  onBackButtonClick: func,
  backButtonVisible: bool,
  isModalVisible: bool,
  onModalClose: func,
  noDecodeURI: bool,
  title: oneOfType([string, node]),
  extraSubTitleClasses: arrayOf(string),
  subTitle: oneOfType([string, node]),
  customStyles: object,
  backToMixModalActionLogger: func
};

Header.defaultProps = {
  setView: f => f,
  actionLogger: f => f,
  onBackButtonClick: null,
  backButtonVisible: false,
  isModalVisible: false,
  onModalClose: null,
  noDecodeURI: false,
  title: null,
  subTitle: null,
  extraSubTitleClasses: [],
  customStyles: {},
  backToMixModalActionLogger: f => f
};
