/* eslint-disable camelcase */
import DOMPurify from 'dompurify';
import { getAppPath } from '@jotforminc/router-bridge';
import uniq from 'lodash/uniq';
import Moment from 'moment';
import Tracking from '@jotforminc/tracking';
import { Utils } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { DOCUMENT_ITEM_TYPES, ITEM_TYPES } from './constants/itemTypes';
import { fonts } from './modules/Builder/components/FontPicker/fonts';
import { getDiffOfDates, momentToString } from './modules/Builder/components/Settings/utils';
import BrowserParser from './utils/BrowserParser';
import { RESET_PERIOD_VALUES } from './modules/Builder/components/HomePage/RightPanel/BadgeToggle/badgeToggleConstants';
import { BUTTON_ROLE_TYPES } from './modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import { FEATURE_NAMES } from './constants/features';
import { isFeatureEnabled } from './utils/features/helper';
import { TEAM_ID } from './constants/team';
import { overriddenFeaturedWidgetPropertiesMap } from './constants/availableWidgets';
import { RightPanelModes, getButtonShareDefMessages } from './modules/Builder/components/HomePage/constants';
import { OPTION_VALUES, SIDE_SELECTION_DEFAULTS } from './modules/Builder/components/HomePage/RightPanel/ListItemSideItemSelection/constants';
// TODO: make a queueble action with sagas.
// This is a fast initial version
export const sendTrackData = (actor, action, target) => {
  const allowedEnvs = ['PRODUCTION', 'ENTERPRISE'];
  if (!window.JotFormActions || !allowedEnvs.includes(window.JOTFORM_ENV)) {
    return;
  }
  if (!window.jotPortalEvents) {
    window.jotPortalEvents = window.JotFormActions('portal-app');
  }
  window.jotPortalEvents.tick({ actor, action, target });
};

// This list holds the actions that we dont want to track which related to other products/features
export const ignoredActions = [
  'newAssignWithLinkGenerated'
];

export const getUpdatedDate = (updatedAt, userTimeZone, showRawDate = false) => {
  let editDateTimeZone = updatedAt;
  if (userTimeZone !== undefined && userTimeZone !== null) {
    try {
      editDateTimeZone = new Date(updatedAt).toLocaleString('en-US', { timeZone: userTimeZone });
    } catch (e) { // eslint-disable-line
      editDateTimeZone = new Date(updatedAt); // temporarily fix, show date without time zone formatting
    }
  }
  const editDate = new Date(editDateTimeZone);

  // early return for rawDate
  if (showRawDate) return editDate;

  const currentDate = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  const date = {};
  if (currentDate.toDateString() === editDate.toDateString()) {
    let hour = editDate.getHours() > 12 ? editDate.getHours() - 12 : editDate.getHours();
    hour = hour === 0 ? 12 : hour;
    const minutes = `0${editDate.getMinutes()}`.slice(-2);
    date.today = `${hour}:${minutes} ${editDate.getHours() >= 12 ? 'pm.' : 'am.'}`;
  } else if (editDate.toDateString() === yesterday.toDateString()) {
    date.yesterday = true;
  } else {
    const timeString = editDate.toDateString().split(' ');
    date.oldDate = `${timeString[1]} ${timeString[2]}, ${timeString[3]}.`;
  }
  return date;
};

export const isYes = val => ((typeof val === 'string') ? ['Yes', '1'].includes(val) : !!val);

export const checkPageAvailability = page => {
  const { showPageOnNavigation } = page;
  const isDataSourceLinkedPage = !!page.linkedItemID;

  // Response: [availability (visibility on the app), errorMessage (shows on builder)]

  switch (true) {
    case !isYes(showPageOnNavigation) && !isDataSourceLinkedPage:
      return [true, t('This page is hidden from the navigation menu.')];
    default:
      return [true, null];
  }
};

export const encodeHTMLEntities = str => {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
};

export const sanitizeHTML = (data, settings = {}) => {
  return DOMPurify.sanitize(data, settings);
};

export const readURLHash = () => {
  return window.location.hash;
};

export const generateElement = (type, attrArr = [], targetSelector) => {
  const newEl = document.createElement(type);
  attrArr.forEach(attr => {
    const [key, value] = Object.entries(attr)[0];
    newEl.setAttribute(key, value);
  });
  document.querySelector(targetSelector).appendChild(newEl);
};

export const addGoogleFontsLinkToDOM = () => {
  const jotformBaseURL = '//cdn.jotfor.ms/fonts/?family=';
  const getGoogleFonts = fonts.filter(font => font.category === 'google').map(font => font.name);
  const generateFontLink = getGoogleFonts.map(font => `${font}`).join('|');

  if (!document.querySelector('#portal-fonts')) {
    generateElement(
      'LINK',
      [
        { id: 'portal-fonts' },
        { href: `${jotformBaseURL}${generateFontLink}` },
        { rel: 'stylesheet' }
      ],
      'HEAD'
    );
  }
};

export const getDocumentType = (fileType = '') => {
  switch (true) {
    case fileType.indexOf('image') !== -1: {
      return DOCUMENT_ITEM_TYPES.IMG;
    }
    case fileType.indexOf('application/pdf') !== -1: {
      return DOCUMENT_ITEM_TYPES.PDF;
    }
    case fileType.indexOf('sheet') > -1:
      return DOCUMENT_ITEM_TYPES.SHEET;
    case fileType.indexOf('msword') !== -1 || fileType.indexOf('officedocument') !== -1: {
      return DOCUMENT_ITEM_TYPES.DOC;
    }
    default: {
      return DOCUMENT_ITEM_TYPES.OTHER;
    }
  }
};

export const sendBreadcrumbsToSentry = properties => {
  const allowedAttributes = [
    'category',
    'message',
    'data',
    'level', // (fatal/error/warning/info/debug) Default: info,
    'type' // (default/debug/error/navigation/http/info/query/transaction/ui/user)  Default: default
  ];

  const breadcrumb = Object.keys(properties).reduce((prev, key) => {
    const val = properties[key];
    return (!val || !allowedAttributes.includes(key)) ? { ...prev } : { ...prev, [key]: val };
  }, {});
  Tracking.configureScope(scope => {
    scope.addBreadcrumb(breadcrumb);
  });
};

export const captureException = exception => {
  Tracking.captureException(exception);
};

export const getTinyMCEInstance = () => {
  const editors = global?.tinyMCE?.editors;
  return editors?.[editors.length - 1];
};

export const getTinyMCEContent = () => {
  try {
    return getTinyMCEInstance()?.getContent()?.replace(/\n(?=<)/g, '');
  } catch (e) {
    console.error('Error while getting TinyMCE content');
    return '';
  }
};

export const getTinyMCEGoogleFonts = () => {
  try {
    const elementsWithFonts = getTinyMCEInstance()?.getBody()?.querySelectorAll('*[style*="font-family"]') || [];
    const getGoogleFonts = fonts.filter(font => font.category === 'google').map(font => font.name);
    const elementsWithGoogleFonts = Array.from(elementsWithFonts).filter(font => getGoogleFonts.includes(font.style.fontFamily));
    const fontFamilies = elementsWithGoogleFonts.map(font => font.style.fontFamily);
    return uniq(fontFamilies);
  } catch (e) {
    console.error('Error while getting TinyMCE google fonts');
    return [];
  }
};

// param values are important since cardforms renders form according to this info
export const FORCE_MOBILE_DEVICE_PARAM = ['forceMobileDevice', '1'];
export const shouldForceMobile = () => {
  const url = new URL(window.location.href);
  const [key, val] = FORCE_MOBILE_DEVICE_PARAM;
  const forceMobileVal = url.searchParams.get(key);
  return forceMobileVal === val;
};

export const checkHasMobileWidth = () => window.innerWidth <= 480;
export const checkHasTabletWidth = () => window.innerWidth <= 1024;
export const checkMobilePhone = () => (BrowserParser.is('mobile') && !BrowserParser.is('tablet')) || shouldForceMobile() || window.innerWidth <= 480;
export const checkTablet = () => (BrowserParser.is('tablet') || window.innerWidth <= 768) && !BrowserParser.is('mobile');
export const checkMacOSx = () => BrowserParser.getOSName() === 'macOS';
export const checkMobileOrTablet = () => checkMobilePhone() || checkTablet();
export const isApple = () => BrowserParser.is('ios') || BrowserParser.is('safari');
export const isIosSafari = () => BrowserParser.is('ios') && BrowserParser.is('safari');
export const getUserAgentPlatformType = () => BrowserParser.getPlatformType(true) || 'desktop';
export const isInJotformKiosk = () => !!window.sendMessageToJFMobile;

const allowedProtocols = ['http:', 'https:', 'ftp:', 'tel:', 'sms:', 'mailto:', 'ms-excel:'];

export const isValidLinkTarget = targetURL => {
  const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
  const phoneNumberRegex = /\+?[0-9]{1,15}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validators = {
    'http:': ({ hostname }) => hostname.match(domainRegex),
    'https:': ({ hostname }) => hostname.match(domainRegex),
    'ftp:': ({ hostname }) => hostname.match(domainRegex),
    'tel:': ({ pathname }) => pathname.match(phoneNumberRegex),
    'sms:': ({ pathname }) => pathname.match(phoneNumberRegex),
    'mailto:': ({ pathname }) => pathname.match(emailRegex)
  };

  try {
    const url = new URL(targetURL);
    const { protocol } = url;

    if (validators[protocol]) {
      return validators[protocol](url);
    }

    return allowedProtocols.includes(protocol);
  } catch (e) {
    return false;
  }
};

export const isValidLink = urlString => {
  try {
    const url = new URL(urlString);

    return allowedProtocols.includes(url.protocol);
  } catch (e) {
    return false;
  }
};

export const doesTargetLinkContainsProtocol = targetURL => {
  const protocolRegex = new RegExp(`^(${allowedProtocols.join('|')})`, 'gi');
  return protocolRegex.test(targetURL);
};

export const checkAndAddProtocolToTargetLink = targetURL => {
  if (!doesTargetLinkContainsProtocol(targetURL)) return `https://${targetURL}`;

  return targetURL;
};

export const getAppLinkWithUTM = (appLink, appID, source) => {
  const linkWithUTM = new URL(appLink);
  linkWithUTM.searchParams.append('utm_source', source);
  linkWithUTM.searchParams.append('utm_medium', 'website');
  linkWithUTM.searchParams.append('utm_campaign', 'portal-app');
  linkWithUTM.searchParams.append('utm_term', appID);
  return linkWithUTM.toString().replace('%3D', '=');
};

export const isMultiSelection = event => {
  const { metaKey, ctrlKey, shiftKey } = event || {};
  return metaKey || ctrlKey || shiftKey;
};

export const vibrate = (duration = 200) => {
  const { navigator } = window;
  const vibrator = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
  if (vibrator) {
    vibrator.apply(navigator, [duration]);
  }
};

export const getFormCompletion = ({
  lastSubmitted: _lastSubmitted, showBadge, clearBadgeOn, clearBadgePeriod, required_showBadge, timeZone, progressRestartDate
}) => {
  if (!_lastSubmitted) return false;

  if (!isYes(required_showBadge) && !isYes(showBadge)) return false;

  const lastSubmitted = getUpdatedDate(new Date(`${_lastSubmitted?.replace(/-/g, '/')} GMT-0500`).getTime(), timeZone, true);

  if (progressRestartDate && isYes(required_showBadge)) {
    const modifiedRestartDate = getUpdatedDate(new Date(`${progressRestartDate?.replace(/-/g, '/')} GMT-0500`).getTime(), timeZone, true);
    const restartDate = momentToString(Moment(modifiedRestartDate), true);
    const restartDiff = getDiffOfDates(false, restartDate, momentToString(Moment(lastSubmitted), true), true);
    if (restartDiff > 0) return false;
  }

  const now = new Moment();
  switch (clearBadgePeriod) {
    case RESET_PERIOD_VALUES.NEVER: // wont be cleared, so show
      return true;

    case RESET_PERIOD_VALUES.DAILY:
      const resetDate = `${Moment(lastSubmitted).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`;
      const diff = getDiffOfDates(false, momentToString(now, true), resetDate);
      if (diff > 0) return false;
      return true;

    case RESET_PERIOD_VALUES.WEEKLY:
      const lastSubmittedDay = Moment(lastSubmitted);
      const lastDiff = getDiffOfDates(false, momentToString(now, true), momentToString(lastSubmittedDay));
      const hasPast7Days = Moment.duration(Math.abs(lastDiff), 'milliseconds').asDays() >= 7;
      if (lastDiff > 0 && hasPast7Days) return false;

      const _resetDay = Moment().day(clearBadgeOn);
      const isDatePassed = _resetDay.isBefore(lastSubmitted);
      const resetDay = isDatePassed ? Moment(_resetDay).add(7, 'days') : Moment(_resetDay);
      const resetDiff = getDiffOfDates(false, momentToString(now, true), `${Moment(resetDay).format('YYYY-MM-DD')} 00:00:00}`);
      if (resetDiff > 0) return false;

      return true;

    default:
      return true;
  }
};
export const isItemTypeWidget = itemType => itemType.startsWith(ITEM_TYPES.WIDGET);

export const updateAndOverrideButtonProperties = ({
  defaultTexts,
  currentItem,
  updatingProps,
  userInfo,
  isTitleFormTitle,
  isTitleDefault,
  navigationDefaultPage
}) => {
  const { buttonRole } = updatingProps;
  const { email, phone, phoneNumberVerified } = userInfo;
  const { title } = currentItem;

  let newProps = {};

  switch (buttonRole) {
    case BUTTON_ROLE_TYPES.MAIL:
      const newButtonValue = { buttonValue: email };
      newProps = { ...newProps, ...newButtonValue };
      break;
    case BUTTON_ROLE_TYPES.PHONE:
      newProps = { ...newProps, buttonValue: (isYes(phoneNumberVerified) && phone) ? phone : '' };
      break;
    case BUTTON_ROLE_TYPES.NAVIGATION:
      newProps = { ...newProps, buttonValue: navigationDefaultPage };
      break;
    case BUTTON_ROLE_TYPES.SHARE:
      newProps = { ...newProps, buttonValue: JSON.stringify({ type: 'appShare', text: getButtonShareDefMessages().appShare }) };
      break;
    default:
      newProps = { ...newProps, buttonValue: '' };
      break;
  }

  const { end = {} } = currentItem;
  if (end?.type === OPTION_VALUES.BUTTON
      && (end?.data?.text === SIDE_SELECTION_DEFAULTS[OPTION_VALUES.BUTTON].text || Object.values(defaultTexts).includes(end?.data?.text))) {
    return { ...newProps, end: { ...end, data: { text: t(defaultTexts[buttonRole]) } } };
  }

  if (!currentItem.type === ITEM_TYPES.BUTTON && (!title || Object.values(defaultTexts).includes(title) || isTitleDefault || isTitleFormTitle)) {
    return { ...newProps, title: t(defaultTexts[buttonRole]) };
  }

  return newProps;
};

export const utmParser = scheme => {
  return Object.keys(scheme)
    .map(key => `utm_${key}=${encodeURIComponent(scheme[key])}`)
    .join('&');
};

export const getBaseURL = () => {
  // TODO will use origin for now you can refactor it there's a need
  return window.location.origin;
};

export const getAppSubdomainBaseURL = () => {
  // TODO will use hardcoded value for now but same as above you can refactor if there's a need
  return 'https://app.jotform.com';
};

export const isAppSubdomain = () => {
  return window.location.host === 'app.jotform.com' || window.location.host === 'app3.jotform.com';
};

export const transformJotformURLToSubdomainURL = url => {
  return url.replace(getAppPath(), '').replace('www', 'app');
};

export const slugifyURL = (url, appID, userSlug, appSlug) => {
  return url.replace(appID, `${userSlug}/${appSlug}`);
};

export const isUserWhiteLabeled = user => {
  const { showJotFormPowered = '0', account_type: accountType = 'GUEST' } = user;
  const freeAccountTypes = ['FREE', 'GUEST'];
  const type = accountType.name || accountType;
  const isPaidUser = !(freeAccountTypes.includes(type));

  return [window.JOTFORM_ENV !== 'ENTERPRISE' && (showJotFormPowered === '1' || !isPaidUser), isPaidUser];
};

export const disableShrinkFuncs = {
  [ITEM_TYPES.CARD_ITEM]: ({ cardLayout = null }) => {
    return checkHasMobileWidth() && cardLayout === 'horizontal';
  }
};

export const isShrinkEnabled = ({
  type, shrink, embeddedForm, isMobile, clientID, componentProps, isBuilder = true
}) => {
  const isEmbeddedForm = type === ITEM_TYPES.FORM && isYes(embeddedForm);
  const isWidgetOnMobile = (type === ITEM_TYPES.WIDGET && clientID !== 'e5cea8c176512adc41b76621' && isMobile); // excludes whatsapp widget
  const disableShrinkFunc = disableShrinkFuncs[type];
  if (isYes(shrink) && !isBuilder && disableShrinkFunc) {
    return !disableShrinkFunc(componentProps);
  }
  return isYes(shrink) && !isEmbeddedForm && !isWidgetOnMobile;
};

// From builder
export const guessCustomURL = title => {
  const spaceCleaner = new RegExp('( )', 'g');
  const titleCleaner = new RegExp('([^a-zA-Z0-9-_])', 'g');

  const lowerCaseTitle = title.toLowerCase();
  const titleWithoutSpace = lowerCaseTitle.replace(spaceCleaner, '-');
  const cleanTitle = titleWithoutSpace.replace(titleCleaner, '');
  return cleanTitle.substr(0, 35);
};

export const getTeamID = () => TEAM_ID;

export const isTeamResourcePicker = () => isFeatureEnabled(FEATURE_NAMES.TeamResourcePicker) && !!getTeamID();

export const handleSelectAllText = ({ target }) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(target);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(target);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const getTargetResourceIDByItem = ({
  id, buttonValue, resourceID, formID, type
}) => {
  const resourceIDMap = {
    [ITEM_TYPES.FORM]: id,
    [ITEM_TYPES.BUTTON]: buttonValue,
    [ITEM_TYPES.CARD_ITEM]: buttonValue,
    [ITEM_TYPES.IMAGE]: buttonValue,
    [ITEM_TYPES.TABLE_LINK]: resourceID,
    [ITEM_TYPES.REPORT_LINK]: resourceID,
    [ITEM_TYPES.SENTBOX_LINK]: resourceID,
    [ITEM_TYPES.SIGN_LINK]: formID,
    [ITEM_TYPES.LIST]: resourceID
  };

  return resourceIDMap[type];
};

export const getWidgets = _widgets => _widgets.map(widget => {
  const { client_id: clientID, name } = widget;
  const { icon: assignedIcon, name: assignedName } = overriddenFeaturedWidgetPropertiesMap[clientID];
  const draggableWidgetElementID = `${ITEM_TYPES.WIDGET}_${clientID}`;

  return {
    type: draggableWidgetElementID,
    title: assignedName || name,
    icon: assignedIcon
  };
});

export const changeDomainWithCurrent = url => {
  try {
    const iconUrlObj = new URL(url);
    iconUrlObj.hostname = window.location.hostname;
    return iconUrlObj.toString();
  } catch (e) {
    return url;
  }
};

export const parseAndGenerateOptionProperties = (str = '', type) => {
  const properties = str.split('\n');
  return properties
    .map(prop => ({ text: prop, value: type === 'quantity' ? parseInt(prop, 10) : prop }))
    // min value must be bigger than zero for quantity option
    .filter(({ value }) => type !== 'quantity' || value > 0);
};

export const actAsButton = handler => ({
  role: 'button',
  tabIndex: 0,
  onClick: handler,
  onKeyDown: e => (Utils.isPressedKeyEnter(e) && handler(e))
});

export const isTestingEnv = () => window.JOTFORM_ENV === 'TESTING';

export const getAlternativeDomains = () => (Array.isArray(window?.customizedConfigs?.ALTERNATIVE_DOMAINS) ? [...window.customizedConfigs.ALTERNATIVE_DOMAINS, window.companyDomain] : []);

export const tabVisibilityCheck = tabName => {
  const tabConfigMapping = {
    payments: 'HIDE_PAYMENTS_TAB',
    widgets: 'HIDE_WIDGETS_TAB'
  };

  try {
    const configValue = window?.customizedConfigs?.[tabConfigMapping[tabName]];
    const isVisibleTab = (isEnterprise()
                          && typeof configValue === 'object'
                          && Array.isArray(configValue)
                          && configValue.includes('formBuilder')) ?? false;

    return !isVisibleTab;
  } catch (e) {
    console.error(`Error checking visibility for tab "${tabName}":`, e);
    return true;
  }
};

export const getRightPanelModeByItemType = itemType => {
  switch (itemType) {
    case ITEM_TYPES.WIDGET:
      return RightPanelModes.APP_WIDGET;
    case ITEM_TYPES.CONTACT_INFORMATION:
      return RightPanelModes.CONTACT_INFORMATION_SETTINGS;
    default:
      return RightPanelModes.APP_ITEM;
  }
};

export const isNewPanel = () => true;
