import { SET_USER_MANAGEMENT_USERS } from '../actionTypes';

const initialState = {
  users: [],
  sheetID: null,
  sheetViewID: null
};

const UserManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_MANAGEMENT_USERS:
      const { users, sheetID, sheetViewID } = action.payload;
      return {
        ...state,
        users,
        sheetID,
        sheetViewID
      };
    default:
      return state;
  }
};

export default UserManagementReducer;
