import { t } from '@jotforminc/translation';
import React from 'react';
import Styled from 'styled-components';
import { Button } from '@jotforminc/magnet';

export const ScFooterRenderer = Styled.div`
  background-color: #F3F3FE;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  text-align: center;
  height: 72px;
  padding: 16px 32px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ECEDFB;

  .taste {
    text-transform: capitalize;
    font-weight: 400;
  }
`;

const FooterRenderer = (onClose, onItemSettingsClick) => () => {
  const handleOnCustomizeClick = () => {
    onItemSettingsClick();
    onClose();
  };

  return (
    <ScFooterRenderer>
      <Button
        onClick={handleOnCustomizeClick}
      >
        {t('Customize')}
      </Button>
    </ScFooterRenderer>
  );
};

export default FooterRenderer;
