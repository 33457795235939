import * as actionTypes from '../actionTypes';
import { ISellProductsItem } from '../../types/common';

const activeProductReducer = (state: Partial<ISellProductsItem> = {}, action: IAction) : Partial<ISellProductsItem> => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_PRODUCT:
      return action.payload.value;
    case actionTypes.UPDATE_ACTIVE_PRODUCT:
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

interface IPayload {
  prop: keyof ISellProductsItem
  propValue: ISellProductsItem[keyof ISellProductsItem]
  value: ISellProductsItem,
  data: Partial<ISellProductsItem>
}
interface IAction {
  type: string,
  payload: IPayload
}

export default activeProductReducer;
