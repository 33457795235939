import React from 'react';
import { array } from 'prop-types';
import { SettingsPanelWrapper, SettingsPanelListItem } from '@jotforminc/panel-components';
import NavigationPanelItem from './NavigationPanelItem';
import ScNavigationPanel from './ScNavigationPanel';
import { useIfMobileSettings } from '../../../../utils/navigation';

const NavigationPanel = ({
  navigationProperties
}) => {
  const renderNavigationPanel = () => {
    return navigationProperties?.map(navigationItemProps => {
      return (
        <SettingsPanelListItem key={navigationItemProps.key}>
          <NavigationPanelItem
            {...navigationItemProps}
          />
        </SettingsPanelListItem>
      );
    });
  };

  const isMobileSubPage = useIfMobileSettings();

  return (
    <ScNavigationPanel
      className="navigationPanel newPanel"
      isMobileSubPage={isMobileSubPage}
    >
      <SettingsPanelWrapper>
        {renderNavigationPanel()}
      </SettingsPanelWrapper>
    </ScNavigationPanel>
  );
};

NavigationPanel.propTypes = {
  navigationProperties: array // eslint-disable-line
};

NavigationPanel.defaultProps = {
  navigationProperties: array
};

export default NavigationPanel;
