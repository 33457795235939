import React, { useCallback, useMemo, useState } from 'react';
import {
  elementType, func, number, oneOfType, string
} from 'prop-types';
import DefaultItemRenderer from './defaults/DefaultItemRenderer';
import DefaultContainerRenderer from './defaults/DefaultContainerRenderer';

const Rating = ({
  scale,
  value,
  onChange,
  ItemRenderer,
  ContainerRenderer
}) => {
  const [hoveredIndex, setHoveredIndex] = useState();

  const items = useMemo(() => Array.from(new Array(scale), f => f), [scale]); // eslint-disable-line prefer-spread

  const valueCoversIndex = useCallback(itemIndex => { // itemIndices are 1 based!
    if (!value) return false;

    const numericVal = Number(value);
    return !Number.isNaN(value) && itemIndex <= numericVal;
  }, [value]);

  const handleMouseEnter = useCallback(hoveredIdx => setHoveredIndex(hoveredIdx), []);
  const handleMouseLeave = useCallback(() => setHoveredIndex(), []);

  return (
    <ContainerRenderer onMouseLeave={handleMouseLeave}>
      {items.map((nothing, index) => {
        const itemIndex = index + 1;

        return (
          <ItemRenderer
            onMouseEnter={handleMouseEnter}
            key={itemIndex - 1}
            index={itemIndex}
            hovered={itemIndex <= hoveredIndex}
            covered={valueCoversIndex(itemIndex)}
            onChange={onChange}
          />
        );
      })}
    </ContainerRenderer>
  );
};

Rating.propTypes = {
  scale: number.isRequired,
  value: oneOfType([string, number]),
  onChange: func,
  ItemRenderer: elementType,
  ContainerRenderer: elementType
};

Rating.defaultProps = {
  value: '',
  onChange: f => f,
  ItemRenderer: DefaultItemRenderer,
  ContainerRenderer: DefaultContainerRenderer
};

export default Rating;
