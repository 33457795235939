/* eslint-disable complexity */
import React from 'react';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import { MODE_INDICATORS } from '../../Constants';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';

// COMPONENTS
import AddConnectionButton from './AddConnectionButton';
import SectionHeader from './SectionHeader';
import GatewayNotConnectedWarning from './GatewayNotConnectedWarning';
import ConnectionInfo from './ConnectionInfo';
import ConnectionStatusWarning from './ConnectionStatusWarning';
import ConnectionOwnerIndicator from './ConnectionOwnerIndicator';
import ConnectionModalButton from './ConnectionModalButton';
import RemoveConnectionButton from './RemoveConnectionButton';
import ConnectionGatewayInfo from './ConnectionGatewayInfo';
import AdditionalSetupRequiredDialog from '../AdditionalSetupRequiredDialog';
import GatewayDisabledWarning from './GatewayDisabledWarning';

const Divider = () => <div className='h-px bg-gray-500 mt-3 mb-3' />;

const PaymentConnectionSection = (): JSX.Element => {
  const {
    connectionInfo,
    hasConnection,
    gatewayType,
    isCollaborator,
    isJotformerAccessingUserResource,
    user,
    isInTeamAsset,
    showAdditionalSetupDialog,
    setShowAdditionalSetupDialog,
    settingConfigModeState
  } = usePaymentPropsPanel();

  const hasPaymentGateway = gatewayType !== 'control_payment';

  const modeIndicator = MODE_INDICATORS.find(item => item.sandbox === connectionInfo.sandbox);
  const disabledBtnClass = 'opacity-40 cursor-not-allowed';

  const isConnectionFailed = connectionInfo.status === '3'; // || connectionInfo.status === '5'

  // connection owner indicator
  const hasConnScope = connectionInfo.scope && connectionInfo.scope_fullname;
  const notConnOwner = (hasConnScope && user.username !== connectionInfo.scope_name) || false;
  const isUserConnectionOwner = (hasConnScope && connectionInfo.scope === 'user' && user.username === connectionInfo.scope_name) || false;
  const showConnOwnerIndicator = hasConnection && hasConnScope && (isInTeamAsset || notConnOwner);

  return (
    <div className={`paymentConnectionSection ml-4 mr-4 mt-6 ${settingConfigModeState.active ? 'opacity-40' : ''}`}>
      <SectionHeader />
      {
        hasPaymentGateway ? (
          <div className="bg-gray-700 p-4 radius">
            <div className="flex flex-row h-10 items-center justify-between">
              <ConnectionGatewayInfo />
              <div className='shrink-0'>
                <ConnectionModalButton
                  disabledBtnClass={disabledBtnClass}
                />
                <RemoveConnectionButton
                  disabledBtnClass={disabledBtnClass}
                />
              </div>
            </div>
            {
              !hasConnection && !isCollaborator && !isJotformerAccessingUserResource && (
                <GatewayNotConnectedWarning />
              )
            }
            {
              hasConnection && modeIndicator && (
                <>
                  <Divider />
                  <ConnectionInfo
                    modeIndicator={modeIndicator}
                    connectionInfo={connectionInfo}
                    disabled={isConnectionFailed}
                  />
                  {
                    isEnterprise() && connectionInfo.is_gateway_disabled && (
                      <GatewayDisabledWarning />
                    )
                  }
                </>
              )
            }
            {
              isConnectionFailed && (
                <ConnectionStatusWarning
                  isUserConnectionOwner={isUserConnectionOwner}
                  notConnOwner={notConnOwner}
                  connectionInfo={connectionInfo}
                />
              )
            }
            {
              showConnOwnerIndicator && (
                <ConnectionOwnerIndicator
                  connectionInfo={connectionInfo}
                />
              )
            }
            {
              showAdditionalSetupDialog && (
                <AdditionalSetupRequiredDialog
                  onClose={() => setShowAdditionalSetupDialog(false)}
                  showDialog={showAdditionalSetupDialog}
                />
              )
            }
          </div>
        ) : (
          <AddConnectionButton disabledBtnClass={disabledBtnClass} />
        )
      }
    </div>
  );
};

export default PaymentConnectionSection;
