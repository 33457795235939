import { createSelector } from 'reselect';
import { APP_MODES, CHECKOUT_FORM_STATUSES } from '../../constants';
import { checkMobilePhone } from '../../utils';
import { RightPanelModes } from '../../modules/Builder/components/HomePage/constants';
import { BUILDER_TABS } from '../../constants/navigation';

export const getUIState = state => state.ui || {};
export const getAppDesignModeSelector = createSelector(getUIState, ui => ui.appDesignMode);
export const getRightPanelModeSelector = createSelector(getUIState, ui => ui.rightPanelMode);
export const getAppModeSelector = createSelector(getUIState, ui => ui.mode);
export const getIsBuilder = createSelector(getAppModeSelector, mode => mode === APP_MODES.builder);
export const isOnlineSelector = createSelector(getUIState, ui => ui.isOnline);
export const isOpenedInApp = createSelector(getUIState, ui => ui.isOpenedInPWA);
export const isAppInstalled = createSelector(getUIState, ui => ui.isPWAInstalled);
export const isAppReadySelector = createSelector(getUIState, ui => ui.isAppReady);
export const isRightPanelOpenSelector = createSelector(getUIState, ui => ui.isRightPanelOpen);
export const isPreviewPanelOpenSelector = createSelector(getUIState, ui => ui.isPreviewPanelOpen);
export const getIsLivePreviewOn = createSelector(getUIState, ui => ui.isLivePreviewOn);
export const areAPIRequestsCompletedSelector = createSelector(getUIState, ui => ui.areAPIRequestsCompleted);
export const isLeftPanelOpenSelector = createSelector(getUIState, ui => ui.isLeftPanelOpen);
export const getEnvironmentSelector = createSelector(getUIState, ui => ui.environment);
export const customErrorTextSelector = createSelector(getUIState, ui => ui.customErrorText);
export const isAppFailedSelector = createSelector(getUIState, ui => ui.error);
export const getSelectedPage = createSelector(getUIState, ui => ui.selectedPages[0]);
export const getLastInteractedPageID = createSelector(getUIState, ui => ui.lastInteractedPageID);
export const getNavigationSettingsPopoverId = createSelector(getUIState, ui => ui.navigationSettingsPopoverId);
export const getAppLocation = createSelector(getUIState, ui => ui.appLocation);
export const getRouterAnimationType = createSelector(getUIState, ui => ui.routerAnimationType);
export const getSelectedPortalItems = createSelector(getUIState, ui => ui.selectedItems);
export const getSelectedPortalItem = createSelector(getSelectedPortalItems, ([selectedPortalItem]) => selectedPortalItem);
export const getSelectedPortalItemsCount = createSelector(getSelectedPortalItems, selectedPortalItems => selectedPortalItems.length);
export const getIsMultipleItemsSelected = createSelector(getSelectedPortalItemsCount, selectedItemCount => selectedItemCount > 1);
export const getIsItemSelected = itemID => createSelector(getSelectedPortalItems, selectedPortalItems => selectedPortalItems.some(id => id === itemID));
export const getSelectedMultipleItems = createSelector(getUIState, ui => ui.selectedItems);
export const getMobileMultipleSelectionMode = createSelector(getUIState, ui => ui.isMobileMultipleSelectionMode);
export const getCurrentStep = createSelector(getUIState, ui => ui.currentStep);
export const getCurrentSubTab = createSelector(getUIState, ui => ui.currentSubTab);
export const shouldSqueezeSelector = createSelector(getUIState, ui => ui.shouldSqueeze);
export const appPreviewStatusSelector = createSelector(getUIState, ui => ui.appPreviewStatus);
export const appPreviewDeviceSelector = createSelector(getUIState, ui => ui.appPreviewDevice);
export const getAppHeaderCropState = createSelector(getUIState, ui => ui.isAppHeaderInCropMode);
export const getAppCoverCropState = createSelector(getUIState, ui => ui.isAppCoverInCropMode);
export const getLoadingItems = createSelector(getUIState, ui => ui.loadingItems);
export const getItemLoadingStatusByID = itemID => createSelector(getLoadingItems, loadingItems => loadingItems[itemID]);
export const getIsSelectedItemLoadingStatus = createSelector([getLoadingItems, getSelectedPortalItem], (loadingItems, itemID) => loadingItems[itemID]);
export const isAppsFetchedSelector = createSelector(getUIState, ui => ui.appsFetched);
export const isUIBlockedSelector = createSelector(getUIState, ui => ui.isUIBlocked);
export const getLastAddedItemIDSelector = createSelector(getUIState, ui => ui.lastAddedItemID);
export const getForceTitleRenameSelector = createSelector(getUIState, ui => ui.forceTitleRename);
export const getCheckoutFormStatus = createSelector(getUIState, ({ checkoutFormStatus }) => checkoutFormStatus);
export const getCheckoutFormIsCompleted = createSelector(getCheckoutFormStatus, checkoutFormStatus => checkoutFormStatus === CHECKOUT_FORM_STATUSES.COMPLETED);
export const getCheckoutFormIsLoading = createSelector(getCheckoutFormStatus, checkoutFormStatus => [CHECKOUT_FORM_STATUSES.SUBMITTED, CHECKOUT_FORM_STATUSES.COMPLETED].includes(checkoutFormStatus));
export const getCheckoutFormVisibility = createSelector(getUIState, ({ checkoutFormVisibility }) => checkoutFormVisibility);
export const getAppToastStack = createSelector(getUIState, ui => ui.appToastStack);
export const selectAccountBoxStatus = createSelector(getUIState, ui => ui.accountBoxStatus);
export const getActiveProductDetails = createSelector(getUIState, ({ activeProductDetail }) => activeProductDetail);
export const getActiveScreen = createSelector(getUIState, ({ activeScreen = '' }) => activeScreen);
export const getActiveFormProduct = createSelector(getUIState, ({ activeFormProduct = '' }) => activeFormProduct);
export const getIsMobileCtxMenuOpened = createSelector(getUIState, ({ mobileContextMenuOpened }) => mobileContextMenuOpened);
export const getIsNavigationPanelItemActive = item => createSelector(getCurrentSubTab, currentSubTab => item === currentSubTab && !checkMobilePhone());
export const getAppNavigationBarSelected = createSelector(getUIState, ({ rightPanelMode, isRightPanelOpen }) => {
  return isRightPanelOpen && rightPanelMode === RightPanelModes.MENU_SETTINGS;
});

export const getIsAppHeaderSelected = createSelector(getUIState, ({ rightPanelMode, isRightPanelOpen }) => {
  return isRightPanelOpen && [RightPanelModes.APP_HEADER, RightPanelModes.APP_LOGO, RightPanelModes.APP_STYLE].includes(rightPanelMode);
});
export const getActiveModal = createSelector(getUIState, ui => ui.activeModal);
export const getIsBuildTab = createSelector(getCurrentStep, currentStep => currentStep === BUILDER_TABS.BUILD);
export const getMultipleSelectionSubHeaderVisible = createSelector(
  [getMobileMultipleSelectionMode, getSelectedPortalItemsCount],
  (isMobileMultipleSelectMode, selectedPortalItemsCount) => selectedPortalItemsCount >= 2 || (isMobileMultipleSelectMode && selectedPortalItemsCount >= 1)
);
export const getIsParagraphInlineEditMode = itemID => createSelector(getUIState, ui => ui.isParagraphInlineEditMode === itemID);
export const getIsItemInlineEditMode = createSelector(
  [getSelectedPortalItemsCount, getMobileMultipleSelectionMode],
  (selectedItemCount, isMobileMultipleSelectMode) => selectedItemCount <= 1 && !isMobileMultipleSelectMode
);
export const getUIProp = key => createSelector(getUIState, ui => ui[key]);
export const getLastPanelOpened = createSelector(getUIState, ui => ui.lastOpenedPanel);
export const getActiveRightPanelTab = createSelector(getUIState, ui => ui.activeRightPanelTab);
