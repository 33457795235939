import type { GATEWAY_DEFAULT_SANDBOX_PROP_NAMES } from '../types';

/**
 * Default sandbox property names for a payment gateway.
 * These property names are used to configure the sandbox environment.
 * @type {GATEWAY_DEFAULT_SANDBOX_PROP_NAMES}
 */
export const gatewayDefaultSandboxPropNames: GATEWAY_DEFAULT_SANDBOX_PROP_NAMES = [
  'allowTest',
  'environment',
  'sandbox',
  'testMode',
  'testmodeAuth'
];
