import React from 'react';
import { SVGIcon as SVGIconLib } from '@jotforminc/icon-selector';
import PropTypes from 'prop-types';
import { changeDomainWithCurrent } from '../../utils';

const SVGIcon = ({ url, ...props }) => <SVGIconLib url={changeDomainWithCurrent(url)} {...props} />;

SVGIcon.propTypes = {
  url: PropTypes.string.isRequired
};

export default SVGIcon;
