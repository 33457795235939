import React, { FunctionComponent } from 'react';
import { t } from '@jotforminc/translation';
import cx from 'classnames';
import PriceInput from './PriceInput';
import { ICurrency } from '../../types/common';

type InputRangeType = {
  currencyInfo: ICurrency,
  minLimit: string,
  maxLimit: string,
  onCustomChange: ({ key, value }: { key: string, value: string }) => void,
  inputClass: string,
  minKey?: string,
  maxKey?: string
}

const InputRange: FunctionComponent<InputRangeType> = ({
  onCustomChange, minLimit, maxLimit, currencyInfo, inputClass, minKey = '', maxKey = ''
}) => {
  const handleChange = (key: string) => (value: string | number) => {
    onCustomChange({ key, value: value as string });
  };
  return (
    <div className={cx('flex justify-between gap-4', inputClass)}>
      <div className='w-1/2 sm:w-full'>
        <PriceInput
          currencyInfo={currencyInfo}
          onChange={handleChange(currencyInfo ? 'minCustomAmountLimit' : minKey)}
          min={0}
          max={maxLimit}
          description={t('Minimum')}
          value={minLimit}
        />
      </div>
      <div className='w-1/2 sm:w-full'>
        <PriceInput
          currencyInfo={currencyInfo}
          onChange={handleChange(currencyInfo ? 'maxCustomAmountLimit' : maxKey)}
          min={minLimit || 0}
          description={t('Maximum')}
          value={maxLimit}
        />
      </div>
    </div>
  );
};

InputRange.defaultProps = {
  minKey: 'minsize',
  maxKey: 'maxsize'
};

export default InputRange;
