import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selectors from '../../../store/selectors';
import * as actionCreators from '../../../store/actionCreators';
import { updateAllProductListSettingsOnProduct } from '../../../api';
import { PaymentSettings } from '../../../NewComponents';

type PaymentSettingsPanelType = {
  appName: string;
  appID: string;
  isPaymentOverQuota: boolean;
  isDonationItemAvailable: boolean;
  sendActionTrackEvent: () => void;
  useFastCheckout: string;
  onUseFastCheckoutChange: (useFastCheckout: string) => void;
  onAddPaymentIntegrationBtnClick: () => void;
  onEditPaymentIntegrationBtnClick: () => void;
  onDeletePaymentIntegrationBtnClick: () => void;
}

const PaymentSettingsPanel = ({
  appName,
  appID,
  isPaymentOverQuota,
  isDonationItemAvailable,
  sendActionTrackEvent,
  useFastCheckout,
  onUseFastCheckoutChange,
  onAddPaymentIntegrationBtnClick,
  onEditPaymentIntegrationBtnClick,
  onDeletePaymentIntegrationBtnClick
} : PaymentSettingsPanelType): JSX.Element => {
  const activeGatewaySettings = useSelector(Selectors.getActiveGatewaySettings);
  const productListSettingsFromStore = useSelector(Selectors.getProductListSettings);
  // TODO :: GET LAYOUT SETTINGS ONLY AS KEY-VAL

  const dispatch = useDispatch();

  const onChange = (_: any, data: { [key: string]: string}) => {
    if (data.key === 'useFastCheckout') {
      onUseFastCheckoutChange(data.value);
      return;
    }
    const props = { [data.key]: data.value };
    updateAllProductListSettingsOnProduct(appID, { platform: appName, questionProperties: props })
      .then(res => {
        if (res) {
          dispatch(actionCreators.updateProductListSettings(props));
        }
      }).catch((err: Error) => {
        console.error(err);
      });
  };

  const elementProps = {
    currency: productListSettingsFromStore.currency,
    useDecimal: productListSettingsFromStore.useDecimal,
    decimalMark: productListSettingsFromStore.decimalMark,
    ...useFastCheckout ? { useFastCheckout } : {}
  };

  return (
    <div className="productSettings">
      <PaymentSettings
        resourceType='APP'
        elementProps={elementProps}
        isPaymentOverQuota={isPaymentOverQuota}
        onChange={onChange}
        activeGatewaySettings={activeGatewaySettings}
        isDonationItemAvailable={isDonationItemAvailable}
        sendActionTrackEvent={sendActionTrackEvent}
        onAddPaymentIntegrationBtnClick={onAddPaymentIntegrationBtnClick}
        onEditPaymentIntegrationBtnClick={onEditPaymentIntegrationBtnClick}
        onDeletePaymentIntegrationBtnClick={onDeletePaymentIntegrationBtnClick}
      />
    </div>
  );
};

export default PaymentSettingsPanel;
