import React from 'react';
import moment from 'moment';
import {
  IconLocationPinFilled, IconTagsFilled, IconCalendarFilled, IconNumberSquareFilled,
  IconTypeSquareFilled, IconTagFilled, IconText, IconPaperclipDiagonal, IconAt,
  IconPhoneFilled, IconPenSignFilled, IconStarFilled, IconConditionalBranchFilled,
  IconUsersFilled, IconShapesFilled, IconSquaresArrow
} from '@jotforminc/svg-icons';

import {
  operators, dateOperators, emptyValueOperators, UTILITY_FIELDS,
  startAllTime, customStr, allTimeStr, lessThanStr, greaterThanStr,
  startFormat, endFormat, oldLessThanStr, oldGreaterThanStr, ICON_SIZES
} from '../components/constants';

export const getFilterGroupText = (filters, allFilterText) => {
  const groupFilter = filters.find(f => f.field === 'new' || f.field === 'flag' || f.field === 'formIDs' || f.field === 'ai_agent_id' || f.field === 'conversation_type');

  if (groupFilter) {
    const {
      field, value, operator, text
    } = groupFilter;

    if (field === 'formIDs') {
      if (text) return text;

      if (value?.slice(-1)[0] === 'allForms') return 'All Forms';

      const selectedTitle = filters.slice(-1)[0].selectedFormTitle;

      if (selectedTitle) {
        return selectedTitle;
      }

      return 'All Forms';
    }

    if (field === 'ai_agent_id') {
      return operator === 'ne' && value === 'null' ? 'Conversations' : 'No Conversations';
    }

    if (field === 'conversation_type') {
      return value === 'submission' ? 'Submission' : 'Incomplete';
    }

    if (field === 'new') {
      return operator === 'eq' && value === '0' ? 'Read' : 'Unread';
    }

    if (field === 'flag') {
      return value === '1' ? 'Starred' : 'Unstarred';
    }
  }

  if (window.location.pathname.indexOf('/sentbox/all') > -1) {
    return 'All Forms';
  }

  return allFilterText || 'All Submissions';
};

export const getFilterGroup = filters => {
  const groupFilter = filters.find(f => f.field === 'new' || f.field === 'flag' || f.field === 'formIDs' || f.field === 'ai_agent_id' || f.field === 'conversation_type');
  if (groupFilter) {
    const { field, value, operator } = groupFilter;

    if (field === 'new') {
      return operator === 'eq' && value === '0' ? 'read' : 'unread';
    }

    if (field === 'ai_agent_id') {
      return operator === 'ne' && value === 'null' ? 'ai_agent_id' : 'no_ai_agent_id';
    }

    if (field === 'conversation_type') {
      return value === 'submission' ? 'submission' : 'incomplete';
    }

    if (field === 'flag') {
      return value === '1' ? 'flag' : 'unflag';
    }

    if (field === 'formIDs') {
      return value[0];
    }
  }

  if (window.location.pathname.indexOf('/sentbox/all') > -1) {
    return 'allForms';
  }

  return 'all';
};

export const getFilterTimeRange = filters => {
  const timeRangeFilters = filters.filter(f => f.field === 'created_at');
  const newFilters = {};
  if (timeRangeFilters.length > 0) {
    timeRangeFilters.forEach(f => {
      newFilters[`${f.field}:${f.operator}:${f.type}`] = f.value;
    });
    return newFilters;
  }
  return newFilters;
};

export const getNextFilterID = filters => {
  return filters.reduce((nextID, filter) => {
    return filter.id >= nextID ? filter.id + 1 : nextID;
  }, 0);
};

export const getOperators = genericType => {
  let operatorList = Object.assign([], operators);
  if (genericType === 'submitter') {
    return operators.filter(op => op.name === 'eq');
  }
  if (genericType === 'submittedVia') {
    return operators.filter(op => op.title === 'eq');
  }
  if (genericType === 'bool') {
    return operators.filter(operator => operator.name === 'eq');
  }
  if (genericType === 'submissionWidget' || genericType === 'dropdown' || genericType === 'scale') {
    operatorList = operators.filter(operator => operator.name !== 'matches');
  } else if (genericType === 'date') {
    operatorList = Object.assign([], dateOperators);
  }
  if (genericType !== 'scale' && genericType !== 'numeric' && genericType !== 'date') {
    operatorList = operators.filter(operator => ['lt', 'lte', 'gt', 'gte'].indexOf(operator.name) < 0);
  }
  if (genericType === 'workflow' || genericType === 'eq') {
    operatorList = operators.filter(operator => ['eq', 'ne'].includes(operator.name));
  }

  return operatorList;
};

export const getDefaultHeaderText = (questions, question) => {
  const {
    type: qType = '',
    text,
    subLabel,
    hint,
    order
  } = question;

  const readyToUseLabels = {
    control_fullname: 'Name',
    control_email: 'Email',
    control_address: 'Address',
    control_phone: 'Phone',
    control_datetime: 'Date',
    control_time: 'Time',
    control_inline: 'Fill in the Blanks'
  };

  // if question has a label, then use it
  if (text) {
    return text;
  }

  // if question does not has a label but it has a sublabel, then use it
  if (subLabel) {
    return subLabel;
  }

  // if question does not has a label or a sublabel then look for placeholder text
  if (hint) {
    return hint;
  }

  // if one of the ready to use fields use default label text
  if (['control_fullname', 'control_email', 'control_address', 'control_phone', 'control_datetime', 'control_time', 'control_inline'].indexOf(qType) > -1) {
    return readyToUseLabels[qType];
  }

  // if question does not has a label, sublabel or placeholder and not a ready to use field then look for the previous question. It might be a text.
  if (qType !== 'control_text') {
    const qOrder = parseInt(order, 10);
    const previousQuestion = Object.values(questions).find(q => q.order === (qOrder - 1).toString());

    if (previousQuestion && previousQuestion.type === 'control_text') {
      return previousQuestion.text.replace(/<(?:.|\n)*?>/gm, '');
    }
  }

  // if there is nothing to use then use the question type for column header
  const typeLabel = qType.replace('control_', '');
  return typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1);
};

export const getStaticOption = (obj, value, type) => {
  const objOption = obj && obj.find(f => f[type] === value);
  return objOption ? objOption.text : '';
};

// eslint-disable-next-line complexity
export const getSVGbyQuestionType = type => {
  switch (type) {
    case 'control_checkbox':
      return {
        svg: <IconTagsFilled />
      };
    case 'control_datetime':
    case 'control_submissionDate':
    case 'control_appointment':
    case 'control_birthdate':
    case 'control_documentCreationTime':
    case 'control_documentModificationTime':
      return {
        svg: <IconCalendarFilled />
      };
    case 'control_time':
      return {
        svg: <IconTypeSquareFilled />
      };
    case 'control_dropdown':
      return {
        svg: <IconTagFilled />
      };
    case 'control_email':
      return {
        svg: <IconAt />
      };
    case 'control_fullname':
      return {
        svg: <IconTypeSquareFilled />
      };
    case 'control_stripe':
    case 'control_paypal':
      return {
        svg: <IconTypeSquareFilled />
      };
    case 'control_number':
    case 'control_spinner':
      return {
        svg: <IconNumberSquareFilled />
      };
    case 'control_phone':
      return {
        svg: <IconPhoneFilled />
      };
    case 'control_radio':
      return {
        svg: <IconTagFilled />
      };
    case 'control_text':
      return {
        svg: <IconTypeSquareFilled />
      };
    case 'control_textarea':
      return {
        svg: <IconText />
      };
    case 'control_textbox':
      return {
        svg: <IconTypeSquareFilled />
      };
    case 'control_address':
      return {
        svg: <IconLocationPinFilled />
      };
    case 'control_documentAttachment':
    case 'control_attachment':
      return {
        svg: <IconPaperclipDiagonal />
      };
    case 'control_rating':
      return {
        svg: <IconStarFilled />
      };
    case 'control_fileupload':
      return {
        svg: <IconPaperclipDiagonal />
      };
    case 'control_workflowStatus':
      return {
        svg: <IconConditionalBranchFilled />
      };
    case 'control_assignee':
      return {
        svg: <IconUsersFilled />
      };
    case 'control_signature':
      return {
        svg: <IconPenSignFilled />
      };
    case 'control_documentStatus':
      return {
        svg: <IconSquaresArrow />
      };
    case 'control_widget':
      return {
        svg: <IconShapesFilled />
      };
    default:
      return {
        svg: <IconTypeSquareFilled />
      };
  }
};

export const convertFiltersArrayToObject = (filters, handleCurrentFilters = null) => {
  const currentLockedFilters = {};
  const currentUnlockedFilters = {};
  const reducedFilters = filters.reduce((filterString, filter) => {
    if (!filter || (filter && !filter.value)) return filterString;

    const key = [filter.field, filter.operator, filter.type]
      .filter(f => f).map(f => f).join(':');
    // If value is not initialize in the filterString directly put the value inside.
    // Not support multiple values for the appointment and datetime filter types, take the last filter inserted.
    if (filterString[key] === undefined || filter.type === 'datetime' || filter.type === 'appointment') {
      if (filter.lock === '1') {
        currentLockedFilters[key] = filter.value;
      } else {
        currentUnlockedFilters[key] = filter.value;
      }
      return {
        ...filterString,
        [key]: filter.value
      };
    }
    // If there is a present filter with same configuration do not add it to the object
    // Filter outs empty filters too
    if ((Array.isArray(filterString[key]) && filterString[key].includes(filter.value)) || filterString[key] === filter.value) return filterString;

    // Check if current value of the filter is an array.
    if (filter.lock === '1') {
      if (currentLockedFilters[key] === undefined) {
        currentLockedFilters[key] = filter.value;
      } else {
        currentLockedFilters[key] = Array.isArray(currentLockedFilters[key]) ? [...currentLockedFilters[key], filter.value] : [currentLockedFilters[key], filter.value];
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (currentUnlockedFilters[key] === undefined) {
        currentUnlockedFilters[key] = filter.value;
      } else {
        currentUnlockedFilters[key] = Array.isArray(currentUnlockedFilters[key]) ? [...currentUnlockedFilters[key], filter.value] : [currentUnlockedFilters[key], filter.value];
      }
    }
    return {
      ...filterString,
      [key]: Array.isArray(filterString[key]) ? [...filterString[key], filter.value] : [filterString[key], filter.value]
    };
  }, {});
  if (handleCurrentFilters) {
    handleCurrentFilters({ currentLockedFilters, currentUnlockedFilters });
  }
  return reducedFilters;
};

// Returns filters with single values from the filter list
const getSingleFilters = (filters, lockedFilters = {}) => {
  const singleValues = Object.entries(filters).filter(([, value]) => !Array.isArray(value));
  return singleValues.map(([key, value], index) => {
    if (key === 'timeFilterText') return false;
    const [field, operator, type] = key.split(':');
    return {
      field,
      operator,
      type,
      value,
      isFilled: true,
      isValueDisabled: emptyValueOperators.indexOf(operator) !== -1,
      id: index,
      lock: lockedFilters[key] === value ? '1' : '0'
    };
  });
};

// Extract/Create new filters from filters with multiple values by creating a new filter for each value
// With same field, operator and type
const extractMultipleFilters = (filters, start, lockedFilters = {}) => {
  let index = start;
  const multipleValues = Object.entries(filters).filter(([, value]) => Array.isArray(value));
  return multipleValues.reduce((acc, [key, value]) => {
    const [field, operator, type] = key.split(':');
    value.forEach(v => {
      const lockFilterValuesToSearch = Array.isArray(lockedFilters[key]) ? lockedFilters[key] : [lockedFilters[key]];
      acc.push({
        field,
        operator,
        type,
        value: v,
        isFilled: true,
        isValueDisabled: emptyValueOperators.indexOf(operator) !== -1,
        id: index,
        lock: lockFilterValuesToSearch.includes(v) ? '1' : '0'
      });
      index++;
    });
    return acc;
  }, []);
};

export const convertFiltersObjectToArray = (filters, lockedFilters = []) => {
  // gets the single valued filters extract filters from multiple valued filters and returns them as array
  const singleFilters = getSingleFilters(filters, lockedFilters);
  const multipleFilters = extractMultipleFilters(filters, singleFilters.length, lockedFilters);
  return [...singleFilters, ...multipleFilters].filter(f => f);
};

export const createOrderByValue = ({
  sortByQid,
  sortIsDescending,
  isDatetime,
  isNumeric,
  isSelectable,
  isAppointment
}) => `${
  sortByQid
},${
  sortIsDescending ? 'desc' : 'asc'
}${
  isDatetime ? ',datetime' : ''
}${
  isNumeric ? ',numeric' : ''
}${
  isSelectable ? ',selectable' : ''
}${
  isAppointment ? ',appointment' : ''
}`;

export const getOrderByValue = (view, questions) => {
  if (!view) return '';

  let sortByQid = view.get('sortByQid', false);
  if (!sortByQid) return '';

  // if selectedColumn is one of the non-question columns then remove the 'q' prefix
  UTILITY_FIELDS.forEach(nq => {
    if (sortByQid.includes(nq)) {
      sortByQid = nq;
    }
  });

  const sortIsDescending = view.get('sortIsDescending');
  const qType = questions.getIn([sortByQid.replace('q', ''), 'type'], '');

  const isDatetime = qType === 'control_datetime' || qType === 'control_birthdate';
  const isNumeric = qType === 'control_number' || qType === 'control_spinner';
  const isSelectable = qType === 'control_radio' || qType === 'control_checkbox';
  const isAppointment = qType === 'control_appointment';

  return createOrderByValue({
    sortByQid,
    sortIsDescending,
    isDatetime,
    isNumeric,
    isSelectable,
    isAppointment
  });
};

export const getDateThresholdRange = timeFilterType => {
  let startDate;
  let endDate;

  switch (timeFilterType) {
    case allTimeStr:
      startDate = startAllTime;
      endDate = moment();
      break;
    case 'Today':
      startDate = moment();
      endDate = moment();
      break;
    case 'Yesterday':
      startDate = moment().subtract(1, 'days');
      endDate = moment().subtract(1, 'days');
      break;
    case 'Last 3 days':
      startDate = moment().subtract(2, 'days');
      endDate = moment();
      break;
    case 'Last 7 days':
      startDate = moment().subtract(6, 'days');
      endDate = moment();
      break;
    case 'Last 30 days':
      startDate = moment().subtract(29, 'days');
      endDate = moment();
      break;
    case 'Last 90 days':
      startDate = moment().subtract(89, 'days');
      endDate = moment();
      break;
    case 'Last 180 days':
      startDate = moment().subtract(179, 'days');
      endDate = moment();
      break;
    case 'Last 1 year':
      startDate = moment().subtract(1, 'year');
      endDate = moment();
      break;
    case 'Last 2 years':
      startDate = moment().subtract(2, 'years');
      endDate = moment();
      break;
    case 'Last 3 years':
      startDate = moment().subtract(3, 'years');
      endDate = moment();
      break;
    case 'Previous week':
      startDate = moment().subtract(7, 'days').startOf('week');
      endDate = moment().subtract(7, 'days').endOf('week');
      break;
    case 'Previous month':
      startDate = moment().subtract(1, 'months').startOf('month');
      endDate = moment().subtract(1, 'months').endOf('month');
      break;
    case 'Last 3 months':
      startDate = moment().subtract(3, 'months').startOf('month');
      endDate = moment();
      break;
    case 'This year':
      startDate = moment().startOf('year');
      endDate = moment();
      break;
    case 'Previous year':
      startDate = moment().subtract(1, 'year').startOf('year');
      endDate = moment().subtract(1, 'year').endOf('year');
      break;
    case customStr:
      startDate = moment().subtract(1, 'days');
      endDate = moment();
      break;
    default:
      startDate = startAllTime;
      endDate = moment();
      break;
  }

  return {
    startDate,
    endDate,
    selectedDate: endDate,
    timeFilterType,
    showDatePicker: 'start'
  };
};

export const createFilterFromTimeFilterText = timeFilterText => {
  if (!timeFilterText || timeFilterText === allTimeStr || timeFilterText === customStr) return {};
  const timeRangeObj = getDateThresholdRange(timeFilterText);
  return {
    [greaterThanStr]: timeRangeObj.startDate.format(startFormat),
    [lessThanStr]: timeRangeObj.endDate.format(endFormat)
  };
};

export const getExactOtherFilters = (filters, timeFilterText) => {
  const newFilters = { ...filters };
  if (timeFilterText && timeFilterText !== allTimeStr && timeFilterText !== customStr) {
    if (newFilters[oldLessThanStr]) delete newFilters[oldLessThanStr];
    if (newFilters[oldGreaterThanStr]) delete newFilters[oldGreaterThanStr];
    return newFilters;
  }
  return newFilters;
};

export const getPreparedFilters = filters => {
  const {
    timeFilterText,
    ...otherFilters
  } = filters;

  return {
    ...getExactOtherFilters(otherFilters, timeFilterText),
    ...createFilterFromTimeFilterText(timeFilterText)
  };
};

const dynamicTimeFilterOptionList = ['Today', 'Last 3 days', 'Last 7 days', 'Last 30 days', 'Last 1 year', 'Previous week', 'Previous month', 'This year', 'Previous year'];

// TODO Check here
export const getUpdatedTimeFilterText = (removedFilter, timeFilterText, remainingFilters) => {
  const convertedFilter = convertFiltersArrayToObject([removedFilter]);
  if ((convertedFilter[greaterThanStr] || convertedFilter[lessThanStr]) && dynamicTimeFilterOptionList.indexOf(timeFilterText) > -1) {
    return customStr;
  }
  const convertedRemainingFilter = convertFiltersArrayToObject(remainingFilters);
  if (convertedRemainingFilter[greaterThanStr] || convertedRemainingFilter[lessThanStr]) {
    return customStr;
  }
  return allTimeStr;
};

export const getTrueDateTimeStr = datetime => {
  const count = datetime.split('').filter(key => key === ':').length;
  if (count === 1) {
    return `${datetime}:00`;
  }
  return datetime;
};

const IMAGE_TYPES = {
  icon: 'LOGO/ICON',
  image: 'LOGO/IMAGE'
};

export const getIconInformationForPortalItem = (titleForAlt, icon, iconSize = ICON_SIZES.SMALL) => {
  const {
    iconColor,
    type,
    url,
    backgroundColor
  } = icon || {};

  const isSvgIcon = type === IMAGE_TYPES.icon;
  const alt = `${titleForAlt} Logo`;
  const svgStyleProps = isSvgIcon ? { backgroundColor } : {};

  const defaultIconUrlV0 = 'https://cdn.jotfor.ms/resources/assets/icon/min/jotform-logomark-white-400x400.png';
  const defaulIconUrlV1 = 'https://cdn.jotfor.ms/resources/assets/icon/min/jotform-icon-orange-400x400.png';
  const defaultIconURLBranding21 = 'https://cdn.jotfor.ms/assets/img/logo2021/jotform_portal_logo_icon400x400-dark.png';
  const isDefaultIcon = [defaultIconUrlV0, defaulIconUrlV1, defaultIconURLBranding21].includes(url);

  const finalDefaultIcon = 'https://cdn.jotfor.ms/assets/img/portal/pickerItemDefaultB21.png';
  const finalUrl = isDefaultIcon ? finalDefaultIcon : url;

  return {
    iconColor,
    alt,
    finalUrl,
    url,
    isSvgIcon,
    svgStyleProps,
    iconSize
  };
};

export const ColorList = [
  { backgroundColor: '#FFEBB3' },
  { backgroundColor: '#EDE2FE' },
  { backgroundColor: '#FEE2D5' },
  { backgroundColor: '#D0F0FD' },
  { backgroundColor: '#E1E9F6' },
  { backgroundColor: '#FFBDC7' },
  { backgroundColor: '#BFBCFF' },
  { backgroundColor: '#DCD59D' },
  { backgroundColor: '#D1F7C4' },
  { backgroundColor: '#B5F2EB' },
  { backgroundColor: '#FFBE98' },
  { backgroundColor: '#FFC59E' },
  { backgroundColor: '#AEE5FF' }
];

export const extractColor = (index, list) => {
  return index > -1 ? list[index % list.length] : {};
};

export const mergeTwoFiltersObjects = (obj1, obj2) => {
  const obj1Clone = { ...obj1 };
  const obj2Clone = { ...obj2 };
  const result = {};
  const obj1Keys = Object.keys(obj1Clone);
  obj1Keys.forEach(key => {
    const obj1Value = obj1Clone[key];
    const obj2Value = obj2Clone[key] ?? [];
    let allValuesOfKey = Array.isArray(obj1Value) ? [...obj1Value] : [obj1Value];
    allValuesOfKey = Array.isArray(obj2Value) ? [...allValuesOfKey, ...obj2Value] : [...allValuesOfKey, obj2Value];
    result[key] = allValuesOfKey.length === 1 ? allValuesOfKey[0] : allValuesOfKey;
    delete obj2Clone[key];
  });
  // Since we deleted duplicate keys from obj2Clone,
  // we can directly put the remaining in the result
  return { ...result, ...obj2Clone };
};

export const filtersDifferentiate = (filters, filterObj) => {
  const temp = filters.reduce((acc, curr) => {
    const filter = filterObj[curr];
    // Extract values from filters with multiple entries
    // Differentiate values for the same key with the index of the value
    if (Array.isArray(filter)) {
      filter.forEach((e, i) => {
        acc[`${curr}#${i}`] = e;
      });
    } else {
      acc[`${curr}`] = filter;
    }
    return acc;
  }, {});
  return temp;
};

export const checkKeyAndValueExistsOnLockedFilters = (actualKey, value, activeViewLockedFilters) => {
  const tempKey = actualKey.split('#')[0];
  const valueInLockFilters = activeViewLockedFilters[tempKey];

  return Array.isArray(valueInLockFilters) ? valueInLockFilters.includes(value) : valueInLockFilters === value;
};

export const removeSingleFilterFromFilterObject = (actualKey, value, filterObject) => {
  const tempKey = actualKey.split('#')[0];

  const filterObjectClone = { ...filterObject };
  const result = {};
  const keys = Object.keys(filterObjectClone);
  keys.forEach(key => {
    const _value = filterObjectClone[key];
    if (key === tempKey) {
      if (Array.isArray(_value)) {
        const foundIndex = _value.indexOf(value);
        if (foundIndex > -1) {
          _value.splice(foundIndex, 1);
        }
      } else if (_value === value) {
        return;
      }
    }
    const allValuesOfKey = Array.isArray(_value) ? [..._value] : [_value];
    result[key] = allValuesOfKey.length === 1 ? allValuesOfKey[0] : allValuesOfKey;
  });
  return result;
};

export const BOARD_SOURCE_FORM_QUESTION = {
  qid: '110',
  name: '__board_submission_source_form_id__'
};

export const BOARD_QUESTION_VALUES = {
  [BOARD_SOURCE_FORM_QUESTION.qid]: 'sheetViewForm'
};
