import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import SELECTORS from '../../../../../../store/selectors';
import { DefaultViewButton } from '.';
import { Loading } from '../../../../../../components/Loading';
import { DS_ITEM_LOAD_TYPES } from '../../../../../../store/reducers/dataSource/constants';

export const handleListNavigation = ({
  itemID, navigationFunc, dispatch
}) => {
  return () => {
    dispatch(ACTION_CREATORS.dsEditTable({
      navigationFunc,
      itemID
    }));
  };
};

export const ListViewButton = ({ itemID, ...rest }) => {
  const isLoading = useSelector(SELECTORS.dsGetItemIsLoading(itemID, DS_ITEM_LOAD_TYPES.TABLE_CLONE));
  return (
    <DefaultViewButton {...rest}>
      <div>
        {isLoading ? <Loading /> : t('Edit List')}
      </div>
    </DefaultViewButton>
  );
};

ListViewButton.propTypes = {
  itemID: string.isRequired
};
