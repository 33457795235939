import React from 'react';
import { renderable } from '../../../../constants/propTypes';
import ScSettingsStage from './scSettingsStage';
import { useIfMobileSettings } from '../../../../utils/navigation';

const SettingsStage = ({
  children
}) => {
  const isMobileSubPage = useIfMobileSettings();
  return (
    <ScSettingsStage isMobileSubPage={isMobileSubPage}>
      {children}
    </ScSettingsStage>
  );
};

SettingsStage.propTypes = {
  children: renderable.isRequired
};

export default SettingsStage;
