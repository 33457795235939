import qs from 'qs';
import layer from './layer';
import {
  FTfetchProducts,
  FTcreateProduct,
  FTupdateProduct,
  FTremoveProduct,
  FTSortProducts,
  FTfetchConnectionList,
  FTswitchPaymentGateway,
  FTupdateActiveGatewaySettings,
  FTgetQuestionProps,
  FTupdateCheckoutFormQuestionProp,
  FTupdateAllProductListSettingsOnProduct,
  FTaddCheckoutFormQuestion,
  FTremoveCheckoutFormQuestion,
  FTupdateCheckoutFormQuestion,
  RESOURCES
} from '../types/common';
import { I_GATEWAYS } from '../components/modals/Flows/Types';

const iak = window.location.href.match(/iak=([\d | \w | -]*)/);
const isThereInvitation = iak && iak.length === 2;
let invitationAPIKey = '';
if (isThereInvitation) {
  [, invitationAPIKey] = iak;
}
let apiKey = '';
if (isThereInvitation && invitationAPIKey.length > 0) {
  apiKey = `${invitationAPIKey}`;
}

export const fetchProducts: FTfetchProducts = async formId => {
  return layer.post(`payment/product/list/${formId}`);
};
export const createProduct: FTcreateProduct = async (formId, data) => {
  return layer.post(`payment/product/create/${formId}`, qs.stringify(data));
};

export const updateProduct: FTupdateProduct = async (formId, pid, data) => {
  return layer.post(`payment/product/update/${formId}/${pid}`, qs.stringify(data));
};

export const removeProduct: FTremoveProduct = async (formId, pid) => {
  return layer.post(`payment/product/delete/${formId}/${pid}`);
};

export const sortProducts: FTSortProducts = async (formId, oldPid, newPid) => {
  return layer.post(`payment/editor/${formId}/sort-product`, qs.stringify({ spid: oldPid, fpid: newPid }));
};

export const fetchConnectionList: FTfetchConnectionList = async username => {
  return layer.post('payment/connection/list', qs.stringify({ username }));
};

export const switchPaymentGateway: FTswitchPaymentGateway = async (checkoutFormId, data, itemDeleted) => {
  return layer.post(`payment/gateway/switch/${checkoutFormId}`, qs.stringify({ ...data, ...(itemDeleted === 1 && { itemDeleted }) }));
};

export const updateActiveGatewaySettings: FTupdateActiveGatewaySettings = async (checkoutFormId, qid, data) => {
  return layer.post(`form/${checkoutFormId}/question/${qid}`, qs.stringify(data));
};

export const getQuestionProps: FTgetQuestionProps = async checkoutFormId => {
  return layer.get(`form/${checkoutFormId}/questions`);
};

export const updateCheckoutFormQuestionProp: FTupdateCheckoutFormQuestionProp = async (checkoutFormId, questionId, data) => {
  return layer.post(`form/${checkoutFormId}/question/${questionId}`, qs.stringify({ question: data, v4: 1 }));
};

export const addCheckoutFormQuestion: FTaddCheckoutFormQuestion = async (checkoutFormId, question) => {
  return layer.post(`form/${checkoutFormId}/questions`, qs.stringify({ question, v4: 1 }));
};

export const removeCheckoutFormQuestion: FTremoveCheckoutFormQuestion = async (checkoutFormId, questionId) => {
  return layer.delete(`form/${checkoutFormId}/question/${questionId}`);
};

export const updateCheckoutFormQuestions: FTupdateCheckoutFormQuestion = async (checkoutFormId, questions) => {
  return layer.put(`form/${checkoutFormId}/questions`, { questions, v4: 1 });
};

export const updateAllProductListSettingsOnProduct: FTupdateAllProductListSettingsOnProduct = async (appID, { platform, formProperties, questionProperties }) => {
  return layer.post(`payment/setting/setpropallform/${appID}`, qs.stringify({ platform, formproperties: formProperties, questions: questionProperties }));
};

export const changePaymentType: any = async (formID: any, old: any, paymentType: any) => {
  return layer.post('payment/change-payment-type', qs.stringify({ formID, old, paymentType }), { apiKey });
};

export const getConnectionInformation: (connectionID: string, checkoutFormID: string) => Promise<any> = async (connectionID: string, checkoutFormID: string) => {
  return layer.get(`payment/connections/${connectionID}?summary=1&resourceID=${checkoutFormID}`);
};

export const detachPaymentConnection: (resource: RESOURCES, resourceID: string) => Promise<any> = async (resource, resourceID) => {
  return layer.post(`payment/detach-connection/${resource}/${resourceID}`);
};

/* oAuth Gateway Requests Start */

/* Stripe Start */

export const deleteConnectionToReusable : Promise<any> = (connectionID: string) => {
  return layer.post('payment/connection/deletefromreusable', qs.stringify({ connectionID }));
};

export const moveConnectionToReusable: Promise<any> = async (gatewayType: string, questionID: number, connectionID: string, formID: string) => {
  return layer.post('payment/connection/move', qs.stringify({
    gatewayType,
    questionID,
    connectionID,
    formID
  }));
};

export const getStripeConnectCode: Promise<any> = async (stripeCode: string, allowTest: string, formId: string, controlType: string, isNewPaymentModal = false) => {
  const url = `${window.location.origin}/API/payment/stripe/connect`;
  const params = {
    stripeCode: stripeCode,
    allowTest: [1, 'true', 'Enabled', 'Yes'].includes(allowTest) ? 'true' : 'false',
    formID: formId,
    gatewayType: controlType,
    isNewPaymentModal
  };

  return layer.post(url, qs.stringify(params));
};

export const checkStripeAccountCountry = (resourceId: string, qid: string): Promise<'Yes' | 'No'> => {
  const params = { form_id: resourceId, qid };
  return layer.get(`${window.location.origin}/API/payment/stripe/account/inAvailableCountries`, { params });
};

/* Stripe End */

/* Square Start */

export const getSquareConnectionData: Promise<any> = async (params: object) => {
  const url = `${window.location.origin}/API/payment/square/gettokenandlocations`;

  return layer.post(url, qs.stringify(params));
};

export const getSquareLocations = async (allowTest: string, formId: string, connectionID?: string): Promise<any> => {
  const url = `payment/square/getSquareLocations/${formId}`;

  const params = {
    ...(connectionID && { connectionID }),
    allowTest
  };
  return layer.get(url, { params });
};

/* Square End */

/* Paypal Start */

export const createPaypalReferral = async (data: object): Promise<any> => {
  const url = 'payment/paypal/createReferral';
  return layer.post(url, qs.stringify(data));
};

export const getPaypalMerchantStatus = async (data: object): Promise<any> => {
  const url = 'payment/paypal/getMerchantStatus';
  return layer.post(url, qs.stringify(data));
};

/* Paypal End */

/* Mollie Start */

export const checkMollieConnection = async (formId: string, connectionID: string): Promise<any> => {
  const url = `payment/mollie/connection/control/${formId}`;
  return layer.post(url, qs.stringify({ connectionID: connectionID }));
};

export const getMollieProfiles = async (formId: string, connectionID?: string): Promise<any> => {
  const url = `payment/mollie/getprofiles/${formId}`;
  const params = {
    ...(connectionID && { connectionID }),
    reusable: '1'
  };
  return layer.post(url, qs.stringify(params));
};

export const getMollieConnectCode = async (formId: string, params: object): Promise<any> => {
  const url = `payment/mollie/connection/${formId}`;
  return layer.post(url, qs.stringify(params));
};

/* Mollie End */

/* SensePass Start */
export const fetchConnections = async (gateway: I_GATEWAYS): Promise<object> => { return layer.get('payment/connections', { params: { gateway } }); };
/* SensePass End */

export const getJWTToken = async (formID: string, username: string): Promise<any> => {
  return layer.post('payment/connection/generatejwt', qs.stringify({ formID, username }));
};

/* oAuth Gateway Requests End */
