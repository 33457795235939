import React, {
  forwardRef,
  memo,
  useCallback,
  useState,
  useRef
} from 'react';
import { t } from '@jotforminc/translation';
import { bool, shape, string } from 'prop-types';
import { useDebounce } from '@jotforminc/hooks';
import { useDispatch } from 'react-redux';
import {
  IconGridDotsVertical,
  IconLockFilled,
  IconEllipsisVertical
} from '@jotforminc/svg-icons';
import { InputText, Flex } from '@jotforminc/magnet';
import { Hooks, Popover } from '@jotforminc/uikit';
import { updatePageAction, onDeletePage } from '../../../../../../store/actionCreators';
import { isYes } from '../../../../../../utils';
import ScPageSettingsItem from './ScPageSettingsItem';
import PageIconButton from './PageIconButton';
import { DELETE_PAGE_TYPES } from '../../constants';
import PageSettingsPopover from './PageSettingsPopover';

const PageSettingsItem = forwardRef(({
  draggableProps,
  dragHandleProps,
  showAllPagesIcon,
  isLocked,
  page
}, ref) => {
  const {
    id: pageID,
    name,
    showPageIcon,
    pageIcon,
    showPageOnNavigation: _showPageOnNavigation,
    defaultName // defaultName prop is required for race condition,
  } = page;
  const dispatch = useDispatch();
  const textInputRef = useRef(null);
  const pageSettingsPopoverRef = useRef();
  const pageSettingsButtonPopoverRef = useRef();
  const [isTooltipVisible, setTooltipVisible] = Hooks.useClickOutsideStateWithSelector(false, '.popover-settings-item');

  const [pageName, setPageName] = useState(name);
  const showPageOnNavigation = isYes(_showPageOnNavigation);

  const handlePageUpdate = useCallback(prop => {
    dispatch(updatePageAction({
      pageID,
      prop
    }));
  }, []);

  const debouncedHandlePageUpdate = useDebounce(handlePageUpdate);

  const handlePageNameTextBlur = e => {
    const { target: { value } } = e;
    if (!value) {
      setPageName(defaultName);
    }
  };

  const handlePageNameChange = (props, value) => {
    setPageName(value);
    debouncedHandlePageUpdate({ [props]: value });
  };

  const toggleVisibility = () => {
    handlePageUpdate({ showPageOnNavigation: showPageOnNavigation ? 'No' : 'Yes' });
    setTooltipVisible(false);
  };

  const handleDeletePage = () => {
    dispatch(onDeletePage({
      pageID,
      type: DELETE_PAGE_TYPES.rightPanel
    }));
  };

  const onFocusTextInput = () => {
    if (textInputRef.current) {
      textInputRef.current.focus();
      setTooltipVisible(false);
    }
  };

  return (
    <ScPageSettingsItem
      ref={ref}
      {...draggableProps}
      {...dragHandleProps}
    >
      <div className='pageSettings-dragHandle '>
        {
          isLocked ? <IconLockFilled /> : <IconGridDotsVertical />
        }
      </div>
      <div className='relative w-full'>
        <Flex className={`page-content gap-2 w-full ${!showPageOnNavigation ? 'opacity-30' : ''}`} alignItems='center'>
          {isYes(showAllPagesIcon) && (
            <PageIconButton
              showAllPagesIcon={showAllPagesIcon}
              showPageIcon={showPageIcon}
              pageIcon={pageIcon}
              handlePageUpdate={handlePageUpdate}
            />
          )}
          <InputText
            ref={textInputRef}
            className='w-full'
            id="search"
            placeholder={t('Page Name')}
            value={pageName}
            onChange={e => handlePageNameChange('name', e.target.value)}
            onBlur={handlePageNameTextBlur}
            suffix={{
              as: 'button',
              icon: IconEllipsisVertical,
              ref: pageSettingsButtonPopoverRef,
              onClick: () => setTooltipVisible(true)
            }}
            theme="dark"
            maxLength={50}
          />
        </Flex>
        {isTooltipVisible && (
          <Popover
            ref={pageSettingsPopoverRef}
            className='page-settings-popover'
            targetRef={pageSettingsButtonPopoverRef}
            style={{ zIndex: '2' }}
            popoverOptions={{
              placement: 'bottom-end',
              modifiers: [
                { name: 'offset', options: { offset: [0, 5] } }
              ]
            }}
          >
            <PageSettingsPopover
              onFocusTextInput={onFocusTextInput}
              handleDeletePage={handleDeletePage}
              toggleVisibility={toggleVisibility}
              showPageOnNavigation={showPageOnNavigation}
            />
          </Popover>
        )}
      </div>
    </ScPageSettingsItem>
  );
});

PageSettingsItem.propTypes = {
  page: shape({}),
  draggableProps: shape({}),
  dragHandleProps: shape({}),
  showAllPagesIcon: string,
  isLocked: bool
};

PageSettingsItem.defaultProps = {
  page: {},
  draggableProps: {},
  dragHandleProps: {},
  showAllPagesIcon: '',
  isLocked: false
};

export default memo(PageSettingsItem);
