import React, { forwardRef, useMemo } from 'react';
import { ColorPicker as UIKitColorPicker } from '@jotforminc/uikit';

const ColorPicker = ({ onChange, value } : IProps) : JSX.Element => {
  const colorWrapper = forwardRef(({ color, ...props }, ref) => (
    <button
      style={{ backgroundColor: color || '#ecedfb' }}
      className="absolute right-1 top-1 h-8 w-8 radius overflow-hidden color-transparent shrink-0"
      type="button"
      ref={ref}
      {...props}
    >
      Change color
    </button>
  ));

  const popoverProps = useMemo(() => (
    {
      popoverOptions: {
        placement: 'top'
      }
    }
  ), []);

  return (
    <div className="pwColorPicker relative h-10 bg-white color-navy-700 border text-md min-w-44 z-10
    p-2 text-left border-navy-100 hover:border-navy-200 hover:border-opacity-80 radius
    focus:outline focus:outline-blue-200 w-68 z-9 mt-3 sm:mt-0"
    >
      <UIKitColorPicker
        ColorRenderer={colorWrapper}
        onChange={onChange}
        value={value || '#ecedfb'}
        popoverProps={popoverProps}
      />
    </div>
  );
};

export interface IProps {
  onChange: (value: string | number) => void,
  value: string
}

export default ColorPicker;
