/** Data */
import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';
import type { GATEWAY_CONNECTION_PROPS, SORTING_ENABLED_COUNTRIES } from '@jotforminc/payment-constants';
import type { UTILS_GET_GATEWAY_LIST_OPTIONS } from '../../types';
import { isDevelopment } from './isDevelopment';

/**
 * Retrieves a list of supported gateway connections based on the specified options.
 *
 * @param options - The options for retrieving the gateway list.
 * @returns An array of gateway list with connection properties.
 */
export const getGatewayList = ({
  gatewayList = supportedGatewayConnectionPropsList,
  resourceType,
  includePurchaseOrder = false,
  includeNotAllowedNewConnection = false,
  includeDeprecated = false,
  enableSorting = false,
  userLocation = null
}: UTILS_GET_GATEWAY_LIST_OPTIONS): GATEWAY_CONNECTION_PROPS[] => {
  const getList = Object.values(gatewayList).filter(gateway => {
    if (gateway.allowResourceTypes.includes(resourceType)) {
      if (gateway.gateway_type === 'control_payment' && !includePurchaseOrder) {
        return false;
      }

      if (gateway.isDeprecated && !includeDeprecated) {
        return false;
      }

      if (gateway.env === 'production') {
        if (gateway.allowNewConnection === false && !includeNotAllowedNewConnection) {
          return false;
        }
        return true;
      }

      if (
        (gateway.env === 'development' && !isDevelopment())
        || (gateway.allowNewConnection === false && !includeNotAllowedNewConnection)
      ) {
        return false;
      }

      return true;
    }
    return false;
  });

  if (enableSorting && userLocation && userLocation.country_code) {
    return getList.sort((a, b) => {
      const aCountryValue = a.sortByCountry.countries[userLocation.country_code as SORTING_ENABLED_COUNTRIES] || a.sortByCountry.default;
      const bCountryValue = b.sortByCountry.countries[userLocation.country_code as SORTING_ENABLED_COUNTRIES] || b.sortByCountry.default;
      return aCountryValue - bCountryValue;
    });
  }

  return getList;
};
