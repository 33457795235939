/* eslint-disable camelcase */
import { t } from '@jotforminc/translation';
import { func, string } from 'prop-types';
import React, { useCallback } from 'react';
import Dropdown from '../../../../../../components/Dropdown';
import { ScRightPanelDropdown } from '../../../../styles/scRightPanel';
import {
  RESET_PERIOD, WEEKLY_PERIOD_OPTIONS, RESET_PERIOD_VALUES
} from './badgeToggleConstants';

const ResetBadge = ({
  onChange,
  completed_clearBadgePeriod, completed_clearBadgeOn
}) => {
  const isClearBadgePeriodWeekly = completed_clearBadgePeriod === RESET_PERIOD_VALUES.WEEKLY;

  const handleClearBadgeOnChange = useCallback(e => {
    onChange({ completed_clearBadgeOn: e });
  }, [onChange]);

  const handleClearBadgePeriodChange = useCallback(e => {
    onChange({ completed_clearBadgePeriod: e });
  }, [onChange]);

  return (
    <>
      <Dropdown
        onChange={handleClearBadgePeriodChange}
        options={RESET_PERIOD}
        defaultValue={completed_clearBadgePeriod}
        DropdownWrapperRenderer={ScRightPanelDropdown}
      />
      {isClearBadgePeriodWeekly && (
      <div style={{ marginTop: '20px' }}>
        <div style={{ marginBottom: '9px', fontWeight: '700' }}>{t('Select a day')}</div>
        <Dropdown
          onChange={handleClearBadgeOnChange}
          options={WEEKLY_PERIOD_OPTIONS}
          defaultValue={completed_clearBadgeOn || 'multi-selected'}
          DropdownWrapperRenderer={ScRightPanelDropdown}
        />
      </div>
      )}
    </>
  );
};

ResetBadge.propTypes = {
  onChange: func,
  completed_clearBadgePeriod: string,
  completed_clearBadgeOn: string
};

ResetBadge.defaultProps = {
  onChange: f => f,
  completed_clearBadgePeriod: '',
  completed_clearBadgeOn: ''
};

export default ResetBadge;
