import Styled from 'styled-components';

const ScSettingsStage = Styled.main`
  display: block;
  position: absolute;
  width: calc(100% - 310px);
  height: 100%;
  margin-left: 310px;
  overflow: scroll;
  padding: 36px 88px;

  @media screen and (max-width: 900px) {
    padding: 36px;
  }
  @media screen and (max-width: 768px) {
    width: calc(100% - 144px);
    margin-left: 144px;
  }

  ${({ isMobileSubPage }) => (`
  @media screen and (max-width: 480px) {
    width: 100%;
    .rpOpen & { margin-right: 0; padding-right: 0; }
    .lpOpen & { margin-left: 200px; }

    margin-left: ${isMobileSubPage ? '0' : '100%'};
    padding: 0;
    height: 100dvh;
  }
  `)}
`;

export default ScSettingsStage;
