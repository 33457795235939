import Styled from 'styled-components';
import { primaryRed, rightPanelItemBorderColor } from '../../../../../../styles/colors';

export const ScPopoverIconSelector = Styled.div`  
  .icon-selector-wizard {
    background-color: ${rightPanelItemBorderColor};
    border-radius: 4px;
    margin: 0 1em;

    .iconSelectorPanel {
      border-radius: 4px;
    }

    .remove-icon-button {
      position: relative;
      background-color: #212326;
      width: 95%;
      margin: 10px auto;
      left: 50%;
      transform: translateX(-50%);

      &:hover {
        background-color: ${primaryRed};
      }
    }
  }

  [data-popper-placement^='top'] [data-popper-arrow] {
    bottom: -4px;
  }
  [data-popper-placement^='bottom'] [data-popper-arrow] {
    top: -4px;
  }
  [data-popper-placement^='left'] [data-popper-arrow] {
    right: -4px;
  }
  [data-popper-placement^='right'] [data-popper-arrow] {
    left: -4px;
  }

  [data-popper-arrow] {
    transform: translate(29px, 0px) !important;
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: ${rightPanelItemBorderColor};
      transform: rotate(45deg);
    }
  }
`;
