/* eslint-disable max-len, complexity */
import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import flatMap from 'lodash/flatMap';
import {
  IconCaretDown,
  IconCaretUp,
  IconExclamationCircleFilled,
  IconInfoCircleFilled
} from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';
import { Tooltip } from '@jotforminc/tooltip';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { FormWizard } from '@jotforminc/form-wizard';
import Toggle from '@jotforminc/toggle';
import WarningBox from '@jotforminc/warning-box';
import * as Modal from '../Items';
import { C_GATEWAYS } from '../Constants';
import useGatewayFlows from '../Context/useGatewayFlows';
import COMPONENT_TYPE_LIST from '../../../../constants/componentTypes';
import ConnectionButton from '../../../fields/ConnectionButton';
import NewDropdown from '../../../fields/NewDropdown';
import { Credential, NewTextInput } from '../../../fields';
import { CONNECTION_SECTION_PROPERTIES } from '../Constants/ConnectionSectionProperties';
import { checkIsGatewayConnected, checkCurrencySupport } from '../../../../utils';
import { fetchConnections } from '../../../../api';
import { isPositiveValue, prepareConnectionOptions } from '../Utils';
import { getDataFromSettings } from '../Constants/GatewayProperties';

import { IFormWizardElements } from '../../../../types/common';
import { I_SQUARE_SETTINGS } from '../Types/GatewayTypes';
import * as COMPONENT_NAMES from '../../../../constants/componentNames';

const PaymentGatewaySettings = (): JSX.Element => {
  const [toggleAdditional, setToggleAdditional] = useState<boolean>(true);
  const width = window.innerWidth;
  const {
    currentlyGateway,
    gatewayQuestionProps,
    changedSettings,
    setChangedSettings,
    resourceType,
    formId,
    formQuestions,
    defaultFlowType,
    currentlyPaymentType,
    getDefaultGatewayProps,
    currentlyApm,
    currentlyApmProperties,
    getParentGatewayType,
    staticSettings,
    _setStaticSettings,
    isSandboxWarningVisible,
    isMasked
  } = useGatewayFlows();

  useEffect(() => {
    if (currentlyApm && currentlyApmProperties) {
      setChangedSettings({ ...changedSettings, nameAPM: currentlyApmProperties.nameAPMType });
    }
  }, []);

  useEffect(() => {
    if (currentlyGateway === 'control_sensepass' && isEnterprise()) {
      (async () => {
        const data = await fetchConnections('control_sensepass');
        _setStaticSettings({ connectionIdOptions: data });
      })();
    }
  }, []);

  let { name, icon: GatewayIcon } = C_GATEWAYS.find(gateway => gateway.type === currentlyGateway) ?? {};

  const isEdit = defaultFlowType === 'EDIT_GATEWAY' && (
    gatewayQuestionProps.type === currentlyGateway
    || gatewayQuestionProps.type === getParentGatewayType()
  );

  if (currentlyApm && currentlyApmProperties) {
    name = currentlyApmProperties.name;
    GatewayIcon = currentlyApmProperties.icon;
  }

  const settings = {
    ...(isEdit ? gatewayQuestionProps : getDefaultGatewayProps()),
    ...changedSettings
  };

  const {
    gatewayProperties,
    connectionSettings,
    modeSettings,
    selectedOptionValue,
    toggleValue
  } = getDataFromSettings(settings, currentlyGateway);

  const modeTypeProps = [
    {
      type: 'control_square',
      properties: (squareSettings: I_SQUARE_SETTINGS): IFormWizardElements => ({
        infoline: {
          type: COMPONENT_NAMES.INFOLINE,
          componentProps: {
            // is flatmap necessary?
            text: flatMap(translationRenderer('To start the OAuth flow for a Test Mode, first launch the seller test account from the [1[Square Developer Dashboard]]. '
              + 'Please login to your Square account and then launch your sandbox account from [2["Sandbox Test Accounts"]] section.')({
              renderer1: (str: string) => `<a href="https://developer.squareup.com/apps" target="_blank">${str}</a>`,
              renderer2: (str: string) => `<b>${str}</b>`
            })).join('')
          },
          condition: () => squareSettings.allowTest && squareSettings.allowTest.toString() === 'true'
        }
      })
    }
  ];

  const modeTypeProperties = modeTypeProps.find(gateway => gateway.type === currentlyGateway);
  const connSectionProperties = CONNECTION_SECTION_PROPERTIES.filter(gateway => gateway.type === currentlyGateway);

  const additionalSettings = gatewayProperties && gatewayProperties.additionalSettings;
  const hideAdditionalSettings = gatewayProperties && gatewayProperties.hideAdditionalSettings;
  const connectionFields = (connectionSettings && connectionSettings.fields) ? connectionSettings.fields : [];
  const connectionSettingsLabel = (connectionSettings && connectionSettings.label) ? connectionSettings.label : t('Connect to {gateway}').replace('{gateway}', name);
  const connectionSettingsDesc = (connectionSettings && connectionSettings.description) ? connectionSettings.description : t('You’ll be redirected to {gateway} to finish setting up the connection.').replace('{gateway}', name);

  const isOauth = connectionSettings && gatewayProperties.connectionSettings.type === 'oauth';
  const isCredential = connectionSettings && gatewayProperties.connectionSettings.type === 'credential';

  const connSettingsCondition = connectionSettings && connectionSettings.condition;
  const connectionSectionVisible = connSettingsCondition ? connSettingsCondition(selectedOptionValue) : true;

  const isConnected = checkIsGatewayConnected({ ...staticSettings, ...settings, type: currentlyGateway });

  const isModeSectionHidden = modeSettings && modeSettings.hideCondition && modeSettings.hideCondition(staticSettings);

  const checkSandBoxValues = isPositiveValue(selectedOptionValue);

  const onSandboxToggleClick = () => {
    setChangedSettings({
      ...changedSettings,
      [modeSettings.key]: toggleValue
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isCurrencySupported = checkCurrencySupport(currentlyGateway, gatewayQuestionProps.currency);

  useEffect(() => {
    const testModeExclamationBox = document.querySelector('.testModeExclamation');
    if (!isConnected && isSandboxWarningVisible) {
      testModeExclamationBox?.classList.remove('hide');
      testModeExclamationBox?.classList.add('show');
    } else {
      testModeExclamationBox?.classList.remove('show');
      testModeExclamationBox?.classList.add('hide');
    }
  }, [isSandboxWarningVisible, isConnected]);

  return (
    <>
      <Modal.Header
        title={`${name} ${t('Settings')}`}
        leftIcon={GatewayIcon && <GatewayIcon className='h-10 w-10' />}
        iconBackgroundColor='bg-gray-50'
        iconColor='color-white'
      />
      <Modal.Body>
        <div className={classNames(
          (isConnected && !checkSandBoxValues) ? 'bg-green-100 border-green-200' : 'bg-navy-25 border-navy-50',
          'border radius px-3 py-5 duration-300'
        )}
        >
          <div className={classNames('flex flex-col items-start justify-between sm:flex-row sm:items-center', { 'sm:flex-col sm:items-start mb-4': isCredential, 'mb-7': isOauth })}>
            <div className={classNames({ 'w-full mb-3 sm:basis-2/3 sm:mb-0 pr-2': isOauth }, { 'mb-3': isCredential })}>
              <p className='text-md font-medium color-navy-700 line-height-xl tracking-md mb-1'>
                {connectionSettingsLabel}
                {
                  isMasked && (
                    <div className='inline-block relative ml-1 jfTooltipNew-hoverTarget'>
                      <IconInfoCircleFilled
                        className="h-4 w-4"
                      />
                      <Tooltip
                        v2
                        attach={width > 640 ? '' : 'Bottom'}
                        hideArrow
                        tooltipDistance={width > 640 ? '4px' : '2px'}
                        style={{
                          zIndex: 999999,
                          width: width > 640 ? '360px' : '272px',
                          fontSize: '14px',
                          lineHeight: '14px',
                          backgroundColor: '#0a1551',
                          padding: '8px 12px'
                        }}
                      >
                        {t('Your gateway\'s credentials are partially hidden due to security reasons.')}
                      </Tooltip>
                    </div>
                  )
                }
              </p>
              <p className='text-sm font-normal color-navy-400'>
                {connectionSettingsDesc}
              </p>
            </div>
            {
              isOauth
                ? (
                  <div className='w-full sm:max-w-68 sm:basis-1/3'>
                    <ConnectionButton
                      settings={settings}
                      checkoutFormId={formId}
                    />
                  </div>
                )
                : (
                  connectionSectionVisible && (
                    <div className={`flex items-${isMasked ? 'start' : 'center'} justify-between flex-wrap w-full`}>
                      {
                        connectionFields.map(({
                          key,
                          label,
                          hideCondition,
                          type,
                          staticKey,
                          isSensitiveField
                        }) => {
                          const defaultValue = isEdit ? gatewayQuestionProps[key] : changedSettings[key];
                          const isHidden = hideCondition && hideCondition(staticSettings);
                          if (isHidden) {
                            return null;
                          }
                          const CredentialComponent = (isMasked && isSensitiveField !== false) ? Credential : NewTextInput;
                          const sensitiveContainerClass = 'sm:max-w-68 md:max-w-84 lg:max-w-96';
                          const credentialContainerClass = 'sm:max-w-56 md:max-w-72 lg:max-w-84';
                          const notSensitiveWhenMasked = isMasked && isSensitiveField === false;
                          return (
                            <div key={key} className={classNames('w-full sm:basis-1/2', { [credentialContainerClass]: notSensitiveWhenMasked, [sensitiveContainerClass]: (!notSensitiveWhenMasked || (notSensitiveWhenMasked && connectionFields.length < 3)) })}>
                              <label
                                className='block text-sm font-medium color-navy-700 line-height-xl tracking-md mt-2 mb-1'
                                htmlFor={key}
                                key={key}
                              >
                                {t(label)}
                                <span className='required color-red-500 ml-1'>*</span>
                              </label>
                              {
                                type === 'dropdown' ? (
                                  <NewDropdown
                                    zIndex={999999}
                                    options={prepareConnectionOptions(currentlyGateway, staticSettings[staticKey || key])}
                                    selectedOptionValues={settings.reusableConnectionID || 'none'}
                                    onChange={val => { setChangedSettings({ ...changedSettings, [key]: val }); }}
                                    inputClass="w-full"
                                    dropdownWrapperClass="portal-new-dropdown-wrapper"
                                  />
                                ) : (
                                  <CredentialComponent
                                    id={key}
                                    onChange={val => setChangedSettings({ ...changedSettings, [key]: val })}
                                    defaultValue={defaultValue}
                                  />
                                )
                              }
                            </div>
                          );
                        })
                      }
                    </div>
                  )
                )
            }
          </div>
          {
            connSectionProperties && connSectionProperties.map(prop => {
              return (
                <FormWizard
                  elementList={prop.properties(settings, isEdit)}
                  componentTypeList={COMPONENT_TYPE_LIST}
                  onChange={(_: object, data: { key: string, value: string }) => {
                    setChangedSettings({ ...changedSettings, [data.key]: data.value });
                  }}
                />
              );
            })
          }
          {
            modeSettings && !isModeSectionHidden && (
              <div className={
                classNames(
                  'flex flex-col justify-between items-center flex-wrap -mx-3 -mb-5 pt-3 px-3 pb-5 sm:flex-row radius-b duration-300'
                )
              }
              >
                <div className='w-full mb-3 sm:basis-9/12 sm:mb-0'>
                  <p className='text-md font-medium color-navy-700 line-height-xl tracking-md mb-1'>
                    {t('Enable Test Mode')}
                  </p>
                  <p className='text-sm font-normal color-navy-400 mb-2'>
                    {t('Use Test Mode to process simulated payments before going live.')}
                  </p>
                </div>
                <div className='w-full sm:max-w-28 sm:basis-3/12 flex justify-start sm:justify-end'>
                  <Toggle
                    id="gateway-testmode-toggle"
                    onClick={onSandboxToggleClick}
                    isSelected={checkSandBoxValues}
                    squareMode={true}
                  />
                </div>
                <WarningBox
                  wrapperClassName='mt-0'
                  className={`${(isConnected && checkSandBoxValues) ? 'show' : 'hide'}`}
                >
                  {t('Test mode is enabled for this payment.')}
                </WarningBox>
                <WarningBox
                  colorStyle='error'
                  wrapperClassName='mt-0'
                  className='testModeExclamation hide'
                >
                  {t('If you change your mode type, you’ll need to reconnect to your payment gateway.')}
                </WarningBox>
              </div>
            )
          }
          {
            modeTypeProperties && (
              <FormWizard
                elementList={modeTypeProperties.properties(settings)}
                componentTypeList={COMPONENT_TYPE_LIST}
              />
            )
          }
        </div>
        {!isCurrencySupported.isSupported && (
          <div className='flex flex-row gap-1 justify-self-center color-navy-300 bg-navy-25 border-navy-50 border radius px-3 py-3 duration-300 mt-3 text-sm tracking-lg'>
            <IconExclamationCircleFilled className='w-5 h-5' />
            {' '}
            <b>{name}</b>
            {' '}
            doesn’t support
            {' '}
            <b>{gatewayQuestionProps.currency}</b>
            {' '}
            and your currency will be set to
            {' '}
            <b>{isCurrencySupported.supportedCurrency}</b>
            {' '}
            once you complete the integration.
          </div>
        )}
        {
          additionalSettings && !hideAdditionalSettings && (
            <>
              <div
                className='flex items-start justify-between w-full max-w-64 cursor-pointer mt-12 px-3'
                onClick={() => setToggleAdditional(!toggleAdditional)}
                onKeyDown={() => setToggleAdditional(!toggleAdditional)}
              >
                <span className='text-md font-medium color-navy-700 line-height-xl tracking-md'>{t('Additional Gateway Settings')}</span>
                {toggleAdditional ? <IconCaretUp className='w-6 h-6' /> : <IconCaretDown className='w-6 h-6' />}
              </div>
              <div className={toggleAdditional ? 'block' : 'hidden'}>
                <FormWizard
                  elementList={additionalSettings(settings, resourceType, formQuestions, currentlyPaymentType)}
                  componentTypeList={COMPONENT_TYPE_LIST}
                  onChange={(_: object, data: { key: string, value: string }) => {
                    setChangedSettings({ ...changedSettings, [data.key]: data.value });
                  }}
                />
              </div>
            </>
          )
        }
      </Modal.Body>
      <Modal.Footer
        enableNextButton
        // nextButtonDisabledCondition={!currentlyGateway}
      />
    </>
  );
};

export default memo(PaymentGatewaySettings);
