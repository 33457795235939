import {
  LogoSquareLogomarkDark,
  LogoPaypalLogomarkColor,
  LogoPaypalBusinessLogomarkColor,
  LogoPaypalPersonalLogomarkColor,
  LogoPaypalInvoicingLogomarkColor,
  LogoPaypalCheckoutLogomarkColor,
  LogoPaypalExpressLogomarkColor,
  LogoCashappLogomarkColor,
  LogoVenmoLogomarkColor,
  LogoStripeLogomarkColor,
  LogoStripeCheckoutLogomarkColor,
  LogoStripeAchmLogomarkColor,
  LogoStripeAchLogomarkColor,
  LogoBraintreeLogomarkDark,
  LogoMollieLogomarkDark,
  LogoApplegooglepayColor,
  LogoCybersourceLogomarkColor,
  LogoEwayLogomarkColor,
  LogoPayuLogomarkColor,
  LogoPayuIndiaLogomarkColor,
  LogoMonerisLogomarkColor,
  LogoGocardlessLogomarkDark,
  LogoPagseguroLogomarkColor,
  LogoChargifyLogomarkColor,
  LogoBluesnapLogomarkColor,
  LogoPayfastLogomarkDark,
  LogoSensepassLogomarkColor,
  LogoKlarnaLogomarkColor,
  LogoFirstdataLogomarkColor,
  LogoOnebipLogomarkColor,
  LogoBluepayLogomarkColor,
  LogoClickbankLogomarkColor,
  LogoCardconnectLogomarkColor,
  LogoPaysafeLogomarkColor,
  LogoIyzicoLogomarkColor,
  LogoSkrillLogomarkColor,
  LogoPayjunctionLogomarkColor,
  LogoPaymentwallLogomarkColor,
  LogoAuthorizenetLogomarkColor,
  LogoEcheckLogomarkColor,
  LogoWorldpayUkLogomarkColor,
  LogoWorldpayUsLogomarkColor,
  Logo2CheckoutLogomarkColor,
  LogoPurchaseorderLogomarkDark,
  LogoAfterpayLogomarkColor,
  LogoAfterpayLogomarkDark
} from '@jotforminc/svg-icons';
import type { GATEWAY_DEFAULT_ICONS } from '../types';

/**
 * Default icons for payment gateways.
 * @type {GATEWAY_DEFAULT_ICONS}
 */
export const gatewayDefaultIcons: GATEWAY_DEFAULT_ICONS = {
  control_square: LogoSquareLogomarkDark,
  control_paypalcomplete: LogoPaypalBusinessLogomarkColor,
  control_paypal: LogoPaypalPersonalLogomarkColor,
  control_paypalInvoicing: LogoPaypalInvoicingLogomarkColor,
  control_paypalpro: LogoPaypalLogomarkColor,
  control_paypalSPB: LogoPaypalCheckoutLogomarkColor,
  control_paypalexpress: LogoPaypalExpressLogomarkColor,
  control_authnet: LogoAuthorizenetLogomarkColor,
  control_stripe: LogoStripeLogomarkColor,
  control_stripeCheckout: LogoStripeCheckoutLogomarkColor,
  control_stripeACHManual: LogoStripeAchmLogomarkColor,
  control_stripeACH: LogoStripeAchLogomarkColor,
  control_braintree: LogoBraintreeLogomarkDark,
  control_cashApp: LogoCashappLogomarkColor,
  control_afterpay: LogoAfterpayLogomarkColor,
  control_clearpay: LogoAfterpayLogomarkDark,
  control_venmo: LogoVenmoLogomarkColor,
  control_applePayGooglePay: LogoApplegooglepayColor,
  control_mollie: LogoMollieLogomarkDark,
  control_cybersource: LogoCybersourceLogomarkColor,
  control_echeck: LogoEcheckLogomarkColor,
  control_bluepay: LogoBluepayLogomarkColor,
  control_eway: LogoEwayLogomarkColor,
  control_payu: LogoPayuLogomarkColor,
  control_payuMoney: LogoPayuIndiaLogomarkColor,
  control_worldpay: LogoWorldpayUkLogomarkColor,
  control_bluesnap: LogoBluesnapLogomarkColor,
  control_moneris: LogoMonerisLogomarkColor,
  control_gocardless: LogoGocardlessLogomarkDark,
  control_payfast: LogoPayfastLogomarkDark,
  control_sensepass: LogoSensepassLogomarkColor,
  control_sofort: LogoKlarnaLogomarkColor,
  control_pagseguro: LogoPagseguroLogomarkColor,
  control_firstdata: LogoFirstdataLogomarkColor,
  control_cardconnect: LogoCardconnectLogomarkColor,
  control_worldpayus: LogoWorldpayUsLogomarkColor,
  control_2co: Logo2CheckoutLogomarkColor,
  control_paysafe: LogoPaysafeLogomarkColor,
  control_iyzico: LogoIyzicoLogomarkColor,
  control_skrill: LogoSkrillLogomarkColor,
  control_chargify: LogoChargifyLogomarkColor,
  control_payjunction: LogoPayjunctionLogomarkColor,
  control_clickbank: LogoClickbankLogomarkColor,
  control_onebip: LogoOnebipLogomarkColor,
  control_paymentwall: LogoPaymentwallLogomarkColor,
  control_payment: LogoPurchaseorderLogomarkDark
};
