import { shape, string } from 'prop-types';
import React from 'react';

import { ITEM_ICONS } from '../../constants';
import { DOCUMENT_ITEM_TYPES, ITEM_TYPES } from '../../constants/itemTypes';
import { getDocumentType } from '../../utils';

const Thumbnail = ({ file: { type, url } }) => {
  const iconType = getDocumentType(type);

  const renderIconProps = () => ITEM_ICONS[ITEM_TYPES.DOCUMENT][DOCUMENT_ITEM_TYPES.PLACEHOLDER];

  const renderThumbnail = () => {
    if (iconType === DOCUMENT_ITEM_TYPES.IMG) {
      return <img className="document-thumbnail" src={url} alt="file_preview_thumbnail_alternative" />;
    }
    const { Icon } = renderIconProps();
    const DocumentIcon = props => Icon(props);
    return (
      <div>
        <DocumentIcon />
      </div>
    );
  };
  return renderThumbnail();
};

Thumbnail.propTypes = {
  file: shape({
    name: string,
    type: string
  })
};

Thumbnail.defaultProps = {
  file: {}
};

export default Thumbnail;
