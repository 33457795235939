import { StorageHelper } from '@jotforminc/storage-helper';
import { ResponseResult } from '@jotforminc/request-layer';

import { PERMISSION, SUBSCRIPTION_ID_KEY } from './constants';
import { ProjectConfig, PushManagerConfig } from './types';
import { base64ToArray } from './utils';
import Api from './api';
import { SubscriptionError } from './SubscriptionError';

export class PushManager {
  public readonly resourceId: string;

  public readonly resourceType: string;

  public readonly api: Api;

  public subscription: PushSubscription | null;

  constructor(config: PushManagerConfig) {
    this.resourceType = config.resourceType;
    this.resourceId = config.resourceId;
    this.subscription = null;
    this.api = new Api(config);
  }

  async subscribe(payload?: Record<string, string>): Promise<ResponseResult<string>> {
    if (!PushManager.isSupported()) {
      throw new SubscriptionError({
        name: 'DeviceNotSupported',
        message: 'Device does not support push messaging'
      });
    }

    const registration = await window.navigator.serviceWorker.getRegistration();

    if (!registration) {
      throw new SubscriptionError({
        name: 'NoSwRegistration',
        message: 'Cannot get service worker registration'
      });
    }

    let permission = this.getPermission();

    if (permission === PERMISSION.DEFAULT) {
      permission = await Notification.requestPermission();
    }

    if (permission === PERMISSION.GRANTED) {
      let vapidPublicKey;
      try {
        const config = await this.api.getConfig() as ProjectConfig;
        vapidPublicKey = config.vapidKey;
      } catch (err) {
        throw new SubscriptionError({
          name: 'NoVapidKey',
          message: 'Can not obtain vapid public key'
        });
      }
      this.subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToArray(vapidPublicKey)
      });
      const subscriptionId = await this.api.postSubscription({
        subscription: this.subscription,
        payload
      }) as string;
      this.persistSubscriptionId(subscriptionId);
      return subscriptionId;
    }

    if (permission === PERMISSION.DENIED) {
      // TODO: handle permission deny case
      throw new SubscriptionError({
        name: 'PermissionDenied',
        message: 'Permission denied'
      });
    }

    throw new SubscriptionError({
      name: 'PermissionDefault',
      message: 'Permission default'
    });
  }

  private persistSubscriptionId(subscriptionId: string) {
    const key = this.subscriptionIdKey();
    StorageHelper.setLocalStorageItem({ key, value: subscriptionId });
  }

  get subscriptionId(): string {
    const key = this.subscriptionIdKey();
    return StorageHelper.getLocalStorageItem({ key });
  }

  private subscriptionIdKey() {
    return `${SUBSCRIPTION_ID_KEY}-${this.resourceType}-${this.resourceId}`;
  }

  public getPermission(): NotificationPermission {
    return Notification.permission;
  }

  async isSubscribed(): Promise<boolean> {
    const subscriptionStatus = await this.checkDeviceSubscription();
    if (subscriptionStatus === false) {
      return false;
    }
    if (this.subscription !== null) {
      return true;
    }
    const subscription = await this.getSubscription();
    return !!subscription;
  }

  private async checkDeviceSubscription(): Promise<ResponseResult<boolean>> {
    if (this.subscriptionId === null) {
      return false;
    }

    // TODO: consider caching the subscription response
    const status = await this.api.checkSubscription(this.subscriptionId);
    return status;
  }

  async getSubscription(): Promise<PushSubscription | null> {
    if (this.subscription !== null) {
      return this.subscription;
    }
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) return null;
    this.subscription = await registration.pushManager.getSubscription();
    return this.subscription;
  }

  static isSupported(): boolean {
    return (
      'PushManager' in window
       && 'serviceWorker' in navigator
       && 'Notification' in window
       && Object.prototype.hasOwnProperty.call(ServiceWorkerRegistration.prototype, 'showNotification')
       && StorageHelper.isSupported
    );
  }
}
