import { FETCH_CDN_CONFIG } from '../actionTypes';

const initialState = {};

const CDNConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CDN_CONFIG.SUCCESS: {
      return action.payload;
    }
    default:
      break;
  }
  return state;
};

export default CDNConfigReducer;
