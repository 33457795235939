import React from 'react';
import PropTypes from 'prop-types';
import { renderable } from '../../constants/propTypes';

const TetheredWrapper = ({ hasWrapper, children, Wrapper }) => (hasWrapper ? <Wrapper>{children}</Wrapper> : children);

TetheredWrapper.propTypes = {
  hasWrapper: PropTypes.bool.isRequired,
  children: renderable.isRequired,
  Wrapper: PropTypes.elementType.isRequired
};

export default TetheredWrapper;
