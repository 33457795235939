import type { GATEWAY_SEARCHABLE_TAGS_DATA } from '../types';

/**
 * Represents a mapping of gateway searchable tags.
 * @type {GATEWAY_SEARCHABLE_TAGS_DATA}
 */
export const gatewaySearchableTags: GATEWAY_SEARCHABLE_TAGS_DATA = {
  control_square: ['Square', 'Credit Card', 'Apple Pay', 'Google Pay', 'ACH'],
  control_paypalcomplete: ['PayPal Business', 'Credit Card', 'PayPal', 'SPB', 'Venmo'],
  control_paypal: ['PayPal Personal', 'Credit Card', 'PayPal'],
  control_paypalInvoicing: ['PayPal Invoicing', 'Credit Card', 'PayPal'],
  control_paypalpro: ['PayPal Pro', 'Credit Card', 'PayPal'],
  control_paypalSPB: ['PayPal Checkout', 'Credit Card', 'PayPal'],
  control_paypalexpress: ['PayPal Express', 'Credit Card', 'PayPal'],
  control_authnet: ['Authorize.Net', 'Credit Card'],
  control_stripe: ['Stripe', 'Credit Card', 'Link'],
  control_stripeCheckout: ['Stripe Checkout', 'Credit Card', 'Apple Pay', 'Google Pay'],
  control_stripeACHManual: ['Stripe ACH Manual', 'ACH', 'Plaid'],
  control_stripeACH: ['Stripe ACH', 'ACH', 'Plaid'],
  control_braintree: ['Braintree', 'Credit Card'],
  control_cashApp: ['Cash App Pay', 'Credit Card', 'Cash App', 'Square'],
  control_afterpay: ['Afterpay', 'Square'],
  control_clearpay: ['Clearpay', 'Square'],
  control_venmo: ['Venmo', 'Credit Card', 'PayPal', 'PayPal Business'],
  control_applePayGooglePay: ['Apple & Google Pay', 'Apple Pay', 'Google Pay', 'Square', 'Stripe', 'Stripe Checkout'],
  control_mollie: [
    'Mollie', 'Credit Card', 'iDEAL', 'Bank Transfer', 'Sofort', 'Gift Cards', 'Bancontact',
    'EPS', 'Giropay', 'Przelewy24', 'KBC', 'CBC', 'KBC/CBC Payment', 'Belfius', 'Cartes Bancaires',
    'Riverty', 'in3', 'Billie', 'Satispay', 'Postepay', 'Bacs', 'Paysafe Card', 'Twint', 'Alma', 'Edenred',
    'EPS', 'Blik', 'Trustly', 'Swish'
  ],
  control_cybersource: ['CyberSource', 'Credit Card'],
  control_echeck: ['eCheck.Net', 'eCheck', 'Bank Transfer'],
  control_bluepay: ['Bluepay', 'Credit Card', 'Bank Transfer', 'Clover', 'Cash', 'Gift Card', 'EBT', 'Alipay'],
  control_eway: ['Eway', 'Credit Card', 'Australia'],
  control_payu: ['PayU', 'Credit Card', 'Bank Transfer', 'Blik'],
  control_payuMoney: ['PayU Money', 'PayU In', 'PayU India', 'Credit Card', 'Bank Transfer', 'Blik'],
  control_worldpay: ['Worldpay UK', 'Credit Card'],
  control_bluesnap: ['BlueSnap', 'Credit Card', 'ACH', 'Paysafe Card', 'Splitit'],
  control_moneris: ['Moneris', 'Credit Card'],
  control_gocardless: ['GoCardless'],
  control_payfast: ['Payfast', 'Credit Card', 'SnapScan', 'instantEFT', 'MoreTyme', 'Mobicred'],
  control_sensepass: ['SensePass', 'Credit Card', 'Alipay', 'Bitpay', 'Klarna', 'WeChat', 'Trustly', 'Coinbase', 'Apple Pay', 'Google Pay'],
  control_sofort: ['Sofort', 'Bank Transfer', 'Bank Transfer'],
  control_pagseguro: ['PagSeguro', 'Credit Card', 'PagBank', 'PagSeguro Credit', 'PagSeguro Credit Card'],
  control_firstdata: ['First Data', 'Credit Card', 'Payeezy'],
  control_cardconnect: ['CardConnect', 'Credit Card', 'Card Connect'],
  control_worldpayus: ['Worldpay US', 'Credit Card'],
  control_2co: ['2Checkout', 'Credit Card'],
  control_paysafe: ['Paysafe', 'Credit Card', 'ACH', 'Paysafe Card'],
  control_iyzico: ['Iyzico', 'Credit Card', 'iDEAL', 'Sofort', 'Giropay', 'Qiwi'],
  control_skrill: ['Skrill', 'Credit Card', 'Bank Transfer', 'Skrill Wallet'],
  control_chargify: ['Chargify', 'Credit Card', 'Maxio'],
  control_payjunction: ['PayJunction', 'Credit Card'],
  control_clickbank: ['ClickBank', 'Credit Card'],
  control_onebip: ['OneBip', 'Credit Card', 'One Bip'],
  control_paymentwall: ['PaymentWall', 'Credit Card', 'Payment Wall', 'Alipay', 'Amazon Pay', 'Pagseguro', 'Bitcoin', 'Union Pay', 'Mobiano'],
  control_payment: ['Payment', 'Purchase Order']
};
