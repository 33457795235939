import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import WarningBox from '@jotforminc/warning-box';
import { IconTrashFilled, IconGearFilled } from '@jotforminc/svg-icons';

import Selectors from '../../store/selectors';
import { changeGatewaySettingsView, changeGatewaySettingsVisibility, setActiveGatewaySettings } from '../../store/actionCreators';
import { IGatewayProperties, IGatewaySettings } from '../../types/common';
import { checkIsGatewayConnectedAndTestMode } from '../../utils';
import { getGatewayProperties } from '../../utils/paymentProperties';
import { switchPaymentGateway } from '../../api';
import { C_GATEWAYS } from '../modals/Flows/Constants';

export default function EditPaymentIntegration({
  activeGatewaySettings,
  onRemoveGateway,
  onOpenGatewaySettings,
  onEditPaymentIntegrationBtnClick,
  onDeletePaymentIntegrationBtnClick
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const checkoutFormId = useSelector(Selectors.getCheckoutFormId);
  const productListSettings = useSelector(Selectors.getProductListSettings);
  const { name, icon: Icon } = C_GATEWAYS.find(gateway => {
    if (activeGatewaySettings.nameAPM) {
      return gateway.nameAPMType === activeGatewaySettings.nameAPM;
    }
    return gateway.type === activeGatewaySettings.type;
  });

  const getGatewayProperty = <I extends keyof IGatewayProperties>(prop: I): IGatewayProperties[I] => {
    const type = activeGatewaySettings.nameAPM ? activeGatewaySettings.nameAPM : activeGatewaySettings.type;
    if (getGatewayProperties(type) && getGatewayProperties(type)[prop]) {
      return getGatewayProperties(type)[prop];
    }
  };

  const openGatewaySettings = () => {
    if (onOpenGatewaySettings) {
      onOpenGatewaySettings();
      return;
    }
    dispatch(changeGatewaySettingsVisibility(true));
    dispatch(changeGatewaySettingsView('gateway-settings'));
  };

  const removeGateway = () => {
    if (onRemoveGateway) {
      onRemoveGateway();
      return;
    }
    const data = {
      type: 'control_payment',
      currency: productListSettings.currency
    };

    switchPaymentGateway(checkoutFormId, data)
      .then(([gateway]) => {
        dispatch(setActiveGatewaySettings(gateway));
      });
  };

  const EditPaymentIntegrationButtonClick = () => {
    if (onEditPaymentIntegrationBtnClick) {
      onEditPaymentIntegrationBtnClick();
    } else {
      openGatewaySettings();
    }
  };

  const DeletePaymentIntegrationButtonClick = () => {
    if (onDeletePaymentIntegrationBtnClick) {
      onDeletePaymentIntegrationBtnClick();
    } else {
      removeGateway();
    }
  };

  const [isGatewayConnected, isTestModeEnabled] = checkIsGatewayConnectedAndTestMode(activeGatewaySettings);
  return (
    <div className={classNames('selectedGatewayInfo')}>
      {
        getGatewayProperty('icon')
          ? (<img className={classNames('selectedGatewayImage')} src={getGatewayProperty('icon')} alt='' />)
          : (<Icon className='w-12 h-12 radius' />)
      }
      <div className={classNames('gatewayTitle')}>
        <span>{name}</span>
      </div>
      <div className={classNames('buttonGroup')}>
        <Button
          colorStyle="secondary"
          onClick={EditPaymentIntegrationButtonClick}
          startIcon={IconGearFilled}
          aria-label={t('Edit Payment Integration')}
        />
        <Button
          colorStyle="secondary"
          className="ml-1"
          onClick={DeletePaymentIntegrationButtonClick}
          startIcon={IconTrashFilled}
          aria-label={t('Remove Payment Integration')}
        />
      </div>
      {isTestModeEnabled ? (
        <WarningBox>
          {t('Test mode is enabled for this payment.')}
        </WarningBox>
      ) : (
        !isGatewayConnected && (
          <WarningBox
            colorStyle='error'
          >
            {t('Integration is not configured.')}
          </WarningBox>
        )
      )}
    </div>
  );
}

interface IProps {
  activeGatewaySettings: IGatewaySettings
  onRemoveGateway: () => void
  onOpenGatewaySettings: () => void
}
