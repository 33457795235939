const noneColor = 'rgba(0, 0, 0, 0)';

export const BUTTON_STYLING_LIST = {
  default: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#0A1551'
  },
  cold: {
    itemFontColor: '#0A1551',
    itemBorderColor: noneColor,
    itemBgColor: '#DFDFFF'
  },
  sax: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#321860'
  },
  sky: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#0099FF'
  },
  grass: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#78BB07'
  },
  diabolic: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#FE3945'
  },
  savannah: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#FB7041'
  },
  caffeine: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#321F16'
  },
  yinyang: {
    itemFontColor: '#0A1551',
    itemBgColor: '#FFFFFF',
    itemBorderColor: '#D3DCEF'
  },
  oceanic: {
    itemFontColor: '#FFFFFF',
    itemBgColor: '#264B67',
    itemBorderColor: noneColor
  },
  detox: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#3C4C1E'
  },
  sakura: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#972D54'
  },
  shadow: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: '#11111B',
    itemBgColor: '#11111B'
  },
  parliament: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: noneColor,
    itemBgColor: '#02357D'
  }
};

export const getItemStylingList = ({
  itemFontColor,
  itemBorderColor,
  itemBgColor
}) => ({
  default: {
    itemBorderColor,
    itemBgColor,
    itemFontColor,
    itemIconBgColor: itemBgColor,
    itemIconColor: itemFontColor
  },
  oceanic: {
    itemFontColor: '#264B67',
    itemBorderColor: '#264B67',
    itemBgColor: '#C5EBF4',
    itemIconBgColor: '#C5EBF4',
    itemIconColor: '#264B67'
  },
  savannah: {
    itemBorderColor: '#6A1C00',
    itemBgColor: '#FFF1C5',
    itemFontColor: '#6A1C00',
    itemIconBgColor: '#FFF1C5',
    itemIconColor: '#6A1C00'
  },
  caffeine: {
    itemFontColor: '#321F16',
    itemBorderColor: '#321F16',
    itemBgColor: '#BEAD9D',
    itemIconBgColor: '#BEAD9D',
    itemIconColor: '#321F16'
  },
  detox: {
    itemFontColor: '#3C4C1E',
    itemBorderColor: '#3C4C1E',
    itemBgColor: '#D5EEAB',
    itemIconBgColor: '#D5EEAB',
    itemIconColor: '#3C4C1E'
  },
  shadow: {
    itemFontColor: '#FFFFFF',
    itemBorderColor: '#11111B',
    itemBgColor: '#424757',
    itemIconBgColor: '#424757',
    itemIconColor: '#FFFFFF'
  },
  sakura: {
    itemFontColor: '#972D54',
    itemBorderColor: '#972D54',
    itemBgColor: '#FFF8FA',
    itemIconBgColor: '#FFF8FA',
    itemIconColor: '#972D54'
  },
  parliament: {
    itemFontColor: '#02357D',
    itemBorderColor: '#02357D',
    itemBgColor: '#AACCFB',
    itemIconBgColor: '#AACCFB',
    itemIconColor: '#02357D'
  },
  sax: {
    itemFontColor: '#321860',
    itemBorderColor: '#321860',
    itemBgColor: '#C8B2EE',
    itemIconBgColor: '#C8B2EE',
    itemIconColor: '#321860'
  },
  blossom: {
    itemFontColor: '#3C0A1D',
    itemBorderColor: '#9B557C',
    itemBgColor: '#DFB4CC',
    itemIconBgColor: '#DFB4CC',
    itemIconColor: '#3C0A1D'
  }

});

export const getAppColorStylings = ({
  appBgColor,
  appBgColorEnd,
  appHeaderBgColor,
  appFontColor,
  listBgColor,
  logoBackground,
  iconColor,
  splashBgColor,
  splashFontColor,
  appIconBackground,
  appIconColor
}, itemStylingList) => (
  {
    default: {
      appColors: {
        appBgColor,
        appBgColorEnd,
        appHeaderBgColor,
        appFontColor,
        listBgColor,
        logoBackground,
        iconColor,
        splashBgColor,
        splashFontColor,
        appIconBackground,
        appIconColor
      },
      itemColors: itemStylingList.default,
      appPaletteColors: {
        borderColor: appHeaderBgColor,
        backgroundColor: appBgColor,
        fontColor: appFontColor
      }
    },
    oceanic: {
      appColors: {
        appBgColor: '#264B67',
        appBgColorEnd: '#264B67',
        appHeaderBgColor: '#83D0E4',
        appFontColor: '#264B67',
        listBgColor: '#F1FCFF',
        logoBackground: '#264B67',
        iconColor: '#F1FCFF',
        splashBgColor: '#83D0E4',
        splashFontColor: '#00485A',
        appIconBackground: '#264B67',
        appIconColor: '#F1FCFF'
      },
      itemColors: itemStylingList.oceanic,
      appPaletteColors: {
        borderColor: '#95CEE1',
        backgroundColor: '#F3FCFF',
        fontColor: '#264B67'
      }
    },
    savannah: {
      appColors: {
        appBgColor: '#FB7041',
        appBgColorEnd: '#FB7041',
        appHeaderBgColor: '#FFDF79',
        appFontColor: '#6A1C00',
        listBgColor: '#FFFFFF',
        logoBackground: '#FB7041',
        iconColor: '#FFDF79',
        splashBgColor: '#FFDF79',
        splashFontColor: '#6A1C00',
        appIconBackground: '#FB7041',
        appIconColor: '#FFDF79'
      },
      itemColors: itemStylingList.savannah,
      appPaletteColors: {
        borderColor: '#EA774E',
        backgroundColor: '#FADF88',
        fontColor: '#000000'
      }
    },
    caffeine: {
      appColors: {
        appBgColor: '#321F16',
        appBgColorEnd: '#321F16',
        designatedItemFontColor: '#321F16',
        appHeaderBgColor: '#5A4B45',
        appFontColor: '#EFE8D4',
        listBgColor: '#EFE8D4',
        logoBackground: '#321F16',
        iconColor: '#EFE8D4',
        splashBgColor: '#5A4B45',
        splashFontColor: '#FFFFFF',
        appIconBackground: '#321F16',
        appIconColor: '#EFE8D4'
      },
      itemColors: itemStylingList.caffeine,
      appPaletteColors: {
        borderColor: '#584C46',
        backgroundColor: '#EEE8D6',
        fontColor: '#321F16'
      }
    },
    detox: {
      appColors: {
        appBgColor: '#3C4C1E',
        appBgColorEnd: '#3C4C1E',
        designatedItemFontColor: '#3C4C1E',
        appHeaderBgColor: '#B3D37B',
        appFontColor: '#3C4C1E',
        listBgColor: '#F4FFDF',
        logoBackground: '#3C4C1E',
        iconColor: '#F4FFDF',
        splashBgColor: '#B3D37B',
        splashFontColor: '#3C4C1E',
        appIconBackground: '#3C4C1E',
        appIconColor: '#F4FFDF'
      },
      itemColors: itemStylingList.detox,
      appPaletteColors: {
        borderColor: '#B9D185',
        backgroundColor: '#F6FFE2',
        fontColor: '#3C4C1E'
      }
    },
    shadow: {
      appColors: {
        appBgColor: '#424757',
        appBgColorEnd: '#424757',
        designatedItemFontColor: '#FFFFFF',
        appHeaderBgColor: '#11111B',
        appFontColor: '#FFFFFF',
        listBgColor: '#6E7487',
        logoBackground: '#424757',
        iconColor: '#FFFFFF',
        splashBgColor: '#11111B',
        splashFontColor: '#FFFFFF',
        appIconBackground: '#424757',
        appIconColor: '#FFFFFF'
      },
      itemColors: itemStylingList.shadow,
      appPaletteColors: {
        borderColor: '#11111A',
        backgroundColor: '#434756',
        fontColor: '#FFFFFF'
      }
    },
    sakura: {
      appColors: {
        appBgColor: '#FF98BC',
        appBgColorEnd: '#FF98BC',
        designatedItemFontColor: '#972D54',
        appHeaderBgColor: '#972D54',
        appFontColor: '#FFFFFF',
        listBgColor: '#FFFFFF',
        logoBackground: '#FF98BC',
        iconColor: '#FFF8FA',
        splashBgColor: '#972D54',
        splashFontColor: '#FFFFFF',
        appIconBackground: '#FF98BC',
        appIconColor: '#FFFFFF'
      },
      itemColors: itemStylingList.sakura,
      appPaletteColors: {
        borderColor: '#F19DBB',
        backgroundColor: '#FEF8FA',
        fontColor: '#972D54'
      }
    },
    parliament: {
      appColors: {
        appBgColor: '#02357D',
        appBgColorEnd: '#02357D',
        designatedItemFontColor: '#02357D',
        appHeaderBgColor: '#2462BA',
        appFontColor: '#FFFFFF',
        listBgColor: '#E5EFFF',
        logoBackground: '#02357D',
        iconColor: '#E5EFFF',
        splashBgColor: '#2462BA',
        splashFontColor: '#FFFFFF',
        appIconBackground: '#02357D',
        appIconColor: '#E5EFFF'
      },
      itemColors: itemStylingList.parliament,
      appPaletteColors: {
        borderColor: '#3462B4',
        backgroundColor: '#E7EFFD',
        fontColor: '#02357D'
      }
    },
    sax: {
      appColors: {
        appBgColor: '#412A69',
        appBgColorEnd: '#412A69',
        designatedItemFontColor: '#321860',
        appHeaderBgColor: '#7C57BD',
        appFontColor: '#F7F2FF',
        listBgColor: '#F7F2FF',
        logoBackground: '#412A69',
        iconColor: '#F7F2FF',
        splashBgColor: '#7C57BD',
        splashFontColor: '#FFFFFF',
        appIconBackground: '#412A69',
        appIconColor: '#F7F2FF'
      },
      itemColors: itemStylingList.sax,
      appPaletteColors: {
        borderColor: '#765BB7',
        backgroundColor: '#F6F2FE',
        fontColor: '#321860'
      }
    }
  }
);
