import React from 'react';
import { useSelector } from 'react-redux';
import WarningBox from '@jotforminc/warning-box';
import SELECTORS from '../../../../../../store/selectors';

const DonationGatewayWarningItem = () => {
  const selectedItemID = useSelector(SELECTORS.getSelectedPortalItem);
  const { error, shouldShowError } = useSelector(SELECTORS.getItemAvailable(selectedItemID, 'RightPanel'));

  return shouldShowError && (
  <div className="px-4">
    <WarningBox
      isLongText
      size='medium'
      className="mt-5"
    >
      {error}
    </WarningBox>
  </div>
  );
};

export default DonationGatewayWarningItem;
