import React from 'react';
import { bool, shape } from 'prop-types';
import FormItemWrapper from '../../../FormPicker/FormItem/styled';
import { CheckBox } from '../../../FormPicker/CheckBox';

const OperationItem = ({
  option: {
    name
  },
  isSelected,
  isMultiSelect
}) => {
  return (
    <FormItemWrapper tabIndex="0">
      {isMultiSelect && <CheckBox isSelected={isSelected} />}
      <div className="itemContent">
        <h2 className="itemTitle">
          {name}
        </h2>
      </div>
    </FormItemWrapper>
  );
};

OperationItem.propTypes = {
  option: shape().isRequired,
  isSelected: bool.isRequired,
  isMultiSelect: bool
};

OperationItem.defaultProps = {
  isMultiSelect: true
};

export default OperationItem;
