import { Button } from '@jotforminc/magnet';
import React from 'react';
import { t } from '@jotforminc/translation';
import { IconImageArrowUpFilled } from '@jotforminc/svg-icons';
import propTypes from 'prop-types';
import { isNewPanel } from '../../../../../utils';

const ChooseFileButton = ({ onClick, text }) => {
  return (
    <Button
      variant={isNewPanel() ? 'filled' : 'outline'}
      fullWidth={!isNewPanel()}
      className={`${isNewPanel() ? 'flex mt-1 mb-2' : 'customMagnetBtn'}`}
      colorStyle='secondary'
      startIcon={isNewPanel() && IconImageArrowUpFilled}
      onClick={onClick}
    >
      {t(text)}
    </Button>
  );
};

ChooseFileButton.propTypes = {
  onClick: propTypes.func,
  text: propTypes.string
};

ChooseFileButton.defaultProps = {
  onClick: f => f,
  text: 'Choose a file'
};

export default ChooseFileButton;
