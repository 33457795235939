export const TOGGLE = 'toggle';
export const SELECTBOX = 'selectbox';
export const CONNECTION_BUTTON = 'connection_button';
export const CURRENCY_DROPDOWN = 'currency_dropdown';
export const LAYOUT_BUTTON = 'layout_button';
export const TEXTINPUT = 'textinput';
export const INFOLINE = 'infoline';
export const CHECKBOX = 'checkbox';
export const COPYURL = 'copyurl';
export const STEPS = 'steps';
export const COLORPICKER = 'colorpicker';
export const TEXTAREA = 'textarea';
export const GROUPHEADER = 'groupheader';
export const FIXED_AMOUNTS = 'fixed_amounts';
export const DIVIDER = 'divider';
export const PRICE_INPUT = 'price_input';
export const INPUT_RANGE = 'input_range';
export const INPUT_RANGE_WITH_TYPE = 'input_range_with_type';
export const DROPDOWN = 'dropdown';
export const NEWDROPDOWN = 'newdropdown';
export const NEWTEXTINPUT = 'newtextinput';
export const CREDENTIAL = 'credential';
export const LABELVALUETABLE = 'labelvaluetable';
export const RADIOGROUP = 'radiogroup';
export const SUFFIXINPUT = 'suffixinput';
