export const Languages = [
  { key: 'aa', value: 'Afar' },
  { key: 'ab', value: 'Abkhazian' },
  { key: 'ae', value: 'Avestan' },
  { key: 'af', value: 'Afrikaans' },
  { key: 'ak', value: 'Akan' },
  { key: 'am', value: 'Amharic' },
  { key: 'an', value: 'Aragonese' },
  { key: 'ar', value: 'Arabic' },
  { key: 'as', value: 'Assamese' },
  { key: 'av', value: 'Avaric' },
  { key: 'ay', value: 'Aymara' },
  { key: 'az', value: 'Azerbaijani' },
  { key: 'ba', value: 'Bashkir' },
  { key: 'be', value: 'Belarusian' },
  { key: 'bg', value: 'Bulgarian' },
  { key: 'bh', value: 'Bihari' },
  { key: 'bi', value: 'Bislama' },
  { key: 'bm', value: 'Bambara' },
  { key: 'bn', value: 'Bengali' },
  { key: 'bo', value: 'Tibetan' },
  { key: 'br', value: 'Breton' },
  { key: 'bs', value: 'Bosnian' },
  { key: 'ca', value: 'Catalan' },
  { key: 'ce', value: 'Chechen' },
  { key: 'ch', value: 'Chamorro' },
  { key: 'co', value: 'Corsican' },
  { key: 'cr', value: 'Cree' },
  { key: 'cs', value: 'Czech' },
  { key: 'cu', value: 'Church Slavic' },
  { key: 'cv', value: 'Chuvash' },
  { key: 'cy', value: 'Welsh' },
  { key: 'da', value: 'Danish' },
  { key: 'de', value: 'German' },
  { key: 'dv', value: 'Divehi' },
  { key: 'dz', value: 'Dzongkha' },
  { key: 'ee', value: 'Ewe' },
  { key: 'el', value: 'Greek' },
  { key: 'en', value: 'English' },
  { key: 'eo', value: 'Esperanto' },
  { key: 'es', value: 'Spanish' },
  { key: 'et', value: 'Estonian' },
  { key: 'eu', value: 'Basque' },
  { key: 'fa', value: 'Persian' },
  { key: 'ff', value: 'Fulah' },
  { key: 'fi', value: 'Finnish' },
  { key: 'fj', value: 'Fijian' },
  { key: 'fo', value: 'Faroese' },
  { key: 'fr', value: 'Français' },
  { key: 'fy', value: 'Western Frisian' },
  { key: 'ga', value: 'Irish' },
  { key: 'gd', value: 'Scottish Gaelic' },
  { key: 'gl', value: 'Galician' },
  { key: 'gn', value: 'Guarani' },
  { key: 'gu', value: 'Gujarati' },
  { key: 'gv', value: 'Manx' },
  { key: 'ha', value: 'Hausa' },
  { key: 'he', value: 'Hebrew' },
  { key: 'hi', value: 'Hindi' },
  { key: 'ho', value: 'Hiri Motu' },
  { key: 'hr', value: 'Croatian' },
  { key: 'ht', value: 'Haitian' },
  { key: 'hu', value: 'Hungarian' },
  { key: 'hy', value: 'Armenian' },
  { key: 'hz', value: 'Herero' },
  { key: 'ia', value: 'Interlingua' },
  { key: 'id', value: 'Indonesian' },
  { key: 'ie', value: 'Interlingue' },
  { key: 'ig', value: 'Igbo' },
  { key: 'ii', value: 'Sichuan Yi' },
  { key: 'ik', value: 'Inupiaq' },
  { key: 'io', value: 'Ido' },
  { key: 'is', value: 'Icelandic' },
  { key: 'it', value: 'Italian' },
  { key: 'iu', value: 'Inuktitut' },
  { key: 'ja', value: 'Japanese' },
  { key: 'jv', value: 'Javanese' },
  { key: 'ka', value: 'Georgian' },
  { key: 'kg', value: 'Kongo' },
  { key: 'ki', value: 'Kikuyu' },
  { key: 'kj', value: 'Kwanyama' },
  { key: 'kk', value: 'Kazakh' },
  { key: 'kl', value: 'Kalaallisut' },
  { key: 'km', value: 'Khmer' },
  { key: 'kn', value: 'Kannada' },
  { key: 'ko', value: 'Korean' },
  { key: 'kr', value: 'Kanuri' },
  { key: 'ks', value: 'Kashmiri' },
  { key: 'ku', value: 'Kurdish' },
  { key: 'kv', value: 'Komi' },
  { key: 'kw', value: 'Cornish' },
  { key: 'ky', value: 'Kirghiz' },
  { key: 'la', value: 'Latin' },
  { key: 'lb', value: 'Luxembourgish' },
  { key: 'lg', value: 'Ganda' },
  { key: 'li', value: 'Limburgish' },
  { key: 'ln', value: 'Lingala' },
  { key: 'lo', value: 'Lao' },
  { key: 'lt', value: 'Lithuanian' },
  { key: 'lu', value: 'Luba-Katanga' },
  { key: 'lv', value: 'Latvian' },
  { key: 'mg', value: 'Malagasy' },
  { key: 'mh', value: 'Marshallese' },
  { key: 'mi', value: 'Maori' },
  { key: 'mk', value: 'Macedonian' },
  { key: 'ml', value: 'Malayalam' },
  { key: 'mn', value: 'Mongolian' },
  { key: 'mr', value: 'Marathi' },
  { key: 'ms', value: 'Malay' },
  { key: 'mt', value: 'Maltese' },
  { key: 'my', value: 'Burmese' },
  { key: 'na', value: 'Nauru' },
  { key: 'nb', value: 'Norwegian Bokmal' },
  { key: 'nd', value: 'North Ndebele' },
  { key: 'ne', value: 'Nepali' },
  { key: 'ng', value: 'Ndonga' },
  { key: 'nl', value: 'Nederlands' },
  { key: 'nn', value: 'Norwegian Nynorsk' },
  { key: 'no', value: 'Norwegian' },
  { key: 'nr', value: 'South Ndebele' },
  { key: 'nv', value: 'Navajo' },
  { key: 'ny', value: 'Chichewa' },
  { key: 'oc', value: 'Occitan' },
  { key: 'oj', value: 'Ojibwa' },
  { key: 'om', value: 'Oromo' },
  { key: 'or', value: 'Oriya' },
  { key: 'os', value: 'Ossetian' },
  { key: 'pa', value: 'Panjabi' },
  { key: 'pi', value: 'Pali' },
  { key: 'pl', value: 'Polish' },
  { key: 'ps', value: 'Pashto' },
  { key: 'pt', value: 'Portuguese' },
  { key: 'qu', value: 'Quechua' },
  { key: 'rm', value: 'Raeto-Romance' },
  { key: 'rn', value: 'Kirundi' },
  { key: 'ro', value: 'Română' },
  { key: 'ru', value: 'Russian' },
  { key: 'rw', value: 'Kinyarwanda' },
  { key: 'sa', value: 'Sanskrit' },
  { key: 'sc', value: 'Sardinian' },
  { key: 'sd', value: 'Sindhi' },
  { key: 'se', value: 'Northern Sami' },
  { key: 'sg', value: 'Sango' },
  { key: 'si', value: 'Sinhala' },
  { key: 'sk', value: 'Slovak' },
  { key: 'sl', value: 'Slovenian' },
  { key: 'sm', value: 'Samoan' },
  { key: 'sn', value: 'Shona' },
  { key: 'so', value: 'Somali' },
  { key: 'sq', value: 'Albanian' },
  { key: 'sr', value: 'Serbian' },
  { key: 'ss', value: 'Swati' },
  { key: 'st', value: 'Southern Sotho' },
  { key: 'su', value: 'Sundanese' },
  { key: 'sv', value: 'Swedish' },
  { key: 'sw', value: 'Swahili' },
  { key: 'ta', value: 'Tamil' },
  { key: 'te', value: 'Telugu' },
  { key: 'tg', value: 'Tajik' },
  { key: 'th', value: 'Thai' },
  { key: 'ti', value: 'Tigrinya' },
  { key: 'tk', value: 'Turkmen' },
  { key: 'tl', value: 'Filipino' },
  { key: 'tn', value: 'Tswana' },
  { key: 'to', value: 'Tonga' },
  { key: 'tr', value: 'Turkish' },
  { key: 'ts', value: 'Tsonga' },
  { key: 'tt', value: 'Tatar' },
  { key: 'tw', value: 'Twi' },
  { key: 'ty', value: 'Tahitian' },
  { key: 'ug', value: 'Uighur' },
  { key: 'uk', value: 'Ukrainian' },
  { key: 'ur', value: 'Urdu' },
  { key: 'uz', value: 'Uzbek' },
  { key: 've', value: 'Venda' },
  { key: 'vi', value: 'Vietnamese' },
  { key: 'vo', value: 'Volapuk' },
  { key: 'wa', value: 'Walloon' },
  { key: 'wo', value: 'Wolof' },
  { key: 'xh', value: 'Xhosa' },
  { key: 'yi', value: 'Yiddish' },
  { key: 'yo', value: 'Yoruba' },
  { key: 'za', value: 'Zhuang' },
  { key: 'zh', value: 'Chinese' },
  { key: 'zu', value: 'Zulu' }
];
