import React, { useCallback } from 'react';
import { TextInput } from '@jotforminc/uikit';

const PwTextInput = ({
  id,
  className,
  onChange,
  defaultValue,
  hintOn
}: IProps): JSX.Element => {
  const onBlurHandler = useCallback(e => {
    const val = e.target.value;
    onChange(val);
  }, [onChange]);

  return (
    <div className="pwTextInput">
      <TextInput
        id={id}
        className={className}
        onBlur={onBlurHandler}
        defaultValue={defaultValue}
      />
      {hintOn && <p>{hintOn}</p>}
    </div>
  );
};

export interface IProps {
  id?: string
  className?: string
  onChange: (value: boolean | string) => void
  defaultValue: string,
  hintOn?: string
}

PwTextInput.defaultProps = {
  id: '',
  className: '',
  hintOn: ''
};

export default PwTextInput;
