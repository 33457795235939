import React from 'react';
import {
  string, func, bool, array
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { FormControl, FormLabel, InputText } from '@jotforminc/magnet';
import { OptionGroup } from '@jotforminc/option-group';

const CloneSettings = ({
  title,
  onTitleChange,
  cloneSetting,
  onCloneSettingChange,
  isThereAnyForm,
  options,
  settingLabel,
  inputLabel,
  inputPlaceholder
}) => {
  return (
    <>
      <FormControl id="duplicateName" className="mt-5">
        <FormLabel className="text-md">
          {t(inputLabel)}
        </FormLabel>
        <InputText
          placeholder={inputPlaceholder}
          className="jfWizard-body-input"
          value={title}
          onChange={e => {
            const { value } = e.target;
            onTitleChange(value);
          }}
        />
      </FormControl>
      {isThereAnyForm && (
        <FormControl id="duplicateAppName" className="mt-7">
          <FormLabel>{t(settingLabel)}</FormLabel>
          <OptionGroup
            key={cloneSetting}
            isMultiLine
            isMultiSelect={false}
            defaultValue={cloneSetting}
            options={options}
            onSelectionChange={onCloneSettingChange}
          />
        </FormControl>
      )}
    </>
  );
};

CloneSettings.propTypes = {
  title: string,
  onTitleChange: func,
  cloneSetting: string,
  onCloneSettingChange: func,
  isThereAnyForm: bool,
  options: array,
  settingLabel: string,
  inputLabel: string,
  inputPlaceholder: string
};

CloneSettings.defaultProps = {
  title: '',
  onTitleChange: f => f,
  cloneSetting: '',
  onCloneSettingChange: f => f,
  isThereAnyForm: false,
  options: [],
  settingLabel: '',
  inputLabel: '',
  inputPlaceholder: 'pls enter one...'
};

export default CloneSettings;
