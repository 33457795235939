import React from 'react';
import { t } from '@jotforminc/translation';
import PropTypes from 'prop-types';
import { isTeamResourcePicker } from '../../../../../utils';

const Header = ({ isMultiSelect }) => (
  <>
    <div className="pm-t">{t(`Select Form${isMultiSelect ? 's' : ''}`)}</div>
    <p className="pm-d">{t(`Select the form${isMultiSelect ? 's' : ''} you want to add to your ${isTeamResourcePicker() ? 'team\'s app.' : 'app.'}`)}</p>
  </>
);

Header.propTypes = {
  isMultiSelect: PropTypes.bool.isRequired
};

export default Header;
