import { FORM_PRODUCTS } from './index';

export const DESTINATION_TYPES = {
  BACK: 'back',
  SCREEN: 'screen',
  PRODUCT: 'product',
  PAGE: 'page',
  SPECIAL_PAGE: 'specialPage',
  BUILDER_PAGE: 'builderPage'
};

export const BUILDER_TABS = {
  BUILD: 'build',
  SETTINGS: 'settings',
  PUBLISH: 'publish'
};

export const SETTINGS_SUBTABS = {
  DETAILS: 'details',
  APP_NAME: 'appName',
  SPLASH: 'splash'
};

export const PUBLISH_SUBTABS = {
  LINK: 'link',
  EMBED: 'embed'
};

export const DEFAULT_SUBTABS = {
  [BUILDER_TABS.BUILD]: '',
  [BUILDER_TABS.SETTINGS]: SETTINGS_SUBTABS.DETAILS,
  [BUILDER_TABS.PUBLISH]: PUBLISH_SUBTABS.LINK
};

export const SPECIAL_PAGES = {
  CART: 'cart',
  CHECKOUT: 'checkout',
  PREVIOUS_ORDERS: 'previousOrders',
  PREVIOUS_DONATIONS: 'previousDonations',
  PRODUCT: 'product',
  DETAILS: 'details'
};

export const ANIMATION_TYPES = {
  PAGE_CHANGE: 'pageChange',
  LOCATION: 'location',
  INITIAL: 'initial',
  NONE: ''
};

export const PRODUCT_SEGMENTS = {
  [FORM_PRODUCTS.FORM]: '',
  [FORM_PRODUCTS.CONTINUE_DRAFT]: '/continue',
  [FORM_PRODUCTS.SENTBOX]: '/sentbox',
  [FORM_PRODUCTS.TABLES]: '/tables',
  [FORM_PRODUCTS.REPORT]: '/report',
  [FORM_PRODUCTS.SIGN]: '/sign'
};

export const TRACK_MESSAGE_PREFIX = {
  [FORM_PRODUCTS.FORM]: 'form',
  [FORM_PRODUCTS.CONTINUE_DRAFT]: 'draft',
  [FORM_PRODUCTS.SENTBOX]: 'sentbox',
  [FORM_PRODUCTS.TABLES]: 'tables',
  [FORM_PRODUCTS.REPORT]: 'report',
  [FORM_PRODUCTS.SIGN]: 'sign'
};
