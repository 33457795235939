/* eslint-disable max-len */
import Styled from 'styled-components';

const greenColor = '#78BB07';
const greenColorDarken = '#6da70c';

export const ScSearch = Styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  transition: all 0.2s linear;

  @media screen and (max-width: 768px) {
    & {
      background: #fff;
      padding: 10px 20px 20px;
      width: calc(100% + 40px);
      margin-inline-start: -20px;
    }
  }
  .searchWrapper {
    position: relative;
    width: calc(100% - 72px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .input {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      outline: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 46px;
      height: 48px;
      padding: 0 16px;
      display: flex;
      position: relative;
      border: 1px solid transparent;
      border-radius: 4px;
      transition: all 0.2s;
      -webkit-appearance: none;
      background-color: #E3E5F5;
      color: #6F76A7;
      @media screen and (max-width: 768px) {
        height: 36px;
        font-size: 16px;
        padding: 0 12px 0 8px;
        line-height: 30px;
      }   
      &:-webkit-autofill,
      &:-webkit-autofill:hover {
        box-shadow: 0 0 0 30px #e6ebf2 inset !important;
      }

      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #fff inset !important;
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: #2c3243 !important;
      }

      &::-webkit-autofill {
        box-shadow: none;
      }

      &::placeholder {
        color: #6F76A7;
        font-weight: 400;
      }

      &:-ms-input-placeholder {
        color: #6F76A7 !important;
        font-weight: 400;
      }

      &:-webkit-search-cancel-button {
        -webkit-appearance: none;
        content: url("data:image/svg+xml,%3Csvg width='12' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-518-18H26c2.2091 0 4 1.7909 4 4v40c0 2.2091-1.7909 4-4 4h-544c-2.2091 0-4-1.7909-4-4v-40c0-2.2091 1.7909-4 4-4z' fill='none' fill-rule='nonzero'/%3E%3Cpath d='M6 7.4142l-4.2929 4.293c-.3905.3904-1.0237.3904-1.4142 0-.3905-.3906-.3905-1.0238 0-1.4143L4.5858 6 .2928 1.7071C-.0975 1.3166-.0975.6834.2929.293c.3906-.3905 1.0238-.3905 1.4143 0L6 4.5858l4.2929-4.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L7.4142 6l4.293 4.2929c.3904.3905.3904 1.0237 0 1.4142-.3906.3905-1.0238.3905-1.4143 0L6 7.4142z' fill='%23c3cad8'/%3E%3C/g%3E%3C/svg%3E");
        cursor: pointer;
      }

      &:focus {
        outline: none;
        border-color: $blueColor;
        box-shadow: 0px 0px 0px 3px #bdcefb;
        background-color: #FFF;
      }

      &:focus,
      &:active {
        background-color: #ffffff;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  .go {
    background-color: ${greenColor};
    border: none;
    border-radius: 4px;
    margin-inline-start: 8px;
    height: 48px;
    flex: 0 0 64px;
    max-width: 64px;
    transition: all 0.2s;
    border: 1px solid transparent;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${greenColorDarken};
    }
    @media screen and (max-width: 768px) {
      height: 36px;
      flex: 0 0 44px;
      max-width: 44px;
      background-size: 12px;
      margin-left: 4px;
    } 
  }
  &.forSidebar {
    margin-bottom: 20px;
    .searchWrapper {
      width: calc(100% - 42px);
      .input {
        font-size: 12px;
        padding: 0 12px 0 8px;
        line-height: 30px;
        height: 32px;     
      }
    }
    .go {
      height: 32px;
      flex: 0 0 40px;
      max-width: 40px;
      background-size: 12px;
      margin-left: 4px;
    }
    @media screen and (max-width: 768px) {
      display: none;
      padding: 10px 0 0;
      margin-inline-start: 0;
      width: 100%;
    }
  }
  &.showMobile {
    display: none;
    @media screen and (max-width: 768px) {
      & {
        display: flex;
      }
    }
  }
  
  &.isSticky {
    position: sticky;
    top: -17px;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.5) 0 16px 16px -16px;
    padding: 10px 20px;

    .searchWrapper {
      width: calc(100% - 52px);

      .input {
        height: 36px;
        font-size: 16px;
      }
    }

    .go {
      height: 36px;
      flex: 0 0 44px;
      max-width: 44px;
      background-size: 16px;
    }
  }
`;
