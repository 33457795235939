import React, { useCallback } from 'react';
import {
  bool, func, shape, string
} from 'prop-types';

import {
  PanelHeader
} from '@jotforminc/jotform-common';
import { t } from '@jotforminc/translation';
import { Utils } from '@jotforminc/uikit';

import {
  IconAngleLeft, IconMobileTitle
} from '@jotforminc/svg-icons';
import {
  InputText, FormControl, FormLabel, FormHelperText
} from '@jotforminc/magnet';
import { ScSettingsContent } from '../scSettings';
import SettingsRightPanel from '../SettingsRightPanel';
import { RightPanelModes } from '../../HomePage/constants';
import ScStageHeader from '../scStageHeader';
import AppIconPreview from './AppIconPreview';
import { useIfMobileSettings } from '../../../../../utils/navigation';
import ScBackIconWrapper from './ScBackIconWrapper';

const AppIdentifierSettings = ({
  appName,
  appTitle,
  isWarningVisible,
  appIconURL,
  appVersion,
  appProperties,
  onTitleChange,
  onTitleBlur,
  onIconClick,
  onOpenRightPanel,
  onEditIconClick, // A/B Test: ctAppNameIconModal
  showPanelHeader, // A/B Test: ctAppNameIconModal
  onAppIconClick // A/B Test: ctAppNameIconModal
}) => {
  const isInMobileSettings = useIfMobileSettings();
  const icon = isInMobileSettings ? <IconAngleLeft width="40" color="#fff" /> : <IconMobileTitle width="28px" color="#fff" />;
  const handleKeyPress = e => (Utils.isPressedKeyEnter(e) && onIconClick());
  const IconWrapper = props => (<ScBackIconWrapper {...props} onClick={onIconClick} onKeyPress={handleKeyPress} />);

  const handleAppNameKeyPress = useCallback(e => Utils.isPressedKeyEnter(e) && e.target.blur(), []);

  return (
    <div>
      {showPanelHeader && (
      <ScStageHeader>
        <PanelHeader
          title={t('App Name & Icon')}
          subtitle={t('The app icon will appear when users add your app to their home screen.')}
          icon={icon}
          IconWrapper={isInMobileSettings ? IconWrapper : undefined}
        />
      </ScStageHeader>
      )}
      <ScSettingsContent>
        <FormControl
          className="mb-4"
          id="appName"
          size="large"
          colorStyle={isWarningVisible ? 'error' : 'default'}
        >
          <FormLabel
            htmlFor='appName'
            description={t('Enter a short, descriptive name for this app.')}
          >
            {t('App Name')}
          </FormLabel>
          <InputText
            id="appName"
            defaultValue={appName}
            onChange={e => onTitleChange(e.target.value)}
            onBlur={onTitleBlur}
            onKeyPress={handleAppNameKeyPress}
            placeholder={t('New App')}
            aria-label={t('Enter app name')}
          />
          {isWarningVisible
            && <FormHelperText>{t('App name cannot be blank.')}</FormHelperText>}
        </FormControl>
        <div className="appIconSection">
          <SettingsRightPanel
            featureName={t('Preview')}
            editButtonText={t('Edit icon')}
            mode={RightPanelModes.APP_ICON}
            // A/B Test: ctAppNameIconModal
            onOpenRightPanel={onEditIconClick || onOpenRightPanel(RightPanelModes.APP_ICON)}
          />
          <AppIconPreview
            appName={appTitle}
            appIconURL={appIconURL}
            appVersion={appVersion}
            appProperties={appProperties}
            // A/B Test: ctAppNameIconModal
            onOpenRightPanel={onAppIconClick || onOpenRightPanel(RightPanelModes.APP_ICON)}
          />
        </div>
      </ScSettingsContent>
    </div>
  );
};

AppIdentifierSettings.propTypes = {
  appName: string,
  appTitle: string,
  isWarningVisible: bool,
  appIconURL: string,
  appVersion: string,
  appProperties: shape({}),
  showPanelHeader: bool,
  onTitleChange: func,
  onTitleBlur: func,
  onIconClick: func,
  onOpenRightPanel: func,
  onEditIconClick: func,
  onAppIconClick: func
};

AppIdentifierSettings.defaultProps = {
  appName: '',
  appTitle: '',
  isWarningVisible: false,
  appIconURL: '',
  appVersion: '',
  appProperties: {},
  showPanelHeader: true,
  onTitleChange: f => f,
  onTitleBlur: f => f,
  onIconClick: f => f,
  onOpenRightPanel: f => f,
  onEditIconClick: null,
  onAppIconClick: null
};

export default AppIdentifierSettings;
