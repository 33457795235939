import React from 'react';
import { Spinner } from '@jotforminc/portal-components';
import Styled from 'styled-components';
import Dropdown from '../../../components/Dropdown';
import { ScDropdown } from '../../../components/Dropdown/scDropdown';
import { getOptionProps } from '../../../utils/paymentUtils';

/* eslint-disable max-len */
export const ScProductDetailOption = Styled(ScDropdown)`
  width: 100%;

  .dropdown-button {
    padding: 6.5px 10px;

    &:after {
      border-left: none;
      width: 11px;
      right: 11px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 7'%3E%3Cpath stroke='%231D2543' stroke-width='1.2' d='m10.4705 1.0842-4.47699 4.177-4.475-4.178a.313012.313012 0 0 0-.21916-.07662.312987.312987 0 0 0-.21164.09542.312957.312957 0 0 0-.0877.21496.313.313 0 0 0 .0845.21624l4.7 4.384a.314007.314007 0 0 0 .2125.08283.314044.314044 0 0 0 .2125-.08283l4.68399-4.377a.314166.314166 0 0 0 .072-.10131c.017-.03823.0262-.07947.027-.1213a.31566.31566 0 0 0-.0221-.12228.31515.31515 0 0 0-.0679-.10411c-.0569-.05715-.1339-.08985-.2146-.09116-.0806-.001306-.1586.02888-.2174.08416Z'/%3E%3C/svg%3E");
    }

    & ~ [data-popper-placement] {
      max-height: 170px;
      overflow: scroll;
      z-index: 7;
    }
  }

  .dropdown-option {
    &-container {
      font-size: 14px;
      padding: 8px 11px;
    }
  }
`;
/* eslint-enable max-len */

const ProductDetailOptionsGroup = ({
  options, onChange
}) => {
  return options.map((option, index) => { // we must accept index as option id :(
    const {
      name,
      type,
      dropdownProperties,
      optionValue
    } = option;

    let spinnerProps;

    const isTypeQuantity = type === 'quantity';

    if (isTypeQuantity) {
      spinnerProps = getOptionProps(option);
    }

    const onOptionChange = value => {
      onChange(
        { [index.toString()]: value }
      );
    };

    return (
      <div className="product-option" key={`${option.type}_${option.name}`}>
        <div className="product-option-label">{name}</div>
        {
          (type === 'quantity') ? (
            <Spinner value={optionValue} onChange={onOptionChange} {...spinnerProps} />
          ) : (
            <ScProductDetailOption>
              <Dropdown options={dropdownProperties} onChange={onOptionChange} value={optionValue} />
            </ScProductDetailOption>
          )
        }
      </div>
    );
  });
};

export default ProductDetailOptionsGroup;
