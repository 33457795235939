import React from 'react';
import Styled from 'styled-components';
import { renderable } from '../../../../constants/propTypes';

const ScShareAppModalDialog = Styled.div`
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100%;
  z-index: 1002;
  background-color: rgba(39, 41, 55, 0.61);
  display: flex;
  flex-direction: column;
  align-items: center;

  div[data-uikit-modal-container="true"] {
    width: 92%;
    max-width: 674px;
    max-height: 90%;
    min-width: 335px;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: auto;
    overflow: auto;
  }

  .copyBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
    border: 0;
    font-size: 1em;
    line-height: 1.5;
    font-weight: 500;
    font-family: inherit;
    background-color: #343C6A;
    color: #fff;
    height: 40px;
    transition: background-color ease .3s;

    &:hover {
      background-color: #4E5684;
    }
    &:active {
      background-color: #1B2351;
    }
  }
`;

const DialogRenderer = ({ children }) => (<ScShareAppModalDialog>{children}</ScShareAppModalDialog>);
DialogRenderer.propTypes = { children: renderable.isRequired };

export default DialogRenderer;
