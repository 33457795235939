import {
  combineReducers, createStore, compose, applyMiddleware
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import UIReducer from './reducers/ui';
import userReducer from './reducers/user';
import portalReducer from './reducers/portal';
import formsReducer from './reducers/forms';
import signsReducer from './reducers/signs';
import shareReducer from './reducers/share';
import appsReducer from './reducers/apps';
import undoRedo from './reducers/undoRedo';
import progress from './reducers/progress';
import widgetsReducer from './reducers/widgets';
import productsReducer from './reducers/products';
import CDNConfigReducer from './reducers/cdnconfig';
import teamReducer from './reducers/team';
import checkoutFormReducer from './reducers/checkoutForm';
import pushNotificationReducer from './reducers/pushNotification';
import livePreviewReducer from './reducers/livePreview';
import AssistantReducer from './reducers/assistant';
import dataSourceReducer from './reducers/dataSource';
import UserManagementReducer from './reducers/userManagement';

import { rootSagaFlow } from './saga';

const rootReducer = combineReducers({
  ui: UIReducer,
  user: userReducer,
  forms: formsReducer,
  signs: signsReducer,
  portal: portalReducer,
  share: shareReducer,
  apps: appsReducer,
  widgets: widgetsReducer,
  cdnConfig: CDNConfigReducer,
  undoRedo,
  progress,
  products: productsReducer,
  team: teamReducer,
  checkoutForm: checkoutFormReducer,
  pushNotification: pushNotificationReducer,
  livePreview: livePreviewReducer,
  assistant: AssistantReducer,
  dataSource: dataSourceReducer,
  userManagement: UserManagementReducer
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = applyMiddleware(
  sagaMiddleware
);

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(rootReducer, composeEnhancers(middlewares));

sagaMiddleware.run(rootSagaFlow);
export default store;
