import {
  func, number, object, string
} from 'prop-types';

export const stepPropTypes = {
  prevStep: func,
  nextStep: func,
  stepsLength: number,
  sharedState: object,
  setSharedState: func,
  setCurrentStep: func,
  currentStep: number,
  itemID: string
};

export const stepPropTypeDefaults = {
  prevStep: f => f,
  nextStep: f => f,
  stepsLength: 0,
  sharedState: {},
  setSharedState: f => f,
  setCurrentStep: f => f,
  currentStep: 0,
  itemID: ''
};
