import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';
import Toggle from '@jotforminc/toggle';
import { TextInput, TextArea } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import { closest } from '@jotforminc/utils';
import { useDebounce } from '@jotforminc/hooks';

import { hasClassName } from '../../utils/domUtils';
import { ISellProductsItem, InterfaceToKV } from '../../types/common';
import { changeActiveEditor, setActiveProduct } from '../../store/actionCreators';
import { fixProductData } from '../../utils';
import { DEFAULT_PRODUCT_DATA } from '../../constants/variables';
import PriceInput from './PriceInput';
import ImagesInput from './ImagesInput';
import Selectors from '../../store/selectors';

const ProductBasicTabEditor = ({
  name,
  description,
  autoScaleImages,
  category,
  // required,
  // selectedByDefault,
  activeProduct,
  onProductCreate,
  onProductUpdate
} : IProductEditorBasic) : JSX.Element => {
  const dispatch = useDispatch();
  const { currency } = useSelector(Selectors.getProductListSettings);

  const saveChanges = (id: string, value: string) => {
    const onProductAction = activeProduct && activeProduct.pid ? onProductUpdate : onProductCreate;
    onProductAction({ [id]: value });
  };

  const handleChange = useCallback(useDebounce(saveChanges, 250), [activeProduct, onProductCreate, onProductUpdate]);

  const onValueChangeDynamically = ({ id, value }: InterfaceToKV<Pick<ISellProductsItem, 'fitImageToCanvas' | 'required' | 'selected' | 'showSubTotal'>>) => {
    handleChange(id, value);
  };

  const onValueChangeNative = (event: React.ChangeEvent<HTMLInputElement>) => {
    const el = hasClassName(event.target, 'productEditorItem') ? event.target : closest(event.target, '.productEditorItem', '.productEditorBasic');
    if (!el) { return; }

    const { id, value } = el as HTMLInputElement;
    if (!id) { return; }

    handleChange(id, value);
  };

  const goToSellProductsPanel = useCallback(() => {
    dispatch(setActiveProduct(''));
    dispatch(changeActiveEditor(''));
  }, [dispatch]);

  useEffect(() => { // Create product with default values on first render
    if (!activeProduct.pid) {
      onProductCreate({ ...DEFAULT_PRODUCT_DATA, name: t(DEFAULT_PRODUCT_DATA.name), description: t(DEFAULT_PRODUCT_DATA.description) });
    }
  }, [activeProduct.pid, onProductCreate]);

  return (
    <div className="productEditorBasic">
      {name?.visibility === true && (
        <div className="productEditorInputGroup">
          <label htmlFor="#">{t(name.title)}</label>
          <TextInput
            id="name"
            className="productEditorItem"
            onChange={onValueChangeNative}
            defaultValue={activeProduct.name}
          />
        </div>
      )}
      <PriceInput
        currency={currency}
        price={activeProduct.price}
        onValueChangeNative={onValueChangeNative}
      />

      {description?.visibility === true && (
        <div className="productEditorInputGroup">
          <label htmlFor="#">{t(description.title)}</label>
          <TextArea
            id="description"
            className="descriptionTextArea productEditorItem"
            onChange={(val: string) => handleChange('description', val)}
            value={activeProduct.description}
          />
        </div>
      )}

      <ImagesInput
        handleChange={handleChange}
        images={fixProductData(activeProduct).images as string[]}
      />

      {autoScaleImages?.visibility === true && (
        <div className="productEditorInputGroup autoScaleImage formWizard-wrapper">
          <div className='formWizard-label'>
            <label htmlFor="#">{t(autoScaleImages.title)}</label>
            <div className='row-description optionsProductEditor-option-description'>
              {t(autoScaleImages.description)}
            </div>
          </div>
          <Toggle
            id="fitImageToCanvas"
            className="productEditorItem"
            onClick={() => onValueChangeDynamically({ id: 'fitImageToCanvas', value: activeProduct.fitImageToCanvas === 'Yes' ? 'No' : 'Yes' })}
            isSelected={!activeProduct.fitImageToCanvas || activeProduct.fitImageToCanvas === 'Yes'}
            squareMode
          />
        </div>
      )}

      {category?.visibility === true && (
        <div className="productEditorInputGroup">
          <span>category</span>
        </div>
      )}

      {/* {required?.visibility === true && (
        <div className="productEditorInputGroup">
          <label htmlFor="#">{required.title}</label>
          <Toggle
            id="required"
            className="productEditorItem"
            onClick={() => onValueChangeDynamically({ id: 'required', value: activeProduct.required !== true ? true : false })}
            isSelected={activeProduct.required === true}
            squareMode
          />
        </div>
      )} */}

      {/* {selectedByDefault?.visibility === true && (
        <div className="productEditorInputGroup">
          <label htmlFor="#">{selectedByDefault.title}</label>
          <Toggle
            id="selected"
            className="productEditorItem"
            onClick={() => onValueChangeDynamically({ id: 'selected', value: activeProduct.selected !== true ? true : false })}
            isSelected={activeProduct.selected === true}
            squareMode
          />
        </div>
      )} */}

      { /* for development */ }
      <div className="py-5 px-4">
        <Button
          onClick={goToSellProductsPanel}
          className='large'
        >
          {t('Go Back')}
        </Button>
      </div>
      { /* for development */ }
    </div>
  );
};

interface IField {
  title: string,
  description: string,
  placeholder?: string,
  visibility?: boolean
}

interface IProductEditorBasic {
  readonly name?: IField,
  readonly description?: IField,
  readonly autoScaleImages?: IField,
  readonly category?: IField,
  // readonly required?: IField,
  // readonly selectedByDefault?: IField,
  readonly activeProduct: ISellProductsItem,
  readonly onProductCreate: (data: Partial<ISellProductsItem>) => Promise<any>,
  readonly onProductUpdate: (data: Partial<ISellProductsItem>) => Promise<any>
}

ProductBasicTabEditor.defaultProps = {
  name: { title: 'Name', visibility: true },
  description: { title: 'Description', visibility: true },
  autoScaleImages: { title: 'Auto Scale Images', description: 'Scale image to fill available canvas.', visibility: true },
  category: { title: 'Category', visibility: false }
  // required: { title: t('Required'), visibility: true },
  // selectedByDefault: { title: t('Selected By Default'), visibility: true }
};

export default ProductBasicTabEditor;
