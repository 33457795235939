import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/uikit';
import { GATEWAYS_WITH_APM } from '../constants/variables';
import { changeSelectedGatewayWithAPM } from '../store/actionCreators';
import { TApm } from '../types/common';
import { getGatewayProperties } from '../utils/paymentProperties';
import Selectors from '../store/selectors';

const APMPicker = ({
  activeAPM
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const selectedGateway = useSelector(Selectors.getSelectedGatewayWithAPM);
  const apmMethods = GATEWAYS_WITH_APM[activeAPM];

  useEffect(() => () => {
    dispatch(changeSelectedGatewayWithAPM(null));
  }, []);

  const getProperty = type => getGatewayProperties(activeAPM)[type];

  return (
    <div className='apmPickerView'>
      <div className='apmDescription'>
        {t(getProperty('description'))}
      </div>
      <p className='title-choose-gateway'>{t('CHOOSE A GATEWAY')}</p>
      <div className='apmList'>
        {apmMethods.map(({ type, icon }) => (
          <Button
            onClick={() => dispatch(changeSelectedGatewayWithAPM(type))}
            className={`gatewayListItem${type === selectedGateway ? ' selected' : ''}`}
          >
            <div className="details">
              <img src={icon} alt="text" />
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

interface IProps {
  activeAPM: TApm
}

export default APMPicker;
