import React from 'react';
import {
  arrayOf, bool, func, oneOfType, string, shape
} from 'prop-types';
import { v4 as uuid } from 'uuid';
import isObject from 'lodash/isObject';
import { t } from '@jotforminc/translation';

const UploadedImagesPanel = ({
  onImageSelect, selectedImage, allowMultipleSelection, onImageDeselect, uploadedImageList, noUploadedImageText
}) => {
  const hasQueryParams = (url = '') => url.indexOf('?') !== -1;

  const renderUploadedImages = () => {
    return uploadedImageList.map(image => {
      const isImgObject = isObject(image);
      const imgThumb = isImgObject ? image.urlThumb : image;
      const urlThumb = hasQueryParams(imgThumb) ? imgThumb : `${imgThumb}?height=80`;
      const urlType = image.urlFull !== undefined ? image.urlFull : image.urlThumb;
      const urlFull = isImgObject ? urlType : image;
      const imgUserInfo = isImgObject ? image.userInfo : '';
      const key = isImgObject ? `${image.id}_${uuid()}` : urlFull;
      const isImageSelected = allowMultipleSelection ? (selectedImage.indexOf(urlFull) > -1) : (urlFull === selectedImage);
      const imageAltLabel = image.alt !== undefined ? image.alt : '';
      return (
        <div
          key={key}
          className="prevUploads-listItem"
          onKeyDown={null}
          onClick={!isImageSelected ? onImageSelect(urlFull) : onImageDeselect(urlFull)}
          data-selected={isImageSelected}
        >
          <img alt={imageAltLabel} src={urlThumb} style={{ height: '80px' }} />
          {imgUserInfo && (
            <p>
              by&nbsp;
              <a href={urlFull}>{imgUserInfo}</a>
            </p>
          )}
        </div>
      );
    });
  };

  const renderNoImageScreen = () => {
    return (
      <div className="jfImageUpload-noImage">
        {noUploadedImageText}
      </div>
    );
  };

  return (
    <>
      {uploadedImageList.length ? renderUploadedImages() : renderNoImageScreen()}
    </>
  );
};

UploadedImagesPanel.propTypes = {
  onImageSelect: func,
  selectedImage: oneOfType([string, arrayOf(string)]),
  allowMultipleSelection: bool,
  onImageDeselect: func,
  uploadedImageList: oneOfType([arrayOf(string), arrayOf(shape({}))]),
  noUploadedImageText: string
};

UploadedImagesPanel.defaultProps = {
  onImageSelect: f => f,
  selectedImage: '',
  allowMultipleSelection: false,
  onImageDeselect: f => f,
  uploadedImageList: [],
  noUploadedImageText: t('There is no image to show!')
};

export default UploadedImagesPanel;
