/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
/* eslint-disable @jotforminc/no-native-button */
import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { t } from '@jotforminc/translation';
import { classNames, capitalizeFirstLetter } from '@jotforminc/utils';

import { Button, InputText } from '@jotforminc/magnet';
import {
  IconArrowDown, IconXmark, IconChevronLeft,
  IconChevronRight, IconMagnifyingGlass
} from '@jotforminc/svg-icons';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import texts from '../../core/texts';
import ModalControls from '../StandaloneTemplates/components/PreviewModalContents/ModalControls';
import SimilarTemplatesItem from '../StandaloneTemplates/components/PreviewModalContents/SimilarTemplatesItem';
import useIsMobile from '../../hooks/useIsMobile';
import { useOutsideClick } from '../StandaloneTemplates/hooks';
import { getTemplatePreviewURL } from '../StandaloneTemplates/utils';
import Loader from '../Loader';
import { customModalConstants, DefaultOverviewTabRenderer } from '../StandaloneTemplates/customModalConstants';

const NewWizardTemplateModal = ({
  similarTemplates, handleClose, previewURL, modalProps, username, formTemplate, forms,
  template, title, frameSrc, categoryData, setModalContentLoading, theme, displayMyformsModal,
  isCloningTemplate = false, useTemplate, categories, setSelectedCategory, legacyStandaloneFrameRef,
  templateTabs = [], searchKeyword, setSearchKeyword, standaloneTemplates, newStandaloneActive, DOM, openTemplateModalInPortal
}) => {
  const [activeTabID, setActiveTabID] = useState('overview');

  const [modelContentLoading, setModelContentLoading] = useState(true);
  const wizardModalBodyRef = useOutsideClick(
    handleClose,
    ['.jfWizard-modal', '#CookieBanner', '.templateSuggestionModal-neverShowGroup']
  );
  const frameRef = useRef();
  const closeBtnRef = useRef();
  const similarTemplatefRef = useRef();

  const leftArrowRef = useRef();
  const rightArrowRef = useRef();

  const templateType = frameSrc.split('/')[1];
  const [resourceName] = templateType.split(' ');

  const isMobile = useIsMobile();
  const modalVisibleClassName = 'jfWizard--isModalVisible';

  const {
    _id, id,
    _metaDescription, metaDescription, _slug, thumbnailBackgroundColor: backgroundColor,
    _categories, categories: templateCategories, _previewImageUrl
  } = template;

  useEffect(() => {
    if (closeBtnRef.current) {
      closeBtnRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setModelContentLoading(true);
  }, [(_id || id)], _previewImageUrl);

  const handleArrowClick = ({ key }) => {
    if (!['ArrowLeft', 'ArrowRight'].includes(key)) return;
    const isArrowLeft = key === 'ArrowLeft';
    const actionTarget = isArrowLeft ? leftArrowRef.current : rightArrowRef.current;

    if (actionTarget) actionTarget.classList.add('load-active');

    let currentTemplateOrder = standaloneTemplates.findIndex(templateIterator => (_id || id) === (templateIterator._id || templateIterator.id));

    if ((currentTemplateOrder === 0 && isArrowLeft) || (currentTemplateOrder === standaloneTemplates.length - 1 && !isArrowLeft)) {
      currentTemplateOrder = isArrowLeft ? standaloneTemplates.length : -1;
    }

    const navigatedTemplate = standaloneTemplates[isArrowLeft ? currentTemplateOrder - 1 : currentTemplateOrder + 1];
    if (navigatedTemplate) {
      global.setSelectedTemplate(navigatedTemplate);
    }
  };

  useEffect(() => {
    document.body.classList.add(modalVisibleClassName);
    return () => {
      document.body.classList.remove(modalVisibleClassName);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleArrowClick);
    return () => {
      window.removeEventListener('keydown', handleArrowClick);
    };
  }, [standaloneTemplates, template]);

  const {
    tabs = [],
    ctaClasses = [],
    OverviewTabRenderer,
    FormTemplatesTabRenderer = () => null,
    SecondaryCTA = () => null,
    customCTAText = texts.USE_TEMPLATE,
    templateAreaRendererClass = 'w-full h-full lg:min-h-md lg:radius-md lg:absolute'
  } = customModalConstants[templateType];

  const formatedTabs = [
    { id: 'overview', name: 'Overview', data: '' },
    ...tabs,
    ...(templateTabs.filter(tab => tab.id !== 'more')) // more: unknown app template tab?
  ];
  const splitedTemplateCategories = (_categories || templateCategories || '').trim().split(',').filter(category => category);
  const templateCategoriesData = categories.filter(({ id: categoryID, _id: _categoryID }) => splitedTemplateCategories.find(cat => cat == (_categoryID || categoryID)));
  const isEnterpriseEnv = isEnterprise();

  const tabsRenderer = () => {
    switch (activeTabID) {
      case 'overview':
        if (OverviewTabRenderer) return <OverviewTabRenderer {...template} />;
        return (
          <DefaultOverviewTabRenderer
            {...template}
            templateType={templateType}
            isEnterpriseEnv={isEnterpriseEnv}
            templateCategoriesData={templateCategoriesData}
          />
        );
      case 'form-templates':
        return (
          <FormTemplatesTabRenderer
            forms={forms}
            template={template}
            username={username}
            formTemplate={formTemplate}
          />
        );
      default:
        break;
    }
    const { data: activeTabContent } = formatedTabs.find(tab => tab.id === activeTabID);
    return (
      <div className="jfR-description-area mt-10 gap-10 lg:gap-16 pb-4 lg:pb-16">
        <div dangerouslySetInnerHTML={{ __html: activeTabContent }} />
      </div>
    );
  };

  const formatLegacyStandaloneCategoryData = () => {
    if (!categoryData) return [];
    const result = [];
    const { parent } = categoryData;
    if (parent) result.push({ ...parent, title: parent.name });
    result.push({ ...categoryData, title: categoryData.name });
    return result;
  };

  const templateTypePretty = templateType.split('-')[0] === 'pdf' ? 'PDF' : capitalizeFirstLetter(templateType.split('-')[0]);
  const templatePreviewURL = getTemplatePreviewURL({ template, templateType, previewURL });

  const CategoriesRenderer = () => (
    <div className="page-detail-categories mt-10 lg:mt-16 lg:block">
      <strong className="font-bold">{t('Categories')}</strong>
      <ul className="flex flex-wrap gap-3 -mb-1 mt-4">
        {
          (newStandaloneActive ? templateCategoriesData : formatLegacyStandaloneCategoryData(categoryData)).map(category => (
            <li>
              <button
                title={category.title}
                onClick={event => {
                  event.stopPropagation();
                  if (newStandaloneActive) {
                    setSelectedCategory(category);
                  } else if (legacyStandaloneFrameRef?.current) {
                    const frameWindow = legacyStandaloneFrameRef?.current.contentWindow;
                    const currentPath = frameWindow.location.pathname;
                    frameWindow.open(`${currentPath}/category/${category.slug}`, '_self');
                  }
                  handleClose();
                }}
                className="no-underline color-navy-300 py-0.5 px-2 text-sm line-height-md radius mb-1 flex border-0 bg-navy-25 hover:bg-navy-75"
              >
                {category.title}
              </button>
            </li>
          ))
        }
      </ul>
    </div>
  );
  const QRImageRenderer = () => (
    <div className="flex items-center w-full gap-4 mt-4 pt-8 color-navy-900">
      <div
        className="qr-wrapper p-2 radius bg-white radius-lg"
        style={{ boxShadow: '0px 8px 16px 0px rgba(84, 95, 111, 0.16), 0px 2px 4px 0px rgba(37, 45, 91, 0.04)' }}
      >
        <img
          width={210}
          height={210}
          loading="lazy"
          className="block w-20 h-auto max-w-full lazyload"
          src={JSON.parse(template.JSON || '{}')?.templateQRCode}
          alt={title}
        />
      </div>

      <div className="inline-flex flex-col gap-2">
        <h3 className="title locale text-md font-bold">{t('See app in action')}</h3>
        <p className="subtitle text-xs color-navy-300 max-w-48">
          {t('Use your camera to scan the QR code and preview the app on your device.')}
        </p>
      </div>
    </div>
  );

  const {
    customLoadMoreText = 'Show more templates',
    customSearchPlaceholder = `Search in ${templateTypePretty} Templates`,
    TemplateAreaRenderer, cloneCTAColor = 'primary',
    CategoriesSectionRenderer = CategoriesRenderer,
    QRSectionRenderer = QRImageRenderer
  } = customModalConstants[templateType];

  const CloneTemplateCTA = ({ mainClass }) => (
    <Button
      fullWidth
      colorStyle={cloneCTAColor}
      disabled={isCloningTemplate && 'disabled'}
      className={
        classNames(
          mainClass,
          isCloningTemplate && 'isCloningTemplate',
          ctaClasses.join(' ')
        )
      }
      onClick={({ target }) => useTemplate(template, target)}
      size="large"
    >
      {t(isCloningTemplate ? texts.PLEASE_WAIT : customCTAText)}
    </Button>
  );

  const ModalRenderer = (
    <div
      ref={wizardModalBodyRef}
      style={{ zIndex: 10000002 }}
      className="modal template-detail fixed top-0 left-0 w-full h-full overflow-auto bg-navy-700 bg-opacity-80"
    >
      <button
        onClick={handleClose}
        className="close-wp absolute lg:fixed bg-transparent border-0 color-gray-100 text-xs opacity-70 hover:opacity-100 sm:duration-200 right-4 rtl:right-auto rtl:left-4 top-3 sm:top-2 z-8"
        aria-label="ESC"
        ref={closeBtnRef}
        tabindex="0"
        type="button"
      >
        <div className="close-icon w-7 sm:w-11 h-7 sm:h-11 pointer-events-none flex items-center justify-center">
          <IconXmark width={36} height={36} />
        </div>
      </button>
      <div className="relative max-w-xxl mx-auto md:mb-12 mt-12 lg:mt-16 lg:px-16">
        <div className="template-detail-wrapper relative w-full">
          <div className="bg-white radius-2xl lg:px-8 flex">
            <div className="page-detail-wrapper page-detail-new w-full mx-auto relative mt-2 mb-4 z-2">
              <div className="w-full px-6 md:px-3 mt-3">
                <p
                  style={{ overflowWrap: 'anywhere' }}
                  className="flex lg:hidden title lg:px-4 mb-4 max-w-full overflow-hidden m-0 grow-1 font-bold text-xl lg:text-4xl color-navy-700"
                >
                  {title}
                </p>
              </div>
              <div className="flex flex-col lg:flex-row gap-8">
                <div
                  className="outline-area w-full max-w-full lg:max-w-1/2 xl:max-w-md h-auto radius-2xl lg:p-2 shrink-0"
                  style={{ background: 'linear-gradient(180deg,rgba(31, 34, 37, 0.03) 0%,rgba(31, 34, 37, 0) 100%)' }}
                >
                  <div className="w-full h-full">
                    {
                      TemplateAreaRenderer ? (
                        <TemplateAreaRenderer
                          DOM={DOM}
                          title={title}
                          _slug={_slug}
                          template={template}
                          templateType={templateType}
                          leftArrowRef={leftArrowRef}
                          rightArrowRef={rightArrowRef}
                          backgroundColor={backgroundColor}
                          _previewImageUrl={_previewImageUrl}
                          modelContentLoading={modelContentLoading}
                          templatePreviewURL={templatePreviewURL}
                          setModelContentLoading={setModelContentLoading}
                          templateAreaRendererClass={templateAreaRendererClass}
                        />
                      ) : (
                        <div className="modal-template-area relative z-1 md:min-h-sm md:max-h-sm duration-200 w-full h-120 lg:border border-navy-75 radius-none lg:radius-lg overflow-hidden">
                          {modelContentLoading && <Loader />}
                          <object
                            ref={frameRef}
                            aria-label="Preview"
                            type="text/html"
                            id={`${resourceName}PreviewArea`}
                            data={templatePreviewURL}
                            onLoad={() => {
                              setModelContentLoading(false);
                              rightArrowRef.current.classList.remove('load-active');
                              leftArrowRef.current.classList.remove('load-active');
                            }}
                            className={templateAreaRendererClass}
                          />
                        </div>
                      )
                    }
                    <div
                      style={{
                        boxShadow: '0px -2px 4px 0px rgba(84, 95, 111, 0.16),0px 0px 1px 0px rgba(37, 45, 91, 0.04)'
                      }}
                      className="cta-controls sticky flex lg:hidden left-0 right-0 bottom-0 bg-white border-t border-t-navy-50 py-4 flex justify-between gap-2 mx-1 z-1 lg:mt-2 px-1 overflow-hidden"
                    >
                      <button
                        type="button"
                        ref={isMobile ? leftArrowRef : f => f}
                        // eslint-disable-next-line max-len
                        className="modal-arrow bg-transparent border-0 outline-0 left relative w-12 h-12 color-navy-500 inline-flex items-center justify-center radius hover:bg-navy-25 duration-200 transform rtl:rotate-180"
                        aria-label={t('Previous Template')}
                        onClick={() => handleArrowClick({ key: 'ArrowLeft' })}
                      >
                        <IconChevronLeft width={24} height={24} className="transform rotate-0" />
                      </button>
                      <SecondaryCTA slug={_slug} />
                      <CloneTemplateCTA mainClass='cta-usetemplate w-full min-w-44 max-w-68' />
                      <button
                        type="button"
                        ref={isMobile ? rightArrowRef : f => f}
                        className="modal-arrow bg-transparent border-0 outline-0 right relative w-12 h-12 color-navy-500 inline-flex items-center justify-center radius hover:bg-navy-25 duration-200 transform rtl:rotate-180"
                        aria-label={t('Next Template')}
                        onClick={() => handleArrowClick({ key: 'ArrowRight' })}
                      >
                        <IconChevronRight width={24} height={24} className="transform rotate-0" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1 hidden lg:block">
                  <div className="w-full mt-2">
                    <div className="w-full flex flex-col gap-8 mt-4 lg:mt-0">
                      <div className={`hero-search-wrapper ${templateType} aside-search-hero w-full flex gap-2`}>
                        <div className="hero-search grow-1 relative">
                          <InputText
                            placeholder={t(customSearchPlaceholder)}
                            className="w-full"
                            value={searchKeyword}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                if (!newStandaloneActive) {
                                  const frameWindow = legacyStandaloneFrameRef?.current.contentWindow;
                                  const currentPath = frameWindow.location.pathname;
                                  frameWindow.open(`${currentPath}/search?q=${searchKeyword}`, '_self');
                                } else {
                                  window.handleSearch();
                                }
                                handleClose();
                              }
                            }}
                            onChange={event => {
                              setSearchKeyword(event.target.value);
                            }}
                            prefix={{ icon: IconMagnifyingGlass }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="modalHeader" className="modal-header flex items-center z-6 mt-10 lg:mt-6">
                    <div className="flex flex-col flex-1">
                      <h1 className="title max-w-full overflow-hidden m-0 grow-1 font-bold text-lg lg:text-4xl color-navy-700">
                        {title}
                      </h1>
                    </div>
                  </div>
                  <div className="jfR-description-area modal-content-desc mt-6 color-navy-300 text-md line-height-xl">
                    {_metaDescription || metaDescription}
                  </div>
                  {
                    !isMobile && (
                      <div className="button-wrapper lg:flex gap-2 mt-8">
                        <SecondaryCTA slug={_slug} />
                        <CloneTemplateCTA mainClass='forUseTemplate' />
                      </div>
                    )
                  }
                  <CategoriesSectionRenderer template={template} frameRef={frameRef} />
                  {
                    JSON.parse(template.JSON || '{}')?.templateQRCode && (
                      <QRSectionRenderer />
                    )
                  }
                </div>
              </div>
              <div className="lg:mt-10 px-6 flex flex-col gap-8">
                <div id="overview" className="w-full">
                  <div className="py-4 flex flex-wrap gap-4 items-center border-b border-b-navy-50 justify-between">
                    {
                      !isEnterpriseEnv && (
                        <div className="details-tab-buttons flex gap-1 md:gap-2">
                          {
                            formatedTabs.map(({ id: tabID, name: tabName }) => (
                              <button
                                type="button"
                                key={tabID}
                                className={
                                  // eslint-disable-next-line max-len
                                  `bg-transparent border-0 duration-200 font-medium px-2 md:px-4 py-1.5 radius-full text-xs md:text-sm line-height-md hover:bg-navy-50 color-navy-300${activeTabID === tabID ? ' bg-navy-25' : ''}`
                                }
                                onClick={() => {
                                  setActiveTabID(tabID);
                                  if (tabID === 'form-templates') similarTemplatefRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                                data-tab={tabID}
                              >
                                {tabName}
                              </button>
                            ))
                          }
                        </div>
                      )
                    }
                    <button
                      type="button"
                      onClick={() => {
                        similarTemplatefRef.current.scrollIntoView({ behavior: 'smooth' });
                      }}
                      className="showMoreTemplatesBtn flex items-center color-navy-500 outline-0 border-0 radius-full hover:bg-navy-25 pr-3 rtl:pl-3 rtl:pr-0"
                    >
                      <span className="p-2 flex">
                        <IconArrowDown width={16} height={16} />
                      </span>
                      <span className="text-xs font-medium">{customLoadMoreText}</span>
                    </button>
                  </div>
                  {tabsRenderer()}
                </div>
                {
                  similarTemplates && (
                    <div ref={similarTemplatefRef}>
                      <SimilarTemplatesItem
                        {...modalProps}
                        theme={theme}
                        useSlider={true}
                        parentTemplateID={_id || id}
                        templateType={templateType}
                        modalBodyRef={wizardModalBodyRef}
                        newStandaloneActive={newStandaloneActive}
                        onTemplateClone={(templateParam, target) => useTemplate(templateParam, target)}
                        categoryData={categoryData}
                        displayMyformsModal={displayMyformsModal}
                        modelContentLoading={modelContentLoading}
                        similarTemplates={similarTemplates}
                        setModalContentLoading={stillLoading => setModalContentLoading(stillLoading)}
                      />
                    </div>
                  )
                }
              </div>
            </div>
            {
              !isMobile && (
                <ModalControls
                  useTemplate={useTemplate}
                  isCloningTemplate={isCloningTemplate}
                  handlePrevNextTemplateHandler={handleArrowClick}
                  newStyle={true}
                  leftRef={!isMobile ? leftArrowRef : f => f}
                  rightRef={!isMobile ? rightArrowRef : f => f}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );

  const usePortal = openTemplateModalInPortal;

  if (usePortal) {
    return ReactDOM.createPortal(ModalRenderer, global.document.body);
  }
  return ModalRenderer;
};

export default NewWizardTemplateModal;
