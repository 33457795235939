import { t } from '@jotforminc/translation';

export const getShortMonthName = month => {
  const monthNames = [
    t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')
  ];
  return monthNames[month - 1].substring(0, 3);
};

export const getIconType = (productType, type, isNewBranding, paymentProps) => {
  let iconType = '';

  if (productType === 'Table') {
    return 'isSheet';
  }

  if (type === 'CARD') {
    iconType += 'isCard ';
  }

  if (isNewBranding) {
    iconType += 'isNewBranding ';
  }

  if (paymentProps) {
    iconType += 'isPaymentForm ';
  }

  return iconType;
};
