import Styled from 'styled-components';

export const ScSuggestions = Styled.div`
  font-size: 16px;
  line-height: 1em;
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: clamp(320px, 100%, 640px);
  padding: .375em 0;
  height: 260px;
  overflow-x: hidden;

  .suggestion {
    display: flex;
    align-items: center;
    padding: .75em 1.25em;
    position: relative;
    cursor: pointer;
    transition: background-color .15s;

    &-email-container {
      display: flex;
      justify-content: flex-start;
      margin-right: 10px;
    }

    &-email {
      text-overflow: ellipsis;
      width: 16em;
      overflow: hidden;
      white-space: nowrap;
    }

    &-info {
      font-size: .875em;
      color: #2c3345;
      color: #6a6e8f;
      flex: 1 1 auto;
      position: relative;
    }

    &-teamlist {
      background-color: #2b3245;
      border-radius: 0.25em;
      z-index: 1;
      width: auto;
      position: absolute;
      right: -10em;
    }

    &-teamitem {
      background-color: #2b3245;
      color: #abadb4;
      padding: 0.5em 0.6875em;
      text-align: left;
      display: flex;
      right: 0em;
      width: 14.0625em;
      z-index: 1;
    }

    &-avatar {
      flex: 0 0 32px;
      height: 32px;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      font-size: .75em;
      margin-right: 1em;

      div {
        height: 100%;
        line-height: 32px;
        color: #fff;
        text-align: center;
        font-weight: 500;
        background-color: #b9bbd2;
        background-repeat: no-repeat;
        background-size: cover;
        text-transform: uppercase;
      }
    }
    .expand-list {
      background-color: #EBEDF3;
      color: #2C3345;
      border-radius: 1em;
      text-align: center;
      width: 5em;
      height: 1.5em;
      white-space: nowrap;
      margin-left: 2em;

      &:hover {
        background-color: rgba(84, 180, 92, 0.1);
      }
    }

    & + .suggestion:before {
      content: "";
      display: block;
      height: 1px;
      right: 1em;
      left: 1em;
      position: absolute;
      top: 0;
      background-color: #eaebf2;
    }

    &:hover {
      background-color: rgba(84, 180, 92, 0.1);
    }
  }  
`;

export const ScList = Styled.div`
  .suggestion {
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;

    &-teamlist {
      background-color: #2b3245;
      border-radius: 0.25em;
      z-index: 1;
      width: auto;
      position: absolute;
      top: -1em;
      right: -10em ;
      max-height: 40em;
      overflow-y: scroll;
    }

    &-info {
      font-size: .875em;
      color: white;
      flex: 1 1 auto;
      position: relative;
      max-width: 13em;
    }


    &-email {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #A9AACB;
    }

    &-teamitem {
      background-color: #2b3245;
      color: #abadb4;
      padding: 0.5em 0.6875em;
      text-align: left;
      display: flex;
      right: 0em;
      width: 16em;
      z-index: 1;
      border-radius: 1em;
    }

    &-avatar {
      flex: 0 0 32px;
      height: 32px;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      font-size: .75em;
      margin-right: 1em;

      div {
        height: 100%;
        line-height: 32px;
        color: #fff;
        text-align: center;
        font-weight: 500;
        background-color: #b9bbd2;
        background-repeat: no-repeat;
        background-size: cover;
        text-transform: uppercase;
      }
    }

    &:after {
    border: 0.3125em solid transparent;
    border-bottom-color: #000;
    bottom: 1em;
    content: " ";
    left: -3em;
    margin-left: -0.3125em;
    position: absolute;
  }
`;
