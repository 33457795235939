import React, { memo } from 'react';
import {
  bool, elementType, oneOf, string
} from 'prop-types';
import { IMAGE_TYPE } from '../../constants';
import SvgRenderer from '../common/SvgRenderer';

const getImageComponent = (url, alt) => {
  if (!url) return null;
  return (
    <img
      loading="lazy"
      width="100"
      height="100"
      src={url}
      alt=""
      data-testid="logo-img"
      aria-label={alt}
    />
  );
};

const AppImage = ({
  type,
  bgColor,
  bgURL,
  iconColor,
  svgRef,
  alt,
  Wrapper,
  withBorder
}) => {
  const isSVG = type === IMAGE_TYPE.icon;

  const url = isSVG ? bgURL.replace('http://', 'https://') : bgURL;

  return (
    <Wrapper isSVG={isSVG} bgColor={bgColor} withBorder={withBorder}>
      {isSVG

        ? (
          <div className="jfIconSVG-wrapper" data-testid="logo-svg">
            <SvgRenderer svgRef={svgRef} url={url} iconColor={iconColor} />
          </div>
        )
        : getImageComponent(url, alt)}
    </Wrapper>
  );
};

AppImage.propTypes = {
  type: oneOf([IMAGE_TYPE.icon, IMAGE_TYPE.image]),
  bgColor: string,
  bgURL: string,
  iconColor: string,
  svgRef: string,
  alt: string,
  Wrapper: elementType,
  withBorder: bool
};

AppImage.defaultProps = {
  type: IMAGE_TYPE.image,
  bgColor: '',
  bgURL: '',
  iconColor: '',
  svgRef: '',
  alt: '',
  Wrapper: ({ children, ...props }) => (<div {...props}>{children}</div>), // eslint-disable-line react/prop-types
  withBorder: false
};

export default memo(AppImage);
