import React from 'react';
import { useDebounce } from '@jotforminc/hooks';
import { DimensionPicker as JFDimensionPicker } from '@jotforminc/jotform-common';
import { IconLockFilled, IconUnlockFilled } from '@jotforminc/svg-icons';
import {
  func, number, oneOfType, string
} from 'prop-types';
import { isYes } from '../../../utils';

const DimensionPicker = ({
  keepProportion, width, height, onChange, proportion
}) => {
  const debouncedOnChange = useDebounce(onChange, 400);
  return (
    <JFDimensionPicker
      keepProportion={isYes(keepProportion)}
      width={width}
      height={height}
      proportion={proportion}
      keepRatioButton
      onChange={debouncedOnChange}
      LockIcon={() => <IconLockFilled width={24} height={24} />}
      UnlockIcon={() => <IconUnlockFilled width={24} height={24} />}
    />
  );
};

DimensionPicker.propTypes = {
  keepProportion: string,
  onChange: func,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  proportion: oneOfType([number, string])
};

DimensionPicker.defaultProps = {
  keepProportion: 'Yes',
  onChange: f => f,
  width: '',
  height: '',
  proportion: ''
};

export default DimensionPicker;
