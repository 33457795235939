import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';

const Thumbnail = ({ file: { type, url } }) => {
  const [imageURL, style = { height: '64px' }] = useMemo(() => {
    switch (true) {
      case type.indexOf('image') !== -1: {
        return [url, {
          width: '120px', minHeight: '64px', maxHeight: '120px', objectFit: 'contain'
        }];
      }
      case type.indexOf('application/pdf') !== -1: {
        return ['//cdn.jotfor.ms/assets/img/builder/pdf_icon.png'];
      }
      case type.indexOf('msword') !== -1 || type.indexOf('officedocument') !== -1: {
        return ['//cdn.jotfor.ms/assets/img/builder/doc_icon.png'];
      }
      default: {
        return ['//cdn.jotfor.ms/assets/img/builder/other_file_icon.png'];
      }
    }
  }, [type, url]);

  return (
    <img alt="" src={imageURL} style={style} />
  );
};

Thumbnail.propTypes = {
  file: shape({
    name: string,
    type: string
  })
};

Thumbnail.defaultProps = {
  file: {}
};

export default Thumbnail;
