import React, { FunctionComponent } from 'react';
import RadioGroupWrapper from '@jotforminc/radio-group';

type RadioGroupType = {
  onChange: (value: string) => void
}

const RadioGroup: FunctionComponent<RadioGroupType> = ({ onChange, ...props }): JSX.Element => {
  const elementLabel = {
    align: 'left',
    textAlign: 'left',
    text: 'State Options'
  };
  return (
    <RadioGroupWrapper
      {...props}
      elementLabel={elementLabel}
      onChange={(e: any) => onChange(e.target.value)}
    />
  );
};

export default RadioGroup;
