import React, { memo, useEffect, useState } from 'react';
import Tracking from '@jotforminc/tracking';
import type { GATEWAY_CONNECTION_MODEL_PROVIDER, CONNECTION_MODAL_FLOW_TYPES } from '../types';

/** context */
import { GatewayConnectionProvider } from '../context';

/** components */
import { ModalContainer } from './components/core/modalContainer';

/** Fullstory */
const fullstoryVars = { reusableConnectionModal_bool: true };

export const GatewayConnectionModal = memo((props: GATEWAY_CONNECTION_MODEL_PROVIDER): any => {
  const [parentUsesFS, setParentUsesFS] = useState(true);
  const editableFlowTypes: CONNECTION_MODAL_FLOW_TYPES[] = ['EDIT_CONNECTION', 'RE_CONNECT'];

  if (editableFlowTypes.includes(props.defaultFlowType)) {
    const requiredProps = ['editConnectionData'];
    const missingProps = requiredProps.filter(prop => !Object.keys(props).includes(prop));

    if (missingProps.length > 0) {
      throw new Error(`GatewayConnectionManagement is missing the following required props: ${missingProps.join(', ')}`);
    }
  }

  useEffect(() => {
    const { user } = props;

    if (user?.account_type.name && ['ADMIN', 'SUPPORT', 'INTERN', 'HELPDESK'].includes(user?.account_type.name)) {
      return;
    }

    if (user?.username && !Tracking.FSisInitialized()) {
      setParentUsesFS(false);
      Tracking.enableFS(fullstoryVars);
      Tracking.identify(user.username || '', {
        displayName: user.name || '',
        source: props.modalSource || '',
        accountType: user.account_type.name || '',
        paymentgateway_bool: true,
        ...fullstoryVars
      });
    } else {
      Tracking.setUserVars(fullstoryVars);
    }

    return () => {
      if (!parentUsesFS) {
        Tracking.shutdown();
      }
    };
  }, [parentUsesFS, props]);

  return (
    <GatewayConnectionProvider {...props}>
      <ModalContainer />
    </GatewayConnectionProvider>
  );
});
