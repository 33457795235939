import { t } from '@jotforminc/translation';
import { DATA_SOURCE_COLUMN_TYPES } from '../../../../../../constants/itemTypes';
import { PROPERTY_ITEM } from '../constants';

export const DS_COLUMN_REGEX = /\{(\d+_[^}]+)\}/g;

export const DEFAULT_DS_OPTIONS = {
  [DATA_SOURCE_COLUMN_TYPES.IMAGE]: {
    label: t('Choose a file'),
    value: 'image'
  }
};

export const normalizeDefaultSubfields = {
  [PROPERTY_ITEM.LIST_ITEM_START]: { mapValue: val => ({ url: val }), onChange: val => val?.url ?? val },
  [PROPERTY_ITEM.LIST_ITEM_END]: { mapValue: val => ({ url: val }), onChange: val => val?.url ?? val },
  [PROPERTY_ITEM.IMAGE_UPLOAD_WITH_LOADING]: {
    mapValue: val => ({ url: val }),
    onChange: val => {
      if (!val) {
        return ({
          imageURL: '',
          width: '',
          height: '',
          proportion: ''
        });
      }
      return ({ imageURL: val });
    }
  }
};

export const isDsFormat = str => {
  const match = str.match(DS_COLUMN_REGEX);
  return !!match;
};
export const parseDsFormat = (str, columnType, excludedDefaultDsOptions) => {
  if (typeof str !== 'string' || !str) {
    return excludedDefaultDsOptions?.includes(columnType) ? '' : DEFAULT_DS_OPTIONS[columnType]?.value;
  }

  const match = str.match(DS_COLUMN_REGEX);
  return match ? match[0] : DEFAULT_DS_OPTIONS[columnType]?.value;
};

export const decideDefaultOptions = (columnType, excludedDefaultDsOptions) => {
  const defaults = [];
  const defaultOptionByColumnType = excludedDefaultDsOptions?.some(type => type === columnType) ? null : DEFAULT_DS_OPTIONS[columnType];

  if (defaultOptionByColumnType) {
    defaults.push({ ...defaultOptionByColumnType });
  }

  return defaults;
};
