/**
 * Masks sensitive data in a credential string.
 *
 * @param credential - The credential string to be masked.
 * @returns The masked credential string.
 */
export const maskCredentialData = (credential: string): string => {
  // Check if the data is in email format
  if (/^\S+@\S+\.\S+$/.test(credential)) {
    // Split the email into username and domain, then mask appropriately
    const [username, domain] = credential.split('@');
    const domainParts = domain.split('.');
    const maskedUsername = `${username.substring(0, 1)}****`;
    const maskedDomain = `****.${domainParts.slice(1).join('.')}`;
    return `${maskedUsername}@${maskedDomain}`;
  }
  // For other data, mask approximately half of the characters
  const maskLength = Math.ceil(credential.length / 2);
  const visibleLength = credential.length - maskLength;
  const maskedPart = '*'.repeat(maskLength);
  const visiblePart = credential.substring(0, visibleLength);
  return `${visiblePart}${maskedPart}`;
};
