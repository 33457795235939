import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';

/** Data */
import { supportedGatewayConnectionPropsList, gatewayDefaultIcons } from '@jotforminc/payment-constants';

/** Context */
import { useGatewayConnection } from '../../../context';

/**
 * Renders a gateway picker for Apple Pay & Google Pay integration.
 *
 * This component displays a list of supported gateways for Apple Pay & Google Pay integration.
 * It includes an icon, name, and information about the integration. Users can select a gateway from the list.
 *
 * @returns {React.ReactElement} The rendered gateway picker component
 */
export function ApmGatewayPicker(): any {
  const {
    selectedChildGatewayType, currentlyGatewayProperties, backStep, changeChildSelectedGateway
  } = useGatewayConnection();

  if (!currentlyGatewayProperties) {
    backStep();
    return null;
  }

  const {
    isChildTypes, childTypes, name, gateway_type: GatewayType
  } = currentlyGatewayProperties;
  const Icon = gatewayDefaultIcons[GatewayType] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

  return (
    <div key="apm-gateway-picker">
      <div>
        {/* header */}

        <div className="p-3 radius mb-4 bg-navy-25">
          <div className='flex justify-between items-center'>
            {/* left */}
            <div>
              <div className="flex items-center gap-3">
                <div className='h-12 w-12 p-2 radius flex justify-center items-center border border-navy-100 bg-white'>
                  <Icon className="h-10 w-10" />
                </div>
                <div className="gap-1">
                  <div className='font-medium text-lg line-height-xl tracking-sm color-navy-700'>{name}</div>
                </div>
              </div>
            </div>
          </div>
          {/* Information */}
          <div className='border-navy-50 mt-3 pt-3 flex justify-start items-start gap-2' style={{ borderTopWidth: '1px' }}>
            <IconInfoCircleFilled className='w-5 h-5 color-blue-500 shrink-0' />
            {' '}
            <span className='color-navy-700 text-sm line-height-md tracking-lg'>
              {translationRenderer(`
                Jotform’s Apple Pay & Google Pay integration is supported by [1[{square}]] and [2[{stripe}]].
                Credit card will also be offered as a payment option in case the user is on a device or browser that doesn’t support Apple Pay & Google Pay.`)({
                renderer1: () => <span className='font-bold'>Square</span>,
                renderer2: () => <span className='font-bold'>Stripe Checkout</span>
              })}
            </span>
          </div>
        </div>

        {/* body: child gateway list */}
        <div>
          {isChildTypes && (
            <div className='mb-2 flex flex-col max-h-11'>
              <span className="font-medium text-md tracking-md color-navy-700 line-height-xl">
                {t('Select Gateway')}
                {' '}
                <span className='color-red-400'>*</span>
              </span>
            </div>
          )}
          <div className='grid cols-2 gap-3'>
            {isChildTypes === true && childTypes && childTypes.map(childType => {
              const { gateway_type: Gateway, name: ChildGatewayName } = supportedGatewayConnectionPropsList[childType];
              const ChildIcon = gatewayDefaultIcons[Gateway] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

              return (
                <div
                  key={`child_${Gateway}`}
                  className={`
                    flex justify-start items-center h-14 bg-white border px-3 py-2 gap-3 radius cursor-pointer shadow-xs hover:border-blue-500
                    ${selectedChildGatewayType === Gateway ? 'border-blue-500 bg-blue-100' : 'border-navy-100 bg-white'}
                  `}
                  role='button'
                  onClick={() => changeChildSelectedGateway(Gateway)}
                  data-test-id={`child_gateway_${Gateway}`}
                >
                  <div className='w-10 h-10'>
                    <ChildIcon />
                  </div>
                  <span className='color-navy-600 font-medium text-md line-height-xs tracking-md'>{ChildGatewayName}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
