import { createSelector } from 'reselect';
import { PaymentProperties, Variables } from '@jotforminc/payment-settings-editor';

const { CHECKOUT_FORM_QUESTIONS, CHECKOUT_FORM_HIDDEN_QUESTION_IDS, CHECKOUT_FORM_HIDDEN_QUESTION_TYPES } = Variables;

const isCheckoutFormQuestionHidden = ({ qid, type }) => CHECKOUT_FORM_HIDDEN_QUESTION_IDS.includes(qid) || CHECKOUT_FORM_HIDDEN_QUESTION_TYPES.includes(type);

export const getCheckoutForm = state => state.checkoutForm;
export const getCheckoutFormQuestions = createSelector(getCheckoutForm, ({ questions }) => questions);
export const getCheckoutFormQuestionsArray = createSelector(getCheckoutForm, ({ questions }) => Object.values(questions).sort((a, b) => a.order - b.order));
export const getCheckoutFormVisibleQuestionsArray = createSelector(getCheckoutFormQuestionsArray, questions => questions.filter(q => !isCheckoutFormQuestionHidden(q)));
export const getCheckoutFormHiddenQuestionsArray = createSelector(getCheckoutFormQuestionsArray, questions => questions.filter(isCheckoutFormQuestionHidden));
export const getLastVisibleQuestionOrder = createSelector(getCheckoutFormVisibleQuestionsArray, questions => parseInt(questions[questions.length - 1].order, 10));
export const getLastQuestionID = createSelector(getCheckoutFormQuestionsArray, questions => Math.max(...questions.map(({ qid }) => parseInt(qid, 10))));
export const getCheckoutFormPaymentQuestion = createSelector(getCheckoutFormQuestions, questions => questions[CHECKOUT_FORM_QUESTIONS.GATEWAY]);
export const getCheckoutFormActiveGatewaySettings = createSelector(getCheckoutFormPaymentQuestion, paymentQuestion => (paymentQuestion?.type in PaymentProperties ? paymentQuestion : {}));
export const getPaymentType = createSelector(getCheckoutFormPaymentQuestion, ({ paymentType }) => paymentType);
export const getPaymentQuestionMappedAddressFieldId = createSelector(getCheckoutFormPaymentQuestion, paymentQuestion => {
  if (paymentQuestion?.type === 'control_iyzico') {
    return paymentQuestion.billingAdd;
  }
  return null;
});

// gateway selectors
export const getPaymentModalFlowType = state => {
  return state.checkoutForm.paymentModalFlowType;
};

export const getPaymentModalFlowStep = state => {
  return state.checkoutForm.paymentModalFlowStep;
};
