/* eslint-disable max-len */
import Styled from 'styled-components';

export const ScControlBar = Styled.div`
  margin-bottom: 1em;
  margin-top: 28px;

  &, .cb-actions, .cb-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cb-toggle {
    padding: 0 .5em 0 .75em;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
  .cb-actions, .cb-body { flex: 1 1 auto; }
  .cb-actions { padding-right: .5em; }
  .cb-searchCont:only-child { flex: 0 0 100%; }
  .cb-searchCont { flex: 0 0 44%; }
  .cb-searchCont input[type="search"] {
    width: 100%;
    display: block;
    padding: .5em .75em .5em 1.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%238D8EAA' fill-rule='evenodd' stroke='%238D8EAA' d='M1.875 6.688c0-2.654 2.159-4.813 4.813-4.813 2.652 0 4.812 2.159 4.812 4.813 0 2.652-2.16 4.812-4.813 4.812-2.653 0-4.812-2.16-4.812-4.813m12.996 7.566l-3.869-3.87c.853-.995 1.373-2.285 1.373-3.695C12.375 3.551 9.823 1 6.687 1 3.553 1 1 3.552 1 6.688c0 3.135 2.552 5.687 5.688 5.687 1.41 0 2.7-.52 3.696-1.373l3.869 3.87c.08.079.189.128.31.128.241 0 .437-.196.437-.438 0-.12-.049-.23-.129-.31'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0.5rem center;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #bcbcc8;
    border-radius: 3px;
    height: 47.25px;
  }

  .cb-status {
    display: flex;
    align-items: center;
    border: 1px solid #0078E8;
    color: #0078E8;
    border-radius: 15px;
    user-select: none;

    span { padding-left: .5em; font-size: .875em; }
    button { background: transparent; border: 0; padding: 4px; box-shadow: none; cursor: pointer; color: #0078E8 }
    svg { width: 20px; height: 20px; display: block; }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    .cb-searchCont { flex: 0 0 100%; order: -1; margin-bottom: .75em; }
    .cb-searchCont:only-child { margin-bottom: 0; }
    .cb-actions { padding-right: 0; }
  }
`;
