import React from 'react';
import { node } from 'prop-types';

const TabButtonContainer = ({ children = null, ...props }) => <div className="jfTabs-row forTabs" {...props}><div className="jfTabs-tabs" role="tablist">{children}</div></div>;

TabButtonContainer.propTypes = {
  children: node.isRequired
};

export default TabButtonContainer;
