import { t } from '@jotforminc/translation';
import { Hooks, Popover } from '@jotforminc/uikit';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import { func, string } from 'prop-types';
import React, {
  useCallback, useMemo, useRef
} from 'react';
import Button from '../../../../../../components/Button';
import ItemStyler from '../../../ItemStyler';
import { ScChangeStyle } from '../../RightPanel/ListItemStyler/ScChangeStyle';
import { getItemStylingColors, ITEM_STYLING_LIST } from '../../../../../../properties/styling';
import { checkMobilePhone } from '../../../../../../utils';

const ElementStyler = ({
  onChange,
  itemBgColor,
  itemBorderColor,
  itemFontColor
}) => {
  const colorPalettePopoverRef = useRef();
  const chooseColorPaletteRef = useRef();
  const [isColorPaletteVisible, setColorPaletteVisible] = Hooks.useClickOutsideState(false, [colorPalettePopoverRef, chooseColorPaletteRef]);

  const appliedColors = { itemBgColor, itemBorderColor, itemFontColor };

  const chooseButtonStyling = useMemo(() => {
    const defaultColors = getItemStylingColors(ITEM_STYLING_LIST, 'default');
    const fallbackToDefault = compact(Object.values(appliedColors)).length !== 3;
    return isEqual(defaultColors, appliedColors) || fallbackToDefault ? { // default colors of "Choose Style" button
      itemBgColor: '#555F6E',
      itemBorderColor: '#303339',
      itemFontColor: '#ffffff'
    } : appliedColors;
  }, [appliedColors]);

  const handleChange = useCallback(data => {
    onChange(data);
  }, [onChange]);

  const handleSchemeChange = useCallback(schemeID => {
    onChange({ schemeID });
  }, [onChange]);

  const isMobile = checkMobilePhone();
  const popoverStyle = { padding: '5px 3%', zIndex: '2' };
  const popoverMobileStyle = { padding: '5px 3%', zIndex: '2', maxWidth: '280px' };

  return (
    <>
      <ScChangeStyle {...chooseButtonStyling}>
        <Button ref={chooseColorPaletteRef} onClick={() => setColorPaletteVisible(!isColorPaletteVisible)}>{t('Change Style')}</Button>
      </ScChangeStyle>
      {
          isColorPaletteVisible
          && (
            <Popover
              ref={colorPalettePopoverRef}
              targetRef={chooseColorPaletteRef}
              className="itemStyler"
              style={isMobile ? popoverMobileStyle : popoverStyle}
              popoverOptions={{
                placement: 'bottom'
              }}
            >
              <ItemStyler
                id="itemStylerComponent"
                onChange={handleChange}
                onSchemeChange={handleSchemeChange}
                {...appliedColors}
              />
            </Popover>
          )
        }
    </>
  );
};

ElementStyler.propTypes = {
  onChange: func,
  itemFontColor: string,
  itemBorderColor: string,
  itemBgColor: string
};

ElementStyler.defaultProps = {
  onChange: f => f,
  itemFontColor: '',
  itemBorderColor: '',
  itemBgColor: ''
};

export default ElementStyler;
