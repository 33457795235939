import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';

import { templateConstants } from '../../templateConstants';
import { getDeviceType } from '../../getDeviceType';
import Accordion from './Accordion';
import RadioButton from './RadioButton';

const FormLayout = ({
  filters, setFilters, username
}) => {
  const { theme: formLayout } = filters;

  const handleChange = e => {
    const option = e.target.value;
    if (option === formLayout) return;
    setFilters({
      ...filters,
      theme: option
    });
    window.standaloneTemplatesLogger({
      actor: username,
      action: `${getDeviceType()}:filterTheme`,
      target: option
    });
  };

  return (
    <Accordion username={username} text={t('FORM LAYOUT')} label={t(capitalizeFirstLetter(formLayout))}>
      {(templateConstants['form-templates'].formLayoutOptions || []).map(option => (
        <RadioButton
          key={option}
          label={t(capitalizeFirstLetter(option))}
          name="form-layout"
          value={option}
          id={option}
          checked={formLayout === option}
          onChange={handleChange}
        />
      ))}
    </Accordion>
  );
};

FormLayout.defaultProps = {
  username: ''
};

FormLayout.propTypes = {
  username: PropTypes.string,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired
};

export default FormLayout;
