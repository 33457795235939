import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';
import type { PAYMENT_FIELDS } from '@jotforminc/payment-constants';

/**
 * Maps a sandbox value to its corresponding mapped value based on the gateway type.
 *
 * @param {Object} params - The parameters object.
 * @param {PAYMENT_FIELDS} params.gatewayType - The type of the payment gateway.
 * @param {string | null} [params.sandboxValue=null] - The sandbox value to be mapped.
 * @returns {string | null} - The mapped value if found, otherwise null.
 */
export const sandboxValueToMappedValue = ({
  gatewayType = null,
  sandboxValue = null
}: {
  gatewayType: PAYMENT_FIELDS | null,
  sandboxValue: string | null
}): string | null => {
  if (!sandboxValue || gatewayType === null) {
    return null;
  }

  const { connection: { allowSwitchMode, mode } } = supportedGatewayConnectionPropsList[gatewayType];

  if (allowSwitchMode && mode && mode.options) {
    const { options } = mode;
    const mappedValue = options.find(option => option.value === sandboxValue);

    if (mappedValue) {
      return mappedValue.mappedValue;
    }
  }

  return null;
};
