import {
  combineReducers, compose, createStore, Store, applyMiddleware
} from 'redux';
import Tracking from '@jotforminc/tracking';
import * as reducers from './reducers';
import { IStore } from '../types/common';
import actionCatcher from './middlewares/actionCatcher';

const rootReducer = combineReducers(reducers);

export const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
let enhancers = null;

if (composeEnhancers) {
  const sentryRedux = Tracking.createReduxEnhancer();
  if (sentryRedux) {
    enhancers = composeEnhancers(applyMiddleware(actionCatcher), sentryRedux);
  } else {
    enhancers = composeEnhancers(applyMiddleware(actionCatcher));
  }
}

const store: Store<IStore> = createStore(rootReducer, undefined, enhancers);

export default store;
