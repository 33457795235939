import Styled from 'styled-components';

export const ScTextInput = Styled.div`
  ${props => !props.unstyled && `
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 2px;
    input {
      width: 100%;
      height: 100%;
      border: 0px;  
    }
  `}
`;
