import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Eway gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlEway: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_eway: {
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      description: 'Authorize the card now to charge it manually later.',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP']
    },
    requireBillingAddr: {
      title: 'Require Billing Address',
      type: 'toggle',
      selectedCondition: settings => settings.requireBillingAddr === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM']
    },
    customDataField: {
      title: 'Fetch Data from a Text Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_textbox', 'control_hidden'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: {
        or: [
          { prop: 'formQuestions', operator: 'has', value: 'control_textbox' },
          { prop: 'formQuestions', operator: 'has', value: 'control_hidden' }
        ]
      }
    }
  }
};

export default controlEway;
