import {
  ComponentPropsWithoutRef, MutableRefObject
} from 'react';
import { Icon } from '../../constants/common.types';

export type DialogProps = {
  open: boolean,
  onClose: () => void,
  initialFocus?: MutableRefObject<HTMLElement>,
  zIndex?: number,
  ariaLabel: string,
  noFade?: boolean,
  size?: 'small' | 'medium',
};

export type DialogBodyProps = ComponentPropsWithoutRef<'div'>;

export type DialogIconProps = ComponentPropsWithoutRef<'div'> & {
  colorStyle?: 'success' | 'informative' | 'warning' | 'error',
  icon: Icon
}

export type DialogTitleProps = ComponentPropsWithoutRef<'h2'>;
export type DialogCloseButtonProps = ComponentPropsWithoutRef<'button'>;
export type DialogDescriptionProps = ComponentPropsWithoutRef<'p'>;
export type DialogContentProps = ComponentPropsWithoutRef<'div'>;
export type DialogFooterProps = ComponentPropsWithoutRef<'div'>;

export type DialogActionsProps = ComponentPropsWithoutRef<'div'> & {
  defaultAlignment?: boolean
}

export const dialogActionsDefaultProps:Partial<DialogActionsProps> = {
  defaultAlignment: true
} as const;

export const dialogDefaultProps:Partial<DialogProps> = {
  open: false,
  zIndex: 1004,
  noFade: false,
  size: 'small'
} as const;

export const dialogIconDefaultProps:Partial<DialogIconProps> = {
  colorStyle: 'informative'
} as const;
