import React, {
  ComponentPropsWithRef, KeyboardEvent, forwardRef, useEffect, useRef, useState,
  ClipboardEvent
} from 'react';
import { IconCaretUp, IconCaretDown, IconLockFilled } from '@jotforminc/svg-icons';
import { BaseInput, BaseInputAddon } from './BaseInput';
import { InputNumberProps, inputNumberDefaultProps } from './input.types';
import { numberSpinButtonCVA } from './input.cva';
import { useFormControl } from '../../contexts';

const AVAILABLE_KEYS:KeyboardEvent<HTMLInputElement>['key'][] = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Backspace'];

export const InputNumber = forwardRef((
  props: InputNumberProps,
  ref?: ComponentPropsWithRef<'input'>['ref']
):JSX.Element => {
  const {
    disabled,
    theme,
    size,
    readOnly,
    step,
    id,
    onKeyDown,
    onPaste,
    ...rest
  } = useFormControl(props);

  const controlsRef = useRef<HTMLInputElement>(null);
  const changeEvent = new Event('change', { bubbles: true });

  const [input, setInput] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    const inputNode = controlsRef.current
      ? (controlsRef.current?.parentNode as HTMLElement)?.firstChild as HTMLInputElement
      : null;
    setInput(inputNode);
  }, []);

  const handleValue = (dir: 'stepUp' | 'stepDown') => {
    if (!input || readOnly || disabled) return;
    input.focus();
    input[dir]();
    input.dispatchEvent(changeEvent);
  };

  const handleKeyDown = (event:KeyboardEvent<HTMLInputElement>) => {
    if (event.metaKey || event.ctrlKey) return;
    if (Number.isNaN(parseInt(event.key, 10)) && !AVAILABLE_KEYS.includes(event.key)) event.preventDefault();
    if (onKeyDown) onKeyDown(event);
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = (event.clipboardData).getData('text');
    if (Number.isNaN(parseInt(pastedText, 10))) event.preventDefault();
    if (onPaste) onPaste(event);
  };

  return (
    <BaseInput
      ref={ref}
      type="number"
      disabled={!!disabled}
      readOnly={!!readOnly}
      theme={theme}
      size={size}
      id={id}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      addonEnd={(
        <>
          {readOnly && (
            <BaseInputAddon
              icon={IconLockFilled}
              size={size}
              id={id}
              direction="end"
              theme={theme}
            />
          )}
          <div className="flex flex-col w-8 radius-r overflow-hidden" ref={controlsRef}>
            <button
              type="button"
              disabled={disabled}
              className={numberSpinButtonCVA({ theme, readOnly: !!readOnly, disabled: !!disabled })}
              onClick={() => handleValue('stepUp')}
            >
              <IconCaretUp className="w-4 h-4" />
            </button>
            <button
              type="button"
              disabled={disabled}
              className={numberSpinButtonCVA({ theme, readOnly: !!readOnly, disabled: !!disabled })}
              onClick={() => handleValue('stepDown')}
            >
              <IconCaretDown className="w-4 h-4" />
            </button>
          </div>
        </>
      )}
      step={step}
      {...rest}
    />
  );
});

InputNumber.defaultProps = inputNumberDefaultProps;
