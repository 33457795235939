import { Checkbox as CommonCheckbox } from '@jotforminc/checkbox';
import {
  bool, func, string
} from 'prop-types';
import React from 'react';
import { ScCheckbox } from './ScCheckbox';

const Checkbox = ({
  value,
  onChange,
  ...props
}) => {
  const checked = typeof value === 'boolean' ? value : !!parseInt(value, 10);
  return (
    <ScCheckbox>
      <CommonCheckbox
        {...props}
        onChange={e => onChange(e.target.checked)}
        checked={checked}
      />
    </ScCheckbox>
  );
};

Checkbox.propTypes = {
  value: bool,
  onChange: func,
  itemTextAlignment: string
};

Checkbox.defaultProps = {
  value: false,
  onChange: f => f,
  itemTextAlignment: ''
};

export default Checkbox;
