import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
.sortableHelper {
z-index: 1004;
opacity: 0.9;
}
`;
// Z-index of the right panel for portal is 1001, for form builder it is 1003 in mobile view.

export default GlobalStyle;
