import { t } from '@jotforminc/translation';
import { SVGIcon } from '@jotforminc/icon-selector';
import { Button } from '@jotforminc/magnet';
import { IconTrashFilled } from '@jotforminc/svg-icons';
import {
  elementType, func, number, shape, string
} from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Image from '../../../../components/Image';
import { IMAGE_TYPE } from '../../../../constants';
import { ScImagePreview } from './ScImagePreview';

const ImagePreview = ({
  ImagePreviewWrapper, ImageWrapper, RemoveButtonWrapper, RemoveButtonRenderer, RightSideWrapper,
  imageURL, imageHint, imageType, onRemove, svgProps, altText, layout, id
}) => {
  const isImageIcon = useMemo(() => imageType === IMAGE_TYPE.icon, [imageType]);

  const isLayoutLine = useMemo(() => layout === 'line', [layout]);

  const PreviewImage = useCallback(() => {
    return (
      <ImageWrapper>
        {isImageIcon ? (
          <SVGIcon
            url={imageURL}
            {...svgProps}
          />
        ) : (
          <Image imageURL={imageURL} altText={t(altText)} showAltTextActiveOnError={false} />
        )}
      </ImageWrapper>
    );
  }, [isImageIcon, imageURL, altText, svgProps]);

  const ImageHint = useCallback(() => {
    return (imageHint && isLayoutLine) && (
      <span className="previewHint" title={imageHint}>{imageHint}</span>
    );
  }, [imageHint, isLayoutLine]);

  const RemoveButton = useCallback(() => {
    return (
      <RemoveButtonWrapper>
        <RemoveButtonRenderer
          onClick={() => onRemove(id)}
          data-testid="reset-image"
        />
      </RemoveButtonWrapper>
    );
  }, [onRemove, RemoveButtonRenderer, id]);

  return (
    <ImagePreviewWrapper layout={layout}>
      <PreviewImage />
      <RightSideWrapper>
        <ImageHint />
        <RemoveButton />
      </RightSideWrapper>
    </ImagePreviewWrapper>
  );
};

ImagePreview.propTypes = {
  id: number,
  imageURL: string,
  imageHint: string,
  imageType: string,
  onRemove: func,
  svgProps: shape({}),
  altText: string,
  ImagePreviewWrapper: elementType,
  ImageWrapper: elementType,
  RemoveButtonWrapper: elementType,
  RemoveButtonRenderer: elementType,
  RightSideWrapper: elementType,
  layout: string
};

ImagePreview.defaultProps = {
  id: 0,
  imageURL: '',
  imageHint: '',
  imageType: IMAGE_TYPE.image,
  onRemove: f => f,
  svgProps: {},
  altText: t('preview'),
  ImagePreviewWrapper: ScImagePreview,
  ImageWrapper: props => <div className="previewMode-image" {...props} />,
  RemoveButtonWrapper: props => <div className='flex justify-start pt-3' {...props} />,
  RemoveButtonRenderer: props => {
    return (
      <Button
        colorStyle="secondary"
        size="medium"
        startIcon={IconTrashFilled}
        {...props}
      >
        {t('Remove Image')}
      </Button>
    );
  },
  RightSideWrapper: props => <div className="rightSideWrapper" {...props} />,
  layout: 'column'
};

export default ImagePreview;
