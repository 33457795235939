import CurrencyJS from 'currency.js';

const noDecimalCurrencies = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'VUV', 'XAF', 'XOF', 'XPF'];

const DEFAULT_VALUES = {
  currency: 'USD',
  useDecimal: 'Yes',
  decimalMark: 'point'
};

export const getCurrencyPrefixSuffix = currency => {
  switch (currency) {
    case 'EUR':
      return ['€', ''];
    case 'GBP':
      return ['£', ''];
    case 'BRL':
      return ['R$', ''];
    case 'JPY':
      return ['¥', ''];
    case 'USD':
      return ['$', ''];
    case 'AUD':
    case 'CAD':
    case 'NZD':
    case 'SGD':
    case 'HKD':
    case 'CLP':
      return ['$', currency];
    case 'TRY':
      return ['', '₺'];
    default:
      return ['', currency || ''];
  }
};

const getDecimalAndSeparator = decimalMark => {
  const DECIMAL_MARKS = {
    point: '.',
    comma: ','
  };

  // Thousands separator as in 10,000,000
  const SEPARATOR_MARKS = {
    point: DECIMAL_MARKS.comma,
    comma: DECIMAL_MARKS.point
  };

  const decimalChar = DECIMAL_MARKS[decimalMark] || DECIMAL_MARKS.point;
  const separatorChar = SEPARATOR_MARKS[decimalMark] || DECIMAL_MARKS.comma;
  return [decimalChar, separatorChar];
};

const isYes = val => (typeof val === 'string' ? ['Yes'].includes(val) : !!val);

/**
 * Constructs currency.js backed object to work on.
 * Note that price argument is either a float value or a string value in the form 111.11
 * It is independent of decimalMark (always uses '.')
 * Also it does not contain any thousands separator like 111,111.11
 * @param price
 * @param currency
 * @param useDecimal
 * @param decimalMark
 * @returns {currency}
 */
const constructPriceObj = ({
  price,
  currency = DEFAULT_VALUES.currency,
  useDecimal = DEFAULT_VALUES.useDecimal,
  decimalMark = DEFAULT_VALUES.decimalMark
}) => {
  const isDecimalAllowed = !noDecimalCurrencies.includes(currency);
  const includeDecimal = isDecimalAllowed && isYes(useDecimal);
  const precision = includeDecimal ? 2 : 0;
  const [decimal, separator] = getDecimalAndSeparator(decimalMark);

  // To avoid a decimalMark mismatch (like price=111.11, decimalMark='comma')
  const numberPrice = typeof price === 'string' ? parseFloat(price) : price;

  return CurrencyJS(numberPrice, {
    symbol: '', // Don't inform library about currency. It's handled in formatting phase
    decimal,
    separator,
    precision
  });
};

export const formatPriceObj = (priceObj, { decimalMark = DEFAULT_VALUES.decimalMark, currency = DEFAULT_VALUES.currency }) => {
  const [decimal, separator] = getDecimalAndSeparator(decimalMark);

  const exactPrice = priceObj.format({ decimal, separator });
  const [prefix, suffix] = getCurrencyPrefixSuffix(currency);

  return [prefix, exactPrice, ` ${suffix}`].join('');
};

/**
 * Returns human-readable price without currency but considering decimals and separators : 1,000,000.00
 *
 * @param priceObjectOptions
 * @returns string
 */
export const getExactPrice = ({
  price, currency = DEFAULT_VALUES.currency, useDecimal = DEFAULT_VALUES.useDecimal, decimalMark = DEFAULT_VALUES.decimalMark
}) => {
  const priceObj = constructPriceObj({
    price, currency, useDecimal, decimalMark
  });
  const [decimal, separator] = getDecimalAndSeparator(decimalMark);
  return priceObj.format({ decimal, separator });
};

/**
 * Returns fully formatted price info: $1,000,000.00
 * @param priceObjectOptions
 * @returns string
 */
export const getFormattedPrice = priceObjectOptions => {
  const priceObj = constructPriceObj(priceObjectOptions);
  return formatPriceObj(priceObj, priceObjectOptions);
};

export const multiplyMoney = (priceObjectOptions, multiplyBy) => {
  const priceObj = constructPriceObj(priceObjectOptions);
  return priceObj.multiply(multiplyBy);
};

export const sumMoney = (priceObjectOptions, ...prices) => prices.reduce((prev, acc) => prev.add(acc), constructPriceObj(priceObjectOptions));
