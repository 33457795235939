import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Loading } from '@jotforminc/loading';
import { getAccountTypeName, getAvailablePlansForUser, decideActiveCampaign } from '@jotforminc/utils';

import {
  getLimitFromType,
  fetchUserPlans,
  fetchCombinedInfo,
  actionTracker
} from '../utils';

import { shouldShowNewDesign } from '../utils/abtests';

const LazyLimitDialogModal = React.lazy(/* webpackChunkName: "LimitDialogModal" */ () => import('./LimitDialogModal'));

const openLimitDialog = async ({
  user: _user = {},
  plans: _plans = {},
  selectedLimitType = 'formCount',
  dispatchedFrom = '',
  portalRoot
}) => {
  let user;
  let plans;

  const isCredentialsFetched = !!Object.keys(_user)?.length;
  if (isCredentialsFetched) {
    user = _user;
  } else {
    user = await fetchCombinedInfo();
  }

  const activeCampaign = decideActiveCampaign(user);
  const accountTypeName = getAccountTypeName(user);
  const selectedLimit = getLimitFromType(user, selectedLimitType);

  const isPlansFetched = !!Object.keys(_plans)?.length;
  if (isPlansFetched) {
    plans = _plans;
  } else {
    plans = await fetchUserPlans();
  }

  const isTestVariant = await shouldShowNewDesign(user, dispatchedFrom);

  const availablePlansForUser = getAvailablePlansForUser(accountTypeName);

  return new Promise((resolve, reject) => {
    const handleClose = () => {
      actionTracker({
        username: user?.username,
        action: 'close-btn-clicked',
        target: dispatchedFrom,
        isV2: isTestVariant
      });

      reject();
      ReactDOM.unmountComponentAtNode(portalRoot);
    };

    ReactDOM.render((
      <Suspense fallback={<Loading />}>
        <LazyLimitDialogModal
          currentAccountType={accountTypeName}
          modalProps={{
            usePortal: true
          }}
          selectedLimit={selectedLimit}
          selectedLimitType={selectedLimitType}
          onClose={handleClose}
          activeCampaign={activeCampaign}
          campaignData={user?.campaign}
          user={user}
          availablePlansForUser={availablePlansForUser}
          limitDialogV2={isTestVariant}
          plans={plans}
          dispatchedFrom={dispatchedFrom}
        />
      </Suspense>
    ), portalRoot);
  });
};

export default openLimitDialog;
