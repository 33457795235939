import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseFooter from './BaseFooter';
import { changeGatewaySettingsView } from '../../store/actionCreators';
import {
  TApm,
  TGateway,
  TCurrencyCode,
  TFormId
} from '../../types/common';
import Selectors from '../../store/selectors';

const ApmPickerFooter = ({
  checkoutFormId,
  activeAPM,
  currentCurrency,
  handleSwitchPaymentGateway
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const selectedGateway = useSelector(Selectors.getSelectedGatewayWithAPM);

  return (
    <>
      <BaseFooter
        saveText='Continue'
        backText='Back'
        handleSave={() => {
          if (selectedGateway) {
            const data = {
              nameAPM: activeAPM as TApm,
              type: selectedGateway as TGateway,
              currency: currentCurrency as TCurrencyCode
            };
            handleSwitchPaymentGateway(checkoutFormId, data);
          }
        }}
        handleBack={() => dispatch(changeGatewaySettingsView('gateway-picker'))}
        disabled={!selectedGateway}
      />
    </>
  );
};

interface IProps {
  checkoutFormId: TFormId
  activeAPM: TApm
  currentCurrency: TCurrencyCode
  handleSwitchPaymentGateway: (id: TFormId, data: { nameAPM: TApm, type: TGateway, currency: TCurrencyCode }) => void
}

export default ApmPickerFooter;
