import React, { useCallback } from 'react';
import { TextArea } from '@jotforminc/uikit';

const PwTextArea = ({
  id,
  className,
  inputClass,
  onChange,
  defaultValue,
  ...props
}: IProps): JSX.Element => {
  const onChangeHandler = useCallback(val => {
    onChange(val);
  }, [onChange]);

  // for additional class
  const additionalClass = inputClass !== '' ? inputClass : 'w-full';

  return (
    <div className="pwTextArea">
      <TextArea
        id={id}
        className={`${className} ${additionalClass}`}
        onChange={onChangeHandler}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
};

export interface IProps {
  id?: string,
  inputClass: string,
  className?: string
  onChange: (value: boolean | string) => void
  defaultValue: string
}

PwTextArea.defaultProps = {
  id: '',
  className: '',
  inputClass: ''
};

export default PwTextArea;
