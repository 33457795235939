import {
  COMPONENT_PROPERTIES,
  FORM_QUESTION_TYPES,
  FORM_TYPES,
  GATEWAY_QUESTION_PROPERTIES,
  GATEWAY_SUPPORTED_RESOURCES,
  PAYMENT_QUESTION_PROP_NAMES
} from '@jotforminc/payment-constants';
import { isResourcePropertyRequired } from './isResourcePropertyRequired';
import { isResourcePropertyVisible } from './isResourcePropertyVisible';

export const getVisibleAndRequiredResourceProps = (
  gatewayResourceProps: Partial<Record<PAYMENT_QUESTION_PROP_NAMES, COMPONENT_PROPERTIES>>,
  resource: GATEWAY_SUPPORTED_RESOURCES,
  isEnterprise: boolean,
  formType: FORM_TYPES,
  formQuestionTypes: FORM_QUESTION_TYPES[],
  gatewayProperties: GATEWAY_QUESTION_PROPERTIES
): [string, COMPONENT_PROPERTIES][] => {
  const dontValidate = ['title'];
  const components = Object.entries(gatewayResourceProps)
    .filter(item => !dontValidate.includes(item[1].type)) // field type validation blacklist
    .filter(item => isResourcePropertyVisible(
      item[1].renderCondition || true,
      item[1].resources,
      resource,
      isEnterprise,
      formType,
      formQuestionTypes,
      gatewayProperties
    )) // get visible fields
    .filter(item => isResourcePropertyRequired(
      item[1].requiredCondition || false,
      isEnterprise,
      formType,
      formQuestionTypes,
      gatewayProperties
    )); // get required fields

  return components;
};
