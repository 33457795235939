import { ABTestManager } from '@jotforminc/abtest-manager';

export const shouldShowNewDesign = async (user, dispatchedFrom) => {
  try {
    if (dispatchedFrom === 'sheets') return true;

    const getNewDesignParam = new URLSearchParams(window.location.search).get('limitDialogV2');
    const showNewDesign = getNewDesignParam === '1';

    if (showNewDesign) return true;
    if (user?.account_type?.name === 'GUEST') return false;

    const abTestManager = new ABTestManager({
      user: user,
      isTestEnabled: true,
      testName: 'limitDialogNewDesign',
      testVariantCode: '8781',
      controlVariantCode: '8771',
      urlParam: 'limitDialogV2',
      cacheVariantCodeAtLocalStorage: true
    });

    const getTestVariant = await abTestManager.isTestVariant();
    return getTestVariant;
  } catch (error) {
    return false;
  }
};
