import {
  bool, elementType, func, number, oneOfType, string
} from 'prop-types';
import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import { ScDimensionPicker } from './ScDimensionPicker';

const DimensionPicker = ({
  width,
  height,
  proportion,
  widthUnit,
  heightUnit,
  onChange,
  keepProportion: initialKeepProportion,
  keepRatioButton,
  Wrapper,
  WidthWrapper,
  TextInput,
  ProportionIconWrapper,
  HeightWrapper,
  SizeUnitRenderer,
  LockIcon,
  UnlockIcon,
  WidthHintRenderer,
  HeightHintRenderer
}) => {
  const [values, setValues] = useState({ width, height });
  const [keepProportion, setKeepProportion] = useState(initialKeepProportion);
  const [initialValues] = useState({ width, height });

  const calculateProportionalValues = (name, value) => {
    return {
      width: {
        width: value,
        height: Math.round(value / proportion)
      },
      height: {
        width: Math.round(value * proportion),
        height: Math.round(value)
      }
    }[name];
  };

  const handleProportionalChange = (name, value) => {
    const updatedValues = calculateProportionalValues(name, value);
    setValues(updatedValues);
    onChange(updatedValues);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const parsedValue = parseInt(value, 10);
    const newValue = (Number.isNaN(parsedValue) || parsedValue === 0) ? initialValues[name] : Math.round(parsedValue);

    if (keepProportion) {
      handleProportionalChange(name, newValue);
    } else {
      setValues({
        ...values,
        [name]: newValue
      });
      onChange({
        ...values,
        [name]: newValue
      });
    }
  };

  const handleProportionSettingChange = () => setKeepProportion(!keepProportion);

  return (
    <Wrapper>
      <WidthWrapper className="dimention-input-wrapper">
        <TextInput
          className="text-input width-input"
          value={values.width || initialValues.width}
          type="number"
          name='width'
          onChange={handleChange}
        />
        <SizeUnitRenderer className="suffix">{widthUnit || 'PX'}</SizeUnitRenderer>
        <WidthHintRenderer className="hint" />
      </WidthWrapper>
      <ProportionIconWrapper>
        {keepRatioButton
          ? (
            <span className="lock-icon-wrapper" aria-hidden="true" onClick={handleProportionSettingChange}>
              {keepProportion ? <LockIcon /> : <UnlockIcon />}
            </span>
          )
          : (
            <span>
              <LockIcon />
            </span>
          )}
      </ProportionIconWrapper>
      <HeightWrapper className="dimention-input-wrapper">
        <TextInput
          className="text-input height-input"
          value={values.height || initialValues.height}
          type="number"
          name='height'
          onChange={handleChange}
        />
        <SizeUnitRenderer className="suffix">{heightUnit || 'PX'}</SizeUnitRenderer>
        <HeightHintRenderer className="hint" />
      </HeightWrapper>
    </Wrapper>
  );
};

DimensionPicker.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  onChange: func,
  keepProportion: bool,
  widthUnit: string,
  heightUnit: string,
  keepRatioButton: bool,
  Wrapper: elementType,
  WidthWrapper: elementType,
  TextInput: elementType,
  ProportionIconWrapper: elementType,
  HeightWrapper: elementType,
  SizeUnitRenderer: elementType,
  LockIcon: elementType,
  UnlockIcon: elementType,
  WidthHintRenderer: elementType,
  HeightHintRenderer: elementType,
  proportion: oneOfType([number, string])
};

DimensionPicker.defaultProps = {
  width: 0,
  height: 0,
  onChange: f => f,
  keepProportion: true,
  widthUnit: '',
  heightUnit: '',
  keepRatioButton: true,
  Wrapper: ScDimensionPicker,
  WidthWrapper: props => <div {...props} />,
  TextInput: props => <input {...props} />,
  ProportionIconWrapper: props => <div {...props} />,
  HeightWrapper: props => <div {...props} />,
  SizeUnitRenderer: props => <span {...props} />,
  LockIcon: props => <span {...props}>Locked!</span>,
  UnlockIcon: props => <span {...props}>Unlocked!</span>,
  WidthHintRenderer: props => <p {...props}>{t('Width')}</p>,
  HeightHintRenderer: props => <p {...props}>{t('Height')}</p>,
  proportion: 0
};

export default DimensionPicker;
