import React, { memo } from 'react';
import { bool, elementType, string } from 'prop-types';
import { IconImageBrokenFilled } from '@jotforminc/svg-icons';
import '../item-image.scss';

const NoImage = ({ altText, showAltText, PlaceholderRenderer }) => {
  return (
    <div className='image-empty-state'>
      <PlaceholderRenderer />
      {altText && showAltText && <div title={altText} className="altText">{altText}</div>}
    </div>
  );
};

NoImage.propTypes = {
  altText: string,
  showAltText: bool,
  PlaceholderRenderer: elementType
};

NoImage.defaultProps = {
  altText: '',
  showAltText: true,
  PlaceholderRenderer: IconImageBrokenFilled
};

export default memo(NoImage);
