/* eslint-disable @jotforminc/no-native-button */
import React, { forwardRef } from 'react';
import {
  bool, oneOfType, shape, string
} from 'prop-types';

const ButtonRenderer = forwardRef(({
  option: { text, value } = {},
  isOptionsVisible, placeholder, ...props
}, ref) => {
  return (
    <button
      ref={ref}
      className='dropdown-button'
      type="button"
      data-value={value}
      {...props}
    >
      {text ?? placeholder}
    </button>
  );
});

ButtonRenderer.propTypes = {
  option: oneOfType([shape({}), bool]),
  isOptionsVisible: bool,
  placeholder: string
};

ButtonRenderer.defaultProps = {
  option: {},
  isOptionsVisible: false,
  placeholder: ''
};

export default ButtonRenderer;
