import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import { safeJSONParse } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import WarningBox from '@jotforminc/warning-box';
import SELECTORS from '../../../../../../../store/selectors';
import Dropdown from '../../../../../../../components/Dropdown';
import TextArea from '../../../../TextArea';
import { ScRightPanelDropdown } from '../../../../../styles/scRightPanel';
import { isYes } from '../../../../../../../utils';
import { getButtonShareDefMessages, getDefaultButtonShareObj } from '../../../constants';
import { getUpdatedShareMessage } from '../../utils';

const ButtonShareSelection = ({ value, onChange }) => {
  const parsedValue = typeof value === 'string' ? safeJSONParse(value) : value;
  const donationItems = useSelector(SELECTORS.getDonationItems) ?? [];
  const isDonationGoalActive = donationItems[0] && isYes(donationItems[0].showDonationGoal);

  const messageTypes = [
    { text: t('App Share'), value: 'appShare' },
    (donationItems.length) && [{ text: t('Donation Share'), value: 'donationShare' }]
  ].flat().filter(Boolean);

  const handleMessageTypeChange = typeValue => {
    const defaultMessageType = (typeValue === 'donationShare' && isDonationGoalActive) ? 'donationGoalShare' : typeValue;
    onChange(JSON.stringify({ type: typeValue, text: getButtonShareDefMessages()[defaultMessageType] }));
  };

  const handleMessageChange = messageValue => {
    onChange(JSON.stringify({ ...parsedValue, text: messageValue }));
  };

  useEffect(() => {
    const updatedShareMessage = getUpdatedShareMessage(parsedValue, donationItems, isDonationGoalActive);
    if (updatedShareMessage) { onChange(JSON.stringify(updatedShareMessage)); }
  }, [isDonationGoalActive]);

  // needed for first render on role change
  useEffect(() => {
    if (value === '') {
      onChange(JSON.stringify(getDefaultButtonShareObj()));
    }
  }, []);

  return (
    <>
      <Dropdown
        options={messageTypes}
        onChange={handleMessageTypeChange}
        DropdownWrapperRenderer={ScRightPanelDropdown}
        value={parsedValue.type}
      />
      <TextArea
        style={{ marginTop: '8px' }}
        placeholder='Type a message'
        value={parsedValue.text}
        onChange={handleMessageChange}
        limit={200}
      />
      <WarningBox
        isLongText
        size='medium'
      >
        {t('Your custom share text might show on certain platforms, while others may not support it.')}
      </WarningBox>
    </>
  );
};

ButtonShareSelection.propTypes = {
  value: string,
  onChange: func
};

ButtonShareSelection.defaultProps = {
  value: '',
  onChange: f => f
};

export default ButtonShareSelection;
