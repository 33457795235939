import React, { useCallback, useMemo } from 'react';
import {
  elementType, bool, string, node
} from 'prop-types';
import { checkMobilePhone, sanitizeHTML } from '../../../utils';
import { DefaultDivRenderer } from '../defaultRenderers';
import { withContentWrapper } from '../withContentWrapper';

const SimpleItem = ({
  TitleRenderer, DescriptionRenderer,
  showIcon, IconRenderer,
  title, description,
  badge, iconClassName
}) => {
  const isMobile = checkMobilePhone();
  // Consider twice while adding prop!
  const contentPart = useMemo(withContentWrapper(() => (
    <>
      <TitleRenderer className="title" tabIndex='0' defaultValue={title}>{sanitizeHTML(title)}</TitleRenderer>
      <DescriptionRenderer className="description" defaultValue={description}>{sanitizeHTML(description)}</DescriptionRenderer>
      {isMobile ? badge : null}
    </>
  )), [title, description, DescriptionRenderer, badge]); // In any case of extra render; consider use individually memoed components

  const Wrapper = useCallback(props => {
    const wrapperClassName = showIcon ? 'withIconItemContent' : 'withBadgeItemContent';
    return (!showIcon && !badge) ? (
      // eslint-disable-next-line react/prop-types
      <>{props.children}</>
    ) : (
      <div className={wrapperClassName} {...props} />
    );
  }, [showIcon, badge]);

  const iconPart = useMemo(() => showIcon && (
    <IconRenderer iconClassName={iconClassName} />
  ), [showIcon, IconRenderer]);

  const badgePart = useMemo(() => !!badge && !isMobile && badge);

  return (
    <Wrapper>
      {iconPart}
      {contentPart}
      {badgePart}
    </Wrapper>
  );
};

SimpleItem.propTypes = {
  title: string,
  description: string,
  showIcon: bool,
  IconRenderer: elementType,
  TitleRenderer: elementType,
  DescriptionRenderer: elementType,
  badge: node,
  iconClassName: string
};
SimpleItem.defaultProps = {
  title: '',
  description: '',
  showIcon: false,
  IconRenderer: null,
  TitleRenderer: DefaultDivRenderer,
  DescriptionRenderer: DefaultDivRenderer,
  badge: undefined,
  iconClassName: ''
};

export default SimpleItem;
