import {
  Children, cloneElement, Fragment, isValidElement, ReactChild, ReactNode
} from 'react';
import { Responsive } from '../constants/common.types';

export type ResponsiveClasses<T extends readonly string[]> = Record<T[number], Record<Responsive, string>>;

export const convertResponsiveClass = <T extends readonly string[]>(
  propValue: Partial<Record<Responsive, T[number]>> | string | undefined, classList: ResponsiveClasses<T>
): string[] => {
  if (propValue === undefined) {
    return [];
  }

  const valueObject = typeof propValue === 'string' ? { base: propValue } : propValue;

  const classArr = Object.entries(valueObject).map(values => {
    const _key:T[number] = values[1];
    const _val = values[0] as Responsive;

    return classList[_key][_val];
  });

  return classArr;
};

export function pick<T, K extends keyof T>(
  object: T,
  keys: K[]
): Pick<T, K> {
  return Object.assign(
    {},
    ...keys.map((key: K) => {
      return { [key]: object[key] };
    })
  );
}

type FlattenChildrenArgs = {
  children: ReactNode,
  depth: number,
  keys?: (string | number)[]
  filter: string,
  group:string
}

export function flattenChildren({
  children, depth = 0, keys = [], filter, group
}: FlattenChildrenArgs): ReactChild[] {
  return Children.toArray(children).reduce(
    (acc: ReactChild[] | any, node: any, nodeIndex) => {
      if (node?.type?.displayName === group || node?.type === Fragment) {
        acc.push(
          acc,
          ...flattenChildren({
            children: node.props.children,
            depth: depth + 1,
            keys: keys.concat(node.key || nodeIndex),
            group,
            filter
          })
        );
      } else if (isValidElement(node)) {
        acc.push(
          cloneElement(node, {
            key: keys.concat(String(node.key)).join('.')
          })
        );
      } else if (typeof node === 'string' || typeof node === 'number') {
        acc.push(node);
      }
      return acc;
    },
    []
  ).filter((child: any) => child?.type?.displayName === filter);
}

export const arraysEqualsCheck = (a:any[] = [], b:any[] = []):boolean => a.length === b.length && a.every((v, i) => v === b[i]);
