import React from 'react';
import Styled, { css } from 'styled-components';

const ScLoadingFrame = Styled.div`
  height: 99%;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isPreview }) => isPreview && css`
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: #fff;
  `}
`;

const ScLoadingCircle = Styled.div`
  @keyframes spinz { to { transform: rotate(360deg); } }

  width: 26px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: #d9d7e2 #d9d7e2 #d9d7e2 #8583a9;
  z-index: 2;
  font-size: 0;
  animation: spinz 1s cubic-bezier(0.49, 0.95, 0.46, 0.08) infinite forwards;
`;

export function Loading(props) { // I think we need to add this to common.
  return (
    <ScLoadingFrame {...props} className='app-loading-spinner'>
      <ScLoadingCircle />
    </ScLoadingFrame>
  );
}
