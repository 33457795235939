import { requestLayer } from './requestLayer';

/**
 * Checks if the provided connection name is unique by making a request to the server.
 * @param connectionName - The connection name to check for uniqueness.
 * @returns A Promise that resolves with the server response.
 */
export const checkUniqueConnectionNameWithRequest = ({
  connectionName,
  isEditMode = false,
  isReconnectMode = false,
  connectionId
}: {
  connectionName: string;
  isEditMode?: boolean;
  isReconnectMode?: boolean;
  connectionId?: string;
}): Promise<any> => {
  const postData = new FormData();
  postData.append('title', connectionName);

  if ((isEditMode || isReconnectMode) && connectionId) {
    postData.append('connectionId', connectionId);
  }

  return requestLayer.post('/API/payment/check-title', postData);
};
