import React, { FunctionComponent } from 'react';
import DataGrid from '@jotforminc/data-grid';
import { t } from '@jotforminc/translation';

type LabelValueTableType = {
  onCellChange: (labels: { [key: string]: string }) => void,
  onSort: (value: any) => void,
  onCheckbox: (value: any) => void,
  subfieldOrder: string,
  sublabels: { [key: string]: string },
  subfields: string,
  states: string,
  disabled: boolean
}

const LabelValueTable: FunctionComponent<LabelValueTableType> = ({
  subfieldOrder = '', subfields, sublabels, onCellChange, onSort, onCheckbox, states, disabled = false
}): JSX.Element => {
  const countryRow = (states !== 'americanStates'
    ? {
      id: 'country',
      key: 'country',
      label: t('Country')
    }
    : null);

  const getRows = () => ([
    {
      id: 'st1',
      key: 'addr_line1',
      label: t('Street Address 1')
    },
    {
      id: 'st2',
      key: 'addr_line2',
      label: t('Street Address 2')
    },
    {
      id: 'city',
      key: 'city',
      label: t('City')
    },
    {
      id: 'state',
      key: 'state',
      label: t('State / Province')
    },
    {
      id: 'zip',
      key: 'postal',
      label: t('Postal / Zip Code')
    },
    countryRow
  ]).filter(row => row !== null);

  const getOrderOfRow = (rowId: string) => subfieldOrder.split('|').indexOf(rowId);

  const filledRows = getRows().map(row => {
    return { ...row, sublabel: sublabels[row!.key] || '', visible: subfields.split('|').includes(row!.id) };
  }).sort((a, b) => getOrderOfRow(a.key as string) - getOrderOfRow(b.key as string));

  const getKeyFromId = (id: string) => getRows().find(row => row!.id === id)?.key || '';

  const columns = [
    {
      id: 'label',
      type: 'label',
      width: 160
    },
    {
      id: 'sublabel',
      type: 'textInput',
      cellProps: {
        onCellChange: (value: any, id: any) => onCellChange({ ...sublabels, [getKeyFromId(id)]: value })
      }
    }
  ];

  return (
    <DataGrid
      rows={filledRows}
      columns={columns}
      checkboxColumnKey={disabled ? false : 'visible'}
      sortable
      onSort={(fields: any) => onSort(fields.map((field: any) => field.key).join('|'))}
      onCheckbox={(fields: any) => onCheckbox(fields.join('|'))}
    />
  );
};

export default LabelValueTable;
