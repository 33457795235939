import React from 'react';
import { string } from 'prop-types';
import Styled from 'styled-components';
import { t } from '@jotforminc/translation';

import { portalTitleCommonStyle } from './commonCss';

const ScTitleRenderer = Styled.h2`
  ${portalTitleCommonStyle}
`;

const TitleRenderer = ({ title }) => <ScTitleRenderer>{t(title)}</ScTitleRenderer>;

TitleRenderer.propTypes = { title: string };
TitleRenderer.defaultProps = { title: '' };

export default TitleRenderer;
