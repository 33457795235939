import { requestLayer } from './requestLayer';

/**
 * Retrieves the payment connections for a specific resource.
 *
 * @returns A Promise that resolves to the payment connections for the specified resource.
 */
export const getPaymentConnections = ({
  resourceType,
  resourceId = null
}: {
  resourceType: string,
  resourceId?: string | null
}
): Promise<any> => {
  if (!resourceId || !resourceType) {
    return requestLayer.get('/API/payment/connection-list');
  }

  return requestLayer.get(`/API/payment/connection-list?resourceType=${resourceType}&resourceID=${resourceId}`);
};
