import React, {
  ChangeEvent,
  ComponentPropsWithRef, forwardRef, useEffect, useRef, useState
} from 'react';

import { IconLockFilled } from '@jotforminc/svg-icons';
import { BaseInput, BaseInputAddon } from './BaseInput';
import { InputColorProps, inputColorDefaultProps } from './input.types';
import { useFormControl } from '../../contexts';
import { ColorPicker } from '../ColorPicker';

export const InputColor = forwardRef((
  props: InputColorProps,
  ref?: ComponentPropsWithRef<'input'>['ref']
):JSX.Element => {
  const {
    disabled,
    size,
    readOnly,
    onChange,
    defaultValue,
    theme,
    value,
    pickerOptions,
    ...rest
  } = useFormControl(props);

  const inputColorPickerRef = useRef<HTMLInputElement>(null);
  const changeEvent = new Event('change', { bubbles: true });

  const defaultColor = defaultValue || value;
  const [colorValue, setColorValue] = useState(typeof defaultColor === 'string' ? defaultColor : '');
  const [input, setInput] = useState<HTMLInputElement | null>(null);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColorValue(e.target.value);
    if (onChange) onChange(e);
  };

  useEffect(() => {
    const inputNode = inputColorPickerRef.current
      ? (inputColorPickerRef.current?.parentNode as HTMLElement)?.firstChild as HTMLInputElement
      : null;
    setInput(inputNode);
  }, []);

  const handlePickerChange = (color: string) => {
    const inputVal = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');

    if (input) {
      inputVal?.set?.call(input, color);
      input.dispatchEvent(changeEvent);
    }

    setColorValue(color);
  };

  return (
    <>
      <BaseInput
        disabled={!!disabled}
        size={size}
        readOnly={!!readOnly}
        value={colorValue}
        theme={theme}
        onChange={handleOnChange}
        ref={ref}
        addonEnd={(
          <>
            {readOnly && (
              <BaseInputAddon
                icon={IconLockFilled}
                size={size}
                direction="end"
                theme={theme}
              />
            )}
            <div className="magnet-input-colorpicker flex items-center shrink-0 mr-2" ref={inputColorPickerRef}>
              <ColorPicker
                onChange={handlePickerChange}
                color={colorValue}
                disabled={readOnly || disabled}
                size={size}
                theme={theme}
                {...pickerOptions}
              />
            </div>
          </>
        )}
        {...rest}
      />
    </>
  );
});

InputColor.defaultProps = inputColorDefaultProps;
