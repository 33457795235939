import type { PAYMENT_FIELDS } from '@jotforminc/payment-constants';

/** utils */
import { requestLayer } from './requestLayer';
import { isEnterpriseInTeamEnv } from '../utils/functions';

/**
 * Fetches the connection statistics for a specified payment gateway.
 *
 * @returns {Promise<any>} A promise that resolves to the connection statistics.
 */
export const getHasConnectionStats = ({
  scope = 'user',
  gateway,
  nameApm
}: {
  scope?: 'user' | 'team' | 'org',
  gateway: PAYMENT_FIELDS,
  nameApm?: string | null
}): Promise<any> => {
  const postData = new FormData();
  if (isEnterpriseInTeamEnv()) {
    postData.append('scope', 'team');
  } else if (scope) {
    postData.append('scope', scope);
  }

  postData.append('gateway', gateway);
  if (nameApm) {
    postData.append('nameApm', nameApm);
  }

  return requestLayer.post('/API/payment/gateway-stats', postData);
};
