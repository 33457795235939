export const PRODUCT_LIST_QUESTION_ID = '3';
export const PRODUCT_LIST_LAYOUTS = ['HR', 'V2C', 'V3C'];
export const PAYMENTLISTSETTINGS_QUESTION_PROPS = ['useDecimal', 'decimalMark', 'currency'] as const;
export const OAUTH_SUPPORTED_PAYPAL_TYPES = ['control_paypalcomplete', 'control_paypalSPB', 'control_paypalInvoicing', 'control_Venmo'];
// Gateway list that determine currency after request
export const GATEWAYS_WITH_FIXED_CURRENCY = ['control_square'];

export const GATEWAYS_WITH_APM: {[key: string]: any} = {
  appleAndGooglePay: [
    {
      type: 'control_square',
      icon: 'https://cdn.jotfor.ms/assets/img/payments/square_in_apm.svg',
      supportedCurrencies: ['USD', 'EUR', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK', 'CHF', 'THB', 'TRY']
    },
    {
      type: 'control_stripeCheckout',
      icon: 'https://cdn.jotfor.ms/assets/img/payments/stripe_in_apm.svg',
      // eslint-disable-next-line max-len
      supportedCurrencies: ['USD', 'EUR', 'AED', 'AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'INR', 'JPY', 'LVL', 'LTL', 'MYR', 'MXN', 'NOK', 'NZD', 'PLN', 'RON', 'SEK', 'SGD', 'SKK']
    }
  ],
  Venmo: [
    {
      type: 'control_paypalcomplete',
      supportedCurrencies: ['USD']
    }
  ],
  CashApp: [
    {
      type: 'control_square',
      supportedCurrencies: ['USD']
    }
  ]
};

export const CHECKOUT_FORM_QUESTIONS = {
  FULL_NAME: 3,
  EMAIL: 4,
  PHONE: 5,
  SHIPPING_ADDRESS: 6,
  BILLING_ADDRESS: 8,
  MESSAGE: 9,
  GATEWAY: 11
};

export const CHECKOUT_FORM_REQUIRED_QIDS = ['3', '4'];

export const CHECKOUT_FORM_HIDDEN_QUESTION_IDS = ['2', '7', '10', '11', '13'];

export const CHECKOUT_FORM_HIDDEN_QUESTION_TYPES = ['control_pagebreak'];

// eslint-disable-next-line max-len
export const BILLING_REQUIRED_GATEWAYS = ['control_authnet', 'control_paypalpro', 'control_paysafe', 'control_iyzico', 'control_cardconnect', 'control_payjunction', 'control_cybersource', 'control_braintree'];

export const DEFAULT_PRODUCT_DATA = {
  name: 'Product Name',
  description: 'Product Description',
  images: JSON.stringify(['https://cdn.jotfor.ms/assets/img/payments/sample_image-4.png']),
  options: JSON.stringify([{
    type: 'quantity', properties: '1\n2\n3\n4\n5\n6\n7\n8\n9\n10', name: 'Quantity', defaultQuantity: '', specialPricing: false, specialPrices: '', expanded: false
  }]),
  price: 10,
  fitImageToCanvas: 'Yes' as const
};

export const DEFAULT_QUANTITY = {
  name: 'Quantity', start: '1', end: '10'
};
