import React, { useState, useCallback } from 'react';
import { CopyButton } from '@jotforminc/uikit';

const CopyUrl = ({
  url
} : IProps) : JSX.Element => {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = useCallback(() => {
    setShowCopied(true);
    setTimeout(() => { setShowCopied(false); }, 3000);
  }, [setShowCopied]);

  function handleSelectAllText({ target }): void {
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(target);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(target);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  return (
    <div className="pwCopyUrl">
      <span className='pwCopyUrl-text' onClick={handleSelectAllText}>{url}</span>
      <CopyButton
        className='pwCopyUrl-button'
        textToCopy={url}
        onCopy={handleCopy}
      >
        <span>{showCopied ? 'Copied!' : 'Copy URL'}</span>
      </CopyButton>
    </div>
  );
};

export interface IProps {
  url: string,
}

export default CopyUrl;
