import React from 'react';
import { func, string } from 'prop-types';
import { IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';

const CloseButtonRenderer = ({ onClose, colorStyle }) => {
  return (
    <Button
      rounded
      startIcon={IconXmark}
      colorStyle={colorStyle}
      aria-label={t('Close')}
      onClick={onClose}
    />
  );
};

CloseButtonRenderer.propTypes = {
  onClose: func,
  colorStyle: string
};

CloseButtonRenderer.defaultProps = {
  onClose: f => f,
  colorStyle: 'secondary'
};

export default CloseButtonRenderer;
