import classNames from 'classnames';
import React, {
  MouseEvent, FunctionComponent, useRef, useCallback, useEffect, useState
} from 'react';
import { t } from '@jotforminc/translation';
import { IconEyeFilled, IconPlus } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import { Hooks } from '@jotforminc/uikit';

import { closest } from '@jotforminc/utils';
import { IActionButtons, IQuestion, TPaymentType } from '../../../types/common';
import AddElementPopover from './AddElementPopover';
import { SortableWrapper } from '../../../utils';
import StyledSortableItem from '../../StyledSortableItem';
import { CHECKOUT_FORM_REQUIRED_QIDS } from '../../../constants/variables';
import { getFieldTitleByType } from './utils';
import { checkMobileOrTablet } from '../../../utils/domUtils';
import { Toggle } from '../../fields';

type FormSettingsEditorViewType = {
  onPreviewClick: (e: MouseEvent) => void,
  checkoutFormQuestions: IQuestion[],
  onAddElement: (fieldType: string) => void,
  onSortEnd: (oldIndex: number, newIndex: number) => void,
  actionButtons: IActionButtons,
  gatewayRequiredQuestionIds: string[],
  onUseFastCheckoutChange: (val: 'Yes' | 'No') => void,
  useFastCheckout: 'Yes' | 'No',
  isReusableConnectionEnabled: boolean,
  paymentType: TPaymentType
};

const FormSettingsEditorView: FunctionComponent<FormSettingsEditorViewType> = ({
  onPreviewClick,
  checkoutFormQuestions,
  onAddElement,
  onSortEnd,
  actionButtons,
  gatewayRequiredQuestionIds = [],
  onUseFastCheckoutChange,
  useFastCheckout,
  isReusableConnectionEnabled,
  paymentType
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const addElementButtonRef = useRef();
  const elementsPopoverRef = useRef();
  const [isElementsVisible, setIsElementsVisible] = Hooks.useClickOutsideState(false, [elementsPopoverRef, addElementButtonRef]);
  const [itemSettingsOpen, setItemSettingsOpen] = Hooks.useClickOutsideStateWithSelector(false, '.sortableWrapper');

  const showItemActions = useCallback((qid): void => {
    setSelectedItemId(qid);
    setItemSettingsOpen(true);
  }, []);

  useEffect(() => {
    if (!itemSettingsOpen) {
      setSelectedItemId(null);
    }
  }, [itemSettingsOpen]);

  const handleShouldSortingStart = useCallback((event: React.MouseEvent<EventTarget>) : boolean => {
    return (
      !!closest(event.target as HTMLElement, '.productItemActions', '.productItem')
      || !!closest(event.target as HTMLElement, '.actionButton', '.productItem')
    );
  }, []);

  const isMobileOrTablet = checkMobileOrTablet();

  return (
    <div className={classNames('checkoutFormQuestionsVisibilitySettings')}>
      {
        (isReusableConnectionEnabled && paymentType === 'product') && (
          <label
            labelFor="useFastCheckout"
            className='flex gap-2 w-full px-4 useFastCheckoutLabel'
          >
            <div className='flex flex-col'>
              <span className='formWizard-label-text'>{t('Express Checkout')}</span>
              <span className='formWizard-label-description'>{t('Users can order items quickly while not allowing to favorite items and revisit the shopping cart.')}</span>
            </div>
            <Toggle
              id="useFastCheckout"
              className="productEditorItem"
              valueTrue="Yes"
              valueFalse="No"
              onText="ON"
              offText="OFF"
              onChange={val => onUseFastCheckoutChange(val)}
              isSelected={useFastCheckout === 'Yes'}
            />
          </label>
        )
      }
      <div className="block mb-0 pt-7 pb-6 px-4 font-medium color-white">
        <div className={classNames('previewWizard-wrapper')}>
          <div className="row-title">{t('Contact Information Page')}</div>
          <div className="row-description">
            {t('Users will see this page during the checkout process.')}
          </div>
          <div className="previewField">
            <div className="previewField-hover">
              <Button
                onClick={onPreviewClick}
                startIcon={IconEyeFilled}
                colorStyle="primary"
                showTextOnly="xs"
              >
                {t('View Contact Information Page')}
              </Button>
            </div>
            <img
              src="https://cdn.jotfor.ms/assets/img/payments/panel_checkout_preview.png"
              alt="Checkout preview"
              className="previewImg object-cover"
            />
          </div>
        </div>
      </div>
      <hr className='lineHr' />
      <div className="flex flex-col justify-center items-center px-4 pt-7 text-center">
        <Button
          ref={addElementButtonRef}
          variant="filled"
          colorStyle="primary"
          startIcon={IconPlus}
          onClick={() => setIsElementsVisible(!isElementsVisible)}
          className="mb-6"
          fullWidth
        >
          {t('Add Element')}
        </Button>
        {
          isElementsVisible && (
            <AddElementPopover
              elementsPopoverRef={elementsPopoverRef}
              addElementButtonRef={addElementButtonRef}
              onAddElement={onAddElement}
              setIsElementsVisible={setIsElementsVisible}
            />
          )
        }
        <div className="w-full">
          <SortableWrapper
            onSortEnd={onSortEnd}
            shouldCancelStart={handleShouldSortingStart}
            helperClass='paymentSettingsEditorSortableHelper'
            useDragHandle={isMobileOrTablet}
            lockAxis='y'
          >
            {checkoutFormQuestions.map(({ name: _, ...elem }, idx) => {
              const filteredActionButtons = actionButtons.filter(item => item.type !== 'delete');
              return (
                <StyledSortableItem
                  key={elem.qid}
                  idx={idx}
                  item={{ ...elem, text: t(getFieldTitleByType(elem.type)), name: elem.text }}
                  itemSettingsOpen={itemSettingsOpen}
                  actionButtons={[...CHECKOUT_FORM_REQUIRED_QIDS, ...gatewayRequiredQuestionIds].includes(elem.qid) ? filteredActionButtons : actionButtons}
                  showItemActions={showItemActions}
                  selectedItemId={selectedItemId}
                />
              );
            })}
          </SortableWrapper>
        </div>
      </div>
    </div>
  );
};

export default FormSettingsEditorView;
