import { Moment } from '@jotforminc/jotform-common';
import { updateTitleWithNewResourceName } from '@jotforminc/utils';

import {
  FETCH_PORTAL,
  UPDATE_PORTAL,
  UPDATE_ORDER,
  UPDATE_ITEM_PROP,
  ADD_PORTAL_ITEMS,
  REMOVE_PORTAL_ITEMS,
  SET_API_REQUESTS_COMPLETED,
  UPDATE_MULTIPLE_ITEM, ADD_NEW_PAGE, DELETE_PAGE, UPDATE_PAGE, CHANGE_PAGE_ORDER, REPLACE_FORM_ITEM, APP_UPDATED
} from '../actionTypes';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { ITEM_TYPES } from '../../constants/itemTypes';
import { getPortalTypeByItems } from '../../constants';

const initialState = {
  items: [],
  pages: []
};

// todo ?
// eslint-disable-next-line complexity
const PortalReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PORTAL.SUCCESS:
    case UPDATE_PORTAL.REQUEST:
    case UPDATE_PORTAL.SUCCESS: {
      // Update document title
      // TODO: find a better way 🥲
      // When building with form, app name in the reducer is overrided
      // via the backend request response after fetching the newly created app.
      // Thats why this document update is here 😣
      // Maybe we can use directly the form title instead of 'New App' default
      // if the user comes to the app with buildWith param.
      if (
        action.payload?.title
        // This condition is needed otherwise the standalone app's title becomes
        // "My neat app - My neat app"
        && window.document.title !== action.payload?.title
      ) {
        updateTitleWithNewResourceName(action.payload.title);
      }

      // for username changes
      // temporarily disabled
      // if (window.JOTFORM_ENV !== 'ENTERPRISE') {
      //   normalizeIconURLs(action.payload);
      // }

      return {
        ...state,
        ...action.payload
      };
    }
    case UPDATE_ORDER.REQUEST: {
      return {
        ...state,
        items: action.payload.map(({ id, ...rest }) => {
          const origItem = state.items.find(f => f.id === id);
          return { ...origItem, ...rest };
        })
      };
    }
    case UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE:
    case UPDATE_ITEM_PROP.WITH_DEBOUNCE:
    case UPDATE_ITEM_PROP.REQUEST: {
      return {
        ...state,
        items: state.items?.map(item => {
          return (item.id === action.payload.itemID ? { ...item, ...action.payload.prop } : item);
        })
      };
    }
    case UPDATE_ITEM_PROP.SUCCESS:
    case UPDATE_MULTIPLE_ITEM.REQUEST:
    case UPDATE_MULTIPLE_ITEM.SUCCESS: {
      const updatedItems = action.payload;
      return {
        ...state,
        items: [...state.items].map(currentItem => {
          const { id: currentID } = currentItem;
          const updatedItem = updatedItems[currentID] || {};
          return {
            ...currentItem,
            ...updatedItem
          };
        })
      };
    }
    case ADD_PORTAL_ITEMS.REQUEST: {
      const { newItems } = action.payload;
      return {
        ...state,
        items: newItems
      };
    }
    case REPLACE_FORM_ITEM.SUCCESS: {
      const { itemID, form } = action.payload;

      return {
        ...state,
        items: state.items.map(item => (item.id === itemID ? form : item))
      };
    }
    case ADD_PORTAL_ITEMS.SUCCESS: {
      const addedItems = action.payload;
      const items = [...state.items].map(currentItem => {
        const { id: currentID, tempID: currentTempID, type: currentType } = currentItem;

        const finderFn = isFeatureEnabled(FEATURE_NAMES.FormResource)
          ? ({ tempID }) => tempID === currentTempID
          : ({ id, tempID }) => {
            return !currentID && tempID && currentType !== ITEM_TYPES.FORM
              ? tempID === currentTempID // Non-form app item, they have predefined tempIDs.
              : parseInt(id, 10) === parseInt(currentID, 10);
          };
        const newItem = addedItems.find(finderFn);

        const finalItem = { ...currentItem, ...newItem };
        return {
          ...finalItem,
          // next line is added to prevent consecutive adding elements success collision
          ...{ portalOrder: finalItem.portalOrder ? finalItem.portalOrder.toString() : '' }
        };
      });

      const presentationItems = addedItems.filter(addedItem => !!items.find(item => item.presentationItemID === addedItem.id));

      return {
        ...state,
        items: [...items, ...presentationItems],
        type: getPortalTypeByItems(addedItems)
      };
    }
    case REMOVE_PORTAL_ITEMS.SUCCESS: {
      const itemIDList = action.payload;
      return {
        ...state,
        items: itemIDList,
        type: getPortalTypeByItems(itemIDList)
      };
    }
    case SET_API_REQUESTS_COMPLETED: {
      const date = Moment.formatDate(new Date(), 'YYYY-MM-DD HH:mm ZZ', 'YYYY-MM-DD HH:mm:ss');
      return {
        ...state, updated_at: date, appUpdatedAt: date
      };
    }

    case APP_UPDATED: {
      return { ...state, appUpdatedAt: Date.now() };
    }

    case ADD_NEW_PAGE.REQUEST: {
      const { pages } = action.payload; // has tempID
      return {
        ...state,
        pages: [
          ...state.pages,
          ...pages
        ]
      };
    }
    case ADD_NEW_PAGE.SUCCESS: {
      const { pages } = action.payload;
      return {
        ...state,
        pages
      };
    }
    case CHANGE_PAGE_ORDER.SUCCESS: {
      return {
        ...state,
        pages: action.payload
      };
    }
    case DELETE_PAGE.REQUEST: {
      const { pageID } = action.payload;
      const newPages = state.pages.filter(p => p.id !== pageID);
      return {
        ...state,
        pages: newPages
      };
    }
    case DELETE_PAGE.SUCCESS: {
      const {
        items, pages
      } = action.payload;
      return {
        ...state,
        pages,
        items
      };
    }
    case UPDATE_PAGE.SUCCESS: {
      const updatedPages = action.payload;
      return {
        ...state,
        pages: [...state.pages].map(page => {
          const { id: pageID } = page;
          const updated = updatedPages[pageID] || {};
          return {
            ...page,
            ...updated
          };
        })
      };
    }
    default:
      break;
  }
  return state;
};

export default PortalReducer;
