import React, { useCallback } from 'react';
import { CheckoutFormVisibilitySettingsPanel } from '@jotforminc/payment-settings-editor';
import { useDispatch, useSelector } from 'react-redux';
import SELECTORS from '../../../../../../store/selectors';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';
import { MODALS } from '../../../../../../constants/modals';
import { FEATURE_NAMES } from '../../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../../utils/features/helper';
import { Loading } from '../../../../../../components/Loading';

const ContactInformationSettingsPanel = () => {
  const dispatch = useDispatch();
  const checkoutFormActiveGatewaySettings = useSelector(SELECTORS.getCheckoutFormActiveGatewaySettings);
  const checkoutFormVisibleQuestionsArray = useSelector(SELECTORS.getCheckoutFormVisibleQuestionsArray);
  const checkoutFormQuestions = useSelector(SELECTORS.getCheckoutFormQuestions);
  const paymentType = useSelector(SELECTORS.getPaymentType);
  const mappedAddressFieldQid = useSelector(SELECTORS.getPaymentQuestionMappedAddressFieldId);

  const handlePreviewClick = () => dispatch(ACTION_CREATORS.showGenericModalAction({ name: MODALS.CHECKOUT_FORM_PREVIEW_MODAL }));

  const handleCheckoutFormQuestionUpdate = useCallback((id, prop) => dispatch(ACTION_CREATORS.updateCheckoutFormQuestion(id, prop)), []);
  const handleUseFastCheckoutChange = value => dispatch(ACTION_CREATORS.updatePortalAction({ useFastCheckout: value }));
  const { useFastCheckout } = useSelector(SELECTORS.getPortalInfoSelector);
  const isReusableConnectionEnabled = useSelector(SELECTORS.getIsReusableConnectionEnabled);

  const handleAddElement = useCallback(type => dispatch(ACTION_CREATORS.addCheckoutFormQuestion({ type })), []);

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => dispatch(ACTION_CREATORS.sortCheckoutFormQuestions({ oldIndex, newIndex })), []);
  const handleRemoveElement = useCallback(qid => dispatch(ACTION_CREATORS.removeCheckoutFormQuestion(qid)), []);

  return paymentType ? (
    <CheckoutFormVisibilitySettingsPanel
      useFastCheckout={useFastCheckout}
      onUseFastCheckoutChange={handleUseFastCheckoutChange}
      isReusableConnectionEnabled={isReusableConnectionEnabled}
      onPreviewClick={handlePreviewClick}
      paymentType={paymentType}
      activeGatewaySettings={checkoutFormActiveGatewaySettings}
      checkoutFormQuestions={isFeatureEnabled(FEATURE_NAMES.ContactInformation) ? checkoutFormVisibleQuestionsArray : checkoutFormQuestions}
      onCheckoutFormQuestionUpdate={handleCheckoutFormQuestionUpdate}
      isContactInformationEnabled={isFeatureEnabled(FEATURE_NAMES.ContactInformation)}
      onAddElement={handleAddElement}
      onSortEnd={handleSortEnd}
      onRemoveElement={handleRemoveElement}
      gatewayRequiredQuestionIds={[mappedAddressFieldQid]}
    />
  ) : <Loading />;
};

export default ContactInformationSettingsPanel;
