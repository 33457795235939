import Styled from 'styled-components';

const ScPageSettingsItem = Styled.li`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
  user-select: none;

  .pageSettings-dragHandle {
    display: flex;
    cursor: grab;

    svg{
      height: 24px;
      fill: #C8CEED;
      margin-top: 8px;
    }
  }

  .taste {
    background-color: var(--jfv-panel-semidark-gray);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    color: #fff;
    padding: 0;

    svg { width: 17px; }

    .jfIconSVG-wrapper > div {
      width: 17px;
      height: 17px;
    }
  }

  .pageSettings {
    &-icon {
      flex-grow: 0;

      .pageSettingsIconBtn {
        display: flex;
        transition: background-color 0.1s ease;
        & > div {
          margin: 0;
          width: 17px;
          height: 17px;
        }

        &.change {
          &:hover {
            background-color: #7F859C;
            fill: #fff;
          }
          svg {
            fill: #fff;
          }
        }

        &.add {
          &:hover {
            background-color: #7F859C;
          }
          svg {
              fill: #BFC3CE;
            }
        }

        svg {
          width: 16px;
        }
      }
    }
  }

  .page-settings-popover {    
    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background: #252d5b;
      top: -6px;
      right: 17px;
    }

    &[data-popper-placement="top-end"]::after {
      top: auto;
      bottom: -6px;
    }
  }

  @media screen and (max-width: 480px) {
    &:last-child {
      padding-bottom: 40px;
    }
  }
`;

export default ScPageSettingsItem;
