import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';
import classNames from 'classnames';
import { IconXmark } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import { I_MODAL_HEADER } from '../Types';
import useGatewayFlows from '../Context/useGatewayFlows';
import { sendTrackData } from '../../../../utils';
import selectors from '../../../../store/selectors';

const Header: FunctionComponent<I_MODAL_HEADER> = ((headerProps: I_MODAL_HEADER) => {
  const {
    leftIcon, iconBackgroundColor, iconColor, title, description
  } = headerProps;
  const {
    closeModal,
    currentlyStep,
    changedSettings,
    resourceType,
    formId,
    setStep,
    setNextStepForDiscard
  } = useGatewayFlows();
  const username = useSelector(selectors.getUsername);

  return (
    <div className={classNames(
      'w-full flex justify-between items-center border-navy-50 px-6 sticky top-0 left-0 pt-6 bg-white z-2',
      {
        'border-b pb-6': currentlyStep !== 'discard-changes'
      }
    )}
    >
      <div className='flex justify-between items-center gap-3'>
        {leftIcon && (
          <div className={`flex shrink-0 justify-center items-center radius h-10 w-10 ${iconBackgroundColor} ${iconColor}`}>
            {leftIcon}
          </div>
        )}
        <div>
          {currentlyStep !== 'discard-changes' && (<h1 className="color-navy-700 text-md font-medium">{title}</h1>)}
          {description && (
            <h2 className="color-navy-300 text-sm">{description}</h2>
          )}
        </div>
      </div>
      <Button
        aria-label={t('Close Button')}
        className={`${currentlyStep === 'discard-changes' ? 'mt-2' : ''}`}
        onClick={() => {
          const hasChangedSettings = currentlyStep === 'select-gatewaySettings' && Object.keys(changedSettings).length > 0;
          if (hasChangedSettings) {
            setStep('discard-changes');
            setNextStepForDiscard(null);
          } else {
            sendTrackData(username, 'new_payment_modal_close_button_clicked', { resourceType, formId });
            closeModal();
          }
        }}
        startIcon={IconXmark}
        colorStyle='secondary'
        rounded
      />
    </div>
  );
});

export default Header;
