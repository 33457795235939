import React, { useCallback, useRef } from 'react';
import { func, string } from 'prop-types';
import Styled from 'styled-components';
import AppModal from '../../../../components/AppModal';
import ProductItemDetails from '../../ProductItemDetails';

const ScDetailModalOverlay = Styled.div`
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100%;
  z-index: 1002;
  background-color: rgba(39, 41, 55, 0.61);
  display: flex;
  flex-direction: column;
  align-items: center;

  div[data-uikit-modal-container="true"] {
    width: 92%;
    max-width: 674px;
    max-height: 90%;
    min-width: 335px;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: auto;
    overflow: auto;
  }
`;

const ProductItemDetailsModal = ({
  onClose,
  itemID,
  productID
}) => {
  const ref = useRef();

  const DialogRenderer = useCallback(({ children }) => (
    <ScDetailModalOverlay>{children}</ScDetailModalOverlay>
  ), []);

  const HeaderRenderer = () => null;

  return (
    <AppModal
      defaultVisible={true}
      DialogRenderer={DialogRenderer}
      HeaderRenderer={HeaderRenderer}
      onModalClose={onClose}
      ref={ref}
    >
      <ProductItemDetails
        onClose={onClose}
        itemID={itemID}
        productID={productID}
      />
    </AppModal>
  );
};

ProductItemDetailsModal.propTypes = {
  onClose: func,
  itemID: string.isRequired,
  productID: string.isRequired
};

ProductItemDetailsModal.defaultProps = {
  onClose: f => f
};

export default ProductItemDetailsModal;
