import React, { useEffect, useRef, useState } from 'react';
import {
  string, func, arrayOf, shape, bool
} from 'prop-types';
import { Popover, Utils } from '@jotforminc/uikit';

import { ScSuggestions, ScList } from './styles';

const SubMember = ({
  name, avatar, subMemberList
}) => {
  const [isListExpanded, setIsListExpanded] = useState(false);
  const targetRef = useRef();

  return (
    <>
      <div className="suggestion-avatar">
        {avatar}
      </div>
      <div className="suggestion-info">
        <div className="suggestion-name">{name}</div>
        <div className="suggestion-email-container">
          <div className="suggestion-email">
            {subMemberList.map(member => (member.email)).toString()}
          </div>
          <div
            className="expand-list"
            id='email'
            onMouseEnter={() => setIsListExpanded(true)}
            onMouseLeave={() => setIsListExpanded(false)}
            ref={targetRef}
          >
            {`+ ${subMemberList.length} More`}
          </div>
        </div>
        {isListExpanded && (
        <Popover
          targetRef={targetRef}
          usePortal={true}
          popoverOptions={{
            placement: 'bottom-end',
            modifiers: [
              { name: 'offset', options: { offset: [15, 25] } }
            ]
          }}
          style={{ zIndex: 100002 }}
        >
          <ScList>
            <div className='suggestion'>
              <div className="suggestion-teamlist">
                {subMemberList.map(member => {
                  return (
                    <div className='suggestion-teamitem'>
                      <div className="suggestion-avatar">
                        <div style={{ backgroundImage: `url(${member.avatar})` }} />
                      </div>
                      <div className="suggestion-info">
                        <div className="suggestion-name">{member.username}</div>
                        <div className="suggestion-email">{member.email}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ScList>
        </Popover>
        )}
      </div>
    </>
  );
};

SubMember.propTypes = {
  name: string.isRequired,
  avatar: string.isRequired,
  subMemberList: arrayOf(shape({
    name: string,
    email: string,
    avatarUrl: string
  })).isRequired
};

const UserList = ({
  list, onClick, useMouseDown, useKeyboardEvents, preventClickDefault, onBlurEventHandler
}) => {
  const containerRef = useRef();
  const tabIndex = useKeyboardEvents ? { tabIndex: 0 } : {};
  const keyHandler = event => {
    const relatedKeyCodes = [40, 38];
    // Don't handle if key is not arrowdown/arrowup
    if (relatedKeyCodes.indexOf(event.keyCode) === -1 || !containerRef.current) { return; }
    event.preventDefault();
    const { activeElement } = document;
    let nextElement;
    const ownElement = containerRef.current.contains(activeElement);
    if (ownElement && !activeElement.classList.contains('suggestion')) {
      return;
    }

    const direction = event.keyCode === 40 ? 'down' : 'up';
    switch (true) {
      case ownElement && direction === 'down' && !!activeElement.nextElementSibling:
        nextElement = activeElement.nextElementSibling;
        break;
      case ownElement && direction === 'up' && !!activeElement.previousElementSibling:
        nextElement = activeElement.previousElementSibling;
        break;
      default:
        nextElement = containerRef.current.querySelector('.suggestion'); // First Suggestion
        break;
    }
    nextElement.focus();
  };
  if (useKeyboardEvents) {
    useEffect(() => {
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    }, []);
  }
  return (
    <ScSuggestions className="suggestion-list" ref={containerRef}>
      {list.map(user => {
        const {
          name, email, avatarUrl, username, subMembers
        } = user;
        const handleClick = e => {
          // to prevent looping behavior with input onBlur
          if (preventClickDefault) {
            e.preventDefault();
          }
          return onClick(user);
        };
        const handleKeyDown = event => Utils.isPressedKeyEnterOrSpace(event) && onClick(user);

        const identifier = name || email || username;
        const avatar = avatarUrl
          ? <div style={{ backgroundImage: `url(${avatarUrl})` }} />
          : <div>{identifier[0]}</div>;

        const handler = {
          [useMouseDown ? 'onMouseDown' : 'onClick']: handleClick,
          onBlur: onBlurEventHandler
        };
        if (subMembers) {
          return (
            <div
              className='suggestion'
              key={email}
              {...handler}
              onKeyDown={handleKeyDown}
              {...tabIndex}
            >
              <SubMember
                name={name}
                avatar={avatar}
                subMemberList={subMembers}
                containerRef={containerRef}
              />
            </div>
          );
        }
        return (
          <div
            key={email}
            className="suggestion"
            {...handler}
            onKeyDown={handleKeyDown}
            {...tabIndex}
          >
            <div className="suggestion-avatar">
              {avatar}
            </div>
            <div className="suggestion-info">
              <div className="suggestion-name">{name}</div>
              <div className="suggestion-email">{email}</div>
            </div>
          </div>
        );
      })}
    </ScSuggestions>
  );
};

UserList.propTypes = {
  list: arrayOf(shape({
    name: string,
    email: string,
    avatarUrl: string
  })).isRequired,
  onClick: func.isRequired,
  useMouseDown: bool,
  useKeyboardEvents: bool,
  preventClickDefault: bool,
  onBlurEventHandler: func
};

UserList.defaultProps = {
  useMouseDown: false,
  useKeyboardEvents: false,
  preventClickDefault: false,
  onBlurEventHandler: f => f
};

export default UserList;
