import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import DocumentFileUpload from './DocumentFileUpload';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';

const RightPanelDocumentFileUpload = props => {
  const dispatch = useDispatch();
  const selectedItemID = useSelector(SELECTORS.getSelectedPortalItem);

  const handleChange = useCallback(data => {
    dispatch(ACTION_CREATORS.updateItemPropAction({
      itemID: selectedItemID,
      prop: { ...data, fileURL: `${data.fileURL}?serveInlineWithCache=1` }
    }));
  }, [selectedItemID]);

  return <DocumentFileUpload {...props} onChange={handleChange} />;
};

RightPanelDocumentFileUpload.propTypes = {
  fileType: string
};

RightPanelDocumentFileUpload.defaultProps = {
  fileType: ''
};

export default RightPanelDocumentFileUpload;
