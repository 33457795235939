import React from 'react';
import {
  oneOfType, arrayOf, func, string, node
} from 'prop-types';
import { classNames } from '@jotforminc/utils';

export default class Button extends React.Component {
  render() {
    const { children, className, ...props } = this.props;
    return (
      <button
        type="button"
        className={classNames('jfWizard-button', className)}
        {...props}
      >
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  onClick: func,
  className: string,
  children: oneOfType([
    arrayOf(node),
    node
  ])
};

Button.defaultProps = {
  onClick: f => f,
  className: null,
  children: null
};
