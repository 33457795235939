import React, { Suspense } from 'react';
import { Loading } from '@jotforminc/loading';
import { safeLazy } from '@jotforminc/safe-lazy-import';

const LibIconSelector = safeLazy(() => import(/* webpackChunkName: "LibIconSelector" */'./LibIconSelector'));

const IconSelector = props => {
  return (
    <Suspense fallback={<Loading />}>
      <LibIconSelector
        {...props}
      />
    </Suspense>
  );
};

export default IconSelector;
