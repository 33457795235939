import React from 'react';
import ReactDOM from 'react-dom';
import { handleCloneApp } from '../../core/handleCloneApp';
import { CLONE_APP_SETTINGS, CLONE_MODAL_PROPERTIES } from './constants';
import CloneModal from '../../../CloneModal';
import withActions from '../../core/jotformActions';

const CloneAppModal = withActions(CloneModal, 'portal');

const openCloneAppModal = ({
  id,
  title,
  portalRoot,
  user,
  portalItems,
  teamID
}) => {
  const formItems = portalItems.filter(({ type, buttonRole, buttonValue }) => (type === 'FORM')
      || (type === 'BUTTON' && buttonRole === 'form' && !!buttonValue));
  const isThereAnyForm = formItems.length > 0;

  const onClone = ({ title: changedTitle, cloneSetting, actionLogger }) => {
    return handleCloneApp({
      id,
      appTitle: changedTitle,
      withForm: !isThereAnyForm ? false : cloneSetting === CLONE_APP_SETTINGS.WITH_FORMS,
      portalRoot,
      user,
      formCount: formItems.length,
      actionLogger,
      teamID
    });
  };
  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      ReactDOM.unmountComponentAtNode(portalRoot);
    };
    ReactDOM.render((
      <CloneAppModal
        title={title}
        root={portalRoot}
        onClone={onClone}
        onClose={handleClose}
        isThereAnyForm={isThereAnyForm}
        {...CLONE_MODAL_PROPERTIES}
      />
    ), portalRoot);
  });
};

export default openCloneAppModal;
