import { Button } from '@jotforminc/magnet';
import {
  arrayOf, func, number, shape, string
} from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { t } from '@jotforminc/translation';
import { IconGearFilled, IconTrashFilled, IconAlignTextJustify } from '@jotforminc/svg-icons';

const isNewPanel = () => true;

const ScSchemaCard = styled.div`
  display: flex;
  border-radius: 2px;
  background-color: ${isNewPanel() ? '#434a60' : '#555F6E'};

  .schemaCard {
    &-dragHandleWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 7px 6px;
      background-color: #0099FF;
      border-radius: 2px 0px 0px 2px;
      cursor: move;

      svg {
        width: 18px;
        height: 18px;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
      }
    }

    &-content {
      display: flex;
      border: 1px solid #303339;
      border-radius: 0px 2px 2px 0px;
      padding: 16px;
      flex: 1;

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }

      &-image {
        & > img {
          max-width: unset;
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
        }
      }

      &-texts {
        flex: 1 1 auto;

        .title {
          font-weight: 500;
          color: #FFFFFF;
        }

        .desc {
          margin-top: 8px;
          color: ${isNewPanel() ? '#C8CEED' : '#AEB0B8'};
          font-size: 15px;
          ${isNewPanel() && `
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;`}
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        pointer-events: none;
        ${isNewPanel() ? `
            flex: 0 0 80px;
            justify-content: end;
            gap: 8px;
          }
        ` : `
          opacity: 0;
          flex: 0 0 96px;
          justify-content: space-between;
          margin-left: 8px;
          & > button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #303339;
            width: 45px;
            height: 45px;
            border-radius: 4px;

            & > svg { pointer-events: none; }
          }

          .settingsBtn {
            svg { width: 21px; }

            &:hover {
              background-color: #45484E;
            }

            &:active {
              background-color: #222429;
            }
          }

          .deleteBtn {
            svg { width: 20px; }

            &:hover {
              background-color: #FF4947;
            }

            &:active {
              background-color: #DF2D2B;
            }
          }
        `}
      }
    }
  }

  .schemaItemWrapper:hover & {
    .schemaCard {
      &-content {
        &-actions {
          opacity: 1;
          pointer-events: all;

          .isDragMove & {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

const SchemaCard = ({
  id, onChangeEditableID, onDelete, fields, itemIndex, name
}) => {
  const filteredFieldArray = useMemo(() => {
    return fields.reduce((prev, { type, value }) => ({
      ...prev,
      [type]: value
    }), {});
  }, [fields]);

  const handleOnEdit = useCallback(() => {
    onChangeEditableID(id);
  }, [id]);

  const handleOnDelete = useCallback(() => {
    onDelete(id);
  }, [id]);

  const Title = useCallback(() => {
    const value = filteredFieldArray.text;

    return (
      <div className="title">{value || t(`New ${name}`)}</div>
    );
  }, [filteredFieldArray, name]);

  const Definition = useCallback(() => {
    const value = filteredFieldArray.textarea;

    return (
      <div className="desc">{value}</div>
    );
  }, [filteredFieldArray]);

  const Image = useCallback(() => {
    const value = filteredFieldArray.image;

    return value ? (
      <>
        <img src={value} alt="schemaCard-img" />
      </>
    ) : null;
  }, [filteredFieldArray]);

  return (
    <ScSchemaCard className="schemaCard">
      <div className="schemaCard-dragHandleWrapper">
        <IconAlignTextJustify />
        <span>{itemIndex + 1}</span>
      </div>
      <div className="schemaCard-content">
        <div className="schemaCard-content-image">
          {Image && (<Image />)}
        </div>
        <div className="schemaCard-content-texts">
          <Title />
          <Definition />
        </div>
        <div className="schemaCard-content-actions">
          {
            isNewPanel()
              ? (
                <>
                  <Button
                    colorStyle='secondary' variant='filled' size='small'
                    startIcon={IconGearFilled}
                    onClick={handleOnEdit} className="settingsBtn"
                  />
                  <Button
                    colorStyle='secondary' variant='filled' size='small'
                    startIcon={IconTrashFilled}
                    onClick={handleOnDelete}
                  />
                </>
              ) : (
                <>
                  <Button onClick={handleOnEdit} className="settingsBtn">
                    <IconGearFilled />
                  </Button>
                  <Button onClick={handleOnDelete} className="deleteBtn">
                    <IconTrashFilled />
                  </Button>
                </>
              )
          }
        </div>
      </div>
    </ScSchemaCard>
  );
};
SchemaCard.propTypes = {
  id: number,
  name: string,
  fields: arrayOf(shape({})),
  itemIndex: number,
  onChangeEditableID: func,
  onDelete: func
};

SchemaCard.defaultProps = {
  id: 0,
  name: '',
  fields: [],
  itemIndex: 0,
  onChangeEditableID: f => f,
  onDelete: f => f
};

export default SchemaCard;
