import Fuse from 'fuse.js';
import { gatewayFieldToName } from '@jotforminc/payment-constants';

import type { CONNECTION_LIST_DATA, EXTENDED_CONNECTION_LIST_DATA } from '../../types';
import { sandboxGetDataConverter } from './sandboxGetDataConverter';

export const connectionListSearch = ({
  connectedId = null,
  connectionListData = [],
  searchText = null,
  includeGatewayNames = false,
  includeModeSearch = false
} : {
  connectedId: string | null
  connectionListData: EXTENDED_CONNECTION_LIST_DATA[] | null,
  searchText?: string | null
  includeGatewayNames?: boolean,
  includeModeSearch?: boolean,
}): CONNECTION_LIST_DATA[] => {
  if (!connectionListData) {
    return [];
  }

  if (connectedId) {
    const connectedIndex = connectionListData.findIndex(connection => connection.id === connectedId);
    if (connectedIndex !== -1) {
      const connectedItem = connectionListData.splice(connectedIndex, 1)[0];
      connectionListData.unshift(connectedItem);
    }
  }

  const sortedConnectionList = [...connectionListData].sort((a, b) => Number(b.inUse) - Number(a.inUse));

  if (!searchText) {
    return sortedConnectionList;
  }

  const addionalConnectionProps = sortedConnectionList.map(connection => {
    const connectionListClone = { ...connection };
    if (includeGatewayNames) {
      const names = [];
      names.push(gatewayFieldToName[connection.gateway]);
      if (connection.parent_gateway) {
        names.push(gatewayFieldToName[connection.parent_gateway]);
      }
      connectionListClone.gatewayName = names;
    }

    if (includeModeSearch) {
      connectionListClone.gatewayModeReadable = [sandboxGetDataConverter(connection.sandbox)];
    }

    return connectionListClone;
  });

  const searchableKeys = [{ name: 'title', weight: 0.3 }];
  if (includeGatewayNames) { searchableKeys.push({ name: 'gatewayName', weight: 0.3 }); }
  if (includeModeSearch) { searchableKeys.push({ name: 'gatewayModeReadable', weight: 0.3 }); }

  /**
   * Options for configuring the Fuse.js search algorithm.
   */
  const fuseOptions = {
    threshold: 0.3,
    tokenize: true,
    matchAllTokens: true,
    keys: searchableKeys
  };

  const fuseInit = new Fuse(addionalConnectionProps, fuseOptions);
  const result = fuseInit.search(searchText);

  if (result.length === 0) {
    return [];
  }

  return result.map(connection => {
    const connectionClone = { ...connection.item };
    delete connectionClone.gatewayName;
    delete connectionClone.gatewayModeReadable;
    return connectionClone;
  });
};
