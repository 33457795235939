import { arrayMoveImmutable } from 'array-move';
import max from 'lodash/max';
import { put, select } from 'redux-saga/effects';
import { UPDATE_ORDER } from '../actionTypes';
import SELECTORS from '../selectors';

export function* watchItemSorting(action) {
  const {
    oldIndex, newIndex, page = 'homepage', elementID, oldpage, allItems
  } = action.payload;

  if (allItems) {
    // resat&irem will handle this, this is temporary
    yield put({
      type: UPDATE_ORDER.UNDOABLE,
      payload: {
        data: allItems
      },
      dontStack: true
    });
    return;
  }

  const appItems = yield select(SELECTORS.getPortalItems);
  const pages = yield select(SELECTORS.getPages);

  const sortingItem = appItems.find(item => parseInt(item.id, 10) === parseInt(elementID, 10));
  const _oldIndex = parseInt(sortingItem.portalOrder, 10) - 1;
  let _newIndex = newIndex;

  const newPage = pages.find(pg => parseInt(pg.id, 10) === parseInt(page, 10));

  if (page && page !== 'homepage') {
    const oldPage = pages.find(pg => parseInt(pg.id, 10) === parseInt(sortingItem.page, 10));
    // _oldIndex = parseInt(sortingItem.portalOrder, 10) - 1;

    const pageItems = appItems.filter(item => parseInt(item.page, 10) === parseInt(page, 10));
    const sortedPageItems = pageItems.sort((x, y) => parseInt(x.portalOrder, 10) - parseInt(y.portalOrder, 10));
    if (sortedPageItems.length === 0) {
      const newPageOrder = parseInt(newPage.pageOrder, 10);
      const minPagesIDs = pages.filter(pg => parseInt(pg.pageOrder, 10) < newPageOrder).map(pg => parseInt(pg.id, 10));
      const minPagesItems = appItems.filter(item => minPagesIDs.includes(parseInt(item.page, 10)) || !item.page);
      const minPagesItemsPortalOrders = minPagesItems.map(item => parseInt(item.portalOrder, 10));
      const maxPortalOrder = max(minPagesItemsPortalOrders);

      _newIndex = maxPortalOrder - 1;
    } else {
      const lastItem = sortedPageItems[newIndex] || sortedPageItems[sortedPageItems.length - 1];
      const actualNewIndex = parseInt(lastItem.portalOrder, 10);

      if (sortedPageItems[newIndex] && (!oldPage || (parseInt(newPage.pageOrder, 10) > parseInt(oldPage.pageOrder, 10)))) {
        _newIndex = actualNewIndex - 2;
      } else {
        _newIndex = actualNewIndex - 1;
      }
    }
  }

  const ordered = arrayMoveImmutable(appItems, _oldIndex, _newIndex).map((item, index) => ({ ...item, index }));
  const apiObj = ordered.map(({ id, type }, index) => {
    const moveToPage = elementID && parseInt(id, 10) === parseInt(elementID, 10);
    const props = {
      id,
      type,
      portalOrder: `${index + 1}` // 1 based portalOrders
    };
    return moveToPage ? { ...props, page: (page || '') } : props;
  });

  const sortedItem = ordered.find(x => x.index === newIndex);
  const { id: sortedItemID } = sortedItem;
  const sortInfo = { sortedItemID, _oldIndex, _newIndex }; // For event tracking

  yield put({
    type: UPDATE_ORDER.UNDOABLE,
    payload: {
      data: apiObj, sortInfo, oldIndex, newIndex, page, elementID, oldpage
    }
  });
}
