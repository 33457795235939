import { IProductListSettings } from '../../types/common';
import * as actionTypes from '../actionTypes';

const productListSettingsReducer = (state: Partial<IProductListSettings> = {}, action: IActionType): Partial<IProductListSettings> => {
  switch (action.type) {
    case actionTypes.UPDATE_PRODUCT_LIST_SETTINGS:
      return {
        ...state,
        ...action.payload.productListSettingsItem
      };
    default:
      return state || {};
  }
};

interface IPayload {
  productListSettingsItem: Partial<IProductListSettings>
}

interface IActionType {
  type: string,
  payload: IPayload
}

export default productListSettingsReducer;
