/* eslint-disable max-len */
import React, {
  useMemo, useState, useEffect,
  useCallback
} from 'react';
import { useDebounce } from '@jotforminc/hooks';
import { Button, Flex } from '@jotforminc/magnet';
import { t, translationRenderer } from '@jotforminc/translation';
import { IconInfoCircleFilled, IconCheckCircleFilled } from '@jotforminc/svg-icons';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import { SCROLLABLE_CONTAINER_CLASSLIST } from '../Constants';

const RequiredSettingsIndicator = (): JSX.Element => {
  const {
    correctedPropNames,
    allRequiredPropNames,
    setSettingConfigModeState,
    resetSettingConfigModeState,
    settingConfigModeState,
    resource
  } = usePaymentPropsPanel();
  const [isSlideOut, setIsSlideOut] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const numberOfCorrected = correctedPropNames.length;
  const numberOfRequired = allRequiredPropNames.length;
  const isBothEqual = numberOfCorrected === numberOfRequired;
  const IndicatorIcon = isBothEqual ? IconCheckCircleFilled : IconInfoCircleFilled;
  const iconClass = isBothEqual ? 'fill-green-400' : 'fill-red-400';
  const textClass = isBothEqual ? 'color-green-500' : 'color-red-400';
  const scrollableEl = useMemo(() => {
    return resource ? document.querySelector(SCROLLABLE_CONTAINER_CLASSLIST[resource]) : null;
  }, [resource]);

  const handleShadow = () => {
    if (scrollableEl) {
      const atBottom = scrollableEl?.scrollTop >= (scrollableEl.scrollHeight - scrollableEl.offsetHeight);
      if (!atBottom !== hasShadow) { setHasShadow(!atBottom); }
    }
  };

  const handleShadowDebounced = useCallback(useDebounce(handleShadow, 200), [scrollableEl, hasShadow]);
  handleShadowDebounced();

  useEffect(() => {
    scrollableEl?.addEventListener('scroll', handleShadow);
    window.addEventListener('resize', handleShadow);
    return () => {
      scrollableEl?.removeEventListener('scroll', handleShadow);
      window.removeEventListener('resize', handleShadow);
    };
  }, [scrollableEl, hasShadow]);

  const onViewButtonClick = () => {
    let _index = settingConfigModeState.index;
    while (correctedPropNames.includes(allRequiredPropNames[_index])) {
      ++_index;
    }

    if (!allRequiredPropNames[_index]) {
      resetSettingConfigModeState();
      return;
    }

    const element = document.querySelector(`.paymentPropertyField.${allRequiredPropNames[_index]}`);
    if (element && scrollableEl) {
      scrollableEl.scroll({
        top: element.offsetTop - 300,
        behavior: 'smooth'
      });
    }

    setSettingConfigModeState({
      active: true,
      index: ++_index
    });
  };

  useEffect(() => { // reset settingConfigMode
    if (settingConfigModeState.active) {
      const timer = setTimeout(() => {
        resetSettingConfigModeState();
      }, 5000);
      return () => { clearTimeout(timer); };
    }
    return () => null;
  }, [settingConfigModeState]);

  useEffect(() => { // handling slide in-out anims
    const timer = setTimeout(() => {
      setIsSlideOut(isBothEqual);
    }, isBothEqual ? 2000 : 500);

    return () => { clearTimeout(timer); };
  }, [isBothEqual]);

  useEffect(() => { // reset config mode state if all corrected
    if (isBothEqual && settingConfigModeState.active) {
      resetSettingConfigModeState();
    }
  }, [isBothEqual, settingConfigModeState.active]);

  useEffect(() => { // reset config mode state if clicked outside of focused fields
    const handler = (e: Event) => {
      if (!settingConfigModeState.active) { return; }

      const target = e.target as HTMLElement;
      let parent = target.parentElement;

      if (parent) {
        const classlistToResetState = ['paymentConnectionSection', 'paymentPropertyField', 'form-currency-dropdown-container', 'paymentTypeDropdown'];
        while (parent && !Array.from(parent.classList).some(cx => classlistToResetState.includes(cx))) {
          parent = parent.parentElement;
        }

        if (parent) {
          const hasClass = Array.from(parent.classList).some(cx => allRequiredPropNames.includes(cx) && !correctedPropNames.includes(cx));
          if (!hasClass) {
            resetSettingConfigModeState();
          }
        }
      }
    };

    scrollableEl?.addEventListener('click', handler);
    return () => {
      scrollableEl?.removeEventListener('click', handler);
    };
  }, [settingConfigModeState.active, scrollableEl, allRequiredPropNames, correctedPropNames]);

  const ViewButton = useMemo(() => (
    <Button
      id="payment_view_button"
      colorStyle="error"
      className='text-capitalize xs:ml-auto w-20'
      onClick={onViewButtonClick}
      data-testid='viewbutton'
    >
      <span className="text-xs font-medium tracking-lg">{t('View')}</span>
    </Button>
  ), [settingConfigModeState, correctedPropNames, allRequiredPropNames]);

  const navigationBar = document.querySelector('.multi-page-navigation-bar') !== null;
  const allowShadow = useMemo(() => !window.showPaymentReusableMigrationWarning, []);

  const bgClass = isBothEqual ? 'bg-green-100' : 'bg-red-100';
  const shadowClass = hasShadow && allowShadow ? 'hasShadow' : '';
  return (
    <div className={`fixed bottom-0 requiredSettingsIndicator ${navigationBar ? 'bottom-16' : ''} ${isSlideOut ? 'anim slide-out-down' : 'anim slide-in-top'}`}>
      <Flex
        className={`px-4 py-3.5 gap-3 mx-2 radius-tr-lg radius-tl-lg ${bgClass} ${shadowClass}`}
        alignItems="center"
        {...(!isBothEqual && { justifyContent: 'between' })}
      >
        <IndicatorIcon className={`h-5 w-5 shrink-0 items-start ${iconClass}`} />
        <Flex
          className="gap-2"
          direction="column"
          justifyContent="between"
        >
          <span className={`${textClass} text-md tracking-md`}>{t('Configure required settings')}</span>
          <span className='color-gray-400 text-sm line-height-md tracking-lg'>
            {translationRenderer(`[1[${numberOfCorrected}]] of [2[${numberOfRequired}]] completed`)({
              renderer1: corrected => <span>{corrected}</span>,
              renderer2: all => <span>{all}</span>
            })}
          </span>
        </Flex>
        { !isBothEqual && (ViewButton) }
      </Flex>
    </div>
  );
};

export default RequiredSettingsIndicator;
