import React from 'react';
import { t } from '@jotforminc/translation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions
} from '@jotforminc/magnet';
import {
  IconPlugFilled, IconPlus, IconPencilLineFilled, IconArrowsRotate
} from '@jotforminc/svg-icons';

/** Context */
import { useGatewayConnection } from '../../../context';

/** Components */
import { ModalStepsRenderer } from './modalStepsRenderer';
import { ModalFooter } from './modalFooter';
import { DiscardProcessInformation } from './discardProcessInformation';

export const ModalContainer = (): any => {
  const {
    isOpen,
    closeModal,
    currentlyStep,
    currentlyFlow,
    connectionState,
    showOrHideDiscardNewConnectionDialog,
    currentlyGatewayProperties
  } = useGatewayConnection();

  if (!isOpen) {
    return null;
  }

  const getTitle = () => {
    switch (true) {
      case currentlyFlow === 'EDIT_CONNECTION':
        return t('Edit Connection');
      case currentlyFlow === 'RE_CONNECT':
        return t('Reconnect');
      case currentlyStep === 'gatewayPicker':
        return t('Select Payment Gateway');
      case currentlyStep === 'gatewayConnection':
      case currentlyStep === 'apmGatewayPicker':
        if (currentlyGatewayProperties) {
          const { name, gateway_type: gatewayType } = currentlyGatewayProperties;

          if (gatewayType === 'control_applePayGooglePay') {
            return t('Add Apple Pay & Google Pay Connection');
          }

          return t(`Add ${name} Connection`);
        }
        return 'Add Connection';
      default:
        return t('Payment Connections');
    }
  };

  const getDescription = () => {
    switch (true) {
      case currentlyFlow === 'EDIT_CONNECTION':
        return t('Change your payment connection details');
      case currentlyFlow === 'RE_CONNECT':
        return t('Update your payment connection details');
      case currentlyStep === 'gatewayPicker':
        return t('Choose a payment gateway to create a connection');
      case currentlyStep === 'connectionPicker':
        return t('Select a payment connection to start collecting online payments');
      case currentlyStep === 'gatewayConnection':
      case currentlyStep === 'apmGatewayPicker':
        if (currentlyGatewayProperties) {
          const { name, gateway_type: gatewayType } = currentlyGatewayProperties;

          if (gatewayType === 'control_applePayGooglePay') {
            return t('Connect with Apple Pay & Google Pay to start collecting online payments');
          }

          return t(`Connect with ${name} to start collecting online payments`);
        }
        return t('Collect online payments with reusable payment connections');
      default:
        return t('Collect online payments with reusable payment connections');
    }
  };

  const getIcon = () => {
    switch (true) {
      case currentlyFlow === 'EDIT_CONNECTION':
        return IconPencilLineFilled;
      case currentlyFlow === 'RE_CONNECT':
        return IconArrowsRotate;
      case currentlyStep === 'gatewayPicker':
      case currentlyStep === 'gatewayConnection':
      case currentlyStep === 'apmGatewayPicker':
        return IconPlus;
      default:
        return IconPlugFilled;
    }
  };

  const handleOnClose = (event?: object | boolean) => {
    if (typeof event === 'boolean' && event === false) {
      return;
    }

    if (currentlyStep === 'gatewayConnection' && connectionState === 'connected') {
      showOrHideDiscardNewConnectionDialog('backOrClose');
      return;
    }
    closeModal();
  };

  return (
    <>
      <Modal
        ariaLabel={getTitle()}
        onClose={handleOnClose}
        open={isOpen}
        id='gateway-connection-modal-container'
        size='large'
        fitOnMobile={true}
      >
        <ModalHeader
          title={getTitle()}
          description={getDescription()}
          icon={getIcon()}
          iconColorStyle="primary"
          onClose={handleOnClose}
        />
        <ModalBody tabIndex={0}>
          <ModalStepsRenderer />
        </ModalBody>
        <ModalActions>
          <ModalFooter />
        </ModalActions>
      </Modal>
      <DiscardProcessInformation />
    </>
  );
};
