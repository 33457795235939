import React from 'react';
import { func, string } from 'prop-types';

import { toReadable } from './helper';

const FontPickerHeader = ({ onClick, selected }) => (
  <button
    type="button"
    className="jfc-font-picker-header"
    onClick={onClick}
  >
    <p>
      {selected.length < 1 ? 'Select a font' : toReadable(selected)}
    </p>
    <div>
      <span />
    </div>
  </button>
);

FontPickerHeader.propTypes = {
  onClick: func,
  selected: string
};

FontPickerHeader.defaultProps = {
  onClick: f => f,
  selected: ''
};

export default FontPickerHeader;
