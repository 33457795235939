import { Button } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import {
  arrayOf, func, number, shape
} from 'prop-types';
import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { getFieldComponent } from './getFieldComponent';

const ScSchemeEditable = styled.div`
  .schemaEditable {
    display: flex;
    border-radius: 2px;
    background-color: #555F6E;

    &-dragHandleWrapper {
      padding: 7px;
      background-color: #0099FF;
      border-radius: 2px 0px 0px 2px;
      flex: 0 0 29px;
    }

    &-content {
      border: 1px solid #303339;
      border-radius: 0px 2px 2px 0px;
      padding: 16px;
      flex: 1 1 auto;
      min-width: 0;

      &-field {
        margin-top: 20px;

        &:first-of-type {
          margin-top: unset;
        }

        label { 
          display: block;
        }

        input {
          width: 100%;
          border: 1px solid #303339;
          background-color: #626E81;
          box-shadow: none;
          padding: 9px 8px;
        }

        textarea {
          resize: none;
          outline: none;
          background-color: #626E81;
          color: #fff;
          width: 100%;
          border: 1px solid #303339;
          border-radius: 2px;
          padding: 9px 12px 22px 9px;
          font-size: 16px;
          font-family: inherit;
          height: 110px;
        }
      }

      &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
  
        & > button {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          text-transform: uppercase;
          padding: 10px 15px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
        }
  
        .closeBtn { background-color: #303339; }
        .saveBtn { background-color: #78BB07; }
      }
    }
  }
`;

const SchemaEditable = ({
  id, fields, onSave, onCancel, onChangeEditableID
}) => {
  const [value, setValue] = useState(fields);

  const handleClose = useCallback(() => {
    onChangeEditableID('');
    onCancel();
  }, [onChangeEditableID, onCancel]);

  const handleOnChange = (type, label) => data => {
    const newValue = [];
    value.forEach(val => {
      newValue.push(val.label !== label ? val : { type, label, value: data });
    });
    setValue(newValue);
  };

  const handleOnSave = useCallback(() => {
    onSave({ id, fields: value });
    handleClose();
  }, [value, id, onSave, handleClose]);

  return (
    <ScSchemeEditable>
      <div className="schemaEditable">
        <div className="schemaEditable-dragHandleWrapper" />
        <div className="schemaEditable-content">
          {value.map(fieldProps => {
            const { type, label, ...rest } = fieldProps;
            const FieldComponent = getFieldComponent(type);

            return (
              <div key={`field_${type}_${label}`} className="schemaEditable-content-field">
                <label htmlFor={label}>{t(label)}</label>
                <FieldComponent id={label} onChange={handleOnChange(type, label)} {...rest} />
              </div>
            );
          })}
          <div className="schemaEditable-content-actions">
            <Button onClick={handleClose} className="closeBtn">{t('Cancel')}</Button>
            <Button onClick={handleOnSave} className="saveBtn">{t('Save')}</Button>
          </div>
        </div>
      </div>
    </ScSchemeEditable>
  );
};

SchemaEditable.propTypes = {
  id: number,
  fields: arrayOf(shape({})),
  onSave: func,
  onCancel: func,
  onChangeEditableID: func
};

SchemaEditable.defaultProps = {
  id: 0,
  fields: [],
  onSave: f => f,
  onCancel: f => f,
  onChangeEditableID: f => f
};

export default SchemaEditable;
