import React from 'react';
import {
  bool, elementType, func, shape, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { ImageUpload as CommonFileUpload } from '@jotforminc/image-upload';
import { ScFileUpload } from './scFileUpload';
import Thumbnail from './Thumbnail';
import FileInput from './FileInputRenderer';
import FileLoadingRenderer from './FileLoadingRenderer';

const PortalFileUpload = ({
  onFileUpload, onFileRemove, onFileUploading, theme, isFileUploading, value, onFileUploadError, TextWrapperRenderer,
  inputButtonText, allowedFileTypes, allowImageUpload
}) => {
  return (
    <ScFileUpload>
      <CommonFileUpload
        value={value.url ? value : undefined}
        onImageUpload={val => onFileUpload(val)}
        renderTabs={['upload']}
        theme={theme}
        useAPI={true}
        forceSelect={false}
        allowedTypes={allowedFileTypes}
        allowImageUpload={allowImageUpload}
        onFileUploading={val => onFileUploading(val)}
        onFileRemove={() => onFileRemove()}
        isFileUploading={isFileUploading}
        removeButtonText={t('Remove File')}
        PreviewThumbnail={Thumbnail}
        FileInputRenderer={FileInput}
        mode="FileUpload"
        onFileUploadError={onFileUploadError}
        {...!isFileUploading && {
          TextWrapperRenderer: TextWrapperRenderer
        }}
        inputButtonText={inputButtonText}
        ImageLoadingScreenRenderer={FileLoadingRenderer}
      />
    </ScFileUpload>
  );
};

PortalFileUpload.propTypes = {
  onFileUpload: func,
  onFileRemove: func,
  onFileUploading: func,
  theme: string,
  isFileUploading: bool,
  value: shape({
    name: string,
    type: string,
    url: string,
    size: string
  }),
  onFileUploadError: func,
  TextWrapperRenderer: elementType,
  inputButtonText: string,
  allowedFileTypes: string,
  allowImageUpload: bool
};

PortalFileUpload.defaultProps = {
  onFileUpload: f => f,
  onFileRemove: f => f,
  onFileUploading: f => f,
  theme: 'isDark',
  isFileUploading: false,
  value: {},
  onFileUploadError: f => f,
  TextWrapperRenderer: undefined,
  inputButtonText: undefined,
  allowedFileTypes: '',
  allowImageUpload: false
};

export default PortalFileUpload;
