import React from 'react';
import { renderable } from '../../../constants/propTypes';

const ContainerRenderer = ({ children }) => <ul className="dropdown-container">{children}</ul>;

ContainerRenderer.propTypes = {
  children: renderable.isRequired
};

export default ContainerRenderer;
