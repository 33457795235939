import React from 'react';
import { func, shape } from 'prop-types';
import Styled from 'styled-components';

const ScFilteredProductList = Styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  .search-productList {
    color: #343c6a66;

    &.hasProduct {
      color: #343C6A;
      cursor: pointer;
    }
  }

  .search-productCount {
    display: flex;
    width: 16px;
    color: #6F76A7;
    justify-content: center;
  }
`;

const FilteredProductList = ({ productList, onSaveFilteredProducts }) => {
  const {
    title,
    itemID,
    products
  } = productList;

  const productCountsWilBeShown = products.length;

  const handleProductListClick = () => {
    if (productCountsWilBeShown) {
      onSaveFilteredProducts(itemID, products);
    }
  };

  const searchProductCount = productCountsWilBeShown > 0 ? 'hasProduct' : '';

  return (
    <ScFilteredProductList key={itemID} onClick={handleProductListClick}>
      <div className={`search-productList ${searchProductCount}`}>{title}</div>
      <div className="search-productCount">{productCountsWilBeShown}</div>
    </ScFilteredProductList>
  );
};

FilteredProductList.propTypes = {
  productList: shape({}),
  onSaveFilteredProducts: func
};

FilteredProductList.defaultProps = {
  productList: {},
  onSaveFilteredProducts: f => f
};

export default FilteredProductList;
