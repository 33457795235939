import React from 'react';
import { shape } from 'prop-types';

const Form = ({ style, ...props }) => (
  <form
    style={{ textAlign: 'center', ...style }}
    {...props}
  />
);

Form.propTypes = {
  style: shape({})
};

Form.defaultProps = {
  style: {}
};

export default Form;
