import React, { useMemo } from 'react';
import { objectOf, bool } from 'prop-types';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { filterObjectKeysByValue } from '@jotforminc/utils';

const FeatureTogglesComponent = ({ features, ...props }) => {
  const normalizedFeatures = useMemo(() => filterObjectKeysByValue(features), [features]);
  return (
    <FeatureToggles features={normalizedFeatures} {...props} />
  );
};

FeatureTogglesComponent.propTypes = {
  features: objectOf(bool).isRequired
};

export default FeatureTogglesComponent;
