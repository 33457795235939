import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control PayPal Checkout gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlPaypalSPB: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_paypalSPB',
  name: gatewayFieldToName.control_paypalSPB,
  supportedCurrencies: gatewaySupportedCurrencies.control_paypalSPB,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_paypalSPB,
  sortByCountry: gatewayListSortedByCountry.control_paypalSPB,

  /** APM's */
  isApm: false,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'oauth',
    allowSwitchMode: true,
    mode: {
      name: 'environment',
      options: [
        { value: 'sandbox', text: 'Test Mode', mappedValue: '1' },
        { value: 'production', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    propReferences: {
      merchantId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Merchant ID' } },
      clientID: { isSecret: 'No', isRequired: 'No', inputLabels: { label: 'Client ID' } },
      clientSecret: { isSecret: 'Yes', isRequired: 'No', inputLabels: { label: 'Client Secret' } },
      environment: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['merchantId']
  }
};
