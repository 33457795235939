import React from 'react';
import {
  elementType, shape, string, func
} from 'prop-types';
import DefaultImageRenderer from '../ProductCartItem/ImageRenderer';
import CartItemContentRenderer from '../ProductCart/Cart/CartItemContentRenderer';
import { checkMobilePhone } from '../../../utils';

const SimpleProductItem = ({
  product,
  ImageRenderer,
  ContentRenderer,
  totalAmount,
  optionsValue,
  OptionsRenderer,
  onChange,
  ActionButtonRenderer
}) => {
  const {
    pid: productID, options, images, name
  } = product;
  const isMobile = checkMobilePhone();

  const { fitImageToCanvas } = product;

  return (
    <div className="simple-product-item">
      <ImageRenderer fitImageToCanvas={fitImageToCanvas} images={images} />
      {
        !isMobile ? (
          <ContentRenderer
            totalAmount={totalAmount}
            name={name}
            optionsValue={optionsValue}
            OptionsRenderer={OptionsRenderer}
          />
        ) : (
          <div className="product-content-mobile-wrapper">
            <ContentRenderer
              totalAmount={totalAmount}
              name={name}
              optionsValue={optionsValue}
              OptionsRenderer={OptionsRenderer}
            />
            <div className="product-cart-actions">
              <OptionsRenderer
                options={options}
                onChange={onChange}
              />
              <ActionButtonRenderer productID={productID} />
            </div>
          </div>
        )
      }
    </div>
  );
};

SimpleProductItem.propTypes = {
  product: shape({}),
  ImageRenderer: elementType,
  ContentRenderer: elementType,
  totalAmount: string,
  optionsValue: string,
  OptionsRenderer: elementType,
  ActionButtonRenderer: elementType,
  onChange: func
};

SimpleProductItem.defaultProps = {
  product: {},
  ImageRenderer: DefaultImageRenderer,
  ContentRenderer: CartItemContentRenderer,
  OptionsRenderer: props => <div {...props} />,
  ActionButtonRenderer: elementType,
  totalAmount: '',
  optionsValue: '',
  onChange: f => f
};

export default SimpleProductItem;
