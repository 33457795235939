import React, {
  useState, forwardRef, FC, ChangeEvent, ReactNode, KeyboardEvent, useRef, useEffect, useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
// eslint-disable-next-line import/no-unresolved
import { arrayMove } from '@jotforminc/utils';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useDebounce } from '@jotforminc/hooks';
import isNil from 'lodash/isNil';

import { IconGridDotsVertical } from '@jotforminc/svg-icons';
import Selectors from '../../store/selectors';
import * as actionCreators from '../../store/actionCreators';
import { ICustomOption, IQuantityOption } from '../../types/common';
import { updateProduct } from '../../api';
import {
  arrayToString, stringToArray, changeInArray
} from '../../utils/general';
import { SortableDragHandle, getActiveProduct } from '../../utils';

const getDropdownOptions = () => (
  [
    { id: '', value: 'none', text: t('None') },
    { id: 'color', value: 'color', text: t('Color') },
    { id: 'tshirtSize', value: 'tshirtSize', text: t('T-Shirt Size') },
    { id: 'printSize', value: 'printSize', text: t('Print Size') },
    { id: 'screenResolution', value: 'screenResolution', text: t('Screen Resolution') },
    { id: 'shoeSize', value: 'shoeSize', text: t('Shoe Size') }
  ]
);

const getPresetOptions = (currPreset: string): {[key: string]: any} => {
  switch (currPreset) {
    case 'color':
      return {
        optionTemplate: 'color',
        name: t('Color'),
        properties: [t('Red'), t('Blue'), t('Green'), t('Yellow'), t('Magenta')],
        specialPrices: []
      };
    case 'tshirtSize':
      return {
        optionTemplate: 'tshirtSize',
        name: t('Size'),
        properties: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        specialPrices: []
      };
    case 'printSize':
      return {
        optionTemplate: 'printSize',
        name: t('Print size'),
        properties: ['A4', 'A3', 'A2', 'A1'],
        specialPrices: []
      };
    case 'screenResolution':
      return {
        optionTemplate: 'screenResolution',
        name: t('Screen resolution'),
        properties: ['1024x768', '1152x864', '1280×768', '1280×800', '1280×960', '1280×1024', '1366×768', '1440×900', '1600×1200', '1680×1050', '1920×1080', '1920×1200'],
        specialPrices: []
      };
    case 'shoeSize':
      return {
        optionTemplate: 'shoeSize',
        name: t('Shoe Size'),
        properties: ['8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '13', '14'],
        specialPrices: []
      };
    default:
      return {};
  }
};

type ButtonProps = {
  isOptionsVisible: boolean
};

const getFromPreset = (key: string, currPreset = 'color') => getPresetOptions(currPreset)[key];

const isInOption = (properties: string, val: string): boolean => stringToArray(properties).indexOf(val) > -1;

const handleOptionData = (properties: string | string[], name = ''): ICustomOption => ({
  type: 'custom',
  name,
  properties: Array.isArray(properties) ? arrayToString(properties) : properties,
  defaultQuantity: '',
  expanded: false
});

const SortElement = SortableElement(({ children }: { children: ReactNode }) => (
  <div className="productOptionCreatorTabEditor-optionList-item">{children}</div>
));

const SortContainer = SortableContainer(({ children }: { children: ReactNode }) => (
  <div className="productOptionCreatorTabEditor-optionList-cont">{children}</div>
));

const ProductOptionCreatorTabEditor: FC = () => {
  const dispatch = useDispatch();
  const formId = useSelector(Selectors.getFormId);
  const activeProduct = useSelector(Selectors.getActiveProduct);
  const selectedOption = useSelector(Selectors.getSelectedOption);
  const productOptions = getActiveProduct(activeProduct);

  const [currPreset, setCurrPreset] = useState('');
  const [optionChange, setOptionChange] = useState(false);
  const [option, setOption] = useState<ICustomOption>(handleOptionData(
    // BUGFIX #4128373 :: We can't use selectedOption for if condition, because zero index would be evaluated to zero. We can check if it is null or undefined.
    !isNil(selectedOption) ? productOptions[selectedOption]?.properties : arrayToString(getFromPreset('properties')),
    typeof selectedOption === 'number' ? productOptions[selectedOption]?.name : ''
  ));
  const [hasSameOption, setHasSameOption] = useState(false);
  const newOptionRef = useRef<HTMLInputElement>(null);

  const handleGoBack = () => {
    dispatch(actionCreators.changeActiveEditor('productEditor'));
    dispatch(actionCreators.changeSelectedOption(null));
  };

  const saveChanges = (newOption: ICustomOption) => {
    let updatedOptions;

    if (typeof selectedOption === 'number') {
      updatedOptions = changeInArray(productOptions, selectedOption, newOption) as (IQuantityOption | ICustomOption)[];
    } else {
      updatedOptions = [...productOptions, newOption];
      dispatch(actionCreators.changeSelectedOption(updatedOptions.length - 1));
    }

    updateProduct(formId, activeProduct.pid, { options: JSON.stringify(updatedOptions) });
    dispatch(actionCreators.updateProduct({ options: updatedOptions }, activeProduct.pid));
    dispatch(actionCreators.updateActiveProduct({ options: updatedOptions }));
  };

  const debouncedSaveOption = useCallback(useDebounce(saveChanges, 250), []);

  useEffect(() => {
    debouncedSaveOption(option);
  }, [option]);

  return (
    <div className="productOptionCreatorTabEditor">
      <div className="productOptionCreatorTabEditor-header section-title">
        <span>{t('Product Option')}</span>
      </div>
      <div className="productOptionCreatorTabEditor-label">
        <span className="row-title">{t('Label')}</span>
        <input
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setOption({ ...option, name: e.target.value });
          }}
          placeholder={t('e.g. Color')}
          value={option.name}
        />
      </div>
      <div className="productOptionCreatorTabEditor-populateSection">
        <span className="row-title">{t('Populate Options from Presets')}</span>
        <div className="dropdownWrapper">
          <Dropdown
            options={getDropdownOptions()}
            ButtonRenderer={forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
              <button
                ref={ref}
                type="button"
                className="dropdown-button"
                {...props}
              >
                <span className="dropdown-text">
                  {currPreset ? getFromPreset('name', currPreset) : t('None')}
                </span>
                <div className="dropdown-arrow-Wrapper">
                  <span className="dropdown-arrow" />
                </div>
              </button>
            ))}
            popoverProps={{ popoverOptions: { placement: 'bottom-start' } }}
            onOptionSelect={({ id = '', text = '' }) => {
              if (currPreset === id) return;
              setCurrPreset(id);
              const newOption = handleOptionData(arrayToString(getFromPreset('properties', id || 'color')), id ? text : '');
              setOption(newOption);
              if (hasSameOption) {
                setHasSameOption(false);
              }
            }}
            defaultValue={currPreset}
          />
        </div>
        <SortContainer
          helperClass="onDrag"
          onSortEnd={({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
            const newOption = handleOptionData(arrayMove(stringToArray(option.properties), oldIndex, newIndex), option.name);
            setOption(newOption);
          }}
          useDragHandle
          lockAxis="y"
        >
          {stringToArray(option.properties).map((opt, i) => (
            <SortElement
              index={i}
              // eslint-disable-next-line react/no-array-index-key
              key={`opt-${i}-${currPreset}`}
            >
              {stringToArray(option.properties).length > 1 && (
                <SortableDragHandle>
                  <IconGridDotsVertical className="productOptionCreatorTabEditor-optionList-drag" />
                </SortableDragHandle>
              )}
              <input
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setOptionChange(true);
                  const optionProperties = e.target.value.length > 0
                    ? changeInArray(stringToArray(option.properties), i, e.target.value)
                    : stringToArray(option.properties).filter((_, index) => i !== index);
                  const newOption = handleOptionData(optionProperties as string[], option.name);
                  setOption(newOption);
                }}
                {...((typeof selectedOption === 'number' || optionChange || currPreset || getFromPreset('properties', currPreset || 'color').indexOf(opt) === -1)
                  ? { defaultValue: opt }
                  : { placeholder: opt })
                }
              />
            </SortElement>
          ))}
        </SortContainer>
        <div className="productOptionCreatorTabEditor-addItemCont">
          <input
            type="text"
            placeholder={t('Enter new option')}
            ref={newOptionRef}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.target.setAttribute('filled', e.target.value.length > 0 ? 'true' : 'false');
              setHasSameOption(isInOption(option.properties, e.target.value));
            }}
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              if ((e.key === 'Enter' || e.keyCode === 13) && target.value.length > 0 && !isInOption(option.properties, target.value)) {
                setOption(handleOptionData([...stringToArray(option.properties), target.value], option.name));
                target.value = '';
                newOptionRef.current?.setAttribute('filled', 'false');
              }
            }}
          />
          <Button
            className="addBtn text-capitalize"
            disabled={hasSameOption}
            onClick={() => {
              if (newOptionRef.current && !hasSameOption) {
                const newOption = handleOptionData([...stringToArray(option.properties), newOptionRef.current.value], option.name);
                setOption(newOption);
                newOptionRef.current.value = '';
                newOptionRef.current.setAttribute('filled', 'false');
              }
            }}
          >
            {t('Add')}
          </Button>
        </div>
      </div>
      {hasSameOption && (
        <div className="productOptionCreatorTabEditor-addItemCont-error">{t('This item already exists in the list')}</div>
      )}
      <Button className="text-capitalize" onClick={handleGoBack}>{t('Go back')}</Button>
    </div>
  );
};

export default ProductOptionCreatorTabEditor;
