import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import PropTypes from 'prop-types';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import IframeRenderer from '../ProductRenderer/Renderers/IframeRenderer';
import {
  useFormMessageSender,
  useFormSubmissionCompleteHandler,
  useFormMessageHandler,
  useFormSubmissionPendingCaptchaHandler,
  useFormSubmissionErrorHandler,
  useFormSubmissionPendingPaymentHandler,
  useFormattedPrice
} from '../../../utils/hooks';
import { trackEventAction, setCheckoutFormStatus } from '../../../store/actionCreators';
import { CHECKOUT_FORM_STATUSES } from '../../../constants';
import SELECTORS from '../../../store/selectors';
import { ScCircleLoader, CircleLoaderSvg } from './ScCircleLoader';
import ScCheckoutFormRenderer from './ScCheckoutFormRenderer';
import CheckoutFormHeader from './CheckoutFormHeader';

const CheckoutFormRenderer = ({ previewMode }) => {
  const user = useSelector(SELECTORS.getUser);
  const checkoutFormURL = useSelector(SELECTORS.getCheckoutFormURLSelector);
  const checkoutFormID = useSelector(SELECTORS.getCheckoutFormIDSelector);
  const checkoutKey = useSelector(SELECTORS.getCheckoutKey);
  const cartTotal = useSelector(SELECTORS.getCartTotal);
  const cartProductItemCount = useSelector(SELECTORS.getCartProductItemCount);
  const isCheckoutFormLoading = useSelector(SELECTORS.getCheckoutFormIsLoading);
  const cartProducts = useSelector(SELECTORS.getAllCartProducts);
  const isAppLoginable = useSelector(SELECTORS.selectIsAppLoginable);
  const isUserLoggedIn = useSelector(SELECTORS.selectIsUserLoggedIn);
  const checkoutFormStatus = useSelector(SELECTORS.getCheckoutFormStatus);
  const isDonationApp = useSelector(SELECTORS.getIsDonationApp);

  const getFormattedPrice = useFormattedPrice();

  const formattedPrice = getFormattedPrice(cartTotal);

  const dispatch = useDispatch();

  const [fallbackStatus, setFallbackStatus] = useState(CHECKOUT_FORM_STATUSES.OPENED);

  const openCheckoutForm = () => dispatch(setCheckoutFormStatus(fallbackStatus));

  const handleFormSubmitted = () => dispatch(setCheckoutFormStatus(CHECKOUT_FORM_STATUSES.SUBMITTED));

  const handleFormCompleted = () => dispatch(setCheckoutFormStatus(CHECKOUT_FORM_STATUSES.COMPLETED));

  const formMessageSender = useFormMessageSender(checkoutFormID);

  useFormSubmissionCompleteHandler(checkoutFormID, handleFormCompleted);

  useFormSubmissionPendingCaptchaHandler(openCheckoutForm);

  useFormSubmissionErrorHandler(openCheckoutForm);

  useFormSubmissionPendingPaymentHandler(openCheckoutForm);

  Hooks.useEffectIgnoreFirst(() => {
    if (isUserLoggedIn) {
      formMessageSender('checkout-userLogIn', isUserLoggedIn);
    }
  }, [isUserLoggedIn]);

  const CHECKOUT_WORDS = {
    ORDER: t('Order'),
    PROCEED_TO_PAY: t('Proceed to Payment'),
    PAY: t('Pay'),
    TOTAL: t('Total'),
    ITEMS: cartProductItemCount > 1 ? t('Items') : t('Item')
  };

  useFormMessageHandler(checkoutFormID, ({ name: actionName, payload }) => {
    switch (actionName) {
      case 'appFlowReady':
        formMessageSender('initCheckoutFormFlow', {
          CHECKOUT_WORDS,
          CHECKOUT_FORM_STATUSES,
          user,
          isDonationApp
        });
        break;
      case 'initAppFlow':
        formMessageSender('initManager', {
          formIsEmbedded: true,
          isCheckoutForm: true,
          checkoutKey,
          formattedPrice,
          cartTotal,
          cartProductItemCount,
          cartProducts,
          isUserLoggedIn
        });
        break;
      case 'onSubmitCheckoutForm':
        dispatch(trackEventAction({ action: 'proceedToPayButtonClicked' }));
        handleFormSubmitted();
        break;
      case 'onFormHasError':
        openCheckoutForm();
        break;
      case 'onCheckoutFormPageChange':
        if (payload === CHECKOUT_FORM_STATUSES.PAYMENT) {
          setFallbackStatus(payload);
        }
        dispatch(setCheckoutFormStatus(payload));
        break;
      case 'checkoutFormStatus':
        dispatch(setCheckoutFormStatus(payload));
        break;
      default:
        console.log('Dont know what to do with the message:', { actionName, payload });
        break;
    }
  });

  const [reloadKey, setReloadKey] = useState(0);

  Hooks.useEffectIgnoreFirst(() => setReloadKey(c => c + 1), [user]);

  const isGatewayRedirectInfoScreen = checkoutFormStatus === CHECKOUT_FORM_STATUSES.GATEWAY_REDIRECT_INFO;
  return (
    <>
      <ScCheckoutFormRenderer previewMode={previewMode} isLoading={isCheckoutFormLoading}>
        {(!isCheckoutFormLoading && !isGatewayRedirectInfoScreen) && (
        <CheckoutFormHeader
          previewMode={previewMode}
          checkoutFormStatus={previewMode ? CHECKOUT_FORM_STATUSES.OPENED : checkoutFormStatus}
          showLoginCue={isAppLoginable && !isUserLoggedIn}
        />
        )}
        {isCheckoutFormLoading && (
        <ScCircleLoader isDonationApp={isDonationApp}>
          <CircleLoaderSvg
            style={{ overflow: 'visible' }}
            width="60"
            height="60"
            viewBox="0 0 60 60"
          >
            <circle
              className="circle c1"
              cx="30"
              cy="30"
              r="20.87"
              stroke="#0099ff"
            />
          </CircleLoaderSvg>
          <span className='loadingText'>{isDonationApp ? t('Processing your donation…') : t('Placing your order...')}</span>
        </ScCircleLoader>
        )}
        {isGatewayRedirectInfoScreen ? (
          <div className="h-92 flex flex-col justify-center items-center">
            <span className="w-24 h-24 bg-blue-100 flex justify-center items-center radius-full">
              <IconInfoCircleFilled className="color-blue-500" width="62" />
            </span>
            <h1 className="mt-3 color-navy-700 text-4xl font-bold text-center">{t('Gateway redirect information')}</h1>
            <span className="mt-3 text-center text-md line-height-3xl">
              {t('Your payment process will continue from the newly opened tab.')}
              <br />
              {t('You can close this window now.')}
            </span>
          </div>
        ) : (
          <IframeRenderer reloadKey={reloadKey} pageURL={checkoutFormURL} />
        )}
      </ScCheckoutFormRenderer>
    </>
  );
};

CheckoutFormRenderer.propTypes = {
  previewMode: PropTypes.bool
};

CheckoutFormRenderer.defaultProps = {
  previewMode: false
};

export default CheckoutFormRenderer;
