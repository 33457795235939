import React, { memo } from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import AppImage from '../AppImage';
import AppLogo from '../AppLogo';
import ScAppIconWrapper from './ScAppIconWrapper';
import VERSIONS from '../../properties/versions';
import { IMAGE_TYPE } from '../../constants';
import SELECTORS from '../../store/selectors';

const AppIcon = ({ withBorder }) => {
  const {
    appIconType, appIconBackground, appIconColor, appIconSvgRef, appIconURL, installableIconURL
  } = useSelector(SELECTORS.getAppIconProperties);
  const title = useSelector(SELECTORS.getAppTitle);
  const appVersion = useSelector(SELECTORS.getAppVersionSelector);
  const logoProperties = useSelector(SELECTORS.getLogoProperties);
  const Wrapper = ScAppIconWrapper;
  const hasInstallableAppIcon = !!installableIconURL;

  // Fallback to AppLogo
  const shouldFallbackToLogo = appVersion === VERSIONS[0];
  if (shouldFallbackToLogo) return (<AppLogo Wrapper={Wrapper} title={title} logoProperties={logoProperties} />);

  return (
    <AppImage
      type={hasInstallableAppIcon ? IMAGE_TYPE.image : appIconType}
      bgColor={appIconBackground}
      bgURL={hasInstallableAppIcon ? installableIconURL : appIconURL}
      iconColor={appIconColor}
      svgRef={appIconSvgRef}
      alt={`${title} Icon`}
      Wrapper={Wrapper}
      withBorder={withBorder}
    />
  );
};

AppIcon.propTypes = {
  withBorder: bool
};

AppIcon.defaultProps = {
  withBorder: false
};

export default memo(AppIcon);
