export const FEATURE_NAMES = {
  FeedbackOnError: 'FeedbackOnError',
  AppThemes: 'AppThemes',
  ShareAsTemplate: 'ShareAsTemplate',
  Embed: 'Embed',
  CustomSlug: 'CustomSlug',
  EmbeddedForm: 'EmbeddedForm',
  TopBar: 'TopBar',
  AppSubdomain: 'AppSubdomain',
  FullIconUrl: 'FullIconUrl',
  AppFooter: 'AppFooter',
  SortPages: 'SortPages',
  BuilderTopBar: 'BuilderTopBar',
  ImageLink: 'ImageLink',
  AppHeaderVisibility: 'AppHeaderVisibility',
  AppLogoSize: 'AppLogoSize',
  ItemDuplication: 'ItemDuplication',
  ItemDuplicationToPage: 'ItemDuplicationToPage',
  MultipageImps: 'Multipage Imps',
  FormResource: 'FormResource',
  ProductList: 'ProductList',
  Flags: 'Flags',
  MobileProductDetail: 'MobileProductDetail',
  FormFields: 'FormFields',
  TeamResourcePicker: 'TeamResourcePicker',
  NewNavigation: 'NewNavigation',
  TeamCollaboration: 'TeamCollaboration',
  SignField: 'SignField',
  OverQuota: 'OverQuota',
  ThankYouPageDisplayTime: 'ThankYouPageDisplayTime',
  DonationItem: 'DonationItem',
  PWAEnabled: 'PWAEnabled',
  ContactInformation: 'ContactInformation',
  ShareOptions: 'ShareOptions',
  NewPaymentModal: 'NewPaymentModal',
  HelpMenu: 'HelpMenu',
  CardItem: 'CardItem',
  List: 'List',
  PushNotification: 'PushNotification',
  AiAssistant: 'AiAssistant',
  UserManagement: 'UserManagement',
  BackNavigation: 'BackNavigation',
  CopilotWithActions: 'CopilotWithActions'
};

export const defaultFeatures = {
  [FEATURE_NAMES.Embed]: true, // Needs some refactor and cleaning like OldPublish before removing
  [FEATURE_NAMES.EmbeddedForm]: true,
  [FEATURE_NAMES.TopBar]: true,
  [FEATURE_NAMES.ImageLink]: true,
  [FEATURE_NAMES.BuilderTopBar]: true,
  [FEATURE_NAMES.SortPages]: true,
  [FEATURE_NAMES.AppHeaderVisibility]: true,
  [FEATURE_NAMES.ItemDuplication]: true,
  [FEATURE_NAMES.AppLogoSize]: true,
  [FEATURE_NAMES.MultipageImps]: true,
  [FEATURE_NAMES.MobileProductDetail]: true,
  [FEATURE_NAMES.TeamResourcePicker]: true,
  [FEATURE_NAMES.TeamCollaboration]: true,
  [FEATURE_NAMES.OverQuota]: true,
  [FEATURE_NAMES.ThankYouPageDisplayTime]: true,
  // WIPs
  [FEATURE_NAMES.FeedbackOnError]: false,
  [FEATURE_NAMES.AppThemes]: false,
  [FEATURE_NAMES.AppSubdomain]: window.JOTFORM_ENV === 'PRODUCTION' && !window.isHIPAA && !window.isGDPR,
  [FEATURE_NAMES.FormResource]: false,
  [FEATURE_NAMES.ProductList]: window.isStoreBuilderReleased,
  [FEATURE_NAMES.Flags]: false,
  [FEATURE_NAMES.FormFields]: false,
  [FEATURE_NAMES.SignField]: window.isSignReleased,
  [FEATURE_NAMES.DonationItem]: window.isDonationBoxReleased,
  [FEATURE_NAMES.NewPaymentModal]: true,
  [FEATURE_NAMES.PushNotification]: window.isPushNotificationBetaUser,
  [FEATURE_NAMES.AiAssistant]: window.isAppAIAssistantBetaUser,
  [FEATURE_NAMES.LinkField]: false,
  [FEATURE_NAMES.UserManagement]: window.isPortalUserManagementBetaUser,

  // Long terms
  [FEATURE_NAMES.ShareAsTemplate]: window.isTemplateCreatorUser,
  [FEATURE_NAMES.AppFooter]: false,
  [FEATURE_NAMES.ItemDuplicationToPage]: false,
  [FEATURE_NAMES.FullIconUrl]: false,
  [FEATURE_NAMES.PWAEnabled]: false,
  [FEATURE_NAMES.ContactInformation]: true,
  [FEATURE_NAMES.ShareOptions]: true,
  [FEATURE_NAMES.HelpMenu]: true,

  [FEATURE_NAMES.CardItem]: true,
  [FEATURE_NAMES.List]: window.isListElementBetaUser,
  [FEATURE_NAMES.BackNavigation]: true,
  [FEATURE_NAMES.CopilotWithActions]: false
};

export const ignoreList = [
  FEATURE_NAMES.AppSubdomain,
  FEATURE_NAMES.FullIconUrl,
  FEATURE_NAMES.ItemDuplicationToPage,
  FEATURE_NAMES.AppFooter,
  FEATURE_NAMES.FormResource,
  FEATURE_NAMES.HelpMenu
];

export const sessionStorageEnabledFeatures = [];

export const localStorageEnabledFeatures = [];

export const adminOnlyFeatures = [];

const testFeaturesKeys = {
  [FEATURE_NAMES.AppSubdomain]: 'appSubdomainTester'
};

export const isTesterFeatureEnabled = (user, featureName) => {
  return !!user[testFeaturesKeys[featureName]];
};
