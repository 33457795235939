import {
  take, call, put, select
} from 'redux-saga/effects';
import { APPLY_COLOR_SCHEME_TO_APP, APPLY_COLOR_SCHEME_TO_ITEM } from '../actionTypes';
import { updateItemPropAction, updateMultipleItemAction, updatePortalAction } from '../actionCreators';
import {
  BUTTON_STYLING_LIST, getAppStylingColors, getColoredPropertiesBySchemeID, getItemStylingColors, ITEM_STYLING_LIST
} from '../../properties/styling';
import SELECTORS from '../selectors';

function* applyColorSchemeToApp(action) {
  const { payload } = action;
  const { schemeID } = payload;
  const appColors = getAppStylingColors(schemeID);
  const itemOverridingColors = getItemStylingColors(ITEM_STYLING_LIST, schemeID);
  const buttonOverridingColors = getItemStylingColors(BUTTON_STYLING_LIST, schemeID);
  const allItems = yield select(SELECTORS.getPortalItems);
  const version = yield select(SELECTORS.getAppVersionSelector);
  const { itemsToChange: properties } = getColoredPropertiesBySchemeID(allItems, schemeID, version);

  yield put(updatePortalAction({ ...appColors, overridingItemProps: JSON.stringify({ itemColors: itemOverridingColors, buttonColors: buttonOverridingColors }) }));
  if (allItems.length) yield put(updateMultipleItemAction(properties));
}

function* applyColorSchemeToItem(action) {
  const { payload } = action;
  const { schemeID, itemID, colorList } = payload;
  const itemColors = getItemStylingColors(colorList, schemeID);
  yield put(updateItemPropAction({ itemID, prop: { ...itemColors } }));
}

export function* watchStylingActions() {
  while (true) {
    const action = yield take([
      APPLY_COLOR_SCHEME_TO_APP,
      APPLY_COLOR_SCHEME_TO_ITEM
    ]);
    const { type } = action;
    switch (type) {
      case APPLY_COLOR_SCHEME_TO_APP:
        yield call(applyColorSchemeToApp, action);
        break;
      case APPLY_COLOR_SCHEME_TO_ITEM:
        yield call(applyColorSchemeToItem, action);
        break;
      default:
        break;
    }
  }
}
