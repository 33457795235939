import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

export const requestLayer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: window.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer],
    shouldCache: false,
    cacheTTL: 3000
  }
});
