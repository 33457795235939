import type { SUPPORTED_PAYMENT_TYPES } from './types';

/**
 * Supported payment types.
 *
 * @remarks
 * This constant represents the supported payment types in the application.
 * Each payment type is associated with a descriptive label.
 * @type {SUPPORTED_PAYMENT_TYPES}
 */
export const supportedPaymentTypesWithLabel: SUPPORTED_PAYMENT_TYPES = {
  product: 'Sell Products',
  subscription: 'Sell Subscriptions',
  custom: 'User Defined Amount',
  donation: 'Collect Donations'
};
