import React, { useMemo } from 'react';
import { elementType, func } from 'prop-types';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import ColorPaletteItem from './ColorPaletteItem';
import SELECTORS from '../../../../store/selectors';
import {
  getAppStylingColors, getButtonText, getPaletteColors, SCHEME_TYPES
} from '../../../../properties/styling';

const ColorPalette = ({
  onChange, Wrapper, ButtonWrapper
}) => {
  const schemeType = useSelector(SELECTORS.getSchemeType);
  const currentSchemeProps = useSelector(SELECTORS.getColorSchemeProps);
  const paletteColors = useMemo(() => getPaletteColors(schemeType), [schemeType]);

  const paletteButtonList = useMemo(() => (
    paletteColors.map(palette => {
      const { id, ...paletteProps } = palette;
      const { designatedItemFontColor, ...stylingProps } = schemeType === SCHEME_TYPES.APP ? getAppStylingColors(id) : paletteProps;
      const isSelected = isEqual(currentSchemeProps, stylingProps);
      const buttonText = getButtonText(schemeType);
      return (
        <ColorPaletteItem
          key={id}
          onChange={onChange}
          Wrapper={ButtonWrapper}
          buttonText={buttonText}
          palette={palette}
          isSelected={isSelected}
        />
      );
    })
  ), [paletteColors, currentSchemeProps]);

  return (
    <Wrapper>
      {paletteButtonList}
    </Wrapper>
  );
};

ColorPalette.propTypes = {
  onChange: func,
  Wrapper: elementType,
  ButtonWrapper: elementType
};

ColorPalette.defaultProps = {
  onChange: f => f,
  Wrapper: props => <div {...props} className="color-palette" />,
  ButtonWrapper: props => <div {...props} />
};

export default ColorPalette;
