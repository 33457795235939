import React from 'react';
import {
  string, func, object, bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconEyeFilled } from '@jotforminc/svg-icons';
import Loader from '../../../Loader';
import './style.scss';

const TableTemplateAreaRenderer = ({
  modelContentLoading, _slug, backgroundColor, setModelContentLoading,
  rightArrowRef, leftArrowRef, _previewImageUrl, title
}) => {
  return (
    <div className="modal-template-area relative z-1 md:min-h-sm duration-200 w-full h-108 md:h-120 lg:border border-navy-75 lg:radius-md overflow-hidden">
      <div className="table-template relative flex w-full h-full" style={{ backgroundColor }}>
        <div className="template-area w-full h-full flex items-start justify-center">
          <div className="template-area-track w-full h-full flex overflow-hidden items-center justify-center">
            { modelContentLoading && <Loader /> }
            <a
              className="modal-template-area-content w-full h-full group relative flex"
              href={`/table-templates/demo/${_slug}`}
              target='_self'
              id="modal-template-area"
              rel="nofollow noopener"
            >
              <img
                width={1078}
                height={632}
                loading="lazy"
                id="modalImage"
                onLoad={() => {
                  setModelContentLoading(false);
                  rightArrowRef.current.classList.remove('load-active');
                  leftArrowRef.current.classList.remove('load-active');
                }}
                className="pointer-events-none absolute left-0 bottom-0 z-0 block w-auto radius-lg transform translate-x-10 translate-y-8 image lazyloaded"
                src={_previewImageUrl}
                alt={title}
                style={{ maxWidth: 'unset', height: '99%' }}
              />
              <div className="w-full hidden md:block">
                <div
                  // eslint-disable-next-line max-len
                  className="template-hint absolute z-4 top-1/2 left-0 right-0 w-full flex justify-center items-center duration-200 opacity-0 transform group-hover:opacity-100 group-hover:-translate-y-4 pointer-events-none"
                  aria-hidden="true"
                >
                  <span className="radius-full bg-navy-800 bg-opacity-80 py-2.5 px-4 inline-flex justify-center items-center gap-1 color-white">
                    <IconEyeFilled width={20} height={20} />
                    <span className="color-white text-md">
                      <span>{t('See Demo')}</span>
                      <span className="sr-only">: </span>
                    </span>
                  </span>
                </div>
                <div className="bg-navy-700 w-full h-full left-0 top-0 absolute z-3 opacity-0 duration-200 group-hover:opacity-80" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

TableTemplateAreaRenderer.defaultProps = {
  _slug: '',
  backgroundColor: '',
  setModelContentLoading: f => f,
  rightArrowRef: null,
  leftArrowRef: null,
  _previewImageUrl: '',
  title: '',
  modelContentLoading: true
};

TableTemplateAreaRenderer.propTypes = {
  modelContentLoading: bool,
  _slug: string,
  backgroundColor: string,
  setModelContentLoading: func,
  rightArrowRef: object,
  leftArrowRef: object,
  _previewImageUrl: string,
  title: string
};

export default TableTemplateAreaRenderer;
