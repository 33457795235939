import React from 'react';
import { Provider } from 'react-redux';
import store from '../store';

const PaymentProvider = ({ children } : IProps) : JSX.Element => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};

interface IProps {
  children: React.ReactChild
}

export default PaymentProvider;
