import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const GroupOptionRenderer = ({
  defaultOptionGroupTextClass, optionGroupWrapperClass,
  optionGroupTextClass, optionGroupClass, children, text, color, defaultExpandValue
}) => {
  const [expanded, setExpanded] = useState(defaultExpandValue);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(defaultExpandValue);
  }, [defaultExpandValue]);

  return (
    <div
      key={text}
      className={['jfSelect-formGroup', optionGroupWrapperClass, expanded ? 'isExpanded' : null].filter(Boolean).join(' ')}
      role="group"
      aria-label={text}
      style={{ '--formColor': color }}
    >
      <span
        className={clsx(defaultOptionGroupTextClass, optionGroupTextClass)}
        onClick={toggleExpand}
      >
        {text}
      </span>
      {expanded && (
        <div className={optionGroupClass}>
          {children}
        </div>
      )}
    </div>
  );
};
GroupOptionRenderer.propTypes = {
  defaultOptionGroupTextClass: PropTypes.string,
  optionGroupWrapperClass: PropTypes.string,
  optionGroupTextClass: PropTypes.string,
  optionGroupClass: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  defaultExpandValue: PropTypes.bool

};

GroupOptionRenderer.defaultProps = {
  defaultOptionGroupTextClass: 'jfSelect-optionGroup',
  optionGroupWrapperClass: '',
  optionGroupTextClass: '',
  optionGroupClass: '',
  text: '',
  color: null,
  children: null,
  defaultExpandValue: false
};

export default GroupOptionRenderer;
