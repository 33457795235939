import { css } from 'styled-components';

const portalContentCommonStyle = css`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const portalTitleCommonStyle = css`
  ${portalContentCommonStyle}
  display: block;
  max-width: 100%;
  padding: 0;
  font-size: 16px;


  @media screen and (max-width: 600px) {
    white-space: normal;
  }
`;

export const portalDescriptionCommonStyle = css`
  ${portalContentCommonStyle}
  font-size: 14px;
`;
