/* eslint-disable max-len */
import Styled from 'styled-components';
import { Button as UIKitButton } from '@jotforminc/uikit';
import { mainFont } from '../../styles/global';

export const ScUIKitButton = Styled(UIKitButton)`
  &.bare {
    font-size: .875em;
    color: #4573e3;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    padding: 0;
    border: 0;
  }

  &.taste {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5em .75em;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    border: 0;
    font-size: 1em;
    line-height: 1.5;
    font-weight: 500;
    font-family: inherit;
  }

  &.panel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
    border: 0;
    padding: 6px;
    margin: 0;
    font-size: 15px;
    font-family: ${mainFont};
    transition: .3s background-color ease;
  }

  &.panel.isGreen {
    background-color: #78BB07;
    color: #fff;

    &:hover {
      background-color: #4EB100;
    }

    &:active {
      background-color: #5FA200;
    }
  }

  &.taste.isGreen {
    background-color: #54b45c;
    color: #fff;
  }

  &.taste.isDarkBlue {
    background-color: #0A1551;
    color: #fff;
    border: 1px solid #0A1551;
  }

  &.taste.isDarkBlueColor {
    color: #0A1551;
    background-color: #fff;
    border: 1px solid #0A1551;

    &:hover {
      background-color: #C8CEED;
    }
  }

  &.taste.isPurple {
    background-color: #8563ce;
    color: #fff;
  }

  &.taste.isRed {
    background-color: #ff4947;
    color: #fff;
  }

  &.bare.isBlue {
    color: #0099FF;
  }

  &.taste.isGray {
    background-color: #FAFAFC;
    border: 1px solid #ADB4D2;
    color: #A0A6C3;

    &:hover {
      background-color: #fff;
      color: #9EA7CE;
      border-color: #9EA7CE;
    }

    &[disabled] {
      color: #A0A6C3;
      border-color: #A0A6C3;
      background-color: #FAFAFC;
    }
  }

  &.taste.isBlue, &.panel.isBlue {
    background-color: #0099FF;
    color: #fff;
    transition: .3s background-color ease;

    &:hover {
      background-color: #33adff;
    }

    &:active {
      background-color: #007acc;
    }
  }

  &.taste.isLightGreen {
    background-color: #78BB07;
    color: #fff;

    &:hover {
      background-color: #4EB100;
    }

    &:active {
      background-color: #5FA200;
    }
  }

  &.taste.isSubGray {
    background-color: #e9e9f2;
    color: #898ba9;

    &:hover {
      background-color: #F2F2FE;
    }

    &:active {
      background-color: #DEDEEC;
    }

    &[disabled] {
      &:hover,&:active {
        background-color: #e9e9f2;
      }
    }
  }

  &.taste.isLightGreenNegative {
    background-color: #fff;
    color: #78BB07;
    border: 1px solid #78BB07;
    &:hover {
      background-color: #78BB07;
      color: #fff;
    }
    &:active {
      background-color: #5FA200;
      color: #fff;
    }
  }

  &.taste.isWhite {
    background-color: #fff;
    color: #8E4FCD;
    transition: .3s background-color ease;
    border: 1px solid #8E4FCD;

    svg path {
      fill: #8E4FCD;
    }

    &:hover {
      background-color: #7735BA;
      color: #fff;

      svg path { fill: #fff; }
    }

    &:active {
      background-color: #6722AC;
      color: #fff;

      svg path { fill: #fff; }
    }
  }


  &.taste.quickQR {
    border-radius: 4px;
    & > svg {
      & > path { fill: #fff }
    }

    @media screen and (max-width: 480px) {
      border-radius: 29px;
    }
  }

  &.taste.quickQR {
    margin: 0 20px;
    font-weight: 500;
    font-size: 14px;
    font-family: inherit;
    transition: .3s background-color ease;
    text-transform: none;

    & > svg { margin-right: 7px;}

    @media screen and (max-width: 480px) {
      padding: 11px;
      margin: 0;

      & > span { display: none; }
      & > svg {
        margin-right: 0;
        & > path { fill: #fff }
      }
    }
  }

  &.taste.quickQR {
    background-color: #0a1551;

    &:hover {
      background-color: #252d5b;
    }
  }

  &.taste.quickQR.isSelected {
    background-color: #091141;
  }

  &.taste.undoRedo {
    margin: 0 2px;
    padding: 12px 17px;
    font-size: 14px;
    font-family: inherit;
    transition: .3s background-color ease;
    text-transform: none;
    font-weight: 400;

    & > svg { margin-right: 11px;}
    & > span { color: #23283A; line-height: 16px; }

    @media screen and (max-width: 768px) {
      padding: 12px 10px;
      & > span { display: none; }
      & > svg { margin-right: 0; }
    }

    @media screen and (max-width: 480px) {
      padding: 12px 10px;
      & > span { display: none; }
      & > svg { margin-right: 0; }
    }

    &[disabled] {
      & > svg > path { fill: #979797;}
      & > span { color: #898BA9; }
    }
  }

  &[disabled] { opacity: .6; cursor: initial; }


  &.outline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5em 1em;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    border: 1px solid currentColor;
    font-size: .875em;
    line-height: 1rem;
    font-weight: 500;
  }

  &.outline.isRed {
    color: #ff4947;
  }

  &.outline.removeForms { padding: .625em; }
  &.outline.removeForms span { margin-left: .25rem; }

  &.goBack {
    font-size: .875em;
    line-height: 1;
    color: #90a4ae;
    padding: 0 1em 1em 0;

    & > * { vertical-align: middle; }
    svg { width: 18px; margin-right: 5px; }
  }

  @media screen and (max-width: 480px) {
    &.outline.removeForms span { display: none; }
    &.cb-addForm {
      margin-left: 0.5em;
      span { display: none; }
      svg { margin-right: 0 !important; }
    }
  }
`;
