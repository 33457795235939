import { requestLayer } from './requestLayer';

/**
 * Retrieves the list of gateways.
 *
 * @returns A Promise that resolves to the list of gateways.
 */
export const getGatewayList = (): Promise<any> => {
  return requestLayer.get('/API/payment/gateway-list');
};
