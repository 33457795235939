/* eslint-disable complexity */
import isEmpty from 'lodash/isEmpty';
import * as ACTION_TYPES from '../../actionTypes';
import { DS_ITEM_LOAD_TYPES, DS_ITEM_OFFSET } from './constants';

const initialState = {
  items: {},
  resources: {},
  pages: {}
  // items: {
  //   [itemID]: {
  //     data: {

  //     },
  //     isLoading:
  //   },
  // },
  // }
  // }
  // resources: {
  //   [resourceID]: {
  //     columns: [],
  //   }
  // }
};
const DataSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.DS_SET_LOADING: {
      const { itemID, isLoading, loadType = DS_ITEM_LOAD_TYPES.DEFAULT } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(state.items[itemID] ?? []),
            [loadType]: isLoading
          }
        }
      };
    }

    case ACTION_TYPES.DS_FETCH_RESOURCE_ROW.REQUEST: {
      const { itemID } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(state.items[itemID] ?? {}),
            isLoading: true
          }
        }
      };
    }

    case ACTION_TYPES.DS_LIST_SET_ACTIVE_ROW_ID.REQUEST:
    case ACTION_TYPES.DS_FETCH_RESOURCE_ROW.SUCCESS: {
      const {
        itemID, itemData
      } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(state.items[itemID] ?? {}),
            data: itemData,
            isLoading: false
          }
        }
      };
    }

    case ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.REQUEST: {
      const { itemID } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(state.items[itemID] ?? {}),
            isLoading: true
          }
        }
      };
    }

    case ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.ERROR: {
      const { itemID } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(state.items[itemID] ?? {}),
            [DS_ITEM_LOAD_TYPES.DEFAULT]: false,
            [DS_ITEM_LOAD_TYPES.INIT]: false
          }
        }
      };
    }

    case ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.SUCCESS: {
      const { itemID, itemData } = action.payload;
      const currentItem = state.items[itemID];
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(currentItem ?? {}),
            data: [...currentItem?.data ?? [], ...itemData],
            [DS_ITEM_LOAD_TYPES.DEFAULT]: false,
            [DS_ITEM_LOAD_TYPES.TABLE_CLONE]: false,
            hasMore: !isEmpty(itemData) && itemData.length === DS_ITEM_OFFSET,
            offset: (currentItem.offset ?? 0) + DS_ITEM_OFFSET
          }
        }
      };
    }

    case ACTION_TYPES.DS_LIST_SET_ACTIVE_ROW_ID.SUCCESS: {
      const { id: itemID, rowID } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [itemID]: {
            ...(state.items[itemID] ?? {}),
            isLoading: false,
            activeRowID: rowID
          }
        }
      };
    }

    case ACTION_TYPES.DS_CLEAR_ITEMS_BY_IDS: {
      const { payload } = action;

      const filteredItems = Object.fromEntries(
        Object.entries(state.items).filter(([itemID]) => !payload.includes(itemID))
      );

      return {
        ...state,
        items: filteredItems
      };
    }

    case ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.SUCCESS: {
      const {
        resourceID, viewID, columns, title
      } = action.payload;

      const key = viewID || resourceID;

      return {
        ...state,
        resources: {
          ...state.resources,
          [key]: {
            ...(state.resources[resourceID] ?? {}),
            columns,
            title
          }
        }
      };
    }

    case ACTION_TYPES.DS_FETCH_DETAIL_PAGE.REQUEST: {
      const { pageID } = action.payload;
      return {
        ...state,
        pages: {
          ...state.pages,
          [pageID]: {
            isLoading: true
          }
        }
      };
    }

    case ACTION_TYPES.DS_FETCH_DETAIL_PAGE.SUCCESS: {
      const { pageID, data: arrData, divideByItemID = false } = action.payload;

      if (divideByItemID) {
        const newItems = arrData.reduce((prev, itemData) => ({
          ...prev,
          [itemData.id]: {
            data: itemData,
            isLoading: false
          }
        }), {});

        return {
          ...state,
          items: {
            ...state.items,
            ...newItems
          }
        };
      }

      const data = arrData.reduce((acc, itemData) => ({ ...acc, [itemData.id]: { ...itemData } }), {});

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageID]: {
            data,
            isLoading: false
          }
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default DataSourceReducer;
