import {
  arrayOf, func, shape, string
} from 'prop-types';
import React from 'react';
import Dropdown from '../../../../../../../../components/Dropdown';
import { ScRightPanelDropdown } from '../../../../../../styles/scRightPanel';

const PageSelection = ({ options, onChange, value }) => (
  <Dropdown
    onChange={onChange}
    defaultValue={value}
    options={options}
    DropdownWrapperRenderer={ScRightPanelDropdown}
  />
);

PageSelection.propTypes = {
  options: arrayOf(shape({})),
  onChange: func,
  value: string
};

PageSelection.defaultProps = {
  options: [],
  onChange: f => f,
  value: ''
};

export default PageSelection;
