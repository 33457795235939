import { ComponentPropsWithoutRef } from 'react';
import { FormControlContextType, commonInputDefaults } from '../../contexts';

export type NativeTextareaTypes = Omit<ComponentPropsWithoutRef<'textarea'>, keyof FormControlContextType | 'maxLength'>
export type TextareaProps = FormControlContextType & NativeTextareaTypes & {
  resize?: 'none' | 'both' | 'horizontal' | 'vertical',
  maxLength?: number,
  ghost?: boolean
}

export const textareaDefaultProps:Partial<TextareaProps> = {
  ...commonInputDefaults,
  resize: 'none'
};
