import { t } from '@jotforminc/translation';

export const RightPanelTabLists = () => ({
  AppName: {
    Upload: {
      label: t('Image')
    },
    Icon: {
      label: t('Icon')
    }
  },
  SelectForms: {
    App: {
      label: t('App')
    },
    Splash: {
      label: t('Splash Screen')
    }
  },
  AppHeader: {
    General: {
      label: t('General')
    },
    Logo: {
      label: t('Logo')
    },
    Style: {
      label: t('Style')
    }
  },
  MenuSettings: {
    General: {
      label: t('General')
    },
    AllPages: {
      label: t('Pages')
    }
  },
  DonationPaymentProperties: {
    paymentMethod: {
      label: t('Payment')
    },
    checkoutForm: {
      label: t('Checkout Page')
    }
  },
  StorePaymentProperties: {
    paymentMethod: {
      label: t('Payment')
    },
    productSettings: {
      label: t('Product Settings')
    },
    checkoutForm: {
      label: t('Checkout Page')
    }
  }
});
