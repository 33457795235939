import React, { useRef, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_OPTIONS } from '../helper';
import { ScOptionLabel } from './styled';

const ValueArea = ({ option, placeholder }) => {
  const searchTextRef = useRef();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!isFirstRender) {
      if (option.value === DEFAULT_OPTIONS.CUSTOM_TEXT.value && searchTextRef.current) {
        searchTextRef.current.focus();
      }
    } else {
      setIsFirstRender(false);
    }
  }, [option.value]);

  // if (option?.value === DEFAULT_OPTIONS.CUSTOM_TEXT.value) {
  //   return (
  //     <div>
  //       <input
  //         ref={searchTextRef}
  //         value={value?.text}
  //         onChange={e => onChange({ text: e.target.value })}
  //         maxLength={option?.limit}
  //       />
  //       {
  //         !!option?.limit && <span className="char-limit">{`${value?.text?.length ?? 0}/${option?.limit}`}</span>
  //       }
  //     </div>
  //   );
  // }
  return (
    <ScOptionLabel>
      <span className={`${isEmpty(option) ? 'placeholder' : ''}`}>{!isEmpty(option) ? option?.label : placeholder}</span>
    </ScOptionLabel>
  );
};

ValueArea.propTypes = {
  option: propTypes.shape({
    value: propTypes.any,
    label: propTypes.string,
    icon: propTypes.func,
    limit: propTypes.number
  }),
  placeholder: propTypes.string
};

ValueArea.defaultProps = {
  option: {},
  placeholder: ''
};

export default ValueArea;
