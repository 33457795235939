import styled, { css } from 'styled-components';

export const ScSvgOptionRendererContainer = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    grid-row-gap: 16px;
    grid-column-gap: 8px;
    > div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const ScOptionButton = styled.button`
    display: grid;
    grid-template-columns: repeat(auto-fit, 179px);
    grid-template-rows: repeat(auto-fit, 132px);
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${({ isSelected }) => (isSelected ? 'rgba(0, 153, 255, 1)' : 'rgba(67, 74, 96, 1)')};
    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(auto-fit, 166px);
        grid-template-rows: repeat(auto-fit, 102px);
    }
    svg {
        display: flex;
        justify-self: center;
    }
    ${({ isSelected }) => (isSelected ? css`
    svg rect,
    path {
        stroke: white;
    }
    path, rect:not(:first-of-type), g rect{
        fill: white;
    }
    svg > rect[width="69"] {
        fill: none;
    }
    ` : '')}
`;

export const ScOptionLabel = styled.span`
    display: block;
    margin-top: 8px;
    text-align: left;
    width: 100%;
    font-size: 14px;
`;
