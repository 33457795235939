import React, { ChangeEvent, useCallback, useState } from 'react';
import { t } from '@jotforminc/translation';
import {
  Button, FormControl, InputText, Badge, Grid
} from '@jotforminc/magnet';
import {
  IconMagnifyingGlass,
  IconPlus,
  IconAnglesSelectorHorizontal,
  IconCreditCardXmarkFilled,
  IconXmark
} from '@jotforminc/svg-icons';
import { useDebounce } from '@jotforminc/hooks';
import { isEnterprise } from '@jotforminc/enterprise-utils';

/** Data */
import { gatewayDefaultIcons } from '@jotforminc/payment-constants';

/** Context */
import { useGatewayConnection } from '../../../context';

/** Utils */
import { isDevelopment, gatewayListSearch } from '../../../utils/functions';

/** components */
import { GatewayRequestModal } from '../../../gatewayRequestModal';

import '../assets/style.css';

/**
 * Renders a gateway picker component for selecting a gateway from a list.
 *
 * This component displays a list of gateways and allows users to select one.
 * It includes search functionality, gateway details, and various badges for different types of gateways.
 *
 * @returns {React.ReactElement} The rendered gateway picker component
 */
export function GatewayPicker(): any {
  const {
    currentlyGateway,
    changeSelectedGateway,
    modalSource,
    resourceType,
    resourceId,
    user,
    userLocation = null,
    logPaymentEvent,
    gatewayList: gatewayListProps
  } = useGatewayConnection();

  const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string | null>(null);

  const logPaymentEventDebounced = useDebounce(logPaymentEvent, 1000);

  const gatewayList = useCallback(() => {
    return gatewayListSearch({
      gatewayList: gatewayListProps,
      searchText: searchKey,
      includeSearchTags: true,
      includeSupportedCurrency: true,
      includeSupportedPaymentTypes: true,
      gatewayFilterOptions: {
        resourceType,
        enableSorting: true,
        userLocation
      }
    });
  }, [gatewayListProps, resourceType, searchKey, userLocation]);

  return (
    <div key="gateway-picker" className='min-h-0 flex flex-col'>

      <div className='mb-6'>
        <FormControl className="relative">
          <InputText
            type="text"
            placeholder={t('Search gateways')}
            prefix={{
              as: 'span',
              icon: IconMagnifyingGlass,
              variant: 'ghost'
            }}
            className='gatewaySearchArea border-navy-100 hover:border-blue-500'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchKey(e.target.value || null);
              // log entry
              if (e.target.value !== '') { logPaymentEventDebounced('search-gateways', e.target.value); }
            }}
            value={searchKey || ''}
            data-test-id="gateway_search_input"
          />
          {searchKey && (
            <div className='flex items-center shrink-0 mr-2 absolute absolute right-1 top-0' style={{ marginTop: '10px' }} data-test-id='clear_input'>
              <IconXmark className='h-5 w-5 color-navy-700 cursor-pointer' onClick={() => setSearchKey(null)} />
            </div>
          )}
        </FormControl>
      </div>

      {gatewayList().length > 0 ? (
        <div className='overflow-auto scrollerColor pb-8 max-h-112'>

          <Grid
            className="gap-3"
            columns={{
              base: '2',
              sm: '4',
              xs: '2'
            }}
            justifyContent={{
              base: 'center',
              xs: 'start'
            }}
          >
            {gatewayList().map(gateway => {
              const Icon = gatewayDefaultIcons[gateway.gateway_type] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

              return (
                <Button
                  className={`
                  relative flex justify-center items-center text-center h-32 radius border hover:shadow-light-xs hover:border-blue-500
                  ${currentlyGateway === gateway.gateway_type ? 'border-blue-500 bg-blue-100 gateway-picker-box-selected' : 'bg-white border-navy-100'}
                  gateway-picker-box
                `}
                  key={gateway.gateway_type}
                  variant="ghost"
                  style={{ width: '164px' }}
                  onClick={() => changeSelectedGateway(gateway.gateway_type)}
                  data-test-id={`gateway_picker_${gateway.gateway_type}`}
                >
                  {isDevelopment() && gateway.env === 'development' && (
                    <Badge icon={IconAnglesSelectorHorizontal} className="absolute right-1 top-1">
                      {t('Only Devs')}
                    </Badge>
                  )}

                  {isDevelopment() && gateway.isDeprecated && (
                    <Badge icon={IconCreditCardXmarkFilled} className="absolute -left-1 -bottom-1" colorStyle="error">
                      {t('Deprecated!')}
                    </Badge>
                  )}

                  <div>
                    <div className='w-full h-10' style={{ width: '146px' }}>
                      <Icon className='w-10 h-10' />
                    </div>
                    <div className='w-full h-3' style={{ width: '146px' }}>
                      <h5 className='text-md tracking-md font-medium line-height-xl color-navy-600 mt-3'>{gateway.name}</h5>
                    </div>
                  </div>
                </Button>
              );
            })}
          </Grid>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <span className='mb-4'>
            <IconMagnifyingGlass className='color-navy-300 h-10 w-10' />
          </span>

          <h5 className='font-bold text-lg color-navy-500 m-0' data-test-id="no_results_found_title">{t('No results found.')}</h5>
          <span className='color-navy-300 text-md line-height-xl tracking-md font-normal py-2' data-test-id="no_results_found_desc">
            {t('Sorry, we couldn’t find what you’re looking for in our gateway list.')}
          </span>

          {!isEnterprise() && (
            <Button
              variant="ghost"
              startIcon={IconPlus}
              onClick={() => {
                setOpenRequestModal(true);
              }}
              data-test-id="request_payment_gateway"
            >
              {t('Request payment gateway')}
            </Button>
          )}

          {!isEnterprise() && openRequestModal && (
            <GatewayRequestModal
              isOpen={openRequestModal}
              onClose={() => setOpenRequestModal(false)}
              onCompleteRequest={() => setSearchKey(null)}
              searchedKeyword={searchKey}
              user={user || null}
              userLocation={userLocation}
              modalSource={modalSource || resourceType}
              resourceId={resourceId || null}
            />
          )}
        </div>
      )}
    </div>
  );
}
