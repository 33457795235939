import React, { Component } from 'react';
import {
  string,
  bool,
  func,
  node,
  elementType,
  oneOfType
} from 'prop-types';

import '../styles/style.scss';

export class Checkbox extends Component {
  get classNames() {
    const {
      className,
      checked,
      size,
      backgroundColor
    } = this.props;

    return [
      'jfCheckbox-wrapper',
      className,
      checked ? 'isChecked' : null,
      size ? 'hasSize' : null,
      backgroundColor === 'transparent' ? 'isTransparent' : null
    ].filter(a => a).join(' ');
  }

  render() {
    const {
      tabIndex,
      name,
      icon,
      checked,
      indeterminate,
      onChange,
      onClick,
      backgroundColor,
      borderColor,
      disabled,
      dataId,
      size,
      ariaLabel
    } = this.props;

    return (
      <div
        className={this.classNames}
        style={{
          backgroundColor,
          borderColor,
          width: size,
          height: size
        }}
        data-test-id="checkbox-wrapper"
      >
        <input
          tabIndex={tabIndex}
          type="checkbox"
          id={`jfCheckbox-item-${name}`}
          name={name}
          className="jfCheckbox-input"
          data-test-id="checkbox-input"
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
          data-id={dataId}
          ref={input => {
            input ? input.indeterminate = indeterminate : null; // eslint-disable-line
          }}
          aria-label={ariaLabel}
        />
        {icon ? (
          <div className="jfCheckbox-check-icon">{icon}</div>
        ) : (
          <div className="jfCheckbox-check">
            <div className="jfCheckbox-checkmark" />
          </div>
        )}
      </div>
    );
  }
}

Checkbox.propTypes = {
  name: string,
  className: string,
  icon: oneOfType([elementType, node]),
  indeterminate: bool,
  checked: bool,
  onChange: func,
  onClick: func,
  backgroundColor: string,
  borderColor: string,
  disabled: bool,
  dataId: string,
  tabIndex: string,
  size: string,
  ariaLabel: string
};

Checkbox.defaultProps = {
  name: '',
  className: '',
  icon: null,
  indeterminate: false,
  checked: false,
  onChange: f => f,
  onClick: f => f,
  backgroundColor: null,
  borderColor: null,
  disabled: false,
  dataId: null,
  tabIndex: '-1',
  size: null,
  ariaLabel: ''
};
