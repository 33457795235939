import { arrayMove } from '@jotforminc/utils';
import { ISellProductsItem, TPid } from '../../types/common';
import * as actionTypes from '../actionTypes';

const productsReducer = (state: ISellProductsItem[] = [], action: actionType) : ISellProductsItem[] => {
  switch (action.type) {
    case actionTypes.INIT_PRODUCTS:
      return action.payload.products;
    case actionTypes.CREATE_PRODUCT:
      return [...state, action.payload.product];
    case actionTypes.UPDATE_PRODUCT:
      return state.map(product => {
        if (product.pid === action.payload.pid) {
          return { ...product, ...action.payload.data };
        }

        return product;
      });
    case actionTypes.REMOVE_PRODUCT:
      return state.filter(product => product.pid !== action.payload.pid);
    case actionTypes.SORT_PRODUCTS:
      const products = [...state];
      const oldIndex = products.findIndex(product => product.pid === action.payload.oldPid);
      const newIndex = products.findIndex(product => product.pid === action.payload.newPid);
      return arrayMove(products, oldIndex, newIndex);
    default:
      return state;
  }
};

interface payloadType {
  products: ISellProductsItem[]
  product: ISellProductsItem,
  pid: TPid,
  productItemProp: keyof ISellProductsItem
  data: Partial<ISellProductsItem>,
  oldPid: TPid,
  newPid: TPid,
}

interface actionType {
  type: string,
  payload: payloadType
}

export default productsReducer;
