import { put, select } from 'redux-saga/effects';
import { addPortalItemAction, trackEventAction } from '../actionCreators';
import SELECTORS from '../selectors';
import { ITEM_TYPES } from '../../constants/itemTypes';
import { ITEM_ADDITION_ORDER_STRATEGY } from '../../constants';

export function* watchItemDuplication() {
  const selectedItems = yield select(SELECTORS.getSelectedMultipleItems);
  const selectedItemsProps = (yield select(SELECTORS.getSelectedPortalItemsWithInfo))
    .filter(item => item.type !== ITEM_TYPES.FORM); // Currently, forms can't be duplicated

  if (selectedItemsProps.length === 1) {
    const [selectedItem] = selectedItemsProps;
    const {
      portalOrder, id,
      ...rest
    } = selectedItem;

    const orderFreeItemProps = { ...rest };

    const newPortalOrder = parseInt(portalOrder, 10) + 1;

    yield put(addPortalItemAction([orderFreeItemProps], newPortalOrder, true, true));
  } else {
    const duplicatedItems = selectedItemsProps.reduce((prev, item, index) => {
      const { id, portalOrder, ...rest } = item;
      return [
        ...prev,
        { ...rest, portalOrder: parseInt(portalOrder, 10) + index + 1 }
      ];
    }, []);

    yield put(addPortalItemAction(duplicatedItems, ITEM_ADDITION_ORDER_STRATEGY.MULTIPLE_ITEM_DUPLICATION, true, true));
  }
  yield put(trackEventAction({ action: 'itemsDuplicated', target: { count: selectedItems.length } }));
}
