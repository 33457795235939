import { t } from '@jotforminc/translation';
import { Hooks } from '@jotforminc/uikit';
import { elementType, func, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import PanelItemDescriptionRenderer from '../HomePage/RightPanel/PanelItemDescriptionRenderer';
import ResettableInput from '../ResettableInput';

const FormLabel = props => {
  const {
    DescriptionRenderer, onChange, value, placeholder, formTitle
  } = props;

  const [innerValue, setInnerValue] = useState(value || formTitle);
  const generateDescription = useCallback(title => t('Original form title: {formTitle}'.replace('{formTitle}', title)), []);

  const HintRenderer = useCallback(({ defaultValue }) => <DescriptionRenderer description={generateDescription(defaultValue)} />, []);

  Hooks.useEffectIgnoreFirst(() => {
    if (value && value !== formTitle) setInnerValue(value);
  }, [value, formTitle]);

  return (
    <ResettableInput
      value={innerValue}
      defaultValue={formTitle}
      placeholder={placeholder}
      onChange={onChange}
      HintRenderer={HintRenderer}
    />
  );
};

FormLabel.propTypes = {
  value: string,
  placeholder: string,
  onChange: func,
  DescriptionRenderer: elementType,
  formTitle: string
};

FormLabel.defaultProps = {
  value: '',
  placeholder: '',
  onChange: f => f,
  DescriptionRenderer: PanelItemDescriptionRenderer,
  formTitle: ''
};

export default FormLabel;
