import React from 'react';
import { elementType, func, string } from 'prop-types';
import JFTextInput from '@jotforminc/text-input';
import { ScTextInput } from './scTextInput';

const TextInput = ({
  description, DescriptionRenderer, theme, onItemChangeWithoutDebounce: _, ...props
}) => {
  return (
    <div className='relative'>
      <ScTextInput theme={theme}>
        <JFTextInput {...props} />
      </ScTextInput>
      {description && <DescriptionRenderer description={description} style={{ marginTop: 9 }} />}
    </div>
  );
};

TextInput.propTypes = {
  DescriptionRenderer: elementType,
  description: string,
  theme: string,
  onItemChangeWithoutDebounce: func
};

TextInput.defaultProps = {
  DescriptionRenderer: null,
  description: '',
  theme: 'dark',
  onItemChangeWithoutDebounce: f => f
};

export default TextInput;
