import React, {
  FunctionComponent, useEffect, useRef, useState, useCallback, KeyboardEvent, WheelEvent
} from 'react';
import { Utils } from '@jotforminc/uikit';
import { IconPencilFilled, IconTrashFilled } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import classNames from 'classnames';
import { ICurrency } from '../../../types/common';
import { useFormattedPrice } from '../../../hooks';
import { checkMobilePhone } from '../../../utils/domUtils';
import { isLetterKey, isYes } from '../../../utils';

type AmountListItemType = {
  id: string | number,
  onChange: (id: string | number, amount: string) => void,
  value: string,
  onDelete: (id: string | number) => void,
  forceActive: boolean,
  currencyInfo: ICurrency
}

const AmountListItem: FunctionComponent<AmountListItemType> = ({
  id = '', onChange, value = '', onDelete, forceActive = false, currencyInfo
}) => {
  const [amount, newAmount] = useState(value);
  const getFormattedPrice = useFormattedPrice(currencyInfo);

  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditActive, setIsEditActive] = useState(forceActive);

  const [isMobileTouched, setIsMobileTouched] = useState(false);

  const focusAndSelect = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [inputRef]);

  useEffect(() => {
    if (isEditActive) {
      const timeout = setTimeout(focusAndSelect, 250); // wait for right panel animation
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isEditActive]);

  const handleEdit = () => setIsEditActive(true);

  const handleSave = () => {
    if (amount) {
      setIsEditActive(false);
      onChange(id, amount);
    } else {
      onDelete(id);
    }
  };

  const handleBlur = (e:React.FocusEvent<HTMLInputElement>) => {
    if (e.target.checkValidity()) {
      handleSave();
    } else {
      e.target.reportValidity();
    }
  };

  const handleOnTouchStart = () => {
    if (checkMobilePhone()) {
      setIsMobileTouched(true);
    }
  };

  const handleOnTouchEnd = () => {
    if (checkMobilePhone()) {
      setTimeout(() => setIsMobileTouched(false), 150);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if ((Utils.isPressedKeyEnter(e) || Utils.isPressedKeyEscape(e)) && inputRef.current) {
      inputRef.current.blur();
    } else if (isLetterKey(e)) {
      e.preventDefault();
    }
  };

  const handleMouseWheel = (e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  return (
    <div className={classNames(
      'donation-container group bg-white flex relative items-center radius h-10 color-navy-700 border-2 border-solid border-transparent',
      { 'border-blue-400': isEditActive }
    )}
    >
      <div className="price-container flex items-center relative flex-1 radius ml-3 mr-3 p-0 h-8 font-bold">
        <label htmlFor={amount} className='hidden'>{amount}</label>
        <input
          className="price flex-1 bg-white color-navy-700 border-opacity-0 p-0 font-bold"
          type={isEditActive ? 'number' : 'text'}
          onChange={e => newAmount(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onTouchStart={handleOnTouchStart}
          onTouchEnd={handleOnTouchEnd}
          onWheel={handleMouseWheel}
          min={1}
          max={999999999999999}
          value={isEditActive ? amount : getFormattedPrice(amount)}
          readOnly={!isEditActive}
          ref={inputRef}
          step={currencyInfo && isYes(currencyInfo.useDecimal) ? 'any' : 1}
          id={amount}
        />
      </div>

      <div className={classNames(
        'group-hover:flex hiddenjf absolute right-2',
        { 'group-hover:hiddenjf pointer-events-none': isEditActive || isMobileTouched },
        { 'group-hover:flex pointer-events-auto': !isMobileTouched }
      )}
      >
        <Button
          startIcon={IconPencilFilled}
          onClick={handleEdit}
          className="max-w-7 max-h-7 mr-2"
          colorStyle="primary"
        />
        <Button
          startIcon={IconTrashFilled}
          onClick={() => onDelete(id)}
          className="max-w-7 max-h-7"
          colorStyle="primary"
        />
      </div>
    </div>
  );
};

export default AmountListItem;
