import React from 'react';
import Styled from 'styled-components';
import { string, oneOfType } from 'prop-types';
import { propTypes } from '@jotforminc/constants';
import { t as translate } from '@jotforminc/translation';
import './styles/panelHeaders.scss';

const ScHeaderWrapper = Styled(props => <div data-sc="panelHeader" {...props} />)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  .panelHeader-icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    
    &-orange {
      background-color: #FF6100;
    }
    &-light-green {
      background-color: #78BB07;
    }
    &-green {
      background-color: #58B966;
    }
    &-dark-green {
      background-color: #37836F;
    }
    &-dark-purple {
      background-color: #4C4F97;
    }
    &-blue {
      background-color: #0099FF;
    }

    &-purple {
      background-color: #B559D4;
    }

    &-red {
      background-color: #D62E2E;
    }   
    
    &-dark-blue, .panelHeader-icon-prefillIcon {
      background-color: #0A1551;
    }

    &-grey {
      background-color: #4E6C9A;
    }
    &-yellow {
      background-color: #FFB841;
    }
    &-light-blue {
      background-color:#4E79F6;
    }
    &-blue-zirgon {
      background-color: #34BAC2;
    }
    &-core-orange-400 {
      background-color: #FF7B1C;
    }
    &-core-green-400 {
      background-color: #7FCA00;
    }
  }
`;

const ScContentWrapper = Styled(props => <div data-sc="panelHeader-content" {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  color: #0A1551;
  .panelHeader-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-transform: uppercase;
    display: inline-block;
    gap: 4px;
  }

  .panelHeader-subtitle {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.17px;
    color: #6F76A7;
  }

  .panelHeader-betaBadge {
    background: #0075E3;
    color: #fff;
    padding: 1px 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-radius: 32px;
    white-space: nowrap;
    margin-left: 8px;
  }
`;

const ScIconWrapper = Styled(props => <div data-sc="panelHeader-iconWrapper" {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  img {
    margin-bottom: auto;
    width: 40px;
    border-radius: 2px;
  }

  &:not(:empty) + .panelHeader-content {
    margin-left: 12px;
  }
`;

const PanelHeader = ({
  title,
  subtitle,
  icon,
  iconType,
  ContentWrapper,
  HeaderWrapper,
  IconWrapper,
  TitleWrapper,
  SubtitleWrapper,
  IconComponent,
  showBetaBadge,
  NewBadge
}) => {
  return (
    <HeaderWrapper className="panelHeader">
      {(icon || IconComponent) && (
        <IconWrapper className={['panelHeader-iconWrapper', iconType, IconComponent && 'isComponent'].filter(Boolean).join(' ')}>
          {icon && (typeof icon === 'string') ? (
            <img
              src={icon}
              alt={`${title} Icon`}
              className="panelHeader-iconImage"
            />
          ) : icon}
          {IconComponent && (
            <IconComponent className="panelHeader-iconSvg" width="24" height="24" />
          )}
        </IconWrapper>
      )}
      <ContentWrapper className="panelHeader-content">
        <TitleWrapper className="panelHeader-text panelHeader-title" role="heading" aria-label={title}>
          {title}
          {showBetaBadge && <span className="panelHeader-betaBadge">{translate('BETA')}</span>}
          {NewBadge}
        </TitleWrapper>
        {subtitle && <SubtitleWrapper className="panelHeader-text panelHeader-subtitle">{subtitle}</SubtitleWrapper>}
      </ContentWrapper>
    </HeaderWrapper>
  );
};

PanelHeader.propTypes = {
  title: string.isRequired,
  subtitle: string,
  icon: oneOfType([
    string,
    propTypes.renderable
  ]),
  iconType: string,
  HeaderWrapper: propTypes.renderable,
  ContentWrapper: propTypes.renderable,
  IconWrapper: propTypes.renderable,
  TitleWrapper: propTypes.renderable,
  SubtitleWrapper: propTypes.renderable,
  IconComponent: propTypes.renderable,
  showBetaBadge: propTypes.Boolean,
  NewBadge: propTypes.renderable
};

PanelHeader.defaultProps = {
  subtitle: '',
  icon: false,
  iconType: null,
  HeaderWrapper: ScHeaderWrapper,
  ContentWrapper: ScContentWrapper,
  IconWrapper: ScIconWrapper,
  TitleWrapper: 'h2',
  SubtitleWrapper: 'span',
  IconComponent: null,
  showBetaBadge: false,
  NewBadge: null
};

export default PanelHeader;
