/* eslint-disable max-len */
/* eslint-disable complexity */
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_CONNECTION_MODE_INFORMATION } from '../../../types';

const ExternalLink: React.FC<{ href: string; children: React.ReactNode }> = ({ href, children }) => (
  <a
    href={href} target='_blank' rel="noopener noreferrer"
    className='font-bold underline color-blue-500'
  >
    {children}
  </a>
);

const BoldText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span className='font-bold'>{children}</span>
);

/**
 * Returns the gateway mode information based on the provided parameters.
 *
 * @param {GATEWAY_CONNECTION_MODE_INFORMATION} params - The parameters for the gateway mode information.
 * @returns {React.ReactNode | null} - The gateway mode information as a React node or null if no information is available.
 */
export const gatewayModeInformation = ({ gateway, connectionMode, connectionState }: GATEWAY_CONNECTION_MODE_INFORMATION): any => {
  switch (gateway) {
    case 'control_square':
    case 'control_cashApp':
    case 'control_afterpay':
    case 'control_clearpay':
      if (connectionMode === 'true' && connectionState !== 'connected') {
        return translationRenderer('To connect using Test Mode, go to the [1[{square_developer_dashboard}]] and login to your Square account. Then launch your sandbox account from the [2[{sanbox_test_accounts}]] section.')({
          renderer1: () => (
            <ExternalLink href="https://developer.squareup.com/apps">Square Developer Dashboard</ExternalLink>
          ),
          renderer2: () => (
            <BoldText>Sandbox Test Accounts</BoldText>
          )
        });
      }
      return null;
    case 'control_paypalInvoicing':
      if (connectionMode === 'Disabled') {
        return translationRenderer('Visit our user guide to learn [1[{how_to_integrate_paypal_invoicing_with_jotform}]] with Jotform.')({
          renderer1: () => (
            <ExternalLink href="https://www.jotform.com/help/565-How-to-integrate-PayPal-Invoicing-with-JotForm">how to integrate PayPal Invoicing</ExternalLink>
          )
        });
      }

      return null;
    case 'control_venmo':
      return translationRenderer('Our [1[{venmo}]] integration is powered by PayPal Business and is available only to U.S.-based merchants and customers.')({
        renderer1: () => (
          <ExternalLink href="https://venmo.com/">Venmo</ExternalLink>
        )
      });
    case 'control_echeck':
      if (connectionMode === 'disabled') {
        return translationRenderer('eCheck Type is set to [1[{web}]].')({
          renderer1: () => (
            <BoldText>WEB</BoldText>
          )
        });
      }
      return null;
    case 'control_eway':
      if (connectionState !== 'connected') {
        return translationRenderer('If you do not have an Eway account, please [1[{click_here}]] to sign up.')({
          renderer1: () => (
            <ExternalLink href="https://my.eway.io/jotform">click here</ExternalLink>
          )
        });
      }
      return null;
    case 'control_bluesnap':
      return translationRenderer('You need to add [1[{jotform_server_ips}]] to your BlueSnap account’s [2[{authorized_ips}]] section via [3[{this_guide}]].')({
        renderer1: () => (
          <BoldText>Jotform Server IPs</BoldText>
        ),
        renderer2: () => (
          <BoldText>Authorized IPs</BoldText>
        ),
        renderer3: () => (
          <ExternalLink href="https://www.jotform.com/help/469-how-to-integrate-bluesnap-with-jotform/#authorizedBluesnap">this guide</ExternalLink>
        )
      });
    case 'control_cardconnect':
      if (connectionMode === 'enabled' && connectionState !== 'connected') {
        return t('Your Test mode Credentials have been generated automatically. Use the credentials below to test your payment gateway.');
      }
      return null;
    case 'control_paysafe':
      if (connectionState !== 'connected') {
        return translationRenderer('Don’t have a Paysafe account? [1[{sign_up}]] to receive your merchant account number.')({
          renderer1: () => (
            <ExternalLink href="https://merchant.paysafe.com/onboarding-form/#/signup?configId=102138">Sign up</ExternalLink>
          )
        });
      }
      return null;
    default:
      return null;
  }
};
