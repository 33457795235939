import type {
  COMPONENT_PROPERTIES, FORM_QUESTION_TYPES, FORM_TYPES, GATEWAY_QUESTION_PROPERTIES, GATEWAY_SUPPORTED_RESOURCES, PAYMENT_QUESTION_PROP_NAMES
} from '@jotforminc/payment-constants';
import { getVisibleAndRequiredResourceProps } from './getVisibleAndRequiredResourceProps';

// isGetCorrectedPropNames: get current corrected prop names that are previously invalid
// without isGetCorrectedPropNames: get current invalid prop names
export const getInvalidOrCorrectedPropNames = (
  gatewayResourceProps: Partial<Record<PAYMENT_QUESTION_PROP_NAMES, COMPONENT_PROPERTIES>>,
  resource: GATEWAY_SUPPORTED_RESOURCES,
  isEnterprise: boolean,
  formType: FORM_TYPES,
  formQuestionTypes: FORM_QUESTION_TYPES[],
  gatewayProperties: GATEWAY_QUESTION_PROPERTIES,
  isGetCorrectedPropNames?: boolean
): PAYMENT_QUESTION_PROP_NAMES[] => {
  const emptyValues = ['none', '', null, undefined];
  const _invalidPropNames = getVisibleAndRequiredResourceProps(
    gatewayResourceProps,
    resource,
    isEnterprise,
    formType,
    formQuestionTypes,
    gatewayProperties
  ).reduce((acc: any, item: any) => {
    const hasEmptyValue = emptyValues.includes(gatewayProperties[item[0] as PAYMENT_QUESTION_PROP_NAMES]);
    const isInvalid = !isGetCorrectedPropNames && hasEmptyValue;
    const isCorrected = !hasEmptyValue && isGetCorrectedPropNames;

    if (isInvalid || isCorrected) { return [...acc, item[0]]; }

    return acc;
  }, []);

  return _invalidPropNames;
};
