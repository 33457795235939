/* eslint-disable max-len */
import React from 'react';

const LogoApplegooglepayLight = ({ className }: { className: string }): any => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4002 16.7192C26.4002 16.3534 26.3675 16.0035 26.3068 15.667H21.605V17.5949L24.3129 17.5955C24.203 18.2369 23.8496 18.7838 23.308 19.1483V20.3991H24.9198C25.861 19.528 26.4002 18.2404 26.4002 16.7192Z" fill="#4285F4" />
      <path d="M23.308 19.1479C22.8594 19.4505 22.2816 19.6275 21.6056 19.6275C20.2999 19.6275 19.1923 18.7477 18.7956 17.5618H17.1329V18.8517C17.9567 20.4863 19.6497 21.608 21.6056 21.608C22.9575 21.608 24.0932 21.1634 24.9199 20.3981L23.308 19.1479Z" fill="#34A853" />
      <path d="M18.6393 16.6051C18.6393 16.2721 18.6948 15.9502 18.7959 15.6476V14.3577H17.1332C16.7926 15.0336 16.601 15.7966 16.601 16.6051C16.601 17.4137 16.7932 18.1766 17.1332 18.8526L18.7959 17.5626C18.6948 17.26 18.6393 16.9381 18.6393 16.6051Z" fill="#FABB05" />
      <path d="M21.6056 13.5815C22.3435 13.5815 23.0042 13.8357 23.5259 14.3323L24.9543 12.905C24.0868 12.0971 22.9558 11.6011 21.6056 11.6011C19.6503 11.6011 17.9567 12.7228 17.1329 14.3574L18.7956 15.6473C19.1923 14.4614 20.2999 13.5815 21.6056 13.5815Z" fill="#E94235" />
      <path d="M12.2489 11.5243C12.6631 11.0062 12.9442 10.3107 12.87 9.59985C12.2637 9.63 11.5238 9.99985 11.0955 10.5183C10.7109 10.9623 10.3705 11.687 10.4592 12.368C11.1398 12.427 11.8198 12.0278 12.2489 11.5243Z" fill="white" />
      <path d="M12.8623 12.5008C11.8739 12.442 11.0335 13.0618 10.5615 13.0618C10.0892 13.0618 9.36638 12.5305 8.58461 12.5448C7.56708 12.5598 6.62294 13.1351 6.10659 14.0501C5.04454 15.8806 5.82631 18.5959 6.8591 20.0867C7.36064 20.8243 7.96508 21.6364 8.76153 21.6072C9.51405 21.5777 9.809 21.12 10.7237 21.12C11.6377 21.12 11.9034 21.6072 12.7 21.5925C13.5261 21.5777 14.0425 20.8545 14.5441 20.1162C15.1194 19.2755 15.355 18.4636 15.3698 18.419C15.355 18.4043 13.7769 17.7989 13.7622 15.9836C13.7473 14.4636 15.0012 13.7407 15.0602 13.6958C14.3522 12.6486 13.2458 12.5305 12.8623 12.5008Z" fill="white" />
    </svg>
  );
};

export default LogoApplegooglepayLight;
