/* eslint-disable max-len */
import {
  LogoSquareLogomarkDark,
  LogoPaypalLogomarkColor,
  LogoPaypalBusinessLogomarkColor,
  LogoPaypalPersonalLogomarkColor,
  LogoPaypalInvoicingLogomarkColor,
  LogoPaypalCheckoutLogomarkColor,
  LogoCashappLogomarkColor,
  LogoVenmoLogomarkColor,
  LogoStripeLogomarkColor,
  LogoStripeCheckoutLogomarkColor,
  LogoStripeAchmLogomarkColor,
  LogoStripeAchLogomarkColor,
  LogoBraintreeLogomarkDark,
  LogoMollieLogomarkDark,
  LogoApplegooglepayColor,
  LogoCybersourceLogomarkColor,
  LogoEwayLogomarkColor,
  LogoPayuLogomarkColor,
  LogoMonerisLogomarkColor,
  LogoGocardlessLogomarkDark,
  LogoPagseguroLogomarkColor,
  LogoChargifyLogomarkColor,
  LogoBluesnapLogomarkColor,
  LogoPayfastLogomarkDark,
  LogoSensepassLogomarkColor,
  LogoKlarnaLogomarkColor,
  LogoFirstdataLogomarkColor,
  LogoOnebipLogomarkColor,
  LogoBluepayLogomarkColor,
  LogoClickbankLogomarkColor,
  LogoCardconnectLogomarkColor,
  LogoPaysafeLogomarkColor,
  LogoIyzicoLogomarkColor,
  LogoSkrillLogomarkColor,
  LogoPayjunctionLogomarkColor,
  LogoPaymentwallLogomarkColor,
  LogoAuthorizenetLogomarkColor,
  LogoEcheckLogomarkColor,
  LogoWorldpayUkLogomarkColor,
  LogoWorldpayUsLogomarkColor,
  Logo2CheckoutLogomarkColor,
  LogoPurchaseorderLogomarkDark
} from '@jotforminc/svg-icons';
import { I_GATEWAY_LIST } from '../Types';

const C_GATEWAYS: I_GATEWAY_LIST[] = [
  {
    type: 'control_square',
    name: 'Square',
    icon: LogoSquareLogomarkDark,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    apmPaymentTypes: [
      { appleAndGooglePay: ['product', 'donation', 'custom'] }
    ],
    supportedCurrencies: [
      'USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK',
      'CHF', 'THB', 'TRY'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_paypalcomplete',
    name: 'PayPal Business',
    icon: LogoPaypalBusinessLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: [
      'USD', 'GBP', 'CAD', 'AUD', 'EUR', 'BRL', 'CZK', 'DKK', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD',
      'SEK', 'CHF', 'THB', 'INR'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_paypal',
    name: 'PayPal Personal',
    icon: LogoPaypalPersonalLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: [
      'USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD',
      'SEK', 'CHF', 'THB', 'TRY'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_paypalInvoicing',
    name: 'PayPal Invoicing',
    icon: LogoPaypalInvoicingLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: [
      'USD', 'AUD', 'BRL', 'CAD', 'CZK', 'DKK', 'EUR', 'HKD', 'HUF', 'INR', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'GBP', 'RUB',
      'SGD', 'SEK', 'CHF', 'THB'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_authnet',
    name: 'Authorize.net',
    icon: LogoAuthorizenetLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'EUR', 'CAD', 'GBP', 'AUD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_stripe',
    name: 'Stripe',
    icon: LogoStripeLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: [
      'USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL',
      'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP',
      'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK',
      'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL',
      'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB',
      'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD',
      'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST',
      'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_stripeCheckout',
    name: 'Stripe Checkout',
    icon: LogoStripeCheckoutLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: [
      'USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB',
      'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD',
      'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD',
      'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD',
      'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR',
      'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP',
      'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU',
      'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_braintree',
    name: 'Braintree',
    icon: LogoBraintreeLogomarkDark,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: [
      'USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND',
      'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP',
      'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR',
      'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD',
      'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD',
      'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD',
      'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD',
      'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'
    ],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_paypalpro',
    name: 'PayPal Pro',
    icon: LogoPaypalLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK', 'CHF', 'THB', 'TRY'],
    resourceTypes: ['APP', 'FORM']
  },
  /** Name APM: Start */
  {
    type: 'control_CashApp',
    name: 'Cash App Pay',
    icon: LogoCashappLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    nameAPMType: 'CashApp',
    parentType: 'control_square',
    supportedCurrencies: ['USD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_Venmo',
    name: 'Venmo',
    icon: LogoVenmoLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    nameAPMType: 'Venmo',
    parentType: 'control_paypalcomplete',
    supportedCurrencies: ['USD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_appleAndGooglePay',
    name: 'Apple Pay & Google Pay',
    icon: LogoApplegooglepayColor,
    paymentTypes: null,
    nameAPMType: 'appleAndGooglePay',
    childTypes: ['control_square', 'control_stripeCheckout'],
    supportedCurrencies: [], // currency will not be applicable in new flow
    resourceTypes: ['APP', 'FORM']
  },
  /** Name APM: End */
  {
    type: 'control_mollie',
    name: 'Mollie',
    icon: LogoMollieLogomarkDark,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['EUR', 'USD', 'AED', 'AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'RON', 'RUB', 'SEK', 'SGD', 'THB', 'TWD', 'ZAR'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_cybersource',
    name: 'CyberSource',
    icon: LogoCybersourceLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MYR', 'MXN', 'TWD', 'NZD', 'NOK', 'PHP', 'PLN', 'RUB', 'SGD', 'SEK', 'CHF', 'THB', 'TRY'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_echeck',
    name: 'eCheck.Net',
    icon: LogoEcheckLogomarkColor,
    paymentTypes: ['custom'],
    supportedCurrencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'DKK', 'GBP', 'HKD', 'INR', 'ILS', 'JPY', 'MYR', 'MXN', 'NZD', 'NOK', 'PHP', 'SGD', 'SEK', 'CHF', 'TRY', 'AED', 'ZAR', 'ARS'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_bluepay',
    name: 'Bluepay',
    icon: LogoBluepayLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_eway',
    name: 'Eway',
    icon: LogoEwayLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_payu',
    name: 'PayU',
    icon: LogoPayuLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['PLN', 'BGN', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HUF', 'NOK', 'RON', 'RUB', 'SEK', 'UAH', 'USD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_worldpay',
    name: 'Worldpay UK',
    icon: LogoWorldpayUkLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'GBP', 'EUR', 'ARS', 'AUD', 'BRL', 'CAD', 'CHF', 'CLP', 'CNY', 'COP', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'IDR', 'ISK', 'JPY', 'KES', 'KRW', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'SEK', 'SGD', 'THB', 'TRY', 'TWD', 'VND', 'ZAR'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_bluesnap',
    name: 'BlueSnap',
    icon: LogoBluesnapLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'EUR', 'AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'GBP', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_moneris',
    name: 'Moneris',
    icon: LogoMonerisLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['CAD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_stripeACHManual',
    name: 'Stripe ACH Manual',
    icon: LogoStripeAchmLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_stripeACH',
    name: 'Stripe ACH',
    icon: LogoStripeAchLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['USD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_gocardless',
    name: 'GoCardless',
    icon: LogoGocardlessLogomarkDark,
    paymentTypes: ['subscription'],
    supportedCurrencies: ['GBP', 'EUR', 'SEK', 'AUD', 'DKK', 'NZD', 'CAD', 'USD'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_payfast',
    name: 'Payfast',
    icon: LogoPayfastLogomarkDark,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['ZAR'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_sensepass',
    name: 'SensePass',
    icon: LogoSensepassLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'EUR', 'CAD', 'ILS'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_sofort',
    name: 'Sofort',
    icon: LogoKlarnaLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['EUR', 'CHF', 'GBP', 'PLN', 'SEK', 'CZK', 'HUF'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_pagseguro',
    name: 'PagSeguro',
    icon: LogoPagseguroLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['BRL'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_firstdata',
    name: 'First Data',
    icon: LogoFirstdataLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_cardconnect',
    name: 'CardConnect',
    icon: LogoCardconnectLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['USD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_worldpayus',
    name: 'Worldpay US',
    icon: LogoWorldpayUsLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['USD'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_2co',
    name: '2Checkout',
    icon: Logo2CheckoutLogomarkColor,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'EUR', 'AUD', 'CAD', 'BRL', 'DKK', 'GBP', 'HKD', 'INR', 'ILS', 'JPY', 'MYR', 'MXN', 'NZD', 'NOK', 'PHP', 'SGD', 'SEK', 'CHF', 'TRY', 'AED', 'ZAR', 'ARS'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_paysafe',
    name: 'Paysafe',
    icon: LogoPaysafeLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'CAD', 'EUR', 'GBP'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_iyzico',
    name: 'iyzico',
    icon: LogoIyzicoLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['TRY', 'EUR', 'USD', 'GBP', 'NOK', 'RUB', 'CHF'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_skrill',
    name: 'Skrill',
    icon: LogoSkrillLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['EUR', 'USD', 'GBP', 'HKD', 'SGD', 'JPY', 'CAD', 'AUD', 'CHF', 'DKK', 'SEK', 'NOK', 'ILS', 'MYR', 'NZD', 'TRY', 'AED', 'MAD', 'QAR', 'SAR', 'TWD', 'THB', 'CZK', 'HUF', 'BGN', 'PLN', 'ISK', 'INR', 'KRW', 'ZAR', 'RON', 'JOD', 'OMR', 'RSD', 'TND'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_chargify',
    name: 'Chargify',
    icon: LogoChargifyLogomarkColor,
    paymentTypes: ['subscription'],
    supportedCurrencies: [],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_payjunction',
    name: 'PayJunction',
    icon: LogoPayjunctionLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['USD'],
    resourceTypes: ['APP', 'FORM']
  },
  {
    type: 'control_paypalSPB',
    name: 'PayPal Checkout',
    icon: LogoPaypalCheckoutLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['USD', 'AUD', 'BRL', 'CAD', 'CZK', 'DKK', 'EUR', 'HKD', 'HUF', 'INR', 'JPY', 'MXN', 'NZD', 'NOK', 'PHP', 'PLN', 'GBP', 'SGD', 'SEK', 'CHF', 'THB'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_clickbank',
    name: 'ClickBank',
    icon: LogoClickbankLogomarkColor,
    paymentTypes: ['product'],
    supportedCurrencies: ['USD', 'EUR'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_onebip',
    name: 'OneBip',
    icon: LogoOnebipLogomarkColor,
    paymentTypes: ['product'],
    supportedCurrencies: ['USD', 'EUR'],
    resourceTypes: ['FORM']
  },
  {
    type: 'control_paymentwall',
    name: 'Paymentwall',
    icon: LogoPaymentwallLogomarkColor,
    paymentTypes: ['product', 'custom', 'donation'],
    supportedCurrencies: ['AUD', 'NZD', 'SGD', 'HKD', 'MYR', 'USD', 'GBP', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZWD'],
    resourceTypes: ['APP', 'FORM']
  },
  /** Purchase Order */
  {
    type: 'control_payment',
    name: 'Purchase Order',
    icon: LogoPurchaseorderLogomarkDark,
    paymentTypes: ['product', 'subscription', 'custom', 'donation'],
    elementType: 'purchase_order',
    supportedCurrencies: ['USD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW', 'ZWD'],
    resourceTypes: ['FORM']
  }
];

export default C_GATEWAYS;
