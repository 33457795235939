import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@jotforminc/hooks';
import { useDispatch, useSelector } from 'react-redux';
import SELECTORS from '../../../../../store/selectors';
import {
  navigateToAction,
  setRightPanelModeAction, toggleRightPanelAction, updatePortalAction
} from '../../../../../store/actionCreators';
import AppIdentifierSettings from './AppIdentifierSettings';
import { DESTINATION_TYPES } from '../../../../../constants/navigation';

const AppIdentifierSettingsContainer = props => {
  const dispatch = useDispatch();

  const appProperties = useSelector(SELECTORS.getAppInfoWithDefaults);
  const appVersion = useSelector(SELECTORS.getAppVersionSelector);
  const isRightPanelOpen = useSelector(SELECTORS.isRightPanelOpenSelector);

  const { title: appTitle, appIconURL } = appProperties;

  const openRightPanel = useCallback(mode => () => {
    dispatch(setRightPanelModeAction(mode));
    if (!isRightPanelOpen) dispatch(toggleRightPanelAction(true));
  }, [isRightPanelOpen]);

  const [appName, setAppName] = useState(appTitle);

  const [isWarningVisible, setWarningVisible] = useState(false);

  const makeWarningUnvisibleIfNeeded = useCallback(() => {
    if (isWarningVisible) setWarningVisible(false);
  }, [isWarningVisible]);

  useEffect(() => {
    if (appTitle !== 'New App' && appName !== appTitle) {
      makeWarningUnvisibleIfNeeded();
      setAppName(appTitle);
    }
  }, [appTitle, makeWarningUnvisibleIfNeeded]);

  const _handleTitleChange = useCallback(newTitle => {
    if (newTitle) makeWarningUnvisibleIfNeeded();
    setAppName(newTitle);
    dispatch(updatePortalAction({ title: newTitle || 'New App' }));
  }, [makeWarningUnvisibleIfNeeded]);

  const handleTitleChange = useDebounce(_handleTitleChange);
  const onIconClick = () => {
    dispatch(navigateToAction({ to: DESTINATION_TYPES.BACK }));
  };

  const handleTitleBlur = useCallback(() => {
    if (!appName) setWarningVisible(true);
  }, [appName]);

  return (
    <AppIdentifierSettings
      appName={appName}
      appTitle={appTitle}
      isWarningVisible={isWarningVisible}
      appIconURL={appIconURL}
      appVersion={appVersion}
      appProperties={appProperties}
      onTitleChange={handleTitleChange}
      onTitleBlur={handleTitleBlur}
      onIconClick={onIconClick}
      onOpenRightPanel={openRightPanel}
      {...props}
    />
  );
};

export default AppIdentifierSettingsContainer;
