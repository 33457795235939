import React, { createContext, useContext, useState } from 'react';
import { element } from 'prop-types';

const SharedStateContext = createContext();

export const useSharedState = () => {
  return useContext(SharedStateContext);
};

export const SharedStateProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState({});

  return (
    <SharedStateContext.Provider value={{ sharedState, setSharedState }}>
      {children}
    </SharedStateContext.Provider>
  );
};

SharedStateProvider.propTypes = {
  children: element.isRequired
};
