import { t } from '@jotforminc/translation';
import { DATA_SOURCE_COLUMN_TYPES, ITEM_TYPES } from '../../../../../constants/itemTypes';
import { OPTION_VALUES, SIDE_SELECTION_OPTIONS } from './ListItemSideItemSelection/constants';
import { listItemEndDsSubFieldSelection, listItemStartDsSubFieldSelection } from './ListItemSideItemSelection/helper';
import { CARD_ACTION_VALUES, LIST_LAYOUT_OPTIONS, PROPERTY_ITEM } from './constants';
import DsPageSelection from './ButtonActions/components/DsPageSelection';

export const AVAILABLE_DETAIL_PAGE_ITEMS = [
  ITEM_TYPES.FORM,
  ITEM_TYPES.HEADING,
  ITEM_TYPES.CARD_ITEM,
  ITEM_TYPES.PARAGRAPH,
  ITEM_TYPES.LINK,
  ITEM_TYPES.IMAGE,
  ITEM_TYPES.BUTTON,
  ITEM_TYPES.DIVIDER
];

export const DATA_SOURCE_TYPE_MAPPER = {
  [PROPERTY_ITEM.TEXT_AREA]: { type: DATA_SOURCE_COLUMN_TYPES.TEXT },
  [PROPERTY_ITEM.TEXT_INPUT]: { type: DATA_SOURCE_COLUMN_TYPES.TEXT },
  [PROPERTY_ITEM.IMAGE_UPLOAD]: { type: DATA_SOURCE_COLUMN_TYPES.IMAGE },
  [PROPERTY_ITEM.FORM_LABEL]: { type: DATA_SOURCE_COLUMN_TYPES.TEXT },
  [PROPERTY_ITEM.IMAGE_UPLOAD_WITH_LOADING]: { type: DATA_SOURCE_COLUMN_TYPES.IMAGE },
  [PROPERTY_ITEM.BUTTON_ROLE_COMPONENTS]: { type: PROPERTY_ITEM.DS_ROLE_COMPONENTS }
};

export const mergePanelConfigs = (config1, config2) => {
  const mergedTabs = { ...config1.tabs, ...config2.tabs };

  const mergedProperties = Object.keys(config1.properties).reduce((acc, key) => {
    if (config2.properties[key]) {
      acc[key] = { ...config1.properties[key], ...config2.properties[key] };
    } else {
      acc[key] = config1.properties[key];
    }
    return acc;
  }, {});

  Object.keys(config2.properties).forEach(key => {
    if (!config1.properties[key]) {
      mergedProperties[key] = config2.properties[key];
    }
  });

  return {
    tabs: mergedTabs,
    properties: mergedProperties
  };
};

export const DATA_SOURCE_EXCLUDED_TYPES = [];
export const DATA_SOURCE_ADD_DATA_SECTION_ENABLED_COLUMNS = [DATA_SOURCE_COLUMN_TYPES.TEXT];

export const DATA_SOURCE_EXCLUDED_PROPS = {
  [ITEM_TYPES.LIST]: ['shrink', 'duplicate']
};
export const DATA_SOURCE_PICKER_EXCLUDED_DEFAULT_OPTIONS = {
  [ITEM_TYPES.LIST]: {
    [PROPERTY_ITEM.LIST_ITEM_START]: [DATA_SOURCE_COLUMN_TYPES.IMAGE]
  }
};

export const DATA_SOURCE_TYPE_MUTATER = {
  [PROPERTY_ITEM.ROLES]: (props, isDataSourceItem) => {
    const { buttonActionProps: actions } = props;

    const excludedRoleComponents = [CARD_ACTION_VALUES.FORM];

    return {
      ...props,
      buttonActionProps: actions.filter(action => !excludedRoleComponents.includes(action.value)),
      ...(isDataSourceItem ? { title: t('List Action') } : {})
    };
  },

  [PROPERTY_ITEM.BUTTON_ROLE_COMPONENTS]: (props, isDataSourceItem) => {
    if (isDataSourceItem) {
      return {
        ...props,
        ROLE_COMPONENT_PROPS: {
          ...props.ROLE_COMPONENT_PROPS,
          [CARD_ACTION_VALUES.NAVIGATION]: {
            title: t('Page'),
            RoleComponent: DsPageSelection
          }
        }
      };
    }

    return props;
  },

  [PROPERTY_ITEM.LIST_ITEM_START]: (props, isDataSourceItem) => {
    const selectSubFieldProp = {
      selectSubfield: listItemStartDsSubFieldSelection(props)
    };

    if (isDataSourceItem) {
      return {
        ...props,
        ...selectSubFieldProp,
        dropdownSections: [
          {
            description: '',
            options: [
              { ...SIDE_SELECTION_OPTIONS[OPTION_VALUES.IMAGE], label: t('Image'), title: t('Image Source') },
              { ...SIDE_SELECTION_OPTIONS[OPTION_VALUES.AVATAR], title: ('Avatar Source') }]
          }
        ],
        excludedDefaultDsOptions: [DATA_SOURCE_COLUMN_TYPES.IMAGE]
      };
    }

    return {
      ...props,
      dropdownSections: [
        {
          description: '',
          options: [SIDE_SELECTION_OPTIONS[OPTION_VALUES.IMAGE], SIDE_SELECTION_OPTIONS[OPTION_VALUES.AVATAR]]
        }
      ],
      ...selectSubFieldProp
    };
  },

  [PROPERTY_ITEM.LIST_ITEM_END]: (props, isDataSourceItem) => {
    const selectSubFieldProp = {
      selectSubfield: listItemEndDsSubFieldSelection(props)
    };

    if (isDataSourceItem) {
      return {
        ...props,
        ...selectSubFieldProp
      };
    }

    return {
      ...props,
      dropdownSections: [
        {
          description: '',
          options: [SIDE_SELECTION_OPTIONS[OPTION_VALUES.ICON], SIDE_SELECTION_OPTIONS[OPTION_VALUES.BUTTON]]

        }
      ],
      ...selectSubFieldProp
    };
  },

  [PROPERTY_ITEM.CARD_LAYOUT]: (props, isDataSourceItem) => {
    if (!isDataSourceItem) {
      return props;
    }

    return {
      ...props,
      title: 'List Layout',
      options: LIST_LAYOUT_OPTIONS
    };
  }
};

export const isSingleEntryMap = {
  [ITEM_TYPES.LINK]: {
    title: true
  }
};

// right panel prop mutater for data source
export const detailPageItemPropertiesMutater = ({ itemProps, dataSourcePageLinkedItemProps = {}, isDataSourceItem }, [prev, _panelTabProperty]) => {
  if (!_panelTabProperty) {
    console.error('panel tab property is  missing for ', itemProps.type);
    return prev;
  }
  const excludedProps = DATA_SOURCE_EXCLUDED_PROPS[itemProps.type];

  const panelTabProperty = excludedProps ? Object.entries(_panelTabProperty).reduce((tabPropPrev, [propName, prop]) => {
    if (excludedProps.includes(propName)) {
      return tabPropPrev;
    }
    return { ...tabPropPrev, [propName]: prop };
  }, {}) : _panelTabProperty;

  const dataSourcePropIndicator = isDataSourceItem ? {
    presentationItemID: itemProps?.presentationItemID,
    linkedItemID: itemProps?.id,
    linkedResourceID: itemProps?.resourceID,
    linkedViewID: itemProps?.viewID
  } : {
    linkedItemID: dataSourcePageLinkedItemProps?.id,
    linkedResourceID: dataSourcePageLinkedItemProps?.resourceID,
    linkedViewID: dataSourcePageLinkedItemProps?.viewID
  };

  return Object.entries(panelTabProperty).reduce((prevTabProperties, [propName, tabProp]) => {
    const propMutateFunc = DATA_SOURCE_TYPE_MUTATER[tabProp.type];
    if (typeof propMutateFunc === 'function') {
      // eslint-disable-next-line no-param-reassign
      tabProp = propMutateFunc({ parentType: tabProp.type, ...tabProp, ...dataSourcePropIndicator }, isDataSourceItem);
    }

    const { type: dataSourceTypeOfProp, parentComponent } = DATA_SOURCE_TYPE_MAPPER[tabProp?.type] ?? {};

    if (!dataSourceTypeOfProp) {
      return { ...prevTabProperties, [propName]: { ...tabProp } };
    }

    // check has unique component for data source
    const hasUniqueComponent = !Object.values(DATA_SOURCE_COLUMN_TYPES).includes(dataSourceTypeOfProp) && Object.values(PROPERTY_ITEM).includes(dataSourceTypeOfProp);
    const excludedDefaultDsOptions = DATA_SOURCE_PICKER_EXCLUDED_DEFAULT_OPTIONS?.[itemProps.type]?.[tabProp.type];
    const isSingleEntry = isSingleEntryMap[itemProps.type]?.[propName];

    return {
      ...prevTabProperties,
      [propName]: {
        ...tabProp,
        ...dataSourcePropIndicator,
        type: hasUniqueComponent ? dataSourceTypeOfProp : PROPERTY_ITEM.DATA_SOURCE_PICKER,
        parentType: tabProp.type,
        ...(parentComponent ? { parentComponent } : {}),
        ...(!hasUniqueComponent ? { columnType: dataSourceTypeOfProp } : {}),
        ...(excludedDefaultDsOptions ? { excludedDefaultDsOptions } : {}),
        ...(isSingleEntry ? { isSingleEntry } : {})
      }
    };
  }, {});
};
