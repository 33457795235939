import AppLogoPropertyPanel from '../../HomePage/RightPanel/AppLogoPropertyPanel';
import AppIdentifierSettingsContainer from '../../Settings/AppIdentifierSettings';

export const NavigationNames = {
  AppLogoPropertyPanel: 'AppLogoPropertyPanel',
  AppIdentifierSettingsContainer: 'AppIdentifierSettingsContainer'
};

export const Navigation = {
  AppLogoPropertyPanel,
  AppIdentifierSettingsContainer
};

export const DefaultView = NavigationNames.AppIdentifierSettingsContainer;
