import { call, put, select } from 'redux-saga/effects';
import SELECTORS from '../selectors';
import {
  bulkSharePortal, bulkDeleteSharePortal, generateNewResourceShareLink, getShareList
} from '../../modules/api';
import { FETCH_SHARE_LIST, UPDATE_RESOURCE_SHARE_URL } from '../actionTypes';
import { trackEventAction } from '../actionCreators';

export function* watchFetchShareList() {
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const shareList = yield call(getShareList, portalID);
  yield put({ type: FETCH_SHARE_LIST.SUCCESS, payload: shareList });
}

export function* watchSharePortal({ payload: { emails, message } }) {
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  yield call(bulkSharePortal, portalID, emails, message);
  yield put(trackEventAction({ action: 'appShared', target: { count: emails.length } }));
  yield put({ type: FETCH_SHARE_LIST.REQUEST });
}

export function* watchShareDeletePortal({ payload: { resourceShareIDList } }) {
  yield call(bulkDeleteSharePortal, resourceShareIDList);
  yield put(trackEventAction({ action: 'appUnshared', target: { count: resourceShareIDList.length } }));
  yield put({ type: FETCH_SHARE_LIST.REQUEST });
}

export function* watchResourceShareURLUpdate() {
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const resourceShareURL = yield call(generateNewResourceShareLink, portalID);
  yield put(trackEventAction({ action: 'resourceShareURLGenerated' }));
  yield put({ type: UPDATE_RESOURCE_SHARE_URL.SUCCESS, payload: resourceShareURL });
}
