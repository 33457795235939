import React from 'react';
import Styled from 'styled-components';
import { renderable } from '../../../../../constants/propTypes';

export const ScContentRenderer = Styled.div`
  background-color: rgb(254, 254, 254);
  padding: 0;
  margin: 0;
  max-width: 580px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
  width: 80%;
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: none;
  }
`;

const ContentRenderer = ({ children }) => (<ScContentRenderer>{children}</ScContentRenderer>);
ContentRenderer.propTypes = { children: renderable.isRequired };

export default ContentRenderer;
