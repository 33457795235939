import { TextInput } from '@jotforminc/uikit';
import { func, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

const SchemaTextInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnBlur = useCallback(({ target: { value: newValue } }) => {
    onChange(newValue);
    setInputValue(newValue);
  }, [onChange]);

  return (
    <TextInput
      value={inputValue}
      onBlur={handleOnBlur}
    />
  );
};

SchemaTextInput.propTypes = {
  value: string,
  onChange: func
};

SchemaTextInput.defaultProps = {
  value: '',
  onChange: f => f
};

export default SchemaTextInput;
