import { t } from '@jotforminc/translation';
import {
  IGatewaySettings, IProductListSettings, IProductListSettingsProperties, IQuestions, TPaymentType
} from '../types/common';
import * as COMPONENT_NAMES from './componentNames';
import { BILLING_REQUIRED_GATEWAYS, CHECKOUT_FORM_QUESTIONS } from './variables';

export const ProductListSettingsProperties: IProductListSettingsProperties = {
  layoutSettings: {
    basicElementList: (settings: IProductListSettings) => ({
      HR: {
        type: COMPONENT_NAMES.LAYOUT_BUTTON,
        labelProps: {
          label: t('Single Column')
        },
        componentProps: {
          isSelected: settings?.productListLayout === 'HR'
        }
      },
      V2C: {
        type: COMPONENT_NAMES.LAYOUT_BUTTON,
        labelProps: {
          label: t('Two Columns')
        },
        componentProps: {
          isSelected: settings?.productListLayout === 'V2C'
        }
      },
      V3C: {
        type: COMPONENT_NAMES.LAYOUT_BUTTON,
        labelProps: {
          label: t('Three Columns')
        },
        componentProps: {
          isSelected: settings?.productListLayout === 'V3C'
        }
      }
    })
  },
  externalAppProperties: {
    basicElementList: (settings: IProductListSettings) => ({
      useFastCheckout: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Express Checkout'),
          description: t('Users can order items quickly while not allowing to favorite items and revisit the shopping cart.')
        },
        componentProps: {
          visibility: true,
          isSelected: settings?.useFastCheckout === 'Yes'
        }
      }
    })
  },
  listSettings: {
    basicElementList: (settings: IProductListSettings & IGatewaySettings, isReusableConnectionEnabled: boolean) => ({
      currency: {
        type: COMPONENT_NAMES.CURRENCY_DROPDOWN,
        labelProps: {
          label: t('Currency')
        },
        componentProps: {
          activeCurrencyCode: settings?.currency,
          activeGatewaySettings: settings?.activeGatewaySettings
        }
      },
      showSearch: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable Search'),
          description: t('Let customers search for a specific product')
        },
        componentProps: {
          visibility: true,
          isSelected: settings?.showSearch === 'Yes'
        }
      },
      showSorting: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Enable Sort'),
          description: t('Allow your users to sort products on the product list according to their name or price.')
        },
        componentProps: {
          visibility: true,
          isSelected: settings?.showSorting === 'Yes'
        }
      },
      useDecimal: {
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
          label: t('Use Decimals')
        },
        componentProps: {
          isSelected: settings?.useDecimal === 'Yes'
        },
        condition: () => isReusableConnectionEnabled
      },
      decimalMark: {
        type: COMPONENT_NAMES.SELECTBOX,
        labelProps: {
          label: t('Decimal Separator')
        },
        componentProps: {
          options: [
            { text: t('Point'), value: 'point' },
            { text: t('Comma'), value: 'comma' }
          ],
          defaultValue: settings?.decimalMark
        },
        condition: () => settings?.useDecimal === 'Yes' && isReusableConnectionEnabled
      }
    }),
    advancedElementList: props => ({ ...props })
  },
  checkoutFormQuestionsVisibilitySettings: {
    basicElementList: ({ checkoutFormQuestions, activeGatewaySettings, paymentType }: {checkoutFormQuestions: IQuestions, activeGatewaySettings: IGatewaySettings, paymentType: TPaymentType}) => {
      const isSelected = (field: number) => {
        const hidden = checkoutFormQuestions[field]?.hidden;
        return (!hidden || hidden === 'No');
      };

      return {
        [CHECKOUT_FORM_QUESTIONS.FULL_NAME]: {
          type: COMPONENT_NAMES.TOGGLE,
          labelProps: {
            label: t('Full Name')
          },
          componentProps: {
            isSelected: isSelected(CHECKOUT_FORM_QUESTIONS.FULL_NAME)
          }
        },
        [CHECKOUT_FORM_QUESTIONS.EMAIL]: {
          type: COMPONENT_NAMES.TOGGLE,
          labelProps: {
            label: t('Email Address')
          },
          componentProps: {
            isSelected: isSelected(CHECKOUT_FORM_QUESTIONS.EMAIL),
            readOnly: true
          }
        },
        [CHECKOUT_FORM_QUESTIONS.PHONE]: {
          type: COMPONENT_NAMES.TOGGLE,
          labelProps: {
            label: t('Phone Number')
          },
          componentProps: {
            isSelected: isSelected(CHECKOUT_FORM_QUESTIONS.PHONE)
          }
        },
        [CHECKOUT_FORM_QUESTIONS.SHIPPING_ADDRESS]: {
          type: COMPONENT_NAMES.TOGGLE,
          labelProps: {
            label: t('Shipping Address')
          },
          componentProps: {
            isSelected: isSelected(CHECKOUT_FORM_QUESTIONS.SHIPPING_ADDRESS)
          }
        },
        [CHECKOUT_FORM_QUESTIONS.BILLING_ADDRESS]: {
          type: COMPONENT_NAMES.TOGGLE,
          labelProps: {
            label: t('Billing Address')
          },
          componentProps: {
            isSelected: isSelected(CHECKOUT_FORM_QUESTIONS.BILLING_ADDRESS),
            readOnly: BILLING_REQUIRED_GATEWAYS.includes(activeGatewaySettings?.type)
          }
        },
        [CHECKOUT_FORM_QUESTIONS.MESSAGE]: {
          type: COMPONENT_NAMES.TOGGLE,
          labelProps: {
            label: t('Message'),
            description: paymentType === 'donation' && t('Let donors leave a message for you')
          },
          componentProps: {
            isSelected: isSelected(CHECKOUT_FORM_QUESTIONS.MESSAGE)
          }
        }
      };
    }
  }
};
