import React, { useCallback } from 'react';
import {
  arrayOf, elementType, any, func
} from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { renderable } from '../../constants/propTypes';
import DefaultErrorFallback from './DefaultErrorFallback';
import { captureException } from '../../utils';

const PortalErrorBoundary = ({
  Fallback, children, resetKeys, onReset
}) => {
  const handleError = useCallback((error, info) => {
    console.log('PORTAL ERROR', { error, info });
    captureException(error);
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={handleError}
      resetKeys={resetKeys}
      onReset={onReset}
    >
      {children}
    </ErrorBoundary>
  );
};

PortalErrorBoundary.propTypes = {
  children: renderable.isRequired,
  Fallback: elementType,
  resetKeys: arrayOf(any),
  onReset: func
};

PortalErrorBoundary.defaultProps = {
  Fallback: DefaultErrorFallback,
  resetKeys: [],
  onReset: f => f
};

export default PortalErrorBoundary;
