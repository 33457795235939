import React, {
  FunctionComponent, useState, useCallback, ChangeEvent, KeyboardEvent, WheelEvent
} from 'react';
import { TextInput, Utils } from '@jotforminc/uikit';
import { getCurrencyPrefixSuffix } from '@jotforminc/money-utils';
import { useDebounce } from '@jotforminc/hooks';
import { useFormattedPrice } from '../../hooks';
import { ICurrency } from '../../types/common';
import { isLetterKey } from '../../utils';

type PriceInputType = {
  value: string | number,
  onChange: (val: string | number) => void,
  currencyInfo: ICurrency,
  max?: string | number,
  min?: string | number,
  description?: string
}

const PriceInput: FunctionComponent<PriceInputType> = ({
  value, onChange, currencyInfo, max, min, description, ...props
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const getFormattedPrice = useFormattedPrice(currencyInfo);

  const { currency } = currencyInfo;

  const currencyPrefixSuffix = getCurrencyPrefixSuffix(currency);

  // TODO: remove this after refactor
  let formattedPrice = '';
  if (currentValue) {
    formattedPrice = currencyInfo ? getFormattedPrice(currentValue as number) : `${currentValue}`;
  } else {
    formattedPrice = currencyInfo ? currencyPrefixSuffix.join(' ') : ' ';
  }

  const handleChange = useCallback((newValue: string | number) => {
    onChange(newValue);
  }, [onChange]);

  const debouncedChange = useDebounce(handleChange, 250);

  const handleCustomPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checkValidity()) {
      setCurrentValue(e.target.value);
      debouncedChange(e.target.value);
    } else {
      e.target.reportValidity();
    }
  };

  const handleCustomPriceBlur = () => {
    setIsFocused(false);
  };

  const handleKeydown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (Utils.isPressedKeyEnter(e)) {
      (e.target as HTMLElement).blur();
    } else if (isLetterKey(e)) {
      e.preventDefault();
    }
  };

  const handleMouseWheel = (e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };
  return (
    <>
      <TextInput
        {...props}
        value={isFocused ? currentValue : formattedPrice}
        className="textInput h-10 w-full border-1 border-solid border-gray-800 bg-current color-white radius p-3 shadow-none"
        type={isFocused ? 'number' : 'text'}
        onFocus={() => setIsFocused(true)}
        onBlur={handleCustomPriceBlur}
        onChange={handleCustomPriceChange}
        onKeyDown={handleKeydown}
        onWheel={handleMouseWheel}
        max={max}
        min={min}
      />
      {description && (
        <div className="mt-2 color-white opacity-60">{description}</div>
      )}
    </>
  );
};

PriceInput.defaultProps = {
  max: '',
  min: '',
  description: ''
};

export default PriceInput;
