import { call, put, take } from 'redux-saga/effects';
import { MODALS, MODAL_EVENTS } from '../../../constants/modals';
import { SHOW_MODAL } from '../../actionTypes';
import { handleShowGuestLoginModal } from './guestLoginModal';
import { handleCloneApp } from './cloneApp';
import { trackEventAction } from '../../actionCreators';
import { handleStillEditing } from './StillEditingModal';
import { handlePortalFeedbackModal } from './portalFeedbackModal';

export function* watchModals() {
  while (true) {
    const action = yield take(SHOW_MODAL);
    const { payload } = action;
    const { name, resourceType } = payload;
    switch (name) {
      case MODALS.GUEST_LOGIN_MODAL:
        yield call(handleShowGuestLoginModal, action);
        break;
      case MODALS.CLONE_APP_MODAL:
        yield call(handleCloneApp, action);
        break;
      case MODALS.TEAM_STILL_EDITING:
        yield call(handleStillEditing, action);
        break;
      case MODALS.PORTAL_FEEDBACK_MODAL:
        yield call(handlePortalFeedbackModal, action);
        break;
      default:
        console.log('Unknown modal', name);
    }

    if (MODAL_EVENTS[name]) {
      yield put(trackEventAction({ action: `${MODAL_EVENTS[name]}Opened`, ...resourceType ? { target: { resourceType } } : {} }));
    }
  }
}
