/* eslint-disable complexity */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @jotforminc/no-native-button */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import {
  object, func, bool, string
} from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { IconEyeFilled } from '@jotforminc/svg-icons';
import { classNames } from '@jotforminc/utils';

import { getDeviceType } from '../../getDeviceType';
import { getTopValue } from './utils';
import { placeholderImage } from '../../constantImages';

const FormTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate,
  setImageLoaded, templateCloneWrapper, theme, context, useSlider, customCloneText
}) => {
  const screenshotRef = useRef();
  const [loadImageErr, setLoadImageErr] = useState(false);
  const [screenshotScrolled, setScreenshotScrolled] = useState(false);
  const [isPlaceholder, setIsPlaceholder] = useState(false);
  const { _title, screenshot, _previewImageUrl } = template;
  const [screenshotPosition, setScreenshotPosition] = useState(0);
  const isSkeleton = _title === 'skeleton';

  const updateScreenshotPosition = position => {
    if (!imageLoaded || getDeviceType() !== 'desktop') return;
    const screenshotElement = screenshotRef.current;
    if (screenshotElement === null || !screenshotElement) return;
    setScreenshotPosition(position);
    setScreenshotScrolled(true);
  };

  const handleMouseEnterAnimation = () => {
    if (isPlaceholder) return;
    updateScreenshotPosition(getTopValue(screenshotRef) - 24);
    // animationTimeoutReferance = setTimeout(() => updateScreenshotPosition(0), 2000);
  };

  return (
    <div
      data-theme={theme}
      className={cx('js-new-card item text-left rtl:text-right inline-block shrink-0 sm:mx-0 xs:mx-auto align-top w-full max-w-92 mx-auto JF-directory-list-mode', {
        'image-loaded': !isSkeleton,
        'sm:max-w-1/2 lg:max-w-1/3': !useSlider,
        'sm:min-w-1/2 lg:min-w-1/3 min-w-full': !useSlider && context === 'subCategoryGallery',
        'w-full': useSlider
      })}
    >
      <div className={cx('category-box flex flex-col items-start mb-12 mt-4', {
        'px-2.5 lg:px-5': !useSlider
      })}
      >
        <div
          className="category-box-imgwrapper js-scrollable-card w-full group relative border border-navy-50 radius-lg overflow-hidden"
          onMouseLeave={() => {
            setScreenshotPosition(0);
            setScreenshotScrolled(false);
          }}
          onMouseEnter={handleMouseEnterAnimation}
        >
          <div className="template-item-imgur-wrapper h-full">
            <div
              aria-hidden="true"
              onClick={() => handlePreviewTemplate('viewTemplateFromImg')}
              className="template-item-imgur cursor-pointer block h-full bg-navy-50 color-white"
            >
              <svg
                viewBox={`1 0 3 ${theme === 'classic' ? '3' : '2.9'}`}
                className="relative z-0 bg-navy-50 block duration-200 w-full h-full"
              />
              <div
                className={classNames('absolute z-3 w-full h-full left-0 top-0 bottom-0', theme === 'classic' && 'pt-3 px-3')}
              >
                {theme === 'card' && (
                  <div className='bg-navy-700 w-full h-full left-0 top-0 absolute z-2 opacity-0 duration-200 group-hover:opacity-80' />
                )}

                <div
                  className={`category-image relative z-1 block w-full h-auto radius-lg overflow-hidden top-0${isPlaceholder ? ' mt-1.5' : ''} ${isSkeleton ? 'opacity-0' : 'opacity-1'}`}
                  ref={theme === 'classic' ? screenshotRef : null}
                  style={{
                    ...(theme === 'classic' ? {
                      transitionProperty: 'transform',
                      transitionDuration: screenshotScrolled ? '0.6s' : '0.3s',
                      transitionTimingFunction: 'cubic-bezier(1, 0, 0.64, 0.64)',
                      boxShadow: 'rgba(37, 45, 91, 0.04) 0px 0px 2px 0px, rgba(84, 95, 111, 0.16) 0px 4px 8px 0px',
                      transform: `translateY(${screenshotPosition}px)`
                    } : {})
                  }}
                >
                  <img
                    className="w-full h-auto block duration-200"
                    alt={_title}
                    src={(!isSkeleton && !loadImageErr) ? screenshot || _previewImageUrl : placeholderImage}
                    onError={() => setLoadImageErr(true)}
                    onLoad={({ target: { src: screenshotSrc } }) => {
                      const isScreenshotImageLoaded = screenshotSrc === placeholderImage;
                      setIsPlaceholder(screenshotSrc && isScreenshotImageLoaded);
                      setImageLoaded(!isScreenshotImageLoaded);
                    }}
                  />
                </div>
                {theme === 'classic' && (
                  <>
                    <div
                      style={{
                        transitionProperty: 'opacity',
                        transitionDuration: screenshotScrolled ? '0.3s' : '0.6s',
                        transitionTimingFunction: 'cubic-bezier(1, 0, 0.64, 0.64)',
                        opacity: screenshotScrolled ? 0 : 1
                      }}
                      className="js-shadow-bottom absolute w-full h-8 bottom-0 left-0 z-2 pointer-events-none gradient-to-t from-navy-50 to-transparent opacity-100"
                    />
                    <div
                      style={{
                        transitionProperty: 'opacity',
                        transitionDuration: screenshotScrolled ? '0.3s' : '0.6s',
                        transitionTimingFunction: 'cubic-bezier(1, 0, 0.64, 0.64)',
                        opacity: screenshotScrolled ? 1 : 0
                      }}
                      className="js-shadow-top absolute w-full h-8 top-0 left-0 z-2 pointer-events-none gradient-to-t from-transparent to-navy-50 opacity-0"
                    />
                  </>
                )}
              </div>
              <div
                className="template-hint absolute z-4 top-1/2 left-0 right-0 w-full flex justify-center items-center duration-700 opacity-0 transform group-hover:opacity-100 group-hover:-translate-y-4 pointer-events-none"
                aria-hidden="true"
              >
                <span className="radius-full bg-navy-800 bg-opacity-80 py-2.5 px-4 inline-flex justify-center items-center gap-1">
                  <IconEyeFilled width={20} height={20} />
                  <span className="color-white text-md">{t('Preview')}</span>
                </span>
              </div>
              {theme === 'classic' && (
                <div
                  className={`js-placeholder absolute z-1 w-full h-full top-0 lef-0 bg-no-repeat bg-size-cover bg-center duration-200 ${imageLoaded ? 'opacity-0' : 'opacity-1'}`}
                  style={{
                    backgroundImage: 'url("https://cdn.jotfor.ms/assets/img/templates/placeholder-form-new.svg")'
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className='template-item-titleurl truncate max-w-full'>
          <h3
            className="category-box-title max-w-sm font-medium color-navy-700 truncate mt-4 mb-2 text-lg"
            onClick={() => handlePreviewTemplate('viewTemplateFromTitle')}
            onKeyDown={() => handlePreviewTemplate('viewTemplateFromTitle')}
          >
            {!isSkeleton && _title}
          </h3>
        </div>
        <div className="category-box-button-wrapper space-y-2 w-full mt-6">
          <button
            type="button"
            className="useTemplate cta-usetemplate"
            onClick={templateCloneWrapper}
          >
            <span>
              { t(customCloneText || 'Use Template') }
            </span>
          </button>
          <button
            className="previewCta"
            type="button"
            onClick={() => handlePreviewTemplate('viewTemplateFromPreviewCta')}
          >
            <span>{t('Preview')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormTemplateItem;
FormTemplateItem.defaultProps = {
  context: '',
  useSlider: false,
  customCloneText: ''
};
FormTemplateItem.propTypes = {
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  templateCloneWrapper: func.isRequired,
  handlePreviewTemplate: func.isRequired,
  theme: string.isRequired,
  context: string,
  useSlider: bool,
  customCloneText: string
};
