import React, { ReactElement } from 'react';
import { IconBuildings, IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { translationRenderer, t } from '@jotforminc/translation';

const GatewayDisabledWarning = (): ReactElement => {
  return (
    <div className='bg-gray-500 mt-3 p-2 radius text-xs'>
      <div className='color-white flex justify-start gap-1 items-center'>
        <IconInfoCircleFilled className='shrink-0 w-4 h-4' />
        {t('Gateway disabled!')}
      </div>
      <div className='flex items-center gap-1 mt-1 ml-5 color-gray-100'>
        {
          translationRenderer('This gateway is disabled by your [1[{organization}]]')({
            renderer1: () => (
              <span className='color-orange-400 flex items-center gap-1'>
                <IconBuildings className="color-current shrink-0 w-4 h-4" />
                {' '}
                Organization
              </span>
            )
          })
        }
      </div>
    </div>
  );
};

export default GatewayDisabledWarning;
