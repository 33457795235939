import { requestLayer } from './requestLayer';

/**
 * Moves resources from an old connection to a new connection.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.oldConnectionID - The ID of the old connection.
 * @param {string} params.newConnectionID - The ID of the new connection.
 * @returns {Promise<any>} A promise that resolves when the resources have been moved.
 */
export const moveResourcesToNewConnection = ({
  oldConnectionID,
  newConnectionID
}: {
  oldConnectionID: string;
  newConnectionID: string;
}): Promise<any> => {
  const postData = new FormData();
  postData.append('oldConnectionID', oldConnectionID);
  postData.append('newConnectionID', newConnectionID);

  return requestLayer.post('/API/payment/connection/edit', postData);
};
