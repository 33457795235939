import React, { useMemo } from 'react';
import {
  shape, string, func, arrayOf, oneOf, oneOfType, bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { propTypes } from '@jotforminc/constants';

import { EmptyList, NotFoundList } from '@jotforminc/empty-list';
import { SmartList } from '@jotforminc/smart-list';
import FormListWrapper from '../FormPicker/FormList';
import { resourceConfigs } from './constants';
import { theme as defaultTheme } from '../FormPicker/helpers/defaultTheme';
import ConditionalModal from '../FormPicker/ConditionalModal';
import '../../styles/jfResourcePicker.scss';

export const getResourcePickerMappings = resourceType => {
  const { [resourceType]: config } = resourceConfigs;
  return {
    emptyListPlaceholderTitle: config?.emptyListPlaceholderTitle || '',
    emptyListPlaceholderIcon: config?.emptyListPlaceholderIcon || (() => null),
    emptyListPlaceholderDescription: config?.emptyListPlaceholderDescription || '',
    searchPlaceholder: config?.searchPlaceholder || '',
    ItemComponent: config?.ItemComponent || (() => null),
    fuseOptions: config?.defaultFuseOptions || {},
    notFoundIcon: config?.notFoundIcon || (() => null),
    notFoundTitle: 'No results found.',
    notFoundDescription: 'We couldn\'t find any matching items.'
  };
};

const ResourcePicker = ({
  theme,
  resourceType,
  resources,
  selectedResources,
  ItemComponent: PropItemComponent,
  onSelectionChange,
  DataProvider,
  searchPlaceholder: propSearchPlaceholder,
  emptyListPlaceholder: PropEmptyListPlaceholder,
  notFoundListPlaceholder: PropNotFoundListPlaceholder,
  searchKeys,
  isMultiSelect,
  showSelectAll,
  defaultItemIsOnTop,
  isModal,
  isModalOpen,
  isClosable,
  onClose,
  multipleManage,
  showHeader,
  isEditable,
  fuseOptions: PropFuseOptions,
  customItemsList,
  productType,
  sortMenu,
  filterForm,
  ...rest
}) => {
  const items = useMemo(() => {
    if (!defaultItemIsOnTop || typeof selectedResources !== 'string') return resources;
    return [...resources].sort((first, second) => {
      // Sort propItems such that default item will be on top
      if (first?.id === selectedResources) return -1;
      if (second?.id === selectedResources) return 1;
      return 0;
    });
  }, [defaultItemIsOnTop, resources]);

  const config = useMemo(() => getResourcePickerMappings(resourceType), [resourceType]);
  const ItemComponent = useMemo(() => PropItemComponent || config.ItemComponent, [PropItemComponent, config]);
  const searchPlaceholder = useMemo(() => t(propSearchPlaceholder || config.searchPlaceholder), [propSearchPlaceholder, config]);
  const fuseOptions = useMemo(() => PropFuseOptions || config.fuseOptions, [PropFuseOptions, config]);

  const EmptyListPlaceholder = useMemo(() => {
    if (PropEmptyListPlaceholder) {
      return PropEmptyListPlaceholder;
    }
    const { emptyListPlaceholderResourceType: resource, emptyListPlaceholderTitle: title, emptyListPlaceholderDescription: description } = config;
    return (
      <EmptyList
        resourceType={resource}
        title={title}
        description={description}
      />
    );
  }, [PropEmptyListPlaceholder, config]);

  const NotFoundListPlaceholder = useMemo(() => {
    if (PropNotFoundListPlaceholder) {
      return PropNotFoundListPlaceholder;
    }
    const { emptyListPlaceholderResourceType: resource, notFoundTitle: title, notFoundDescription: description } = config;
    return (
      <NotFoundList
        resourceType={resource}
        title={title}
        description={description}
      />
    );
  }, [PropNotFoundListPlaceholder, config]);

  return (
    <ConditionalModal
      isModal={isModal}
      isModalOpen={isModalOpen}
      isClosable={isClosable}
      onClose={onClose}
    >
      <div className={[
        'jfResourcePicker',
        'pm-b',
        sortMenu && 'hasSort',
        filterForm && 'hasFilter',
        (sortMenu || filterForm) && 'hasOptions'
      ].filter(Boolean).join(' ')}
      >
        <SmartList
          theme={theme}
          items={items}
          ItemComponent={ItemComponent}
          ListWrapper={FormListWrapper}
          searchKeys={searchKeys}
          searchPlaceholder={searchPlaceholder}
          defaultValue={selectedResources}
          emptyListPlaceholder={EmptyListPlaceholder}
          notFoundListPlaceholder={NotFoundListPlaceholder}
          isMultiSelect={isMultiSelect}
          showSelectAll={showSelectAll}
          showSelectedItemCount={false}
          onSelectionChange={onSelectionChange}
          DataProvider={DataProvider}
          isNewBranding={true}
          defaultItemIsOnTop={defaultItemIsOnTop}
          isItemEditable={isEditable}
          fuseOptions={fuseOptions}
          customItemsList={customItemsList}
          features={{
            multipleManage,
            showHeader,
            sortMenu,
            filterForm
          }}
          productType={productType}
          {...rest}
        />
      </div>
    </ConditionalModal>
  );
};

ResourcePicker.propTypes = {
  resourceType: oneOf(Object.keys(resourceConfigs)).isRequired,
  resources: arrayOf(shape({})),
  ItemComponent: propTypes.renderable,
  bulkActions: propTypes.renderable,
  onSelectionChange: func,
  DataProvider: func,
  searchPlaceholder: string,
  emptyListPlaceholder: propTypes.renderable,
  notFoundListPlaceholder: propTypes.renderable,
  theme: shape({}),
  selectedResources: oneOfType([arrayOf(string), string]),
  searchKeys: arrayOf(string),
  isMultiSelect: bool,
  showSelectAll: bool,
  defaultItemIsOnTop: bool,
  multipleManage: bool,
  showHeader: bool,
  isSortable: bool,
  SortableProps: shape({}),
  isModal: bool,
  isModalOpen: bool,
  isClosable: bool,
  onClose: func,
  isEditable: bool,
  otherControlBarItems: propTypes.renderable,
  showFavorite: bool,
  showFormId: bool,
  submissionsKeyword: string,
  fuseOptions: shape({}),
  customItemsList: arrayOf(shape({
    CustomItemComponent: propTypes.renderable,
    props: shape({})
  })),
  ControlBarRenderer: propTypes.renderable,
  productType: string,
  sortMenu: bool,
  filterForm: bool
};

ResourcePicker.defaultProps = {
  resources: [],
  ItemComponent: null,
  bulkActions: null,
  onSelectionChange: f => f,
  DataProvider: undefined,
  searchPlaceholder: undefined,
  emptyListPlaceholder: null,
  notFoundListPlaceholder: null,
  theme: defaultTheme,
  selectedResources: [],
  searchKeys: ['title', 'name', 'id'],
  isMultiSelect: false,
  showSelectAll: false,
  defaultItemIsOnTop: true,
  multipleManage: true,
  showHeader: false,
  isSortable: false,
  SortableProps: {},
  isModal: false,
  isModalOpen: false,
  isClosable: true,
  onClose: f => f,
  isEditable: false,
  otherControlBarItems: [],
  showFavorite: false,
  showFormId: false,
  submissionsKeyword: 'Submissions',
  fuseOptions: null,
  customItemsList: [],
  ControlBarRenderer: undefined,
  productType: '',
  sortMenu: false,
  filterForm: false
};

export default ResourcePicker;
