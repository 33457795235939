import React, { memo, useCallback } from 'react';
import { string } from 'prop-types';
import { ReactSVG } from 'react-svg';

const SvgErrorFallback = () => <img src="https://www.jotform.com/images/image_placeholder.png" alt="error-placeholer" />;

export const SVGIcon = ({
  url, iconColor, ...props
}) => {
  return (
    <ReactSVG
      src={url}
      afterInjection={useCallback((error, svg) => {
        if (error) return;
        const _svg = svg;
        _svg.style.fill = iconColor;
      }, [iconColor])}
      beforeInjection={useCallback(svg => {
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
      }, [])}
      fallback={SvgErrorFallback}
      renumerateIRIElements={false}
      wrapper="div"
      className="jfIconSVG-wrapper"
      {...props}
    />
  );
};

SVGIcon.propTypes = {
  url: string,
  iconColor: string
};

SVGIcon.defaultProps = {
  url: '',
  iconColor: ''
};

export default memo(SVGIcon);
