import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Square gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlSquare: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_square: {
    location: {
      title: 'Business Location',
      type: 'dropdown',
      subType: 'dynamicDropdown',
      default: '',
      resources: ['FORM', 'APP']
    },
    sendReceipt: {
      title: 'Send Payment Receipt',
      type: 'toggle',
      description: 'Send an email with a link to the Square receipt to the customer.',
      selectedCondition: settings => String(settings.sendReceipt) === 'true',
      valueTrue: 'true',
      valueFalse: 'false',
      default: 'false',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      description: 'Authorize the card now to charge it manually later. Expires after 6 days.',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    emailField: {
      title: 'Customer Email',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: { prop: 'paymentType', operator: '===', value: 'subscription' },
      autoSave: true
    },
    orderFulfillmentType: {
      title: 'Order Fulfillment Type',
      type: 'dropdown',
      options: [
        { id: 'PICKUP', key: 'PICKUP', text: 'Pickup' },
        { id: 'SHIPMENT', key: 'SHIPMENT', text: 'Shipment' }
      ],
      default: 'Pickup',
      resources: ['FORM', 'APP'],
      renderCondition: { prop: 'paymentType', operator: '!==', value: 'subscription' }
    },
    title: {
      type: 'title',
      title: 'Payment Methods',
      resources: ['FORM', 'APP'],
      requiredCondition: true,
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      }
    },
    showCard: {
      text: 'Credit Card',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      },
      disableCondition: () => true,
      lockedCondition: () => true
    },
    showGooglePay: {
      text: 'Google Pay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      },
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD', 'GBP', 'CAD'].includes(settings.currency) || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showApplePay: {
      text: 'Apple Pay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      },
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showCashApp: {
      text: 'Cash App Pay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      },
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD'].includes(settings.currency) || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showACH: {
      text: 'ACH Bank Transfer',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' },
          { prop: 'jotformEnv', operator: '!==', value: 'enterprise' }
        ]
      },
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD'].includes(settings.currency) || settings.paymentType === 'subscription' || settings.authOnly === 'Yes'),
      lockedCondition: () => false
    },
    showAfterpay: {
      text: 'Afterpay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      },
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD', 'AUD', 'NZD', 'CAD'].includes(settings.currency) || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showClearpay: {
      text: 'Clearpay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'nameAPM', operator: '!==', value: 'CashApp' },
          { prop: 'nameAPM', operator: '!==', value: 'appleAndGooglePay' },
          { prop: 'nameAPM', operator: '!==', value: 'Afterpay' },
          { prop: 'nameAPM', operator: '!==', value: 'Clearpay' }
        ]
      },
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['GBP'].includes(settings.currency) || settings.paymentType === 'subscription' || settings.authOnly === 'Yes'),
      lockedCondition: () => false
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP'],
      renderCondition: {
        and: [
          { prop: 'showApplePay', operator: '!==', value: 'Yes' },
          { prop: 'showGooglePay', operator: '!==', value: 'Yes' },
          { prop: 'showCashApp', operator: '!==', value: 'Yes' },
          { prop: 'showACH', operator: '!==', value: 'Yes' },
          { prop: 'showAfterpay', operator: '!==', value: 'Yes' }
        ]
      }
    },
    paymentMethodsTitle: {
      title: 'Payment Methods Label Text',
      type: 'input',
      default: 'Payment Methods',
      resources: ['FORM', 'APP'],
      renderCondition: {
        or: [
          { prop: 'showApplePay', operator: '===', value: 'Yes' },
          { prop: 'showGooglePay', operator: '===', value: 'Yes' },
          { prop: 'showCashApp', operator: '===', value: 'Yes' },
          { prop: 'showACH', operator: '===', value: 'Yes' },
          { prop: 'showAfterpay', operator: '===', value: 'Yes' }
        ]
      }
    }
  }
};

export default controlSquare;
