import Styled from 'styled-components';

export const ScAppThemesPanel = Styled.div`
${
  ({
    appCoverBgColor, appHeaderBgColor, appIconBackground, appFontFamily, appFontColor, appBgColor, appBgColorEnd, listBgColor, itemBorderColor, itemBgColor, isSelected
  }) => {
    return `
      display: inline-block;
      background-color: #485068;
      border-radius: 4px;
      padding: 3px;
      width: calc(50% - 10px);
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;

      &:nth-child(even) { margin-right: 10px; }
      &:nth-child(odd) { margin-left: 10px; }

      &:hover {
        .theme {
          &-overlay {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        &:nth-child(even) { margin-right: 0; }
        &:nth-child(odd) { margin-left: 0; }
      }

      .theme {
        position: relative;
        max-height: 110px;
        overflow: hidden;
        ${(appBgColor === appBgColorEnd || appBgColor === '') ? `background: ${appBgColor};` : ''}
        ${(appBgColor && appBgColorEnd) ? `background: linear-gradient(${appBgColor}, ${appBgColorEnd});` : ''}

        &-cover {
          position: absolute;
          height: 29px;
          width: 100%;
          ${appCoverBgColor ? `background-color: ${appCoverBgColor};` : ''}
        }

        &-header {
          position: relative;
          height: 45px;
          width: 90px;
          margin: 12px auto 5px;
          overflow: auto;
          ${appHeaderBgColor ? `background-color: ${appHeaderBgColor};` : ''}

          &-logo {
            width: 19px;
            height: 19px;
            margin: 6px auto 1px;
            ${appIconBackground ? `background-color: ${appIconBackground};` : ''}
          }

          &-title {
            font-size: 13px;
            margin: 0 auto 3px;
            text-align: center;
            ${appFontFamily ? `font-family: ${appFontFamily};` : ''}
            ${appFontColor ? `color: ${appFontColor};` : ''}
          }
        }

        &-itemList {
          position: relative;
          height: 63px;
          width: 90px;
          margin: 0 auto;
          ${listBgColor ? `background-color: ${listBgColor};` : ''}
          overflow: auto;
          padding: 10px 8px;

          &-item {
            width: 100%;
            height: 13px;
            border: 1px solid;
            ${itemBorderColor ? `border-color: ${itemBorderColor};` : ''}
            margin: 8px 0;
            ${itemBgColor ? `background-color: ${itemBgColor};` : ''}
            &:first-child { margin-top: 0px; }
          }
        }

        &-name {
          font-size: 12px;
          margin: 9px 7px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0;
          pointer-events: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          ${isSelected ? 'background-color: rgba(36,43,62,.4);' : 'background-color: transparent;'}
          transition: .3s all ease;
          border-radius: 4px;
          border: 1.5px solid #3468E7;

          & > button.panel {
            width: 140px;

            &:nth-child(2) {
              margin-top: 9px;
            }
          }
        }
      }
    `;
  }
}
`;
