import { STATES } from '@jotforminc/constants';

export function addressHintStringToObject(hintString, isStateDropdown = false) {
  const hintsArray = hintString.split(',');
  if (isStateDropdown) {
    if (hintsArray.length === 4) { // s1,s2,city,zip
      return {
        addr_line1: hintsArray[0],
        addr_line2: hintsArray[1],
        city: hintsArray[2],
        state: '',
        state_select: '',
        postal: hintsArray[3],
        country_select: '',
        search: ''
      };
    }
    if (hintsArray.length === 5) { // s1,s2,city,zip,search
      return {
        addr_line1: hintsArray[0],
        addr_line2: hintsArray[1],
        city: hintsArray[2],
        state: '',
        state_select: '',
        postal: hintsArray[3],
        country_select: '',
        search: hintsArray[4]
      };
    }
    if (hintsArray.length === 6) { // s1,s2,city,zip,search,country
      return {
        addr_line1: hintsArray[0],
        addr_line2: hintsArray[1],
        city: hintsArray[2],
        state: '',
        state_select: '',
        postal: hintsArray[3],
        country_select: hintsArray[5],
        search: hintsArray[4]
      };
    }
  } else {
    if (hintsArray.length === 5) { // s1,s2,city,state,zip
      return {
        addr_line1: hintsArray[0],
        addr_line2: hintsArray[1],
        city: hintsArray[2],
        state: hintsArray[3],
        state_select: '',
        postal: hintsArray[4],
        country_select: '',
        search: ''
      };
    }
    if (hintsArray.length === 6) { // s1,s2,city,state,zip,search
      return {
        addr_line1: hintsArray[0],
        addr_line2: hintsArray[1],
        city: hintsArray[2],
        state: hintsArray[3],
        state_select: '',
        postal: hintsArray[4],
        country_select: '',
        search: hintsArray[5]
      };
    }
    if (hintsArray.length === 7) { // s1,s2,city,state,zip,search,country
      return {
        addr_line1: hintsArray[0],
        addr_line2: hintsArray[1],
        city: hintsArray[2],
        state: hintsArray[3],
        state_select: '',
        postal: hintsArray[4],
        country_select: hintsArray[6],
        search: hintsArray[5]
      };
    }
  }

  if (hintsArray.length !== 8) {
    return {
      addr_line1: null,
      addr_line2: null,
      city: null,
      state: null,
      state_select: null,
      postal: null,
      country_select: null,
      search: null
    };
  }

  return { // s1,s2,city,state,state_select,zip,country_select,search
    addr_line1: hintsArray[0],
    addr_line2: hintsArray[1],
    city: hintsArray[2],
    state: hintsArray[3],
    state_select: hintsArray[4],
    postal: hintsArray[5],
    country_select: hintsArray[6],
    search: hintsArray[7]
  };
}

export function addressHintObjectToString(hintObject = {}) {
  return [
    hintObject.addr_line1,
    hintObject.addr_line2,
    hintObject.city,
    hintObject.state,
    hintObject.state_select,
    hintObject.postal,
    hintObject.country_select,
    hintObject.search
  ].join(',');
}

export const stateOptionsArray = () => {
  return Object.entries(STATES).reduce((options, [country, value]) => {
    return {
      ...options,
      [country]: {
        controls: 'dropdown',
        value: value || []
      }
    };
  }, {});
};
