import React, {
  useRef, useImperativeHandle, forwardRef, useEffect
} from 'react';
import {
  arrayOf, shape, string, func, bool, oneOfType, objectOf
} from 'prop-types';
import classNames from 'classnames';
import { Popover, Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { getPlaceholderListWithStaticFields, preparePlaceholderDynamicList } from '@jotforminc/utils';
import { STATIC_FIELDS_WITH_PLACEHOLDER } from '@jotforminc/constants';

import { IconLinkDiagonal } from '@jotforminc/svg-icons';
import {
  SingleForm, MultipleForms
} from './components';

import './styles/FormFields.scss';

const FormFields = forwardRef(({
  usedFromWorkflow,
  anotherFieldDropdown,
  defaultValue,
  questions,
  onChange,
  addStaticFields,
  staticFields,
  hasForm,
  formTitle,
  isSingle,
  menuTargetRef,
  isButtonVisible,
  fieldsLabelText,
  useMultipleForms,
  formArray,
  isDark,
  usePortal,
  popoverClassName,
  showOptionValue,
  showProtectedTag,
  onVisibilityChange,
  duplicatedEmails,
  isEncrypted
}, ref) => {
  const menuRef = useRef();
  const buttonRef = useRef();
  const onButtonClickCallbackRef = useRef(null);
  const [isMenuVisible, setMenuVisibility] = Hooks.useClickOutsideState(false, [menuRef, buttonRef]);
  const isFormFieldsDisabledForWorkflow = value => duplicatedEmails && value && duplicatedEmails?.length > 0 && duplicatedEmails.includes(value);
  const getOptions = (formQuestions, opts = {}) => {
    const { staticFields: staticFieldsOptions = staticFields } = opts;
    const questionsArray = Object.values(formQuestions);
    const placeholderList = addStaticFields
      ? getPlaceholderListWithStaticFields({
        questions: questionsArray, includeSeparator: false, staticFields: staticFieldsOptions, anotherFieldDropdown, usedFromWorkflow
      })
      : preparePlaceholderDynamicList(questionsArray, anotherFieldDropdown, usedFromWorkflow);
    // 'protected' is a reserved word in strict mode. so mapeed it to isProtected field
    return placeholderList.map(({
      text, placeholder: value, disabled, isProtected
    }) => ({
      text, value, disabled: isFormFieldsDisabledForWorkflow(value) || disabled, isProtected: isProtected && showProtectedTag
    }));
  };

  const handleButtonClick = () => {
    onButtonClickCallbackRef.current?.();
    setMenuVisibility(!isMenuVisible);
  };

  useImperativeHandle(ref, () => ({
    setMenuVisibility,
    getIsMenuVisible: () => isMenuVisible,
    setOnButtonClickCallback: callback => {
      onButtonClickCallbackRef.current = callback;
    }
  }));

  useEffect(() => {
    onVisibilityChange(isMenuVisible);
  }, [isMenuVisible]);

  return (
    <div className="formFields">
      {isButtonVisible && (
        <button
          className={`formFields-button${isMenuVisible ? ' isMenuVisible' : ''}`}
          type="button"
          ref={buttonRef}
          onClick={handleButtonClick}
        >
          <div className="formFields-icon-wrapper">
            <IconLinkDiagonal className="formFields-icon" />
          </div>
          <div className="formFields-text">
            {t(fieldsLabelText)}
          </div>
        </button>
      )}
      {isMenuVisible && (
        <Popover
          ref={menuRef}
          className={classNames('formFields-popover', popoverClassName)}
          targetRef={menuTargetRef || buttonRef}
          usePortal={usePortal || useMultipleForms}
          popoverOptions={{ placement: 'bottom-end' }}
        >
          {useMultipleForms ? (
            <MultipleForms
              optionParser={getOptions}
              formArray={formArray}
              hasForm={hasForm}
              isSingle={isSingle}
              setMenuVisibility={setMenuVisibility}
              defaultValue={defaultValue}
              onChange={onChange}
              staticFields={staticFields}
              isDark={isDark}
            />
          ) : (
            <SingleForm
              questions={getOptions(questions)}
              formTitle={formTitle}
              hasForm={hasForm}
              isSingle={isSingle}
              setMenuVisibility={setMenuVisibility}
              defaultValue={defaultValue}
              onChange={onChange}
              showOptionValue={showOptionValue}
              isEncrypted={isEncrypted}
            />
          )}
        </Popover>
      )}
    </div>
  );
});

const questionsPropType = oneOfType([
  arrayOf(shape({ text: string, qid: string })),
  objectOf(shape({ text: string, qid: string }))
]);

FormFields.propTypes = {
  usedFromWorkflow: bool,
  anotherFieldDropdown: bool,
  defaultValue: arrayOf(string),
  questions: questionsPropType,
  addStaticFields: bool,
  staticFields: arrayOf(shape({})),
  isSingle: bool,
  onChange: func,
  hasForm: bool,
  formTitle: string,
  menuTargetRef: shape({}),
  isButtonVisible: bool,
  fieldsLabelText: string,
  useMultipleForms: bool,
  formArray: arrayOf(shape({})),
  isDark: bool,
  usePortal: bool,
  popoverClassName: string,
  showOptionValue: bool,
  showProtectedTag: bool,
  onVisibilityChange: func,
  duplicatedEmails: shape(),
  isEncrypted: bool
};

FormFields.defaultProps = {
  usedFromWorkflow: false,
  anotherFieldDropdown: false,
  defaultValue: [],
  questions: [],
  addStaticFields: false,
  staticFields: STATIC_FIELDS_WITH_PLACEHOLDER,
  isSingle: false,
  onChange: f => f,
  hasForm: true,
  formTitle: '',
  menuTargetRef: null,
  isButtonVisible: true,
  fieldsLabelText: 'Form Fields',
  useMultipleForms: false,
  formArray: [],
  isDark: false,
  usePortal: false,
  popoverClassName: null,
  showOptionValue: false,
  showProtectedTag: false,
  onVisibilityChange: () => null,
  duplicatedEmails: [],
  isEncrypted: false
};

export default FormFields;
