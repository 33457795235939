import FormItem from '../FormPicker/FormItem';
import PortalOption from '../PortalPicker/defaults/PortalOption';
import SheetItem from './items/Sheet';
import ReportsItem from './items/Reports';
import ApprovalItem from './items/Approval';
import SignItem from './items/Sign';
import OperationItem from './items/Operation';
import BoardItem from './items/Board';

import NoFormsFoundIcon from '../../assets/svg/resourcePickerIcons/noFormsFoundIcon.svg';
import NoAppsFoundIcon from '../../assets/svg/resourcePickerIcons/noAppsFoundIcon.svg';
import NoSheetsFoundIcon from '../../assets/svg/resourcePickerIcons/noTablesFoundIcon.svg';
import NoReportsFoundIcon from '../../assets/svg/resourcePickerIcons/noReportsFoundIcon.svg';
import NoApprovalsFoundIcon from '../../assets/svg/resourcePickerIcons/noApprovalsFoundIcon.svg';
import NoSignDocumentsFoundIcon from '../../assets/svg/resourcePickerIcons/noSignDocumentsFoundIcon.svg';
import NoBoardsFoundIcon from '../../assets/svg/resourcePickerIcons/noBoardsFoundIcon.svg';

import NoFormsIcon from '../../assets/svg/resourcePickerIcons/noFormsIcon.svg';
import NoAppsIcon from '../../assets/svg/resourcePickerIcons/noAppsIcon.svg';
import NoTablesIcon from '../../assets/svg/resourcePickerIcons/noTablesIcon.svg';
import NoReportsIcon from '../../assets/svg/resourcePickerIcons/noReportsIcon.svg';
import NoApprovalsIcon from '../../assets/svg/resourcePickerIcons/noApprovalsIcon.svg';
import NoSignDocumentsIcon from '../../assets/svg/resourcePickerIcons/noSignDocumentsIcon.svg';
import NoBoardsIcon from '../../assets/svg/resourcePickerIcons/noBoardsIcon.svg';

export const RESOURCE_TYPES = {
  FORM: 'form',
  APP: 'app',
  SHEET: 'sheet',
  REPORT: 'report',
  APPROVAL: 'approval',
  SIGN: 'sign',
  SIGN_INBOX: 'signInbox',
  OPERATION: 'operation',
  BOARD: 'board'
};

export const resourceConfigs = {
  [RESOURCE_TYPES.FORM]: {
    ItemComponent: FormItem,
    searchPlaceholder: 'Search in your forms',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY FORMS YET.',
    emptyListPlaceholderIcon: NoFormsIcon,
    emptyListPlaceholderDescription: 'Your forms will appear here.',
    emptyListPlaceholderResourceType: 'form',
    headerTitle: 'Select a form',
    notFoundIcon: NoFormsFoundIcon,
    defaultFuseOptions: { threshold: 0.5 }
  },
  [RESOURCE_TYPES.APP]: {
    ItemComponent: PortalOption,
    searchPlaceholder: 'Search in your apps',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY APPS YET.',
    emptyListPlaceholderIcon: NoAppsIcon,
    emptyListPlaceholderDescription: 'Your apps will appear here.',
    emptyListPlaceholderResourceType: 'portal',
    headerTitle: 'Select an app',
    notFoundIcon: NoAppsFoundIcon
  },
  [RESOURCE_TYPES.SHEET]: {
    ItemComponent: SheetItem,
    searchPlaceholder: 'Search in your tables',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY TABLES YET.',
    emptyListPlaceholderIcon: NoTablesIcon,
    emptyListPlaceholderDescription: 'Your tables will appear here.',
    emptyListPlaceholderResourceType: 'table',
    headerTitle: 'Select a table',
    notFoundIcon: NoSheetsFoundIcon
  },
  [RESOURCE_TYPES.REPORT]: {
    ItemComponent: ReportsItem,
    searchPlaceholder: 'Search in your reports',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY REPORTS YET.',
    emptyListPlaceholderIcon: NoReportsIcon,
    emptyListPlaceholderDescription: 'Your reports will appear here.',
    emptyListPlaceholderResourceType: 'report',
    headerTitle: 'Select a report',
    notFoundIcon: NoReportsFoundIcon
  },
  [RESOURCE_TYPES.SIGN]: {
    ItemComponent: SignItem,
    searchPlaceholder: 'Search in sign documents',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY SIGN DOCUMENTS YET.',
    emptyListPlaceholderIcon: NoSignDocumentsIcon,
    emptyListPlaceholderDescription: 'Your sign documents will appear here.',
    emptyListPlaceholderResourceType: 'sign',
    headerTitle: 'Select a sign document',
    notFoundIcon: NoSignDocumentsFoundIcon
  },
  [RESOURCE_TYPES.SIGN_INBOX]: {
    ItemComponent: SignItem,
    searchPlaceholder: 'Search in sign documents',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY SIGN DOCUMENTS YET.',
    emptyListPlaceholderIcon: NoSignDocumentsIcon,
    emptyListPlaceholderDescription: 'Your sign documents will appear here.',
    emptyListPlaceholderResourceType: 'sign',
    headerTitle: 'Select a sign document',
    notFoundIcon: NoSignDocumentsFoundIcon
  },
  [RESOURCE_TYPES.APPROVAL]: {
    ItemComponent: ApprovalItem,
    searchPlaceholder: 'Search in your approvals',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY APPROVALS YET.',
    emptyListPlaceholderIcon: NoApprovalsIcon,
    emptyListPlaceholderDescription: 'Your approvals will appear here.',
    emptyListPlaceholderResourceType: 'approval',
    headerTitle: 'Select an approval',
    notFoundIcon: NoApprovalsFoundIcon
  },
  [RESOURCE_TYPES.OPERATION]: {
    ItemComponent: OperationItem,
    searchPlaceholder: 'Search in your operations',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY OPERATIONS YET.',
    emptyListPlaceholderIcon: NoFormsIcon,
    emptyListPlaceholderDescription: 'Your operations will appear here.',
    emptyListPlaceholderResourceType: 'form',
    headerTitle: 'Select an operation',
    notFoundIcon: NoFormsFoundIcon
  },
  [RESOURCE_TYPES.BOARD]: {
    ItemComponent: BoardItem,
    searchPlaceholder: 'Search in your boards',
    emptyListPlaceholderTitle: 'YOU DON\'T HAVE ANY BOARDS YET.',
    emptyListPlaceholderIcon: NoBoardsIcon,
    emptyListPlaceholderDescription: 'Your boards will appear here.',
    emptyListPlaceholderResourceType: 'board',
    headerTitle: 'Select board',
    notFoundIcon: NoBoardsFoundIcon
  }
};
