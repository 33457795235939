export const INIT_PRODUCTS = '@PRODUCTS/INIT_PRODUCTS';
export const CREATE_PRODUCT = '@PRODUCTS/CREATE_PRODUCT';
export const UPDATE_PRODUCT = '@PRODUCTS/UPDATE_PRODUCT';
export const REMOVE_PRODUCT = '@PRODUCTS/REMOVE_PRODUCT';
export const SORT_PRODUCTS = '@PRODUCTS/SORT_PRODUCTS';

export const UPDATE_PRODUCT_LIST_SETTINGS = '@PRODUCT_LIST/UPDATE_PRODUCT_LIST_SETTINGS';

export const INIT_SUBSCRIPTIONS = '@SUBSCRIPTIONS/INIT_SUBSCRIPTIONS';
export const CREATE_SUBSCRIPTION = '@SUBSCRIPTIONS/CREATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = '@SUBSCRIPTIONS/UPDATE_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = '@SUBSCRIPTIONS/REMOVE_SUBSCRIPTION';

export const INIT_UI = '@UI/INIT_UI';
export const CHANGE_ACTIVE_PANEL = '@UI/CHANGE_ACTIVE_PANEL';
export const CHANGE_ACTIVE_EDITOR = '@UI/CHANGE_ACTIVE_EDITOR';
export const CHANGE_ACTIVE_TAB = '@UI/CHANGE_ACTIVE_TAB';
export const CHANGE_GATEWAY_SETTINGS_VISIBILIY = '@UI/CHANGE_GATEWAY_SETTINGS_VISIBILIY';
export const CHANGE_GATEWAY_SETTINGS_VIEW = '@UI/CHANGE_GATEWAY_SETTINGS_VIEW';
export const UPDATE_TEMPORARY_ACTIVE_GATEWAY_SETTINGS = '@UI/UPDATE_TEMPORARY_ACTIVE_GATEWAY_SETTINGS';
export const CHANGE_SELECTED_OPTION = '@UI/CHANGE_SELECTED_OPTION';
export const CHANGE_ACTIVE_APM_SELECTION = '@UI/CHANGE_ACTIVE_APM_SELECTION';
export const CHANGE_SELECTED_GATEWAY_WITH_APM = '@UI/CHANGE_SELECTED_GATEWAY_WITH_APM';
export const CHANGE_CONNECTION_MODAL_PROP_VALUES = '@UI/CHANGE_CONNECTION_MODAL_PROP_VALUES';

export const SET_ACTIVE_PRODUCT = '@ACTIVE_PRODUCT/SET_ACTIVE_PRODUCT';
export const UPDATE_ACTIVE_PRODUCT = '@ACTIVE_PRODUCT/UPDATE_ACTIVE_PRODUCT';

export const INIT_USER = '@USER/INIT_USER';

export const SET_ACTIVE_CONNECTION = '@ACTIVE_CONNECTION/SET_ACTIVE_CONNECTION';

export const INIT_CONNECTIONS = '@CONNECTIONS/INIT_CONNECTIONS';
export const CREATE_CONNECTION = '@CONNECTIONS/CREATE_CONNECTION';
export const UPDATE_CONNECTION = '@CONNECTIONS/UPDATE_CONNECTION';
export const REMOVE_CONNECTION = '@CONNECTIONS/REMOVE_CONNECTION';

export const INIT_ACTIVE_GATEWAY_SETTINGS = '@ACTIVE_GATEWAY_SETTINGS/INIT';
export const SET_ACTIVE_GATEWAY_SETTINGS = '@ACTIVE_GATEWAY_SETTINGS/SET';
export const UPDATE_ACTIVE_GATEWAY_SETTINGS = '@ACTIVE_GATEWAY_SETTINGS/UPDATE';

export const INIT_CHECKOUT_FORM_QUESTIONS = '@CHECKOUT_FORM/INIT_QUESTIONS';
export const UPDATE_CHECKOUT_FORM_QUESTION_VISIBILITY = '@CHECKOUT_FORM/UPDATE_QUESTION_VISIBILITY';
