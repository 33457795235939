/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { sanitize } from 'dompurify';
import { IconMagnifyingGlass } from '@jotforminc/svg-icons';
import { classNameGenerator } from '@jotforminc/utils';
import { getDeviceType } from '../getDeviceType';

import { isHomepage } from '../utils';
import { logSearchAction } from '../api';
import { ScSearch } from './ScSearch';
import { templateConstants } from '../templateConstants';

const Search = ({ props, sidebarSearch }) => {
  const {
    getTemplates, searchKeyword, searchResult, setSearchResult, isMyTemplates, username,
    setSelectedCategory, setSearchKeyword, stickySearchWrapper, setTemplates, setStandaloneTemplates, templateType,
    selectedCategory, setIsAllCategoriesSelected, setIsMyTemplates, isAllCategoriesSelected, source
  } = props;

  const handleSearch = () => {
    const isEmpty = searchKeyword === '';
    setTemplates([]);
    setStandaloneTemplates([]);
    if (!isEmpty) logSearchAction(username, searchKeyword, templateType, source);
    setSearchResult({ searchView: !isEmpty, searchedKeyword: searchKeyword });
    getTemplates({ searchKeywordParameter: searchKeyword });
    setSelectedCategory();
    setIsMyTemplates(false);
    setIsAllCategoriesSelected(false);
    window.standaloneTemplatesLogger({
      actor: username,
      target: searchKeyword,
      action: `${getDeviceType()}:search`
    });
  };

  useEffect(() => {
    window.handleSearch = handleSearch;
    return () => {
      window.handleSearch = undefined;
    };
  }, [searchKeyword]);

  const handleFocusSearch = () => {
    if (global?.standaloneTemplatesLogger) {
      global?.standaloneTemplatesLogger({
        actor: username,
        action: `${getDeviceType()}:searchBox-click`
      });
    }
  };

  const { searchPlaceholder = 'Search in all templates' } = templateConstants[templateType];
  return (
    <ScSearch
      className={classNameGenerator({
        'search forSidebar': sidebarSearch,
        isSticky: stickySearchWrapper,
        showMobile: (!isHomepage(isMyTemplates, selectedCategory, searchResult.searchView, isAllCategoriesSelected) && !sidebarSearch)
      })}
    >
      <>
        <div className="searchWrapper">
          <input
            type="search"
            className="input"
            aria-label={searchPlaceholder}
            value={searchKeyword}
            onFocus={handleFocusSearch}
            placeholder={t(searchPlaceholder)}
            onChange={e => setSearchKeyword(sanitize(e.target.value))}
            onKeyDown={e => e.key === 'Enter' && handleSearch()}
          />
        </div>
        <button className="go" onClick={handleSearch} type="button">
          <span className="sr-only">{t('Search')}</span>
          <IconMagnifyingGlass width="20" height="20" />
        </button>
      </>
    </ScSearch>
  );
};

export default Search;
