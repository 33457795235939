import React from 'react';
import { string } from 'prop-types';
import { classNames } from '@jotforminc/utils';

import { IconXmark } from '@jotforminc/svg-icons';
import texts from '../../core/texts';
import Button from '.';

export default class CloseButton extends React.Component {
  render() {
    const {
      className, label, ...props
    } = this.props;
    return (
      <Button
        className={classNames('isRounded', 'forClose', className)}
        aria-label={texts.CLOSE}
        label={label}
        data-testid="closeButton"
        {...props}
      >
        <IconXmark className="closeButton-svg" />
      </Button>
    );
  }
}

CloseButton.propTypes = {
  className: string,
  label: string
};

CloseButton.defaultProps = {
  className: null,
  label: texts.CLOSE
};
