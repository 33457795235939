import React from 'react';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';

const GatewayNotConnectedWarning = (): JSX.Element => {
  return (
    <div className="paymentConnectionWarningContainer">
      <span className="paymentConnectionWarningArrow" />
      <IconInfoCircleFilled className="h-5 w-5 shrink-0" />
      <span className="paymentConnectionWarningText">{t('Add a payment connection to start collecting payments.')}</span>
    </div>
  );
};

export default GatewayNotConnectedWarning;
