import React from 'react';
import {
  boolean, string, oneOfType, arrayOf, shape, node
} from 'prop-types';

const WizardWrapper = ({
  children, withContainer, wrapperRestProps, className
}) => {
  return withContainer ? (
    <div
      className={className}
      {...wrapperRestProps}
      role="dialog"
    >
      {children}
    </div>
  ) : children;
};

WizardWrapper.propTypes = {
  withContainer: boolean,
  className: string,
  children: oneOfType([
    arrayOf(node),
    node
  ]),
  wrapperRestProps: shape({})
};

WizardWrapper.defaultProps = {
  withContainer: true,
  className: '',
  children: null,
  wrapperRestProps: {}
};

export default WizardWrapper;
