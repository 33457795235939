import React, { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';
import { IconCheckCircleFilled, IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import {
  FormControlContext, commonInputDefaults, useFormControl
} from '../../contexts';
import { FormControlProps, FormHelperProps, FormLabelProps } from './form-control.types';
import {
  formLabelDescCVA, formLabelCVA, formHelperTextCVA
} from './form-control.cva';
import { primary } from '../../tokens/colors';

export const FormControl = (props: PropsWithChildren<FormControlProps>):JSX.Element => {
  const {
    className,
    children,
    ...rest
  } = props;

  const formControlClassName = cx(className, 'magnet-form-control space-y-2');

  return (
    <FormControlContext.Provider value={rest}>
      <div className={formControlClassName}>
        {children}
      </div>
    </FormControlContext.Provider>
  );
};

FormControl.defaultProps = commonInputDefaults;

export const FormLabel = (props: PropsWithChildren<FormLabelProps>):JSX.Element => {
  const {
    id,
    theme,
    size,
    required
  } = useFormControl<FormLabelProps>(props);

  const { children, description, ...rest } = props;

  const formLabelClassName = formLabelCVA({ theme, size, required });
  const formLabelDescClassName = formLabelDescCVA({ theme, size });

  return (
    <label htmlFor={id} {...rest}>
      <span className={formLabelClassName}>
        {children}
        {required && <span className={primary.error.default.color.base}> *</span>}
      </span>
      {description && <span className={formLabelDescClassName}>{description}</span>}
    </label>
  );
};

export const FormHelperText = (props: PropsWithChildren<FormHelperProps>):JSX.Element => {
  const {
    theme,
    colorStyle
  } = useFormControl<FormHelperProps>(props);

  const { children, ...rest } = props;
  const formHelperTextClassName = formHelperTextCVA({ theme, colorStyle });
  const HelperIcon = colorStyle === 'success' ? IconCheckCircleFilled : IconExclamationCircleFilled;

  return (
    <p className={formHelperTextClassName} {...rest}>
      <HelperIcon className="w-4 h-4 shrink-0" />
      {children}
    </p>
  );
};
