import {
  bool, elementType, string
} from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import NoImage from './NoImage';

const Image = ({
  imageURL, altText, showAltTextActiveOnError, PlaceholderRenderer
}) => {
  const [useFallback, setUseFallback] = useState(false);

  useEffect(() => {
    setUseFallback(false);
  }, [imageURL]);

  if (useFallback) {
    return <NoImage altText={altText} showAltText={showAltTextActiveOnError} {...(!imageURL && { PlaceholderRenderer })} />;
  }

  return (
    <img
      src={imageURL}
      alt={altText}
      onError={() => { setUseFallback(true); }}
      className="imageElementImg"
    />
  );
};

Image.propTypes = {
  imageURL: string,
  altText: string,
  showAltTextActiveOnError: bool,
  PlaceholderRenderer: elementType
};

Image.defaultProps = {
  imageURL: '',
  altText: '',
  showAltTextActiveOnError: true,
  PlaceholderRenderer: undefined
};

export default memo(Image);
