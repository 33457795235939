import React, { useCallback, useMemo } from 'react';
import {
  elementType, number, string, func, shape
} from 'prop-types';
import { SVGIcon } from '@jotforminc/icon-selector';
import { t } from '@jotforminc/translation';

import ScImagePreview from './sc/ScImagePreview';
import Image from './Image';

const IMAGE_TYPE = {
  icon: 'LOGO/ICON',
  image: 'LOGO/IMAGE'
};

const ImagePreview = ({
  ImagePreviewWrapper,
  ImageWrapper,
  RemoveButtonWrapper,
  RemoveButtonRenderer,
  RightSideWrapper,
  imageURL,
  imageHint,
  imageType,
  onRemove,
  svgProps,
  altText,
  layout,
  id
}) => {
  const isImageIcon = useMemo(() => imageType === IMAGE_TYPE.icon, [imageType]);
  const isLayoutLine = useMemo(() => layout === 'line', [layout]);

  const PreviewImage = useCallback(() => {
    return (
      <ImageWrapper>
        {isImageIcon ? (
          <SVGIcon
            url={imageURL}
            {...svgProps}
          />
        ) : (
          <Image imageURL={imageURL} altText={t(altText)} showAltTextActiveOnError={false} />
        )}
      </ImageWrapper>
    );
  }, [isImageIcon, imageURL, altText, svgProps]);

  const ImageHint = useCallback(() => {
    return (imageHint && isLayoutLine) && (
    <span className="previewHint" title={imageHint}>{imageHint}</span>
    );
  }, [imageHint, isLayoutLine]);

  const RemoveButton = useCallback(() => {
    return (
      <RemoveButtonWrapper>
        <RemoveButtonRenderer
          onClick={() => onRemove(id)}
          data-testid="reset-image"
        />
      </RemoveButtonWrapper>
    );
  }, [onRemove, RemoveButtonRenderer, id]);

  return (
    <ImagePreviewWrapper layout={layout}>
      <PreviewImage />
      <RightSideWrapper>
        <ImageHint />
        <RemoveButton />
      </RightSideWrapper>
    </ImagePreviewWrapper>
  );
};

ImagePreview.propTypes = {
  id: number,
  imageURL: string,
  imageHint: string,
  imageType: string,
  onRemove: func,
  svgProps: shape({}),
  altText: string,
  ImagePreviewWrapper: elementType,
  ImageWrapper: elementType,
  RemoveButtonWrapper: elementType,
  RemoveButtonRenderer: elementType,
  RightSideWrapper: elementType,
  layout: string
};

ImagePreview.defaultProps = {
  id: 0,
  imageURL: '',
  imageHint: '',
  imageType: IMAGE_TYPE.image,
  onRemove: f => f,
  svgProps: {},
  altText: t('preview'),
  ImagePreviewWrapper: ScImagePreview,
  ImageWrapper: props => <div className="previewMode-image" {...props} />,
  RemoveButtonWrapper: props => <div className="previewMode-button" {...props} />,
  RemoveButtonRenderer: props => <button type="button" {...props}>{t('Remove Image')}</button>,
  RightSideWrapper: props => <div className="rightSideWrapper" {...props} />,
  layout: 'column'
};

export default ImagePreview;
