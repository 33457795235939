import React from 'react';
import { func, shape, string } from 'prop-types';
import { Wizard, NavigationWizardTemplate as UseTemplate } from '@jotforminc/wizard-shared';
import { StartFromScratch } from '@jotforminc/create-new-wizards';
import { t } from '@jotforminc/translation';
import { ScFormTemplates } from '../styles/scSelect';

const SelectTemplate = ({
  handleClone, handleFormCreate, handlePickerClose, user, templateType, source
}) => {
  const templateHeaderTextKeys = { form: 'form', sign: 'sign document' };
  const templateHeaderTextKey = templateHeaderTextKeys[templateType];
  const templateHeaderSubText = `You don't have any ${templateHeaderTextKey}. You can select a ${templateHeaderTextKey} from templates to use in your app`;

  const templatePath = `/${templateType}-templates/standalone`;
  return (
    <ScFormTemplates>
      <Wizard
        user={user}
        source={source}
        closeable={true}
        hiddenHeader={false}
        isMobileApp={false}
        DefaultView='UseTemplate'
        backButtonVisible={false}
        onHide={handlePickerClose}
        templatePath={templatePath}
        hideOnEscapeKeyPress={false}
        Navigation={{ StartFromScratch, UseTemplate }}
        initialVisibilityState={true}
        onFormCreate={handleFormCreate}
        customHandleClone={handleClone}
        templateHeaderText={t('Use from template')}
        templateHeaderSubText={templateHeaderSubText}
      />
    </ScFormTemplates>
  );
};

SelectTemplate.propTypes = {
  user: shape({}),
  handleClone: func,
  handleFormCreate: func,
  templateType: string,
  handlePickerClose: func,
  source: string
};

SelectTemplate.defaultProps = {
  user: {},
  source: '',
  handleClone: f => f,
  handleFormCreate: f => f,
  templateType: undefined,
  handlePickerClose: f => f
};

export default SelectTemplate;
