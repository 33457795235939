import React from 'react';
import {
  arrayOf, node, oneOfType, string, bool
} from 'prop-types';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import '@jotforminc/jotform.css';

function EmptyListBase({
  icon,
  title,
  description,
  isTitleUppercase
}) {
  return (
    <div className="mt-20 text-center select-none">
      {icon}
      <div className={classNames('text-lg font-medium color-navy-700 mb-2 mt-9', { 'text-uppercase': isTitleUppercase })}>{t(title)}</div>
      <div className="text-lg font-normal color-navy-300">{t(description)}</div>
    </div>
  );
}

EmptyListBase.propTypes = {
  icon: oneOfType([arrayOf(node), node]),
  title: string,
  description: string,
  isTitleUppercase: bool
};

EmptyListBase.defaultProps = {
  icon: null,
  title: null,
  description: null,
  isTitleUppercase: true
};

export default EmptyListBase;
