import React, { useCallback, useMemo } from 'react';
import { elementType, func, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import RadioToggle from '../../../RadioToggle';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import { isYes } from '../../../../../../utils';

const ShrinkToggle = ({
  title, description, DescriptionRenderer, value: shrink, embeddedForm, onChange
}) => {
  const tooltipText = useMemo(() => (isYes(embeddedForm) ? t('This can’t be enabled If show form is enabled') : null), [embeddedForm]);

  const handleChange = useCallback(val => {
    onChange({ shrink: val });
  }, [onChange]);

  return (
    <div>
      <RadioToggle
        value={shrink}
        onChange={handleChange}
        title={title}
        description={description}
        DescriptionRenderer={DescriptionRenderer}
        readOnly={isYes(embeddedForm)}
        tooltipText={tooltipText}
        tooltipTextAlign='center'
        tooltipTextZindex={2}
      />
    </div>
  );
};

ShrinkToggle.propTypes = {
  title: string,
  description: string,
  DescriptionRenderer: elementType,
  value: string,
  onChange: func,
  embeddedForm: string
};

ShrinkToggle.defaultProps = {
  title: '',
  description: '',
  DescriptionRenderer: PanelItemDescriptionRenderer,
  value: 'No',
  onChange: f => f,
  embeddedForm: 'No'
};

export default ShrinkToggle;
