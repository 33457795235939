/**
 * Get gateway type.
 *
 * @param type - Gateway question type.
 * @param nameAPM - Gateway APM type.
 */

import type {
  PAYMENT_FIELDS,
  PAYMENT_APM_NAMES,
  PAYMENT_APM_FIELDS
} from '@jotforminc/payment-constants';

type T_PARENT_APM_CONTROL_TYPES = Partial<{
  [key in PAYMENT_APM_NAMES]: PAYMENT_FIELDS;
}>;

const PARENT_APM_CONTROL_TYPES: T_PARENT_APM_CONTROL_TYPES = {
  appleAndGooglePay: 'control_applePayGooglePay'
};

export const getGatewayType = (
  type: PAYMENT_FIELDS,
  nameAPM: PAYMENT_APM_NAMES
): PAYMENT_FIELDS | PAYMENT_APM_FIELDS => {
  if (nameAPM) {
    const parentType = (nameAPM in PARENT_APM_CONTROL_TYPES) && PARENT_APM_CONTROL_TYPES[nameAPM];
    return parentType ? parentType : `control_${nameAPM.charAt(0).toLowerCase() + nameAPM.slice(1)}` as PAYMENT_APM_FIELDS;
  }

  return type;
};
