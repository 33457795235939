import React, { useRef } from 'react';
import { bool, func, string } from 'prop-types';
import { Button } from '@jotforminc/magnet';
import { IconArrowDownToLine } from '@jotforminc/svg-icons';
import { isPressedKeyEnterOrSpace } from '../../../utils';

const FileInput = ({
  putFileInput, translate, buttonText, ...inputProps
}) => {
  const inputRef = useRef();
  const isBrowserMsEdge = global.isMsEdge && global.isMsEdge === true;
  const handleLabelClick = () => isBrowserMsEdge && inputRef.current && inputRef.current.click();
  const handleLabelKeyDown = event => isPressedKeyEnterOrSpace(event) && handleLabelClick();
  const onUploadBtnClick = () => inputRef.current.click();

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          onUploadBtnClick();
          handleLabelClick();
        }}
        onKeyDown={handleLabelKeyDown}
        startIcon={IconArrowDownToLine}
        className='fileInputBtn min-h-8 h-auto py-3'
        aria-label={translate(buttonText)}
      >
        {translate(buttonText)}
      </Button>
      {putFileInput && (
        <input
          type="file"
          ref={inputRef}
          {...inputProps}
        />
      )}
    </>
  );
};

FileInput.propTypes = {
  buttonText: string,
  putFileInput: bool,
  translate: func
};

FileInput.defaultProps = {
  buttonText: 'Upload File',
  putFileInput: true,
  translate: f => f
};

export default FileInput;
