import { elementType, func } from 'prop-types';
import React from 'react';

const LoadingScreen = ({
  Wrapper, translate
}) => {
  return (
    <Wrapper>
      {translate('Uploading...')}
    </Wrapper>
  );
};

LoadingScreen.propTypes = {
  Wrapper: elementType,
  translate: func
};

LoadingScreen.defaultProps = {
  Wrapper: props => <div {...props} />,
  translate: f => f
};

export default LoadingScreen;
