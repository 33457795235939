import Styled from 'styled-components';
import { iconSelector, tabMenu, imageUpload } from '../../../styles/scRightPanel';

export const SCDialogRenderer = Styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(37, 45, 91, 0.7);
  z-index: 2000;
  div[role="dialog"] {
    display: flex;
    height: 100%;
    overflow: auto;
    padding: 32px;
  }
`;

export const SCContentRenderer = Styled.div`
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(10, 11, 13, 0.08), 0px 20px 32px 0px rgba(10, 11, 13, 0.32);
  width: 640px;
  border-radius: 8px;
  margin: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SCViewWrapper = Styled.div`
  padding: 32px;
  flex: 1;
  overflow: auto;
  ${iconSelector}
  ${tabMenu}
  ${imageUpload}

  .icon-selector-wrap {
    min-height: 469px;
  }
`;

export const SCHeaderRenderer = Styled.div`
  padding: 24px 32px;
  border-bottom: 1px solid #E3E5F5;
  display: flex;
  align-items: center;
`;

export const SCHeaderTitle = Styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SCHeaderTitleIcon = Styled.div`
  display: flex;
  background: #0A1551;
  border-radius: 4px;
  color: #fff;
  padding: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SCHeaderTitleContent = Styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  html[dir='rtl'] & {
    padding-right: 0;
    padding-left: 24px;
  }
  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.252px;
    color: #0A1551;
  }
  span {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.084px;
    color: #6C73A8;
  }
`;

export const SCHeaderTitleClose = Styled.div`
  margin-left: auto;
  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }
`;
export const SCFooterRenderer = Styled.div`
  padding: 16px 32px;
  border-top: 1px solid #E3E5F5;
  display: flex;
  justify-content: end;
  gap: 16px;
`;
