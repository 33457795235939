export const initializeValueList = elementListMap => elementListMap
  .filter(([, { static: isStaticField }]) => !isStaticField)
  .reduce((prev, [key, { componentProps: { defaultValue, value } = {} }]) => ({ ...prev, [key]: defaultValue || value }), {});

export const generateListUpdaterByMethod = ({
  list,
  setList,
  methodKey,
  baseMethod
}) => (key, { [methodKey]: onChange = f => f }) => {
  return (value, ...parameters) => {
    onChange(...parameters);
    const newValueList = { ...list, [key]: value };
    setList(newValueList);

    const baseKeyValuePair = { key, value };
    const keyValuePair = parameters.find(parameter => parameter.key && parameter.value);
    baseMethod(newValueList, keyValuePair || baseKeyValuePair);
  };
};

export const extendProps = ({ props, ...remainingProps }) => ({
  ...(typeof props === 'function' ? props(remainingProps) : props),
  ...remainingProps
});

export const getValueFromEvent = event => {
  if (typeof event === 'string') { return event; }
  if (Array.isArray(event)) {
    return event;
  }
  if (typeof event === 'object' && event.target) {
    return event?.target?.value;
  }
  return event;
};
