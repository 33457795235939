import {
  string, elementType, func, number
} from 'prop-types';
import React, {
  forwardRef, memo, useCallback, useRef, useState
} from 'react';
import { Hooks } from '@jotforminc/uikit';
import styled from 'styled-components';
import { Loading } from '../../../../components/Loading';

const ScIframeRenderer = styled.iframe`
  display: block;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: 0.3s opacity ease;
`;

const ScLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ScIframeContent = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  flex: 1;
`;

const IframeRenderer = forwardRef(({
  reloadKey, pageURL, title, LoaderRenderer, onLoad, scrolling, ...props
}, ref) => {
  const [isPageLoaded, setPageLoaded] = useState(false);
  const [canScrolling, setCanScrolling] = useState(false);
  const isAnotherPageLoaded = useRef(false);

  const handlePageLoaded = useCallback(e => {
    setPageLoaded(true);
    onLoad(e);
    if (isAnotherPageLoaded.current && !canScrolling) {
      setCanScrolling(true);
    }
    isAnotherPageLoaded.current = true;
  }, [isAnotherPageLoaded]);
  Hooks.useEffectIgnoreFirst(() => setPageLoaded(false), [reloadKey]);

  return (
    <ScIframeContent>
      {!isPageLoaded && <LoaderRenderer />}
      <ScIframeRenderer
        key={reloadKey}
        ref={ref}
        title={title}
        src={pageURL}
        loaded={isPageLoaded}
        onLoad={handlePageLoaded}
        allowtransparency="true"
        allowFullScreen={true}
        allow="geolocation; microphone; camera"
        scrolling={canScrolling ? 'auto' : scrolling}
        {...props}
      />
    </ScIframeContent>
  );
});

IframeRenderer.propTypes = {
  reloadKey: number,
  pageURL: string,
  title: string,
  LoaderRenderer: elementType,
  onLoad: func,
  scrolling: string
};

IframeRenderer.defaultProps = {
  reloadKey: 0,
  pageURL: '',
  title: '',
  LoaderRenderer: ScLoading,
  onLoad: f => f,
  scrolling: 'auto'
};

export default memo(IframeRenderer);
