import Styled, { css } from 'styled-components';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';

export const ScLogo = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  ${isFeatureEnabled(FEATURE_NAMES.AppLogoSize) ? css`
  border-radius: 0;
  & > * {
    border-radius: 0;
  }
  ` : `
  border-radius: 14px;
  & > * {
    border-radius: 4px;
  }
  `}

  img {
    width: 100%;
    height: auto;
  }

  ${({ withBorder }) => {
    return withBorder ? `
      border: 1px solid #D3DCEF;
    ` : '';
  }}
`;

export const ScAppLogo = Styled.div`
  ${ScLogo}
  ${({ isSVG, bgColor }) => (isSVG ? `
    background: ${bgColor};
    ${isFeatureEnabled(FEATURE_NAMES.AppLogoSize) ? `
      border-radius: 14px;
      width: 80px;
      height: 80px;
    ` : ''}
    ` : '')}
`;
