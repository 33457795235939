/* eslint-disable complexity */
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { Component } from 'react';
import clsx from 'clsx';
import { Portal } from '@jotforminc/magnet';
import GroupOptionRenderer from './GroupOptionRenderer';

import './style.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.stopKeyEvent = this.stopKeyEvent.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.createTags = this.createTags.bind(this);

    this.inputRef = React.createRef();
    this.listRef = React.createRef();
    this.searchRef = React.createRef();
    this.optionListRef = React.createRef();
    this.areAllOptionsDisabled = this.flattenOptions(props.data).every(({ isDisabled }) => isDisabled);

    this.state = {
      isOpen: false,
      currentOptionIndex: 0,
      searchTerm: '',
      filteredOptions: props.data || [],
      shouldRenderOptions: false
    };
  }

  componentDidMount() {
    const { searchable } = this.props;
    document.addEventListener('scroll', this.handleScroll, true);
    document.addEventListener('click', this.handleClickOutside, true);
    window.addEventListener('resize', this.handleResize);

    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('keyup', this.handleKey);
      this.inputRef.current.addEventListener('keydown', this.stopKeyEvent);
      this.inputRef.current.addEventListener('keypress', this.handleKey);
    }

    if (this.listRef.current) this.listRef.current.addEventListener('keyup', this.stopKeyEvent);

    if (this.searchRef.current && searchable) {
      this.searchRef.current.addEventListener('keydown', this.handleKey);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, searchable } = this.props;
    const { data: prevData } = prevProps;
    const { isOpen: prevIsOpen } = prevState;
    const { searchTerm, isOpen, shouldRenderOptions } = this.state;

    if (prevData !== data && shouldRenderOptions) {
      this.setState({ filteredOptions: this.filterOptions(data, searchTerm) });
    }

    if (isOpen && !prevIsOpen) {
      this.setState({
        shouldRenderOptions: true,
        filteredOptions: this.filterOptions(data, searchTerm)
      });

      if (searchable && this.searchRef?.current) {
        this.searchRef.current.focus();
      }
    } else if (!isOpen && prevIsOpen && searchable) {
      this.searchRef.current.value = '';
      this.setState({
        searchTerm: '',
        shouldRenderOptions: false,
        filteredOptions: []
      });
    }
  }

  componentWillUnmount() {
    const { searchable } = this.props;
    document.removeEventListener('click', this.handleClickOutside, true);
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('scroll', this.handleScroll);

    this.inputRef.current.removeEventListener('keyup', this.handleKey);

    this.listRef.current.removeEventListener('keyup', this.stopKeyEvent);
    this.inputRef.current.removeEventListener('keydown', this.stopKeyEvent);
    this.inputRef.current.removeEventListener('keypress', this.handleKey);
    if (searchable && this.searchRef?.current) {
      this.searchRef.current.removeEventListener('keydown', this.handleKey);
    }
  }

  handleClickOutside(e) {
    const { isOpen } = this.state;
    const isClickedInput = this.inputRef.current && this.inputRef.current.contains(e.target);
    const isClickedWrapper = this.listRef.current && this.listRef.current.contains(e.target);

    if (isClickedInput || isClickedWrapper) return;
    if (isOpen) this.hideDropdown(true);
  }

  handleInputClick() {
    const { isOpen } = this.state;
    const {
      disabled,
      onInputClick,
      autoFocus,
      listAutoFocus
    } = this.props;

    if (!disabled) {
      if (!isOpen) {
        this.openDropdown();
        onInputClick();
        if (this.listRef.current && (autoFocus || listAutoFocus)) {
          this.listRef.current.focus();
        }
      }
      this.hideDropdown();
    }
  }

  handleResize() {
    const { isOpen } = this.state;

    const minKeyboardHeight = 300;
    const visualViewportHeight = window?.visualViewport?.height || window.screen.height;
    const isKeyboardOpen = window.screen.height - minKeyboardHeight > visualViewportHeight;

    if (isOpen && !isKeyboardOpen) this.hideDropdown();
  }

  handleScroll(e) {
    const { isOpen } = this.state;
    const { isMobile } = this.props;
    const isDisabledScrollHandler = isMobile ? this.searchRef.current !== document.activeElement : true;
    if (e.target !== this.optionListRef.current && isDisabledScrollHandler && isOpen) this.hideDropdown();
  }

  handleKey(event) {
    const { isKeyboardHandlingActive, disabled, defaultOptionClass } = this.props;
    const { isOpen, currentOptionIndex, filteredOptions } = this.state;

    if (isKeyboardHandlingActive && !disabled) {
      if (event.key === 'Enter') {
        event.stopPropagation();

        if (isOpen) {
          const currentOption = this.flattenOptions(filteredOptions)[currentOptionIndex];
          const searchRegExp = /\s/g; // replacing all ' ' with '.'
          const replaceWith = '.';
          if (currentOption) {
            const currentOptionEl = this.listRef?.current?.querySelector(`.${defaultOptionClass.replace(searchRegExp, replaceWith)}[value="${currentOption.value}"]`);
            currentOptionEl?.focus();
            currentOptionEl?.click();
          } else {
            this.hideDropdown();
          }
        }
      } else if (event.key === 'ArrowDown') {
        if (isOpen) {
          event.preventDefault();
          this.handleArrowKey(1);
        } else {
          this.openDropdown();
        }
      } else if (event.key === 'ArrowUp') {
        if (isOpen) {
          event.preventDefault();
          this.handleArrowKey(-1);
        }
      }
    }
  }

  handleArrowKey(indicator) {
    if (this.areAllOptionsDisabled) return;
    const { defaultOptionClass } = this.props;
    this.setState(state => {
      const flattenedOptions = this.flattenOptions(state.filteredOptions);
      const newIndex = (state.currentOptionIndex + indicator + flattenedOptions.length) % flattenedOptions.length;
      return { currentOptionIndex: newIndex };
    }, () => {
      const { currentOptionIndex, filteredOptions } = this.state;
      const { value, isDisabled } = this.flattenOptions(filteredOptions)[currentOptionIndex];
      if (isDisabled) {
        this.handleArrowKey(indicator);
        return;
      }
      const searchRegExp = /\s/g; // replacing all ' ' with '.'
      const replaceWith = '.';
      const currentOptionEl = this.listRef?.current?.querySelector(`.${defaultOptionClass.replace(searchRegExp, replaceWith)}[value="${value}"]`);
      currentOptionEl?.scrollIntoView({ block: 'nearest' });
    });
  }

  handleSearch(e) {
    const { data, onSearch } = this.props;
    const { value } = e.target;

    this.setState({ filteredOptions: this.filterOptions(data, value), searchTerm: value });
    setTimeout(() => { this.handleDropdownPosition(); }, 0);
    onSearch(value);
  }

  handleSearchClear() {
    const value = '';
    this.searchRef.current.value = value;
    this.handleSearch({ target: { value } });
  }

  handleDropdownPosition() {
    const {
      dropdownLeft,
      dropdownTop,
      dropdownWidth,
      getDropdownPosition,
      isLite,
      dropdownPositionResolver
    } = this.props;
    const listRefStyle = this.listRef?.current?.style || {};

    listRefStyle.opacity = 1;
    listRefStyle.visibility = 'visible';
    listRefStyle.width = `${this.inputRef?.current?.offsetWidth}px`;

    if (isLite) { return; }

    const {
      left = 0,
      top = 0,
      width = 0,
      height = 0,
      right = 0
    } = this.inputRef?.current?.getBoundingClientRect() || {};

    const {
      offsetHeight: listRefHeight = 0
    } = this.listRef.current || {};

    let positions = {};
    const topOffset = height + top;
    const totalHeight = topOffset + listRefHeight;

    positions.left = left;
    positions.width = width;

    if (totalHeight > window.innerHeight) {
      positions.top = top - listRefHeight;
      this.listRef?.current?.setAttribute('data-position', 'top');
      this.inputRef?.current?.setAttribute('data-position', 'top');
      getDropdownPosition('top');
    } else {
      positions.top = topOffset;
      this.listRef?.current?.setAttribute('data-position', 'bottom');
      this.inputRef?.current?.setAttribute('data-position', 'bottom');
      getDropdownPosition('bottom');
    }

    if (dropdownPositionResolver && typeof dropdownPositionResolver === 'function') {
      positions = {
        ...positions,
        ...dropdownPositionResolver({
          left, top, width, height, right
        })
      };
    }

    listRefStyle.top = dropdownTop
      ? `${dropdownTop}px`
      : `${positions.top}px`;
    listRefStyle.left = dropdownLeft
      ? `${dropdownLeft}px`
      : `${positions.left}px`;
    listRefStyle.width = dropdownWidth
      ? `${dropdownWidth}px`
      : `${positions.width}px`;
  }

  handleOptionClick = option => e => {
    const { value } = e.currentTarget;
    const {
      multiple, isKeyboardHandlingActive, onOptionClick, autoFocus,
      sendFullOption, listAutoFocus
    } = this.props;

    if (isKeyboardHandlingActive) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    if (multiple) {
      setTimeout(() => {
        this.handleDropdownPosition();
      }, 0);
    } else {
      setTimeout(() => {
        this.hideDropdown();
      }, 0);
    }
    onOptionClick(sendFullOption ? option : value, e);
    if (this.inputRef.current && (autoFocus || listAutoFocus)) {
      this.inputRef.current.focus();
    }
  };

  handleRemoveTag(e) {
    const { onRemoveTag } = this.props;
    const { value } = e.target;
    onRemoveTag(value, e);
    e.stopPropagation();
  }

  handleMouseEnter(e) {
    const { target } = e;
    const { filteredOptions } = this.state;
    const newIndex = this.flattenOptions(filteredOptions).findIndex(opt => opt.value === target.value);
    this.setState({ currentOptionIndex: newIndex });
  }

  handleMouseLeave() {
    this.setState({ currentOptionIndex: -1 });
  }

  isValueInOption(option, value) {
    if (option?.searchTerm) {
      return option.searchTerm.toLowerCase().indexOf(value?.toLowerCase()) !== -1;
    }
    return typeof option.text === 'string' ? option.text?.toLowerCase().indexOf(value?.toLowerCase()) > -1 : false;
  }

  isLastElement(options, index) {
    return options?.length > 0 && options?.length > index + 1;
  }

  filterOptions(options, value) {
    return options.reduce((acc, currentOption) => {
      if (currentOption.type === 'group') {
        const groupOptions = this.filterOptions(currentOption.options, value);
        return groupOptions.length > 0 ? [...acc, { ...currentOption, options: groupOptions }] : acc;
      }

      return this.isValueInOption(currentOption, value) ? [...acc, currentOption] : acc;
    }, []);
  }

  flattenOptions(options) {
    return options.flatMap(option => {
      return option.type === 'group' ? (this.flattenOptions(option?.options) || []) : option;
    });
  }

  openDropdown() {
    const { isDropdownOpen, selectedOptionValues, multiple } = this.props;

    this.setState(state => {
      const flattenedOptions = this.flattenOptions(state.filteredOptions);
      const currentOptionIndex = multiple ? 0 : flattenedOptions.findIndex(opt => opt.value === selectedOptionValues[0]);
      return {
        isOpen: true,
        currentOptionIndex
      };
    });
    this.handleDropdownPosition();
    isDropdownOpen(true);
  }

  stopKeyEvent(event) {
    const { isKeyboardHandlingActive } = this.props;
    const { isOpen } = this.state;
    if (isKeyboardHandlingActive && isOpen) {
      event.stopPropagation();
    }
  }

  hideDropdown(noFocus = true) {
    const { isOpen } = this.state;
    const { isDropdownOpen } = this.props;

    if (!noFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }

    if (isOpen && this.listRef.current) {
      this.listRef.current.style.opacity = 0;
      this.listRef.current.style.visibility = 'hidden';

      this.setState({
        isOpen: false,
        currentOptionIndex: 0,
        shouldRenderOptions: false,
        filteredOptions: []
      });
      isDropdownOpen(false);
    }
  }

  createTags() {
    const {
      data, defaultTagClass, defaultRemoveButtonClass, tagClass, removeButtonClass, removeButtonIcon, selectedOptionStyles
    } = this.props;
    const { selectedOptionValues } = this.props;

    const removeButtonClasses = clsx(defaultRemoveButtonClass, removeButtonClass);

    return this.flattenOptions(data).map(option => {
      const {
        value, text, dataAttributes, key, additionalTagClass
      } = option;
      if (selectedOptionValues.indexOf(value) > -1) {
        return (
          <span className={clsx(defaultTagClass, tagClass, additionalTagClass)} style={selectedOptionStyles[value]} key={key || value}>
            {text}
            <button
              value={value}
              onClick={this.handleRemoveTag}
              className={removeButtonClasses}
              type="button"
              {...dataAttributes}
              aria-label="Remove"
            >
              {removeButtonIcon}
            </button>
          </span>
        );
      }
      return null;
    });
  }

  renderOptionComponent(option, name = 'beforeComponent') {
    return option && option[name] !== undefined ? option[name] : null;
  }

  renderOptionItem(option, index) {
    const {
      selectedOptionValues, defaultOptionClass,
      activeOptionClass, disabledOptionClass, hoverOptionClass,
      optionTextClass, inActiveOptionClass
    } = this.props;

    const { currentOptionIndex } = this.state;
    const isOptionSelected = selectedOptionValues.indexOf(option.value) > -1;
    return (
      <button
        key={option.value}
        type="button"
        className={clsx(defaultOptionClass, {
          [option.additionalClassName]: option.additionalClassName,
          [activeOptionClass]: isOptionSelected,
          [inActiveOptionClass]: !isOptionSelected,
          [disabledOptionClass]: option.isDisabled === true,
          [hoverOptionClass]: currentOptionIndex === index
        })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        title={option.title || option.text}
        onClick={this.handleOptionClick(option)}
        role="option"
        aria-selected={selectedOptionValues.indexOf(option.value) > -1}
        value={option.value}
        disabled={option.isDisabled && option.isDisabled === true ? 'true' : null}
        {...option.dataAttributes}
        aria-label={option.ariaLabel || option.text}
      >
        {this.renderOptionComponent(option)}
        {optionTextClass ? <span className={optionTextClass}>{option.text}</span> : option.text}
        {this.renderOptionComponent(option, 'afterComponent')}
      </button>
    );
  }

  renderOptionItemGroup(groupOption, groupIndex, isLastElement) {
    const {
      defaultOptionGroupTextClass, optionGroupWrapperClass,
      optionGroupTextClass, optionGroupClass
    } = this.props;

    const divider = groupOption.hideLabel && isLastElement;
    return (
      <div
        key={groupOption.key ? groupOption.key : groupOption.text}
        className={['jfSelect-optionGroup-wrapper', optionGroupWrapperClass].filter(Boolean).join(' ')}
        role="group"
        aria-label={groupOption.ariaLabel || groupOption.text}
      >
        { !groupOption.hideLabel && <span className={clsx(defaultOptionGroupTextClass, optionGroupTextClass)}>{groupOption.text}</span> }
        <div className={optionGroupClass}>
          { groupOption.options.map((option, index) => (option.type === 'group'
            ? this.renderOptionItemGroup(option, groupIndex + index, this.isLastElement(groupOption.options, index))
            : this.renderOptionItem(option, groupIndex + index))) }
        </div>
        { divider && <div className='h-px w-full bg-navy-100' /> }
      </div>
    );
  }

  renderMultipleGroupItem(option, index, isLastElement) {
    const {
      defaultOptionGroupTextClass, optionGroupWrapperClass,
      optionGroupTextClass, optionGroupClass
    } = this.props;

    const {
      text, color, options, collapsible, defaultExpandValue
    } = option;

    const { filteredOptions, searchTerm } = this.state;
    const isOptionFiltered = searchTerm && filteredOptions?.findIndex(filteredOption => filteredOption === option) > -1;

    if (collapsible) {
      return (
        <GroupOptionRenderer
          defaultOptionGroupTextClass={defaultOptionGroupTextClass}
          optionGroupWrapperClass={optionGroupWrapperClass}
          optionGroupTextClass={optionGroupTextClass}
          optionGroupClass={optionGroupClass}
          text={text}
          color={color}
          defaultExpandValue={isOptionFiltered || defaultExpandValue}
        >
          {options?.map((val, optionIndex) => (val.type === 'group'
            ? this.renderOptionItemGroup(val, index + optionIndex, this.isLastElement(options, optionIndex))
            : this.renderOptionItem(val, index + optionIndex)))}
        </GroupOptionRenderer>
      );
    }
    return (
      this.renderOptionItemGroup(option, index, isLastElement)
    );
  }

  renderOptions() {
    const {
      defaultNotResultFoundClass, notResultFoundClass, notResultFoundText,
      defaultOptionClass, dropdownOpenInEmail, data
    } = this.props;

    const { filteredOptions, shouldRenderOptions } = this.state;

    // Don't render options if shouldRenderOptions is false
    if (!shouldRenderOptions) {
      return null;
    }

    const notResultFoundClassses = clsx(defaultNotResultFoundClass, notResultFoundClass);

    if (!dropdownOpenInEmail && filteredOptions?.length === 0) {
      return <div className={notResultFoundClassses}>{notResultFoundText}</div>;
    }

    if (dropdownOpenInEmail && data?.length === 0) {
      return <div className={notResultFoundClassses}>{notResultFoundText}</div>;
    }

    const filteredValue = dropdownOpenInEmail ? data : filteredOptions;

    const { items } = filteredValue.reduce((result, current) => {
      switch (true) {
        case current?.showOutsideButton:
          return {
            itemIndex: result.itemIndex + 1,
            items: [
              ...result.items,
              (
                <div className={defaultOptionClass}>
                  {this.renderOptionComponent(current)}
                </div>
              )
            ]
          };
        case current.type === 'group':
          return {
            itemIndex: result.itemIndex + current.options.length,
            items: [...result.items, this.renderMultipleGroupItem(current, result.itemIndex, this.isLastElement(current.options, result.itemIndex))]
          };
        default:
          return { itemIndex: result.itemIndex + 1, items: [...result.items, this.renderOptionItem(current, result.itemIndex)] };
      }
    }, { itemIndex: 0, items: [] });

    return items;
  }

  renderInput() {
    const { isOpen } = this.state;
    const {
      inputAfter,
      inputBefore,
      inputClass,
      inputTextClass,
      tagWrapperClass,
      defaultInputClass,
      defaultInputTextClass,
      defaultOptionText,
      defaultOptionTitle,
      defaultTagWrapperClass,
      activeInputClass,
      selectedOptionValues,
      searchableClass,
      isDarkClass,
      disabledClass,
      multipleClass,
      multiple,
      searchable,
      disabled,
      data,
      inputTabIndex,
      isDark,
      autoFocus,
      defaultInnerInput,
      pdfOptions
    } = this.props;

    const inputClasses = clsx(defaultInputClass, inputClass, {
      [activeInputClass]: activeInputClass && isOpen,
      [multipleClass]: multiple,
      [searchableClass]: searchable,
      [disabledClass]: disabled,
      [isDarkClass]: isDark
    });

    const inputTextClasses = clsx(defaultInputTextClass, inputTextClass);
    const tagWrapperClasses = clsx(defaultTagWrapperClass, tagWrapperClass);

    if (multiple) {
      return (
        <span
          tabIndex={inputTabIndex}
          className={inputClasses}
          onClick={this.handleInputClick}
          data-selected={selectedOptionValues.length !== 0 ? true : null}
          ref={this.inputRef}
        >
          {inputBefore && inputBefore}
          <span className={tagWrapperClasses}>
            {selectedOptionValues.length === 0 ? defaultOptionText : this.createTags()}
          </span>
          {inputAfter && inputAfter}
        </span>
      );
    }
    const selectedOption = this.flattenOptions(data).find(option => selectedOptionValues[0] === option.value);
    const setOpen = () => this.setState({ isOpen: true });
    const hasNoOptions = pdfOptions?.length === 0;

    return (
      <button
        data-testid='wrapper-button'
        className={inputClasses}
        onClick={this.handleInputClick}
        ref={this.inputRef}
        tabIndex={inputTabIndex}
        data-selected={selectedOptionValues.length !== 0 || pdfOptions?.length > 0 ? true : null}
        type="button"
        aria-disabled={disabled}
        aria-haspopup="true"
        aria-expanded={isOpen}
        autoFocus={autoFocus}
        title={(selectedOptionValues.length === 0 || !selectedOption) ? (defaultOptionTitle || defaultOptionText) : (selectedOption.title || selectedOption.text)}
      >
        {inputBefore && inputBefore}
        {selectedOptionValues.length > 0 && this.renderOptionComponent(selectedOption)}
        <span className={inputTextClasses}>
          {(selectedOptionValues.length === 0 || !selectedOption) ? defaultOptionText : selectedOption.text}
        </span>
        {selectedOptionValues.length > 0 && this.renderOptionComponent(selectedOption, 'afterComponent')}
        {defaultInnerInput && pdfOptions?.length >= 0 && (
          <div className={`resourceDropdown-pill ${hasNoOptions ? 'isHollow' : ''}`} onKeyDown={hasNoOptions && setOpen} onClick={hasNoOptions && setOpen}>
            {hasNoOptions ? 'Select Document' : `${pdfOptions.length} document${pdfOptions.length > 1 ? 's' : ''} selected`}
          </div>
        )}
        {inputAfter && inputAfter}
      </button>
    );
  }

  render() {
    const { isOpen, searchTerm } = this.state;
    const {
      defaultWrapperClass,
      wrapperClass,
      activeWrapperClass,
      defaultListClass,
      listClass,
      liteClass,
      searchable,
      multiple,
      searchableClass,
      isDarkClass,
      isDark,
      multipleClass,
      defaultSearchBoxClass,
      searchBoxClass,
      defaultSearchInputClass,
      searchInputClass,
      searchInputPlaceholder,
      zIndex,
      isLite,
      listAriaLabel,
      searchIcon,
      useMagnetPortal,
      clearSearchButtonText
    } = this.props;

    const listClasses = clsx(defaultListClass, listClass);
    const wrapperClasses = clsx(defaultWrapperClass, wrapperClass, {
      [activeWrapperClass]: activeWrapperClass && isOpen,
      [multipleClass]: multiple,
      [searchableClass]: searchable,
      [isDarkClass]: isDark,
      [liteClass]: isLite
    });
    const searchBoxClasses = clsx(defaultSearchBoxClass, searchBoxClass);
    const searchInputClasses = clsx(defaultSearchInputClass, searchInputClass);

    const renderWrapper = () => {
      return (
        <div
          data-testid="dropdown-wrapper"
          className={wrapperClasses}
          ref={this.listRef}
          style={{
            position: isLite ? 'absolute' : 'fixed',
            left: isLite ? 'auto' : -999,
            top: isLite ? 'auto' : -999,
            opacity: 0,
            visibility: 'hidden',
            zIndex
          }}
          tabIndex="-1"
        >
          {searchable
            && (
              <div
                className={searchBoxClasses}
                role="searchbox"
                aria-label={searchInputPlaceholder || 'Search'}
              >
                <label htmlFor="jfSelect-searchInput" className="jfSelect-label">
                  <span className="sr-only">Search</span>
                </label>
                {searchIcon && searchIcon}
                <input
                  id="jfSelect-searchInput"
                  ref={this.searchRef}
                  className={searchInputClasses}
                  placeholder={searchInputPlaceholder}
                  type="search"
                  onChange={this.handleSearch}
                />
                {clearSearchButtonText && searchTerm !== '' && <button type="button" className="jfSelect-searchInputClear" onClick={this.handleSearchClear}>{clearSearchButtonText}</button>}
              </div>
            )}
          <div
            role="listbox"
            className={listClasses}
            ref={this.optionListRef}
            aria-label={listAriaLabel}
          >
            {this.renderOptions()}
          </div>
        </div>
      );
    };

    return (
      <>
        {isLite ? (
          <div className="jfSelect-liteContainer">
            {this.renderInput()}
            {renderWrapper()}
          </div>
        ) : (
          <>
            {this.renderInput()}
            {!useMagnetPortal ? createPortal(renderWrapper(), document.body) : <Portal>{renderWrapper()}</Portal>}
          </>
        )}
      </>
    );
  }
}

Dropdown.defaultProps = {
  defaultOptionText: 'Dropdown',
  defaultOptionTitle: 'Select',
  defaultInputClass: 'jfSelect-input',
  defaultInputTextClass: 'jfSelect-inputText',
  defaultWrapperClass: 'jfSelect-wrapper',
  defaultOptionClass: 'jfSelect-option',
  defaultOptionGroupTextClass: 'jfSelect-optionGroup',
  defaultListClass: 'jfSelect-list',
  defaultTagClass: 'jfSelect-tag',
  defaultTagWrapperClass: 'jfSelect-tagWrapper',
  defaultRemoveButtonClass: 'jfSelect-removeButton',
  defaultNotResultFoundClass: 'jfSelect-notResultFound',
  defaultSearchBoxClass: 'jfSelect-searchBox',
  defaultSearchInputClass: 'jfSelect-searchInput',
  defaultOptionTextClass: 'jfSelect-optionText',
  activeWrapperClass: 'isActive',
  activeInputClass: 'isActive',
  activeOptionClass: 'isActive',
  hoverOptionClass: 'isHover',
  searchableClass: 'isSearchable',
  multipleClass: 'isMultiple',
  disabledClass: 'isDisabled',
  disabledOptionClass: 'isDisabled',
  isDarkClass: 'isDark',
  liteClass: 'jfSelect-liteWrapper',
  notResultFoundText: 'No results found.',
  inActiveOptionClass: 'inActive',
  removeButtonIcon:
  <svg viewBox="0 0 212.982 212.982">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M131.804 106.491l75.936-75.936c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312
        0L106.491 81.18 30.554 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.989 6.99-6.989 18.323 0 25.312l75.937
        75.936-75.937 75.937c-6.989 6.99-6.989 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0l75.937-75.937
        75.937 75.937c6.989 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.322 0-25.312l-75.936-75.936z"
      clipRule="evenodd"
    />
  </svg>,
  inputClass: '',
  inputTextClass: '',
  wrapperClass: '',
  listClass: '',
  tagClass: '',
  tagWrapperClass: '',
  notResultFoundClass: '',
  searchBoxClass: '',
  searchInputClass: '',
  removeButtonClass: '',
  optionGroupWrapperClass: '',
  optionGroupTextClass: '',
  optionGroupClass: '',
  inputAfter: '',
  inputBefore: '',
  zIndex: 1000,
  optionTextClass: null,
  dropdownWidth: null,
  dropdownLeft: null,
  dropdownTop: null,
  multiple: false,
  disabled: false,
  isKeyboardHandlingActive: false,
  searchable: false,
  inputTabIndex: 0,
  searchInputPlaceholder: null,
  isDark: false,
  autoFocus: false,
  data: [],
  selectedOptionValues: [],
  selectedOptionStyles: {},
  onInputClick: f => f,
  onOptionClick: f => f,
  onRemoveTag: f => f,
  onSearch: f => f,
  isLite: false,
  defaultInnerInput: false,
  dropdownOpenInEmail: false,
  isDropdownOpen: f => f,
  getDropdownPosition: f => f,
  pdfOptions: null,
  listAriaLabel: null,
  sendFullOption: false,
  dropdownPositionResolver: null,
  listAutoFocus: false,
  isMobile: false,
  searchIcon: null,
  useMagnetPortal: false,
  clearSearchButtonText: null
};

const dataOptionType = PropTypes.shape({
  text: PropTypes.string,
  value: PropTypes.string,
  beforeComponent: PropTypes.node,
  afterComponent: PropTypes.node,
  additionalClassName: PropTypes.string
});

const dataOptionGroupType = PropTypes.shape({
  type: PropTypes.oneOf(['group']),
  text: PropTypes.string,
  options: PropTypes.arrayOf(dataOptionType),
  beforeComponent: PropTypes.node
});

Dropdown.propTypes = {
  defaultOptionText: PropTypes.string,
  defaultOptionTitle: PropTypes.string,
  defaultInputClass: PropTypes.string,
  defaultInputTextClass: PropTypes.string,
  defaultWrapperClass: PropTypes.string,
  defaultListClass: PropTypes.string,
  defaultOptionClass: PropTypes.string,
  defaultOptionGroupTextClass: PropTypes.string,
  defaultTagClass: PropTypes.string,
  defaultTagWrapperClass: PropTypes.string,
  defaultRemoveButtonClass: PropTypes.string,
  defaultNotResultFoundClass: PropTypes.string,
  defaultSearchBoxClass: PropTypes.string,
  defaultSearchInputClass: PropTypes.string,
  defaultOptionTextClass: PropTypes.string,
  activeWrapperClass: PropTypes.string,
  activeOptionClass: PropTypes.string,
  hoverOptionClass: PropTypes.string,
  activeInputClass: PropTypes.string,
  isKeyboardHandlingActive: PropTypes.bool,
  inputClass: PropTypes.string,
  inputTextClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  tagClass: PropTypes.string,
  zIndex: PropTypes.number,
  tagWrapperClass: PropTypes.string,
  removeButtonClass: PropTypes.string,
  optionGroupWrapperClass: PropTypes.string,
  optionGroupTextClass: PropTypes.string,
  optionGroupClass: PropTypes.string,
  multipleClass: PropTypes.string,
  disabledClass: PropTypes.string,
  searchableClass: PropTypes.string,
  isDarkClass: PropTypes.string,
  listClass: PropTypes.string,
  liteClass: PropTypes.string,
  searchInputClass: PropTypes.string,
  searchBoxClass: PropTypes.string,
  optionTextClass: PropTypes.string,
  notResultFoundClass: PropTypes.string,
  notResultFoundText: PropTypes.string,
  disabledOptionClass: PropTypes.string,
  removeButtonIcon: PropTypes.node,
  inputAfter: PropTypes.node,
  inputBefore: PropTypes.node,
  dropdownWidth: PropTypes.number,
  dropdownLeft: PropTypes.number,
  dropdownTop: PropTypes.number,
  multiple: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.oneOfType([dataOptionType, dataOptionGroupType])),
  selectedOptionValues: PropTypes.arrayOf(PropTypes.string),
  selectedOptionStyles: PropTypes.shape({}),
  onInputClick: PropTypes.func,
  onOptionClick: PropTypes.func,
  onSearch: PropTypes.func,
  onRemoveTag: PropTypes.func,
  inputTabIndex: PropTypes.number,
  searchInputPlaceholder: PropTypes.string,
  isDark: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isLite: PropTypes.bool,
  defaultInnerInput: PropTypes.bool,
  dropdownOpenInEmail: PropTypes.bool,
  isDropdownOpen: PropTypes.func,
  getDropdownPosition: PropTypes.func,
  inActiveOptionClass: PropTypes.string,
  pdfOptions: PropTypes.array,
  listAriaLabel: PropTypes.string,
  sendFullOption: PropTypes.bool,
  dropdownPositionResolver: PropTypes.func,
  listAutoFocus: PropTypes.bool,
  isMobile: PropTypes.bool,
  searchIcon: PropTypes.node,
  useMagnetPortal: PropTypes.bool,
  clearSearchButtonText: PropTypes.string
};

export default Dropdown;
