import React, {
  useRef, forwardRef, useState
} from 'react';
import {
  string, node, func, bool
} from 'prop-types';

import { Modal } from '@jotforminc/uikit';
import { IconXmark } from '@jotforminc/svg-icons';

import './style.scss';
import { Loading } from '../../../../../components/Loading';

const DialogRenderer = ({ children }) => (
  <div className='jfBasicModal'>
    {children}
  </div>
);

DialogRenderer.propTypes = {
  children: node.isRequired
};

const ContentRenderer = ({ children }) => (
  <div className="uxr-survey-content-wrapper">
    <div>{children}</div>
  </div>
);

ContentRenderer.propTypes = {
  children: node.isRequired
};

const HeaderRenderer = forwardRef(({ iframeLoaded }, uikitModalRef) => {
  if (!iframeLoaded) return <></>;

  return (
    <button className="uxr-survey-close" type="button" onClick={() => { uikitModalRef?.current?.hide(); }}>
      <IconXmark />
    </button>
  );
});

HeaderRenderer.propTypes = {
  iframeLoaded: bool.isRequired
};

export const ExitIntentSurveyModal = ({ username, appID, onClose }) => {
  const uikitModalRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  return (
    <div className='uxr-survey-container'>
      <Modal
        ref={uikitModalRef}
        usePortal={true}
        portalContainerSelector='.uxr-survey-container'
        defaultVisible={true}
        closeOnEscPress={true}
        closeOnOutsideClick={true}
        DialogRenderer={DialogRenderer}
        ContentRenderer={ContentRenderer}
        HeaderRenderer={() => <HeaderRenderer ref={uikitModalRef} iframeLoaded={iframeLoaded} />}
        onModalClose={onClose}
      >
        <div className="modal-content">
          {!iframeLoaded && (
            <Loading />
          )}
          <iframe
            title="UXR Survey"
            src={`https://jotform.com/241892972545974&username=${username}&appID=${appID}`} style={{ width: '100%', height: '100%', border: 'none' }}
            onLoad={e => {
              setIframeLoaded(true);
              const externalStyles = document.getElementById('externalStyles');
              e.target.contentWindow.document.querySelector('head').append(externalStyles);
              window.parent.scrollTo(0, 0);
            }}
          />
        </div>
        <style id="externalStyles">
          {`
            .jfForm-wrapper.enabledBadges {
              padding: 50px 0 50px !important;
            }
            @media screen and (max-width: 570px) {
              .jfForm-wrapper.enabledBadges {
                padding: 30px 0 90px !important;
              }
            }
            .jfQuestion-fullscreen {
              display: none !important;
            }
          `}
        </style>
      </Modal>
    </div>
  );
};

ExitIntentSurveyModal.propTypes = {
  appID: string.isRequired,
  username: string.isRequired,
  onClose: func
};

ExitIntentSurveyModal.defaultProps = {
  onClose: () => { }
};
