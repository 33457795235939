import React, { memo } from 'react';
import useGatewayFlows, { GatewayFlowProvider } from './Context/useGatewayFlows';
import { I_GATEWAY_FLOW_PROVIDER } from './Types';
import * as Modal from './Items';
import * as Steps from './Steps';
import PaymentProvider from '../../../containers/PaymentProviderContainer';

const RenderStep = (): JSX.Element | null => {
  const { currentlyStep } = useGatewayFlows();

  if (!currentlyStep) {
    return null;
  }

  switch (currentlyStep) {
    case 'select-gateway':
      return <Steps.PaymentGateways />;
    case 'select-gatewaySettings':
      return <Steps.PaymentGatewaySettings />;
    case 'select-paymentType':
      return <Steps.PaymentTypes />;
    case 'select-nameapmType':
      return <Steps.PaymentApmPicker />;
    case 'discard-changes':
      return <Steps.PaymentDiscardChanges />;
    default:
      return null;
  }
};

const PaymentGatewayFlows = (props: I_GATEWAY_FLOW_PROVIDER): JSX.Element => {
  const {
    defaultVisible = true,
    defaultGateway,
    defaultFlowType = 'GATEWAY_TO_PAYMENT_TYPE',
    defaultStep = 'select-gateway',
    defaultPaymentType,
    gatewayQuestionProperties,
    resourceType = 'FORM',
    formId,
    formQuestions,
    user,
    onCloseModal,
    onSelectGateway,
    onSelectPaymentType,
    onAddPaymentType,
    onSaveGateway,
    portalOwner
  } = props;

  return (
    <GatewayFlowProvider
      defaultVisible={defaultVisible}
      defaultGateway={defaultGateway}
      defaultFlowType={defaultFlowType}
      defaultStep={defaultStep}
      defaultPaymentType={defaultPaymentType}
      gatewayQuestionProperties={gatewayQuestionProperties}
      resourceType={resourceType}
      formId={formId}
      formQuestions={formQuestions}
      onCloseModal={onCloseModal}
      onSelectGateway={onSelectGateway}
      onSelectPaymentType={onSelectPaymentType}
      onAddPaymentType={onAddPaymentType}
      onSaveGateway={onSaveGateway}
      user={user}
      portalOwner={portalOwner}
    >
      <PaymentProvider>
        <Modal.Wrapper>
          <RenderStep />
        </Modal.Wrapper>
      </PaymentProvider>
    </GatewayFlowProvider>
  );
};

export default memo(PaymentGatewayFlows);
