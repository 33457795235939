import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { cx } from 'class-variance-authority';
import { IconCheck, IconMinus } from '@jotforminc/svg-icons';

import '@jotforminc/jotform.css';

import { checkboxCVA, checkboxIconCVA, checkboxWrapperCVA } from './checkbox.cva';
import { CheckboxProps, checkboxDefaultProps } from './checkbox.types';

import { ChoiceLabel } from '../../shared/ChoiceLabel/ChoiceLabel';
import { useFormControl } from '../../contexts';

export const Checkbox = forwardRef((
  props: CheckboxProps,
  ref?: ComponentPropsWithRef<'input'>['ref']
): JSX.Element => {
  const {
    description,
    label,
    htmlSize,
    theme,
    disabled,
    indeterminate,
    checked,
    size,
    error,
    className,
    id,
    truncate,
    ...rest
  } = useFormControl<CheckboxProps>(props, ['theme', 'size']);

  const hasLabel = label && label.length > 0;
  const hasDescription = description && description.length > 0;
  const wrapperClass = cx(!hasLabel && !hasDescription ? className : null, checkboxWrapperCVA({ size }));

  const checkIcon = (
    <span className={wrapperClass}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        className={checkboxCVA({
          size,
          theme,
          disabled: !!disabled,
          checked: !!checked,
          indeterminate: !!indeterminate,
          error: !!error
        })}
        disabled={disabled}
        ref={ref}
        size={htmlSize}
        {...rest}
      />
      {checked && <IconCheck className={checkboxIconCVA({ theme, disabled: !!disabled })} />}
      {indeterminate && <IconMinus className={checkboxIconCVA({ theme, disabled: !!disabled })} />}
    </span>
  );

  return (
    (hasLabel || hasDescription) ? (
      <ChoiceLabel
        id={id}
        input={checkIcon}
        description={description}
        label={label}
        className={className}
        size={size}
        disabled={!!disabled}
        theme={theme}
        truncate={truncate}
      />
    ) : checkIcon
  );
});

Checkbox.defaultProps = checkboxDefaultProps;
