import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import useGatewayFlows from '../Context/useGatewayFlows';
import { I_MODAL_WRAPPER } from '../Types';

import '@jotforminc/jotform.css';
import '../../../../styles/donationGatewayModal.scss';

const Wrapper: FC<I_MODAL_WRAPPER> = (({ children }) => {
  const { isOpen, currentlyStep } = useGatewayFlows();

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className='payment-modal-container bg-navy-600 bg-opacity-70 fixed inset-0 w-full flex justify-center items-center overflow-y-scroll'>
      <div className='payment-modal-wrapper bg-white radius-lg overflow-auto w-full h-auto relative sm:max-h-80vh' style={{ maxWidth: currentlyStep === 'discard-changes' ? '600px' : '856px' }}>
        {children}
      </div>
      <div className="bg-red-600 w-1 h-1 opacity-0" />
    </div>,
    document.body
  );
});

export default Wrapper;
