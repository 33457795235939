import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Chargify gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlChargify: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_chargify',
  name: gatewayFieldToName.control_chargify,
  supportedCurrencies: gatewaySupportedCurrencies.control_chargify,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_chargify,
  sortByCountry: gatewayListSortedByCountry.control_chargify,

  /** APM's */
  isApm: false,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: false,
    mode: {},
    propReferences: {
      hostName: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Host Name' } },
      apiKey: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'API Key' } },
      sharedKey: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Shared Key' } }
    },
    uniquePropNames: ['hostName', 'apiKey', 'sharedKey']
  }
};
