import { IconImageFilled, IconText } from '@jotforminc/svg-icons';
import { DATA_SOURCE_COLUMN_TYPES } from '../../../../../../constants/itemTypes';

export const COLUMN_IDS = {
  SELECTION_COLUMN: 'selectionColumn',
  SELECTEDS_COLUMN: 'selectedsColumn'
};

export const ICONS_WRT_COLUMN_TYPE = {
  [DATA_SOURCE_COLUMN_TYPES.IMAGE]: IconImageFilled,
  [DATA_SOURCE_COLUMN_TYPES.TEXT]: IconText
};
