/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
import { bool, func } from 'prop-types';
import React from 'react';
import { IconHeartFilled, IconHeart } from '@jotforminc/svg-icons';

const ProductFavorite = ({
  isProductFavorite,
  isFastCheckoutActive,
  onFavorite
}) => {
  return !isFastCheckoutActive && (
  <>
    <input
      name="favoriteButton"
      className="product-list-item-favBtn"
      type="checkbox"
      checked={isProductFavorite}
      onChange={f => f}
    />
    <label className="product-list-item-favLabel" onClick={onFavorite}>
      {
        isProductFavorite
          ? (<IconHeartFilled className='w-5 h-5 fill-navy-700 duration-300' />)
          : (<IconHeart className='w-5 h-5 fill-navy-700 duration-300' />)
      }
    </label>
  </>
  );
};

ProductFavorite.propTypes = {
  isProductFavorite: bool,
  isFastCheckoutActive: bool,
  onFavorite: func
};

ProductFavorite.defaultProps = {
  isProductFavorite: false,
  isFastCheckoutActive: false,
  onFavorite: f => f
};

export default ProductFavorite;
