import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { arrayOf, shape, string } from 'prop-types';
import DraggablePageSettingsItem from './DraggablePageSettingsItem';
import { changePageOrderAction, trackEventAction } from '../../../../../../store/actionCreators';

const PageSettingsContainer = ({
  filteredPages,
  showAllPagesIcon
}) => {
  const dispatch = useDispatch();

  const handleDragEnd = dragDropResult => {
    const { source, destination } = dragDropResult;

    if (source?.index === undefined || destination?.index === undefined) {
      return false;
    }
    dispatch(trackEventAction({ action: 'pageSorted' }));
    dispatch(changePageOrderAction({
      oldIndex: source.index,
      newIndex: destination.index
    }));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {({ innerRef: droppableRef, droppableProps, placeholder }) => (
          <div ref={droppableRef} {...droppableProps}>
            {filteredPages.map((page, index) => (
              <DraggablePageSettingsItem
                showAllPagesIcon={showAllPagesIcon}
                key={page.id || page.tempID}
                page={page}
                index={index}
                itemCount={filteredPages.length}
              />
            ))}
            {placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

PageSettingsContainer.propTypes = {
  filteredPages: arrayOf(shape({})),
  showAllPagesIcon: string
};

PageSettingsContainer.defaultProps = {
  filteredPages: [],
  showAllPagesIcon: ''
};

export default PageSettingsContainer;
