import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

import { filterImages } from '../utils';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export const deleteSelectedImage = url => {
  const requestURL = Array.isArray(url) ? url.join('","') : url;
  return layer.post(`/server.php?action=deleteUserUpload&file=["${requestURL}"]`, null, { disableResponseInterceptors: true });
};

export const getUploadedUserImage = showOnlyImages => {
  return layer.post('/server.php?action=getUploadedImages', null, { disableResponseInterceptors: true })
    .then(response => {
      const { data: { files = [] } = {} } = response;
      // Files may be both array and object
      const fileList = Array.isArray(files) ? files : Object.values(files);
      return showOnlyImages ? filterImages(fileList) : fileList;
    });
};

export const searchUnsplash = (keyword, count = 16, page = 1) => {
  return layer.get(`image/searchUnsplash?keyword=${keyword}&limit=${count}&page=${page}`);
};

export const getRandomImagesFromUnsplash = (count = 16) => {
  return layer.get(`unsplash/photos/random?count=${count}`);
};
