/* eslint-disable react/prop-types */
import React from 'react';
import Styled from 'styled-components';
import Container from '../Container';

export const LoaderSvg = Styled.svg`
  width: 100%;
  height: 100%;
  .mainRect {
    transform-origin: center;
    animation: scale2 1.5s 0s infinite normal cubic-bezier(0.2, 0.68, 0.18, 1.08);
    @keyframes scale2 {
      0% { transform: scale(1); }
      10% { transform: scale(1.1); }
      20% { transform: scale(1); }
      100% {
        transform: scale(1);
      }
    }
  }
  .item {
    animation-direction: alternate;
    animation-fill-mode: backwards;
    animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-iteration-count: infinite;
    animation-name: scale;
    animation-duration: 0.75s;

    &.item1 {
      animation-delay: -0.36s;
      transform-origin: 20px 20px;
    }
    &.item2 {
      animation-delay: -0.24s;
      transform-origin: 20px 50px;
    }
    &.item3 {
      transform-origin: 50px 20px;
      animation-delay: -0.12s;
    }
    &.item4 {
      transform-origin: 50px 50px;
      animation-delay: 0s;
    }
    @keyframes scale {
      0% { transform: scale(0); }
      30% { transform: scale(0); }
      70% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

const Loader = ({ className }) => {
  return (
    <Container className={className}>
      <LoaderSvg
        style={{ overflow: 'visible' }}
        width="67"
        height="67"
        viewBox="0 0 67 67"
      >
        <rect
          fill="#e4ebfc"
          className="mainRect"
          width="66.2"
          height="67.02"
          rx="3.8"
        />
        <rect
          fill="#4099f7"
          className="item item1"
          x="5.15"
          y="6.24"
          width="24.95"
          height="24.95"
          rx="2.16"
        />
        <rect
          fill="#ed6b2d"
          className="item item2"
          x="5.15"
          y="37.3"
          width="24.95"
          height="24.95"
          rx="2.16"
        />
        <rect
          fill="#f5b84b"
          className="item item3"
          x="36.17"
          y="6.24"
          width="24.95"
          height="24.95"
          rx="2.16"
        />
        <g className="item item4">
          <path
            fill="#0b174e"
            d="M56.63,47.24h-7V40a1.77,1.77,0,1,0-3.54,0v7.26h-7a1.77,1.77,0,0,0,0,3.54h7v6.7a1.77,1.77,0,1,0,3.54,0v-6.7h7a1.77,1.77,0,0,0,0-3.54Z"
          />
        </g>
      </LoaderSvg>
    </Container>
  );
};

export default Loader;
