import React from 'react';
import {
  bool, func, number, string
} from 'prop-types';
import { useFeatures } from '@paralleldrive/react-feature-toggles';

import { Feature, FeatureToggles } from '@jotforminc/feature-toggle';
import { createObjectWithKeys } from '@jotforminc/utils';
import { Button } from '@jotforminc/common-button';
import { IconXmarkSm } from '@jotforminc/svg-icons';
import { propTypes } from '@jotforminc/constants';
import { translationRenderer, t } from '@jotforminc/translation';

import { ScControlBar } from './scControlBar';
import { CheckBox } from './CheckBox';
import SearchInput from './SearchInput';
import { debounce } from '../utils';
import SortMenu from './SortMenu';
import FilterForm from './FilterForm';

function ControlBar({
  isVisible,
  isAllSelected,
  onSelectAll,
  selectedItemCount,
  clearSelection,
  searchPlaceholder,
  searchTerm,
  onSearchTermChange,
  bulkActions,
  isMultiSelect,
  otherItems,
  showSelectAll,
  showSelectedItemCount,
  autoFocus,
  isNewBranding,
  sortOnClient,
  filterOnClient
}) {
  if (!isVisible) return null;
  const checkboxID = Math.random(); // Better way?
  let selectAllStatus = 'false';

  // const selectAllStatus = isAllSelected ? 'true' : (selectedItemCount > 0 ? 'partial' : 'false'); nested ternary bad
  switch (true) {
    case isAllSelected:
      selectAllStatus = 'true';
      break;
    case selectedItemCount > 0:
      selectAllStatus = 'partial';
      break;
    default:
      break;
  }

  const shouldShowSelectAll = showSelectAll && isMultiSelect;
  const shouldShowCount = selectedItemCount && showSelectedItemCount;
  const shouldShowOthers = otherItems && otherItems?.length > 0;
  const shouldShowBody = shouldShowSelectAll || shouldShowCount || shouldShowOthers;

  return (
    <FeatureToggles
      features={{
        ...createObjectWithKeys(useFeatures())
      }}
    >
      <ScControlBar data-sc="smartList-controlBar">
        <Feature
          name="multipleManage"
          activeComponent={shouldShowBody ? (
            <div className="cb-body">
              {shouldShowSelectAll && (
                <label
                  htmlFor={checkboxID}
                  className="cb-toggle"
                  aria-selected={selectAllStatus}
                >
                  <CheckBox
                    id={checkboxID}
                    checked={isAllSelected}
                    onChange={onSelectAll}
                    disabled={!!searchTerm}
                  />
                  {t('Select all')}
                </label>
              )}
              {shouldShowCount ? (
                <div className="cb-actions">
                  <div className="cb-status">
                    <span>
                      {translationRenderer('[1[{selectedItemCount}]] selected')({
                        renderer1: () => <>{selectedItemCount}</>
                      })}
                    </span>
                    <Button onClick={clearSelection}>
                      <IconXmarkSm />
                    </Button>
                  </div>
                  <Feature name="multipleManage" activeComponent={<div className="cb-bulk">{bulkActions}</div>} />
                </div>
              ) : null}
              {shouldShowOthers ? (
                <div className="cb-other">
                  {otherItems.map((e, index) => (e.key ? e : ({ ...e, key: index })))}
                </div>
              ) : null}
            </div>
          ) : null}
        />
        <>
          <Feature
            name="search"
            activeComponent={(
              <div className="cb-searchCont">
                <SearchInput
                  placeholder={searchPlaceholder}
                  value={searchTerm}
                  onChange={debounce(onSearchTermChange)}
                  autoFocus={autoFocus}
                  isNewBranding={isNewBranding}
                />
              </div>
            )}
          />
          <Feature
            name="filterForm"
            activeComponent={(
              <div className="jfResourcePicker-filterMenu isResourcePickerDropdown">
                <FilterForm filterOnClient={filterOnClient} />
              </div>
            )}
          />
          <Feature
            name="sortMenu"
            activeComponent={(
              <div className="jfResourcePicker-sortMenu isResourcePickerDropdown">
                <SortMenu sortOnClient={sortOnClient} />
              </div>
            )}
          />
        </>
      </ScControlBar>
    </FeatureToggles>
  );
}

ControlBar.propTypes = {
  isVisible: bool,
  isAllSelected: bool,
  isMultiSelect: bool,
  onSelectAll: func,
  selectedItemCount: number,
  clearSelection: func,
  searchPlaceholder: string,
  searchTerm: string,
  onSearchTermChange: func,
  bulkActions: propTypes.renderable,
  otherItems: propTypes.renderable,
  showSelectAll: bool,
  showSelectedItemCount: bool,
  autoFocus: bool,
  isNewBranding: bool,
  sortOnClient: func,
  filterOnClient: func
};

ControlBar.defaultProps = {
  isVisible: true,
  isAllSelected: false,
  isMultiSelect: true,
  onSelectAll: f => f,
  selectedItemCount: 0,
  clearSelection: f => f,
  searchPlaceholder: '',
  searchTerm: '',
  onSearchTermChange: f => f,
  bulkActions: null,
  otherItems: [],
  showSelectAll: true,
  showSelectedItemCount: true,
  autoFocus: false,
  isNewBranding: false,
  sortOnClient: f => f,
  filterOnClient: f => f
};

export default ControlBar;
