import Styled from 'styled-components';

export const ScCard = Styled.div`
  width: 550px;
  text-align: center;
  padding: 50px 36px;
  background-color: #FFF;
  color: #0A1551;
  box-shadow: 0px 2px 4px 3px rgba(181, 182, 201, 0.33);
  border-radius: 4px;
`;
