/* eslint-disable */

import { getAlternativeDomains } from "../../../../utils";

function imageConverter({ url, blob = false }) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    var c = document.createElement('canvas');
    var ctx = c.getContext('2d');
    img.crossOrigin = "anonymous";
    img.src = url;
    img.onload = function () {
      const width = 512;
      const height = 512 * this.naturalHeight / this.naturalWidth;
      c.width = width; // update canvas size to match image
      c.height = height;
      ctx.drawImage(this, 0, 0, width, height); // draw in image
      if (blob) {
        c.toBlob(blob => {
          // image as a blob
          resolve(blob);
        }, 'image/jpeg', 0.75);
      } else {
          // base64
          resolve(c.toDataURL());
      }
    };
  });
};

/**
 * Simple function that converts a plain SVG string or SVG DOM Node into an image with custom dimensions.
 *
 * @param {Object} settings The configuration object to override the default settings.
 * @see https://ourcodeworld.com/articles/read/1456/how-to-convert-a-plain-svg-string-or-svg-node-to-an-image-png-or-jpeg-in-the-browser-with-javascript
 * @returns {Promise}
 */

async function generateAsset(settings) {
  const _settings = {
    svg: null,
    // Usually all SVG have transparency, so PNG is the way to go by default
    mimetype: 'image/png',
    quality: 1,
    width: 'auto',
    height: 'auto',
    outputFormat: 'base64',
    url: null,
    assetType: 'svg' // asset types can be image or svg
  };

  let imageBase64 = '';

  // Override default settings
  for (const key in settings) {
    _settings[key] = settings[key];
  }

  if (_settings.assetType === 'image' && settings.url) {
    try {
      let url = getAlternativeDomains().length > 0 ? new URL(settings.url).pathname : settings.url;
      imageBase64 = await imageConverter({ url, blob: settings.outputFormat === 'blob' });
    } catch (error) {
      console.error('Image converter error: ', error);
    }
  }

  return new Promise((resolve, reject) => {
    let svgNode;
    let svgBase64;

    // generate w/SVG
    if (_settings.assetType === 'svg') {
      // Create SVG Node if a plain string has been provided
      if (typeof (_settings.svg) === 'string') {
        // Create a non-visible node to render the SVG string
        const SVGContainer = document.createElement('div');
        SVGContainer.style.display = 'none';
        SVGContainer.innerHTML = _settings.svg;
        svgNode = SVGContainer.firstElementChild;
      } else {
        svgNode = _settings.svg;
      }

      const svgXml = new XMLSerializer().serializeToString(svgNode);
      svgBase64 = `data:image/svg+xml;base64,${btoa(svgXml)}`;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    // decide which type to use
    const assetBase64 = _settings.assetType === 'image' ? imageBase64 : svgBase64;

    const image = new Image();

    image.onload = function () {
      let finalWidth;
      let finalHeight;

      if (this.height >= this.width) {
        finalWidth = (this.width / this.height) * _settings.width;
        finalHeight = _settings.height;
      } else {
        finalHeight = (this.height / this.width) * _settings.height;
        finalWidth = _settings.width;
      }

      // Calculate width if set to auto and the height is specified (to preserve aspect ratio)
      // if (_settings.width === 'auto' && _settings.height !== 'auto') {
      //   finalWidth = (this.width / this.height) * _settings.height;
      //   // Use image original width
      // } else if (_settings.width === 'auto') {
      //   finalWidth = this.naturalWidth;
      //   // Use custom width
      // } else {
      //   finalWidth = _settings.width;
      // }
      //
      // // Calculate height if set to auto and the width is specified (to preserve aspect ratio)
      // if (_settings.height === 'auto' && _settings.width !== 'auto') {
      //   finalHeight = (this.height / this.width) * _settings.width;
      //   // Use image original height
      // } else if (_settings.height === 'auto') {
      //   finalHeight = this.naturalHeight;
      //   // Use custom height
      // } else {
      //   finalHeight = _settings.height;
      // }

      // Define the canvas intrinsic size
      canvas.width = _settings.width;
      canvas.height = _settings.height;

      // set bg
      context.fillStyle = settings.bgColor;

      const rectangleHeight = this.height >= this.width ? 512 : finalHeight;
      const rectangleWidth = this.height >= this.width ? finalWidth : 512;
      const imagePositionX = rectangleHeight > rectangleWidth ? ((rectangleHeight - rectangleWidth) / 2) : 0
      const imagePositionY = rectangleHeight > rectangleWidth ? 0 : (rectangleWidth - rectangleHeight) / 2;

      context.fillRect(0, 0, 512, 512);

      // Render image in the canvas
      context.drawImage(this, imagePositionX, imagePositionY, rectangleWidth, rectangleHeight);

      if (_settings.outputFormat == 'blob') {
        // Fullfil and Return the Blob image
        canvas.toBlob(blob => {
          resolve(blob);
        }, _settings.mimetype, _settings.quality);
      } else {
        // Fullfil and Return the Base64 image
        resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
      }
    };

    // Load the SVG or Image in Base64 to the image
    image.src = assetBase64
  });
}

/* eslint-enable */

export default generateAsset;
