import React, { useState, ChangeEvent } from 'react';
import {
  IconCreditCardFilled,
  IconMagnifyingGlass,
  IconXmark
} from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import { t, translationRenderer } from '@jotforminc/translation';
import { C_GATEWAYS } from '../Constants';
import { I_APM_ELEMENT_TYPES } from '../Types';
import * as Modal from '../Items';
import useGatewayFlows from '../Context/useGatewayFlows';

const PaymentGateways = (): JSX.Element => {
  const {
    selectGateway,
    selectApm,
    currentlyGateway,
    resourceType
  } = useGatewayFlows();
  const [filteredList, setFilteredList] = useState(C_GATEWAYS);
  const [searchKey, setSearchKey] = useState<string>('');

  const filterBySearch = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    let updatedList = [...C_GATEWAYS];
    updatedList = updatedList.filter(item => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setSearchKey(query);
    setFilteredList(updatedList);
  };

  return (
    <>
      <Modal.Header
        title={t('Add payment integration')}
        description={t('Choose your preferred payment gateway from the options below')}
        leftIcon={<IconCreditCardFilled className='h-7 w-7' />}
        iconBackgroundColor='bg-green-500'
        iconColor='color-white'
      />
      <Modal.Body>
        <>
          <div className="w-full flex justify-start items-center bg-white border border-navy-100 radius mb-5 px-3 py-2">
            <IconMagnifyingGlass className='w-4 h-4 pointer-events-none color-navy-300' />
            <input
              type="text"
              className="text-sm font-normal line-height-xs w-full h-full border-0 color-navy-300 placeholder-navy-300 ml-3 outline-0"
              placeholder={t('Search gateways')}
              onChange={filterBySearch}
              value={searchKey}
            />
            {
              searchKey
              && searchKey.length > 0
              && (
                <Button
                  onClick={() => {
                    setSearchKey('');
                    setFilteredList(C_GATEWAYS);
                  }}
                  variant='ghost'
                  colorStyle='neutral'
                  size='small'
                  startIcon={IconXmark}
                  className='max-h-4'
                />
              )
            }
          </div>
          {filteredList.length > 0 ? (
            <div className='grid cols-2 gap-3 md:cols-4'>
              {filteredList.filter(gateway => gateway.resourceTypes.includes(resourceType)).map(data => {
                return (
                  <button
                    onClick={() => {
                      if (data.nameAPMType && data.nameAPMType === 'appleAndGooglePay') {
                        selectApm(data.type as I_APM_ELEMENT_TYPES);
                      } else {
                        selectGateway(data.type);
                      }
                    }}
                    type='button'
                    key={data.type}
                    className={`w-full h-auto radius border hover:border-blue-500 ${currentlyGateway === data.type ? 'border-blue-500 bg-blue-100' : 'border-navy-100'}`}
                  >
                    <div className='w-full h-full px-4 pt-5 pb-4'>
                      {data.icon && <data.icon className='w-10 h-10' />}
                      <h5 className='text-md font-medium line-height-xl color-navy-700 mt-3'>{data.name}</h5>
                    </div>
                  </button>
                );
              })}
            </div>
          ) : (
            <div className='w-full h-full flex items-center justify-between flex-col bg-navy-25 border radius border-navy-50 px-3 py-5'>
              <p className="text-sm font-medium color-navy-700 line-height-xl tracking-md mb-1">
                {t('Oops, No Result Found')}
              </p>
              <p className="text-xs font-normal color-navy-300 line-height-xxs">
                {translationRenderer('Sorry we could not find any results for [1[{keyword}]] in our gateway list.')({
                  renderer1: () => (
                    <b>
                      {`"${searchKey}"`}
                    </b>
                  )
                })}
              </p>
            </div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer
        enableNextButton
        nextButtonDisabledCondition={!currentlyGateway}
      />
    </>
  );
};

export default PaymentGateways;
