import semver from 'semver';
import isUndefined from 'lodash/isUndefined';
import { t } from '@jotforminc/translation';
import { safeJSONParse } from '@jotforminc/utils';
import {
  AppV0, FormItemV0, AppV1, ButtonItemV1, DividerItemV1, DocumentItemV1, FormItemV1, HeadingItemV1, ImageItemV1, LinkItemV1, ParagraphItemV1,
  WidgetItemV1, TableLinkItemV1, ReportLinkItemV1, SentboxLinkItemV1, PageV1, ProductListItemV1, SignLinkItem, DonationItemV1, ShareButtonItemV1,
  ListItemV1, CardItemV1, AgentItemv1
} from '@jotforminc/portal-schemas';
import { generateDefaults } from './utils';
import { ITEM_TYPES, PAGE_TYPE } from '../constants/itemTypes';
import VERSIONS from './versions';
import DefaultsCacheSingleton from './DefaultsCacheSingleton';

const schemas = {
  [VERSIONS[0]]: {
    App: AppV0,
    [ITEM_TYPES.FORM]: FormItemV0
  },
  [VERSIONS[1]]: {
    App: AppV1,
    [ITEM_TYPES.FORM]: FormItemV1,
    [ITEM_TYPES.HEADING]: HeadingItemV1,
    [ITEM_TYPES.DONATION]: DonationItemV1,
    [ITEM_TYPES.PARAGRAPH]: ParagraphItemV1,
    [ITEM_TYPES.LINK]: LinkItemV1,
    [ITEM_TYPES.TABLE_LINK]: TableLinkItemV1,
    [ITEM_TYPES.REPORT_LINK]: ReportLinkItemV1,
    [ITEM_TYPES.SIGN_LINK]: SignLinkItem,
    [ITEM_TYPES.SENTBOX_LINK]: SentboxLinkItemV1,
    [ITEM_TYPES.DOCUMENT]: DocumentItemV1,
    [ITEM_TYPES.IMAGE]: ImageItemV1,
    [ITEM_TYPES.BUTTON]: ButtonItemV1,
    [ITEM_TYPES.SHARE_BUTTON]: ShareButtonItemV1,
    [ITEM_TYPES.DIVIDER]: DividerItemV1,
    [ITEM_TYPES.WIDGET]: WidgetItemV1,
    [ITEM_TYPES.PRODUCT_LIST]: ProductListItemV1,
    [PAGE_TYPE]: PageV1,
    [ITEM_TYPES.LIST]: ListItemV1,
    [ITEM_TYPES.CARD_ITEM]: CardItemV1,
    [ITEM_TYPES.AGENT]: AgentItemv1
  }
};

export const guardPropsForGrandfatheredApps = {
  showAppCover: 'No',
  showItemIcons: 'No',
  showAppHeader: 'Yes',
  showListBg: 'No'
};

// this constant changes App&Item defaults. CHANGE WITH CAUTION!
export const LATEST_VERSION = VERSIONS[1];

export const NEXT_VERSION = global.isDevelopment || global?.location?.href?.includes('useNewBuilder=1');
if (NEXT_VERSION && !global.isDevelopment) {
  console.warn('NEXT VERSION DEFAULTS ARE BEING USED!');
}

export const getLatestVersion = () => (NEXT_VERSION ? Math.max(...Object.keys(schemas)).toString() : LATEST_VERSION);

export const isAppGrandfathered = appVersion => {
  const currentLatest = getLatestVersion();
  try {
    const semverLatest = semver.coerce(currentLatest);
    const semverApp = semver.coerce(appVersion || getLatestVersion());
    const isOlderApp = semver.lt(semverApp, semverLatest);
    return isOlderApp;
  } catch (e) {
    console.log('Grandfather check failed', e);
    return false;
  }
};

const getSchema = (type = 'App', version) => schemas[version || getLatestVersion()][type];

export const translateDefaultProperties = defaultProperties => Object.fromEntries(Object.entries(defaultProperties).map(([key, value]) => {
  if (value.translate) {
    const parsedDefault = safeJSONParse(value.default, value.default);
    const translatedDefaultValue = parsedDefault.text ? JSON.stringify({ ...parsedDefault, text: t(parsedDefault.text) }) : t(parsedDefault);
    return [key, { ...value, default: translatedDefaultValue }];
  }
  return [key, value];
}));

export const useAppDefaults = version => {
  const cache = new DefaultsCacheSingleton();
  const cacheKey = cache.generateKey('useAppDefaults', 'version');
  const cacheItem = cache.get(cacheKey);

  if (cacheItem) {
    return cacheItem;
  }

  const appSchema = getSchema('App', version);
  const defaults = generateDefaults({ ...appSchema, properties: translateDefaultProperties(appSchema.properties) });
  if (global.JOTFORM_ENV === 'ENTERPRISE') {
    const companyLogoURL = `https://${global.companyDomain}${global.companyLogo}`;
    return {
      ...defaults,
      logoURL: companyLogoURL,
      appIconURL: companyLogoURL,
      loginable: 'No'
    };
  }

  cache.set(cacheKey, defaults);
  return defaults;
};

export const getItemSchemaKeys = (type, version) => {
  const schema = getSchema(type, version) || { properties: {} };
  return Object.keys(schema.properties);
};

export const itemCanUseProperty = (type, version, property) => {
  const keys = getItemSchemaKeys(type, version);
  return keys.includes(property);
};

export const filterItemPropertiesBySchema = (type, version, properties) => {
  const keys = getItemSchemaKeys(type, version);
  return properties.filter(prop => keys.includes(prop));
};

export const useItemDefaults = (type, version) => {
  const cache = new DefaultsCacheSingleton();
  const cacheKey = cache.generateKey('useItemDefaults', type, version);
  const cacheItem = cache.get(cacheKey);

  if (cacheItem) {
    return cacheItem;
  }

  const itemSchema = getSchema(type, version);
  if (!itemSchema) return {};

  const defaults = generateDefaults({ ...itemSchema, properties: translateDefaultProperties(itemSchema.properties) });
  cache.set(cacheKey, defaults);
  return defaults;
};

export const usePageDefaults = () => {
  const cache = new DefaultsCacheSingleton();
  const cacheKey = cache.generateKey('usePageDefaults', PAGE_TYPE);
  const cacheItem = cache.get(cacheKey);

  if (cacheItem) {
    return cacheItem;
  }

  const pageSchema = getSchema(PAGE_TYPE);
  const defaults = generateDefaults(pageSchema);
  cache.set(cacheKey, defaults);
  return defaults;
};

/**
 * Fixes irregular widget properties
 * @param {Object} widget
 * taken from BUILDER repo
 */
export const fixWidgetDefaults = widget => {
  const _widget = { ...widget };
  // assign field width and height
  if (!('fieldWidth' in _widget) || _widget.fieldHeight === null) {
    _widget.fieldWidth = '500px';
  }
  if (!('fieldHeight' in _widget) || _widget.fieldHeight === null) {
    _widget.fieldHeight = '500px';
  }

  if (_widget.fieldParameters && _widget.fieldParameters.length > 0) {
    const fieldParameters = _widget.fieldParameters.map(params => {
      const _params = { ...params };
      if (!isUndefined(params.tip) && params.tip.trim().replace(/(<([^>]+)>)/ig, '') !== '') {
        let clearHint = params.tip.trim().replace(/\n/g, ' ');
        clearHint = clearHint.replace('<p>', '').replace('</p>', '');
        clearHint = clearHint.replaceAll('<br />', '<br>');
        _params.tip = clearHint;
      }
      return _params;
    });
    return { ..._widget, fieldParameters };
  }

  if (_widget.type === 'widget') {
    _widget.fieldParameters = [{
      readable: 'URL',
      name: 'URL',
      type: 'text'
    }];
  }

  if (_widget.type === 'embed') {
    _widget.fieldParameters = [{
      readable: 'HTML',
      name: 'HTML',
      type: 'textarea'
    }];
  }
  return _widget;
};

export const useWidgetDefaults = (allWidgets, widgetType) => {
  const [, clientID] = widgetType.split('_');
  const widget = allWidgets.find(({ client_id: _clientID }) => clientID === _clientID);
  if (!widget) return null;
  const {
    slug, type, details: { website }, fieldParameters, fieldWidth: frameWidth, fieldHeight: frameHeight
  } = fixWidgetDefaults(widget);
  const settingNames = fieldParameters.map(field => field.name).join(',');
  const initialWidgetInfo = {
    clientID,
    widgetSlug: slug,
    widgetType: type,
    frameSrc: website,
    finalSrc: website,
    settingNames,
    frameWidth,
    frameHeight
  };

  return fieldParameters.reduce((prev, param) => {
    const extraProps = param.type === 'external' ? { src: param.default } : {};
    return { ...prev, [param.name]: param.default, ...extraProps };
  }, initialWidgetInfo);
};

export const getAvailableItemTypesByVersion = version => {
  const forVersion = version || getLatestVersion();
  const definedTypes = schemas[forVersion];
  if (!definedTypes) return;
  return Object.keys(definedTypes).filter(type => type !== 'App');
};
