import Styled from 'styled-components';

export const ScErrorFallback = Styled.div`
  padding: 1em;

  .errorFallback-text {
    margin-bottom: 16px;
    color: #fff;
  }

  .errorFallback-label {
    display: inline-block;
    width: auto;
    font-size: 1em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-bottom: 0.4em;
    color: #fff;
  }

  .errorFallback-textarea {
    outline: none;
    background-color: rgb(72, 80, 104);
    width: 100%;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 2px;
    padding: 9px 12px 22px 9px;
    font-size: 16px;
    font-family: inherit;
    box-shadow: rgb(36 43 62) 0px 0px 0px 1px;
    margin-bottom: 0.4em;
  }
`;
