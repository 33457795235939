import React, { forwardRef } from 'react';
import { elementType, func } from 'prop-types';
import { Modal as UIKitModal } from '@jotforminc/uikit';

const AppModal = forwardRef(({
  DialogRenderer, ContentRenderer, HeaderRenderer, FooterRenderer, onModalClose, ...props
}, ref) => {
  return (
    <UIKitModal
      {...props}
      ref={ref}
      DialogRenderer={DialogRenderer}
      ContentRenderer={ContentRenderer}
      HeaderRenderer={HeaderRenderer}
      FooterRenderer={FooterRenderer}
      onModalClose={onModalClose}
    />
  );
});

/* eslint react/prop-types: "off" */
AppModal.propTypes = {
  DialogRenderer: elementType,
  HeaderRenderer: elementType,
  ContentRenderer: elementType,
  FooterRenderer: elementType,
  onModalClose: func
};

AppModal.defaultProps = {
  DialogRenderer: ({ children }) => (
    <div style={{
      position: 'fixed',
      zIndex: 1,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: 'rgb(0, 0, 0, 0.4)'
    }}
    >
      {children}
    </div>
  ),
  HeaderRenderer: ({ children }) => <div>{children}</div>,
  ContentRenderer: ({ children }) => (<div>{children}</div>),
  FooterRenderer: ({ children }) => <div>{children}</div>,
  onModalClose: () => {}
};

export default AppModal;
