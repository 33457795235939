import React, {
  Children,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo
} from 'react';
import {
  objectOf, shape, node, string, elementType, arrayOf, func
} from 'prop-types';

const TabList = forwardRef(({
  list, children, defaultActiveTab, erroredTabs, onTabChange,
  Wrapper, TabButton, TabButtonContainer, TabListContainer, TabContainer, activeTabClassName, tabButtonClassName
}, tabListRef) => {
  const tabs = Object.entries(list);
  const [activeTabID, setActiveTabID] = useState(defaultActiveTab || tabs[0][0]);
  useImperativeHandle(tabListRef, () => ({ setActiveTabID, activeTabID }));

  useEffect(() => {
    onTabChange(activeTabID);
  }, [activeTabID]);

  const generateTabClickHandler = id => () => setActiveTabID(id);
  const tabIdPrefix = useMemo(() => Math.random().toString(36).slice(2), []);
  const tabButtonPrefix = useMemo(() => Math.random().toString(36).slice(2), []);

  return (
    <Wrapper data-activetab={activeTabID} data-testid={activeTabID}>
      <TabButtonContainer>
        {tabs
          .filter(([, { hidden = false }]) => !hidden)
          .map(([id, tabProps], index) => {
            const hasError = erroredTabs[index];
            const isActiveTab = id === activeTabID;
            const classNames = tabButtonClassName ? { className: `${tabButtonClassName} ${isActiveTab ? activeTabClassName : ''}` } : {};
            const ariaId = [tabIdPrefix, id].join('_');
            const buttonId = [tabButtonPrefix, id].join('-');

            return (
              <TabButton
                id={buttonId}
                data-isactive={activeTabID === id}
                aria-selected={activeTabID === id}
                data-tabid={id}
                aria-controls={ariaId}
                data-haserror={hasError}
                key={id}
                onClick={generateTabClickHandler(id)}
                data-eventkey={id}
                {...classNames}
              >
                {tabProps.label}
                {hasError && <span className="isErrorBullet" />}
              </TabButton>
            );
          })}
      </TabButtonContainer>
      <TabListContainer data-activetab={activeTabID}>
        {Children.map(children, tab => {
          const { props: { id } } = tab;
          const isActiveTab = id === activeTabID;
          const ariaId = [tabIdPrefix, id].join('_');
          const ariaLabelledByButtonId = [tabButtonPrefix, id].join('-');

          return (
            <TabContainer
              role="tabpanel"
              aria-labelledby={ariaLabelledByButtonId}
              id={ariaId}
              data-isactive={isActiveTab ? 'true' : 'false'}
              style={{ display: isActiveTab ? 'block' : 'none' }}
            >
              {tab}
            </TabContainer>
          );
        })}
      </TabListContainer>
    </Wrapper>
  );
});

TabList.propTypes = {
  children: node,
  defaultActiveTab: string,
  list: objectOf(shape({})).isRequired,
  Wrapper: elementType,
  TabButton: elementType,
  TabButtonContainer: elementType,
  TabListContainer: elementType,
  TabContainer: elementType,
  erroredTabs: arrayOf(string),
  activeTabClassName: string,
  tabButtonClassName: string,
  onTabChange: func
};

TabList.defaultProps = {
  children: null,
  defaultActiveTab: null,
  Wrapper: props => <div {...props} />,
  TabButton: props => <button type="button" {...props} />,
  TabButtonContainer: props => <div {...props} />,
  TabListContainer: props => <div {...props} />,
  TabContainer: props => <div {...props} />,
  erroredTabs: [],
  activeTabClassName: '',
  tabButtonClassName: '',
  onTabChange: () => {}
};

export default TabList;
