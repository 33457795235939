import React from 'react';

interface IComponentWrapper {
  children: React.ReactNode;
  additionalClass?: string;
}

const ComponentWrapper = ({ children, additionalClass = '' }: IComponentWrapper): JSX.Element => {
  return (
    <div className={`${additionalClass} paymentPropertyField px-4 color-white`}>
      {children}
    </div>
  );
};

export default ComponentWrapper;

ComponentWrapper.defaultProps = {
  additionalClass: ''
};
