import uniq from 'lodash/uniq';
import union from 'lodash/union';
import every from 'lodash/every';

import {
  filterItemPropertiesBySchema,
  getItemSchemaKeys,
  itemCanUseProperty
} from '../../../../../properties';
import { isYes } from '../../../../../utils';
import { ITEM_TYPES } from '../../../../../constants/itemTypes';

const allowedMultiPanelKeys = [
  'itemBgColor',
  'itemFontColor',
  'itemTextAlignment',
  'itemBorderColor',
  'clearBadgeOn',
  'clearBadgePeriod',
  'showBadge',
  'headingSize',
  'completed_showBadge',
  'completed_clearBadgeOn',
  'completed_clearBadgePeriod',
  'required_showBadge',
  'showURL',
  'showItemIcon',
  'shrink',
  'itemIcon',
  'itemIconColor',
  'itemIconBgColor',
  'itemBgURL'
];

const getUniqueItemTypes = items => uniq(items.map(({ type }) => type));

export const getMultiplePanelKeys = (items, version) => {
  const types = getUniqueItemTypes(items);
  let unionKeys = [];
  types.forEach(type => {
    unionKeys = union(unionKeys, getItemSchemaKeys(type, version));
  });
  // Button color palette and item color palette are different.
  if (types.length > 1 && types.includes(ITEM_TYPES.BUTTON)) {
    unionKeys = unionKeys.filter(key => key !== 'itemBgColor');
  }

  return unionKeys.filter(key => allowedMultiPanelKeys.includes(key));
};

export const getFilteredProperties = (items, props, version) => {
  const propertyKeys = Object.keys(props);
  const itemsToChange = [];
  items.forEach(({ type, id }) => {
    // filter 'props' according to every items own schema
    const filteredKeys = filterItemPropertiesBySchema(type, version, propertyKeys);
    if (filteredKeys.length) {
      const filteredProperties = {};
      filteredKeys.forEach(key => { filteredProperties[key] = props[key]; });
      // use new filtered 'props' to update item
      itemsToChange.push({ id, ...filteredProperties });
    }
  });
  return itemsToChange;
};

export const showComponentByProperty = ({ items, version, properties = [] }) => {
  // send 'propertyName', this method will send you 'propertyNameVisible'
  const multipleKeys = getMultiplePanelKeys(items, version);
  return properties.reduce((prev, keys) => {
    const value = typeof keys === 'object' ? every(keys, key => multipleKeys.includes(key)) : multipleKeys.includes(keys);
    // if component requires more than one key
    const name = typeof keys === 'object' ? keys[0] : keys;
    return { ...prev, [`${name}Visible`]: value };
  }, {});
};

export const getDefaultValueForProp = (items, version, propertyName) => {
  const badgeItems = items.filter(({ type, embeddedForm }) => {
    /**
     * Prevents embeded forms to affect mixed status.
     * We do not let embedded forms to shrink.
     * TODO: Put the information regarding props blocking other props in a constant maybe?
     */
    const isBlocked = propertyName === 'shrink' && isYes(embeddedForm);

    return itemCanUseProperty(type, version, propertyName) && !isBlocked;
  });

  const values = uniq(badgeItems.map(item => item[propertyName]));
  return values.length === 1 ? values[0] : '';
};

export const getDefaultValuesforKeys = (items, version) => {
  const properties = getMultiplePanelKeys(items, version);
  return properties.reduce((prev, propKey) => {
    return { ...prev, [propKey]: getDefaultValueForProp(items, version, propKey) };
  }, {});
};

export const HEADING_SIZE_OPTIONS = [
  {
    text: 'Default',
    value: 'default'
  },
  {
    text: 'Large',
    value: 'large'
  },
  {
    text: 'Small',
    value: 'small'
  }
];
