import Styled from 'styled-components';
import { primaryAccentDefault } from '../../../../styles/colors';

export const ScRadioToggle = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .radioToggleTexts {
    font-size: 16px;
    color: #ffffff;

    &-title {
      font-weight: bold;
    }
  }

  .radioToggle-toggleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > [data-selected=true] .toggle {
      background-color: ${primaryAccentDefault};
    }

    .toggle {
      border-radius: 4px;
      background-color: #56687B;
    }
  }
`;
