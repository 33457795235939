import './style/main.scss';
import React, { useCallback, useEffect, useState } from 'react';
import {
  arrayOf, func, shape, string
} from 'prop-types';
import FontPickerHeader from './FontPickerHeader';
import FontPickerList from './FontPickerList';
import { createNewData, handleSearchResult } from './helper';
import fontsData from './data/fonts.json';

const fonts = fontsData && fontsData.fonts ? fontsData.fonts : null;

const FontPicker = ({
  additionalClassname, defaultFont, dropDownCallback, fontsArr, initialFont, onSelectFont,
  theme
}) => {
  const [showList, setShowList] = useState(false);
  const [data, setData] = useState(fontsArr);
  const [selectedFont, setSelectedFont] = useState(initialFont || defaultFont || '');
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [listCreated, setListCreated] = useState(false);

  const outSideClick = useCallback(e => {
    if (
      (!e || !e.target || !e.target.className || typeof e.target.className.indexOf !== 'function')
    ) return;

    const isOutsideClicked = !(e.target.className.indexOf('jfc-font-picker') > -1);
    if (isOutsideClicked) setShowList(!isOutsideClicked);
  }, []);

  const handleClickHeader = () => {
    setShowList(prevState => !prevState);
    dropDownCallback({ dropdownIsOpened: showList });
    document.removeEventListener('click', outSideClick, true);
  };

  const handleFontSelection = selectedObj => {
    setSelectedFont(selectedObj.name);
    onSelectFont(selectedObj);
  };

  const handleFontSearcing = ({ target: { value } }) => {
    setSearchValue(value);
    setSearchResult(handleSearchResult(data, value));
  };

  useEffect(() => {
    if (
      fontsArr.length === 0
      && fonts
      && Object.keys(fonts).length > 0
    ) {
      setData(createNewData(fonts));
      setListCreated(true);
    }
  }, []);

  useEffect(() => {
    if (!showList) {
      document.removeEventListener('click', outSideClick, true);
      return;
    }

    document.addEventListener('click', outSideClick, true);
  }, [showList]);

  return (
    <div
      className={`jfc-font-picker theme-${theme}${additionalClassname.length > 0 ? ` ${additionalClassname}` : ''}`}
    >
      <FontPickerHeader
        selected={selectedFont}
        onClick={handleClickHeader}
      />
      <FontPickerList
        data={data}
        defaultFont={defaultFont}
        handleFontSearching={handleFontSearcing}
        isOpened={showList}
        listCreated={listCreated}
        onSelectFont={handleFontSelection}
        searchValue={searchValue}
        searchResult={searchResult}
        selected={selectedFont}
      />
    </div>
  );
};

FontPicker.propTypes = {
  additionalClassname: string,
  dropDownCallback: func,
  defaultFont: string,
  initialFont: string,
  fontsArr: arrayOf(
    shape({
      category: string,
      name: string.isRequired
    })
  ),
  onSelectFont: func,
  theme: string
};

FontPicker.defaultProps = {
  additionalClassname: '',
  dropDownCallback: f => f,
  defaultFont: '',
  initialFont: '',
  fontsArr: [],
  onSelectFont: f => f,
  theme: 'default'
};

export default FontPicker;
