import React, {
  useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { ThemeProvider } from 'styled-components';
import isEqual from 'lodash/isEqual';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { t } from '@jotforminc/translation';
import { arrayMove, closest } from '@jotforminc/utils';
import { Button } from '@jotforminc/magnet';
import { IconTrashFilled } from '@jotforminc/svg-icons';
import ImageUpload from './ImageUpload';
import ImagePreview from './ImagePreviewForViewer';
import ScGlobal from './sc/ScGlobal';
import ScContainer from './sc/ScContainer';
import ScMultipleImagePreview from './sc/ScMultipleImagePreview';

const ImageRenderer = ({
  image,
  onRemove,
  id,
  layout
}) => (
  <ImagePreview
    id={id}
    imageURL={image}
    onRemove={onRemove}
    layout={layout}
    RemoveButtonRenderer={buttonProps => (
      <div className="multipleImage-removeBtn" {...buttonProps}>
        <IconTrashFilled />
      </div>
    )}
  />
);

ImageRenderer.propTypes = {
  image: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  layout: PropTypes.string
};

ImageRenderer.defaultProps = {
  layout: 'column'
};

const SortableItem = sortableElement(props => <ImageRenderer {...props} />);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="container">{children}</ul>;
});

function ImageUploadWithViewer({
  isSortable,
  imageUploadProps,
  onChange,
  defaultValue,
  buttonText,
  layout,
  targetURL
}) {
  const [selectedImages, setSelectedImages] = useState(defaultValue);
  const [isImageUploadVisible, setImageUplaodVisibility] = useState(false);
  useEffect(() => {
    if (defaultValue && !isEqual(defaultValue, selectedImages)) {
      setSelectedImages(defaultValue);
    }
  }, [defaultValue]);

  const handleShouldSortingStart = e => {
    return !!closest(e.target, '.rightSideWrapper', '.container');
  };

  const handleImageSelect = url => {
    setSelectedImages(prevState => [...prevState, ...url]);
  };

  const handleOnImageUpload = res => {
    if (Array.isArray(res) && res.length > 0) {
      setSelectedImages(prevState => [...prevState, ...res.map(p => p.url)]);
    }
  };

  const handleOnLinkInputSubmit = res => {
    if (res.length === 0) { return false; }
    const arr = [];
    res.split('\n').forEach(p => {
      if (p) {
        arr.push(p);
      }
    });

    setSelectedImages(prevState => [...prevState, ...arr]);
  };

  const handleRemoveImage = idx => {
    const images = [...selectedImages];
    images.splice(idx, 1);
    setSelectedImages(images);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedImages(prevState => arrayMove(prevState, oldIndex, newIndex));
  };

  Hooks.useEffectIgnoreFirst(() => {
    onChange(selectedImages);
    setImageUplaodVisibility(false);
  }, [selectedImages]);

  return (
    <ThemeProvider theme={{}}>
      <ScGlobal />
      <ScContainer>
        {isSortable && (
        <SortableContainer
          axis="xy"
          onSortEnd={onSortEnd}
          helperClass='sortableHelper'
          shouldCancelStart={handleShouldSortingStart}
        >
          <ScMultipleImagePreview sortable={isSortable}>
            {selectedImages.map((selectedImage, index) => (
              <SortableItem
                key={`item-${selectedImage.substr(-10)}`}
                id={index}
                index={index}
                image={selectedImage}
                layout={layout}
                onRemove={handleRemoveImage}
              />
            ))}
          </ScMultipleImagePreview>
        </SortableContainer>
        )}

        {!isSortable && (
        <ScMultipleImagePreview>
          {selectedImages.map((image, index) => (
            <ImageRenderer
              id={index} image={image} index={index}
              layout={layout} onRemove={handleRemoveImage}
            />
          ))}
        </ScMultipleImagePreview>
        )}

        <Button
          type="button"
          fullWidth
          variant='outline'
          className='customMagnetBtn'
          onClick={() => setImageUplaodVisibility(prevState => !prevState)}
        >
          {buttonText}
        </Button>

        {isImageUploadVisible && (
        <ImageUpload
          allowLinkInputLabel={true}
          useAPI={true}
          renderTabs={['upload', 'choose', 'link']}
          {...imageUploadProps}
          onImageSelect={handleImageSelect}
          onImageUpload={handleOnImageUpload}
          onLinkTextInputSubmit={handleOnLinkInputSubmit}
          allowMultipleSelection={true}
          multipleFileUpload={true}
          targetURL={targetURL}
        />
        )}
      </ScContainer>
    </ThemeProvider>
  );
}

ImageUploadWithViewer.propTypes = {
  isSortable: PropTypes.bool,
  imageUploadProps: PropTypes.shape({}),
  buttonText: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.string,
  targetURL: PropTypes.string
};

ImageUploadWithViewer.defaultProps = {
  isSortable: true,
  imageUploadProps: {},
  buttonText: t('Choose Images'),
  onChange: f => f,
  defaultValue: [],
  layout: 'column',
  targetURL: undefined
};

export default ImageUploadWithViewer;
