import React from 'react';
import { t } from '@jotforminc/translation';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';
import DeletePageModal from '../DeletePageModal';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import { RightPanelModes } from '../../HomePage/constants';

const PreventDetailPageDeletionModal = ({ onClose = () => {}, dsItemID = '', ...rest }) => {
  const dispatch = useDispatch();

  const openListActionTab = () => {
    dispatch(ACTION_CREATORS.selectPortalItemAction(dsItemID));
    dispatch(ACTION_CREATORS.openRightPanelWithModeAction(RightPanelModes.APP_ITEM));
    dispatch(ACTION_CREATORS.setActiveRightPanelTab('Action'));
    onClose();
  };

  return (
    <DeletePageModal
      {...rest}
      onClose={onClose}
      showDontShowAgain={false}
      forceDeleteItems
      confirmationDisable
      title={t('Update required before deletion')}
      subtitle={t("To delete this detail page, first ensure the list's action is not linked to it.")}
      description={(
        <div>
          <span>
            {t("You can update the list's action in the")}
          </span>
          {' '}
          <span className='select-none cursor-pointer color-blue-400' onClick={openListActionTab}>
            {t('list properties')}
          </span>
        </div>
)}
    />
  );
};

PreventDetailPageDeletionModal.propTypes = {
  onClose: func,
  dsItemID: string
};

export default PreventDetailPageDeletionModal;
