import isEmpty from 'lodash/isEmpty';
import { t } from '@jotforminc/translation';
import { Slider } from '@jotforminc/jotform-common';
import {
  bool, elementType, func, string
} from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIONS from '../../../store/actionCreators';
import SELECTORS from '../../../store/selectors';
import { parseAndGenerateOptionProperties } from '../../../utils';
import { DESTINATION_TYPES } from '../../../constants/navigation';
import ProductFavorite from '../ProductDetailContentRenderer/ProductFavorite';
import ProductDetailOptionsGroup from '../ProductDetailContentRenderer/ProductDetailOptionsGroup';
import ScDetailsModal from '../ProductDetailContentRenderer/ScDetailsModal';
import ProductDetailActionButtons from '../ProductDetailContentRenderer/ProductDetailActionButtons';
import CloseButton from '../ProductDetailContentRenderer/CloseButtonRenderer';
import { useFormattedPrice } from '../../../utils/hooks';

const ProductItemDetails = ({
  onClose,
  CloseButtonRenderer,
  ProductDetailActionButtonsRenderer,
  ContentRendererWrapper,
  showFavorite,
  itemID,
  productID
}) => {
  const dispatch = useDispatch();

  const isFastCheckoutActive = useSelector(SELECTORS.getIsFastCheckoutActive);
  const {
    name, description, price, pid, images, options = [], fitImageToCanvas
  } = useSelector(SELECTORS.getFormProductListProduct(itemID, productID));
  const { formID } = useSelector(SELECTORS.getPortalItemByIDSelector(itemID));
  const selectedOptionsValue = useSelector(SELECTORS.getSelectedProductOptions(formID, productID));
  const isFavorite = useSelector(SELECTORS.getIsProductFavorite(itemID, productID));
  const isProductSelected = useSelector(SELECTORS.getIsProductSelected(itemID, productID));

  const getFormattedPrice = useFormattedPrice();

  const [optionsValue, setOptionsValue] = useState(selectedOptionsValue);

  const handleProductItemChange = (shouldCloseModal = true) => {
    dispatch(ACTIONS.onProductItemDetailsAction({
      itemID,
      productProps: {
        productID: pid,
        ...isEmpty(optionsValue) ? {} : { optionsValue }
      }
    }));

    if (shouldCloseModal) {
      onClose();
    }
  };

  const handleFastCheckout = () => {
    dispatch(ACTIONS.addOrUpdateCartAction(formID, productID, { options: optionsValue }));
    onClose();
  };

  const handleNavigateOrderNow = () => {
    handleProductItemChange();
    dispatch(ACTIONS.navigateToAction({ to: DESTINATION_TYPES.SPECIAL_PAGE, pageID: 'checkout' }));
  };

  const handleFavorite = () => dispatch(ACTIONS.favoriteProductAction(itemID, productID));

  const handleOptionsChange = newValue => setOptionsValue(prev => ({ ...prev, ...newValue }));

  const mappedOptions = options.map((option, index) => {
    const { properties, type } = option;

    const optionValue = optionsValue[index.toString()];
    const dropdownProperties = parseAndGenerateOptionProperties(properties, type);

    if (!optionValue) {
      const initialValue = dropdownProperties[0].value;
      handleOptionsChange({ [index.toString()]: initialValue });
    }

    return {
      ...option,
      dropdownProperties,
      optionValue
    };
  });

  const hasOptions = options && Array.isArray(options) && options.length > 0;

  return (
    <ContentRendererWrapper fitImageToCanvas={fitImageToCanvas}>
      <div className="modal-content-wrapper">
        <div className="modal-actions-wrapper">
          <div className="modal-actions">
            {showFavorite && <ProductFavorite isFastCheckoutActive={isFastCheckoutActive} isProductFavorite={isFavorite} onFavorite={handleFavorite} />}
            <CloseButtonRenderer onClose={onClose} />
          </div>
        </div>
        <Slider imgArray={images} />
        <div className="content">
          <div className="product-info">
            <div className="product-name">{name}</div>
            <div className="product-price">
              {getFormattedPrice(price)}
            </div>
          </div>
          <div className="product-details">
            <div className="product-options">
              <ProductDetailOptionsGroup
                options={mappedOptions}
                onChange={handleOptionsChange}
                isFastCheckoutActive={isFastCheckoutActive}
              />
            </div>
            <div className="product-description">{description}</div>
          </div>
        </div>
      </div>
      <ProductDetailActionButtonsRenderer
        isFastCheckoutActive={isFastCheckoutActive}
        onNavigateOrderNow={handleNavigateOrderNow}
        showDoneButton={!isProductSelected || hasOptions}
        isProductSelected={isProductSelected}
        onProductItemChange={handleProductItemChange}
        onFastCheckout={handleFastCheckout}
        actionButtonText={isProductSelected ? t('Update Cart') : t('Add to Cart')}
      />
    </ContentRendererWrapper>
  );
};

ProductItemDetails.propTypes = {
  onClose: func,
  CloseButtonRenderer: elementType,
  ProductDetailActionButtonsRenderer: elementType,
  ContentRendererWrapper: elementType,
  showFavorite: bool,
  itemID: string.isRequired,
  productID: string
};

ProductItemDetails.defaultProps = {
  onClose: f => f,
  CloseButtonRenderer: CloseButton,
  ProductDetailActionButtonsRenderer: ProductDetailActionButtons,
  ContentRendererWrapper: ScDetailsModal,
  showFavorite: true,
  productID: ''
};

export default ProductItemDetails;
