import {
  arrayOf, bool, func, objectOf, oneOfType, shape, string
} from 'prop-types';
import React from 'react';
import { t } from '@jotforminc/translation';

import SelectionGroup from './SelectionGroup';
import Option from './Option';

export default function SingleForm({
  formTitle,
  questions,
  hasForm,
  isSingle,
  setMenuVisibility,
  defaultValue,
  onChange,
  showOptionValue,
  isEncrypted
}) {
  const checkIsEncrypted = value => (isEncrypted && !['{form_title}', '{id}'].includes(value));

  const refactoredQuestions = questions.map(question => ({
    ...question,
    isEncrypted: checkIsEncrypted(question.value)
  }));
  return (
    <div className="formFields-wrapper" key={formTitle}>
      <div className="formFields-select">
        {formTitle && (
        <div className="formFields-header">
          {formTitle}
        </div>
        )}
        {questions.length !== 0 && hasForm && (
        <SelectionGroup
          isMultiSelect={isSingle} // yes!
          options={refactoredQuestions}
          defaultValue={isSingle ? defaultValue : ''}
          onSelectionChange={val => {
            if (!isSingle) {
              setMenuVisibility(false);
            }
            onChange(isSingle ? val : [val]);
          }}
          OptionRenderer={Option(isSingle, showOptionValue)}
        />
        )}
        {questions.length === 0 && hasForm && (
        <div className="formFields-empty">
          {t("Your form doesn't have any appropriate fields to add.")}
        </div>
        )}
        {!hasForm && (
        <div className="formFields-empty">
          {t("You don't have a form to select a field.")}
        </div>
        )}
      </div>
    </div>
  );
}

const questionsPropType = oneOfType([
  arrayOf(shape({ text: string, qid: string })),
  objectOf(shape({ text: string, qid: string }))
]);

SingleForm.defaultProps = {
  formTitle: '',
  showOptionValue: false,
  isEncrypted: false
};

SingleForm.propTypes = {
  formTitle: string,
  questions: questionsPropType.isRequired,
  hasForm: bool.isRequired,
  isSingle: bool.isRequired,
  setMenuVisibility: func.isRequired,
  defaultValue: arrayOf(string).isRequired,
  onChange: func.isRequired,
  showOptionValue: bool,
  isEncrypted: bool
};
