/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import MultiStepModal from '../../../modules/Builder/components/Modals/MultiStepModal';
import { SharedStateProvider } from '../../../modules/Builder/components/Modals/MultiStepModal/SharedStateContext';
import TableSelection from './Steps/TableSelection';
import ColumnSelection from './Steps/ColumnSelection';
import './style.scss';

const DataSourcePickerModal = props => {
  const [steps, setSteps] = useState([
    TableSelection,
    ColumnSelection
  ]);

  return (
    <SharedStateProvider>
      <MultiStepModal
        steps={steps}
        setSteps={setSteps}
        modalProps={props}
      />
    </SharedStateProvider>
  );
};

export default DataSourcePickerModal;
