import React, { forwardRef } from 'react';
import { elementType, shape } from 'prop-types';
import { ScUIKitTooltip, ScTooltipArrow } from './scTooltip';

const Tooltip = forwardRef(({ popoverOptions, children, ...props }, ref) => {
  return (
    <ScUIKitTooltip
      targetRef={ref}
      popoverOptions={popoverOptions}
      {...props}
    >
      {children}
      <ScTooltipArrow
        data-popper-arrow
      />
    </ScUIKitTooltip>
  );
});

Tooltip.propTypes = {
  popoverOptions: shape({}),
  children: elementType
};
Tooltip.defaultProps = {
  popoverOptions: {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  },
  children: f => f
};
export default Tooltip;
