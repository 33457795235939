import React, { useCallback } from 'react';
import Styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useDebounce } from '@jotforminc/hooks';
import ColorPalette from './index';
import { applyColorSchemeToAppAction } from '../../../../store/actionCreators';

const ScColorSchemeButton = Styled.div`
  button {
    font-size: 16px;
    cursor: pointer;
    margin: 3px;
    padding: 0;
    height: 40px;
    width: 40px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ fontColor }) => fontColor};
    border: ${({ borderColor }) => `4px solid ${borderColor}`};

    &.selected {
      border-radius: 2px;
      outline: #fff solid 1px;
    }
  }
`;

const AppColorSchemes = () => {
  const dispatch = useDispatch();

  const ItemWrapper = useCallback(props => <div {...props} style={{ display: 'flex', flexWrap: 'wrap', gap: '6px' }} />, []);
  const _handleChange = useCallback(data => dispatch(applyColorSchemeToAppAction(data)), []);
  const handleChange = useDebounce(_handleChange);

  return (
    <ColorPalette
      onChange={handleChange}
      ButtonWrapper={ScColorSchemeButton}
      Wrapper={ItemWrapper}
    />
  );
};
export default AppColorSchemes;
