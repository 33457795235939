import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import appConfig from '../../constants/appConfig';
import SELECTORS from '../../store/selectors';
import { APP_MODES } from '../../constants';

const Toaster = () => {
  const appMode = useSelector(SELECTORS.getAppModeSelector);
  const toasterContainerID = appMode === APP_MODES.builder ? appConfig.builderToasterContainerID : appConfig.publicToasterContainerID;
  const toasterContainerSelector = `body #${toasterContainerID}`;

  if (!document.body.querySelector(toasterContainerSelector)) {
    const element = document.createElement('div');
    element.setAttribute('id', toasterContainerID);
    document.body.appendChild(element);
  }

  return ReactDOM.createPortal(
    <ToastContainer
      icon={false}
      newestOnTop={true}
      hideProgressBar={true}
      pauseOnHover={true}
      closeButton={false}
    />, document.body.querySelector(toasterContainerSelector)
  );
};

export default Toaster;
