import React from 'react';
import { EmptyList } from '@jotforminc/empty-list';
import { bool } from 'prop-types';

const EmptyListPlaceholder = ({ isTeamSelected }) => {
  return (
    <EmptyList resourceType={!isTeamSelected ? 'form' : 'teamform'} />
  );
};

EmptyListPlaceholder.propTypes = {
  isTeamSelected: bool.isRequired
};

export default EmptyListPlaceholder;
