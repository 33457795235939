import React, { useCallback, useEffect } from 'react';
import { FormWizard } from '@jotforminc/form-wizard';
import COMPONENT_TYPE_LIST from '../constants/componentTypes';
import { BILLING_REQUIRED_GATEWAYS, CHECKOUT_FORM_QUESTIONS } from '../constants/variables';
import { IGatewaySettings, IQuestions, TPaymentType } from '../types/common';
import { getProductListSettingsProperties } from '../utils';

type CheckoutFormVisibilitySettingsFormType = {
  paymentType: TPaymentType,
  activeGatewaySettings: IGatewaySettings,
  checkoutFormQuestions: IQuestions,
  onCheckoutFormQuestionUpdate: (id: number | string, prop: { [key: string]: string }) => void
}

const CheckoutFormVisibilitySettingsForm = ({
  paymentType, activeGatewaySettings, checkoutFormQuestions, onCheckoutFormQuestionUpdate
}: CheckoutFormVisibilitySettingsFormType): JSX.Element => {
  const handleCheckoutFormQuestionVisibilityChanges = useCallback((_: any, { key, value }: { key: number, value: string }) => {
    onCheckoutFormQuestionUpdate(key, { hidden: value === 'Yes' ? 'No' : 'Yes' });
  }, []);

  const activeGatewayType = activeGatewaySettings?.type;
  useEffect(() => {
    const billingAddressVisibility = checkoutFormQuestions[CHECKOUT_FORM_QUESTIONS.BILLING_ADDRESS]?.hidden;
    if (billingAddressVisibility === 'Yes' && BILLING_REQUIRED_GATEWAYS.includes(activeGatewayType)) {
      handleCheckoutFormQuestionVisibilityChanges(null, { key: CHECKOUT_FORM_QUESTIONS.BILLING_ADDRESS, value: 'Yes' });
    }
  }, [activeGatewayType, checkoutFormQuestions, handleCheckoutFormQuestionVisibilityChanges]);

  const checkoutFormQuestionsVisibilitySettings = getProductListSettingsProperties('checkoutFormQuestionsVisibilitySettings');

  const checkoutFormQuestionElements = checkoutFormQuestionsVisibilitySettings?.basicElementList({ checkoutFormQuestions, activeGatewaySettings, paymentType });

  return (
    <FormWizard
      onChange={handleCheckoutFormQuestionVisibilityChanges}
      elementList={checkoutFormQuestionElements}
      componentTypeList={COMPONENT_TYPE_LIST}
    />
  );
};

export default CheckoutFormVisibilitySettingsForm;
