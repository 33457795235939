import React from 'react';
import { SVGIcon, EmojiRenderer } from '@jotforminc/icon-selector';
import { number, string } from 'prop-types';

const TeamImage = ({
  url, icon, emojiId, emojiSize, iconColor, iconSvgRef
}) => {
  if (url) return (<img src={url} alt="Team Avatar" data-testid="team-avatar-image" />);

  if (emojiId) return (<EmojiRenderer id={emojiId} size={emojiSize} />);

  const isIconSpriteLoaded = !!document.getElementById('app-icons');

  return isIconSpriteLoaded && iconSvgRef ? (
    <div className="jfIconSVG-wrapper">
      <svg
        fill={iconColor}
        viewBox="0 0 24 24"
        style={{ width: '100%', height: '100%' }}
      >
        <use xlinkHref={`#${iconSvgRef}`} href={`#${iconSvgRef}`} />
      </svg>
    </div>
  ) : (
    <SVGIcon
      url={icon}
      iconColor={iconColor}
    />
  );
};

TeamImage.propTypes = {
  url: string,
  icon: string,
  emojiId: string,
  emojiSize: number,
  iconColor: string,
  iconSvgRef: string
};

TeamImage.defaultProps = {
  url: '',
  icon: '',
  emojiId: '',
  emojiSize: 24,
  iconColor: '',
  iconSvgRef: ''
};

export default TeamImage;
