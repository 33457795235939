import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import SVGIcon from '../../SVGIcon';
import { ITEM_TYPES } from '../../../constants/itemTypes';
import { getDocumentType } from '../../../utils';
import { ScItemIcon } from '../../ItemIcon/scItemIcon';
import { ITEM_ICONS, RESOURCE_SELECTION_ICONS } from '../../../constants';
import { FEATURE_NAMES } from '../../../constants/features';
import { isFeatureEnabled } from '../../../utils/features/helper';

export const typeHasItem = type => Object.keys(ITEM_ICONS).includes(type);

const SimpleIcon = ({
  iconURL, itemType, fileType, itemTextAlignment, itemIconBgColor, itemIconColor, elementSize, useResourceSelectionIcons, iconClassName
}) => {
  if (!typeHasItem(itemType)) return null;

  const isFullIconUrlEnabled = isFeatureEnabled(FEATURE_NAMES.FullIconUrl);
  const url = isFullIconUrlEnabled ? `https://cdn.jotfor.ms${iconURL}` : iconURL;

  const iconProps = useResourceSelectionIcons ? RESOURCE_SELECTION_ICONS[itemType] : (
    itemType === ITEM_TYPES.DOCUMENT
      ? ITEM_ICONS[itemType][getDocumentType(fileType)]
      : ITEM_ICONS[itemType]
  ) || {};
  const { Icon, bgColor } = iconProps;
  const ItemIcon = useMemo(() => (iconURL ? <SVGIcon url={url} /> : <Icon />), [iconURL, Icon]);
  if (!ItemIcon) return null;

  return (
    <ScItemIcon
      backgroundColor={bgColor || itemIconBgColor}
      itemTextAlignment={itemTextAlignment}
      iconColor={itemIconColor}
      elementSize={elementSize}
      className={iconClassName}
    >
      <div>
        {ItemIcon}
      </div>
    </ScItemIcon>
  );
};

SimpleIcon.propTypes = {
  iconURL: string,
  itemType: string,
  fileType: string,
  itemTextAlignment: string,
  itemIconBgColor: string,
  itemIconColor: string,
  elementSize: string,
  useResourceSelectionIcons: bool,
  iconClassName: string
};

SimpleIcon.defaultProps = {
  iconURL: '',
  itemType: '',
  fileType: '',
  itemTextAlignment: '',
  itemIconBgColor: '',
  itemIconColor: '',
  elementSize: '',
  useResourceSelectionIcons: false,
  iconClassName: ''
};

export default SimpleIcon;
