import { useMemo } from 'react';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import type { LOGGED_USER } from '../types';

interface USER_ROLES {
  isInTeamAsset: boolean;
  isTeamMemberAndCreator: boolean;
  isTeamAdmin: boolean;
  isOrganizationAdmin: boolean;
}

/**
 * Custom hook to determine user roles and permissions.
 *
 * @param {LOGGED_USER | null} user - The logged-in user object or null if no user is logged in.
 * @returns {USER_ROLES} An object containing various boolean flags indicating the user's roles and permissions:
 * - `isInTeamAsset`: Whether the user is a member of a team and the team ID is available.
 * - `isTeamMemberAndCreator`: Whether the user is a team member and has the role of 'form_collaborator'.
 * - `isTeamAdmin`: Whether the user has the 'team_admin' role.
 * - `isOrganizationAdmin`: Whether the user is an organization admin in an enterprise account.
 */
export const useUserRoles = (user: LOGGED_USER | null): USER_ROLES => {
  return useMemo(() => {
    const teamID = window.teamID ?? null;
    const isTeamMember = window.isTeamMember ?? null;
    const isInTeamAsset = Boolean(isTeamMember && teamID);

    const userAccountType = user?.accountType ?? user?.account_type?.name;
    const userTeamRoles = user?.teamRoles ?? [];

    const isTeamMemberAndCreator = Boolean(
      isInTeamAsset
      && userTeamRoles.length === 1
      && userTeamRoles.includes('form_collaborator')
    );

    const isTeamAdmin = userTeamRoles.includes('team_admin');
    const isOrganizationAdmin = isEnterprise() && userAccountType === 'ADMIN';

    return {
      isInTeamAsset,
      isTeamMemberAndCreator,
      isTeamAdmin,
      isOrganizationAdmin
    };
  }, [user]);
};
