import React from 'react';
import { arrayOf, string } from 'prop-types';
import { isYes } from '../../../utils';

const ImageRenderer = ({ images: [firstImage], fitImageToCanvas }) => (firstImage ? (
  <div className="product-image">
    <img style={{ objectFit: isYes(fitImageToCanvas) ? 'cover' : 'contain' }} alt="productImg" src={firstImage} />
  </div>
) : null);

ImageRenderer.propTypes = {
  images: arrayOf(string),
  fitImageToCanvas: string
};

ImageRenderer.defaultProps = {
  images: [],
  fitImageToCanvas: 'Yes'
};

export default ImageRenderer;
