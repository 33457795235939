import React, { ChangeEvent, useMemo } from 'react';
import { t } from '@jotforminc/translation';
import type { DROPDOWN_COMPONENT_PROPERTIES, PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import { TBuilderDropdown } from '../../../../types/common';
import DynamicDropdown from './DynamicDropdown';
import Dropdown from '../../../fields/selectbox';

interface IDropdown {
  BuilderDropdown: TBuilderDropdown;
  name: PAYMENT_QUESTION_PROP_NAMES;
  required: boolean;
  onPropertyChange: (key: string, value: string) => void;
}

const DD: React.FunctionComponent<IDropdown & DROPDOWN_COMPONENT_PROPERTIES> = ({
  title,
  BuilderDropdown,
  name,
  subType,
  options,
  default: defaultValue,
  questionTypesAsOption,
  questionTypeToAdd,
  onPropertyChange,
  addressSubfields,
  required
}) => {
  const {
    formQuestions,
    gatewayProperties,
    invalidPropNames,
    resource,
    getQidOnAddQuestion
  } = usePaymentPropsPanel();

  const prepareSubtypeOptions = () => {
    const noneOption = { id: 'none', key: 'none', text: t('None') };
    const filteredQuestions = formQuestions.filter(q => questionTypesAsOption && questionTypesAsOption.includes(q.type));

    if (filteredQuestions && filteredQuestions.length > 0) {
      return [
        noneOption,
        ...(options || []), // add options as static along with dynamic ones, if any
        ...filteredQuestions.map(q => ({ id: q.qid, key: q.qid, text: q.text }))
      ];
    }
    return [
      { id: 'addone', key: 'addone', text: t('Add field') },
      noneOption
    ];
  };

  const onDropdownChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    if (subType === 'addQuestion' && resource === 'FORM') {
      const val = target.getAttribute('dataKey');
      if (val === 'addone' && typeof getQidOnAddQuestion === 'function' && questionTypeToAdd) {
        const qid = await getQidOnAddQuestion({
          type: questionTypeToAdd,
          required: required ? 'Yes' : 'No',
          subfields: addressSubfields || 'state|zip|st1|city|st2'
        });

        return qid;
      }

      return val;
    }

    return target.value;
  };

  const value = gatewayProperties[name] || defaultValue;
  const dropdownOptions = subType === 'addQuestion' || subType === 'formQuestion' ? prepareSubtypeOptions() : options;
  const translatedDropdownOptions = useMemo(() => {
    return dropdownOptions ? dropdownOptions.reduce((prev, acc) => {
      return [...prev, { ...acc, text: t(acc.text) }];
    }, []) : [];
  }, [dropdownOptions]);

  const appOpts = useMemo(() => {
    return translatedDropdownOptions ? translatedDropdownOptions.reduce((prev, acc) => {
      return [...prev, { value: acc.key, text: acc.text }];
    }, []) : [];
  }, [translatedDropdownOptions]);

  if (subType === 'dynamicDropdown') {
    return (
      <DynamicDropdown
        propName={name}
        BuilderDropdown={BuilderDropdown}
        aria-label={`${title} Dropdown`}
        className="m-dropdown"
        testId={name}
        options={translatedDropdownOptions}
        value={value}
        defaultValue={defaultValue}
        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
          const qid = await onDropdownChange(e);
          if (qid) {
            onPropertyChange(name, qid);
          }
        }}
        onChangeWithData={(data: string) => {
          onPropertyChange(name, data);
        }}
        isErrored={invalidPropNames.includes(name)}
      />
    );
  }

  // IT WILL BE COMMONIZED WHEN MAGNET DROPDOWN IS READY
  if (resource !== 'FORM') {
    return (
      <Dropdown
        defaultValue={value}
        options={appOpts}
        onChange={data => {
          onPropertyChange(name, data);
        }}
      />
    );
  }

  return (
    <BuilderDropdown
      {...(subType === 'addQuestion' && { type: 'newQuestion' })}
      aria-label={`${title} Dropdown`}
      disabled={false}
      className="m-dropdown"
      testId={name}
      options={translatedDropdownOptions}
      value={value}
      defaultValue={defaultValue}
      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
        const qid = await onDropdownChange(e);
        if (qid) {
          onPropertyChange(name, qid);
        }
      }}
      isErrored={invalidPropNames.includes(name)}
    />
  );
};

export default DD;
