import React, { forwardRef } from 'react';
import { elementType, node, oneOfType } from 'prop-types';

import { StyledModal as Modal } from '@jotforminc/styled-modal';
import { ScRB } from '../styles/ScRB';

const PickerModal = forwardRef(({
  children,
  footer,
  ...modalProps
}, ref) => (
  <Modal
    {...modalProps}
    ref={ref}
    footer={footer}
  >
    <ScRB data-sc="picker-body">
      {children}
    </ScRB>
  </Modal>
));

PickerModal.propTypes = {
  children: oneOfType([elementType, node]),
  footer: oneOfType([elementType, node])
};

PickerModal.defaultProps = {
  children: null,
  footer: null
};

export default PickerModal;
