import { getBaseURL } from '@jotforminc/router-bridge';
import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

let basePATH = getBaseURL();
if (window.STORYBOOK_ENV) {
  basePATH = `https://${window.location.hostname}`;
}

const layer = new RequestLayer(`${basePATH}/API`, {
  interceptorConfig: {
    teamID: window.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  },
  engineConfig: {
    validateStatus: () => true
  }
});

export default layer;
