import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Cardconnect gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlCardconnect: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_cardconnect: {
    addCaptcha: {
      title: 'Add Captcha',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_captcha'],
      questionTypeToAdd: 'control_captcha',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: true,
      autoSave: true
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlCardconnect;
