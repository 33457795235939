import { createSelector } from 'reselect';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { t } from '@jotforminc/translation';
import { safeJSONParse } from '@jotforminc/utils';
import { useAppDefaults, usePageDefaults } from '../../properties';
import {
  APP_SCHEME_KEYS,
  getAppPaletteColors,
  getAppStylingColors,
  ITEM_SCHEME_KEYS,
  SCHEME_TYPES
} from '../../properties/styling';
import { isYes } from '../../utils';
import { ITEM_TYPES } from '../../constants/itemTypes';
import { BUTTON_ROLE_TYPES } from '../../modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { systemFontAlternativeMap } from '../../modules/Builder/components/FontPicker/fonts';
import * as UI_SELECTORS from './uiSelectors';
import { includeJotformFonts } from '../utils';

export const getPortalInfoSelector = state => state.portal;
export const getPortalPages = state => state.portal.pages;
export const getPortalHomepageName = state => state.portal.homepageName;

export const getUpdatedAt = createSelector(getPortalInfoSelector, ({ updated_at: updatedAt }) => updatedAt);
export const getAppUpdatedAt = createSelector(getPortalInfoSelector, ({ appUpdatedAt }) => appUpdatedAt);
export const getScope = createSelector(getPortalInfoSelector, ({ scope }) => scope);
export const getAppVersionSelector = createSelector(getPortalInfoSelector, app => (app.appVersion ? app.appVersion.toString() : '0')); // Non versioned apps are simply 0
export const getAppInfoWithDefaults = createSelector(getPortalInfoSelector, app => {
  const { appVersion } = app;
  const defaultsByVersion = useAppDefaults(appVersion);
  return { ...defaultsByVersion, ...app };
});

export const getDataSourcePage = itemID => createSelector(getPortalPages, pages => pages.find(page => page.linkedItemID && page.linkedItemID === itemID));
export const getDataSourcePages = itemID => createSelector(getPortalPages, pages => pages.filter(page => page.linkedItemID && page.linkedItemID === itemID));

export const getAppVersion = state => state.portal.appVersion;
export const getAppDefaults = createSelector(getAppVersion, appVersion => {
  return useAppDefaults(appVersion);
});

export const getIsAssigneeProtected = state => state.portal.assigneeProtected;
export const getIsOrganizationAccess = state => state.portal.organizationAccess;
export const getSsoProtected = state => state.portal.ssoProtected;
export const getIsGatewayConnectedDirect = state => state.portal.isGatewayConnected;
export const getIsGatewayConfigured = state => state.portal.isGatewayConfigured;

export const avaliableItemAppProperties = createSelector(
  getIsAssigneeProtected,
  getIsOrganizationAccess,
  getSsoProtected,
  getIsGatewayConfigured,
  getIsGatewayConnectedDirect,
  getAppDefaults,
  (assigneeProtected, organizationAccess, ssoProtected, isGatewayConfigured, isGatewayConnected, appDefaults) => {
    return {
      assigneeProtected,
      organizationAccess,
      ssoProtected,
      isGatewayConfigured,
      isGatewayConnected,
      ...appDefaults
    };
  }

);
export const getAppID = createSelector(getAppInfoWithDefaults, ({ id }) => id);
export const getAppBgProperties = createSelector(getAppInfoWithDefaults, ({ appBgColor, appBgColorEnd, appBgURL }) => ({ appBgColor, appBgColorEnd, appBgURL }));
export const getCreatedWithForm = createSelector(getAppInfoWithDefaults, ({ createdWithForm }) => createdWithForm);
export const getPortalItems = createSelector(getPortalInfoSelector, portal => portal.items);
export const getPortalItemsCount = createSelector(getPortalItems, items => items.length);
export const getPortalItemByIDSelector = id => createSelector(getPortalItems, items => (items ? (items.find(({ id: itemID }) => id && (id === itemID)) || {}) : {}));
export const getPortalItemsByPage = page => createSelector(getPortalItems, items => {
  if (!page) {
    return [];
  }

  return items.filter(({ page: pageID = 'homepage' }) => pageID.toString() === page.toString());
});
export const getPortalIDSelector = createSelector(getPortalInfoSelector, portal => portal.id);
export const getPortalType = createSelector(getPortalInfoSelector, ({ type }) => type);
export const getDesignatedItemIDSelector = createSelector(getPortalInfoSelector, portal => portal.designatedItemProps);
export const getAppSlug = createSelector(getPortalInfoSelector, portal => portal.slug);
export const getPortalOwner = createSelector(getPortalInfoSelector, ({ username }) => username);
export const getPushNotificationStatus = createSelector(getPortalInfoSelector, ({ pushNotification }) => pushNotification);
export const getIsAppPrivate = createSelector(getAppInfoWithDefaults, ({ assigneeProtected, ssoProtected, organizationAccess }) => {
  return isYes(assigneeProtected) || isYes(ssoProtected) || !!organizationAccess;
});
export const selectIsAppLoginable = createSelector(getAppInfoWithDefaults, ({
  loginable, assigneeProtected, ssoProtected, organizationAccess, requireLogin
}) => {
  if (!isEnterprise()) {
    return isYes(loginable);
  }

  const isAppPrivate = isYes(assigneeProtected) || isYes(ssoProtected) || !!organizationAccess;
  const isAppRequireLogin = isYes(requireLogin);

  return isAppPrivate || isAppRequireLogin;
});
export const getUsedPortalFormIDs = createSelector(getPortalItems, portalItems => {
  return portalItems
    .filter(({ type, buttonRole = '' }) => ITEM_TYPES.FORM === type || (ITEM_TYPES.BUTTON === type && buttonRole === BUTTON_ROLE_TYPES.FORM))
    .map(({ id, type, buttonValue = '' }) => (ITEM_TYPES.FORM === type ? id : buttonValue));
});
export const getTodoItems = createSelector(getPortalItems, items => items.filter(item => isYes(item.required_showBadge)));
export const getIsProgressBarVisible = createSelector(getPortalInfoSelector, ({ showProgressBar }) => isYes(showProgressBar));
export const getProgressRestartable = createSelector(getPortalInfoSelector, ({ progressRestartable }) => isYes(progressRestartable));
export const getHomepageName = createSelector(getPortalHomepageName, homepageName => homepageName || t('Home'));
export const getPages = createSelector(getPortalPages, pages => {
  const pageDefaults = usePageDefaults();
  const homePageDefaults = { showPageIcon: 'Yes' };

  const _pages = sortBy(pages, p => parseInt(p.pageOrder, 10)).map(p => ({ ...pageDefaults, ...(p.pageOrder === '0' ? homePageDefaults : {}), ...p }));
  if (isFeatureEnabled(FEATURE_NAMES.SortPages)) {
    return _pages;
  }

  return (_pages.some(p => p.id?.toString() === 'homepage') ? _pages : [{ id: 'homepage', pageOrder: 0 }, ..._pages]);
});
export const getPagesWithNames = createSelector([getPages, getHomepageName], (pages, homepageName) => {
  return pages.map((page, index) => {
    const { name, pageIcon, showPageIcon } = page;

    if (index === 0) {
      return {
        ...page,
        ...name ? { name } : { name: homepageName },
        pageIcon: pageIcon,
        showPageIcon: showPageIcon || 'Yes',
        defaultName: homepageName
      };
    }

    const defaultName = t('Page {pageOrder}').replace('{pageOrder}', index);
    return { ...page, defaultName, ...name ? { name } : { name: defaultName } };
  });
});
export const getIsMultiPage = createSelector(getPortalPages, pages => pages.filter(page => !page.linkedItemID || page.linkedItemID === '').length > 1);
export const getFirstPageID = createSelector(getPages, pages => {
  const [firstPage = { id: 0, pageOrder: 0 }] = pages;
  return firstPage.id;
});
export const getIsHomepage = pageID => createSelector(getFirstPageID, firstPageID => firstPageID === pageID);
export const getRawPageByID = pageID => createSelector(getPortalInfoSelector, ({ pages }) => pages.find(p => p.id === pageID));
export const getShowPageIcon = pageID => createSelector(getRawPageByID(pageID), page => page?.showPageIcon || 'No');
export const getPageWithMaxPageOrder = createSelector(getPagesWithNames, pages => maxBy(pages, 'pageOrder'));
export const getPageByID = pageID => createSelector(getPages, pages => pages.filter(page => page.id === pageID)[0]);
export const getIsDataSourcePage = pageID => createSelector(getPageByID(pageID), page => !!page?.linkedItemID);

export const getLinkedItemByPageID = pageID => createSelector(
  [getPageByID(pageID), getPortalItems],
  (page, items) => {
    const { linkedItemID } = page;
    return items.find(item => item.id === linkedItemID);
  }
);

export const getPresentationItemByDsPageID = pageID => createSelector(
  [getLinkedItemByPageID(pageID), getPortalItems],
  (linkedItem, items) => {
    if (!linkedItem) return null; // Handle case where linkedItem might be null
    const { presentationItemID } = linkedItem;
    return items.find(item => item.id === presentationItemID);
  }
);

export const getShowJotFormPowered = createSelector(getPortalInfoSelector, ({ showJotFormPowered }) => showJotFormPowered);
export const getUserProductListLayout = createSelector(getPortalInfoSelector, ({ userProductListLayout }) => userProductListLayout);
export const getAppFontColor = createSelector(getPortalInfoSelector, ({ appFontColor }) => appFontColor);
export const getAppFontFamily = createSelector(getPortalInfoSelector, ({ appFontFamily }) => {
  if (systemFontAlternativeMap[appFontFamily]) {
    includeJotformFonts(systemFontAlternativeMap[appFontFamily].googleAlt);
    return systemFontAlternativeMap[appFontFamily].map;
  }
  return appFontFamily;
});
export const getAppHeaderBgColor = createSelector(getPortalInfoSelector, ({ appHeaderBgColor }) => appHeaderBgColor);
export const getIsFastCheckoutActive = createSelector(getPortalInfoSelector, ({ useFastCheckout }) => {
  return isYes(useFastCheckout);
});
export const getMobileMenuTitle = createSelector(getPortalInfoSelector, portal => {
  if (Object.prototype?.hasOwnProperty.call(portal, 'mobileMenuTitle')) {
    return portal.mobileMenuTitle;
  }

  return t('PAGES');
});
export const getAppLayout = createSelector(getPortalIDSelector, ({ appLayout }) => appLayout);

export const getProductListItems = createSelector(getPortalItems, items => items.filter(item => item.type === ITEM_TYPES.PRODUCT_LIST));
export const getDonationItems = createSelector(getPortalItems, items => items.filter(item => item.type === ITEM_TYPES.DONATION));
export const getDonationItemID = createSelector(getDonationItems, ([donationItem]) => donationItem?.id);
export const getAreThereMultipleProductLists = createSelector(getProductListItems, items => items.length > 1);

export const getSampleProductListElement = createSelector(getProductListItems, prodListElements => (prodListElements.length ? prodListElements[0] : {}));
export const getOverridingProps = createSelector(getPortalInfoSelector, ({ overridingItemProps }) => safeJSONParse(overridingItemProps));
export const getThemeProperties = createSelector(getPortalInfoSelector, ({ appFontColor, appHeaderBgColor }) => ({ appFontColor, appHeaderBgColor }));
export const getSchemeType = createSelector([UI_SELECTORS.getSelectedPortalItems, getPortalItems], (selectedItemIDs, allItems) => {
  const isAppScheme = selectedItemIDs.length === 0;
  const isButtonScheme = selectedItemIDs.length > 0 && allItems.filter(i => selectedItemIDs.includes(i.id)).every(i => i.type === ITEM_TYPES.BUTTON);
  const isDonationScheme = selectedItemIDs.length > 0 && allItems.filter(i => selectedItemIDs.includes(i.id)).every(i => i.type === ITEM_TYPES.DONATION);
  if (isAppScheme) {
    return SCHEME_TYPES.APP;
  }
  if (isButtonScheme) {
    return SCHEME_TYPES.BUTTON;
  }
  if (isDonationScheme) {
    return SCHEME_TYPES.DONATION;
  }
  return SCHEME_TYPES.ITEM;
});

export const getColorSchemeProps = createSelector([
  getPortalInfoSelector, getSchemeType, UI_SELECTORS.getSelectedPortalItems
], (portalInfo, schemeType, selectedItemIDs) => {
  const selectedItems = portalInfo.items.filter(item => selectedItemIDs.includes(item.id));
  const props = {};

  if (schemeType === SCHEME_TYPES.APP) {
    APP_SCHEME_KEYS.forEach(key => {
      props[key] = portalInfo[key];
    });
  } else {
    ITEM_SCHEME_KEYS.forEach(key => {
      let prop = selectedItems[0][key];
      selectedItems.forEach(item => {
        if (item[key] !== prop) {
          prop = '';
        }
      });
      props[key] = prop;
    });
  }
  return props;
});
export const getPortalColorSchemeProps = createSelector(getPortalInfoSelector, portalInfo => pick(portalInfo, APP_SCHEME_KEYS));
export const getSelectedAppTheme = createSelector(getPortalColorSchemeProps, portalColorSchemeProps => {
  return getAppPaletteColors().find(({ id }) => {
    const { designatedItemFontColor, ...stylingProps } = getAppStylingColors(id);
    return isEqual(portalColorSchemeProps, stylingProps);
  });
});
export const getMobileMenuIconProperties = createSelector(getAppInfoWithDefaults, ({ showMobileMenuIcon, mobileMenuIcon }) => ({ showMobileMenuIcon, mobileMenuIcon }));
export const getAppIconProperties = createSelector(getPortalInfoSelector, ({
  appIconType, appIconBackground, appIconColor, appIconSvgRef, appIconURL, installableIconURL
}) => ({
  appIconType, appIconBackground, appIconColor, appIconSvgRef, appIconURL, installableIconURL
}));
export const getAppType = createSelector(getPortalInfoSelector, ({ type }) => type);
export const getAppTitle = createSelector(getPortalInfoSelector, ({ title }) => title);
export const getAppName = createSelector(getPortalInfoSelector, ({ name }) => name);
export const getAppDescription = createSelector(getPortalInfoSelector, ({ description }) => description);
export const getLogoProperties = createSelector(getPortalInfoSelector, ({
  logoType, logoBackground, logoURL, iconColor, appLogoSize
}) => ({
  logoType, logoBackground, logoURL, iconColor, appLogoSize
}));
export const getShowNavigationBar = createSelector(getAppInfoWithDefaults, ({ showNavigationBar }) => showNavigationBar);
export const getProgressRestartDate = createSelector(getAppInfoWithDefaults, appProperties => appProperties.progressManualRestartDate);
export const getUsedSignItems = createSelector(getPortalItems, items => {
  return items.filter(item => item.type === ITEM_TYPES.SIGN_LINK).map(item => item.formID);
});
export const getListBgColor = createSelector(getAppInfoWithDefaults, ({ listBgColor }) => listBgColor);
export const getShowAppHeader = createSelector(getAppInfoWithDefaults, ({ showAppHeader }) => showAppHeader);
export const getIsShoppingApp = createSelector(getProductListItems, productListItems => productListItems.length > 0);
export const getIsDonationApp = createSelector(getDonationItems, donationItems => donationItems.length > 0);

export const getIsPaymentApp = createSelector([getIsShoppingApp, getIsDonationApp], (isShoppingApp, isDonationApp) => isShoppingApp || isDonationApp);

export const getPageIDByItemID = itemID => createSelector(getPortalItemByIDSelector(itemID), item => item?.page);
export const getPageWithDefaults = pageID => createSelector(getPagesWithNames, pages => {
  const page = pages.find(p => p.id === pageID);
  return page && { ...usePageDefaults(), ...page };
});
export const getInstallableIconURL = createSelector(getAppInfoWithDefaults, ({ installableIconURL }) => installableIconURL);
export const getAppHeaderBgCropInfo = createSelector(getAppInfoWithDefaults, ({ appHeaderBgCropInfo }) => appHeaderBgCropInfo);
export const getAppHeaderBgURL = createSelector(getAppInfoWithDefaults, ({ appHeaderBgURL }) => appHeaderBgURL);
export const getEditingResourceInfo = createSelector(getAppInfoWithDefaults, ({ editingResourceProps }) => editingResourceProps);
export const getOpenAppHeader = createSelector(getAppInfoWithDefaults, ({ openAppHeader }) => openAppHeader);
export const getAppFooterVisible = createSelector(getAppInfoWithDefaults, ({ appFooterVisible }) => appFooterVisible);
export const getShouldShowEditAppHeader = createSelector(getAppInfoWithDefaults, ({ name, description, logoURL }) => !name && !description && !logoURL);
export const getAppHeaderProperties = createSelector(getAppInfoWithDefaults, ({
  appHeaderBgColor, appHeaderBgURL, appHeaderBgCropInfo, appHeaderTextAlignment
}) => ({
  appHeaderBgColor, appHeaderBgURL, appHeaderBgCropInfo, appHeaderTextAlignment
}));
export const getShowAllPagesIcon = createSelector(getAppInfoWithDefaults, ({ showAllPagesIcon }) => showAllPagesIcon);
export const getSentboxByFormID = formID => createSelector(getPortalItems, items => items.find(item => item.type === ITEM_TYPES.SENTBOX_LINK && item.resourceID === formID));
export const getIsItemAvailable = type => createSelector([getIsShoppingApp, getIsDonationApp],
  (isShoppingApp, isDonationApp) => (isShoppingApp && type === ITEM_TYPES.DONATION) || (isDonationApp && type === ITEM_TYPES.PRODUCT_LIST));
export const getShouldShowInUse = type => createSelector([getIsShoppingApp, getIsDonationApp],
  (isShoppingApp, isDonationApp) => (type === ITEM_TYPES.PRODUCT_LIST && isShoppingApp) || (type === ITEM_TYPES.DONATION && isDonationApp));

export const getIsItemAdditionDisabled = type => createSelector([getIsShoppingApp, getIsDonationApp], (isShoppingApp, isDonationApp) => (
  (isShoppingApp && (type === ITEM_TYPES.DONATION)) || (isDonationApp && (type === ITEM_TYPES.DONATION || type === ITEM_TYPES.PRODUCT_LIST))
));

export const getCurrencySymbol = createSelector(getAppInfoWithDefaults, ({ currency }) => currency);
export const getUseDecimal = createSelector(getAppInfoWithDefaults, ({ useDecimal }) => useDecimal);
export const getPortalPaymentCurrencyInfo = createSelector(getAppInfoWithDefaults, ({ currency, decimalMark, useDecimal }) => ({ currency, decimalMark, useDecimal }));
export const getCurrentDonationGoal = createSelector(getAppInfoWithDefaults, ({ currentDonationGoal }) => currentDonationGoal);
export const getTargetDonationGoal = createSelector(getDonationItems, donation => donation[0]?.donationGoal);

export const getProductListLayout = createSelector(getAppInfoWithDefaults, ({ productListLayout }) => productListLayout);
export const getShowSearch = createSelector(getAppInfoWithDefaults, ({ showSearch }) => showSearch);
export const getIsGatewayConnected = createSelector(getAppInfoWithDefaults, ({ isGatewayConnected = false }) => isGatewayConnected);
export const getLastOrderAmount = createSelector(getAppInfoWithDefaults, ({ lastOrderAmount }) => lastOrderAmount);

export const getGatewayProperties = createSelector(getAppInfoWithDefaults, ({ connectedGateway, isGatewayConfigured, isGatewayTestMode }) => {
  return { connectedGateway, isGatewayConfigured, isGatewayTestMode };
});

export const getIsTemplate = createSelector(getAppInfoWithDefaults, ({ isTemplate }) => isTemplate);
export const getAppNameIconSettingsModalAvailable = createSelector(getAppInfoWithDefaults, ({ showAppNameIconSettingsModal }) => showAppNameIconSettingsModal);
