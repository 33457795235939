import Styled from 'styled-components';
import { settingsLeftPanelBgColor } from '../../../../../styles/colors';

const ScSettingsNavigationPanel = Styled.div`
  display: block;
  position: absolute;
  left: 0;
  width: 318px;
  height: 100%;
  background-color: ${settingsLeftPanelBgColor};

  @media screen and (max-width: 768px) {
    width: 144px;
  }

  @media screen and (max-width: 480px) {
    display: block;
    width: 100%;
    ${({ isMobileSubPage }) => (isMobileSubPage ? 'display: none;' : '')}
  }
`;
export default ScSettingsNavigationPanel;
