import React, { useState } from 'react';
import { Modal } from '@jotforminc/magnet';
import { array, func, object } from 'prop-types';
import { useSharedState } from './SharedStateContext';

const MultiStepModal = ({
  steps,
  setSteps,
  modalProps
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { sharedState, setSharedState } = useSharedState({});
  const { onClose } = modalProps;

  const stepsLength = steps.length;

  const nextStep = () => setCurrentStep(prev => (prev < stepsLength - 1 ? prev + 1 : prev));
  const prevStep = () => setCurrentStep(prev => (prev > 0 ? prev - 1 : prev));

  const ActiveStep = steps[currentStep];

  return (
    <Modal open onClose={onClose}>
      <ActiveStep
        sharedState={sharedState}
        setSharedState={setSharedState}
        nextStep={nextStep}
        prevStep={prevStep}
        setCurrentStep={setCurrentStep}
        stepsLength={stepsLength}
        currentStep={currentStep}
        setSteps={setSteps}
        {...modalProps}
      />
    </Modal>
  );
};

MultiStepModal.propTypes = {
  steps: array,
  setSteps: func,
  modalProps: object
};

MultiStepModal.defaultProps = {
  steps: [],
  setSteps: f => f,
  modalProps: {}
};

export default MultiStepModal;
