import React from 'react';
import {
  arrayOf, node, oneOfType, string, bool
} from 'prop-types';

import EmptyListBase from './EmptyListBase';

import NoFormsIcon from '../assets/svg/noFormsIcon.svg';
import NoAppsIcon from '../assets/svg/noAppsIcon.svg';
import NoTablesIcon from '../assets/svg/noTablesIcon.svg';
import NoReportsIcon from '../assets/svg/noReportsIcon.svg';
import NoApprovalsIcon from '../assets/svg/noApprovalsIcon.svg';
import NoSignDocumentsIcon from '../assets/svg/noSignDocumentsIcon.svg';
import NoTeamFormsIcon from '../assets/svg/noTeamFormsIcon.svg';
import NoDocumentIcon from '../assets/svg/noDocumentIcon.svg';

export const resourceData = {
  form: {
    title: 'You don\'t have any forms yet.',
    description: 'Your forms will appear here.',
    icon: <NoFormsIcon className="w-48" />
  },
  portal: {
    title: 'You don\'t have any apps yet.',
    description: '',
    icon: <NoAppsIcon className="w-48" />
  },
  table: {
    title: 'You don\'t have any tables yet.',
    description: '',
    icon: <NoTablesIcon className="w-48" />
  },
  report: {
    title: 'You don\'t have any reports yet.',
    description: '',
    icon: <NoReportsIcon className="w-48" />
  },
  approval: {
    title: 'You don\'t have any approvals yet.',
    description: '',
    icon: <NoApprovalsIcon className="w-48" />
  },
  sign: {
    title: 'You don\'t have any signs in the app yet.',
    description: '',
    icon: <NoSignDocumentsIcon className="w-48" />
  },
  pdf: {
    title: 'You don\'t have any documents yet.',
    description: 'Your documents will appear here.',
    icon: <NoDocumentIcon className="w-28 h-28" />
  },
  teamform: {
    title: 'This team doesn\'t have any forms yet',
    description: 'Team forms will appear here.',
    icon: <NoTeamFormsIcon className="w-48" />
  }
};

function EmptyList({
  icon,
  title,
  description,
  resourceType,
  isTitleUppercase
}) {
  const defaults = (resourceType && resourceData[resourceType]) ? resourceData[resourceType] : resourceData.form;
  const uppercase = isTitleUppercase !== null ? isTitleUppercase : true;

  return (
    <EmptyListBase
      title={title || defaults.title}
      description={description || defaults.description}
      icon={icon || defaults.icon}
      isTitleUppercase={uppercase}
    />
  );
}

EmptyList.propTypes = {
  icon: oneOfType([arrayOf(node), node]),
  title: string,
  description: string,
  resourceType: string,
  isTitleUppercase: bool
};

EmptyList.defaultProps = {
  icon: null,
  title: null,
  description: null,
  resourceType: 'form',
  isTitleUppercase: null
};

export default EmptyList;
