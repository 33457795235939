import { ICheckoutForm } from '../../types/common';
import * as actionTypes from '../actionTypes';

const initialState: ICheckoutForm = {
  questions: {}
};

const checkoutFormReducer = (state = initialState, action: actionType) : ICheckoutForm => {
  switch (action.type) {
    case actionTypes.INIT_CHECKOUT_FORM_QUESTIONS:
      return { ...state, questions: action.payload };
    case actionTypes.UPDATE_CHECKOUT_FORM_QUESTION_VISIBILITY:
      const { key, value } = action.payload;
      const question = state.questions[key];

      const questions = {
        ...state.questions,
        [key]: {
          ...question,
          hidden: value
        }
      };

      return {
        ...state,
        questions
      };
    default:
      return state;
  }
};

interface actionType {
  type: string,
  payload: any
}

export default checkoutFormReducer;
