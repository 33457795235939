import React, { useMemo } from 'react';
import { t } from '@jotforminc/translation';
import classNames from 'classnames';
import { FormWizard } from '@jotforminc/form-wizard';
import { updateAllProductListSettingsOnProduct } from '../../../api';
import COMPONENT_TYPE_LIST from '../../../constants/componentTypes';
import { getProductListSettingsProperties } from '../../../utils';
import { PAYMENTLISTSETTINGS_QUESTION_PROPS, PRODUCT_LIST_LAYOUTS } from '../../../constants/variables';
import '../../../styles/editor.scss';

type ProductSettingsPanelType = {
  appID: string;
  productListSettings: { showSearch: string, productListLayout: string };
  onProductListSettingsChange: (type: string, data: { productListSettingsItem: {[key: string]: string} }) => void;
  isReusableConnectionEnabled: boolean;
}

const ProductSettingsPanel = ({
  appID,
  productListSettings,
  onProductListSettingsChange,
  isReusableConnectionEnabled
} : ProductSettingsPanelType): JSX.Element => {
  const productLayoutSettings = useMemo(() => getProductListSettingsProperties('layoutSettings'), []);
  const productListSettingsProperties = useMemo(() => getProductListSettingsProperties('listSettings'), []);

  const onChange = (allChanges, data) => {
    let [key, value] = [data.key, data.value];

    if (PRODUCT_LIST_LAYOUTS.indexOf(key) > -1) {
      value = key;
      key = 'productListLayout';
    }

    const propertyType = PAYMENTLISTSETTINGS_QUESTION_PROPS.indexOf(key) > -1 ? 'questionProperties' : 'formProperties';

    updateAllProductListSettingsOnProduct(appID, { platform: 'appBuilder', [propertyType]: { [key]: value } })
      .then(res => {
        if (res) {
          onProductListSettingsChange('', { productListSettingsItem: { [key]: value } });
        }
      }).catch((err: Error) => {
        console.error(err);
      });
  };

  const listSettingsElements = productListSettingsProperties?.basicElementList(productListSettings, isReusableConnectionEnabled) || {};

  return (
    <div className="paymentPanel">
      <div className="product-settingsTab">
        <div className="lineElement">
          <div className="row-title">{t('Product List Layout')}</div>
          <div className="row-description">{t('Select the default layout of the product list')}</div>
          <div className={classNames('layoutSettings')}>
            <FormWizard
              onChange={onChange}
              elementList={productLayoutSettings?.basicElementList(productListSettings)}
              componentTypeList={COMPONENT_TYPE_LIST}
            />
          </div>
        </div>
        {Object.entries(listSettingsElements).filter(([key]) => ['useDecimal', 'decimalMark', 'showSearch'].includes(key)).map(([key, value]) => (
          <>
            {isReusableConnectionEnabled && <hr className="lineHr" />}
            <div className="lineElement">
              <div className={classNames('listSettings')}>
                <FormWizard
                  onChange={onChange}
                  elementList={{ [key]: value }}
                  componentTypeList={COMPONENT_TYPE_LIST}
                />
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default ProductSettingsPanel;
