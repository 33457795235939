import React from 'react';
import type { ElementType, PropsWithChildren } from 'react';
import { Dialog as HeadlessDialog } from '@headlessui/react';
import '@jotforminc/jotform.css';
// eslint-disable-next-line no-restricted-imports
import '@jotforminc/jotform.css/animation.css';

import { DialogProps, dialogDefaultProps } from './dialog.types';
import { PolymorphicProps } from '../../types/system.types';
import { dialogCVA, dialogContainerCVA } from './dialog.cva';

export const Dialog = <Element extends ElementType>(props: PropsWithChildren<PolymorphicProps<Element, DialogProps>>): JSX.Element => {
  const {
    open,
    onClose,
    initialFocus,
    zIndex,
    children,
    ariaLabel,
    noFade,
    as: _as = 'div',
    size,
    ...rest
  } = props;

  const InnerComponent = _as as ElementType;

  return (
    <HeadlessDialog
      onClose={onClose}
      open={open}
      initialFocus={initialFocus}
      style={{ zIndex }}
      className={dialogContainerCVA({ noFade })}
      aria-modal={true}
      data-magnet-dialog
    >
      <InnerComponent
        aria-label={ariaLabel}
        className={dialogCVA({ size })}
        {...rest}
        tabIndex={0}
      >
        {children}
      </InnerComponent>
    </HeadlessDialog>
  );
};

Dialog.defaultProps = dialogDefaultProps;
