import { autoTranslate } from '@jotforminc/utils';

export default autoTranslate({
  CREATE_A_FORM: 'Create a Form',
  CREATE_FORM: 'Create Form',
  CREATE_A_FORM_SUBTITLE: 'Create or import a form to start gathering data',
  START_FROM_SCRATCH: 'Start From Scratch',
  START_FROM_SCRATCH_SUBTEXT: 'A blank slate is all you need',
  USE_TEMPLATE: 'Use Template',
  USE_TEMPLATE_SUBTEXT: 'Choose from 10,000+ premade forms',
  IMPORT_FORM: 'Import Form',
  IMPORT_FORM_SUBTEXT: 'Convert an existing form in seconds',
  IMPORT_FORM_SUBTITLE: 'Import existing forms into your Jotform account in seconds',
  SELECT_LAYOUT: 'Select form layout',
  SELECT_LAYOUT_SUBTITLE: 'Choose a layout according to your needs',
  CLASSIC_FORM: 'Classic Form',
  CLASSIC_FORM_SUBTITLE: 'Show all questions on one page',
  CARD_FORM: 'Card Form',
  CARD_FORM_SUBTITLE: 'Show single question per page',
  DESCRIBE_IN_SECONDS: 'Describe a form and get it in seconds',
  DESCRIBE_IN_WORDS: 'Describe in words what kind of information you want to collect.',
  PDF_FORM: 'PDF Form',
  PDF_FORM_SUBTITLE: 'PDF forms can be filled out on digital devices',
  GENERATING_FORM: 'Generating form',
  ENTER_URL: 'Enter URL',
  SELECT_FROM_YOUR_FORMS: 'Select from your forms',
  IMPORT_FAILED_PLEASE_TRY_AGAIN: 'Import failed, please try again later.',
  FROM_A_WEBPAGE: 'From a web page',
  FROM_A_WEBPAGE_SUBTITLE: 'Import a form from any page',
  CLONE_AN_EXISTING: 'Clone your existing form',
  CLONE_AN_EXISTING_SUBTITLE: 'Create a copy of one of your existing forms',
  IMPORT_PDF_FORM: 'Import PDF form',
  IMPORT_PDF_FORM_SUBTITLE: 'Convert your PDF form to an online form',
  SELECT_A_FORM: 'Select a Form',
  SELECT_FORM: 'Select Form',
  PREVIEW_FORM: 'Preview Form',
  PLEASE_WAIT: 'Please wait...',
  BETA: 'Beta',
  CREATE_SALESFORCE_FORM: 'Create Salesforce Form',
  CREATE_SALESFORCE_FORM_SUBTEXT: 'Create an integrated form with Salesforce objects',
  SALESFORCE_SELECT_OBJECT: 'Enter or select object',
  SALESFORCE_OBJECTS: 'Salesforce Objects',
  SALESFORCE_FIELD_SELECTED: '1 field selected',
  SALESFORCE_FIELDS_SELECTED: '{selectedFieldsCount} fields selected'
});
