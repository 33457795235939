import { t } from '@jotforminc/translation';

import { IconLinkDiagonal, IconAnglesSelectorSlashHorizontal } from '@jotforminc/svg-icons';

export const publishNavPaths = {
  quickShare: 'link',
  embed: 'embed'
};

export const getPublishNavigationProperties = () => ([
  {
    key: 'link',
    title: t('Quick Share'),
    description: t('Direct app link.'),
    path: publishNavPaths.quickShare,
    Icon: IconLinkDiagonal
  },
  {
    key: 'embed',
    title: t('Embed'),
    description: t('Get embed code.'),
    path: publishNavPaths.embed,
    Icon: IconAnglesSelectorSlashHorizontal
  }
]);

export const publishDefaultActiveTab = publishNavPaths.quickShare;
