import { t } from '@jotforminc/translation';
import type { GATEWAY_NEW_CONNECTION_ERROR_TYPES } from '../../types';

/**
 * Returns a user-friendly error message based on the provided gateway error type.
 *
 * @param messageType - The type of the gateway error.
 * @param connectionInvalidCredentialMessages - Optional custom message for invalid credentials.
 * @returns A string containing the user-friendly error message.
 */
export const getGatewayErrorMessage = (
  messageType: GATEWAY_NEW_CONNECTION_ERROR_TYPES,
  connectionInvalidCredentialMessages?: string | null
): string => {
  switch (messageType) {
    case 'missingRequiredFields':
      return t('You have to fill all required fields.');
    case 'invalidCredentials':
      return connectionInvalidCredentialMessages || t('Your credentials are invalid.');
    case 'notUniqueConnectionName':
      return t('You already have a connection with this connection name.');
    case 'notUniqueConnection':
      return t('You already have a connection with these credentials.');
    case 'invalidFields':
      return t('Some fields are invalid. Please check and try again.');
    case 'invalidGateway':
      return t('Something went wrong. Please try again later. #E00IGT');
    case 'rateLimiter':
      return t('Because of too many failed attempts connection creation is temporarily disabled. Try again later.');
    case 'invalidEmailAddress':
      return t('The format of your email address is not correct.');
    case 'credentialsNotSame':
      return t('Changing the credentials of this payment connection will affect your connected assets. Reconnect using the same credentials as before.');
    default:
      return t('Something went wrong. Please try again later.');
  }
};
