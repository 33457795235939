/* eslint-disable */
const JFExternalWidgetParamsServer = (function () {
  var root = {};
  var subscribeToken = -1;
  var subscriberIndex = {};
  var referrer = false;

  var registeredReceivers = {};

  root.subscribe = function (type, handler) {
    if (!subscriberIndex[type]) {
      subscriberIndex[type] = [];
    }
    var token = (++subscribeToken).toString();
    subscriberIndex[type].push({
      func: handler,
      token: token
    });

    return token;
  };

  root.unsubscribe = function (token) {
    for (var type in subscriberIndex) {
      var subscribers = subscriberIndex[type];
      for (var i = 0; i < subscribers.length; i++) {
        var subscriber = subscribers[i];
        if (token === subscriber.token) {
          subscribers.splice(i, 1);
          return token;
        }
      }
    }
    return this;
  };

  root.getSubscribers = function () {
    return subscriberIndex;
  };

  root.getRegisteredReceivers = function () {
    return registeredReceivers;
  };

  var publish = function () {
    // type of event is the first argument
    var type = arguments[0];
    // rest of arguments will be used as arguments for handler execution
    var args = [].slice.call(arguments, 1);
    if (!subscriberIndex[type]) {
      return false;
    }
    var subscribers = subscriberIndex[type];
    var len = subscribers ? subscribers.length : 0;
    while (len--) {
      subscribers[len].func(args[0]);
    }
  };

  /**
   * Start the process
   */
  root.begin = function (callback, refobject, targetframe) {
    var formID = ('formID' in refobject) ? refobject.formID : BuildSource.formID;
    var { qid } = refobject;
    var { name } = refobject;
    var { ref } = refobject;

    var key = qid + '-' + name;

    // avoid listening from another postMessage for each qid
    if (key in registeredReceivers) {
      callback && callback();
    } else {
      // set referrer
      var a = document.createElement('a');
      a.href = ref;
      referrer = `${a.protocol}//${a.hostname}`;

      // register qid to registeredReceivers
      registeredReceivers[key] = true;

      // start recieving
      XD.receiveMessage(message => {
        if (!message.data || !(typeof message.data === 'string' || message.data instanceof String)) return;
        var data = JSON.parse(message.data);

        // filter out events from other frames which cause builder hang up
        // specially if there are multiple widgets on 1 form
        if (parseInt(qid) !== parseInt(data.qid) || name !== data.name) {
          console.log('Rejecting postMessage from ', data.qid, data.name, ' we only receive from', qid, name);
          return;
        }

        // publish to all listerners/subscribers
        switch (data.type) {
          case 'widgetParamData':
            publish('paramData', data);
            break;
          case 'widgetFrameResize':
            publish('frameResize', data);
            break;
        }
      }, referrer);

      callback && callback();
    }

    // notify clients that we are now ready to receive
    XD.postMessage(JSON.stringify({
      type: 'ready',
      qid: qid,
      name: name,
      formID: formID,
      value: refobject.value,
      widget: refobject.widget
    }), referrer, targetframe);
  };

  return root;
}());

export default JFExternalWidgetParamsServer;
