export const stateClass = (action, className) => {
  return global.document.body.classList[action](className);
};

export const truncate = (text, length) => { return text.length > length ? `${text.substr(0, length - 3).trim()}...` : text; };

// Minimal fetch
// https://github.com/developit/unfetch
export const fetch = (url, options) => {
  /* eslint-disable */
  options = options || {};
  return new Promise( (resolve, reject) => {
    const request = new XMLHttpRequest();
    const keys = [];
    const all = [];
    const headers = {};

    const response = () => ({
      ok: (request.status/100|0) == 2,    // 200-299
      statusText: request.statusText,
      status: request.status,
      url: request.responseURL,
      text: () => Promise.resolve(request.responseText),
      json: () => Promise.resolve(JSON.parse(request.responseText)),
      blob: () => Promise.resolve(new Blob([request.response])),
      clone: response,
      headers: {
        keys: () => keys,
        entries: () => all,
        get: n => headers[n.toLowerCase()],
        has: n => n.toLowerCase() in headers
      }
    });

    request.open(options.method || 'get', url, true);

    request.onload = () => {
      request.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm, (m, key, value) => {
        keys.push(key = key.toLowerCase());
        all.push([key, value]);
        headers[key] = headers[key] ? `${headers[key]},${value}` : value;
      });
      resolve(response());
    };

    request.onerror = reject;

    request.withCredentials = options.credentials == 'include';

    for (const i in options.headers) {
      request.setRequestHeader(i, options.headers[i]);
    }

    request.send(options.body || null);
  });
  /* eslint-enable */
};

export const eventPath = evt => {
  const path = (evt.composedPath && evt.composedPath()) || evt.path;
  const { target } = evt;

  if (path != null) {
    // Safari doesn't include Window, but it should.
    return (!path.includes(window)) ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo = []) {
    const { parentNode } = node;

    if (!parentNode) {
      return memo;
    }

    return getParents(parentNode, memo.concat(parentNode));
  }

  return [target].concat(getParents(target), window);
};

export const getFileExtension = url => url.split(/[#?]/)[0].split('.').pop().trim();
