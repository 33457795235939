import { IconAngleDown, IconAngleUp } from '@jotforminc/svg-icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import { classNames } from '@jotforminc/utils';

const Accordion = ({
  text, label, children, open, scrollable, username, isLast, onToggle
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClick = () => {
    const actionText = `${isOpen ? 'close' : 'open'}-clicked-${camelCase(text)}`;
    setIsOpen(!isOpen);
    window.standaloneTemplatesLogger({
      actor: username,
      action: `${window.innerWidth <= 780 ? 'mobile' : 'desktop'}:${actionText}`,
      target: 'newSidebar'
    });
    onToggle();
  };

  return (
    <div
      className={classNames(
        'jfSidebar--accordion',
        isOpen && 'opened'
      )}
    >
      <button
        type="button"
        className={classNames(
          'jfSidebar--accordion-button',
          (!isLast && !isOpen) && 'border-b border-navy-75'
        )}
        onClick={handleClick}
        aria-label={camelCase(text)}
      >
        {text && (
          <strong className="jfSidebar--accordion-button-text">{text}</strong>
        )}
        {label && (
          <span className="jfSidebar--accordion-button-label">{label}</span>
        )}
        <i className="jfSidebar--accordion-button-icon">
          {isOpen ? (
            <IconAngleUp width="24" height="24" />
          ) : (
            <IconAngleDown width="24" height="24" />
          )}
        </i>
      </button>
      {isOpen && (
        <div
          className={classNames(
            'jfSidebar--accordion-content',
            scrollable && 'scrollable',
            !isLast && 'border-b border-navy-75 pb-6'
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Accordion.defaultProps = {
  children: null,
  label: null,
  text: null,
  open: false,
  scrollable: false,
  username: '',
  isLast: false,
  onToggle: () => {}
};
Accordion.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool,
  scrollable: PropTypes.bool,
  username: PropTypes.string,
  isLast: PropTypes.bool,
  onToggle: PropTypes.func
};
export default Accordion;
