import { IconCheck } from '@jotforminc/svg-icons';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { object, number, func } from 'prop-types';
import { COLUMN_IDS, ICONS_WRT_COLUMN_TYPE } from './constants';

const ScSelectionItem = styled.div`
  border-color: ${({ isAdded }) => (isAdded ? 'rgba(120, 187, 7, 0.4)' : '#D7D9E3')};
  background-color: ${({ isAdded }) => (isAdded ? '#EFF9F3' : '#FBFCFE')};
`;

const SelectionItem = ({
  item, index, setColumns, columns
}) => {
  const { qid, text, type } = item;

  const isItemAdded = columns[COLUMN_IDS.SELECTEDS_COLUMN].items?.some(selectedItem => selectedItem.qid === qid);

  const handleItemClick = () => {
    if (isItemAdded) {
      setColumns(prev => {
        const newSelectedItems = prev[COLUMN_IDS.SELECTEDS_COLUMN].items.filter(({ qid: selectedItemID }) => selectedItemID !== item.qid);

        return {
          ...prev,
          [COLUMN_IDS.SELECTEDS_COLUMN]: {
            ...prev[COLUMN_IDS.SELECTEDS_COLUMN],
            items: newSelectedItems
          }
        };
      });
    } else {
      setColumns(prev => {
        const newSelectedItems = [...prev[COLUMN_IDS.SELECTEDS_COLUMN].items, { ...item }];

        return {
          ...prev,
          [COLUMN_IDS.SELECTEDS_COLUMN]: {
            ...prev[COLUMN_IDS.SELECTEDS_COLUMN],
            items: newSelectedItems
          }
        };
      });
    }
  };

  const Icon = ICONS_WRT_COLUMN_TYPE[type];

  return (
    <Draggable
      key={`selection-${qid}`}
      draggableId={`selection-${qid}`}
      index={index}
    >
      {provided => {
        return (
          <ScSelectionItem
            className="selection-item column-left"
            isAdded={isItemAdded}
            onClick={handleItemClick}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {Icon
                        && (
                        <div className='column-type-icon'>
                          <Icon width={16} height={16} />
                        </div>
                        )}
            <div className='column-item-text'>
              {text}
            </div>
            <div className='is-checked'>
              {
                isItemAdded && <IconCheck height={16} width={16} />
              }
            </div>
          </ScSelectionItem>
        );
      }}
    </Draggable>
  );
};

SelectionItem.propTypes = {
  item: object.isRequired,
  index: number.isRequired,
  setColumns: func.isRequired,
  columns: object.isRequired
};

export default SelectionItem;
