import React from 'react';
import {
  GatewayPicker, ApmGatewayPicker, GatewayConnection, ConnectionPicker
} from '../steps';

/** Context */
import { useGatewayConnection } from '../../../context';

/**
 * Renders the appropriate step component based on the currentlyStep state.
 *
 * @returns {React.ReactNode} The rendered component or null if currentlyStep is not recognized.
 */
export const ModalStepsRenderer = (): any => {
  const { currentlyStep } = useGatewayConnection();

  switch (currentlyStep) {
    case 'gatewayPicker':
      return <GatewayPicker key="connectionModal-gatewayPicker" />;
    case 'apmGatewayPicker':
      return <ApmGatewayPicker key="connectionModal-apmGatewayPicker" />;
    case 'gatewayConnection':
      return <GatewayConnection key="connectionModal-gatewayConnection" />;
    case 'connectionPicker':
      return <ConnectionPicker key="connectionModal-connectionPicker" />;
    default:
      return null;
  }
};
