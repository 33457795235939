import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

export const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export const baseLayer = new RequestLayer('/', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});
