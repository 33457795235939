import React, {
  useCallback, useState, useMemo, useEffect
} from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@jotforminc/translation';
import { TabListWithStyles, Tab } from '@jotforminc/tabs';
import { FormWizard } from '@jotforminc/form-wizard';
import COMPONENT_TYPE_LIST from '../constants/componentTypes';
import { getGatewayProperties } from '../utils/paymentProperties';
import * as actionCreators from '../store/actionCreators';
import {
  IAllActions,
  TAppId,
  TAppName,
  TFormId,
  IGatewaySettings
} from '../types/common';

const GatewaySettings = ({
  checkoutFormId,
  actions,
  appID,
  appName,
  activeGatewaySettings,
  temporaryActiveGatewaySettings
} : IProps): JSX.Element => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<'basic' | 'advanced'>('basic');

  const onTabSelect = useCallback(tab => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    dispatch(actionCreators.updateTemporaryActiveGatewaySettings({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gatewayProperties = useMemo(() => {
    return getGatewayProperties(activeGatewaySettings.type);
  }, [activeGatewaySettings]);

  const handleTemporaryGatewaySettingsChange = (_, { key, value }) => {
    dispatch(actionCreators.updateTemporaryActiveGatewaySettings({
      ...temporaryActiveGatewaySettings,
      [key]: value
    }));
  };

  const basicElementListWithAlteredComponentProps = useMemo(() => {
    const baseElementList = gatewayProperties.basicElementList({
      ...activeGatewaySettings,
      ...temporaryActiveGatewaySettings // override saved values and show temporary changes
    }, actions);

    const overridingComponentProps = {
      ...baseElementList.connection.componentProps,
      checkoutFormId,
      temporaryActiveGatewaySettings,
      appID,
      appName
    };

    return {
      ...baseElementList,
      connection: {
        ...baseElementList.connection,
        componentProps: overridingComponentProps
      }
    };
  }, [actions, activeGatewaySettings, checkoutFormId, gatewayProperties, temporaryActiveGatewaySettings, appID, appName]);

  const advancedElements = gatewayProperties.advancedElementList({ ...activeGatewaySettings, ...temporaryActiveGatewaySettings }, actions, checkoutFormId);
  const shouldActivateTabs = Object.keys(advancedElements).length > 0;

  return (
    <>
      <div className={`gatewayConnectionSettingsView${!shouldActivateTabs ? ' noTabs' : ''}`}>
        <TabListWithStyles
          list={{ basic: { label: t('Basic') }, advanced: { label: t('Advanced') } }}
          onTabChange={onTabSelect}
          defaultActiveTab={activeTab}
        >
          <Tab id="basic">
            <div className="basicTabView">
              <FormWizard
                elementList={basicElementListWithAlteredComponentProps}
                componentTypeList={COMPONENT_TYPE_LIST}
                onChange={handleTemporaryGatewaySettingsChange}
              />
            </div>
          </Tab>
          <Tab id="advanced">
            <div className="advancedTabView">
              <FormWizard
                elementList={advancedElements}
                componentTypeList={COMPONENT_TYPE_LIST}
                onChange={handleTemporaryGatewaySettingsChange}
              />
            </div>
          </Tab>
        </TabListWithStyles>
      </div>
    </>
  );
};

interface IProps {
  actions: IAllActions,
  checkoutFormId: TFormId
  appID: TAppId
  appName: TAppName
  activeGatewaySettings: IGatewaySettings
  temporaryActiveGatewaySettings: Partial<IGatewaySettings>
}

GatewaySettings.defaultProps = {};

export default GatewaySettings;
