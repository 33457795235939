import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import propTypes, { bool, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TagInput from '@jotforminc/tag-input';
import { t } from '@jotforminc/translation';
import isEmpty from 'lodash/isEmpty';
import { DATA_SOURCE_COLUMN_TYPES } from '../../../../../../constants/itemTypes';
import MultiSectionSelect from '../MultiSectionSelect';
import SELECTORS from '../../../../../../store/selectors';
import {
  DEFAULT_DS_OPTIONS, decideDefaultOptions, normalizeDefaultSubfields, parseDsFormat
} from './helper';
import { getSubfield } from './getSubfield';
import { ColumnPickerWrapper } from './styled';
import { DS_COLUMN_PLACEHOLDERS } from './constants';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';

const DataSourcePicker = ({
  id,
  columnType,
  value,
  linkedResourceID,
  linkedViewID,
  onChange: rightPanelOnChange,
  excludedDefaultDsOptions,
  parentType,
  parentComponent,
  isSingleEntry,
  placeholder
}) => {
  const [parsedOptionValue, setParsedOptionValue] = useState(parseDsFormat(value, columnType, excludedDefaultDsOptions));
  const { mapValue, onChange: subfieldOnChange = val => val } = normalizeDefaultSubfields?.[parentType] ?? {};
  const dispatch = useDispatch();
  const tagRef = useRef(null);

  useEffect(() => {
    const formFieldsRef = tagRef?.current?.formFieldsRef;

    if (!formFieldsRef?.current) {
      return;
    }

    const { setOnButtonClickCallback = () => {}, getIsMenuVisible } = formFieldsRef.current;

    setOnButtonClickCallback(() => {
      if (!getIsMenuVisible()) {
        dispatch(ACTION_CREATORS.dsFetchColumnsRequestWithThrottle({ resourceID: linkedResourceID, viewID: linkedViewID }));
      }
    });
  }, [tagRef?.current?.formFieldsRef]);

  // to fix stupid placeholder bug, i need below dom manipulation
  const tagInputFixPlaceholder = useCallback(() => {
    const spanInput = tagRef?.current?.tagifyRef?.current?.DOM?.input;

    if (
      spanInput
        && spanInput.children.length === 1
        && spanInput.children[0].tagName === 'BR') {
      spanInput?.replaceChildren();
    }
  }, []);

  const onChange = useCallback(val => {
    rightPanelOnChange(subfieldOnChange(val));

    if (isEmpty(val)) {
      tagInputFixPlaceholder();
    }
  }, [rightPanelOnChange, placeholder]);

  const { columns = [], title: tableTitle } = useSelector(SELECTORS.dsGetColumnsByResourceID({ resourceID: linkedResourceID, viewID: linkedViewID }));

  const filteredColumnsByType = columns.filter(column => column?.type === columnType);

  const columnOptions = filteredColumnsByType.reduce(
    (prev, column) => (
      [...prev,
        {
          label: column.text,
          value: `{${column.qid}}`
        }]
    ),
    []);

  const defaultSection = { description: '', options: decideDefaultOptions(columnType, excludedDefaultDsOptions) };

  const sections = [
    { description: tableTitle, options: columnOptions }
  ];

  const isOptionDefault = _option => Object.values(DEFAULT_DS_OPTIONS)
    .some(
      defaultOptionConfig => defaultOptionConfig.value === _option.value
      && defaultOptionConfig.label === _option.label
    );

  const onColumnChange = ({ option }) => {
    if (!isOptionDefault(option)) {
      onChange(`${option.value}`);
    } else {
      onChange('');
    }

    setParsedOptionValue(option.value);
  };

  const onRemoveTag = () => {
    tagInputFixPlaceholder();
  };

  const Subfield = useMemo(() => parentComponent ?? getSubfield(columnType), [columnType, parentComponent]);

  switch (columnType) {
    case DATA_SOURCE_COLUMN_TYPES.TEXT: {
      const tagInputColumns = filteredColumnsByType.map(col => ({ name: col.qid, text: col.text }));
      return (
        <ColumnPickerWrapper>
          <TagInput
            key={id}
            isSingleEntry={isSingleEntry}
            ref={tagRef}
            addFormFields
            formTitle={tableTitle}
            fieldsLabelText={t('Add Field')}
            onChange={onChange}
            onRemove={onRemoveTag}
            value={value}
            questions={tagInputColumns}
            settings={{
              mode: 'mix'
            }}
            placeholder={placeholder}
          />
        </ColumnPickerWrapper>
      );
    }

    default: {
      return (
        <>
          <MultiSectionSelect
            shouldDivideSections
            defaultSection={defaultSection}
            sections={sections}
            value={{ option: { value: parsedOptionValue } }}
            onChange={option => onColumnChange(option)}
            placeholder={DS_COLUMN_PLACEHOLDERS[columnType]}
            noOptionsText="Your table does not have any appropriate fields to add"
          />
          {
        DEFAULT_DS_OPTIONS[columnType]?.value === parsedOptionValue
         && <Subfield value={mapValue?.(value) ?? value} onChange={onChange} />

      }
        </>
      );
    }
  }
};

DataSourcePicker.propTypes = {
  id: propTypes.string,
  columnType: propTypes.oneOf(Object.values(DATA_SOURCE_COLUMN_TYPES)).isRequired,
  value: propTypes.string,
  linkedResourceID: propTypes.string,
  linkedViewID: propTypes.string,
  onChange: propTypes.func,
  excludedDefaultDsOptions: propTypes.array,
  parentType: propTypes.string,
  parentComponent: propTypes.element,
  isSingleEntry: bool,
  placeholder: string
};

DataSourcePicker.defaultProps = {
  id: '',
  value: '',
  linkedResourceID: '',
  linkedViewID: '',
  onChange: f => f,
  excludedDefaultDsOptions: null,
  parentType: '',
  parentComponent: null,
  isSingleEntry: false,
  placeholder: ''
};

export default DataSourcePicker;
