import React from 'react';
import { StillEditingModal } from '@jotforminc/modals';
import { call, put } from 'redux-saga/effects';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { createRoot } from 'react-dom/client';
import { ScStillEditingModal } from './ScStillEditingModal';
import { updatePortalAction } from '../../../actionCreators';

const stillEditingModal = () => {
  return new Promise(resolve => {
    const container = document.getElementById('modal-root');
    const root = createRoot(container);

    const handleResolve = () => {
      resolve(true);
      root.unmount();
    };
    const handleReject = () => {
      resolve(true);
      handleCustomNavigation('/myapps', '_self');
      root.unmount();
    };

    const handleClose = () => {
      resolve(true);
      root.unmount();
    };

    root.render((
      <ScStillEditingModal>
        <StillEditingModal
          onReject={handleReject}
          onResolve={handleResolve}
          backText={t('GO TO MY APPS')}
          confirmText={t('CONTINUE EDITING')}
          title={t('Are you still there?')}
          description={null}
          defaultVisible={true}
          confirmButtonColor="success"
          onClose={handleClose}
          content={(
            <>
              <div>
                {t('To continue editing this app, let us know you\'re still active by clicking the button below.')}
              </div>
              <div className='custom-content-second-line'>
                {t('Otherwise, you\'ll be redirected to the Apps page.')}
              </div>
            </>
          )}
        />
      </ScStillEditingModal>
    ));
  });
};

export function* handleStillEditing() {
  const isClosed = yield call(stillEditingModal);

  // dummy update for the activity log
  if (isClosed) {
    yield put(updatePortalAction({}));
  }
}
