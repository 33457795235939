import React, { useCallback } from 'react';
import { array, func, string } from 'prop-types';
import Styled from 'styled-components';
import { t } from '@jotforminc/translation';
import { IconMagnifyingGlass } from '@jotforminc/svg-icons';
import FilteredProductList from './FilteredProductList';

/* eslint-disable max-len */
const ScSearchProductContent = Styled.div`
  *:focus {
    outline: none;
  }

  .search-field {
    position: relative;
    margin-bottom: 24px;
    border:none;
    background-image:none;
    background-color:transparent;
    box-shadow: none;

    svg {
      width: 16px;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .search-clear {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    color: #6f76a799;
    transition: .2s opacity ease;
  }

  input[type="search"] {
    border: none;
    border-bottom: 1px solid #E3E5F5;
    padding: 9px 0 9px 24px;
    border-radius: 0;
    color: #0099FF;
    font-size: 14px;
    width: 100%;
    font-family: inherit;
  }

  input[type="search"]::-webkit-search-cancel-button {
    appearance: none;
    height: 16px;
    width: 16px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 17 16'%3E%3Cpath fill='%236F76A7' d='M8.5 16c4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' stroke='%23fff' stroke-width='.470588' d='M11.8276 10.7733 9.05459 8.00033l2.77301-2.77296c.153-.15307.153-.40153 0-.55459-.1531-.15307-.4016-.15307-.5546 0L8.5 7.44574 5.72703 4.67278c-.15307-.15307-.40152-.15307-.55459 0-.15307.15306-.15307.40152 0 .55459l2.77297 2.77296-2.77297 2.77297c-.15307.1531-.15307.4015 0 .5546.15307.1531.40152.1531.55459 0L8.5 8.55493l2.773 2.77297c.153.1531.4015.1531.5546 0 .153-.1531.153-.4015 0-.5546Z' clip-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50%;
    opacity: 0;
    pointer-events: none;
    transition: .2s opacity ease;
  }

  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  input[type="search"]:not([value=""]):focus {
    ~ .search-clear {
      opacity: 1;
    }
  }

  .filtered-product-lists {
    max-height: calc(90vh - 122px);
    overflow-y: auto;
  }
`;
/* eslint-enable max-len */

const SearchInProductsContent = ({
  onSearchQueryChange, filteredProductsLists, onSaveFilteredProducts, searchQuery
  // onClearFilteredProduct
}) => {
  const callbackRef = useCallback(inputElement => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <ScSearchProductContent>
      <div className="search-field">
        <IconMagnifyingGlass />
        <input
          type='search'
          onChange={({ target: { value } }) => onSearchQueryChange(value)}
          value={searchQuery}
          ref={callbackRef}
        />
        <span className="search-clear">{t('Clear')}</span>
      </div>
      <div className="filtered-product-lists">
        {filteredProductsLists.map(productList => <FilteredProductList productList={productList} onSaveFilteredProducts={onSaveFilteredProducts} />)}
      </div>
    </ScSearchProductContent>
  );
};

SearchInProductsContent.propTypes = {
  onSearchQueryChange: func,
  filteredProductsLists: array,
  onSaveFilteredProducts: func,
  // onClearFilteredProduct: func,
  searchQuery: string
};

SearchInProductsContent.defaultProps = {
  onSearchQueryChange: f => f,
  filteredProductsLists: [],
  onSaveFilteredProducts: f => f,
  // onClearFilteredProduct: f => f,
  searchQuery: ''
};

export default SearchInProductsContent;
