import React from 'react';
import {
  oneOfType, arrayOf, node, string, bool
} from 'prop-types';
import { classNames } from '@jotforminc/utils';

export default class WizardItem extends React.Component {
  render() {
    const {
      children,
      className,
      noGutter,
      noMaxWidth,
      ...props
    } = this.props;

    return (
      <div className={classNames('jfWizard-item', !noGutter && 'jfWizard-gutter', !noMaxWidth && 'withMaxWidth', className)} {...props}>
        {children}
      </div>
    );
  }
}

WizardItem.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]),
  className: string,
  noGutter: bool,
  noMaxWidth: bool
};

WizardItem.defaultProps = {
  children: null,
  className: '',
  noGutter: false,
  noMaxWidth: false
};
