/* eslint-disable camelcase */
import { put, select, call } from 'redux-saga/effects';
import { UPDATE_PORTAL } from '../actionTypes';
import SELECTORS from '../selectors';
import { APP_MODES } from '../../constants';
import {
  calculateDoneItemCount, setDoneItemCount, setIsAppDone, setTodoItemCount
} from '../actionCreators';

import { restartProgressManually } from '../../modules/api';
import { getFormCompletion } from '../../utils';

export function* watchDoneItemProgress() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode !== APP_MODES.public) return;

  const todoItems = yield select(SELECTORS.getTodoItems);
  const { progressManualRestartDate } = yield select(SELECTORS.getPortalInfoSelector);
  const { time_zone: timeZone } = yield select(SELECTORS.getUser);
  const completedTodoItems = todoItems.filter(item => {
    const {
      lastSubmitted,
      completed_showBadge,
      completed_clearBadgeOn,
      completed_clearBadgePeriod,
      required_showBadge
    } = item;

    return getFormCompletion({
      lastSubmitted,
      showBadge: completed_showBadge,
      clearBadgeOn: completed_clearBadgeOn,
      clearBadgePeriod: completed_clearBadgePeriod,
      required_showBadge,
      timeZone,
      progressRestartDate: progressManualRestartDate
    });
  });
  yield put(setDoneItemCount(completedTodoItems.length));

  if (completedTodoItems.length === todoItems.length) {
    yield put(setIsAppDone(true));
  }

  const { isAppDone } = yield select(SELECTORS.getTodoItemsProgress);

  if (isAppDone && (completedTodoItems.length !== todoItems.length)) {
    yield put(setIsAppDone(false));
  }
}

export function* watchTodoItemProgress() {
  const todoItems = yield select(SELECTORS.getTodoItems);
  yield put(setTodoItemCount(todoItems.length));
}

export function* watchRestartProgress() {
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  try {
    const { progressManualRestartDate } = yield call(restartProgressManually, portalID);
    yield put({ type: UPDATE_PORTAL.REQUEST, payload: { progressManualRestartDate } });
    yield put(calculateDoneItemCount());
  } catch (e) {
    console.log('Error on restarting progress: ', e);
  }
}
