import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Hooks } from '@jotforminc/uikit';
import { closest } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import { getFormattedPrice } from '@jotforminc/money-utils';
import {
  IconPencilFilled, IconCopyFilled, IconTrashFilled, IconGearFilled
} from '@jotforminc/svg-icons';
import Selectors from '../store/selectors';
import {
  SortableWrapper,
  fixAllProductsData,
  stringifyProductData
} from '../utils';
import * as actionCreators from '../store/actionCreators';
import {
  FTremoveProduct,
  FTSortProducts,
  FTupdateProduct,
  FTcreateProduct,
  ISellProductsItem,
  TFormId,
  IActionButtons
} from '../types/common';
import StyledSortableItem from './StyledSortableItem';
import { checkMobileOrTablet } from '../utils/domUtils';

const ProductItemList = ({ actions } : IProductItemListProps) : JSX.Element | null => {
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const formId: TFormId = useSelector(Selectors.getFormId);
  const products : ISellProductsItem[] = fixAllProductsData(useSelector(Selectors.getProducts));
  const { useDecimal, currency, decimalMark } = useSelector(Selectors.getProductListSettings);
  const [productSettingsOpen, setProductSettingsOpen] = Hooks.useClickOutsideStateWithSelector(false, '.sortableWrapper');
  const isMobileOrTablet = checkMobileOrTablet();
  useEffect(() => {
    if (!productSettingsOpen) {
      setSelectedProductId(null);
    }
  }, [productSettingsOpen]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) { return; }

    const oldPid = products[oldIndex].pid;
    const newPid = products[newIndex].pid;

    dispatch(actionCreators.sortProducts(oldPid, newPid));

    actions.sortProducts(formId, oldPid, newPid)
      .then(response => {
        if (response !== true) {
          throw new Error('Products couldn\'t be sorted');
        }
      }).catch((err: Error) => {
        // Revert the changes.
        console.error(err);
        dispatch(actionCreators.sortProducts(newPid, oldPid));
      });
  }, [formId, dispatch, actions, products]);

  const handleShouldSortingStart = useCallback((event: React.MouseEvent<EventTarget>) : boolean => {
    return (
      !!closest(event.target as HTMLElement, '.productItemActions', '.productItem')
      || !!closest(event.target as HTMLElement, '.actionButton', '.productItem')
    );
  }, []);

  const showProductActions = useCallback((pid): void => {
    setSelectedProductId(pid);
    setProductSettingsOpen(true);
  }, [products, selectedProductId]);

  const removeProduct = useCallback((pid) : void => {
    actions.removeProduct(formId, pid)
      .then(res => {
        dispatch(actionCreators.initProducts(res));
      }).catch((err: Error) => {
        console.error(err);
      });
    setSelectedProductId(null);
  }, [dispatch, actions, formId]);

  const duplicateProduct = useCallback((pid) : void => {
    const selectedProduct = products.find(p => p.pid === pid);
    if (!selectedProduct) { return; }

    const _selectedProduct: Partial<ISellProductsItem> = stringifyProductData({ ...selectedProduct });
    // omit pid to use createProduct flow
    delete _selectedProduct.pid;
    actions.createProduct(formId, _selectedProduct)
      .then(response => {
        dispatch(actionCreators.createProduct(response));
        setSelectedProductId(null);
      }).catch((err: Error) => console.error(err));
  }, [dispatch, actions, formId]);

  const openProductEditor = useCallback((pid) : void => {
    const activeProductData = products.find(p => p.pid === pid);
    if (!activeProductData) {
      return;
    }

    dispatch(actionCreators.setActiveProduct(activeProductData));
    dispatch(actionCreators.changeActiveEditor('productEditor'));
  }, [dispatch, products]);

  if (!products || (products && products.length === 0)) {
    return null;
  }

  const settingsActionButtons: IActionButtons = [
    {
      icon: IconPencilFilled,
      action: openProductEditor
    },
    {
      icon: IconGearFilled,
      action: showProductActions
    }
  ];

  const actionButtons: IActionButtons = [
    ...isMobileOrTablet ? [{
      icon: IconPencilFilled,
      title: t('EDIT'),
      action: openProductEditor
    }] : [],
    {
      icon: IconCopyFilled,
      title: t('DUPLICATE'),
      action: duplicateProduct
    },
    {
      icon: IconTrashFilled,
      title: t('DELETE'),
      action: removeProduct,
      colorStyle: 'error'
    }
  ].flat();

  const renderProducts = () => {
    return products.map((product, idx) => {
      const { price, pid } = product;

      const formattedPrice = price ? getFormattedPrice({
        price, useDecimal, currency, decimalMark
      }) : t('Free');
      return (
        <StyledSortableItem
          key={pid}
          idx={idx}
          item={product}
          showItemActions={showProductActions}
          selectedItemId={selectedProductId}
          itemSettingsOpen={productSettingsOpen}
          formattedPrice={formattedPrice}
          actionButtons={actionButtons}
          settingsActionButtons={settingsActionButtons}
        />
      );
    });
  };

  return (
    <div className="productsListing">
      <SortableWrapper
        onSortEnd={onSortEnd}
        helperClass='paymentSettingsEditorSortableHelper'
        shouldCancelStart={handleShouldSortingStart}
        useDragHandle={isMobileOrTablet}
        lockAxis='y'
      >
        {renderProducts()}
      </SortableWrapper>
    </div>
  );
};

interface IActions {
  sortProducts: FTSortProducts
  removeProduct: FTremoveProduct
  updateProduct: FTupdateProduct,
  createProduct: FTcreateProduct
}

interface IProductItemListProps {
  actions: IActions
}

export default ProductItemList;
