import React from 'react';
import { object, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';

import { placeholderApprovalImage, placeholderApprovalBG } from '../../constantImages';
import { onImageError } from '../../onImageError';
import PreviewHint from './PreviewHint';

const ApprovalTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate,
  setImageLoaded, templateCloneWrapper
}) => {
  const {
    slug,
    title
  } = template;
  const isSkeleton = title === 'skeleton';

  const screenshotURL = `https://cdn.jotfor.ms/templates/screenshot/workflow-templates/${slug}?f=png&w=1482`;
  const image = slug ? screenshotURL : placeholderApprovalImage;

  return (
    <div className={`item approval-item${imageLoaded && !isSkeleton ? ' image-loaded' : ''}`}>
      <div className="image" onClick={() => handlePreviewTemplate('viewTemplateFromImg')}>
        <div
          className='bgColorItem'
          style={{ backgroundColor: template.thumbnailBackgroundColor }}
        >
          <svg className="opacity-10 absolute top-0 left-0 w-full h-full">
            <pattern
              id="workflowTemplatePattern"
              x="7.4972684453736065"
              y="-9.090875228454903"
              width="13.370102098732522"
              height="13.370102098732522"
              patternUnits="userSpaceOnUse"
              patternTransform="translate(-0.7864765940430896,-0.7864765940430896)"
            >
              <circle
                cx="0.7864765940430896"
                cy="0.7864765940430896"
                r="0.7864765940430896"
                fill="#0a1551"
              />
            </pattern>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#workflowTemplatePattern)"
            />
          </svg>

        </div>
        <PreviewHint />
        <img
          className='approval-main'
          alt={title}
          src={!isSkeleton ? image : placeholderApprovalImage}
          onError={e => onImageError(e, placeholderApprovalBG)}
          onLoad={() => setImageLoaded(true)}
        />
        <img
          className='approval-blank'
          alt={title}
          src={placeholderApprovalImage}
        />
        <div className="animBg" />
      </div>
      <h3 onClick={() => handlePreviewTemplate('viewTemplateFromTitle')}><span>{title}</span></h3>
      <button
        className="useTemplate cta-usetemplate"
        type="button"
        onClick={event => templateCloneWrapper(event)}
      >
        <span>{t('Use Template')}</span>
      </button>
      <button
        className="previewCta"
        type="button"
        onClick={() => handlePreviewTemplate('viewTemplateFromPreviewCta')}
      >
        <span>{t('Preview')}</span>
      </button>
    </div>
  );
};

export default ApprovalTemplateItem;
ApprovalTemplateItem.defaultProps = {
};
ApprovalTemplateItem.propTypes = {
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  templateCloneWrapper: func.isRequired,
  handlePreviewTemplate: func.isRequired
};
