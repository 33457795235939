import FeaturesSingleton from '.';

export const useEnabledFeatures = () => {
  const features = new FeaturesSingleton();
  return features.getEnabledFeatures();
};

export const isFeatureEnabled = featureName => {
  const enabledFeatures = useEnabledFeatures();
  return enabledFeatures[featureName];
};
