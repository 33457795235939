import Base from '../v0/FormItem.json';

const migrate = () => {
  const orig = { ...Base };

  return {
    ...orig,
    ...{
      $id: 'https://www.jotform.com/portal/schemas/v1/FormItem.json#',
      title: 'FormItem v1',
      properties: {
        ...orig.properties,
        itemBgColor: {
          ...orig.properties.itemBgColor,
          default: '#ffffff'
        },
        itemFontColor: {
          ...orig.properties.itemFontColor,
          default: '#0A1551'
        },
        itemBorderColor: {
          ...orig.properties.itemBorderColor,
          default: '#D3DCEF'
        },
        showItemIcon: {
          $ref: '../commonDefinitions.json#/definitions/yesNo',
          description: 'Whether to show icons on items or not',
          default: 'Yes'
        },
        shrink: {
          $ref: '../commonDefinitions.json#/definitions/yesNo',
          description: 'Whether to shrink element or not',
          default: 'No'
        },
        embeddedForm: {
          $ref: '../commonDefinitions.json#/definitions/yesNo',
          description: 'Whether to show embedded form in the app or not',
          default: 'No'
        },
        embeddedFormHeight: {
          type: 'string',
          description: 'Height of the embedded form in px',
          default: '535'
        },
        showEmbeddedFormFullHeight: {
          $ref: '../commonDefinitions.json#/definitions/yesNo',
          description: 'Resize the embedded form as iframe sizes',
          default: 'Yes'
        },
        embeddedFormThankYouPageDisplaySecond: {
          type: 'number',
          description: 'Set the Thank You Page display time.',
          default: 3
        },
        itemIcon: {
          type: 'string',
          description: 'Item Icon URL',
          default: ''
        },
        itemIconColor: {
          type: 'string',
          description: 'Item Icon Color',
          default: '#FF6100'
        },
        itemIconBgColor: {
          type: 'string',
          description: 'Item Icon Background Color',
          default: '#FFF0'
        },
        itemBgURL: {
          type: 'string',
          description: 'Item Background Image',
          default: ''
        },
        elementSize: {
          type: 'string',
          enum: [
            'normal',
            'large'
          ],
          description: 'Element size',
          default: 'normal'
        },
        resourceType: {
          type: 'string',
          description: 'Type of the resource',
          const: 'FORM',
          default: 'FORM'
        },
        resourceID: {
          type: 'string',
          description: 'ID of the resource',
          default: ''
        },
        resourceTitle: {
          type: 'string',
          description: 'Original title of the resource',
          default: ''
        },
        resourceURL: {
          type: 'string',
          description: 'URL of the resource',
          default: ''
        }
      }
    }
  };
};
const migrated = migrate();

export { migrated as FormItemV1 };
