import React from 'react';
import {
  bool, func, number, shape, string
} from 'prop-types';

import { toReadable } from './helper';

const FontPickerListItem = ({
  baseBgUrl,
  defaultFont,
  handleFontSelection,
  handleHoverAction,
  hoverIndex,
  index,
  item,
  listCreated,
  selected
}) => {
  const genericListItem = () => (
    <button
      type="button"
      data-id={index}
      data-name={toReadable(item.name)}
      aria-label={toReadable(item.name)}
      className={`jfc-font-picker-list__item${toReadable(selected) === toReadable(item.name) ? ' is-selected' : ''}`}
      onClick={handleFontSelection}
      style={{
        fontFamily: toReadable(item.name)
      }}
    >
      {item.name}
      {item.name === defaultFont ? ' (Default)' : ''}
    </button>
  );

  const specialListItem = () => (
    <button
      type="button"
      className={`jfc-font-picker-list__item${toReadable(selected) === toReadable(item.name) ? ' is-selected' : ''}`}
      data-id={index}
      data-name={toReadable(item.name)}
      aria-label={toReadable(item.name)}
      onClick={handleFontSelection}
      onMouseEnter={() => handleHoverAction({ type: 'enter', index: item.orderNumber || index })}
      onMouseLeave={() => handleHoverAction({ type: 'leave', index: item.orderNumber || index })}
      style={(item.category && item.category === 'jotform') ? {
        backgroundImage: item.name !== defaultFont && `url(${baseBgUrl}${hoverIndex === (item.orderNumber || index) ? '-hover' : ''}.png)`,
        backgroundPosition: item.name !== defaultFont && `0 ${((item.orderNumber || index) - 1) * -32}px`,
        height: 32,
        fontFamily: toReadable(item.name)
      } : {
        fontFamily: toReadable(item.name)
      }}
    >
      {`
        ${((item.category && item.category) === 'system' || item.name === defaultFont
        || !item.category || item.category === '')
        ? toReadable(item.name) : ''}${item.name === defaultFont ? ' (Default)'
          : ''}
      `}
    </button>
  );

  return listCreated ? specialListItem() : genericListItem();
};

FontPickerListItem.propTypes = {
  baseBgUrl: string,
  defaultFont: string,
  handleFontSelection: func,
  handleHoverAction: func,
  hoverIndex: number,
  index: number,
  item: shape({}),
  listCreated: bool,
  selected: string
};

FontPickerListItem.defaultProps = {
  baseBgUrl: '',
  defaultFont: '',
  handleFontSelection: f => f,
  handleHoverAction: f => f,
  hoverIndex: -1,
  index: 0,
  item: {},
  listCreated: true,
  selected: ''
};

export default FontPickerListItem;
