import { ComponentPropsWithoutRef } from 'react';
import {
  ColorStyle, Icon, ProductName, Size
} from '../../constants/common.types';

export type BadgeProps = ComponentPropsWithoutRef<'span'> & {
  size?: Size,
  colorStyle?: ProductName | ColorStyle | 'neutral' | 'warning',
  variant?: 'filled' | 'subtle',
  truncate?: boolean,
  rounded?: boolean,
  icon?: Icon
}

export const badgeDefaultProps:Partial<BadgeProps> = {
  rounded: true,
  variant: 'filled',
  colorStyle: 'primary',
  size: 'medium',
  truncate: false
};
