/* eslint-disable max-len */
import Styled from 'styled-components';
import { mainFontColor, settingsBgColor } from '../../../../styles/colors';
import { DEFAULT_BUILDER_WIDTHS } from '../../../../constants';

export const ScSettings = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
  background-color: ${settingsBgColor};

  .rightPanelContainer {
    position: fixed;
    top: 114px;
    overflow-x: hidden;
    right: -${DEFAULT_BUILDER_WIDTHS.rightPanel}px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    opacity: 0;
    transition: width 0s ease;
    transition: all .4s ease;

    &.isOpen {
      right: 0px;
      opacity: 1;
      pointer-events: all;
    }

    @media screen and (max-width: 480px){
      top: 0;
    }

  }

  label.title {
    color: ${mainFontColor};
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  p.description {
    font-size: 14px;
    color: #6F76A7;
    margin-bottom: 14px;

    &.noSubElement {
      margin-bottom: 0;
    }
  }
`;

export const ScSettingsContent = Styled.div`
  ${({ longWidth }) => (`
    max-width: ${longWidth ? '660px' : '600px'};
  `)}
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  margin-bottom: 36px;
  
  // A/B Test: ctAppNameIconModal
  .appNameIconSettingsModal & {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;

    label.title {
      color: ${mainFontColor};
      display: block;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    p.description {
      font-size: 14px;
      color: #6F76A7;
      margin-bottom: 14px;

      &.noSubElement {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 31px 22px;
    margin: 10px 8px;
    
    .appNameIconSettingsModal & {
      padding: 0;
      margin: 0;
    }
  }
`;

export const ScRow = Styled.div`
  padding: 25px 25px 28px;

  &:not(:first-child) {
    border-top: 1px solid rgba(228,228,235,.5);
  }
`;
