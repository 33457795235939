import React, { KeyboardEvent } from 'react';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { safeJSONParse } from '@jotforminc/utils';
import range from 'lodash/range';
import { I_GATEWAYS } from '../components/modals/Flows/Types';

import { ProductListSettingsProperties } from '../constants/ProductListSettingsProperties';
import {
  IProductListSettingProperties,
  ISellProductsItem,
  TCurrencyCode,
  TCurrencySymbol,
  TProductListSetting,
  IGatewaySettings,
  ICustomOption,
  IQuantityOption,
  InputValuesType,
  TOption
} from '../types/common';
import { DEFAULT_QUANTITY } from '../constants/variables';
import { arrayToString, changeInArray } from './general';
import { checkMobilePhone, checkTablet } from './domUtils';
import { C_GATEWAYS } from '../components/modals/Flows/Constants';

export const getActiveProduct = (activeProd: ISellProductsItem): (ICustomOption | IQuantityOption)[] | [] => {
  if (activeProd && ('options' in activeProd)) {
    return typeof activeProd.options === 'string'
      ? safeJSONParse(activeProd.options, []) as (ICustomOption | IQuantityOption)[]
      : activeProd.options;
  }

  return [];
};

export function getProductListSettingsProperties(settingType: TProductListSetting): IProductListSettingProperties | undefined {
  if (!ProductListSettingsProperties[settingType]) return;
  return ProductListSettingsProperties[settingType];
}

export function getCurrencySymbol(currency: TCurrencyCode) : TCurrencySymbol {
  let symbol: TCurrencySymbol;

  switch (currency) {
    case 'EUR': symbol = '€'; break;
    case 'GBP': symbol = '£'; break;
    case 'BRL': symbol = 'R$'; break;
    case 'JPY': symbol = '¥'; break;
    case 'USD': symbol = '$'; break;
    case 'TRY':
      symbol = '₺'; break;
    default:
      symbol = currency;
      break;
  }

  return symbol;
}

export function fixProductData(productData: ISellProductsItem): ISellProductsItem {
  const newProductData : ISellProductsItem = { ...productData };

  if (typeof productData.images === 'string') {
    newProductData.images = safeJSONParse(productData.images, []) as string[];
  }

  // if (typeof productData.options === 'string') {
  //   newProductData.options = safeJSONParse(productData.options, []) as string[];
  // }

  // const stringToBooleanProps = ['required', 'selected', 'showSubTotal', 'hasExpandedOption', 'hasQuantity', 'hasSpecialPricing'];

  return newProductData;
}

export function stringifyProductData(p: ISellProductsItem): ISellProductsItem {
  const newProductData : ISellProductsItem = { ...p };

  if (isArray(p.images)) {
    newProductData.images = JSON.stringify(p.images);
  }

  if (isArray(p.options)) {
    newProductData.options = JSON.stringify(p.options);
  }

  return newProductData;
}

export function fixAllProductsData(productsData: ISellProductsItem[]) : ISellProductsItem[] {
  return productsData.map(p => fixProductData(p));
}

export function stringifyAllProductsData(productsData: ISellProductsItem[]) : ISellProductsItem[] {
  return productsData.map(p => stringifyProductData(p));
}

export const SortableItem = SortableElement(({ children } : IChildren) : React.ReactNode => {
  return children;
});

export const SortableWrapper = SortableContainer(({ children } : IChildren) : JSX.Element => (
  <div className='sortableWrapper'>
    {children}
  </div>
));

export const SortableDragHandle = SortableHandle(({ children } : IChildren) : React.ReactNode => {
  return children;
});

/* eslint-disable complexity, max-statements */
export const checkIsGatewayConnected = (settings: IGatewaySettings) : boolean => {
  if (settings.paymentConnectionID) { return true; }

  let isConnected = false;
  switch (settings.type) {
    case 'control_square':
      if (settings.location) { isConnected = true; }
      break;
    case 'control_paypal':
      if (settings.account && settings.account.length > 0) { isConnected = true; }
      break;
    case 'control_stripeCheckout':
      if (settings.stripeUserId) { isConnected = true; }
      break;
    case 'control_stripe':
      if (settings.publishableKey) { isConnected = true; }
      break;
    case 'control_authnet':
      if (settings.apiLoginId && settings.transactionKey) {
        if (settings.apiLoginId.length > 0 && settings.transactionKey.length > 0) { isConnected = true; }
      }
      break;
    case 'control_paysafe':
      if (settings.account) {
        if (settings.account.length > 0) { isConnected = true; }
      }
      break;
    case 'control_iyzico':
      if (settings.iyzicoApiKey && settings.iyzicoSecretKey) {
        if (settings.iyzicoApiKey.length > 0 && settings.iyzicoSecretKey.length > 0) { isConnected = true; }
      }
      break;
    case 'control_braintree':
      if (settings.merchantId && settings.publicKey && settings.privateKey && settings.merchantAccountId) {
        if (settings.merchantId.length > 0 && settings.publicKey.length > 0 && settings.privateKey.length > 0 && settings.merchantAccountId.length > 0) { isConnected = true; }
      }
      break;
    case 'control_cardconnect':
      if (
        (settings.merchantId && settings.apiUsername && settings.apiPassword && settings.merchantId.length > 0 && settings.apiUsername.length > 0 && settings.apiPassword.length > 0)
        || (settings.sandbox && settings.sandbox === 'enabled')
      ) {
        isConnected = true;
      }
      break;
    case 'control_bluepay':
      if (settings.accountId && settings.apiSecret) {
        if (settings.accountId.length > 0 && settings.apiSecret.length > 0) { isConnected = true; }
      }
      break;
    case 'control_moneris':
      if (settings.monerisid && settings.monerisapi) {
        if (settings.monerisid.length > 0 && settings.monerisapi.length > 0) { isConnected = true; }
      }
      break;
    case 'control_eway':
      if (settings.apiKey && settings.apiPassword && settings.encryptionKey) {
        if (settings.apiKey.length > 0 && settings.apiPassword.length > 0 && settings.encryptionKey.length > 0) { isConnected = true; }
      }
      break;
    case 'control_paypalpro':
      if (settings.username && settings.password && settings.signature) {
        if (settings.username.length > 0 && settings.password.length > 0 && settings.signature.length > 0) { isConnected = true; }
      }
      break;
    case 'control_worldpay':
      if (settings.installationID) {
        if (settings.installationID.length > 0) { isConnected = true; }
      }
      break;
    case 'control_payjunction':
      if (settings.username && settings.password) {
        if (settings.username.length > 0 && settings.password.length > 0) { isConnected = true; }
      }
      break;
    case 'control_bluesnap':
      if (settings.apiKey && settings.password && settings.softDescriptor) {
        if (settings.apiKey.length > 0 && settings.password.length > 0 && settings.softDescriptor.length > 0) { isConnected = true; }
      }
      break;
    case 'control_payfast':
      if (settings.merchantId && settings.merchantKey && settings.passphrase) {
        if (settings.merchantId.length > 0 && settings.merchantKey.length > 0 && settings.passphrase.length > 0) { isConnected = true; }
      }
      break;
    case 'control_firstdata':
      if (settings.gatewayId && settings.password) {
        if (settings.gatewayId.length > 0 && settings.password.length > 0) { isConnected = true; }
      }
      break;
    case 'control_stripeACH':
      if (settings.apiKey && settings.plaidClient && settings.plaidSecret) {
        if (settings.apiKey.length > 0 && settings.plaidClient.length > 0 && settings.plaidSecret.length > 0) { isConnected = true; }
      }
      break;
    case 'control_cybersource':
      if (settings.merchantID && settings.transactionKey) {
        if (settings.merchantID.length > 0 && settings.transactionKey.length > 0) { isConnected = true; }
      }
      break;
    case 'control_2co':
      if (settings.vendorNumber) {
        if (settings.vendorNumber.length > 0) { isConnected = true; }
      }
      break;
    case 'control_skrill':
      if (settings.account) {
        if (settings.account.length > 0) { isConnected = true; }
      }
      break;
    case 'control_sensepass':
      const hasApiKey = settings.apiKey && settings.apiKey.length > 0;
      const isDirectFlowYes = settings.isDirectFlow === 'Yes';
      const hasClientId = isDirectFlowYes && settings.clientID && settings.clientID.length > 0;
      if (isEnterprise()) {
        const hasConnId = settings.reusableConnectionID && settings.reusableConnectionID.length > 0 && settings.reusableConnectionID !== 'none';

        const withConnId = hasConnId && isPlainObject(settings.connectionIdOptions);
        const withApiKey = hasApiKey && !isPlainObject(settings.connectionIdOptions);

        if (withConnId || withApiKey || hasConnId) {
          isConnected = true;
        }
      } else if ((!isDirectFlowYes && hasApiKey) || (isDirectFlowYes && hasApiKey && hasClientId)) {
        isConnected = true;
      }
      break;
    case 'control_sofort':
      if (settings.configKey) {
        if (settings.configKey.length > 0) { isConnected = true; }
      }
      break;
    case 'control_paymentwall':
      if (settings.publicKey && settings.privateKey) {
        if (settings.publicKey.length > 0 && settings.privateKey.length > 0) { isConnected = true; }
      }
      break;
    case 'control_paypalcomplete':
      if (settings.merchantId) { isConnected = true; }
      break;
    case 'control_paypalInvoicing':
      if (settings.merchantEmail) { isConnected = true; }
      break;
    case 'control_mollie':
      if (settings.profileId && settings.profileId.length > 0) {
        isConnected = true;
      }
      break;
    case 'control_payu':
      if (settings.merchantPosId && settings.merchantPosId.length > 0
        && settings.signatureKey && settings.signatureKey.length > 0
        && settings.oAuthClientId && settings.oAuthClientId.length > 0
        && settings.oAuthClientSecret && settings.oAuthClientSecret.length > 0) {
        isConnected = true;
      }
      break;
    case 'control_pagseguro':
      if (
        settings.pagseguroid && settings.pagseguroid.length > 0
        && settings.pagseguroapi && settings.pagseguroapi.length > 0
      ) {
        isConnected = true;
      }
      break;
    case 'control_echeck':
      if (
        settings.apiLoginId && settings.apiLoginId.length > 0
        && settings.transactionKey && settings.transactionKey.length > 0
      ) {
        isConnected = true;
      }
      break;
    default:
      break;
  }

  return isConnected;
};

export const isYes = (val : unknown) : boolean => (typeof val === 'string' ? ['yes', 'Yes', '1', 'enabled', 'enable', 'Enabled', 'true'].includes(val) : !!val);

export const checkIsGatewayTestMode = (settings: IGatewaySettings) : boolean => {
  return (settings.environment === 'sandbox' || isYes(settings.sandbox) || isYes(settings.allowTest) || isYes(settings.testmodeAuth) || isYes(settings.testMode)) && checkIsGatewayConnected(settings);
};

export const checkIsGatewayConnectedAndTestMode = (settings: IGatewaySettings) : [boolean, boolean] => [checkIsGatewayConnected(settings), checkIsGatewayTestMode(settings)];
/* eslint-enable complexity, max-statements */

export const chunk = (array: Array<unknown>, _size = 1): Array<Array<unknown>> => {
  const size = Math.max(_size, 0);
  const length = array == null ? 0 : array.length;
  if (!length || size < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = new Array(Math.ceil(length / size));

  while (index < length) {
    result[resIndex++] = array.slice(index, (index += size));
  }
  return result;
};

export const convertTextAlignment2Flex = (textAlignmentValue: string): string => {
  switch (textAlignmentValue) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

const handleQuantityData = ({ start, end, name = '' }: InputValuesType): IQuantityOption => ({
  type: 'quantity',
  name,
  properties: arrayToString(range(parseInt(start, 10), parseInt(end, 10) + 1)),
  defaultQuantity: '',
  expanded: false
});

export const getFilteredOptions = (opts: (ICustomOption | IQuantityOption)[], type: TOption): (ICustomOption | IQuantityOption)[] => (
  (Array.isArray(opts) && opts.length > 0) ? (opts).filter(x => x.type === type) : []
);

export const getQuantityData = (productOptions: (ICustomOption | IQuantityOption)[], selectedOption: number | null, inputValues = DEFAULT_QUANTITY) : (ICustomOption | IQuantityOption)[] => {
  const quantityData = handleQuantityData(inputValues);
  const restOpts = getFilteredOptions(productOptions, 'custom');
  return typeof selectedOption === 'number'
    ? changeInArray(productOptions, selectedOption, quantityData) as (IQuantityOption | ICustomOption)[]
    : [quantityData, ...restOpts];
};

interface IChildren {
  children: React.ReactNode
}

export const sendTrackData = (actor: string, action: string, _target: object = {}) : void => {
  if (!window.JotFormActions || window.JOTFORM_ENV !== 'PRODUCTION') {
    return;
  }
  if (!window.jotPortalEvents) {
    window.jotPortalEvents = window.JotFormActions('portal-app');
  }
  let device = 'desktop';
  if (checkMobilePhone()) {
    device = 'mobile';
  } else if (checkTablet()) {
    device = 'tablet';
  }
  const target = { device, ..._target };
  window.jotPortalEvents.tick({ actor, action, target });
};

export const isLetterKey = (e: KeyboardEvent): boolean => {
  const charCode = (e.which) ? e.which : e.keyCode;
  return (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 8;
};

type CurrencyCheckResult = {
  isSupported: boolean;
  supportedCurrency: TCurrencyCode;
  allSupportedCurrencies: TCurrencyCode[];
};

export const checkCurrencySupport = (gatewayType: I_GATEWAYS, currency: TCurrencyCode): CurrencyCheckResult => {
  const gatewayDetail = C_GATEWAYS.find(g => g.type === gatewayType);

  if (!gatewayDetail) {
    return {
      isSupported: false,
      supportedCurrency: 'USD',
      allSupportedCurrencies: ['USD']
    };
  }

  const isSupported = gatewayDetail.supportedCurrencies.includes(currency);

  return {
    isSupported,
    supportedCurrency: isSupported ? currency : gatewayDetail.supportedCurrencies[0] as TCurrencyCode,
    allSupportedCurrencies: gatewayDetail.supportedCurrencies as TCurrencyCode[]
  };
};

export const isPaymentProductDev = (username: string): boolean => ['sevki', 'miracbaydogan', 'sylas', 'sinanusluer', 'meliskaya'].includes(username);
