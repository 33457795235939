import { t } from '@jotforminc/translation';
import scriptjs from 'scriptjs';

import { handleCustomNavigation } from './platform';

const handleShare = (urlString, target) => {
  const isMobileApp = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;
  if (isMobileApp && window.sendMessageToJFMobile) {
    window.sendMessageToJFMobile({
      shareOnSocials: urlString
    });
  } else {
    handleCustomNavigation(urlString, target, false, 'scrollbars=yes, toolbar=no, width=550, height=420');
  }
};
export const shareFacebook = productURL => {
  const url = new URL(productURL);
  const { host } = url;
  if (host.split('.')[0] === 'form') {
    url.host = host.replace('form', 'fb');
  }

  const urlString = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
  handleShare(urlString, 'sharer');
};

export const shareTwitter = tweetText => {
  scriptjs(['//platform.twitter.com/widgets.js'], () => { });
  const urlString = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
  handleShare(urlString, 'blank');
};

export const shareLinkedin = productURL => {
  const urlString = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(productURL)}`;
  handleShare(urlString, 'blank');
};

export const shareWhatsapp = whatsappText => {
  const urlString = `https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappText)}`;
  handleShare(urlString, 'blank');
};

export const shareUsingNavigator = async shareData => {
  try {
    await window.navigator.share(shareData);
  } catch (err) {
    console.error(err);
  }
};

export const getHighlightedShareOptions = ({
  productURL, onShareQR, productName
}) => {
  let tweetText;
  if (productName === 'app') {
    tweetText = `${t('Excited to share the new app I created with @Jotform! Check it out:')}\n ${productURL}\n`;
  } else if (productName === 'form') {
    tweetText = `${t('Hi! I just created a form via @JotForm. Please click the link below and complete this form.')}\n ${productURL}\n`;
  }

  let whatsappText = productURL;
  if (productName === 'form') {
    whatsappText = `${t('Hi! I made a form via Jotform. Your input would mean a lot. Here’s the link:')}\n${productURL}`;
  }

  const socialsArr = [
    {
      title: 'Share on Facebook',
      description: `Share your ${productName} on Facebook`,
      icon: 'https://cdn.jotfor.ms/assets/img/support-forum/share-facebook.png',
      action: () => shareFacebook(productURL),
      platformClass: 'social-facebook-icon',
      testID: 'share-on-facebook-button'
    },
    {
      title: 'Share on WhatsApp',
      description: `Share your ${productName} on WhatsApp`,
      icon: 'https://cdn.jotfor.ms/assets/img/support-forum/share-whatsapp.png',
      action: () => shareWhatsapp(whatsappText),
      platformClass: 'social-whatsapp-icon',
      testID: 'share-on-whatsapp-button'
    },
    {
      title: 'Share on X',
      description: `Share your ${productName} on X`,
      icon: 'https://cdn.jotfor.ms/assets/img/support-forum/share-x.png',
      action: () => shareTwitter(tweetText),
      platformClass: 'social-twitter-icon',
      testID: 'share-on-x-button'
    },
    {
      title: 'Share on LinkedIn',
      description: `Share your ${productName} as a LinkedIn post or private message`,
      icon: 'https://cdn.jotfor.ms/assets/img/support-forum/share-linkedin.png',
      action: () => shareLinkedin(productURL),
      platformClass: 'social-linkedin-icon',
      testID: 'share-on-linkedin-button'
    },
    {
      title: 'Share with QR Code',
      description: `Download or embed a QR code to share your ${productName}`,
      icon: '//cdn.jotfor.ms/img/builder/publish/qr_code.png?v=0.2',
      action: onShareQR,
      platformClass: 'social-qr-icon',
      testID: 'share-with-qr-code-button'
    }
  ];

  return socialsArr;
};
