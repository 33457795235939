import { call, put, select } from 'redux-saga/effects';
import React from 'react';
import { createRoot } from 'react-dom/client';
import GuestLoginModal from '../../../modules/Builder/components/Modals/GuestLoginModal';
import SELECTORS from '../../selectors';
import { fetchPortalAction, setUserAction } from '../../actionCreators';

export const showGuestModal = user => {
  return new Promise((resolve, reject) => {
    const container = document.getElementById('modal-root');
    const root = createRoot(container);
    const handleClose = () => {
      reject();
      root.unmount();
    };
    const handleConfirm = selection => {
      resolve(selection);
      root.unmount();
    };

    root.render(<GuestLoginModal
      onCancel={handleClose}
      onConfirm={handleConfirm}
      user={user}
    />);
  });
};

export function* handleShowGuestLoginModal({
  payload: {
    onCancel = f => f
  }
}) {
  try {
    const { id: appID } = yield select(SELECTORS.getAppInfoWithDefaults);
    const user = yield select(SELECTORS.getUser);
    const newUser = yield call(showGuestModal, user);

    // if new user logs in
    yield put(setUserAction(newUser));
    yield put(fetchPortalAction(appID));
  } catch {
    yield call(onCancel);
  }
}
