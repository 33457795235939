import { cva } from 'class-variance-authority';
import { CVAType } from '../../types/system.types';
import { ModalProps, ModalHeaderProps, ModalBodyProps } from './modal.types';
import { primary } from '../../tokens/colors';

type ModalIconWrapperCVAType = CVAType<Pick<ModalHeaderProps, 'iconColorStyle' | 'logoIcon'>>;
type ModalIconCVAType = CVAType<Pick<ModalHeaderProps, 'logoIcon'>>;
type ModalCVAType = CVAType<Pick<ModalProps, 'size'>>;
type ModalBodyCVAType = CVAType<Pick<ModalBodyProps, 'noPadding'>>;
type ModalContainerCVAType = CVAType<Pick<ModalProps, 'fitOnMobile' | 'noFade'>>;

export const modalContainerCVA = cva<ModalContainerCVAType>('bg-gray-900 bg-opacity-60 fixed inset-0 flex xs:items-center justify-center pt-12 xs:pt-10 pb-0 xs:pb-10', {
  variants: {
    fitOnMobile: {
      true: 'items-stretch',
      false: 'items-end'
    },
    noFade: {
      false: 'anim-150 fade-in'
    }
  }
});

export const modalCVA = cva<ModalCVAType>('color-navy-300 bg-white w-full radius-t-lg xs:radius-b-lg max-h-full flex flex-col relative m-0', {
  variants: {
    size: {
      small: 'max-w-120',
      medium: 'max-w-sm',
      large: 'max-w-md',
      xlarge: 'max-w-lg',
      xxlarge: 'max-w-xl'
    }
  }
});

export const modalBodyCVA = cva<ModalBodyCVAType>('overflow-auto mb-0 text-md grow-1 xs:grow-0', {
  variants: {
    noPadding: {
      true: 'p-0',
      false: 'p-8'
    }
  }
});

export const modalIconWrapperCVA = cva<ModalIconWrapperCVAType>('flex items-center justify-center w-10 h-10 radius shrink-0 mr-4', {
  variants: {
    iconColorStyle: {
      primary: primary.accent.default.bg.base,
      success: primary.success.default.bg.base,
      error: primary.error.default.bg.base,
      secondary: primary.background.light.base,
      analytics: 'bg-analytics-default',
      pages: 'bg-pages-default',
      teams: 'bg-teams-default',
      sign: 'bg-sign-default',
      pdf: 'bg-pdf-default',
      apps: 'bg-apps-default',
      reports: 'bg-reports-default',
      forms: 'bg-forms-default',
      tables: 'bg-tables-default',
      inbox: 'bg-inbox-default',
      approvals: 'bg-approvals-default'
    },
    logoIcon: {
      false: 'color-white'
    }
  }
});

export const modalIconCVA = cva<ModalIconCVAType>('', {
  variants: {
    logoIcon: {
      true: 'w-full',
      false: 'w-6'
    }
  }
});
