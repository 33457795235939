import Styled from 'styled-components';
import React, { forwardRef } from 'react';
import { CheckboxItem as CommonCheckboxItem } from '@jotforminc/checkbox';
import { branding21MainBlue, rightPanelItemBgColor, rightPanelItemBorderColor } from '../../../styles/colors';

const CheckboxItem = forwardRef((props, ref) => (<CommonCheckboxItem {...props} ref={ref} />));
const ScCheckboxItem = Styled(CheckboxItem)`
  padding: 0 0 0 1.5em !important;
  margin-top: 20px;

  div.jfCheckbox-wrapper {
    left: 0;
    width: 16px;
    height: 16px;
    border: none;
    box-shadow: 0 0 0px 1px ${rightPanelItemBorderColor};
    background-color: ${branding21MainBlue};

    input {
      border: solid 1px ${rightPanelItemBorderColor} !important;
      cursor: pointer;
    }
  }

  .jfCheckbox-inputText {
    font-size: 1em;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #ffffff;
    padding-right: 0px;
  }

  div.jfCheckbox-check {
    transition: all 0.1s ease !important;
    border-radius: 4px;
    border: none !important;
    background-color: ${rightPanelItemBgColor} !important;
  }
`;

export default ScCheckboxItem;
