import { cva } from 'class-variance-authority';
import { CVAType } from '../../types';
import { TextareaProps } from './textarea.types';

type TextareaCVAType = CVAType<Pick<TextareaProps, 'size'>>
type TextareaContainerCVAType = CVAType<Pick<TextareaProps, 'resize'>>
type TextareaBarCVAType = CVAType<Pick<TextareaProps, 'size' | 'theme'>>

export const textareaCVA = cva<TextareaCVAType>('magnet-textarea resize-none', {
  variants: {
    size: {
      small: 'p-3',
      medium: 'p-3',
      large: 'py-3 px-4'
    }
  }
});

export const textareaContainerCVA = cva<TextareaContainerCVAType>('magnet-textarea-container flex-col', {
  variants: {
    resize: {
      both: 'resize-both',
      none: 'resize-none',
      horizontal: 'resize-x',
      vertical: 'resize-y'
    }
  },
  compoundVariants: [
    {
      resize: ['both', 'horizontal', 'vertical'],
      className: 'overflow-auto'
    }
  ]
});

export const textareaBarCVA = cva<TextareaBarCVAType>('flex items-center justify-end pb-2 gap-2', {
  variants: {
    size: {
      small: 'px-3 text-sm',
      medium: 'px-3 text-sm',
      large: 'px-4 text-md'
    },
    theme: {
      light: 'color-navy-200',
      dark: 'color-gray-200'
    }
  }
});
