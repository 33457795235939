import React from 'react';
import { Emoji } from '@jotforminc/emoji-mart';
import { string, oneOfType, number } from 'prop-types';

const EmojiRenderer = ({ id, size }) => (
  <Emoji
    emoji={{ id }}
    size={size}
    native={true}
  />
);

EmojiRenderer.propTypes = {
  id: string,
  size: oneOfType([number, string])
};

EmojiRenderer.defaultProps = {
  id: '',
  size: 55
};

export default EmojiRenderer;
