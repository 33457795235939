import React from 'react';
import { Badge } from '@jotforminc/magnet';
import { IconUserFilled } from '@jotforminc/svg-icons';

interface I_RESOURCEOWNERBADGE {
  ownerFullname: string;
  additionalClass: string;
  ownerUsername: string;
}

const COLOR_CLASS_LIST = [
  'bg-navy-300',
  'bg-blue-600',
  'bg-red-400',
  'bg-green-600',
  'bg-orange-600',
  'bg-purple-600',
  'bg-tables-dark',
  'bg-sign-dark',
  'bg-reports-dark',
  'bg-pdf-dark',
  'bg-pages-dark',
  'bg-inbox-dark',
  'bg-apps-dark',
  'bg-approvals-dark',
  'bg-analytics-dark'
];

/* eslint-disable no-bitwise */
function simpleHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  return Math.abs(hash);
}
/* eslint-enable no-bitwise */

const getColorClass = (ownerUsername: string) => {
  return COLOR_CLASS_LIST[simpleHash(ownerUsername) % COLOR_CLASS_LIST.length];
};

/**
 * Renders a badge displaying the resource owner's information.
 *
 * This component creates a customized badge with the owner's full name and a color-coded background.
 * The background color is determined by a hash of the owner's username for consistent, unique coloring.
 *
 * @param {Object} props - The component props
 * @param {string} props.ownerFullname - The full name of the resource owner
 * @param {string} props.additionalClass - Additional CSS classes to apply to the badge
 * @param {string} props.ownerUsername - The username of the resource owner (used for color determination)
 * @returns {React.ReactElement} The rendered badge component
 */
export function ResourceOwnerBadge({
  ownerFullname,
  additionalClass = '',
  ownerUsername
}: I_RESOURCEOWNERBADGE): any {
  return (
    <Badge
      truncate
      variant="subtle"
      className={`color-black shrink-0 ml-2 ${additionalClass} ${getColorClass(ownerUsername)}`}
      icon={() => <IconUserFilled className="color-black shrink-0 w-3.5 h-3.5" />}
    >
      <span className='text-xs line-height-xs font-normal'>{ownerFullname}</span>
    </Badge>
  );
}
