import React from 'react';
import { number } from 'prop-types';

export const LimitRenderer = ({ length, limit }) => <span>{`${length}/${limit}`}</span>;

LimitRenderer.propTypes = {
  length: number,
  limit: number
};

LimitRenderer.defaultProps = {
  length: 0,
  limit: 0
};
