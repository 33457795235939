import React from 'react';
import Styled from 'styled-components';
import { bool, func, number } from 'prop-types';

const ScCh = Styled.div`
  position: relative;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.chBorder};
  background-color: #fff;

  &:after {
    content: "";
    display: block;
    background: url("${p => p.theme.chIcon}") no-repeat center;
  }

  input[type=checkbox], &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    margin: 0;
    padding: 0;
  }

  input[type=checkbox] { opacity: 0; }

  [aria-selected="true"] & {
    border: 0;
    background-color: ${p => p.theme.chSelected};

    &:after { transform: scale(1) rotate(0); }
  }

  [aria-selected="partial"] & {
    border: 0;
    background-color: ${p => p.theme.chSelected};

    &:after { background: url("${p => p.theme.chPartialIcon}") no-repeat center; transform: scale(1) rotate(0); }
  }
`;

export const CheckBox = ({ isSelected, onChange, id }) => (
  <ScCh className="itemCheckbox">
    <input
      type="checkbox"
      id={id}
      checked={isSelected}
      tabIndex="-1"
      onChange={onChange}
    />
  </ScCh>
);

CheckBox.propTypes = {
  isSelected: bool,
  id: number,
  onChange: func
};

CheckBox.defaultProps = {
  isSelected: false,
  id: 5,
  onChange: e => e.preventDefault()
};
