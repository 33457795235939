import { t } from '@jotforminc/translation';
import { capitalizeFirstLetter, isProduction } from '@jotforminc/utils';

import * as Texts from '../constants/texts';
import { trackAction, systemPlans, combinedInfo } from '../api';

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'EUR':
      return '&euro;';
    case 'GBP':
      return '&pound;';
    case 'BRL':
      return 'R$';
    case 'JPY':
      return '¥';
    case 'USD':
      return '$';
    case 'AUD':
    case 'CAD':
    case 'NZD':
    case 'SGD':
    case 'HKD':
    case 'CLP':
      return '$';
    case 'TRY':
      return 'TL';
    default:
      return '';
  }
};
export const getPlanClasName = accountTypeName => `is${capitalizeFirstLetter(accountTypeName === 'FREE' ? 'STARTER' : accountTypeName.toLowerCase())}`;

// Also Translates
export const prettyAccountName = accountTypeName => (accountTypeName === 'FREE' ? t('STARTER') : t(accountTypeName));

export const getCurrencyDirection = currency => {
  return currency === 'TRY' ? 'right' : 'left';
};

export const LimitTypes = {
  formCount: {
    title: Texts.FORM_LIMIT,
    body: Texts.FORMS
  },
  submissions: {
    title: Texts.SUBMISSION_LIMIT,
    body: Texts.SUBMISSIONS
  },
  workflowRuns: {
    title: Texts.WORKFLOW_RUNS_LIMIT,
    body: Texts.WORKFLOW_RUNS
  },
  aiAgents: {
    title: Texts.AI_AGENTS_LIMIT,
    body: Texts.AI_AGENTS
  }
};

export const fetchUserPlans = async () => {
  const plans = await systemPlans();
  return plans;
};

export const fetchCombinedInfo = async () => {
  const { credentials } = await combinedInfo();
  return credentials;
};

export const getLimitFromType = (user, limitType) => {
  return user?.account_type?.limits?.[limitType] || null;
};

export const actionTracker = ({
  username, action, target, isV2
}) => {
  if (!username) {
    return console.log('[Limit Dialog Actions] :: Unable to track, user not found.');
  }

  const log = {
    project: `limitDialog${isV2 ? '-v2' : ''}`,
    oldActions: {
      action: action,
      target: target
    },
    actor: username,
    location: window.location && window.location.href
  };

  if (!isProduction()) {
    return console.log('[Limit Dialog Actions] :: ', log);
  }

  trackAction(log);
};

export const setLocalStorageWithExpiry = (key, value, ttl = 0) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: ttl === 0 ? undefined : now.getTime() + ttl
  };
  global?.localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = key => {
  const itemStr = global?.localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    global?.localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
