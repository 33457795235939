export const STATIC_FIELDS_WITH_PLACEHOLDER_FOR_SACL = [
  { text: 'Form Title', placeholder: '{form_title}' }
];

export const EMAIL_TEMPLATE_TYPES = {
  WORKFLOW_APPROVE_START: 'WORKFLOW_APPROVE_START',
  WORKFLOW_APPROVE_APPROVED: 'WORKFLOW_APPROVE_APPROVED',
  WORKFLOW_APPROVE_REJECTED: 'WORKFLOW_APPROVE_REJECTED',
  WORKFLOW_SEND_EMAIL_GENERAL: 'WORKFLOW_SEND_EMAIL_GENERAL',
  WORKFLOW_APPROVE_REASSIGN: 'WORKFLOW_APPROVE_REASSIGN',
  WORKFLOW_APPROVE_REASSIGN_APPROVER: 'WORKFLOW_APPROVE_REASSIGN_ASSIGNEE',
  WORKFLOW_ASSIGN_START: 'WORKFLOW_ASSIGN_START',
  FORM_SUBMIT_AUTORESPONDER: 'autorespond',
  FORM_SUBMIT_NOTIFICATION: 'notification',
  FORM_SAVE_AND_CONTINUE_LATER: 'sacl',
  FORM_SUBMIT_CONDITIONAL: 'FORM_SUBMIT_CONDITIONAL',
  FORM_SHARE: 'FORM_SHARE',
  FORM_REMINDER: 'FORM_REMINDER',
  APPOINTMENT_REMINDER: 'APPOINTMENT_REMINDER',
  WORKFLOW_ASSIGN_FORM_NOTIFICATION: 'WORKFLOW_ASSIGN_FORM_NOTIFICATION',
  WORKFLOW_SEND_PDF: 'WORKFLOW_SEND_PDF',
  WORKFLOW_SEND_REPORT: 'WORKFLOW_SEND_REPORT'
};
