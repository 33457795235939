export class ActionManager {
  constructor({
    user,
    projectName,
    trackOnce,
    enableDebugMode
  }) {
    this.user = user || {};
    this.projectName = projectName;
    this.trackOnce = trackOnce || false;
    this.JotFormActions = null;
    this.registerJotformAction = this.registerJotformAction.bind(this);
    this.sentEvents = {};
    this.enableDebugMode = enableDebugMode || false;
  }

  setupJotFormActions() {
    const { JotFormActions } = global;
    if (typeof JotFormActions !== 'function') return;
    this.JotFormActions = JotFormActions(this.projectName);
  }

  tick({
    actor = '', action = '', target = '', trackOnce = false
  } = {}) {
    this.JotFormActions?.tick({ actor, action, target });

    if (this.trackOnce || trackOnce) {
      this.sentEvents[`${action}${target}`] = true;
    }
  }

  logDebugAction({
    actor, action, target, trackOnce
  }) {
    console.log({
      actor, action, target, trackOnce
    });
    const hasMissingParameter = [actor, action, target].some(param => !param);
    if (hasMissingParameter) {
      console.error(`[missing-actor]: Couldn't tick action. 'actor', 'action', 'target' parameters are needed. project: ${this.projectName} actor: ${actor} action: ${action} target: ${target}`);
    }

    if (this.trackOnce || trackOnce) {
      this.sentEvents[`${action}${target}`] = true;
    }
  }

  registerJotformAction({ action, target, trackOnce = false }) {
    if (!this.JotFormActions) {
      this.setupJotFormActions();
    }

    if ((this.trackOnce || trackOnce) && this.sentEvents[`${action}${target}`]) {
      return;
    }

    const { username: actor = null, account_type: accountType } = this.user;
    const isEnterprise = global.JOTFORM_ENV === 'ENTERPRISE';
    const isEnvProd = global.JOTFORM_ENV === 'PRODUCTION' && !isEnterprise;
    const isJotFormer = ['ADMIN', 'SUPPORT', 'INTERN'].indexOf(accountType?.name) > -1 && !isEnterprise;

    if (this.enableDebugMode) {
      this.logDebugAction({
        actor, action, target, trackOnce
      });
      return;
    }

    if (!actor) {
      // eslint-disable-next-line max-len
      console.warn(`[missing-actor]: Couldn't tick action. 'ActionManager' constructor needs 'user' parameter with 'username' field. Please check 'user' parameter. project: ${this.projectName} actor: ${actor} action: ${action} target: ${target}`);
    }

    if (!this.JotFormActions || !actor || !isEnvProd || isJotFormer) return;

    this.tick({
      actor, action, target, trackOnce
    });
  }
}
