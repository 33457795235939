import React, { ReactElement } from 'react';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import RequiredSettingsIndicator from './RequiredSettingsIndicator';

const FooterContainer = (): ReactElement | null => {
  const {
    allRequiredPropNames,
    hasConnection
  } = usePaymentPropsPanel();

  if (allRequiredPropNames.length === 0 || !hasConnection) {
    return null;
  }

  return <RequiredSettingsIndicator />;
};

export default FooterContainer;
