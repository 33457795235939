import { BUTTON_ROLE_TYPES } from '../../../modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import ActionableItem from '../ActionableItem';

export default class Button extends ActionableItem {
  constructor(defaults = {}, actions = {}) {
    super(defaults, actions);
    this.DEFAULT_TEXTS = {
      [BUTTON_ROLE_TYPES.LINK]: 'Go to Link',
      [BUTTON_ROLE_TYPES.MAIL]: 'Email Us',
      [BUTTON_ROLE_TYPES.PHONE]: 'Call Us',
      [BUTTON_ROLE_TYPES.NAVIGATION]: 'Go to Page',
      [BUTTON_ROLE_TYPES.FORM]: 'Button',
      [BUTTON_ROLE_TYPES.SHARE]: 'Share This App'
    };
  }

  isTitleDefault(title) {
    return this.defaults.title === title;
  }

  get defaultTexts() {
    return this.DEFAULT_TEXTS;
  }
}
