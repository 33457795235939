import React, { useEffect, useState } from 'react';
import { func, shape, string } from 'prop-types';

import { ScPreviewCont } from '../../../styles/scPreview';
import ScAppIconPreview from './ScAppIconPreview';
import SignalStrength from '../../../../../assets/icon_signalStrength.svg';

import ScDevice from './ScDevice';
import { momentToTime } from '../utils';
import { useBattery } from '../../../../../utils/hooks';
import { actAsButton } from '../../../../../utils';
import IdleBattery from '../../../../../assets/battery_idle.svg';
import ChargingBattery from '../../../../../assets/battery_charging.svg';
import AppIcon from '../../../../../components/AppIcon';
import { useAppDefaults } from '../../../../../properties';

const AppIconPreview = ({
  appName, onOpenRightPanel, appProperties
}) => {
  const [time, setTime] = useState(momentToTime());
  useEffect(() => {
    const timer = setInterval(() => setTime(momentToTime()), 1e3);

    return () => clearInterval(timer);
  }, []);
  const { batteryLevel, isCharging } = useBattery();
  const { logoURL: defaultLogoURL } = useAppDefaults();

  return (
    <ScAppIconPreview>
      <ScPreviewCont>
        <ScDevice>
          <div className="appIconPreviewWrapper">
            <div className="statusBar">
              <div className="statusBar-operator">
                <SignalStrength />
                <span>Jotform</span>
              </div>
              <div className="statusBar-time">{time}</div>
              <div className="statusBar-battery-container">
                <span>{`%${batteryLevel}`}</span>
                { isCharging ? <ChargingBattery /> : <IdleBattery /> }
              </div>
            </div>
            <div className="appIconPreview">
              <div className="iconBlock">
                <div className="icon" />
                <div className="icon" />
                <div className="icon" />
              </div>
              <div className="iconBlock">
                <div className="icon" />
                <div
                  className="appIcon icon"
                  {...actAsButton(onOpenRightPanel)}
                >
                  <AppIcon appProperties={appProperties} defaultLogoURL={defaultLogoURL} />
                  <span>{appName}</span>
                </div>
              </div>
              <div className="iconBlock" style={{ marginTop: 'auto' }}>
                <div className="icon" />
                <div className="icon" />
                <div className="icon" />
              </div>
            </div>
          </div>
        </ScDevice>
      </ScPreviewCont>
    </ScAppIconPreview>
  );
};

AppIconPreview.propTypes = {
  appName: string,
  onOpenRightPanel: func,
  appProperties: shape({})
};

AppIconPreview.defaultProps = {
  appName: '',
  onOpenRightPanel: '',
  appProperties: {}
};

export default AppIconPreview;
