import * as actionTypes from '../actionTypes';
import { IGatewaySettings } from '../../types/common';

const activeGatewaySettings = (state: IGatewaySettings, action: IAction) : IGatewaySettings => {
  switch (action.type) {
    case actionTypes.INIT_ACTIVE_GATEWAY_SETTINGS:
    case actionTypes.SET_ACTIVE_GATEWAY_SETTINGS:
      return action.payload.settings;
    case actionTypes.UPDATE_ACTIVE_GATEWAY_SETTINGS:
      return { ...state, ...action.payload.settingsItem };
    default:
      return state || {};
  }
};

interface IPayload {
  settings: IGatewaySettings
  settingsItem: Partial<IGatewaySettings>
}

interface IAction {
  type: string,
  payload: IPayload
}

export default activeGatewaySettings;
