import React from 'react';
import { ControlBar } from '@jotforminc/smart-list';
import { bool, func, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { useSelector } from 'react-redux';
import { IconFormFilled, IconProductReportBuilderFilled, IconProductTablesFilled } from '@jotforminc/svg-icons';
import ScResourcePickerControlBar from './ScResourcePickerControlBar';
import Dropdown from '../Dropdown';
import OptionRenderer from './OptionRenderer';
import ButtonRenderer from './ButtonRenderer';
import SELECTORS from '../../store/selectors';
import TeamAvatar from '../TeamAvatar';
import { ScControlBarDropdown } from './ScControlBarDropdown';
import * as API from '../../modules/api';
import { getTeamID } from '../../utils';

export const RESOURCES_WITH_CONTROL_BAR = {
  sheet: {
    resourceFetcher: API.fetchTables,
    optionValue: 'mytables',
    optionText: 'My Tables',
    Icon: IconProductTablesFilled
  },
  report: {
    resourceFetcher: API.fetchReports,
    optionValue: 'myreports',
    optionText: 'My Reports',
    Icon: IconProductReportBuilderFilled
  },
  form: {
    resourceFetcher: API.fetchForms,
    optionValue: 'myforms',
    optionText: 'My Forms',
    Icon: IconFormFilled
  }
};

const ResourcePickerControlBar = ({
  type, selectedOptionValue, onChangeOption, allowOnlyOwnTeam, ...rest
}) => {
  const userTeams = useSelector(SELECTORS.getUserTeams);

  const { [type]: { optionValue, optionText, Icon } } = RESOURCES_WITH_CONTROL_BAR;

  const options = [
    { text: t(optionText), value: optionValue, Icon: () => <Icon style={{ width: '17px', height: '17px', fill: '#939BC4' }} /> },
    ...userTeams.filter(team => team.id === getTeamID() || !allowOnlyOwnTeam).map(({
      name, id, teamAvatarURL, teamAvatarIcon, teamAvatarEmojiId, teamAvatarIconColor, teamAvatarIconSvgRef, teamAvatarIconBgColor
    }) => ({
      text: name,
      value: id,
      Icon: () => (
        <TeamAvatar
          url={teamAvatarURL}
          icon={teamAvatarIcon}
          emojiId={teamAvatarEmojiId}
          iconColor={teamAvatarIconColor}
          iconBgColor={teamAvatarIconBgColor}
          iconSvgRef={teamAvatarIconSvgRef}
          emojiSize={13}
        />
      )
    }))
  ];

  return (
    <ScResourcePickerControlBar>
      <ControlBar {...rest} isVisible={true} />
      <Dropdown
        options={options}
        defaultValue={selectedOptionValue}
        onChange={onChangeOption}
        OptionRenderer={OptionRenderer}
        ButtonRenderer={ButtonRenderer}
        DropdownWrapperRenderer={ScControlBarDropdown}
        popoverProps={{
          popoverOptions: { placement: 'bottom-start' }
        }}
      />
    </ScResourcePickerControlBar>
  );
};

ResourcePickerControlBar.propTypes = {
  type: string.isRequired,
  selectedOptionValue: string,
  onChangeOption: func,
  allowOnlyOwnTeam: bool
};

ResourcePickerControlBar.defaultProps = {
  selectedOptionValue: null,
  onChangeOption: f => f,
  allowOnlyOwnTeam: false
};

export default ResourcePickerControlBar;
