import { ISubscriptionItem, TPid } from '../../types/common';
import * as actionTypes from '../actionTypes';

const subscriptionsReducer = (state: ISubscriptionItem[] = [], action: actionType) : ISubscriptionItem[] => {
  switch (action.type) {
    case actionTypes.INIT_SUBSCRIPTIONS:
      return action.payload.subscriptions;
    case actionTypes.CREATE_SUBSCRIPTION:
      return [...state, action.payload.subscription];
    case actionTypes.REMOVE_SUBSCRIPTION:
      return state.filter((product: ISubscriptionItem) => product.pid !== action.payload.pid);
    default:
      return state;
  }
};

interface payloadType {
  subscriptions: ISubscriptionItem[],
  subscription: ISubscriptionItem,
  pid: TPid
}

interface actionType {
  type: string,
  payload: payloadType
}

export default subscriptionsReducer;
