import React from 'react';
import { Checkbox as MagnetCheckbox } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

interface ICheckboxGroup {
  name: PAYMENT_QUESTION_PROP_NAMES;
  options: ReadonlyArray<{
    id: string;
    key: string;
    text: string;
  }>;
  delimiter: '';
  onChange: (val: string) => void;
}

const CheckboxGroup: React.FunctionComponent<ICheckboxGroup> = ({
  name,
  options,
  delimiter,
  onChange
}) => {
  const { gatewayProperties } = usePaymentPropsPanel();
  const value = gatewayProperties[name];
  const valueList: string[] = value.length > 0 ? value.split(delimiter) : [];

  return (
    <>
      {
        options.map(opt => {
          const val = opt.id;
          const isOptIncluded = valueList.includes(val);
          return (
            <label htmlFor={`${val}`} className='mb-3 bg-gray-500 flex flex-row items-center px-3 py-2.5 radius text-sm line-height-xs font-normal justify-between'>
              <div className='flex flex-row items-center'>
                <MagnetCheckbox
                  id={val}
                  key={opt.key}
                  theme="dark"
                  checked={isOptIncluded}
                  onChange={() => {
                    if (isOptIncluded) { // uncheck
                      onChange(valueList.filter((item: string) => item !== val).join(delimiter));
                    } else { // check
                      onChange(valueList.length > 0 ? [...valueList, val].join(delimiter) : val);
                    }
                  }}
                />
                <span className="ml-3">{t(opt.text)}</span>
              </div>
            </label>
          );
        })
      }
    </>
  );
};

export default CheckboxGroup;
