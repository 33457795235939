/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @jotforminc/no-native-button */
/* eslint-disable max-len */
import React, {
  useState, useMemo, useEffect, useRef
} from 'react';
import propTypes from 'prop-types';
import { IconCaretDown, IconMagnifyingGlass } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { Popover } from '@jotforminc/uikit';
import { generateDefaultSection, findOption } from './helper';
import ValueArea from './ValueArea';
import { ScDropdown } from './ScDropdown';
import OptionGroup from './OptionGroup';
import { useClickOutside } from '../../../../../../utils/hooks';

const MultiSectionSelect = props => {
  const {
    sections,
    shouldDivideSections,
    onChange,
    value,
    isSearchable,
    shouldNavigateWithKeyboard,
    defaultSection,
    noOptionsText,
    placeholder
  } = props;

  const _defaultSection = defaultSection ?? generateDefaultSection(props);
  const sectionsWithDefaultOptions = useMemo(() => ([_defaultSection, ...sections]), [sections, _defaultSection]);

  const initialOption = findOption(sectionsWithDefaultOptions, value.option.value);

  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef(null);
  const dropdownRef = useRef(null);
  const popoverRef = useRef(null);

  useClickOutside(() => {
    setIsOptionsVisible(false);
  }, dropdownRef);

  const toggleOptionVisibility = () => setIsOptionsVisible(visibility => !visibility);

  const filteredSections = useMemo(() => {
    if (!isSearchable || !searchText) {
      return sectionsWithDefaultOptions;
    }

    return sectionsWithDefaultOptions.reduce((prev, section) => {
      const newOptions = section.options.filter(option => option.label.includes(searchText) || option.value.includes(searchText));
      if (!newOptions.length) {
        return prev;
      }
      return [...prev, { ...section, options: newOptions }];
    }, []);
  }, [sectionsWithDefaultOptions, isSearchable]);

  const onChangeOption = option => {
    toggleOptionVisibility();
    setSelectedOption(option);
    setSearchText('');
    onChange({ option });
  };

  useEffect(() => {
    if (isOptionsVisible) {
      searchRef?.current?.focus();
    }
  }, [isOptionsVisible]);

  const isThereAnyOption = Object.values(filteredSections).some(({ options }) => options.length > 0);

  return (
    <ScDropdown ref={dropdownRef}>
      <div
        className='rp-dropdown'
        onClick={toggleOptionVisibility}
      >
        <ValueArea
          value={value?.value}
          option={selectedOption}
          onChange={val => onChange({ option: selectedOption, value: val })}
          placeholder={placeholder}
        />
      </div>
      <button
        className='rp-dropdown-icon-wrapper'
        type="button"
        aria-haspopup='listbox'
        aria-expanded={isOptionsVisible}
        aria-label={t('toggle options visibility')}
        onClick={toggleOptionVisibility}
      >
        <IconCaretDown width={24} height={24} />
      </button>
      {
        isOptionsVisible
      && (
      <div className='dropdown-option-wrapper'>
        {
          isSearchable
        && (
        <div>
          <input
            ref={searchRef}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <IconMagnifyingGlass width={16} height={16} />
        </div>
        )
        }
        <Popover
          ref={popoverRef}
          targetRef={dropdownRef}
          modifiers={{
            placement: 'bottom',
            modifiers: [
              { name: 'offset', options: { offset: [0, 5] } }
            ]
          }}
        >
          {
            isThereAnyOption
              ? (
                <OptionGroup
                  onChangeOption={onChangeOption}
                  sections={filteredSections}
                  selectedOption={selectedOption}
                  shouldDivideSections={shouldDivideSections}
                  shouldNavigateWithKeyboard={shouldNavigateWithKeyboard}
                />
              )

              : <div className='no-options-text-wrapper'>{t(noOptionsText)}</div>
          }
        </Popover>
      </div>
      )
      }
    </ScDropdown>
  );
};

MultiSectionSelect.propTypes = {
  sections: propTypes.array,
  onChange: propTypes.func,
  type: propTypes.string,
  value: propTypes.object,
  isNoneAvailable: propTypes.bool,
  isCustomTextAvailable: propTypes.shape({
    limit: propTypes.number
  }),
  shouldDivideSections: propTypes.bool,
  isSearchable: propTypes.bool,
  shouldNavigateWithKeyboard: propTypes.bool,
  defaultSection: propTypes.object,
  onlyRenderOptionGroup: propTypes.bool,
  noOptionsText: propTypes.string,
  placeholder: propTypes.string
};

MultiSectionSelect.defaultProps = {
  sections: [],
  onChange: f => f,
  type: '',
  value: {},
  isNoneAvailable: false,
  isCustomTextAvailable: undefined,
  shouldDivideSections: false,
  isSearchable: false,
  shouldNavigateWithKeyboard: false,
  defaultSection: undefined,
  onlyRenderOptionGroup: false,
  noOptionsText: 'There is no options',
  placeholder: ''
};

export default MultiSectionSelect;
