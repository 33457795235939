import React from 'react';
import { NotFoundList } from '@jotforminc/empty-list';

const NotFoundListPlaceholder = () => ( // eslint-disable-line react/prop-types
  <NotFoundList
    resourceType="form"
  />
);

export default NotFoundListPlaceholder;
