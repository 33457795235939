import { IUser } from '../../types/common';
import * as actionTypes from '../actionTypes';

const initialState = {
  username: ''
};

const userReducer = (state: IUser = initialState, action: actionType) : IUser => {
  switch (action.type) {
    case actionTypes.INIT_USER:
      return action.payload.user;
    default:
      return state;
  }
};

interface payloadType {
  user: IUser
}

interface actionType {
  type: string,
  payload: payloadType
}

export default userReducer;
