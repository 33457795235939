import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../types';

/**
 * Control Chargify gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlChargify: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_chargify: {
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    },
    billingAddress: {
      title: 'Billing Address Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_address'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'formQuestions', operator: 'has', value: 'control_address' }
    },
    customerPhone: {
      title: 'Customer Phone',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_phone'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: { prop: 'formQuestions', operator: 'has', value: 'control_phone' }
    }
  }
};

export default controlChargify;
