import React from 'react';
import type { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';
import { DialogContentProps } from './dialog.types';

export const DialogContent = (props: PropsWithChildren<DialogContentProps>): JSX.Element => {
  const { className, children, ...rest } = props;
  return (
    <div {...rest} className={cx(className, 'mt-8 -mx-8 text-md')}>{children}</div>
  );
};
