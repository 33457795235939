import React from 'react';
import { Button, ModalActions, ModalBody } from '@jotforminc/magnet';
import { SheetItem } from '@jotforminc/resource-picker';
import { t } from '@jotforminc/translation';
import TableSelectionDefaults from './common/TableSelectionDefaults';
import { stepPropTypeDefaults, stepPropTypes } from './constants';
import Dropdown from '../../../Dropdown';

const TabSelection = props => {
  const {
    sharedState: {
      selectedResource, tableTabs, viewID
    }, setSharedState, prevStep, nextStep, viewID: defaultViewID
  } = props;
  const options = tableTabs.map(tab => ({ text: tab.name, value: tab.id }));

  const handleChangeOption = selectedViewID => {
    setSharedState(prev => ({ ...prev, viewID: selectedViewID }));
  };

  const onClickNext = () => {
    setSharedState(prev => ({ ...prev, viewID }));
    nextStep();
  };

  return (
    <>
      <TableSelectionDefaults.Header {...props} />
      <ModalBody className="overflow-visible">
        <div className="selected-sheet">
          <SheetItem
            option={{
              ...selectedResource
            }}
            isSelected
          />
        </div>
        <div className='mt-6 mb-8'>
          <Dropdown
            options={options}
            placeholder={t('Please select a tab')}
            defaultValue={defaultViewID ?? null}
            value={viewID ?? null}
            allowEmpty
            onChange={handleChangeOption}
            popoverProps={{
              popoverOptions: {
                placement: 'bottom-start',
                modifiers: [
                  { name: 'offset', options: { offset: [0, 3] } }
                ]
              }
            }}
          />
        </div>
      </ModalBody>
      <ModalActions>
        <Button
          type='button'
          title={t('Back')}
          colorStyle='secondary'
          onClick={prevStep}
        >
          {t('Back')}
        </Button>
        <Button
          type='button'
          title={t('Next')}
          colorStyle='primary'
          onClick={onClickNext}
          disabled={!viewID}
        >
          {t('Next')}
        </Button>
      </ModalActions>
    </>
  );
};

TabSelection.propTypes = {
  ...stepPropTypes
};

TabSelection.defaultProps = {
  ...stepPropTypeDefaults
};

export default TabSelection;
