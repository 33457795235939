import Styled from 'styled-components';
import { darken } from 'polished';

export const ScChangeStyle = Styled.div`
  button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.125rem;
    font-family: inherit;
    letter-spacing: normal;
    cursor: pointer;
    text-transform: capitalize;
    transition: all .3s ease-in-out;
    color: ${({ itemFontColor }) => itemFontColor};
    ${({ itemBorderColor }) => (itemBorderColor ? `border: 1px solid ${itemBorderColor}` : 'border: none')};
    ${({ itemBgColor, itemBgColorEnd }) => (itemBgColor && itemBgColorEnd) && `background: linear-gradient(${itemBgColor}, ${itemBgColorEnd})`};
    ${({ itemBgColor, itemBgColorEnd }) => (itemBgColor === itemBgColorEnd || !itemBgColorEnd) && `background: ${itemBgColor}`};
    &:focus {
      outline: none;
    }
    &:hover {
      ${({ itemBgColor }) => (itemBgColor) && `background: ${darken(0.1, itemBgColor)}`};
    }
  }
`;
