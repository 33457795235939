import React, { useCallback } from 'react';
import { closest } from '@jotforminc/utils';

const Tabs = ({ children, onSelect } : ITabs) : JSX.Element => {
  const onClickHandler = useCallback((event: React.MouseEvent<EventTarget>): void => {
    const el = event.target as HTMLDivElement;
    const tabItem = closest(el, '.tabItem', '.tabs');
    if (tabItem) {
      onSelect(tabItem.id);
    }
  }, [onSelect]);

  // Added onclick to div due to prevent eslint issue.
  const onKeyPress = useCallback(() => ({}), []);
  return <div className="tabs" onClick={onClickHandler} onKeyPress={onKeyPress}>{children}</div>;
};

interface ITabs {
  children: React.ReactNode,
  onSelect: (tabId: string) => void
}

export default Tabs;
