import React from 'react';
import { node } from 'prop-types';

const TabListContainer = ({ children = null, ...props }) => <div className="jfTabs-row forBody" {...props}><div className="jfTabs-body">{children}</div></div>;

TabListContainer.propTypes = {
  children: node.isRequired
};

export default TabListContainer;
