import React from 'react';
import { func, string } from 'prop-types';
import { classNames, trackDataForMobile } from '@jotforminc/utils';
import {
  WizardBody,
  WizardList,
  WizardItem,
  WizardListItem,
  WizardHeader as Header
} from '@jotforminc/wizard-shared';

import texts from '../../core/texts';
import IconClassicForm from '../../assets/svg/iconClassicForm.svg';
import IconCardForm from '../../assets/svg/iconCardForm.svg';

export default class FormTypeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null
    };
  }

  onCreateForm(formType) {
    const { onClickItem } = this.props;
    this.setState({ isLoading: formType });
    onClickItem(formType);
  }

  render() {
    const {
      setView, className, onBackButtonClick, actionLogger
    } = this.props;

    const {
      isLoading
    } = this.state;

    return (
      <WizardItem noMaxWidth noGutter className={classNames(className, isLoading && 'isLoading')}>
        <Header
          setView={setView}
          actionLogger={actionLogger}
          title={texts.SELECT_LAYOUT}
          subTitle={texts.SELECT_LAYOUT_SUBTITLE}
          onBackButtonClick={onBackButtonClick}
          backButtonVisible
        />
        <div className="jfWizard-gutter withMaxWidth">
          <WizardBody>
            <WizardList>
              <WizardListItem
                data-test-id="cw-create-classic-form"
                className="forClassicForm"
                onClick={() => {
                  this.onCreateForm('ClassicForm');
                  trackDataForMobile('formLayout', 'classic');
                }}
                icon={<IconClassicForm className="jfWizard-list-item-icon-svg" width="187" height="204" />}
                text={texts.CLASSIC_FORM}
                subText={texts.CLASSIC_FORM_SUBTITLE}
                isLoading={isLoading === 'ClassicForm'}
              />
              <WizardListItem
                data-test-id="cw-create-card-form"
                className="forCardForm"
                onClick={() => {
                  this.onCreateForm('CardForm');
                  trackDataForMobile('formLayout', 'card');
                }}
                icon={<IconCardForm className="jfWizard-list-item-icon-svg" width="166" height="146" />}
                text={texts.CARD_FORM}
                subText={texts.CARD_FORM_SUBTITLE}
                isLoading={isLoading === 'CardForm'}
              />
            </WizardList>
          </WizardBody>
        </div>
      </WizardItem>
    );
  }
}

FormTypeSelect.propTypes = {
  onClickItem: func,
  setView: func,
  actionLogger: func,
  onBackButtonClick: func,
  className: string
};

FormTypeSelect.defaultProps = {
  actionLogger: f => f,
  onClickItem: f => f,
  setView: f => f,
  onBackButtonClick: null,
  className: null
};
