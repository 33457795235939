import * as actionTypes from './actionTypes';
import { TPid } from '../types/common';
import * as actionCreatorTypes from '../types/actionCreatorTypes';
// import { fixAllProductsData } from '../utils';

export const initProducts: actionCreatorTypes.FTInitSellProducts = products => ({ type: actionTypes.INIT_PRODUCTS, payload: { products } });
export const createProduct: actionCreatorTypes.FTCreateSellProduct = product => ({ type: actionTypes.CREATE_PRODUCT, payload: { product } });
export const updateProduct: actionCreatorTypes.FTUpdateSellProduct = (data, pid) => ({ type: actionTypes.UPDATE_PRODUCT, payload: { data, pid } });
export const removeProduct: actionCreatorTypes.FTRemoveSellProduct = pid => ({ type: actionTypes.REMOVE_PRODUCT, payload: { pid } });
export const sortProducts: actionCreatorTypes.FTSortProducts = (oldPid, newPid) => ({ type: actionTypes.SORT_PRODUCTS, payload: { oldPid, newPid } });

/* eslint-disable max-len */
export const updateProductListSettings: actionCreatorTypes.FTUpdateProductListSettings = productListSettingsItem => ({ type: actionTypes.UPDATE_PRODUCT_LIST_SETTINGS, payload: { productListSettingsItem } });
/* eslint-enable max-len */

export const initSubscriptions: actionCreatorTypes.FTInitSubscriptions = subscriptions => ({ type: actionTypes.INIT_SUBSCRIPTIONS, payload: { subscriptions } });
export const createSubscription: actionCreatorTypes.FTCreateSubscriptionProduct = subscription => ({ type: actionTypes.CREATE_SUBSCRIPTION, payload: { subscription } });
export const updateSubscription: actionCreatorTypes.FTUpdateSubscriptionProduct = (prop, value, pid) => ({ type: actionTypes.UPDATE_PRODUCT, payload: { prop, value, pid } });
export const removeSubscription: actionCreatorTypes.FTRemoveSubscriptionProduct = (pid: TPid) => ({ type: actionTypes.REMOVE_SUBSCRIPTION, payload: { pid } });

export const initUi: actionCreatorTypes.FTUiInitValue = value => ({ type: actionTypes.INIT_UI, payload: { ...value } });
export const changeActivePanel: actionCreatorTypes.FTUiChangeActivePanel = value => ({ type: actionTypes.CHANGE_ACTIVE_PANEL, payload: { value } });
export const changeActiveAPMSelection: actionCreatorTypes.FTUiChangeActiveAPMSelection = value => ({ type: actionTypes.CHANGE_ACTIVE_APM_SELECTION, payload: { value } });
export const changeActiveEditor: actionCreatorTypes.FTUiChangeActiveEditor = value => ({ type: actionTypes.CHANGE_ACTIVE_EDITOR, payload: { value } });
export const changeActiveTab: actionCreatorTypes.FTUiChangeActiveTab = value => ({ type: actionTypes.CHANGE_ACTIVE_TAB, payload: { value } });
export const changeGatewaySettingsVisibility: actionCreatorTypes.FTUiChangeGatewaySettingsVisibility = value => ({ type: actionTypes.CHANGE_GATEWAY_SETTINGS_VISIBILIY, payload: { value } });
export const changeGatewaySettingsView: actionCreatorTypes.FTUiChangeGatewaySettingsView = value => ({ type: actionTypes.CHANGE_GATEWAY_SETTINGS_VIEW, payload: { value } });
export const updateTemporaryActiveGatewaySettings: actionCreatorTypes.FTupdateTemporaryActiveGatewaySettings = settings => ({
  type: actionTypes.UPDATE_TEMPORARY_ACTIVE_GATEWAY_SETTINGS, payload: { value: settings }
});
export const changeSelectedOption: actionCreatorTypes.FTUiChangeSelectedOption = value => ({ type: actionTypes.CHANGE_SELECTED_OPTION, payload: { value } });

export const changeSelectedGatewayWithAPM: actionCreatorTypes.FTUiChangeSelectedGatewayWithAPM = value => ({ type: actionTypes.CHANGE_SELECTED_GATEWAY_WITH_APM, payload: { value } });

export const changeConnectionModalPropValues: actionCreatorTypes.FTUiChangeConnectionModalPropValues = value => ({ type: actionTypes.CHANGE_CONNECTION_MODAL_PROP_VALUES, payload: { value } });

export const setActiveProduct: actionCreatorTypes.FTSetActiveProduct = (value, silent = false) => ({ type: actionTypes.SET_ACTIVE_PRODUCT, payload: { value }, silent });
export const updateActiveProduct: actionCreatorTypes.FTUpdateActiveProduct = data => ({ type: actionTypes.UPDATE_ACTIVE_PRODUCT, payload: { data } });

export const initUser: actionCreatorTypes.FTInitUser = user => ({ type: actionTypes.INIT_USER, payload: { user } });

export const setActiveConnection: actionCreatorTypes.FTsetActiveConnection = connection => ({ type: actionTypes.SET_ACTIVE_CONNECTION, payload: connection });

export const initActiveGatewaySettings: actionCreatorTypes.FTinitActiveGatewaySettings = settings => ({ type: actionTypes.INIT_ACTIVE_GATEWAY_SETTINGS, payload: { settings } });
export const setActiveGatewaySettings: actionCreatorTypes.FTsetActiveGatewaySettings = settings => ({ type: actionTypes.SET_ACTIVE_GATEWAY_SETTINGS, payload: { settings } });
export const updateActiveGatewaySettings: actionCreatorTypes.FTupdateActiveGatewaySettings = settingsItem => ({ type: actionTypes.UPDATE_ACTIVE_GATEWAY_SETTINGS, payload: { settingsItem } });

export const initCheckoutFormQuestions: actionCreatorTypes.FTinitCheckoutFormQuestions = questions => ({ type: actionTypes.INIT_CHECKOUT_FORM_QUESTIONS, payload: questions });
export const updateCheckoutFormQuestionVisibility: actionCreatorTypes.FTupdateCheckoutFormQuestionVisibility = payload => ({ type: actionTypes.UPDATE_CHECKOUT_FORM_QUESTION_VISIBILITY, payload });
