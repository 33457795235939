import { StorageHelper } from '@jotforminc/storage-helper';
import Moment from 'moment';
import { NOTIFICATION_DISMISS_KEY } from '../../../constants';

export const isPushPermissionRecentlyDismissed = appID => {
  const notificationDismissKey = `${NOTIFICATION_DISMISS_KEY}_${appID}`;
  const dismissedAt = StorageHelper.getLocalStorageItem({ key: notificationDismissKey });
  if (dismissedAt === null) return false;
  return Moment(dismissedAt).isAfter(Moment().subtract(1, 'month'));
};
