import React, {
  useCallback, useRef, useState, useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, func, string } from 'prop-types';
import Styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { t } from '@jotforminc/translation';
import { IconXmark } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import SELECTORS from '../../../../store/selectors';
import * as ACTIONS from '../../../../store/actionCreators';
import AppModal from '../../../../components/AppModal';
import SearchInProductsContent from './SearchInProductsContent';
import { useFilteredProducts } from '../../../../utils/hooks';
import { checkMobilePhone } from '../../../../utils';
import { DESTINATION_TYPES } from '../../../../constants/navigation';

const ScSearchModalOverlay = Styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background-color: rgba(39, 41, 55, 0.61);
  display: flex;
  flex-direction: column;
  align-items: center;

  div[data-uikit-modal-container="true"] {
    width: 92%;
    max-width: 674px;
    max-height: 90%;
    min-width: 335px;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: auto;
    overflow: auto;
    padding: 28px 34px 20px;
  }
`;

const ScSearchInProductsModal = Styled.div`
  .mobileCtxMenu {
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #29355C80;
    overflow: hidden;
    opacity: 0;
  }

  .mobileCtxMenu-enter {
    opacity: 0;
    transition: all 400ms;
  }

  .mobileCtxMenu-enter-active {
    opacity: 1;
  }

  .mobileCtxMenu-enter-done {
    opacity: 1;
  }

  .mobileCtxMenu-exit {
    opacity: 1;
    transition: all 400ms;
  }
  .mobileCtxMenu-exit-active {
    opacity: 0;
  }
`;

export const ScMobileSearch = Styled.div`
  position: absolute;
  bottom: 0;
  background-color: #fefefe;
  padding: 16px 16px 36px 16px;
  width: 100%;
  border-radius: 16px 16px 0 0;
  transform: translateY(20px);
  max-height: 90vh;

  .mobileCtxMenu-enter & {
    animation: rebound .4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transform: translateY(20px);

    @keyframes rebound {
      0%{
        transform: translateY(100%);
      }
      100%{
        transform: translateY(20px);
      }
    }
  }

  .mobileCtxMenu-exit-active & {
    transition: .4s ease-in-out 0s;
    transform: translateY(100%);
  }

  .mobileCtxMenuHeader {
    position: relative;
    margin-bottom: 20px;
    color: #0A1551;
    font-size: 18px;
    font-weight: 700;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  `;

const SearchInProductsModal = ({ onClose }) => {
  const ref = useRef();
  const isMobile = checkMobilePhone();
  const dispatch = useDispatch();

  const filteredProductListProps = useSelector(SELECTORS.getFilteredProducts);
  const productLists = useSelector(SELECTORS.getProductListItems);
  const activePageID = useSelector(SELECTORS.getActivePageID);

  const { searchQuery: initialSearchQuery } = filteredProductListProps;

  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);

  const filteredProductsLists = useFilteredProducts(searchQuery, productLists);

  const onNavigateToProductListPage = foundItemID => {
    const { page } = productLists.find(({ id }) => id === foundItemID);
    if (page === undefined || page === activePageID) return;
    dispatch(ACTIONS.navigateToAction({ to: DESTINATION_TYPES.PAGE, pageID: page }));
  };

  const handleSaveFilteredProducts = (itemID, filteredProducts) => {
    dispatch(ACTIONS.onFilterProductsAction({ itemID, filteredProducts, searchQuery }));
    onNavigateToProductListPage(itemID);
    onClose();
  };

  const DesktopDialogRenderer = useCallback(({ children }) => (
    <ScSearchModalOverlay>{children}</ScSearchModalOverlay>
  ), []);

  const MobileDialogRenderer = ({ children }) => ( // eslint-disable-line react/prop-types
    <div className="mobileCtxMenu">
      {children}
    </div>
  );

  const DialogRenderer = useCallback(props => {
    return isMobile ? <MobileDialogRenderer {...props} /> : <DesktopDialogRenderer {...props} />;
  }, [isMobile]);

  const ContentWrapper = useCallback(({ children }) => {
    return isMobile ? (
      <>
        <ScMobileSearch>
          <div className="mobileCtxMenuHeader">
            <div>{t('Search')}</div>
            <Button
              rounded
              startIcon={IconXmark}
              colorStyle='secondary'
              size='small'
              onClick={onClose}
            />
          </div>
          {children}
        </ScMobileSearch>
      </>
    ) : (<>{children}</>);
  }, []);

  const ModalWrapper = useCallback(({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      setIsOpen(true);
    }, []);

    return isMobile ? (
      <CSSTransition
        unmountOnExit={true}
        timeout={400}
        in={isOpen}
        appear={true}
        classNames="mobileCtxMenu"
      >
        {children}
      </CSSTransition>
    ) : (
      <>{children}</>
    );
  }, []);

  const HeaderRenderer = () => null;

  return (
    <ScSearchInProductsModal>
      <ModalWrapper>
        <AppModal
          defaultVisible={true}
          DialogRenderer={DialogRenderer}
          HeaderRenderer={HeaderRenderer}
          onModalClose={onClose}
          ref={ref}
        >
          <ContentWrapper>
            <SearchInProductsContent
              onSearchQueryChange={setSearchQuery}
              filteredProductsLists={filteredProductsLists}
              onSaveFilteredProducts={handleSaveFilteredProducts}
              searchQuery={searchQuery}
            />
          </ContentWrapper>
        </AppModal>
      </ModalWrapper>
    </ScSearchInProductsModal>
  );
};

SearchInProductsModal.propTypes = {
  onClose: func,
  productLists: array,
  onSaveFilteredProducts: func,
  searchQuery: string,
  onNavigateToProductListPage: func
};

SearchInProductsModal.defaultProps = {
  onClose: f => f,
  productLists: [],
  onSaveFilteredProducts: f => f,
  searchQuery: '',
  onNavigateToProductListPage: f => f
};

export default SearchInProductsModal;
