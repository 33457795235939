import { checkMacOSx } from '../../../utils';

export const getUndoRedoBindings = () => {
  const isMacOSx = checkMacOSx();
  const metaKey = isMacOSx ? 'command' : 'ctrl';
  return [
    `${metaKey} + z`,
    isMacOSx ? `${metaKey} + shift + z` : `${metaKey} + y`
  ];
};
