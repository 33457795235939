import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Moment from './moment';

export const useKeyboardEvent = (keyArray, callback) => {
  useEffect(() => {
    const handler = e => {
      const { key = '', keyCode } = e;
      if ((key && keyArray.indexOf(key) > -1) || (keyArray.indexOf(keyCode) > -1)) callback();
    };
    global.window.addEventListener('keydown', handler);
    return () => global.window.removeEventListener('keydown', handler);
  }, []);
};

export const useMomentDate = (date, format) => {
  const [momentDate, setMomentDate] = useState(Moment.dateToMoment(date, format));
  const renewMomentDate = () => {
    setMomentDate(Moment.dateToMoment(date, format));
  };

  useEffect(() => {
    const listenerKey = `renewMomentDate_${uuid()}`;
    Moment.addListener('setLocale', listenerKey, renewMomentDate);
    return () => Moment.removeListener('setLocale', listenerKey);
  }, []);

  useEffect(() => {
    renewMomentDate();
  }, [date, format]);

  return [momentDate, setMomentDate];
};
