import React from 'react';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';

const BaseFooter = ({
  saveText,
  backText,
  handleSave,
  handleBack,
  showSaveBtn,
  showBackBtn,
  disabled
}: IProps): JSX.Element => {
  const BackButton = () => (
    <Button
      colorStyle='secondary'
      variant='outline'
      className='text-capitalize'
      onClick={handleBack}
    >
      {t(backText)}
    </Button>
  );

  const SaveButton = () => (
    <Button
      className='text-capitalize'
      onClick={handleSave}
      disabled={disabled}
    >
      {t(saveText)}
    </Button>
  );

  return (
    <div className="gatewaySettingsViewFooter">
      {showBackBtn && <BackButton />}
      {showSaveBtn && <SaveButton />}
    </div>
  );
};

interface IProps {
  saveText: 'Save' | 'Continue'
  backText: 'Back'
  handleSave: () => void
  handleBack: () => void
  showSaveBtn?: boolean
  showBackBtn?: boolean
  disabled: boolean
}

BaseFooter.defaultProps = {
  showSaveBtn: true,
  showBackBtn: true
};

export default BaseFooter;
