import React, {
  useState
} from 'react';
import { IGatewaySettings, TConnectionStatus, TFormId } from '../../../types/common';
import ConnectionButtonStripe from './ConnectionButtonStripe';
import ConnectionButtonSquare from './ConnectionButtonSquare';
import ConnectionButtonPaypal from './ConnectionButtonPaypal';
import useGatewayFlows from '../../modals/Flows/Context/useGatewayFlows';
import { OAUTH_SUPPORTED_PAYPAL_TYPES } from '../../../constants/variables';
import ConnectionButtonMollie from './ConnectionButtonMollie';

const ConnectionButton = ({
  settings,
  checkoutFormId
} : IProps) : JSX.Element => {
  const { currentlyGateway, currentlyPaymentType, getParentGatewayType } = useGatewayFlows();
  const isOauthPaypal = OAUTH_SUPPORTED_PAYPAL_TYPES.includes(currentlyGateway);
  const [connectionStatus, setConnectionStatus] = useState<TConnectionStatus>(isOauthPaypal ? 'initializing' : 'notConnected');

  const renderConnectionButton = () => {
    const separatedStripeTypes = ['control_stripe', 'control_stripeCheckout', 'control_stripeACHManual'];
    const gatewayParentType = getParentGatewayType();

    if (separatedStripeTypes.includes(currentlyGateway)) {
      return (
        <ConnectionButtonStripe
          settings={settings}
          checkoutFormID={checkoutFormId}
          gatewayType={currentlyGateway}
          connectionStatus={connectionStatus}
          setConnectionStatus={setConnectionStatus}
        />
      );
    }

    if (currentlyGateway === 'control_square' || gatewayParentType === 'control_square') {
      return (
        <ConnectionButtonSquare
          settings={settings}
          gatewayType={gatewayParentType ?? currentlyGateway}
          connectionStatus={connectionStatus}
          setConnectionStatus={setConnectionStatus}
          checkoutFormId={checkoutFormId}
        />
      );
    }

    if (currentlyGateway === 'control_mollie') {
      return (
        <ConnectionButtonMollie
          settings={settings}
          gatewayType={currentlyGateway}
          checkoutFormId={checkoutFormId}
          connectionStatus={connectionStatus}
          setConnectionStatus={setConnectionStatus}
        />
      );
    }

    if (isOauthPaypal || gatewayParentType === 'control_paypalcomplete') {
      return (
        <ConnectionButtonPaypal
          settings={settings}
          gatewayType={gatewayParentType ?? currentlyGateway}
          checkoutFormId={checkoutFormId}
          connectionStatus={connectionStatus}
          setConnectionStatus={setConnectionStatus}
          paymentType={currentlyPaymentType}
        />
      );
    }

    return null;
  };

  return (
    <>
      { renderConnectionButton() }
    </>
  );
};
export interface IProps {
  settings: IGatewaySettings,
  checkoutFormId: TFormId
}

export default ConnectionButton;
