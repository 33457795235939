import {
  FETCH_USER, UPDATE_USER, SET_USER_SLUG, CONTINUE_AS_USER
} from '../actionTypes';

const initialState = {
  ...(window?.__userInfo?.credentials || {}),
  USER_TYPE: window?.__userInfo?.type || '',
  slug: false,
  paymentOverQuotaWarnings: window?.__userInfo?.paymentOverQuotaWarnings || {},
  showContinueAs: window?.__showContinueAs
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER.SUCCESS: {
      const userTypes = { FORM_USER: 'formuser', USER: 'user' };
      const userType = action.payload.type && userTypes[action.payload.type];

      return {
        ...action.payload.credentials,
        USER_TYPE: action.payload.type,
        ...userType ? { userType } : {},
        showContinueAs: state.showContinueAs
      };
    }
    case UPDATE_USER.SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_USER_SLUG: {
      const { slug } = action;
      return {
        ...state,
        slug
      };
    }
    case CONTINUE_AS_USER.SUCCESS: {
      return {
        ...state,
        showContinueAs: false
      };
    }
    default:
      return state;
  }
};

export default userReducer;
