export const fonts = [
  { category: 'system', name: 'Circular' },
  { category: 'google', name: 'Bevan' },
  { category: 'google', name: 'Bitter' },
  { category: 'google', name: 'Diplomata' },
  { category: 'google', name: 'Galada' },
  { category: 'google', name: 'Lobster' },
  { category: 'google', name: 'Montserrat' },
  { category: 'google', name: 'Open Sans' },
  { category: 'google', name: 'PT Serif' },
  { category: 'google', name: 'Playball' },
  { category: 'google', name: 'Roboto' },
  { category: 'google', name: 'Sail' },
  { category: 'system', name: 'Arial' },
  { category: 'system', name: 'Arial Black' },
  { category: 'system', name: 'Comic Sans MS' },
  { category: 'system', name: 'Courier New' },
  { category: 'system', name: 'Georgia' },
  { category: 'system', name: 'Helvetica' },
  { category: 'system', name: 'Impact' },
  { category: 'system', name: 'Tahoma' },
  { category: 'system', name: 'Times New Roman' },
  { category: 'system', name: 'Trebuchet MS' },
  { category: 'system', name: 'Verdana' }
];

export const systemFontAlternativeMap = {
  'Arial Black': { googleAlt: 'Archivo Black', map: '"Arial Black", "Archivo Black"' },
  'Comic Sans MS': { googleAlt: 'Chilanka', map: '"Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", "Chilanka"' },
  Impact: { googleAlt: 'Anton', map: '"Impact", "Anton"' },
  'Trebuchet MS': { googleAlt: 'Source Sans Pro', map: '"Trebuchet MS", "Source Sans Pro"' },
  Verdana: { googleAlt: 'Arimo', map: '"Verdana", "Arimo"' }
};
