import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { handleCustomNavigation } from '@jotforminc/utils';
import { Hooks } from '@jotforminc/uikit';
import { T_QUESTION_PROPERTIES } from '../../modals/Flows/Types/GatewayTypes';
import { getJWTToken, getStripeConnectCode } from '../../../api';
import { loadXDSScript } from '../../../utils/general';
import { getUpdatingSettingsFromResponse } from './utils';
import BaseConnectionButton from './BaseConnectionButton';
import useGatewayFlows from '../../modals/Flows/Context/useGatewayFlows';
import { TConnectionStatus } from '../../../types/common';
import { checkIsGatewayConnected } from '../../../utils';

const ConnectionButtonStripe = ({
  settings,
  checkoutFormID,
  gatewayType,
  connectionStatus,
  setConnectionStatus
}: IProps): JSX.Element => {
  const {
    changedSettings,
    setChangedSettings,
    user: { username },
    handleDisconnectGateway
  } = useGatewayFlows();

  Hooks.useEffectIgnoreFirst(() => {
    if (connectionStatus === 'connected') {
      handleDisconnectGateway(gatewayType);
    }
  }, [settings.allowTest]);

  useEffect(() => {
    if (checkIsGatewayConnected({ ...settings, type: gatewayType })) {
      setConnectionStatus('connected');
    } else {
      setConnectionStatus('notConnected');
    }
  }, [settings]);

  const allowTest = () => {
    const acceptedValues = ['true', true, 1, '1', 'Yes', 'Enabled'];
    if (typeof settings.allowTest !== 'undefined') {
      if (acceptedValues.includes(settings.allowTest)) {
        return 'Yes';
      }
      return 'No';
    }
    return acceptedValues.includes(settings.allowTest) ? 'Yes' : 'No';
  };

  const stripeConnectLoop = () => {
    let stripeCode: any = null;
    /*eslint-disable*/
    let x = new jotFormxds();
    /* eslint-enable */
    x.getItem('stripeCode', val => {
      stripeCode = val;
    });

    const innerTimer = setInterval(() => {
      clearInterval(innerTimer);
      if (stripeCode !== null) {
        getStripeConnectCode(stripeCode, allowTest(), checkoutFormID, gatewayType, true)
          .then(resp => {
            const settingsToBeUpdated = getUpdatingSettingsFromResponse(resp, gatewayType);
            if (!Object.keys(settingsToBeUpdated).length) return;
            const { connectionID } = resp;
            setChangedSettings({ ...changedSettings, ...settingsToBeUpdated, connectionID });
            setConnectionStatus(typeof connectionID !== 'undefined' ? 'connected' : 'notConnected');
          }).catch(() => {
            window.localStorage.removeItem('stripeCode');
            x.removeItem('stripeCode');
            setConnectionStatus('notConnected');
          });
      } else {
        setConnectionStatus('notConnected');
      }
    }, 1000);
  };

  const connectStripe = async () => {
    setConnectionStatus('connecting');
    const clientId = allowTest() === 'Yes' ? 'ca_1eOr9d8rS4JHOxiabxdL7qcTv88FrAZk' : 'ca_1eOrq5RMioZTllO0kuM79LbzT5vxIL7D';
    let urlStripe = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&stripe_landing=login&client_id=${clientId}`;

    const JWTToken = await getJWTToken(checkoutFormID, username);

    if (!JWTToken) {
      return setConnectionStatus('notConnected');
    }
    urlStripe += `&state=${JWTToken}`;

    window.localStorage.removeItem('stripeCode');

    const windowFeatures = `toolbar=yes,scrollbars=yes,resizable=yes,top=${(window.innerHeight / 2) - 400},left=${(window.innerWidth / 2) - 400},width=800,height=800`;
    const win = handleCustomNavigation(urlStripe, 'paymentWindow', false, windowFeatures);

    const timer = setInterval(() => {
      if (win && win.closed) {
        clearInterval(timer);

        if (typeof jotFormxds === 'undefined') {
          loadXDSScript(() => {
            stripeConnectLoop();
          });
        } else {
          stripeConnectLoop();
        }
      }
    }, 500);
  };

  return (
    <BaseConnectionButton
      settings={settings}
      connect={connectStripe}
      connectionStatus={connectionStatus}
    />
  );
};

export interface IProps {
  settings: T_QUESTION_PROPERTIES;
  checkoutFormID: string;
  gatewayType: string | null;
  connectionStatus: TConnectionStatus;
  setConnectionStatus: Dispatch<SetStateAction<TConnectionStatus>>
}

export default ConnectionButtonStripe;
