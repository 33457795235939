import React from 'react';
import {
  arrayOf, node, oneOfType, string, bool
} from 'prop-types';
import { IconMagnifyingGlass } from '@jotforminc/svg-icons';

import EmptyListBase from './EmptyListBase';

import NoFormsFoundIcon from '../assets/svg/noFormsFoundIcon.svg';
import NoAppsFoundIcon from '../assets/svg/noAppsFoundIcon.svg';
import NoTablessFoundIcon from '../assets/svg/noTablesFoundIcon.svg';
import NoReportsFoundIcon from '../assets/svg/noReportsFoundIcon.svg';
import NoApprovalsFoundIcon from '../assets/svg/noApprovalsFoundIcon.svg';
import NoSignDocumentsFoundIcon from '../assets/svg/noSignDocumentsFoundIcon.svg';

export const resourceData = {
  item: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: (
      <div className="radius-full w-18 h-18 bg-navy-75 inline-flex items-center justify-center">
        <IconMagnifyingGlass className="w-10 h-10 fill-navy-300" />
      </div>
    )
  },
  form: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: <NoFormsFoundIcon className="w-28" />
  },
  portal: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: <NoAppsFoundIcon className="w-28" />
  },
  table: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: <NoTablessFoundIcon className="w-28" />
  },
  report: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: <NoReportsFoundIcon className="w-28" />
  },
  approval: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: <NoApprovalsFoundIcon className="w-28" />
  },
  sign: {
    title: 'No results found.',
    description: 'We couldn\'t find any matching items.',
    icon: <NoSignDocumentsFoundIcon className="w-28" />
  }
};

function NotFoundList({
  icon,
  title,
  description,
  resourceType,
  isTitleUppercase
}) {
  const defaults = (resourceType && resourceData[resourceType]) ? resourceData[resourceType] : resourceData.form;
  const uppercase = isTitleUppercase !== null ? isTitleUppercase : false;

  return (
    <EmptyListBase
      title={title || defaults.title}
      description={description || defaults.description}
      icon={icon || defaults.icon}
      isTitleUppercase={uppercase}
    />
  );
}

NotFoundList.propTypes = {
  icon: oneOfType([arrayOf(node), node]),
  title: string,
  description: string,
  resourceType: string,
  isTitleUppercase: bool
};

NotFoundList.defaultProps = {
  icon: null,
  title: null,
  description: null,
  resourceType: 'form',
  isTitleUppercase: null
};

export default NotFoundList;
