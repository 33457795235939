import React from 'react';
import {
  bool, elementType, shape, string
} from 'prop-types';
import { ScAppLogo } from './scLogo';
import AppImage from '../AppImage';

const AppLogo = ({
  Wrapper,
  withBorder,
  title,
  logoProperties
}) => {
  const {
    logoType, logoBackground, logoURL, logoSvgRef, iconColor
  } = logoProperties;

  const alt = `${title} Logo`;
  return (
    <AppImage
      type={logoType}
      bgColor={logoBackground}
      bgURL={logoURL}
      iconColor={iconColor}
      svgRef={logoSvgRef}
      alt={alt}
      Wrapper={Wrapper}
      withBorder={withBorder}
    />
  );
};

AppLogo.propTypes = {
  Wrapper: elementType,
  title: string,
  logoProperties: shape({}),
  withBorder: bool
};

AppLogo.defaultProps = {
  Wrapper: ScAppLogo,
  title: '',
  logoProperties: {},
  withBorder: false
};

export default AppLogo;
