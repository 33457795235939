import type {
  T_CONDITIONS,
  FORM_QUESTION_TYPES,
  GATEWAY_QUESTION_PROPERTIES,
  FORM_TYPES
} from '@jotforminc/payment-constants';
import { isPlainObject } from './isPlainObject';
import { checkResourcePropertyCondition } from './checkResourcePropertyCondition';
/**
 * Checks if resource property required
 * @returns {boolean}
 */

export const isResourcePropertyRequired = (
  requiredCondition: T_CONDITIONS,
  isEnterprise: boolean,
  formType: FORM_TYPES,
  formQuestionTypes: FORM_QUESTION_TYPES[],
  gatewayProperties: GATEWAY_QUESTION_PROPERTIES
): boolean => {
  if (typeof requiredCondition === 'undefined') { return false; }
  if (typeof requiredCondition === 'boolean') { return requiredCondition; }

  if (isPlainObject(requiredCondition)) {
    return checkResourcePropertyCondition(
      requiredCondition,
      isEnterprise,
      formType,
      formQuestionTypes,
      gatewayProperties
    );
  }

  return false;
};
