import React from 'react';
import {
  IconTrashFilled,
  IconEyeFilled,
  IconEyeSlashFilled,
  IconPencilFilled
} from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import {
  bool,
  func
} from 'prop-types';
import { Button } from '@jotforminc/magnet';
import { ScPageSettingsPopover } from '../../../../styles/ScPageSettingsPopover';

const PageSettingsPopover = ({
  onFocusTextInput, toggleVisibility, handleDeletePage, showPageOnNavigation
}) => {
  const renderText = value => {
    return <h5 className='popover-settings-item-text'>{t(value)}</h5>;
  };

  return (
    <ScPageSettingsPopover>
      <Button
        className='popover-settings-item'
        onClick={toggleVisibility}
        startIcon={showPageOnNavigation ? IconEyeSlashFilled : IconEyeFilled}
      >
        {showPageOnNavigation ? renderText('Hide Page') : renderText('Show Page')}
      </Button>

      <span className='settings-item-divider' />

      <Button
        className='popover-settings-item'
        onClick={onFocusTextInput}
        startIcon={IconPencilFilled}
      >
        {renderText('Rename')}
      </Button>

      <span className='settings-item-divider' />

      <Button
        className='popover-settings-item forDelete'
        onClick={handleDeletePage}
        startIcon={IconTrashFilled}
      >
        {renderText('Delete')}
      </Button>
    </ScPageSettingsPopover>
  );
};

PageSettingsPopover.propTypes = {
  showPageOnNavigation: bool,
  onFocusTextInput: func,
  toggleVisibility: func,
  handleDeletePage: func
};

PageSettingsPopover.defaultProps = {
  showPageOnNavigation: true,
  onFocusTextInput: f => f,
  toggleVisibility: f => f,
  handleDeletePage: f => f
};

export default PageSettingsPopover;
