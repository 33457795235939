/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, memo } from 'react';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { useSelector } from 'react-redux';
import useGatewayFlows from '../Context/useGatewayFlows';
import { I_MODAL_FOOTER } from '../Types';
import { GATEWAY_PROPERTIES, GATEWAYS_PREDEFINED_TEST } from '../Constants/GatewayProperties';
import { deleteConnectionToReusable } from '../../../../api';
import { checkIsGatewayConnected, isYes, sendTrackData } from '../../../../utils';
import selectors from '../../../../store/selectors';

const Footer: FunctionComponent<I_MODAL_FOOTER> = (footerProps: I_MODAL_FOOTER) => {
  // const { enableBackButton, enableNextButton, nextButtonDisabledCondition } = footerProps;
  const {
    closeModal,
    currentlyStep,
    currentlyPaymentType,
    currentlyGateway,
    addPaymentType,
    nextStep,
    backStep,
    resourceType,
    changedSettings,
    defaultFlowType,
    onSaveGatewaySettings,
    gatewayQuestionProps,
    getParentGatewayType,
    staticSettings,
    formId,
    isMaskedCredentialSaved
  } = useGatewayFlows();

  const username = useSelector(selectors.getUsername);

  const leftSide = () => {
    switch (currentlyStep) {
      case 'select-gatewaySettings':
        return (
          <Button
            colorStyle='secondary'
            onClick={backStep}
          >
            {t('Back')}
          </Button>
        );
      case 'select-gateway':
      case 'select-paymentType':
        return (
          <Button
            colorStyle='secondary'
            onClick={() => {
              sendTrackData(username, 'new_payment_modal_cancel_button_clicked', { resourceType, formId });
              closeModal();
            }}
          >
            {t('Cancel')}
          </Button>
        );
      case 'select-nameapmType':
        if (defaultFlowType === 'GATEWAY_TO_PAYMENT_TYPE') {
          return null;
        }

        return (
          <Button
            colorStyle='secondary'
            onClick={backStep}
          >
            {t('Back')}
          </Button>
        );
      case 'discard-changes':
        return (
          <Button
            colorStyle='secondary'
            variant='outline'
            onClick={backStep}
          >
            {t('No, Keep')}
          </Button>
        );
      default:
        return null;
    }
  };

  /* eslint-disable complexity */
  const rightSide = () => {
    switch (currentlyStep) {
      case 'select-paymentType':
        return (
          <Button
            colorStyle='success'
            data-test-id="select-paymentType-addElementButton"
            disabled={!currentlyPaymentType}
            onClick={addPaymentType}
          >
            {resourceType === 'FORM' && t('Add to Form')}
            {resourceType === 'APP' && t('Add to App')}
          </Button>
        );
      case 'select-gatewaySettings':
        const noChangedSettings = defaultFlowType === 'EDIT_GATEWAY' && Object.keys(changedSettings).length === 0;
        // if any of connection credential is empty
        const gatewayProperties = GATEWAY_PROPERTIES.find(gateway => gateway.type === currentlyGateway);
        const connectionSettings = gatewayProperties && gatewayProperties.connectionSettings;
        const props = { ...(defaultFlowType === 'EDIT_GATEWAY' && gatewayQuestionProps), ...changedSettings };

        const modeSettingsKey = connectionSettings && connectionSettings.modeSettings && connectionSettings.modeSettings.key;
        const isTest = modeSettingsKey && isYes(props[modeSettingsKey]);
        const isCredentialsPredefined = GATEWAYS_PREDEFINED_TEST.find(gateway => gateway === currentlyGateway) && isTest;

        const isCredentialEmpty = isCredentialsPredefined ? false : !checkIsGatewayConnected({ ...staticSettings, ...props, type: getParentGatewayType() });

        const _isMaskedCredentialSaved = isMaskedCredentialSaved && gatewayQuestionProps.type === getParentGatewayType();
        const disabled = (noChangedSettings || isCredentialEmpty) && !_isMaskedCredentialSaved;

        return (
          <Button
            colorStyle='success'
            data-test-id="select-gatewaySettings-saveButton"
            disabled={disabled}
            title={disabled ? t('To save you need to connect the gateway') : ''}
            onClick={() => {
              onSaveGatewaySettings();
              closeModal();
              const type = getParentGatewayType();
              sendTrackData(username, 'new_payment_modal_save_clicked', { resourceType, formId, gateway: type });
            }}
          >
            {t('Save')}
          </Button>
        );
      case 'select-gateway':
        return (
          <Button
            data-test-id="select-gateway-continueButton"
            disabled={!currentlyGateway}
            onClick={() => {
              nextStep();
              const type = getParentGatewayType();
              sendTrackData(username, 'new_payment_modal_gateway_continue_clicked', { resourceType, formId, gateway: type });
            }}
          >
            {t('Continue')}
          </Button>
        );
      case 'select-nameapmType':
        const isDisabled = !currentlyGateway || currentlyGateway === 'control_appleAndGooglePay';
        return (
          <Button
            data-test-id="select-nameapmType-continueButton"
            disabled={isDisabled}
            onClick={() => {
              nextStep();
              sendTrackData(username, 'new_payment_modal_nameapm_gateway_continue_clicked', { resourceType, formId });
            }}
          >
            {t('Continue')}
          </Button>
        );
      case 'discard-changes':
        return (
          <Button
            colorStyle='error'
            data-test-id="discard-changes-discardChangesButton"
            onClick={() => {
              nextStep();
              const type = getParentGatewayType();
              sendTrackData(username, 'new_payment_modal_discard_changes_clicked', { resourceType, formId, gateway: type });
              // Will be delete maybe
              // eslint-disable-next-line max-len
              if (['control_stripe', 'control_stripeCheckout', 'control_stripeACHManual', 'control_mollie', 'control_square'].includes(type) && typeof changedSettings.connectionID !== 'undefined') {
                deleteConnectionToReusable(changedSettings?.connectionID);
              }
            }}
          >
            {t('Yes, Discard Changes')}
          </Button>
        );
      default:
        return null;
    }
  };
  /* eslint-enable complexity */

  return (
    <div className='w-full border-t border-navy-50 py-4 px-8 bg-navy-25 sticky bottom-0 z-2'>
      <div className='w-full flex justify-between items-center'>
        {leftSide()}
        {rightSide()}
      </div>
    </div>
  );
};

export default memo(Footer);
