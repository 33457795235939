import { call, select } from 'redux-saga/effects';

import SELECTORS from '../selectors';
import { getAsset } from '../../modules/Builder/components/AssetGenerator/utils';
import { APP_ICON_KEYS, APP_MODES, IMAGE_TYPE } from '../../constants';
import * as API from '../../modules/api';
import { sanitizeSVGIconURLs } from '../utils';

export function* handleInstallableAppIcon(updatedProps = {}) {
  const currentApp = yield select(SELECTORS.getAppInfoWithDefaults);
  const isIconUpdating = APP_ICON_KEYS.some(key => updatedProps[key]);
  let iconProps = { ...updatedProps };

  if (isIconUpdating) {
    // current app icon values of the app
    const currentValues = APP_ICON_KEYS.reduce((pre, next) => (currentApp[next] ? { ...pre, [next]: currentApp[next] } : pre), {});
    // merged app icon props
    const finalInstallableProps = Object.keys(currentValues).reduce((prev, next) => {
      const nextValue = updatedProps[next] ? updatedProps[next] : currentValues[next];
      return { ...prev, [next]: nextValue };
    }, {});

    const asset = yield call(getAsset, finalInstallableProps);

    iconProps = { ...iconProps, asset };
  }

  if (updatedProps.appIconURL && updatedProps.appIconType === IMAGE_TYPE.icon) {
    iconProps = { ...iconProps, appIconURL: sanitizeSVGIconURLs(updatedProps.appIconURL) };
  }
  if (updatedProps.logoURL && updatedProps.logoType === IMAGE_TYPE.icon) {
    iconProps = { ...iconProps, logoURL: sanitizeSVGIconURLs(updatedProps.logoURL) };
  }

  return yield call(API.updateAppLogo, { appID: currentApp.id, iconProps });
}

export function* watchInstallableIconBuilderFlow() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode !== APP_MODES.builder) return;

  try {
    const { installableIconURL, appIconURL } = yield select(SELECTORS.getAppIconProperties);
    if (!installableIconURL) {
      yield call(handleInstallableAppIcon, { appIconURL });
    }
  } catch (error) {
    console.error(error);
  }
}
