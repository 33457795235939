import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { IconTrashFilled } from '@jotforminc/svg-icons';
import JFIconSelector from '../../../../../../../components/IconSelector';
import appConfig from '../../../../../../../constants/appConfig';
import { ScRightPanelBlock } from '../../../../../styles/scRightPanel';
import { ColorPicker } from '../../../../ColorPicker';
import { ScIconSelector } from './styled';
import { OPTION_VALUES, SIDE_SELECTION_DEFAULTS } from '../constants';
import ImagePreview from '../../../../ImagePreview';
import { IMAGE_TYPE } from '../../../../../../../constants';
import { changeDomainWithCurrent } from '../../../../../../../utils';
import ChooseFileButton from '../../ChooseFileButton';

const IconSelector = ({
  value, onChange, colorScheme, id, defaultSelection
}) => {
  const isValueDefault = SIDE_SELECTION_DEFAULTS[OPTION_VALUES.ICON].svgRef === value?.svgRef;
  const [activeColors, setActiveColors] = useState({
    color: value?.color,
    bgColor: value?.bgColor ?? defaultSelection?.bgColor
  });

  // @eren fix this with forwarding ref
  useEffect(() => {
    setActiveColors({
      color: value?.color,
      bgColor: value?.bgColor ?? defaultSelection?.bgColor
    });
  }, [value?.color, value?.bgColor]);

  const [isFileUploadButtonVisible, setIsFileUploadButtonVisible] = useState(!value || isValueDefault);

  const onIconChange = iconInfo => {
    if (!iconInfo) {
      onChange(null);
      return;
    }

    const normalizedIconInfo = {
      ...iconInfo,
      ...activeColors,
      svgRef: iconInfo?.iconSvgRef,
      iconSvgRef: undefined
    };

    onChange(normalizedIconInfo);
  };

  const onColorChange = color => {
    setActiveColors(prevColors => ({ ...prevColors, ...color }));
    onChange({
      ...value,
      ...color
    });
  };

  const handleRemove = () => { onIconChange(null); };

  if (isFileUploadButtonVisible) {
    return (
      <ScRightPanelBlock line style={{ padding: 0, marginTop: '8px' }}>
        <ChooseFileButton onClick={() => setIsFileUploadButtonVisible(false)} text="Choose an icon" />
      </ScRightPanelBlock>
    );
  }

  return (
    <div className='card-icon-selector'>
      <ScRightPanelBlock column>
        <label htmlFor={`itemIconColor-${id}`}>{t('Icon Color')}</label>
        <ColorPicker
          id={`itemIconColor-${id}`}
          value={activeColors?.color ?? colorScheme?.itemFontColor}
          onChange={color => { onColorChange({ color }); }}
        />
      </ScRightPanelBlock>
      <ScRightPanelBlock column>
        <label htmlFor={`itemIconBgColor-${id}`}>{t('Background Color')}</label>
        <ColorPicker
          id={`itemIconBgColor-${id}`}
          value={activeColors?.bgColor}
          onChange={color => { onColorChange({ bgColor: color }); }}
        />
      </ScRightPanelBlock>
      {
      !isValueDefault && (value?.url || value?.svgRef)
        ? (
          <ImagePreview
            imageType={IMAGE_TYPE.icon}
            layout="line"
            svgProps={{
              url: changeDomainWithCurrent(value?.url),
              svgRef: value?.svgRef,
              iconColor: '#fff'
            }}
            onRemove={handleRemove}
            RemoveButtonRenderer={props => <Button startIcon={IconTrashFilled} colorStyle="secondary" {...props}>{t('Remove Icon')}</Button>}
          />
        )
        : (
          <ScIconSelector>
            <JFIconSelector
              allIconsContainerID={appConfig.svgIconsContainer}
              onChange={onIconChange}
              value={value?.url}
              onRemove={() => { setIsFileUploadButtonVisible(true); }}
              removeButtonText={t('Close')}
              allowShuffle={false}
              uniqKey={`iconselector-${id}`}
            />
          </ScIconSelector>
        )
      }
    </div>
  );
};

IconSelector.propTypes = {
  value: propTypes.object,
  onChange: propTypes.func,
  colorScheme: propTypes.object,
  id: propTypes.string,
  defaultSelection: propTypes.object
};

IconSelector.defaultProps = {
  value: undefined,
  onChange: f => f,
  colorScheme: {},
  id: '',
  defaultSelection: {}
};

export default IconSelector;
