import { isSalesforceEnv } from '@jotforminc/utils';
import { StorageHelper } from '@jotforminc/storage-helper';
import { isEnvProd, isJotformer, isMobileApp } from './utils';
import { ActionManager } from './ActionManager';
import { ABTestVariantCache } from './ABTestVariantCache';
import layer from './layer';

export class ABTestManager {
  constructor({
    user,
    testName,
    urlParam,
    debugMode,
    isTestEnabled,
    testVariantCode,
    controlVariantCode,
    cacheVariantCodeAtLocalStorage,
    customUserChecks,
    setUserSetting,
    forceSingleEpTest
  }) {
    this.user = user || {};
    this.testName = testName;
    this.urlParam = urlParam || '';
    this.isTestEnabled = isTestEnabled || false;
    this.customUserChecks = customUserChecks || {};
    this.cacheVariantCodeAtLocalStorage = cacheVariantCodeAtLocalStorage || false;
    this.isSingleTestVariant = (typeof testVariantCode === 'string') && testVariantCode;
    this.isMultipleTestVariant = (typeof testVariantCode === 'object') && Array.isArray(testVariantCode);
    this.setUserSetting = setUserSetting || null;
    this.forceSingleEpTest = forceSingleEpTest || null;
    this.testConfig = {
      testName, controlVariantCode, testVariantCode, urlParam
    };

    this.searchParams = new URLSearchParams(document?.location?.search || '');
    this.localStorageVariantKey = `${this.testName}VariantCode`;

    // debug mode
    const debugModeDefaults = {
      forceUserEligible: false, forceTestVariant: false, forceControlVariant: false, logTestState: false
    };
    this.debugMode = { ...debugModeDefaults, ...debugMode };
    if (this.isDebugModeOn) {
      this.showDebugModeWarnings();
    }

    // jotform actions
    this.actionManager = new ActionManager({
      user: this.user,
      projectName: this.testName,
      ...(this.isDebugModeOn ? { enableDebugMode: true } : {})
    });

    // should register actions
    const shouldRegisterActions = (this.isTestEnabled && this.isUserEligible)
      || this.isDebugModeOn
      || this.hasTestVariantUrlParam
      || this.hasControlVariantUrlParam
      || this.hasTestVariantUrlParamAmongMultiple();

    // register ab test action
    this.registerABTestAction = shouldRegisterActions ? this.actionManager.registerJotformAction : f => f;
  }

  // is variant code singleton cached
  get isVariantCodeSingletonCached() {
    return !!this.getVariantFromSingletonCache() || false;
  }

  // is variant code local storage cached
  get isVariantCodeLocalStorageCached() {
    return !!this.getVariantFromLocalStorage() || false;
  }

  // are base checks valid
  get areBaseChecksValid() {
    return isEnvProd() && !isJotformer(this.user) && !isMobileApp() && !isSalesforceEnv();
  }

  // are custom user checks valid
  get areCustomUserChecksValid() {
    return Object.values(this.customUserChecks).length > 0
      ? Object.values(this.customUserChecks).every(customCheck => customCheck)
      : true;
  }

  // is user eligible
  get isUserEligible() {
    const { forceUserEligible } = this.debugMode;
    if (forceUserEligible) return true;
    const isValid = this.areBaseChecksValid && this.areCustomUserChecksValid;
    if (isValid) {
      return true;
    }
    if (!isValid && this.isDebugModeOn) {
      console.warn(`Warning: ${this.testName} A/B test user is not eligible.`);
    }
    return false;
  }

  // has test variant url param
  get hasTestVariantUrlParam() {
    return this.searchParams.get(`${this.urlParam}`) === 'true';
  }

  // has control variant url param
  get hasControlVariantUrlParam() {
    return this.searchParams.get(`${this.urlParam}`) === 'false';
  }

  get cachedVariantCode() {
    return this.getVariantFromSingletonCache() || this.getVariantFromLocalStorage();
  }

  // get/set variant cache
  getVariantFromSingletonCache() {
    return ABTestVariantCache[this.testName] || '';
  }

  setVariantToSingletonCache(variation) {
    if (!this.getVariantFromSingletonCache()) {
      ABTestVariantCache[this.testName] = variation;
    }
    if (this.isDebugModeOn) {
      console.warn(`Warning: ${this.testName} A/B test singleton cached variation code: ${variation}`);
    }
  }

  // get/set variant local storage
  getVariantFromLocalStorage() {
    return StorageHelper.getLocalStorageItem({ key: this.localStorageVariantKey }) || '';
  }

  setVariantToLocalStorage(variation) {
    if (this.getVariantFromLocalStorage()) return;
    StorageHelper.setLocalStorageItem({
      key: this.localStorageVariantKey, value: variation
    });
    if (this.isDebugModeOn) {
      console.warn(`Warning: ${this.testName} A/B test localStorage cached variation code: ${variation}`);
    }
  }

  // check current variant matches any of the multiple variants
  includesAnyTestVariant(currentVariant, multipleVariants) {
    return multipleVariants.map(variant => variant.code).includes(currentVariant);
  }

  // is variant code equal to test variant
  get isVariantCodeEqualTestVariant() {
    const { testVariantCode } = this.testConfig;
    if (this.isSingleTestVariant) {
      return this.cachedVariantCode === testVariantCode;
    }
    if (this.isMultipleTestVariant) {
      return this.includesAnyTestVariant(this.cachedVariantCode, testVariantCode);
    }
    return false;
  }

  // variant resolver
  variantResolver(cb) {
    if (typeof cb !== 'function') return;
    if (this.isSingleTestVariant && this.isVariantCodeEqualTestVariant) {
      cb(true);
    } else if (this.isMultipleTestVariant) {
      const isTestVariant = this.isVariantCodeEqualTestVariant;
      cb([isTestVariant, this.cachedVariantCode]);
    } else {
      cb(false);
    }
  }

  // get test variant
  getTestVariant() {
    return new Promise((resolve, reject) => {
      const params = { setUserSetting: this.setUserSetting, forceSingleEpTest: this.forceSingleEpTest };
      layer.get(`user/abtest/${this.testName}/variation`, { params }).then(res => {
        let { variation } = res;
        variation = variation ? variation.toString() : variation;
        resolve(variation);
        if (this.isDebugModeOn) {
          console.warn(`Warning: ${this.testName} A/B test variant request success.`);
        }
      }).catch(reject);
    });
  }

  hasTestVariantUrlParamAmongMultiple() {
    if (!this.isMultipleTestVariant) return false;
    const { testVariantCode: multipleVariants } = this.testConfig;
    let result = false;
    multipleVariants.forEach(variant => {
      if (this.searchParams.get(`${variant.urlParam}`) === 'true') {
        result = [true, variant.code];
      }
    });
    return result;
  }

  // is test variant
  isTestVariant() {
    return new Promise((resolve, reject) => {
      const { forceTestVariant, forceControlVariant } = this.debugMode;

      // checks for enabling
      if (this.isSingleTestVariant && (forceTestVariant || this.hasTestVariantUrlParam)) {
        resolve(true);
        return;
      }

      // checks for multiple variant enabling
      const multipleEnablerResult = this.hasTestVariantUrlParamAmongMultiple();
      if (this.isMultipleTestVariant && multipleEnablerResult) {
        resolve(multipleEnablerResult);
        return;
      }

      // check for settings cache
      // feature: do not allow user settings variant code for multiple variants for now...
      if (!this.isMultipleTestVariant && this.setUserSetting && this.user[this.testName]) {
        const { testVariantCode } = this.testConfig;
        resolve(this.user[this.testName] === testVariantCode);
        return;
      }

      // checks for disabling
      if ((!this.isTestEnabled
        || forceControlVariant
        || this.hasControlVariantUrlParam
        || !this.isUserEligible)) {
        const { controlVariantCode } = this.testConfig;
        const result = this.isSingleTestVariant ? false : [false, controlVariantCode];
        resolve(result);
        return;
      }

      // is variant code singleton cached or local storage cached
      if (this.isVariantCodeSingletonCached || this.isVariantCodeLocalStorageCached) {
        this.variantResolver(result => resolve(result));
        return;
      }

      // get variant code from api
      this.getTestVariant().then(variation => {
        // between component mount and unmount ABTestHelper instance is created from scratch
        // so caching can be useful to prevent repeating network requests

        // cache level 1: singleton cache
        this.setVariantToSingletonCache(variation);

        // optional cache level 2: local storage cache
        if (variation && this.cacheVariantCodeAtLocalStorage) {
          this.setVariantToLocalStorage(variation);
        }

        if (this.isDebugModeOn) {
          this.showDebugModeWarnings();
        }

        this.variantResolver(result => resolve(result));
      }).catch(reject);
    });
  }

  // is debug mode on
  get isDebugModeOn() {
    return Object.values(this.debugMode).includes(true);
  }

  // show debugMode warnings
  showDebugModeWarnings() {
    const {
      forceUserEligible, forceTestVariant, forceControlVariant, logTestState
    } = this.debugMode;
    const forceFlags = [forceUserEligible, forceTestVariant, forceControlVariant, logTestState];

    if (forceFlags.includes(true)) {
      console.warn(`Warning: ${this.testName} A/B test is running on debug mode!`);

      // log debug mode flags
      Object.keys(this.debugMode).forEach(forceFlagKey => {
        if (!this.debugMode[forceFlagKey]) return;
        console.warn(`${forceFlagKey} property is ${this.debugMode[forceFlagKey]}`);
      });

      const warnData = {
        isTestEnabled: this.isTestEnabled,
        testConfig: this.testConfig,
        variantCaches: {
          singletonCachedVariantCode: this.getVariantFromSingletonCache(),
          localStorageCachedVariantCode: this.getVariantFromLocalStorage()
        },
        baseChecks: {
          isJotFormer: isJotformer(this.user),
          isEnvProd: isEnvProd(),
          isMobileApp: isMobileApp()
        },
        customUserChecks: this.customUserChecks
      };

      console.warn(`Warning: ${this.testName} test state:`, warnData);
    }
  }
}
