/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox } from '@jotforminc/magnet';
import ProtectedTag from './ProtectedTag';
import EncryptedTag from './EncryptedTag';

const WithOptionRenderer = (isSingle, showOptionValue) => {
  return ({
    option: {
      text, value, isProtected, isEncrypted
    }, isSelected
  }) => {
    return (
      <div
        className="formFields-option"
        data-value={value}
        data-selected={isSelected}
        data-testid={`selectiongroup-option-${value}`}
      >
        {isSingle && (
          <Checkbox
            checked={isSelected}
            size="small"
            onChange={f => f}
            data-testid={`selectiongroup-option-checkbox-${value}`}
          />
        )}
        <div className="formFields-option-column formFields-option-content">
          <span className="formFields-option-text">{text}</span>
          {showOptionValue && (
          <i style={{ opacity: '0.75', fontStyle: 'normal', fontSize: '12px' }}>
            {' '}
            { ` - ${value}` }
            {' '}
          </i>
          )}
        </div>
        {(isProtected || isEncrypted) && (
          <div className="formFields-option-column forProtectedFields">
            {isProtected ? <ProtectedTag /> : <EncryptedTag />}
          </div>
        )}
      </div>
    );
  };
};

export default WithOptionRenderer;
