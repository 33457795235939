/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  createContext, FunctionComponent, useContext, useEffect, useMemo, useState
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { isEnterprise } from '@jotforminc/enterprise-utils';

/** Types */
import {
  I_GATEWAY_FLOW_CONTEXT,
  I_GATEWAY_FLOW_PROVIDER,
  I_STEPS,
  I_PAYMENT_TYPES,
  I_GATEWAY_LIST,
  I_GATEWAYS,
  // I_GATEWAY_APMS,
  I_APM_ELEMENT_TYPES
} from '../Types';

import { T_QUESTION_PROPERTIES } from '../Types/GatewayTypes';

/** Constants */
import { C_GATEWAYS } from '../Constants';
import { moveConnectionToReusable } from '../../../../api';
import { DEFAULT_GATEWAY_PROPS } from '../Constants/DefaultGatewayProps';
import { getDisconnectSettings, isPositiveValue } from '../Utils';
import { checkIsGatewayConnected } from '../../../../utils';
import { getDataFromSettings } from '../Constants/GatewayProperties';

const GatewayFlowContext = createContext<I_GATEWAY_FLOW_CONTEXT>({} as I_GATEWAY_FLOW_CONTEXT);

export const GatewayFlowProvider: FunctionComponent<I_GATEWAY_FLOW_PROVIDER> = (({
  children,
  /** Defaults */
  defaultVisible = false,
  defaultStep = null,
  defaultPaymentType = null,
  defaultGateway = null,
  defaultFlowType,
  gatewayQuestionProperties,
  formQuestions,
  user,
  portalOwner,
  /** Resource */
  resourceType,
  formId,
  /** Callbacks */
  onCloseModal,
  onSelectGateway,
  onSelectPaymentType,
  onAddPaymentType,
  onSaveGateway
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultVisible);
  const [currentlyStep, setStep] = useState<I_STEPS | null>(defaultStep);
  const [currentlyGateway, setGateway] = useState<I_GATEWAYS | null>(defaultGateway);
  const [currentlyApm, setApm] = useState<I_APM_ELEMENT_TYPES | null>(null);
  const [currentlyApmProperties, setApmProperties] = useState<I_GATEWAY_LIST | null>(null);
  const [currentlyGatewayProperties, setGatewayProperties] = useState<I_GATEWAY_LIST | null>(null);
  const [currentlyPaymentType, setPaymentType] = useState<I_PAYMENT_TYPES | null>(defaultPaymentType);
  const [gatewayQuestionProps, setGatewayQuestionProps] = useState<T_QUESTION_PROPERTIES>(gatewayQuestionProperties);
  const [changedSettings, _setChangedSettings] = useState<object>({});
  const [staticSettings, setStaticSettings] = useState<object>({});
  const [nextStepForDiscard, setNextStepForDiscard] = useState<I_STEPS | null>(null);
  const [isSandboxWarningVisible, setIsSandboxWarningVisible] = useState(false);
  const [isMaskedCredentialSaved, setIsMaskedCredentialSaved] = useState(false);

  useEffect(() => {
    if (defaultGateway) {
      if (gatewayQuestionProperties && gatewayQuestionProperties.nameAPM) {
        if (['CashApp', 'Venmo'].includes(gatewayQuestionProperties.nameAPM)) {
          selectGateway(`control_${gatewayQuestionProperties.nameAPM}`);
        } else if (gatewayQuestionProperties.nameAPM === 'appleAndGooglePay') {
          const Properties = C_GATEWAYS.find(data => data.type === 'control_appleAndGooglePay');
          setApmProperties(Properties);
          setApm('control_appleAndGooglePay');
        }
      } else if (isApmGateway(defaultGateway)) {
        selectApm(defaultGateway as I_APM_ELEMENT_TYPES);
      } else {
        selectGateway(defaultGateway);
      }
    }
  }, []);

  useEffect(() => {
    let timeoutId: number;
    if (isSandboxWarningVisible) {
      timeoutId = setTimeout(() => {
        setIsSandboxWarningVisible(false);
      }, 10000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSandboxWarningVisible]);

  const _setStaticSettings = (data: object) => {
    setStaticSettings({ ...staticSettings, ...data });
  };

  const isApmGateway = (gateway: I_GATEWAYS) => {
    return ['control_CashApp', 'control_Venmo', 'control_appleAndGooglePay'].includes(gateway);
  };

  const closeModal = () => {
    setIsOpen(false);
    if (typeof onCloseModal === 'function') {
      onCloseModal();
    }
    document.body.classList.remove('overflow-hidden');
  };

  const selectGateway = (gateway: I_GATEWAYS | null, apmProperties?: I_GATEWAY_LIST) => {
    if (gateway) {
      const _properties = C_GATEWAYS.find(data => data.type === gateway);
      const Properties = cloneDeep(_properties);
      if (Properties) {
        if (apmProperties) {
          Object.assign(Properties, {
            nameAPMType: apmProperties.nameAPMType,
            name: apmProperties.name
          });

          if (apmProperties.paymentTypes) {
            Object.assign(Properties, {
              paymentTypes: apmProperties.paymentTypes
            });
          }

          if (Properties.apmPaymentTypes) {
            if (apmProperties.nameAPMType) {
              const getAPMPaymentTypes = Properties.apmPaymentTypes.find(data => {
                return Object.keys(data).some(key => key === apmProperties.nameAPMType);
              });

              if (getAPMPaymentTypes && Object.values(getAPMPaymentTypes)[0] && Object.values(getAPMPaymentTypes)[0].length >= 1) {
                Object.assign(Properties, {
                  paymentTypes: Object.values(getAPMPaymentTypes)[0]
                });
              }
            }
          }
        }

        setGateway(gateway);
        setGatewayProperties(Properties);
      }
    } else {
      setApm(null);
      setGateway(null);
      setGatewayProperties(null);
      setApmProperties(null);
      setPaymentType(null);
    }

    if (typeof onSelectGateway === 'function') {
      onSelectGateway();
    }
  };

  const selectPaymentType = (paymentType: I_PAYMENT_TYPES | null) => {
    setPaymentType(paymentType);
    if (typeof onSelectPaymentType === 'function') {
      onSelectPaymentType();
    }
  };

  const selectApm = (apmId: I_APM_ELEMENT_TYPES | null) => {
    setApm(apmId);
    setGateway(null);
    if (apmId) {
      const Properties = C_GATEWAYS.find(data => data.type === apmId && data.nameAPMType);
      if (Properties !== undefined) {
        setApmProperties(Properties);

        if (defaultFlowType === 'GATEWAY_TO_PAYMENT_TYPE') {
          if (Properties.nameAPMType && Properties.parentType) {
            selectGateway(Properties.parentType, Properties);
            setStep('select-paymentType');
          }
        }

        if (Properties.childTypes) {
          setStep('select-nameapmType');
        }
      }
    }
  };

  const addPaymentType = () => {
    if (typeof onAddPaymentType === 'function') {
      /** Form Elements -> Payments -> Select Gateway */
      if (resourceType === 'FORM' && defaultFlowType === 'GATEWAY_TO_PAYMENT_TYPE') {
        if (currentlyGateway && currentlyPaymentType && currentlyGatewayProperties) {
          const { nameAPMType, elementType, name } = currentlyGatewayProperties;
          const questionProps = {
            type: currentlyGateway,
            paymentType: currentlyPaymentType,
            question_type: currentlyGateway,
            builderLabel: name,
            ...(currentlyPaymentType === 'custom' && { paymentType: 'donation', nonprofit: 'No' }),
            ...(currentlyPaymentType === 'product' && { isPaymentStoreInBasicFields: true, paymentModalFlow: true })
          };

          if (nameAPMType) {
            Object.assign(questionProps, { nameAPM: nameAPMType });
          }

          if (elementType && elementType === 'purchase_order') {
            Object.assign(questionProps, { isPaymentStoreInBasicFields: false });
          }

          onAddPaymentType(questionProps);
        }

        /** Close Modal */
        closeModal();
      }
    }
  };

  const getDefaultGatewayProps = () => {
    const defaultGatewaySettings = DEFAULT_GATEWAY_PROPS.find(gateway => gateway.type === currentlyGateway);
    const defaultGatewayProperties = defaultGatewaySettings ? defaultGatewaySettings.properties : {};

    return defaultGatewayProperties;
  };

  const getParentGatewayType = (): (I_GATEWAYS | null) => {
    const gatewayData = C_GATEWAYS.find(items => items.type === currentlyGateway);
    const gatewayParentType = gatewayData && gatewayData.parentType;
    return gatewayParentType ?? currentlyGateway;
  };

  const isEdit = defaultFlowType === 'EDIT_GATEWAY' && (
    gatewayQuestionProps.type === currentlyGateway
    || gatewayQuestionProps.type === getParentGatewayType()
  );
  const settings = {
    ...(isEdit ? gatewayQuestionProps : getDefaultGatewayProps()),
    ...changedSettings
  };
  const isConnected = checkIsGatewayConnected({ ...staticSettings, ...settings, type: currentlyGateway });
  const [isPreviouslyConnected, setIsPreviouslyConnected] = useState(isConnected);

  const setChangedSettings = (_changedSettings: object) => {
    const newSettings = { type: currentlyGateway, ...settings, ..._changedSettings };
    const { toggleValue } = getDataFromSettings(newSettings, currentlyGateway);

    const isConnectedNow = checkIsGatewayConnected(newSettings);
    if (isPreviouslyConnected && !isConnectedNow && !isPositiveValue(toggleValue) && !isSandboxWarningVisible) {
      setIsSandboxWarningVisible(true);
    }
    setIsPreviouslyConnected(isConnectedNow);
    _setChangedSettings(_changedSettings);
  };

  const onSaveGatewaySettings = () => {
    if (!currentlyGateway) { return; }
    const type = getParentGatewayType();
    const settingsToUpdate = { ...(isEdit ? {} : getDefaultGatewayProps()), ...changedSettings };

    if (Object.keys(settingsToUpdate).length > 0) {
      onSaveGateway(type, settingsToUpdate, isEdit, true);
    }

    if (['control_stripe', 'control_stripeCheckout', 'control_stripeACHManual', 'control_mollie', 'control_square'].includes(type) && typeof changedSettings?.connectionID !== 'undefined') {
      setTimeout(async () => {
        await moveConnectionToReusable(type, gatewayQuestionProperties?.qid, settingsToUpdate?.connectionID, formId);
      }, 1000);
    }
  };

  const onSaveGatewayCredential = (updateObj: object) => {
    if (!currentlyGateway) { return; }
    const type = getParentGatewayType();
    const shouldCloseModal = false;
    setIsMaskedCredentialSaved(true);
    setGatewayQuestionProps({ ...gatewayQuestionProps, ...updateObj });
    onSaveGateway(type, updateObj, isEdit, shouldCloseModal);
  };

  const isCredential = () => {
    const { connectionSettings } = getDataFromSettings({}, currentlyGateway);
    if (connectionSettings?.type === 'credential') {
      return true;
    }
    return false;
  };
  const isMasked = isCredential() && isEdit && !isEnterprise();

  const handleDisconnectGateway = (gateway: I_GATEWAYS): void => {
    const disconnectSettings = getDisconnectSettings(gateway);
    setChangedSettings({ ...changedSettings, ...disconnectSettings });
  };

  const nextStep = () => {
    switch (currentlyStep) {
      case 'select-gateway':
        setStep('select-gatewaySettings');
        break;
      case 'discard-changes':
        setChangedSettings({});
        if (nextStepForDiscard) {
          setStep(nextStepForDiscard);
        } else {
          closeModal();
        }
        setApm(null);
        setApmProperties(null);
        break;
      case 'select-gatewaySettings':
        break;
      case 'select-nameapmType':
        setStep('select-gatewaySettings');
        break;
      default:
        setStep('select-paymentType');
        break;
    }
  };

  const backStep = () => {
    switch (currentlyStep) {
      case 'select-nameapmType':
        setStep('select-gateway');
        setGatewayProperties(null);
        setGateway(null);
        setApm(null);
        setApmProperties(null);
        break;
      case 'select-gatewaySettings':
        if (Object.keys(changedSettings).length > 0) {
          setStep('discard-changes');
          setNextStepForDiscard('select-gateway');
        } else {
          setStep('select-gateway');
          setApm(null);
          setApmProperties(null);
        }
        break;
      case 'discard-changes':
        setStep('select-gatewaySettings');
        break;
      default:
        break;
    }
  };

  const returnValue = useMemo(() => {
    return {
      isOpen,
      currentlyStep,
      currentlyGateway,
      currentlyApm,
      currentlyApmProperties,
      currentlyGatewayProperties,
      currentlyPaymentType,
      gatewayQuestionProps,
      changedSettings,
      defaultGateway,
      defaultFlowType,
      formQuestions,
      user,
      portalOwner,
      staticSettings,
      /** Resource: Start */
      resourceType,
      formId,
      /** Resource: End */
      closeModal,
      nextStep,
      backStep,
      selectGateway,
      selectPaymentType,
      selectApm,
      addPaymentType,
      setGatewayQuestionProps,
      setChangedSettings,
      setStep,
      setNextStepForDiscard,
      onSaveGatewaySettings,
      getDefaultGatewayProps,
      getParentGatewayType,
      handleDisconnectGateway,
      _setStaticSettings,
      isSandboxWarningVisible,
      onSaveGatewayCredential,
      isMasked,
      isMaskedCredentialSaved,
      setIsMaskedCredentialSaved
    };
  }, [
    isOpen,
    currentlyStep,
    currentlyGateway,
    currentlyApm,
    currentlyGatewayProperties,
    currentlyPaymentType,
    gatewayQuestionProps,
    changedSettings,
    formQuestions,
    staticSettings,
    isSandboxWarningVisible,
    isMasked,
    isMaskedCredentialSaved
  ]);

  return <GatewayFlowContext.Provider value={returnValue}>{children}</GatewayFlowContext.Provider>;
});

export default function useGatewayFlows(): I_GATEWAY_FLOW_CONTEXT {
  return useContext(GatewayFlowContext);
}
