import { createSelector } from 'reselect';

export const getUserManagementState = state => state.userManagement;

export const userManagementUsers = createSelector(getUserManagementState, state => state.users);
export const userManagementSheetViewPath = createSelector(getUserManagementState, state => {
  if (!state.sheetID || !state.sheetViewID) {
    return false;
  }

  return `/tables/${state.sheetID}/${state.sheetViewID}`;
});
