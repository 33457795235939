import { cva } from 'class-variance-authority';
import { primary } from '../../tokens/colors';
import { CVAType } from '../../types/system.types';
import { BadgeProps } from './badge.types';
import { COLOR_STYLE_VALUES, PRODUCT_NAME_VALUES } from '../../constants/common.values';

// CVA Types
type BadgeCVAType = CVAType<Pick<BadgeProps, 'size' | 'colorStyle' | 'variant' | 'rounded' | 'truncate'> & { hasIcon?: boolean, hasChildren?: boolean }>;
type BadgeIconCVAType = CVAType<Pick<BadgeProps, 'size'>>
type BadgeTextCVAType = CVAType<Pick<BadgeProps, 'truncate'>>

// Classes
export const badgeCVA = cva<BadgeCVAType>(
  'magnet-badge inline-flex items-center font-medium gap-1', {
    variants: {
      size: {
        small: 'h-4 text-xxs',
        medium: 'h-5 text-xs',
        large: 'h-6 text-sm'
      },
      colorStyle: {},
      variant: {},
      rounded: {
        true: 'radius-full',
        false: 'radius'
      },
      truncate: {
        true: 'max-w-full'
      },
      hasChildren: {},
      hasIcon: {}
    },
    compoundVariants: [
      {
        hasIcon: true,
        hasChildren: false,
        class: 'justify-center'
      },
      {
        hasIcon: true,
        hasChildren: false,
        size: 'small',
        class: 'min-w-4'
      },
      {
        hasIcon: true,
        hasChildren: false,
        size: 'medium',
        class: 'min-w-5'
      },
      {
        hasIcon: true,
        hasChildren: false,
        size: 'large',
        class: 'min-w-6'
      },
      {
        hasChildren: true,
        size: 'small',
        class: 'px-1.5'
      },
      {
        hasChildren: true,
        size: 'medium',
        class: 'px-2'
      },
      {
        hasChildren: true,
        size: 'large',
        class: 'px-2.5'
      },
      {
        hasIcon: true,
        hasChildren: true,
        size: 'small',
        class: 'min-w-10'
      },
      {
        hasIcon: true,
        hasChildren: true,
        size: 'medium',
        class: 'min-w-12'
      },
      {
        hasIcon: true,
        hasChildren: true,
        size: 'large',
        class: 'min-w-14'
      },
      {
        colorStyle: ['warning', ...COLOR_STYLE_VALUES],
        variant: 'filled',
        class: 'color-white'
      },
      {
        colorStyle: [...PRODUCT_NAME_VALUES],
        class: 'color-white'
      },
      {
        colorStyle: ['neutral', 'warning', ...COLOR_STYLE_VALUES],
        variant: 'subtle',
        class: 'color-black bg-opacity-20'
      },
      {
        colorStyle: 'primary',
        variant: 'filled',
        class: primary.accent.default.bg.base
      },
      {
        colorStyle: 'primary',
        variant: 'subtle',
        class: primary.accent.dark.bg.base
      },
      {
        colorStyle: 'success',
        variant: 'filled',
        class: primary.success.default.bg.base
      },
      {
        colorStyle: 'success',
        variant: 'subtle',
        class: primary.success.dark.bg.base
      },
      {
        colorStyle: 'error',
        variant: 'filled',
        class: primary.error.default.bg.base
      },
      {
        colorStyle: 'error',
        variant: 'subtle',
        class: primary.error.default.bg.base
      },
      {
        colorStyle: 'warning',
        class: 'bg-yellow-600'
      },
      {
        colorStyle: 'neutral',
        variant: 'filled',
        class: [primary.background.lightest.base, primary.text.darkest.base]
      },
      {
        colorStyle: 'neutral',
        variant: 'subtle',
        class: primary.background.dark.base
      },
      {
        colorStyle: 'analytics',
        class: 'bg-analytics-default'
      },
      {
        colorStyle: 'approvals',
        class: 'bg-approvals-default'
      },
      {
        colorStyle: 'apps',
        class: 'bg-apps-default'
      },
      {
        colorStyle: 'forms',
        class: 'bg-forms-default'
      },
      {
        colorStyle: 'inbox',
        class: 'bg-inbox-default'
      },
      {
        colorStyle: 'pages',
        class: 'bg-pages-default'
      },
      {
        colorStyle: 'pdf',
        class: 'bg-pdf-default'
      },
      {
        colorStyle: 'reports',
        class: 'bg-reports-default'
      },
      {
        colorStyle: 'sign',
        class: 'bg-sign-default'
      },
      {
        colorStyle: 'tables',
        class: 'bg-tables-default'
      },
      {
        colorStyle: 'teams',
        class: 'bg-teams-default'
      }
    ]
  }
);

export const badgeIconCVA = cva<BadgeIconCVAType>('color-current shrink-0', {
  variants: {
    size: {
      small: 'w-2.5 h-2.5',
      medium: 'w-3.5 h-3.5',
      large: 'w-4 h-4'
    }
  }
});

export const badgeTextCVA = cva<BadgeTextCVAType>('', {
  variants: {
    truncate: {
      true: 'min-w-0 truncate',
      false: 'whitespace-nowrap'
    }
  }
});
