import Styled from 'styled-components';
import { appIconBox } from '../../../styles/scGeneral';

const res = {
  phone: {
    long: '665px',
    short: '375px',
    longPad: '72px',
    shortPad: '20px'
  },
  tablet: {
    long: '1024px',
    short: '768px',
    longPad: '96px',
    shortPad: '60px'
  }
};

export const ScPreviewCont = Styled.div`
  width: 100%;
  background-color: #F3F3FE;

  .deviceToggle {
    margin-bottom: 1.5em;
    text-align: center;
  }

  .orientationToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;

    &-title {
      font-size: 0.875em;
      text-align: center;
      font-weight: 500;
      color: #282e42;
      margin-bottom: 0.6875em;
      text-transform: uppercase;
    }

    li:last-child svg { transform: rotate(-90deg); }
  }

  .apCont {
    box-sizing: content-box;
    margin: 1.5em auto;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 0;
    position: relative;
    background-color: #fff;

    &.forPhone, &.forTablet {
      border-radius: 50px;
      border: 1px solid #ccc;

      .ap { border: 1px solid #ccc; }

      &::before {
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid #ccc;
        bottom: 11px;
        left: 50%;
        content: "";
        display: inline-block;
        transform: translateX(-50%);
      }

      &.isLandscape {
        &::before {
          position: absolute;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid rgb(204, 204, 204);
          right: 11px;
          top: 50%;
          content: "";
          display: inline-block;
          transform: translateY(-50%);
          bottom: unset;
          left: unset;
        }
      }
    }

    &.forPhone {
      &.isPortrait {
        width: ${res.phone.short};
        height: ${res.phone.long};
        padding: ${res.phone.longPad} ${res.phone.shortPad};
      }
      &.isLandscape {
        width: ${res.phone.long};
        height: ${res.phone.short};
        padding: ${res.phone.shortPad} ${res.phone.longPad};
      }
    }

    &.forTablet {
      &.isPortrait {
        width: ${res.tablet.short};
        height: ${res.tablet.long};
        padding: ${res.tablet.shortPad} ${res.tablet.shortPad} ${res.tablet.longPad} ${res.tablet.shortPad};
      }
      &.isLandscape {
        width: ${res.tablet.long};
        height: ${res.tablet.short};
        padding: ${res.tablet.shortPad} ${res.tablet.longPad} ${res.tablet.shortPad} ${res.tablet.shortPad};
      }
    }
    &.forDesktop {
      margin: 0 auto;
    }
  }

  .ap, iframe { width: 100%; height: 100%; }

  iframe { border: 0; }

  @media screen and (max-width: 768px) {
    .deviceToggle li:nth-child(2) { display: none; }
  }

  @media screen and (max-width: 480px) {
    .deviceToggle, .orientationToggle { display: none; }
    .apCont.apCont.apCont.apCont { width: 100%; height: 100%; padding: 0; border: 0; margin-top: 0 auto;}
  }
`;

export const ScPreviewSplashCont = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ splashBgColor }) => splashBgColor};

  .splashLogo {
   ${appIconBox}
  }
  .appNameSplash {
    text-align: center;
    color: ${({ splashFontColor }) => splashFontColor || 'white'};
  }

  .splashLogo {
    width: 65px;
    height: 65px;
  }

  .appNameSplash {
    margin-top: 8px;
    font-size: 14px;
  }
`;
