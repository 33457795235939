import React, {
  useEffect, useState, useMemo, useCallback
} from 'react';
import {
  elementType, number, func, string
} from 'prop-types';
import TextInput from '../TextInput';
import { LimitRenderer as DefaultLimitRenderer } from './LimitRenderer';
import { useEffectIgnoreFirst } from '../../utils/hooks';

const TextArea = ({
  limit, LimitRenderer, onChange, value: initialValue, onFocus, onBlur, ...rest
}) => {
  const shouldApplyLimit = useMemo(() => limit > 0, [limit]);
  const normalizeValue = useCallback(inputVal => (shouldApplyLimit ? inputVal.slice(0, limit) : inputVal), [shouldApplyLimit, limit]);

  const [isFocused, setFocused] = useState(false);

  const [value, setValue] = useState(normalizeValue(initialValue));
  const handleChange = e => setValue(normalizeValue(e.target.value));

  const handleFocus = useCallback(e => {
    setFocused(true);
    onFocus(e);
  }, [onFocus]);

  const handleBlur = useCallback(e => {
    setFocused(false);
    onBlur(e);
  }, [onBlur]);

  useEffectIgnoreFirst(() => {
    onChange(value);
  }, [value]);

  useEffect(() => {
    if (value !== initialValue && !isFocused) {
      setValue(normalizeValue(initialValue));
    }
  }, [initialValue]);

  return (
    <>
      <TextInput
        {...rest}
        maxLength={limit}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        multiline
      />
      {shouldApplyLimit && (
        <LimitRenderer length={value.length} limit={limit} />
      )}
    </>
  );
};

TextArea.propTypes = {
  value: string,
  onChange: func,
  limit: number,
  LimitRenderer: elementType,
  onFocus: func,
  onBlur: func
};

TextArea.defaultProps = {
  value: '',
  onChange: f => f,
  limit: -1,
  LimitRenderer: props => <DefaultLimitRenderer {...props} />,
  onFocus: f => f,
  onBlur: f => f
};

export default TextArea;
