import React, {
  ComponentPropsWithRef, ElementType, forwardRef
} from 'react';
import { cx } from 'class-variance-authority';
import { PolymorphicProps } from '../../types/system.types';
import { FlexComponentType, FlexProps } from './flex.types';
import { convertResponsiveClass } from '../../utils';
import { justifyContentClasses } from '../../constants/common.cva';

import '@jotforminc/jotform.css';

import {
  alignItemsClasses,
  alignSelfClasses,
  directionClasses,
  growClasses,
  orderClasses,
  shrinkClasses,
  wrapClasses
} from './flex.cva';

export const Flex:FlexComponentType = forwardRef(
  <Element extends ElementType>(
    props: PolymorphicProps<Element, FlexProps>,
    ref?: ComponentPropsWithRef<Element>['ref']
  ): JSX.Element => {
    const {
      as = 'div',
      alignSelf,
      justifyContent,
      alignItems,
      direction,
      wrap,
      grow,
      shrink,
      order,
      inline,
      children,
      className,
      ...rest
    } = props;

    const Component = as;
    const flexClasses = [
      !inline ? 'flex' : 'inline-flex',
      ...convertResponsiveClass(justifyContent, justifyContentClasses),
      ...convertResponsiveClass(alignItems, alignItemsClasses),
      ...convertResponsiveClass(direction, directionClasses),
      ...convertResponsiveClass(wrap, wrapClasses),
      ...convertResponsiveClass(grow, growClasses),
      ...convertResponsiveClass(shrink, shrinkClasses),
      ...convertResponsiveClass(order, orderClasses),
      ...convertResponsiveClass(alignSelf, alignSelfClasses)
    ];

    return (
      <Component {...rest} ref={ref} className={cx(...flexClasses, className)}>{children}</Component>
    );
  }
);
