import React, { Component } from 'react';
import {
  string,
  bool,
  func,
  node,
  oneOfType,
  elementType,
  shape
} from 'prop-types';

import { Checkbox } from './Checkbox';

class CheckboxItem extends Component {
  constructor(props) {
    super(props);
    this.name = props.name || `checkbox-${Math.random().toString(36).substr(2, 8)}`;
  }

  get classNames() {
    const {
      className,
      checked,
      isReverse,
      backgroundColor
    } = this.props;

    return [
      'jfCheckbox-item',
      className,
      checked ? 'isChecked' : null,
      isReverse ? 'isReverse' : null,
      backgroundColor === 'transparent' ? 'isTransparent' : null
    ].filter(a => a).join(' ');
  }

  render() {
    const {
      children,
      indeterminate,
      checked,
      onChange,
      onClick,
      backgroundColor,
      borderColor,
      icon,
      disabled,
      dataId,
      tabIndex,
      size,
      forwardedRef, // eslint-disable-line react/prop-types
      ariaLabel,
      labelProps = {}
    } = this.props;

    return (
      <label
        className={this.classNames}
        htmlFor={`jfCheckbox-item-${this.name}`}
        ref={forwardedRef}
        {...labelProps}
      >
        <Checkbox
          name={this.name}
          indeterminate={indeterminate}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          icon={icon}
          disabled={disabled}
          dataId={dataId}
          tabIndex={tabIndex}
          size={size}
          ariaLabel={ariaLabel}
        />
        <div className="jfCheckbox-inputText" data-test-id="checkbox-inputText">{children}</div>
      </label>
    );
  }
}

CheckboxItem.propTypes = {
  children: oneOfType([node, elementType]),
  icon: oneOfType([node, elementType]),
  name: string,
  indeterminate: bool,
  checked: bool,
  onChange: func,
  onClick: func,
  className: string,
  isReverse: bool,
  backgroundColor: string,
  borderColor: string,
  disabled: bool,
  dataId: string,
  tabIndex: string,
  size: string,
  ariaLabel: string,
  labelProps: shape({})
};

CheckboxItem.defaultProps = {
  children: null,
  icon: null,
  name: null,
  indeterminate: false,
  checked: false,
  onChange: f => f,
  onClick: f => f,
  className: null,
  isReverse: false,
  backgroundColor: null,
  borderColor: null,
  disabled: false,
  dataId: null,
  tabIndex: null,
  size: null,
  ariaLabel: '',
  labelProps: {}
};

export default React.forwardRef((props, ref) => <CheckboxItem {...props} forwardedRef={ref} />);
