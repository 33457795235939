export const RESPONSIVE_MODES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
};

export const DUMMY_RECOMMENDED_THEMES = [{
  id: 211301991871959,
  thumbnail_url: 'https://cdn.jotfor.ms/assets/img/appPreview/industry_education.png'
}, {
  id: 212721702558958,
  thumbnail_url: 'https://cdn.jotfor.ms/assets/img/appPreview/theme1.png'
}, {
  id: 211602841262951,
  thumbnail_url: 'https://cdn.jotfor.ms/assets/img/appPreview/theme2.png'
}, {
  id: 211302146772952,
  thumbnail_url: 'https://cdn.jotfor.ms/assets/img/appPreview/theme3.png'
}, {
  id: 212382889685978,
  thumbnail_url: 'https://cdn.jotfor.ms/assets/img/appPreview/default.png'
}];
