import React from 'react';
import { shape, string } from 'prop-types';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';
import { IconCheckCircleFilled, IconHeartCircleFilled, IconTrashFilled } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import ScShoppingNotification from './ScShoppingNotification';
import { SHOPPING_NOTIFICATION_TYPES } from '../../../constants';
import { generateAppURL } from '../../../utils/navigation';
import SELECTORS from '../../../store/selectors';
import SimpleProductItem from '../../../modules/PublicApp/SimpleProductItem';
import { navigateToAction } from '../../../store/actionCreators';
import { DESTINATION_TYPES } from '../../../constants/navigation';
import { useFormattedPrice } from '../../../utils/hooks';

const {
  ADD_PRODUCT, DELETE_PRODUCT, FAVORITE_PRODUCT, UNFAVORITE_PRODUCT
} = SHOPPING_NOTIFICATION_TYPES;

const MAP = {
  [ADD_PRODUCT]: { Icon: () => <IconCheckCircleFilled width="24px" color="#78BB07" />, message: 'Item added to your cart', color: 'green' },
  [DELETE_PRODUCT]: {
    Icon: () => (
      <div style={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FC0000'
      }}
      >
        <IconTrashFilled width="14px" color="#FFF" />
      </div>
    ),
    message: 'Item deleted from your cart',
    color: 'red'
  },
  [FAVORITE_PRODUCT]: { Icon: () => <IconHeartCircleFilled width="24px" color="#FF68B0" />, message: 'Item added to your favorites', color: 'pink' },
  [UNFAVORITE_PRODUCT]: { Icon: () => <IconHeartCircleFilled width="24px" color="#6F76A7" />, message: 'Item removed from your favorites', color: 'grey' }
};

// todo :: get icon type from constant, not string

const ShoppingNotification = ({ type, product }) => {
  const { Icon, message, color } = MAP[type];

  const dispatch = useDispatch();
  const appID = useSelector(SELECTORS.getAppID);

  const getFormattedPrice = useFormattedPrice();

  const cartUrl = generateAppURL({
    appID,
    pageID: 'cart',
    absolutePath: false
  });

  const handleViewCart = () => {
    dispatch(navigateToAction({ to: DESTINATION_TYPES.SPECIAL_PAGE, pageID: 'cart' }));
  };

  const handleViewFavorites = () => navigate(cartUrl, { state: { activeTab: 'Fav' } });

  const { price = '' } = product || {};

  const handleOrderNow = () => {
    dispatch(navigateToAction({ to: DESTINATION_TYPES.SPECIAL_PAGE, pageID: 'checkout' }));
  };

  return (
    <ScShoppingNotification color={color} type={type}>
      <div className="title">
        <div className="icon"><Icon /></div>
        <div className="message">{t(message)}</div>
      </div>

      {product && (
        <>
          <div className="product">
            <SimpleProductItem
              product={product}
              totalAmount={getFormattedPrice(price)}
            />
          </div>
          <div className="flex flex-col items-center gap-2 flex-1 mt-7">
            {type === FAVORITE_PRODUCT ? (
              <Button
                fullWidth
                variant='outline'
                colorStyle='secondary'
                onClick={handleViewFavorites}
              >
                {t('View My Favorites')}
              </Button>
            ) : (
              <>
                <Button
                  fullWidth
                  variant='outline'
                  colorStyle='secondary'
                  onClick={handleViewCart}
                >
                  {t('View My Cart')}
                </Button>
                <Button fullWidth colorStyle='teams' onClick={handleOrderNow}>
                  {t('Order Now')}
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </ScShoppingNotification>
  );
};

ShoppingNotification.propTypes = {
  type: string,
  product: shape({})
};

ShoppingNotification.defaultProps = {
  type: 'default',
  product: null
};

export default ShoppingNotification;
