import { t } from '@jotforminc/translation';
import { useDebounce } from '@jotforminc/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScRightPanelBlock } from '../../../styles/scRightPanel';
import RadioToggle from '../../RadioToggle';
import TextInput from '../../TextInput';
import SELECTORS from '../../../../../store/selectors';
import { isYes } from '../../../../../utils';
import { OVERRIDED_ICON_LIST, RightPanelModes } from '../constants';
import IconSelector from '../../../../../components/IconSelector';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import { ScLineHr } from './ScLineHr';

const PageSettingsPanel = () => {
  const dispatch = useDispatch();
  const lastInteractedPageID = useSelector(SELECTORS.getLastInteractedPageID);
  const selectedPage = useSelector(SELECTORS.getSelectedPage);
  /*
    Using last interacted page as a fallback because of the errors on
    item addition with PageSettingsPanel opened.
  */
  const pageId = selectedPage || lastInteractedPageID;
  const pageProperties = useSelector(SELECTORS.getPageWithDefaults(pageId));
  const hasShowPageIcon = useSelector(SELECTORS.getShowPageIcon(pageId));

  const {
    name,
    defaultName,
    showPageOnNavigation,
    showPageIcon,
    pageIcon,
    linkedItemID
  } = pageProperties;

  const [pageName, setPageName] = useState(name);

  useEffect(() => {
    if (pageName !== name) setPageName(name);
  }, [selectedPage]);

  const handlePageUpdate = props => {
    dispatch(ACTION_CREATORS.updatePageAction({
      pageID: pageId,
      prop: props
    }));
  };

  const handleShowPageIcon = () => {
    if (!isYes(hasShowPageIcon)) {
      return { showPageIcon: 'Yes' };
    }
    return {};
  };

  const handlePageNameChange = useDebounce(value => {
    setPageName(value);
    handlePageUpdate({ name: value });
  }, 150);

  const handlePageNameBlur = ({ target: { value } }) => {
    if (!value) {
      setPageName(defaultName);
    }
  };

  return (
    <>
      <ScRightPanelBlock line>
        <RadioToggle
          onChange={value => handlePageUpdate({ showPageOnNavigation: value })}
          title={t('Show Page on Navigation')}
          description={t('Set visibility of the page in the navigation.')}
          value={showPageOnNavigation}
          {...(linkedItemID ? { squareMode: true, readOnly: true } : {})}
        />
      </ScRightPanelBlock>
      {
        !linkedItemID
        && (
        <>
          <ScLineHr />
          <ScRightPanelBlock line>
            <label htmlFor="pageName">{t('Page Name')}</label>
            <TextInput
              id="pageName"
              value={pageName}
              onChange={handlePageNameChange}
              onBlur={handlePageNameBlur}
            />
          </ScRightPanelBlock>
        </>
        )
      }
      <>
        <ScLineHr />
        <ScRightPanelBlock line>
          <RadioToggle
            title={t('Show Icon')}
            onChange={value => handlePageUpdate({ showPageIcon: value })}
            value={showPageIcon}
            {...(linkedItemID ? { squareMode: true, readOnly: true } : {})}
          />
        </ScRightPanelBlock>
      </>
      {isYes(showPageIcon) && (
        <>
          <ScRightPanelBlock line>
            <label htmlFor="selectIcon">{t('Select Icon')}</label>
            <IconSelector
              value={pageIcon}
              overriddenIcons={OVERRIDED_ICON_LIST[RightPanelModes.PAGE_SETTINGS]}
              onChange={({ url }) => handlePageUpdate({ pageIcon: url, ...handleShowPageIcon() })}
              allowRemove={false}
            />
          </ScRightPanelBlock>
        </>
      )}
    </>
  );
};

export default PageSettingsPanel;
