import React, { WheelEvent } from 'react';
import { TextInput } from '@jotforminc/uikit';
import { isThereASeparator } from './ConnectionButton/utils';
import { isLetterKey } from '../../utils';

type SuffixInputProps = {
  type?: string,
  value: string,
  suffix: string,
  parentClass: string,
  onChange: (value: string) => void
}
const handleMouseWheel = (e: WheelEvent<HTMLInputElement>) => {
  e.currentTarget.blur();
};

const SuffixInput = ({
  type, value, suffix, parentClass, onChange
}: SuffixInputProps) : JSX.Element => {
  return (
    <div className={parentClass}>
      <TextInput
        type={type}
        id="price"
        className="inputItem"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        value={value}
        onWheel={handleMouseWheel}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (isLetterKey(e) || isThereASeparator(e, value)) {
            e.preventDefault();
          }
        }}
      />
      <span>{suffix}</span>
    </div>
  );
};

SuffixInput.defaultProps = {
  type: 'text'
};

export default SuffixInput;
