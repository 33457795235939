import React, { FunctionComponent } from 'react';
import { t } from '@jotforminc/translation';
import { IconExclamationTriangleFilled, IconXmark } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';

type FormFieldConfirmationModalType = {
  onCancel: () => void,
  onDiscard: () => void,
  onConfirm: () => void
}

const FormFieldConfirmationModal: FunctionComponent<FormFieldConfirmationModalType> = ({ onCancel, onDiscard, onConfirm }): JSX.Element => {
  return (
    <div className="block formFieldConfirmationModal fixed top-28 right-0 bg-gray-700 radius shadow-dark-xl color-white text-md">
      <div className='flex justify-between items-start px-3 py-5 gap-3 sm:gap-5'>
        <IconExclamationTriangleFilled className='w-12 fill-red-400' />
        <p className='grow-1'>{t('You have unsaved changes. Do you want to save them now?')}</p>
        <Button
          startIcon={IconXmark}
          variant='ghost'
          rounded
          className='formFieldConfirmationModal-closeBtn'
          onClick={onCancel}
        />
      </div>
      <hr />
      <div className="flex justify-center items-center gap-4 p-3">
        <Button colorStyle='secondary' onClick={onDiscard}>
          {t('Discard')}
        </Button>
        <Button
          onClick={onConfirm}
          colorStyle='success'
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};

export default FormFieldConfirmationModal;
