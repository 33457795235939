import React, { useCallback } from 'react';
import { t } from '@jotforminc/translation';
import { func, string } from 'prop-types';
import WarningBox from '@jotforminc/warning-box';
import { Button } from '@jotforminc/magnet';
import { handleCustomNavigation } from '@jotforminc/utils';
import { ITEM_TYPES } from '../../../../../../constants/itemTypes';
import SimpleItem from '../../../../../../components/Items/SimpleItem/SimpleItem';
import SimpleIcon from '../../../../../../components/Items/SimpleItem/SimpleIcon';
import ScResourceSelection from '../ResourceSelection/ScResourceSelection';
import ReplaceFormButton from '../../../../../../components/ReplaceFormButton';
import ScActionButtons from './ScActionButtons';
import { AB_TESTS, logAbTestActionFor } from '../../../../../../utils/abtests';

const FormSelection = ({
  value: currentFormID, formTitle, status, isInitialForm
}) => {
  const TitleRenderer = useCallback(({ children, ...rest }) => <div className="title" {...rest}>{children}</div>, []);
  const IconRenderer = useCallback(() => <SimpleIcon itemType={ITEM_TYPES.FORM} useResourceSelectionIcons={true} />, []);

  const handleEditFormClick = () => {
    if (isInitialForm === 'Yes') {
      logAbTestActionFor(AB_TESTS.ShowFormFromFormBuilder, { action: 'click', target: 'edit-form-rp' });
    }

    handleCustomNavigation(`/build/${currentFormID}`, '_blank');
  };

  return (
    <ScResourceSelection iconFillColor="#FF6100">
      <label htmlFor="resourcePicker" className="resourcePickerLabel">{t('Connected Form')}</label>
      <SimpleItem
        title={formTitle}
        showIcon={true}
        TitleRenderer={TitleRenderer}
        IconRenderer={IconRenderer}
        id="resourcePicker"
      />
      {status === 'DELETED' && (
        <WarningBox
          wrapperClassName='mt-3'
        >
          {t('This form has been deleted and won’t be visible in the app.')}
        </WarningBox>
      )}
      <ScActionButtons buttonBgColor="#FF6200" buttonBgHoverColor="#E55300">
        <Button
          onClick={handleEditFormClick}
          className="customMagnetBtn firstBtn"
        >
          {t('Edit Form')}
        </Button>
        <ReplaceFormButton formID={currentFormID}>
          {t('Change Form')}
        </ReplaceFormButton>
      </ScActionButtons>
    </ScResourceSelection>
  );
};

FormSelection.propTypes = {
  value: string,
  formTitle: string,
  onChange: func,
  status: string.isRequired,
  isInitialForm: string
};

FormSelection.defaultProps = {
  value: '',
  formTitle: '',
  onChange: f => f,
  isInitialForm: ''
};

export default FormSelection;
