import { Hooks } from '@jotforminc/uikit';
import { useCallback, useEffect, useState } from 'react';

const INFINITE_LOADING_FETCH_LIMIT = 50;

export const useInfiniteFormLoading = ({
  fetchInfiniteFormsCallback, searchTerm, setItems
}) => {
  const [offset, setOffset] = useState(-1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleInfiniteLoading = async () => {
    if (!hasMore) {
      return;
    }

    try {
      setLoading(true);
      const res = await fetchInfiniteFormsCallback({ offset: offset < 0 ? 0 : offset * 50, limit: INFINITE_LOADING_FETCH_LIMIT, filter: { fullText: searchTerm } });
      setItems(prev => ([...new Set([...prev, ...res])]));
      setLoading(false);
      if (res?.length < INFINITE_LOADING_FETCH_LIMIT) {
        setHasMore(false);
      }
    } catch {
      // error handling
    }
  };

  const onIntersection = useCallback(([{ isIntersecting }]) => {
    if (isIntersecting) {
      setOffset(prev => prev + 1);
    }
  }, [setOffset]);

  useEffect(() => {
    const intersectionElement = document.querySelector('.infinite-loading-intersection');
    let observer;

    if (global.IntersectionObserver) {
      observer = new global.IntersectionObserver(onIntersection, { threshold: 0.0 });

      if (intersectionElement) observer.observe(intersectionElement);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  Hooks.useEffectIgnoreFirst(() => {
    handleInfiniteLoading();
  }, [offset]);

  return {
    loading, hasMore, setHasMore, setOffset
  };
};
