import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseFooter from './BaseFooter';
import { changeGatewaySettingsView } from '../../store/actionCreators';
import Selectors from '../../store/selectors';
import {
  IGatewaySettings, TFormId
} from '../../types/common';
import { updateGatewaySettingsActionHelper } from '../../utils/actionUtils';

const ConnectionModalFooter = ({
  checkoutFormId,
  activeGatewaySettings,
  temporaryActiveGatewaySettings
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const propValues = useSelector(Selectors.getConnectionModalPropValues) || {};

  const isAllInputsAreFilled = useMemo(() => {
    return Object.keys(propValues).every(key => propValues[key].trim().length > 0);
  }, [propValues]);

  const handleSave = () => {
    updateGatewaySettingsActionHelper({
      checkoutFormId,
      activeGatewaySettings,
      updatingSettings: {
        ...temporaryActiveGatewaySettings,
        ...propValues
      },
      successCallback: () => {
        dispatch(changeGatewaySettingsView('gateway-settings'));
      },
      errorCallback: err => console.error(err),
      dispatch
    });
  };

  return (
    <>
      <BaseFooter
        saveText='Continue'
        backText='Back'
        handleSave={handleSave}
        handleBack={() => dispatch(changeGatewaySettingsView('gateway-settings'))}
        disabled={!isAllInputsAreFilled}
      />
    </>
  );
};

interface IProps {
  activeGatewaySettings: IGatewaySettings
  temporaryActiveGatewaySettings: Partial<IGatewaySettings>
  checkoutFormId: TFormId
}

export default ConnectionModalFooter;
