import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

const ALMOST_FULL_PERCENT = 70;
const ESTIMATION_PERCENT = 50;
const MAX_DEFAULT_LIMIT = 10000000;
const allowedLimitTypes = ['uploads', 'payments', 'signedDocuments', 'views', 'submissions', 'total_submissions'];

const sortObjectByValue = obj => Object.fromEntries(Object.entries(obj).sort((a, b) => parseFloat(b[1]) - parseFloat(a[1])));
const isUnlimited = value => value === null || value === undefined || value === 0 || value === -1;

export const getAvailablePlansForUser = userType => {
  switch (userType) {
    case 'DIAMOND':
    case 'GOLD':
      return ['ENTERPRISE'];
    case 'SILVER':
      return ['GOLD'];
    case 'ECONOMY':
    case 'BRONZE':
      return ['SILVER', 'GOLD'];
    case 'FREE':
    default:
      return ['BRONZE', 'SILVER', 'GOLD'];
  }
};

export const decideActiveCampaign = user => {
  const { campaign = {} } = user;
  const { type = '' } = campaign;

  const availableCampaigns = ['NEWUSER', 'BLACKFRIDAY', 'EOY', 'LOCKDOWN', 'SUMMER', 'OVERQUOTAUSER'];
  return availableCampaigns.includes(type) ? type : false;
};

export const calculateLimitWarning = ({ usage: usageProp = {}, limits: limitsProp = {} }) => {
  const usage = { ...usageProp };
  const limits = { ...limitsProp };

  if (isEmpty(usage) || isEmpty(limits)) return null;

  // Convert signed_documents to signedDocuments.
  delete Object.assign(usage, { signedDocuments: usage?.signed_documents })?.signed_documents;

  // Turn unlimited limits to max int
  let filterLimitTypes = Object.fromEntries(Object.entries(limits)?.map(q => (isUnlimited(q[1]) ? [q[0], MAX_DEFAULT_LIMIT] : q)));

  // Filter by type
  filterLimitTypes = pick(limits, allowedLimitTypes);
  const filterUsageTypes = pick(usage, allowedLimitTypes);
  const limitPercentage = {};

  // Calculate percentage
  Object.keys(filterUsageTypes).forEach(key => {
    const usageValue = parseFloat(filterUsageTypes[key]);
    const limitValue = parseFloat(filterLimitTypes[key]);
    const percentage = ((100 * usageValue) / limitValue);
    limitPercentage[key] = Number(percentage.toFixed(2));
  });

  const sortedLimits = sortObjectByValue(limitPercentage);

  const isOverQuota = Object.values(sortedLimits).some(q => q >= 100);
  if (isOverQuota) {
    const overquotaLimit = Object.keys(sortedLimits).find(key => sortedLimits[key] >= 100);
    return { type: 'overquota', limit: overquotaLimit };
  }

  const isAlmostFull = Object.values(sortedLimits).some(q => q >= ALMOST_FULL_PERCENT);
  if (isAlmostFull) {
    const almostFullLimit = Object.keys(sortedLimits).find(key => sortedLimits[key] >= ALMOST_FULL_PERCENT);
    return { type: 'almostFull', limit: almostFullLimit };
  }

  return null;
};

export const shouldShowEstimationWarning = ({ usage, limit }) => {
  if (usage === 0 || limit === 0) return false;

  const percentage = ((100 * usage) / limit);

  return percentage > ESTIMATION_PERCENT && percentage < ALMOST_FULL_PERCENT;
};
