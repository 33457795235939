import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';

const ContainerDiv = Styled.div`
  position: relative;
  z-index: 1;
  width: ${props => props.size};
  height: ${props => props.size};
  max-width: 100px;
  max-height: 180px;
  display: flex;
  justify-content: center;
`;
const Container = ({ size = 'calc(10px + 5vw + 5vh)', children = null, className = '' }) => {
  return (
    <ContainerDiv className={className} size={size}>
      {children}
    </ContainerDiv>
  );
};

export default Container;

Container.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};
