import {
  FETCH_NOTIFICATION_STATS,
  FETCH_NOTIFICATON_HISTORY, SEND_PUSH_NOTIFICATION
} from '../actionTypes';
import { getDefaultPermissionRequestTexts } from '../../modules/Builder/components/Settings/constants';

const initialState = {
  consentMessage: getDefaultPermissionRequestTexts(),
  notificationHistory: [],
  sendNotificationLoading: false,
  stats: {}
};

const PushNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATON_HISTORY.SUCCESS: {
      return {
        ...state,
        notificationHistory: action.notificationHistory
      };
    }
    case FETCH_NOTIFICATION_STATS.SUCCESS: {
      return {
        ...state,
        stats: action.stats
      };
    }
    case SEND_PUSH_NOTIFICATION.REQUEST: {
      return {
        ...state,
        sendNotificationLoading: true
      };
    }
    case SEND_PUSH_NOTIFICATION.SUCCESS: {
      return {
        ...state,
        sendNotificationLoading: false,
        notificationHistory: action.payload
      };
    }
    case SEND_PUSH_NOTIFICATION.ERROR: {
      return {
        ...state,
        sendNotificationLoading: false
      };
    }
    default:
      break;
  }
  return state;
};

export default PushNotificationReducer;
