export const convertTextAlignment2Flex = textAlignmentValue => {
  switch (textAlignmentValue) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

const convertHexToRGB = colorInHex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorInHex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const extractColorsOfRGB = colorInRgb => {
  const [, r, g, b] = /^rgba?\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})/i.exec(colorInRgb);
  return { r, g, b };
};

const getRGB = color => {
  switch (true) {
    case color.startsWith('#'): // #rrggbbaa notation is not supported
      return convertHexToRGB(color);
    case /rgba?\(/i.test(color):
      return extractColorsOfRGB(color);
    default:
      return null;
  }
};

export const applyAlpha = (color, alpha) => {
  if (!color) return;

  const rgb = getRGB(color);
  if (!rgb) return color;

  const { r, g, b } = rgb;
  const alphaApplied = `rgba(${r},${g},${b},${alpha})`;
  return alphaApplied;
};

export const alignWrapper = textAlignment => {
  switch (textAlignment) {
    case 'left':
      return 'flex-direction: row;';
    case 'center':
      return 'flex-direction: column;';
    case 'right':
      return 'flex-direction: row-reverse;';
    default:
      return '';
  }
};

export const generateStyleByTextAlignment = (textAlignment, textAlignmentValues) => {
  switch (textAlignment) {
    case 'left':
      return textAlignmentValues?.left;
    case 'center':
      return textAlignmentValues?.center;
    case 'right':
      return textAlignmentValues?.right;
    default:
      return '';
  }
};

export const getWidth = device => {
  switch (device) {
    case 'mobile':
      return '100%';
    case 'tablet':
      return '480px';
    default:
      return '672px';
  }
};

export const mediaQuerify = (targetDevice = 'mobile', considerUA, css) => {
  const query = targetDevice === 'mobile' ? '480px' : '768px';
  return `
    ${considerUA ? `${css}` : ''}
    @media screen and (max-width: ${query}) {
      ${css}
    }
  `;
};

export const calculatedAppLogoSize = appLogoSize => {
  const minValue = 80;
  const maxValue = 624;

  const ratio = (maxValue - minValue) / 100;
  return minValue + (appLogoSize * ratio);
};
