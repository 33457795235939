import { cva } from 'class-variance-authority';
import { CVAType } from '../../types/system.types';
import { primary, secondary } from '../../tokens/colors';
import { BaseInputAddonProps, BaseInputProps } from './input.types';

type BaseInputHelperTypes = {
  hasPlaceholder: boolean,
  hasChildren: boolean
}

// CVA Types
type BaseInputCVAType = CVAType<Pick<BaseInputProps, 'as' | 'theme' | 'size'> & BaseInputHelperTypes>
type BaseInputContainerCVAType = CVAType<Pick<BaseInputProps, 'as' | 'size' | 'expand' | 'disabled' | 'readOnly'>>
type BaseInputAddonTextCVAType = CVAType<Pick<BaseInputAddonProps, 'size' | 'theme' | 'readOnly' | 'disabled' | 'direction' | 'variant' | 'useInputColor'>>
type BaseInputAddonIconCVAType = CVAType<Pick<BaseInputAddonProps, 'size'>>
type InputSpinButtonCVAType = CVAType<Pick<BaseInputProps, 'theme' | 'disabled' | 'readOnly'>>

// Classes
export const baseInputContainerCVA = cva<BaseInputContainerCVAType>('', {
  variants: {
    size: {},
    expand: {},
    disabled: {},
    readOnly: {},
    as: {
      input: 'magnet-input-container',
      dropdown: 'magnet-dropdown items-center'
    }
  },
  compoundVariants: [
    {
      expand: false,
      size: 'small',
      className: 'h-8'
    },
    {
      expand: false,
      size: 'medium',
      className: 'h-10'
    },
    {
      expand: false,
      size: 'large',
      className: 'h-12'
    },
    {
      expand: true,
      size: 'small',
      className: 'min-h-8 py-1'
    },
    {
      expand: true,
      size: 'medium',
      className: 'min-h-10 py-1'
    },
    {
      expand: true,
      size: 'large',
      className: 'min-h-12 py-1.5'
    },
    {
      disabled: true,
      as: 'dropdown',
      className: 'cursor-not-allowed'
    },
    {
      disabled: false,
      readOnly: false,
      as: 'dropdown',
      className: 'cursor-pointer'
    }
  ]
});

export const baseInputCVA = cva<BaseInputCVAType>('', {
  variants: {
    size: {
      small: 'px-2',
      medium: 'px-3',
      large: 'px-3'
    },
    as: {
      input: 'magnet-input h-full color-current',
      dropdown: 'truncate'
    },
    hasPlaceholder: {},
    hasChildren: {},
    theme: {}
  },
  compoundVariants: [
    {
      hasPlaceholder: true,
      hasChildren: false,
      as: 'dropdown',
      theme: 'light',
      className: 'color-navy-200'
    },
    {
      hasPlaceholder: true,
      hasChildren: false,
      as: 'dropdown',
      theme: 'dark',
      className: secondary.text.light.base
    }
  ]
});

export const baseInputAddon = {
  textCVA: cva<BaseInputAddonTextCVAType>('flex shrink-0 items-center', {
    variants: {
      size: {
        small: 'gap-2 text-sm',
        medium: 'gap-3 text-sm',
        large: 'gap-3 text-md'
      },
      theme: {},
      readOnly: {},
      disabled: {},
      direction: {},
      variant: {},
      useInputColor: {}
    },
    compoundVariants: [
      {
        direction: 'start',
        className: 'radius-l'
      },
      {
        direction: 'end',
        className: 'radius-r'
      },
      {
        useInputColor: false,
        theme: 'light',
        className: primary.text.medium.base
      },
      {
        useInputColor: false,
        theme: 'dark',
        className: 'color-gray-200'
      },
      {
        direction: 'start',
        variant: 'ghost',
        size: 'small',
        className: 'pl-2'
      },
      {
        direction: 'start',
        variant: 'ghost',
        size: ['medium', 'large'],
        className: 'pl-3'
      },
      {
        direction: 'end',
        variant: 'ghost',
        size: 'small',
        className: 'pr-2'
      },
      {
        direction: 'end',
        variant: 'ghost',
        size: ['medium', 'large'],
        className: 'pr-3'
      },
      {
        variant: 'filled',
        size: 'small',
        className: 'px-2'
      },
      {
        variant: 'filled',
        size: ['medium', 'large'],
        className: 'px-3'
      },
      {
        variant: 'filled',
        readOnly: false,
        disabled: false,
        theme: 'light',
        className: 'bg-navy-25'
      },
      {
        variant: 'filled',
        readOnly: true,
        disabled: false,
        theme: 'light',
        className: 'bg-navy-50'
      },
      {
        variant: 'filled',
        readOnly: false,
        disabled: true,
        theme: 'light',
        className: 'bg-navy-100'
      },
      {
        variant: 'filled',
        theme: 'dark',
        className: 'bg-gray-400 bg-opacity-50'
      }
    ]
  }),
  iconCVA: cva<BaseInputAddonIconCVAType>('shrink-0', {
    variants: {
      size: {
        small: 'w-4',
        medium: 'w-5',
        large: 'w-6'
      }
    }
  })
};

export const numberSpinButtonCVA = cva<InputSpinButtonCVAType>('flex shrink-0 grow-1 justify-center items-center', {
  variants: {
    theme: {},
    readOnly: {},
    disabled: {}
  },
  compoundVariants: [
    {
      theme: 'light',
      disabled: false,
      readOnly: false,
      className: [primary.text.dark.base, 'bg-navy-10']
    },
    {
      theme: 'light',
      readOnly: true,
      disabled: false,
      className: 'color-navy-200 bg-navy-75'
    },
    {
      theme: 'light',
      readOnly: false,
      disabled: true,
      className: 'color-navy-200 bg-navy-75'
    },
    {
      theme: 'dark',
      className: 'bg-gray-400'
    },
    {
      theme: 'dark',
      readOnly: true,
      className: 'color-gray-300'
    },
    {
      theme: 'dark',
      disabled: true,
      className: 'color-gray-300'
    }
  ]
});
