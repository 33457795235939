import React, { useMemo, useCallback, useRef } from 'react';
import { t } from '@jotforminc/translation';
import { useDebounce } from '@jotforminc/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  shape, string, bool, oneOfType, number
} from 'prop-types';
import { sanitizeHTML } from '../../../../../utils';
import { ScRightPanelBlock, ScTetheredBuilderWrapper } from '../../../styles/scRightPanel';
import SELECTORS from '../../../../../store/selectors';
import { RightPanelModes } from '../constants';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import { useAppDefaults, useItemDefaults } from '../../../../../properties';
import PanelItemDescriptionRenderer from './PanelItemDescriptionRenderer';
import WidgetItemDescriptionRenderer from './WidgetItemDescriptionRenderer';
import { ITEM_TYPES } from '../../../../../constants/itemTypes';
import TetheredWrapper from '../../../../../components/TetheredWrapper';
import { PropertyItemMapper } from './rightPanelPropertyItems';

const PanelItemGenerator = ({
  elements, property, selectedItemID, itemProps
}) => {
  const dispatch = useDispatch();
  const rightPanelMode = useSelector(SELECTORS.getRightPanelModeSelector);
  const portalProps = useSelector(SELECTORS.getAppInfoWithDefaults);
  const memoItemProps = useMemo(() => itemProps, [selectedItemID, itemProps]);
  const isItemPropertyPanel = useMemo(() => rightPanelMode === RightPanelModes.APP_ITEM, [rightPanelMode]);
  const isWidgetPanel = rightPanelMode === RightPanelModes.APP_WIDGET;

  const panelRef = useRef();

  const memoProps = useMemo(() => {
    return (isItemPropertyPanel || isWidgetPanel) ? memoItemProps : portalProps;
  }, [isItemPropertyPanel, isWidgetPanel, memoItemProps, portalProps]);

  const {
    title,
    disableElementLabel,
    type: elementType,
    isColumn = false,
    noPadding = false,
    tethered = false,
    sanitize = false,
    noLine = false,
    ariaLabel = '',
    role = '',
    allowMultiplePropChange = false,
    additionalProps,
    ...elementProps
  } = elements;

  const handleItemPropChanges = useCallback((data, changeSettings = {}) => {
    dispatch(ACTION_CREATORS.updateItemPropAction({
      itemID: selectedItemID,
      prop: {
        ...data
      },
      linkedItemID: elements.linkedItemID,
      ...changeSettings
    }));
  }, []);

  const debouncedPortalDispatch = useDebounce(data => dispatch(ACTION_CREATORS.updatePortalAction(data)), 150);
  const onPropChange = useCallback(prop => e => debouncedPortalDispatch({ [prop]: e }), []);
  const onItemPropChange = useCallback(name => e => handleItemPropChanges({ [name]: e }), []);
  const debouncedMultipleItemPropsChange = useDebounce(handleItemPropChanges, 150);
  const onItemMultiplePropChanges = useCallback(debouncedMultipleItemPropsChange, []);

  // This is shitty workaround
  // Todo @resat => refactor here
  const additionalElementProps = useMemo(() => {
    if (isWidgetPanel) {
      return { src: memoItemProps.src, page: memoItemProps.page };
    }
    if (!additionalProps) return {};
    return Object.entries(memoItemProps).reduce((prev, [key, value]) => {
      return additionalProps.includes(key) ? { ...prev, [key]: value } : { ...prev };
    }, {});
  }, [property, memoItemProps, additionalProps, isWidgetPanel]);

  const handleGlobalOnChange = useCallback(prop => {
    switch (true) {
      case allowMultiplePropChange:
        return onItemMultiplePropChanges;
      case isItemPropertyPanel || isWidgetPanel:
        return onItemPropChange(prop);
      default:
        return onPropChange(prop);
    }
  }, []);

  const globalOnChange = useCallback(handleGlobalOnChange, []);
  const defaultProperties = useMemo(() => {
    return isItemPropertyPanel ? useItemDefaults(memoItemProps.type) : useAppDefaults();
  }, [isItemPropertyPanel, memoItemProps.type]);

  const hasValue = useMemo(() => Object.keys(memoProps).includes(property), [memoProps, property]);
  const elementValue = useMemo(() => memoProps[property], [memoProps, property]);
  const defaultValue = useMemo(() => defaultProperties[property], [defaultProperties, property]);
  const value = useMemo(() => (hasValue ? elementValue : defaultValue), [hasValue, defaultValue, elementValue]);
  const propertyValue = useMemo(() => {
    const overrideWithPreviewLink = memoProps.type === ITEM_TYPES.LINK && memoProps.previewTitle && property === 'description';

    const val = overrideWithPreviewLink ? (value || memoProps.previewTitle) : value;
    return sanitize ? sanitizeHTML(val) : val;
  }, [sanitize, value, memoProps.previewTitle]); // TODO more sanitization?

  const id = useMemo(() => `${property}_${selectedItemID || 'app'}`, [selectedItemID]);

  const Element = useMemo(() => (PropertyItemMapper[elementType]), [elementType]);
  if (!Element) {
    return (<div style={{ color: '#ffffff' }}>{`Item prop panel for ${elementType} is not ready`}</div>);
  }

  const extraProps = useMemo(() => {
    return ['textarea', 'textinput'].includes(elementType)
      ? { ...elementProps }
      : {
        ...elementProps,
        targetRef: panelRef,
        onPropChange: onPropChange, // what happens to textAraa or textInput?
        ...disableElementLabel ? { title } : {}
      };
  }, [elementType, elementProps, disableElementLabel, title]);
  const DefaultDescriptionRenderer = isWidgetPanel ? WidgetItemDescriptionRenderer : PanelItemDescriptionRenderer; // TODO can be moved to HOC withDescriptionPanelItem

  return (
    <>
      {noLine ? (
        <Element
          {...elementProps}
          {...portalProps}
          value={value}
          DescriptionRenderer={DefaultDescriptionRenderer}
        />
      ) : (
        <>
          <ScRightPanelBlock
            ref={panelRef}
            column={isColumn}
            noPadding={noPadding}
            key={`${selectedItemID}_${elementType}`}
            role={role}
            aria-label={ariaLabel}
            tabIndex={0}
          >
            {!disableElementLabel && <label htmlFor={id} aria-label={ariaLabel}>{t(title)}</label>}
            <TetheredWrapper key="tetheredWrapper" hasWrapper={tethered} Wrapper={ScTetheredBuilderWrapper}>
              <Element
                {...extraProps}
                {...additionalElementProps}
                id={id}
                onChange={globalOnChange(property)}
                value={propertyValue}
                DescriptionRenderer={DefaultDescriptionRenderer}
              />
            </TetheredWrapper>
          </ScRightPanelBlock>
        </>
      )}
    </>
  );
};

PanelItemGenerator.propTypes = {
  property: string.isRequired,
  elements: oneOfType([string, shape({})]).isRequired,
  lineElement: bool,
  selectedItemID: oneOfType([number, string]),
  itemProps: shape({})
};

PanelItemGenerator.defaultProps = {
  lineElement: false,
  selectedItemID: '',
  itemProps: {}
};

export default PanelItemGenerator;
