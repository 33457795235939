import { t } from '@jotforminc/translation';
import { func } from 'prop-types';
import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogIcon,
  DialogTitle,
  DialogDescription,
  DialogActions
} from '@jotforminc/magnet';
import { useDispatch } from 'react-redux';
import { IconArrowRotateRight } from '@jotforminc/svg-icons';
import * as ACTION_CREATORS from '../../store/actionCreators';

const RestartAppModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const handleRestartProgress = useCallback(() => {
    dispatch(ACTION_CREATORS.restartProgressAction());
    onClose();
  }, []);

  return (
    <Dialog
      open={true}
      onClose={onClose}
    >
      <DialogBody>
        <DialogIcon
          icon={IconArrowRotateRight}
          colorStyle="informative"
        />
        <DialogTitle>{t('Restart progress')}</DialogTitle>
        <DialogDescription>
          <p className='m-5'>{t('Are you sure you want to restart your progress?')}</p>
          <p>{t('All required steps will be restored, and')}</p>
          <p className="font-black">{t('your submissions won’t be deleted.')}</p>
        </DialogDescription>
      </DialogBody>
      <DialogActions defaultAlignment="false">
        <Button
          colorStyle="secondary"
          variant="outline"
          size="medium"
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          colorStyle="primary"
          variant="filled"
          size="medium"
          onClick={handleRestartProgress}
        >
          {t('Yes, Restart')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RestartAppModal.propTypes = {
  onClose: func.isRequired
};

export default RestartAppModal;
